export const QuestionnaireDummyData = [
  {
    answer: 'Immediately.',
    key: 'data-01',
    question: 'What date would you like to move in?',
  },
  {
    answer: 'For one year.',
    key: 'data-02',
    question: 'How long do you want to rent this apartment?',
  },
  {
    answer: 'I have a dog.',
    key: 'data-03',
    question: 'Do you have pets?',
  },
  {
    answer: 'I want a bigger apartment.',
    key: 'data-04',
    question: 'Why are you moving?',
  },
  {
    answer: 'Just me.',
    key: 'data-05',
    question: 'How many people will be living in the unit?',
  },
  {
    answer: 'No',
    key: 'data-06',
    question: 'How many people living with you who smoke?',
  },
  {
    answer: 'no',
    key: 'data-07',
    question: 'Have you ever been convicted of a relevant crime?',
  },
  {
    answer: 'No',
    key: 'data-08',
    question: 'Have you ever been evicted?',
  },
  {
    answer: 'No',
    key: 'data-09',
    question: 'Have you ever broken rental agreement?',
  },
  {
    answer:
      // eslint-disable-next-line max-len
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    key: 'data-10',
    question:
      'Please, give a short description of yourself and your situation?',
  },
];
