import { ReactComponent as ArchitectureDoorIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as MapsPinIcon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin.svg';

import { getUnitAddress } from '../+unit';
import type {
  AttachmentSequenceSerializerDTO,
  CompanySerializerDTO,
} from '../../connectors/company';
import { GeneralAPI } from '../../connectors/company';
import {
  EntityEnumDTO,
  PropertiesAPI,
  UnitCategoryEnumDTO,
} from '../../connectors/property';
import type {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
  CitySerializerDTO,
  CommonProcessSerializersAttachmentAttachmentSerializerDTO as AttachmentSerializerDTO, // eslint-disable-line max-len
  CoordinatesSerializerDTO,
  CountrySerializerDTO,
  CreatePropertySerializerDTO,
  DetailedPropertySerializerDTO,
  ListAttachmentSerializerDTO,
  ListLogSerializerDTO,
  ListPropertiesSerializerDTO,
  ListPropertyObjectsSerializerDTO,
  PropertySerializerDTO,
  UnitSerializerDTO,
} from '../../connectors/property';
import type { AttachmentVisibilityEnumDTO } from '../../connectors/ticket';
import { TicketsAPI } from '../../connectors/ticket';
import { mieInstance } from '../mie.instance';
import type {
  AsyncAutocompleteOption,
  AttachmentFilterValues,
  CustomFile,
  ListQueryParams,
  LocalizationFilterValues,
} from '../shared';
import { getEnvValue } from '../shared/helpers/env.helper';
import { getAddress } from './shared';

class PropertyClient {
  private client;

  private generalClient;

  private ticketClient;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.client = new PropertiesAPI(undefined, baeBath, mieInstance);
    this.generalClient = new GeneralAPI(undefined, baeBath, mieInstance);
    this.ticketClient = new TicketsAPI(undefined, baeBath, mieInstance);
  }

  public getCoordinates$ = async (
    queryParams?: ListQueryParams<LocalizationFilterValues>,
  ): Promise<CoordinatesSerializerDTO[]> => {
    const { filters, search } = { ...queryParams };
    const { city, country, region } = { ...filters };
    const response =
      await this.client.getPropertiesCoordinatesListPropertiesCoordinatesGet({
        cityUuid: city,
        countryUuid: country,
        regionUuid: region,
        search,
      });

    return response.data;
  };

  public getList$ = async (
    companyId?: CompanySerializerDTO['uuid'],
    queryParams?: ListQueryParams<LocalizationFilterValues>,
  ): Promise<ListPropertiesSerializerDTO> => {
    const { filters, page, pageSize, search } = {
      ...queryParams,
    };
    const { city, country, region } = { ...filters };
    const response = await this.client.getPropertiesPropertiesGet({
      cityUuid: city || undefined,
      companyUuid: companyId,
      countryUuid: country || undefined,
      page,
      pageSize,
      regionUuid: region || undefined,
      search,
    });

    return response.data;
  };

  public getPropertyObjects$ = async (
    id: PropertySerializerDTO['uuid'],
    queryParams?: ListQueryParams,
  ): Promise<ListPropertyObjectsSerializerDTO> => {
    const { page, pageSize, search, sort, tab } = { ...queryParams };
    const response =
      await this.client.getPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet(
        {
          category: tab,
          order: sort,
          page,
          pageSize,
          propertyUuid: id,
          search,
        },
      );

    return response.data;
  };

  public getDetails$ = async (
    id: PropertySerializerDTO['uuid'],
  ): Promise<DetailedPropertySerializerDTO> => {
    const response =
      await this.client.getSinglePropertyPropertiesPropertyUuidGet({
        propertyUuid: id,
      });

    return response.data;
  };

  public getLogs$ = async (
    id: PropertySerializerDTO['uuid'],
    queryParams?: ListQueryParams,
  ): Promise<ListLogSerializerDTO> => {
    const { page, pageSize } = { ...queryParams };
    const response =
      await this.client.getSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet(
        {
          page,
          pageSize,
          propertyUuid: id,
        },
      );

    return response.data;
  };

  public update$ = async (
    id: PropertySerializerDTO['uuid'],
    values: CreatePropertySerializerDTO,
  ): Promise<PropertySerializerDTO> => {
    const response = await this.client.patchPropertyPropertiesPropertyUuidPatch(
      {
        patchPropertySerializerDTO: values,
        propertyUuid: id,
      },
    );

    return response.data;
  };

  public create$ = async (
    values: CreatePropertySerializerDTO,
  ): Promise<PropertySerializerDTO> => {
    const response = await this.client.createPropertyPropertiesPost({
      createPropertySerializerDTO: values,
    });

    return response.data;
  };

  public delete$ = async (id: PropertySerializerDTO['uuid']): Promise<void> => {
    await this.client.deletePropertyPropertiesPropertyUuidDelete({
      propertyUuid: id,
    });
  };

  public getSuggestions$ = async (
    query: string,
    companyUuid?: CompanySerializerDTO['uuid'],
    city: CitySerializerDTO['uuid'] = '',
    country: CountrySerializerDTO['uuid'] = '',
  ): Promise<AsyncAutocompleteOption<PropertySerializerDTO['uuid']>[]> => {
    const queryParams: ListQueryParams<LocalizationFilterValues> = {
      filters: { city, country, region: '' },
      search: query,
    };
    const response = await this.getList$(companyUuid, queryParams);

    return response.results.map((result) => {
      const address = getAddress(result.city);

      return {
        icon: MapsPinIcon,
        label: `${result.name} (${address})`,
        value: result.uuid,
      };
    });
  };

  public getHomeSuggestions$ = async (
    id: PropertySerializerDTO['uuid'],
    query: string,
  ): Promise<AsyncAutocompleteOption<UnitSerializerDTO['uuid']>[]> => {
    const response =
      await this.client.getPropertyUnitsPropertiesPropertyUuidUnitsGet({
        category: UnitCategoryEnumDTO.ResidentialHomes,
        propertyUuid: id,
        search: query,
      });

    return response.data.results.map((result) => {
      const address = getUnitAddress(
        result.addressLine || '',
        result.city,
        result.streetNumber || '',
      );

      return {
        icon: ArchitectureDoorIcon,
        label: `${result.name} (${address})`,
        value: result.uuid,
      };
    });
  };

  public getAttachments$ = async (
    id: PropertySerializerDTO['uuid'],
    category?: AttachmentCategoryEnumDTO,
    queryParams?: ListQueryParams<AttachmentFilterValues>,
  ): Promise<ListAttachmentSerializerDTO> => {
    const {
      filters,
      page,
      pageSize = 100,
      search,
      sort = 'sequence_number',
    } = { ...queryParams };
    const { type } = { ...filters };
    const response =
      await this.client.getPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet(
        {
          category,
          order: sort,
          page,
          pageSize,
          propertyUuid: id,
          search,
          type: type?.join(',') || undefined,
        },
      );

    return response.data;
  };

  public uploadAttachment$ = async (
    category: AttachmentCategoryEnumDTO,
    file: CustomFile,
    type: AttachmentTypeEnumDTO,
    id: PropertySerializerDTO['uuid'],
    visibility: AttachmentVisibilityEnumDTO,
  ): Promise<AttachmentSerializerDTO[]> => {
    const response =
      await this.client.uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost(
        {
          attachmentCategory: category,
          attachmentType: type,
          description: file.description,
          files: [file],
          propertyUuid: id,
          title: file.title,
          visibility,
        },
      );

    return response.data;
  };

  public updateAttachment$ = async (
    attachment: AttachmentSerializerDTO,
    id: PropertySerializerDTO['uuid'],
  ): Promise<AttachmentSerializerDTO> => {
    const response =
      await this.generalClient.patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch(
        {
          attachmentUuid: attachment.uuid,
          entityType: EntityEnumDTO.Property,
          entityUuid: id,
          patchAttachmentSerializerDTO: attachment,
        },
      );

    return response.data;
  };

  public updateAttachmentsSequence$ = async (
    sequence: AttachmentSequenceSerializerDTO[],
  ): Promise<AttachmentSequenceSerializerDTO[]> => {
    const response =
      await this.generalClient.updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut(
        { listAttachmentSequenceSerializerDTO: { attachments: sequence } },
      );

    return response.data.results;
  };

  public deleteAttachment$ = async (
    propertyId: PropertySerializerDTO['uuid'],
    attachmentId: AttachmentSerializerDTO['uuid'],
  ): Promise<void> => {
    await this.client.deletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete(
      { attachmentUuid: attachmentId, propertyUuid: propertyId },
    );
  };

  public downloadAttachment$ = async (
    propertyId: PropertySerializerDTO['uuid'],
    attachmentId: AttachmentSerializerDTO['uuid'],
  ): Promise<string> => {
    const response =
      await this.client.downloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet(
        { attachmentUuid: attachmentId, propertyUuid: propertyId },
        { responseType: 'blob' },
      );

    return response.data;
  };
}
export const propertyClient = new PropertyClient();
