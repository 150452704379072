export enum UnitCategoryPath {
  HOMES = 'homes',
  FACILITIES = 'facilities',
}

const PATHS = {
  ACTIVITY_LOG_LIST: '/units/:category/:id/logs',
  ATTACHMENTS_UPLOAD: '/units/:category/:id/attachments/upload',
  COMPLAINT_LIST: '/units/:category/:id/complaints',
  CONTRACT_REQUEST_LIST: '/units/:category/:id/contract-requests',
  CREATE: '/units/:category/create',
  CREATE_COMPLAINT: '/units/:category/:id/complaint',
  CREATE_CONTRACT_REQUEST: '/units/:category/:id/contract-request',
  CREATE_GENERAL_REQUEST: '/units/:category/:id/general-request',
  CREATE_INVOICE_REQUEST: '/units/:category/:id/invoice-request',
  CREATE_SERVICE_REQUEST: '/units/:category/:id/service-request',
  DETAILS: '/units/:category/:id',
  DOCUMENT_LIST: '/units/:category/:id/documents',
  GENERAL_REQUEST_LIST: '/units/:category/:id/general-requests',
  INVOICE_REQUEST_LIST: '/units/:category/:id/invoice-requests',
  LIST: `/units/:category`,
  SERVICE_REQUEST_LIST: '/units/:category/:id/service-requests',
  UPDATE: '/units/:category/:id/update',
};

export default PATHS;
