import { ReactComponent as CommonFileStackIcon } from '@heimstaden/icons-library/img/streamline-regular/files-folders/common-files/common-file-stack.svg';
import { ReactComponent as AddCircle1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add-circle-1.svg';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { AttachmentVisibilityEnumDTO } from '../../../../../../connectors/ticket';
import type { FileValue } from '../../../../form';
import { useTranslation } from '../../../../translations';
import { supportedDocumentTypeExtensions, MAX_FILE_SIZE } from '../../consts';
import { getType } from '../../helpers';
import { useAttachmentUploader } from '../../providers';
import { sxProps } from './uploader.styles';

export const Uploader: FC = () => {
  const { attachments, setAttachments } = useAttachmentUploader();
  const { t } = useTranslation();
  const onDrop = useCallback(
    (files: FileValue) =>
      setAttachments((prevState) => {
        const attachments = files.map((file, index) => ({
          file,
          isCurrent: prevState.length === 0 && index === 0,
          type: getType(file.type),
          visibility: file.visibility || AttachmentVisibilityEnumDTO.Internal,
        }));

        return [...prevState, ...attachments];
      }),
    [setAttachments],
  );
  const { getInputProps, getRootProps } = useDropzone({
    accept: supportedDocumentTypeExtensions,
    maxSize: MAX_FILE_SIZE,
    onDrop,
  });

  return (
    <Box
      {...getRootProps()}
      sx={
        {
          ...sxProps.box,
          ...(attachments.length > 0 && sxProps.reuploader),
        } as SxProps
      }
    >
      <input {...getInputProps()} data-testid="button-upload-attachments" />
      <Grid
        alignItems="center"
        container
        justifyContent="center"
        spacing={2}
        wrap="nowrap"
      >
        <Grid item>
          {attachments.length > 0 ? (
            <AddCircle1Icon height={24} width={24} />
          ) : (
            <CommonFileStackIcon height={64} width={64} />
          )}
        </Grid>
        <Grid item>
          {attachments.length > 0 ? (
            <Typography variant="h5">
              {t('fields.dropzone.reupload')}
            </Typography>
          ) : (
            <>
              <Typography marginBottom={1} variant="h5">
                {t('fields.dropzone.title')}
              </Typography>
              <Typography>{t('fields.dropzone.subtitle')}</Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
