import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import type {
  EntityEnumDTO,
  NotificationSerializerDTO,
} from '../../../../connectors/notification';
import { NotificationStatusDTO } from '../../../../connectors/notification';
import { sanitizeService } from '../../services';
import { useTranslation } from '../../translations';
import { FormattedDate } from '../FormattedDate/formatted-date.component';
import { notificationClient } from '../NotificationBell/notification.client';
import { NotificationsEmpty } from '../NotificationsEmpty/notifications-empty.component';
import { Scrollbar } from '../Scrollbar/scrollbar.component';
import { getIcon, getLink$ } from './notification-list.helper';
import { sxProps } from './notification-list.styles';

type Props = {
  containerHeight: number;
  notifications: NotificationSerializerDTO[];
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setNotifications: Dispatch<SetStateAction<NotificationSerializerDTO[]>>;
};

export const NotificationList: FC<Props> = (props) => {
  const { containerHeight, notifications, setDisabled, setNotifications } =
    props;
  const { t } = useTranslation();
  const [disabledItems, setDisabledItems] = useState<
    NotificationSerializerDTO['uuid'][]
  >([]);
  const navigate = useNavigate();
  const handleClick = useCallback(
    async (
      status: NotificationStatusDTO,
      entityType: EntityEnumDTO | undefined,
      entityUuid: NotificationSerializerDTO['entityUuid'],
      id: NotificationSerializerDTO['uuid'],
    ) => {
      try {
        setDisabled(true);
        setDisabledItems((prevState) => [...prevState, id]);
        const link = await getLink$(entityType, entityUuid);

        if (status !== NotificationStatusDTO.Seen) {
          await notificationClient.markAsSeen$(id);
          setNotifications((prevState) =>
            prevState.map((notification) =>
              notification.uuid === id
                ? { ...notification, status: NotificationStatusDTO.Seen }
                : notification,
            ),
          );
        }
        if (!link) return;

        navigate(link);
      } catch (e) {
        toast.error(t('errors.general.message'));
      } finally {
        setDisabledItems((prevState) =>
          prevState.filter((item) => item !== id),
        );
        setDisabled(false);
      }
    },
    [navigate, setDisabled, setNotifications, t],
  );

  return (
    <Box height={containerHeight}>
      {notifications.length === 0 ? (
        <NotificationsEmpty />
      ) : (
        <Scrollbar>
          <MenuList sx={sxProps.menuList}>
            {notifications.map((notification) => {
              const { body, entityType, entityUuid, sentAt, status, uuid } =
                notification;
              const Icon = getIcon(entityType);

              return (
                <MenuItem
                  disabled={disabledItems.includes(uuid)}
                  key={uuid}
                  onClick={() => {
                    handleClick(status, entityType, entityUuid, uuid);
                  }}
                  sx={
                    {
                      ...sxProps.menuItem,
                      ...(status === NotificationStatusDTO.Seen &&
                        sxProps.menuItemSeen),
                    } as SxProps
                  }
                >
                  <Box sx={sxProps.iconBox}>
                    <Icon height={24} width={24} />
                  </Box>
                  <Box>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: sanitizeService.cleanText(body),
                      }}
                    />
                    {sentAt && (
                      <Typography color="secondary" variant="body2">
                        <FormattedDate date={sentAt} format="distance" />
                      </Typography>
                    )}
                  </Box>
                </MenuItem>
              );
            })}
          </MenuList>
        </Scrollbar>
      )}
    </Box>
  );
};
