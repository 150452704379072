import type {
  TicketStatusEnumDTO,
  UniversalTicketsWithAdditionalFieldsSerializerDTO,
} from '../../../../../connectors/ticket';
import type { TicketConfig } from '../../providers';
import type { DragObject } from './kanban.type';

type Column = {
  labelKey: GenericTypes.TranslationLabel;
  status: TicketStatusEnumDTO;
  tickets: UniversalTicketsWithAdditionalFieldsSerializerDTO[];
};

export const getColumnsData = (
  statusOptions: TicketConfig['statusOptions'],
  tickets: UniversalTicketsWithAdditionalFieldsSerializerDTO[],
): Column[] =>
  statusOptions.map((option) => ({
    labelKey: option.labelKey as string,
    status: option.value,
    tickets: tickets.filter((ticket) => ticket.status === option.value),
  }));

export const getUnavailableStatuses = (
  currentDrag: DragObject | null,
  statusesWorkflowOptions: TicketConfig['statusesWorkflowOptions'],
  statusOptions: TicketConfig['statusOptions'],
): TicketStatusEnumDTO[] =>
  currentDrag
    ? statusOptions
        .filter((option) => option.value !== currentDrag.status)
        .filter((statusOption) =>
          statusesWorkflowOptions[currentDrag.status].every(
            (option) => option.value !== statusOption.value,
          ),
        )
        .map((option) => option.value)
    : [];
