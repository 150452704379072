import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { ServiceRequestDetailSerializerDTO } from '../../../../../connectors/ticket';
import {
  IssueSubtypeEnumDTO,
  RoomTypeEnumDTO,
} from '../../../../../connectors/ticket';
import {
  getDamagedItemTranslationLabelKey,
  getIssueLocationIcon,
  getIssueLocationTranslationLabelKey,
  getIssueSubtypeTranslationLabelKey,
  getIssueTypeIcon,
  getIssueTypeTranslationLabelKey,
  getRoomTypeIcon,
  getRoomTypeTranslationLabelKey,
} from '../../consts';
import { DetailsFeatureList } from '../DetailsFeatureList/details-feature-list.component';

type Props = {
  damagedItem: ServiceRequestDetailSerializerDTO['damagedItem'];
  issueLocation: ServiceRequestDetailSerializerDTO['issueLocation'];
  issueSubtype: ServiceRequestDetailSerializerDTO['issueSubtype'];
  issueType: ServiceRequestDetailSerializerDTO['issueType'];
  roomWithIssue: ServiceRequestDetailSerializerDTO['roomWithIssue'];
  ticketType?: ServiceRequestDetailSerializerDTO['ticketType'];
};

export const ServiceRequestDetailsFeatureList: FC<Props> = (props) => {
  const {
    damagedItem,
    issueLocation,
    issueSubtype,
    issueType,
    roomWithIssue,
    ticketType,
  } = props;
  const features = useMemo(() => {
    const LocationTypeIcon = getIssueLocationIcon(issueLocation);
    const RoomTypeIcon = getRoomTypeIcon(roomWithIssue);
    const IssueTypeIcon = getIssueTypeIcon(issueType);

    return [
      {
        dataTestId: 'issue-location',
        icon: LocationTypeIcon ? <LocationTypeIcon /> : undefined,
        key: 'issueLocation',
        titleKey: 'ticket.fields.issueLocation',
        valueKey: getIssueLocationTranslationLabelKey(issueLocation),
      },
      ...(roomWithIssue && roomWithIssue !== RoomTypeEnumDTO.None
        ? [
            {
              dataTestId: 'room-issue-location',
              icon: RoomTypeIcon ? <RoomTypeIcon /> : undefined,
              key: 'roomWithIssue',
              titleKey: 'ticket.fields.roomWithIssue.label',
              valueKey: getRoomTypeTranslationLabelKey(roomWithIssue),
            },
          ]
        : []),
      {
        dataTestId: 'issue-type',
        icon: IssueTypeIcon ? <IssueTypeIcon /> : undefined,
        key: 'issueType',
        titleKey: 'ticket.fields.issueType.label',
        valueKey: getIssueTypeTranslationLabelKey(issueType),
      },
      ...(damagedItem
        ? [
            {
              dataTestId: 'damaged-item',
              key: 'damagedItem',
              titleKey: 'ticket.fields.damagedItem.label',
              valueKey: getDamagedItemTranslationLabelKey(damagedItem),
            },
          ]
        : []),
      ...(issueSubtype && issueSubtype !== IssueSubtypeEnumDTO.None
        ? [
            {
              dataTestId: 'issue-sub-type',
              key: 'issueSubtype',
              titleKey: 'ticket.fields.issueSubtype.label',
              valueKey: getIssueSubtypeTranslationLabelKey(issueSubtype),
            },
          ]
        : []),
    ];
  }, [damagedItem, issueLocation, issueSubtype, issueType, roomWithIssue]);

  return <DetailsFeatureList features={features} ticketType={ticketType} />;
};
