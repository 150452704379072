import type { Account } from '../../../../+account';
import { typeOptions } from '../../../../+contract/shared';
import { userClient } from '../../../../+user';
import type { CountrySerializerDTO } from '../../../../../connectors/company';
import { TicketTypeEnumDTO } from '../../../../../connectors/ticket';
import type { UserSerializerDTO } from '../../../../../connectors/user';
import { UserTypeEnumDTO } from '../../../../../connectors/user';
import type { FieldConfig } from '../../../../shared';
import {
  DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  DEFAULT_MULTIPLE_SELECT_VALUE,
  DEFAULT_SELECT_OPTION,
} from '../../../../shared/form/form.const';
import {
  getDefaultAssigneeOptions,
  INITIAL_REQUEST_SORT,
  REQUEST_SORT_OPTIONS,
} from '../../consts';
import { ActorRole } from '../../enums';
import {
  actorRoleOptions,
  departmentTypeOptions,
  priorityTypeOptions,
} from '../../helpers';
import type { TicketConfig } from '../../providers';
import type { FilterValues } from './general-request-filter-panel.type';

export const INITIAL_VALUES: FilterValues = {
  actorRole: ActorRole.REPORTER,
  assignee: DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  department: DEFAULT_SELECT_OPTION.value,
  priority: DEFAULT_SELECT_OPTION.value,
  sort: INITIAL_REQUEST_SORT,
  status: DEFAULT_MULTIPLE_SELECT_VALUE,
  ticketType: TicketTypeEnumDTO.GeneralRequest,
};

export const getFields = (
  country: CountrySerializerDTO['uuid'],
  config: TicketConfig,
  user: Account | null,
  filters?: FilterValues,
  specifiedUser?: Pick<UserSerializerDTO, 'userType' | 'uuid'>,
): FieldConfig<FilterValues>[] => [
  {
    componentType: 'select',
    field: {
      initialValue: INITIAL_VALUES.actorRole,
      name: 'actorRole',
    },
    props: {
      isFilter: true,
      isHidden: () =>
        Boolean(
          !specifiedUser || specifiedUser.userType === UserTypeEnumDTO.Customer,
        ),
      labelKey: 'ticket.fields.role',
      options: actorRoleOptions,
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.sort || INITIAL_VALUES.sort,
      name: 'sort',
    },
    props: {
      isFilter: true,
      labelKey: 'collection.sortByLabel',
      options: REQUEST_SORT_OPTIONS,
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.department || INITIAL_VALUES.department,
      name: 'department',
    },
    props: {
      isFilter: true,
      labelKey: 'ticket.filters.department',
      options: [DEFAULT_SELECT_OPTION, ...departmentTypeOptions],
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.priority || INITIAL_VALUES.priority,
      name: 'priority',
    },
    props: {
      isFilter: true,
      labelKey: 'ticket.filters.priority',
      options: [DEFAULT_SELECT_OPTION, ...priorityTypeOptions],
    },
  },
  {
    componentType: 'multiple-select',
    field: {
      initialValue: filters?.status || INITIAL_VALUES.status,
      name: 'status',
    },
    props: {
      isFilter: true,
      labelKey: 'ticket.filters.status',
      options: config.statusOptions,
    },
  },
  {
    componentType: 'async-autocomplete',
    field: {
      initialValue: filters?.assignee || INITIAL_VALUES.assignee,
      name: 'assignee',
    },
    props: {
      callback$: (query) =>
        userClient.getEmployeeSuggestions$(
          query,
          country,
          undefined,
          undefined,
          undefined,
          false,
        ),
      isFilter: true,
      isHidden: () => Boolean(specifiedUser),
      labelKey: 'ticket.filters.assignee',
      options: getDefaultAssigneeOptions(user?.uuid),
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: TicketTypeEnumDTO.GeneralRequest,
      name: 'ticketType',
    },
    props: {
      isHidden: () => true,
      options: typeOptions,
    },
  },
];
