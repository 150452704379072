import { Permission } from '../../../../+auth/shared/enums/permission.enum';

export const VIEW_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.VIEW_ATTACHMENTS,
];
export const CREATE_PERMISSIONS = [
  Permission.GLOBAL_WRITE,
  Permission.CAN_CREATE_ATTACHMENTS,
];
