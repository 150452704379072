import {
  format,
  formatDistance as dateFnsFormatDistance,
  isToday, // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// translations for date-fns are under /locale folder - and needed to be used like now to import them, because ES lint want it to be together.
// eslint-disable-next-line import/no-duplicates
import { enUS, nl, pl } from 'date-fns/locale';

import type { LanguageDTO } from '../../../connectors/company';

export const DEFAULT_MIN_DATE = '1900-01-01';
export const STANDARD_DATE_INPUT_FORMAT = 'dd/MM/yyyy';
export const STANDARD_DATE_WRITE_FORMAT = 'yyyy-MM-dd';
const STANDARD_DATE_FORMAT = 'dd MMMM yyyy';
const STANDARD_DATETIME_FORMAT = 'dd MMMM yyyy, HH:mm';
const STANDARD_DATETIME_FORMAT_WITH_DAY = 'EEEE, dd MMMM yyyy, HH:mm';

const localeLang: Record<string, Locale> = { en: enUS, nl, pl };

export const formatStandardDateWrite = (date: Date | string): string =>
  format(new Date(date), STANDARD_DATE_WRITE_FORMAT);

export const formatStandardDate = (
  date: Date | string,
  langCode: LanguageDTO,
): string =>
  format(new Date(date), STANDARD_DATE_FORMAT, {
    locale: localeLang[langCode],
  });

export const formatStandardDateTime = (
  date: Date | string,
  langCode: LanguageDTO,
): string =>
  format(new Date(date), STANDARD_DATETIME_FORMAT, {
    locale: localeLang[langCode],
  });

export const formatStandardDateTimeWithDay = (
  date: Date | string,
  langCode: LanguageDTO,
): string =>
  format(new Date(date), STANDARD_DATETIME_FORMAT_WITH_DAY, {
    locale: localeLang[langCode],
  });

export const formatDistance = (
  date: Date | string,
  langCode: LanguageDTO,
): string => {
  const createdDate = new Date(date);

  if (isToday(createdDate)) {
    return dateFnsFormatDistance(createdDate, new Date(), {
      addSuffix: true,
      locale: localeLang[langCode],
    });
  }

  return formatStandardDateTime(createdDate, langCode);
};

export const mergeDateAndTime = (date: Date, time: Date): Date =>
  new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds(),
  );
