import { ReactComponent as RemoveIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback } from 'react';

import type { ListTicketsSerializerDTO } from '../../../../../connectors/ticket';
import { TicketStatusEnumDTO } from '../../../../../connectors/ticket';
import { useTranslation } from '../../../../shared';
import { useTransition } from '../../providers';
import type { DetailRequests } from '../../types';
import { sxProps } from './close-request-action.styles';

type Props = {
  rows: DetailRequests[];
  setResponse: Dispatch<SetStateAction<ListTicketsSerializerDTO>>;
  setRowsDisabled: Dispatch<SetStateAction<boolean>>;
};

export const CloseRequestAction: FC<Props> = (props) => {
  const { rows, setResponse, setRowsDisabled } = props;
  const { t } = useTranslation();
  const { changeStatus$ } = useTransition();
  const handleCloseRequest = useCallback(async () => {
    const ids = rows
      .filter((row) => row.status !== TicketStatusEnumDTO.Done)
      .map((row) => row.uuid);

    setRowsDisabled(true);

    try {
      await changeStatus$(ids, TicketStatusEnumDTO.Done);
      setResponse((prevState) => ({
        ...prevState,
        results: [
          ...prevState.results.map((ticket) =>
            ids.includes(ticket.uuid)
              ? { ...ticket, status: TicketStatusEnumDTO.Done }
              : ticket,
          ),
        ],
      }));
    } finally {
      setRowsDisabled(false);
    }
  }, [changeStatus$, rows, setResponse, setRowsDisabled]);

  return (
    <Button color="error" onClick={handleCloseRequest} variant="text">
      <Typography color="error" sx={sxProps.text} variant="subtitle2">
        {t('ticket.actions.close')}
        <Icon component={RemoveIcon} sx={sxProps.icon} />
      </Typography>
    </Button>
  );
};
