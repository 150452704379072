import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { useTranslation } from '../../../translations';
import type { GalleryItem } from '../../fields';
import { GalleryImage } from '../GalleryImage/gallery-image.component';

type Props = {
  entity: GalleryItem['entity'];
  items: GalleryItem[];
  selectItem: (id: GalleryItem['id']) => void;
};

export const GalleryPicker: FC<Props> = (props) => {
  const { entity, items, selectItem } = props;
  const { t } = useTranslation();
  const entityItems = useMemo(
    () => items.filter((item) => item.entity === entity),
    [entity, items],
  );

  return (
    <Grid container spacing={3}>
      {entityItems.length > 0 ? (
        entityItems.map((item) => (
          <GalleryImage
            isSelectAllowed
            item={item}
            key={`gallery-image-${item.id}`}
            selectItem={selectItem}
          />
        ))
      ) : (
        <Grid item xs={12}>
          <Typography>{t('form.gallery.browser.empty')}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
