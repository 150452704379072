import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  avatar: {
    height: (theme) => theme.spacing(5),
    width: (theme) => theme.spacing(5),
  },
  container: {
    cursor: 'pointer',
  },
  contentColumn: {
    minWidth: (theme) => `calc(100% - ${theme.spacing(6)})`,
  },
  createdDate: {
    color: 'text.secondary',
    display: 'block',
    marginTop: 1,
    textAlign: 'right',
  },
};
