import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { useTranslation } from '../../../../../shared';
import { FormFieldset } from '../../../../../shared/form/components';
import type { FormValues } from '../form.types';
import { getFieldsetConfig } from './options-step.model';

export const OptionsStep: FC = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();
  const fieldSuffix = useMemo(
    // TODO: Find better solution - get currency from backend
    () => `${t('currency.pl')} ${t('advertisement.fieldset.pricing.period')}`,
    [t],
  );
  const fieldsetConfig = useMemo(
    () => getFieldsetConfig(fieldSuffix),
    [fieldSuffix],
  );

  return (
    <Grid container spacing={3}>
      {fieldsetConfig.map((config) => (
        <FormFieldset<FormValues>
          config={config}
          key={`fieldset-${config.key}`}
          values={values}
        />
      ))}
    </Grid>
  );
};
