import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: 2,
    bottom: (theme) => theme.spacing(2),
    boxShadow:
      '0px 5px 8px -2px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.31)',
    left: 0,
    ml: 2,
    mr: 2,
    p: 2,
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },
  thumbnail: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 1,
    height: 85,
    width: 128,
  },
};
