import Box from '@mui/material/Box';
import MuiGrid from '@mui/material/Grid';
import React, { useMemo } from 'react';
import type { FC, ReactElement } from 'react';

import type { MapProps } from '../Map';
import { Map } from '../Map';
import { Spinner } from '../Spinner/spinner.component';
import { Grid } from './Grid/grid.component';
import { Header } from './Header/header.component';
import { List } from './List/list.component';
import { Pagination } from './Pagination/pagination.component';
import { Summary } from './Summary/summary.component';
import { ViewMode } from './collection.enum';
import { sxProps } from './collection.styles';
import type {
  ListStateCommonProps,
  ModelCommonProps,
  ViewModesCommonProps,
} from './collection.type';

type Props<T, TFilterValues> = ListStateCommonProps<TFilterValues> &
  ModelCommonProps<T> &
  ViewModesCommonProps & {
    model: T[];
    infoWindowComponent?: (id: string) => ReactElement;
    isPaginationVisible?: (viewMode: ViewMode) => boolean;
    locations?: MapProps['locations'];
  };

export const Collection = <T, TFilterValues = undefined>(
  props: Props<T, TFilterValues>,
): ReturnType<FC<Props<T, TFilterValues>>> => {
  const {
    children,
    count,
    filterPanelComponent,
    infoWindowComponent,
    isFetching = false,
    isPaginationVisible = (viewMode) => viewMode !== ViewMode.MAP,
    listState,
    locations,
    setListState,
    viewModes,
    ...restProps
  } = props;
  const viewMode = useMemo(() => listState.viewMode, [listState.viewMode]);
  const commonProps = useMemo(
    () => ({ ...restProps, viewMode }),
    [restProps, viewMode],
  );
  const listStateProps = useMemo(
    () => ({ count, isFetching, listState, setListState }),
    [count, isFetching, listState, setListState],
  );
  const viewModesProps = useMemo(() => ({ viewModes }), [viewModes]);
  const displayPagination = useMemo(
    () => isPaginationVisible(viewMode),
    [isPaginationVisible, viewMode],
  );

  return (
    <MuiGrid container>
      <MuiGrid item xs={12}>
        <Header<TFilterValues> {...listStateProps}>
          {filterPanelComponent}
        </Header>
        {isFetching && <Spinner />}
        {!isFetching && (
          <>
            <Summary<TFilterValues> {...listStateProps} {...viewModesProps} />
            {viewMode === ViewMode.GRID && (
              <Grid<T> {...commonProps}>{children}</Grid>
            )}
            {viewMode === ViewMode.LIST && (
              <List<T> {...commonProps}>{children}</List>
            )}
            {viewMode === ViewMode.MAP && locations && (
              <Box sx={sxProps.mapWrapper}>
                <Map locations={locations}>{infoWindowComponent}</Map>
              </Box>
            )}
            {displayPagination && (
              <MuiGrid alignItems="center" item xs={12}>
                <Pagination<TFilterValues> {...listStateProps} />
              </MuiGrid>
            )}
          </>
        )}
      </MuiGrid>
    </MuiGrid>
  );
};
