/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttachmentCategoryEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentTypeEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentVisibilityEnumDTO } from '../dto';
// @ts-ignore
import { CommonProcessSerializersAttachmentAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { CoordinatesSerializerDTO } from '../dto';
// @ts-ignore
import { CreatePropertySerializerDTO } from '../dto';
// @ts-ignore
import { DetailedPropertySerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { ListAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ListLogSerializerDTO } from '../dto';
// @ts-ignore
import { ListPropertiesSerializerDTO } from '../dto';
// @ts-ignore
import { ListPropertyObjectsSerializerDTO } from '../dto';
// @ts-ignore
import { ListUnitsSerializerDTO } from '../dto';
// @ts-ignore
import { PatchPropertySerializerDTO } from '../dto';
// @ts-ignore
import { PropertyObjectCategoryEnumDTO } from '../dto';
// @ts-ignore
import { PropertySerializerDTO } from '../dto';
// @ts-ignore
import { UnitStatusEnumDTO } from '../dto';
/**
 * PropertiesAPI - axios parameter creator
 * @export
 */
export const PropertiesAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Property
         * @param {CreatePropertySerializerDTO} createPropertySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyPropertiesPost: async (createPropertySerializerDTO: CreatePropertySerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPropertySerializerDTO' is not null or undefined
            assertParamExists('createPropertyPropertiesPost', 'createPropertySerializerDTO', createPropertySerializerDTO)
            const localVarPath = `/properties/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertySerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Property Attachment
         * @param {string} propertyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete: async (propertyUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('deletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete', 'propertyUuid', propertyUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('deletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/properties/{property_uuid}/attachments/{attachment_uuid}`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Property
         * @param {string} propertyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyPropertiesPropertyUuidDelete: async (propertyUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('deletePropertyPropertiesPropertyUuidDelete', 'propertyUuid', propertyUuid)
            const localVarPath = `/properties/{property_uuid}`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Property Attachment
         * @param {string} propertyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet: async (propertyUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('downloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet', 'propertyUuid', propertyUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('downloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/properties/{property_uuid}/attachments/{attachment_uuid}/download`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Properties Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertiesCoordinatesListPropertiesCoordinatesGet: async (search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/properties/coordinates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Properties
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [nickname] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertiesPropertiesGet: async (page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, nickname?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, companyUuid?: string, businessRegion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/properties/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (nickname !== undefined) {
                localVarQueryParameter['nickname'] = nickname;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (municipalityUuid !== undefined) {
                localVarQueryParameter['municipality_uuid'] = municipalityUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }

            if (companyUuid !== undefined) {
                localVarQueryParameter['company_uuid'] = companyUuid;
            }

            if (businessRegion !== undefined) {
                localVarQueryParameter['business_region'] = businessRegion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Attachments
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet: async (propertyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('getPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet', 'propertyUuid', propertyUuid)
            const localVarPath = `/properties/{property_uuid}/attachments`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Property Objects
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [companyUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {PropertyObjectCategoryEnumDTO} [category] 
         * @param {string} [contactUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet: async (propertyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, companyUuid?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, postalCode?: string, category?: PropertyObjectCategoryEnumDTO, contactUuid?: string, businessRegion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('getPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet', 'propertyUuid', propertyUuid)
            const localVarPath = `/properties/{property_uuid}/property_objects`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (companyUuid !== undefined) {
                localVarQueryParameter['company_uuid'] = companyUuid;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (municipalityUuid !== undefined) {
                localVarQueryParameter['municipality_uuid'] = municipalityUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postal_code'] = postalCode;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (contactUuid !== undefined) {
                localVarQueryParameter['contact_uuid'] = contactUuid;
            }

            if (businessRegion !== undefined) {
                localVarQueryParameter['business_region'] = businessRegion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Units
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyObjectUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyUnitsPropertiesPropertyUuidUnitsGet: async (propertyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyObjectUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('getPropertyUnitsPropertiesPropertyUuidUnitsGet', 'propertyUuid', propertyUuid)
            const localVarPath = `/properties/{property_uuid}/units`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (propertyObjectUuid !== undefined) {
                localVarQueryParameter['property_object_uuid'] = propertyObjectUuid;
            }

            if (entranceUuid !== undefined) {
                localVarQueryParameter['entrance_uuid'] = entranceUuid;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postal_code'] = postalCode;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (excludeCategory !== undefined) {
                localVarQueryParameter['exclude_category'] = excludeCategory;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (contactUuid !== undefined) {
                localVarQueryParameter['contact_uuid'] = contactUuid;
            }

            if (companyUuid !== undefined) {
                localVarQueryParameter['company_uuid'] = companyUuid;
            }

            if (businessRegion !== undefined) {
                localVarQueryParameter['business_region'] = businessRegion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Property Auditlogs
         * @param {string} propertyUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet: async (propertyUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('getSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet', 'propertyUuid', propertyUuid)
            const localVarPath = `/properties/{property_uuid}/auditlogs`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (userUuid !== undefined) {
                localVarQueryParameter['user_uuid'] = userUuid;
            }

            if (excludeFields !== undefined) {
                localVarQueryParameter['exclude_fields'] = excludeFields;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Property
         * @param {string} propertyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyPropertiesPropertyUuidGet: async (propertyUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('getSinglePropertyPropertiesPropertyUuidGet', 'propertyUuid', propertyUuid)
            const localVarPath = `/properties/{property_uuid}`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Property
         * @param {string} propertyUuid 
         * @param {PatchPropertySerializerDTO} patchPropertySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPropertyPropertiesPropertyUuidPatch: async (propertyUuid: string, patchPropertySerializerDTO: PatchPropertySerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('patchPropertyPropertiesPropertyUuidPatch', 'propertyUuid', propertyUuid)
            // verify required parameter 'patchPropertySerializerDTO' is not null or undefined
            assertParamExists('patchPropertyPropertiesPropertyUuidPatch', 'patchPropertySerializerDTO', patchPropertySerializerDTO)
            const localVarPath = `/properties/{property_uuid}`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPropertySerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Property
         * @param {string} propertyUuid 
         * @param {CreatePropertySerializerDTO} createPropertySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPropertyPropertiesPropertyUuidPut: async (propertyUuid: string, createPropertySerializerDTO: CreatePropertySerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('putPropertyPropertiesPropertyUuidPut', 'propertyUuid', propertyUuid)
            // verify required parameter 'createPropertySerializerDTO' is not null or undefined
            assertParamExists('putPropertyPropertiesPropertyUuidPut', 'createPropertySerializerDTO', createPropertySerializerDTO)
            const localVarPath = `/properties/{property_uuid}`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertySerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Property Attachment
         * @param {string} propertyUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost: async (propertyUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost', 'propertyUuid', propertyUuid)
            // verify required parameter 'attachmentCategory' is not null or undefined
            assertParamExists('uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost', 'attachmentCategory', attachmentCategory)
            // verify required parameter 'attachmentType' is not null or undefined
            assertParamExists('uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost', 'attachmentType', attachmentType)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost', 'files', files)
            const localVarPath = `/properties/{property_uuid}/attachments`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


            if (attachmentCategory !== undefined) { 
                localVarFormParams.append('attachment_category', new Blob([JSON.stringify(attachmentCategory)], { type: "application/json", }));
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', new Blob([JSON.stringify(attachmentType)], { type: "application/json", }));
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (visibility !== undefined) { 
                localVarFormParams.append('visibility', new Blob([JSON.stringify(visibility)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateImportDataFormatPropertiesDataImportPost: async (file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('validateImportDataFormatPropertiesDataImportPost', 'file', file)
            const localVarPath = `/properties/data_import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertiesAPI - functional programming interface
 * @export
 */
export const PropertiesAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertiesAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Property
         * @param {CreatePropertySerializerDTO} createPropertySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPropertyPropertiesPost(createPropertySerializerDTO: CreatePropertySerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertySerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPropertyPropertiesPost(createPropertySerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Property Attachment
         * @param {string} propertyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete(propertyUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete(propertyUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Property
         * @param {string} propertyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyPropertiesPropertyUuidDelete(propertyUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyPropertiesPropertyUuidDelete(propertyUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Property Attachment
         * @param {string} propertyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet(propertyUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet(propertyUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Properties Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertiesCoordinatesListPropertiesCoordinatesGet(search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoordinatesSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertiesCoordinatesListPropertiesCoordinatesGet(search, countryUuid, regionUuid, cityUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Properties
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [nickname] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertiesPropertiesGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, nickname?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, companyUuid?: string, businessRegion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPropertiesSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertiesPropertiesGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, nickname, countryUuid, regionUuid, municipalityUuid, cityUuid, companyUuid, businessRegion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Attachments
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet(propertyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet(propertyUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Property Objects
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [companyUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {PropertyObjectCategoryEnumDTO} [category] 
         * @param {string} [contactUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet(propertyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, companyUuid?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, postalCode?: string, category?: PropertyObjectCategoryEnumDTO, contactUuid?: string, businessRegion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPropertyObjectsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet(propertyUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, companyUuid, countryUuid, regionUuid, municipalityUuid, cityUuid, postalCode, category, contactUuid, businessRegion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Units
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyObjectUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyUnitsPropertiesPropertyUuidUnitsGet(propertyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyObjectUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUnitsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyUnitsPropertiesPropertyUuidUnitsGet(propertyUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, propertyObjectUuid, entranceUuid, countryUuid, regionUuid, cityUuid, postalCode, category, excludeCategory, status, contactUuid, companyUuid, businessRegion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Property Auditlogs
         * @param {string} propertyUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet(propertyUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLogSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet(propertyUuid, userUuid, excludeFields, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Property
         * @param {string} propertyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSinglePropertyPropertiesPropertyUuidGet(propertyUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedPropertySerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSinglePropertyPropertiesPropertyUuidGet(propertyUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Property
         * @param {string} propertyUuid 
         * @param {PatchPropertySerializerDTO} patchPropertySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPropertyPropertiesPropertyUuidPatch(propertyUuid: string, patchPropertySerializerDTO: PatchPropertySerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertySerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPropertyPropertiesPropertyUuidPatch(propertyUuid, patchPropertySerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Property
         * @param {string} propertyUuid 
         * @param {CreatePropertySerializerDTO} createPropertySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPropertyPropertiesPropertyUuidPut(propertyUuid: string, createPropertySerializerDTO: CreatePropertySerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertySerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPropertyPropertiesPropertyUuidPut(propertyUuid, createPropertySerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Property Attachment
         * @param {string} propertyUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost(propertyUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommonProcessSerializersAttachmentAttachmentSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost(propertyUuid, attachmentCategory, attachmentType, files, title, description, visibility, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateImportDataFormatPropertiesDataImportPost(file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateImportDataFormatPropertiesDataImportPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertiesAPI - factory interface
 * @export
 */
export const PropertiesAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertiesAPIFp(configuration)
    return {
        /**
         * 
         * @summary Create Property
         * @param {CreatePropertySerializerDTO} createPropertySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyPropertiesPost(createPropertySerializerDTO: CreatePropertySerializerDTO, options?: any): AxiosPromise<PropertySerializerDTO> {
            return localVarFp.createPropertyPropertiesPost(createPropertySerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Property Attachment
         * @param {string} propertyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete(propertyUuid: string, attachmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete(propertyUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Property
         * @param {string} propertyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyPropertiesPropertyUuidDelete(propertyUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePropertyPropertiesPropertyUuidDelete(propertyUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Property Attachment
         * @param {string} propertyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet(propertyUuid: string, attachmentUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet(propertyUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Properties Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertiesCoordinatesListPropertiesCoordinatesGet(search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options?: any): AxiosPromise<Array<CoordinatesSerializerDTO>> {
            return localVarFp.getPropertiesCoordinatesListPropertiesCoordinatesGet(search, countryUuid, regionUuid, cityUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Properties
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [nickname] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertiesPropertiesGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, nickname?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, companyUuid?: string, businessRegion?: string, options?: any): AxiosPromise<ListPropertiesSerializerDTO> {
            return localVarFp.getPropertiesPropertiesGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, nickname, countryUuid, regionUuid, municipalityUuid, cityUuid, companyUuid, businessRegion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Attachments
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet(propertyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): AxiosPromise<ListAttachmentSerializerDTO> {
            return localVarFp.getPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet(propertyUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Property Objects
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [companyUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {PropertyObjectCategoryEnumDTO} [category] 
         * @param {string} [contactUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet(propertyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, companyUuid?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, postalCode?: string, category?: PropertyObjectCategoryEnumDTO, contactUuid?: string, businessRegion?: string, options?: any): AxiosPromise<ListPropertyObjectsSerializerDTO> {
            return localVarFp.getPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet(propertyUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, companyUuid, countryUuid, regionUuid, municipalityUuid, cityUuid, postalCode, category, contactUuid, businessRegion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Units
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyObjectUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyUnitsPropertiesPropertyUuidUnitsGet(propertyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyObjectUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options?: any): AxiosPromise<ListUnitsSerializerDTO> {
            return localVarFp.getPropertyUnitsPropertiesPropertyUuidUnitsGet(propertyUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, propertyObjectUuid, entranceUuid, countryUuid, regionUuid, cityUuid, postalCode, category, excludeCategory, status, contactUuid, companyUuid, businessRegion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Property Auditlogs
         * @param {string} propertyUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet(propertyUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ListLogSerializerDTO> {
            return localVarFp.getSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet(propertyUuid, userUuid, excludeFields, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Property
         * @param {string} propertyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyPropertiesPropertyUuidGet(propertyUuid: string, options?: any): AxiosPromise<DetailedPropertySerializerDTO> {
            return localVarFp.getSinglePropertyPropertiesPropertyUuidGet(propertyUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Property
         * @param {string} propertyUuid 
         * @param {PatchPropertySerializerDTO} patchPropertySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPropertyPropertiesPropertyUuidPatch(propertyUuid: string, patchPropertySerializerDTO: PatchPropertySerializerDTO, options?: any): AxiosPromise<PropertySerializerDTO> {
            return localVarFp.patchPropertyPropertiesPropertyUuidPatch(propertyUuid, patchPropertySerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Property
         * @param {string} propertyUuid 
         * @param {CreatePropertySerializerDTO} createPropertySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPropertyPropertiesPropertyUuidPut(propertyUuid: string, createPropertySerializerDTO: CreatePropertySerializerDTO, options?: any): AxiosPromise<PropertySerializerDTO> {
            return localVarFp.putPropertyPropertiesPropertyUuidPut(propertyUuid, createPropertySerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Property Attachment
         * @param {string} propertyUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost(propertyUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): AxiosPromise<Array<CommonProcessSerializersAttachmentAttachmentSerializerDTO>> {
            return localVarFp.uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost(propertyUuid, attachmentCategory, attachmentType, files, title, description, visibility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateImportDataFormatPropertiesDataImportPost(file: any, options?: any): AxiosPromise<any> {
            return localVarFp.validateImportDataFormatPropertiesDataImportPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPropertyPropertiesPost operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPICreatePropertyPropertiesPostRequest
 */
export interface PropertiesAPICreatePropertyPropertiesPostRequest {
    /**
     * 
     * @type {CreatePropertySerializerDTO}
     * @memberof PropertiesAPICreatePropertyPropertiesPost
     */
    readonly createPropertySerializerDTO: CreatePropertySerializerDTO
}

/**
 * Request parameters for deletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIDeletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDeleteRequest
 */
export interface PropertiesAPIDeletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIDeletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete
     */
    readonly propertyUuid: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIDeletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for deletePropertyPropertiesPropertyUuidDelete operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIDeletePropertyPropertiesPropertyUuidDeleteRequest
 */
export interface PropertiesAPIDeletePropertyPropertiesPropertyUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIDeletePropertyPropertiesPropertyUuidDelete
     */
    readonly propertyUuid: string
}

/**
 * Request parameters for downloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIDownloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGetRequest
 */
export interface PropertiesAPIDownloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIDownloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly propertyUuid: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIDownloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for getPropertiesCoordinatesListPropertiesCoordinatesGet operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIGetPropertiesCoordinatesListPropertiesCoordinatesGetRequest
 */
export interface PropertiesAPIGetPropertiesCoordinatesListPropertiesCoordinatesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesCoordinatesListPropertiesCoordinatesGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesCoordinatesListPropertiesCoordinatesGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesCoordinatesListPropertiesCoordinatesGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesCoordinatesListPropertiesCoordinatesGet
     */
    readonly cityUuid?: string
}

/**
 * Request parameters for getPropertiesPropertiesGet operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIGetPropertiesPropertiesGetRequest
 */
export interface PropertiesAPIGetPropertiesPropertiesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly nickname?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly municipalityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly cityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly companyUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertiesPropertiesGet
     */
    readonly businessRegion?: string
}

/**
 * Request parameters for getPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGetRequest
 */
export interface PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet
     */
    readonly propertyUuid: string

    /**
     * 
     * @type {number}
     * @memberof PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet
     */
    readonly type?: string
}

/**
 * Request parameters for getPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGetRequest
 */
export interface PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly propertyUuid: string

    /**
     * 
     * @type {number}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly companyUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly municipalityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly cityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly postalCode?: string

    /**
     * 
     * @type {PropertyObjectCategoryEnumDTO}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly category?: PropertyObjectCategoryEnumDTO

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly contactUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet
     */
    readonly businessRegion?: string
}

/**
 * Request parameters for getPropertyUnitsPropertiesPropertyUuidUnitsGet operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGetRequest
 */
export interface PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly propertyUuid: string

    /**
     * 
     * @type {number}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly propertyObjectUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly entranceUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly cityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly postalCode?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly excludeCategory?: string

    /**
     * 
     * @type {UnitStatusEnumDTO}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly status?: UnitStatusEnumDTO

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly contactUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly companyUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGet
     */
    readonly businessRegion?: string
}

/**
 * Request parameters for getSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIGetSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGetRequest
 */
export interface PropertiesAPIGetSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet
     */
    readonly propertyUuid: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet
     */
    readonly userUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet
     */
    readonly excludeFields?: string

    /**
     * 
     * @type {number}
     * @memberof PropertiesAPIGetSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PropertiesAPIGetSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getSinglePropertyPropertiesPropertyUuidGet operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIGetSinglePropertyPropertiesPropertyUuidGetRequest
 */
export interface PropertiesAPIGetSinglePropertyPropertiesPropertyUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIGetSinglePropertyPropertiesPropertyUuidGet
     */
    readonly propertyUuid: string
}

/**
 * Request parameters for patchPropertyPropertiesPropertyUuidPatch operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIPatchPropertyPropertiesPropertyUuidPatchRequest
 */
export interface PropertiesAPIPatchPropertyPropertiesPropertyUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIPatchPropertyPropertiesPropertyUuidPatch
     */
    readonly propertyUuid: string

    /**
     * 
     * @type {PatchPropertySerializerDTO}
     * @memberof PropertiesAPIPatchPropertyPropertiesPropertyUuidPatch
     */
    readonly patchPropertySerializerDTO: PatchPropertySerializerDTO
}

/**
 * Request parameters for putPropertyPropertiesPropertyUuidPut operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIPutPropertyPropertiesPropertyUuidPutRequest
 */
export interface PropertiesAPIPutPropertyPropertiesPropertyUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIPutPropertyPropertiesPropertyUuidPut
     */
    readonly propertyUuid: string

    /**
     * 
     * @type {CreatePropertySerializerDTO}
     * @memberof PropertiesAPIPutPropertyPropertiesPropertyUuidPut
     */
    readonly createPropertySerializerDTO: CreatePropertySerializerDTO
}

/**
 * Request parameters for uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIUploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPostRequest
 */
export interface PropertiesAPIUploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIUploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost
     */
    readonly propertyUuid: string

    /**
     * 
     * @type {AttachmentCategoryEnumDTO}
     * @memberof PropertiesAPIUploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost
     */
    readonly attachmentCategory: AttachmentCategoryEnumDTO

    /**
     * 
     * @type {AttachmentTypeEnumDTO}
     * @memberof PropertiesAPIUploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost
     */
    readonly attachmentType: AttachmentTypeEnumDTO

    /**
     * 
     * @type {Array<any>}
     * @memberof PropertiesAPIUploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost
     */
    readonly files: Array<any>

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIUploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost
     */
    readonly title?: string

    /**
     * 
     * @type {string}
     * @memberof PropertiesAPIUploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost
     */
    readonly description?: string

    /**
     * 
     * @type {AttachmentVisibilityEnumDTO}
     * @memberof PropertiesAPIUploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost
     */
    readonly visibility?: AttachmentVisibilityEnumDTO
}

/**
 * Request parameters for validateImportDataFormatPropertiesDataImportPost operation in PropertiesAPI.
 * @export
 * @interface PropertiesAPIValidateImportDataFormatPropertiesDataImportPostRequest
 */
export interface PropertiesAPIValidateImportDataFormatPropertiesDataImportPostRequest {
    /**
     * 
     * @type {any}
     * @memberof PropertiesAPIValidateImportDataFormatPropertiesDataImportPost
     */
    readonly file: any
}

/**
 * PropertiesAPI - object-oriented interface
 * @export
 * @class PropertiesAPI
 * @extends {BaseAPI}
 */
export class PropertiesAPI extends BaseAPI {
    /**
     * 
     * @summary Create Property
     * @param {PropertiesAPICreatePropertyPropertiesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public createPropertyPropertiesPost(requestParameters: PropertiesAPICreatePropertyPropertiesPostRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).createPropertyPropertiesPost(requestParameters.createPropertySerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Property Attachment
     * @param {PropertiesAPIDeletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public deletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete(requestParameters: PropertiesAPIDeletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDeleteRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).deletePropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDelete(requestParameters.propertyUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Property
     * @param {PropertiesAPIDeletePropertyPropertiesPropertyUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public deletePropertyPropertiesPropertyUuidDelete(requestParameters: PropertiesAPIDeletePropertyPropertiesPropertyUuidDeleteRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).deletePropertyPropertiesPropertyUuidDelete(requestParameters.propertyUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Property Attachment
     * @param {PropertiesAPIDownloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public downloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet(requestParameters: PropertiesAPIDownloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGetRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).downloadPropertyAttachmentPropertiesPropertyUuidAttachmentsAttachmentUuidDownloadGet(requestParameters.propertyUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Properties Coordinates List
     * @param {PropertiesAPIGetPropertiesCoordinatesListPropertiesCoordinatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public getPropertiesCoordinatesListPropertiesCoordinatesGet(requestParameters: PropertiesAPIGetPropertiesCoordinatesListPropertiesCoordinatesGetRequest = {}, options?: any) {
        return PropertiesAPIFp(this.configuration).getPropertiesCoordinatesListPropertiesCoordinatesGet(requestParameters.search, requestParameters.countryUuid, requestParameters.regionUuid, requestParameters.cityUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Properties
     * @param {PropertiesAPIGetPropertiesPropertiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public getPropertiesPropertiesGet(requestParameters: PropertiesAPIGetPropertiesPropertiesGetRequest = {}, options?: any) {
        return PropertiesAPIFp(this.configuration).getPropertiesPropertiesGet(requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.nickname, requestParameters.countryUuid, requestParameters.regionUuid, requestParameters.municipalityUuid, requestParameters.cityUuid, requestParameters.companyUuid, requestParameters.businessRegion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Attachments
     * @param {PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public getPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet(requestParameters: PropertiesAPIGetPropertyAttachmentsPropertiesPropertyUuidAttachmentsGetRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).getPropertyAttachmentsPropertiesPropertyUuidAttachmentsGet(requestParameters.propertyUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.category, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Property Objects
     * @param {PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public getPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet(requestParameters: PropertiesAPIGetPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGetRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).getPropertyPropertyObjectsPropertiesPropertyUuidPropertyObjectsGet(requestParameters.propertyUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.companyUuid, requestParameters.countryUuid, requestParameters.regionUuid, requestParameters.municipalityUuid, requestParameters.cityUuid, requestParameters.postalCode, requestParameters.category, requestParameters.contactUuid, requestParameters.businessRegion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Units
     * @param {PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public getPropertyUnitsPropertiesPropertyUuidUnitsGet(requestParameters: PropertiesAPIGetPropertyUnitsPropertiesPropertyUuidUnitsGetRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).getPropertyUnitsPropertiesPropertyUuidUnitsGet(requestParameters.propertyUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.propertyObjectUuid, requestParameters.entranceUuid, requestParameters.countryUuid, requestParameters.regionUuid, requestParameters.cityUuid, requestParameters.postalCode, requestParameters.category, requestParameters.excludeCategory, requestParameters.status, requestParameters.contactUuid, requestParameters.companyUuid, requestParameters.businessRegion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Property Auditlogs
     * @param {PropertiesAPIGetSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public getSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet(requestParameters: PropertiesAPIGetSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGetRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).getSinglePropertyAuditlogsPropertiesPropertyUuidAuditlogsGet(requestParameters.propertyUuid, requestParameters.userUuid, requestParameters.excludeFields, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Property
     * @param {PropertiesAPIGetSinglePropertyPropertiesPropertyUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public getSinglePropertyPropertiesPropertyUuidGet(requestParameters: PropertiesAPIGetSinglePropertyPropertiesPropertyUuidGetRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).getSinglePropertyPropertiesPropertyUuidGet(requestParameters.propertyUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Property
     * @param {PropertiesAPIPatchPropertyPropertiesPropertyUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public patchPropertyPropertiesPropertyUuidPatch(requestParameters: PropertiesAPIPatchPropertyPropertiesPropertyUuidPatchRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).patchPropertyPropertiesPropertyUuidPatch(requestParameters.propertyUuid, requestParameters.patchPropertySerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Property
     * @param {PropertiesAPIPutPropertyPropertiesPropertyUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public putPropertyPropertiesPropertyUuidPut(requestParameters: PropertiesAPIPutPropertyPropertiesPropertyUuidPutRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).putPropertyPropertiesPropertyUuidPut(requestParameters.propertyUuid, requestParameters.createPropertySerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Property Attachment
     * @param {PropertiesAPIUploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost(requestParameters: PropertiesAPIUploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPostRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).uploadPropertyAttachmentPropertiesPropertyUuidAttachmentsPost(requestParameters.propertyUuid, requestParameters.attachmentCategory, requestParameters.attachmentType, requestParameters.files, requestParameters.title, requestParameters.description, requestParameters.visibility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate Import Data Format
     * @param {PropertiesAPIValidateImportDataFormatPropertiesDataImportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesAPI
     */
    public validateImportDataFormatPropertiesDataImportPost(requestParameters: PropertiesAPIValidateImportDataFormatPropertiesDataImportPostRequest, options?: any) {
        return PropertiesAPIFp(this.configuration).validateImportDataFormatPropertiesDataImportPost(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}
