import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  row: {
    '& button + button': {
      ml: 2,
    },
    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
    mt: 3,
    pt: 2,
    textAlign: 'right',
  },
};
