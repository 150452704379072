import { TicketTypeEnumDTO } from '../../../../../connectors/ticket';
import type { ColumnsConfig } from './entity-request-list.type';
import {
  complaintColumnsConfig,
  contractRequestColumnsConfig,
  generalRequestColumnsConfig,
  invoiceRequestColumnsConfig,
  serviceRequestColumnsConfig,
} from './models';

export const getColumnsConfig = (
  ticketType: TicketTypeEnumDTO,
): ColumnsConfig => {
  switch (ticketType) {
    case TicketTypeEnumDTO.Complaint:
      return complaintColumnsConfig;
    case TicketTypeEnumDTO.ContractRequest:
      return contractRequestColumnsConfig;
    case TicketTypeEnumDTO.GeneralRequest:
      return generalRequestColumnsConfig;
    case TicketTypeEnumDTO.InvoiceRequest:
      return invoiceRequestColumnsConfig;
    case TicketTypeEnumDTO.ServiceRequest:
      return serviceRequestColumnsConfig;
    default:
      return [];
  }
};
