import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import isEqual from 'lodash-es/isEqual';
import type { Dispatch, FC, SetStateAction, ReactElement } from 'react';
import React, { useCallback, useMemo } from 'react';

import type { FieldConfig } from '../../../form';
import { Form } from '../../../form';
import type { ListState } from '../list.type';
import { mapFieldsConfig } from './filter-panel.helper';
import { sxProps } from './filter-panel.styles';

type Props<TFilterValues> = {
  isFetching: boolean;
  setListState: Dispatch<SetStateAction<ListState<TFilterValues>>>;
  fieldsConfig?: FieldConfig<TFilterValues>[];
  filterPanelComponent?: ReactElement;
};

const formConfig = {
  onSubmit: () => {
    // do nothing
  },
};

export const FilterPanel = <TFilterValues,>(
  props: Props<TFilterValues>,
): ReturnType<FC<Props<TFilterValues>>> => {
  const { fieldsConfig, filterPanelComponent, isFetching, setListState } =
    props;
  const mappedFieldsConfig = useMemo(
    () => (fieldsConfig ? mapFieldsConfig(fieldsConfig, isFetching) : []),
    [fieldsConfig, isFetching],
  );
  const setFilters = useCallback(
    (values: TFilterValues) => {
      setListState((prevState) => {
        const newState = {
          ...prevState,
          filters: { ...prevState.filters, ...values },
        };

        return !isEqual(prevState, newState)
          ? {
              ...newState,
              paginationConfig: {
                ...prevState.paginationConfig,
                currentPage: 1,
              },
            }
          : prevState;
      });
    },
    [setListState],
  );

  return (
    <Grid item xs={12}>
      <Box sx={sxProps.box}>
        {filterPanelComponent || (
          <Form<TFilterValues>
            config={formConfig}
            fieldsConfig={mappedFieldsConfig}
            inline
            onChange={setFilters}
            showButtons={false}
            spacing={2}
          />
        )}
      </Box>
    </Grid>
  );
};
