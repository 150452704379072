/* eslint-disable max-len */

export type Accept = Record<string, string[]>;

export enum FileType {
  '3MF' = 'model/3mf',
  CSV = 'text/csv',
  JPEG = 'image/jpeg',
  MS_POWER_POINT = 'application/vnd.ms-powerpoint',
  MS_WORD = 'application/msword',
  PDF = 'application/pdf',
  PNG = 'image/png',
  RTF = 'application/rtf',
  SVG = 'image/svg+xml',
  VRML = 'model/vrml',
  XML_DOCUMENT = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XML_PRESENTATION = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}
