import Box from '@mui/material/Box';
import type { FC } from 'react';
import React from 'react';

import type { ProspectDetailsReadOnlySerializerDTO } from '../../../../../connectors/property';
import { DefaultStep } from './DefaultStep/default-step.component';
import { steps } from './status-stepper.const';
import { sxProps } from './status-stepper.styles';

type Props = {
  status: ProspectDetailsReadOnlySerializerDTO['status'];
};

export const StatusStepper: FC<Props> = (props) => {
  const { status } = props;
  const filteredSteps = steps.filter((step) =>
    step.isVisible !== undefined ? step.isVisible.includes(status) : true,
  );

  return (
    <Box sx={sxProps.steps}>
      {filteredSteps.map((step, index) => {
        const { component, key, ...rest } = step;
        const Component = component || DefaultStep;

        return (
          <Box key={key} sx={sxProps.container}>
            <Component {...rest} index={index} status={status} />
            {filteredSteps.length !== index + 1 && <Box sx={sxProps.line} />}
          </Box>
        );
      })}
    </Box>
  );
};
