import { isFuture } from 'date-fns';
import * as Yup from 'yup';

import type {
  AttachmentSerializerDTO,
  ListAttachmentSerializerDTO,
} from '../../../../../connectors/company';
import {
  AttachmentCategoryEnumDTO,
  EntityEnumDTO,
} from '../../../../../connectors/company';
import type {
  UnitAdvertisementSerializerDTO,
  UpdateUnitAdvertisementSerializerDTO,
} from '../../../../../connectors/property';
import type {
  DateValue,
  FieldsetConfig,
  FormProps,
  GalleryItem,
  RadioValue,
} from '../../../../shared';
import {
  DEFAULT_FIELD_VALUE,
  DEFAULT_GALLERY_VALUE,
  formatStandardDateWrite,
} from '../../../../shared';
import { advertisementClient } from '../../../advertisement.client';
import {
  AvailabilityMode,
  getAvailabilityModeRadioOptions,
} from '../../consts';
import type { DynamicValidationRules } from '../Form';
import { DEFAULT_MAX_SELECTED_ITEMS } from '../Form/GalleryStep/gallery-step.model';
import { updateAttachments } from './update-form.helper';

export type Values = Omit<
  UpdateUnitAdvertisementSerializerDTO,
  'availableDateFrom'
> & {
  attachments: GalleryItem<AttachmentSerializerDTO, EntityEnumDTO>[];
  availability: AvailabilityMode;
  availableDateFrom?: DateValue;
};

export const getFields = (
  details: UnitAdvertisementSerializerDTO,
  getImages$: () => Promise<
    GalleryItem<AttachmentSerializerDTO, EntityEnumDTO>[]
  >,
  priceFieldSuffix: string,
  dynamicValidationRules?: DynamicValidationRules,
): FieldsetConfig<Values>[] => [
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: details.name || DEFAULT_FIELD_VALUE,
          name: 'name',
          validationSchema: Yup.string()
            .min(
              dynamicValidationRules?.title?.minLength ?? 1,
              'form.errors.number.min',
            )
            .max(
              dynamicValidationRules?.title?.maxLength ?? 1000,
              'form.errors.number.max',
            ),
        },
        props: {
          labelKey: 'advertisement.fields.summary',
          required: true,
          type: 'text',
        },
      },
    ],
    key: 'advertisement-title',
    titleKey: 'advertisement.fieldset.description.summary',
  },
  {
    fields: [
      {
        componentType: 'editor',
        field: {
          initialValue: details.advertisementDescription || DEFAULT_FIELD_VALUE,
          name: 'advertisementDescription',
          // TODO: improve editor validation schema
          validationSchema: Yup.string().test('range', (value, context) => {
            const { createError, path } = context;
            const maxLength =
              dynamicValidationRules?.description?.maxLength ?? 65535;
            const minLength =
              dynamicValidationRules?.description?.minLength ?? 1;

            if (!value) {
              return createError({ message: 'form.errors.required', path });
            }

            const rawValue = value.replace(/<[^>]+>/g, '');

            // check value length without html
            if (rawValue.length < minLength) {
              return createError({ message: 'form.errors.number.min', path });
            }

            // check value length with html
            if (value.length > maxLength) {
              return createError({ message: 'form.errors.number.max', path });
            }

            return true;
          }),
        },
        props: {
          labelKey: 'advertisement.fields.description',
          required: true,
        },
      },
    ],
    key: 'advertisement-description',
    titleKey: 'advertisement.fieldset.description.description',
  },
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: details.virtualTourUrl || DEFAULT_FIELD_VALUE,
          name: 'virtualTourUrl',
          validationSchema: Yup.string().url('form.errors.url'),
        },
        props: {
          labelKey: 'advertisement.fields.virtualTourUrl',
          type: 'text',
        },
      },
    ],
    helperTextKey: 'advertisement.fieldset.virtualTour.helperText',
    key: 'virtualTour',
    titleKey: 'advertisement.fieldset.virtualTour.title',
  },
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: Number.parseFloat(details.rentPrice)
            ? Number.parseFloat(details.rentPrice).toString()
            : DEFAULT_FIELD_VALUE,
          name: 'rentPrice',
          validationSchema: Yup.number()
            .typeError('form.errors.number.general')
            .positive('form.errors.number.positive'),
        },
        props: {
          endAdornment: priceFieldSuffix,
          labelKey: 'advertisement.fields.rentPrice',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: Number.parseFloat(details.additionalFees)
            ? Number.parseFloat(details.additionalFees).toString()
            : DEFAULT_FIELD_VALUE,
          name: 'additionalFees',
          validationSchema: Yup.number()
            .nullable()
            .typeError('form.errors.number.general')
            .positive('form.errors.number.positive'),
        },
        props: {
          endAdornment: priceFieldSuffix,
          labelKey: 'advertisement.fields.feesPrice',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: Number.parseFloat(details.deposit)
            ? Number.parseFloat(details.deposit).toString()
            : DEFAULT_FIELD_VALUE,
          name: 'deposit',
          validationSchema: Yup.number()
            .nullable()
            .typeError('form.errors.number.general')
            .positive('form.errors.number.positive'),
        },
        props: {
          endAdornment: priceFieldSuffix,
          labelKey: 'advertisement.fields.depositPrice',
          required: true,
          type: 'text',
        },
      },
    ],
    key: 'pricing',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'advertisement.fieldset.pricing.title',
  },
  {
    fields: [
      {
        componentType: 'radio',
        field: {
          initialValue:
            details.availableDateFrom &&
            isFuture(new Date(details.availableDateFrom))
              ? AvailabilityMode.CUSTOM_DATE
              : AvailabilityMode.IMMEDIATELY,
          name: 'availability',
        },
        props: {
          options: getAvailabilityModeRadioOptions,
          required: true,
        },
      },
      {
        componentType: 'date',
        field: {
          initialValue: details.availableDateFrom
            ? details.availableDateFrom
            : new Date(),
          name: 'availableDateFrom',
          validationSchema: Yup.mixed().when(['availability'], {
            is: (availability: RadioValue<AvailabilityMode>) =>
              availability === AvailabilityMode.CUSTOM_DATE,
            otherwise: Yup.mixed().nullable(),
            then: Yup.mixed().required('form.errors.required'),
          }),
        },
        props: {
          isHidden: ({ availability }) =>
            availability !== AvailabilityMode.CUSTOM_DATE,
          labelKey:
            'advertisement.fieldset.options.availabilityCustomDateOption',
          minDate: details.availableDateFrom
            ? new Date(details.availableDateFrom)
            : new Date(),
          requiredWhen: ({ availability }) =>
            availability === AvailabilityMode.CUSTOM_DATE,
        },
      },
    ],
    key: 'availability',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'advertisement.fieldset.options.availability',
  },
  {
    fields: [
      {
        componentType: 'gallery',
        field: {
          initialValue: DEFAULT_GALLERY_VALUE,
          name: 'attachments',
          validationSchema: Yup.array().test('range', (value, context) => {
            const { createError, path } = context;
            const maxSelectedItems =
              dynamicValidationRules?.attachments?.max ??
              DEFAULT_MAX_SELECTED_ITEMS;
            const minSelectedItems =
              dynamicValidationRules?.attachments?.min ?? 0;
            const selectedItems =
              value?.filter((item) => item.isSelected) || [];

            if (selectedItems.length < minSelectedItems) {
              return createError({ message: 'form.errors.required', path });
            }

            if (selectedItems.length > maxSelectedItems) {
              return createError({ message: 'form.errors.gallery.max', path });
            }

            return true;
          }),
        },
        props: {
          callback$: getImages$,
          helperTextKey: 'advertisement.fieldset.gallery.helperText',
          labelKey: 'advertisement.fieldset.gallery.title',
          required: true,
          tabs: [
            {
              labelKey: 'advertisement.fieldset.gallery.browser.unit',
              value: EntityEnumDTO.Unit,
            },
            {
              labelKey: 'advertisement.fieldset.gallery.browser.propertyObject',
              value: EntityEnumDTO.PropertyObject,
            },
            {
              labelKey: 'advertisement.fieldset.gallery.browser.property',
              value: EntityEnumDTO.Property,
            },
          ],
        },
      },
    ],
    key: 'attachments-fieldset-config-section',
    titleKey: '',
  },
];

export const getConfig = (
  attachments: ListAttachmentSerializerDTO['results'],
  details: UnitAdvertisementSerializerDTO,
): FormProps<Values>['config'] => ({
  onSubmit: async (values) => {
    const payload: UpdateUnitAdvertisementSerializerDTO = {
      ...details,
      ...values,
      // TODO: consult with backend if marking as optional string or null is possible on their side
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      availableDateFrom:
        values.availability === AvailabilityMode.CUSTOM_DATE &&
        values.availableDateFrom
          ? formatStandardDateWrite(values.availableDateFrom)
          : formatStandardDateWrite(new Date()),
      virtualTourUrl: values.virtualTourUrl || '',
    };
    const imageValues = values.attachments.filter(
      (attachment) => attachment.category === AttachmentCategoryEnumDTO.Image,
    );

    await updateAttachments(attachments, details, imageValues);

    const updatedAdvertisement = await advertisementClient.update$(
      details.uuid,
      payload,
    );

    await advertisementClient.publish$(updatedAdvertisement.uuid);

    return updatedAdvertisement;
  },
});
