import { AutoScrollPlugin } from '@lexical/react/LexicalAutoScrollPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import type { InputBaseComponentProps } from '@mui/material';
import Box from '@mui/material/Box';
import React, { forwardRef, useRef } from 'react';

import { DisabledContent } from '../DisabledContent/disabled-content.component';
import { sxProps } from './textarea.styles';

export const Textarea = forwardRef<
  HTMLDivElement,
  Omit<InputBaseComponentProps, 'align'>
>((props, ref) => {
  const { disabled, placeholder = null, ...rest } = props;
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <Box contentEditable={false} ref={ref} {...rest}>
      <AutoScrollPlugin scrollRef={scrollRef} />
      <Box ref={scrollRef} sx={sxProps.box}>
        <RichTextPlugin
          contentEditable={
            <Box
              component={disabled ? DisabledContent : ContentEditable}
              sx={sxProps.content}
            />
          }
          placeholder={placeholder}
        />
        <ListPlugin />
        <HistoryPlugin />
      </Box>
    </Box>
  );
});
