import type { FieldProps as FormikFieldProps } from 'formik/dist/Field';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { MapWrapper } from '../../../components';
import { LocationContent } from '../../components';
import { getFirstErrorKey } from '../../helpers';
import type { LocationProps, LocationValue } from '../types';
import { sxProps } from './location-field.styles';

type Props<TFormValues> = FormikFieldProps<LocationValue, TFormValues> & {
  props: LocationProps<TFormValues>;
};

export const LocationField = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const { meta, form, field, props: fieldProps } = props;
  const { error, value, touched } = meta;
  const { setFieldValue, values } = form;
  const { name } = field;
  const {
    disabled,
    disableWhen,
    getInfoData,
    pinUrl,
    placeholderKey,
    readonly,
    required,
    requiredWhen,
    tipKey,
  } = fieldProps;
  const errorMessageKey = useMemo(
    () => touched && getFirstErrorKey(error),
    [error, touched],
  );
  const isPermanentlyDisabled = useMemo(
    () => Boolean(disabled || readonly || (disableWhen && disableWhen(values))),
    [disabled, disableWhen, readonly, values],
  );
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );
  const infoData = useMemo(() => getInfoData(values), [getInfoData, values]);
  const handleChangePosition = useCallback(
    (position: LocationValue): void => {
      setFieldValue(name, position);
    },
    [name, setFieldValue],
  );

  return (
    <MapWrapper sx={sxProps.box}>
      <LocationContent
        disabled={isPermanentlyDisabled}
        errorMessageKey={errorMessageKey || undefined}
        infoData={infoData}
        onPositionChange={handleChangePosition}
        pinUrl={pinUrl}
        placeholderKey={placeholderKey}
        position={value}
        required={isRequired && !readonly}
        tipKey={tipKey}
      />
    </MapWrapper>
  );
};
