import { useTheme } from '@mui/material/styles';
import React from 'react';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Logo } from '../../shared';
import {
  LayoutCard,
  LayoutCardContent,
  LayoutContainer,
  LayoutLogoContainer,
  LayoutRoot,
} from './layout.styles';

export const Layout: FC = () => {
  const theme = useTheme();

  return (
    <LayoutRoot>
      <LayoutContainer maxWidth="sm">
        <LayoutLogoContainer>
          <Logo fill={theme.palette.primary.main} />
        </LayoutLogoContainer>
        <LayoutCard>
          <LayoutCardContent>
            <Outlet />
          </LayoutCardContent>
        </LayoutCard>
      </LayoutContainer>
    </LayoutRoot>
  );
};
