import { ReactComponent as ArrowRightIcon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-right.svg';
import Grid from '@mui/material/Grid';
import MuiIcon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { QueryKey } from 'react-query';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import {
  getTicketTypeIcon,
  TICKET_PATHS,
  ticketClient,
  TicketTypePath,
} from '../../../../+ticket';
import { TicketTypeEnumDTO } from '../../../../../connectors/ticket';
import { useTranslation } from '../../../translations';
import { Box } from '../../Box/box.component';
import type { QueryContentProps } from '../../QueryContent';
import { QueryContent } from '../../QueryContent';
import { Chart } from './Chart/chart.component';
import { sxProps } from './ticket-status-chart.styles';

type Props = {
  type: TicketTypeEnumDTO;
  queryKey: QueryKey;
};

// TODO: type could be improved after https://fredensborg.atlassian.net/browse/MB-325
const QUERY_OPTIONS: QueryContentProps<
  Record<string | 'total', number>
>['options'] = {
  hideRefetchSpinner: true,
  refetchInterval: 15000,
};

export const TicketStatusChart: FC<Props> = (props) => {
  const { type, queryKey } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const Icon = useMemo(
    () => getTicketTypeIcon(TicketTypeEnumDTO.ServiceRequest),
    [],
  );
  const getData$ = useCallback(
    () => ticketClient.getTicketsCount$(type),
    [type],
  );
  const redirectPath = generatePath(TICKET_PATHS.LIST, {
    type: TicketTypePath.SERVICE_REQUESTS,
  });

  return (
    <Box sx={sxProps.box}>
      <QueryContent
        getData$={getData$}
        options={QUERY_OPTIONS}
        queryKey={queryKey}
      >
        {(result) => (
          <Grid container>
            <Grid item sx={sxProps.header} xs={12}>
              <Grid
                alignItems="center"
                container
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Icon
                    height={24}
                    stroke={theme.palette.secondary.main}
                    width={24}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {t('dashboard.widgets.ticketStatusChart.title')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Chart counts={result} />
            <Grid item sx={sxProps.footer} xs={12}>
              <Grid container>
                <Link component={RouterLink} to={redirectPath} underline="none">
                  <Typography color="primary" variant="button">
                    <strong>
                      {t('dashboard.widgets.ticketStatusChart.link')}
                    </strong>
                  </Typography>
                  <MuiIcon sx={sxProps.linkIcon}>
                    <ArrowRightIcon />
                  </MuiIcon>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        )}
      </QueryContent>
    </Box>
  );
};
