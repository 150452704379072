import type { DetailedUnitSerializerDTO } from '../../../../connectors/property';

export const getAddress = (
  addressLine: DetailedUnitSerializerDTO['addressLine'],
  city: DetailedUnitSerializerDTO['city'],
  streetNumber: DetailedUnitSerializerDTO['streetNumber'],
  unitNumber?: DetailedUnitSerializerDTO['unitNumber'],
): string =>
  [unitNumber, `${addressLine} ${streetNumber}`, city?.name, city?.country.name]
    .filter((s) => s)
    .join(', ');
