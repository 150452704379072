import Box from '@mui/material/Box';
import MUIIcon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import type { FC, Key, SVGProps } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowRightIcon } from '../../icons';
import { useTranslation } from '../../translations';
import { sxProps } from './breadcrumbs.styles';

type Props = {
  items: {
    key: Key;
    titleKey: GenericTypes.TranslationLabel;
    icon?: FC<SVGProps<SVGSVGElement>>;
    url?: string;
  }[];
};

export const Breadcrumbs: FC<Props> = (props) => {
  const { items } = props;
  const { t } = useTranslation();

  return (
    <Box sx={sxProps.container}>
      {items.map((item, index) => {
        const { icon: Icon, key, titleKey, url } = item;
        const content = (
          <Box sx={sxProps.item} key={key}>
            {Icon && (
              <MUIIcon
                component={Icon}
                height={22}
                sx={sxProps.icon}
                width={22}
              />
            )}
            <Typography sx={sxProps.text}>{t(titleKey)}</Typography>
            {index !== items.length - 1 && (
              <MUIIcon
                component={ArrowRightIcon}
                height={22}
                width={22}
                sx={sxProps.arrow}
              />
            )}
          </Box>
        );

        return url ? (
          <Link key={key} to={url}>
            {content}
          </Link>
        ) : (
          content
        );
      })}
    </Box>
  );
};
