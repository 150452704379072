import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  icon: {
    '& path': {
      stroke: 'currentColor',
    },
    color: (theme) => theme.palette.text.primary,
  },
  large: {
    fontSize: (theme) => theme.spacing(4),
  },
  normal: {
    fontSize: (theme) => theme.spacing(3),
  },
  small: {
    fontSize: (theme) => theme.spacing(2),
  },
};
