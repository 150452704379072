import { ReactComponent as NavigationMenu4Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/menu/navigation-menu-4.svg';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import type { Dispatch, FC } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import type { SelectOption } from '../../../../shared';
import { useTranslation } from '../../../../shared';
import { sxProps } from './messages-filter.styles';

type Props<T> = {
  options: Required<Omit<SelectOption<T>, 'label'>>[];
  setValue: Dispatch<T>;
  value: T;
};

export const MessagesFilter = <T,>(
  props: Props<T>,
): ReturnType<FC<Props<T>>> => {
  const { value, setValue, options } = props;
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);
  const handleClick = useCallback(
    (value: T) => {
      setValue(value);
      setOpen(false);
    },
    [setValue],
  );
  const activeOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value],
  );
  const filteredOptions = useMemo(
    () => options.filter((option) => option.value !== value),
    [options, value],
  );

  return (
    <Grid alignItems="center" container ref={ref} sx={sxProps.container}>
      <Typography color="text.primary" variant="overline">
        {t(activeOption?.labelKey || '')}
      </Typography>
      <IconButton onClick={() => setOpen(true)} sx={sxProps.button}>
        <NavigationMenu4Icon height={16} width={16} />
      </IconButton>
      <Menu anchorEl={ref.current} open={isOpen} onClose={() => setOpen(false)}>
        {filteredOptions.map((option) => {
          const { labelKey, value } = option;

          return (
            <MenuItem onClick={() => handleClick(value)} key={labelKey}>
              <Typography variant="button">{t(labelKey)}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Grid>
  );
};
