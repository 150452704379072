import { ReactComponent as SubtractIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/subtract.svg';
import { ReactComponent as SearchIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/search/search.svg';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import type { FC, Dispatch, SetStateAction } from 'react';
import React, { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { CustomErrorType, useTranslation } from '../../../../shared';
import type { ContactUserId, User } from '../../types';
import type { PickedConfirmationConfig } from '../ConfirmationDialog/confirmation-dialog.type';
import { sxProps } from './item-actions.styles';

type Props = {
  isDisabled: boolean;
  isFixed: User['isFixed'];
  isReassign: boolean;
  isRemove: boolean;
  removeId: ContactUserId;
  setDialogConfig: (config: PickedConfirmationConfig) => void;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  userId: User['id'];
  onRemove$?: (
    id: ContactUserId,
    setEditMode: Dispatch<SetStateAction<boolean>>,
  ) => Promise<void>;
};

export const ItemActions: FC<Props> = (props) => {
  const {
    isDisabled,
    isFixed,
    isReassign,
    isRemove,
    onRemove$,
    removeId,
    setDialogConfig,
    setDisabled,
    setEditMode,
    userId,
  } = props;
  const { t } = useTranslation();
  const handleReassign = useCallback(() => {
    setDialogConfig({ oldUserId: userId });
    setEditMode(true);
  }, [setDialogConfig, setEditMode, userId]);
  const handleRemove$ = useCallback(async () => {
    if (onRemove$) {
      try {
        setDisabled(true);
        await onRemove$(removeId, setEditMode);
      } catch (e) {
        if (e.message === CustomErrorType.PREVENT_TOAST_ERROR) return;

        toast.error(t('errors.general.message'));
      } finally {
        setDisabled(false);
      }

      return;
    }

    setDialogConfig({ id: removeId, isOpen: true });
  }, [onRemove$, removeId, setDialogConfig, setDisabled, setEditMode, t]);

  return (
    <Box sx={sxProps.iconContainer}>
      {isReassign && (
        <IconButton
          color="default"
          disabled={isDisabled}
          onClick={handleReassign}
          sx={sxProps.button}
        >
          <SearchIcon height={16} width={16} />
        </IconButton>
      )}
      {isRemove && !isFixed && (
        <IconButton
          color="error"
          disabled={isDisabled}
          onClick={handleRemove$}
          sx={sxProps.button}
        >
          <SubtractIcon height={16} width={16} />
        </IconButton>
      )}
    </Box>
  );
};
