import React, { useCallback } from 'react';
import type { FC } from 'react';
import { generatePath, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { PermissionGuard } from '../../+auth';
import {
  UNIT_PATHS,
  UNIT_VIEW_PERMISSIONS,
  UnitCategoryPath,
} from '../../+unit';
import type { ListUnitsSerializerDTO } from '../../../connectors/property';
import {
  INITIAL_LIST_STATE,
  List as GenericList,
  useApi,
  useList,
} from '../../shared';
import PATHS from '../paths.const';
import { userClient } from '../user.client';
import type { FilterValues } from './home-list.model';
import {
  filterFieldsConfig,
  columnConfigs,
  INITIAL_FILTER_VALUES,
  INITIAL_SORT,
} from './home-list.model';

const HomeList: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    listState,
    queryParams: listQueryParams,
    setListState,
    setPaginationFromResponse,
  } = useList<ListUnitsSerializerDTO, FilterValues>(false, {
    ...INITIAL_LIST_STATE,
    filters: INITIAL_FILTER_VALUES,
    sort: INITIAL_SORT,
  });
  const getData$ = useCallback(async () => {
    const response = await userClient.getHomes$(id, listQueryParams);

    setPaginationFromResponse(response);

    return response;
  }, [id, listQueryParams, setPaginationFromResponse]);
  const { isFetching, response } = useApi<ListUnitsSerializerDTO>(
    { count: 0, results: [] },
    getData$,
  );

  return (
    <PermissionGuard
      fallbackRoute={generatePath(PATHS.DETAILS, { id })}
      permissions={UNIT_VIEW_PERMISSIONS}
    >
      <GenericList
        columnsConfig={columnConfigs}
        filterFieldsConfig={filterFieldsConfig}
        isFetching={isFetching}
        listState={listState}
        model={response.results}
        modelKey="uuid"
        onRowClick={({ uuid }) =>
          navigate(
            generatePath(UNIT_PATHS.DETAILS, {
              category: UnitCategoryPath.HOMES,
              id: uuid,
            }),
          )
        }
        setListState={setListState}
      />
    </PermissionGuard>
  );
};

export default HomeList;
