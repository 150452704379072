import { ReactComponent as CommonFileTextIcon } from '@heimstaden/icons-library/img/streamline-regular/files-folders/common-files/common-file-text.svg';
import { ReactComponent as ImageFileJpgIcon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/image-files/image-file-jpg.svg';
import { ReactComponent as ImageFilePngIcon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/image-files/image-file-png.svg';
import { ReactComponent as ImageFileSvgIcon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/image-files/image-file-svg.svg';
import { ReactComponent as OfficeFilePdfIcon } from '@heimstaden/icons-library/img/streamline-regular/work-office-companies/office-files/office-file-pdf-1.svg';
import type { AxiosRequestConfig } from 'axios';
import differenceWith from 'lodash-es/differenceWith';
import isEqual from 'lodash-es/isEqual';
import uniqBy from 'lodash-es/uniqBy';
import type { FC, SVGProps } from 'react';

import type {
  AttachmentSequenceSerializerDTO,
  AttachmentSerializerDTO,
} from '../../../../../connectors/company';
import {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
  AttachmentVisibilityEnumDTO,
} from '../../../../../connectors/company';
import type { CustomFile, FileValue, SelectOption } from '../../../form';
import { FileType } from '../enums';

const ATTACHMENT_CATEGORY_KEY = 'attachment_category' as const;
const ATTACHMENT_TYPE_KEY = 'attachment_type' as const;
const ATTACHMENT_VISIBILITY_KEY = 'visibility' as const;

const isAttachment = (
  file: AttachmentSerializerDTO | CustomFile,
): file is AttachmentSerializerDTO => 'uuid' in file;

const getEnumValueBasedOnText = (
  text: string,
):
  | AttachmentCategoryEnumDTO
  | AttachmentTypeEnumDTO
  | AttachmentVisibilityEnumDTO
  | undefined => {
  const parsedText = JSON.parse(text);
  const values = {
    ...AttachmentCategoryEnumDTO,
    ...AttachmentTypeEnumDTO,
    ...AttachmentVisibilityEnumDTO,
  };

  return Object.values(values).find((value) => value === parsedText);
};

const replaceFormDataValue = async (
  data: FormData,
  blobValue: Blob | null,
  key:
    | typeof ATTACHMENT_CATEGORY_KEY
    | typeof ATTACHMENT_TYPE_KEY
    | typeof ATTACHMENT_VISIBILITY_KEY,
): Promise<void> => {
  if (!blobValue) return;

  const text = await blobValue.text();
  const value = getEnumValueBasedOnText(text);

  if (value) {
    data.set(key, value);
  }
};

export const transformAttachmentFormData = async (
  data: FormData,
): Promise<AxiosRequestConfig['data']> => {
  const attachmentCategoryBlob = data.get(
    ATTACHMENT_CATEGORY_KEY,
  ) as Blob | null;
  const attachmentTypeBlob = data.get(ATTACHMENT_TYPE_KEY) as Blob | null;
  const attachmentVisibilityBlob = data.get(
    ATTACHMENT_VISIBILITY_KEY,
  ) as Blob | null;

  if (attachmentCategoryBlob) {
    await replaceFormDataValue(
      data,
      attachmentCategoryBlob,
      ATTACHMENT_CATEGORY_KEY,
    );
  }

  if (attachmentTypeBlob) {
    await replaceFormDataValue(data, attachmentTypeBlob, ATTACHMENT_TYPE_KEY);
  }

  if (attachmentVisibilityBlob) {
    await replaceFormDataValue(
      data,
      attachmentVisibilityBlob,
      ATTACHMENT_VISIBILITY_KEY,
    );
  }

  return data;
};

export const getIcon = (
  type: AttachmentSerializerDTO['contentType'],
): FC<SVGProps<SVGSVGElement>> => {
  switch (type) {
    case FileType.JPEG:
      return ImageFileJpgIcon;
    case FileType.PNG:
      return ImageFilePngIcon;
    case FileType.SVG:
      return ImageFileSvgIcon;
    case FileType.PDF:
      return OfficeFilePdfIcon;
    default:
      return CommonFileTextIcon;
  }
};

const getCategory = (type: string): AttachmentCategoryEnumDTO | undefined => {
  switch (type) {
    case FileType.JPEG:
    case FileType.PNG:
    case FileType.SVG:
      return AttachmentCategoryEnumDTO.Image;
    case FileType.CSV:
    case FileType.MS_POWER_POINT:
    case FileType.MS_WORD:
    case FileType.PDF:
    case FileType.RTF:
    case FileType.XML_DOCUMENT:
    case FileType.XML_PRESENTATION:
      return AttachmentCategoryEnumDTO.Document;
    case FileType['3MF']:
    case FileType.VRML:
      // eslint-disable-next-line no-underscore-dangle
      return AttachmentCategoryEnumDTO._3dScan;
    default:
      return undefined;
  }
};

const getOrderedAttachments = (
  files: FileValue,
  existingAttachments: AttachmentSerializerDTO[] = [],
  uploadedAttachments: AttachmentSerializerDTO[][] = [],
): {
  attachments: AttachmentSerializerDTO[];
  orderedAttachments: AttachmentSerializerDTO[];
} => {
  const flatUploadedAttachments = [...uploadedAttachments]
    .flat()
    .sort((a, b) => a.sequenceNumber - b.sequenceNumber);
  const attachments: AttachmentSerializerDTO[] = [
    ...existingAttachments,
    ...flatUploadedAttachments,
  ];
  const orderedAttachments = [...attachments].sort((a, b) => {
    const aExistingIndex = files.findIndex((file) => file.path === a.url);
    const bExistingIndex = files.findIndex((file) => file.path === b.url);
    const aUploadedIndex = files.findIndex((file) => file.name === a.name);
    const bUploadedIndex = files.findIndex((file) => file.name === b.name);
    const aIndex = aExistingIndex > -1 ? aExistingIndex : aUploadedIndex;
    const bIndex = bExistingIndex > -1 ? bExistingIndex : bUploadedIndex;

    return aIndex - bIndex;
  });

  return { attachments, orderedAttachments };
};

const getAttachmentsSequence = (
  files: FileValue,
  existingAttachments: AttachmentSerializerDTO[] = [],
  uploadedAttachments: AttachmentSerializerDTO[][] = [],
): AttachmentSequenceSerializerDTO[] => {
  const { attachments, orderedAttachments } = getOrderedAttachments(
    files,
    existingAttachments,
    uploadedAttachments,
  );

  return attachments
    .map((attachment) => {
      const swappingPosition = orderedAttachments.findIndex(
        (att) => att.uuid === attachment.uuid,
      );
      const predecessor = attachments[swappingPosition];

      return predecessor &&
        attachment.sequenceNumber !== predecessor.sequenceNumber
        ? {
            sequenceNumber: predecessor.sequenceNumber,
            uuid: attachment.uuid,
          }
        : null;
    })
    .filter((sequence) => sequence !== null) as AttachmentSerializerDTO[];
};

const getUploadAttachmentRequests$ = (
  callback$: UploadAttachmentCallback,
  files: FileValue,
): Promise<AttachmentSerializerDTO[]>[] =>
  files.map((file) => {
    const category = getCategory(file.type);

    switch (category) {
      case AttachmentCategoryEnumDTO.Image:
        return callback$(
          category,
          AttachmentTypeEnumDTO.Gallery,
          file,
          file.visibility || AttachmentVisibilityEnumDTO.Internal,
        );
      case AttachmentCategoryEnumDTO.Document:
        return callback$(
          category,
          AttachmentTypeEnumDTO.Pdf,
          file,
          file.visibility || AttachmentVisibilityEnumDTO.Internal,
        );
      // eslint-disable-next-line no-underscore-dangle
      case AttachmentCategoryEnumDTO._3dScan:
        return callback$(
          category,
          AttachmentTypeEnumDTO.Pdf,
          file,
          file.visibility || AttachmentVisibilityEnumDTO.Internal,
        );
      default:
        return Promise.resolve([]);
    }
  });

export type UploadAttachmentCallback = (
  category: AttachmentCategoryEnumDTO,
  type: AttachmentTypeEnumDTO,
  file: CustomFile,
  visibility: AttachmentVisibilityEnumDTO,
) => Promise<AttachmentSerializerDTO[]>;

const uploadAttachments$ = (
  callback$: UploadAttachmentCallback,
  files: FileValue,
): Promise<AttachmentSerializerDTO[][]> => {
  const requests$ = getUploadAttachmentRequests$(callback$, files);

  return Promise.all(requests$);
};

export type UpdateAttachmentsSequenceCallback = (
  sequence: AttachmentSequenceSerializerDTO[],
) => Promise<AttachmentSequenceSerializerDTO[]>;

const updateAttachmentsSequence$ = (
  callback$: UpdateAttachmentsSequenceCallback,
  files: FileValue,
  existingAttachments: AttachmentSerializerDTO[] = [],
  uploadedAttachments: AttachmentSerializerDTO[][] = [],
): Promise<AttachmentSequenceSerializerDTO[]> => {
  const sequence = getAttachmentsSequence(
    files,
    existingAttachments,
    uploadedAttachments,
  );

  return sequence.length > 0 ? callback$(sequence) : Promise.resolve([]);
};

export type RemoveAttachmentCallback = (
  id: AttachmentSerializerDTO['uuid'],
) => Promise<void>;

const removeAttachments$ = (
  attachmentIds: AttachmentSerializerDTO['uuid'][],
  callback$: RemoveAttachmentCallback,
): Promise<void>[] => attachmentIds.map((id) => callback$(id));

export type UpdateAttachmentCallback = (
  attachment: AttachmentSerializerDTO,
) => Promise<AttachmentSerializerDTO>;

const updateAttachments$ = (
  callback$: UpdateAttachmentCallback,
  files: FileValue,
  existingAttachments: AttachmentSerializerDTO[] = [],
): Promise<AttachmentSerializerDTO>[] => {
  const updatedAttachments = existingAttachments.map((attachment) => {
    const file = files.find((f) => f.path === attachment.url);

    return {
      ...attachment,
      description:
        file && attachment.description !== file.description
          ? file.description
          : attachment.description,
      title:
        file && attachment.title !== file.title ? file.title : attachment.title,
      visibility:
        file && attachment.visibility !== file.visibility
          ? file.visibility
          : attachment.visibility,
    };
  });
  const differences = differenceWith(
    updatedAttachments,
    existingAttachments,
    isEqual,
  );
  const uniqDifferences = uniqBy(differences, 'uuid');

  return uniqDifferences.map((attachment) => callback$(attachment));
};

/**
 * This function builds CustomFile.
 * Returns new object instead of mutating original one.
 */
export const buildCustomFile = (
  file: AttachmentSerializerDTO | CustomFile,
  title?: string,
  description?: string,
  visibility?: AttachmentVisibilityEnumDTO,
): CustomFile => {
  const blob = isAttachment(file) ? file.url : file;
  const path = isAttachment(file) ? file.url : file.path;
  const type = isAttachment(file) ? file.contentType : file.type;
  const customFile = new File([blob], file.name, { type });

  Object.defineProperty(customFile, 'path', {
    value: path,
    writable: false,
  });
  Object.defineProperty(customFile, 'title', {
    value: title,
    writable: false,
  });
  Object.defineProperty(customFile, 'description', {
    value: description,
    writable: false,
  });
  Object.defineProperty(customFile, 'visibility', {
    value: visibility,
    writable: false,
  });

  return customFile;
};

export const convertToFiles = (
  attachments: AttachmentSerializerDTO[],
): FileValue =>
  attachments.map((attachment) =>
    buildCustomFile(
      attachment,
      attachment.title,
      attachment.description,
      attachment.visibility,
    ),
  );

const getUpdatedAttachments = (
  files: FileValue,
  attachments: AttachmentSerializerDTO[] = [],
): [FileValue, AttachmentSerializerDTO['uuid'][]] => {
  const uploadedFiles = convertToFiles(attachments);
  const filesToUpload = files.filter(
    (f) => !uploadedFiles.some((uploadedFile) => uploadedFile.path === f.path),
  );
  const removedAttachmentIds = uploadedFiles
    .filter((uploadedFile) => !files.some((f) => f.path === uploadedFile.path))
    .map((f) => attachments.find((a) => a.url === f.path)?.uuid)
    .filter((uuid) => uuid !== undefined) as AttachmentSerializerDTO['uuid'][];

  return [filesToUpload, removedAttachmentIds];
};

const attachmentTypeToTranslationLabelKeyMap: Record<
  AttachmentTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [AttachmentTypeEnumDTO.ContractPdf]: 'attachment.type.contractPdf',
  [AttachmentTypeEnumDTO.FloorPlan]: 'attachment.type.floorPlan',
  [AttachmentTypeEnumDTO.Gallery]: 'attachment.type.gallery',
  [AttachmentTypeEnumDTO.Hero]: 'attachment.type.hero',
  [AttachmentTypeEnumDTO.HouseRules]: 'attachment.type.houseRules',
  [AttachmentTypeEnumDTO.Manuals]: 'attachment.type.manuals',
  [AttachmentTypeEnumDTO.Other]: 'attachment.type.other',
  [AttachmentTypeEnumDTO.Pdf]: 'attachment.type.pdf',
  [AttachmentTypeEnumDTO.ProfilePicture]: 'attachment.type.profilePicture',
  [AttachmentTypeEnumDTO.Thumbnail]: 'attachment.type.thumbnail',
};

export const documentTypeSelectOptions: SelectOption<AttachmentTypeEnumDTO>[] =
  [
    AttachmentTypeEnumDTO.ContractPdf,
    AttachmentTypeEnumDTO.FloorPlan,
    AttachmentTypeEnumDTO.HouseRules,
    AttachmentTypeEnumDTO.Manuals,
    AttachmentTypeEnumDTO.Pdf,
    AttachmentTypeEnumDTO.Other,
  ].map((type) => ({
    labelKey: attachmentTypeToTranslationLabelKeyMap[type],
    value: type,
  }));

export const getType = (type: CustomFile['type']): AttachmentTypeEnumDTO => {
  switch (type) {
    case FileType.JPEG:
    case FileType.PNG:
    case FileType.SVG:
      return AttachmentTypeEnumDTO.Gallery;
    case FileType.PDF:
      return AttachmentTypeEnumDTO.Pdf;
    case FileType['3MF']:
    case FileType.VRML:
      return AttachmentTypeEnumDTO.FloorPlan;
    default:
      return AttachmentTypeEnumDTO.Other;
  }
};

export const getImagePreviewPath = (file: CustomFile): string => {
  const category = getCategory(file.type);

  if (!file.path || category !== AttachmentCategoryEnumDTO.Image) return '';

  return file.path.startsWith('http') ? file.path : URL.createObjectURL(file);
};

export type ProceedAttachmentsUploadCallbacks = {
  handleUpload$: UploadAttachmentCallback;
};

export const proceedAttachmentsUpload$ = async (
  attachments: FileValue,
  callbacks: ProceedAttachmentsUploadCallbacks,
): Promise<void> => {
  const { handleUpload$ } = callbacks;

  // uploading new attachments
  await uploadAttachments$(handleUpload$, attachments);
};

export type ProceedAttachmentsUpdateCallbacks = {
  handleUpload$: UploadAttachmentCallback;
  handleUpdate$: UpdateAttachmentCallback;
  handleRemove$: RemoveAttachmentCallback;
  handleSequence$: UpdateAttachmentsSequenceCallback;
};

export const proceedAttachmentsUpdate$ = async (
  attachments: {
    allAttachments: FileValue;
    existingAttachments: AttachmentSerializerDTO[];
  },
  callbacks: ProceedAttachmentsUpdateCallbacks,
): Promise<void> => {
  const { allAttachments, existingAttachments } = attachments;
  const { handleUpdate$, handleUpload$, handleRemove$, handleSequence$ } =
    callbacks;
  const [newAttachments, removedAttachmentIds] = getUpdatedAttachments(
    allAttachments,
    existingAttachments,
  );

  // uploading new attachments
  const uploadedAttachments = await uploadAttachments$(
    handleUpload$,
    newAttachments,
  );

  // set new sequence
  await updateAttachmentsSequence$(
    handleSequence$,
    allAttachments,
    existingAttachments,
    uploadedAttachments,
  );

  // update attachments
  await Promise.all(
    updateAttachments$(handleUpdate$, allAttachments, existingAttachments),
  );

  // remove attachments
  await Promise.all(removeAttachments$(removedAttachmentIds, handleRemove$));
};

const attachmentVisibilityToTranslationLabelKeyMap: Record<
  AttachmentVisibilityEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [AttachmentVisibilityEnumDTO.Internal]: 'attachment.visibility.internal',
  [AttachmentVisibilityEnumDTO.Public]: 'attachment.visibility.public',
};

export const documentVisibilitySelectOptions = [
  AttachmentVisibilityEnumDTO.Internal,
  AttachmentVisibilityEnumDTO.Public,
].map((visibility) => ({
  labelKey: attachmentVisibilityToTranslationLabelKeyMap[visibility],
  value: visibility,
}));

export const getVisibilityLabelKey = (
  visibility: AttachmentSerializerDTO['visibility'],
): string => {
  return visibility
    ? attachmentVisibilityToTranslationLabelKeyMap[visibility]
    : AttachmentVisibilityEnumDTO.Public;
};
