import { createContext } from 'react';

import { initialSettings } from './const';
import type { Settings } from './type';

export interface ContextValue {
  saveSettings: (update: Partial<Settings>) => void;
  settings: Settings;
}

export const Context = createContext<ContextValue>({
  saveSettings: () => null,
  settings: initialSettings,
});
