/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ComplaintTypeEnumDTO {
    NeighbourComplaint = 'neighbour_complaint',
    CustomerServiceComplaint = 'customer_service_complaint',
    GeneralNoiseIssueComplaint = 'general_noise_issue_complaint',
    BreakingHouseRulesComplaint = 'breaking_house_rules_complaint',
    PetsComplaint = 'pets_complaint',
    HeimstadenComplaint = 'heimstaden_complaint',
    SmokeOdorComplaint = 'smoke_odor_complaint',
    ConstructionWorkNoiseComplaint = 'construction_work_noise_complaint',
    GarbageComplaint = 'garbage_complaint',
    CleaningCommonAreaComplaint = 'cleaning_common_area_complaint',
    GreeneryComplaint = 'greenery_complaint',
    Other = 'other'
}



