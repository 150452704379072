import Box from '@mui/material/Box';
import type { FC, ReactElement } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import type {
  TicketStatusEnumDTO,
  UniversalTicketSerializerDTO,
} from '../../../../../connectors/ticket';
import { CommentVisibilityEnumDTO } from '../../../../../connectors/ticket';
import {
  CustomErrorType,
  Dialog,
  Form,
  useTranslation,
} from '../../../../shared';
import { ticketClient } from '../../../ticket.client';
import type { ContextValue } from './context';
import { Context } from './context';
import type { Values } from './form-model.const';
import { formConfig, getFieldsConfig } from './form-model.const';
import {
  getTransitionComment,
  getValues,
  isTransitionFormNeeded,
} from './helper';

type Props = {
  children: ReactElement;
};

export const Provider: FC<Props> = (props) => {
  const { children } = props;
  const { langCode, t } = useTranslation();
  const [confirmPromise, setConfirmPromise] = useState<{
    resolve: (value: Values) => void;
    reject: (reason?: Error) => void;
  }>();
  const [isOpen, setOpen] = useState(false);
  const [transition, setTransition] = useState<TicketStatusEnumDTO>();
  const [transitionIds, setTransitionIds] = useState<
    UniversalTicketSerializerDTO['uuid'][]
  >([]);
  const [values, setValues] = useState<Values>();
  const fieldsConfig = useMemo(() => getFieldsConfig(transition), [transition]);
  const resetDialog = useCallback(() => {
    setConfirmPromise(undefined);
    setTransition(undefined);
    setTransitionIds([]);
    setOpen(false);
  }, []);
  const submitTransitionForm$ = useCallback(async (): Promise<Values> => {
    if (transitionIds.length === 0 || !transition || !values)
      return Promise.reject();

    const transitionComment = getTransitionComment(
      langCode,
      t,
      transition,
      values,
    );
    const publicComment = !values.isInternalComment ? values.comment : '';

    try {
      await Promise.all(
        transitionIds.map((id) => [
          ticketClient.addComment$(
            id,
            transitionComment,
            CommentVisibilityEnumDTO.Internal,
          ),
          publicComment
            ? ticketClient.addComment$(
                id,
                publicComment,
                CommentVisibilityEnumDTO.Public,
              )
            : Promise.resolve(),
        ]),
      );

      return values;
    } catch (e) {
      toast.error(t('errors.general.message'));
      throw e;
    }
  }, [langCode, transitionIds, t, transition, values]);
  const onCancel = useCallback(() => {
    confirmPromise?.reject(new Error(CustomErrorType.ACTION_CANCELED));
    resetDialog();
  }, [confirmPromise, resetDialog]);
  const onConfirm = useCallback(async () => {
    const values = await submitTransitionForm$();

    confirmPromise?.resolve(values);
    resetDialog();
  }, [confirmPromise, resetDialog, submitTransitionForm$]);
  const changeStatus$ = useCallback(
    async (
      ids: UniversalTicketSerializerDTO['uuid'][],
      status: TicketStatusEnumDTO,
    ) => {
      let values: Values;

      if (isTransitionFormNeeded(status)) {
        const confirm = new Promise<Values>((resolve, reject) => {
          setConfirmPromise({ reject, resolve });
        });

        setTransitionIds(ids);
        setTransition(status);
        setOpen(true);
        values = await confirm;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const finalValues = getValues(status, values);

      return Promise.all(
        ids.map((id) => ticketClient.changeStatus$(id, status, finalValues)),
      );
    },
    [],
  );
  const value = useMemo<ContextValue>(
    () => ({ changeStatus$ }),
    [changeStatus$],
  );

  return (
    <Context.Provider value={value}>
      {children}
      <Dialog
        isOpen={isOpen}
        onClose={onCancel}
        onConfirm={onConfirm}
        titleKey="ticket.actions.changeStatus"
      >
        {fieldsConfig && (
          <Box mt={1}>
            <Form<Values>
              config={formConfig}
              fieldsConfig={fieldsConfig}
              onChange={setValues}
              showButtons={false}
              spacing={2}
            />
          </Box>
        )}
      </Dialog>
    </Context.Provider>
  );
};
