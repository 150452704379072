import * as Yup from 'yup';

import type { FieldsetConfig } from '../../../../../shared';
import { DEFAULT_FIELD_VALUE } from '../../../../../shared';
import type { FormValues } from '../form.types';

export const getFieldsetConfig = (
  fieldSuffix: string,
): FieldsetConfig<FormValues>[] => [
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: DEFAULT_FIELD_VALUE,
          name: 'rentPrice',
          validationSchema: Yup.number()
            .typeError('form.errors.number.general')
            .positive('form.errors.number.positive'),
        },
        props: {
          endAdornment: fieldSuffix,
          labelKey: 'advertisement.fields.rentPrice',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: DEFAULT_FIELD_VALUE,
          name: 'feesPrice',
          validationSchema: Yup.number()
            .nullable()
            .typeError('form.errors.number.general')
            .positive('form.errors.number.positive'),
        },
        props: {
          endAdornment: fieldSuffix,
          labelKey: 'advertisement.fields.feesPrice',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: DEFAULT_FIELD_VALUE,
          name: 'depositPrice',
          validationSchema: Yup.number()
            .nullable()
            .typeError('form.errors.number.general')
            .positive('form.errors.number.positive'),
        },
        props: {
          endAdornment: fieldSuffix,
          labelKey: 'advertisement.fields.depositPrice',
          required: true,
          type: 'text',
        },
      },
    ],
    key: 'pricing',
    titleKey: 'advertisement.fieldset.pricing.title',
  },
];
