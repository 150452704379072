import Box from '@mui/material/Box';
import MUIIcon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback } from 'react';

import { useTranslation } from '../../../../../shared';
import type { ServiceRequestValues } from '../../../types';
import { steps } from '../service-request-form.const';
import { isStepValid } from '../service-request-form.helper';
import { sxProps } from './navigation.styles';

type Props = {
  activeStepIdx: number;
  setActiveStepIdx: Dispatch<SetStateAction<number>>;
};

export const Navigation: FC<Props> = (props) => {
  const { activeStepIdx, setActiveStepIdx } = props;
  const { errors } = useFormikContext<ServiceRequestValues>();
  const { t } = useTranslation();
  const handleClick = useCallback(
    (idx: number) => {
      if (!isStepValid(idx, errors) && idx > 0) return;

      setActiveStepIdx(idx);
    },
    [errors, setActiveStepIdx],
  );

  return (
    <>
      {steps.map((step, idx) => {
        const { icon: Icon, labelKey, key } = step;
        const isStepCompleted =
          activeStepIdx !== idx && isStepValid(idx, errors);
        const contentIconProps = {
          ...sxProps.content,
          ...((isStepCompleted || idx === 0) && sxProps.completedContent),
        };
        const iconSxProps = {
          ...sxProps.icon,
          ...(activeStepIdx === idx && sxProps.activeIcon),
          ...(isStepCompleted && sxProps.completedIcon),
        };

        return (
          <Box sx={sxProps.element} key={key}>
            <Box onClick={() => handleClick(idx)} sx={contentIconProps}>
              <MUIIcon sx={iconSxProps}>
                <Icon height={24} width={24} />
              </MUIIcon>
              <Typography
                fontWeight={activeStepIdx === idx ? 'bold' : ''}
                variant="body1"
              >
                {t(labelKey)}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
