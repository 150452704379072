const PATHS = {
  ACTIVITY_LOG_LIST: '/property-objects/:id/logs',
  COMPLAINT_LIST: '/property-objects/:id/complaints',
  CONTRACT_REQUEST_LIST: '/property-objects/:id/contract-requests',
  CREATE: '/property-objects/create',
  CREATE_COMPLAINT: '/property-objects/:id/complaint',
  CREATE_CONTRACT_REQUEST: '/property-objects/:id/contract-request',
  CREATE_GENERAL_REQUEST: '/property-objects/:id/general-request',
  CREATE_INVOICE_REQUEST: '/property-objects/:id/invoice-request',
  CREATE_SERVICE_REQUEST: '/property-objects/:id/service-request',
  DETAILS: '/property-objects/:id',
  DOCUMENT_LIST: '/property-objects/:id/documents',
  FACILITIES_LIST: '/property-objects/:id/facilities',
  GENERAL_REQUEST_LIST: '/property-objects/:id/general-requests',
  HOME_LIST: '/property-objects/:id/homes',
  INVOICE_REQUEST_LIST: '/property-objects/:id/invoice-requests',
  LIST: '/property-objects',
  SERVICE_REQUEST_LIST: '/property-objects/:id/service-requests',
  UPDATE: '/property-objects/:id/update',
};

export default PATHS;
