import type {
  CitySerializerDTO,
  CompanyDetailSerializerDTO,
  CountrySerializerDTO,
  CreateCompanySerializerDTO,
} from '../../../../connectors/company';
import { localizationClient } from '../../../localization.client';
import type {
  AsyncAutocompleteOption,
  FieldsetConfig,
  SelectOption,
} from '../../../shared';
import {
  DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  DEFAULT_SELECT_OPTION,
} from '../../../shared';

export type Values = Omit<
  CreateCompanySerializerDTO,
  'countryUuid' | 'cityUuid'
> & {
  city: AsyncAutocompleteOption<CitySerializerDTO['uuid']>;
  country: CountrySerializerDTO['uuid'];
};

export const getFields = (
  countryOptions: SelectOption<CountrySerializerDTO['uuid']>[],
  values?: CompanyDetailSerializerDTO,
): FieldsetConfig<Values>[] => [
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: values?.name,
          name: 'name',
        },
        props: {
          labelKey: 'company.fields.name',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.description,
          name: 'description',
        },
        props: {
          labelKey: 'company.fields.description',
          required: true,
          type: 'textarea',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.addressLine1,
          name: 'addressLine1',
        },
        props: {
          labelKey: 'company.fields.addressLine1',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.addressLine2,
          name: 'addressLine2',
        },
        props: {
          labelKey: 'company.fields.addressLine2',
          type: 'text',
        },
      },
      {
        componentType: 'select',
        field: {
          initialValue:
            values?.city.country.uuid || DEFAULT_SELECT_OPTION.value,
          name: 'country',
        },
        props: {
          disabled: countryOptions.length <= 1,
          labelKey: 'company.fields.country',
          options: countryOptions,
          relatedFields: ['city', 'postalCode'],
          required: true,
        },
      },
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue: values?.city
            ? {
                label: values.city.name,
                value: values.city.uuid,
              }
            : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
          name: 'city',
        },
        props: {
          callback$: async (query, values) =>
            values.country
              ? localizationClient.getCities$(
                  values.country,
                  undefined,
                  undefined,
                  query,
                )
              : [],
          disableWhen: ({ country }) => !country,
          labelKey: 'company.fields.city',
          relatedFields: ['postalCode'],
          required: true,
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.postalCode,
          name: 'postalCode',
        },
        props: {
          disableWhen: ({ city, country }) => !country || !city,
          labelKey: 'company.fields.postalCode',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.organizationNumber,
          name: 'organizationNumber',
        },
        props: {
          labelKey: 'company.fields.organization',
          required: true,
          type: 'number',
        },
      },
    ],
    helperTextKey: 'company.fieldset.details.helperText',
    key: 'company-details',
    size: { lg: 6, md: 6, sm: 6, xl: 6, xs: 12 },
    titleKey: 'company.fieldset.details.title',
  },
];
