import Box from '@mui/material/Box';
import type { FC } from 'react';
import React from 'react';

import type { CommentFilePreview } from '../Typer/typer.type';
import { AttachmentPreview } from './AttachmentPreview/attachment-preview.component';
import { sxProps } from './attachments-preview.styles';

type Props = {
  attachments: CommentFilePreview[];
};

export const AttachmentsPreview: FC<Props> = (props) => {
  const { attachments } = props;

  return (
    <Box sx={sxProps.container}>
      {attachments?.map((attachment, idx) => (
        <AttachmentPreview
          attachment={attachment}
          idx={idx}
          key={attachment.id}
        />
      ))}
    </Box>
  );
};
