import type {
  CitySerializerDTO,
  CountrySerializerDTO,
} from '../../connectors/company';
import type { ListLeasesSerializerDTO } from '../../connectors/contract';
import { LeasesAPI } from '../../connectors/contract';
import type {
  ListPropertyObjectsSerializerDTO,
  ListUnitsSerializerDTO,
} from '../../connectors/property';
import {
  PropertyObjectsAPI,
  UnitCategoryEnumDTO,
  UnitsAPI,
} from '../../connectors/property';
import { TicketsAPI } from '../../connectors/ticket';
import type {
  BaseUserSerializerDTO,
  DetailedUserSerializerDTO,
  ListUserSerializerDTO,
  NationalitySerializerDTO,
  PatchUserSerializerDTO,
  UsersAPIGetUsersUsersGetRequest,
  UserSerializerDTO,
} from '../../connectors/user';
import {
  UserRoleEnumDTO,
  UsersAPI,
  UserTypeEnumDTO,
} from '../../connectors/user';
import { mieInstance } from '../mie.instance';
import type { AsyncAutocompleteOption, ListQueryParams } from '../shared';
import { getUserName } from '../shared';
import { getEnvValue } from '../shared/helpers/env.helper';
import type { FilterValues as HomeFilterValues } from './HomeList/home-list.model';
import type { FilterValues as LeaseFilterValues } from './LeaseList/lease-list.model';
import type { FilterValues as PropertyObjectFilterValues } from './PropertyObjectList/property-object-list.model';

class UserClient {
  private client;

  private leaseClient;

  private propertyObjectClient;

  private ticketClient;

  private unitClient;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.client = new UsersAPI(undefined, baeBath, mieInstance);
    this.leaseClient = new LeasesAPI(undefined, baeBath, mieInstance);
    this.propertyObjectClient = new PropertyObjectsAPI(
      undefined,
      baeBath,
      mieInstance,
    );
    this.ticketClient = new TicketsAPI(undefined, baeBath, mieInstance);
    this.unitClient = new UnitsAPI(undefined, baeBath, mieInstance);
  }

  public getList$ = async (
    queryParams?: ListQueryParams,
  ): Promise<ListUserSerializerDTO> => {
    const { page, pageSize, search, sort, tab } = { ...queryParams };
    const response = await this.client.getUsersUsersGet({
      order: sort,
      page,
      pageSize,
      search,
      userType: tab,
    });

    return response.data;
  };

  public getDetails$ = async (
    id: UserSerializerDTO['uuid'],
  ): Promise<DetailedUserSerializerDTO> => {
    const response = await this.client.getUserUsersUserUuidGet({
      userUuid: id,
    });

    return response.data;
  };

  public update$ = async (
    id: UserSerializerDTO['uuid'],
    values: PatchUserSerializerDTO,
  ): Promise<UserSerializerDTO> => {
    const response = await this.client.patchUserUsersUserUuidPatch({
      patchUserSerializerDTO: values,
      userUuid: id,
    });

    return response.data;
  };

  public create$ = async (
    values: BaseUserSerializerDTO,
  ): Promise<UserSerializerDTO> => {
    const response = await this.client.createUserUsersPost({
      baseUserSerializerDTO: values,
    });

    return response.data;
  };

  public delete$ = async (id: UserSerializerDTO['uuid']): Promise<void> => {
    await this.client.deleteUserUsersUserUuidDelete({ userUuid: id });
  };

  public getEmployeeSuggestions$ = async (
    query: string,
    country?: CountrySerializerDTO['uuid'],
    city?: CitySerializerDTO['uuid'],
    roles?: UserRoleEnumDTO[],
    excludedRoles?: UserRoleEnumDTO[],
    activeUsers?: UsersAPIGetUsersUsersGetRequest['activeUsers'],
  ): Promise<
    NonNullable<AsyncAutocompleteOption<UserSerializerDTO['uuid']>>[]
  > => {
    const tab = [
      UserTypeEnumDTO.Employee,
      UserTypeEnumDTO.ExternalEmployee,
    ].join(',');
    const finalExcludedRoles = [
      ...(excludedRoles || []),
      UserRoleEnumDTO.SuperUser,
      UserRoleEnumDTO.SystemAdministrator,
    ].join(',');
    const response = await this.client.getUsersUsersGet({
      activeUsers,
      countryUuid: country,
      excludeRole: finalExcludedRoles,
      pageSize: 100,
      role: roles?.join(','),
      search: query,
      userType: tab,
    });

    return response.data.results.map((user) => ({
      avatar: user.profilePicture,
      label: `${getUserName(user)} - ${user.email}`,
      value: user.uuid,
    }));
  };

  public getTenantSuggestions$ = async (
    query: string,
  ): Promise<AsyncAutocompleteOption<UserSerializerDTO['uuid']>[]> => {
    const response = await this.getList$({
      search: query,
      tab: UserTypeEnumDTO.Customer,
    });

    return response.results.map((user) => ({
      label: `${getUserName(user)} - ${user.email}`,
      value: user.uuid,
    }));
  };

  public getLeases$ = async (
    id: UserSerializerDTO['uuid'],
    queryParams: ListQueryParams<LeaseFilterValues>,
  ): Promise<ListLeasesSerializerDTO> => {
    const { filters, page, pageSize, sort, search } = { ...queryParams };
    const { status } = { ...filters };
    const response = await this.leaseClient.getLeasesLeasesGet({
      order: sort,
      page,
      pageSize,
      search,
      signerUuid: id,
      status: status || undefined,
    });

    return response.data;
  };

  public getPropertyObjects$ = async (
    id: UserSerializerDTO['uuid'],
    queryParams?: ListQueryParams<PropertyObjectFilterValues>,
  ): Promise<ListPropertyObjectsSerializerDTO> => {
    const { filters, page, pageSize, search, sort } = { ...queryParams };
    const { category } = { ...filters };
    const response =
      await this.propertyObjectClient.getPropertyObjectsPropertyObjectsGet({
        category: category || undefined,
        contactUuid: id,
        order: sort,
        page,
        pageSize,
        search,
      });

    return response.data;
  };

  public getHomes$ = async (
    id: UserSerializerDTO['uuid'],
    queryParams?: ListQueryParams<HomeFilterValues>,
  ): Promise<ListUnitsSerializerDTO> => {
    const { filters, page, pageSize, search, sort } = { ...queryParams };
    const { status } = { ...filters };
    const response = await this.unitClient.getUnitsUnitsGet({
      category: UnitCategoryEnumDTO.ResidentialHomes,
      contactUuid: id,
      order: sort,
      page,
      pageSize,
      search,
      status: status || undefined,
    });

    return response.data;
  };

  public getNationalities$ = async (
    search?: string,
  ): Promise<
    NonNullable<
      AsyncAutocompleteOption<NationalitySerializerDTO['countryCode']>
    >[]
  > => {
    const response = await this.client.getNationalitiesUsersNationalityGet({
      search,
    });

    return response.data.results.map(({ countryCode, name }) => ({
      label: name,
      value: countryCode,
    }));
  };
}
export const userClient = new UserClient();
