import type { Action, State } from './type';

export const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  isLoggedOut: false,
  user: null,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'AUTH_INITIALIZE': {
      return {
        ...state,
        ...action.payload,
        isInitialized: true,
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
        isLoggedOut: false,
        user: action.payload.user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        isLoggedOut: true,
        user: null,
      };
    }
    case 'SET_NOTIFICATION_SETTINGS': {
      if (state.user) {
        return {
          ...state,
          user: {
            ...state.user,
            notificationConfig: {
              ...state.user.notificationConfig,
              ...action.payload,
            },
          },
        };
      }

      return state;
    }
    default: {
      return state;
    }
  }
};
