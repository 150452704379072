import { ErrorBoundary } from '@sentry/react';
import type { FC } from 'react';
import React, { Suspense } from 'react';

import { GeneralError, Loading } from '../shared';

export const Loadable: (Component: FC) => FC = (Component) => (props) =>
  (
    <ErrorBoundary
      fallback={<GeneralError messageKey="errors.general.message" />}
    >
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    </ErrorBoundary>
  );
