import type { AxiosError, AxiosResponse } from 'axios';

import { AuthAPI } from '../../connectors/user';
import type {
  AuthenticationTypeEnumDTO,
  LoginOutputSerializerDTO,
  MeSerializerDTO,
  UserNotificationConfigSerializerDTO,
  UserUpdateNotificationConfigSerializerDTO,
} from '../../connectors/user';
import { mieInstance } from '../mie.instance';
import { getEnvValue } from '../shared/helpers/env.helper';
import storageService from '../shared/services/storage.service';
import type { ContextValue } from './shared';

class AuthClient {
  private client;

  private idToken;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.client = new AuthAPI(undefined, baeBath, mieInstance);
    this.idToken = storageService.getIdToken();
  }

  public authorize$: ContextValue['authorize$'] = async (
    authType,
    searchParams,
  ) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const options = {
        query: Object.fromEntries(searchParams.entries()),
      };
      const response = await this.client.tokenAuthAuthTypeTokenGet(
        { authType },
        options,
      );
      const tokens: LoginOutputSerializerDTO = {
        accessToken: response.data.accessToken,
        idToken: response.data.idToken,
        refreshToken: response.data.refreshToken,
      };

      this.setTokens(tokens, authType);
    } catch (e) {
      // Propagate error to next catch to display the error component
      throw e;
    }
  };

  public login$: ContextValue['login$'] = async (email, password) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.client.loginAuthLoginPost({
        password,
        username: email,
      });

      this.setTokens(response.data);
    } catch (e) {
      throw e;
    }
  };

  public loginByProvider: ContextValue['loginByProvider'] = (type) => {
    const provider = type.toUpperCase();
    const origin = getEnvValue('API_PATH');
    const authorizeUrl = getEnvValue(`${provider}_AUTHORIZE_URL`);
    const redirectUri = window.origin + authorizeUrl;
    const url = origin + authorizeUrl;

    window.location.href = `${url}?redirect_uri=${redirectUri}`;
  };

  public refreshToken$ = async (error: AxiosError): Promise<AxiosResponse> => {
    const EXCLUDED_URLS = ['authorize', 'login', 'logout', 'refresh_token'];
    const { config } = error;

    if (
      EXCLUDED_URLS.some((url) => error.config.url?.includes(url)) ||
      !this.idToken
    ) {
      return Promise.reject(error.response);
    }

    try {
      const refreshToken = storageService.getRefreshToken();
      const response = await this.client.tokenRefreshAuthRefreshGet({
        idToken: this.idToken,
        refreshToken,
      });
      const tokens: LoginOutputSerializerDTO = {
        accessToken: response.data.accessToken,
        idToken: response.data.idToken,
        refreshToken,
      };

      this.setTokens(tokens);

      return mieInstance.request({
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
    } catch (e) {
      this.logout();

      return Promise.reject(error.response);
    }
  };

  public logout: ContextValue['logout'] = async () => {
    const refreshToken = storageService.getRefreshToken();

    storageService.clear();
    this.idToken = '';

    if (refreshToken) {
      await this.client.logoutAuthLogoutPost({
        logoutSerializerDTO: { refreshToken },
      });
    }

    mieInstance.defaults.headers.common.Authorization = '';
  };

  public me$ = async (): Promise<MeSerializerDTO | undefined> => {
    try {
      const response = await this.client.getMeAuthMeGet();

      return response.data;
    } catch (e) {
      return undefined;
    }
  };

  private setTokens = (
    tokens: LoginOutputSerializerDTO,
    authType?: AuthenticationTypeEnumDTO,
  ): void => {
    const { accessToken, idToken } = tokens;

    this.idToken = idToken;
    mieInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    storageService.setTokens(tokens);

    if (authType) {
      storageService.setAuthType(authType);
    }
  };

  public updateNotificationSetting$ = async (
    data: UserUpdateNotificationConfigSerializerDTO,
  ): Promise<UserNotificationConfigSerializerDTO> => {
    const response =
      await this.client.updateUserNotificationConfigAuthMeNotificationConfigurationPatch(
        {
          userUpdateNotificationConfigSerializerDTO: data,
        },
      );

    return response.data;
  };
}
export const authClient = new AuthClient();
