import { ReactComponent as AddCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add-circle.svg';
import { ReactComponent as RealEstateDealDocumentIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/deals/real-estate-deal-document.svg';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';

import { advertisementClient } from '../../../../+advertisement';
import type {
  ProspectDetailsSerializerDTO,
  UnitAdvertisementSerializerDTO,
} from '../../../../../connectors/property';
import {
  AsyncContentProvider,
  Dialog,
  useTranslation,
} from '../../../../shared';
import { Form } from '../Form/form.component';
import { sxProps } from './create.styles';

type Props = {
  advertisementId: UnitAdvertisementSerializerDTO['uuid'];
  handleSuccess: (prospect: ProspectDetailsSerializerDTO) => void;
};

export const Create: FC<Props> = (props) => {
  const { advertisementId, handleSuccess } = props;
  const { t } = useTranslation();
  const [isFormDialog, setFormDialogOpen] = useState(false);
  const [isInfoDialogOpen, setInfoDialogOpen] = useState(false);
  const closeFormDialog = useCallback(() => setFormDialogOpen(false), []);
  const closeInfoDialog = useCallback(() => setInfoDialogOpen(false), []);
  const handleFormSuccess = useCallback(
    (prospect: ProspectDetailsSerializerDTO) => {
      handleSuccess(prospect);
      closeFormDialog();
      setInfoDialogOpen(true);
    },
    [closeFormDialog, handleSuccess],
  );
  const getData$ = useCallback(
    () => advertisementClient.getUnitAdvertisement$(advertisementId),
    [advertisementId],
  );

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Button
          data-testid="create-prospect-button"
          color="secondary"
          onClick={() => setFormDialogOpen(true)}
          size="large"
          variant="contained"
        >
          <Icon sx={sxProps.icon}>
            <AddCircleIcon />
          </Icon>
          {t('prospect.buttons.create')}
        </Button>
      </Grid>
      <Dialog
        enableActions={false}
        fullWidth
        isOpen={isFormDialog}
        maxWidth="md"
        onClose={closeFormDialog}
        titleComponent={
          <Typography variant="h2">{t('prospect.create.title')}</Typography>
        }
      >
        <AsyncContentProvider getData$={getData$}>
          {(advertisement) => (
            <Grid container mt={1} spacing={2}>
              <Grid item sm={6} xs={12}>
                <Form
                  advertisement={advertisement}
                  handleSuccess={handleFormSuccess}
                />
              </Grid>
              <Grid item sm={6} display={['none', 'block']}>
                <Box sx={sxProps.formIllustration} />
              </Grid>
            </Grid>
          )}
        </AsyncContentProvider>
      </Dialog>
      <Dialog
        enableActions={false}
        fullWidth
        isOpen={isInfoDialogOpen}
        maxWidth="sm"
        onClose={closeInfoDialog}
      >
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <RealEstateDealDocumentIcon height={120} width={120} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h2">
              {t('prospect.create.success')}
            </Typography>
            <Typography align="center">
              {t('prospect.create.helperText')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Button
                onClick={closeInfoDialog}
                size="large"
                variant="contained"
              >
                {t('dialog.confirmation.buttons.agree')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
