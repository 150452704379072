import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';
import { useFormikContext } from 'formik';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useMemo } from 'react';

import { useTranslation } from '../../../../../shared';
import { isStepValid } from '../form.helper';
import type { FormValues, Step } from '../form.types';
import { sxProps } from './navigation-button.styles';

type Props = {
  activeStep: Step;
  activeStepIdx: number;
  setActiveStepIdx: Dispatch<SetStateAction<number>>;
};

export const NavigationButton: FC<Props> = (props) => {
  const { activeStep, activeStepIdx, setActiveStepIdx } = props;
  const { errors, setTouched } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const isDisabled = useMemo(
    () => !isStepValid(activeStepIdx, errors),
    [activeStepIdx, errors],
  );
  const handleNextStep = useCallback(() => {
    const { validationFieldNames } = activeStep;
    const touchedFields = validationFieldNames.reduce(
      (acc, fieldName) => ({ ...acc, [fieldName]: true }),
      {},
    );

    setTouched(touchedFields);

    if (!isDisabled) {
      setActiveStepIdx((activeStepIdx) => activeStepIdx + 1);
    }
  }, [activeStep, isDisabled, setActiveStepIdx, setTouched]);

  return (
    <Box sx={sxProps.container}>
      <MUIButton
        data-testid="form-navigation-button-next"
        disabled={isDisabled}
        onClick={handleNextStep}
        variant="contained"
      >
        {t('advertisement.create.button.next')}
      </MUIButton>
    </Box>
  );
};
