import { useMemo } from 'react';
import type { FC, ReactElement } from 'react';

import type { Permission } from '../../../+auth';
import { isPermitted, useAuth } from '../../../+auth';

type Props = {
  tabs: {
    expectedPermissions: Permission[];
    component: ReactElement;
    additionalCondition?: boolean;
  }[];
};
export const DefaultRouteTabContent: FC<Props> = (props) => {
  const { tabs } = props;
  const { permissions } = useAuth();
  const foundTab = useMemo(
    () =>
      tabs.find((tab) => {
        const isVisible =
          tab.additionalCondition !== undefined
            ? tab.additionalCondition
            : true;

        return isVisible && isPermitted(tab.expectedPermissions, permissions);
      }),
    [tabs, permissions],
  );

  if (!foundTab) return null;

  return foundTab.component;
};
