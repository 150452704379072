import type { FC, ReactNode } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../+auth';
import type {
  AdvertisementIntegrationSerializerDTO,
  CountrySerializerDTO,
} from '../../../../../connectors/company';
import type { AdvertisementIntegrationPlatformValidationRulesSerializerDTO } from '../../../../../connectors/property';
import { NOT_FOUND_PATH } from '../../../../routing';
import type { Country } from '../../../../shared';
import {
  HttpStatus,
  transformKeys,
  useApi,
  useTranslation,
} from '../../../../shared';
import { advertisementClient } from '../../../advertisement.client';
import type { DynamicValidationRules } from '../../components';
import type { ContextValue } from './context';
import { Context, initialConfig, initialCountryConfig } from './context';

type Props = {
  children: ReactNode;
};

export const Provider: FC<Props> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [dynamicValidationRules, setDynamicValidationRules] =
    useState<DynamicValidationRules>({});
  const [validationRules, setValidationRules] = useState<
    AdvertisementIntegrationPlatformValidationRulesSerializerDTO[]
  >([]);
  const fetchConfig$ = useCallback(
    (): Promise<
      {
        countryId: CountrySerializerDTO['uuid'];
        integrations: AdvertisementIntegrationSerializerDTO[];
      }[]
    > =>
      user?.countryUuids
        ? Promise.all(
            user.countryUuids.map(async (countryId) => {
              const integrationsConfig =
                await advertisementClient.getIntegrationsConfig$(countryId);

              return { countryId, integrations: integrationsConfig.results };
            }, Promise.resolve([])),
          )
        : Promise.resolve([]),
    [user?.countryUuids],
  );
  const { error, response } = useApi([], fetchConfig$);
  const config = useMemo<ContextValue['config']>(
    () =>
      response.reduce(
        (config, { countryId, integrations }) => ({
          ...config,
          [countryId]: {
            integrations: integrations.map((integration) => ({
              ...integration,
              advertisementPlatform: transformKeys(
                integration.advertisementPlatform,
                'SNAKE_CASE',
              ),
            })),
          },
        }),
        initialConfig,
      ),
    [response],
  );
  const isNotFoundError = useMemo(
    () => error?.status === HttpStatus.NOT_FOUND,
    [error],
  );
  const getCountryConfig = useCallback(
    (country?: Country | CountrySerializerDTO['uuid']) =>
      country ? config[country as Country] : initialCountryConfig,
    [config],
  );
  const getIntegrationsConfig = useCallback(
    (country?: Country | CountrySerializerDTO['uuid']) =>
      getCountryConfig(country)?.integrations || [],
    [getCountryConfig],
  );
  const fetchValidationRules$ = useCallback(
    async (country?: Country | CountrySerializerDTO['uuid']) => {
      if (!country) return;

      try {
        const schema = await advertisementClient.getValidationRules$(country);

        setValidationRules(schema);
      } catch (e) {
        toast.error(t('errors.general.message'));
      }
    },
    [t],
  );
  const value = useMemo<ContextValue>(
    () => ({
      config,
      dynamicValidationRules,
      fetchValidationRules$,
      getCountryConfig,
      getIntegrationsConfig,
      setDynamicValidationRules,
      validationRules,
    }),
    [
      config,
      dynamicValidationRules,
      fetchValidationRules$,
      getCountryConfig,
      getIntegrationsConfig,
      validationRules,
    ],
  );

  if (isNotFoundError) {
    navigate(NOT_FOUND_PATH);

    return null;
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
