import type { ProfileUpdateTicketData } from './profile-update-ticket.type';

export const getDataDifferences = (
  currentData: ProfileUpdateTicketData,
  newData?: ProfileUpdateTicketData,
): Partial<ProfileUpdateTicketData> => {
  if (!newData) return {};

  return Object.entries(newData).reduce((acc, [key, value]) => {
    const transformedKey = key as keyof ProfileUpdateTicketData;

    if (!Object.values(currentData).includes(value)) {
      acc[transformedKey] = value;
    }

    return acc;
  }, {} as Partial<ProfileUpdateTicketData>);
};

export const isDataNotEqual = (
  currentData: ProfileUpdateTicketData,
  newData?: ProfileUpdateTicketData,
): boolean =>
  newData
    ? Object.values(newData).some(
        (value) => !Object.values(currentData).includes(value),
      )
    : true;
