import { ReactComponent as ArchitectureDoorIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as SingleManHomeIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-man/single-man-home.svg';
import { addDays } from 'date-fns';
import * as Yup from 'yup';

import { unitClient } from '../../../../+unit';
import type {
  ListAttachmentSerializerDTO,
  ComplaintDetailSerializerDTO,
} from '../../../../../connectors/ticket';
import {
  DepartmentTypeEnumDTO,
  EntityEnumDTO,
  PriorityTypeEnumDTO,
  TicketStatusEnumDTO,
  TicketVisibilityEnumDTO,
} from '../../../../../connectors/ticket';
import { localizationClient } from '../../../../localization.client';
import type {
  FieldConfig,
  FieldsetConfig,
  LocalizationConfig,
} from '../../../../shared';
import {
  ATTACHMENT_MAX_FILE_SIZE,
  attachmentConvertToFiles,
  attachmentSupportedFileTypeExtensions,
  DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  DEFAULT_DATE_FIELD_VALUE,
  DEFAULT_FIELD_VALUE,
} from '../../../../shared';
import { ticketClient } from '../../../ticket.client';
import { Reporter } from '../../enums';
import {
  departmentTypeOptions,
  entityTypeOptions,
  getEntityIcon,
  getEntitySuggestions$,
  getEntityTypeFieldTranslationLabelKey,
  getEntityUnitSuggestions$,
  getTenantFieldInitialValue,
  isUnitEntity,
  isUserEntity,
  priorityTypeOptions,
  reporterTypeOptions,
} from '../../helpers';
import type { GeneralRequestValues, RequestDetailsData } from '../../types';

export const getInitialValues = (
  countries: LocalizationConfig['userCountries'],
  data?: RequestDetailsData<ComplaintDetailSerializerDTO>,
  attachments: ListAttachmentSerializerDTO['results'] = [],
): GeneralRequestValues => ({
  apartment:
    data?.entity && isUnitEntity(data.entity)
      ? { label: data.entity.name, value: data.entity.uuid }
      : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  assignee: data?.assignee
    ? { label: data.assignee.email, value: data.assignee.uuid }
    : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  attachments: attachments ? attachmentConvertToFiles(attachments) : [],
  city:
    data?.entity && !isUserEntity(data.entity) && data.entity.city
      ? { label: data.entity.city.name, value: data.entity.city.uuid }
      : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  country: data?.countryUuid || countries[0]?.value,
  department: data?.department || DepartmentTypeEnumDTO.CustomerService,
  description: data?.description || DEFAULT_FIELD_VALUE,
  dueDate: data?.dueDate ? new Date(data.dueDate) : DEFAULT_DATE_FIELD_VALUE,
  entity:
    data?.entity && !isUserEntity(data.entity)
      ? {
          additionalValues: data.entity,
          label: data.entity.name,
          value: data.entity.uuid,
        }
      : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  entityType:
    data?.entity && !isUserEntity(data.entity)
      ? data?.entity.type
      : EntityEnumDTO.Unit,
  isPrivate: data?.visibility !== TicketVisibilityEnumDTO.Public,
  priority: data?.priority || PriorityTypeEnumDTO.Medium,
  reporter: Reporter.TENANT,
  summary: data?.summary || DEFAULT_FIELD_VALUE,
  tenant: getTenantFieldInitialValue(data),
});

export const getFields = (
  countries: LocalizationConfig['userCountries'],
  initialValues: GeneralRequestValues,
  isUpdateMode: boolean,
  isPredefinedData?: boolean,
  status?: TicketStatusEnumDTO | undefined,
): FieldsetConfig<GeneralRequestValues>[] => [
  {
    fields: [
      {
        componentType: 'select',
        field: {
          initialValue: initialValues.country,
          name: 'country',
        },
        props: {
          disabled:
            (isPredefinedData && Boolean(initialValues.country)) ||
            countries.length === 1,
          labelKey: 'ticket.fields.country.label',
          options: countries,
          placeholderKey: 'ticket.fields.country.placeholder',
          relatedFields: [
            'apartment',
            'assignee',
            'city',
            'department',
            'dueDate',
            'entity',
            'tenant',
          ],
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue: initialValues.city,
          name: 'city',
        },
        props: {
          callback$: (query, { country }) =>
            localizationClient.getCities$(country, undefined, undefined, query),
          disabled: isPredefinedData && Boolean(initialValues.city),
          disableWhen: ({ country }) => !country,
          labelKey: 'ticket.fields.city',
          relatedFields: ['apartment', 'assignee', 'entity', 'tenant'],
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
      {
        componentType: 'select',
        field: {
          initialValue: initialValues.entityType,
          name: 'entityType',
        },
        props: {
          disabled: isPredefinedData && !isUpdateMode,
          disableWhen: ({ city, country }) => !country || !city,
          labelKey: 'ticket.fields.entityType',
          options: entityTypeOptions,
          relatedFields: ['apartment', 'assignee', 'entity', 'tenant'],
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          startAdornmentIcon: ({ entityType }: GeneralRequestValues) =>
            getEntityIcon(entityType),
        },
      },
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue: initialValues.entity,
          name: 'entity',
          validationSchema: Yup.mixed().when(['entityType'], {
            is: (entityType: EntityEnumDTO) => entityType,
            otherwise: Yup.mixed().nullable(),
            then: Yup.mixed().required('form.errors.required'),
          }),
        },
        props: {
          callback$: (query, { city, country, entityType }) =>
            getEntitySuggestions$(entityType, query, city?.value, country),
          disabled: isPredefinedData && Boolean(initialValues.entity),
          disableWhen: ({ city, country, entityType }) =>
            !country || !city?.value || !entityType,
          isHidden: ({ entityType }) => !entityType,
          labelKey: ({ entityType }) =>
            getEntityTypeFieldTranslationLabelKey(entityType),
          relatedFields: ['apartment', 'assignee', 'tenant'],
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
    ],
    helperTextKey: 'ticket.fieldset.address.text',
    key: 'address-fieldset-config-section',
    titleKey: 'ticket.fieldset.address.title',
  },
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: initialValues.summary,
          name: 'summary',
        },
        props: {
          disabled: status && status !== TicketStatusEnumDTO.Open,
          labelKey: 'ticket.fields.summary.label',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: initialValues.description,
          name: 'description',
        },
        props: {
          disabled: status && status !== TicketStatusEnumDTO.Open,
          labelKey: 'ticket.fields.description',
          required: true,
          type: 'textarea',
        },
      },
      {
        componentType: 'switch',
        field: {
          initialValue: initialValues.isPrivate,
          name: 'isPrivate',
        },
        props: {
          helperTextKey: 'ticket.fields.visibility.helperText',
          labelKey: 'ticket.fields.visibility.label',
        },
      },
    ],
    helperTextKey: 'ticket.fieldset.details.text',
    key: 'details-fieldset-config-section',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'ticket.fieldset.details.title',
  },
  // The reporter field should be visible only during the creation
  ...(isUpdateMode
    ? []
    : ([
        {
          fields: [
            {
              componentType: 'select',
              field: {
                initialValue: initialValues.reporter,
                name: 'reporter',
              },
              props: {
                labelKey: 'ticket.fields.reporter.label',
                options: reporterTypeOptions,
                relatedFields: ['apartment', 'tenant'],
                required: true,
              },
            },
            {
              componentType: 'async-autocomplete',
              field: {
                initialValue: initialValues.apartment,
                name: 'apartment',
                validationSchema: Yup.mixed().when(['reporter'], {
                  is: (reporter: Reporter) => reporter === Reporter.TENANT,
                  otherwise: Yup.mixed().nullable(),
                  then: Yup.mixed().required('form.errors.required'),
                }),
              },
              props: {
                callback$: (query, { city, country, entity, entityType }) =>
                  getEntityUnitSuggestions$(
                    query,
                    entityType,
                    city?.value,
                    country,
                    entity?.value,
                  ),
                disableWhen: ({ country, city, entityType, reporter }) =>
                  !country ||
                  !city?.value ||
                  reporter !== Reporter.TENANT ||
                  entityType === EntityEnumDTO.Unit,
                isHidden: ({ reporter }) => reporter === Reporter.ME,
                labelKey: 'ticket.fields.apartment.label',
                placeholderKey: 'ticket.fields.apartment.placeholder',
                relatedFields: ['tenant'],
                requiredWhen: ({ reporter }) => reporter === Reporter.TENANT,
                startAdornmentIcon: ArchitectureDoorIcon,
              },
            },
            {
              componentType: 'async-autocomplete',
              field: {
                initialValue: initialValues.tenant,
                name: 'tenant',
                validationSchema: Yup.mixed().when(['reporter'], {
                  is: (reporter: Reporter) => reporter === Reporter.TENANT,
                  otherwise: Yup.mixed().nullable(),
                  then: Yup.mixed().required('form.errors.required'),
                }),
              },
              props: {
                callback$: (query, { apartment }) =>
                  apartment
                    ? unitClient.getTenantSuggestions$(apartment.value, query)
                    : Promise.resolve([]),
                disableWhen: ({ apartment, reporter }) =>
                  reporter !== Reporter.TENANT || !apartment?.value,
                isHidden: ({ reporter }) => reporter === Reporter.ME,
                labelKey: 'ticket.fields.tenant.label',
                placeholderKey: 'ticket.fields.tenant.placeholder',
                requiredWhen: ({ reporter }) => reporter === Reporter.TENANT,
                startAdornmentIcon: SingleManHomeIcon,
              },
            },
          ],
          helperTextKey: 'ticket.fieldset.reporter.text',
          key: 'reporter-fieldset-config-section',
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          titleKey: 'ticket.fieldset.reporter.title',
        },
      ] as FieldsetConfig<GeneralRequestValues>[])),
  {
    fields: [
      {
        componentType: 'date',
        field: {
          initialValue: initialValues.dueDate,
          name: 'dueDate',
        },
        props: {
          labelKey: 'ticket.fields.dueDate',
          minDate: addDays(new Date(), 1),
        },
      },
      ...(isUpdateMode
        ? []
        : ([
            {
              componentType: 'select',
              field: {
                initialValue: initialValues.department,
                name: 'department',
              },
              props: {
                labelKey: 'ticket.fields.department',
                options: departmentTypeOptions,
              },
            },
          ] as FieldConfig<GeneralRequestValues>[])),
      ...(isUpdateMode
        ? []
        : ([
            {
              componentType: 'async-autocomplete',
              field: {
                initialValue: initialValues.assignee,
                name: 'assignee',
              },
              props: {
                callback$: (query, { city, country, entity, entityType }) =>
                  country && city?.value && entityType && entity?.value
                    ? ticketClient.getAssigneeSuggestions$(
                        country,
                        query,
                        city.value,
                        entityType,
                        entity.value,
                        entity?.additionalValues?.propertyObject?.uuid,
                      )
                    : Promise.resolve([]),
                disableWhen: ({ city, country, entity, entityType }) =>
                  !country || !city?.value || !entityType || !entity?.value,
                labelKey: 'ticket.fields.assignee',
              },
            },
          ] as FieldConfig<GeneralRequestValues>[])),
    ],
    helperTextKey: isUpdateMode
      ? undefined
      : 'ticket.fieldset.responsibility.text',
    key: 'responsibility-fieldset-config-section',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'ticket.fieldset.responsibility.title',
  },
  {
    fields: [
      {
        componentType: 'select',
        field: {
          initialValue: initialValues.priority,
          name: 'priority',
        },
        props: {
          labelKey: 'ticket.fields.priority',
          options: priorityTypeOptions,
          required: true,
        },
      },
    ],
    helperTextKey: 'ticket.fieldset.priority.text',
    key: 'priority-fieldset-config-section',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'ticket.fieldset.priority.title',
  },
  {
    fields: [
      {
        componentType: 'file',
        field: {
          initialValue: initialValues.attachments,
          name: 'attachments',
        },
        props: {
          accept: attachmentSupportedFileTypeExtensions,
          maxSize: ATTACHMENT_MAX_FILE_SIZE,
        },
      },
    ],
    helperTextKey: 'ticket.fieldset.attachment.text',
    key: 'attachments-fieldset-config-section',
    titleKey: 'ticket.fieldset.attachment.title',
  },
];
