import type {
  AttachmentSerializerDTO,
  ListAttachmentSerializerDTO,
} from '../../../../connectors/company';
import { AttachmentCategoryEnumDTO } from '../../../../connectors/ticket';

type GalleryImage = {
  description: AttachmentSerializerDTO['description'];
  id: AttachmentSerializerDTO['uuid'];
  name: AttachmentSerializerDTO['name'];
  url: AttachmentSerializerDTO['url'];
  visibility: AttachmentSerializerDTO['visibility'];
};

export const getAttachmentsByCategory = (
  attachments: NonNullable<ListAttachmentSerializerDTO['results']>,
  category: AttachmentCategoryEnumDTO,
): ListAttachmentSerializerDTO['results'] =>
  attachments.filter((attachment) => attachment.category === category);

export const getGalleryPreviewImages = (
  images: NonNullable<ListAttachmentSerializerDTO['results']>,
): GalleryImage[] =>
  getAttachmentsByCategory(images, AttachmentCategoryEnumDTO.Image).map(
    (image) => ({
      description: image.description,
      id: image.uuid,
      name: image.title || image.name,
      url: image.url,
      visibility: image.visibility,
    }),
  );
