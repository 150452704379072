import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import type { FC, HTMLAttributes } from 'react';
import React from 'react';

import { useTranslation } from '../../../translations';
import type { AsyncAutocompleteOption } from '../../fields';
import { SuggestionIcon } from '../index';
import { sxProps } from './async-autocomplete-menu-item.styles';

type Props = {
  isFilter: boolean | undefined;
  htmlProps: HTMLAttributes<HTMLLIElement>;
  option: AsyncAutocompleteOption;
};
export const AsyncAutocompleteMenuItem: FC<Props> = (props) => {
  const { isFilter, htmlProps, option } = props;
  const { id, ...restProps } = htmlProps;
  const { t } = useTranslation();

  return (
    <MenuItem
      {...restProps}
      {...(isFilter && { sx: sxProps.filterItem })}
      data-testid={id}
      key={option?.value || 'default-option'}
    >
      {option?.avatar && <Avatar src={option.avatar} sx={sxProps.avatar} />}
      {option?.icon && <SuggestionIcon icon={option?.icon} />}
      {option?.labelKey ? t(option.labelKey) : option?.label}
    </MenuItem>
  );
};
