import Box from '@mui/material/Box';
import type { FC } from 'react';
import React, { useMemo, useState } from 'react';

import { GalleryPreview } from '../GalleryPreview/gallery-preview.component';
import { Image } from './Image/image.component';
import { Navigation } from './Navigation/navigation.component';
import { sxProps } from './carousel.styles';
import type { Image as TImage } from './carousel.type';

type Props = {
  images: TImage[];
  placeholderUrl: string;
};

export const Carousel: FC<Props> = (props) => {
  const { placeholderUrl, images } = props;
  const [activeIdx, setActiveIdx] = useState(0);
  const [isPreview, setPreview] = useState(false);
  const areImages = useMemo(() => images.length > 0, [images.length]);

  return (
    <Box sx={sxProps.container}>
      {areImages ? (
        <>
          {images.map((image, idx) => (
            <Image
              isVisible={activeIdx === idx}
              key={image.id}
              onClick={() => setPreview(true)}
              {...image}
            />
          ))}
          <Navigation
            activeIdx={activeIdx}
            images={images}
            setActiveIdx={setActiveIdx}
          />
          <GalleryPreview
            activeIdx={activeIdx}
            images={images}
            open={isPreview}
            setActiveIdx={setActiveIdx}
            setOpen={setPreview}
          />
        </>
      ) : (
        <Image url={placeholderUrl} isVisible />
      )}
    </Box>
  );
};
