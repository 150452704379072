import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<
  'button' | 'buttonsGridItem' | 'gridItem',
  SxProps<Theme>
> = {
  button: {
    mx: 0.5,
  },
  buttonsGridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  gridItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
};
