import type {
  CreateComplaintSerializerDTO,
  CreateContractRequestSerializerDTO,
  CreateGeneralRequestSerializerDTO,
  CreateInvoiceRequestSerializerDTO,
  CreateServiceRequestSerializerDTO,
  UniversalTicketSerializerDTO,
} from '../../../../connectors/ticket';
import { TicketTypeEnumDTO } from '../../../../connectors/ticket';
import { TicketTypePath } from '../../paths.const';
import { ticketClient } from '../../ticket.client';
import type { CreateRequest, Requests } from '../types';

export const getCreateRequest$ = (
  data: CreateRequest,
  type: TicketTypePath | UniversalTicketSerializerDTO['ticketType'],
): Promise<Requests> => {
  switch (type) {
    case TicketTypeEnumDTO.Complaint:
    case TicketTypePath.COMPLAINTS: {
      return ticketClient.createComplaint$(
        data as CreateComplaintSerializerDTO,
      );
    }
    case TicketTypeEnumDTO.ContractRequest:
    case TicketTypePath.CONTRACT_REQUESTS: {
      return ticketClient.createContractRequest$(
        data as CreateContractRequestSerializerDTO,
      );
    }
    case TicketTypeEnumDTO.GeneralRequest:
    case TicketTypePath.GENERAL_REQUESTS: {
      return ticketClient.createGeneralRequest$(
        data as CreateGeneralRequestSerializerDTO,
      );
    }
    case TicketTypeEnumDTO.InvoiceRequest:
    case TicketTypePath.INVOICE_REQUESTS: {
      return ticketClient.createInvoiceRequest$(
        data as CreateInvoiceRequestSerializerDTO,
      );
    }
    case TicketTypeEnumDTO.ServiceRequest:
    case TicketTypePath.SERVICE_REQUESTS: {
      return ticketClient.createServiceRequest$(
        data as CreateServiceRequestSerializerDTO,
      );
    }
    default: {
      return Promise.reject();
    }
  }
};
