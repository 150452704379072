import type { Account } from '../../../../+account';
import { userClient } from '../../../../+user';
import { TicketTypeEnumDTO } from '../../../../../connectors/ticket';
import type { UserSerializerDTO } from '../../../../../connectors/user';
import { UserRoleEnumDTO } from '../../../../../connectors/user';
import type {
  AsyncAutocompleteOption,
  FieldConfig,
  FormProps,
} from '../../../../shared';
import { DEFAULT_ASYNC_AUTOCOMPLETE_VALUE } from '../../../../shared/form/form.const';
import { getDefaultAssigneeOptions } from '../../consts';

export type Values = {
  assignee: AsyncAutocompleteOption<UserSerializerDTO['uuid']>;
};

const EXCLUDED_ROLES = [UserRoleEnumDTO.Janitor];
const TICKET_TYPES_TO_EXCLUDE_ROLES = [
  TicketTypeEnumDTO.ContractRequest,
  TicketTypeEnumDTO.InvoiceRequest,
];

export const getFieldsConfig = (
  user: Account,
  ticketType: TicketTypeEnumDTO,
): FieldConfig<Values>[] => [
  {
    componentType: 'async-autocomplete',
    field: {
      initialValue: DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
      name: 'assignee',
    },
    props: {
      callback$: (query) =>
        userClient.getEmployeeSuggestions$(
          query,
          undefined,
          undefined,
          undefined,
          TICKET_TYPES_TO_EXCLUDE_ROLES.includes(ticketType)
            ? EXCLUDED_ROLES
            : undefined,
        ),
      labelKey: 'ticket.fields.assignee',
      options: getDefaultAssigneeOptions(user.uuid),
      required: true,
    },
  },
];

export const formConfig: FormProps<Values>['config'] = {
  onSubmit: () => {
    // do nothing
  },
};
