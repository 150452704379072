import { lazy } from 'react';
import type { FC } from 'react';

import { Loadable } from './loadable.component';

const lazyRetry = (
  factory: () => Promise<{ default: FC }>,
  name: string,
): Promise<{ default: FC }> => {
  const itemName = `RETRY_${name.toUpperCase()}_REFRESHED`;
  const item = window.sessionStorage.getItem(itemName);
  const hasRefreshed = item === 'true';

  return new Promise((resolve, reject) => {
    factory()
      .then((component) => {
        if (hasRefreshed) {
          window.sessionStorage.setItem(itemName, 'false');
        }

        resolve(component);
      })
      .catch((error) => {
        if (hasRefreshed) {
          reject(error);

          return;
        }

        window.sessionStorage.setItem(itemName, 'true');
        window.location.reload();
      });
  });
};

export const componentLazyImport = (
  factory: () => Promise<{ default: FC }>,
  name: string,
): FC => Loadable(lazy(() => lazyRetry(factory, name)));
