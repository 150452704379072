import type { FC } from 'react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { Sidebar } from '../Sidebar/sidebar.component';
import {
  LayoutContainer,
  LayoutContent,
  LayoutRoot,
  LayoutWrapper,
} from './layout.styles';

export const Layout: FC = () => (
  <LayoutRoot>
    <Sidebar />
    <LayoutWrapper>
      <LayoutContainer>
        <LayoutContent>
          <Outlet />
        </LayoutContent>
      </LayoutContainer>
    </LayoutWrapper>
  </LayoutRoot>
);
