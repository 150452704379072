import { ReactComponent as CommonFileStackIcon } from '@heimstaden/icons-library/img/streamline-regular/files-folders/common-files/common-file-stack.svg';
import { ReactComponent as AddCircle1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add-circle-1.svg';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FieldProps as FormikFieldProps } from 'formik';
import isFunction from 'lodash-es/isFunction';
import React, { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import type { DropzoneOptions } from 'react-dropzone';

import { useTranslation } from '../../../translations';
import { FieldLabel, FilesPreview } from '../../components';
import { getFirstErrorKey } from '../../helpers';
import type { CustomFile, FileProps, FileValue } from '../types';
import { sxProps } from './file-field.styles';

type Props<TFormValues> = FormikFieldProps<FileValue, TFormValues> & {
  props: FileProps<TFormValues>;
};

export const FileField = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const { t } = useTranslation();
  const { field, form, meta, props: fieldProps } = props;
  const { name } = field;
  const { setFieldValue, values } = form;
  const {
    accept,
    disabled,
    disableWhen,
    formControlSx,
    labelKey,
    maxFiles,
    maxSize,
    minSize,
    readonly,
    required,
    requiredWhen,
  } = fieldProps;
  const { error, value: files, touched } = meta;
  const finalLabelKey = useMemo(
    () => (isFunction(labelKey) ? labelKey(values) : labelKey),
    [labelKey, values],
  );
  const errorMessageKey = useMemo(
    () => touched && getFirstErrorKey(error),
    [error, touched],
  );
  const isDisabled = useMemo(
    () => Boolean(disabled || readonly || (disableWhen && disableWhen(values))),
    [disableWhen, disabled, readonly, values],
  );
  const isError = useMemo(() => Boolean(errorMessageKey), [errorMessageKey]);
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );
  const onDrop = useCallback(
    (newFiles: FileValue) => setFieldValue(name, [...files, ...newFiles]),
    [files, name, setFieldValue],
  );
  const onUpdate = useCallback(
    (files: FileValue) => setFieldValue(name, files),
    [name, setFieldValue],
  );
  const onRemove = useCallback(
    (index: number) => {
      const updatedFiles = [
        ...files.slice(0, index),
        ...files.slice(index + 1),
      ];

      onUpdate(updatedFiles);
    },
    [files, onUpdate],
  );
  const validator: DropzoneOptions['validator'] = useCallback(
    (file: CustomFile) => {
      const isDuplicated = files.some((f) => f.path === file.path);

      return isDuplicated ? { code: 'file-duplicated', message: '' } : null;
    },
    [files],
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    disabled: isDisabled,
    maxFiles,
    maxSize,
    minSize,
    onDrop,
    validator,
  });

  return (
    <FormControl
      component="fieldset"
      disabled={isDisabled}
      error={isError}
      fullWidth
      required={isRequired}
      sx={formControlSx}
    >
      <FieldLabel labelKey={finalLabelKey} />
      {files.length > 0 && (
        <FilesPreview files={files} onUpdate={onUpdate} onRemove={onRemove} />
      )}
      <Box
        {...getRootProps()}
        sx={
          {
            ...sxProps.box,
            ...(files.length > 0 && sxProps.reuploader),
            ...(disabled && sxProps.disabled),
          } as SxProps
        }
      >
        <input {...getInputProps()} />
        <Grid
          alignItems="center"
          container
          justifyContent="center"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item>
            {files.length > 0 ? (
              <AddCircle1Icon height={24} width={24} />
            ) : (
              <CommonFileStackIcon height={64} width={64} />
            )}
          </Grid>
          <Grid item>
            {files.length > 0 ? (
              <Typography variant="h5">
                {t('fields.dropzone.reupload')}
              </Typography>
            ) : (
              <>
                <Typography marginBottom={1} variant="h5">
                  {t('fields.dropzone.title')}
                </Typography>
                <Typography>{t('fields.dropzone.subtitle')}</Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      {isError && (
        <FormHelperText error>{t(errorMessageKey || '')}</FormHelperText>
      )}
    </FormControl>
  );
};
