/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ProspectStatusEnumDTO {
    ContractSent = 'contract_sent',
    ContractSigned = 'contract_signed',
    ProspectRegistered = 'prospect_registered',
    ProspectConfirmedByUser = 'prospect_confirmed_by_user',
    ProspectCancelled = 'prospect_cancelled',
    ProspectRejected = 'prospect_rejected',
    ViewingRequested = 'viewing_requested',
    ViewingDateConfirmed = 'viewing_date_confirmed',
    Viewed = 'viewed',
    ViewingCanceled = 'viewing_canceled',
    ViewingFailed = 'viewing_failed'
}



