import type { FC } from 'react';
import React from 'react';

import { Wrapper } from './Wrapper/wrapper.component';
import type { Props } from './map.type';
import { Button, Content } from './shared';

export const Map: FC<Props> = (props) => {
  const { isButton, locations } = props;

  return (
    <>
      <Wrapper>
        <Content {...props} />
      </Wrapper>
      {isButton && <Button location={locations[0]} />}
    </>
  );
};
