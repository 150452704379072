import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import type { TablePaginationProps } from '@mui/material/TablePagination';
import React from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';

import type { ListState } from '../list.type';

type Props<TFilterValues> = {
  listState: ListState<TFilterValues>;
  setListState: Dispatch<SetStateAction<ListState<TFilterValues>>>;
};

export const Pagination = <TFilterValues,>(
  props: Props<TFilterValues>,
): ReturnType<FC<Props<TFilterValues>>> => {
  const { listState, setListState } = props;
  const handlePageChange: TablePaginationProps['onPageChange'] = (
    event,
    value,
  ) => {
    if (!listState || !setListState) return;

    const { paginationConfig } = listState;
    const currentPage =
      paginationConfig.currentPage < value + 1
        ? paginationConfig.next
        : paginationConfig.previous;

    if (currentPage === null) return;

    setListState((prevState) => {
      return {
        ...prevState,
        paginationConfig: {
          ...prevState.paginationConfig,
          currentPage,
        },
      };
    });
  };
  const handleLimitChange: TablePaginationProps['onRowsPerPageChange'] = (
    event,
  ) => {
    if (!listState || !setListState) return;

    setListState((prevState) => {
      return {
        ...prevState,
        paginationConfig: {
          ...prevState.paginationConfig,
          currentPage: 1,
          pageSize: parseInt(event.target.value, 10),
        },
      };
    });
  };

  return (
    <Box>
      <TablePagination
        component="div"
        count={listState.paginationConfig.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={listState.paginationConfig.currentPage - 1}
        rowsPerPage={listState.paginationConfig.pageSize}
        rowsPerPageOptions={listState.paginationConfig.pageSizeOptions}
      />
    </Box>
  );
};
