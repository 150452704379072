import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  wrapper: {
    borderRadius: 2,
    filter: 'drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15))',
    height: '100%',
    position: 'relative',
  },
};
