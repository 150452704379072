import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  button: {
    backgroundColor: (theme) => theme.palette.grey[200],
    ml: 1,
  },
  iconContainer: {
    display: 'flex',
  },
};
