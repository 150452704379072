import { ReactComponent as Bin2Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/delete/bin-2.svg';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import type { Dispatch, SetStateAction } from 'react';
import React, { forwardRef, useCallback } from 'react';
import { toast } from 'react-hot-toast';

import type { NotificationSerializerDTO } from '../../../../connectors/notification';
import { useTranslation } from '../../translations';
import { notificationClient } from '../NotificationBell/notification.client';
import { sxProps } from './notifications-footer.styles';

type Props = {
  isDisabled: boolean;
  isEmpty: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setNotifications: Dispatch<SetStateAction<NotificationSerializerDTO[]>>;
};

export const NotificationsFooter = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { isEmpty, isDisabled, setDisabled, setNotifications } = props;
    const { t } = useTranslation();
    const handleClick = useCallback(async () => {
      try {
        setDisabled(true);
        await notificationClient.clearAll$();
        setNotifications([]);
      } catch (e) {
        toast.error(t('errors.general.message'));
      } finally {
        setDisabled(false);
      }
    }, [setDisabled, setNotifications, t]);

    return (
      <Box ref={ref} sx={sxProps.footer}>
        <Button
          {...(isEmpty && { sx: sxProps.notificationButton })}
          color="secondary"
          disabled={isEmpty && isDisabled}
          onClick={handleClick}
          variant="text"
        >
          <Bin2Icon height={16} width={16} />
          <Typography component="span" ml={1}>
            {t('notification.clearAllButton')}
          </Typography>
        </Button>
      </Box>
    );
  },
);
