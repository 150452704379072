/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SolutionDoneEnumDTO {
    DoneByHeimstaden = 'done_by_heimstaden',
    DoneByExternalPartners = 'done_by_external_partners',
    DoneByTheTenantHimself = 'done_by_the_tenant_himself',
    AgreementOnTheTenantsMaintenanceObligation = 'agreement_on_the_tenant\'s_maintenance_obligation'
}



