import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  image: {
    backgroundSize: 'cover',
    cursor: 'pointer',
    height: '100px',
    mb: 1,
    mr: 1,
    position: 'relative',
    width: '100px',
  },
  skeleton: {
    mb: 1,
    mr: 1,
  },
};
