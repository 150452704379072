import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    alignItems: 'flex-start',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
  },
};
