import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';

import type { ServiceRequestDetailSerializerDTO } from '../../../../../connectors/ticket';
import { AttachmentCategoryEnumDTO } from '../../../../../connectors/ticket';
import { AsyncContentProvider, useTranslation } from '../../../../shared';
import { ticketClient } from '../../../ticket.client';
import { Effect } from './Effect/effect.component';
import { Gallery } from './Gallery/gallery.component';
import { ImagesUploader } from './Uploader/uploader.component';
import { sxProps } from './images.styles';

type Props = {
  id: ServiceRequestDetailSerializerDTO['uuid'];
};

export const Images: FC<Props> = (props) => {
  const { id } = props;
  const { t } = useTranslation();
  const [isEditMode, setEditMode] = useState(true);
  const getImages$ = useCallback(
    async () =>
      ticketClient.getAttachments$(id, AttachmentCategoryEnumDTO.Image),
    [id],
  );

  return (
    <AsyncContentProvider getData$={getImages$}>
      {() => (
        <>
          <Typography variant="h2">
            {t('ticket.details.labels.attachment')}
          </Typography>
          <Effect setEditMode={setEditMode} />
          {isEditMode ? (
            <ImagesUploader setEditMode={setEditMode} />
          ) : (
            <>
              <Gallery />
              <Button
                onClick={() => setEditMode(true)}
                sx={sxProps.button}
                variant="contained"
              >
                {t('ticket.details.buttons.uploadImages')}
              </Button>
            </>
          )}
        </>
      )}
    </AsyncContentProvider>
  );
};
