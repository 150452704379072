import Grid from '@mui/material/Grid';
import { Form as FormikForm, Formik } from 'formik';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import type {
  CreateUnitAdvertisementSerializerDTO,
  UnitAdvertisementSerializerDTO,
} from '../../../../../connectors/property';
import {
  Box,
  formatStandardDateWrite,
  handleSubmit as genericHandleSubmit,
  prepareInitialValues,
  prepareValidationSchema,
  sanitizeService,
  useLocalization,
  useTranslation,
} from '../../../../shared';
import { FormikEffect } from '../../../../shared/form/components';
import { advertisementClient } from '../../../advertisement.client';
import PATHS from '../../../paths.const';
import { AvailabilityMode } from '../../consts';
import { FormProvider, useAdvertisementConfig } from '../../providers';
import { DataEffect } from './DataEffect/data-effect.component';
import { StepperContent } from './StepperContent/stepper-content.component';
import { getFieldsConfig } from './form.const';
import { sxProps } from './form.styles';
import type { FormValues } from './form.types';

export const Form: FC = () => {
  const { t } = useTranslation();
  const { userCountries } = useLocalization();
  const navigate = useNavigate();
  const { dynamicValidationRules } = useAdvertisementConfig();
  const [globalError, setGlobalError] = useState<
    GenericTypes.TranslationLabel | undefined
  >(undefined);
  const dynamicFieldsConfig = useMemo(
    () => getFieldsConfig(userCountries, dynamicValidationRules),
    [userCountries, dynamicValidationRules],
  );
  const fieldsConfig = useMemo(
    () => getFieldsConfig(userCountries),
    [userCountries],
  );
  const initialValues = useMemo(
    () => prepareInitialValues(fieldsConfig),
    [fieldsConfig],
  );
  const validationSchema = useMemo(
    () => prepareValidationSchema(dynamicFieldsConfig),
    [dynamicFieldsConfig],
  );
  const handleSuccess = useCallback(
    ({ uuid }: UnitAdvertisementSerializerDTO) =>
      navigate(generatePath(PATHS.DETAILS, { id: uuid })),
    [navigate],
  );
  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (!values.unit?.value) return Promise.reject();
      const description = sanitizeService.cleanText(values.description);

      const advertisement: CreateUnitAdvertisementSerializerDTO = {
        additionalFees: values.feesPrice.toString() || '', // TODO: BE will change it to: {'value': int, 'currency': str}
        additionalFeesCurrency: 'PLN', // TODO: BE will provide it
        advertisementDescription: description,
        attachments: values.attachments
          .filter((attachment) => attachment.isSelected)
          .map((attachment, idx) => ({
            attachmentUuid: attachment.uuid,
            sequenceNumber: idx,
          })),
        availableDateFrom:
          values.availability === AvailabilityMode.CUSTOM_DATE &&
          values.availableDateFrom
            ? formatStandardDateWrite(values.availableDateFrom)
            : undefined,
        deposit: values.depositPrice.toString() || '', // TODO: BE will change it to: {'value': int, 'currency': str}
        depositCurrency: 'PLN', // TODO: BE will provide it
        integrations: values.integrations.map((integrationUuid) => ({
          integrationUuid,
        })),
        name: values.summary,
        rentPrice: values.rentPrice.toString() || '', // TODO: BE will change it to: {'value': int, 'currency': str}
        rentPriceCurrency: 'PLN', // TODO: BE will provide it
        unitUuid: values.unit.value,
        virtualTourUrl: values.virtualTourUrl || undefined,
      };

      try {
        const createdAdvertisement = await advertisementClient.create$(
          advertisement,
        );

        await advertisementClient.publish$(createdAdvertisement.uuid);

        return createdAdvertisement;
      } catch (e) {
        toast.error(t('errors.general.message'));

        throw e;
      }
    },
    [t],
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={genericHandleSubmit(
        setGlobalError,
        handleSuccess,
        handleSubmit,
      )}
      validateOnMount
      validationSchema={validationSchema}
    >
      {() => (
        <FormikForm noValidate>
          <FormikEffect fieldsConfig={fieldsConfig} />
          <Box sx={sxProps.box}>
            <Grid container spacing={3}>
              <FormProvider>
                <DataEffect />
                <StepperContent globalErrorKey={globalError} />
              </FormProvider>
            </Grid>
          </Box>
        </FormikForm>
      )}
    </Formik>
  );
};
