import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash-es/isEmpty';
import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';

import { useAsync } from '../../../providers';
import type { AsyncAutocompleteProps } from '../../fields';
import { FormField } from '../form-field.component';

type Props<TFormValues> = {
  fieldsConfig: NonNullable<
    AsyncAutocompleteProps<TFormValues>['nestedFieldsConfig']
  >;
};

export const AsyncAutocompleteNestedFields = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const { fieldsConfig } = props;
  const { values, setFieldValue } = useFormikContext<TFormValues>();
  const { response } = useAsync<never>();
  const { fields, getFormFieldsToResponseMap, spacing } = fieldsConfig;
  const formFieldsToResponseMap = useMemo(
    () => (response ? getFormFieldsToResponseMap(response) : {}),
    [getFormFieldsToResponseMap, response],
  );

  useEffect(() => {
    if (isEmpty(formFieldsToResponseMap)) return;

    Object.entries(formFieldsToResponseMap).forEach(([fieldName, value]) => {
      setFieldValue(fieldName, value);
    });
  }, [formFieldsToResponseMap, setFieldValue]);

  return (
    <Grid container marginTop="inherit" spacing={spacing}>
      {fields.map((config) => {
        const key = `async-autocomplete-nested-field-${config.field.name}`;

        return (
          <FormField<TFormValues> config={config} key={key} values={values} />
        );
      })}
    </Grid>
  );
};
