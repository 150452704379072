import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import type { FC } from 'react';
import React, { useState } from 'react';

import { sxProps } from './thumbnail.styles';

type Props = {
  name?: string;
  url: string;
  width?: number;
  height?: number;
};

export const Thumbnail: FC<Props> = (props) => {
  const { height = 100, name = '', url, width = 100 } = props;
  const [isLoaded, setLoaded] = useState(false);

  return (
    <Box sx={sxProps.box} height={height} width={width}>
      <img
        alt={name}
        onLoad={() => setLoaded(true)}
        src={url}
        style={{ display: isLoaded ? 'block' : 'none' }}
      />
      {!isLoaded && (
        <Skeleton variant="rectangular" width={width} height={height} />
      )}
    </Box>
  );
};
