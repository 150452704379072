import type { GridProps } from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import type { FC, DragEvent, TouchEvent } from 'react';
import React, { useCallback, useRef, useState } from 'react';

import { InvisibleDragImage, Scrollbar } from '../../../components';
import {
  findTouchDropColumnIndex,
  handleVerticalScroll,
  isDragEvent,
} from '../../../helpers';
import { useDebounce } from '../../../hooks';
import type { CustomFile, FileValue } from '../../fields';
import { FilePreview } from '../FilePreview/file-preview.component';
import { sxProps } from './files-preview.styles';

type Props = {
  files: FileValue;
  onUpdate: (files: FileValue) => void;
  onRemove?: (index: number) => void;
};

// TODO: Files preview needs to be paginated after: https://fredensborg.atlassian.net/browse/MYW-435
export const FilesPreview: FC<Props> = (props) => {
  const { files, onUpdate, onRemove } = props;
  const container = useRef<HTMLDivElement>(null);
  const scrollbar = useRef<HTMLElement | null>(null);
  const invisibleDragImage = useRef<HTMLImageElement>(null);
  const listItemRefs = useRef<(HTMLLIElement | null)[]>([]);
  const [currentDrag, setCurrentDrag] = useState<CustomFile | null>(null);
  const handleDrop = useCallback(
    (currentDrag: CustomFile, positionIndex: number) => {
      const filesWithoutCurrentDrag = files.filter(
        (file) => file.path !== currentDrag.path,
      );

      if (positionIndex === -1) {
        onUpdate([...filesWithoutCurrentDrag, currentDrag]);
      } else if (positionIndex === 0) {
        onUpdate([currentDrag, ...filesWithoutCurrentDrag]);
      } else {
        const left = filesWithoutCurrentDrag.slice(0, positionIndex - 1);
        const right = filesWithoutCurrentDrag.slice(positionIndex - 1);

        onUpdate([...left, currentDrag, ...right]);
      }
    },
    [files, onUpdate],
  );
  const handleScroll = useDebounce(handleVerticalScroll, 100, {
    maxWait: 100,
  });
  const onDragOver = useCallback(
    (event: DragEvent | TouchEvent) => {
      if (isDragEvent(event)) {
        event.preventDefault();
      }
      handleScroll(container.current, event, scrollbar.current);
    },
    [handleScroll],
  );
  const onDrop: GridProps['onDrop'] = useCallback(
    (event) => {
      setCurrentDrag(null);

      if (!currentDrag) return;

      const positionIndex = listItemRefs.current.findIndex(
        (listItem) =>
          listItem && listItem.getBoundingClientRect().y > event.pageY,
      );

      handleDrop(currentDrag, positionIndex);
    },
    [currentDrag, handleDrop],
  );
  const onTouchDrop = useCallback(
    (position: DOMRect) => {
      setCurrentDrag(null);

      if (!currentDrag) return;

      const columnIndex = findTouchDropColumnIndex(
        [container.current],
        position,
      );

      if (columnIndex > -1) {
        const positionIndex = listItemRefs.current.findIndex(
          (listItem) =>
            listItem && listItem.getBoundingClientRect().y > position.y,
        );

        handleDrop(currentDrag, positionIndex);
      }
    },
    [currentDrag, handleDrop],
  );

  return (
    <Grid container onDragOver={onDragOver} onDrop={onDrop} ref={container}>
      <InvisibleDragImage ref={invisibleDragImage} />
      <Grid item xs={12} sx={sxProps.column}>
        <Scrollbar
          ref={(element) => {
            scrollbar.current = element;
          }}
        >
          <List sx={sxProps.list}>
            {files.map((file, idx) => (
              <FilePreview
                container={container.current}
                currentDrag={currentDrag}
                dragImageElement={invisibleDragImage.current}
                file={file}
                files={files}
                index={idx}
                key={`file-${file.path}`}
                onUpdate={onUpdate}
                onTouchDrop={onTouchDrop}
                onTouchMoveOver={onDragOver}
                onRemove={onRemove}
                ref={(el) => {
                  listItemRefs.current[idx] = el;
                }}
                setCurrentDrag={setCurrentDrag}
              />
            ))}
          </List>
        </Scrollbar>
      </Grid>
    </Grid>
  );
};
