/* eslint-disable max-len */
import type { FormProviderValue } from '../../../providers';

const getDescription = (desc?: string): string =>
  desc && desc.trim().length > 0 ? `<br />${desc.trim()}` : '';

export const getInitialDescriptionValue = (
  property: NonNullable<FormProviderValue['property']>,
  propertyObject: NonNullable<FormProviderValue['propertyObject']>,
  unit: NonNullable<FormProviderValue['unit']>,
): string => {
  const propertyDescription = getDescription(property.description);
  const propertyData = `<p><strong>${property.name}</strong>${propertyDescription}</p>`;
  const propertyObjectDescription = getDescription(propertyObject.description);
  const propertyObjectData = `<p><strong>${propertyObject.name}</strong>${propertyObjectDescription}</p>`;
  const unitDescription = getDescription(unit.description);
  const unitData = `<p><strong>${unit.name}</strong>${unitDescription}</p>`;

  return `${propertyData}<p></p>${propertyObjectData}<p></p>${unitData}<p></p><p></p>`;
};
