import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  link: {
    '& > svg': {
      '& path': {
        color: 'primary.main',
      },
      mr: 1,
    },
    '&:hover': {
      '& > svg path': {
        color: 'primary.contrastText',
        transition: (theme) => theme.transitions.create('color'),
      },
      backgroundColor: 'primary.main',
      color: 'primary.contrastText',
    },
    backgroundColor: 'background.paper',
    borderRadius: 2,
    bottom: (theme) => [0, 0, theme.spacing(2)],
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    color: 'text.primary',
    padding: (theme) => theme.spacing(1, 2),
    position: 'absolute',
    right: (theme) => [0, 0, theme.spacing(2)],
    zIndex: 5,
  },
};
