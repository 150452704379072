import { ReactComponent as RealEstateDealKeyIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/deals/real-estate-deal-key.svg';
import { ReactComponent as RealEstateDealShakeIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/deals/real-estate-deal-shake.svg';
import MuiBox from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { getUnitAddress } from '../../../../+unit';
import type { ProspectDetailsReadOnlySerializerDTO } from '../../../../../connectors/property';
import {
  Box,
  Feature,
  FormattedDate,
  getFormattedPrice,
} from '../../../../shared';
import { ViewingScheduleButton } from '../ViewingScheduleButton/viewing-schedule-button.component';
import { sxProps } from './entity-box.styles';

type Props = {
  prospect: ProspectDetailsReadOnlySerializerDTO;
};

const THUMBNAIL_PLACEHOLDER_PATH = '/assets/thumbnail_unit_placeholder.svg';

export const EntityBox: FC<Props> = (props) => {
  const { prospect } = props;
  const name = useMemo(() => {
    const { addressLine, city, streetNumber } = prospect.unit;

    return getUnitAddress(addressLine, city, streetNumber);
  }, [prospect.unit]);
  const thumbnailPath = useMemo(
    () => prospect.unit.thumbnail || THUMBNAIL_PLACEHOLDER_PATH,
    [prospect.unit.thumbnail],
  );
  const price = useMemo(
    () =>
      getFormattedPrice(
        prospect.unit.rentPrice,
        prospect.unit.rentPriceCurrency,
      ),
    [prospect.unit.rentPrice, prospect.unit.rentPriceCurrency],
  );

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <MuiBox
              sx={
                {
                  ...sxProps.thumbnail,
                  backgroundImage: `url(${thumbnailPath})`,
                } as SxProps
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" marginBottom={0} variant="h2">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Grid container justifyContent="center">
                <MuiBox>
                  <Feature
                    dataTestId="rent-price"
                    icon={<RealEstateDealShakeIcon />}
                    titleKey="advertisement.feature.rentPrice"
                    value={price}
                  />
                </MuiBox>
              </Grid>
            </Grid>
            {prospect.unit.availableDateFrom && (
              <Grid item sm={6} xs={12}>
                <Grid container justifyContent="center">
                  <MuiBox>
                    <Feature
                      dataTestId="availability-date"
                      icon={<RealEstateDealKeyIcon />}
                      titleKey="advertisement.feature.availableFrom"
                      value={
                        <FormattedDate date={prospect.unit.availableDateFrom} />
                      }
                    />
                  </MuiBox>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <ViewingScheduleButton prospect={prospect} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
