import type { TOptions } from 'i18next';

import type { LanguageDTO } from '../../../../../connectors/company';
import { formatStandardDateTime } from '../../../../shared';

export const getScheduleCommentConfig = (
  date: Date,
  langCode: LanguageDTO,
  isRescheduled = false,
): { key: GenericTypes.TranslationLabel; options: TOptions } => {
  const options = { date: formatStandardDateTime(date, langCode) };

  return isRescheduled
    ? { key: 'prospect.entity.viewing.rescheduleConfirmed', options }
    : { key: 'prospect.entity.viewing.scheduleConfirmed', options };
};
