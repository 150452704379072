import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

const ILLUSTRATION_PATH = '/assets/illustration_1.svg';

export const sxProps: Record<
  'editButton' | 'formIllustration' | 'statusCell',
  SxProps<Theme>
> = {
  editButton: {
    mx: 1,
  },
  formIllustration: {
    backgroundImage: `url(${ILLUSTRATION_PATH})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '100%',
    minHeight: 280,
    width: '100%',
  },
  statusCell: {
    ml: 1,
  },
};
