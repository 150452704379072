import Box from '@mui/material/Box';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { Location, Props as MapsProps } from '../../../map.type';
import { getInfoWindowPosition } from '../../helpers';
import { sxProps } from './info-window.styles';

type Props = {
  id: Location['id'];
  children?: MapsProps['children'];
};

export const InfoWindow: FC<Props> = (props) => {
  const { children, id } = props;

  const positionSx = useMemo(() => {
    if (!id) return undefined;

    return getInfoWindowPosition();
  }, [id]);

  if (!children || !id) return null;

  return (
    <Box sx={{ ...sxProps.infoWindow, ...positionSx } as SxProps}>
      {children(id)}
    </Box>
  );
};
