import { useCallback, useMemo } from 'react';

import { isPermitted, Permission, useAuth } from '../../../+auth';
import { useApi } from '../../../shared';
import { getEntityContactsSuggestions$ } from '../helpers';
import type { Entity } from '../types';

export const useEntityContact = (entity: {
  id: Entity['uuid'];
  type: Entity['type'];
  parentId?: Entity['uuid'];
}): { isEntityContact: boolean; isFetching: boolean } => {
  const { permissions, user } = useAuth();
  const preventFetch = useMemo(
    () => isPermitted([Permission.GLOBAL_READ], permissions),
    [permissions],
  );
  const getContacts$ = useCallback(
    () =>
      preventFetch
        ? Promise.resolve([{ value: user?.uuid }])
        : getEntityContactsSuggestions$(
            entity.type,
            entity.id,
            entity.parentId,
          ),
    [preventFetch, user?.uuid, entity.type, entity.id, entity.parentId],
  );
  const { isFetching, response: contacts } = useApi([], getContacts$);
  const isEntityContact = useMemo(
    () => contacts.some((contact) => contact.value === user?.uuid),
    [contacts, user?.uuid],
  );

  return { isEntityContact, isFetching };
};
