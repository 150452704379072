import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    background: (theme) => theme.palette.background.paper,
    borderRadius: (theme) => theme.spacing(1),
    boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.15)`,
    height: 150,
    p: 0,
    width: '100%',
  },
  contentColumn: {
    width: 'calc(100% - 150px)',
  },
  contentRow: {
    overflow: 'hidden',
  },
  contentWrapper: {
    p: (theme) => theme.spacing(2, 2, 1, 2),
  },
  divider: {
    m: (theme) => theme.spacing(1, 0, 3, 0),
  },
  listItemPointerEvents: {
    cursor: 'pointer',
  },
  thumbnail: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: (theme) => theme.spacing(1, 0, 0, 1),
    boxShadow: 'none',
    height: 150,
    width: 150,
  },
};
