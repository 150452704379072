import { ReactComponent as SubtractSquareIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/subtract-square.svg';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC, Dispatch, SetStateAction } from 'react';
import React, { useCallback } from 'react';

import type { ResponseTemplateSerializerDTO } from '../../../../../connectors/ticket';
import { ConfirmationDelete } from '../../ConfirmationDelete/confirmation-delete.component';
import { sxProps } from './template.styles';

type Props = {
  pick: ResponseTemplateSerializerDTO | undefined;
  setPick: Dispatch<SetStateAction<ResponseTemplateSerializerDTO | undefined>>;
  template: ResponseTemplateSerializerDTO;
  removeTemplate$?: (
    id: ResponseTemplateSerializerDTO['uuid'],
  ) => Promise<void>;
};

export const Template: FC<Props> = (props) => {
  const { pick, setPick, removeTemplate$, template } = props;
  const handleRemove$ = useCallback(
    () => removeTemplate$?.(template.uuid) || Promise.reject(),
    [removeTemplate$, template.uuid],
  );

  return (
    <ListItem
      onClick={() => setPick(template)}
      sx={
        {
          ...sxProps.listItem,
          ...(pick?.uuid === template.uuid && sxProps.listItemSelected),
        } as SxProps
      }
    >
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={1}
        wrap="nowrap"
      >
        <Grid item>
          <Typography variant="body2">{template.content}</Typography>
        </Grid>
        {removeTemplate$ && (
          <Grid item>
            <ConfirmationDelete
              callback$={handleRemove$}
              Icon={<SubtractSquareIcon height={16} width={16} />}
            />
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};
