import React, { useCallback } from 'react';
import type { FC } from 'react';

import type {
  CoordinatesSerializerDTO,
  PropertyObjectSerializerDTO,
} from '../../../../../connectors/property';
import { ThumbnailInfoWindow } from '../../../../shared';
import { propertyObjectClient } from '../../../property-object.client';
import { THUMBNAIL_PLACEHOLDER_PATH } from '../../consts';
import { getAddress } from '../../helpers';
import { FeaturesList } from '../FeaturesList/features-list.component';

type Props = {
  id: string;
  navigateToDetails: (id: CoordinatesSerializerDTO['uuid']) => void;
};

export const InfoWindow: FC<Props> = (props) => {
  const { id, navigateToDetails } = props;
  const getData$ = useCallback(
    () => propertyObjectClient.getDetails$(id),
    [id],
  );

  return (
    <ThumbnailInfoWindow<PropertyObjectSerializerDTO>
      descriptionContent={({ addressLine, city, streetNumber }) =>
        getAddress(addressLine, city, streetNumber)
      }
      getData$={getData$}
      onItemClick={({ uuid }) => navigateToDetails(uuid)}
      thumbnailUrl={({ thumbnail }) => thumbnail || THUMBNAIL_PLACEHOLDER_PATH}
      titleContent={({ name }) => name}
    >
      {(item) => <FeaturesList data={item} />}
    </ThumbnailInfoWindow>
  );
};
