import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export enum BoxClassName {
  CONSTANT = 'boxConstant',
  CONSTANT_SMALL = 'boxConstantSmall',
  SMALL = 'boxSmall',
  SUCCEEDED = 'boxSucceeded',
}

export enum IconClassName {
  SMALL = 'iconSmall',
}

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    borderRadius: '50%',
    p: 0.375,
    [`&.${BoxClassName.CONSTANT}`]: {
      border: (theme) => `1px dashed ${theme.palette.secondary.main}`,
      height: 24,
      width: 24,
    },
    [`&.${BoxClassName.CONSTANT_SMALL}`]: {
      height: 16,
      width: 16,
    },
    [`&.${BoxClassName.SMALL}`]: {
      p: 0.125,
    },
    [`&.${BoxClassName.SUCCEEDED}`]: {
      backgroundColor: (theme) => theme.palette.success.main,
      border: (theme) => `1px solid ${theme.palette.success.main}`,
    },
  },
  icon: {
    alignItems: 'center',
    color: (theme) => theme.palette.primary.contrastText,
    display: 'flex',
    height: 16,
    justifyContent: 'center',
    width: 16,
    [`&.${IconClassName.SMALL}`]: {
      height: 12,
      width: 12,
    },
  },
};
