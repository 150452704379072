import { alpha } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  listItem: {
    cursor: 'pointer',
    p: 2,
  },
  listItemSelected: {
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
  },
};
