import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    '& img': {
      height: '100%',
      objectFit: 'cover',
      width: '100%',
    },
    cursor: 'pointer',
    position: 'relative',
  },
};
