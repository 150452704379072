import Grid from '@mui/material/Grid';
import type { FC, Key } from 'react';
import React, { Fragment, useMemo } from 'react';

import type { UniversalTicketSerializerDTO } from '../../../../../connectors/ticket';
import type { FeatureProps } from '../../../../shared';
import { Feature } from '../../../../shared';
import {
  getTicketTypeIcon,
  getTicketTypeTranslationLabelKey,
} from '../../consts';

type Props = {
  features: (FeatureProps & { key: Key })[];
  ticketType?: UniversalTicketSerializerDTO['ticketType'];
};

export const DetailsFeatureList: FC<Props> = (props) => {
  const { features, ticketType } = props;
  const finalFeatures = useMemo(() => {
    const TicketTypeIcon = ticketType
      ? getTicketTypeIcon(ticketType)
      : Fragment;

    return [
      ...(ticketType
        ? [
            {
              dataTestId: 'ticket-type',
              icon: <TicketTypeIcon />,
              key: 'ticketType',
              titleKey: 'ticket.fields.ticketType',
              valueKey: getTicketTypeTranslationLabelKey(ticketType),
            },
          ]
        : []),
      ...features,
    ];
  }, [features, ticketType]);

  return (
    <Grid direction="column" container spacing={2}>
      {finalFeatures.map((feature) => {
        const { key, ...rest } = feature;

        return (
          <Grid key={`details-feature-${key}`} item xs={12}>
            <Feature {...rest} textWrap />
          </Grid>
        );
      })}
    </Grid>
  );
};
