import { alpha } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  button: {
    backgroundColor: (theme) => theme.palette.grey[300],
    height: 24,
    ml: 1,
    px: 1,
    py: 0.25,
  },
  item: {
    '&:hover': {
      borderColor: (theme) => theme.palette.secondary.light,
    },
    border: (theme) => `2px solid ${alpha(theme.palette.secondary.main, 0.08)}`,
    borderRadius: 4,
    cursor: 'pointer',
    flexDirection: 'column',
    height: '100%',
    p: 3,
    textAlign: 'center',
    width: '100%',
  },
  itemSelected: {
    borderColor: (theme) => theme.palette.secondary.light,
  },
};
