import FormHelperText from '@mui/material/FormHelperText';
import type { FC } from 'react';
import React from 'react';

import { useTranslation } from '../../../translations';
import { FieldLabel } from '../field-label.component';
import { sxProps } from './switch-field-label.styles';

type Props = {
  helperTextKey?: GenericTypes.TranslationLabel;
  labelKey?: GenericTypes.TranslationLabel;
};

export const SwitchFieldLabel: FC<Props> = (props) => {
  const { helperTextKey, labelKey } = props;
  const { t } = useTranslation();

  return (
    <>
      <FieldLabel
        isShrink={false}
        labelKey={labelKey}
        labelSx={sxProps.label}
      />
      {helperTextKey && (
        <FormHelperText sx={sxProps.helperText}>
          {t(helperTextKey)}
        </FormHelperText>
      )}
    </>
  );
};
