import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.grey[500],
    borderRadius: 16,
    color: (theme) => theme.palette.primary.contrastText,
    display: 'flex',
    px: 2.5,
    py: 1,
  },
  right: {
    mr: 2,
  },
  text: {
    color: (theme) => theme.palette.primary.contrastText,
    fontWeight: (theme) => theme.typography.fontWeightBold,
  },
  textIcon: {
    mr: 1,
  },
  verified: {
    backgroundColor: (theme) => theme.palette.success.main,
  },
};
