import type { FC } from 'react';
import React from 'react';

import type { FilterPanelProps } from '../../types';
import { FilterPanel } from '../FilterPanel/filter-panel.component';
import { getFields } from './complaint-filter-panel.model';
import type { FilterValues } from './complaint-filter-panel.type';

type Props = FilterPanelProps<FilterValues>;

export const ComplaintFilterPanel: FC<Props> = (props) => (
  <FilterPanel getFieldsConfig={getFields} {...props} />
);
