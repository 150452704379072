import type { FieldConfig } from '../../../form';

export const mapFieldsConfig = <T>(
  fieldsConfig: FieldConfig<T>[],
  isFetching: boolean,
): FieldConfig<T>[] =>
  fieldsConfig.map((field) => ({
    ...field,
    props: { ...field.props, disabled: isFetching },
  })) as FieldConfig<T>[];
