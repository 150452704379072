import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { useTranslation } from '../../../translations';
import { ViewModeSwitch } from '../ViewModeSwitch/view-mode-switch.component';
import type {
  ListStateCommonProps,
  ViewModesCommonProps,
} from '../collection.type';
import { sxProps } from './summary.styles';

type Props<TFilterValues> = ListStateCommonProps<TFilterValues> &
  ViewModesCommonProps;

export const Summary = <TFilterValues,>(
  props: Props<TFilterValues>,
): ReturnType<FC<Props<TFilterValues>>> => {
  const { count, ...restProps } = props;
  const { t } = useTranslation();
  const isSwitchVisible = useMemo(() => Boolean(count && count > 0), [count]);

  return (
    <Grid alignItems="center" container spacing={4} sx={sxProps.row}>
      <Grid item xs={6}>
        {count !== undefined && (
          <Typography variant="h3">
            {t('collection.resultsCount', { count })}
          </Typography>
        )}
      </Grid>
      <Grid item justifyContent="flex-end" xs={6}>
        {isSwitchVisible && (
          <ViewModeSwitch<TFilterValues> count={count} {...restProps} />
        )}
      </Grid>
    </Grid>
  );
};
