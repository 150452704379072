import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import type { FC } from 'react';
import React from 'react';

import type { ListAttachmentSerializerDTO } from '../../../../../connectors/company';
import { sxProps } from './document-list.styles';

type Props = {
  documents: ListAttachmentSerializerDTO['results'];
  isFetchingDocuments?: boolean;
};

export const DocumentList: FC<Props> = (props) => {
  const { documents, isFetchingDocuments } = props;

  return (
    <Box>
      {isFetchingDocuments ? (
        <Skeleton />
      ) : (
        documents.map((document) => (
          <Link
            sx={sxProps.container}
            mr={1}
            href={document.url}
            key={document.uuid}
            target="_blank"
            variant="subtitle2"
          >
            {document.name}
          </Link>
        ))
      )}
    </Box>
  );
};
