import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { CSSProperties } from 'react';

export const SCROLLBAR_CSS_PROPERTIES: CSSProperties = {
  height: 'auto',
  maxHeight: 400,
};

export const sxProps: Record<string, SxProps<Theme>> = {
  messages: {
    maxHeight: 500,
    minHeight: 200,
    px: 2,
  },
  spinnerWrapper: {
    py: 2,
  },
};
