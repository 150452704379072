import MUIBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useCallback } from 'react';

import type { UnitAdvertisementSerializerDTO } from '../../../../../connectors/property';
import {
  Box,
  DoughnutChart,
  Spinner,
  useApi,
  useTranslation,
} from '../../../../shared';
import { prospectClient } from '../../../prospect.client';
import { getStatusColorsHex } from '../../helpers';
import { ChartWidgetLegend } from '../ChartWidgetLegend/chart-widget-legend.component';
import { sxProps } from './chart-widget-box.styles';

type Props = {
  id: UnitAdvertisementSerializerDTO['uuid'];
};

export const ChartWidgetBox: FC<Props> = (props) => {
  const { id } = props;
  const { t } = useTranslation();
  const getCounts$ = useCallback(() => prospectClient.getCounts$(id), [id]);
  const { isFetching, response } = useApi(undefined, getCounts$);

  if (isFetching) {
    return (
      <Box size="small">
        <Spinner />
      </Box>
    );
  }

  if (!response) return null;

  return (
    <Box size="small">
      <Typography marginBottom={1.5} variant="overline">
        {t('prospect.chart.title')}
      </Typography>
      <MUIBox sx={sxProps.container}>
        <DoughnutChart
          countObject={response}
          countLabelKey="prospect.chart.count"
          getStatusColorsHex={getStatusColorsHex}
          percentLabelKey="prospect.chart.percent"
        />
      </MUIBox>
      <ChartWidgetLegend countObject={response} />
    </Box>
  );
};
