import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../../../theme';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    alignItems: 'center',
    display: 'flex',
    pl: 1,
    pr: 1,
  },
  cell: {
    pl: 0,
    pr: 0,
  },
  cellInfo: {
    borderBottom: 'unset',
    width: '50%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'space-between',
  },
  icon: {
    border: `1px solid ${palette.heather}`,
    borderRadius: '50%',
    height: 56,
    mr: 1,
    p: 2,
    width: 56,
  },
  table: {
    mt: 2,
  },
};
