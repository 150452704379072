import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';

import {
  FormField,
  useLocalization,
  useTranslation,
} from '../../../../../shared';
import type { ServiceRequestValues } from '../../../types';
import type { StepComponentProps } from '../service-request-form.type';
import { getFieldsConfig } from './address-step.model';

export const AddressStep: FC<StepComponentProps> = (props) => {
  const { details, isPredefinedData } = props;
  const { userCountries } = useLocalization();
  const { setFieldValue, values } = useFormikContext<ServiceRequestValues>();
  const { t } = useTranslation();
  const fieldsConfig = useMemo(
    () => getFieldsConfig(userCountries, details, isPredefinedData),
    [details, userCountries, isPredefinedData],
  );

  useEffect(() => {
    if (details || values.country) return;

    setFieldValue('country', userCountries[0]?.value);
  }, [details, setFieldValue, userCountries, values.country]);

  return (
    <>
      <Typography variant="h5" marginBottom={0.5}>
        {t('ticket.fieldset.address.title')}
      </Typography>
      <Typography color="text.secondary" marginBottom={4} variant="body1">
        {t('ticket.fieldset.address.text')}
      </Typography>
      <Grid container spacing={3}>
        {fieldsConfig.map((config) => (
          <FormField<ServiceRequestValues>
            config={config}
            key={`fieldset-field-${config.field.name}`}
            values={values}
          />
        ))}
      </Grid>
    </>
  );
};
