import { ReactComponent as LayoutDashboardIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/dashboard/layout-dashboard.svg';
import { ReactComponent as ListBullets1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/lists/list-bullets-1.svg';
import { ReactComponent as MapsPin1Icon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin-1.svg';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { ViewMode } from '../collection.enum';
import type {
  ListStateCommonProps,
  ViewModesCommonProps,
} from '../collection.type';
import { sxProps } from './view-mode-switch.styles';

type Props<TFilterValues> = ListStateCommonProps<TFilterValues> &
  ViewModesCommonProps;

export const ViewModeSwitch = <TFilterValues,>(
  props: Props<TFilterValues>,
): ReturnType<FC<Props<TFilterValues>>> => {
  const { listState, setListState, viewModes } = props;
  const viewMode = useMemo(() => listState.viewMode, [listState.viewMode]);
  const getViewModeIcon = useCallback((mode: ViewMode) => {
    switch (mode) {
      case ViewMode.GRID:
        return <LayoutDashboardIcon height={24} width={24} />;
      case ViewMode.LIST:
        return <ListBullets1Icon height={24} width={24} />;
      case ViewMode.MAP:
        return <MapsPin1Icon height={24} width={24} />;
      default:
        return null;
    }
  }, []);
  const setViewMode = useCallback(
    (mode: ListStateCommonProps['listState']['viewMode']) => {
      setListState((prevState) => ({
        ...prevState,
        viewMode: mode,
      }));
    },
    [setListState],
  );

  return (
    <Grid container justifyContent="flex-end">
      {viewModes.map((mode) => (
        <Grid key={`view-mode-${mode}`} item>
          <IconButton
            color="inherit"
            onClick={() => setViewMode(mode)}
            sx={
              {
                ...sxProps.iconButton,
                ...(viewMode === mode && sxProps.iconButtonActive),
              } as SxProps
            }
          >
            {getViewModeIcon(mode)}
          </IconButton>
        </Grid>
      ))}
    </Grid>
  );
};
