import { ReactComponent as NotesChecklistFlipIcon } from '@heimstaden/icons-library/img/streamline-regular/content/notes/notes-checklist-flip.svg';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';

import { Box, useTranslation } from '../../../../shared';
import { sxProps } from './questionnaire-box.styles';
import { QuestionnaireContent } from './questionnaire-content.component';
import { QuestionnaireDummyData } from './questionnaire-dummy-data.const';
import { QuestionnaireEmptyMessage } from './questionnaire-empty-message.component';

export const QuestionnaireBox: FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={sxProps.box}>
      <Grid item sx={sxProps.header}>
        <NotesChecklistFlipIcon height={24} />
        <Typography sx={sxProps.title} variant="h2">
          {t('prospect.questionnaire.title')}
        </Typography>
      </Grid>
      {QuestionnaireDummyData.length > 0 ? (
        <QuestionnaireContent />
      ) : (
        <QuestionnaireEmptyMessage />
      )}
      <Grid />
    </Box>
  );
};
