import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import type { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';
import type { FieldProps as FormikFieldProps } from 'formik';
import isFunction from 'lodash-es/isFunction';
import React, { useMemo } from 'react';
import type { ChangeEvent, FC } from 'react';

import { FieldLabel, SwitchFieldLabel } from '../components';
import { getFirstErrorKey } from '../helpers';
import type { HtmlDataAttribute, SwitchProps, SwitchValue } from './types';

type Props<TFormValues> = FormikFieldProps<SwitchValue, TFormValues> & {
  props: SwitchProps<TFormValues>;
};

export const SwitchField = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const { meta, form, field, props: fieldProps } = props;
  const { touched, error, value } = meta;
  const { setFieldValue, values } = form;
  const { name, onBlur } = field;
  const {
    disabled,
    disableWhen,
    fieldLabelKey,
    fieldSx,
    formControlSx,
    helperTextKey,
    labelKey,
    readonly,
    required,
    requiredWhen,
  } = fieldProps;
  const finalLabelKey = useMemo(
    () => (isFunction(labelKey) ? labelKey(values) : labelKey),
    [labelKey, values],
  );
  const isDisabled = useMemo(
    () => Boolean(disabled || readonly || (disableWhen && disableWhen(values))),
    [disabled, readonly, disableWhen, values],
  );
  const errorMessageKey = useMemo(
    () => touched && getFirstErrorKey(error),
    [error, touched],
  );
  const isError = useMemo(() => Boolean(errorMessageKey), [errorMessageKey]);
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );
  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    setFieldValue(name, checked);
  };

  return (
    <FormControl
      component="fieldset"
      disabled={isDisabled}
      required={isRequired && !readonly}
      error={isError}
      sx={formControlSx}
    >
      {fieldLabelKey && (
        <FieldLabel isShrink={false} labelKey={fieldLabelKey} />
      )}
      <FormControlLabel
        control={
          <Switch
            checked={value}
            inputProps={
              { 'data-testid': name } as HtmlDataAttribute<
                MuiSwitchProps['inputProps']
              >
            }
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
            sx={{ m: 1, ...fieldSx }}
          />
        }
        label={
          <SwitchFieldLabel
            helperTextKey={helperTextKey}
            labelKey={finalLabelKey}
          />
        }
      />
      {isError && <FormHelperText error>{errorMessageKey}</FormHelperText>}
    </FormControl>
  );
};
