import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  headerMenu: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2,
  },
  notificationButton: {
    ':disabled': {
      color: 'text.secondary',
    },
    opacity: '0.2',
  },
};
