import type { PaletteMode } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';

import { palette } from '../../../theme';
import type { ColorsHex } from '../../../theme';
import type { LegendEntry } from './doughnut-chart.type';

export const TOTAL_BACKGROUND_COLOR = palette.black;
export const TOTAL_LEGEND_ENTRY: LegendEntry = {
  backgroundColor: TOTAL_BACKGROUND_COLOR,
  key: `legend-item-total`,
  labelKey: 'chart.legend.total',
  status: 'total',
};

export const getLegendEntries = <T>(
  countObject: Record<string | 'total', number>,
  getStatusColorsHex: (paletteMode: PaletteMode, status: T) => ColorsHex,
  getTranslationLabelKey: (status: T) => GenericTypes.TranslationLabel,
  mode: Theme['palette']['mode'],
): LegendEntry[] => {
  const sortedDescendingKeys = Object.entries(countObject)
    .sort(([, prevValue], [, nextValue]) => (prevValue >= nextValue ? -1 : 0))
    .map(([key]) => key);

  return sortedDescendingKeys.map((key) => {
    if (key === 'total') return TOTAL_LEGEND_ENTRY;

    const status = key as unknown as T;

    return {
      ...getStatusColorsHex(mode, status),
      key: `legend-item-${status}`,
      labelKey: getTranslationLabelKey(status),
      status,
    };
  });
};
