import React from 'react';
import { Outlet } from 'react-router-dom';

import { componentLazyImport } from '../routing/routes.helper';

const Details = componentLazyImport(
  () => import('./Details/details.component'),
  'DETAILS',
);
const List = componentLazyImport(
  () => import('./List/list.component'),
  'TERMINATION_LIST',
);
const NotFound = componentLazyImport(
  () => import('../shared/errors/NotFound/not-found.component'),
  'TERMINATION_LIST_NOT_FOUND',
);

const routes = {
  children: [
    { element: <List />, path: '' },
    { element: <Details />, path: ':id' },
    { element: <NotFound />, path: '*' },
  ],
  element: <Outlet />,
  path: 'termination',
};

export default routes;
