import { ProspectStatusEnumDTO } from '../../../../../connectors/property';
import type { Step } from './status-stepper.type';

export const steps: Step[] = [
  {
    awaitingLabelKey: 'prospect.stepperLabel.confirmation.awaiting',
    confirmedLabelKey: 'prospect.stepperLabel.confirmation.confirmed',
    defaultLabelKey: 'prospect.stepperLabel.confirmation.default',
    isAwaiting: [ProspectStatusEnumDTO.ProspectRegistered],
    isConfirmed: [
      ProspectStatusEnumDTO.ProspectCancelled,
      ProspectStatusEnumDTO.ProspectConfirmedByUser,
      ProspectStatusEnumDTO.ProspectRejected,
      ProspectStatusEnumDTO.Viewed,
      ProspectStatusEnumDTO.ViewingCanceled,
      ProspectStatusEnumDTO.ViewingDateConfirmed,
      ProspectStatusEnumDTO.ViewingFailed,
      ProspectStatusEnumDTO.ViewingRequested,
    ],
    key: 'prospect-confirmation-step',
  },
  // TODO: It is not a part of the MVP - implement later
  // {
  //   awaitingLabelKey: 'prospect.stepperLabel.selfAssessment.awaiting',
  //   confirmedLabelKey: 'prospect.stepperLabel.selfAssessment.confirmed',
  //   defaultLabelKey: 'prospect.stepperLabel.selfAssessment.default',
  //   isAwaiting: [ProspectStatusEnumDTO.ProspectConfirmedByUser],
  //   isConfirmed: [ProspectStatusEnumDTO.ProspectConfirmedByUser],
  //   key: 'self-assessment-step',
  // },
  {
    awaitingLabelKey: 'prospect.stepperLabel.viewing.awaiting',
    confirmedLabelKey: 'prospect.stepperLabel.viewing.confirmed',
    defaultLabelKey: 'prospect.stepperLabel.viewing.default',
    isAwaiting: [ProspectStatusEnumDTO.ViewingRequested],
    isConfirmed: [
      ProspectStatusEnumDTO.Viewed,
      ProspectStatusEnumDTO.ViewingDateConfirmed,
    ],
    key: 'viewing-step',
  },
  // TODO: It is not a part of the MVP - implement later
  // {
  //   awaitingLabelKey: 'prospect.stepperLabel.verification.awaiting',
  //   confirmedLabelKey: 'prospect.stepperLabel.verification.confirmed',
  //   defaultLabelKey: 'prospect.stepperLabel.verification.default',
  //   isAwaiting: [
  //     ProspectStatusEnumDTO.VerificationInProgress,
  //     ProspectStatusEnumDTO.ViewingConfirmed,
  //   ],
  //   isConfirmed: [
  //     ProspectStatusEnumDTO.AwaitingContractSignature,
  //     ProspectStatusEnumDTO.VerificationSucceeded,
  //   ],
  //   key: 'verification-step',
  // },
  // {
  //   awaitingLabelKey: '',
  //   component: ContractStep,
  //   confirmedLabelKey: 'prospect.stepperLabel.contract.confirmed',
  //   defaultLabelKey: 'prospect.stepperLabel.contract.default',
  //   isAwaiting: [ProspectStatusEnumDTO.AwaitingContractSignature],
  //   isConfirmed: [ProspectStatusEnumDTO.Confirmed],
  //   isVisible: [
  //     ProspectStatusEnumDTO.AwaitingContractSignature,
  //     ProspectStatusEnumDTO.VerificationSucceeded,
  //   ],
  //   key: 'contract-step',
  // },
];
