import { ReactComponent as MessagesBubbleStarIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-star.svg';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link as RouterLink } from 'react-router-dom';

import { useAuth } from '../../../../+auth';
import type { ListAttachmentSerializerDTO } from '../../../../../connectors/company';
import type {
  CommentSerializerDTO,
  CreateReponseTemplateSerializerDTO,
  ResponseTemplateSerializerDTO,
} from '../../../../../connectors/ticket';
import {
  AttachmentCategoryEnumDTO,
  CommentVisibilityEnumDTO,
  ResponseTemplateVisibilityEnumDTO,
} from '../../../../../connectors/ticket';
import { getCommentDisplayConfig, getUserName } from '../../../helpers';
import { useTranslation } from '../../../translations';
import type { Image } from '../../Carousel/carousel.type';
import { Dialog } from '../../Dialog/dialog.component';
import { FormattedDate } from '../../FormattedDate/formatted-date.component';
import {
  getAttachmentsByCategory,
  getGalleryPreviewImages,
} from '../../GalleryPreview';
import { CommentGallery } from '../CommentGallery/comment-gallery.component';
import { DocumentList } from '../DocumentList/document-list.component';
import { sxProps } from './comment.styles';

type Props = CommentSerializerDTO & {
  addResponseTemplate$?: (
    template: CreateReponseTemplateSerializerDTO,
  ) => Promise<ResponseTemplateSerializerDTO>;
  isFetchingImages?: boolean;
  isFetchingDocuments?: boolean;
};

export const Comment: FC<Props> = (props) => {
  const {
    addResponseTemplate$,
    attachments,
    author,
    content,
    createdAt,
    isFetchingImages,
    isFetchingDocuments,
    taggedUsers,
    uuid,
    visibility,
  } = props;
  const { langCode, t } = useTranslation();
  const { user } = useAuth();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const isAuthor = useMemo(() => author.uuid === user?.uuid, [author, user]);
  const avatarSxProps = useMemo(
    () => ({ ...sxProps.avatar, ...(isAuthor && sxProps.authorAvatar) }),
    [isAuthor],
  );
  const commentSxProps = useMemo(
    () =>
      ({
        ...sxProps.comment,
        ...(isAuthor && sxProps.authorComment),
      } as SxProps),
    [isAuthor],
  );
  const contentSxProps = useMemo(
    () =>
      ({
        ...sxProps.content,
        ...(visibility === CommentVisibilityEnumDTO.Internal &&
          sxProps.internalContent),
        ...(visibility !== CommentVisibilityEnumDTO.Internal &&
          isAuthor &&
          sxProps.authorContent),
      } as SxProps),
    [isAuthor, visibility],
  );
  const dateSxProps = useMemo(
    () => ({ ...sxProps.date, ...(isAuthor && sxProps.authorDate) } as SxProps),
    [isAuthor],
  );
  const name = useMemo(
    () => (isAuthor ? t('comment.me') : getUserName(author)),
    [author, isAuthor, t],
  );
  const displayConfig = useMemo(
    () => getCommentDisplayConfig(content, taggedUsers),
    [content, taggedUsers],
  );
  const createTemplate = useCallback(async () => {
    if (!addResponseTemplate$) return;

    setDialogOpen(false);
    try {
      await addResponseTemplate$({
        content,
        language: langCode,
        name: 'template', // TODO: consider how to set template name
        visibility: ResponseTemplateVisibilityEnumDTO.Country, // TODO: consider how to pick visibility type
      });
      toast.success(t('comment.template.success'));
    } catch (e) {
      toast.error(t('comment.errors.template'));
    }
  }, [addResponseTemplate$, content, langCode, t]);

  const galleryImages: Image[] = useMemo(
    () => (attachments ? getGalleryPreviewImages(attachments) : []),
    [attachments],
  );
  const commentDocuments: ListAttachmentSerializerDTO['results'] = useMemo(
    () =>
      attachments
        ? getAttachmentsByCategory(
            attachments,
            AttachmentCategoryEnumDTO.Document,
          )
        : [],
    [attachments],
  );

  return (
    <Box sx={commentSxProps}>
      <Avatar src={author.profilePicture} sx={avatarSxProps} />
      <Box flexShrink={1} flexGrow={1}>
        <Box sx={contentSxProps}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={1}
                wrap="nowrap"
              >
                <Grid item>
                  <Typography variant="subtitle2">{name}</Typography>
                </Grid>
                {addResponseTemplate$ && isAuthor && (
                  <Grid item>
                    <IconButton
                      sx={sxProps.actionIconButton}
                      onClick={() => setDialogOpen(true)}
                    >
                      <MessagesBubbleStarIcon height={16} width={16} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={sxProps.text} variant="body2">
                {displayConfig.map((phrase) => (
                  <Fragment key={`comment-${uuid}-${phrase.id}`}>
                    {phrase.mention && phrase.path ? (
                      <Link component={RouterLink} to={phrase.path}>
                        {phrase.content}
                      </Link>
                    ) : (
                      phrase.content
                    )}
                  </Fragment>
                ))}
              </Typography>
            </Grid>
            {galleryImages && (
              <Grid item xs={12}>
                <CommentGallery
                  images={galleryImages}
                  isFetchingImages={isFetchingImages}
                />
              </Grid>
            )}
            {commentDocuments && (
              <Grid item xs={12}>
                <DocumentList
                  documents={commentDocuments}
                  isFetchingDocuments={isFetchingDocuments}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Typography color="text.secondary" sx={dateSxProps} variant="caption">
          <FormattedDate date={createdAt} format="distance" />
        </Typography>
      </Box>
      <Dialog
        confirmButtonTitleKey="comment.template.add"
        isOpen={isDialogOpen}
        maxWidth="md"
        onClose={() => setDialogOpen(false)}
        onConfirm={createTemplate}
        titleComponent={
          <Grid alignItems="center" container spacing={1} wrap="nowrap">
            <Grid item>
              <Icon color="primary">
                <MessagesBubbleStarIcon height={24} width={24} />
              </Icon>
            </Grid>
            <Grid item>
              <Typography color="text.secondary" marginBottom={0} variant="h2">
                {t('comment.template.title')}
              </Typography>
            </Grid>
          </Grid>
        }
      >
        <Grid container sx={sxProps.dialogContent}>
          <Grid
            item
            sx={
              {
                ...sxProps.dialogContentRow,
                ...sxProps.templatePreview,
              } as SxProps
            }
            xs={12}
          >
            <Box>
              <Typography variant="h5">{content}</Typography>
            </Box>
          </Grid>
          <Grid item sx={sxProps.dialogContentRow} xs={12}>
            <Typography variant="h6">
              {t('comment.template.confirm')}
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};
