import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  header: {
    '& > svg': {
      color: (theme) => theme.palette.grey[500],
    },
    alignItems: 'center',
    backgroundColor: 'background.paper',
    borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 2,
    color: 'text.primary',
    display: 'flex',
    flexWrap: 'nowrap',
    left: 0,
    p: 3,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
  map: {
    height: '100%',
    minHeight: 300,
    position: 'relative',
  },
  text: {
    ml: 1,
  },
};
