import type { FC } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  DEFAULT_MAP_CONFIG,
  MapView,
  DEFAULT_CENTER_POSITION,
  getRoundedCoordinate,
} from '../../../components';
import type { LocationInfoData, LocationValue } from '../../fields';
import { LocationInfoBox } from '../LocationInfoBox/location-info-box.component';
import { LocationSearchBox } from '../LocationSearchBox/location-search-box.component';
import { LocationTipBox } from '../LocationTipBox/location-tip-box.component';

type Props = {
  disabled: boolean;
  infoData: LocationInfoData;
  onPositionChange: (position: LocationValue) => void;
  pinUrl: GenericTypes.URL;
  position: LocationValue;
  required: boolean;
  errorMessageKey?: GenericTypes.TranslationLabel;
  placeholderKey?: GenericTypes.TranslationLabel;
  tipKey?: GenericTypes.TranslationLabel;
};

export const LocationContent: FC<Props> = (props) => {
  const {
    disabled,
    errorMessageKey,
    infoData,
    onPositionChange,
    pinUrl,
    placeholderKey,
    position,
    required,
    tipKey,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<{
    latLng: google.maps.LatLng;
    placeId?: string;
  }>({
    latLng: new google.maps.LatLng(
      position
        ? { lat: position.lat, lng: position.lng }
        : DEFAULT_CENTER_POSITION,
    ),
    placeId: position?.placeId,
  });
  const [value, setValue] =
    useState<google.maps.places.AutocompletePrediction | null>(null);
  const [isRefRendered, setRefRendered] = useState(false);
  const map = useMemo(() => {
    if (!isRefRendered || !ref.current) return null;

    return new google.maps.Map(ref.current, {
      center: data.latLng,
      ...DEFAULT_MAP_CONFIG,
    });
  }, [data.latLng, isRefRendered]);
  const marker = useMemo(() => {
    if (!map) return null;

    return new google.maps.Marker({
      icon: {
        scaledSize: new google.maps.Size(42, 60),
        url: pinUrl,
      },
      map,
      position: data.latLng,
    });
  }, [data.latLng, map, pinUrl]);
  const geocoder = useMemo(() => new google.maps.Geocoder(), []);
  const handleMarkerDragend = useCallback(
    async (event: google.maps.MapMouseEvent) => {
      if (!event.latLng || !marker) return;

      const latLng = new google.maps.LatLng(
        event.latLng.lat(),
        event.latLng.lng(),
      );
      let placeId;

      await geocoder.geocode({ location: position }, (results) => {
        if (!results) return;

        const place = results.find((result) =>
          result.types.includes('street_address'),
        );

        placeId = place?.place_id;
      });

      setData({ latLng, placeId });
      marker.setPosition(latLng);
    },
    [geocoder, marker, position],
  );

  useEffect(() => {
    setRefRendered(true);
  }, []);

  useEffect(() => {
    if (!marker) return undefined;

    const listeners = ['dragend'].map((event) =>
      google.maps.event.addListener(marker, event, handleMarkerDragend),
    );

    return (): void => {
      listeners.forEach((listener) =>
        google.maps.event.removeListener(listener),
      );
    };
  }, [handleMarkerDragend, marker]);

  useEffect(() => {
    if (!data.latLng || !map) return;

    const bounds = new google.maps.LatLngBounds();

    bounds.extend(data.latLng);
    map.fitBounds(bounds);
  }, [data.latLng, map]);

  useEffect(() => {
    if (!map || !marker) return;

    map.setOptions({
      gestureHandling: disabled ? 'none' : DEFAULT_MAP_CONFIG.gestureHandling,
    });
    marker.setDraggable(!disabled);
  }, [disabled, map, marker]);

  useEffect(() => {
    onPositionChange({
      lat: getRoundedCoordinate(data.latLng.lat()),
      lng: getRoundedCoordinate(data.latLng.lng()),
      ...(data.placeId ? { placeId: data.placeId } : []),
    });
  }, [data, onPositionChange]);

  return (
    <>
      {map && (
        <>
          <LocationSearchBox
            disabled={disabled}
            errorMessageKey={errorMessageKey}
            formInput={infoData.address}
            map={map}
            placeholderKey={placeholderKey}
            prediction={value}
            required={required}
            setData={setData}
            setPrediction={setValue}
          />
          <LocationInfoBox infoData={infoData} latLng={data.latLng} />
          {tipKey && <LocationTipBox textKey={tipKey} />}
        </>
      )}
      <MapView ref={ref} />
    </>
  );
};
