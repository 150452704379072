import Badge from '@mui/material/Badge';
import ListItem from '@mui/material/ListItem';
import type { FC, Key } from 'react';
import React, { useEffect, useMemo } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { useTranslation } from '../../translations';
import {
  areExactSearchParams,
  getPathSearchIndex,
  isExactPath,
} from './item.helper';
import { ACTIVE_CLASS_NAME, sxProps } from './item.styles';

export type Props = {
  dataTestId: string;
  handleInitialActiveState: (isActive: boolean) => void;
  titleKey: GenericTypes.TranslationLabel;
  count?: number;
  key?: Key;
  onClick?: () => void;
  path?: string;
};

export const Item: FC<Props> = (props) => {
  const {
    count,
    dataTestId,
    handleInitialActiveState,
    onClick,
    path,
    titleKey,
  } = props;
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // TODO: Think about adding the search parameters to the routing declaration
  const isActive = useMemo(() => {
    const pathSearchIndex = getPathSearchIndex(path);
    const simplePath = pathSearchIndex
      ? path?.substring(0, pathSearchIndex)
      : path;

    return (
      isExactPath(pathname, simplePath) &&
      areExactSearchParams(searchParams, path)
    );
  }, [path, pathname, searchParams]);
  const className = useMemo(
    () => (isActive ? ACTIVE_CLASS_NAME : ''),
    [isActive],
  );

  useEffect(() => {
    if (isActive) {
      handleInitialActiveState(isActive);
    }
  }, [handleInitialActiveState, isActive]);
  const text = useMemo(() => t(titleKey), [t, titleKey]);

  return (
    <Badge
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      badgeContent={count}
      color="primary"
      sx={sxProps.badge}
    >
      <ListItem
        className={className}
        data-testid={dataTestId}
        disableGutters
        sx={sxProps.item}
        {...(onClick ? { onClick } : { component: Link, to: path })}
      >
        {text}
      </ListItem>
    </Badge>
  );
};
