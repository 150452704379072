import { getEnvValue } from '../../../../helpers';

const MAP_ID = getEnvValue('GOOGLE_MAP_ID');

export const CUSTOM_INFO_WINDOW_SPACING = 16;
export const DEFAULT_CENTER_POSITION = { lat: 59.9138688, lng: 10.7522454 }; // Norway, Oslo
export const DEFAULT_MAP_CONFIG = {
  disableDefaultUI: true,
  gestureHandling: 'cooperative',
  mapId: MAP_ID, // styles
  mapTypeId: 'roadmap',
  maxZoom: 17,
  minZoom: 3,
  streetViewControl: false,
  zoom: 12,
};
export const INFO_WINDOW_HEIGHT = 150;
export const INFO_WINDOW_QUERY_SELECTOR = '.gm-style-iw';
export const MAP_QUERY_SELECTOR = '.gm-style';
export const MARKER_SIZE = 24;
export const MAX_COORDINATES_DIGITS = 10000000000; // 12 digits
export const MULTIPLE_MAP_CONFIG = {
  ...DEFAULT_MAP_CONFIG,
  zoomControl: true,
};
export const NATIVE_INFO_WINDOW_SPACING = 12;
export const READONLY_MAP_CONFIG = {
  ...DEFAULT_MAP_CONFIG,
  draggable: false,
  scaleControl: false,
  scrollwheel: false,
  zoom: 10,
  zoomControl: false,
};
