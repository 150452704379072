import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

const ILLUSTRATION_PATH = '/assets/illustration_4.svg';

export const sxProps: Record<'formIllustration' | 'icon', SxProps<Theme>> = {
  formIllustration: {
    backgroundImage: `url(${ILLUSTRATION_PATH})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '100%',
    width: '100%',
  },
  icon: {
    mr: 1,
  },
};
