import React from 'react';
import { Outlet } from 'react-router-dom';

import { componentLazyImport } from '../routing/routes.helper';
import { FeatureAvailableGuard } from '../shared/guards/FeatureAvailable/feature-available.guard';
import { Country } from '../shared/providers/Country/enum';
import { ConfigProvider } from './shared';

const Details = componentLazyImport(
  () => import('./Details/details.component'),
  'PROSPECT_DETAILS',
);
const NotFound = componentLazyImport(
  () => import('../shared/errors/NotFound/not-found.component'),
  'PROSPECT_NOT_FOUND',
);

const routes = {
  children: [
    { element: <Details />, path: ':id' },
    { element: <NotFound />, path: '*' },
  ],
  element: (
    <FeatureAvailableGuard countries={[Country.PL]}>
      <ConfigProvider>
        <Outlet />
      </ConfigProvider>
    </FeatureAvailableGuard>
  ),
  path: 'prospects',
};

export default routes;
