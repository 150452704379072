import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export enum CheckboxClassName {
  FILTERED_FIELD = 'filterField',
  PICTORIAL_CONTROL = 'pictorialControl',
}

export enum FormControlLabelClassName {
  PICTORIAL = 'pictorial',
  PICTORIAL_CHECKED = 'pictorialChecked',
}

export const sxProps: Record<string, SxProps<Theme>> = {
  checkbox: {
    marginLeft: -1.125, // Align to the left
    [`&.${CheckboxClassName.PICTORIAL_CONTROL}`]: {
      mr: 2,
      padding: 0,
    },
    [`&.${CheckboxClassName.FILTERED_FIELD}`]: {
      py: 0,
    },
  },
  formControlLabel: {
    [`&.${FormControlLabelClassName.PICTORIAL}`]: {
      '&:hover': {
        borderColor: (theme) => theme.palette.secondary.light,
      },
      border: '2px solid',
      borderColor: (theme) => theme.palette.grey[300],
      borderRadius: 4,
      display: 'flex',
      p: 3,
    },
    [`&.${FormControlLabelClassName.PICTORIAL_CHECKED}`]: {
      borderColor: (theme) => theme.palette.secondary.light,
    },
    ml: 0,
  },
};
