import Box from '@mui/material/Box';
import type { GridProps } from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { DragEvent, FC, TouchEvent } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';

import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../../../connectors/ticket';
import { TicketStatusEnumDTO } from '../../../../../connectors/ticket';
import {
  CustomErrorType,
  findTouchDropColumnIndex,
  handleHorizontalScroll,
  InvisibleDragImage,
  Scrollbar,
  useDebounce,
  useLocalization,
  useTranslation,
} from '../../../../shared';
import {
  DAYS_NUMBER_TO_FILTER_DONE_TICKET,
  getStatusTranslationLabelKey,
} from '../../consts';
import { useTicketConfig, useTransition } from '../../providers';
import { DraggableCard } from './DraggableCard/draggable-card.component';
import { getColumnsData, getUnavailableStatuses } from './kanban.helper';
import { sxProps } from './kanban.styles';
import type { DragObject } from './kanban.type';

type Props = {
  model: UniversalTicketsWithAdditionalFieldsSerializerDTO[];
};

const COLUMN_SPACING = 3;

export const Kanban: FC<Props> = (props) => {
  const { model } = props;
  const { userCountries } = useLocalization();
  const { getCountryConfig } = useTicketConfig();
  // TODO: Display a select field to define a country if a user has more
  const { statusesWorkflowOptions, statusOptions } = useMemo(
    () => getCountryConfig(userCountries[0]?.value),
    [getCountryConfig, userCountries],
  );
  const { t } = useTranslation();
  const { changeStatus$ } = useTransition();
  const columnRefs = useRef<(HTMLDivElement | null)[]>([]);
  const container = useRef<HTMLDivElement>(null);
  const invisibleDragImage = useRef<HTMLImageElement>(null);
  const scrollbar = useRef<HTMLElement | null>(null);
  const [currentDrag, setCurrentDrag] = useState<DragObject | null>(null);
  const unavailableStatuses = useMemo(
    () =>
      getUnavailableStatuses(
        currentDrag,
        statusesWorkflowOptions,
        statusOptions,
      ),
    [currentDrag, statusOptions, statusesWorkflowOptions],
  );
  const [tickets, setTickets] = useState<Props['model']>([]);
  const columns = useMemo(
    () => getColumnsData(statusOptions, tickets),
    [statusOptions, tickets],
  );
  const columnWidth = useMemo(
    () => `calc(100% / ${columns.length})`,
    [columns.length],
  );
  const isColumnUnavailable = useCallback(
    (status: TicketStatusEnumDTO) => unavailableStatuses.includes(status),
    [unavailableStatuses],
  );
  const updateTicketStatus$ = useCallback(
    async (
      id: UniversalTicketsWithAdditionalFieldsSerializerDTO['uuid'],
      initialStatus: TicketStatusEnumDTO,
      status: TicketStatusEnumDTO,
    ) => {
      setTickets((prevState) =>
        prevState.map((task) =>
          task.uuid === id ? { ...task, status } : task,
        ),
      );
      try {
        await changeStatus$([id], status);
      } catch (e) {
        setTickets((prevState) =>
          prevState.map((task) =>
            task.uuid === id ? { ...task, status: initialStatus } : task,
          ),
        );

        if (e.message === CustomErrorType.ACTION_CANCELED) return;

        toast.error(t('errors.general.message'));
      }
    },
    [changeStatus$, t],
  );
  const handleScroll = useDebounce(handleHorizontalScroll, 100, {
    maxWait: 100,
  });
  const onDragOverContainer = useCallback(
    (event: DragEvent | TouchEvent) => {
      handleScroll(container.current, event, scrollbar.current);
    },
    [handleScroll],
  );
  const unavailableErrorMessage = useCallback(
    (
      finalStatusKey: TicketStatusEnumDTO,
      initialStatusKey: TicketStatusEnumDTO,
    ) => {
      toast.error(
        t('ticket.errors.unavailableStatus', {
          finalStatus: t(getStatusTranslationLabelKey(finalStatusKey)),
          initialStatus: t(getStatusTranslationLabelKey(initialStatusKey)),
        }),
      );
    },
    [t],
  );
  const onDragOverColumn: GridProps['onDragOver'] = useCallback((event) => {
    event.preventDefault();
  }, []);
  const handleUpdatingStatus = useCallback(
    (currentDrag: DragObject, status: TicketStatusEnumDTO) => {
      const { id, status: initialStatus } = currentDrag;
      const initialTask = tickets.find(({ uuid }) => id === uuid);

      if (status === initialStatus || !initialTask) return;

      if (isColumnUnavailable(status)) {
        unavailableErrorMessage(status, currentDrag.status);

        return;
      }

      updateTicketStatus$(
        id,
        initialTask.status || TicketStatusEnumDTO.Open,
        status,
      );
    },
    [
      isColumnUnavailable,
      tickets,
      unavailableErrorMessage,
      updateTicketStatus$,
    ],
  );
  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>, status: TicketStatusEnumDTO) => {
      setCurrentDrag(null);

      if (!currentDrag) return;

      handleUpdatingStatus(currentDrag, status);
    },
    [currentDrag, handleUpdatingStatus],
  );
  const onTouchDrop = useCallback(
    (position: DOMRect) => {
      setCurrentDrag(null);

      if (!currentDrag) return;

      const columnIndex = findTouchDropColumnIndex(
        columnRefs.current,
        position,
      );

      if (columnIndex > -1) {
        const { status } = columns[columnIndex];

        handleUpdatingStatus(currentDrag, status);
      }
    },
    [columns, currentDrag, handleUpdatingStatus],
  );

  useEffect(() => {
    setTickets([...model]);
  }, [model]);

  return (
    <Grid container onDragOver={onDragOverContainer} ref={container}>
      <InvisibleDragImage ref={invisibleDragImage} />
      <Grid item xs={12}>
        <Scrollbar
          ref={(element) => {
            scrollbar.current = element;
          }}
        >
          <Grid
            container
            spacing={COLUMN_SPACING}
            sx={sxProps.container}
            wrap="nowrap"
          >
            {columns.map((column, idx) => (
              <Grid
                key={`column-${column.status}`}
                item
                sx={{
                  ...sxProps.columnWrapper,
                  width: columnWidth,
                }}
                ref={(el) => {
                  columnRefs.current[idx] = el;
                }}
              >
                <Grid
                  container
                  direction="column"
                  onDragOver={onDragOverColumn}
                  onDrop={(event) => onDrop(event, column.status)}
                  sx={
                    {
                      ...sxProps.column,
                      ...(isColumnUnavailable(column.status) &&
                        sxProps.disabledColumn),
                    } as SxProps
                  }
                >
                  <Grid item xs={12}>
                    <Box
                      sx={
                        {
                          ...sxProps.columnNameBox,
                          ...sxProps[column.status],
                        } as SxProps
                      }
                    >
                      <Typography
                        align="center"
                        color="primary.contrastText"
                        variant="subtitle2"
                        fontWeight="normal"
                      >
                        <strong>{t(column.labelKey)}</strong>
                        {column.status === TicketStatusEnumDTO.Done &&
                          ` |  ${t('ticket.statusDoneHelperText', {
                            number: DAYS_NUMBER_TO_FILTER_DONE_TICKET,
                          })}`}
                      </Typography>
                    </Box>
                  </Grid>
                  {column.tickets.map((ticket) => (
                    <Grid key={`ticket-${ticket.uuid}`} item xs={12}>
                      <DraggableCard
                        columnElement={columnRefs.current[idx]}
                        columnSpacing={COLUMN_SPACING}
                        currentDrag={currentDrag}
                        data={ticket}
                        dragImageElement={invisibleDragImage.current}
                        onTouchDrop={onTouchDrop}
                        onTouchMoveOver={onDragOverContainer}
                        setCurrentDrag={setCurrentDrag}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Scrollbar>
      </Grid>
    </Grid>
  );
};
