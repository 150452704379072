import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { TicketTypeEnumDTO } from '../../../../../connectors/ticket';
import { palette } from '../../../../shared';
import { getTicketTypeIcon } from '../../consts';
import { sxProps } from './request-cell.styles';

type Props = {
  id: string;
  summary: string;
  type: TicketTypeEnumDTO;
};

export const RequestCell: FC<Props> = (props) => {
  const { id, summary, type } = props;
  const Icon = useMemo(() => getTicketTypeIcon(type), [type]);

  return (
    <Box sx={sxProps.box}>
      <Grid alignItems="center" container spacing={1} wrap="nowrap">
        <Grid item>
          <Icon height={24} stroke={palette.silverChalice} width={24} />
        </Grid>
        <Grid item>
          <Box sx={sxProps.text}>
            <Typography
              color="text.secondary"
              sx={sxProps.textOverflow}
              variant="subtitle2"
            >
              {id}
            </Typography>
            <Typography sx={sxProps.textOverflow} variant="subtitle2">
              {summary}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
