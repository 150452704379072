import type { FC } from 'react';
import React, { useMemo } from 'react';

import {
  formatDistance,
  formatStandardDate,
  formatStandardDateTime,
  formatStandardDateTimeWithDay,
} from '../../helpers';
import { useTranslation } from '../../translations';

type Props = {
  date: Date | string;
  format?:
    | 'distance'
    | 'standardDate'
    | 'standardDateTime'
    | 'standardDateTimeWithDay';
};

export const FormattedDate: FC<Props> = (props) => {
  const { date, format = 'standardDate' } = props;
  const { langCode } = useTranslation();
  const formattedDate = useMemo(() => {
    switch (format) {
      case 'distance':
        return formatDistance(date, langCode);
      case 'standardDate':
        return formatStandardDate(date, langCode);
      case 'standardDateTime':
        return formatStandardDateTime(date, langCode);
      case 'standardDateTimeWithDay':
        return formatStandardDateTimeWithDay(date, langCode);
      default:
        return date;
    }
  }, [date, format, langCode]);

  // Fragment wants more than 1 child - but we want to send only string from formatting the date
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{formattedDate}</>;
};
