/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ServiceEnumDTO {
    Attachment = 'attachment',
    Auditlog = 'auditlog',
    Company = 'company',
    Property = 'property',
    Inventory = 'inventory',
    Contract = 'contract',
    Transaction = 'transaction',
    User = 'user',
    Ticketing = 'ticketing',
    Comment = 'comment',
    Notification = 'notification'
}



