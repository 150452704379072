import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import type { FC } from 'react';

import { Spinner } from '../../Spinner/spinner.component';

type Props = {
  colSpan: number;
};

export const FetchingRow: FC<Props> = (props) => {
  const { colSpan } = props;

  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Spinner />
      </TableCell>
    </TableRow>
  );
};
