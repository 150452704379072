import type { UserSerializerDTO } from '../../../../connectors/user';
import { UserRoleEnumDTO } from '../../../../connectors/user';

const userRoleToTranslationLabelKeyMap: Record<
  UserRoleEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [UserRoleEnumDTO.AssetManager]: 'user.role.assetManager',
  [UserRoleEnumDTO.BackOffice]: 'user.role.backOffice',
  [UserRoleEnumDTO.Contractor]: 'user.role.contractor',
  [UserRoleEnumDTO.CountryManager]: 'user.role.countryManager',
  [UserRoleEnumDTO.CustomerService]: 'user.role.customerService',
  [UserRoleEnumDTO.ExternalCatalog]: 'user.role.externalCatalog',
  [UserRoleEnumDTO.FinanceManager]: 'user.role.financeManager',
  [UserRoleEnumDTO.Janitor]: 'user.role.janitor',
  [UserRoleEnumDTO.LettingAgent]: 'user.role.lettingAgent',
  [UserRoleEnumDTO.PropertyManager]: 'user.role.propertyManager',
  [UserRoleEnumDTO.Prospect]: 'user.role.prospect',
  [UserRoleEnumDTO.ReadOnlyAll]: 'user.role.readOnlyAll',
  [UserRoleEnumDTO.ReadOnlyCountry]: 'user.role.readOnlyCountry',
  [UserRoleEnumDTO.SuperUser]: 'user.role.superUser',
  [UserRoleEnumDTO.SystemAdministrator]: 'user.role.systemAdministrator',
  [UserRoleEnumDTO.Tenant]: 'user.role.tenant',
};

export const getUserRoleTranslationLabelKey = (
  userRole: UserRoleEnumDTO | UserRoleEnumDTO[] | string[],
): GenericTypes.TranslationLabel =>
  Array.isArray(userRole)
    ? userRoleToTranslationLabelKeyMap[userRole[0] as UserRoleEnumDTO]
    : userRoleToTranslationLabelKeyMap[userRole] || '';

export const isSuperUser = (
  roles: UserSerializerDTO['roles'] | string[],
): boolean => Boolean(roles?.includes(UserRoleEnumDTO.SuperUser));

export const isTenant = (
  roles: UserSerializerDTO['roles'] | string[],
): boolean => Boolean(roles?.includes(UserRoleEnumDTO.Tenant));
