import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { generatePath, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../+auth';
import type {
  ComplaintDetailSerializerDTO,
  ComplaintSerializerDTO,
  ListAttachmentSerializerDTO,
} from '../../../../../connectors/ticket';
import { TicketVisibilityEnumDTO } from '../../../../../connectors/ticket';
import type { FormProps } from '../../../../shared';
import { Box, Form, useAsync, useLocalization } from '../../../../shared';
import PATHS from '../../../paths.const';
import { Reporter } from '../../enums';
import { getTypePathBasedOnTicketType } from '../../helpers';
import { useTicketConfig } from '../../providers';
import type {
  ComplaintValues,
  RequestDetailsData,
  TicketFormProps,
} from '../../types';
import { RequestFormEffect } from '../RequestFormEffect/request-form-effect.component';
import { getFields, getInitialValues } from './complaint-form.model';

export const ComplaintForm: FC<TicketFormProps> = (props) => {
  const { onSubmit$, isPredefinedData } = props;
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { config: ticketConfig } = useTicketConfig();
  const { userCountries } = useLocalization();
  const { response } = useAsync<
    | [
        RequestDetailsData<ComplaintDetailSerializerDTO>,
        ListAttachmentSerializerDTO | undefined,
      ]
    | undefined
  >();
  const [details, attachments] = [...(response || [])];
  const isUpdateMode = useMemo(
    () => Boolean(details?.reporter),
    [details?.reporter],
  );
  const existingAttachments = useMemo(
    () => attachments?.results || [],
    [attachments],
  );
  const initialValues = useMemo(
    () => getInitialValues(userCountries, details, existingAttachments),
    [details, existingAttachments, userCountries],
  );
  const fieldsConfig = useMemo(
    () =>
      getFields(
        userCountries,
        ticketConfig,
        initialValues,
        isUpdateMode,
        isPredefinedData,
        details && 'status' in details ? details.status : undefined,
      ),
    [
      details,
      initialValues,
      isPredefinedData,
      isUpdateMode,
      ticketConfig,
      userCountries,
    ],
  );
  const { user } = useAuth();
  const config = useMemo<FormProps<ComplaintValues>['config']>(
    () => ({
      onSubmit: async (values) => {
        const {
          apartment,
          assignee,
          attachments,
          complaintType,
          dueDate,
          entity,
          entityType,
          isPrivate,
          reporter,
          tenant,
          ...rest
        } = values;
        const existingReporter = details?.reporter?.uuid;

        if (
          complaintType.length === 0 ||
          !entityType ||
          !entity ||
          (!existingReporter && reporter === Reporter.TENANT && !apartment)
        )
          return Promise.reject();

        const newReporter =
          reporter === Reporter.ME ? user?.uuid || '' : tenant?.value || '';
        const reporterUuid = existingReporter || newReporter;
        const data = {
          ...rest,
          assigneeUuid: assignee?.value || null,
          complaintType,
          dueDate: dueDate ? dueDate.toISOString() : undefined,
          entityType,
          entityUuid: entity?.value || '',
          reporterUuid,
          visibility: isPrivate
            ? TicketVisibilityEnumDTO.Private
            : TicketVisibilityEnumDTO.Public,
        };

        return onSubmit$(
          // TODO: consult with backend if marking as optional string or null is possible on their side
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data,
          attachments,
          existingAttachments,
        );
      },
    }),
    [details?.reporter?.uuid, existingAttachments, onSubmit$, user?.uuid],
  );
  const handleSuccess = useCallback(
    ({ ticketType, uuid }: ComplaintSerializerDTO) => {
      const type = getTypePathBasedOnTicketType(ticketType);

      navigate(generatePath(PATHS.DETAILS, { id: uuid, type }));
    },
    [navigate],
  );
  const handleCancel = useCallback(() => {
    navigate(generatePath(PATHS.DETAILS, { id, type }));
  }, [id, navigate, type]);

  return (
    <Box>
      <Form<ComplaintValues>
        config={config}
        fieldsConfig={fieldsConfig}
        formEffect={<RequestFormEffect />}
        handleSuccess={handleSuccess}
        onCancel={attachments ? handleCancel : undefined}
        submitLabelKey={
          !attachments ? 'ticket.fieldset.summary.button' : undefined
        }
      />
    </Box>
  );
};
