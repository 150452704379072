import type { UnitFeatureSerializerDTO } from '../../../../connectors/property';
import { transformKeys } from '../../../shared';
import type { CheckboxOption, CheckboxValue } from '../../../shared';

// services/common/enums/enums.py
export enum UnitFeatureEnum {
  AIR_CONDITIONING = 'has_air_conditioning',
  BALCONY = 'has_balcony',
}

const featureToTranslationLabelKeyMap: Record<
  UnitFeatureEnum,
  GenericTypes.TranslationLabel
> = {
  [UnitFeatureEnum.AIR_CONDITIONING]: 'unit.fields.feature.airConditioning',
  [UnitFeatureEnum.BALCONY]: 'unit.fields.feature.balcony',
};

export const getFeatureTranslationLabelKey = (
  feature: UnitFeatureEnum,
): GenericTypes.TranslationLabel =>
  featureToTranslationLabelKeyMap[feature] || feature;

export const featureOptions: CheckboxOption[] = Object.values(
  UnitFeatureEnum,
).map((feature) => ({
  labelKey: getFeatureTranslationLabelKey(feature),
  name: feature,
}));

export const getFeaturesCheckboxValue = (
  features?: UnitFeatureSerializerDTO,
): CheckboxValue<UnitFeatureEnum> => {
  if (!features) return [];

  const transformedFeatures = transformKeys(features, 'SNAKE_CASE');

  return Object.entries(transformedFeatures).reduce<
    CheckboxValue<UnitFeatureEnum>
  >(
    (acc, [key, value]) => [...acc, ...(value ? [key as UnitFeatureEnum] : [])],
    [],
  );
};
