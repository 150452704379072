import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { ContractRequestDetailSerializerDTO } from '../../../../../connectors/ticket';
import { getContractRequestTypeTranslationLabelKey } from '../../consts';
import { DetailsFeatureList } from '../DetailsFeatureList/details-feature-list.component';

type Props = {
  ticketSubtype: ContractRequestDetailSerializerDTO['ticketSubtype'];
  ticketType?: ContractRequestDetailSerializerDTO['ticketType'];
};

export const ContractRequestDetailsFeatureList: FC<Props> = (props) => {
  const { ticketSubtype, ticketType } = props;
  const features = useMemo(
    () => [
      {
        dataTestId: 'ticket-subtype',
        key: 'ticketSubtype',
        textWrap: true,
        titleKey: 'ticket.fields.ticketSubtype',
        valueKey: getContractRequestTypeTranslationLabelKey(ticketSubtype),
      },
    ],
    [ticketSubtype],
  );

  return <DetailsFeatureList features={features} ticketType={ticketType} />;
};
