import type { ListAttachmentSerializerDTO } from '../../../../../../connectors/ticket';
import type { FileValue, FormProps } from '../../../../../shared';
import {
  ATTACHMENT_MAX_FILE_SIZE,
  attachmentConvertToFiles,
} from '../../../../../shared';
import { supportedImageTypeExtensions } from '../../../../../shared/attachment/shared';

export type Values = {
  images: FileValue;
};

export const getFields = (
  images?: ListAttachmentSerializerDTO['results'],
): FormProps<Values>['fieldsConfig'] => [
  {
    componentType: 'file',
    field: {
      initialValue: images ? attachmentConvertToFiles(images) : [],
      name: 'images',
    },
    props: {
      accept: supportedImageTypeExtensions,
      maxSize: ATTACHMENT_MAX_FILE_SIZE,
    },
  },
];
