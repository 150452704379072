import { ReactComponent as CertifiedCertificateIcon } from '@heimstaden/icons-library/img/streamline-regular/social-medias-rewards-rating/certified/certified-certificate.svg';
import LoadingButton from '@mui/lab/LoadingButton';
import Icon from '@mui/material/Icon';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';

import type {
  ProspectSerializerDTO,
  ProspectVerificationSerializerDTO,
} from '../../../../../../connectors/property';
import { useTranslation } from '../../../../../shared';
import { prospectClient } from '../../../../prospect.client';
import { StatusIndicator } from '../StatusIndicator/status-indicator.component';
import { sxProps } from '../verification-stepper.styles';

type Props = {
  prospectId: ProspectSerializerDTO['uuid'];
  verificationId?: ProspectVerificationSerializerDTO['uuid'];
};

export const Certificate: FC<Props> = (props) => {
  const { prospectId, verificationId } = props;
  const { t } = useTranslation();
  const [isFetching, setFetching] = useState(false);
  const handleDownloadCertificate$ = useCallback(async () => {
    if (!verificationId) return;

    setFetching(true);

    try {
      const data = await prospectClient.downloadCertificate$(
        prospectId,
        verificationId,
      );

      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');
    } catch (e) {
      toast.error(t('errors.general.message'));
    } finally {
      setFetching(false);
    }
  }, [prospectId, verificationId, t]);

  if (!verificationId) return null;

  return (
    <>
      <StatusIndicator status="verified" />
      <LoadingButton
        color="info"
        disabled={isFetching}
        endIcon={
          <Icon
            component={CertifiedCertificateIcon}
            sx={sxProps.certificateIcon}
          />
        }
        loading={isFetching}
        loadingPosition="end"
        onClick={handleDownloadCertificate$}
        sx={sxProps.text}
        variant="text"
      >
        {t('prospect.verification.certificate')}
      </LoadingButton>
    </>
  );
};
