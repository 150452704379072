import type { Permission } from '../../../+auth/shared/enums/permission.enum';
import { UserTypeEnumDTO } from '../../../../connectors/user';
import {
  VIEW_EMPLOYEE_PERMISSIONS,
  VIEW_EXTERNAL_EMPLOYEES_PERMISSIONS,
  VIEW_TENANTS_PERMISSIONS,
} from '../consts';

export const userTypePermissionsMap: Record<UserTypeEnumDTO, Permission[]> = {
  [UserTypeEnumDTO.Customer]: VIEW_TENANTS_PERMISSIONS,
  [UserTypeEnumDTO.Employee]: VIEW_EMPLOYEE_PERMISSIONS,
  [UserTypeEnumDTO.ExternalEmployee]: VIEW_EXTERNAL_EMPLOYEES_PERMISSIONS,
  [UserTypeEnumDTO.System]: [],
};

export const getPermissionsBaseOnUserType = (
  userType: UserTypeEnumDTO,
): Permission[] => userTypePermissionsMap[userType] || [];
