import { ReactComponent as BuildingIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/buildings/building.svg';
import { ReactComponent as NotesQuestionIcon } from '@heimstaden/icons-library/img/streamline-regular/content/notes/notes-question.svg';
import { ReactComponent as ProtestKnuckleUpIcon } from '@heimstaden/icons-library/img/streamline-regular/crime-war-protection/rally-riot/protest-knuckle-up.svg';
import { ReactComponent as FamilyOutdoorsPlayhouseIcon } from '@heimstaden/icons-library/img/streamline-regular/family-babies-kids/outdoor-activities/family-outdoors-playhouse.svg';
import { ReactComponent as FamilyOutdoorsTeeterIcon } from '@heimstaden/icons-library/img/streamline-regular/family-babies-kids/outdoor-activities/family-outdoors-teeter.svg';
import { ReactComponent as BathroomTubTowelIcon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/bathroom/bathroom-tub-towel.svg';
import { ReactComponent as InformationCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alerts/information-circle.svg';
import { ReactComponent as AccountingDocumentIcon } from '@heimstaden/icons-library/img/streamline-regular/money-payments-finance/accounting-billing/accounting-document.svg';
import { ReactComponent as RealEstateActionHouseLocationIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/actions-houses/real-estate-action-house-location.svg';
import { ReactComponent as RealEstateDealDocumentIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/deals/real-estate-deal-document.svg';
import { ReactComponent as ToolsWenchScrewdriverIcon } from '@heimstaden/icons-library/img/streamline-regular/tools-constructions/tools/tools-wench-screwdriver.svg';
import { ReactComponent as GarbageBinIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/garbages/garbage-bin.svg';
import { ReactComponent as IceRoadIcon } from '@heimstaden/icons-library/img/streamline-regular/weather/ice-snow/ice-road.svg';
import type { FC, SVGProps } from 'react';

import {
  IssueTypeEnumDTO,
  LocationTypeEnumDTO,
  RoomTypeEnumDTO,
  TicketTypeEnumDTO,
} from '../../../../connectors/ticket';

export const ticketTypeIconKeyMap: Record<
  TicketTypeEnumDTO,
  FC<SVGProps<SVGSVGElement>>
> = {
  [TicketTypeEnumDTO.Complaint]: ProtestKnuckleUpIcon,
  [TicketTypeEnumDTO.ContractRequest]: RealEstateDealDocumentIcon,
  [TicketTypeEnumDTO.GeneralRequest]: InformationCircleIcon,
  [TicketTypeEnumDTO.InvoiceRequest]: AccountingDocumentIcon,
  [TicketTypeEnumDTO.ServiceRequest]: ToolsWenchScrewdriverIcon,
};

export const issueLocationIconKeyMap: Record<
  LocationTypeEnumDTO,
  FC<SVGProps<SVGSVGElement>> | undefined
> = {
  [LocationTypeEnumDTO.Apartment]: RealEstateActionHouseLocationIcon,
  [LocationTypeEnumDTO.CommonArea]: undefined,
  [LocationTypeEnumDTO.CommonOutdoorArea]: undefined,
  [LocationTypeEnumDTO.OutdoorArea]: undefined,
  [LocationTypeEnumDTO.PrivateOutdoorArea]: undefined,
};

export const issueTypeIconKeyMap: Record<
  IssueTypeEnumDTO,
  FC<SVGProps<SVGSVGElement>> | undefined
> = {
  [IssueTypeEnumDTO.BathroomAppliances]: undefined,
  [IssueTypeEnumDTO.BicycleParkingIssue]: undefined,
  [IssueTypeEnumDTO.BikeStorageIssue]: undefined,
  [IssueTypeEnumDTO.ContainerGarbageIssue]: undefined,
  [IssueTypeEnumDTO.DishwasherIssue]: undefined,
  [IssueTypeEnumDTO.ElectricityIssue]: undefined,
  [IssueTypeEnumDTO.ElevatorsIssue]: undefined,
  [IssueTypeEnumDTO.FacadeIssue]: BuildingIcon,
  [IssueTypeEnumDTO.FencePavingPlantingIssue]: undefined,
  [IssueTypeEnumDTO.FireAlarmIssue]: undefined,
  [IssueTypeEnumDTO.FurnitureIssue]: undefined,
  [IssueTypeEnumDTO.GarageParkingIssue]: undefined,
  [IssueTypeEnumDTO.GaragePlatformParkingIssue]: undefined,
  [IssueTypeEnumDTO.GarbageRoomIssue]: GarbageBinIcon,
  [IssueTypeEnumDTO.GasIssue]: undefined,
  [IssueTypeEnumDTO.GreenAreasIssue]: FamilyOutdoorsPlayhouseIcon,
  [IssueTypeEnumDTO.HallwayStairsIssue]: undefined,
  [IssueTypeEnumDTO.HeatingVentilationIssue]: undefined,
  [IssueTypeEnumDTO.InternetIssue]: undefined,
  [IssueTypeEnumDTO.KeysLockIntercomIssue]: undefined,
  [IssueTypeEnumDTO.KitchenAppliances]: undefined,
  [IssueTypeEnumDTO.KitchenFanIssue]: undefined,
  [IssueTypeEnumDTO.KitchenSinkIssue]: undefined,
  [IssueTypeEnumDTO.KitchenUnit]: undefined,
  [IssueTypeEnumDTO.LaundryRoomIssue]: undefined,
  [IssueTypeEnumDTO.MailBoxNameplateIssue]: undefined,
  [IssueTypeEnumDTO.MechanicalVentilation]: undefined,
  [IssueTypeEnumDTO.MoistureMoldIssue]: undefined,
  [IssueTypeEnumDTO.MovableBarrierIssue]: undefined,
  [IssueTypeEnumDTO.Other]: NotesQuestionIcon,
  [IssueTypeEnumDTO.OutdoorSmellIssue]: undefined,
  [IssueTypeEnumDTO.OvenCookerIssue]: undefined,
  [IssueTypeEnumDTO.PlaygroundIssue]: FamilyOutdoorsTeeterIcon,
  [IssueTypeEnumDTO.RefrigeratorFreezerIssue]: undefined,
  [IssueTypeEnumDTO.RooftopIssue]: undefined,
  [IssueTypeEnumDTO.SandIssue]: undefined,
  [IssueTypeEnumDTO.ShowerBathIssue]: undefined,
  [IssueTypeEnumDTO.SinkIssue]: undefined,
  [IssueTypeEnumDTO.SnowRemovalIssue]: IceRoadIcon,
  [IssueTypeEnumDTO.SprinklingSystemIssue]: undefined,
  [IssueTypeEnumDTO.StorageRoomIssue]: undefined,
  [IssueTypeEnumDTO.StreetWorkoutZoneEquipmentIssue]: undefined,
  [IssueTypeEnumDTO.SurfacesIssue]: undefined,
  [IssueTypeEnumDTO.ToiletIssue]: undefined,
  [IssueTypeEnumDTO.TvInternetIssue]: undefined,
  [IssueTypeEnumDTO.VerminIssue]: undefined,
  [IssueTypeEnumDTO.WashingMachineIssue]: undefined,
  [IssueTypeEnumDTO.WasteIssue]: undefined,
  [IssueTypeEnumDTO.WaterDrainPipesIssue]: undefined,
};

export const roomTypeIconKeyMap: Record<
  RoomTypeEnumDTO,
  FC<SVGProps<SVGSVGElement>> | undefined
> = {
  [RoomTypeEnumDTO.Attic]: undefined,
  [RoomTypeEnumDTO.BalconyOrRoofTerrace]: undefined,
  [RoomTypeEnumDTO.Basement]: undefined,
  [RoomTypeEnumDTO.Bathroom]: BathroomTubTowelIcon,
  [RoomTypeEnumDTO.Bedroom]: undefined,
  [RoomTypeEnumDTO.BicycleParking]: undefined,
  [RoomTypeEnumDTO.BoilerRoom]: undefined,
  [RoomTypeEnumDTO.Elevator]: undefined,
  [RoomTypeEnumDTO.Facade]: undefined,
  [RoomTypeEnumDTO.Firebreak]: undefined,
  [RoomTypeEnumDTO.GarageGate]: undefined,
  [RoomTypeEnumDTO.Hall]: undefined,
  [RoomTypeEnumDTO.Hallway]: undefined,
  [RoomTypeEnumDTO.Kitchen]: undefined,
  [RoomTypeEnumDTO.LivingRoom]: undefined,
  [RoomTypeEnumDTO.None]: undefined,
  [RoomTypeEnumDTO.OnTheRoof]: undefined,
  [RoomTypeEnumDTO.OutdoorAreaOrGarden]: undefined,
  [RoomTypeEnumDTO.ParkingSpace]: undefined,
  [RoomTypeEnumDTO.ParkingSpacePlatforms]: undefined,
  [RoomTypeEnumDTO.PlaygroundEquipment]: undefined,
  [RoomTypeEnumDTO.RecreationRoom]: undefined,
  [RoomTypeEnumDTO.Stairwell]: undefined,
  [RoomTypeEnumDTO.Storage]: undefined,
  [RoomTypeEnumDTO.StorageHallway]: undefined,
  [RoomTypeEnumDTO.StreetWorkoutZoneEquipment]: undefined,
  [RoomTypeEnumDTO.ToiletRoom]: undefined,
};

export const getTicketTypeIcon = (
  ticketType: TicketTypeEnumDTO,
): FC<SVGProps<SVGSVGElement>> => ticketTypeIconKeyMap[ticketType];

export const getIssueLocationIcon = (
  issueLocation: LocationTypeEnumDTO,
): FC<SVGProps<SVGSVGElement>> | undefined =>
  issueLocationIconKeyMap[issueLocation] || undefined;

export const getIssueTypeIcon = (
  issueType: IssueTypeEnumDTO,
): FC<SVGProps<SVGSVGElement>> | undefined =>
  issueTypeIconKeyMap[issueType] || undefined;

export const getRoomTypeIcon = (
  roomType: RoomTypeEnumDTO,
): FC<SVGProps<SVGSVGElement>> | undefined =>
  roomTypeIconKeyMap[roomType] || undefined;
