import Grid from '@mui/material/Grid';
import { Form as FormikForm } from 'formik';
import type { FC } from 'react';
import React from 'react';

import type { ServiceRequestDetailSerializerDTO } from '../../../../../../connectors/ticket';
import type { FieldConfig } from '../../../../../shared';
import { Box } from '../../../../../shared';
import { FormikEffect } from '../../../../../shared/form/components';
import type { RequestDetailsData, ServiceRequestValues } from '../../../types';
import { OnePageContent } from '../OnePageContent/one-page-content.component';
import { StepperContent } from '../StepperContent/stepper-content.component';
import { sxProps } from './form.styles';

type Props = {
  fieldsConfig: FieldConfig<ServiceRequestValues>[];
  isStepper: boolean;
  details?: RequestDetailsData<ServiceRequestDetailSerializerDTO>;
  globalErrorKey?: string;
  isPredefinedData?: boolean;
  onCancel?: () => void;
};

export const Form: FC<Props> = (props) => {
  const {
    details,
    fieldsConfig,
    globalErrorKey,
    isPredefinedData,
    isStepper,
    onCancel,
  } = props;

  return (
    <FormikForm noValidate>
      <FormikEffect fieldsConfig={fieldsConfig} />
      <Box sx={sxProps.box}>
        <Grid container spacing={3}>
          {isStepper ? (
            <StepperContent
              globalErrorKey={globalErrorKey}
              details={details}
              isPredefinedData={isPredefinedData}
            />
          ) : (
            <OnePageContent
              details={details}
              globalErrorKey={globalErrorKey}
              onCancel={onCancel}
            />
          )}
        </Grid>
      </Box>
    </FormikForm>
  );
};
