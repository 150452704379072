import { createSvgIcon } from '@mui/material/utils';
import React from 'react';

export const MailOpenIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5279 5.6944C1.18272 5.91013 0.898084 6.21012 0.700758
      6.56615C0.503432 6.92218 0.399901 7.32254 0.399902 7.7296V17.2C0.399902
      17.8365 0.652759 18.447 1.10285 18.8971C1.55293 19.3471 2.16338 19.6
      2.7999 19.6H17.1999C17.8364 19.6 18.4469 19.3471 18.897 18.8971C19.347
      18.447 19.5999 17.8365 19.5999 17.2V7.7296C19.5999 7.32254 19.4964 6.92218
      19.299 6.56615C19.1017 6.21012 18.8171 5.91013 18.4719 5.6944L11.2719
      1.1944C10.8905 0.955997 10.4497 0.82959 9.9999 0.82959C9.55009 0.82959
      9.10934 0.955997 8.7279 1.1944L1.5279 5.6944V5.6944ZM4.6659 8.602C4.53479
      8.51454 4.38774 8.45376 4.23313 8.42313C4.07853 8.3925 3.91941 8.39262
      3.76486 8.42349C3.61031 8.45435 3.46335 8.51536 3.33237 8.60302C3.20139
      8.69068 3.08896 8.80328 3.0015 8.9344C2.91404 9.06551 2.85326 9.21256
      2.82263 9.36716C2.79201 9.52176 2.79213 9.68088 2.82299 9.83544C2.88533
      10.1476 3.06911 10.4222 3.3339 10.5988L9.3339 14.5984C9.5311 14.73 9.76284
      14.8002 9.9999 14.8002C10.237 14.8002 10.4687 14.73 10.6659
      14.5984L16.6659 10.5988C16.9307 10.4222 17.1145 10.1476 17.1768
      9.83544C17.2391 9.5233 17.1749 9.19919 16.9983 8.9344C16.8217 8.6696
      16.5471 8.48582 16.2349 8.42349C15.9228 8.36115 15.5987 8.42536 15.3339
      8.602L9.9999 12.1576L4.6659 8.602Z"
      fill="currentColor"
    />
  </svg>,

  'MailOpen',
);
