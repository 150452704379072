import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    display: 'flex',
  },
  label: {
    alignItems: 'center',
    color: (theme) => theme.palette.grey[800],
    display: 'flex',
    fontSize: (theme) => theme.typography.pxToRem(12),
    fontWeight: (theme) => theme.typography.fontWeightBold,
    minHeight: 30,
  },
  rectangle: {
    borderRadius: 6,
    mr: 1,
    width: 12,
  },
  total: {
    color: (theme) => theme.palette.text.primary,
    fontSize: (theme) => theme.typography.pxToRem(24),
  },
};
