import React from 'react';
import { Outlet } from 'react-router-dom';

import { PermissionGuard } from '../+auth/shared/guards/permission.guard';
import { ProspectConfigProvider } from '../+prospect';
import { APP_MAIN_PATH } from '../routing';
import { componentLazyImport } from '../routing/routes.helper';
import { FeatureAvailableGuard } from '../shared/guards/FeatureAvailable/feature-available.guard';
import { Country } from '../shared/providers/Country/enum';
import PATHS from './paths.const';
import { ConfigProvider, CREATE_PERMISSIONS, VIEW_PERMISSIONS } from './shared';

const Create = componentLazyImport(
  () => import('./Create/create.component'),
  'ADVERTISEMENT_CREATE',
);
const Details = componentLazyImport(
  () => import('./Details/details.component'),
  'ADVERTISEMENT_DETAILS',
);
const List = componentLazyImport(
  () => import('./List/list.component'),
  'ADVERTISEMENT_LIST',
);
const NotFound = componentLazyImport(
  () => import('../shared/errors/NotFound/not-found.component'),
  'ADVERTISEMENT_NOT_FOUND',
);
const ProspectList = componentLazyImport(
  () => import('./ProspectList/prospect-list.component'),
  'ADVERTISEMENT_PROSPECT_LIST',
);
const Update = componentLazyImport(
  () => import('./Update/update.component'),
  'ADVERTISEMENT_UPDATE',
);

const routes = {
  children: [
    { element: <List />, path: '' },
    {
      element: (
        <PermissionGuard
          fallbackRoute={PATHS.LIST}
          permissions={CREATE_PERMISSIONS}
        >
          <Create />
        </PermissionGuard>
      ),
      path: 'create',
    },
    {
      element: <Update />,
      path: ':id/update',
    },
    {
      children: [
        { element: <ProspectList />, path: '' },
        { element: <ProspectList />, path: 'prospects' },
        { element: <NotFound />, path: '*' },
      ],
      element: (
        // TODO: To improve
        <ProspectConfigProvider>
          <Details />
        </ProspectConfigProvider>
      ),
      path: ':id',
    },
    { element: <NotFound />, path: '*' },
  ],
  element: (
    <FeatureAvailableGuard countries={[Country.PL]}>
      <PermissionGuard
        fallbackRoute={APP_MAIN_PATH}
        permissions={VIEW_PERMISSIONS}
      >
        <ConfigProvider>
          <Outlet />
        </ConfigProvider>
      </PermissionGuard>
    </FeatureAvailableGuard>
  ),
  path: 'advertisements',
};

export default routes;
