import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

export type ContextValue<T> = {
  response: T;
  setResponse: Dispatch<SetStateAction<T>>;
};

// needed here to introduce any type of ContextValue
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Context = createContext<ContextValue<any>>({
  response: undefined,
  setResponse: () => null,
});
