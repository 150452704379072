import Box from '@mui/material/Box';
import NProgress from 'nprogress';
import React, { useEffect } from 'react';
import type { FC } from 'react';

export const Loading: FC = () => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return <Box sx={{ minHeight: '100%' }} />;
};
