import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import type { FC, ReactElement } from 'react';
import React, { useMemo, useState } from 'react';

import { PLACEHOLDER } from '../../consts';
import { useRefHeight } from '../../hooks';
import { sanitizeService } from '../../services';
import { useTranslation } from '../../translations';
import { sxProps } from './collapse-test.styles';

export type Props = {
  additionalContent?: ReactElement;
  isTitle?: boolean;
  text?: string;
};

export const COLLAPSE_SIZE = 96;
const MIN_COLLAPSE_SIZE = 24;

export const CollapseText: FC<Props> = (props) => {
  const { additionalContent, isTitle = true, text } = props;
  const [textHeight, ref] = useRefHeight();
  const { t } = useTranslation();
  const [isCollapsed, setCollapsed] = useState(false);
  const isButtonNeeded = useMemo(
    () => Boolean(textHeight && textHeight > COLLAPSE_SIZE),
    [textHeight],
  );
  const collapseSize = useMemo(
    () =>
      isButtonNeeded || additionalContent ? COLLAPSE_SIZE : MIN_COLLAPSE_SIZE,
    [additionalContent, isButtonNeeded],
  );
  const buttonLabelKey = useMemo(
    () => (isCollapsed ? 'description.readLess' : 'description.readMore'),
    [isCollapsed],
  );
  const collapseSxProps = useMemo(
    () => (textHeight && !isButtonNeeded ? sxProps.collapse : undefined),
    [isButtonNeeded, textHeight],
  );

  return (
    <>
      {isTitle && (
        <Typography component="div" variant="h2">
          {t('description.title')}
        </Typography>
      )}
      <Collapse
        collapsedSize={collapseSize}
        in={isCollapsed}
        orientation="vertical"
        sx={collapseSxProps}
      >
        {additionalContent}
        <Typography
          component="div"
          {...(text
            ? {
                dangerouslySetInnerHTML: {
                  __html: sanitizeService.cleanText(text),
                },
              }
            : { children: PLACEHOLDER })}
          ref={ref}
          variant="body1"
        />
      </Collapse>
      {isButtonNeeded && (
        <Button
          onClick={() => setCollapsed((prevState) => !prevState)}
          variant="text"
          sx={sxProps.button}
        >
          {t(buttonLabelKey)}
        </Button>
      )}
    </>
  );
};
