import { startCase } from 'lodash-es';
import camelCase from 'lodash-es/camelCase';

import type { PropConfigSerializerDTO } from '../../../../connectors/company';
import { PropConfigDataTypeEnumDTO } from '../../../../connectors/company';
import { DEFAULT_FIELD_VALUE } from '../../form';
import type {
  CheckboxFieldConfig,
  CheckboxValue,
  CheckboxOption,
  FieldConfig,
  InputFieldConfig,
} from '../../form';
import { capitalizeFirstLetter } from '../../helpers';
import { getAttributeListModel } from './attribute-list.model';
import type {
  AdditionalProps,
  AdditionalPropsValues,
  SpecificAttributeList,
} from './attribute-list.type';

export const getAttributeLabel = (
  key: PropConfigSerializerDTO['key'],
): string => capitalizeFirstLetter(startCase(key).toLowerCase());

const getBooleanPropOptions = (
  propModel: AdditionalProps[],
): CheckboxOption[] =>
  propModel
    .filter((prop) => prop.datatype === PropConfigDataTypeEnumDTO.Boolean)
    .map((prop) => ({
      label: prop.label,
      name: prop.key,
    }));

const getBooleanPropInitialValues = (propModel: AdditionalProps[]): string[] =>
  propModel
    .filter(
      (prop) =>
        prop.datatype === PropConfigDataTypeEnumDTO.Boolean && prop.value,
    )
    .map((prop) => prop.key);

const getBooleanPropField = (
  propModel: AdditionalProps[],
): CheckboxFieldConfig<AdditionalPropsValues> => ({
  componentType: 'checkbox',
  field: {
    initialValue: getBooleanPropInitialValues(propModel),
    name: 'booleanProps',
  },
  props: {
    labelKey: '',
    options: getBooleanPropOptions(propModel),
    optionSize: { lg: 6, md: 6, sm: 6, xl: 6, xs: 12 },
  },
});

const getNonBooleanPropFormFields = (
  propsModel: AdditionalProps[],
): InputFieldConfig<AdditionalPropsValues>[] =>
  propsModel
    .filter((prop) => prop.datatype !== PropConfigDataTypeEnumDTO.Boolean)
    .map((prop) => ({
      componentType: 'input',
      field: {
        initialValue: prop.value ? prop.value.toString() : DEFAULT_FIELD_VALUE,
        name: `props${prop.key}`,
      },
      props: {
        label: prop.label,
        required: prop.required,
        type:
          prop.datatype === PropConfigDataTypeEnumDTO.String
            ? 'text'
            : 'number',
      },
    }));

export const getAttributeFormFields = (
  props?: SpecificAttributeList,
  propConfigs?: PropConfigSerializerDTO[],
): FieldConfig<AdditionalPropsValues>[] => {
  if (!props || !propConfigs) return [];
  const propModel = getAttributeListModel(props, propConfigs);

  return [
    ...getNonBooleanPropFormFields(propModel),
    getBooleanPropField(propModel),
  ];
};
const getBooleanPropsPayloadObject = (
  booleanProps: CheckboxValue,
  propsConfigs: PropConfigSerializerDTO[],
): Record<string, boolean> =>
  propsConfigs
    .filter((prop) => prop.datatype === PropConfigDataTypeEnumDTO.Boolean)
    .reduce(
      (acc, prop) => ({
        ...acc,
        [prop.key]: booleanProps?.includes(camelCase(prop.key)),
      }),
      {},
    );

const getNonBooleanPropsPayloadObject = (
  values: Record<string, unknown>,
  part: string,
): SpecificAttributeList =>
  Object.fromEntries(
    Object.entries(values)
      .filter(([k]) => k.startsWith(part))
      .map((item) => [item[0].replace(part, ''), item[1]]),
  ) as SpecificAttributeList;

export const getAttributePayloadObject = (
  values: Record<string, unknown>,
  propsConfig: PropConfigSerializerDTO[],
): SpecificAttributeList => {
  const nonBooleanProps = getNonBooleanPropsPayloadObject(values, 'props');
  const booleanProps = getBooleanPropsPayloadObject(
    values.booleanProps as CheckboxValue,
    propsConfig,
  );

  return { ...nonBooleanProps, ...booleanProps };
};
