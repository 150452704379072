/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum IssueSubtypeEnumDTO {
    PowerOff = 'power_off',
    SocketDamage = 'socket_damage',
    BlownFuse = 'blown_fuse',
    BrokenLight = 'broken_light',
    TemperatureTooHighLow = 'temperature_too_high_low',
    ThermostatFailure = 'thermostat_failure',
    PoorIndoorClimate = 'poor_indoor_climate',
    DamagedWaterTap = 'damaged_water_tap',
    CloggedDrains = 'clogged_drains',
    WaterLeakage = 'water_leakage',
    WaterSupplyFailure = 'water_supply_failure',
    DamagedFloors = 'damaged_floors',
    DamagedCeiling = 'damaged_ceiling',
    ShatteredWindow = 'shattered_window',
    GeneralDamageToWindow = 'general_damage_to_window',
    BrokenDoors = 'broken_doors',
    NeedPaint = 'need_paint',
    MoistureIssues = 'moisture_issues',
    MoldIssues = 'mold_issues',
    BrokenMirror = 'broken_mirror',
    DamagedCabinets = 'damaged_cabinets',
    DamagedCountertop = 'damaged_countertop',
    DamagedCabinetHinges = 'damaged_cabinet_hinges',
    DamagedWardrobeHatRack = 'damaged_wardrobe_hat_rack',
    CloggedToilet = 'clogged_toilet',
    RunningToilet = 'running_toilet',
    DamagedToilet = 'damaged_toilet',
    DamagedShowerHeadOrHose = 'damaged_shower_head_or_hose',
    DamagedShowerCabin = 'damaged_shower_cabin',
    DamagedShowerTap = 'damaged_shower_tap',
    DamagedBathtub = 'damaged_bathtub',
    DamagedBathtubTap = 'damaged_bathtub_tap',
    DamagedSink = 'damaged_sink',
    WaterSupplyIssue = 'water_supply_issue',
    PowerSupplyIssue = 'power_supply_issue',
    BrokenOven = 'broken_oven',
    OvenGuardIssue = 'oven_guard_issue',
    BrokenCooktop = 'broken_cooktop',
    CrackedCooktop = 'cracked_cooktop',
    DamagedKitchenFan = 'damaged_kitchen_fan',
    LostOrMissingKey = 'lost_or_missing_key',
    BrokenKey = 'broken_key',
    BrokenLock = 'broken_lock',
    BrokenDoorBellIntercom = 'broken_door_bell_intercom',
    BatteryFailure = 'battery_failure',
    BrokenAlarmClock = 'broken_alarm_clock',
    FireAlarmSystemDefect = 'fire_alarm_system_defect',
    DamagedTv = 'damaged_tv',
    InternetConnectionIssue = 'internet_connection_issue',
    InternetSubscriptionIssue = 'internet_subscription_issue',
    TvSignalIssue = 'tv_signal_issue',
    GarageDoorFailure = 'garage_door_failure',
    ElevatorNotOpening = 'elevator_not_opening',
    IncorrectMailBoxLabelling = 'incorrect_mail_box_labelling',
    BrokenMailBox = 'broken_mail_box',
    MailBoxKeyIssue = 'mail_box_key_issue',
    IncorrectNameplateLabelling = 'incorrect_nameplate_labelling',
    NotCleaned = 'not_cleaned',
    WashingMachineFailure = 'washing_machine_failure',
    DryerFailure = 'dryer_failure',
    CoinOperatedSystemFailure = 'coin_operated_system_failure',
    BookingSystemFailure = 'booking_system_failure',
    WantAccessToStorageRoom = 'want_access_to_storage_room',
    CannotAccess = 'cannot_access',
    DamagedPartitionWall = 'damaged_partition_wall',
    None = 'none',
    Other = 'other',
    AutomaticDoorOpenerBrokenResidentsCannotEnterOrExitTheComplex = 'automatic_door_opener_broken_residents_cannot_enter_or_exit_the_complex',
    AutomaticDoorOpenerBrokenResidentsCanEnterOrExitTheComplex = 'automatic_door_opener_broken_residents_can_enter_or_exit_the_complex',
    Broken = 'broken',
    BrokenOrLoose = 'broken_or_loose',
    LooseOrBrokenHazardousSituation = 'loose_or_broken_hazardous_situation',
    BuiltInEquipmentDefectOwnedByHeimstaden = 'built_in_equipment_defect_owned_by_heimstaden',
    BuiltInEquipmentDefectOwnedByTenant = 'built_in_equipment_defect_owned_by_tenant',
    BuiltInEquipmentDefectOwnershipUnknown = 'built_in_equipment_defect_ownership_unknown',
    BurglaryDamageNoLongerLockable = 'burglary_damage_no_longer_lockable',
    BurglaryDamageResidentsCannotEnterOrExitTheComplex = 'burglary_damage_residents_cannot_enter_or_exit_the_complex',
    BurglaryDamageResidentsCanEnterOrExitTheComplex = 'burglary_damage_residents_can_enter_or_exit_the_complex',
    BurglaryDamageStillLockable = 'burglary_damage_still_lockable',
    CaiAndTelephoneConnectionIsLooseOrCracked = 'cai_and_telephone_connection_is_loose_or_cracked',
    CaiAndTelephoneConnectionNoSignal = 'cai_and_telephone_connection_no_signal',
    CertifiedKeyLost = 'certified_key_lost',
    ChangeExtractorFilter = 'change_extractor_filter',
    Clamps = 'clamps',
    Clogged = 'clogged',
    ContainsNoOffensiveTexts = 'contains_no_offensive_texts',
    ContainsOffensiveTexts = 'contains_offensive_texts',
    ContainsOffensiveTextsNotPassedOnByInHouseSpecialist = 'contains_offensive_texts_not_passed_on_by_in_house_specialist',
    ContainsOffensiveTextsPassedOnByInHouseSpecialist = 'contains_offensive_texts_passed_on_by_in_house_specialist',
    ContainsNoOffensiveTextsNotPassedOnByInHouseSpecialist = 'contains_no_offensive_texts_not_passed_on_by_in_house_specialist',
    ContainsNoOffensiveTextsPassedOnByInHouseSpecialist = 'contains_no_offensive_texts_passed_on_by_in_house_specialist',
    CountertopSealantWorkIsBroken = 'countertop_sealant_work_is_broken',
    CountertopSealantWorkIsMouldy = 'countertop_sealant_work_is_mouldy',
    CountertopSinkBroken = 'countertop_sink_broken',
    CountertopSinkClogged = 'countertop_sink_clogged',
    CountertopSinkLeaking = 'countertop_sink_leaking',
    CrowCapMissingOrBroken = 'crow_cap_missing_or_broken',
    Damaged = 'damaged',
    DamagedOrBroken = 'damaged_or_broken',
    DishwasherBlocked = 'dishwasher_blocked',
    DishwasherClogged = 'dishwasher_clogged',
    DishwasherLeaks = 'dishwasher_leaks',
    DishwasherTapNoWater = 'dishwasher_tap_no_water',
    DoesNotCloseOrOpen = 'does_not_close_or_open',
    DoesNotCloseOrOpenCannotWait = 'does_not_close_or_open_cannot_wait',
    DoesNotCloseOrOpenCanWait = 'does_not_close_or_open_can_wait',
    DoesNotOpenOrCloseProperly = 'does_not_open_or_close_properly',
    DoorBellBroken = 'door_bell_broken',
    DoorBellHangsLoose = 'door_bell_hangs_loose',
    DoorCloserBroken = 'door_closer_broken',
    DoorClosesResidentsCannotEnterOrExitTheComplex = 'door_closes_residents_cannot_enter_or_exit_the_complex',
    DoorClosesResidentsCanEnterOrExitTheComplex = 'door_closes_residents_can_enter_or_exit_the_complex',
    DoorCLockDefective = 'door_c_lock_defective',
    DoorDraft = 'door_draft',
    DoorOpenerBrokenTenantsCannotEnterAndExitTheBuilding = 'door_opener_broken_tenants_cannot_enter_and_exit_the_building',
    DoorOpenerBrokenTenantsCanEnterAndExitTheBuilding = 'door_opener_broken_tenants_can_enter_and_exit_the_building',
    DoorWindowBroken = 'door_window_broken',
    DoubleGlazingLeakingOrFoggedUpInside = 'double_glazing_leaking_or_fogged_up_inside',
    DrainBasinOrFountainClogged = 'drain_basin_or_fountain_clogged',
    DrainBasinOrFountainLeaks = 'drain_basin_or_fountain_leaks',
    Draught = 'draught',
    ExtractorHoodBrokenByHeimstaden = 'extractor_hood_broken_by_heimstaden',
    ExtractorHoodBrokenPropertyUnknown = 'extractor_hood_broken_property_unknown',
    Failure = 'failure',
    FaucetNoHotWaterMultipleFaucetsInTheHome = 'faucet_no_hot_water_multiple_faucets_in_the_home',
    FaucetNoHotWaterOnlyThisFaucet = 'faucet_no_hot_water_only_this_faucet',
    FenceDoorBroken = 'fence_door_broken',
    FenceFromSteelBrokenOrCrooked = 'fence_from_steel_broken_or_crooked',
    FenceFromSteelDoorDoesNotCloseOrOpen = 'fence_from_steel_door_does_not_close_or_open',
    FenceFromWoodBrokenOrCrooked = 'fence_from_wood_broken_or_crooked',
    FenceFromWoodDoorDoesNotCloseOrOpen = 'fence_from_wood_door_does_not_close_or_open',
    FenceOrFenceDoorBroken = 'fence_or_fence_door_broken',
    FenceSteelBrokenOrCrooked = 'fence_steel_broken_or_crooked',
    FenceSteelDoorDoesNotCloseOrOpen = 'fence_steel_door_does_not_close_or_open',
    FenceWoodenBrokenOrCrooked = 'fence_wooden_broken_or_crooked',
    FenceWoodenDoesNotCloseOrOpen = 'fence_wooden_does_not_close_or_open',
    FireDetectorBrokenOrHangingLoose = 'fire_detector_broken_or_hanging_loose',
    FireExtinguisherBrokenHangingLooseOrEmpty = 'fire_extinguisher_broken_hanging_loose_or_empty',
    FireHoseOrReelBrokenOrHangingLoose = 'fire_hose_or_reel_broken_or_hanging_loose',
    FixtureBroken = 'fixture_broken',
    FloorCarpetingSoftOrCarpetBrokenOrLoose = 'floor_carpeting_soft_or_carpet_broken_or_loose',
    FloorDrainClogged = 'floor_drain_clogged',
    FloorHatchIsLooseOrBroken = 'floor_hatch_is_loose_or_broken',
    FloorLaminateOrParquetBrokenOrLooseFromTenant = 'floor_laminate_or_parquet_broken_or_loose_from_tenant',
    FloorSubfloorOfCementOrConcreteBroken = 'floor_subfloor_of_cement_or_concrete_broken',
    FloorSubfloorWoodenCreaks = 'floor_subfloor_wooden_creaks',
    FloorSubfloorWoodenLooseOrBroken = 'floor_subfloor_wooden_loose_or_broken',
    FloorSubfloorWoodenSags = 'floor_subfloor_wooden_sags',
    FloorTilesOrGroutBrokenOrLoose = 'floor_tiles_or_grout_broken_or_loose',
    FloorTileOrGroutLeaks = 'floor_tile_or_grout_leaks',
    FrameDamaged = 'frame_damaged',
    Frozen = 'frozen',
    GlassIsLoose = 'glass_is_loose',
    HandleBrokenOrLoose = 'handle_broken_or_loose',
    HandleBrokenOrLooseResidentsCannotEnterOrExitTheComplex = 'handle_broken_or_loose_residents_cannot_enter_or_exit_the_complex',
    HandleBrokenOrLooseResidentsCanEnterOrExitTheComplex = 'handle_broken_or_loose_residents_can_enter_or_exit_the_complex',
    HandleLoose = 'handle_loose',
    HandleMissing = 'handle_missing',
    HandrailIsLooseOrBrokenHazardousSituation = 'handrail_is_loose_or_broken_hazardous_situation',
    HandrailIsLooseOrBrokenNoDangerousSituation = 'handrail_is_loose_or_broken_no_dangerous_situation',
    HangsLoose = 'hangs_loose',
    HeatingSystemRefill = 'heating_system_refill',
    HeatMeterBrokenOrLoose = 'heat_meter_broken_or_loose',
    HingeBroken = 'hinge_broken',
    HoleInDoor = 'hole_in_door',
    HookForSecuringDoorBroken = 'hook_for_securing_door_broken',
    HouseNumberMissing = 'house_number_missing',
    InsufficientWaterPressureMultipleFaucetsInTheHome = 'insufficient_water_pressure_multiple_faucets_in_the_home',
    InsufficientWaterPressureMultipleTapsInTheHome = 'insufficient_water_pressure_multiple_taps_in_the_home',
    InsufficientWaterPressureOnlyThisTap = 'insufficient_water_pressure_only_this_tap',
    InsufficientWaterPressureThisFaucetOnly = 'insufficient_water_pressure_this_faucet_only',
    InsufficientWaterPressureThisTapOnly = 'insufficient_water_pressure_this_tap_only',
    IntercomDefect = 'intercom_defect',
    JammedNoLongerLockable = 'jammed_no_longer_lockable',
    JammedStillLockable = 'jammed_still_lockable',
    JointOrLeadIsLoose = 'joint_or_lead_is_loose',
    KetelLeaks = 'ketel_leaks',
    KeyLostOrMisplacedOccupantCanEnterOrExitTheHouse = 'key_lost_or_misplaced_occupant_can_enter_or_exit_the_house',
    KeyLostOrMisplacedResidentUnableToEnterOrExitHome = 'key_lost_or_misplaced_resident_unable_to_enter_or_exit_home',
    KeyProcessorDefective = 'key_processor_defective',
    KnobsDefective = 'knobs_defective',
    KnobBroken = 'knob_broken',
    Leakage = 'leakage',
    LeakingAfterMeter = 'leaking_after_meter',
    LeakingBeforeMeter = 'leaking_before_meter',
    LeaksOrBroken = 'leaks_or_broken',
    LeaksOrClogged = 'leaks_or_clogged',
    LeaksOrLoose = 'leaks_or_loose',
    LetterboxBroken = 'letterbox_broken',
    LetterboxCoverBroken = 'letterbox_cover_broken',
    LidMissing = 'lid_missing',
    LockBroken = 'lock_broken',
    LockBrokenResidentsCannotEnterOrExitTheComplex = 'lock_broken_residents_cannot_enter_or_exit_the_complex',
    LockBrokenResidentsCanEnterOrExitTheComplex = 'lock_broken_residents_can_enter_or_exit_the_complex',
    Loose = 'loose',
    LooseOrCrooked = 'loose_or_crooked',
    Lost = 'lost',
    MailboxBroken = 'mailbox_broken',
    MainsPoweredSmokeDetectorBeeps = 'mains_powered_smoke_detector_beeps',
    MakesNoise = 'makes_noise',
    InMalfunction = 'in_malfunction',
    MalfunctionOrStandstill = 'malfunction_or_standstill',
    MinorLeakage = 'minor_leakage',
    Missing = 'missing',
    NameplateContainsWrongName = 'nameplate_contains_wrong_name',
    NotMountedProperly = 'not_mounted_properly',
    NoHeat = 'no_heat',
    NoHeatMultipleRadiators = 'no_heat_multiple_radiators',
    NoHeatMultipleRadiatorsCollectiveCv = 'no_heat_multiple_radiators_collective_cv',
    NoHeatMultipleRadiatorsDistrictHeating = 'no_heat_multiple_radiators_district_heating',
    NoHeatOnlyThisRadiator = 'no_heat_only_this_radiator',
    NoHotWaterMultipleFaucetsInTheHome = 'no_hot_water_multiple_faucets_in_the_home',
    NoHotWaterMultipleTapsInTheHome = 'no_hot_water_multiple_taps_in_the_home',
    NoHotWaterOnlyThisTap = 'no_hot_water_only_this_tap',
    NoWaterMultipleTapsInTheHome = 'no_water_multiple_taps_in_the_home',
    NoWaterMultipleTapsInTheHouse = 'no_water_multiple_taps_in_the_house',
    NoWaterOnlyThisFaucet = 'no_water_only_this_faucet',
    NoWaterOnlyThisTap = 'no_water_only_this_tap',
    PaintingOutdatedOrDamaged = 'painting_outdated_or_damaged',
    PanelingBroken = 'paneling_broken',
    PavingSagged = 'paving_sagged',
    PavingSubsided = 'paving_subsided',
    PavingTilesLoose = 'paving_tiles_loose',
    PipeLineFrozen = 'pipe_line_frozen',
    PipeLineHangsLooseOrLeaking = 'pipe_line_hangs_loose_or_leaking',
    PipeLineMinorLeakage = 'pipe_line_minor_leakage',
    PipeLineSevereLeakage = 'pipe_line_severe_leakage',
    PipeLineTicksMakesNoice = 'pipe_line_ticks_makes_noice',
    PlantingBrokenGoneOrNuisance = 'planting_broken_gone_or_nuisance',
    PlantingOvergrowthOrNuisance = 'planting_overgrowth_or_nuisance',
    PlasticGutterClogged = 'plastic_gutter_clogged',
    PlasticGutterLeaks = 'plastic_gutter_leaks',
    PlasticGutterLeaksOrIsClogged = 'plastic_gutter_leaks_or_is_clogged',
    PullSwitchFromLightingBroken = 'pull_switch_from_lighting_broken',
    PullSwitchOfDishwasherBroken = 'pull_switch_of_dishwasher_broken',
    PuttyLeaksOrLoose = 'putty_leaks_or_loose',
    RefillingHeatingSystem = 'refilling_heating_system',
    RustIssue = 'rust_issue',
    RoofLeaks = 'roof_leaks',
    SevereLeakage = 'severe_leakage',
    ShadesOrSunshadesBrokenOrHangingLoose = 'shades_or_sunshades_broken_or_hanging_loose',
    SillBroken = 'sill_broken',
    SillLooseOrDamaged = 'sill_loose_or_damaged',
    SinkClogged = 'sink_clogged',
    SinkCrackedOrDamaged = 'sink_cracked_or_damaged',
    SinkHangsLooseOrLeaks = 'sink_hangs_loose_or_leaks',
    SinkIsHangingLooseOrLeaking = 'sink_is_hanging_loose_or_leaking',
    SinkLeaks = 'sink_leaks',
    SinkMirrorIsLooseOrBroken = 'sink_mirror_is_loose_or_broken',
    SinkOrFountainClogged = 'sink_or_fountain_clogged',
    SinkOrFountainHangsLooseOrLeaks = 'sink_or_fountain_hangs_loose_or_leaks',
    SinkOrFountainTornOrDamaged = 'sink_or_fountain_torn_or_damaged',
    SinkShelfIsLooseOrBroken = 'sink_shelf_is_loose_or_broken',
    SinkTornOrDamaged = 'sink_torn_or_damaged',
    SkirtingBrokenOrLoose = 'skirting_broken_or_loose',
    SmokeDetectorAtMainsVoltageBeeps = 'smoke_detector_at_mains_voltage_beeps',
    SmokeDetectorAtMainsVoltageLoose = 'smoke_detector_at_mains_voltage_loose',
    SmokeDetectorWithBatteryBeeps = 'smoke_detector_with_battery_beeps',
    SmokeDetectorWithBatteryLoose = 'smoke_detector_with_battery_loose',
    StairRailIsLooseOrBroken = 'stair_rail_is_loose_or_broken',
    Stinks = 'stinks',
    StuccoCrackedOrLoose = 'stucco_cracked_or_loose',
    TankLeakage = 'tank_leakage',
    TheGutterMadeOfPlasticIsLeakingOrIsClogged = 'the_gutter_made_of_plastic_is_leaking_or_is_clogged',
    TheGutterMadeOfZincIsLeakingOrIsClogged = 'the_gutter_made_of_zinc_is_leaking_or_is_clogged',
    TheGutterMaterialUnknownIsLeakingOrIsClogged = 'the_gutter_material_unknown_is_leaking_or_is_clogged',
    ThresholdIsLooseOrIsDamaged = 'threshold_is_loose_or_is_damaged',
    ThresholdLooseOrDamaged = 'threshold_loose_or_damaged',
    Ticks = 'ticks',
    TileOrGroutBrokenOrLoose = 'tile_or_grout_broken_or_loose',
    ToiletBowlClogged = 'toilet_bowl_clogged',
    ToiletBowlLooseOrLeaking = 'toilet_bowl_loose_or_leaking',
    ToiletBowlTornOrDamaged = 'toilet_bowl_torn_or_damaged',
    ToiletCisternBroken = 'toilet_cistern_broken',
    ToiletCisternContinuesToRun = 'toilet_cistern_continues_to_run',
    ToiletCisternFaucetBroken = 'toilet_cistern_faucet_broken',
    ToiletCisternGivesNoWater = 'toilet_cistern_gives_no_water',
    ToiletSeatIsLooseOrBroken = 'toilet_seat_is_loose_or_broken',
    VentilationGridBroken = 'ventilation_grid_broken',
    VentilationGridDoesNotVentilate = 'ventilation_grid_does_not_ventilate',
    VentilationGridHasNoVentilation = 'ventilation_grid_has_no_ventilation',
    VentilationGridHasToBeMounted = 'ventilation_grid_has_to_be_mounted',
    VentilationGridInWallDirty = 'ventilation_grid_in_wall_dirty',
    VentilationGridInWindowFrameOrDoorDirty = 'ventilation_grid_in_window_frame_or_door_dirty',
    VentilationGridInWindowFrameOrDoorDoesNotVentilate = 'ventilation_grid_in_window_frame_or_door_does_not_ventilate',
    VentilationGridIsDirty = 'ventilation_grid_is_dirty',
    VentilationMechanicalDirty = 'ventilation_mechanical_dirty',
    VentilationMechanicalDoesNotVentilate = 'ventilation_mechanical_does_not_ventilate',
    VentilationMechanicalGridInWallDoesNotVentilate = 'ventilation_mechanical_grid_in_wall_does_not_ventilate',
    VentilationMechanicalGridInWallMakesNoise = 'ventilation_mechanical_grid_in_wall_makes_noise',
    VentilationMechanicalGridInWallStinks = 'ventilation_mechanical_grid_in_wall_stinks',
    VentilationMechanicalMakesNoise = 'ventilation_mechanical_makes_noise',
    VentilationMechanicalStinks = 'ventilation_mechanical_stinks',
    VentilationNaturalGridInWallDoesNotVentilate = 'ventilation_natural_grid_in_wall_does_not_ventilate',
    VentilationNaturalGridInWallMakesNoise = 'ventilation_natural_grid_in_wall_makes_noise',
    VentilationNaturalGridInWallStinks = 'ventilation_natural_grid_in_wall_stinks',
    Vermin = 'vermin',
    WallpaperBrokenOrLoose = 'wallpaper_broken_or_loose',
    WashbasinDrainClogged = 'washbasin_drain_clogged',
    WashbasinDrainLeaks = 'washbasin_drain_leaks',
    WashingMachineBlocked = 'washing_machine_blocked',
    WashingMachineLeaks = 'washing_machine_leaks',
    WashingMachinePullSwitchBroken = 'washing_machine_pull_switch_broken',
    WashingMachineTapNoWater = 'washing_machine_tap_no_water',
    WashingMachineValveLeaksOrIsLoose = 'washing_machine_valve_leaks_or_is_loose',
    WaspNest = 'wasp_nest',
    WaterPipesDrainValveLeakingOrBroken = 'water_pipes_drain_valve_leaking_or_broken',
    WaterPipesFrozen = 'water_pipes_frozen',
    WaterPipesHangsLooseOrLeaks = 'water_pipes_hangs_loose_or_leaks',
    WaterPipesMinorLeakage = 'water_pipes_minor_leakage',
    WaterPipesSevereLeakage = 'water_pipes_severe_leakage',
    WaterPipesTapsMakesNoise = 'water_pipes_taps_makes_noise',
    WaterPipesTicksMakesNoise = 'water_pipes_ticks_makes_noise',
    WindowBroken = 'window_broken',
    WindowGlassIsLoose = 'window_glass_is_loose',
    WoodRot = 'wood_rot',
    WtwBalancedVentilationReplacingFilters = 'wtw_balanced_ventilation_replacing_filters',
    ZincGutterClogged = 'zinc_gutter_clogged',
    ZincGutterLeaks = 'zinc_gutter_leaks',
    ZincGutterLeaksOrClogged = 'zinc_gutter_leaks_or_clogged',
    ChainBroken = 'chain_broken',
    ConnectionProblem = 'connection_problem',
    Dirty = 'dirty',
    Flooded = 'flooded',
    GlassIsDamaged = 'glass_is_damaged',
    Lamp = 'lamp',
    LowInternetSpeed = 'low_internet_speed',
    Luminaire = 'luminaire',
    MajorLeakage = 'major_leakage',
    NoInternetSignal = 'no_internet_signal',
    NoWifiSignal = 'no_wifi_signal',
    PlatformNotWorking = 'platform_not_working',
    PlatformWorkingSlow = 'platform_working_slow',
    PoorWiFiCoverage = 'poor_WiFi_coverage',
    RouterFailure = 'router_failure',
    WaterFlooding = 'water_flooding'
}



