/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateGeneralRequestSerializerDTO } from '../dto';
// @ts-ignore
import { GeneralRequestDetailSerializerDTO } from '../dto';
// @ts-ignore
import { GeneralRequestSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { PatchGeneralRequestSerializerDTO } from '../dto';
/**
 * GeneralRequestsAPI - axios parameter creator
 * @export
 */
export const GeneralRequestsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create General Request
         * @param {CreateGeneralRequestSerializerDTO} createGeneralRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralRequestGeneralRequestsPost: async (createGeneralRequestSerializerDTO: CreateGeneralRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createGeneralRequestSerializerDTO' is not null or undefined
            assertParamExists('createGeneralRequestGeneralRequestsPost', 'createGeneralRequestSerializerDTO', createGeneralRequestSerializerDTO)
            const localVarPath = `/general-requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGeneralRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get General Request Details
         * @param {string} generalRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet: async (generalRequestUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'generalRequestUuid' is not null or undefined
            assertParamExists('getGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet', 'generalRequestUuid', generalRequestUuid)
            const localVarPath = `/general-requests/{general_request_uuid}`
                .replace(`{${"general_request_uuid"}}`, encodeURIComponent(String(generalRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch General Request
         * @param {string} generalRequestUuid 
         * @param {PatchGeneralRequestSerializerDTO} patchGeneralRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch: async (generalRequestUuid: string, patchGeneralRequestSerializerDTO: PatchGeneralRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'generalRequestUuid' is not null or undefined
            assertParamExists('patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch', 'generalRequestUuid', generalRequestUuid)
            // verify required parameter 'patchGeneralRequestSerializerDTO' is not null or undefined
            assertParamExists('patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch', 'patchGeneralRequestSerializerDTO', patchGeneralRequestSerializerDTO)
            const localVarPath = `/general-requests/{general_request_uuid}`
                .replace(`{${"general_request_uuid"}}`, encodeURIComponent(String(generalRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchGeneralRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put General Request
         * @param {string} generalRequestUuid 
         * @param {CreateGeneralRequestSerializerDTO} createGeneralRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGeneralRequestGeneralRequestsGeneralRequestUuidPut: async (generalRequestUuid: string, createGeneralRequestSerializerDTO: CreateGeneralRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'generalRequestUuid' is not null or undefined
            assertParamExists('putGeneralRequestGeneralRequestsGeneralRequestUuidPut', 'generalRequestUuid', generalRequestUuid)
            // verify required parameter 'createGeneralRequestSerializerDTO' is not null or undefined
            assertParamExists('putGeneralRequestGeneralRequestsGeneralRequestUuidPut', 'createGeneralRequestSerializerDTO', createGeneralRequestSerializerDTO)
            const localVarPath = `/general-requests/{general_request_uuid}`
                .replace(`{${"general_request_uuid"}}`, encodeURIComponent(String(generalRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGeneralRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralRequestsAPI - functional programming interface
 * @export
 */
export const GeneralRequestsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralRequestsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create General Request
         * @param {CreateGeneralRequestSerializerDTO} createGeneralRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGeneralRequestGeneralRequestsPost(createGeneralRequestSerializerDTO: CreateGeneralRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGeneralRequestGeneralRequestsPost(createGeneralRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get General Request Details
         * @param {string} generalRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet(generalRequestUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralRequestDetailSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet(generalRequestUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch General Request
         * @param {string} generalRequestUuid 
         * @param {PatchGeneralRequestSerializerDTO} patchGeneralRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch(generalRequestUuid: string, patchGeneralRequestSerializerDTO: PatchGeneralRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch(generalRequestUuid, patchGeneralRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put General Request
         * @param {string} generalRequestUuid 
         * @param {CreateGeneralRequestSerializerDTO} createGeneralRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGeneralRequestGeneralRequestsGeneralRequestUuidPut(generalRequestUuid: string, createGeneralRequestSerializerDTO: CreateGeneralRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGeneralRequestGeneralRequestsGeneralRequestUuidPut(generalRequestUuid, createGeneralRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralRequestsAPI - factory interface
 * @export
 */
export const GeneralRequestsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralRequestsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Create General Request
         * @param {CreateGeneralRequestSerializerDTO} createGeneralRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeneralRequestGeneralRequestsPost(createGeneralRequestSerializerDTO: CreateGeneralRequestSerializerDTO, options?: any): AxiosPromise<GeneralRequestSerializerDTO> {
            return localVarFp.createGeneralRequestGeneralRequestsPost(createGeneralRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get General Request Details
         * @param {string} generalRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet(generalRequestUuid: string, options?: any): AxiosPromise<GeneralRequestDetailSerializerDTO> {
            return localVarFp.getGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet(generalRequestUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch General Request
         * @param {string} generalRequestUuid 
         * @param {PatchGeneralRequestSerializerDTO} patchGeneralRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch(generalRequestUuid: string, patchGeneralRequestSerializerDTO: PatchGeneralRequestSerializerDTO, options?: any): AxiosPromise<GeneralRequestSerializerDTO> {
            return localVarFp.patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch(generalRequestUuid, patchGeneralRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put General Request
         * @param {string} generalRequestUuid 
         * @param {CreateGeneralRequestSerializerDTO} createGeneralRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGeneralRequestGeneralRequestsGeneralRequestUuidPut(generalRequestUuid: string, createGeneralRequestSerializerDTO: CreateGeneralRequestSerializerDTO, options?: any): AxiosPromise<GeneralRequestSerializerDTO> {
            return localVarFp.putGeneralRequestGeneralRequestsGeneralRequestUuidPut(generalRequestUuid, createGeneralRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createGeneralRequestGeneralRequestsPost operation in GeneralRequestsAPI.
 * @export
 * @interface GeneralRequestsAPICreateGeneralRequestGeneralRequestsPostRequest
 */
export interface GeneralRequestsAPICreateGeneralRequestGeneralRequestsPostRequest {
    /**
     * 
     * @type {CreateGeneralRequestSerializerDTO}
     * @memberof GeneralRequestsAPICreateGeneralRequestGeneralRequestsPost
     */
    readonly createGeneralRequestSerializerDTO: CreateGeneralRequestSerializerDTO
}

/**
 * Request parameters for getGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet operation in GeneralRequestsAPI.
 * @export
 * @interface GeneralRequestsAPIGetGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGetRequest
 */
export interface GeneralRequestsAPIGetGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralRequestsAPIGetGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet
     */
    readonly generalRequestUuid: string
}

/**
 * Request parameters for patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch operation in GeneralRequestsAPI.
 * @export
 * @interface GeneralRequestsAPIPatchGeneralRequestGeneralRequestsGeneralRequestUuidPatchRequest
 */
export interface GeneralRequestsAPIPatchGeneralRequestGeneralRequestsGeneralRequestUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralRequestsAPIPatchGeneralRequestGeneralRequestsGeneralRequestUuidPatch
     */
    readonly generalRequestUuid: string

    /**
     * 
     * @type {PatchGeneralRequestSerializerDTO}
     * @memberof GeneralRequestsAPIPatchGeneralRequestGeneralRequestsGeneralRequestUuidPatch
     */
    readonly patchGeneralRequestSerializerDTO: PatchGeneralRequestSerializerDTO
}

/**
 * Request parameters for putGeneralRequestGeneralRequestsGeneralRequestUuidPut operation in GeneralRequestsAPI.
 * @export
 * @interface GeneralRequestsAPIPutGeneralRequestGeneralRequestsGeneralRequestUuidPutRequest
 */
export interface GeneralRequestsAPIPutGeneralRequestGeneralRequestsGeneralRequestUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralRequestsAPIPutGeneralRequestGeneralRequestsGeneralRequestUuidPut
     */
    readonly generalRequestUuid: string

    /**
     * 
     * @type {CreateGeneralRequestSerializerDTO}
     * @memberof GeneralRequestsAPIPutGeneralRequestGeneralRequestsGeneralRequestUuidPut
     */
    readonly createGeneralRequestSerializerDTO: CreateGeneralRequestSerializerDTO
}

/**
 * GeneralRequestsAPI - object-oriented interface
 * @export
 * @class GeneralRequestsAPI
 * @extends {BaseAPI}
 */
export class GeneralRequestsAPI extends BaseAPI {
    /**
     * 
     * @summary Create General Request
     * @param {GeneralRequestsAPICreateGeneralRequestGeneralRequestsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralRequestsAPI
     */
    public createGeneralRequestGeneralRequestsPost(requestParameters: GeneralRequestsAPICreateGeneralRequestGeneralRequestsPostRequest, options?: any) {
        return GeneralRequestsAPIFp(this.configuration).createGeneralRequestGeneralRequestsPost(requestParameters.createGeneralRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get General Request Details
     * @param {GeneralRequestsAPIGetGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralRequestsAPI
     */
    public getGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet(requestParameters: GeneralRequestsAPIGetGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGetRequest, options?: any) {
        return GeneralRequestsAPIFp(this.configuration).getGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet(requestParameters.generalRequestUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch General Request
     * @param {GeneralRequestsAPIPatchGeneralRequestGeneralRequestsGeneralRequestUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralRequestsAPI
     */
    public patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch(requestParameters: GeneralRequestsAPIPatchGeneralRequestGeneralRequestsGeneralRequestUuidPatchRequest, options?: any) {
        return GeneralRequestsAPIFp(this.configuration).patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch(requestParameters.generalRequestUuid, requestParameters.patchGeneralRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put General Request
     * @param {GeneralRequestsAPIPutGeneralRequestGeneralRequestsGeneralRequestUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralRequestsAPI
     */
    public putGeneralRequestGeneralRequestsGeneralRequestUuidPut(requestParameters: GeneralRequestsAPIPutGeneralRequestGeneralRequestsGeneralRequestUuidPutRequest, options?: any) {
        return GeneralRequestsAPIFp(this.configuration).putGeneralRequestGeneralRequestsGeneralRequestUuidPut(requestParameters.generalRequestUuid, requestParameters.createGeneralRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
