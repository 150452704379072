import * as Sentry from '@sentry/react';
import type { AxiosResponse, AxiosError } from 'axios';
import camelCase from 'lodash-es/camelCase';
import flattenDeep from 'lodash-es/flattenDeep';
import isNil from 'lodash-es/isNil';
import snakeCase from 'lodash-es/snakeCase';

import { authClient } from '../../+auth/auth.client';
import { BackendErrorType } from '../errors';
import { parseGlobalError, transformFieldErrors } from '../form/helpers';
import { HttpStatus } from './type';

type TransformType = 'CAMEL_CASE' | 'SNAKE_CASE';

const getTransformByType = (
  type: TransformType,
): typeof camelCase | typeof snakeCase => {
  switch (type) {
    case 'SNAKE_CASE': {
      return snakeCase;
    }

    case 'CAMEL_CASE':
    default: {
      return camelCase;
    }
  }
};

const isRequestErrorNotHandled = (data: AxiosResponse['data']): boolean => {
  if (!data?.detail) return false;

  const backendErrors = Object.values(BackendErrorType);

  if (data.detail.nonFieldErrors) {
    const error = parseGlobalError(data.detail.nonFieldErrors);

    // assertion is needed because the "error" is potentially a type of the BackendErrorType
    return !error || backendErrors.includes(error as BackendErrorType);
  }

  const errors = transformFieldErrors(data.detail);
  const flattenErrors = flattenDeep(Object.values(errors));

  return flattenErrors.some((error) =>
    backendErrors.includes(error as BackendErrorType),
  );
};

export const transformKeys = (
  // TODO: Fix types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  obj: any,
  type: TransformType,
  parseKeys = false,
  // TODO: Fix types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any | undefined => {
  if (isNil(obj)) {
    return obj;
  }

  let parsedObj = obj;

  if (parseKeys) {
    try {
      parsedObj = JSON.parse(obj);
    } catch (e) {
      // do nothing
    }
  }

  if (Array.isArray(parsedObj)) {
    return parsedObj.map((v) => transformKeys(v, type));
  }

  if (parsedObj.constructor === Object) {
    return Object.keys(parsedObj).reduce(
      (result, key) => ({
        ...result,
        [getTransformByType(type)(key)]: transformKeys(parsedObj[key], type),
      }),
      {},
    );
  }

  return parsedObj;
};

export const handleError = async (
  error: AxiosError,
): Promise<AxiosError | unknown> => {
  if (!error.response) {
    return Promise.reject(error);
  }

  if (
    error.response.status === HttpStatus.BAD_REQUEST &&
    isRequestErrorNotHandled(error.response.data)
  ) {
    Sentry.captureException(error.response.data);
  }

  switch (error.response.status) {
    case HttpStatus.UNAUTHORIZED: {
      return authClient.refreshToken$(error);
    }

    default: {
      return Promise.reject(transformKeys(error.response, 'CAMEL_CASE'));
    }
  }
};
