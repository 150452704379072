import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    alignItems: 'center',
    display: 'flex',
  },
  text: {
    ml: 2,
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
