/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum IssueTypeEnumDTO {
    FireAlarmIssue = 'fire_alarm_issue',
    WaterDrainPipesIssue = 'water_drain_pipes_issue',
    KeysLockIntercomIssue = 'keys_lock_intercom_issue',
    SurfacesIssue = 'surfaces_issue',
    ElectricityIssue = 'electricity_issue',
    HeatingVentilationIssue = 'heating_ventilation_issue',
    MoistureMoldIssue = 'moisture_mold_issue',
    ToiletIssue = 'toilet_issue',
    ShowerBathIssue = 'shower_bath_issue',
    SinkIssue = 'sink_issue',
    WashingMachineIssue = 'washing_machine_issue',
    DishwasherIssue = 'dishwasher_issue',
    OvenCookerIssue = 'oven_cooker_issue',
    KitchenFanIssue = 'kitchen_fan_issue',
    RefrigeratorFreezerIssue = 'refrigerator_freezer_issue',
    KitchenSinkIssue = 'kitchen_sink_issue',
    TvInternetIssue = 'tv_internet_issue',
    KitchenUnit = 'kitchen_unit',
    KitchenAppliances = 'kitchen_appliances',
    GasIssue = 'gas_issue',
    BathroomAppliances = 'bathroom_appliances',
    FurnitureIssue = 'furniture_issue',
    InternetIssue = 'internet_issue',
    SprinklingSystemIssue = 'sprinkling_system_issue',
    GarageParkingIssue = 'garage_parking_issue',
    ElevatorsIssue = 'elevators_issue',
    MailBoxNameplateIssue = 'mail_box_nameplate_issue',
    HallwayStairsIssue = 'hallway_stairs_issue',
    LaundryRoomIssue = 'laundry_room_issue',
    StorageRoomIssue = 'storage_room_issue',
    GarbageRoomIssue = 'garbage_room_issue',
    BikeStorageIssue = 'bike_storage_issue',
    OutdoorSmellIssue = 'outdoor_smell_issue',
    WasteIssue = 'waste_issue',
    GaragePlatformParkingIssue = 'garage_platform_parking_issue',
    MechanicalVentilation = 'mechanical_ventilation',
    MovableBarrierIssue = 'movable_barrier_issue',
    SandIssue = 'sand_issue',
    FacadeIssue = 'facade_issue',
    GreenAreasIssue = 'green_areas_issue',
    PlaygroundIssue = 'playground_issue',
    SnowRemovalIssue = 'snow_removal_issue',
    ContainerGarbageIssue = 'container_garbage_issue',
    FencePavingPlantingIssue = 'fence_paving_planting_issue',
    RooftopIssue = 'rooftop_issue',
    VerminIssue = 'vermin_issue',
    BicycleParkingIssue = 'bicycle_parking_issue',
    StreetWorkoutZoneEquipmentIssue = 'street_workout_zone_equipment_issue',
    Other = 'other'
}



