import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import type { UserSerializerDTO } from '../../../../../connectors/user';
import { Box, Form as GenericForm, useAsync } from '../../../../shared';
import PATHS from '../../../paths.const';
import type { Values } from './form-model.const';
import { getConfig, getFieldsConfig } from './form-model.const';

export const Form: FC = () => {
  const navigate = useNavigate();
  const { response } = useAsync<UserSerializerDTO | undefined>();
  const fieldsConfig = useMemo(() => getFieldsConfig(response), [response]);
  const navigateToDetails = useCallback(
    (id: UserSerializerDTO['uuid']) =>
      navigate(generatePath(PATHS.DETAILS, { id })),
    [navigate],
  );
  const config = useMemo(() => getConfig(response?.uuid), [response?.uuid]);

  return (
    <Box>
      <GenericForm<Values>
        config={config}
        fieldsConfig={fieldsConfig}
        handleSuccess={(response: UserSerializerDTO) =>
          navigateToDetails(response.uuid)
        }
        onCancel={response ? () => navigateToDetails(response.uuid) : undefined}
        submitLabelKey={!response ? 'user.form.actions.submit' : undefined}
      />
    </Box>
  );
};
