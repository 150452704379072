import React from 'react';

import { PermissionGuard } from '../+auth/shared/guards/permission.guard';
import { APP_MAIN_PATH } from '../routing';
import { componentLazyImport } from '../routing/routes.helper';
import { VIEW_PERMISSIONS } from './shared';

const List = componentLazyImport(
  () => import('./List/list.component'),
  'ACTIVITY_LOG_LIST',
);

const routes = {
  element: (
    <PermissionGuard
      fallbackRoute={APP_MAIN_PATH}
      permissions={VIEW_PERMISSIONS}
    >
      <List />
    </PermissionGuard>
  ),
  path: 'activity-log',
};

export default routes;
