import { ReactComponent as ArchitectureDoorIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as SingleManHomeIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-man/single-man-home.svg';
import type { GridSize } from '@mui/material/Grid/Grid';
import type { Breakpoint } from '@mui/system/createTheme/createBreakpoints';
import type { Key } from 'react';
import * as Yup from 'yup';

import { unitClient } from '../../../../../+unit';
import type {
  ListAttachmentSerializerDTO,
  ServiceRequestDetailSerializerDTO,
} from '../../../../../../connectors/ticket';
import {
  EntityEnumDTO,
  PriorityTypeEnumDTO,
  TicketStatusEnumDTO,
  TicketVisibilityEnumDTO,
} from '../../../../../../connectors/ticket';
import type { FieldConfig } from '../../../../../shared';
import {
  ATTACHMENT_MAX_FILE_SIZE,
  attachmentConvertToFiles,
  attachmentSupportedFileTypeExtensions,
  DEFAULT_FIELD_VALUE,
  getUserName,
} from '../../../../../shared';
import { DEFAULT_ASYNC_AUTOCOMPLETE_VALUE } from '../../../../../shared/form/form.const';
import { ticketClient } from '../../../../ticket.client';
import { Reporter } from '../../../enums';
import {
  departmentTypeOptions,
  getEntityUnitSuggestions$,
  getTenantFieldInitialValue,
  isUnitEntity,
  priorityTypeOptions,
  reporterTypeOptions,
} from '../../../helpers';
import type { RequestDetailsData, ServiceRequestValues } from '../../../types';

type FieldsetConfig = {
  fields: FieldConfig<ServiceRequestValues>[];
  key: Key;
  titleKey: GenericTypes.TranslationLabel;
  size?: Record<Breakpoint, GridSize>;
  textKey?: GenericTypes.TranslationLabel;
};

export const getFieldsetsConfig = (
  isStepper: boolean,
  details?: RequestDetailsData<ServiceRequestDetailSerializerDTO>,
  attachments: ListAttachmentSerializerDTO['results'] = [],
): FieldsetConfig[] => {
  const assigneeInitialValue = details?.assignee
    ? {
        label: getUserName(details.assignee),
        value: details.assignee.uuid,
      }
    : undefined;
  const isUpdateMode = Boolean(details?.reporter);

  return [
    {
      fields: [
        {
          componentType: 'input',
          field: {
            initialValue: details?.summary || DEFAULT_FIELD_VALUE,
            name: 'summary',
          },
          props: {
            disabled:
              details?.status && details.status !== TicketStatusEnumDTO.Open,
            labelKey: 'ticket.fields.summary.label',
            placeholderKey: 'ticket.fields.summary.placeholder',
            required: true,
            type: 'text',
          },
        },
        {
          componentType: 'input',
          field: {
            initialValue: details?.description || DEFAULT_FIELD_VALUE,
            name: 'description',
          },
          props: {
            disabled:
              details?.status && details.status !== TicketStatusEnumDTO.Open,
            labelKey: 'ticket.fields.description',
            required: true,
            type: 'textarea',
          },
        },
        {
          componentType: 'switch',
          field: {
            initialValue:
              details?.visibility !== TicketVisibilityEnumDTO.Public,
            name: 'isPrivate',
          },
          props: {
            helperTextKey: 'ticket.fields.visibility.helperText',
            labelKey: 'ticket.fields.visibility.label',
          },
        },
      ],
      key: 'service-request-details-fieldset',
      textKey: 'ticket.fieldset.details.text',
      titleKey: 'ticket.fieldset.details.title',
    },
    ...(isUpdateMode
      ? []
      : ([
          {
            fields: [
              {
                componentType: 'select',
                field: {
                  initialValue: details?.department,
                  name: 'department',
                },
                props: {
                  labelKey: 'ticket.fields.department',
                  options: departmentTypeOptions,
                },
              },
              {
                componentType: 'async-autocomplete',
                field: {
                  initialValue:
                    assigneeInitialValue || DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
                  name: 'assignee',
                },
                props: {
                  callback$: (query, { city, country, entity, entityType }) =>
                    country && city?.value && entity?.value
                      ? ticketClient.getAssigneeSuggestions$(
                          country,
                          query,
                          city.value,
                          entityType,
                          entity.value,
                          entity?.additionalValues?.propertyObject?.uuid,
                        )
                      : Promise.resolve([]),
                  disableWhen: ({ city, country, entity, entityType }) =>
                    !country || !city?.value || !entity?.value || !entityType,
                  labelKey: 'ticket.fields.assignee',
                },
              },
            ],
            key: 'service-request-responsibility-fieldset',
            size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
            textKey: 'ticket.fieldset.responsibility.text',
            titleKey: 'ticket.fieldset.responsibility.title',
          },
        ] as FieldsetConfig[])),
    // The reporter field should be visible only during the creation
    ...(isUpdateMode
      ? []
      : ([
          {
            fields: [
              {
                componentType: 'select',
                field: {
                  initialValue: Reporter.TENANT,
                  name: 'reporter',
                },
                props: {
                  labelKey: 'ticket.fields.reporter.label',
                  options: reporterTypeOptions,
                  relatedFields: ['apartment', 'tenant'],
                  required: true,
                },
              },
              {
                componentType: 'async-autocomplete',
                field: {
                  initialValue:
                    details?.entity && isUnitEntity(details.entity)
                      ? {
                          label: details.entity.name,
                          value: details.entity.uuid,
                        }
                      : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
                  name: 'apartment',
                  validationSchema: Yup.mixed().when(['reporter'], {
                    is: (reporter: Reporter) => reporter === Reporter.TENANT,
                    otherwise: Yup.mixed().nullable(),
                    then: Yup.mixed().required('form.errors.required'),
                  }),
                },
                props: {
                  callback$: (query, { city, country, entity, entityType }) =>
                    getEntityUnitSuggestions$(
                      query,
                      entityType,
                      city?.value,
                      country,
                      entity?.value,
                    ),
                  disableWhen: ({ country, city, entityType, reporter }) =>
                    !country ||
                    !city?.value ||
                    reporter !== Reporter.TENANT ||
                    entityType === EntityEnumDTO.Unit,
                  isHidden: ({ reporter }) => reporter === Reporter.ME,
                  labelKey: 'ticket.fields.apartment.label',
                  placeholderKey: 'ticket.fields.apartment.placeholder',
                  relatedFields: ['tenant'],
                  requiredWhen: ({ reporter }) => reporter === Reporter.TENANT,
                  startAdornmentIcon: ArchitectureDoorIcon,
                },
              },
              {
                componentType: 'async-autocomplete',
                field: {
                  initialValue: getTenantFieldInitialValue(details),
                  name: 'tenant',
                  validationSchema: Yup.mixed().when(['reporter'], {
                    is: (reporter: Reporter) => reporter === Reporter.TENANT,
                    otherwise: Yup.mixed().nullable(),
                    then: Yup.mixed().required('form.errors.required'),
                  }),
                },
                props: {
                  callback$: (query, { apartment }) =>
                    apartment
                      ? unitClient.getTenantSuggestions$(apartment.value, query)
                      : Promise.resolve([]),
                  disableWhen: ({ reporter }) => reporter !== Reporter.TENANT,
                  isHidden: ({ reporter }) => reporter === Reporter.ME,
                  labelKey: 'ticket.fields.tenant.label',
                  placeholderKey: 'ticket.fields.tenant.placeholder',
                  requiredWhen: ({ reporter }) => reporter === Reporter.TENANT,
                  startAdornmentIcon: SingleManHomeIcon,
                },
              },
            ],
            key: 'service-request-reporter-fieldset',
            size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
            textKey: 'ticket.fieldset.reporter.text',
            titleKey: 'ticket.fieldset.reporter.title',
          },
        ] as FieldsetConfig[])),
    {
      ...(isUpdateMode && { size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 } }),
      fields: [
        {
          componentType: 'select',
          field: {
            initialValue: details?.priority || PriorityTypeEnumDTO.Medium,
            name: 'priority',
          },
          props: {
            labelKey: 'ticket.fields.priority',
            options: priorityTypeOptions,
            size: {
              lg: isStepper && !isUpdateMode ? 6 : 12,
              md: isStepper && !isUpdateMode ? 6 : 12,
              sm: isStepper && !isUpdateMode ? 6 : 12,
              xl: isStepper && !isUpdateMode ? 6 : 12,
              xs: 12,
            },
          },
        },
      ],
      key: 'service-request-priority-fieldset',
      textKey: 'ticket.fieldset.priority.text',
      titleKey: 'ticket.fieldset.priority.title',
    },
    {
      fields: [
        {
          componentType: 'file',
          field: {
            initialValue: attachmentConvertToFiles(attachments),
            name: 'attachments',
          },
          props: {
            accept: attachmentSupportedFileTypeExtensions,
            maxSize: ATTACHMENT_MAX_FILE_SIZE,
          },
        },
      ],
      key: 'service-request-attachment-fieldset',
      size: { lg: 12, md: 12, sm: 12, xl: 12, xs: 12 },
      textKey: 'ticket.fieldset.attachment.text',
      titleKey: 'ticket.fieldset.attachment.title',
    },
  ];
};
