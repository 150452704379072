import { ReactComponent as PencilWriteIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/edit/pen-write.svg';
import React from 'react';
import { generatePath } from 'react-router';

import {
  getPropertyObjectCategoryTranslationLabelKey,
  PROPERTY_OBJECT_PATHS,
  propertyObjectCategoryOptions,
} from '../../+property-object';
import { getTicketTypeIcon } from '../../+ticket';
import type {
  PropertyObjectCategoryEnumDTO,
  PropertyObjectSerializerDTO,
} from '../../../connectors/property';
import { TicketTypeEnumDTO } from '../../../connectors/ticket';
import type { ColumnConfig, FieldConfig } from '../../shared';
import { getDisplayValue, ListActions, TranslationCell } from '../../shared';
import { DEFAULT_SELECT_OPTION } from '../../shared/form/form.const';

export const columnConfigs: ColumnConfig<PropertyObjectSerializerDTO>[] = [
  {
    id: 'name',
    labelKey: 'propertyObject.fields.name',
    propertyKey: 'name',
    sortConfig: { asc: 'name', desc: '-name' },
  },
  {
    getContent: ({ category }) => (
      <TranslationCell
        labelKey={getPropertyObjectCategoryTranslationLabelKey(category)}
      />
    ),
    id: 'category',
    labelKey: 'propertyObject.fields.category.label',
  },
  {
    id: 'floors',
    labelKey: 'propertyObject.fields.floors',
    propertyKey: 'floors',
  },
  {
    getContent: ({ area }) => (
      <TranslationCell
        labelKey="metricUnit.squareMetre"
        options={{
          value: getDisplayValue(area).value,
        }}
      />
    ),
    id: 'area',
    labelKey: 'propertyObject.fields.area',
    sortConfig: { asc: 'area', desc: '-area' },
  },
  {
    id: 'residentialHomeCount',
    labelKey: 'propertyObject.fields.residentialHomeCount',
    propertyKey: 'residentialHomeCount',
  },
  {
    align: 'right',
    getContent: ({ uuid }) => {
      const elements = [
        {
          icon: getTicketTypeIcon(TicketTypeEnumDTO.ServiceRequest),
          key: 'service-request',
          to: generatePath(PROPERTY_OBJECT_PATHS.SERVICE_REQUEST_LIST, {
            id: uuid,
          }),
        },
        {
          icon: PencilWriteIcon,
          key: 'property-object-update',
          to: generatePath(PROPERTY_OBJECT_PATHS.UPDATE, { id: uuid }),
        },
      ];

      return <ListActions elements={elements} />;
    },
    id: 'actions',
    labelKey: 'list.actionsLabel',
  },
];

export type FilterValues = {
  category: PropertyObjectCategoryEnumDTO | typeof DEFAULT_SELECT_OPTION.value;
};

export const INITIAL_FILTER_VALUES: FilterValues = {
  category: DEFAULT_SELECT_OPTION.value,
};

export const INITIAL_SORT = '-area';

export const filterFieldsConfig: FieldConfig<FilterValues>[] = [
  {
    componentType: 'select',
    field: {
      initialValue: INITIAL_FILTER_VALUES.category,
      name: 'category',
    },
    props: {
      isFilter: true,
      labelKey: 'propertyObject.fields.category.label',
      options: [DEFAULT_SELECT_OPTION, ...propertyObjectCategoryOptions],
    },
  },
];
