import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../../../theme';

const container: SxProps<Theme> = {
  borderRadius: (theme) => theme.spacing(2),
  mb: 1,
  mr: 2,
  mt: 1,
  position: 'relative',
};

export const sxProps: Record<string, SxProps<Theme>> = {
  documentContainer: {
    ...container,
    border: (theme) => `2px solid ${theme.palette.divider}`,
    display: 'flex',
    p: 2,
  },
  icon: {
    mr: 1,
  },
  imageContainer: {
    ...container,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100px',
    width: '100px',
  },
  name: {
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  removeButton: {
    '&:hover': {
      background: palette.white,
    },
    background: palette.white,
    p: 0.25,
    position: 'absolute',
    right: '-10px',
    top: '-10px',
  },
};
