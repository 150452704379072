import Avatar from '@mui/material/Avatar';
import MUIBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';
import { Trans } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { sxProps } from './object-row.styles';

type Props = {
  id: string;
  subtitleKey: GenericTypes.TranslationLabel;
  title: string;
  avatarSrc?: GenericTypes.URL;
  path?: string;
};

export const ObjectRow: FC<Props> = (props) => {
  const { avatarSrc, id, subtitleKey, title, path } = props;

  return (
    <MUIBox display="flex">
      <Avatar src={avatarSrc} sx={sxProps.avatar} />
      <MUIBox>
        <Typography
          color="primary"
          variant="subtitle2"
          {...(path && { component: Link, to: generatePath(path, { id }) })}
        >
          {title}
        </Typography>
        <Typography color="secondary" variant="body2">
          <Trans>{subtitleKey}</Trans>
        </Typography>
      </MUIBox>
    </MUIBox>
  );
};
