import { ReactComponent as CalendarFavourite1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-favorite-1.svg';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';

import { FormattedDate, useTranslation } from '../../../../shared';
import { sxProps } from './timeline.styles';

type Event = {
  date: GenericTypes.DateISO;
  labelKey: GenericTypes.TranslationLabel;
  position: number;
};

type Props = {
  events: Event[];
};

export const Timeline: FC<Props> = (props) => {
  const { events } = props;
  const { t } = useTranslation();

  return (
    <Box sx={sxProps.box}>
      <Box sx={sxProps.timeline}>
        {events.map((event) => (
          <Box
            key={new Date(event.date).getTime()}
            sx={{ ...sxProps.point, left: `${event.position}%` }}
          >
            <Box sx={sxProps.dot} />
            <Box sx={sxProps.info}>
              <Typography variant="overline">{t(event.labelKey)}</Typography>
              <Typography sx={sxProps.text} variant="body1">
                <CalendarFavourite1Icon height={24} width={24} />
                <Box component="span">
                  <FormattedDate date={event.date} format="standardDateTime" />
                </Box>
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
