import Grid from '@mui/material/Grid';
import isEqual from 'lodash-es/isEqual';
import type { FC, Dispatch, SetStateAction } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import type { FieldConfig } from '../../form';
import { Form, prepareInitialValues } from '../../form';
import type { ListState } from '../List';

type Props<T> = {
  fieldsConfig: FieldConfig<T>[];
  listState: ListState<T>;
  setListState: Dispatch<SetStateAction<ListState<T>>>;
};

export const FilterPanel = <T,>(props: Props<T>): ReturnType<FC<Props<T>>> => {
  const { fieldsConfig, listState, setListState } = props;
  const initialValues = useMemo(
    () => ({ ...listState.filters, ...prepareInitialValues(fieldsConfig) }),
    [fieldsConfig, listState],
  );
  const [filters, setFilters] = useState(initialValues);
  const setFiltersModel = useCallback(() => {
    setListState((prevState) => ({
      ...prevState,
      filters: !isEqual(filters, prevState.filters)
        ? filters
        : prevState.filters,
      paginationConfig: !isEqual(filters, prevState.filters)
        ? { ...prevState.paginationConfig, currentPage: 1 }
        : prevState.paginationConfig,
    }));
  }, [filters, setListState]);

  useEffect(() => {
    setFiltersModel();
  }, [setFiltersModel]);

  return (
    <Grid container data-testid="filter-panel">
      <Grid item xs={12}>
        <Form
          config={{
            onSubmit: () => {
              // do nothing
            },
          }}
          fieldsConfig={fieldsConfig}
          inline
          onChange={setFilters} // TODO: Use formEffect instead
          showButtons={false}
          spacing={2}
        />
      </Grid>
    </Grid>
  );
};
