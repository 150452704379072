import { ReactComponent as CalendarAddIcon1 } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-add-1.svg';
import { ReactComponent as CalendarRefreshIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-refresh.svg';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

import type { ProspectDetailsReadOnlySerializerDTO } from '../../../../../connectors/property';
import { ProspectStatusEnumDTO } from '../../../../../connectors/property';
import {
  ConfirmationDelete,
  Dialog,
  Feature,
  FormattedDate,
  useAsync,
  useTranslation,
} from '../../../../shared';
import { prospectClient } from '../../../prospect.client';
import { StatusCell } from '../StatusCell/status-cell.component';
import { ViewingScheduleForm } from '../ViewingScheduleForm/viewing-schedule-form.component';
import { sxProps } from './viewing-schedule-button.styles';

type Props = {
  prospect: ProspectDetailsReadOnlySerializerDTO;
};

export const ViewingScheduleButton: FC<Props> = (props) => {
  const { prospect } = props;
  const { setResponse } = useAsync<ProspectDetailsReadOnlySerializerDTO>();
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const closeDialog = useCallback(() => setDialogOpen(false), []);
  const openDialog = useCallback(() => setDialogOpen(true), []);
  const isProspectCancelled = useMemo(
    () => prospect.status === ProspectStatusEnumDTO.ProspectCancelled,
    [prospect.status],
  );
  const isViewingDateCancelled = useMemo(
    () => prospect.status === ProspectStatusEnumDTO.ViewingCanceled,
    [prospect.status],
  );
  const handleSuccess = useCallback(
    (value: ProspectDetailsReadOnlySerializerDTO) => {
      setResponse(value);
      closeDialog();
    },
    [closeDialog, setResponse],
  );
  const handleCancel$ = useCallback(async () => {
    if (isViewingDateCancelled || isProspectCancelled) return;

    // eslint-disable-next-line no-useless-catch
    try {
      const data = await prospectClient.viewingCancel$(prospect.uuid);

      setResponse(data);
    } catch (e) {
      throw e;
    }
  }, [isProspectCancelled, isViewingDateCancelled, prospect.uuid, setResponse]);

  return (
    <>
      <Box>
        {prospect.agreedDatetimeForViewing ? (
          <Grid alignItems="center" container wrap="nowrap">
            <Feature
              dataTestId="viewing-date"
              icon={<CalendarRefreshIcon height={24} width={24} />}
              titleKey="prospect.fields.viewingDate"
              value={
                <Grid alignItems="center" container>
                  <FormattedDate
                    format="standardDateTime"
                    date={prospect.agreedDatetimeForViewing}
                  />
                  {isViewingDateCancelled && (
                    <Box sx={sxProps.statusCell}>
                      <StatusCell status={prospect.status} />
                    </Box>
                  )}
                  {!isProspectCancelled && !isViewingDateCancelled && (
                    <>
                      <Button
                        color="info"
                        onClick={openDialog}
                        size="small"
                        sx={sxProps.editButton}
                        variant="contained"
                      >
                        {t('prospect.entity.viewing.reschedule')}
                      </Button>
                      <ConfirmationDelete
                        callback$={handleCancel$}
                        confirmationTextKey="prospect.cancel.text"
                        confirmButtonTitleKey="prospect.cancel.button.confirm"
                        declineButtonTitleKey="prospect.cancel.button.decline"
                        isIconButton={false}
                      />
                    </>
                  )}
                </Grid>
              }
            />
          </Grid>
        ) : (
          <Button
            onClick={openDialog}
            size="large"
            startIcon={<CalendarAddIcon1 height={24} width={24} />}
            variant="outlined"
          >
            {t('prospect.entity.viewing.schedule')}
          </Button>
        )}
      </Box>
      <Dialog
        enableActions={false}
        fullWidth
        isOpen={isDialogOpen}
        maxWidth="md"
        onClose={closeDialog}
        titleComponent={
          <Typography variant="h2">
            {t('prospect.entity.viewing.schedule')}
          </Typography>
        }
      >
        <Grid container mt={1} spacing={2}>
          <Grid item sm={6} xs={12}>
            <ViewingScheduleForm
              handleSuccess={handleSuccess}
              prospect={prospect}
            />
          </Grid>
          <Grid item sm={6} display={['none', 'block']}>
            <Box sx={sxProps.formIllustration} />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
