import Box from '@mui/material/Box';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { ProspectStatusEnumDTO } from '../../../../../connectors/property';
import { useTranslation } from '../../../../shared';
import { getStatusTranslationLabelKey } from '../../helpers';
import { sxProps } from './status-cell.styles';

type Props = {
  status: ProspectStatusEnumDTO;
};

export const StatusCell: FC<Props> = (props) => {
  const { status } = props;
  const { t } = useTranslation();
  const labelKey = useMemo(
    () => getStatusTranslationLabelKey(status),
    [status],
  );

  return (
    <Box
      component="span"
      sx={{ ...sxProps.box, ...sxProps[status] } as SxProps}
    >
      {t(labelKey)}
    </Box>
  );
};
