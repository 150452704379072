import type {
  ContactSerializerDTO,
  TenantSerializerDTO,
} from '../../../../connectors/property';
import { SignerRoleEnumDTO } from '../../../../connectors/property';

const signerRoleToTranslationLabelKeyMap: Record<
  SignerRoleEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [SignerRoleEnumDTO.CoTenant]: 'user.signerRole.coTenant',
  [SignerRoleEnumDTO.Depositor]: 'user.signerRole.depositor',
  [SignerRoleEnumDTO.Resident]: 'user.signerRole.resident',
  [SignerRoleEnumDTO.Tenant]: 'user.signerRole.tenant',
};

export const getSignerRoleTranslationLabelKey = (
  signerRole: SignerRoleEnumDTO | SignerRoleEnumDTO[],
): GenericTypes.TranslationLabel =>
  Array.isArray(signerRole)
    ? signerRoleToTranslationLabelKeyMap[signerRole[0]]
    : signerRoleToTranslationLabelKeyMap[signerRole] || '';

export const isSigner = (
  roles: ContactSerializerDTO['roles'] | TenantSerializerDTO['roles'],
): boolean => {
  const signerRoles = Object.values(SignerRoleEnumDTO);

  return Boolean(
    roles?.some((role) => signerRoles.includes(role as SignerRoleEnumDTO)),
  );
};
