/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateServiceRequestSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { ListTicketsSerializerDTO } from '../dto';
// @ts-ignore
import { PatchServiceRequestSerializerDTO } from '../dto';
// @ts-ignore
import { ServiceRequestDetailSerializerDTO } from '../dto';
// @ts-ignore
import { ServiceRequestSerializerDTO } from '../dto';
// @ts-ignore
import { TicketTypeEnumDTO } from '../dto';
// @ts-ignore
import { TicketsCountByStatusAndEntityEnumSerializerDTO } from '../dto';
/**
 * ServiceRequestsAPI - axios parameter creator
 * @export
 */
export const ServiceRequestsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Service Request
         * @param {CreateServiceRequestSerializerDTO} createServiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceRequestServiceRequestsPost: async (createServiceRequestSerializerDTO: CreateServiceRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createServiceRequestSerializerDTO' is not null or undefined
            assertParamExists('createServiceRequestServiceRequestsPost', 'createServiceRequestSerializerDTO', createServiceRequestSerializerDTO)
            const localVarPath = `/service-requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createServiceRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Service Request Details
         * @param {string} serviceRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceRequestDetailsServiceRequestsServiceRequestUuidGet: async (serviceRequestUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceRequestUuid' is not null or undefined
            assertParamExists('getServiceRequestDetailsServiceRequestsServiceRequestUuidGet', 'serviceRequestUuid', serviceRequestUuid)
            const localVarPath = `/service-requests/{service_request_uuid}`
                .replace(`{${"service_request_uuid"}}`, encodeURIComponent(String(serviceRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Service Requests By Property Uuid
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet: async (propertyUuid: string, page?: number, pageSize?: number, order?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyUuid' is not null or undefined
            assertParamExists('getServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet', 'propertyUuid', propertyUuid)
            const localVarPath = `/service-requests/property/{property_uuid}`
                .replace(`{${"property_uuid"}}`, encodeURIComponent(String(propertyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Service Requests For Entity
         * @param {string} entityUuid 
         * @param {string} entityType 
         * @param {TicketTypeEnumDTO} [ticketType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet: async (entityUuid: string, entityType: string, ticketType?: TicketTypeEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityUuid' is not null or undefined
            assertParamExists('getServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet', 'entityUuid', entityUuid)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet', 'entityType', entityType)
            const localVarPath = `/service-requests/{entity_uuid}/{entity_type}`
                .replace(`{${"entity_uuid"}}`, encodeURIComponent(String(entityUuid)))
                .replace(`{${"entity_type"}}`, encodeURIComponent(String(entityType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (ticketType !== undefined) {
                localVarQueryParameter['ticket_type'] = ticketType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Service Request
         * @param {string} serviceRequestUuid 
         * @param {PatchServiceRequestSerializerDTO} patchServiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchServiceRequestServiceRequestsServiceRequestUuidPatch: async (serviceRequestUuid: string, patchServiceRequestSerializerDTO: PatchServiceRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceRequestUuid' is not null or undefined
            assertParamExists('patchServiceRequestServiceRequestsServiceRequestUuidPatch', 'serviceRequestUuid', serviceRequestUuid)
            // verify required parameter 'patchServiceRequestSerializerDTO' is not null or undefined
            assertParamExists('patchServiceRequestServiceRequestsServiceRequestUuidPatch', 'patchServiceRequestSerializerDTO', patchServiceRequestSerializerDTO)
            const localVarPath = `/service-requests/{service_request_uuid}`
                .replace(`{${"service_request_uuid"}}`, encodeURIComponent(String(serviceRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchServiceRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Service Request
         * @param {string} serviceRequestUuid 
         * @param {CreateServiceRequestSerializerDTO} createServiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putServiceRequestServiceRequestsServiceRequestUuidPut: async (serviceRequestUuid: string, createServiceRequestSerializerDTO: CreateServiceRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceRequestUuid' is not null or undefined
            assertParamExists('putServiceRequestServiceRequestsServiceRequestUuidPut', 'serviceRequestUuid', serviceRequestUuid)
            // verify required parameter 'createServiceRequestSerializerDTO' is not null or undefined
            assertParamExists('putServiceRequestServiceRequestsServiceRequestUuidPut', 'createServiceRequestSerializerDTO', createServiceRequestSerializerDTO)
            const localVarPath = `/service-requests/{service_request_uuid}`
                .replace(`{${"service_request_uuid"}}`, encodeURIComponent(String(serviceRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createServiceRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceRequestsAPI - functional programming interface
 * @export
 */
export const ServiceRequestsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceRequestsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Service Request
         * @param {CreateServiceRequestSerializerDTO} createServiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createServiceRequestServiceRequestsPost(createServiceRequestSerializerDTO: CreateServiceRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceRequestServiceRequestsPost(createServiceRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Service Request Details
         * @param {string} serviceRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceRequestDetailsServiceRequestsServiceRequestUuidGet(serviceRequestUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceRequestDetailSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceRequestDetailsServiceRequestsServiceRequestUuidGet(serviceRequestUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Service Requests By Property Uuid
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet(propertyUuid: string, page?: number, pageSize?: number, order?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTicketsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet(propertyUuid, page, pageSize, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Service Requests For Entity
         * @param {string} entityUuid 
         * @param {string} entityType 
         * @param {TicketTypeEnumDTO} [ticketType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet(entityUuid: string, entityType: string, ticketType?: TicketTypeEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketsCountByStatusAndEntityEnumSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet(entityUuid, entityType, ticketType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Service Request
         * @param {string} serviceRequestUuid 
         * @param {PatchServiceRequestSerializerDTO} patchServiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchServiceRequestServiceRequestsServiceRequestUuidPatch(serviceRequestUuid: string, patchServiceRequestSerializerDTO: PatchServiceRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchServiceRequestServiceRequestsServiceRequestUuidPatch(serviceRequestUuid, patchServiceRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Service Request
         * @param {string} serviceRequestUuid 
         * @param {CreateServiceRequestSerializerDTO} createServiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putServiceRequestServiceRequestsServiceRequestUuidPut(serviceRequestUuid: string, createServiceRequestSerializerDTO: CreateServiceRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putServiceRequestServiceRequestsServiceRequestUuidPut(serviceRequestUuid, createServiceRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceRequestsAPI - factory interface
 * @export
 */
export const ServiceRequestsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceRequestsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Create Service Request
         * @param {CreateServiceRequestSerializerDTO} createServiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceRequestServiceRequestsPost(createServiceRequestSerializerDTO: CreateServiceRequestSerializerDTO, options?: any): AxiosPromise<ServiceRequestSerializerDTO> {
            return localVarFp.createServiceRequestServiceRequestsPost(createServiceRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Service Request Details
         * @param {string} serviceRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceRequestDetailsServiceRequestsServiceRequestUuidGet(serviceRequestUuid: string, options?: any): AxiosPromise<ServiceRequestDetailSerializerDTO> {
            return localVarFp.getServiceRequestDetailsServiceRequestsServiceRequestUuidGet(serviceRequestUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Service Requests By Property Uuid
         * @param {string} propertyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet(propertyUuid: string, page?: number, pageSize?: number, order?: string, options?: any): AxiosPromise<ListTicketsSerializerDTO> {
            return localVarFp.getServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet(propertyUuid, page, pageSize, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Service Requests For Entity
         * @param {string} entityUuid 
         * @param {string} entityType 
         * @param {TicketTypeEnumDTO} [ticketType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet(entityUuid: string, entityType: string, ticketType?: TicketTypeEnumDTO, options?: any): AxiosPromise<TicketsCountByStatusAndEntityEnumSerializerDTO> {
            return localVarFp.getServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet(entityUuid, entityType, ticketType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Service Request
         * @param {string} serviceRequestUuid 
         * @param {PatchServiceRequestSerializerDTO} patchServiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchServiceRequestServiceRequestsServiceRequestUuidPatch(serviceRequestUuid: string, patchServiceRequestSerializerDTO: PatchServiceRequestSerializerDTO, options?: any): AxiosPromise<ServiceRequestSerializerDTO> {
            return localVarFp.patchServiceRequestServiceRequestsServiceRequestUuidPatch(serviceRequestUuid, patchServiceRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Service Request
         * @param {string} serviceRequestUuid 
         * @param {CreateServiceRequestSerializerDTO} createServiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putServiceRequestServiceRequestsServiceRequestUuidPut(serviceRequestUuid: string, createServiceRequestSerializerDTO: CreateServiceRequestSerializerDTO, options?: any): AxiosPromise<ServiceRequestSerializerDTO> {
            return localVarFp.putServiceRequestServiceRequestsServiceRequestUuidPut(serviceRequestUuid, createServiceRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createServiceRequestServiceRequestsPost operation in ServiceRequestsAPI.
 * @export
 * @interface ServiceRequestsAPICreateServiceRequestServiceRequestsPostRequest
 */
export interface ServiceRequestsAPICreateServiceRequestServiceRequestsPostRequest {
    /**
     * 
     * @type {CreateServiceRequestSerializerDTO}
     * @memberof ServiceRequestsAPICreateServiceRequestServiceRequestsPost
     */
    readonly createServiceRequestSerializerDTO: CreateServiceRequestSerializerDTO
}

/**
 * Request parameters for getServiceRequestDetailsServiceRequestsServiceRequestUuidGet operation in ServiceRequestsAPI.
 * @export
 * @interface ServiceRequestsAPIGetServiceRequestDetailsServiceRequestsServiceRequestUuidGetRequest
 */
export interface ServiceRequestsAPIGetServiceRequestDetailsServiceRequestsServiceRequestUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceRequestsAPIGetServiceRequestDetailsServiceRequestsServiceRequestUuidGet
     */
    readonly serviceRequestUuid: string
}

/**
 * Request parameters for getServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet operation in ServiceRequestsAPI.
 * @export
 * @interface ServiceRequestsAPIGetServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGetRequest
 */
export interface ServiceRequestsAPIGetServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceRequestsAPIGetServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet
     */
    readonly propertyUuid: string

    /**
     * 
     * @type {number}
     * @memberof ServiceRequestsAPIGetServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ServiceRequestsAPIGetServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ServiceRequestsAPIGetServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet
     */
    readonly order?: string
}

/**
 * Request parameters for getServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet operation in ServiceRequestsAPI.
 * @export
 * @interface ServiceRequestsAPIGetServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGetRequest
 */
export interface ServiceRequestsAPIGetServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceRequestsAPIGetServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet
     */
    readonly entityUuid: string

    /**
     * 
     * @type {string}
     * @memberof ServiceRequestsAPIGetServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet
     */
    readonly entityType: string

    /**
     * 
     * @type {TicketTypeEnumDTO}
     * @memberof ServiceRequestsAPIGetServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet
     */
    readonly ticketType?: TicketTypeEnumDTO
}

/**
 * Request parameters for patchServiceRequestServiceRequestsServiceRequestUuidPatch operation in ServiceRequestsAPI.
 * @export
 * @interface ServiceRequestsAPIPatchServiceRequestServiceRequestsServiceRequestUuidPatchRequest
 */
export interface ServiceRequestsAPIPatchServiceRequestServiceRequestsServiceRequestUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceRequestsAPIPatchServiceRequestServiceRequestsServiceRequestUuidPatch
     */
    readonly serviceRequestUuid: string

    /**
     * 
     * @type {PatchServiceRequestSerializerDTO}
     * @memberof ServiceRequestsAPIPatchServiceRequestServiceRequestsServiceRequestUuidPatch
     */
    readonly patchServiceRequestSerializerDTO: PatchServiceRequestSerializerDTO
}

/**
 * Request parameters for putServiceRequestServiceRequestsServiceRequestUuidPut operation in ServiceRequestsAPI.
 * @export
 * @interface ServiceRequestsAPIPutServiceRequestServiceRequestsServiceRequestUuidPutRequest
 */
export interface ServiceRequestsAPIPutServiceRequestServiceRequestsServiceRequestUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceRequestsAPIPutServiceRequestServiceRequestsServiceRequestUuidPut
     */
    readonly serviceRequestUuid: string

    /**
     * 
     * @type {CreateServiceRequestSerializerDTO}
     * @memberof ServiceRequestsAPIPutServiceRequestServiceRequestsServiceRequestUuidPut
     */
    readonly createServiceRequestSerializerDTO: CreateServiceRequestSerializerDTO
}

/**
 * ServiceRequestsAPI - object-oriented interface
 * @export
 * @class ServiceRequestsAPI
 * @extends {BaseAPI}
 */
export class ServiceRequestsAPI extends BaseAPI {
    /**
     * 
     * @summary Create Service Request
     * @param {ServiceRequestsAPICreateServiceRequestServiceRequestsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRequestsAPI
     */
    public createServiceRequestServiceRequestsPost(requestParameters: ServiceRequestsAPICreateServiceRequestServiceRequestsPostRequest, options?: any) {
        return ServiceRequestsAPIFp(this.configuration).createServiceRequestServiceRequestsPost(requestParameters.createServiceRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Service Request Details
     * @param {ServiceRequestsAPIGetServiceRequestDetailsServiceRequestsServiceRequestUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRequestsAPI
     */
    public getServiceRequestDetailsServiceRequestsServiceRequestUuidGet(requestParameters: ServiceRequestsAPIGetServiceRequestDetailsServiceRequestsServiceRequestUuidGetRequest, options?: any) {
        return ServiceRequestsAPIFp(this.configuration).getServiceRequestDetailsServiceRequestsServiceRequestUuidGet(requestParameters.serviceRequestUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Service Requests By Property Uuid
     * @param {ServiceRequestsAPIGetServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRequestsAPI
     */
    public getServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet(requestParameters: ServiceRequestsAPIGetServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGetRequest, options?: any) {
        return ServiceRequestsAPIFp(this.configuration).getServiceRequestsByPropertyUuidServiceRequestsPropertyPropertyUuidGet(requestParameters.propertyUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Service Requests For Entity
     * @param {ServiceRequestsAPIGetServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRequestsAPI
     */
    public getServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet(requestParameters: ServiceRequestsAPIGetServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGetRequest, options?: any) {
        return ServiceRequestsAPIFp(this.configuration).getServiceRequestsForEntityServiceRequestsEntityUuidEntityTypeGet(requestParameters.entityUuid, requestParameters.entityType, requestParameters.ticketType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Service Request
     * @param {ServiceRequestsAPIPatchServiceRequestServiceRequestsServiceRequestUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRequestsAPI
     */
    public patchServiceRequestServiceRequestsServiceRequestUuidPatch(requestParameters: ServiceRequestsAPIPatchServiceRequestServiceRequestsServiceRequestUuidPatchRequest, options?: any) {
        return ServiceRequestsAPIFp(this.configuration).patchServiceRequestServiceRequestsServiceRequestUuidPatch(requestParameters.serviceRequestUuid, requestParameters.patchServiceRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Service Request
     * @param {ServiceRequestsAPIPutServiceRequestServiceRequestsServiceRequestUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceRequestsAPI
     */
    public putServiceRequestServiceRequestsServiceRequestUuidPut(requestParameters: ServiceRequestsAPIPutServiceRequestServiceRequestsServiceRequestUuidPutRequest, options?: any) {
        return ServiceRequestsAPIFp(this.configuration).putServiceRequestServiceRequestsServiceRequestUuidPut(requestParameters.serviceRequestUuid, requestParameters.createServiceRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
