export enum TicketTypePath {
  COMPLAINTS = 'complaints',
  CONTRACT_REQUESTS = 'contract-requests',
  GENERAL_REQUESTS = 'general-requests',
  INVOICE_REQUESTS = 'invoice-requests',
  SERVICE_REQUESTS = 'service-requests',
}

const PATHS = {
  ACTIVITY_LOG_LIST: '/tickets/:type/:id/logs',
  CREATE: '/tickets/:type/create',
  DETAILS: '/tickets/:type/:id',
  DOCUMENT_LIST: '/tickets/:type/:id/documents',
  LIST: '/tickets/:type',
  UPDATE: '/tickets/:type/:id/update',
};

export default PATHS;
