/* eslint-disable max-len */

export enum CustomErrorType {
  ACTION_CANCELED = 'Action canceled by user',
  PREVENT_TOAST_ERROR = 'Prevent toast error',
}

// services/common/error_constants.py
export enum BackendErrorType {
  ASSIGNEE_ROLE_NOT_ALLOWED = 'ERR_ASSIGNEE_ROLE_NOT_ALLOWED',
  BOOLEAN_REQUIRED = 'ERR_BOOLEAN_REQUIRED',
  DECIMAL_REQUIRED = 'ERR_DECIMAL_NUMBER_REQUIRED',
  EMPTY_FIELD = 'ERR_EMPTY',
  ENTRANCE_PROPERTY_OBJECT_MISMATCH = 'ERR_ENTRANCE_DOES_NOT_BELONG_TO_PROPERTY_OBJECT',
  ERR_UNEXPECTED = 'ERR_UNEXPECTED',
  FORBIDDEN = 'ERR_FORBIDDEN',
  INTEGER_REQUIRED = 'ERR_INTEGER_REQUIRED',
  INVALID_BODY_FORMAT = 'ERR_INVALID_BODY_FORMAT',
  INVALID_CHOICE = 'ERR_INVALID_CHOICE',
  INVALID_DATE_FORMAT = 'ERR_INVALID_DATE_FORMAT',
  INVALID_ENUM_TYPE = 'ERR_ENUM_TYPES',
  INVALID_FORMAT_NO_VARIABLES = 'ERR_INVALID_FORMAT_NO_VARIABLES',
  INVALID_FORMAT_VARIABLES = 'ERR_INVALID_VARIABLES',
  INVALID_VALUE = 'ERR_INVALID_VALUE',
  NOT_FOUND = 'ERR_NOT_FOUND',
  NOT_UNIQUE_FIELD = 'ERR_FIELD_IS_UNIQUE',
  OBJECT_DOES_NOT_EXIST = 'ERR_DOES_NOT_EXIST',
  OPEN_TICKET_ALREADY_EXIST = 'ERR_OPEN_TICKET_ALREADY_EXIST',
  PHONE_NUMBER_MISSING = 'ERR_PHONE_NUMBER_MISSING',
  PROSPECT_ALREADY_EXIST = 'ERR_PROSPECT_ALREADY_EXIST',
  REQUIRED_FIELD = 'ERR_REQUIRED',
  UNIT_NUMBER_ALREADY_EXISTS = 'ERR_UNIT_NUMBER_ALREADY_EXISTS',
  UPDATE_NOT_ALLOWED = 'ERR_UPDATE_NOT_ALLOWED',
  USER_NOTIFICATION_CONFIG_ALREADY_EXIST = 'ERR_USER_NOTIFICATION_CONFIG_ALREADY_EXIST',
  UUID_REQUIRED = 'ERR_UUID_REQUIRED',
}
