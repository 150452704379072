import type { ReactElement, FC } from 'react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';

import { NOT_FOUND_PATH } from '../../../routing';
import { TicketTypePath } from '../../paths.const';

type Props = {
  children: NonNullable<ReactElement>;
};

export const TypeGuard: FC<Props> = (props) => {
  const { children } = props;
  const { type } = useParams();
  const isValid = useMemo(
    () => Object.values(TicketTypePath).includes(type as TicketTypePath),
    [type],
  );

  if (!isValid) {
    return <Navigate to={NOT_FOUND_PATH} />;
  }

  return children;
};
