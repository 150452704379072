/* eslint-disable max-len */
import {
  ComplaintTypeEnumDTO,
  ContractRequestTypeEnumDTO,
  DamagedItemEnumDTO,
  DepartmentTypeEnumDTO,
  EntityEnumDTO,
  InvoiceRequestTypeEnumDTO,
  IssueSubtypeEnumDTO,
  IssueTypeEnumDTO,
  LocationTypeEnumDTO,
  PriorityTypeEnumDTO,
  RoomTypeEnumDTO,
  TicketStatusEnumDTO,
  TicketTypeEnumDTO,
} from '../../../../connectors/ticket';

const issueLocationToTranslationLabelKeyMap: Record<
  LocationTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [LocationTypeEnumDTO.Apartment]:
    'ticket.options.issueLocation.apartment.label',
  [LocationTypeEnumDTO.CommonArea]:
    'ticket.options.issueLocation.commonArea.label',
  [LocationTypeEnumDTO.CommonOutdoorArea]:
    'ticket.options.issueLocation.commonOutdoorArea.label',
  [LocationTypeEnumDTO.OutdoorArea]:
    'ticket.options.issueLocation.outdoorArea.label',
  [LocationTypeEnumDTO.PrivateOutdoorArea]:
    'ticket.options.issueLocation.privateOutdoorArea.label',
};

const issueSubtypeToTranslationLabelKeyMap: Record<
  IssueSubtypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [IssueSubtypeEnumDTO.AutomaticDoorOpenerBrokenResidentsCanEnterOrExitTheComplex]:
    'ticket.options.issueSubtype.automaticDoorOpenerBrokenResidentsCanEnterOrExitTheComplex',
  [IssueSubtypeEnumDTO.AutomaticDoorOpenerBrokenResidentsCannotEnterOrExitTheComplex]:
    'ticket.options.issueSubtype.automaticDoorOpenerBrokenResidentsCannotEnterOrExitTheComplex',
  [IssueSubtypeEnumDTO.BatteryFailure]:
    'ticket.options.issueSubtype.batteryFailure',
  [IssueSubtypeEnumDTO.BlownFuse]: 'ticket.options.issueSubtype.blownFuse',
  [IssueSubtypeEnumDTO.BookingSystemFailure]:
    'ticket.options.issueSubtype.bookingSystemFailure',
  [IssueSubtypeEnumDTO.Broken]: 'ticket.options.issueSubtype.broken',
  [IssueSubtypeEnumDTO.BrokenAlarmClock]:
    'ticket.options.issueSubtype.brokenAlarmClock',
  [IssueSubtypeEnumDTO.BrokenCooktop]:
    'ticket.options.issueSubtype.brokenCooktop',
  [IssueSubtypeEnumDTO.BrokenDoorBellIntercom]:
    'ticket.options.issueSubtype.brokenDoorBellIntercom',
  [IssueSubtypeEnumDTO.BrokenDoors]: 'ticket.options.issueSubtype.brokenDoors',
  [IssueSubtypeEnumDTO.BrokenKey]: 'ticket.options.issueSubtype.brokenKey',
  [IssueSubtypeEnumDTO.BrokenLight]: 'ticket.options.issueSubtype.brokenLight',
  [IssueSubtypeEnumDTO.BrokenLock]: 'ticket.options.issueSubtype.brokenLock',
  [IssueSubtypeEnumDTO.BrokenMailBox]:
    'ticket.options.issueSubtype.brokenMailBox',
  [IssueSubtypeEnumDTO.BrokenMirror]:
    'ticket.options.issueSubtype.brokenMirror',
  [IssueSubtypeEnumDTO.BrokenOrLoose]:
    'ticket.options.issueSubtype.brokenOrLoose',
  [IssueSubtypeEnumDTO.BrokenOven]: 'ticket.options.issueSubtype.brokenOven',
  [IssueSubtypeEnumDTO.BuiltInEquipmentDefectOwnedByHeimstaden]:
    'ticket.options.issueSubtype.builtInEquipmentDefectOwnedByHeimstaden',
  [IssueSubtypeEnumDTO.BuiltInEquipmentDefectOwnedByTenant]:
    'ticket.options.issueSubtype.builtInEquipmentDefectOwnedByTenant',
  [IssueSubtypeEnumDTO.BuiltInEquipmentDefectOwnershipUnknown]:
    'ticket.options.issueSubtype.builtInEquipmentDefectOwnershipUnknown',
  [IssueSubtypeEnumDTO.BurglaryDamageNoLongerLockable]:
    'ticket.options.issueSubtype.burglaryDamageNoLongerLockable',
  [IssueSubtypeEnumDTO.BurglaryDamageResidentsCanEnterOrExitTheComplex]:
    'ticket.options.issueSubtype.burglaryDamageResidentsCanEnterOrExitTheComplex',
  [IssueSubtypeEnumDTO.BurglaryDamageResidentsCannotEnterOrExitTheComplex]:
    'ticket.options.issueSubtype.burglaryDamageResidentsCannotEnterOrExitTheComplex',
  [IssueSubtypeEnumDTO.BurglaryDamageStillLockable]:
    'ticket.options.issueSubtype.burglaryDamageStillLockable',
  [IssueSubtypeEnumDTO.CaiAndTelephoneConnectionIsLooseOrCracked]:
    'ticket.options.issueSubtype.caiAndTelephoneConnectionIsLooseOrCracked',
  [IssueSubtypeEnumDTO.CaiAndTelephoneConnectionNoSignal]:
    'ticket.options.issueSubtype.caiAndTelephoneConnectionNoSignal',
  [IssueSubtypeEnumDTO.CannotAccess]:
    'ticket.options.issueSubtype.cannotAccess',
  [IssueSubtypeEnumDTO.CertifiedKeyLost]:
    'ticket.options.issueSubtype.certifiedKeyLost',
  [IssueSubtypeEnumDTO.ChainBroken]: 'ticket.options.issueSubtype.chainBroken',
  [IssueSubtypeEnumDTO.ChangeExtractorFilter]:
    'ticket.options.issueSubtype.changeExtractorFilter',
  [IssueSubtypeEnumDTO.Clamps]: 'ticket.options.issueSubtype.clamps',
  [IssueSubtypeEnumDTO.Clogged]: 'ticket.options.issueSubtype.clogged',
  [IssueSubtypeEnumDTO.CloggedDrains]:
    'ticket.options.issueSubtype.cloggedDrains',
  [IssueSubtypeEnumDTO.CloggedToilet]:
    'ticket.options.issueSubtype.cloggedToilet',
  [IssueSubtypeEnumDTO.CoinOperatedSystemFailure]:
    'ticket.options.issueSubtype.coinOperatedSystemFailure',
  [IssueSubtypeEnumDTO.ConnectionProblem]:
    'ticket.options.issueSubtype.connectionProblem',
  [IssueSubtypeEnumDTO.ContainsNoOffensiveTexts]:
    'ticket.options.issueSubtype.containsNoOffensiveTexts',
  [IssueSubtypeEnumDTO.ContainsNoOffensiveTextsNotPassedOnByInHouseSpecialist]:
    'ticket.options.issueSubtype.containsNoOffensiveTextsNotPassedOnByInHouseSpecialist',
  [IssueSubtypeEnumDTO.ContainsNoOffensiveTextsPassedOnByInHouseSpecialist]:
    'ticket.options.issueSubtype.containsNoOffensiveTextsPassedOnByInHouseSpecialist',
  [IssueSubtypeEnumDTO.ContainsOffensiveTexts]:
    'ticket.options.issueSubtype.containsOffensiveTexts',
  [IssueSubtypeEnumDTO.ContainsOffensiveTextsNotPassedOnByInHouseSpecialist]:
    'ticket.options.issueSubtype.containsOffensiveTextsNotPassedOnByInHouseSpecialist',
  [IssueSubtypeEnumDTO.ContainsOffensiveTextsPassedOnByInHouseSpecialist]:
    'ticket.options.issueSubtype.containsOffensiveTextsPassedOnByInHouseSpecialist',
  [IssueSubtypeEnumDTO.CountertopSealantWorkIsBroken]:
    'ticket.options.issueSubtype.countertopSealantWorkIsBroken',
  [IssueSubtypeEnumDTO.CountertopSealantWorkIsMouldy]:
    'ticket.options.issueSubtype.countertopSealantWorkIsMouldy',
  [IssueSubtypeEnumDTO.CountertopSinkBroken]:
    'ticket.options.issueSubtype.countertopSinkBroken',
  [IssueSubtypeEnumDTO.CountertopSinkClogged]:
    'ticket.options.issueSubtype.countertopSinkClogged',
  [IssueSubtypeEnumDTO.CountertopSinkLeaking]:
    'ticket.options.issueSubtype.countertopSinkLeaking',
  [IssueSubtypeEnumDTO.CrackedCooktop]:
    'ticket.options.issueSubtype.crackedCooktop',
  [IssueSubtypeEnumDTO.CrowCapMissingOrBroken]:
    'ticket.options.issueSubtype.crowCapMissingOrBroken',
  [IssueSubtypeEnumDTO.Damaged]: 'ticket.options.issueSubtype.damaged',
  [IssueSubtypeEnumDTO.DamagedBathtub]:
    'ticket.options.issueSubtype.damagedBathtub',
  [IssueSubtypeEnumDTO.DamagedBathtubTap]:
    'ticket.options.issueSubtype.damagedBathtubTap',
  [IssueSubtypeEnumDTO.DamagedCabinetHinges]:
    'ticket.options.issueSubtype.damagedCabinetHinges',
  [IssueSubtypeEnumDTO.DamagedCabinets]:
    'ticket.options.issueSubtype.damagedCabinets',
  [IssueSubtypeEnumDTO.DamagedCeiling]:
    'ticket.options.issueSubtype.damagedCeiling',
  [IssueSubtypeEnumDTO.DamagedCountertop]:
    'ticket.options.issueSubtype.damagedCountertop',
  [IssueSubtypeEnumDTO.DamagedFloors]:
    'ticket.options.issueSubtype.damagedFloors',
  [IssueSubtypeEnumDTO.DamagedKitchenFan]:
    'ticket.options.issueSubtype.damagedKitchenFan',
  [IssueSubtypeEnumDTO.DamagedOrBroken]:
    'ticket.options.issueSubtype.damagedOrBroken',
  [IssueSubtypeEnumDTO.DamagedPartitionWall]:
    'ticket.options.issueSubtype.damagedPartitionWall',
  [IssueSubtypeEnumDTO.DamagedShowerCabin]:
    'ticket.options.issueSubtype.damagedShowerCabin',
  [IssueSubtypeEnumDTO.DamagedShowerHeadOrHose]:
    'ticket.options.issueSubtype.damagedShowerHeadOrHose',
  [IssueSubtypeEnumDTO.DamagedShowerTap]:
    'ticket.options.issueSubtype.damagedShowerTap',
  [IssueSubtypeEnumDTO.DamagedSink]: 'ticket.options.issueSubtype.damagedSink',
  [IssueSubtypeEnumDTO.DamagedToilet]:
    'ticket.options.issueSubtype.damagedToilet',
  [IssueSubtypeEnumDTO.DamagedTv]: 'ticket.options.issueSubtype.damagedTv',
  [IssueSubtypeEnumDTO.DamagedWardrobeHatRack]:
    'ticket.options.issueSubtype.damagedWardrobeHatRack',
  [IssueSubtypeEnumDTO.DamagedWaterTap]:
    'ticket.options.issueSubtype.damagedWaterTap',
  [IssueSubtypeEnumDTO.Dirty]: 'ticket.options.issueSubtype.dirty',
  [IssueSubtypeEnumDTO.DishwasherBlocked]:
    'ticket.options.issueSubtype.dishwasherBlocked',
  [IssueSubtypeEnumDTO.DishwasherClogged]:
    'ticket.options.issueSubtype.dishwasherClogged',
  [IssueSubtypeEnumDTO.DishwasherLeaks]:
    'ticket.options.issueSubtype.dishwasherLeaks',
  [IssueSubtypeEnumDTO.DishwasherTapNoWater]:
    'ticket.options.issueSubtype.dishwasherTapNoWater',
  [IssueSubtypeEnumDTO.DoesNotCloseOrOpen]:
    'ticket.options.issueSubtype.doesNotCloseOrOpen',
  [IssueSubtypeEnumDTO.DoesNotCloseOrOpenCannotWait]:
    'ticket.options.issueSubtype.doesNotCloseOrOpenCannotWait',
  [IssueSubtypeEnumDTO.DoesNotCloseOrOpenCanWait]:
    'ticket.options.issueSubtype.doesNotCloseOrOpenCanWait',
  [IssueSubtypeEnumDTO.DoesNotOpenOrCloseProperly]:
    'ticket.options.issueSubtype.doesNotOpenOrCloseProperly',
  [IssueSubtypeEnumDTO.DoorBellBroken]:
    'ticket.options.issueSubtype.doorBellBroken',
  [IssueSubtypeEnumDTO.DoorBellHangsLoose]:
    'ticket.options.issueSubtype.doorBellHangsLoose',
  [IssueSubtypeEnumDTO.DoorCLockDefective]:
    'ticket.options.issueSubtype.doorCLockDefective',
  [IssueSubtypeEnumDTO.DoorCloserBroken]:
    'ticket.options.issueSubtype.doorCloserBroken',
  [IssueSubtypeEnumDTO.DoorClosesResidentsCanEnterOrExitTheComplex]:
    'ticket.options.issueSubtype.doorClosesResidentsCanEnterOrExitTheComplex',
  [IssueSubtypeEnumDTO.DoorClosesResidentsCannotEnterOrExitTheComplex]:
    'ticket.options.issueSubtype.doorClosesResidentsCannotEnterOrExitTheComplex',
  [IssueSubtypeEnumDTO.DoorDraft]: 'ticket.options.issueSubtype.doorDraft',
  [IssueSubtypeEnumDTO.DoorOpenerBrokenTenantsCanEnterAndExitTheBuilding]:
    'ticket.options.issueSubtype.doorOpenerBrokenTenantsCanEnterAndExitTheBuilding',
  [IssueSubtypeEnumDTO.DoorOpenerBrokenTenantsCannotEnterAndExitTheBuilding]:
    'ticket.options.issueSubtype.doorOpenerBrokenTenantsCannotEnterAndExitTheBuilding',
  [IssueSubtypeEnumDTO.DoorWindowBroken]:
    'ticket.options.issueSubtype.doorWindowBroken',
  [IssueSubtypeEnumDTO.DoubleGlazingLeakingOrFoggedUpInside]:
    'ticket.options.issueSubtype.doubleGlazingLeakingOrFoggedUpInside',
  [IssueSubtypeEnumDTO.DrainBasinOrFountainClogged]:
    'ticket.options.issueSubtype.drainBasinOrFountainClogged',
  [IssueSubtypeEnumDTO.DrainBasinOrFountainLeaks]:
    'ticket.options.issueSubtype.drainBasinOrFountainLeaks',
  [IssueSubtypeEnumDTO.Draught]: 'ticket.options.issueSubtype.draught',
  [IssueSubtypeEnumDTO.DryerFailure]:
    'ticket.options.issueSubtype.dryerFailure',
  [IssueSubtypeEnumDTO.ElevatorNotOpening]:
    'ticket.options.issueSubtype.elevatorNotOpening',
  [IssueSubtypeEnumDTO.ExtractorHoodBrokenByHeimstaden]:
    'ticket.options.issueSubtype.extractorHoodBrokenByHeimstaden',
  [IssueSubtypeEnumDTO.ExtractorHoodBrokenPropertyUnknown]:
    'ticket.options.issueSubtype.extractorHoodBrokenPropertyUnknown',
  [IssueSubtypeEnumDTO.Failure]: 'ticket.options.issueSubtype.failure',
  [IssueSubtypeEnumDTO.FaucetNoHotWaterMultipleFaucetsInTheHome]:
    'ticket.options.issueSubtype.faucetNoHotWaterMultipleFaucetsInTheHome',
  [IssueSubtypeEnumDTO.FaucetNoHotWaterOnlyThisFaucet]:
    'ticket.options.issueSubtype.faucetNoHotWaterOnlyThisFaucet',
  [IssueSubtypeEnumDTO.FenceDoorBroken]:
    'ticket.options.issueSubtype.fenceDoorBroken',
  [IssueSubtypeEnumDTO.FenceFromSteelBrokenOrCrooked]:
    'ticket.options.issueSubtype.fenceFromSteelBrokenOrCrooked',
  [IssueSubtypeEnumDTO.FenceFromSteelDoorDoesNotCloseOrOpen]:
    'ticket.options.issueSubtype.fenceFromSteelDoorDoesNotCloseOrOpen',
  [IssueSubtypeEnumDTO.FenceFromWoodBrokenOrCrooked]:
    'ticket.options.issueSubtype.fenceFromWoodBrokenOrCrooked',
  [IssueSubtypeEnumDTO.FenceFromWoodDoorDoesNotCloseOrOpen]:
    'ticket.options.issueSubtype.fenceFromWoodDoorDoesNotCloseOrOpen',
  [IssueSubtypeEnumDTO.FenceOrFenceDoorBroken]:
    'ticket.options.issueSubtype.fenceOrFenceDoorBroken',
  [IssueSubtypeEnumDTO.FenceSteelBrokenOrCrooked]:
    'ticket.options.issueSubtype.fenceSteelBrokenOrCrooked',
  [IssueSubtypeEnumDTO.FenceSteelDoorDoesNotCloseOrOpen]:
    'ticket.options.issueSubtype.fenceSteelDoorDoesNotCloseOrOpen',
  [IssueSubtypeEnumDTO.FenceWoodenBrokenOrCrooked]:
    'ticket.options.issueSubtype.fenceWoodenBrokenOrCrooked',
  [IssueSubtypeEnumDTO.FenceWoodenDoesNotCloseOrOpen]:
    'ticket.options.issueSubtype.fenceWoodenDoesNotCloseOrOpen',
  [IssueSubtypeEnumDTO.FireAlarmSystemDefect]:
    'ticket.options.issueSubtype.fireAlarmSystemDefect',
  [IssueSubtypeEnumDTO.FireDetectorBrokenOrHangingLoose]:
    'ticket.options.issueSubtype.fireDetectorBrokenOrHangingLoose',
  [IssueSubtypeEnumDTO.FireExtinguisherBrokenHangingLooseOrEmpty]:
    'ticket.options.issueSubtype.fireExtinguisherBrokenHangingLooseOrEmpty',
  [IssueSubtypeEnumDTO.FireHoseOrReelBrokenOrHangingLoose]:
    'ticket.options.issueSubtype.fireHoseOrReelBrokenOrHangingLoose',
  [IssueSubtypeEnumDTO.FixtureBroken]:
    'ticket.options.issueSubtype.fixtureBroken',
  [IssueSubtypeEnumDTO.Flooded]: 'ticket.options.issueSubtype.flooded',
  [IssueSubtypeEnumDTO.FloorCarpetingSoftOrCarpetBrokenOrLoose]:
    'ticket.options.issueSubtype.floorCarpetingSoftOrCarpetBrokenOrLoose',
  [IssueSubtypeEnumDTO.FloorDrainClogged]:
    'ticket.options.issueSubtype.floorDrainClogged',
  [IssueSubtypeEnumDTO.FloorHatchIsLooseOrBroken]:
    'ticket.options.issueSubtype.floorHatchIsLooseOrBroken',
  [IssueSubtypeEnumDTO.FloorLaminateOrParquetBrokenOrLooseFromTenant]:
    'ticket.options.issueSubtype.floorLaminateOrParquetBrokenOrLooseFromTenant',
  [IssueSubtypeEnumDTO.FloorSubfloorOfCementOrConcreteBroken]:
    'ticket.options.issueSubtype.floorSubfloorOfCementOrConcreteBroken',
  [IssueSubtypeEnumDTO.FloorSubfloorWoodenCreaks]:
    'ticket.options.issueSubtype.floorSubfloorWoodenCreaks',
  [IssueSubtypeEnumDTO.FloorSubfloorWoodenLooseOrBroken]:
    'ticket.options.issueSubtype.floorSubfloorWoodenLooseOrBroken',
  [IssueSubtypeEnumDTO.FloorSubfloorWoodenSags]:
    'ticket.options.issueSubtype.floorSubfloorWoodenSags',
  [IssueSubtypeEnumDTO.FloorTileOrGroutLeaks]:
    'ticket.options.issueSubtype.floorTileOrGroutLeaks',
  [IssueSubtypeEnumDTO.FloorTilesOrGroutBrokenOrLoose]:
    'ticket.options.issueSubtype.floorTilesOrGroutBrokenOrLoose',
  [IssueSubtypeEnumDTO.FrameDamaged]:
    'ticket.options.issueSubtype.frameDamaged',
  [IssueSubtypeEnumDTO.Frozen]: 'ticket.options.issueSubtype.frozen',
  [IssueSubtypeEnumDTO.GarageDoorFailure]:
    'ticket.options.issueSubtype.garageDoorFailure',
  [IssueSubtypeEnumDTO.GeneralDamageToWindow]:
    'ticket.options.issueSubtype.generalDamageToWindow',
  [IssueSubtypeEnumDTO.GlassIsDamaged]:
    'ticket.options.issueSubtype.glassIsDamaged',
  [IssueSubtypeEnumDTO.GlassIsLoose]:
    'ticket.options.issueSubtype.glassIsLoose',
  [IssueSubtypeEnumDTO.HandleBrokenOrLoose]:
    'ticket.options.issueSubtype.handleBrokenOrLoose',
  [IssueSubtypeEnumDTO.HandleBrokenOrLooseResidentsCanEnterOrExitTheComplex]:
    'ticket.options.issueSubtype.handleBrokenOrLooseResidentsCanEnterOrExitTheComplex',
  [IssueSubtypeEnumDTO.HandleBrokenOrLooseResidentsCannotEnterOrExitTheComplex]:
    'ticket.options.issueSubtype.handleBrokenOrLooseResidentsCannotEnterOrExitTheComplex',
  [IssueSubtypeEnumDTO.HandleLoose]: 'ticket.options.issueSubtype.handleLoose',
  [IssueSubtypeEnumDTO.HandleMissing]:
    'ticket.options.issueSubtype.handleMissing',
  [IssueSubtypeEnumDTO.HandrailIsLooseOrBrokenHazardousSituation]:
    'ticket.options.issueSubtype.handrailIsLooseOrBrokenHazardousSituation',
  [IssueSubtypeEnumDTO.HandrailIsLooseOrBrokenNoDangerousSituation]:
    'ticket.options.issueSubtype.handrailIsLooseOrBrokenNoDangerousSituation',
  [IssueSubtypeEnumDTO.HangsLoose]: 'ticket.options.issueSubtype.hangsLoose',
  [IssueSubtypeEnumDTO.HeatingSystemRefill]:
    'ticket.options.issueSubtype.heatingSystemRefill',
  [IssueSubtypeEnumDTO.HeatMeterBrokenOrLoose]:
    'ticket.options.issueSubtype.heatMeterBrokenOrLoose',
  [IssueSubtypeEnumDTO.HingeBroken]: 'ticket.options.issueSubtype.hingeBroken',
  [IssueSubtypeEnumDTO.HoleInDoor]: 'ticket.options.issueSubtype.holeInDoor',
  [IssueSubtypeEnumDTO.HookForSecuringDoorBroken]:
    'ticket.options.issueSubtype.hookForSecuringDoorBroken',
  [IssueSubtypeEnumDTO.HouseNumberMissing]:
    'ticket.options.issueSubtype.houseNumberMissing',
  [IssueSubtypeEnumDTO.IncorrectMailBoxLabelling]:
    'ticket.options.issueSubtype.incorrectMailBoxLabelling',
  [IssueSubtypeEnumDTO.IncorrectNameplateLabelling]:
    'ticket.options.issueSubtype.incorrectNameplateLabelling',
  [IssueSubtypeEnumDTO.InMalfunction]:
    'ticket.options.issueSubtype.inMalfunction',
  [IssueSubtypeEnumDTO.InsufficientWaterPressureMultipleFaucetsInTheHome]:
    'ticket.options.issueSubtype.insufficientWaterPressureMultipleFaucetsInTheHome',
  [IssueSubtypeEnumDTO.InsufficientWaterPressureMultipleTapsInTheHome]:
    'ticket.options.issueSubtype.insufficientWaterPressureMultipleTapsInTheHome',
  [IssueSubtypeEnumDTO.InsufficientWaterPressureOnlyThisTap]:
    'ticket.options.issueSubtype.insufficientWaterPressureOnlyThisTap',
  [IssueSubtypeEnumDTO.InsufficientWaterPressureThisFaucetOnly]:
    'ticket.options.issueSubtype.insufficientWaterPressureThisFaucetOnly',
  [IssueSubtypeEnumDTO.InsufficientWaterPressureThisTapOnly]:
    'ticket.options.issueSubtype.insufficientWaterPressureThisTapOnly',
  [IssueSubtypeEnumDTO.IntercomDefect]:
    'ticket.options.issueSubtype.intercomDefect',
  [IssueSubtypeEnumDTO.InternetConnectionIssue]:
    'ticket.options.issueSubtype.internetConnectionIssue',
  [IssueSubtypeEnumDTO.InternetSubscriptionIssue]:
    'ticket.options.issueSubtype.internetSubscriptionIssue',
  [IssueSubtypeEnumDTO.JammedNoLongerLockable]:
    'ticket.options.issueSubtype.jammedNoLongerLockable',
  [IssueSubtypeEnumDTO.JammedStillLockable]:
    'ticket.options.issueSubtype.jammedStillLockable',
  [IssueSubtypeEnumDTO.JointOrLeadIsLoose]:
    'ticket.options.issueSubtype.jointOrLeadIsLoose',
  [IssueSubtypeEnumDTO.KetelLeaks]: 'ticket.options.issueSubtype.ketelLeaks',
  [IssueSubtypeEnumDTO.KeyLostOrMisplacedOccupantCanEnterOrExitTheHouse]:
    'ticket.options.issueSubtype.keyLostOrMisplacedOccupantCanEnterOrExitTheHouse',
  [IssueSubtypeEnumDTO.KeyLostOrMisplacedResidentUnableToEnterOrExitHome]:
    'ticket.options.issueSubtype.keyLostOrMisplacedResidentUnableToEnterOrExitHome',
  [IssueSubtypeEnumDTO.KeyProcessorDefective]:
    'ticket.options.issueSubtype.keyProcessorDefective',
  [IssueSubtypeEnumDTO.KnobBroken]: 'ticket.options.issueSubtype.knobBroken',
  [IssueSubtypeEnumDTO.KnobsDefective]:
    'ticket.options.issueSubtype.knobsDefective',
  [IssueSubtypeEnumDTO.Lamp]: 'ticket.options.issueSubtype.lamp',
  [IssueSubtypeEnumDTO.Leakage]: 'ticket.options.issueSubtype.leakage',
  [IssueSubtypeEnumDTO.LeakingAfterMeter]:
    'ticket.options.issueSubtype.leakingAfterMeter',
  [IssueSubtypeEnumDTO.LeakingBeforeMeter]:
    'ticket.options.issueSubtype.leakingBeforeMeter',
  [IssueSubtypeEnumDTO.LeaksOrBroken]:
    'ticket.options.issueSubtype.leaksOrBroken',
  [IssueSubtypeEnumDTO.LeaksOrClogged]:
    'ticket.options.issueSubtype.leaksOrClogged',
  [IssueSubtypeEnumDTO.LeaksOrLoose]:
    'ticket.options.issueSubtype.leaksOrLoose',
  [IssueSubtypeEnumDTO.LetterboxBroken]:
    'ticket.options.issueSubtype.letterboxBroken',
  [IssueSubtypeEnumDTO.LetterboxCoverBroken]:
    'ticket.options.issueSubtype.letterboxCoverBroken',
  [IssueSubtypeEnumDTO.LidMissing]: 'ticket.options.issueSubtype.lidMissing',
  [IssueSubtypeEnumDTO.LockBroken]: 'ticket.options.issueSubtype.lockBroken',
  [IssueSubtypeEnumDTO.LockBrokenResidentsCanEnterOrExitTheComplex]:
    'ticket.options.issueSubtype.lockBrokenResidentsCanEnterOrExitTheComplex',
  [IssueSubtypeEnumDTO.LockBrokenResidentsCannotEnterOrExitTheComplex]:
    'ticket.options.issueSubtype.lockBrokenResidentsCannotEnterOrExitTheComplex',
  [IssueSubtypeEnumDTO.Loose]: 'ticket.options.issueSubtype.loose',
  [IssueSubtypeEnumDTO.LooseOrBrokenHazardousSituation]:
    'ticket.options.issueSubtype.looseOrBrokenHazardousSituation',
  [IssueSubtypeEnumDTO.LooseOrCrooked]:
    'ticket.options.issueSubtype.looseOrCrooked',
  [IssueSubtypeEnumDTO.Lost]: 'ticket.options.issueSubtype.lost',
  [IssueSubtypeEnumDTO.LostOrMissingKey]:
    'ticket.options.issueSubtype.lostOrMissingKey',
  [IssueSubtypeEnumDTO.LowInternetSpeed]:
    'ticket.options.issueSubtype.lowInternetSpeed',
  [IssueSubtypeEnumDTO.Luminaire]: 'ticket.options.issueSubtype.luminaire',
  [IssueSubtypeEnumDTO.MailboxBroken]:
    'ticket.options.issueSubtype.mailboxBroken',
  [IssueSubtypeEnumDTO.MailBoxKeyIssue]:
    'ticket.options.issueSubtype.mailBoxKeyIssue',
  [IssueSubtypeEnumDTO.MainsPoweredSmokeDetectorBeeps]:
    'ticket.options.issueSubtype.mainsPoweredSmokeDetectorBeeps',
  [IssueSubtypeEnumDTO.MajorLeakage]:
    'ticket.options.issueSubtype.majorLeakage',
  [IssueSubtypeEnumDTO.MakesNoise]: 'ticket.options.issueSubtype.makesNoise',
  [IssueSubtypeEnumDTO.MalfunctionOrStandstill]:
    'ticket.options.issueSubtype.malfunctionOrStandstill',
  [IssueSubtypeEnumDTO.MinorLeakage]:
    'ticket.options.issueSubtype.minorLeakage',
  [IssueSubtypeEnumDTO.Missing]: 'ticket.options.issueSubtype.missing',
  [IssueSubtypeEnumDTO.MoistureIssues]:
    'ticket.options.issueSubtype.moistureIssues',
  [IssueSubtypeEnumDTO.MoldIssues]: 'ticket.options.issueSubtype.moldIssues',
  [IssueSubtypeEnumDTO.NameplateContainsWrongName]:
    'ticket.options.issueSubtype.nameplateContainsWrongName',
  [IssueSubtypeEnumDTO.NeedPaint]: 'ticket.options.issueSubtype.needPaint',
  [IssueSubtypeEnumDTO.NoHeat]: 'ticket.options.issueSubtype.noHeat',
  [IssueSubtypeEnumDTO.NoHeatMultipleRadiators]:
    'ticket.options.issueSubtype.noHeatMultipleRadiators',
  [IssueSubtypeEnumDTO.NoHeatMultipleRadiatorsCollectiveCv]:
    'ticket.options.issueSubtype.noHeatMultipleRadiatorsCollectiveCv',
  [IssueSubtypeEnumDTO.NoHeatMultipleRadiatorsDistrictHeating]:
    'ticket.options.issueSubtype.noHeatMultipleRadiatorsDistrictHeating',
  [IssueSubtypeEnumDTO.NoHeatOnlyThisRadiator]:
    'ticket.options.issueSubtype.noHeatOnlyThisRadiator',
  [IssueSubtypeEnumDTO.NoHotWaterMultipleFaucetsInTheHome]:
    'ticket.options.issueSubtype.noHotWaterMultipleFaucetsInTheHome',
  [IssueSubtypeEnumDTO.NoHotWaterMultipleTapsInTheHome]:
    'ticket.options.issueSubtype.noHotWaterMultipleTapsInTheHome',
  [IssueSubtypeEnumDTO.NoHotWaterOnlyThisTap]:
    'ticket.options.issueSubtype.noHotWaterOnlyThisTap',
  [IssueSubtypeEnumDTO.NoInternetSignal]:
    'ticket.options.issueSubtype.noInternetSignal',
  [IssueSubtypeEnumDTO.None]: 'ticket.options.issueSubtype.none',
  [IssueSubtypeEnumDTO.NotCleaned]: 'ticket.options.issueSubtype.notCleaned',
  [IssueSubtypeEnumDTO.NotMountedProperly]:
    'ticket.options.issueSubtype.notMountedProperly',
  [IssueSubtypeEnumDTO.NoWaterMultipleTapsInTheHome]:
    'ticket.options.issueSubtype.noWaterMultipleTapsInTheHome',
  [IssueSubtypeEnumDTO.NoWaterMultipleTapsInTheHouse]:
    'ticket.options.issueSubtype.noWaterMultipleTapsInTheHouse',
  [IssueSubtypeEnumDTO.NoWaterOnlyThisFaucet]:
    'ticket.options.issueSubtype.noWaterOnlyThisFaucet',
  [IssueSubtypeEnumDTO.NoWaterOnlyThisTap]:
    'ticket.options.issueSubtype.noWaterOnlyThisTap',
  [IssueSubtypeEnumDTO.NoWifiSignal]:
    'ticket.options.issueSubtype.noWifiSignal',
  [IssueSubtypeEnumDTO.Other]: 'ticket.options.issueSubtype.other',
  [IssueSubtypeEnumDTO.OvenGuardIssue]:
    'ticket.options.issueSubtype.ovenGuardIssue',
  [IssueSubtypeEnumDTO.PaintingOutdatedOrDamaged]:
    'ticket.options.issueSubtype.paintingOutdatedOrDamaged',
  [IssueSubtypeEnumDTO.PanelingBroken]:
    'ticket.options.issueSubtype.panelingBroken',
  [IssueSubtypeEnumDTO.PavingSagged]:
    'ticket.options.issueSubtype.pavingSagged',
  [IssueSubtypeEnumDTO.PavingSubsided]:
    'ticket.options.issueSubtype.pavingSubsided',
  [IssueSubtypeEnumDTO.PavingTilesLoose]:
    'ticket.options.issueSubtype.pavingTilesLoose',
  [IssueSubtypeEnumDTO.PipeLineFrozen]:
    'ticket.options.issueSubtype.pipeLineFrozen',
  [IssueSubtypeEnumDTO.PipeLineHangsLooseOrLeaking]:
    'ticket.options.issueSubtype.pipeLineHangsLooseOrLeaking',
  [IssueSubtypeEnumDTO.PipeLineMinorLeakage]:
    'ticket.options.issueSubtype.pipeLineMinorLeakage',
  [IssueSubtypeEnumDTO.PipeLineSevereLeakage]:
    'ticket.options.issueSubtype.pipeLineSevereLeakage',
  [IssueSubtypeEnumDTO.PipeLineTicksMakesNoice]:
    'ticket.options.issueSubtype.pipeLineTicksMakesNoice',
  [IssueSubtypeEnumDTO.PlantingBrokenGoneOrNuisance]:
    'ticket.options.issueSubtype.plantingBrokenGoneOrNuisance',
  [IssueSubtypeEnumDTO.PlantingOvergrowthOrNuisance]:
    'ticket.options.issueSubtype.plantingOvergrowthOrNuisance',
  [IssueSubtypeEnumDTO.PlasticGutterClogged]:
    'ticket.options.issueSubtype.plasticGutterClogged',
  [IssueSubtypeEnumDTO.PlasticGutterLeaks]:
    'ticket.options.issueSubtype.plasticGutterLeaks',
  [IssueSubtypeEnumDTO.PlasticGutterLeaksOrIsClogged]:
    'ticket.options.issueSubtype.plasticGutterLeaksOrIsClogged',
  [IssueSubtypeEnumDTO.PlatformNotWorking]:
    'ticket.options.issueSubtype.platformNotWorking',
  [IssueSubtypeEnumDTO.PlatformWorkingSlow]:
    'ticket.options.issueSubtype.platformWorkingSlow',
  [IssueSubtypeEnumDTO.PoorIndoorClimate]:
    'ticket.options.issueSubtype.poorIndoorClimate',
  [IssueSubtypeEnumDTO.PoorWiFiCoverage]:
    'ticket.options.issueSubtype.poorWiFiCoverage',
  [IssueSubtypeEnumDTO.PowerOff]: 'ticket.options.issueSubtype.powerOff',
  [IssueSubtypeEnumDTO.PowerSupplyIssue]:
    'ticket.options.issueSubtype.powerSupplyIssue',
  [IssueSubtypeEnumDTO.PullSwitchFromLightingBroken]:
    'ticket.options.issueSubtype.pullSwitchFromLightingBroken',
  [IssueSubtypeEnumDTO.PullSwitchOfDishwasherBroken]:
    'ticket.options.issueSubtype.pullSwitchOfDishwasherBroken',
  [IssueSubtypeEnumDTO.PuttyLeaksOrLoose]:
    'ticket.options.issueSubtype.puttyLeaksOrLoose',
  [IssueSubtypeEnumDTO.RefillingHeatingSystem]:
    'ticket.options.issueSubtype.refillingHeatingSystem',
  [IssueSubtypeEnumDTO.RoofLeaks]: 'ticket.options.issueSubtype.roofLeaks',
  [IssueSubtypeEnumDTO.RouterFailure]:
    'ticket.options.issueSubtype.routerFailure',
  [IssueSubtypeEnumDTO.RunningToilet]:
    'ticket.options.issueSubtype.runningToilet',
  [IssueSubtypeEnumDTO.RustIssue]: 'ticket.options.issueSubtype.rustIssue',
  [IssueSubtypeEnumDTO.SevereLeakage]:
    'ticket.options.issueSubtype.severeLeakage',
  [IssueSubtypeEnumDTO.ShadesOrSunshadesBrokenOrHangingLoose]:
    'ticket.options.issueSubtype.shadesOrSunshadesBrokenOrHangingLoose',
  [IssueSubtypeEnumDTO.ShatteredWindow]:
    'ticket.options.issueSubtype.shatteredWindow',
  [IssueSubtypeEnumDTO.SillBroken]: 'ticket.options.issueSubtype.sillBroken',
  [IssueSubtypeEnumDTO.SillLooseOrDamaged]:
    'ticket.options.issueSubtype.sillLooseOrDamaged',
  [IssueSubtypeEnumDTO.SinkClogged]: 'ticket.options.issueSubtype.sinkClogged',
  [IssueSubtypeEnumDTO.SinkCrackedOrDamaged]:
    'ticket.options.issueSubtype.sinkCrackedOrDamaged',
  [IssueSubtypeEnumDTO.SinkHangsLooseOrLeaks]:
    'ticket.options.issueSubtype.sinkHangsLooseOrLeaks',
  [IssueSubtypeEnumDTO.SinkIsHangingLooseOrLeaking]:
    'ticket.options.issueSubtype.sinkIsHangingLooseOrLeaking',
  [IssueSubtypeEnumDTO.SinkLeaks]: 'ticket.options.issueSubtype.sinkLeaks',
  [IssueSubtypeEnumDTO.SinkMirrorIsLooseOrBroken]:
    'ticket.options.issueSubtype.sinkMirrorIsLooseOrBroken',
  [IssueSubtypeEnumDTO.SinkOrFountainClogged]:
    'ticket.options.issueSubtype.sinkOrFountainClogged',
  [IssueSubtypeEnumDTO.SinkOrFountainHangsLooseOrLeaks]:
    'ticket.options.issueSubtype.sinkOrFountainHangsLooseOrLeaks',
  [IssueSubtypeEnumDTO.SinkOrFountainTornOrDamaged]:
    'ticket.options.issueSubtype.sinkOrFountainTornOrDamaged',
  [IssueSubtypeEnumDTO.SinkShelfIsLooseOrBroken]:
    'ticket.options.issueSubtype.sinkShelfIsLooseOrBroken',
  [IssueSubtypeEnumDTO.SinkTornOrDamaged]:
    'ticket.options.issueSubtype.sinkTornOrDamaged',
  [IssueSubtypeEnumDTO.SkirtingBrokenOrLoose]:
    'ticket.options.issueSubtype.skirtingBrokenOrLoose',
  [IssueSubtypeEnumDTO.SmokeDetectorAtMainsVoltageBeeps]:
    'ticket.options.issueSubtype.smokeDetectorAtMainsVoltageBeeps',
  [IssueSubtypeEnumDTO.SmokeDetectorAtMainsVoltageLoose]:
    'ticket.options.issueSubtype.smokeDetectorAtMainsVoltageLoose',
  [IssueSubtypeEnumDTO.SmokeDetectorWithBatteryBeeps]:
    'ticket.options.issueSubtype.smokeDetectorWithBatteryBeeps',
  [IssueSubtypeEnumDTO.SmokeDetectorWithBatteryLoose]:
    'ticket.options.issueSubtype.smokeDetectorWithBatteryLoose',
  [IssueSubtypeEnumDTO.SocketDamage]:
    'ticket.options.issueSubtype.socketDamage',
  [IssueSubtypeEnumDTO.StairRailIsLooseOrBroken]:
    'ticket.options.issueSubtype.stairRailIsLooseOrBroken',
  [IssueSubtypeEnumDTO.Stinks]: 'ticket.options.issueSubtype.stinks',
  [IssueSubtypeEnumDTO.StuccoCrackedOrLoose]:
    'ticket.options.issueSubtype.stuccoCrackedOrLoose',
  [IssueSubtypeEnumDTO.TankLeakage]: 'ticket.options.issueSubtype.tankLeakage',
  [IssueSubtypeEnumDTO.TemperatureTooHighLow]:
    'ticket.options.issueSubtype.temperatureTooHighLow',
  [IssueSubtypeEnumDTO.TheGutterMadeOfPlasticIsLeakingOrIsClogged]:
    'ticket.options.issueSubtype.theGutterMadeOfPlasticIsLeakingOrIsClogged',
  [IssueSubtypeEnumDTO.TheGutterMadeOfZincIsLeakingOrIsClogged]:
    'ticket.options.issueSubtype.theGutterMadeOfZincIsLeakingOrIsClogged',
  [IssueSubtypeEnumDTO.TheGutterMaterialUnknownIsLeakingOrIsClogged]:
    'ticket.options.issueSubtype.theGutterMaterialUnknownIsLeakingOrIsClogged',
  [IssueSubtypeEnumDTO.ThermostatFailure]:
    'ticket.options.issueSubtype.thermostatFailure',
  [IssueSubtypeEnumDTO.ThresholdIsLooseOrIsDamaged]:
    'ticket.options.issueSubtype.thresholdIsLooseOrIsDamaged',
  [IssueSubtypeEnumDTO.ThresholdLooseOrDamaged]:
    'ticket.options.issueSubtype.thresholdLooseOrDamaged',
  [IssueSubtypeEnumDTO.Ticks]: 'ticket.options.issueSubtype.ticks',
  [IssueSubtypeEnumDTO.TileOrGroutBrokenOrLoose]:
    'ticket.options.issueSubtype.tileOrGroutBrokenOrLoose',
  [IssueSubtypeEnumDTO.ToiletBowlClogged]:
    'ticket.options.issueSubtype.toiletBowlClogged',
  [IssueSubtypeEnumDTO.ToiletBowlLooseOrLeaking]:
    'ticket.options.issueSubtype.toiletBowlLooseOrLeaking',
  [IssueSubtypeEnumDTO.ToiletBowlTornOrDamaged]:
    'ticket.options.issueSubtype.toiletBowlTornOrDamaged',
  [IssueSubtypeEnumDTO.ToiletCisternBroken]:
    'ticket.options.issueSubtype.toiletCisternBroken',
  [IssueSubtypeEnumDTO.ToiletCisternContinuesToRun]:
    'ticket.options.issueSubtype.toiletCisternContinuesToRun',
  [IssueSubtypeEnumDTO.ToiletCisternFaucetBroken]:
    'ticket.options.issueSubtype.toiletCisternFaucetBroken',
  [IssueSubtypeEnumDTO.ToiletCisternGivesNoWater]:
    'ticket.options.issueSubtype.toiletCisternGivesNoWater',
  [IssueSubtypeEnumDTO.ToiletSeatIsLooseOrBroken]:
    'ticket.options.issueSubtype.toiletSeatIsLooseOrBroken',
  [IssueSubtypeEnumDTO.TvSignalIssue]:
    'ticket.options.issueSubtype.tvSignalIssue',
  [IssueSubtypeEnumDTO.VentilationGridBroken]:
    'ticket.options.issueSubtype.ventilationGridBroken',
  [IssueSubtypeEnumDTO.VentilationGridDoesNotVentilate]:
    'ticket.options.issueSubtype.ventilationGridDoesNotVentilate',
  [IssueSubtypeEnumDTO.VentilationGridHasNoVentilation]:
    'ticket.options.issueSubtype.ventilationGridHasNoVentilation',
  [IssueSubtypeEnumDTO.VentilationGridHasToBeMounted]:
    'ticket.options.issueSubtype.ventilationGridHasToBeMounted',
  [IssueSubtypeEnumDTO.VentilationGridInWallDirty]:
    'ticket.options.issueSubtype.ventilationGridInWallDirty',
  [IssueSubtypeEnumDTO.VentilationGridInWindowFrameOrDoorDirty]:
    'ticket.options.issueSubtype.ventilationGridInWindowFrameOrDoorDirty',
  [IssueSubtypeEnumDTO.VentilationGridInWindowFrameOrDoorDoesNotVentilate]:
    'ticket.options.issueSubtype.ventilationGridInWindowFrameOrDoorDoesNotVentilate',
  [IssueSubtypeEnumDTO.VentilationGridIsDirty]:
    'ticket.options.issueSubtype.ventilationGridIsDirty',
  [IssueSubtypeEnumDTO.VentilationMechanicalDirty]:
    'ticket.options.issueSubtype.ventilationMechanicalDirty',
  [IssueSubtypeEnumDTO.VentilationMechanicalDoesNotVentilate]:
    'ticket.options.issueSubtype.ventilationMechanicalDoesNotVentilate',
  [IssueSubtypeEnumDTO.VentilationMechanicalGridInWallDoesNotVentilate]:
    'ticket.options.issueSubtype.ventilationMechanicalGridInWallDoesNotVentilate',
  [IssueSubtypeEnumDTO.VentilationMechanicalGridInWallMakesNoise]:
    'ticket.options.issueSubtype.ventilationMechanicalGridInWallMakesNoise',
  [IssueSubtypeEnumDTO.VentilationMechanicalGridInWallStinks]:
    'ticket.options.issueSubtype.ventilationMechanicalGridInWallStinks',
  [IssueSubtypeEnumDTO.VentilationMechanicalMakesNoise]:
    'ticket.options.issueSubtype.ventilationMechanicalMakesNoise',
  [IssueSubtypeEnumDTO.VentilationMechanicalStinks]:
    'ticket.options.issueSubtype.ventilationMechanicalStinks',
  [IssueSubtypeEnumDTO.VentilationNaturalGridInWallDoesNotVentilate]:
    'ticket.options.issueSubtype.ventilationNaturalGridInWallDoesNotVentilate',
  [IssueSubtypeEnumDTO.VentilationNaturalGridInWallMakesNoise]:
    'ticket.options.issueSubtype.ventilationNaturalGridInWallMakesNoise',
  [IssueSubtypeEnumDTO.VentilationNaturalGridInWallStinks]:
    'ticket.options.issueSubtype.ventilationNaturalGridInWallStinks',
  [IssueSubtypeEnumDTO.Vermin]: 'ticket.options.issueSubtype.vermin',
  [IssueSubtypeEnumDTO.WallpaperBrokenOrLoose]:
    'ticket.options.issueSubtype.wallpaperBrokenOrLoose',
  [IssueSubtypeEnumDTO.WantAccessToStorageRoom]:
    'ticket.options.issueSubtype.wantAccessToStorageRoom',
  [IssueSubtypeEnumDTO.WashbasinDrainClogged]:
    'ticket.options.issueSubtype.washbasinDrainClogged',
  [IssueSubtypeEnumDTO.WashbasinDrainLeaks]:
    'ticket.options.issueSubtype.washbasinDrainLeaks',
  [IssueSubtypeEnumDTO.WashingMachineBlocked]:
    'ticket.options.issueSubtype.washingMachineBlocked',
  [IssueSubtypeEnumDTO.WashingMachineFailure]:
    'ticket.options.issueSubtype.washingMachineFailure',
  [IssueSubtypeEnumDTO.WashingMachineLeaks]:
    'ticket.options.issueSubtype.washingMachineLeaks',
  [IssueSubtypeEnumDTO.WashingMachinePullSwitchBroken]:
    'ticket.options.issueSubtype.washingMachinePullSwitchBroken',
  [IssueSubtypeEnumDTO.WashingMachineTapNoWater]:
    'ticket.options.issueSubtype.washingMachineTapNoWater',
  [IssueSubtypeEnumDTO.WashingMachineValveLeaksOrIsLoose]:
    'ticket.options.issueSubtype.washingMachineValveLeaksOrIsLoose',
  [IssueSubtypeEnumDTO.WaspNest]: 'ticket.options.issueSubtype.waspNest',
  [IssueSubtypeEnumDTO.WaterFlooding]:
    'ticket.options.issueSubtype.waterFlooding',
  [IssueSubtypeEnumDTO.WaterLeakage]:
    'ticket.options.issueSubtype.waterLeakage',
  [IssueSubtypeEnumDTO.WaterPipesDrainValveLeakingOrBroken]:
    'ticket.options.issueSubtype.waterPipesDrainValveLeakingOrBroken',
  [IssueSubtypeEnumDTO.WaterPipesFrozen]:
    'ticket.options.issueSubtype.waterPipesFrozen',
  [IssueSubtypeEnumDTO.WaterPipesHangsLooseOrLeaks]:
    'ticket.options.issueSubtype.waterPipesHangsLooseOrLeaks',
  [IssueSubtypeEnumDTO.WaterPipesMinorLeakage]:
    'ticket.options.issueSubtype.waterPipesMinorLeakage',
  [IssueSubtypeEnumDTO.WaterPipesSevereLeakage]:
    'ticket.options.issueSubtype.waterPipesSevereLeakage',
  [IssueSubtypeEnumDTO.WaterPipesTapsMakesNoise]:
    'ticket.options.issueSubtype.waterPipesTapsMakesNoise',
  [IssueSubtypeEnumDTO.WaterPipesTicksMakesNoise]:
    'ticket.options.issueSubtype.waterPipesTicksMakesNoise',
  [IssueSubtypeEnumDTO.WaterSupplyFailure]:
    'ticket.options.issueSubtype.waterSupplyFailure',
  [IssueSubtypeEnumDTO.WaterSupplyIssue]:
    'ticket.options.issueSubtype.waterSupplyIssue',
  [IssueSubtypeEnumDTO.WindowBroken]:
    'ticket.options.issueSubtype.windowBroken',
  [IssueSubtypeEnumDTO.WindowGlassIsLoose]:
    'ticket.options.issueSubtype.windowGlassIsLoose',
  [IssueSubtypeEnumDTO.WoodRot]: 'ticket.options.issueSubtype.woodRot',
  [IssueSubtypeEnumDTO.WtwBalancedVentilationReplacingFilters]:
    'ticket.options.issueSubtype.wtwBalancedVentilationReplacingFilters',
  [IssueSubtypeEnumDTO.ZincGutterClogged]:
    'ticket.options.issueSubtype.zincGutterClogged',
  [IssueSubtypeEnumDTO.ZincGutterLeaks]:
    'ticket.options.issueSubtype.zincGutterLeaks',
  [IssueSubtypeEnumDTO.ZincGutterLeaksOrClogged]:
    'ticket.options.issueSubtype.zincGutterLeaksOrClogged',
};

const issueTypeToTranslationLabelKeyMap: Record<
  IssueTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [IssueTypeEnumDTO.BathroomAppliances]:
    'ticket.options.issueType.bathroomAppliances',
  [IssueTypeEnumDTO.BicycleParkingIssue]:
    'ticket.options.issueType.bicycleParkingIssue',
  [IssueTypeEnumDTO.BikeStorageIssue]:
    'ticket.options.issueType.bikeStorageIssue',
  [IssueTypeEnumDTO.ContainerGarbageIssue]:
    'ticket.options.issueType.containerGarbageIssue',
  [IssueTypeEnumDTO.DishwasherIssue]:
    'ticket.options.issueType.dishwasherIssue',
  [IssueTypeEnumDTO.ElectricityIssue]:
    'ticket.options.issueType.electricityIssue',
  [IssueTypeEnumDTO.ElevatorsIssue]:
    'ticket.options.issueType.electricityIssue',
  [IssueTypeEnumDTO.ElevatorsIssue]: 'ticket.options.issueType.elevatorsIssue',
  [IssueTypeEnumDTO.FacadeIssue]: 'ticket.options.issueType.facadeIssue',
  [IssueTypeEnumDTO.FencePavingPlantingIssue]:
    'ticket.options.issueType.fencePavingPlantingIssue',
  [IssueTypeEnumDTO.FireAlarmIssue]: 'ticket.options.issueType.fireAlarmIssue',
  [IssueTypeEnumDTO.FurnitureIssue]: 'ticket.options.issueType.furnitureIssue',
  [IssueTypeEnumDTO.GarageParkingIssue]:
    'ticket.options.issueType.garageParkingIssue',
  [IssueTypeEnumDTO.GaragePlatformParkingIssue]:
    'ticket.options.issueType.garagePlatformParkingIssue',
  [IssueTypeEnumDTO.GarbageRoomIssue]:
    'ticket.options.issueType.garbageRoomIssue',
  [IssueTypeEnumDTO.GasIssue]: 'ticket.options.issueType.gasIssue',
  [IssueTypeEnumDTO.GreenAreasIssue]:
    'ticket.options.issueType.greenAreasIssue',
  [IssueTypeEnumDTO.HallwayStairsIssue]:
    'ticket.options.issueType.hallwayStairsIssue',
  [IssueTypeEnumDTO.HeatingVentilationIssue]:
    'ticket.options.issueType.heatingVentilationIssue',
  [IssueTypeEnumDTO.InternetIssue]: 'ticket.options.issueType.internetIssue',
  [IssueTypeEnumDTO.KeysLockIntercomIssue]:
    'ticket.options.issueType.keysLockIntercomIssue',
  [IssueTypeEnumDTO.KitchenAppliances]:
    'ticket.options.issueType.kitchenAppliances',
  [IssueTypeEnumDTO.KitchenFanIssue]:
    'ticket.options.issueType.kitchenFanIssue',
  [IssueTypeEnumDTO.KitchenSinkIssue]:
    'ticket.options.issueType.kitchenSinkIssue',
  [IssueTypeEnumDTO.KitchenSinkIssue]:
    'ticket.options.issueType.kitchenSinkIssue',
  [IssueTypeEnumDTO.KitchenUnit]: 'ticket.options.issueType.kitchenUnit',
  [IssueTypeEnumDTO.LaundryRoomIssue]:
    'ticket.options.issueType.laundryRoomIssue',
  [IssueTypeEnumDTO.MailBoxNameplateIssue]:
    'ticket.options.issueType.mailBoxNameplateIssue',
  [IssueTypeEnumDTO.MechanicalVentilation]:
    'ticket.options.issueType.mechanicalVentilation',
  [IssueTypeEnumDTO.MoistureMoldIssue]:
    'ticket.options.issueType.moistureMoldIssue',
  [IssueTypeEnumDTO.MovableBarrierIssue]:
    'ticket.options.issueType.movableBarrierIssue',
  [IssueTypeEnumDTO.Other]: 'ticket.options.issueType.other',
  [IssueTypeEnumDTO.OutdoorSmellIssue]:
    'ticket.options.issueType.outdoorSmellIssue',
  [IssueTypeEnumDTO.OvenCookerIssue]:
    'ticket.options.issueType.ovenCookerIssue',
  [IssueTypeEnumDTO.PlaygroundIssue]:
    'ticket.options.issueType.playgroundIssue',
  [IssueTypeEnumDTO.RefrigeratorFreezerIssue]:
    'ticket.options.issueType.refrigeratorFreezerIssue',
  [IssueTypeEnumDTO.RooftopIssue]: 'ticket.options.issueType.rooftopIssue',
  [IssueTypeEnumDTO.SandIssue]: 'ticket.options.issueType.sandIssue',
  [IssueTypeEnumDTO.ShowerBathIssue]:
    'ticket.options.issueType.showerBathIssue',
  [IssueTypeEnumDTO.SinkIssue]: 'ticket.options.issueType.sinkIssue',
  [IssueTypeEnumDTO.SnowRemovalIssue]:
    'ticket.options.issueType.snowRemovalIssue',
  [IssueTypeEnumDTO.SprinklingSystemIssue]:
    'ticket.options.issueType.sprinklingSystemIssue',
  [IssueTypeEnumDTO.StorageRoomIssue]:
    'ticket.options.issueType.storageRoomIssue',
  [IssueTypeEnumDTO.StreetWorkoutZoneEquipmentIssue]:
    'ticket.options.issueType.streetWorkoutZoneEquipmentIssue',
  [IssueTypeEnumDTO.SurfacesIssue]: 'ticket.options.issueType.surfacesIssue',
  [IssueTypeEnumDTO.ToiletIssue]: 'ticket.options.issueType.toiletIssue',
  [IssueTypeEnumDTO.TvInternetIssue]:
    'ticket.options.issueType.tvInternetIssue',
  [IssueTypeEnumDTO.VerminIssue]: 'ticket.options.issueType.verminIssue',
  [IssueTypeEnumDTO.WashingMachineIssue]:
    'ticket.options.issueType.washingMachineIssue',
  [IssueTypeEnumDTO.WasteIssue]: 'ticket.options.issueType.wasteIssue',
  [IssueTypeEnumDTO.WaterDrainPipesIssue]:
    'ticket.options.issueType.waterDrainPipesIssue',
};

const departmentTypeToTranslationLabelKeyMap: Record<
  DepartmentTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [DepartmentTypeEnumDTO.BackOffice]: 'ticket.options.department.backOffice',
  [DepartmentTypeEnumDTO.CollectionsDepartment]:
    'ticket.options.department.collectionsDepartment',
  [DepartmentTypeEnumDTO.ContractAdministration]:
    'ticket.options.department.contractAdministration',
  [DepartmentTypeEnumDTO.CustomerService]:
    'ticket.options.department.customerService',
  [DepartmentTypeEnumDTO.Hoa]: 'ticket.options.department.hoa',
  [DepartmentTypeEnumDTO.MovingService]:
    'ticket.options.department.movingService',
  [DepartmentTypeEnumDTO.Operation]: 'ticket.options.department.operation',
  [DepartmentTypeEnumDTO.RentalService]:
    'ticket.options.department.rentalService',
  [DepartmentTypeEnumDTO.Technical]: 'ticket.options.department.technical',
  [DepartmentTypeEnumDTO.TenantImprovement]:
    'ticket.options.department.tenantImprovement',
};

const entityTypeToTranslationLabelKeyMap: Record<
  EntityEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [EntityEnumDTO.AdvertisementIntegration]:
    'ticket.options.entity.advertisementIntegration',
  [EntityEnumDTO.Attachment]: 'ticket.options.entity.attachment',
  [EntityEnumDTO.City]: 'ticket.options.entity.city',
  [EntityEnumDTO.Comment]: 'ticket.options.entity.comment',
  [EntityEnumDTO.Company]: 'ticket.options.entity.company',
  [EntityEnumDTO.Device]: 'ticket.options.entity.device',
  [EntityEnumDTO.Entrance]: 'ticket.options.entity.entrance',
  [EntityEnumDTO.Inventory]: 'ticket.options.entity.inventory',
  [EntityEnumDTO.KycIntegration]: '',
  [EntityEnumDTO.Lease]: 'ticket.options.entity.lease',
  [EntityEnumDTO.LeaseTemplate]: '',
  [EntityEnumDTO.Property]: 'ticket.options.entity.property',
  [EntityEnumDTO.PropertyObject]: 'ticket.options.entity.propertyObject',
  [EntityEnumDTO.Prospect]: 'ticket.options.entity.prospect',
  [EntityEnumDTO.Region]: 'ticket.options.entity.region',
  [EntityEnumDTO.Ticket]: 'ticket.options.entity.ticket',
  [EntityEnumDTO.Unit]: 'ticket.options.entity.unit',
  [EntityEnumDTO.UnitAdvertisement]: 'ticket.options.entity.unitAdvertisement',
  [EntityEnumDTO.UnitAdvertisementIntegration]:
    'ticket.options.entity.unitAdvertisementIntegration',
  [EntityEnumDTO.UnitContact]: 'ticket.options.entity.unitContact',
  [EntityEnumDTO.User]: 'ticket.options.entity.user',
};

const ticketTypeToTranslationLabelKeyMap: Record<
  TicketTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [TicketTypeEnumDTO.Complaint]: 'ticket.options.ticketType.complaint',
  [TicketTypeEnumDTO.ContractRequest]:
    'ticket.options.ticketType.contractRequest',
  [TicketTypeEnumDTO.GeneralRequest]:
    'ticket.options.ticketType.generalRequest',
  [TicketTypeEnumDTO.InvoiceRequest]:
    'ticket.options.ticketType.invoiceRequest',
  [TicketTypeEnumDTO.ServiceRequest]:
    'ticket.options.ticketType.serviceRequest',
};

const priorityTypeToTranslationLabelKeyMap: Record<
  PriorityTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [PriorityTypeEnumDTO.High]: 'ticket.options.priority.high',
  [PriorityTypeEnumDTO.Highest]: 'ticket.options.priority.highest',
  [PriorityTypeEnumDTO.Low]: 'ticket.options.priority.low',
  [PriorityTypeEnumDTO.Lowest]: 'ticket.options.priority.lowest',
  [PriorityTypeEnumDTO.Medium]: 'ticket.options.priority.medium',
};

const roomTypeToTranslationLabelKeyMap: Record<
  RoomTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [RoomTypeEnumDTO.Attic]: 'ticket.options.room.attic',
  [RoomTypeEnumDTO.BalconyOrRoofTerrace]:
    'ticket.options.room.balconyOrRoofTerrace',
  [RoomTypeEnumDTO.Basement]: 'ticket.options.room.basement',
  [RoomTypeEnumDTO.Bathroom]: 'ticket.options.room.bathroom',
  [RoomTypeEnumDTO.Bedroom]: 'ticket.options.room.bedroom',
  [RoomTypeEnumDTO.BoilerRoom]: 'ticket.options.room.boilerRoom',
  [RoomTypeEnumDTO.Elevator]: 'ticket.options.room.elevator',
  [RoomTypeEnumDTO.Facade]: 'ticket.options.room.facade',
  [RoomTypeEnumDTO.Firebreak]: 'ticket.options.room.firebreak',
  [RoomTypeEnumDTO.Hall]: 'ticket.options.room.hall',
  [RoomTypeEnumDTO.Hallway]: 'ticket.options.room.hallway',
  [RoomTypeEnumDTO.Kitchen]: 'ticket.options.room.kitchen',
  [RoomTypeEnumDTO.LivingRoom]: 'ticket.options.room.livingRoom',
  [RoomTypeEnumDTO.None]: 'ticket.options.room.none',
  [RoomTypeEnumDTO.OnTheRoof]: 'ticket.options.room.onTheRoof',
  [RoomTypeEnumDTO.OutdoorAreaOrGarden]:
    'ticket.options.room.outdoorAreaOrGarden',
  [RoomTypeEnumDTO.ParkingSpace]: 'ticket.options.room.parkingSpace',
  [RoomTypeEnumDTO.RecreationRoom]: 'ticket.options.room.recreationRoom',
  [RoomTypeEnumDTO.Stairwell]: 'ticket.options.room.stairwell',
  [RoomTypeEnumDTO.Storage]: 'ticket.options.room.storage',
  [RoomTypeEnumDTO.StorageHallway]: 'ticket.options.room.storageHallway',
  [RoomTypeEnumDTO.ToiletRoom]: 'ticket.options.room.toiletRoom',
  [RoomTypeEnumDTO.StreetWorkoutZoneEquipment]:
    'ticket.options.room.streetWorkoutZoneEquipment',
  [RoomTypeEnumDTO.BicycleParking]: 'ticket.options.room.bicycleParking',
  [RoomTypeEnumDTO.GarageGate]: 'ticket.options.room.garageGate',
  [RoomTypeEnumDTO.ParkingSpacePlatforms]:
    'ticket.options.room.parkingSpacePlatforms',
  [RoomTypeEnumDTO.PlaygroundEquipment]:
    'ticket.options.room.playgroundEquipment',
};

const damagedItemToTranslationLabelKeyMap: Record<
  DamagedItemEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [DamagedItemEnumDTO.Banister]: 'ticket.options.damagedItem.banister',
  [DamagedItemEnumDTO.Baseboard]: 'ticket.options.damagedItem.baseboard',
  [DamagedItemEnumDTO.Bathtub]: 'ticket.options.damagedItem.bathtub',
  [DamagedItemEnumDTO.BicycleParking]:
    'ticket.options.damagedItem.bicycleParking',
  [DamagedItemEnumDTO.BikeRack]: 'ticket.options.damagedItem.bikeRack',
  [DamagedItemEnumDTO.Boiler]: 'ticket.options.damagedItem.boiler',
  [DamagedItemEnumDTO.BuiltInEquipment]:
    'ticket.options.damagedItem.builtInEquipment',
  [DamagedItemEnumDTO.ButtonByTheElevatorDoor]:
    'ticket.options.damagedItem.buttonByTheElevatorDoor',
  [DamagedItemEnumDTO.ButtonsInTheElevatorItself]:
    'ticket.options.damagedItem.buttonsInTheElevatorItself',
  [DamagedItemEnumDTO.Ceiling]: 'ticket.options.damagedItem.ceiling',
  [DamagedItemEnumDTO.CentralHeatingPiping]:
    'ticket.options.damagedItem.centralHeatingPiping',
  [DamagedItemEnumDTO.Chimney]: 'ticket.options.damagedItem.chimney',
  [DamagedItemEnumDTO.CookerHood]: 'ticket.options.damagedItem.cookerHood',
  [DamagedItemEnumDTO.Countertop]: 'ticket.options.damagedItem.countertop',
  [DamagedItemEnumDTO.DishwasherTap]:
    'ticket.options.damagedItem.dishwasherTap',
  [DamagedItemEnumDTO.Door]: 'ticket.options.damagedItem.door',
  [DamagedItemEnumDTO.DoorInside]: 'ticket.options.damagedItem.doorInside',
  [DamagedItemEnumDTO.DoorOutside]: 'ticket.options.damagedItem.doorOutside',
  [DamagedItemEnumDTO.Drain]: 'ticket.options.damagedItem.drain',
  [DamagedItemEnumDTO.Drainpipe]: 'ticket.options.damagedItem.drainpipe',
  [DamagedItemEnumDTO.ElectricDoor]: 'ticket.options.damagedItem.electricDoor',
  [DamagedItemEnumDTO.ElectricityIssue]:
    'ticket.options.damagedItem.electricityIssue',
  [DamagedItemEnumDTO.ElevatorChair]:
    'ticket.options.damagedItem.elevatorChair',
  [DamagedItemEnumDTO.ElevatorDoors]:
    'ticket.options.damagedItem.elevatorDoors',
  [DamagedItemEnumDTO.ElevatorMalfunctionsOrIsStandingStill]:
    'ticket.options.damagedItem.elevatorMalfunctionsOrIsStandingStill',
  [DamagedItemEnumDTO.ElevatorMirror]:
    'ticket.options.damagedItem.elevatorMirror',
  [DamagedItemEnumDTO.EmergencyLighting]:
    'ticket.options.damagedItem.emergencyLighting',
  [DamagedItemEnumDTO.EmergencyTelephoneInTheElevator]:
    'ticket.options.damagedItem.emergencyTelephoneInTheElevator',
  [DamagedItemEnumDTO.ExtractorHood]:
    'ticket.options.damagedItem.extractorHood',
  [DamagedItemEnumDTO.FacadeCladding]:
    'ticket.options.damagedItem.facadeCladding',
  [DamagedItemEnumDTO.Fence]: 'ticket.options.damagedItem.fence',
  [DamagedItemEnumDTO.FireAlarm]: 'ticket.options.damagedItem.fireAlarm',
  [DamagedItemEnumDTO.FireExtinguisher]:
    'ticket.options.damagedItem.fireExtinguisher',
  [DamagedItemEnumDTO.FireHoseOrFireReel]:
    'ticket.options.damagedItem.fireHoseOrFireReel',
  [DamagedItemEnumDTO.Floor]: 'ticket.options.damagedItem.floor',
  [DamagedItemEnumDTO.FloorHeating]: 'ticket.options.damagedItem.floorHeating',
  [DamagedItemEnumDTO.FrontDoorbell]:
    'ticket.options.damagedItem.frontDoorbell',
  [DamagedItemEnumDTO.FurnitureDamage]:
    'ticket.options.damagedItem.furnitureDamage',
  [DamagedItemEnumDTO.GarageDoor]: 'ticket.options.damagedItem.garageDoor',
  [DamagedItemEnumDTO.GarageGate]: 'ticket.options.damagedItem.garageGate',
  [DamagedItemEnumDTO.GasPipeline]: 'ticket.options.damagedItem.gasPipeline',
  [DamagedItemEnumDTO.GasTap]: 'ticket.options.damagedItem.gasTap',
  [DamagedItemEnumDTO.GeyserTap]: 'ticket.options.damagedItem.geyserTap',
  [DamagedItemEnumDTO.Graffiti]: 'ticket.options.damagedItem.graffiti',
  [DamagedItemEnumDTO.Gutter]: 'ticket.options.damagedItem.gutter',
  [DamagedItemEnumDTO.HandrailInElevator]:
    'ticket.options.damagedItem.handrailInElevator',
  [DamagedItemEnumDTO.HeatingSystem]:
    'ticket.options.damagedItem.heatingSystem',
  [DamagedItemEnumDTO.InductionHob]: 'ticket.options.damagedItem.inductionHob',
  [DamagedItemEnumDTO.Intercom]: 'ticket.options.damagedItem.intercom',
  [DamagedItemEnumDTO.InternetAccess]:
    'ticket.options.damagedItem.internetAccess',
  [DamagedItemEnumDTO.KitchenCabinet]:
    'ticket.options.damagedItem.kitchenCabinet',
  [DamagedItemEnumDTO.KitchenDrawer]:
    'ticket.options.damagedItem.kitchenDrawer',
  [DamagedItemEnumDTO.Lamp]: 'ticket.options.damagedItem.lamp',
  [DamagedItemEnumDTO.LanSocket]: 'ticket.options.damagedItem.lanSocket',
  [DamagedItemEnumDTO.LightingInFirebreak]:
    'ticket.options.damagedItem.lightingInFirebreak',
  [DamagedItemEnumDTO.LightingInTheElevator]:
    'ticket.options.damagedItem.lightingInTheElevator',
  [DamagedItemEnumDTO.LoftLadder]: 'ticket.options.damagedItem.loftLadder',
  [DamagedItemEnumDTO.Luminaire]: 'ticket.options.damagedItem.luminaire',
  [DamagedItemEnumDTO.Mailbox]: 'ticket.options.damagedItem.mailbox',
  [DamagedItemEnumDTO.MainValve]: 'ticket.options.damagedItem.mainValve',
  [DamagedItemEnumDTO.MechanicalVentilation]:
    'ticket.options.damagedItem.mechanicalVentilation',
  [DamagedItemEnumDTO.Mirror]: 'ticket.options.damagedItem.mirror',
  [DamagedItemEnumDTO.MovableBarrier]:
    'ticket.options.damagedItem.movableBarrier',
  [DamagedItemEnumDTO.Nameplate]: 'ticket.options.damagedItem.nameplate',
  [DamagedItemEnumDTO.Numberplate]: 'ticket.options.damagedItem.numberplate',
  [DamagedItemEnumDTO.Other]: 'ticket.options.damagedItem.other',
  [DamagedItemEnumDTO.OutdoorTap]: 'ticket.options.damagedItem.outdoorTap',
  [DamagedItemEnumDTO.Oven]: 'ticket.options.damagedItem.oven',
  [DamagedItemEnumDTO.ParkingBarrier]:
    'ticket.options.damagedItem.parkingBarrier',
  [DamagedItemEnumDTO.ParkingPass]: 'ticket.options.damagedItem.parkingPass',
  [DamagedItemEnumDTO.PartitionWall]:
    'ticket.options.damagedItem.partitionWall',
  [DamagedItemEnumDTO.Paving]: 'ticket.options.damagedItem.paving',
  [DamagedItemEnumDTO.Pests]: 'ticket.options.damagedItem.pests',
  [DamagedItemEnumDTO.Planting]: 'ticket.options.damagedItem.planting',
  [DamagedItemEnumDTO.Platform]: 'ticket.options.damagedItem.platform',
  [DamagedItemEnumDTO.PlaygroundEquipment]:
    'ticket.options.damagedItem.playgroundEquipment',
  [DamagedItemEnumDTO.PowerFailure]: 'ticket.options.damagedItem.powerFailure',
  [DamagedItemEnumDTO.PullSwitch]: 'ticket.options.damagedItem.pullSwitch',
  [DamagedItemEnumDTO.Radiator]: 'ticket.options.damagedItem.radiator',
  [DamagedItemEnumDTO.Refrigerator]: 'ticket.options.damagedItem.refrigerator',
  [DamagedItemEnumDTO.Roof]: 'ticket.options.damagedItem.roof',
  [DamagedItemEnumDTO.RoofCovering]: 'ticket.options.damagedItem.roofCovering',
  [DamagedItemEnumDTO.RoofTile]: 'ticket.options.damagedItem.roofTile',
  [DamagedItemEnumDTO.Router]: 'ticket.options.damagedItem.router',
  [DamagedItemEnumDTO.Sand]: 'ticket.options.damagedItem.sand',
  [DamagedItemEnumDTO.Scrubber]: 'ticket.options.damagedItem.scrubber',
  [DamagedItemEnumDTO.SewerPipe]: 'ticket.options.damagedItem.sewerPipe',
  [DamagedItemEnumDTO.ShadingOrSunshades]:
    'ticket.options.damagedItem.shadingOrSunshades',
  [DamagedItemEnumDTO.ShowerDoor]: 'ticket.options.damagedItem.showerDoor',
  [DamagedItemEnumDTO.ShowerDrain]: 'ticket.options.damagedItem.showerDrain',
  [DamagedItemEnumDTO.ShowerHead]: 'ticket.options.damagedItem.showerHead',
  [DamagedItemEnumDTO.ShowerHose]: 'ticket.options.damagedItem.showerHose',
  [DamagedItemEnumDTO.ShowerRail]: 'ticket.options.damagedItem.showerRail',
  [DamagedItemEnumDTO.Sink]: 'ticket.options.damagedItem.sink',
  [DamagedItemEnumDTO.SinkOrFountain]:
    'ticket.options.damagedItem.sinkOrFountain',
  [DamagedItemEnumDTO.SmokeDetector]:
    'ticket.options.damagedItem.smokeDetector',
  [DamagedItemEnumDTO.Socket]: 'ticket.options.damagedItem.socket',
  [DamagedItemEnumDTO.SocketWithSwitch]:
    'ticket.options.damagedItem.socketWithSwitch',
  [DamagedItemEnumDTO.SolarPanels]: 'ticket.options.damagedItem.solarPanels',
  [DamagedItemEnumDTO.SprinklerSystem]:
    'ticket.options.damagedItem.sprinklerSystem',
  [DamagedItemEnumDTO.StairGate]: 'ticket.options.damagedItem.stairGate',
  [DamagedItemEnumDTO.Step]: 'ticket.options.damagedItem.step',
  [DamagedItemEnumDTO.StreetWorkoutZoneEquipment]:
    'ticket.options.damagedItem.streetWorkoutZoneEquipment',
  [DamagedItemEnumDTO.Switch]: 'ticket.options.damagedItem.switch',
  [DamagedItemEnumDTO.Tap]: 'ticket.options.damagedItem.tap',
  [DamagedItemEnumDTO.TelevisionOrTelephoneConnection]:
    'ticket.options.damagedItem.televisionOrTelephoneConnection',
  [DamagedItemEnumDTO.Thermostat]: 'ticket.options.damagedItem.thermostat',
  [DamagedItemEnumDTO.Toilet]: 'ticket.options.damagedItem.toilet',
  [DamagedItemEnumDTO.VentilationBalance]:
    'ticket.options.damagedItem.ventilationBalance',
  [DamagedItemEnumDTO.VentilationGrid]:
    'ticket.options.damagedItem.ventilationGrid',
  [DamagedItemEnumDTO.Vermin]: 'ticket.options.damagedItem.vermin',
  [DamagedItemEnumDTO.Wall]: 'ticket.options.damagedItem.wall',
  [DamagedItemEnumDTO.WashingMachine]:
    'ticket.options.damagedItem.washingMachine',
  [DamagedItemEnumDTO.WashingMachineFaucet]:
    'ticket.options.damagedItem.washingMachineFaucet',
  [DamagedItemEnumDTO.WaspNest]: 'ticket.options.damagedItem.waspNest',
  [DamagedItemEnumDTO.WaterSystem]: 'ticket.options.damagedItem.waterSystem',
  [DamagedItemEnumDTO.WindowInside]: 'ticket.options.damagedItem.windowInside',
  [DamagedItemEnumDTO.WindowOutside]:
    'ticket.options.damagedItem.windowOutside',
};

const complaintTypeTranslationLabelKeyMap: Record<
  ComplaintTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [ComplaintTypeEnumDTO.CleaningCommonAreaComplaint]:
    'ticket.options.complaintType.cleaningCommonAreaComplaint',
  [ComplaintTypeEnumDTO.ConstructionWorkNoiseComplaint]:
    'ticket.options.complaintType.constructionWorkNoiseComplaint',
  [ComplaintTypeEnumDTO.GarbageComplaint]:
    'ticket.options.complaintType.garbageComplaint',
  [ComplaintTypeEnumDTO.GreeneryComplaint]:
    'ticket.options.complaintType.greeneryComplaint',
  [ComplaintTypeEnumDTO.HeimstadenComplaint]:
    'ticket.options.complaintType.heimstadenComplaint',
  [ComplaintTypeEnumDTO.NeighbourComplaint]:
    'ticket.options.complaintType.neighbourComplaint',
  [ComplaintTypeEnumDTO.Other]: 'ticket.options.complaintType.other',
  [ComplaintTypeEnumDTO.SmokeOdorComplaint]:
    'ticket.options.complaintType.smokeOdorComplaint',
  [ComplaintTypeEnumDTO.CustomerServiceComplaint]:
    'ticket.options.complaintType.customerServiceComplaint',
  [ComplaintTypeEnumDTO.GeneralNoiseIssueComplaint]:
    'ticket.options.complaintType.generalNoiseIssueComplaint',
  [ComplaintTypeEnumDTO.BreakingHouseRulesComplaint]:
    'ticket.options.complaintType.breakingHouseRulesComplaint',
  [ComplaintTypeEnumDTO.PetsComplaint]:
    'ticket.options.complaintType.petsComplaint',
};

const contractRequestTypeTranslationLabelKeyMap: Record<
  ContractRequestTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [ContractRequestTypeEnumDTO.ApplicationForLandlordStatement]:
    'ticket.options.contractRequestType.applicationForLandlordStatement',
  [ContractRequestTypeEnumDTO.ChangeApartment]:
    'ticket.options.contractRequestType.changeApartment',
  [ContractRequestTypeEnumDTO.ChangeOrUpdateContract]:
    'ticket.options.contractRequestType.changeOrUpdateContract',
  [ContractRequestTypeEnumDTO.ChangeOrUpdateCustomerInformation]:
    'ticket.options.contractRequestType.changeOrUpdateCustomerInformation',
  [ContractRequestTypeEnumDTO.ConfirmationReferenceOfTenancy]:
    'ticket.options.contractRequestType.confirmationReferenceOfTenancy',
  [ContractRequestTypeEnumDTO.ContractRenewals]:
    'ticket.options.contractRequestType.contractRenewals',
  [ContractRequestTypeEnumDTO.ContractTermination]:
    'ticket.options.contractRequestType.contractTermination',
  [ContractRequestTypeEnumDTO.CopyOfContract]:
    'ticket.options.contractRequestType.copyOfContract',
  [ContractRequestTypeEnumDTO.Other]:
    'ticket.options.contractRequestType.other',
  [ContractRequestTypeEnumDTO.ParkingSpace]:
    'ticket.options.contractRequestType.parkingSpace',
  [ContractRequestTypeEnumDTO.QuestionsAboutRentIncrease]:
    'ticket.options.contractRequestType.questionsAboutRentIncrease',
  [ContractRequestTypeEnumDTO.RequestForRentSpecification]:
    'ticket.options.contractRequestType.requestForRentSpecification',
  [ContractRequestTypeEnumDTO.Storage]:
    'ticket.options.contractRequestType.storage',
};

const invoiceRequestTypeTranslationLabelKeyMap: Record<
  InvoiceRequestTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [InvoiceRequestTypeEnumDTO.ChangingBankAccountNumber]:
    'ticket.options.invoiceRequestType.changingBankAccountNumber',
  [InvoiceRequestTypeEnumDTO.CopyOfInvoice]:
    'ticket.options.invoiceRequestType.copyOfInvoice',
  [InvoiceRequestTypeEnumDTO.EnforcementOfficerProcessInquiry]:
    'ticket.options.invoiceRequestType.enforcementOfficerProcessInquiry',
  [InvoiceRequestTypeEnumDTO.GeneralInvoiceOrPaymentInquiries]:
    'ticket.options.invoiceRequestType.generalInvoiceOrPaymentInquiries',
  [InvoiceRequestTypeEnumDTO.Other]: 'ticket.options.invoiceRequestType.other',
  [InvoiceRequestTypeEnumDTO.OverviewOfOutstandingPayments]:
    'ticket.options.invoiceRequestType.overviewOfOutstandingPayments',
  [InvoiceRequestTypeEnumDTO.RequestForArrangedPayment]:
    'ticket.options.invoiceRequestType.requestForArrangedPayment',
  [InvoiceRequestTypeEnumDTO.RequestForAutomaticPayment]:
    'ticket.options.invoiceRequestType.requestForAutomaticPayment',
};

const statusTranslationLabelKeyMap: Record<
  TicketStatusEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [TicketStatusEnumDTO.AwaitingExternalProvider]:
    'ticket.options.status.awaitingExternalProvider',
  [TicketStatusEnumDTO.AwaitingTenant]: 'ticket.options.status.awaitingTenant',
  [TicketStatusEnumDTO.Done]: 'ticket.options.status.done',
  [TicketStatusEnumDTO.InProgress]: 'ticket.options.status.inProgress',
  [TicketStatusEnumDTO.OnHold]: 'ticket.options.status.onHold',
  [TicketStatusEnumDTO.Open]: 'ticket.options.status.open',
};

export const getIssueLocationTranslationLabelKey = (
  issueLocation: LocationTypeEnumDTO,
): GenericTypes.TranslationLabel =>
  issueLocationToTranslationLabelKeyMap[issueLocation] || issueLocation;

export const getIssueTypeTranslationLabelKey = (
  issueType: IssueTypeEnumDTO,
): GenericTypes.TranslationLabel =>
  issueTypeToTranslationLabelKeyMap[issueType] || issueType;

export const getIssueSubtypeTranslationLabelKey = (
  issueSubtype: IssueSubtypeEnumDTO,
): GenericTypes.TranslationLabel =>
  issueSubtypeToTranslationLabelKeyMap[issueSubtype] || issueSubtype;

export const getDepartmentTypeTranslationLabelKey = (
  departmentType: DepartmentTypeEnumDTO,
): GenericTypes.TranslationLabel =>
  departmentTypeToTranslationLabelKeyMap[departmentType] || departmentType;

export const getEntityTypeTranslationLabelKey = (
  entityType: EntityEnumDTO,
): GenericTypes.TranslationLabel =>
  entityTypeToTranslationLabelKeyMap[entityType] || entityType;

export const getTicketTypeTranslationLabelKey = (
  ticketType: TicketTypeEnumDTO,
): GenericTypes.TranslationLabel =>
  ticketTypeToTranslationLabelKeyMap[ticketType] || ticketType;

export const getPriorityTypeTranslationLabelKey = (
  priorityType?: PriorityTypeEnumDTO,
): GenericTypes.TranslationLabel => {
  const type = priorityType || PriorityTypeEnumDTO.Medium;

  return (
    priorityTypeToTranslationLabelKeyMap[type] || PriorityTypeEnumDTO.Medium
  );
};

export const getRoomTypeTranslationLabelKey = (
  roomType: RoomTypeEnumDTO,
): GenericTypes.TranslationLabel =>
  roomTypeToTranslationLabelKeyMap[roomType] || roomType;

export const getComplaintTypeTranslationLabelKey = (
  complaintType: ComplaintTypeEnumDTO,
): GenericTypes.TranslationLabel =>
  complaintTypeTranslationLabelKeyMap[complaintType] || complaintType;

export const getContractRequestTypeTranslationLabelKey = (
  contractRequestType: ContractRequestTypeEnumDTO,
): GenericTypes.TranslationLabel =>
  contractRequestTypeTranslationLabelKeyMap[contractRequestType] ||
  contractRequestType;

export const getInvoiceRequestTypeTranslationLabelKey = (
  invoiceRequestType: InvoiceRequestTypeEnumDTO,
): GenericTypes.TranslationLabel =>
  invoiceRequestTypeTranslationLabelKeyMap[invoiceRequestType] ||
  invoiceRequestType;

export const getStatusTranslationLabelKey = (
  status: TicketStatusEnumDTO,
): GenericTypes.TranslationLabel =>
  statusTranslationLabelKeyMap[status] || status;

export const getDamagedItemTranslationLabelKey = (
  damagedItem: DamagedItemEnumDTO,
): GenericTypes.TranslationLabel =>
  damagedItemToTranslationLabelKeyMap[damagedItem] || damagedItem;
