import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import type {
  AdvertisementIntegrationSerializerDTO,
  CountrySerializerDTO,
} from '../../../../../connectors/company';
import type { UnitAdvertisementSerializerDTO } from '../../../../../connectors/property';
import { AdvertisementIntegrationStatusEnumDTO } from '../../../../../connectors/property';
import type { Country } from '../../../../shared';
import { Feature } from '../../../../shared';
import { useAdvertisementConfig } from '../../providers';
import { Unpublish } from '../Unpublish/unpublish.component';
import { sxProps } from './integrations-feature.styles';

type Props = {
  country: Country | CountrySerializerDTO['uuid'];
  integrations: UnitAdvertisementSerializerDTO['integrations'];
  isUnpublishButton?: boolean;
};

export const IntegrationsFeature: FC<Props> = (props) => {
  const { country, integrations, isUnpublishButton = false } = props;
  const { getCountryConfig } = useAdvertisementConfig();
  const config = useMemo(
    () => getCountryConfig(country),
    [country, getCountryConfig],
  );
  const getAdvertisementUrl = useCallback(
    (integrationId: AdvertisementIntegrationSerializerDTO['uuid']) => {
      const integration = integrations.find(
        (integration) => integration.integration.uuid === integrationId,
      );

      return integration?.status ===
        AdvertisementIntegrationStatusEnumDTO.Success
        ? integration.advertisementUrl
        : undefined;
    },
    [integrations],
  );
  const isOnline = useCallback(
    (integrationId: AdvertisementIntegrationSerializerDTO['uuid']) =>
      integrations.some(
        (integration) =>
          integration.status ===
            AdvertisementIntegrationStatusEnumDTO.Success &&
          integration.integration.uuid === integrationId,
      ),
    [integrations],
  );
  const isSomeOnline = useMemo(
    () =>
      integrations.some(
        (integration) =>
          integration.status === AdvertisementIntegrationStatusEnumDTO.Success,
      ),
    [integrations],
  );

  return (
    <Box sx={sxProps.box}>
      <Box>
        <Feature
          dataTestId="integration-feature"
          titleKey="advertisement.feature.integrations"
          value={
            <Grid container spacing={1}>
              {config.integrations.map((integration) => (
                <Grid item key={integration.uuid}>
                  <Link
                    href={getAdvertisementUrl(integration.uuid)}
                    target="_blank"
                  >
                    <Avatar
                      src={integration.logoUrl}
                      sx={
                        {
                          ...sxProps.avatar,
                          ...(!isOnline(integration.uuid) && sxProps.disabled),
                        } as SxProps
                      }
                    />
                  </Link>
                </Grid>
              ))}
            </Grid>
          }
        />
      </Box>
      {isUnpublishButton && isSomeOnline && (
        <Unpublish advertisementIntegrations={integrations} country={country} />
      )}
    </Box>
  );
};
