/* eslint-disable max-len */
import { ReactComponent as BathroomTubTowelIcon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/bathroom/bathroom-tub-towel.svg';
import { ReactComponent as LightBulb1Icon } from '@heimstaden/icons-library/img/streamline-regular/lamps-lights-fire/light-bubbles/light-bulb-1.svg';
import { addDays } from 'date-fns';
import * as Yup from 'yup';

import type { ServiceRequestDetailSerializerDTO } from '../../../../../../connectors/ticket';
import { LocationTypeEnumDTO } from '../../../../../../connectors/ticket';
import type { FieldConfig } from '../../../../../shared';
import { Country, DEFAULT_DATE_FIELD_VALUE } from '../../../../../shared';
import { DEFAULT_SELECT_OPTION } from '../../../../../shared/form/form.const';
import type { TicketConfig } from '../../../providers';
import {
  isIssueSubtypeFieldDisabled,
  isIssueTypeFieldDisabled,
  isRoomWithIssueFieldDisabled,
} from '../../../providers';
import { isDamagedItemFieldDisabled } from '../../../providers/TicketConfig/helper';
import type {
  DetailsData,
  RequestDetailsData,
  ServiceRequestValues,
} from '../../../types';

const getRoomWithIssueFieldConfig = (
  getRoomTypeOptions: TicketConfig['getRoomTypeOptions'],
  initialValue?: DetailsData<ServiceRequestDetailSerializerDTO>['roomWithIssue'],
): FieldConfig<ServiceRequestValues> => ({
  componentType: 'select',
  field: {
    initialValue: initialValue || DEFAULT_SELECT_OPTION.value,
    name: 'roomWithIssue',
    validationSchema: Yup.string().when(['country', 'issueLocation'], {
      is: (country: Country, issueLocation: LocationTypeEnumDTO) =>
        country !== Country.NO ||
        issueLocation === LocationTypeEnumDTO.Apartment,
      otherwise: Yup.string().nullable(),
      then: Yup.string().required('form.errors.required'),
    }),
  },
  props: {
    disableWhen: ({ issueLocation }) =>
      isRoomWithIssueFieldDisabled(issueLocation),
    isHidden: ({ issueLocation }) => {
      const options = getRoomTypeOptions(issueLocation);

      return options.length < 2;
    },
    labelKey: 'ticket.fields.roomWithIssue.label',
    options: ({ issueLocation }) => getRoomTypeOptions(issueLocation),
    placeholderKey: 'ticket.fields.roomWithIssue.placeholder',
    relatedFields: ({ country }) => [
      ...(country !== Country.NO ? (['damagedItem'] as const) : []),
      'department',
      'dueDate',
      'issueSubtype',
      'issueType',
    ],
    requiredWhen: ({ country, issueLocation }) =>
      country !== Country.NO || issueLocation === LocationTypeEnumDTO.Apartment,
    startAdornmentIcon: BathroomTubTowelIcon,
  },
});
const getIssueTypeFieldConfig = (
  getIssueTypeOptions: TicketConfig['getIssueTypeOptions'],
  initialValue?: DetailsData<ServiceRequestDetailSerializerDTO>['issueType'],
): FieldConfig<ServiceRequestValues> => ({
  componentType: 'select',
  field: {
    initialValue: initialValue || DEFAULT_SELECT_OPTION.value,
    name: 'issueType',
  },
  props: {
    disableWhen: ({ issueLocation, roomWithIssue }) =>
      isIssueTypeFieldDisabled(issueLocation, roomWithIssue),
    labelKey: 'ticket.fields.issueType.label',
    options: ({ issueLocation, roomWithIssue }) =>
      getIssueTypeOptions(issueLocation, roomWithIssue),
    placeholderKey: 'ticket.fields.issueType.placeholder',
    relatedFields: ({ country }) => [
      ...(country !== Country.NO ? (['damagedItem'] as const) : []),
      'department',
      'dueDate',
      'issueSubtype',
    ],
    required: true,
    startAdornmentIcon: LightBulb1Icon,
  },
});
const getDamagedItemFieldConfig = (
  getDamageItemOptions: TicketConfig['getDamagedItemOptions'],
  initialValue?: DetailsData<ServiceRequestDetailSerializerDTO>['damagedItem'],
): FieldConfig<ServiceRequestValues> => ({
  componentType: 'select',
  field: {
    initialValue: initialValue || DEFAULT_SELECT_OPTION.value,
    name: 'damagedItem',
    validationSchema: Yup.string().when(['country'], {
      is: (country: Country) => country !== Country.NO,
      otherwise: Yup.string().nullable(),
      then: Yup.string().required('form.errors.required'),
    }),
  },
  props: {
    disableWhen: ({ issueLocation, issueType, roomWithIssue }) =>
      isDamagedItemFieldDisabled(issueLocation, issueType, roomWithIssue),
    labelKey: 'ticket.fields.damagedItem.label',
    options: ({ issueLocation, issueType, roomWithIssue }) =>
      getDamageItemOptions(issueLocation, issueType, roomWithIssue),
    placeholderKey: 'ticket.fields.damagedItem.placeholder',
    relatedFields: ['department', 'dueDate', 'issueSubtype'],
    requiredWhen: ({ country }) => country !== Country.NO,
  },
});
const getIssueSubtypeFieldConfig = (
  getIssueSubtypes: TicketConfig['getIssueSubtypes'],
  initialValue?: DetailsData<ServiceRequestDetailSerializerDTO>['issueSubtype'],
): FieldConfig<ServiceRequestValues> => ({
  componentType: 'select',
  field: {
    initialValue: initialValue || DEFAULT_SELECT_OPTION.value,
    name: 'issueSubtype',
  },
  props: {
    disableWhen: ({ damagedItem, issueLocation, issueType, roomWithIssue }) => {
      const isFieldDisabled = isIssueSubtypeFieldDisabled(
        issueLocation,
        issueType,
        roomWithIssue,
        damagedItem,
      );
      const options = getIssueSubtypes(
        issueLocation,
        issueType,
        roomWithIssue,
        damagedItem,
      );

      return isFieldDisabled || options.length === 0;
    },
    getReferenceFieldsToValuesMap: ({
      damagedItem,
      issueLocation,
      issueSubtype,
      issueType,
      roomWithIssue,
    }) => {
      const options = getIssueSubtypes(
        issueLocation,
        issueType,
        roomWithIssue,
        damagedItem,
      );
      const foundOption = options.find(
        (option) => option.value === issueSubtype,
      );

      if ((!issueSubtype && options.length > 0) || !foundOption) {
        return {};
      }

      return {
        department: foundOption.department,
        dueDate: addDays(new Date(), foundOption.businessDays),
      };
    },
    labelKey: 'ticket.fields.issueSubtype.label',
    options: ({ damagedItem, issueLocation, issueType, roomWithIssue }) =>
      getIssueSubtypes(
        issueLocation,
        issueType,
        roomWithIssue,
        damagedItem,
      ).map(({ labelKey, value }) => ({ labelKey, value })),
    placeholderKey: 'ticket.fields.issueSubtype.placeholder',
    relatedFields: ['dueDate', 'department'],
    required: true,
  },
});
const getDueDateFieldConfig = (
  initialValue?: string,
): FieldConfig<ServiceRequestValues> => ({
  componentType: 'date',
  field: {
    initialValue: initialValue
      ? new Date(initialValue)
      : DEFAULT_DATE_FIELD_VALUE,
    name: 'dueDate',
  },
  props: {
    disableWhen: ({ issueType, issueSubtype, roomWithIssue }) =>
      !issueType || !issueSubtype || !roomWithIssue,
    labelKey: 'ticket.fields.dueDate',
    minDate: addDays(new Date(), 1),
  },
});

export const getFieldsConfig = (
  config: Pick<
    TicketConfig,
    | 'getDamagedItemOptions'
    | 'getIssueSubtypes'
    | 'getIssueTypeOptions'
    | 'getRoomTypeOptions'
    | 'locationTypeOptions'
  >,
  details?: RequestDetailsData<ServiceRequestDetailSerializerDTO>,
): FieldConfig<ServiceRequestValues>[] => {
  const roomWithIssuesFieldConfig = getRoomWithIssueFieldConfig(
    config.getRoomTypeOptions,
    details?.roomWithIssue,
  );
  const issueTypeFieldConfig = getIssueTypeFieldConfig(
    config.getIssueTypeOptions,
    details?.issueType,
  );
  const damagedItemFieldConfig = getDamagedItemFieldConfig(
    config.getDamagedItemOptions,
    details?.damagedItem,
  );
  const issueSubtypeFieldConfig = getIssueSubtypeFieldConfig(
    config.getIssueSubtypes,
    details?.issueSubtype,
  );
  const dueDateFieldConfig = getDueDateFieldConfig(details?.dueDate);

  return [
    {
      componentType: 'advanced-radio',
      field: {
        initialValue: details?.issueLocation,
        name: 'issueLocation',
      },
      props: {
        options: (values) => [
          {
            helperTextKey: 'ticket.options.issueLocation.apartment.helperText',
            labelKey: 'ticket.options.issueLocation.apartment.label',
            nestedFieldsConfig: [
              roomWithIssuesFieldConfig,
              issueTypeFieldConfig,
              ...(values?.country !== Country.NO
                ? [damagedItemFieldConfig]
                : []),
              issueSubtypeFieldConfig,
              dueDateFieldConfig,
            ],
            value: LocationTypeEnumDTO.Apartment,
          },
          {
            helperTextKey: 'ticket.options.issueLocation.commonArea.helperText',
            labelKey: 'ticket.options.issueLocation.commonArea.label',
            nestedFieldsConfig: [
              roomWithIssuesFieldConfig,
              issueTypeFieldConfig,
              ...(values?.country !== Country.NO
                ? [damagedItemFieldConfig]
                : []),
              issueSubtypeFieldConfig,
              dueDateFieldConfig,
            ],
            value: LocationTypeEnumDTO.CommonArea,
          },
          ...(values?.country !== Country.NO
            ? [
                {
                  helperTextKey:
                    'ticket.options.issueLocation.commonOutdoorArea.helperText',
                  labelKey:
                    'ticket.options.issueLocation.commonOutdoorArea.label',
                  nestedFieldsConfig: [
                    roomWithIssuesFieldConfig,
                    issueTypeFieldConfig,
                    damagedItemFieldConfig,
                    issueSubtypeFieldConfig,
                    dueDateFieldConfig,
                  ],
                  value: LocationTypeEnumDTO.CommonOutdoorArea,
                },
                {
                  helperTextKey:
                    'ticket.options.issueLocation.privateOutdoorArea.helperText',
                  labelKey:
                    'ticket.options.issueLocation.privateOutdoorArea.label',
                  nestedFieldsConfig: [
                    roomWithIssuesFieldConfig,
                    issueTypeFieldConfig,
                    damagedItemFieldConfig,
                    issueSubtypeFieldConfig,
                    dueDateFieldConfig,
                  ],
                  value: LocationTypeEnumDTO.PrivateOutdoorArea,
                },
              ]
            : [
                {
                  helperTextKey:
                    'ticket.options.issueLocation.outdoorArea.helperText',
                  labelKey: 'ticket.options.issueLocation.outdoorArea.label',
                  nestedFieldsConfig: [
                    roomWithIssuesFieldConfig,
                    issueTypeFieldConfig,
                    issueSubtypeFieldConfig,
                    dueDateFieldConfig,
                  ],
                  value: LocationTypeEnumDTO.OutdoorArea,
                },
              ]),
        ],
        required: true,
      },
    },
  ];
};
