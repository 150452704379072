import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';

import { useLocalization } from '../../../../../shared';
import { FormFieldset } from '../../../../../shared/form/components';
import { UnitPreview } from '../UnitPreview/unit-preview.component';
import type { FormValues } from '../form.types';
import { getFieldsetConfig } from './address-step.model';

export const AddressStep: FC = () => {
  const { userCountries } = useLocalization();
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const fieldsetConfig = useMemo(
    () => getFieldsetConfig(userCountries),
    [userCountries],
  );

  useEffect(() => {
    if (values.country) return;

    setFieldValue('country', userCountries[0]?.value);
  }, [setFieldValue, userCountries, values.country]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {fieldsetConfig.map((config) => (
            <FormFieldset<FormValues>
              config={config}
              key={`fieldset-${config.key}`}
              values={values}
            />
          ))}
        </Grid>
      </Grid>
      <UnitPreview />
    </Grid>
  );
};
