import { ReactComponent as Pencil2Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/edit/pencil-2.svg';
import React from 'react';
import type { FC } from 'react';

import { Icon } from '../Icon/icon.component';

type Props = {
  dataTestId: string;
  to: string;
  labelKey?: GenericTypes.TranslationLabel;
};

export const Update: FC<Props> = (props) => {
  return <Icon icon={Pencil2Icon} {...props} />;
};
