import { Permission } from '../../../+auth/shared/enums/permission.enum';

export const CAN_CREATE_CONTACTS = [
  Permission.GLOBAL_READ,
  Permission.WRITE_PROPERTY,
  Permission.CAN_CREATE_PROPERTY_OBJECT_CONTACT,
];
export const CAN_DELETE_CONTACTS = [
  Permission.GLOBAL_READ,
  Permission.WRITE_PROPERTY,
  Permission.CAN_DELETE_PROPERTY_OBJECT_CONTACT,
];
export const CAN_VIEW_CONTACTS = [
  Permission.GLOBAL_READ,
  Permission.READ_PROPERTY,
  Permission.CAN_VIEW_PROPERTY_OBJECT_CONTACT,
];
export const CREATE_PERMISSIONS = [
  Permission.GLOBAL_WRITE,
  Permission.WRITE_PROPERTY,
  Permission.CAN_CREATE_PROPERTY_OBJECT,
];
export const UPDATE_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.WRITE_PROPERTY,
  Permission.CAN_UPDATE_PROPERTY_OBJECT,
];
export const VIEW_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.READ_PROPERTY,
  Permission.CAN_VIEW_PROPERTY_OBJECT,
];
