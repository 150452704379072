import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { CSSProperties } from 'react';

export const MAP_STYLES: CSSProperties = { borderRadius: 16, height: '100%' };
export const sxProps: Record<string, SxProps<Theme>> = {
  content: {
    // hiding google maps default info window
    '& .gm-style-iw-t': {
      opacity: 0,
      pointerEvents: 'none',
    },
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
};
