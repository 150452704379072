import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  avatar: {
    height: '100%',
    maxHeight: 24,
    maxWidth: 24,
    width: '100%',
  },
};
