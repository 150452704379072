import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  column: {
    maxHeight: 420,
    pb: 2,
    pt: 0,
  },
  list: {
    mb: 2,
    p: 0,
  },
};
