import { badgeClasses } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const NOTIFICATION_HEIGHT = 470;
export const NOTIFICATION_SPACES = 64;

export const sxProps: Record<string, SxProps<Theme>> = {
  badge: {
    [`& .${badgeClasses.dot}`]: {
      borderRadius: '50%',
      bottom: (theme) => theme.spacing(1.5),
      height: 12,
      right: (theme) => theme.spacing(1.25),
      width: 12,
    },
  },
  notificationBell: {
    '&:hover': {
      backgroundColor: (theme) => theme.palette.background.paper,
    },
    borderRadius: '10%',
    ml: 1,
  },
  paper: {
    borderRadius: 6,
    height: NOTIFICATION_HEIGHT,
    p: 2,
    width: 500,
  },
};
