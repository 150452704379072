import React, { useCallback, useMemo } from 'react';
import type { FC, ReactNode } from 'react';

import { useAuth } from '../../../+auth';
import { localizationClient } from '../../../localization.client';
import { useApi } from '../../hooks';
import type { ContextValue } from './context';
import { Context } from './context';
import type { Country } from './enum';

type Props = {
  children: ReactNode;
};

export const Provider: FC<Props> = (props) => {
  const { children } = props;
  const { user } = useAuth();
  const getCountryOptions = useCallback(
    () => localizationClient.getCountries$(),
    [],
  );
  const { response: countryOptions } = useApi([], getCountryOptions);
  const isCurrentCountry = useCallback(
    (uuid: Country) => user?.countryUuids?.[0] === uuid, // TODO: Find a way to handle current country
    [user?.countryUuids],
  );
  const userCountries = useMemo(
    () =>
      countryOptions?.filter((country) =>
        user?.countryUuids?.includes(country.value),
      ) || [],
    [countryOptions, user?.countryUuids],
  );
  const value = useMemo<ContextValue>(
    () => ({ countryOptions, isCurrentCountry, userCountries }),
    [countryOptions, isCurrentCountry, userCountries],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
