/* eslint-disable max-len */
import type { TFunction } from 'i18next';

import type { LanguageDTO } from '../../../../../connectors/company';
import type {
  AwaitingExternalProviderSerializerDTO,
  AwaitingTenantSerializerDTO,
  DoneSerializerDTO,
  OnHoldSerializerDTO,
} from '../../../../../connectors/ticket';
import {
  ReasonAwaitingExternalProviderEnumDTO,
  ReasonAwaitingTenantEnumDTO,
  ReasonOnHoldEnumDTO,
  SolutionDoneEnumDTO,
  TicketStatusEnumDTO,
} from '../../../../../connectors/ticket';
import type { SelectOption } from '../../../../shared';
import { formatStandardDate } from '../../../../shared';
import { getStatusTranslationLabelKey } from '../../consts';
import type { Values } from './form-model.const';

export const isTransitionFormNeeded = (
  transition: TicketStatusEnumDTO,
): boolean =>
  [
    TicketStatusEnumDTO.AwaitingTenant,
    TicketStatusEnumDTO.AwaitingExternalProvider,
    TicketStatusEnumDTO.Done,
    TicketStatusEnumDTO.OnHold,
  ].includes(transition);

const transitionAwaitingTenantSolutionSelectOptions: SelectOption[] = [
  {
    labelKey: 'ticket.options.transitionSolution.awaitingTenantReply',
    value: ReasonAwaitingTenantEnumDTO.AwaitingTenantReply,
  },
  {
    labelKey:
      'ticket.options.transitionSolution.futureAppointmentAgreedWithTenant',
    value: ReasonAwaitingTenantEnumDTO.FutureAppointmentAgreedWithTenant,
  },
];

const transitionAwaitingExternalProviderSolutionSelectOptions: SelectOption[] =
  [
    {
      labelKey: 'ticket.options.transitionSolution.lackOfInternalExpertise',
      value: ReasonAwaitingExternalProviderEnumDTO.LackOfInternalExpertise,
    },
    {
      labelKey: 'ticket.options.transitionSolution.lackOfInternalResources',
      value: ReasonAwaitingExternalProviderEnumDTO.LackOfInternalResource,
    },
    {
      labelKey: 'ticket.options.transitionSolution.other',
      value: ReasonAwaitingExternalProviderEnumDTO.Other,
    },
  ];

const transitionDoneSolutionSelectOptions: SelectOption[] = [
  {
    labelKey: 'ticket.options.transitionSolution.doneByHeimstaden',
    value: SolutionDoneEnumDTO.DoneByHeimstaden,
  },
  {
    labelKey: 'ticket.options.transitionSolution.doneByExternalPartner',
    value: SolutionDoneEnumDTO.DoneByExternalPartners,
  },
  {
    labelKey: 'ticket.options.transitionSolution.doneByTheTenantHimself',
    value: SolutionDoneEnumDTO.DoneByTheTenantHimself,
  },
  {
    labelKey:
      'ticket.options.transitionSolution.agreementOnTheTenantsMaintenanceObligation',
    value: SolutionDoneEnumDTO.AgreementOnTheTenantsMaintenanceObligation,
  },
  // TODO: Other option is missing
  // {
  //   labelKey: 'ticket.options.transitionSolution.other',
  //   value: SolutionDoneEnumDTO.Other,
  // },
];

const transitionOnHoldSolutionSelectOptions: SelectOption[] = [
  {
    labelKey: 'ticket.options.transitionSolution.awaitingInternalHelp',
    value: ReasonOnHoldEnumDTO.AwaitingHelpFromInternalResource,
  },
  {
    labelKey: 'ticket.options.transitionSolution.awaitingProductOrder',
    value: ReasonOnHoldEnumDTO.AwaitingProductOrder,
  },
  {
    labelKey: 'ticket.options.transitionSolution.other',
    value: ReasonOnHoldEnumDTO.Other,
  },
];

export const getSelectOptions = (
  transition: TicketStatusEnumDTO,
): SelectOption[] => {
  switch (transition) {
    case TicketStatusEnumDTO.AwaitingTenant:
      return transitionAwaitingTenantSolutionSelectOptions;
    case TicketStatusEnumDTO.AwaitingExternalProvider:
      return transitionAwaitingExternalProviderSolutionSelectOptions;
    case TicketStatusEnumDTO.Done:
      return transitionDoneSolutionSelectOptions;
    case TicketStatusEnumDTO.OnHold:
      return transitionOnHoldSolutionSelectOptions;
    default:
      return [];
  }
};

export const getSolutionLabelKey = (
  transition: TicketStatusEnumDTO,
): GenericTypes.TranslationLabel => {
  switch (transition) {
    case TicketStatusEnumDTO.AwaitingTenant:
      return 'ticket.fields.awaitingTenantReason';
    case TicketStatusEnumDTO.AwaitingExternalProvider:
      return 'ticket.fields.awaitingExternalProviderReason';
    case TicketStatusEnumDTO.Done:
      return 'ticket.fields.solution';
    case TicketStatusEnumDTO.OnHold:
      return 'ticket.fields.onHoldReason';
    default:
      return '';
  }
};

const getName = (t: TFunction, transition: TicketStatusEnumDTO): string =>
  `${t('ticket.fields.status')}: ${t(
    getStatusTranslationLabelKey(transition),
  )}`;

const getSolution = (
  t: TFunction,
  transition: TicketStatusEnumDTO,
  values: Values,
): string | undefined => {
  const options = getSelectOptions(transition);
  const labelKey = options.find(
    (option) => option.value === values.solution,
  )?.labelKey;

  return labelKey
    ? `${t(getSolutionLabelKey(transition))}: ${t(labelKey)}`
    : undefined;
};

const getConfirmed = (
  t: TFunction,
  transition: TicketStatusEnumDTO,
  values: Values,
): string | undefined => {
  if (values.confirmed !== 'yes') return undefined;

  switch (transition) {
    case TicketStatusEnumDTO.Done:
      return t('ticket.fields.statusTransitionDoneConfirmed');
    case TicketStatusEnumDTO.OnHold:
      return t('ticket.fields.statusTransitionOnHoldConfirmed');
    default:
      return undefined;
  }
};

const getResumptionDate = (
  langCode: LanguageDTO,
  t: TFunction,
  transition: TicketStatusEnumDTO,
  values: Values,
): string | undefined =>
  values.resumptionDate
    ? t('ticket.fields.resumptionDate', {
        date: formatStandardDate(values.resumptionDate, langCode),
      })
    : undefined;

const getComment = (
  t: TFunction,
  transition: TicketStatusEnumDTO,
  values: Values,
): string | undefined =>
  values.isInternalComment && values.comment
    ? `${t('ticket.fields.comment')}: ${values.comment}`
    : undefined;

export const getTransitionComment = (
  langCode: LanguageDTO,
  t: TFunction,
  transition: TicketStatusEnumDTO,
  values: Values,
): string => {
  return [
    getName(t, transition),
    getSolution(t, transition, values),
    getConfirmed(t, transition, values),
    getResumptionDate(langCode, t, transition, values),
    getComment(t, transition, values),
  ]
    .filter((s) => Boolean(s))
    .join('\n');
};

export const getValues = (
  status: TicketStatusEnumDTO,
  values?: Values,
):
  | AwaitingExternalProviderSerializerDTO
  | AwaitingTenantSerializerDTO
  | DoneSerializerDTO
  | OnHoldSerializerDTO
  | undefined => {
  if (!values) return undefined;

  switch (status) {
    case TicketStatusEnumDTO.AwaitingExternalProvider:
      return {
        estimatedDateForResumption: values.resumptionDate || '',
        reasonAwaitingExternalProvider:
          values.solution as ReasonAwaitingExternalProviderEnumDTO,
      };
    case TicketStatusEnumDTO.AwaitingTenant:
      return {
        estimatedDateForResumption: values.resumptionDate || '',
        reasonAwaitingTenant: values.solution as ReasonAwaitingTenantEnumDTO,
      };
    case TicketStatusEnumDTO.Done:
      return {
        solutionDone: values.solution as SolutionDoneEnumDTO,
        solutionDoneComment: values.comment,
      };
    case TicketStatusEnumDTO.OnHold: {
      return {
        estimatedDateForResumption: values.resumptionDate || '',
        reasonOnHold: values.solution as ReasonOnHoldEnumDTO,
      };
    }
    default: {
      return undefined;
    }
  }
};
