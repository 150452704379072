import React, { useEffect, useMemo } from 'react';
import type { FC, ReactElement } from 'react';
import { toast } from 'react-hot-toast';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../../../+auth';
import type { UserTypeEnumDTO } from '../../../../connectors/user';
import { Spinner, useTranslation } from '../../../shared';
import PATHS from '../../paths.const';
import { isUserTypeEditable, isUserTypeSupported } from '../helpers';

interface Props {
  children: ReactElement;
  type: UserTypeEnumDTO;
}

export const UserTypePermissionsGuard: FC<Props> = (props) => {
  const { children, type } = props;
  const { t } = useTranslation();
  const { isUserFetched } = useAuth();
  const isEditable = useMemo(() => isUserTypeEditable(type), [type]);
  const isSupported = useMemo(() => isUserTypeSupported(type), [type]);

  useEffect(() => {
    if (!isUserFetched) return;

    if (!isSupported) {
      toast.error(t('errors.general.notSupportedUserType', { userType: type }));

      return;
    }

    if (!isEditable) {
      toast.error(t('errors.general.notEditableUserType', { userType: type }));
    }
  }, [isEditable, isSupported, isUserFetched, type, t]);

  if (!isUserFetched) {
    return <Spinner />;
  }

  if (!isEditable || !isSupported) {
    return <Navigate to={PATHS.LIST} />;
  }

  return children;
};
