import Button from '@mui/material/Button';
import React from 'react';
import type { FC, MouseEvent } from 'react';

import { useTranslation } from '../../translations';

type Props = {
  disabled: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  fullWidth?: boolean;
  labelKey?: GenericTypes.TranslationLabel;
};

export const CancelButton: FC<Props> = (props) => {
  const { disabled, fullWidth, labelKey, onClick } = props;
  const { t } = useTranslation();

  return (
    <Button
      color="primary"
      data-testid="form-navigation-button-cancel"
      disabled={disabled}
      onClick={onClick}
      variant="outlined"
      fullWidth={fullWidth}
    >
      {t(labelKey || 'form.buttons.cancel')}
    </Button>
  );
};

CancelButton.defaultProps = {
  fullWidth: undefined,
  labelKey: undefined,
};
