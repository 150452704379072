import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  tab: {
    '&:hover': {
      borderBottomColor: (theme) => theme.palette.primary.main,
    },
    borderBottom: (theme) => `2px solid ${theme.palette.grey[300]}`,
    fontWeight: (theme) => theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
};
