import { createContext } from 'react';

import type {
  CountrySerializerDTO,
  KYCIntegrationSerializerDTO,
} from '../../../../../connectors/company';
import { Country } from '../../../../shared/providers/Country/enum';
import type { Config } from './type';

export type ContextValue = {
  config: Record<Country | CountrySerializerDTO['uuid'], Config>;
  getIntegrationsConfig: (
    country?: Country | CountrySerializerDTO['uuid'],
  ) => KYCIntegrationSerializerDTO[];
};

export const initialCountryConfig: Config = {
  integrations: [],
};

export const initialConfig: ContextValue['config'] = {
  [Country.NL]: initialCountryConfig,
  [Country.NO]: initialCountryConfig,
  [Country.PL]: initialCountryConfig,
};
export const Context = createContext<ContextValue>({
  config: initialConfig,
  getIntegrationsConfig: () => [],
});
