import { useTheme } from '@mui/material/styles';
import React from 'react';
import type { FC, SVGProps } from 'react';
import { Link } from 'react-router-dom';

import { APP_MAIN_PATH } from '../../../routing';
import { useTranslation } from '../../translations';
import { ReactComponent as LogoSmallIcon } from './logo-small.svg';
import { LogoContainer } from './logo.styles';
import { ReactComponent as LogoIcon } from './logo.svg';

type Props = SVGProps<SVGSVGElement> & {
  isSmall?: boolean;
};

export const Logo: FC<Props> = (props) => {
  const { isSmall, ...rest } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const siteTitle = t('siteName');

  return (
    <LogoContainer>
      <Link to={APP_MAIN_PATH} title={siteTitle}>
        {isSmall ? (
          <LogoSmallIcon
            width={24}
            fill={theme.palette.primary.main}
            {...rest}
          />
        ) : (
          <LogoIcon width={192} fill={theme.palette.primary.main} {...rest} />
        )}
      </Link>
    </LogoContainer>
  );
};
