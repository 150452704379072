import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  button: {
    display: 'flex',
    marginLeft: 'auto',
  },
  features: {
    borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
    marginTop: 2,
    paddingTop: 2,
  },
  grid: {
    '& > div': {
      borderRight: (theme) => `1px solid ${theme.palette.grey[300]}`,
      paddingRight: 2,
    },
    '&:last-of-type > div': {
      borderRight: 'unset',
      paddingRight: 'unset',
    },
  },
  title: {
    marginBottom: 2,
  },
};
