import React from 'react';
import { Outlet } from 'react-router-dom';

import { ACTIVITY_LOG_VIEW_PERMISSIONS } from '../+activity-log';
import { PermissionGuard } from '../+auth/shared/guards/permission.guard';
import { READ_PERMISSIONS as TICKET_READ_PERMISSIONS } from '../+ticket/shared/consts/permission.const';
import { VIEW_PERMISSIONS as UNIT_VIEW_PERMISSIONS } from '../+unit/shared/consts/permission.const';
import { APP_MAIN_PATH } from '../routing';
import { componentLazyImport } from '../routing/routes.helper';
import { VIEW_PERMISSIONS as ATTACHMENTS_VIEW_PERMISSIONS } from '../shared/attachment/shared';
import { DefaultRouteTabContent } from '../shared/components/DefaultRouteTabContent/default-route-tab-view.component';
import PATHS from './paths.const';
import { CREATE_PERMISSIONS, VIEW_PERMISSIONS } from './shared';

const NotFound = componentLazyImport(
  () => import('../shared/errors/NotFound/not-found.component'),
  'PROPERTY_OBJECT_NOT_FOUND',
);
const DocumentList = componentLazyImport(
  () => import('./DocumentList/document-list.component'),
  'PROPERTY_OBJECT_DOCUMENT_LIST',
);
const ActivityLogList = componentLazyImport(
  () => import('./ActivityLogList/activity-log-list.component'),
  'PROPERTY_OBJECT_ACTIVITY_LOG_LIST',
);
const Create = componentLazyImport(
  () => import('./Create/create.component'),
  'PROPERTY_OBJECT_CREATE',
);
const Details = componentLazyImport(
  () => import('./Details/details.component'),
  'PROPERTY_OBJECT_DETAILS',
);
const List = componentLazyImport(
  () => import('./List/list.component'),
  'PROPERTY_OBJECT_LIST',
);
const ComplaintList = componentLazyImport(
  () => import('./ComplaintList/complaint-list.component'),
  'PROPERTY_OBJECT_COMPLAINT_LIST',
);
const ContractRequestList = componentLazyImport(
  () => import('./ContractRequestList/contract-request-list.component'),
  'PROPERTY_OBJECT_CREATE_REQUEST_LIST',
);
const GeneralRequestList = componentLazyImport(
  () => import('./GeneralRequestList/general-request-list.component'),
  'PROPERTY_OBJECT_GENERAL_REQUEST_LIST',
);
const InvoiceRequestList = componentLazyImport(
  () => import('./InvoiceRequestList/invoice-request-list.component'),
  'PROPERTY_OBJECT_INVOICE_REQUEST_LIST',
);
const ServiceRequestList = componentLazyImport(
  () => import('./ServiceRequestList/service-request-list.component'),
  'PROPERTY_OBJECT_SERVICE_REQUEST_LIST',
);
const HomeList = componentLazyImport(
  () => import('./HomeList/home-list.component'),
  'PROPERTY_OBJECT_HOME_LIST',
);
const FacilityList = componentLazyImport(
  () => import('./FacilityList/facility-list.component'),
  'PROPERTY_OBJECT_FACILITY_LIST',
);
const Update = componentLazyImport(
  () => import('./Update/update.component'),
  'PROPERTY_OBJECT_UPDATE',
);
const CreateComplaint = componentLazyImport(
  () => import('./CreateComplaint/create-complaint.component'),
  'PROPERTY_OBJECT_COMPLAINT_CREATE',
);
const CreateContractRequest = componentLazyImport(
  () => import('./CreateContractRequest/create-contract-request.component'),
  'PROPERTY_OBJECT_CONTRACT_REQUEST_CREATE',
);
const CreateGeneralRequest = componentLazyImport(
  () => import('./CreateGeneralRequest/create-general-request.component'),
  'PROPERTY_OBJECT_GENERAL_REQUEST_CREATE',
);
const CreateInvoiceRequest = componentLazyImport(
  () => import('./CreateInvoiceRequest/create-invoice-request.component'),
  'PROPERTY_OBJECT_INVOICE_REQUEST_CREATE',
);
const CreateServiceRequest = componentLazyImport(
  () => import('./CreateServiceRequest/create-service-request.component'),
  'PROPERTY_OBJECT_SERVICE_REQUEST_CREATE',
);
const defaultTabs = [
  {
    component: <HomeList />,
    expectedPermissions: UNIT_VIEW_PERMISSIONS,
  },
  {
    component: <DocumentList />,
    expectedPermissions: ATTACHMENTS_VIEW_PERMISSIONS,
  },
  {
    component: <ComplaintList />,
    expectedPermissions: TICKET_READ_PERMISSIONS,
  },
  {
    component: <ActivityLogList />,
    expectedPermissions: ACTIVITY_LOG_VIEW_PERMISSIONS,
  },
];

const routes = {
  children: [
    { element: <List />, path: '' },
    {
      element: (
        <PermissionGuard
          fallbackRoute={PATHS.LIST}
          permissions={CREATE_PERMISSIONS}
        >
          <Create />
        </PermissionGuard>
      ),
      path: 'create',
    },
    { element: <Update />, path: ':id/update' },
    {
      element: <CreateComplaint />,
      path: ':id/complaint',
    },
    {
      element: <CreateContractRequest />,
      path: ':id/contract-request',
    },
    {
      element: <CreateGeneralRequest />,
      path: ':id/general-request',
    },
    {
      element: <CreateInvoiceRequest />,
      path: ':id/invoice-request',
    },
    {
      element: <CreateServiceRequest />,
      path: ':id/service-request',
    },
    {
      children: [
        {
          children: [
            {
              element: <DefaultRouteTabContent tabs={defaultTabs} />,
              path: '',
            },
            { element: <HomeList />, path: 'homes' },
            { element: <FacilityList />, path: 'facilities' },
            { element: <ActivityLogList />, path: 'logs' },
            { element: <DocumentList />, path: 'documents' },
            { element: <ComplaintList />, path: 'complaints' },
            { element: <ContractRequestList />, path: 'contract-requests' },
            { element: <InvoiceRequestList />, path: 'invoice-requests' },
            { element: <GeneralRequestList />, path: 'general-requests' },
            { element: <ServiceRequestList />, path: 'service-requests' },
          ],
          element: <Outlet />,
          path: '',
        },
      ],
      element: <Details />,
      path: ':id',
    },
    { element: <NotFound />, path: '*' },
  ],
  element: (
    <PermissionGuard
      fallbackRoute={APP_MAIN_PATH}
      permissions={VIEW_PERMISSIONS}
    >
      <Outlet />
    </PermissionGuard>
  ),
  path: 'property-objects',
};

export default routes;
