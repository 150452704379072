import { ReactComponent as AddIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add.svg';
import type { FC } from 'react';
import React from 'react';

import { Icon } from '../Icon/icon.component';

type Props = {
  dataTestId: string;
  to: string;
};

export const Create: FC<Props> = (props) => <Icon icon={AddIcon} {...props} />;
