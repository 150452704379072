/* eslint-disable max-len */
import React, { forwardRef } from 'react';

const TRANSPARENT_IMG =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const InvisibleDragImage = forwardRef<HTMLImageElement>((props, ref) => {
  return (
    <img
      alt="dragImageRef"
      src={TRANSPARENT_IMG}
      style={{ visibility: 'hidden' }}
      ref={ref}
    />
  );
});
