import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../../../../shared/theme/palette';

export const sxProps: Record<string, SxProps<Theme>> = {
  text: {
    backgroundColor: palette.governorBay,
    borderRadius: 2,
    color: (theme) => theme.palette.primary.contrastText,
    fontWeight: (theme) => theme.typography.fontWeightBold,
    px: 1,
    py: 0.25,
    width: '100%',
  },
};
