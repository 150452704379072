import type {
  DepartmentTypeEnumDTO,
  UniversalTicketSerializerDTO,
} from '../../../../../connectors/ticket';
import type { FieldConfig, FormProps } from '../../../../shared';
import { DEFAULT_SELECT_OPTION } from '../../../../shared/form/form.const';
import { departmentTypeOptions } from '../../helpers';

export type Values = {
  department: DepartmentTypeEnumDTO | typeof DEFAULT_SELECT_OPTION.value;
};

export const getFieldsConfig = (
  department: UniversalTicketSerializerDTO['department'],
  handleOpenDisclaimerDialog?: () => Promise<void>,
  isDisabled = false,
): FieldConfig<Values>[] => [
  {
    componentType: 'select',
    field: {
      initialValue: department || DEFAULT_SELECT_OPTION.value,
      name: 'department',
    },
    props: {
      disabled: isDisabled,
      labelKey: 'ticket.fields.department',
      onBeforeOpen: handleOpenDisclaimerDialog,
      options: departmentTypeOptions,
    },
  },
];

export const formConfig: FormProps<Values>['config'] = {
  onSubmit: () => {
    // do nothing
  },
};
