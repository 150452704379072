import type { FC } from 'react';
import React, { useMemo } from 'react';

import type {
  ComplaintDetailSerializerDTO,
  ServiceRequestDetailSerializerDTO,
} from '../../../../../connectors/ticket';
import { useTranslation } from '../../../../shared';
import { getComplaintTypeTranslationLabelKey } from '../../consts';
import { DetailsFeatureList } from '../DetailsFeatureList/details-feature-list.component';

type Props = {
  complaintType: ComplaintDetailSerializerDTO['complaintType'];
  ticketType?: ServiceRequestDetailSerializerDTO['ticketType'];
};

export const ComplaintDetailsFeatureList: FC<Props> = (props) => {
  const { complaintType, ticketType } = props;
  const { t } = useTranslation();
  const features = useMemo(
    () => [
      {
        dataTestId: 'complaint-type',
        key: 'complaintType',
        textWrap: true,
        titleKey: 'ticket.fields.complaintType',
        value: complaintType
          .map((type) => t(getComplaintTypeTranslationLabelKey(type)))
          .join(', '),
      },
    ],
    [complaintType, t],
  );

  return <DetailsFeatureList features={features} ticketType={ticketType} />;
};
