/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdvertisementIntegrationListSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { KYCIntegrationListSerializerDTO } from '../dto';
// @ts-ignore
import { LanguageDTO } from '../dto';
/**
 * ConfigsAPI - axios parameter creator
 * @export
 */
export const ConfigsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Advertisement Integrations
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementIntegrationsConfigsPromotionsIntegrationsGet: async (countryUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryUuid' is not null or undefined
            assertParamExists('getAdvertisementIntegrationsConfigsPromotionsIntegrationsGet', 'countryUuid', countryUuid)
            const localVarPath = `/configs/promotions-integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Kyc Integrations
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycIntegrationsConfigsKycIntegrationsGet: async (countryUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryUuid' is not null or undefined
            assertParamExists('getKycIntegrationsConfigsKycIntegrationsGet', 'countryUuid', countryUuid)
            const localVarPath = `/configs/kyc-integrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Translations
         * @param {LanguageDTO} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationsConfigsTranslationsLanguageGet: async (language: LanguageDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            assertParamExists('getTranslationsConfigsTranslationsLanguageGet', 'language', language)
            const localVarPath = `/configs/translations/{language}`
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigsAPI - functional programming interface
 * @export
 */
export const ConfigsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Advertisement Integrations
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdvertisementIntegrationsConfigsPromotionsIntegrationsGet(countryUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementIntegrationListSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdvertisementIntegrationsConfigsPromotionsIntegrationsGet(countryUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Kyc Integrations
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKycIntegrationsConfigsKycIntegrationsGet(countryUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KYCIntegrationListSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKycIntegrationsConfigsKycIntegrationsGet(countryUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Translations
         * @param {LanguageDTO} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslationsConfigsTranslationsLanguageGet(language: LanguageDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranslationsConfigsTranslationsLanguageGet(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigsAPI - factory interface
 * @export
 */
export const ConfigsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Get Advertisement Integrations
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdvertisementIntegrationsConfigsPromotionsIntegrationsGet(countryUuid: string, options?: any): AxiosPromise<AdvertisementIntegrationListSerializerDTO> {
            return localVarFp.getAdvertisementIntegrationsConfigsPromotionsIntegrationsGet(countryUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Kyc Integrations
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycIntegrationsConfigsKycIntegrationsGet(countryUuid: string, options?: any): AxiosPromise<KYCIntegrationListSerializerDTO> {
            return localVarFp.getKycIntegrationsConfigsKycIntegrationsGet(countryUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Translations
         * @param {LanguageDTO} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationsConfigsTranslationsLanguageGet(language: LanguageDTO, options?: any): AxiosPromise<object> {
            return localVarFp.getTranslationsConfigsTranslationsLanguageGet(language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAdvertisementIntegrationsConfigsPromotionsIntegrationsGet operation in ConfigsAPI.
 * @export
 * @interface ConfigsAPIGetAdvertisementIntegrationsConfigsPromotionsIntegrationsGetRequest
 */
export interface ConfigsAPIGetAdvertisementIntegrationsConfigsPromotionsIntegrationsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigsAPIGetAdvertisementIntegrationsConfigsPromotionsIntegrationsGet
     */
    readonly countryUuid: string
}

/**
 * Request parameters for getKycIntegrationsConfigsKycIntegrationsGet operation in ConfigsAPI.
 * @export
 * @interface ConfigsAPIGetKycIntegrationsConfigsKycIntegrationsGetRequest
 */
export interface ConfigsAPIGetKycIntegrationsConfigsKycIntegrationsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigsAPIGetKycIntegrationsConfigsKycIntegrationsGet
     */
    readonly countryUuid: string
}

/**
 * Request parameters for getTranslationsConfigsTranslationsLanguageGet operation in ConfigsAPI.
 * @export
 * @interface ConfigsAPIGetTranslationsConfigsTranslationsLanguageGetRequest
 */
export interface ConfigsAPIGetTranslationsConfigsTranslationsLanguageGetRequest {
    /**
     * 
     * @type {LanguageDTO}
     * @memberof ConfigsAPIGetTranslationsConfigsTranslationsLanguageGet
     */
    readonly language: LanguageDTO
}

/**
 * ConfigsAPI - object-oriented interface
 * @export
 * @class ConfigsAPI
 * @extends {BaseAPI}
 */
export class ConfigsAPI extends BaseAPI {
    /**
     * 
     * @summary Get Advertisement Integrations
     * @param {ConfigsAPIGetAdvertisementIntegrationsConfigsPromotionsIntegrationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigsAPI
     */
    public getAdvertisementIntegrationsConfigsPromotionsIntegrationsGet(requestParameters: ConfigsAPIGetAdvertisementIntegrationsConfigsPromotionsIntegrationsGetRequest, options?: any) {
        return ConfigsAPIFp(this.configuration).getAdvertisementIntegrationsConfigsPromotionsIntegrationsGet(requestParameters.countryUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Kyc Integrations
     * @param {ConfigsAPIGetKycIntegrationsConfigsKycIntegrationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigsAPI
     */
    public getKycIntegrationsConfigsKycIntegrationsGet(requestParameters: ConfigsAPIGetKycIntegrationsConfigsKycIntegrationsGetRequest, options?: any) {
        return ConfigsAPIFp(this.configuration).getKycIntegrationsConfigsKycIntegrationsGet(requestParameters.countryUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Translations
     * @param {ConfigsAPIGetTranslationsConfigsTranslationsLanguageGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigsAPI
     */
    public getTranslationsConfigsTranslationsLanguageGet(requestParameters: ConfigsAPIGetTranslationsConfigsTranslationsLanguageGetRequest, options?: any) {
        return ConfigsAPIFp(this.configuration).getTranslationsConfigsTranslationsLanguageGet(requestParameters.language, options).then((request) => request(this.axios, this.basePath));
    }
}
