import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React, { useCallback } from 'react';

import { userClient } from '../../../../../+user';
import type { UserSerializerDTO } from '../../../../../../connectors/user';
import { AsyncContentProvider, useTranslation } from '../../../../../shared';
import { Reporter as ReporterEnum } from '../../../enums';
import type { ServiceRequestValues } from '../../../types';
import type { StepComponentProps } from '../service-request-form.type';
import { Assignee } from './Assignee/assignee.component';
import { Attachments } from './Attachments/attachments.component';
import { Reporter } from './Reporter/reporter.component';
import { Sidebar } from './Sidebar/sidebar.component';
import { sxProps } from './summary-step.styles';

export const SummaryStep: FC<StepComponentProps> = (props) => {
  const { details } = props;
  const { isSubmitting, isValid, values, submitForm } =
    useFormikContext<ServiceRequestValues>();
  const { t } = useTranslation();
  const getUsersData$ = useCallback(() => {
    const reporterRequest$ = (): Promise<UserSerializerDTO | undefined> => {
      if (details?.reporter)
        return userClient.getDetails$(details.reporter.uuid);
      if (values.tenant && values.reporter === ReporterEnum.TENANT)
        return userClient.getDetails$(values.tenant.value);

      return Promise.resolve(undefined);
    };
    const requests$ = [
      values.assignee
        ? userClient.getDetails$(values.assignee.value)
        : Promise.resolve(undefined),
      reporterRequest$(),
    ];

    return Promise.all(requests$).then(([assignee, reporter]) => ({
      assignee,
      reporter,
    }));
  }, [details?.reporter, values.assignee, values.reporter, values.tenant]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={8}>
        <Typography variant="h2">{values.summary}</Typography>
        <Typography variant="body1">{values.description}</Typography>
        <Divider sx={sxProps.divider} />
        <Attachments />
        <AsyncContentProvider getData$={getUsersData$}>
          {() => (
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <Reporter />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box sx={sxProps.border}>
                  <Assignee />
                </Box>
              </Grid>
            </Grid>
          )}
        </AsyncContentProvider>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box sx={sxProps.border}>
          <Sidebar />
        </Box>
      </Grid>
      <Grid container item justifyContent="flex-end" marginTop={2} xs={12}>
        <Button
          color="primary"
          data-testid="form-navigation-button-create-request"
          disabled={!isValid || isSubmitting}
          onClick={submitForm}
          size="large"
          variant="contained"
        >
          {t('ticket.fieldset.summary.button')}
        </Button>
      </Grid>
    </Grid>
  );
};
