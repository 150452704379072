import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  list: {
    p: 0,
  },
  listItem: {
    '&:last-of-type': {
      mb: 0,
    },
    backgroundColor: (theme) => theme.palette.background.default,
    border: '2px solid transparent',
    borderRadius: (theme) => theme.spacing(2),
    cursor: 'pointer',
    mb: 2,
    p: 3,
  },
  listItemSelected: {
    borderColor: (theme) => theme.palette.secondary.light,
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
  },
  name: {
    wordBreak: 'break-word',
  },
};
