import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import type { FieldProps as FormikFieldProps } from 'formik';
import isFunction from 'lodash-es/isFunction';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { useTranslation } from '../../translations';
import { getFirstErrorKey } from '../helpers';
import type { TimeProps, TimeValue } from './types';

type Props<TFormValues> = FormikFieldProps<TimeValue, TFormValues> & {
  props: TimeProps<TFormValues>;
};

export const TimeField = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const { meta, field, form, props: fieldProps } = props;
  const { setFieldValue, values } = form;
  const { t } = useTranslation();
  const { error, value, touched } = meta;
  const { name, onBlur } = field;
  const {
    disabled,
    fieldSx,
    formControlSx,
    labelKey,
    maxTime,
    minTime,
    placeholderKey,
    readonly,
    required,
    requiredWhen,
  } = fieldProps;
  const finalLabelKey = useMemo(
    () => (isFunction(labelKey) ? labelKey(values) : labelKey),
    [labelKey, values],
  );
  const errorMessageKey = useMemo(
    () => touched && getFirstErrorKey(error),
    [error, touched],
  );
  const isError = useMemo(() => Boolean(errorMessageKey), [errorMessageKey]);
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );
  const handleChange = useCallback(
    (time: TimeValue | null): void => setFieldValue(name, time),
    [name, setFieldValue],
  );

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={isError}
      fullWidth
      required={isRequired && !readonly}
      sx={formControlSx}
    >
      <TimePicker<TimeValue>
        ampm={false}
        disabled={disabled || readonly}
        maxTime={maxTime}
        minTime={minTime}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            error={isError}
            helperText={isError && t(errorMessageKey || '')}
            id={name}
            inputProps={{ ...params.inputProps, 'data-testid': name }}
            onBlur={onBlur}
            sx={fieldSx}
            variant="outlined"
            {...(placeholderKey && { placeholder: t(placeholderKey) })}
            {...(finalLabelKey && {
              InputLabelProps: { required: isRequired, shrink: true },
              label: t(finalLabelKey),
            })}
          />
        )}
        value={value || null}
      />
    </FormControl>
  );
};
