import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    p: 2,
  },
  container: {
    '& > svg': {
      mr: 1,
    },
    '& path': {
      stroke: 'currentColor',
    },
    color: 'text.primary',
    display: 'flex',
    justifyContent: 'space-between',
  },
  header: {
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    p: 2,
  },
};
