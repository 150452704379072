import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  answer: {
    mb: 1.5,
  },
  container: {
    p: 2,
  },
  item: {
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    mt: 1,
  },
  question: {
    fontWeight: 'bold',
  },
};
