import { PropertyObjectCategoryEnumDTO } from '../../../../connectors/property';
import type { ColorsHex, ListTab, SelectOption } from '../../../shared';
import { palette } from '../../../shared/theme/palette';

const categoryToColorsHexMap: Record<PropertyObjectCategoryEnumDTO, ColorsHex> =
  {
    [PropertyObjectCategoryEnumDTO.Building]: {
      backgroundColor: palette.fern,
      color: palette.black,
    },
    [PropertyObjectCategoryEnumDTO.GroundArea]: {
      backgroundColor: palette.pictonBlue,
      color: palette.black,
    },
    [PropertyObjectCategoryEnumDTO.Other]: {
      backgroundColor: palette.regentGray,
      color: palette.black,
    },
  };

export const getCategoryColorsHex = (
  category: PropertyObjectCategoryEnumDTO,
): ColorsHex =>
  categoryToColorsHexMap[category] || {
    backgroundColor: palette.regentGray,
    color: palette.black,
  };

const categoryToTranslationLabelKeyMap: Record<
  PropertyObjectCategoryEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [PropertyObjectCategoryEnumDTO.Building]:
    'propertyObject.fields.category.building',
  [PropertyObjectCategoryEnumDTO.GroundArea]:
    'propertyObject.fields.category.groundArea',
  [PropertyObjectCategoryEnumDTO.Other]: 'propertyObject.fields.category.other',
};

export const getCategoryTranslationLabelKey = (
  category: PropertyObjectCategoryEnumDTO,
): GenericTypes.TranslationLabel =>
  categoryToTranslationLabelKeyMap[category] || category;

export const getCategoryListTabs = (): ListTab[] =>
  Object.values(PropertyObjectCategoryEnumDTO).map((value) => {
    return {
      labelKey: getCategoryTranslationLabelKey(value),
      value,
    };
  });

export const categoryOptions: SelectOption[] = Object.values(
  PropertyObjectCategoryEnumDTO,
).map((category) => ({
  labelKey: getCategoryTranslationLabelKey(category),
  value: category,
}));
