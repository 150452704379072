import { ReactComponent as ArchitectureDoorIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as ConstructionBrickIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/construction/construction-brick.svg';
import { ReactComponent as LayersIcon } from '@heimstaden/icons-library/img/streamline-regular/design/layers/layers.svg';
import { ReactComponent as RealEstateDimensionsBlockIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import { ReactComponent as ShopIcon } from '@heimstaden/icons-library/img/streamline-regular/shopping-ecommerce/shops/shop.svg';
import Grid from '@mui/material/Grid';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { PropertyObjectSerializerDTO } from '../../../../../connectors/property';
import {
  CollectionViewMode,
  Feature,
  getDisplayValue,
  Scrollbar,
  useTranslation,
} from '../../../../shared';

type Props = {
  data: PropertyObjectSerializerDTO;
  viewMode?: CollectionViewMode;
};

const GRID_SPACING_DEFAULT = 3;
const GRID_SPACING_LIST = [2, 4, 4, 6];

export const FeaturesList: FC<Props> = (props) => {
  const { data, viewMode } = props;
  const { t } = useTranslation();
  const gridSpacing = useMemo(
    () =>
      viewMode === CollectionViewMode.LIST
        ? GRID_SPACING_LIST
        : GRID_SPACING_DEFAULT,
    [viewMode],
  );
  const features = useMemo(
    () => [
      {
        dataTestId: 'lettable-area',
        icon: <RealEstateDimensionsBlockIcon />,
        id: 'lettableArea',
        titleKey: 'propertyObject.fields.lettableArea',
        value: t('metricUnit.squareMetre', {
          value: getDisplayValue(data.area).value,
        }),
      },
      {
        dataTestId: 'floor-count',
        icon: <LayersIcon />,
        id: 'floors',
        titleKey: 'propertyObject.fields.floors',
        value: getDisplayValue(data.floors).value,
      },
      {
        dataTestId: 'home-count',
        icon: <ArchitectureDoorIcon />,
        id: 'residentialHomeCount',
        titleKey: 'propertyObject.fields.residentialHomeCount',
        value: getDisplayValue(data.residentialHomeCount).value,
      },
      {
        dataTestId: 'commercial-space-count',
        icon: <ShopIcon />,
        id: 'commercialSpacesCount',
        titleKey: 'propertyObject.fields.commercialSpacesCount',
        value: getDisplayValue(data.commercialSpaceCount).value,
      },
      {
        dataTestId: 'year-built',
        icon: <ConstructionBrickIcon />,
        id: 'yearBuilt',
        titleKey: 'propertyObject.fields.yearBuilt',
        value: data.yearBuilt,
      },
    ],
    [
      data.area,
      data.commercialSpaceCount,
      data.floors,
      data.residentialHomeCount,
      data.yearBuilt,
      t,
    ],
  );

  if (viewMode === CollectionViewMode.MAP) {
    return null;
  }

  return (
    <Scrollbar>
      <Grid container spacing={gridSpacing} wrap="nowrap">
        {features.map(({ id, ...feature }) => (
          <Grid key={`feature-${viewMode}-${id}`} item>
            <Feature
              {...feature}
              size={viewMode === CollectionViewMode.GRID ? 'small' : 'normal'}
            />
          </Grid>
        ))}
      </Grid>
    </Scrollbar>
  );
};
