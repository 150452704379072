import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  avatar: {
    height: 48,
    width: 48,
  },
  disabled: {
    opacity: 0.26,
  },
  icon: {
    '& path': {
      strokeWidth: 0.2,
    },
    color: (theme) => theme.palette.grey[800],
    height: 128,
    width: 128,
  },
};
