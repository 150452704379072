import MUIAvatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { generatePath } from 'react-router';

import { useAuth } from '../../+auth';
import { USER_PATHS } from '../../+user';
import { AvatarRoot, sxProps } from './avatar.styles';

type Props = {
  isSidebarOpened: boolean;
};

export const Avatar: FC<Props> = (props) => {
  const { isSidebarOpened } = props;
  const { user } = useAuth();
  // TODO: Display the highest user role and more descriptive
  const role = useMemo(
    () => (user?.roles.length ? user.roles[0] : ''),
    [user?.roles],
  );
  const to = useMemo(
    () =>
      user?.uuid ? generatePath(USER_PATHS.DETAILS, { id: user.uuid }) : '',
    [user?.uuid],
  );

  return (
    <AvatarRoot to={to}>
      <Grid
        alignItems="center"
        container
        spacing={1}
        wrap="nowrap"
        {...(!isSidebarOpened && { justifyContent: 'center' })}
      >
        <Grid item>
          <MUIAvatar src={user?.profilePicture} />
        </Grid>
        {isSidebarOpened && (
          <Grid item sx={sxProps.contentColumn}>
            <Typography color="primary.main" variant="subtitle2" noWrap>
              {user?.name}
            </Typography>
            {role && (
              <Typography color="text.secondary" variant="body2">
                {role}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </AvatarRoot>
  );
};
