import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import type { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FieldProps as FormikFieldProps } from 'formik/dist/Field';
import isFunction from 'lodash-es/isFunction';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { useTranslation } from '../../../translations';
import { FieldLabel } from '../../components';
import { getFirstErrorKey } from '../../helpers';
import type { HtmlDataAttribute, RadioProps } from '../types';
import { sxProps } from './radio-field.styles';

type Props<TFormValues, TRadioValue> = FormikFieldProps<
  TRadioValue,
  TFormValues
> & {
  props: RadioProps<TFormValues, TRadioValue>;
};

export const RadioField = <TFormValues, TRadioValue = string>(
  props: Props<TFormValues, TRadioValue>,
): ReturnType<FC<Props<TFormValues, TRadioValue>>> => {
  const { meta, field, form, props: fieldProps } = props;
  const { touched, error, value } = meta;
  const { name, onBlur } = field;
  const { setFieldValue, values } = form;
  const {
    disabled,
    disableWhen,
    fieldSx,
    formControlSx,
    labelKey,
    options,
    readonly,
    required,
    requiredWhen,
  } = fieldProps;
  const { t } = useTranslation();
  const staticOptions = useMemo(
    () => (isFunction(options) ? options(values) : options),
    [options, values],
  );
  const finalLabelKey = useMemo(
    () => (isFunction(labelKey) ? labelKey(values) : labelKey),
    [labelKey, values],
  );
  const errorMessageKey = useMemo(
    () => touched && getFirstErrorKey(error),
    [error, touched],
  );
  const isDisabled = useMemo(
    () => Boolean(disabled || readonly || (disableWhen && disableWhen(values))),
    [disableWhen, disabled, readonly, values],
  );
  const isError = useMemo(() => Boolean(errorMessageKey), [errorMessageKey]);
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );
  const handleChange: MuiRadioProps['onChange'] = useCallback(
    (event) => setFieldValue(name, event.target.value),
    [name, setFieldValue],
  );

  return (
    <FormControl
      component="fieldset"
      disabled={isDisabled}
      error={isError}
      fullWidth
      required={isRequired && !readonly}
      sx={formControlSx}
    >
      <FieldLabel isShrink={false} labelKey={finalLabelKey} />
      <RadioGroup>
        <Grid container>
          {staticOptions.map((option) => {
            const { labelKey, value: optionValue } = option;

            return (
              <Grid item key={`radio-option-${optionValue}`} xs={12}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={optionValue === value}
                      name={`radio-${name}`}
                      onBlur={onBlur}
                      onChange={handleChange}
                      sx={{ ...sxProps.radio, ...fieldSx } as SxProps}
                      inputProps={
                        {
                          'data-testid': optionValue as unknown as string,
                        } as HtmlDataAttribute<MuiRadioProps['inputProps']>
                      }
                      value={optionValue}
                    />
                  }
                  label={t(labelKey) as string}
                  sx={sxProps.formControlLabel}
                />
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
      {isError && (
        <FormHelperText error>{t(errorMessageKey || '')}</FormHelperText>
      )}
    </FormControl>
  );
};
