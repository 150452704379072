import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import React, { isValidElement, useMemo } from 'react';
import type { FC, ReactElement, ReactNode } from 'react';

import { useTranslation } from '../../translations';
import { sxProps } from './feature.styles';

export type Props = {
  dataTestId: string;
  titleKey: GenericTypes.TranslationLabel;
  icon?: ReactElement;
  size?: 'large' | 'normal' | 'small';
  textWrap?: boolean;
  value?: ReactNode;
  valueKey?: GenericTypes.TranslationLabel;
};

export const Feature: FC<Props> = (props) => {
  const {
    dataTestId,
    icon,
    titleKey,
    size = 'normal',
    textWrap = false,
    value,
    valueKey,
  } = props;
  const { t } = useTranslation();
  const iconSxProps = useMemo(
    () => ({ ...sxProps.icon, ...sxProps[size] }),
    [size],
  );
  const textSxProps = useMemo(
    () => ({ whiteSpace: textWrap ? 'initial' : 'nowrap' }),
    [textWrap],
  );

  return (
    <Grid container direction="column">
      {size !== 'small' && (
        <Grid item xs={12}>
          <Typography sx={textSxProps} variant="overline">
            {t(titleKey)}
          </Typography>
        </Grid>
      )}
      <Grid data-testid={dataTestId} item xs={12}>
        <Grid alignItems="center" container spacing={1} wrap="nowrap">
          {icon && (
            <Grid item>
              <Icon sx={iconSxProps}>{icon}</Icon>
            </Grid>
          )}
          <Grid item>
            <Typography
              color="secondary"
              component={isValidElement(value) ? 'div' : 'p'}
              sx={textSxProps}
              variant={size === 'small' ? 'body2' : 'body1'}
            >
              {value && value}
              {valueKey && t(valueKey)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
