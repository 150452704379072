import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { Fragment, useMemo } from 'react';
import type { FC } from 'react';

import { useTranslation } from '../../../translations';
import type { FieldsetConfig } from '../../types';
import { FormField } from '../form-field.component';
import { sxProps } from './form-fieldset.styles';

type Props<TFormValues> = {
  config: FieldsetConfig<TFormValues>;
  values: TFormValues;
  isInline?: boolean;
};

export const FormFieldset = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<FieldsetConfig<TFormValues> | null>> => {
  const { config, isInline, values } = props;
  const {
    fields,
    helperTextKey,
    icon: fieldsetIcon,
    isHidden,
    size,
    titleKey,
  } = config;
  const { t } = useTranslation();
  const Icon = useMemo(() => fieldsetIcon || Fragment, [fieldsetIcon]);

  return (
    <Grid
      item
      sx={isHidden ? { display: 'none' } : undefined}
      {...(size || { xs: 12 })}
    >
      <FormControl component="fieldset" fullWidth>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid
              alignItems="flex-start"
              container
              spacing={2}
              sx={sxProps.header}
              wrap="nowrap"
            >
              {fieldsetIcon && (
                <Grid item sx={sxProps.iconBox}>
                  <Icon height={48} width={48} />
                </Grid>
              )}
              <Grid item>
                <Typography variant="h5">{t(titleKey)}</Typography>
                {helperTextKey && (
                  <Typography color="text.secondary" mt={0.5} variant="body1">
                    {t(helperTextKey)}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {fields.length > 0 && (
              <Grid container spacing={3}>
                {fields.map((fieldConfig) => (
                  <FormField<TFormValues>
                    config={fieldConfig}
                    isInline={isInline}
                    key={`from-input-${fieldConfig.field.name}`}
                    values={values}
                  />
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
};
