import React, { useCallback } from 'react';
import type { FC } from 'react';

import type {
  UnitCategoryEnumDTO,
  CoordinatesSerializerDTO,
  UnitSerializerDTO,
} from '../../../../../connectors/property';
import { ThumbnailInfoWindow } from '../../../../shared';
import { unitClient } from '../../../unit.client';
import type { AdditionalUnitCategory } from '../../consts';
import { THUMBNAIL_PLACEHOLDER_PATH } from '../../consts';
import { getAddress } from '../../helpers';
import { FeaturesList } from '../FeaturesList/features-list.component';

type Props = {
  id: string;
  navigateToDetails: (
    category: UnitCategoryEnumDTO | AdditionalUnitCategory,
    id: CoordinatesSerializerDTO['uuid'],
  ) => void;
};

export const InfoWindow: FC<Props> = (props) => {
  const { id, navigateToDetails } = props;
  const getData$ = useCallback(() => unitClient.getDetails$(id), [id]);

  return (
    <ThumbnailInfoWindow<UnitSerializerDTO>
      descriptionContent={({ addressLine, city, streetNumber }) =>
        getAddress(addressLine, city, streetNumber)
      }
      getData$={getData$}
      onItemClick={({ category, uuid }) => navigateToDetails(category, uuid)}
      thumbnailUrl={({ thumbnail }) => thumbnail || THUMBNAIL_PLACEHOLDER_PATH}
      titleContent={({ name }) => name}
    >
      {(item) => <FeaturesList data={item} />}
    </ThumbnailInfoWindow>
  );
};
