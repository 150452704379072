import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../theme';

export const sxProps: Record<string, SxProps<Theme>> = {
  arrow: {
    ml: 1,
    mt: 0.5,
  },
  container: {
    display: 'flex',
  },
  icon: {
    color: palette.boulder,
    height: 22,
    mr: 1,
    width: 22,
  },
  item: {
    display: 'inline-flex',
  },
  text: {
    '&:last-child': {
      color: palette.black,
    },
    color: palette.waikawaGray,
    fontSize: (theme) => theme.typography.pxToRem(16),
    fontWeight: (theme) => theme.typography.fontWeightBold,
  },
};
