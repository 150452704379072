import { generatePath } from 'react-router';

import type { Account } from '../+account';
import {
  ACTIVITY_LOG_PATHS,
  ACTIVITY_LOG_VIEW_PERMISSIONS,
} from '../+activity-log';
import {
  ADVERTISEMENT_PATHS,
  ADVERTISEMENT_VIEW_PERMISSIONS,
} from '../+advertisement';
import { isPermitted } from '../+auth';
import { COMPANY_PATHS, COMPANY_VIEW_PERMISSIONS } from '../+company';
import { LIBRARY_PATHS, LIBRARY_VIEW_PERMISSIONS } from '../+library';
import { PROPERTY_PATHS, PROPERTY_VIEW_PERMISSIONS } from '../+property';
import {
  PROPERTY_OBJECT_PATHS,
  PROPERTY_OBJECT_VIEW_PERMISSIONS,
} from '../+property-object';
import { TERMINATION_PATHS } from '../+termination';
import { TICKET_PATHS, TicketTypePath } from '../+ticket';
import { UNIT_PATHS, UNIT_VIEW_PERMISSIONS, UnitCategoryPath } from '../+unit';
import { USER_PATHS, USER_VIEW_PERMISSIONS } from '../+user';
import type { NavItemProps } from '../shared';

export const getActivityLog = (user: Account | null): NavItemProps[] => [
  ...(isPermitted(ACTIVITY_LOG_VIEW_PERMISSIONS, user?.permissions || [])
    ? [
        {
          dataTestId: 'activity-log',
          path: ACTIVITY_LOG_PATHS.LIST,
          titleKey: 'activityLog.section',
        },
      ]
    : []),
];
export const getAdvertisement = (user: Account | null): NavItemProps[] => [
  ...(isPermitted(ADVERTISEMENT_VIEW_PERMISSIONS, user?.permissions || [])
    ? [
        {
          dataTestId: 'advertisement',
          path: ADVERTISEMENT_PATHS.LIST,
          titleKey: 'advertisement.section',
        },
      ]
    : []),
];
export const getCompany = (user: Account | null): NavItemProps[] => [
  ...(isPermitted(COMPANY_VIEW_PERMISSIONS, user?.permissions || [])
    ? [
        {
          dataTestId: 'company',
          path: COMPANY_PATHS.LIST,
          titleKey: 'company.section',
        },
      ]
    : []),
];
export const getCustomerCare = (): NavItemProps[] => [
  {
    dataTestId: 'complaints',
    path: generatePath(TICKET_PATHS.LIST, {
      type: TicketTypePath.COMPLAINTS,
    }),
    titleKey: 'ticket.section.complaint',
  },
  {
    dataTestId: 'contract-requests',
    path: generatePath(TICKET_PATHS.LIST, {
      type: TicketTypePath.CONTRACT_REQUESTS,
    }),
    titleKey: 'ticket.section.contractRequest',
  },
  {
    dataTestId: 'general-requests',
    path: generatePath(TICKET_PATHS.LIST, {
      type: TicketTypePath.GENERAL_REQUESTS,
    }),
    titleKey: 'ticket.section.generalRequest',
  },
  {
    dataTestId: 'invoice-requests',
    path: generatePath(TICKET_PATHS.LIST, {
      type: TicketTypePath.INVOICE_REQUESTS,
    }),
    titleKey: 'ticket.section.invoiceRequest',
  },
  {
    dataTestId: 'service-requests',
    path: generatePath(TICKET_PATHS.LIST, {
      type: TicketTypePath.SERVICE_REQUESTS,
    }),
    titleKey: 'ticket.section.serviceRequest',
  },
];
export const getFacilities = (user: Account | null): NavItemProps[] => [
  ...(isPermitted(UNIT_VIEW_PERMISSIONS, user?.permissions || [])
    ? [
        {
          dataTestId: 'facilities',
          path: generatePath(UNIT_PATHS.LIST, {
            category: UnitCategoryPath.FACILITIES,
          }),
          titleKey: 'unit.section.facility',
        },
      ]
    : []),
];
export const getFilesLibrary = (user: Account | null): NavItemProps[] => [
  ...(isPermitted(LIBRARY_VIEW_PERMISSIONS, user?.permissions || [])
    ? [
        {
          dataTestId: 'files-library',
          path: LIBRARY_PATHS.LIST,
          titleKey: 'library.section',
        },
      ]
    : []),
];
export const getMyAccount = (user: Account | null): NavItemProps[] => [
  {
    dataTestId: 'account',
    path: generatePath(USER_PATHS.DETAILS, { id: user?.uuid }),
    titleKey: 'account.section',
  },
];
export const getProperties = (user: Account | null): NavItemProps[] => [
  ...(isPermitted(PROPERTY_VIEW_PERMISSIONS, user?.permissions || [])
    ? [
        {
          dataTestId: 'properties',
          path: PROPERTY_PATHS.LIST,
          titleKey: 'property.section',
        },
      ]
    : []),
];
export const getPropertyObject = (user: Account | null): NavItemProps[] => [
  ...(isPermitted(PROPERTY_OBJECT_VIEW_PERMISSIONS, user?.permissions || [])
    ? [
        {
          dataTestId: 'property-objects',
          path: PROPERTY_OBJECT_PATHS.LIST,
          titleKey: 'propertyObject.section.building',
        },
      ]
    : []),
];
export const getUnits = (user: Account | null): NavItemProps[] => [
  ...(isPermitted(UNIT_VIEW_PERMISSIONS, user?.permissions || [])
    ? [
        {
          dataTestId: 'units',
          path: generatePath(UNIT_PATHS.LIST, {
            category: UnitCategoryPath.HOMES,
          }),
          titleKey: 'unit.section.home',
        },
      ]
    : []),
];
export const getUserList = (user: Account | null): NavItemProps[] => [
  ...(isPermitted(USER_VIEW_PERMISSIONS, user?.permissions || [])
    ? [
        {
          dataTestId: 'user-list',
          path: USER_PATHS.LIST,
          titleKey: 'user.section.users',
        },
      ]
    : []),
];
export const getSettings = (): NavItemProps[] => [
  {
    dataTestId: 'settings',
    path: generatePath(USER_PATHS.SETTINGS),
    titleKey: 'settings.drawer.title',
  },
];
export const getTermination = (): NavItemProps[] => [
  {
    dataTestId: 'termination',
    path: TERMINATION_PATHS.LIST,
    titleKey: 'termination.section',
  },
];
