import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProspectStatusCell } from '../../../../+prospect';
import type { CommentWithProspectDataSerializerDTO } from '../../../../../connectors/property';
import type { CommentWithTicketDataSerializerDTO } from '../../../../../connectors/ticket';
import { getCommentDisplayConfig, getUserName } from '../../../helpers';
import { useTranslation } from '../../../translations';
import { FormattedDate } from '../../FormattedDate/formatted-date.component';
import {
  getCommentLink,
  getReferenceNumber,
  getStatus,
} from './inbox-comment.helper';
import { sxProps } from './inbox-comment.styles';

type Props = {
  comment:
    | CommentWithProspectDataSerializerDTO
    | CommentWithTicketDataSerializerDTO;
};

export const InboxComment: FC<Props> = (props) => {
  const { comment } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const avatar = useMemo(
    () => comment.author.profilePicture,
    [comment.author.profilePicture],
  );
  const author = useMemo(() => getUserName(comment.author), [comment.author]);
  const displayConfig = useMemo(
    () => getCommentDisplayConfig(comment.content, comment.taggedUsers),
    [comment.content, comment.taggedUsers],
  );
  const link = useMemo(() => getCommentLink(comment), [comment]);
  const handleClick = useCallback(() => {
    if (!link) return;

    navigate(link);
  }, [link, navigate]);
  const referenceNumber = useMemo(() => getReferenceNumber(comment), [comment]);
  const status = useMemo(() => getStatus(comment), [comment]);

  if (!link) return null;

  return (
    <Grid
      container
      onClick={handleClick}
      spacing={1}
      sx={sxProps.container}
      wrap="nowrap"
    >
      <Grid item>
        <Avatar src={avatar} sx={sxProps.avatar} />
      </Grid>
      <Grid item sx={sxProps.contentColumn}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="subtitle2">
                  {referenceNumber
                    ? t('dashboard.widgets.inbox.messageTitle', {
                        author,
                        referenceNumber,
                      })
                    : author}
                </Typography>
              </Grid>
              {status && (
                <Grid item>
                  <ProspectStatusCell status={status} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography color="text.secondary" variant="body2" noWrap>
              {displayConfig.map((phrase) => (
                <Fragment key={`comment-${comment.uuid}-${phrase.id}`}>
                  {phrase.content}
                </Fragment>
              ))}
            </Typography>
            <Typography sx={sxProps.createdDate} variant="caption">
              <FormattedDate date={comment.createdAt} format="distance" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
