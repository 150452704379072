import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

const THUMBNAIL_SIZE = 138;

export const sxProps: Record<string, SxProps<Theme>> = {
  activeImage: {
    borderColor: 'primary.main',
  },
  image: {
    backgroundPosition: '0 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    border: '4px solid transparent',
    cursor: 'pointer',
    height: THUMBNAIL_SIZE,
    width: THUMBNAIL_SIZE,
  },
  thumbnails: {
    marginTop: 3,
  },
};
