import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { CountrySerializerDTO } from '../../../../../connectors/company';
import type {
  AdvertisementIntegrationPlatformValidationRulesSerializerDTO,
  AdvertisementIntegrationSerializerDTO,
} from '../../../../../connectors/property';
import { Country } from '../../../../shared/providers/Country/enum';
import type { DynamicValidationRules } from '../../components';
import type { Config } from './type';

export type ContextValue = {
  config: Record<Country | CountrySerializerDTO['uuid'], Config>;
  dynamicValidationRules: DynamicValidationRules;
  getCountryConfig: (
    country?: Country | CountrySerializerDTO['uuid'],
  ) => Config;
  getIntegrationsConfig: (
    country?: Country | CountrySerializerDTO['uuid'],
  ) => AdvertisementIntegrationSerializerDTO[];
  fetchValidationRules$: (
    country?: Country | CountrySerializerDTO['uuid'],
  ) => Promise<void>;
  setDynamicValidationRules: Dispatch<SetStateAction<DynamicValidationRules>>;
  // eslint-disable-next-line max-len
  validationRules: AdvertisementIntegrationPlatformValidationRulesSerializerDTO[];
};

export const initialCountryConfig: Config = {
  integrations: [],
};

export const initialConfig: ContextValue['config'] = {
  [Country.NL]: initialCountryConfig,
  [Country.NO]: initialCountryConfig,
  [Country.PL]: initialCountryConfig,
};
export const Context = createContext<ContextValue>({
  config: initialConfig,
  dynamicValidationRules: {},
  fetchValidationRules$: () => Promise.resolve(),
  getCountryConfig: () => initialCountryConfig,
  getIntegrationsConfig: () => [],
  setDynamicValidationRules: () => null,
  validationRules: [],
});
