import uniqBy from 'lodash-es/uniqBy';

import { userClient } from '../+user';
import type {
  AttachmentSequenceSerializerDTO,
  CitySerializerDTO,
  CountrySerializerDTO,
  LanguageDTO,
} from '../../connectors/company';
import { EntityEnumDTO, GeneralAPI } from '../../connectors/company';
import type {
  PropertyObjectSerializerDTO,
  UnitSerializerDTO,
} from '../../connectors/property';
import {
  AttachmentVisibilityEnumDTO,
  CommentVisibilityEnumDTO,
  ComplaintsAPI,
  ContractRequestsAPI,
  GeneralRequestsAPI,
  InvoiceRequestsAPI,
  ServiceRequestsAPI,
  TicketsAPI,
  TicketStatusEnumDTO,
} from '../../connectors/ticket';
import type {
  AttachmentCategoryEnumDTO,
  AttachmentSerializerDTO,
  AttachmentTypeEnumDTO,
  AwaitingExternalProviderSerializerDTO,
  AwaitingTenantSerializerDTO,
  CommentSerializerDTO,
  ComplaintDetailSerializerDTO,
  ComplaintSerializerDTO,
  ContractRequestDetailSerializerDTO,
  ContractRequestSerializerDTO,
  CreateComplaintSerializerDTO,
  CreateContractRequestSerializerDTO,
  CreateGeneralRequestSerializerDTO,
  CreateInvoiceRequestSerializerDTO,
  CreateReponseTemplateSerializerDTO,
  CreateServiceRequestSerializerDTO,
  DoneSerializerDTO,
  GeneralRequestDetailSerializerDTO,
  GeneralRequestSerializerDTO,
  InvoiceRequestDetailSerializerDTO,
  InvoiceRequestSerializerDTO,
  ListAttachmentSerializerDTO,
  ListCommentSerializerDTO,
  ListCommentWithTicketSerializerDTO,
  ListLogSerializerDTO,
  ListResponseTemplateSerializerDTO,
  ListTicketsWithAdditionalFieldsSerializerDTO,
  OnHoldSerializerDTO,
  PatchComplaintSerializerDTO,
  PatchContractRequestSerializerDTO,
  PatchGeneralRequestSerializerDTO,
  PatchInvoiceRequestSerializerDTO,
  PatchServiceRequestSerializerDTO,
  ResponseTemplateSerializerDTO,
  SerializersTicketSerializerDTO,
  ServiceRequestDetailSerializerDTO,
  ServiceRequestSerializerDTO,
  TicketMetadataSerializerDTO,
  TicketTypeEnumDTO,
  UniversalTicketSerializerDTO,
} from '../../connectors/ticket';
import type {
  EmployeeSerializerDTO,
  UserRoleEnumDTO,
  UserSerializerDTO,
} from '../../connectors/user';
import { UsersAPI } from '../../connectors/user';
import { mieInstance } from '../mie.instance';
import type {
  AsyncAutocompleteOption,
  AttachmentFilterValues,
  Country,
  CustomFile,
  FilterValuesWithLocalization,
  ListQueryParams,
} from '../shared';
import { transformKeys } from '../shared';
import { getEnvValue } from '../shared/helpers/env.helper';
import type {
  CommentFilterValues,
  CommonFilterValues,
  ComplaintFilterValues,
  FilterValues,
  ServiceRequestFilterValues,
} from './shared';
import {
  ActorRole,
  getEntityContactsSuggestions$,
  UNASSIGNED_VALUE,
} from './shared';

class TicketClient {
  private client;

  private complaintClient;

  private contractRequestClient;

  private generalClient;

  private generalRequestClient;

  private invoiceRequestClient;

  private serviceRequestClient;

  private userApiClient;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.client = new TicketsAPI(undefined, baeBath, mieInstance);
    this.complaintClient = new ComplaintsAPI(undefined, baeBath, mieInstance);
    this.contractRequestClient = new ContractRequestsAPI(
      undefined,
      baeBath,
      mieInstance,
    );
    this.generalClient = new GeneralAPI(undefined, baeBath, mieInstance);
    this.generalRequestClient = new GeneralRequestsAPI(
      undefined,
      baeBath,
      mieInstance,
    );
    this.invoiceRequestClient = new InvoiceRequestsAPI(
      undefined,
      baeBath,
      mieInstance,
    );
    this.serviceRequestClient = new ServiceRequestsAPI(
      undefined,
      baeBath,
      mieInstance,
    );
    this.userApiClient = new UsersAPI(undefined, baeBath, mieInstance);
  }

  public getTickets$ = async (
    queryParams?: ListQueryParams<FilterValuesWithLocalization<FilterValues>>,
    entity?: { id: ComplaintSerializerDTO['uuid']; type: EntityEnumDTO },
  ): Promise<ListTicketsWithAdditionalFieldsSerializerDTO> => {
    const { filters, page, pageSize, search } = { ...queryParams };
    const { localization, fields } = { ...filters };
    const { city, country, region } = { ...localization };
    const {
      actorRole,
      assignee,
      department,
      doneAtGte,
      priority,
      sort,
      status,
      ticketType,
    } = { ...(fields as CommonFilterValues) };
    const reporter =
      actorRole === ActorRole.REPORTER && entity?.type === EntityEnumDTO.User
        ? entity.id
        : undefined;
    const specifiedAssignee =
      assignee?.value !== UNASSIGNED_VALUE ? assignee?.value : undefined;
    const assigneeUuid =
      actorRole === ActorRole.ASSIGNEE && entity?.type === EntityEnumDTO.User
        ? entity.id
        : specifiedAssignee;
    const response = await this.client.getTicketsTicketsGet({
      assigneeUuid,
      cityUuid: city || undefined,
      complaintType:
        (fields as ComplaintFilterValues)?.complaintType || undefined,
      countryUuid: country || undefined,
      department: department || undefined,
      doneAtGte,
      entityUuid: entity?.type === EntityEnumDTO.Unit ? entity?.id : undefined,
      issueLocation:
        (fields as ServiceRequestFilterValues)?.issueLocation || undefined,
      issueType: (fields as ServiceRequestFilterValues)?.issueType || undefined,
      order: sort,
      page,
      pageSize,
      priority: priority || undefined,
      propertyObjectUuid:
        entity?.type === EntityEnumDTO.PropertyObject ? entity?.id : undefined,
      propertyUuid:
        entity?.type === EntityEnumDTO.Property ? entity?.id : undefined,
      regionUuid: region || undefined,
      reporterUuid: reporter,
      roomWithIssue:
        (fields as ServiceRequestFilterValues)?.roomWithIssue || undefined,
      search,
      status: status?.join(',') || undefined,
      ticketType: ticketType || undefined,
      unassigned: assignee?.value === UNASSIGNED_VALUE || undefined,
    });

    return response.data;
  };

  public getTicketsCount$ = async (
    ticketType?: TicketTypeEnumDTO,
  ): Promise<Record<TicketTypeEnumDTO | 'total', number>> => {
    const response =
      await this.client.getServiceRequestsForEntityTicketsCountsGet({
        ticketType,
      });

    // TODO: it could be improved after https://fredensborg.atlassian.net/browse/MB-325
    return transformKeys(response.data.ticketsCount, 'SNAKE_CASE');
  };

  public getComplaintDetails$ = async (
    id: ComplaintDetailSerializerDTO['uuid'],
  ): Promise<ComplaintDetailSerializerDTO> => {
    const response =
      await this.complaintClient.getComplaintDetailsComplaintsComplaintUuidGet({
        complaintUuid: id,
      });

    return response.data;
  };

  public getContractRequestDetails$ = async (
    id: ContractRequestSerializerDTO['uuid'],
  ): Promise<ContractRequestDetailSerializerDTO> => {
    const response =
      await this.contractRequestClient.getContractRequestDetailsContractRequestsContractRequestUuidGet(
        { contractRequestUuid: id },
      );

    return response.data;
  };

  public getGeneralRequestDetails$ = async (
    id: GeneralRequestDetailSerializerDTO['uuid'],
  ): Promise<GeneralRequestDetailSerializerDTO> => {
    const response =
      await this.generalRequestClient.getGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet(
        { generalRequestUuid: id },
      );

    return response.data;
  };

  public getInvoiceRequestDetails$ = async (
    id: InvoiceRequestSerializerDTO['uuid'],
  ): Promise<InvoiceRequestDetailSerializerDTO> => {
    const response =
      await this.invoiceRequestClient.getInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGet(
        { invoiceRequestUuid: id },
      );

    return response.data;
  };

  public getServiceRequestDetails$ = async (
    id: ServiceRequestDetailSerializerDTO['uuid'],
  ): Promise<ServiceRequestDetailSerializerDTO> => {
    const response =
      await this.serviceRequestClient.getServiceRequestDetailsServiceRequestsServiceRequestUuidGet(
        { serviceRequestUuid: id },
      );

    return response.data;
  };

  public getLogs$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    queryParams?: ListQueryParams,
  ): Promise<ListLogSerializerDTO> => {
    const { page, pageSize } = { ...queryParams };
    const response =
      await this.client.getTicketAuditlogsTicketsTicketUuidAuditlogsGet({
        page,
        pageSize,
        ticketUuid: id,
      });

    return response.data;
  };

  public updateComplaint$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    values: PatchComplaintSerializerDTO,
  ): Promise<ComplaintSerializerDTO> => {
    const response =
      await this.complaintClient.patchComplaintComplaintsComplaintUuidPatch({
        complaintUuid: id,
        patchComplaintSerializerDTO: values,
      });

    return response.data;
  };

  public updateContractRequest$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    values: PatchContractRequestSerializerDTO,
  ): Promise<ContractRequestSerializerDTO> => {
    const response =
      await this.contractRequestClient.patchContractRequestContractRequestsContractRequestUuidPatch(
        { contractRequestUuid: id, patchContractRequestSerializerDTO: values },
      );

    return response.data;
  };

  public updateGeneralRequest$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    values: PatchGeneralRequestSerializerDTO,
  ): Promise<GeneralRequestSerializerDTO> => {
    const response =
      await this.generalRequestClient.patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch(
        { generalRequestUuid: id, patchGeneralRequestSerializerDTO: values },
      );

    return response.data;
  };

  public updateInvoiceRequest$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    values: PatchInvoiceRequestSerializerDTO,
  ): Promise<InvoiceRequestSerializerDTO> => {
    const response =
      await this.invoiceRequestClient.patchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch(
        { invoiceRequestUuid: id, patchInvoiceRequestSerializerDTO: values },
      );

    return response.data;
  };

  public updateServiceRequest$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    values: PatchServiceRequestSerializerDTO,
  ): Promise<ServiceRequestSerializerDTO> => {
    const response =
      await this.serviceRequestClient.patchServiceRequestServiceRequestsServiceRequestUuidPatch(
        { patchServiceRequestSerializerDTO: values, serviceRequestUuid: id },
      );

    return response.data;
  };

  public createComplaint$ = async (
    values: CreateComplaintSerializerDTO,
  ): Promise<ComplaintSerializerDTO> => {
    const response = await this.complaintClient.createComplaintComplaintsPost({
      createComplaintSerializerDTO: values,
    });

    return response.data;
  };

  public createContractRequest$ = async (
    values: CreateContractRequestSerializerDTO,
  ): Promise<ContractRequestSerializerDTO> => {
    const response =
      await this.contractRequestClient.createContractRequestContractRequestsPost(
        { createContractRequestSerializerDTO: values },
      );

    return response.data;
  };

  public createGeneralRequest$ = async (
    values: CreateGeneralRequestSerializerDTO,
  ): Promise<GeneralRequestSerializerDTO> => {
    const response =
      await this.generalRequestClient.createGeneralRequestGeneralRequestsPost({
        createGeneralRequestSerializerDTO: values,
      });

    return response.data;
  };

  public createInvoiceRequest$ = async (
    values: CreateInvoiceRequestSerializerDTO,
  ): Promise<InvoiceRequestSerializerDTO> => {
    const response =
      await this.invoiceRequestClient.createInvoiceRequestInvoiceRequestsPost({
        createInvoiceRequestSerializerDTO: values,
      });

    return response.data;
  };

  public createServiceRequest$ = async (
    values: CreateServiceRequestSerializerDTO,
  ): Promise<ServiceRequestSerializerDTO> => {
    const response =
      await this.serviceRequestClient.createServiceRequestServiceRequestsPost({
        createServiceRequestSerializerDTO: values,
      });

    return response.data;
  };

  public delete$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
  ): Promise<void> => {
    await this.client.deleteTicketTicketsTicketUuidDelete({ ticketUuid: id });
  };

  public getAttachments$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    category?: AttachmentCategoryEnumDTO,
    queryParams?: ListQueryParams<AttachmentFilterValues>,
  ): Promise<ListAttachmentSerializerDTO> => {
    const {
      filters,
      page,
      pageSize = 100,
      search,
      sort = 'sequence_number',
    } = { ...queryParams };
    const { type } = { ...filters };
    const response =
      await this.client.getTicketAttachmentsTicketsTicketUuidAttachmentsGet({
        category,
        order: sort,
        page,
        pageSize,
        search,
        ticketUuid: id,
        type: type?.join(',') || undefined,
      });

    return response.data;
  };

  public uploadAttachment$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    category: AttachmentCategoryEnumDTO,
    type: AttachmentTypeEnumDTO,
    file: CustomFile,
    visibility: AttachmentVisibilityEnumDTO,
  ): Promise<AttachmentSerializerDTO[]> => {
    const response =
      await this.client.uploadTicketAttachmentTicketsTicketUuidAttachmentsPost({
        attachmentCategory: category,
        attachmentType: type,
        description: file.description,
        files: [file],
        ticketUuid: id,
        title: file.title,
        visibility,
      });

    return response.data;
  };

  public updateAttachment$ = async (
    attachment: AttachmentSerializerDTO,
    id: UniversalTicketSerializerDTO['uuid'],
  ): Promise<AttachmentSerializerDTO> => {
    const response =
      await this.generalClient.patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch(
        {
          attachmentUuid: attachment.uuid,
          entityType: EntityEnumDTO.Ticket,
          entityUuid: id,
          patchAttachmentSerializerDTO: attachment,
        },
      );

    return response.data;
  };

  public updateAttachmentsSequence$ = async (
    sequence: AttachmentSequenceSerializerDTO[],
  ): Promise<AttachmentSequenceSerializerDTO[]> => {
    const response =
      await this.generalClient.updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut(
        { listAttachmentSequenceSerializerDTO: { attachments: sequence } },
      );

    return response.data.results;
  };

  public deleteAttachment$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    attachmentId: AttachmentSerializerDTO['uuid'],
  ): Promise<void> => {
    await this.client.deleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete(
      { attachmentUuid: attachmentId, ticketUuid: id },
    );
  };

  public downloadAttachment$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    attachmentId: AttachmentSerializerDTO['uuid'],
  ): Promise<string> => {
    const response =
      await this.client.downloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet(
        { attachmentUuid: attachmentId, ticketUuid: id },
        { responseType: 'blob' },
      );

    return response.data;
  };

  public changeStatus$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    status: TicketStatusEnumDTO,
    values?:
      | AwaitingExternalProviderSerializerDTO
      | AwaitingTenantSerializerDTO
      | DoneSerializerDTO
      | OnHoldSerializerDTO,
  ): Promise<SerializersTicketSerializerDTO> => {
    switch (status) {
      case TicketStatusEnumDTO.AwaitingExternalProvider: {
        const response =
          await this.client.changeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost(
            {
              awaitingExternalProviderSerializerDTO:
                values as AwaitingExternalProviderSerializerDTO,
              ticketUuid: id,
            },
          );

        return response.data;
      }
      case TicketStatusEnumDTO.AwaitingTenant: {
        const response =
          await this.client.changeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost(
            {
              awaitingTenantSerializerDTO:
                values as AwaitingTenantSerializerDTO,
              ticketUuid: id,
            },
          );

        return response.data;
      }
      case TicketStatusEnumDTO.Done: {
        const response =
          await this.client.changeStatusToDoneTicketsTicketUuidDonePost({
            doneSerializerDTO: values as DoneSerializerDTO,
            ticketUuid: id,
          });

        return response.data;
      }
      case TicketStatusEnumDTO.InProgress: {
        const response =
          await this.client.changeStatusToInProgressTicketsTicketUuidInProgressPost(
            { ticketUuid: id },
          );

        return response.data;
      }
      case TicketStatusEnumDTO.OnHold: {
        const response =
          await this.client.changeStatusToOnHoldTicketsTicketUuidOnHoldPost({
            onHoldSerializerDTO: values as OnHoldSerializerDTO,
            ticketUuid: id,
          });

        return response.data;
      }
      default:
        return Promise.reject();
    }
  };

  public addComment$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    content: string,
    visibility?: CommentVisibilityEnumDTO,
  ): Promise<CommentSerializerDTO> => {
    const response =
      await this.client.addTicketCommentTicketsTicketUuidCommentsPost({
        createCommentSerializerDTO: { content, visibility },
        ticketUuid: id,
      });

    return response.data;
  };

  public getComments$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    queryParams?: ListQueryParams<CommentFilterValues>,
  ): Promise<ListCommentSerializerDTO> => {
    const { filters, page, pageSize, search, sort } = { ...queryParams };
    const { visibility } = { ...filters };
    const response =
      await this.client.getTicketCommentsTicketsTicketUuidCommentsGet({
        order: sort,
        page,
        pageSize,
        search,
        ticketUuid: id,
        visibility,
      });

    return response.data;
  };

  public getCommentsByCountry$ = async (
    countryId: CountrySerializerDTO['uuid'],
    queryParams?: ListQueryParams<{ role?: UserRoleEnumDTO }>,
  ): Promise<ListCommentWithTicketSerializerDTO> => {
    const { filters, page, pageSize, sort } = { ...queryParams };
    const { role } = { ...filters };
    const response =
      await this.client.getCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet(
        {
          countryUuid: countryId,
          order: sort,
          page,
          pageSize,
          userRole: role,
        },
      );

    return response.data;
  };

  public getMetadata$ = async (
    countryId: string,
  ): Promise<TicketMetadataSerializerDTO> => {
    const response =
      await this.client.getTicketMetadataForCountryTicketsCountryUuidMetadataGet(
        { countryUuid: countryId },
      );

    return response.data;
  };

  public addResponseTemplate$ = async (
    template: CreateReponseTemplateSerializerDTO,
  ): Promise<ResponseTemplateSerializerDTO> => {
    const response =
      await this.client.createResponseTemplateTicketsResponseTemplatesPost({
        createReponseTemplateSerializerDTO: template,
      });

    return response.data;
  };

  public getResponseTemplates$ = async (
    queryParams?: ListQueryParams,
    language?: LanguageDTO,
  ): Promise<ListResponseTemplateSerializerDTO> => {
    const { page, pageSize, search } = { ...queryParams };
    const response =
      await this.client.getResponseTemplatesTicketsResponseTemplatesGet({
        language,
        page,
        pageSize,
        search,
      });

    return response.data;
  };

  public removeResponseTemplate$ = async (
    id: ResponseTemplateSerializerDTO['uuid'],
  ): Promise<void> => {
    await this.client.deleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete(
      { responseTemplateUuid: id },
    );

    return Promise.resolve();
  };

  public getMentions$ = async (
    query: string,
  ): Promise<EmployeeSerializerDTO[]> => {
    const response =
      await this.userApiClient.searchEmployeeUsersSearchEmployeeGet({
        page: 1,
        pageSize: 25,
        search: query,
      });

    return response.data.results;
  };

  public getAssigneeSuggestions$ = async (
    country: Country | CountrySerializerDTO['uuid'],
    query: string,
    city?: CitySerializerDTO['uuid'],
    entityType?: EntityEnumDTO,
    entityUuid?:
      | PropertyObjectSerializerDTO['uuid']
      | UnitSerializerDTO['uuid'],
    parentEntityUuid?: PropertyObjectSerializerDTO['uuid'],
    role?: UserRoleEnumDTO[],
    excludedRole?: UserRoleEnumDTO[],
  ): Promise<
    NonNullable<AsyncAutocompleteOption<UserSerializerDTO['uuid']>>[]
  > => {
    const contacts$ = getEntityContactsSuggestions$(
      entityType,
      entityUuid,
      parentEntityUuid,
      query,
      role,
      excludedRole,
    );
    const employees$ = userClient.getEmployeeSuggestions$(
      query,
      country,
      city,
      role,
      excludedRole,
    );

    return Promise.all([contacts$, employees$]).then(([contacts, employees]) =>
      uniqBy([...contacts, ...employees], 'value'),
    );
  };

  public getTicketTypeByUuid$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
  ): Promise<TicketTypeEnumDTO | undefined> => {
    const response = await this.client.getTicketsTicketsGet({ uuids: id });

    return response.data.results[0]?.ticketType;
  };

  public uploadCommentAttachment$ = async (
    id: UniversalTicketSerializerDTO['uuid'],
    commentId: CommentSerializerDTO['uuid'],
    attachmentCategory: AttachmentCategoryEnumDTO,
    attachmentType: AttachmentTypeEnumDTO,
    files: File[],
    title?: AttachmentSerializerDTO['title'],
    description?: AttachmentSerializerDTO['description'],
    visibility?: CommentVisibilityEnumDTO,
  ): Promise<AttachmentSerializerDTO[]> => {
    const attachmentVisibility =
      visibility === CommentVisibilityEnumDTO.Internal
        ? AttachmentVisibilityEnumDTO.Internal
        : AttachmentVisibilityEnumDTO.Public;
    const response =
      await this.client.uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost(
        {
          attachmentCategory,
          attachmentType,
          commentUuid: commentId,
          description,
          files,
          ticketUuid: id,
          title,
          visibility: attachmentVisibility,
        },
      );

    return response.data;
  };
}
export const ticketClient = new TicketClient();
