import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    borderRadius: '50%',
    flexShrink: 0,
    height: (theme) => theme.spacing(1),
    mr: 0.5,
    width: (theme) => theme.spacing(1),
  },
  listItem: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    px: 0,
    py: 0.5,
  },
};
