import { ReactComponent as AccountingCoinsBillIcon } from '@heimstaden/icons-library/img/streamline-regular/money-payments-finance/money/accounting-coins-bill.svg';
import { ReactComponent as ProgrammingUser1Icon } from '@heimstaden/icons-library/img/streamline-regular/programing-apps-websites/programing/programming-user-1.svg';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { UnitAdvertisementSerializerDTO } from '../../../../../connectors/property';
import {
  Feature,
  getFormattedPrice,
  getUserName,
  Scrollbar,
} from '../../../../shared';
import { IntegrationsFeature } from '../IntegrationsFeature/integrations-feature.component';
import { StatusFeature } from '../StatusFeature/status-feature.component';
import { isElementFeature } from './feature-list.helper';
import { sxProps } from './feature-list.styles';
import type { Feature as TFeature } from './feature-list.type';

type Props = {
  item: UnitAdvertisementSerializerDTO;
};

const GRID_SPACING_LIST = [2, 4, 4, 6];

export const FeatureList: FC<Props> = (props) => {
  const { item } = props;
  const features = useMemo<TFeature[]>(
    () => [
      ...(item.lettingAgent
        ? [
            {
              dataTestId: 'letting-officer',
              icon: (
                <Avatar
                  sx={sxProps.avatar}
                  src={item.lettingAgent.profilePicture}
                />
              ),
              key: 'letting-officer',
              titleKey: 'advertisement.feature.lettingOfficer',
              value: getUserName(item.lettingAgent),
            },
          ]
        : []),
      {
        dataTestId: 'status',
        element: <StatusFeature status={item.status} />,
        key: 'status',
      },
      {
        dataTestId: 'integration',
        element: (
          <IntegrationsFeature
            country={item.countryUuid}
            integrations={item.integrations}
          />
        ),
        key: 'integrations',
      },
      {
        dataTestId: 'price',
        icon: <AccountingCoinsBillIcon />,
        key: 'price',
        titleKey: 'advertisement.feature.rentPrice',
        value: getFormattedPrice(item.rentPrice, item.rentPriceCurrency),
      },
      {
        dataTestId: 'prospect-count',
        icon: <ProgrammingUser1Icon />,
        key: 'prospectCount',
        titleKey: 'advertisement.feature.prospectCount',
        value: item.prospectCount,
      },
    ],
    [
      item.countryUuid,
      item.integrations,
      item.lettingAgent,
      item.prospectCount,
      item.rentPrice,
      item.rentPriceCurrency,
      item.status,
    ],
  );

  return (
    <Scrollbar>
      <Grid
        columnSpacing={GRID_SPACING_LIST}
        container
        rowSpacing={1}
        wrap="nowrap"
      >
        {features.map((feature) => {
          const { key } = feature;

          return (
            <Grid key={`feature-${key}`} item>
              {isElementFeature(feature) ? (
                feature.element
              ) : (
                <Feature {...feature} />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Scrollbar>
  );
};
