import { LeaseTemplateLanguageTypeEnumDTO } from '../../../../connectors/contract';

const templateTypeToTranslationKeysMap: Record<
  LeaseTemplateLanguageTypeEnumDTO,
  {
    helperTextKey: GenericTypes.TranslationLabel;
    labelKey: GenericTypes.TranslationLabel;
  }
> = {
  [LeaseTemplateLanguageTypeEnumDTO.Dynamic]: {
    helperTextKey: 'library.options.templateType.dynamic.helperText',
    labelKey: 'library.options.templateType.dynamic.label',
  },
  [LeaseTemplateLanguageTypeEnumDTO.Static]: {
    helperTextKey: 'library.options.templateType.static.helperText',
    labelKey: 'library.options.templateType.static.label',
  },
};

export const getTemplateTypeLabelKey = (
  type: LeaseTemplateLanguageTypeEnumDTO,
): GenericTypes.TranslationLabel =>
  templateTypeToTranslationKeysMap[type]?.labelKey || type;

export const templateTypeOptions = [
  {
    nestedFieldsConfig: [],
    value: LeaseTemplateLanguageTypeEnumDTO.Static,
    ...templateTypeToTranslationKeysMap[
      LeaseTemplateLanguageTypeEnumDTO.Static
    ],
  },
  {
    nestedFieldsConfig: [],
    value: LeaseTemplateLanguageTypeEnumDTO.Dynamic,
    ...templateTypeToTranslationKeysMap[
      LeaseTemplateLanguageTypeEnumDTO.Dynamic
    ],
  },
];
