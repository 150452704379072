import { ReactComponent as ResizeExpandSidesIcon } from '@heimstaden/icons-library/img/streamline-regular/design/resize/resize-expand-sides.svg';
import { ReactComponent as ImageFileAddIcon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/image-files/image-file-add.svg';
import { ReactComponent as MapsPin1Icon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin-1.svg';
import { ReactComponent as MapsPinIcon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin.svg';

import { companyClient } from '../../../../+company';
import { propertyClient } from '../../../../+property';
import type {
  AttachmentVisibilityEnumDTO,
  PropConfigSerializerDTO,
  CompanySerializerDTO,
} from '../../../../../connectors/company';
import type {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
  CommonProcessSerializersAttachmentAttachmentSerializerDTO as AttachmentSerializerDTO, // eslint-disable-line max-len
  CreatePropertyObjectSerializerDTO,
  DetailedPropertyObjectSerializerDTO,
  PatchPropertyObjectSerializerDTO,
  PropertyObjectSerializerDTO,
  PropertySerializerDTO,
} from '../../../../../connectors/property';
import type {
  AsyncAutocompleteOption,
  CustomFile,
  FieldsetConfig,
  FileValue,
  FormProps,
  LocationValue,
  SpecificAttributeList,
} from '../../../../shared';
import {
  ATTACHMENT_MAX_FILE_SIZE,
  attachmentConvertToFiles,
  attachmentSupportedImageTypeExtensions,
  DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  DEFAULT_LOCATION_VALUE,
  getAttributeFormFields,
  getAttributePayloadObject,
  getImagePreviewPath,
  proceedAttachmentsUpdate$,
  proceedAttachmentsUpload$,
} from '../../../../shared';
import { propertyObjectClient } from '../../../property-object.client';
import { categoryOptions } from '../../helpers';

export type Values = Omit<
  CreatePropertyObjectSerializerDTO,
  'companyUuid' | 'propertyUuid' | 'yearBuilt' | 'latitude' | 'longitude'
> & {
  attachments: FileValue;
  company: AsyncAutocompleteOption<CompanySerializerDTO['uuid']>;
  location: LocationValue;
  property: AsyncAutocompleteOption<PropertySerializerDTO['uuid']>;
  yearBuilt: string;
};

// TODO: Extend fieldset with Features section
// https://fredensborg.atlassian.net/browse/MB-417
export const getFields = (
  values?: PropertyObjectSerializerDTO,
  attachments: AttachmentSerializerDTO[] = [],
  propConfigs: PropConfigSerializerDTO[] = [],
): FieldsetConfig<Values>[] => [
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: values?.name,
          name: 'name',
        },
        props: {
          labelKey: 'propertyObject.fields.name',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.nickname,
          name: 'nickname',
        },
        props: {
          labelKey: 'propertyObject.fields.nickname',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.description,
          name: 'description',
        },
        props: {
          labelKey: 'propertyObject.fields.description',
          type: 'textarea',
        },
      },
      // TODO improve types - props have dynamic keys/names
      //  for now we don't have good solution how to type them stronger
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...getAttributeFormFields(
        values?.props as SpecificAttributeList,
        propConfigs,
      ),
    ],
    helperTextKey: 'propertyObject.fieldset.basic.helperText',
    icon: MapsPinIcon,
    key: 'basic-information',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'propertyObject.fieldset.basic.title',
  },
  {
    fields: [
      {
        componentType: 'file',
        field: {
          initialValue: attachmentConvertToFiles(attachments),
          name: 'attachments',
        },
        props: {
          accept: attachmentSupportedImageTypeExtensions,
          maxSize: ATTACHMENT_MAX_FILE_SIZE,
        },
      },
    ],
    helperTextKey: 'propertyObject.fieldset.gallery.helperText',
    icon: ImageFileAddIcon,
    key: 'gallery',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'propertyObject.fieldset.gallery.title',
  },
  {
    fields: [
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue: values?.company
            ? {
                label: values.company.name,
                value: values.company.uuid,
              }
            : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
          name: 'company',
        },
        props: {
          callback$: (query) => companyClient.getSuggestions$(query),
          labelKey: 'propertyObject.fields.company',
          required: true,
        },
      },
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue: values?.property
            ? {
                label: values.property.name,
                value: values.property.uuid,
              }
            : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
          name: 'property',
        },
        props: {
          callback$: (query, { company }) =>
            company
              ? propertyClient.getSuggestions$(query, company.value)
              : Promise.resolve([]),
          disableWhen: ({ company }) => !company,
          labelKey: 'propertyObject.fields.property',
          required: true,
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.postalCode,
          name: 'postalCode',
        },
        props: {
          labelKey: 'propertyObject.fields.postalCode',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.addressLine,
          name: 'addressLine',
        },
        props: {
          labelKey: 'propertyObject.fields.addressLine',
          required: true,
          size: { lg: 9, md: 9, sm: 8, xl: 10, xs: 12 },
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.streetNumber,
          name: 'streetNumber',
        },
        props: {
          labelKey: 'propertyObject.fields.streetNumber',
          required: true,
          size: { lg: 3, md: 3, sm: 4, xl: 2, xs: 12 },
          type: 'text',
        },
      },
      {
        componentType: 'location',
        field: {
          initialValue: values
            ? { lat: values?.latitude, lng: values?.longitude }
            : DEFAULT_LOCATION_VALUE,
          name: 'location',
        },
        props: {
          getInfoData: (formValues) => {
            const { addressLine, attachments, name, postalCode, streetNumber } =
              formValues;
            const address = [
              `${addressLine ? `${addressLine} ` : ''}${streetNumber || ''}`,
              postalCode,
            ]
              .filter((a) => a)
              .join(', ');
            const thumbnailUrl = attachments[0]
              ? getImagePreviewPath(attachments[0])
              : '/assets/thumbnail_object_placeholder.svg';

            return {
              address,
              name,
              thumbnailUrl,
            };
          },
          pinUrl: '/assets/pin_object.svg',
          tipKey: 'propertyObject.fields.position.tip',
        },
      },
    ],
    helperTextKey: 'propertyObject.fieldset.localisation.helperText',
    icon: MapsPin1Icon,
    key: 'localisation',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'propertyObject.fieldset.localisation.title',
  },
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: values?.floors,
          name: 'floors',
        },
        props: {
          labelKey: 'propertyObject.fields.floors',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.plot,
          name: 'plot',
        },
        props: {
          labelKey: 'propertyObject.fields.plot',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.area,
          name: 'area',
        },
        props: {
          labelKey: 'propertyObject.fields.area',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.lettableArea,
          name: 'lettableArea',
        },
        props: {
          labelKey: 'propertyObject.fields.lettableArea',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.yearBuilt,
          name: 'yearBuilt',
        },
        props: {
          labelKey: 'propertyObject.fields.yearBuilt',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'select',
        field: {
          initialValue: values?.category,
          name: 'category',
        },
        props: {
          labelKey: 'propertyObject.fields.category.label',
          options: categoryOptions,
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
    ],
    helperTextKey: 'propertyObject.fieldset.additional.helperText',
    icon: ResizeExpandSidesIcon,
    key: 'additional-information',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'propertyObject.fieldset.additional.title',
  },
];

export const getConfig = (
  data?: DetailedPropertyObjectSerializerDTO,
  existingAttachments: AttachmentSerializerDTO[] = [],
  propConfigs: PropConfigSerializerDTO[] = [],
): FormProps<Values>['config'] => ({
  onSubmit: async (values) => {
    const { attachments, company, location, property, yearBuilt, ...rest } =
      values;

    if (!company || !location || !property) return Promise.reject();

    const uploadAttachments$ =
      (id: PropertyObjectSerializerDTO['uuid']) =>
      (
        category: AttachmentCategoryEnumDTO,
        type: AttachmentTypeEnumDTO,
        file: CustomFile,
        visibility: AttachmentVisibilityEnumDTO,
      ): Promise<AttachmentSerializerDTO[]> =>
        propertyObjectClient.uploadAttachments$(
          category,
          file,
          type,
          id,
          visibility,
        );
    const updateAttachment$ =
      (id: PropertyObjectSerializerDTO['uuid']) =>
      (attachment: AttachmentSerializerDTO): Promise<AttachmentSerializerDTO> =>
        propertyObjectClient.updateAttachment$(attachment, id);
    const removeAttachment$ = (
      attachmentId: AttachmentSerializerDTO['uuid'],
    ): Promise<void> =>
      data
        ? propertyObjectClient.deleteAttachment$(data.uuid, attachmentId)
        : Promise.resolve();
    const payload:
      | CreatePropertyObjectSerializerDTO
      | PatchPropertyObjectSerializerDTO = {
      ...rest,
      companyUuid: company.value,
      googlePlaceId: location.placeId,
      latitude: location.lat,
      longitude: location.lng,
      propertyUuid: property.value,
      props: getAttributePayloadObject(values, propConfigs),
      yearBuilt: +yearBuilt,
    };

    if (data) {
      const [details] = await Promise.all([
        propertyObjectClient.update$(data.uuid, payload),
        proceedAttachmentsUpdate$(
          { allAttachments: attachments, existingAttachments },
          {
            handleRemove$: removeAttachment$,
            handleSequence$: propertyObjectClient.updateAttachmentsSequence$,
            handleUpdate$: updateAttachment$(data.uuid),
            handleUpload$: uploadAttachments$(data.uuid),
          },
        ),
      ]);

      return details;
    }

    const details = await propertyObjectClient.create$(
      payload as CreatePropertyObjectSerializerDTO,
    );

    await proceedAttachmentsUpload$(attachments, {
      handleUpload$: uploadAttachments$(details.uuid),
    });

    return details;
  },
});
