import Box from '@mui/material/Box';
import type { FC } from 'react';
import React, { useMemo, useState } from 'react';

import { Form } from '../../../../shared';
import type { PatchRequests, DetailsData } from '../../types';
import type { Values } from './department-switcher.model';
import { formConfig, getFieldsConfig } from './department-switcher.model';
import { Effect } from './effect.component';

type Props = {
  callback$: (data: PatchRequests) => Promise<void>;
  data: DetailsData;
  onDisclaimerDialogOpen?: () => Promise<void>;
};

export const DepartmentSwitcher: FC<Props> = (props) => {
  const { callback$, data, onDisclaimerDialogOpen } = props;
  const [isFetching, setFetching] = useState(false);
  const fieldsConfig = useMemo(
    () => getFieldsConfig(data.department, onDisclaimerDialogOpen, isFetching),
    [data.department, isFetching, onDisclaimerDialogOpen],
  );

  return (
    <Box mb={2}>
      <Form<Values>
        formEffect={
          <Effect
            callback$={callback$}
            department={data.department}
            setFetching={setFetching}
          />
        }
        config={formConfig}
        fieldsConfig={fieldsConfig}
        showButtons={false}
      />
    </Box>
  );
};
