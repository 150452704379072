import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { TicketStatusEnumDTO } from '../../../../../connectors/ticket';
import { getStatusColorsHex } from '../../helpers';

export const sxProps: Record<string, SxProps<Theme>> = {
  chip: {
    borderRadius: (theme) => theme.spacing(1),
    cursor: 'pointer',
    px: 1,
    py: 0.5,
  },
  container: {
    position: 'relative',
  },
  disabled: {
    cursor: 'default',
    opacity: 0.8,
  },
  icon: {
    color: (theme) => theme.palette.primary.contrastText,
    height: 16,
    ml: 1,
    width: 16,
  },
  menu: {
    p: 0,
  },
  menuItem: {
    color: (theme) => theme.palette.primary.contrastText,
  },
  menuItemText: {
    color: 'inherit',
    fontWeight: (theme) => theme.typography.fontWeightBold,
  },
  popover: {
    mt: 1,
  },
  select: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
    alignItems: 'center',
    borderRadius: 2,
    display: 'inline-flex',
    padding: (theme) => theme.spacing(0.5, 2),
  },
  text: {
    fontSize: (theme) => theme.typography.pxToRem(12),
    fontWeight: (theme) => theme.typography.fontWeightBold,
    lineHeight: (theme) => theme.typography.pxToRem(16),
  },
  transitionIcon: {
    color: (theme) => theme.palette.grey['500'],
    mr: 1,
  },
  [TicketStatusEnumDTO.Open]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.Open),
  },
  [TicketStatusEnumDTO.InProgress]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.InProgress),
  },
  [TicketStatusEnumDTO.AwaitingTenant]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        TicketStatusEnumDTO.AwaitingTenant,
      ),
  },
  [TicketStatusEnumDTO.AwaitingExternalProvider]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        TicketStatusEnumDTO.AwaitingExternalProvider,
      ),
  },
  [TicketStatusEnumDTO.OnHold]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.OnHold),
  },
  [TicketStatusEnumDTO.Done]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.Done),
  },
};
