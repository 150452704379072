import Grid from '@mui/material/Grid';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import omitBy from 'lodash-es/omitBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC, Dispatch, SetStateAction } from 'react';

import type { EntityEnumDTO } from '../../../../connectors/company';
import { localizationClient } from '../../../localization.client';
import { Form } from '../../form';
import { useLocalization } from '../../providers';
import type { ListState } from '../List';
import {
  formConfig,
  getFieldsConfig,
  INITIAL_VALUES,
  isInitValue,
} from './localization-filter-panel.model';
import type { FilterValues } from './localization-filter-panel.type';

type Props = {
  listState: ListState<FilterValues>;
  setListState: Dispatch<SetStateAction<ListState<FilterValues>>>;
  entityType?: EntityEnumDTO;
};

export const LocalizationFilterPanel: FC<Props> = (props) => {
  const { entityType, listState, setListState } = props;
  const { countryOptions } = useLocalization(); // TODO: Get country options from the specific user when we are on the user entity view
  const [filters, setFilters] = useState<FilterValues>({
    ...INITIAL_VALUES,
    ...listState.filters,
  });
  const fetchRegions$ = useCallback(async () => {
    const countryId = !isInitValue('country', filters.country)
      ? filters.country
      : undefined;

    if (!countryId) return [];

    return localizationClient.getRegions$(countryId, entityType);
  }, [entityType, filters.country]);
  const fetchCities$ = useCallback(async () => {
    const countryId = !isInitValue('country', filters.country)
      ? filters.country
      : undefined;
    const regionId = !isInitValue('region', filters.region)
      ? filters.region
      : undefined;

    if (!countryId) return [];

    return localizationClient.getCities$(countryId, entityType, regionId);
  }, [entityType, filters.country, filters.region]);
  const fieldsConfig = useMemo(
    () =>
      getFieldsConfig(
        countryOptions,
        fetchCities$,
        fetchRegions$,
        listState.filters,
      ),
    [countryOptions, fetchCities$, fetchRegions$, listState.filters],
  );
  const setFiltersModel = useCallback(() => {
    const touchedFilters = omitBy(filters, (filter, key) =>
      isInitValue(key as keyof FilterValues, filter),
    ) as FilterValues;
    const applicableFilters = !isEmpty(touchedFilters)
      ? touchedFilters
      : undefined; // Undefined because the default value of the filters is undefined - list.hook.ts

    // checking if there is real deep difference between prev and next state
    // to prevent redundant data re-fetching
    setListState((prevState) => ({
      ...prevState,
      filters: !isEqual(applicableFilters, prevState.filters)
        ? applicableFilters
        : prevState.filters,
      paginationConfig: !isEqual(applicableFilters, prevState.filters)
        ? {
            ...prevState.paginationConfig,
            currentPage: 1,
          }
        : prevState.paginationConfig,
    }));
  }, [filters, setListState]);

  useEffect(() => {
    setFiltersModel();
  }, [setFiltersModel]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form
          config={formConfig}
          fieldsConfig={fieldsConfig}
          inline
          onChange={setFilters} // TODO: Use formEffect instead
          showButtons={false}
          spacing={2}
        />
      </Grid>
    </Grid>
  );
};
