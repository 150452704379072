import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MUIIcon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';

import { useTranslation } from '../../translations';
import { sxProps } from './details-stepper.styles';
import type { Step } from './details-stepper.type';

type Props = {
  steps: Step[];
};

export const DetailsStepper: FC<Props> = (props) => {
  const { steps } = props;
  const { t } = useTranslation();

  return (
    <Grid container spacing={1} sx={sxProps.stepper}>
      {steps.map((step) => {
        const {
          content: Content,
          icon: Icon,
          isDisabled,
          key,
          titleKey,
        } = step;
        const stepContentSxProps = {
          ...sxProps.stepContent,
          ...(isDisabled && sxProps.stepContentDisabled),
        };
        const iconSxProps = {
          ...sxProps.icon,
          ...(isDisabled && sxProps.disabled),
        };

        return (
          <Grid item xs={12} sm={12 / steps.length} key={key} sx={sxProps.step}>
            <Box sx={stepContentSxProps}>
              <MUIIcon component={Icon} sx={iconSxProps} />
              <Typography
                fontWeight="bold"
                sx={isDisabled ? sxProps.disabled : undefined}
              >
                {t(titleKey)}
              </Typography>
              {Content && (
                <Box sx={sxProps.content}>
                  <Content />
                </Box>
              )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
