import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React from 'react';

import { useTranslation } from '../../../../../../shared';
import type { ServiceRequestValues } from '../../../../types';
import { sxProps } from '../summary-step.styles';

export const Attachments: FC = () => {
  const { values } = useFormikContext<ServiceRequestValues>();
  const { t } = useTranslation();

  if (values.attachments.length === 0) {
    return null;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography color="text.primary" variant="overline">
            <strong>{t('ticket.fieldset.summary.attachment.label')}</strong>
          </Typography>
        </Grid>
        {values.attachments.map((attachment) => {
          const { lastModified, name, size, title, type } = attachment;
          const url = URL.createObjectURL(attachment);
          // eslint-disable-next-line max-len
          const key = `attachment-${name}-${type}-${size}-${lastModified.toString()}`;

          return (
            <Grid key={key} item xs={12}>
              <Grid alignItems="center" container spacing={2}>
                <Grid item>
                  <Box
                    sx={{
                      ...sxProps.attachment,
                      backgroundImage: `url(${url})`,
                    }}
                  />
                </Grid>
                <Grid item flexBasis={1} flexGrow={1}>
                  <Typography sx={sxProps.name} variant="h3">
                    {title || name}
                  </Typography>
                  <Typography variant="body1">
                    {t('ticket.fieldset.summary.attachment.type')}:{' '}
                    <strong>{type}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Divider sx={sxProps.divider} />
    </>
  );
};
