import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import type { SelectInputProps } from '@mui/material/Select/SelectInput';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FieldProps as FormikFieldProps } from 'formik';
import isEqual from 'lodash-es/isEqual';
import isFunction from 'lodash-es/isFunction';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { ElementType, FC } from 'react';

import { useApi } from '../../../hooks';
import { useTranslation } from '../../../translations';
import { FieldLabel } from '../../components';
import { getFirstErrorKey } from '../../helpers';
import { DEFAULT_FIELD_VALUE, DEFAULT_SELECT_OPTION } from '../../index';
import type { HtmlDataAttribute, SelectOption, SelectProps } from '../types';
import { sxProps } from './select-field.styles';

type Props<TFormValues, TSelectValue> = FormikFieldProps<
  TSelectValue,
  TFormValues
> & {
  props: SelectProps<TFormValues, TSelectValue>;
};

export const SelectField = <TFormValues, TSelectValue = string | number>(
  props: Props<TFormValues, TSelectValue>,
): ReturnType<FC<Props<TFormValues, TSelectValue>>> => {
  const { meta, form, field, props: fieldProps } = props;
  const { t } = useTranslation();
  const { error, initialValue, touched, value } = meta;
  const { setFieldTouched, setFieldValue, values } = form;
  const { name, onBlur } = field;
  const {
    callback$,
    disabled,
    disableWhen,
    fieldSx,
    formControlSx,
    getReferenceFieldsToValuesMap,
    isFilter,
    labelKey,
    onBeforeOpen,
    options,
    placeholderKey,
    readonly,
    required,
    requiredWhen,
    startAdornmentIcon,
  } = fieldProps;
  const labelId = `${name}-label`;
  const [isOpen, setOpen] = useState(false);
  const finalLabelKey = useMemo(
    () => (isFunction(labelKey) ? labelKey(values) : labelKey),
    [labelKey, values],
  );
  const handleChange: SelectInputProps<TSelectValue>['onChange'] = (
    event,
  ): void => {
    setFieldValue(name, event.target.value);
    setFieldTouched(name, true);
  };
  const errorMessageKey = useMemo(
    () => touched && getFirstErrorKey(error),
    [error, touched],
  );
  const isError = useMemo(() => Boolean(errorMessageKey), [errorMessageKey]);
  const isPermanentlyDisabled = useMemo(
    () => Boolean(disabled || readonly || (disableWhen && disableWhen(values))),
    [disabled, disableWhen, readonly, values],
  );
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );
  const staticOptions = useMemo(
    () => (isFunction(options) ? options(values) : options),
    [options, values],
  );
  const fetchOptions$ = useCallback(async () => {
    if (!callback$ || isPermanentlyDisabled)
      return [] as SelectOption<TSelectValue>[];

    return callback$();
  }, [callback$, isPermanentlyDisabled]);
  const { isFetching, response: fetchedOptions } = useApi<
    SelectOption<TSelectValue>[]
  >([], fetchOptions$);
  const selectOptions = useMemo(
    () => [...staticOptions, ...fetchedOptions],
    [fetchedOptions, staticOptions],
  );
  const finalOptions = useMemo(
    () =>
      selectOptions.map(({ label, labelKey, value }, idx) => (
        <MenuItem
          data-testid={`${name}-option-${idx}`}
          key={`select-option-${value}`}
          value={value as never}
        >
          {labelKey ? t(labelKey) : label}
        </MenuItem>
      )),
    [name, selectOptions, t],
  );
  const isValueAvailable = useMemo(
    () =>
      selectOptions.some(
        (option) => (option.value as unknown as TSelectValue) === value,
      ),
    [selectOptions, value],
  );
  const isTemporarilyDisabled = useMemo(
    () => Boolean(isFetching || (callback$ && !isValueAvailable)),
    [callback$, isFetching, isValueAvailable],
  );
  const isDisabled = useMemo(
    () => isPermanentlyDisabled || isTemporarilyDisabled,
    [isPermanentlyDisabled, isTemporarilyDisabled],
  );
  const finalValue = useMemo(
    () =>
      isValueAvailable
        ? value
        : (DEFAULT_FIELD_VALUE as unknown as TSelectValue),
    [isValueAvailable, value],
  );
  const StartAdornmentIcon = useMemo(
    () =>
      (isFunction(startAdornmentIcon)
        ? startAdornmentIcon(values)
        : startAdornmentIcon) as ElementType,
    [startAdornmentIcon, values],
  );
  const handleOpen = useCallback(async () => {
    if (!onBeforeOpen || !value) {
      setOpen(true);

      return;
    }

    try {
      await onBeforeOpen();

      setOpen(true);
    } catch (e) {
      setOpen(false);
    }
  }, [onBeforeOpen, value]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!getReferenceFieldsToValuesMap) return;

    if (!touched && isEqual(initialValue, value)) {
      return;
    }

    const referenceFieldsToValuesMap = getReferenceFieldsToValuesMap(values);

    Object.entries(referenceFieldsToValuesMap).forEach(([fieldName, value]) => {
      setFieldValue(fieldName, value);
    });

    // has to be triggered only on the field value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getReferenceFieldsToValuesMap,
    initialValue,
    name,
    setFieldTouched,
    setFieldValue,
    touched,
    value,
  ]);

  return (
    <FormControl
      component="fieldset"
      disabled={isDisabled}
      error={isError}
      fullWidth
      required={isRequired && !readonly}
      sx={
        {
          ...(isFilter && sxProps.filterFormControl),
          ...formControlSx,
        } as SxProps
      }
      variant="outlined"
    >
      <Box>
        <FieldLabel isShrink={!isFilter} labelKey={finalLabelKey} />
      </Box>
      <>
        <Select<TSelectValue>
          displayEmpty
          error={isError}
          input={
            <OutlinedInput
              {...(!isFilter &&
                finalLabelKey && { label: t(finalLabelKey), notched: true })}
              id={name}
              inputProps={{ 'data-testid': `select-input-${name}` }}
              placeholder={placeholderKey ? t(placeholderKey) : undefined}
              startAdornment={
                startAdornmentIcon && (
                  <InputAdornment position="start">
                    <StartAdornmentIcon height={24} width={24} />
                  </InputAdornment>
                )
              }
            />
          }
          labelId={labelId}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          onClose={handleClose}
          onOpen={handleOpen}
          open={isOpen}
          SelectDisplayProps={
            {
              'data-testid': `select-field-${name}`,
            } as HtmlDataAttribute<SelectInputProps>['SelectDisplayProps']
          }
          sx={{ ...(isFilter && sxProps.filterField), ...fieldSx } as SxProps}
          value={finalValue}
        >
          {placeholderKey && (
            <MenuItem disabled hidden value={DEFAULT_SELECT_OPTION.value}>
              {t(placeholderKey)}
            </MenuItem>
          )}
          {finalOptions}
        </Select>
        {isError && (
          <FormHelperText error>{t(errorMessageKey || '')}</FormHelperText>
        )}
      </>
    </FormControl>
  );
};
