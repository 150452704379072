import type { FormikContextType } from 'formik';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { getResetRelatedFieldsPatch } from '../helpers';
import type { FormProps } from '../types';

type Props<TFormValues> = {
  fieldsConfig: FormProps<TFormValues>['fieldsConfig'];
  onChange?: (values: FormikContextType<TFormValues>['values']) => void;
};

// WORKAROUND for https://github.com/jaredpalmer/formik/issues/1633
export const FormikEffect = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const { fieldsConfig, onChange } = props;
  const { setValues: setContextValues, values: contextValues } =
    useFormikContext<TFormValues>();
  const [values, setValues] = useState(contextValues);

  useEffect(() => {
    if (isEqual(contextValues, values)) return;

    const resetValuesPatch = getResetRelatedFieldsPatch(
      fieldsConfig,
      contextValues,
      values,
    );

    if (isEmpty(resetValuesPatch)) {
      setValues(contextValues);
    } else {
      const payload = {
        ...contextValues,
        ...resetValuesPatch,
      };

      setValues(payload);
      setContextValues(payload);
    }
    // has to be triggered only on contextValues or fieldsConfig change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextValues, fieldsConfig]);

  useEffect(() => {
    onChange?.(values);
  }, [onChange, values]);

  return null;
};
