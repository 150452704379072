import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import React, { isValidElement, useMemo } from 'react';
import type { FC, ReactNode } from 'react';

import { getDisplayValue } from '../../../helpers';
import { useTranslation } from '../../../translations';
import type { ColumnConfig } from '../list.type';

type Props<T> = {
  columnConfig: ColumnConfig<T>;
  idx: number;
  item: T;
};

export const Cell = <T,>(props: Props<T>): ReturnType<FC<Props<T>>> => {
  const { columnConfig, idx, item } = props;
  const { t } = useTranslation();
  const customContent = useMemo(
    () => columnConfig.getContent?.(item),
    [columnConfig, item],
  );
  const simpleContent = useMemo(
    () =>
      columnConfig.propertyKey ? item[columnConfig.propertyKey] : undefined,
    [columnConfig.propertyKey, item],
  );
  const content = useMemo<ReactNode>(
    () => customContent ?? simpleContent,
    [customContent, simpleContent],
  );
  const isFormattingEnabled = useMemo(
    () => !columnConfig.disableFormatting && !isValidElement(content),
    [columnConfig.disableFormatting, content],
  );
  const formattedValue = useMemo(() => {
    if (!isFormattingEnabled) return null;

    const { labelKey, value: displayValue } = getDisplayValue(content);

    return labelKey ? t(labelKey) : displayValue;
  }, [content, isFormattingEnabled, t]);

  return (
    <TableCell align={columnConfig.align}>
      {isFormattingEnabled ? (
        <Typography
          variant={columnConfig.isMain || idx === 0 ? 'subtitle2' : 'body2'}
        >
          {formattedValue}
        </Typography>
      ) : (
        content
      )}
    </TableCell>
  );
};
