/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttachmentCategoryEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentTypeEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentVisibilityEnumDTO } from '../dto';
// @ts-ignore
import { CommonProcessSerializersAttachmentAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ContactSerializerDTO } from '../dto';
// @ts-ignore
import { CoordinatesSerializerDTO } from '../dto';
// @ts-ignore
import { CreateContactSerializerDTO } from '../dto';
// @ts-ignore
import { CreatePropertyObjectSerializerDTO } from '../dto';
// @ts-ignore
import { DetailedPropertyObjectSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { ListAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ListContactSerializerDTO } from '../dto';
// @ts-ignore
import { ListLogSerializerDTO } from '../dto';
// @ts-ignore
import { ListPropertyObjectsSerializerDTO } from '../dto';
// @ts-ignore
import { ListUnitsSerializerDTO } from '../dto';
// @ts-ignore
import { PatchPropertyObjectSerializerDTO } from '../dto';
// @ts-ignore
import { PropertyObjectCategoryEnumDTO } from '../dto';
// @ts-ignore
import { PropertyObjectReassignSerializerDTO } from '../dto';
// @ts-ignore
import { PropertyObjectSerializerDTO } from '../dto';
// @ts-ignore
import { UnitStatusEnumDTO } from '../dto';
/**
 * PropertyObjectsAPI - axios parameter creator
 * @export
 */
export const PropertyObjectsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreateContactSerializerDTO} createContactSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost: async (propertyObjectUuid: string, createContactSerializerDTO: CreateContactSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'createContactSerializerDTO' is not null or undefined
            assertParamExists('addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost', 'createContactSerializerDTO', createContactSerializerDTO)
            const localVarPath = `/property_objects/{property_object_uuid}/contacts`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContactSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Property Object
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyObjectPropertyObjectsPost: async (createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPropertyObjectSerializerDTO' is not null or undefined
            assertParamExists('createPropertyObjectPropertyObjectsPost', 'createPropertyObjectSerializerDTO', createPropertyObjectSerializerDTO)
            const localVarPath = `/property_objects/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyObjectSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {string} contactUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete: async (propertyObjectUuid: string, contactUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'contactUuid' is not null or undefined
            assertParamExists('deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete', 'contactUuid', contactUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/contacts/{contact_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)))
                .replace(`{${"contact_uuid"}}`, encodeURIComponent(String(contactUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete: async (propertyObjectUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/attachments/{attachment_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete: async (propertyObjectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet: async (propertyObjectUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/attachments/{attachment_uuid}/download`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Object Attachments
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet: async (propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/attachments`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Object Contacts
         * @param {string} propertyObjectUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet: async (propertyObjectUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, role?: string, excludeRole?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/contacts`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (excludeRole !== undefined) {
                localVarQueryParameter['exclude_role'] = excludeRole;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Object Units
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet: async (propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/units`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (propertyUuid !== undefined) {
                localVarQueryParameter['property_uuid'] = propertyUuid;
            }

            if (entranceUuid !== undefined) {
                localVarQueryParameter['entrance_uuid'] = entranceUuid;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postal_code'] = postalCode;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (excludeCategory !== undefined) {
                localVarQueryParameter['exclude_category'] = excludeCategory;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (contactUuid !== undefined) {
                localVarQueryParameter['contact_uuid'] = contactUuid;
            }

            if (companyUuid !== undefined) {
                localVarQueryParameter['company_uuid'] = companyUuid;
            }

            if (businessRegion !== undefined) {
                localVarQueryParameter['business_region'] = businessRegion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Objects Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet: async (search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/property_objects/coordinates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Objects
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [companyUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {PropertyObjectCategoryEnumDTO} [category] 
         * @param {string} [propertyUuid] 
         * @param {string} [contactUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectsPropertyObjectsGet: async (page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, companyUuid?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, postalCode?: string, category?: PropertyObjectCategoryEnumDTO, propertyUuid?: string, contactUuid?: string, businessRegion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/property_objects/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (companyUuid !== undefined) {
                localVarQueryParameter['company_uuid'] = companyUuid;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (municipalityUuid !== undefined) {
                localVarQueryParameter['municipality_uuid'] = municipalityUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postal_code'] = postalCode;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (propertyUuid !== undefined) {
                localVarQueryParameter['property_uuid'] = propertyUuid;
            }

            if (contactUuid !== undefined) {
                localVarQueryParameter['contact_uuid'] = contactUuid;
            }

            if (businessRegion !== undefined) {
                localVarQueryParameter['business_region'] = businessRegion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Property Object Auditlogs
         * @param {string} propertyObjectUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet: async (propertyObjectUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/auditlogs`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (userUuid !== undefined) {
                localVarQueryParameter['user_uuid'] = userUuid;
            }

            if (excludeFields !== undefined) {
                localVarQueryParameter['exclude_fields'] = excludeFields;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet: async (propertyObjectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Property Object
         * @param {string} propertyObjectUuid 
         * @param {PatchPropertyObjectSerializerDTO} patchPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch: async (propertyObjectUuid: string, patchPropertyObjectSerializerDTO: PatchPropertyObjectSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'patchPropertyObjectSerializerDTO' is not null or undefined
            assertParamExists('patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch', 'patchPropertyObjectSerializerDTO', patchPropertyObjectSerializerDTO)
            const localVarPath = `/property_objects/{property_object_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPropertyObjectSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPropertyObjectPropertyObjectsPropertyObjectUuidPut: async (propertyObjectUuid: string, createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('putPropertyObjectPropertyObjectsPropertyObjectUuidPut', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'createPropertyObjectSerializerDTO' is not null or undefined
            assertParamExists('putPropertyObjectPropertyObjectsPropertyObjectUuidPut', 'createPropertyObjectSerializerDTO', createPropertyObjectSerializerDTO)
            const localVarPath = `/property_objects/{property_object_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyObjectSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reassign Property Object And Related Units
         * @param {string} propertyObjectUuid 
         * @param {PropertyObjectReassignSerializerDTO} propertyObjectReassignSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost: async (propertyObjectUuid: string, propertyObjectReassignSerializerDTO: PropertyObjectReassignSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'propertyObjectReassignSerializerDTO' is not null or undefined
            assertParamExists('reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost', 'propertyObjectReassignSerializerDTO', propertyObjectReassignSerializerDTO)
            const localVarPath = `/property_objects/{property_object_uuid}/reassign_objects`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyObjectReassignSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost: async (propertyObjectUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'attachmentCategory' is not null or undefined
            assertParamExists('uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost', 'attachmentCategory', attachmentCategory)
            // verify required parameter 'attachmentType' is not null or undefined
            assertParamExists('uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost', 'attachmentType', attachmentType)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost', 'files', files)
            const localVarPath = `/property_objects/{property_object_uuid}/attachments`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


            if (attachmentCategory !== undefined) { 
                localVarFormParams.append('attachment_category', new Blob([JSON.stringify(attachmentCategory)], { type: "application/json", }));
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', new Blob([JSON.stringify(attachmentType)], { type: "application/json", }));
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (visibility !== undefined) { 
                localVarFormParams.append('visibility', new Blob([JSON.stringify(visibility)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyObjectsAPI - functional programming interface
 * @export
 */
export const PropertyObjectsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyObjectsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreateContactSerializerDTO} createContactSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(propertyObjectUuid: string, createContactSerializerDTO: CreateContactSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(propertyObjectUuid, createContactSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Property Object
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPropertyObjectPropertyObjectsPost(createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyObjectSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPropertyObjectPropertyObjectsPost(createPropertyObjectSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {string} contactUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(propertyObjectUuid: string, contactUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(propertyObjectUuid, contactUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(propertyObjectUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(propertyObjectUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(propertyObjectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(propertyObjectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(propertyObjectUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(propertyObjectUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Object Attachments
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(propertyObjectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Object Contacts
         * @param {string} propertyObjectUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(propertyObjectUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, role?: string, excludeRole?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListContactSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(propertyObjectUuid, updatedAtLte, updatedAtGt, search, role, excludeRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Object Units
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUnitsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(propertyObjectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, propertyUuid, entranceUuid, countryUuid, regionUuid, cityUuid, postalCode, category, excludeCategory, status, contactUuid, companyUuid, businessRegion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Objects Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoordinatesSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(search, countryUuid, regionUuid, cityUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Objects
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [companyUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {PropertyObjectCategoryEnumDTO} [category] 
         * @param {string} [propertyUuid] 
         * @param {string} [contactUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyObjectsPropertyObjectsGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, companyUuid?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, postalCode?: string, category?: PropertyObjectCategoryEnumDTO, propertyUuid?: string, contactUuid?: string, businessRegion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPropertyObjectsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyObjectsPropertyObjectsGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, companyUuid, countryUuid, regionUuid, municipalityUuid, cityUuid, postalCode, category, propertyUuid, contactUuid, businessRegion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Property Object Auditlogs
         * @param {string} propertyObjectUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(propertyObjectUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLogSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(propertyObjectUuid, userUuid, excludeFields, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(propertyObjectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedPropertyObjectSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(propertyObjectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Property Object
         * @param {string} propertyObjectUuid 
         * @param {PatchPropertyObjectSerializerDTO} patchPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(propertyObjectUuid: string, patchPropertyObjectSerializerDTO: PatchPropertyObjectSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyObjectSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(propertyObjectUuid, patchPropertyObjectSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPropertyObjectPropertyObjectsPropertyObjectUuidPut(propertyObjectUuid: string, createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyObjectSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPropertyObjectPropertyObjectsPropertyObjectUuidPut(propertyObjectUuid, createPropertyObjectSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reassign Property Object And Related Units
         * @param {string} propertyObjectUuid 
         * @param {PropertyObjectReassignSerializerDTO} propertyObjectReassignSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(propertyObjectUuid: string, propertyObjectReassignSerializerDTO: PropertyObjectReassignSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(propertyObjectUuid, propertyObjectReassignSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(propertyObjectUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommonProcessSerializersAttachmentAttachmentSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(propertyObjectUuid, attachmentCategory, attachmentType, files, title, description, visibility, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyObjectsAPI - factory interface
 * @export
 */
export const PropertyObjectsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyObjectsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Add Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreateContactSerializerDTO} createContactSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(propertyObjectUuid: string, createContactSerializerDTO: CreateContactSerializerDTO, options?: any): AxiosPromise<ContactSerializerDTO> {
            return localVarFp.addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(propertyObjectUuid, createContactSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Property Object
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyObjectPropertyObjectsPost(createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options?: any): AxiosPromise<PropertyObjectSerializerDTO> {
            return localVarFp.createPropertyObjectPropertyObjectsPost(createPropertyObjectSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {string} contactUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(propertyObjectUuid: string, contactUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(propertyObjectUuid, contactUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(propertyObjectUuid: string, attachmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(propertyObjectUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(propertyObjectUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(propertyObjectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(propertyObjectUuid: string, attachmentUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(propertyObjectUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Object Attachments
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): AxiosPromise<ListAttachmentSerializerDTO> {
            return localVarFp.getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(propertyObjectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Object Contacts
         * @param {string} propertyObjectUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(propertyObjectUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, role?: string, excludeRole?: string, options?: any): AxiosPromise<ListContactSerializerDTO> {
            return localVarFp.getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(propertyObjectUuid, updatedAtLte, updatedAtGt, search, role, excludeRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Object Units
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options?: any): AxiosPromise<ListUnitsSerializerDTO> {
            return localVarFp.getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(propertyObjectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, propertyUuid, entranceUuid, countryUuid, regionUuid, cityUuid, postalCode, category, excludeCategory, status, contactUuid, companyUuid, businessRegion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Objects Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options?: any): AxiosPromise<Array<CoordinatesSerializerDTO>> {
            return localVarFp.getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(search, countryUuid, regionUuid, cityUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Objects
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [companyUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {PropertyObjectCategoryEnumDTO} [category] 
         * @param {string} [propertyUuid] 
         * @param {string} [contactUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectsPropertyObjectsGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, companyUuid?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, postalCode?: string, category?: PropertyObjectCategoryEnumDTO, propertyUuid?: string, contactUuid?: string, businessRegion?: string, options?: any): AxiosPromise<ListPropertyObjectsSerializerDTO> {
            return localVarFp.getPropertyObjectsPropertyObjectsGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, companyUuid, countryUuid, regionUuid, municipalityUuid, cityUuid, postalCode, category, propertyUuid, contactUuid, businessRegion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Property Object Auditlogs
         * @param {string} propertyObjectUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(propertyObjectUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ListLogSerializerDTO> {
            return localVarFp.getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(propertyObjectUuid, userUuid, excludeFields, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(propertyObjectUuid: string, options?: any): AxiosPromise<DetailedPropertyObjectSerializerDTO> {
            return localVarFp.getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(propertyObjectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Property Object
         * @param {string} propertyObjectUuid 
         * @param {PatchPropertyObjectSerializerDTO} patchPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(propertyObjectUuid: string, patchPropertyObjectSerializerDTO: PatchPropertyObjectSerializerDTO, options?: any): AxiosPromise<PropertyObjectSerializerDTO> {
            return localVarFp.patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(propertyObjectUuid, patchPropertyObjectSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPropertyObjectPropertyObjectsPropertyObjectUuidPut(propertyObjectUuid: string, createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options?: any): AxiosPromise<PropertyObjectSerializerDTO> {
            return localVarFp.putPropertyObjectPropertyObjectsPropertyObjectUuidPut(propertyObjectUuid, createPropertyObjectSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reassign Property Object And Related Units
         * @param {string} propertyObjectUuid 
         * @param {PropertyObjectReassignSerializerDTO} propertyObjectReassignSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(propertyObjectUuid: string, propertyObjectReassignSerializerDTO: PropertyObjectReassignSerializerDTO, options?: any): AxiosPromise<void> {
            return localVarFp.reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(propertyObjectUuid, propertyObjectReassignSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(propertyObjectUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): AxiosPromise<Array<CommonProcessSerializersAttachmentAttachmentSerializerDTO>> {
            return localVarFp.uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(propertyObjectUuid, attachmentCategory, attachmentType, files, title, description, visibility, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIAddContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPostRequest
 */
export interface PropertyObjectsAPIAddContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIAddContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {CreateContactSerializerDTO}
     * @memberof PropertyObjectsAPIAddContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost
     */
    readonly createContactSerializerDTO: CreateContactSerializerDTO
}

/**
 * Request parameters for createPropertyObjectPropertyObjectsPost operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPICreatePropertyObjectPropertyObjectsPostRequest
 */
export interface PropertyObjectsAPICreatePropertyObjectPropertyObjectsPostRequest {
    /**
     * 
     * @type {CreatePropertyObjectSerializerDTO}
     * @memberof PropertyObjectsAPICreatePropertyObjectPropertyObjectsPost
     */
    readonly createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO
}

/**
 * Request parameters for deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIDeleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDeleteRequest
 */
export interface PropertyObjectsAPIDeleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIDeleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIDeleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete
     */
    readonly contactUuid: string
}

/**
 * Request parameters for deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIDeletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDeleteRequest
 */
export interface PropertyObjectsAPIDeletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIDeletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIDeletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIDeletePropertyObjectPropertyObjectsPropertyObjectUuidDeleteRequest
 */
export interface PropertyObjectsAPIDeletePropertyObjectPropertyObjectsPropertyObjectUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIDeletePropertyObjectPropertyObjectsPropertyObjectUuidDelete
     */
    readonly propertyObjectUuid: string
}

/**
 * Request parameters for downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIDownloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGetRequest
 */
export interface PropertyObjectsAPIDownloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIDownloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIDownloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGetRequest
 */
export interface PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {number}
     * @memberof PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet
     */
    readonly type?: string
}

/**
 * Request parameters for getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIGetPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGetRequest
 */
export interface PropertyObjectsAPIGetPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet
     */
    readonly role?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet
     */
    readonly excludeRole?: string
}

/**
 * Request parameters for getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGetRequest
 */
export interface PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {number}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly propertyUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly entranceUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly cityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly postalCode?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly excludeCategory?: string

    /**
     * 
     * @type {UnitStatusEnumDTO}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly status?: UnitStatusEnumDTO

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly contactUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly companyUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet
     */
    readonly businessRegion?: string
}

/**
 * Request parameters for getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIGetPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGetRequest
 */
export interface PropertyObjectsAPIGetPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet
     */
    readonly cityUuid?: string
}

/**
 * Request parameters for getPropertyObjectsPropertyObjectsGet operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGetRequest
 */
export interface PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly companyUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly municipalityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly cityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly postalCode?: string

    /**
     * 
     * @type {PropertyObjectCategoryEnumDTO}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly category?: PropertyObjectCategoryEnumDTO

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly propertyUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly contactUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGet
     */
    readonly businessRegion?: string
}

/**
 * Request parameters for getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIGetSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGetRequest
 */
export interface PropertyObjectsAPIGetSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet
     */
    readonly userUuid?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet
     */
    readonly excludeFields?: string

    /**
     * 
     * @type {number}
     * @memberof PropertyObjectsAPIGetSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PropertyObjectsAPIGetSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIGetSinglePropertyObjectPropertyObjectsPropertyObjectUuidGetRequest
 */
export interface PropertyObjectsAPIGetSinglePropertyObjectPropertyObjectsPropertyObjectUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIGetSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet
     */
    readonly propertyObjectUuid: string
}

/**
 * Request parameters for patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIPatchPropertyObjectPropertyObjectsPropertyObjectUuidPatchRequest
 */
export interface PropertyObjectsAPIPatchPropertyObjectPropertyObjectsPropertyObjectUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIPatchPropertyObjectPropertyObjectsPropertyObjectUuidPatch
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {PatchPropertyObjectSerializerDTO}
     * @memberof PropertyObjectsAPIPatchPropertyObjectPropertyObjectsPropertyObjectUuidPatch
     */
    readonly patchPropertyObjectSerializerDTO: PatchPropertyObjectSerializerDTO
}

/**
 * Request parameters for putPropertyObjectPropertyObjectsPropertyObjectUuidPut operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIPutPropertyObjectPropertyObjectsPropertyObjectUuidPutRequest
 */
export interface PropertyObjectsAPIPutPropertyObjectPropertyObjectsPropertyObjectUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIPutPropertyObjectPropertyObjectsPropertyObjectUuidPut
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {CreatePropertyObjectSerializerDTO}
     * @memberof PropertyObjectsAPIPutPropertyObjectPropertyObjectsPropertyObjectUuidPut
     */
    readonly createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO
}

/**
 * Request parameters for reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIReassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPostRequest
 */
export interface PropertyObjectsAPIReassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIReassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {PropertyObjectReassignSerializerDTO}
     * @memberof PropertyObjectsAPIReassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost
     */
    readonly propertyObjectReassignSerializerDTO: PropertyObjectReassignSerializerDTO
}

/**
 * Request parameters for uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost operation in PropertyObjectsAPI.
 * @export
 * @interface PropertyObjectsAPIUploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPostRequest
 */
export interface PropertyObjectsAPIUploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIUploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost
     */
    readonly propertyObjectUuid: string

    /**
     * 
     * @type {AttachmentCategoryEnumDTO}
     * @memberof PropertyObjectsAPIUploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost
     */
    readonly attachmentCategory: AttachmentCategoryEnumDTO

    /**
     * 
     * @type {AttachmentTypeEnumDTO}
     * @memberof PropertyObjectsAPIUploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost
     */
    readonly attachmentType: AttachmentTypeEnumDTO

    /**
     * 
     * @type {Array<any>}
     * @memberof PropertyObjectsAPIUploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost
     */
    readonly files: Array<any>

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIUploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost
     */
    readonly title?: string

    /**
     * 
     * @type {string}
     * @memberof PropertyObjectsAPIUploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost
     */
    readonly description?: string

    /**
     * 
     * @type {AttachmentVisibilityEnumDTO}
     * @memberof PropertyObjectsAPIUploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost
     */
    readonly visibility?: AttachmentVisibilityEnumDTO
}

/**
 * PropertyObjectsAPI - object-oriented interface
 * @export
 * @class PropertyObjectsAPI
 * @extends {BaseAPI}
 */
export class PropertyObjectsAPI extends BaseAPI {
    /**
     * 
     * @summary Add Contact For Property Object
     * @param {PropertyObjectsAPIAddContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(requestParameters: PropertyObjectsAPIAddContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPostRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(requestParameters.propertyObjectUuid, requestParameters.createContactSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Property Object
     * @param {PropertyObjectsAPICreatePropertyObjectPropertyObjectsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public createPropertyObjectPropertyObjectsPost(requestParameters: PropertyObjectsAPICreatePropertyObjectPropertyObjectsPostRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).createPropertyObjectPropertyObjectsPost(requestParameters.createPropertyObjectSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Contact For Property Object
     * @param {PropertyObjectsAPIDeleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(requestParameters: PropertyObjectsAPIDeleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDeleteRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(requestParameters.propertyObjectUuid, requestParameters.contactUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Property Object Attachment
     * @param {PropertyObjectsAPIDeletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(requestParameters: PropertyObjectsAPIDeletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDeleteRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(requestParameters.propertyObjectUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Property Object
     * @param {PropertyObjectsAPIDeletePropertyObjectPropertyObjectsPropertyObjectUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(requestParameters: PropertyObjectsAPIDeletePropertyObjectPropertyObjectsPropertyObjectUuidDeleteRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(requestParameters.propertyObjectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Property Object Attachment
     * @param {PropertyObjectsAPIDownloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(requestParameters: PropertyObjectsAPIDownloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGetRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(requestParameters.propertyObjectUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Object Attachments
     * @param {PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(requestParameters: PropertyObjectsAPIGetPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGetRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(requestParameters.propertyObjectUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.category, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Object Contacts
     * @param {PropertyObjectsAPIGetPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(requestParameters: PropertyObjectsAPIGetPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGetRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(requestParameters.propertyObjectUuid, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.role, requestParameters.excludeRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Object Units
     * @param {PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(requestParameters: PropertyObjectsAPIGetPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGetRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(requestParameters.propertyObjectUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.propertyUuid, requestParameters.entranceUuid, requestParameters.countryUuid, requestParameters.regionUuid, requestParameters.cityUuid, requestParameters.postalCode, requestParameters.category, requestParameters.excludeCategory, requestParameters.status, requestParameters.contactUuid, requestParameters.companyUuid, requestParameters.businessRegion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Objects Coordinates List
     * @param {PropertyObjectsAPIGetPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(requestParameters: PropertyObjectsAPIGetPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGetRequest = {}, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(requestParameters.search, requestParameters.countryUuid, requestParameters.regionUuid, requestParameters.cityUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Objects
     * @param {PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getPropertyObjectsPropertyObjectsGet(requestParameters: PropertyObjectsAPIGetPropertyObjectsPropertyObjectsGetRequest = {}, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getPropertyObjectsPropertyObjectsGet(requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.companyUuid, requestParameters.countryUuid, requestParameters.regionUuid, requestParameters.municipalityUuid, requestParameters.cityUuid, requestParameters.postalCode, requestParameters.category, requestParameters.propertyUuid, requestParameters.contactUuid, requestParameters.businessRegion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Property Object Auditlogs
     * @param {PropertyObjectsAPIGetSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(requestParameters: PropertyObjectsAPIGetSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGetRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(requestParameters.propertyObjectUuid, requestParameters.userUuid, requestParameters.excludeFields, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Property Object
     * @param {PropertyObjectsAPIGetSinglePropertyObjectPropertyObjectsPropertyObjectUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(requestParameters: PropertyObjectsAPIGetSinglePropertyObjectPropertyObjectsPropertyObjectUuidGetRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(requestParameters.propertyObjectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Property Object
     * @param {PropertyObjectsAPIPatchPropertyObjectPropertyObjectsPropertyObjectUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(requestParameters: PropertyObjectsAPIPatchPropertyObjectPropertyObjectsPropertyObjectUuidPatchRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(requestParameters.propertyObjectUuid, requestParameters.patchPropertyObjectSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Property Object
     * @param {PropertyObjectsAPIPutPropertyObjectPropertyObjectsPropertyObjectUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public putPropertyObjectPropertyObjectsPropertyObjectUuidPut(requestParameters: PropertyObjectsAPIPutPropertyObjectPropertyObjectsPropertyObjectUuidPutRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).putPropertyObjectPropertyObjectsPropertyObjectUuidPut(requestParameters.propertyObjectUuid, requestParameters.createPropertyObjectSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reassign Property Object And Related Units
     * @param {PropertyObjectsAPIReassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(requestParameters: PropertyObjectsAPIReassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPostRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(requestParameters.propertyObjectUuid, requestParameters.propertyObjectReassignSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Property Object Attachment
     * @param {PropertyObjectsAPIUploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(requestParameters: PropertyObjectsAPIUploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPostRequest, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(requestParameters.propertyObjectUuid, requestParameters.attachmentCategory, requestParameters.attachmentType, requestParameters.files, requestParameters.title, requestParameters.description, requestParameters.visibility, options).then((request) => request(this.axios, this.basePath));
    }
}
