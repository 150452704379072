import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  filterField: {
    border: `2px solid rgba(68, 84, 106, 0.08)`,
    borderRadius: 1,
    display: 'flex',
    flex: '1 1',
    ml: 1,
    position: 'relative',
    top: (theme) => theme.spacing(-0.5),
    width: 190,
  },
  filterFormControl: {
    alignItems: 'flex-start',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
  },
};
