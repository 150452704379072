import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { EntityEnumDTO } from '../../../../../connectors/company';
import type {
  CountrySerializerDTO,
  DetailedPropertySerializerDTO,
  ListAttachmentSerializerDTO,
  PropertySerializerDTO,
} from '../../../../../connectors/property';
import { localizationClient } from '../../../../localization.client';
import {
  Box,
  Form as GenericForm,
  useApi,
  useAsync,
  useLocalization,
} from '../../../../shared';
import PATHS from '../../../paths.const';
import type { Values } from './form.const';
import { getConfig, getFields } from './form.const';

export const Form: FC = () => {
  const navigate = useNavigate();
  const { countryOptions } = useLocalization();
  const { response } = useAsync<
    [DetailedPropertySerializerDTO, ListAttachmentSerializerDTO] | undefined
  >();
  const [details, attachments] = useMemo(() => response || [], [response]);
  const countryUuid: CountrySerializerDTO['uuid'] = useMemo(
    () => details?.city?.country.uuid ?? '',
    [details?.city],
  );
  const getPropConfigs = useCallback(
    () =>
      localizationClient.getPropConfigs$(countryUuid, EntityEnumDTO.Property),
    [countryUuid],
  );
  const propConfigs = useApi([], getPropConfigs);
  const config = useMemo(
    () => getConfig(details, attachments?.results, propConfigs?.response),
    [attachments?.results, details, propConfigs?.response],
  );
  const fields = useMemo(
    () =>
      getFields(
        countryOptions,
        details,
        attachments?.results,
        propConfigs?.response,
      ),
    [attachments?.results, countryOptions, details, propConfigs?.response],
  );
  const handleSuccess = useCallback(
    (data: PropertySerializerDTO) => {
      navigate(generatePath(PATHS.DETAILS, { id: data.uuid }));
    },
    [navigate],
  );
  const handleCancel = useCallback(() => {
    if (!details) return;

    navigate(generatePath(PATHS.DETAILS, { id: details.uuid }));
  }, [details, navigate]);

  return (
    <Box>
      <GenericForm<Values>
        config={config}
        fieldsConfig={fields}
        handleSuccess={handleSuccess}
        onCancel={details ? handleCancel : undefined}
        submitLabelKey={!details ? 'property.form.submit' : undefined}
      />
    </Box>
  );
};
