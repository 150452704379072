import React, { useCallback } from 'react';
import type { FC } from 'react';
import { generatePath } from 'react-router';

import { PermissionGuard } from '../../+auth';
import type { ListLeasesSerializerDTO } from '../../../connectors/contract';
import type { UserSerializerDTO } from '../../../connectors/user';
import {
  INITIAL_LIST_STATE,
  List as GenericList,
  useApi,
  useAsync,
  useList,
} from '../../shared';
import PATHS from '../paths.const';
import { VIEW_LEASE_PERMISSIONS } from '../shared';
import { userClient } from '../user.client';
import type { FilterValues } from './lease-list.model';
import {
  columnConfigs,
  filterFieldsConfig,
  INITIAL_FILTER_VALUES,
  INITIAL_SORT,
} from './lease-list.model';

const LeaseList: FC = () => {
  const { response: user } = useAsync<UserSerializerDTO>();
  const {
    listState,
    queryParams: listQueryParams,
    setListState,
    setPaginationFromResponse,
  } = useList<ListLeasesSerializerDTO, FilterValues>(false, {
    ...INITIAL_LIST_STATE,
    filters: INITIAL_FILTER_VALUES,
    sort: INITIAL_SORT,
  });
  const getData$ = useCallback(async () => {
    const response = await userClient.getLeases$(user.uuid, listQueryParams);

    setPaginationFromResponse(response);

    return response;
  }, [listQueryParams, setPaginationFromResponse, user.uuid]);
  const { isFetching, response } = useApi<ListLeasesSerializerDTO>(
    { count: 0, results: [] },
    getData$,
  );

  return (
    <PermissionGuard
      fallbackRoute={generatePath(PATHS.DETAILS, { id: user.uuid })}
      permissions={VIEW_LEASE_PERMISSIONS}
    >
      <GenericList
        columnsConfig={columnConfigs}
        filterFieldsConfig={filterFieldsConfig}
        isFetching={isFetching}
        listState={listState}
        model={response.results}
        modelKey="uuid"
        setListState={setListState}
      />
    </PermissionGuard>
  );
};

export default LeaseList;
