import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  checkedIcon: {
    backgroundColor: 'secondary.light',
    borderRadius: '50%',
    color: 'white',
    height: 40,
    width: 40,
  },
  container: {
    '&:last-of-type': {
      flexGrow: 'unset',
    },
    display: 'flex',
    flexGrow: 1,
  },
  line: {
    backgroundColor: (theme) => theme.palette.divider,
    height: 2,
    mt: 2.5,
    mx: 'auto',
    top: 0,
    width: '100%',
  },
  steps: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
