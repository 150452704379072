import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../../theme';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    '& svg, & svg path': {
      stroke: palette.silverChalice,
    },
    color: palette.silverChalice,
    mr: 1,
    py: 0.5,
  },
};
