import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useQueryClient } from 'react-query';

import type { ProspectDetailsReadOnlySerializerDTO } from '../../../../../connectors/property';
import type { FormProps } from '../../../../shared';
import {
  Form as GenericForm,
  mergeDateAndTime,
  useTranslation,
} from '../../../../shared';
import { prospectClient } from '../../../prospect.client';
import { QueryKey } from '../../enums';
import type { Values } from './viewing-schedule-form.const';
import { getFieldsConfig } from './viewing-schedule-form.const';
import { getScheduleCommentConfig } from './viewing-schedule-form.helper';

type Props = {
  handleSuccess: FormProps<Values>['handleSuccess'];
  prospect: ProspectDetailsReadOnlySerializerDTO;
};

export const ViewingScheduleForm: FC<Props> = (props) => {
  const { handleSuccess, prospect } = props;
  const { langCode, t } = useTranslation();
  const queryClient = useQueryClient();
  const config = useMemo(
    () => ({
      onSubmit: async (values: Values) => {
        const { dateForViewing, timeForViewing } = values;

        if (!dateForViewing || !timeForViewing) return Promise.reject();

        const date = mergeDateAndTime(dateForViewing, timeForViewing);
        const updatedProspect = await prospectClient.update$(prospect.uuid, {
          agreedDatetimeForViewing: date.toISOString(),
        });
        const { key, options } = getScheduleCommentConfig(
          date,
          langCode,
          Boolean(prospect.agreedDatetimeForViewing),
        );

        await prospectClient.addComment$(prospect.uuid, t(key, options));
        queryClient.refetchQueries(
          [QueryKey.REFRESH_COMMENTS],
          { active: true },
          { throwOnError: false },
        );

        return updatedProspect;
      },
    }),
    [
      langCode,
      prospect.agreedDatetimeForViewing,
      prospect.uuid,
      queryClient,
      t,
    ],
  );
  const fieldsConfig = useMemo(() => getFieldsConfig(prospect), [prospect]);

  return (
    <GenericForm<Values>
      config={config}
      fieldsConfig={fieldsConfig}
      handleSuccess={handleSuccess}
      showButtons
      submitLabelKey="prospect.entity.viewing.schedule"
    />
  );
};
