import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC, ReactElement, ReactNode } from 'react';
import React, { isValidElement } from 'react';

import { useTranslation } from '../../translations';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { NotificationBell } from '../NotificationBell/notification-bell.component';
import { PageContainer } from '../PageContainer/page-container.component';
import type { TabsRouterProps } from '../TabsRouter';
import { TabsRouter } from '../TabsRouter';
import { sxProps } from './page.styles';

type Title = {
  key?: GenericTypes.TranslationLabel;
  options?: { [key: string]: unknown };
  value?: string;
};

export type Props = {
  additionalHeaderContent?: ReactNode;
  dense?: boolean;
  tabsProps?: TabsRouterProps;
  title?: Title;
  subtitle?: Title | ReactElement;
};

export const Page: FC<Props> = (props) => {
  const {
    additionalHeaderContent,
    children,
    dense,
    subtitle,
    tabsProps,
    title,
  } = props;
  const { t } = useTranslation();

  return (
    <PageContainer dense={dense}>
      <Grid container spacing={1}>
        {subtitle && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography color="secondary.light" variant="subtitle2">
                  {isValidElement(subtitle) ? (
                    subtitle
                  ) : (
                    <>
                      {subtitle.key && t(subtitle.key, subtitle.options)}
                      {subtitle.value && subtitle.value}
                    </>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid alignItems="center" container sx={sxProps.header} wrap="nowrap">
            {title && (
              <Grid item>
                <Typography color="textPrimary" variant="h1">
                  {title.key && t(title.key, title.options)}
                  {title.value && title.value}
                </Typography>
              </Grid>
            )}
            {additionalHeaderContent && (
              <Grid item>
                <Box sx={sxProps.headerContent}>{additionalHeaderContent}</Box>
              </Grid>
            )}
            <Grid flexShrink={0} item ml="auto">
              <LanguageSwitcher />
              <NotificationBell />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {tabsProps && <TabsRouter {...tabsProps} />}
      <Box sx={{ my: 3 }}>{children}</Box>
    </PageContainer>
  );
};
