import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

import {
  handleError,
  transformKeys,
  transformAttachmentFormData,
} from './shared';
import storageService from './shared/services/storage.service';

const token = storageService.getToken();

export const mieInstance = axios.create();

mieInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
mieInstance.defaults.headers.common['Content-Type'] = 'application/json';

mieInstance.interceptors.response.use(
  (response: AxiosResponse) => ({
    ...response,
    data: transformKeys(response.data, 'CAMEL_CASE'),
  }),
  (error) => handleError(error),
);

mieInstance.interceptors.request.use(
  async (request: AxiosRequestConfig) => {
    const data =
      request.data instanceof FormData
        ? await transformAttachmentFormData(request.data)
        : request.data;

    return {
      ...request,
      data: transformKeys(data, 'SNAKE_CASE', true),
    };
  },
  (error) => Promise.reject(error),
);
