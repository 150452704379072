import { ReactComponent as ConversationSmileTypeIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/conversation/conversation-smile-type.svg';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import uniqBy from 'lodash-es/uniqBy';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import type {
  CommentSerializerDTO,
  ListCommentSerializerDTO,
  ProspectDetailsSerializerDTO,
} from '../../../../../connectors/property';
import { CommentVisibilityEnumDTO } from '../../../../../connectors/ticket';
import type { QueryContentProps } from '../../../../shared';
import {
  Box,
  Comments,
  INITIAL_LIST_STATE,
  useApi,
  useList,
  useQuery,
  useTranslation,
} from '../../../../shared';
import { prospectClient } from '../../../prospect.client';
import { QueryKey } from '../../enums';
import { sxProps } from './comments-box.styles';

const PAGE_SIZE = 25;
const REFETCH_INTERVAL = 15000;
const SORT = '-created_at';
const QUERY_OPTIONS: QueryContentProps<CommentSerializerDTO[]>['options'] = {
  refetchInterval: REFETCH_INTERVAL,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  retry: 3,
};

type Props = {
  prospectId: ProspectDetailsSerializerDTO['uuid'];
};

export const CommentsBox: FC<Props> = (props) => {
  const { prospectId } = props;
  const { t } = useTranslation();
  const [comments, setComments] = useState<CommentSerializerDTO[]>([]);
  const [isQueryEnabled, setQueryEnabled] = useState(false);
  const { setListState, setPaginationFromResponse, queryParams } =
    useList<ListCommentSerializerDTO>(false, {
      ...INITIAL_LIST_STATE,
      paginationConfig: {
        ...INITIAL_LIST_STATE.paginationConfig,
        pageSize: PAGE_SIZE,
      },
      sort: SORT,
    });
  const orderedComments = useMemo(() => [...comments].reverse(), [comments]);
  const lastCommentId = useMemo(
    () => orderedComments[orderedComments.length - 1]?.uuid,
    [orderedComments],
  );
  const getComments$ = useCallback(async () => {
    const comments = await prospectClient.getComments$(prospectId, queryParams);

    setComments((prevState) =>
      uniqBy([...prevState, ...comments.results], 'uuid'),
    );
    setPaginationFromResponse(comments);

    return comments.results;
  }, [prospectId, queryParams, setPaginationFromResponse]);
  const refreshComments$ = useCallback(async () => {
    const comments = await prospectClient.getComments$(prospectId, {
      sort: SORT,
    });

    setComments((prevState) => {
      const newState = uniqBy([...comments.results, ...prevState], 'uuid');

      return newState.length !== prevState.length ? newState : prevState;
    });

    return comments.results;
  }, [prospectId]);
  const addComment$ = useCallback(
    async (content: string): Promise<void> => {
      const response = await prospectClient.addComment$(prospectId, content);

      setComments((prevState) => [response, ...prevState]);
    },
    [prospectId],
  );
  const setNextPage = useCallback(
    () =>
      setListState((prevState) =>
        prevState.paginationConfig.next
          ? {
              ...prevState,
              paginationConfig: {
                ...prevState.paginationConfig,
                currentPage: prevState.paginationConfig.next,
              },
            }
          : prevState,
      ),
    [setListState],
  );
  const { error, isFetching } = useApi([], getComments$);
  const { error: queryError } = useQuery(
    QueryKey.REFRESH_COMMENTS,
    refreshComments$,
    {
      ...QUERY_OPTIONS,
      enabled: isQueryEnabled,
    },
  );

  useEffect(() => {
    setComments([]);
  }, [queryParams.filters]);

  useEffect(() => {
    if (error || queryError) {
      toast.error(t('errors.general.message'));
    }
  }, [error, queryError, t]);

  useEffect(() => {
    // Workaround to useQuery after timeout
    const timeout = setTimeout(() => setQueryEnabled(true), REFETCH_INTERVAL);

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    <Box sx={sxProps.box}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        sx={sxProps.header}
      >
        <Grid item sx={sxProps.container}>
          <ConversationSmileTypeIcon height={24} width={24} />
          <Typography marginBottom={0} variant="h2">
            {t('prospect.chat.title')}
          </Typography>
        </Grid>
      </Grid>
      <Comments
        addComment$={addComment$}
        comments={orderedComments}
        isFetching={isFetching}
        lastCommentId={lastCommentId}
        onNextPage={setNextPage}
        visibility={CommentVisibilityEnumDTO.Public}
      />
    </Box>
  );
};
