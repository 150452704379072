import Box from '@mui/material/Box';
import React, { forwardRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import type { ScrollBarProps as PerfectScrollbarProps } from 'react-perfect-scrollbar';

import { isMobile } from '../../helpers';
import { sxProps } from './scrollbar.styles';

type Props = PerfectScrollbarProps;

export const Scrollbar = forwardRef<HTMLElement, Props>((props, ref) => {
  const { children, ...other } = props;

  if (isMobile) {
    return (
      <Box ref={ref} sx={sxProps.scrollbar}>
        {children}
      </Box>
    );
  }

  return (
    <PerfectScrollbar
      containerRef={(container) => {
        if (typeof ref === 'function') {
          ref(container);
        }
      }}
      {...other}
    >
      {children}
    </PerfectScrollbar>
  );
});
