import { useCallback, useState } from 'react';

// https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
export const useRefHeight = (): [number, (node: unknown) => void] => {
  const [height, setHeight] = useState(0);
  const ref = useCallback((node) => {
    if (!node) return;

    setHeight(node.scrollHeight);
  }, []);

  return [height, ref];
};
