import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<'icon', SxProps<Theme>> = {
  icon: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: (theme) => theme.spacing(3),
    mr: 1,
    width: (theme) => theme.spacing(3),
  },
};
