import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

const THUMBNAILS_SPACE = 250;

export const sxProps: Record<string, SxProps<Theme>> = {
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    color: 'primary.main',
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
  closeIcon: {
    color: (theme) => theme.palette.primary.contrastText,
    position: 'absolute',
    right: (theme) => theme.spacing(-6),
    top: (theme) => theme.spacing(6),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'space-between',
    position: 'relative',
    py: 4,
    width: '80vw',
  },
  contentColumn: {
    maxHeight: [
      `calc((100vh - ${THUMBNAILS_SPACE}px) / 2)`,
      `calc((100vh - ${THUMBNAILS_SPACE}px) / 2)`,
      `calc((100vh - ${THUMBNAILS_SPACE}px) / 2)`,
      `calc(100vh - ${THUMBNAILS_SPACE}px)`,
    ],
  },
  imageWrapper: {
    height: (theme) => [
      `calc(((100vh - ${THUMBNAILS_SPACE}px) / 2) - ${theme.spacing(2)})`,
      `calc(((100vh - ${THUMBNAILS_SPACE}px) / 2) - ${theme.spacing(2)})`,
      `calc(((100vh - ${THUMBNAILS_SPACE}px) / 2) - ${theme.spacing(2)})`,
      `calc(100vh - ${THUMBNAILS_SPACE}px)`,
    ],
  },
  scrollableColumn: {
    flex: '1 1 auto',
    mt: [2, 2, 0],
    overflowY: 'auto',
  },
};
