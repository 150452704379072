import { ReactComponent as ResizeExpandSidesIcon } from '@heimstaden/icons-library/img/streamline-regular/design/resize/resize-expand-sides.svg';
import { ReactComponent as ImageFileAddIcon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/image-files/image-file-add.svg';
import { ReactComponent as MapsPin1Icon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin-1.svg';
import { ReactComponent as MapsPinIcon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin.svg';

import { companyClient } from '../../../../+company';
import type {
  CompanySerializerDTO,
  PropConfigSerializerDTO,
} from '../../../../../connectors/company';
import type {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
  CitySerializerDTO,
  CommonProcessSerializersAttachmentAttachmentSerializerDTO as AttachmentSerializerDTO, // eslint-disable-line max-len
  CountrySerializerDTO,
  CreatePropertySerializerDTO,
  DetailedPropertySerializerDTO,
  PatchPropertyObjectSerializerDTO,
  PropertySerializerDTO,
} from '../../../../../connectors/property';
import type { AttachmentVisibilityEnumDTO } from '../../../../../connectors/ticket';
import { localizationClient } from '../../../../localization.client';
import type {
  AsyncAutocompleteOption,
  CustomFile,
  FieldsetConfig,
  FileValue,
  FormProps,
  LocationValue,
  SelectOption,
  SpecificAttributeList,
} from '../../../../shared';
import {
  ATTACHMENT_MAX_FILE_SIZE,
  attachmentConvertToFiles,
  attachmentSupportedImageTypeExtensions,
  DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  DEFAULT_LOCATION_VALUE,
  DEFAULT_SELECT_OPTION,
  getAttributeFormFields,
  getAttributePayloadObject,
  getImagePreviewPath,
  proceedAttachmentsUpdate$,
  proceedAttachmentsUpload$,
} from '../../../../shared';
import { propertyClient } from '../../../property.client';

export type Values = Omit<
  CreatePropertySerializerDTO,
  'cityUuid' | 'companyUuid' | 'latitude' | 'longitude'
> & {
  attachments: FileValue;
  city: AsyncAutocompleteOption<CitySerializerDTO['uuid']>;
  company: AsyncAutocompleteOption<CompanySerializerDTO['uuid']>;
  country: CountrySerializerDTO['uuid'];
  location: LocationValue;
};

// TODO: Extend fieldset with an Assigned responsibilities section
// https://fredensborg.atlassian.net/browse/MYW-368?focusedCommentId=63935
// TODO: Extend fieldset with Features section
// https://fredensborg.atlassian.net/browse/MB-417
export const getFields = (
  countryOptions: SelectOption[],
  values?: PropertySerializerDTO,
  attachments: AttachmentSerializerDTO[] = [],
  propConfigs: PropConfigSerializerDTO[] = [],
): FieldsetConfig<Values>[] => [
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: values?.name,
          name: 'name',
        },
        props: {
          labelKey: 'property.fields.name',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.nickname,
          name: 'nickname',
        },
        props: {
          labelKey: 'property.fields.nickname',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.description,
          name: 'description',
        },
        props: {
          labelKey: 'property.fields.description',
          type: 'textarea',
        },
      },
      // TODO improve types - props have dynamic keys/names
      //  for now we don't have good solution how to type them stronger
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...getAttributeFormFields(
        values?.props as SpecificAttributeList,
        propConfigs,
      ),
    ],
    helperTextKey: 'property.fieldset.basic.helperText',
    icon: MapsPinIcon,
    key: 'basic-information',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'property.fieldset.basic.title',
  },
  {
    fields: [
      {
        componentType: 'file',
        field: {
          initialValue: attachmentConvertToFiles(attachments),
          name: 'attachments',
        },
        props: {
          accept: attachmentSupportedImageTypeExtensions,
          maxSize: ATTACHMENT_MAX_FILE_SIZE,
        },
      },
    ],
    helperTextKey: 'property.fieldset.gallery.helperText',
    icon: ImageFileAddIcon,
    key: 'gallery',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'property.fieldset.gallery.title',
  },
  {
    fields: [
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue: values?.company
            ? {
                label: values.company.name,
                value: values.company.uuid,
              }
            : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
          name: 'company',
        },
        props: {
          callback$: (query) => companyClient.getSuggestions$(query),
          labelKey: 'property.fields.company',
          nestedFieldsConfig: {
            callback$: (value) => companyClient.getDetails$(value),
            fields: [
              {
                componentType: 'select',
                field: {
                  initialValue: values?.city?.country
                    ? values.city.country.uuid
                    : DEFAULT_SELECT_OPTION.value,
                  name: 'country',
                },
                props: {
                  disabled: countryOptions.length <= 1,
                  labelKey: 'property.fields.country',
                  options: countryOptions,
                  relatedFields: ['city'],
                  required: true,
                  size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
                },
              },
              {
                componentType: 'async-autocomplete',
                field: {
                  initialValue: values?.city
                    ? {
                        label: values.city.name,
                        value: values.city.uuid,
                      }
                    : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
                  name: 'city',
                },
                props: {
                  callback$: async (query, values) =>
                    values.country
                      ? localizationClient.getCities$(
                          values.country,
                          undefined,
                          undefined,
                          query,
                        )
                      : [],
                  disableWhen: ({ country }) => !country,
                  labelKey: 'property.fields.city',
                  required: true,
                  size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
                },
              },
              {
                componentType: 'location',
                field: {
                  initialValue: values
                    ? {
                        lat: values?.latitude,
                        lng: values?.longitude,
                      }
                    : DEFAULT_LOCATION_VALUE,
                  name: 'location',
                },
                props: {
                  disableWhen: ({ city, country }) => !country || !city?.value,
                  getInfoData: (values) => {
                    const { attachments, city, country, name } = values;
                    const foundCountry = countryOptions.find(
                      (option) => option.value === country,
                    );
                    const address = [
                      ...(city ? [city.label] : []),
                      ...(foundCountry ? [foundCountry.label] : []),
                    ].join(', ');
                    const thumbnailUrl = attachments[0]
                      ? getImagePreviewPath(attachments[0])
                      : '/assets/thumbnail_property_placeholder.svg';

                    return { address, name, thumbnailUrl };
                  },
                  pinUrl: '/assets/pin_property.svg',
                  placeholderKey: 'property.fields.position',
                  required: true,
                },
              },
            ],
            getFormFieldsToResponseMap: (response: CompanySerializerDTO) => ({
              city: { label: response.city.name, value: response.city.uuid },
              country: response.city.country.uuid,
            }),
            spacing: 3,
          },
          required: true,
        },
      },
    ],
    helperTextKey: 'property.fieldset.localisation.helperText',
    icon: MapsPin1Icon,
    key: 'localisation',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'property.fieldset.localisation.title',
  },
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: values?.plot,
          name: 'plot',
        },
        props: {
          labelKey: 'property.fields.plot',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.area,
          name: 'area',
        },
        props: {
          labelKey: 'property.fields.area',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
    ],
    helperTextKey: 'property.fieldset.additional.helperText',
    icon: ResizeExpandSidesIcon,
    key: 'additional-information',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'property.fieldset.additional.title',
  },
];

export const getConfig = (
  data?: DetailedPropertySerializerDTO,
  existingAttachments: AttachmentSerializerDTO[] = [],
  propConfigs: PropConfigSerializerDTO[] = [],
): FormProps<Values>['config'] => ({
  onSubmit: async (values) => {
    const { attachments, city, company, location, ...rest } = values;

    if (!city || !company || !location) return Promise.reject();

    const uploadAttachments$ =
      (id: PropertySerializerDTO['uuid']) =>
      (
        category: AttachmentCategoryEnumDTO,
        type: AttachmentTypeEnumDTO,
        file: CustomFile,
        visibility: AttachmentVisibilityEnumDTO,
      ): Promise<AttachmentSerializerDTO[]> =>
        propertyClient.uploadAttachment$(category, file, type, id, visibility);
    const updateAttachment$ =
      (id: PropertySerializerDTO['uuid']) =>
      (attachment: AttachmentSerializerDTO): Promise<AttachmentSerializerDTO> =>
        propertyClient.updateAttachment$(attachment, id);
    const removeAttachment$ = (
      attachmentId: AttachmentSerializerDTO['uuid'],
    ): Promise<void> =>
      data
        ? propertyClient.deleteAttachment$(data.uuid, attachmentId)
        : Promise.resolve();
    const payload:
      | CreatePropertySerializerDTO
      | PatchPropertyObjectSerializerDTO = {
      ...rest,
      cityUuid: city.value,
      companyUuid: company.value,
      latitude: location.lat,
      longitude: location.lng,
      props: getAttributePayloadObject(values, propConfigs),
    };

    if (data) {
      const [details] = await Promise.all([
        propertyClient.update$(data.uuid, payload),
        proceedAttachmentsUpdate$(
          { allAttachments: attachments, existingAttachments },
          {
            handleRemove$: removeAttachment$,
            handleSequence$: propertyClient.updateAttachmentsSequence$,
            handleUpdate$: updateAttachment$(data.uuid),
            handleUpload$: uploadAttachments$(data.uuid),
          },
        ),
      ]);

      return details;
    }

    const details = await propertyClient.create$(payload);

    await proceedAttachmentsUpload$(attachments, {
      handleUpload$: uploadAttachments$(details.uuid),
    });

    return details;
  },
});
