import type { ListState, PaginationConfig } from '../components';
import { INITIAL_LIST_STATE } from '../hooks';

export const shouldDecreasePaginationAfterMutation = (
  numberOfExcludedItems: number,
  numberOfPageItems: number,
  paginationConfig: PaginationConfig,
): boolean =>
  paginationConfig.next === null &&
  paginationConfig.currentPage !==
    INITIAL_LIST_STATE.paginationConfig.currentPage &&
  numberOfExcludedItems === numberOfPageItems;

export const shouldRevertListStateAfterMutation = (
  paginationConfig: PaginationConfig,
  searchQuery: ListState['searchQuery'],
): boolean =>
  paginationConfig.currentPage !==
    INITIAL_LIST_STATE.paginationConfig.currentPage ||
  searchQuery !== INITIAL_LIST_STATE.searchQuery;
