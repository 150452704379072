import type {
  ComplaintTypeEnumDTO,
  ContractRequestTypeEnumDTO,
  DamagedItemEnumDTO,
  InvoiceRequestTypeEnumDTO,
  IssueTypeEnumDTO,
  TicketMetadataSerializerDTO,
  TicketStatusEnumDTO,
} from '../../../../../connectors/ticket';
import {
  LocationTypeEnumDTO,
  RoomTypeEnumDTO,
} from '../../../../../connectors/ticket';
import { transformKeys } from '../../../../shared';
import type { SelectOption } from '../../../../shared';
import {
  getComplaintTypeTranslationLabelKey,
  getContractRequestTypeTranslationLabelKey,
  getDamagedItemTranslationLabelKey,
  getInvoiceRequestTypeTranslationLabelKey,
  getIssueLocationTranslationLabelKey,
  getIssueSubtypeTranslationLabelKey,
  getIssueTypeTranslationLabelKey,
  getRoomTypeTranslationLabelKey,
  getStatusTranslationLabelKey,
  orderedStatuses,
} from '../../consts';
import type {
  CheckboxOptionsTypeData,
  Config,
  DEFAULT_VALUE,
  IssueSubtype,
  SelectOptionsTypeData,
  ServiceRequestsConfig,
} from './type';

export const isRoomWithIssueFieldDisabled = (
  locationType: LocationTypeEnumDTO | DEFAULT_VALUE,
): boolean => !locationType;

export const isIssueTypeFieldDisabled = (
  locationType: LocationTypeEnumDTO | DEFAULT_VALUE,
  roomType?: RoomTypeEnumDTO | DEFAULT_VALUE,
): boolean => !locationType || (locationType && !roomType);

export const isIssueSubtypeFieldDisabled = (
  locationType: LocationTypeEnumDTO | DEFAULT_VALUE,
  issueType: IssueTypeEnumDTO | DEFAULT_VALUE,
  roomType: RoomTypeEnumDTO | DEFAULT_VALUE,
  damagedItem?: DamagedItemEnumDTO | DEFAULT_VALUE,
): boolean =>
  !locationType ||
  (locationType === LocationTypeEnumDTO.Apartment && !roomType) ||
  (locationType === LocationTypeEnumDTO.Apartment && roomType && !issueType) ||
  ((locationType === LocationTypeEnumDTO.PrivateOutdoorArea ||
    locationType === LocationTypeEnumDTO.CommonOutdoorArea) &&
    !roomType) ||
  ((locationType === LocationTypeEnumDTO.PrivateOutdoorArea ||
    locationType === LocationTypeEnumDTO.CommonOutdoorArea) &&
    roomType &&
    !issueType) ||
  ((locationType === LocationTypeEnumDTO.PrivateOutdoorArea ||
    locationType === LocationTypeEnumDTO.CommonOutdoorArea) &&
    roomType &&
    issueType &&
    !damagedItem) ||
  (locationType && !issueType);

export const isDamagedItemFieldDisabled = (
  locationType: LocationTypeEnumDTO | DEFAULT_VALUE,
  issueType: IssueTypeEnumDTO | DEFAULT_VALUE,
  roomType: RoomTypeEnumDTO | DEFAULT_VALUE,
): boolean => !locationType || !issueType || !roomType;

export const getStatusesWorkflowOptions = (
  statuesWorkflow: TicketMetadataSerializerDTO['statuses'],
): Config['statusesWorkflowOptions'] =>
  Object.entries(statuesWorkflow).reduce(
    (workflows, [key, availableStatuses]) => {
      const status = key as TicketStatusEnumDTO;

      return {
        ...workflows,
        [status]: availableStatuses.map((status) => ({
          labelKey: getStatusTranslationLabelKey(status),
          value: status,
        })),
      };
    },
    {} as Config['statusesWorkflowOptions'],
  );
export const getStatusOptions = (
  statuesWorkflow: TicketMetadataSerializerDTO['statuses'],
): SelectOption<TicketStatusEnumDTO>[] => {
  const workflow = getStatusesWorkflowOptions(statuesWorkflow);

  return Object.keys(workflow)
    .map((key) => {
      const status = key as TicketStatusEnumDTO;

      return {
        labelKey: getStatusTranslationLabelKey(status),
        value: status,
      };
    })
    .sort((currentStatus, nextStatus) => {
      const currentIndexStatus = orderedStatuses.indexOf(currentStatus.value);
      const nextIndexStatus = orderedStatuses.indexOf(nextStatus.value);

      return currentIndexStatus - nextIndexStatus;
    });
};

export const getLocationTypeOptions = (
  config: ServiceRequestsConfig | undefined,
): SelectOption<LocationTypeEnumDTO>[] => {
  if (!config) return [];

  return Object.keys(config).map((key) => {
    const locationType = key as LocationTypeEnumDTO;

    return {
      labelKey: getIssueLocationTranslationLabelKey(locationType),
      value: locationType,
    };
  });
};

export const getRoomTypeOptions = (
  config: ServiceRequestsConfig | undefined,
  locationType: LocationTypeEnumDTO,
): SelectOption<RoomTypeEnumDTO>[] => {
  if (!config) return [];

  const model = config[locationType];

  if (!model) return [];

  return Object.keys(model).map((key) => {
    const roomType = key as RoomTypeEnumDTO;

    return {
      labelKey: getRoomTypeTranslationLabelKey(roomType),
      value: roomType,
    };
  });
};

export const getIssueTypeOptions = (
  config: ServiceRequestsConfig | undefined,
  locationType: LocationTypeEnumDTO,
  roomType: RoomTypeEnumDTO | DEFAULT_VALUE,
): SelectOption<IssueTypeEnumDTO>[] => {
  if (!config || isIssueTypeFieldDisabled(locationType, roomType)) return [];

  const model = config[locationType][roomType || RoomTypeEnumDTO.None];

  if (!model) return [];

  return Object.keys(model).map((key) => {
    const issueType = key as IssueTypeEnumDTO;

    return {
      labelKey: getIssueTypeTranslationLabelKey(issueType),
      value: issueType,
    };
  });
};

export const getDamagedItemOptions = (
  config: ServiceRequestsConfig | undefined,
  locationType: LocationTypeEnumDTO,
  issueType: IssueTypeEnumDTO | DEFAULT_VALUE,
  roomType: RoomTypeEnumDTO | DEFAULT_VALUE,
): SelectOption<DamagedItemEnumDTO>[] => {
  if (
    !config ||
    !roomType ||
    !issueType ||
    isDamagedItemFieldDisabled(locationType, issueType, roomType)
  )
    return [];

  const roomTypeModel = config[locationType][roomType];

  if (!roomTypeModel) return [];

  const issueTypeModel = roomTypeModel[issueType];

  if (!issueTypeModel) return [];

  return Object.keys(issueTypeModel).map((key) => {
    const damagedItem = key as DamagedItemEnumDTO;

    return {
      labelKey: getDamagedItemTranslationLabelKey(damagedItem),
      value: damagedItem,
    };
  });
};

export const getIssueSubtypeOptions = (
  config: ServiceRequestsConfig | undefined,
  locationType: LocationTypeEnumDTO,
  issueType: IssueTypeEnumDTO | DEFAULT_VALUE,
  roomType: RoomTypeEnumDTO | DEFAULT_VALUE,
  damagedItem?: DamagedItemEnumDTO | DEFAULT_VALUE,
): IssueSubtype[] => {
  if (
    !config ||
    !issueType ||
    isIssueSubtypeFieldDisabled(locationType, issueType, roomType, damagedItem)
  )
    return [];

  const model =
    config[locationType][roomType || RoomTypeEnumDTO.None]?.[issueType];

  if (!model) return [];

  if (Array.isArray(model)) {
    return model.map(({ department, subtype, ...rest }) => ({
      businessDays: transformKeys(rest, 'CAMEL_CASE').businessDays,
      department,
      labelKey: getIssueSubtypeTranslationLabelKey(subtype),
      value: subtype,
    }));
  }

  if (!damagedItem) return [];

  const issueSubtypes = model[damagedItem];

  if (!issueSubtypes) return [];

  return issueSubtypes.map(({ department, subtype, ...rest }) => ({
    businessDays: transformKeys(rest, 'CAMEL_CASE').businessDays,
    department,
    labelKey: getIssueSubtypeTranslationLabelKey(subtype),
    value: subtype,
  }));
};

const prepareTypesData = <TData, TEnum>(
  data: TData,
  getTypeTranslationLabel: (
    data: Record<string, never>,
  ) => GenericTypes.TranslationLabel,
  getValue: (data: Record<string, never>) => unknown,
): CheckboxOptionsTypeData<TEnum> | SelectOptionsTypeData<TEnum> => {
  const types = transformKeys(data, 'SNAKE_CASE');

  return types.map((type: Record<string, never>) => ({
    businessDays: transformKeys(type, 'CAMEL_CASE').businessDays,
    department: type.department,
    labelKey: getTypeTranslationLabel(type),
    name: getValue(type),
    value: getValue(type),
  }));
};

export const prepareConfig = (
  metadata: TicketMetadataSerializerDTO,
): Config => {
  const transformedStatuses = transformKeys(
    metadata.statuses || {},
    'SNAKE_CASE',
  );
  const serviceRequestsConfig = transformKeys(
    metadata.serviceRequestsValidationMap,
    'SNAKE_CASE',
  );

  return {
    complaintTypes: prepareTypesData(
      metadata.complaintTypes,
      ({ complaint }) => getComplaintTypeTranslationLabelKey(complaint),
      ({ complaint }) => complaint,
    ) as CheckboxOptionsTypeData<ComplaintTypeEnumDTO>,
    contractRequestTypes: prepareTypesData(
      metadata.contractRequestsTypes,
      ({ category }) => getContractRequestTypeTranslationLabelKey(category),
      ({ category }) => category,
    ) as SelectOptionsTypeData<ContractRequestTypeEnumDTO>,
    getDamagedItemOptions: (locationType, issueType, roomType) =>
      getDamagedItemOptions(
        serviceRequestsConfig,
        locationType,
        issueType,
        roomType,
      ),
    getIssueSubtypes: (locationType, issueType, roomType, damagedItem) =>
      getIssueSubtypeOptions(
        serviceRequestsConfig,
        locationType,
        issueType,
        roomType,
        damagedItem,
      ),
    getIssueTypeOptions: (locationType, roomType) =>
      getIssueTypeOptions(serviceRequestsConfig, locationType, roomType),
    getRoomTypeOptions: (locationType: LocationTypeEnumDTO) =>
      getRoomTypeOptions(serviceRequestsConfig, locationType),
    invoiceRequestTypes: prepareTypesData(
      metadata.invoiceRequestsTypes,
      ({ category }) => getInvoiceRequestTypeTranslationLabelKey(category),
      ({ category }) => category,
    ) as SelectOptionsTypeData<InvoiceRequestTypeEnumDTO>,
    locationTypeOptions: getLocationTypeOptions(serviceRequestsConfig),
    statusesWorkflowOptions: getStatusesWorkflowOptions(transformedStatuses),
    statusOptions: getStatusOptions(transformedStatuses),
  };
};
