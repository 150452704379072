import Box from '@mui/material/Box';
import type { GridProps } from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { Dispatch, FC, SetStateAction, TouchEvent } from 'react';
import React, { useCallback, useMemo, useRef } from 'react';
import { generatePath, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../../../../connectors/ticket';
import { TicketStatusEnumDTO } from '../../../../../../connectors/ticket';
import {
  CollectionViewMode,
  removeDragStyles,
  setDragStyles,
  useTranslation,
} from '../../../../../shared';
import PATHS from '../../../../paths.const';
import { isProfileUpdateTicket } from '../../../helpers';
import { FeatureList } from '../../FeatureList/feature-list.component';
import type { DragObject } from '../kanban.type';
import { sxProps } from './draggable-card.styles';

type Props = {
  columnElement: HTMLDivElement | null;
  columnSpacing: GridProps['spacing'];
  currentDrag: DragObject | null;
  data: UniversalTicketsWithAdditionalFieldsSerializerDTO;
  dragImageElement: HTMLImageElement | null;
  onTouchDrop: (position: DOMRect) => void;
  onTouchMoveOver: (event: TouchEvent) => void;
  setCurrentDrag: Dispatch<SetStateAction<DragObject | null>>;
};

export const DraggableCard: FC<Props> = (props) => {
  const {
    columnElement,
    columnSpacing,
    currentDrag,
    data,
    dragImageElement,
    onTouchDrop,
    onTouchMoveOver,
    setCurrentDrag,
  } = props;
  const { type } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const element = useRef<HTMLDivElement>(null);
  const cardPadding = useMemo(
    () =>
      typeof columnSpacing === 'number' ? theme.spacing(columnSpacing) : '0',
    [columnSpacing, theme],
  );
  const detailsLink = useMemo(
    () => generatePath(PATHS.DETAILS, { id: data.uuid, type }),
    [data.uuid, type],
  );
  const isDragging = useMemo(
    () => data.uuid === currentDrag?.id,
    [currentDrag?.id, data.uuid],
  );
  const onDragStart: GridProps['onDragStart'] = useCallback(
    (event) => {
      if (!dragImageElement) return;

      /* eslint-disable no-param-reassign */
      // changing style of cursor during dragging
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      /* eslint-enable no-param-reassign */
      event.dataTransfer.setDragImage(dragImageElement, 0, 0);
      setCurrentDrag({
        id: data.uuid,
        status: data.status || TicketStatusEnumDTO.Open,
      });
    },
    [data.uuid, data.status, dragImageElement, setCurrentDrag],
  );
  const onDrag: GridProps['onDrag'] = useCallback(
    (event) => {
      const { currentTarget: el, pageX, pageY } = event;

      setDragStyles(cardPadding, columnElement, el, pageX, pageY);
    },
    [cardPadding, columnElement],
  );
  const onDragEnd: GridProps['onDragEnd'] = useCallback(
    (event) => {
      const { currentTarget: el } = event;

      removeDragStyles(el);
      setCurrentDrag(null);
    },
    [setCurrentDrag],
  );
  const onTouchStart = useCallback(() => {
    setCurrentDrag({
      id: data.uuid,
      status: data.status || TicketStatusEnumDTO.Open,
    });
  }, [data.uuid, data.status, setCurrentDrag]);
  const onTouchMove: GridProps['onTouchMove'] = useCallback(
    (event) => {
      if (!element.current) return;

      const { current: el } = element;
      const { pageX, pageY } = event.changedTouches[0];

      setDragStyles(cardPadding, columnElement, el, pageX, pageY);
      onTouchMoveOver(event);
    },
    [cardPadding, columnElement, onTouchMoveOver],
  );
  const onTouchEnd: GridProps['onTouchEnd'] = useCallback(
    (event) => {
      const { currentTarget: el } = event;

      onTouchDrop(el.getBoundingClientRect());
      removeDragStyles(el);
    },
    [onTouchDrop],
  );

  return (
    <Box
      draggable
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      ref={element}
      sx={
        {
          ...sxProps.card,
          ...(isDragging && sxProps.cardDragging),
        } as SxProps
      }
    >
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <Typography variant="h3">
                {isProfileUpdateTicket(data.entityType, data.ticketType)
                  ? t('ticket.details.profileUpdateTicket.title')
                  : data.summary}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {detailsLink && (
                <Link component={RouterLink} to={detailsLink} underline="none">
                  <Typography color="secondary.light" variant="subtitle2">
                    <strong>{data.referenceNumber}</strong>
                  </Typography>
                </Link>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FeatureList data={data} viewMode={CollectionViewMode.GRID} />
        </Grid>
      </Grid>
    </Box>
  );
};
