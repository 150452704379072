import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  button: {
    '& > svg': {
      marginRight: 'unset',
    },
    '& path': {
      strokeWidth: 2,
    },
    minWidth: 'unset',
    ml: 0.5,
    p: 1,
  },
};
