import React, { useMemo } from 'react';
import type { FC } from 'react';

import { PROPERTY_OBJECT_VIEW_PERMISSIONS } from '../../+property-object';
import { TICKET_READ_PERMISSIONS } from '../../+ticket';
import { UNIT_VIEW_PERMISSIONS } from '../../+unit';
import type { UserSerializerDTO } from '../../../connectors/user';
import { UserTypeEnumDTO } from '../../../connectors/user';
import {
  DefaultRouteTabContent as GenericDefaultRouteTabContent,
  useAsync,
} from '../../shared';
import ComplaintList from '../ComplaintList/complaint-list.component';
import HomeList from '../HomeList/home-list.component';
import LeaseList from '../LeaseList/lease-list.component';
import PropertyObjectList from '../PropertyObjectList/property-object-list.component';
import { VIEW_LEASE_PERMISSIONS } from '../shared';

export const DefaultRouteTabContent: FC = () => {
  const { response: user } = useAsync<UserSerializerDTO>();
  const isEmployee = user.userType !== UserTypeEnumDTO.Customer;
  const defaultTabs = useMemo(
    () => [
      {
        additionalCondition: isEmployee,
        component: <PropertyObjectList />,
        expectedPermissions: PROPERTY_OBJECT_VIEW_PERMISSIONS,
      },
      {
        additionalCondition: isEmployee,
        component: <HomeList />,
        expectedPermissions: UNIT_VIEW_PERMISSIONS,
      },
      {
        additionalCondition: !isEmployee,
        component: <LeaseList />,
        expectedPermissions: VIEW_LEASE_PERMISSIONS,
      },
      {
        component: <ComplaintList />,
        expectedPermissions: TICKET_READ_PERMISSIONS,
      },
    ],
    [isEmployee],
  );

  return <GenericDefaultRouteTabContent tabs={defaultTabs} />;
};
