import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { INFO_WINDOW_HEIGHT } from '../../consts';

export const sxProps: Record<string, SxProps<Theme>> = {
  infoWindow: {
    alignItems: 'center',
    background: (theme) => theme.palette.background.paper,
    borderRadius: (theme) => theme.spacing(1),
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: `${INFO_WINDOW_HEIGHT}px`,
    maxWidth: '50%',
    minHeight: (theme) => theme.spacing(12),
    minWidth: (theme) => theme.spacing(20),
    overflow: 'hidden',
    position: 'absolute',
    zIndex: 2,
  },
};
