import { Fragment } from 'react';

import type { ListTicketsWithAdditionalFieldsSerializerDTO } from '../../../../connectors/ticket';
import { TicketTypeEnumDTO } from '../../../../connectors/ticket';
import type { PaginationConfig, QueryContentProps } from '../../../shared';
import { QueryKey as GlobalQueryKey } from '../../../shared';
import { TicketTypePath } from '../../paths.const';
import {
  COMPLAINT_INITIAL_FILTER_VALUES,
  ComplaintFilterPanel,
  CONTRACT_REQUEST_INITIAL_FILTER_VALUES,
  ContractRequestFilterPanel,
  GENERAL_REQUEST_INITIAL_FILTER_VALUES,
  GeneralRequestFilterPanel,
  INVOICE_REQUEST_INITIAL_FILTER_VALUES,
  InvoiceRequestFilterPanel,
  SERVICE_REQUEST_INITIAL_FILTER_VALUES,
  ServiceRequestFilterPanel,
} from '../components';
import { QueryKey } from '../enums';
import type { FilterPanelComponent, FilterValues } from '../types';

export const getQueryOptions = (
  paginationConfig: PaginationConfig,
  // eslint-disable-next-line max-len
): QueryContentProps<ListTicketsWithAdditionalFieldsSerializerDTO>['options'] => {
  const shouldRefetchAutomatically =
    paginationConfig.currentPage === 1 && paginationConfig.previous === null;

  return {
    disableSpinner: true,
    enableRefreshNotification: true,
    hideRefetchSpinner: true,
    initialData: { count: 0, results: [] },
    refetchInterval: shouldRefetchAutomatically ? 30000 : false,
    refetchOnWindowFocus: false,
  };
};

export const getFilterPanelInitialValues = (
  type: TicketTypeEnumDTO | TicketTypePath,
): FilterValues => {
  switch (type) {
    case TicketTypeEnumDTO.Complaint:
    case TicketTypePath.COMPLAINTS:
      return COMPLAINT_INITIAL_FILTER_VALUES;
    case TicketTypeEnumDTO.ContractRequest:
    case TicketTypePath.CONTRACT_REQUESTS:
      return CONTRACT_REQUEST_INITIAL_FILTER_VALUES;
    case TicketTypeEnumDTO.GeneralRequest:
    case TicketTypePath.GENERAL_REQUESTS:
      return GENERAL_REQUEST_INITIAL_FILTER_VALUES;
    case TicketTypeEnumDTO.InvoiceRequest:
    case TicketTypePath.INVOICE_REQUESTS:
      return INVOICE_REQUEST_INITIAL_FILTER_VALUES;
    case TicketTypeEnumDTO.ServiceRequest:
    case TicketTypePath.SERVICE_REQUESTS:
      return SERVICE_REQUEST_INITIAL_FILTER_VALUES;
    default:
      return {};
  }
};

export const getRequestFilterPanel = (
  type: TicketTypePath | TicketTypeEnumDTO,
): FilterPanelComponent => {
  switch (type) {
    case TicketTypeEnumDTO.Complaint:
    case TicketTypePath.COMPLAINTS:
      return ComplaintFilterPanel;
    case TicketTypeEnumDTO.ContractRequest:
    case TicketTypePath.CONTRACT_REQUESTS:
      return ContractRequestFilterPanel;
    case TicketTypeEnumDTO.GeneralRequest:
    case TicketTypePath.GENERAL_REQUESTS:
      return GeneralRequestFilterPanel;
    case TicketTypeEnumDTO.InvoiceRequest:
    case TicketTypePath.INVOICE_REQUESTS:
      return InvoiceRequestFilterPanel;
    case TicketTypeEnumDTO.ServiceRequest:
    case TicketTypePath.SERVICE_REQUESTS:
      return ServiceRequestFilterPanel;
    default:
      return Fragment;
  }
};

export const getTitleKey = (
  type: TicketTypePath | TicketTypeEnumDTO,
): GenericTypes.TranslationLabel => {
  switch (type) {
    case TicketTypeEnumDTO.Complaint:
    case TicketTypePath.COMPLAINTS:
      return 'ticket.title.list.complaint';
    case TicketTypeEnumDTO.ContractRequest:
    case TicketTypePath.CONTRACT_REQUESTS:
      return 'ticket.title.list.contractRequest';
    case TicketTypeEnumDTO.GeneralRequest:
    case TicketTypePath.GENERAL_REQUESTS:
      return 'ticket.title.list.generalRequest';
    case TicketTypeEnumDTO.InvoiceRequest:
    case TicketTypePath.INVOICE_REQUESTS:
      return 'ticket.title.list.invoiceRequest';
    case TicketTypeEnumDTO.ServiceRequest:
    case TicketTypePath.SERVICE_REQUESTS:
      return 'ticket.title.list.serviceRequest';
    default:
      return '';
  }
};

export const getQueryKey = (
  type: TicketTypeEnumDTO | undefined,
): GenericTypes.TranslationLabel => {
  switch (type) {
    case TicketTypeEnumDTO.Complaint:
      return QueryKey.COMPLAINTS;
    case TicketTypeEnumDTO.ContractRequest:
      return QueryKey.CONTRACT_REQUESTS;
    case TicketTypeEnumDTO.GeneralRequest:
      return QueryKey.GENERAL_REQUESTS;
    case TicketTypeEnumDTO.InvoiceRequest:
      return QueryKey.INVOICE_REQUESTS;
    case TicketTypeEnumDTO.ServiceRequest:
      return QueryKey.SERVICE_REQUESTS;
    default:
      return GlobalQueryKey.TICKETS;
  }
};
