import { Permission } from '../../../+auth/shared/enums/permission.enum';

export const CREATE_PERMISSIONS = [
  Permission.GLOBAL_WRITE,
  Permission.CAN_CREATE_PROSPECT,
];
export const VIEW_PERMISSIONS = [
  Permission.GLOBAL_WRITE,
  Permission.CAN_VIEW_PROSPECT,
];
export const DELETE_PERMISSIONS = [
  Permission.GLOBAL_WRITE,
  Permission.CAN_DELETE_PROSPECT,
];
