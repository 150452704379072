import Box from '@mui/material/Box';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type {
  KYCStatusSerializerDTO,
  ProspectDetailsReadOnlySerializerDTO,
} from '../../../../../connectors/property';
import { InvitationButton } from '../VerificationStepper/InvitationButton/invitation-button.component';
import { StatusIndicator } from '../VerificationStepper/StatusIndicator/status-indicator.component';
import { VerificationStepper } from '../VerificationStepper/verification-stepper.component';
import { sxProps } from './verification.styles';

type Props = {
  prospect: ProspectDetailsReadOnlySerializerDTO;
};

export const Verification: FC<Props> = (props) => {
  const { prospect } = props;
  const isVerificationNotInitialized = useMemo(
    () =>
      Boolean(
        prospect.prospectVerifications &&
          prospect.prospectVerifications.length === 0,
      ),
    [prospect.prospectVerifications],
  );
  const verificationStatuses = useMemo<KYCStatusSerializerDTO[]>(
    () => prospect.prospectVerifications?.[0]?.statuses || [],
    [prospect.prospectVerifications],
  );
  const isInvitationSent = useMemo(
    () => !isVerificationNotInitialized && verificationStatuses.length === 0,
    [isVerificationNotInitialized, verificationStatuses.length],
  );

  if (isVerificationNotInitialized) {
    return (
      <InvitationButton
        country={prospect.unit.city.country.uuid}
        id={prospect.uuid}
      />
    );
  }

  if (isInvitationSent) {
    return <StatusIndicator status="isSent" />;
  }

  return (
    <Box sx={sxProps.wrapper}>
      <VerificationStepper
        integrationId={prospect.prospectVerifications?.[0].kycIntegrationUuid}
        prospectId={prospect.uuid}
        statuses={verificationStatuses}
        verificationId={prospect.prospectVerifications?.[0].uuid}
      />
    </Box>
  );
};
