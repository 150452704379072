import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useMemo } from 'react';

import type { Account } from '../../../../+account';
import { useAuth } from '../../../../+auth';
import type { CountrySerializerDTO } from '../../../../../connectors/company';
import { EntityEnumDTO } from '../../../../../connectors/company';
import type { UserSerializerDTO } from '../../../../../connectors/user';
import type {
  FieldConfig,
  ListState,
  FilterValuesWithLocalization,
} from '../../../../shared';
import {
  FilterPanelWithLocalization,
  useLocalization,
} from '../../../../shared';
import type { TicketConfig } from '../../providers';
import { useTicketConfig } from '../../providers';

type Props<T> = {
  getFieldsConfig: (
    country: CountrySerializerDTO['uuid'],
    config: TicketConfig,
    user: Account | null,
    filters?: T,
    specifiedUser?: Pick<UserSerializerDTO, 'userType' | 'uuid'>,
  ) => FieldConfig<T>[];
  listState: ListState<FilterValuesWithLocalization<T>>;
  setListState: Dispatch<
    SetStateAction<ListState<FilterValuesWithLocalization<T>>>
  >;
  isLocalizationHidden?: boolean;
  specifiedUser?: Pick<UserSerializerDTO, 'userType' | 'uuid'>;
};

export const FilterPanel = <T,>(props: Props<T>): ReturnType<FC<Props<T>>> => {
  const {
    getFieldsConfig,
    isLocalizationHidden,
    listState,
    setListState,
    specifiedUser,
  } = props;
  const { user } = useAuth();
  const { userCountries } = useLocalization();
  const { getCountryConfig } = useTicketConfig();
  const country = useMemo(
    () =>
      listState.filters?.localization?.country || userCountries[0]?.value || '',
    [listState.filters?.localization?.country, userCountries],
  );
  const config = useMemo(
    () => getCountryConfig(country),
    [country, getCountryConfig],
  );
  const fieldsConfig = useMemo(
    () =>
      getFieldsConfig(
        country,
        config,
        user,
        listState.filters?.fields,
        specifiedUser,
      ),
    [
      config,
      country,
      getFieldsConfig,
      listState.filters?.fields,
      specifiedUser,
      user,
    ],
  );

  return (
    <FilterPanelWithLocalization
      isLocalizationHidden={isLocalizationHidden}
      fieldsConfig={fieldsConfig}
      listState={listState}
      localizationEntityType={EntityEnumDTO.Ticket}
      setListState={setListState}
    />
  );
};
