import { UnitCategoryEnumDTO } from '../../../../connectors/property';
import type { ColorsHex, ListTab, SelectOption } from '../../../shared';
import { palette } from '../../../shared/theme/palette';
import { UnitCategoryPath } from '../../paths.const';
import type { AdditionalUnitCategory } from '../consts';

const categoryToColorsHexMap: Record<UnitCategoryEnumDTO, ColorsHex> = {
  [UnitCategoryEnumDTO.Advertising]: {
    backgroundColor: palette.burntSienna,
    color: palette.black,
  },
  [UnitCategoryEnumDTO.Antenna]: {
    backgroundColor: palette.frenchRose,
    color: palette.black,
  },
  [UnitCategoryEnumDTO.CommonArea]: {
    backgroundColor: palette.fuchsiaBlue,
    color: palette.white,
  },
  [UnitCategoryEnumDTO.HospitalityAndRecreation]: {
    backgroundColor: palette.indigo,
    color: palette.white,
  },
  [UnitCategoryEnumDTO.Office]: {
    backgroundColor: palette.pictonBlue,
    color: palette.black,
  },
  [UnitCategoryEnumDTO.Other]: {
    backgroundColor: palette.scooter,
    color: palette.black,
  },
  [UnitCategoryEnumDTO.PublicServices]: {
    backgroundColor: palette.fern,
    color: palette.black,
  },
  [UnitCategoryEnumDTO.ResidentialHomes]: {
    backgroundColor: palette.burningOrange,
    color: palette.black,
  },
  [UnitCategoryEnumDTO.ResidentialParking]: {
    backgroundColor: palette.wattle,
    color: palette.black,
  },
  [UnitCategoryEnumDTO.RetailAndServices]: {
    backgroundColor: palette.gorse,
    color: palette.black,
  },
  [UnitCategoryEnumDTO.Storage]: {
    backgroundColor: palette.cement,
    color: palette.black,
  },
  [UnitCategoryEnumDTO.Warehouse]: {
    backgroundColor: palette.silver,
    color: palette.black,
  },
};

export const getCategoryColorsHex = (
  category: UnitCategoryEnumDTO,
): ColorsHex =>
  categoryToColorsHexMap[category] || {
    backgroundColor: palette.regentGray,
    color: palette.black,
  };

const categoryToTranslationLabelKeyMap: Record<
  UnitCategoryEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [UnitCategoryEnumDTO.Advertising]: 'unit.fields.category.advertising',
  [UnitCategoryEnumDTO.Antenna]: 'unit.fields.category.antenna',
  [UnitCategoryEnumDTO.CommonArea]: 'unit.fields.category.commonArea',
  [UnitCategoryEnumDTO.HospitalityAndRecreation]:
    'unit.fields.category.hospitalityAndRecreation',
  [UnitCategoryEnumDTO.Office]: 'unit.fields.category.office',
  [UnitCategoryEnumDTO.Other]: 'unit.fields.category.other',
  [UnitCategoryEnumDTO.PublicServices]: 'unit.fields.category.publicServices',
  [UnitCategoryEnumDTO.ResidentialHomes]:
    'unit.fields.category.residentialHomes',
  [UnitCategoryEnumDTO.ResidentialParking]:
    'unit.fields.category.residentialParking',
  [UnitCategoryEnumDTO.RetailAndServices]:
    'unit.fields.category.retailAndServices',
  [UnitCategoryEnumDTO.Storage]: 'unit.fields.category.storage',
  [UnitCategoryEnumDTO.Warehouse]: 'unit.fields.category.warehouse',
};

export const getCategoryTranslationLabelKey = (
  category: UnitCategoryEnumDTO,
): GenericTypes.TranslationLabel =>
  categoryToTranslationLabelKeyMap[category] || category;

export const getCategoryListTabs = (): ListTab[] =>
  Object.values(UnitCategoryEnumDTO).map((value) => {
    return {
      labelKey: getCategoryTranslationLabelKey(value),
      value,
    };
  });

export const categoryOptions: SelectOption[] = Object.values(
  UnitCategoryEnumDTO,
).map((category) => ({
  labelKey: getCategoryTranslationLabelKey(category),
  value: category,
}));

export const facilityCategoryOptions: SelectOption[] = categoryOptions.filter(
  (option) => option.value !== UnitCategoryEnumDTO.ResidentialHomes,
);

export const getCategoryQueryParams = (
  category: UnitCategoryPath,
): {
  category: UnitCategoryEnumDTO | undefined;
  excludeCategory: UnitCategoryEnumDTO | undefined;
} => {
  switch (category) {
    case UnitCategoryPath.FACILITIES:
      return {
        category: undefined,
        excludeCategory: UnitCategoryEnumDTO.ResidentialHomes,
      };
    case UnitCategoryPath.HOMES:
      return {
        category: UnitCategoryEnumDTO.ResidentialHomes,
        excludeCategory: undefined,
      };
    default:
      return {
        category: undefined,
        excludeCategory: undefined,
      };
  }
};
export const getCategoryPath = (
  category: AdditionalUnitCategory | UnitCategoryEnumDTO,
): UnitCategoryPath => {
  switch (category) {
    case UnitCategoryEnumDTO.ResidentialHomes:
      return UnitCategoryPath.HOMES;
    default:
      return UnitCategoryPath.FACILITIES;
  }
};

export const getListPageTitleKey = (
  category: UnitCategoryPath,
): GenericTypes.TranslationLabel => {
  switch (category) {
    case UnitCategoryPath.FACILITIES:
      return 'unit.section.facility';
    case UnitCategoryPath.HOMES:
      return 'unit.section.home';
    default:
      return 'unit.section.all';
  }
};
