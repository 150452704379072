/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdvertisementIntegrationPlatformValidationRulesListSerializerDTO } from '../dto';
// @ts-ignore
import { CreateUnitAdvertisementAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { CreateUnitAdvertisementSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { ListAttachmentSequenceSerializerDTO } from '../dto';
// @ts-ignore
import { ListAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ListUnitAdvertisementsSerializerDTO } from '../dto';
// @ts-ignore
import { OlxAuthorizeResponseSerializerDTO } from '../dto';
// @ts-ignore
import { OlxWebhookSerializerDTO } from '../dto';
// @ts-ignore
import { PublicationStatusEnumDTO } from '../dto';
// @ts-ignore
import { ResponseAttachmentSequenceSerializerDTO } from '../dto';
// @ts-ignore
import { UnitAdvertisementIntegrationResponseSerializerDTO } from '../dto';
// @ts-ignore
import { UnitAdvertisementSerializerDTO } from '../dto';
// @ts-ignore
import { UnpublishUnitAdvertisementSerializerDTO } from '../dto';
// @ts-ignore
import { UpdateUnitAdvertisementSerializerDTO } from '../dto';
/**
 * AdvertisementsAPI - axios parameter creator
 * @export
 */
export const AdvertisementsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Unit Advertisement Attachments
         * @param {string} unitAdvertisementUuid 
         * @param {CreateUnitAdvertisementAttachmentSerializerDTO} createUnitAdvertisementAttachmentSerializerDTO 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost: async (unitAdvertisementUuid: string, createUnitAdvertisementAttachmentSerializerDTO: CreateUnitAdvertisementAttachmentSerializerDTO, page?: number, pageSize?: number, order?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitAdvertisementUuid' is not null or undefined
            assertParamExists('createUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost', 'unitAdvertisementUuid', unitAdvertisementUuid)
            // verify required parameter 'createUnitAdvertisementAttachmentSerializerDTO' is not null or undefined
            assertParamExists('createUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost', 'createUnitAdvertisementAttachmentSerializerDTO', createUnitAdvertisementAttachmentSerializerDTO)
            const localVarPath = `/promotions/{unit_advertisement_uuid}/attachments`
                .replace(`{${"unit_advertisement_uuid"}}`, encodeURIComponent(String(unitAdvertisementUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUnitAdvertisementAttachmentSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Unit Advertisement
         * @param {CreateUnitAdvertisementSerializerDTO} createUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnitAdvertisementPromotionsPost: async (createUnitAdvertisementSerializerDTO: CreateUnitAdvertisementSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUnitAdvertisementSerializerDTO' is not null or undefined
            assertParamExists('createUnitAdvertisementPromotionsPost', 'createUnitAdvertisementSerializerDTO', createUnitAdvertisementSerializerDTO)
            const localVarPath = `/promotions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUnitAdvertisementSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Unit Advertisement Attachment
         * @param {string} unitAdvertisementUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete: async (unitAdvertisementUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitAdvertisementUuid' is not null or undefined
            assertParamExists('deleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete', 'unitAdvertisementUuid', unitAdvertisementUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('deleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/promotions/{unit_advertisement_uuid}/attachments/{attachment_uuid}`
                .replace(`{${"unit_advertisement_uuid"}}`, encodeURIComponent(String(unitAdvertisementUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGet: async (unitAdvertisementUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitAdvertisementUuid' is not null or undefined
            assertParamExists('getSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGet', 'unitAdvertisementUuid', unitAdvertisementUuid)
            const localVarPath = `/promotions/{unit_advertisement_uuid}`
                .replace(`{${"unit_advertisement_uuid"}}`, encodeURIComponent(String(unitAdvertisementUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Integration Validation Rules
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrationValidationRulesPromotionsIntegrationValidationRulesGet: async (countryUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryUuid' is not null or undefined
            assertParamExists('listIntegrationValidationRulesPromotionsIntegrationValidationRulesGet', 'countryUuid', countryUuid)
            const localVarPath = `/promotions/integration-validation-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Unit Advertisement Attachments
         * @param {string} unitAdvertisementUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet: async (unitAdvertisementUuid: string, page?: number, pageSize?: number, order?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitAdvertisementUuid' is not null or undefined
            assertParamExists('listUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet', 'unitAdvertisementUuid', unitAdvertisementUuid)
            const localVarPath = `/promotions/{unit_advertisement_uuid}/attachments`
                .replace(`{${"unit_advertisement_uuid"}}`, encodeURIComponent(String(unitAdvertisementUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Unit Advertisements
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [lettingAgentUuid] 
         * @param {boolean} [hasProspects] 
         * @param {PublicationStatusEnumDTO} [status] 
         * @param {string} [availableDateFromLt] 
         * @param {string} [availableDateFromGt] 
         * @param {string} [publishedAtLt] 
         * @param {string} [publishedAtGt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnitAdvertisementsPromotionsGet: async (page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, lettingAgentUuid?: string, hasProspects?: boolean, status?: PublicationStatusEnumDTO, availableDateFromLt?: string, availableDateFromGt?: string, publishedAtLt?: string, publishedAtGt?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }

            if (lettingAgentUuid !== undefined) {
                localVarQueryParameter['letting_agent_uuid'] = lettingAgentUuid;
            }

            if (hasProspects !== undefined) {
                localVarQueryParameter['has_prospects'] = hasProspects;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (availableDateFromLt !== undefined) {
                localVarQueryParameter['available_date_from_lt'] = (availableDateFromLt as any instanceof Date) ?
                    (availableDateFromLt as any).toISOString().substr(0,10) :
                    availableDateFromLt;
            }

            if (availableDateFromGt !== undefined) {
                localVarQueryParameter['available_date_from_gt'] = (availableDateFromGt as any instanceof Date) ?
                    (availableDateFromGt as any).toISOString().substr(0,10) :
                    availableDateFromGt;
            }

            if (publishedAtLt !== undefined) {
                localVarQueryParameter['published_at_lt'] = (publishedAtLt as any instanceof Date) ?
                    (publishedAtLt as any).toISOString() :
                    publishedAtLt;
            }

            if (publishedAtGt !== undefined) {
                localVarQueryParameter['published_at_gt'] = (publishedAtGt as any instanceof Date) ?
                    (publishedAtGt as any).toISOString() :
                    publishedAtGt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Olx Authorize
         * @param {string} code 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxAuthorizePromotionsIntegrationsOlxAuthGet: async (code: string, state: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('olxAuthorizePromotionsIntegrationsOlxAuthGet', 'code', code)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('olxAuthorizePromotionsIntegrationsOlxAuthGet', 'state', state)
            const localVarPath = `/promotions/integrations/olx/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Olx Trigger Authorization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/integrations/olx/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Olx Webhook
         * @param {OlxWebhookSerializerDTO} olxWebhookSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxWebhookPromotionsIntegrationsOlxWebhookPost: async (olxWebhookSerializerDTO: OlxWebhookSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'olxWebhookSerializerDTO' is not null or undefined
            assertParamExists('olxWebhookPromotionsIntegrationsOlxWebhookPost', 'olxWebhookSerializerDTO', olxWebhookSerializerDTO)
            const localVarPath = `/promotions/integrations/olx/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(olxWebhookSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {UpdateUnitAdvertisementSerializerDTO} updateUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch: async (unitAdvertisementUuid: string, updateUnitAdvertisementSerializerDTO: UpdateUnitAdvertisementSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitAdvertisementUuid' is not null or undefined
            assertParamExists('patchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch', 'unitAdvertisementUuid', unitAdvertisementUuid)
            // verify required parameter 'updateUnitAdvertisementSerializerDTO' is not null or undefined
            assertParamExists('patchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch', 'updateUnitAdvertisementSerializerDTO', updateUnitAdvertisementSerializerDTO)
            const localVarPath = `/promotions/{unit_advertisement_uuid}`
                .replace(`{${"unit_advertisement_uuid"}}`, encodeURIComponent(String(unitAdvertisementUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUnitAdvertisementSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost: async (unitAdvertisementUuid: string, body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitAdvertisementUuid' is not null or undefined
            assertParamExists('publishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost', 'unitAdvertisementUuid', unitAdvertisementUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('publishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost', 'body', body)
            const localVarPath = `/promotions/{unit_advertisement_uuid}/publish`
                .replace(`{${"unit_advertisement_uuid"}}`, encodeURIComponent(String(unitAdvertisementUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {UpdateUnitAdvertisementSerializerDTO} updateUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUnitAdvertisementPromotionsUnitAdvertisementUuidPut: async (unitAdvertisementUuid: string, updateUnitAdvertisementSerializerDTO: UpdateUnitAdvertisementSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitAdvertisementUuid' is not null or undefined
            assertParamExists('putUnitAdvertisementPromotionsUnitAdvertisementUuidPut', 'unitAdvertisementUuid', unitAdvertisementUuid)
            // verify required parameter 'updateUnitAdvertisementSerializerDTO' is not null or undefined
            assertParamExists('putUnitAdvertisementPromotionsUnitAdvertisementUuidPut', 'updateUnitAdvertisementSerializerDTO', updateUnitAdvertisementSerializerDTO)
            const localVarPath = `/promotions/{unit_advertisement_uuid}`
                .replace(`{${"unit_advertisement_uuid"}}`, encodeURIComponent(String(unitAdvertisementUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUnitAdvertisementSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unpublish Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {UnpublishUnitAdvertisementSerializerDTO} unpublishUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost: async (unitAdvertisementUuid: string, unpublishUnitAdvertisementSerializerDTO: UnpublishUnitAdvertisementSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitAdvertisementUuid' is not null or undefined
            assertParamExists('unpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost', 'unitAdvertisementUuid', unitAdvertisementUuid)
            // verify required parameter 'unpublishUnitAdvertisementSerializerDTO' is not null or undefined
            assertParamExists('unpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost', 'unpublishUnitAdvertisementSerializerDTO', unpublishUnitAdvertisementSerializerDTO)
            const localVarPath = `/promotions/{unit_advertisement_uuid}/unpublish`
                .replace(`{${"unit_advertisement_uuid"}}`, encodeURIComponent(String(unitAdvertisementUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unpublishUnitAdvertisementSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Unit Advertisement Attachments Sequence
         * @param {string} unitAdvertisementUuid 
         * @param {ListAttachmentSequenceSerializerDTO} listAttachmentSequenceSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut: async (unitAdvertisementUuid: string, listAttachmentSequenceSerializerDTO: ListAttachmentSequenceSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitAdvertisementUuid' is not null or undefined
            assertParamExists('updateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut', 'unitAdvertisementUuid', unitAdvertisementUuid)
            // verify required parameter 'listAttachmentSequenceSerializerDTO' is not null or undefined
            assertParamExists('updateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut', 'listAttachmentSequenceSerializerDTO', listAttachmentSequenceSerializerDTO)
            const localVarPath = `/promotions/{unit_advertisement_uuid}/attachments/sequence_numbers`
                .replace(`{${"unit_advertisement_uuid"}}`, encodeURIComponent(String(unitAdvertisementUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listAttachmentSequenceSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvertisementsAPI - functional programming interface
 * @export
 */
export const AdvertisementsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvertisementsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Unit Advertisement Attachments
         * @param {string} unitAdvertisementUuid 
         * @param {CreateUnitAdvertisementAttachmentSerializerDTO} createUnitAdvertisementAttachmentSerializerDTO 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost(unitAdvertisementUuid: string, createUnitAdvertisementAttachmentSerializerDTO: CreateUnitAdvertisementAttachmentSerializerDTO, page?: number, pageSize?: number, order?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost(unitAdvertisementUuid, createUnitAdvertisementAttachmentSerializerDTO, page, pageSize, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Unit Advertisement
         * @param {CreateUnitAdvertisementSerializerDTO} createUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUnitAdvertisementPromotionsPost(createUnitAdvertisementSerializerDTO: CreateUnitAdvertisementSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitAdvertisementSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUnitAdvertisementPromotionsPost(createUnitAdvertisementSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Unit Advertisement Attachment
         * @param {string} unitAdvertisementUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete(unitAdvertisementUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete(unitAdvertisementUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGet(unitAdvertisementUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitAdvertisementSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGet(unitAdvertisementUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Integration Validation Rules
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listIntegrationValidationRulesPromotionsIntegrationValidationRulesGet(countryUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvertisementIntegrationPlatformValidationRulesListSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listIntegrationValidationRulesPromotionsIntegrationValidationRulesGet(countryUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Unit Advertisement Attachments
         * @param {string} unitAdvertisementUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet(unitAdvertisementUuid: string, page?: number, pageSize?: number, order?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet(unitAdvertisementUuid, page, pageSize, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Unit Advertisements
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [lettingAgentUuid] 
         * @param {boolean} [hasProspects] 
         * @param {PublicationStatusEnumDTO} [status] 
         * @param {string} [availableDateFromLt] 
         * @param {string} [availableDateFromGt] 
         * @param {string} [publishedAtLt] 
         * @param {string} [publishedAtGt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUnitAdvertisementsPromotionsGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, lettingAgentUuid?: string, hasProspects?: boolean, status?: PublicationStatusEnumDTO, availableDateFromLt?: string, availableDateFromGt?: string, publishedAtLt?: string, publishedAtGt?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUnitAdvertisementsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUnitAdvertisementsPromotionsGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, countryUuid, regionUuid, cityUuid, lettingAgentUuid, hasProspects, status, availableDateFromLt, availableDateFromGt, publishedAtLt, publishedAtGt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Olx Authorize
         * @param {string} code 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async olxAuthorizePromotionsIntegrationsOlxAuthGet(code: string, state: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.olxAuthorizePromotionsIntegrationsOlxAuthGet(code, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Olx Trigger Authorization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OlxAuthorizeResponseSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Olx Webhook
         * @param {OlxWebhookSerializerDTO} olxWebhookSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async olxWebhookPromotionsIntegrationsOlxWebhookPost(olxWebhookSerializerDTO: OlxWebhookSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.olxWebhookPromotionsIntegrationsOlxWebhookPost(olxWebhookSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {UpdateUnitAdvertisementSerializerDTO} updateUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch(unitAdvertisementUuid: string, updateUnitAdvertisementSerializerDTO: UpdateUnitAdvertisementSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitAdvertisementSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch(unitAdvertisementUuid, updateUnitAdvertisementSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publish Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost(unitAdvertisementUuid: string, body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitAdvertisementIntegrationResponseSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost(unitAdvertisementUuid, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {UpdateUnitAdvertisementSerializerDTO} updateUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUnitAdvertisementPromotionsUnitAdvertisementUuidPut(unitAdvertisementUuid: string, updateUnitAdvertisementSerializerDTO: UpdateUnitAdvertisementSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitAdvertisementSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUnitAdvertisementPromotionsUnitAdvertisementUuidPut(unitAdvertisementUuid, updateUnitAdvertisementSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unpublish Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {UnpublishUnitAdvertisementSerializerDTO} unpublishUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost(unitAdvertisementUuid: string, unpublishUnitAdvertisementSerializerDTO: UnpublishUnitAdvertisementSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost(unitAdvertisementUuid, unpublishUnitAdvertisementSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Unit Advertisement Attachments Sequence
         * @param {string} unitAdvertisementUuid 
         * @param {ListAttachmentSequenceSerializerDTO} listAttachmentSequenceSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut(unitAdvertisementUuid: string, listAttachmentSequenceSerializerDTO: ListAttachmentSequenceSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAttachmentSequenceSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut(unitAdvertisementUuid, listAttachmentSequenceSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvertisementsAPI - factory interface
 * @export
 */
export const AdvertisementsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvertisementsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Create Unit Advertisement Attachments
         * @param {string} unitAdvertisementUuid 
         * @param {CreateUnitAdvertisementAttachmentSerializerDTO} createUnitAdvertisementAttachmentSerializerDTO 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost(unitAdvertisementUuid: string, createUnitAdvertisementAttachmentSerializerDTO: CreateUnitAdvertisementAttachmentSerializerDTO, page?: number, pageSize?: number, order?: string, options?: any): AxiosPromise<ListAttachmentSerializerDTO> {
            return localVarFp.createUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost(unitAdvertisementUuid, createUnitAdvertisementAttachmentSerializerDTO, page, pageSize, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Unit Advertisement
         * @param {CreateUnitAdvertisementSerializerDTO} createUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnitAdvertisementPromotionsPost(createUnitAdvertisementSerializerDTO: CreateUnitAdvertisementSerializerDTO, options?: any): AxiosPromise<UnitAdvertisementSerializerDTO> {
            return localVarFp.createUnitAdvertisementPromotionsPost(createUnitAdvertisementSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Unit Advertisement Attachment
         * @param {string} unitAdvertisementUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete(unitAdvertisementUuid: string, attachmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete(unitAdvertisementUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGet(unitAdvertisementUuid: string, options?: any): AxiosPromise<UnitAdvertisementSerializerDTO> {
            return localVarFp.getSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGet(unitAdvertisementUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Integration Validation Rules
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrationValidationRulesPromotionsIntegrationValidationRulesGet(countryUuid: string, options?: any): AxiosPromise<AdvertisementIntegrationPlatformValidationRulesListSerializerDTO> {
            return localVarFp.listIntegrationValidationRulesPromotionsIntegrationValidationRulesGet(countryUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Unit Advertisement Attachments
         * @param {string} unitAdvertisementUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet(unitAdvertisementUuid: string, page?: number, pageSize?: number, order?: string, options?: any): AxiosPromise<ListAttachmentSerializerDTO> {
            return localVarFp.listUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet(unitAdvertisementUuid, page, pageSize, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Unit Advertisements
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [lettingAgentUuid] 
         * @param {boolean} [hasProspects] 
         * @param {PublicationStatusEnumDTO} [status] 
         * @param {string} [availableDateFromLt] 
         * @param {string} [availableDateFromGt] 
         * @param {string} [publishedAtLt] 
         * @param {string} [publishedAtGt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnitAdvertisementsPromotionsGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, lettingAgentUuid?: string, hasProspects?: boolean, status?: PublicationStatusEnumDTO, availableDateFromLt?: string, availableDateFromGt?: string, publishedAtLt?: string, publishedAtGt?: string, options?: any): AxiosPromise<ListUnitAdvertisementsSerializerDTO> {
            return localVarFp.listUnitAdvertisementsPromotionsGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, countryUuid, regionUuid, cityUuid, lettingAgentUuid, hasProspects, status, availableDateFromLt, availableDateFromGt, publishedAtLt, publishedAtGt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Olx Authorize
         * @param {string} code 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxAuthorizePromotionsIntegrationsOlxAuthGet(code: string, state: string, options?: any): AxiosPromise<any> {
            return localVarFp.olxAuthorizePromotionsIntegrationsOlxAuthGet(code, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Olx Trigger Authorization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options?: any): AxiosPromise<OlxAuthorizeResponseSerializerDTO> {
            return localVarFp.olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Olx Webhook
         * @param {OlxWebhookSerializerDTO} olxWebhookSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxWebhookPromotionsIntegrationsOlxWebhookPost(olxWebhookSerializerDTO: OlxWebhookSerializerDTO, options?: any): AxiosPromise<void> {
            return localVarFp.olxWebhookPromotionsIntegrationsOlxWebhookPost(olxWebhookSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {UpdateUnitAdvertisementSerializerDTO} updateUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch(unitAdvertisementUuid: string, updateUnitAdvertisementSerializerDTO: UpdateUnitAdvertisementSerializerDTO, options?: any): AxiosPromise<UnitAdvertisementSerializerDTO> {
            return localVarFp.patchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch(unitAdvertisementUuid, updateUnitAdvertisementSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost(unitAdvertisementUuid: string, body: object, options?: any): AxiosPromise<UnitAdvertisementIntegrationResponseSerializerDTO> {
            return localVarFp.publishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost(unitAdvertisementUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {UpdateUnitAdvertisementSerializerDTO} updateUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUnitAdvertisementPromotionsUnitAdvertisementUuidPut(unitAdvertisementUuid: string, updateUnitAdvertisementSerializerDTO: UpdateUnitAdvertisementSerializerDTO, options?: any): AxiosPromise<UnitAdvertisementSerializerDTO> {
            return localVarFp.putUnitAdvertisementPromotionsUnitAdvertisementUuidPut(unitAdvertisementUuid, updateUnitAdvertisementSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unpublish Unit Advertisement
         * @param {string} unitAdvertisementUuid 
         * @param {UnpublishUnitAdvertisementSerializerDTO} unpublishUnitAdvertisementSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost(unitAdvertisementUuid: string, unpublishUnitAdvertisementSerializerDTO: UnpublishUnitAdvertisementSerializerDTO, options?: any): AxiosPromise<void> {
            return localVarFp.unpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost(unitAdvertisementUuid, unpublishUnitAdvertisementSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Unit Advertisement Attachments Sequence
         * @param {string} unitAdvertisementUuid 
         * @param {ListAttachmentSequenceSerializerDTO} listAttachmentSequenceSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut(unitAdvertisementUuid: string, listAttachmentSequenceSerializerDTO: ListAttachmentSequenceSerializerDTO, options?: any): AxiosPromise<ResponseAttachmentSequenceSerializerDTO> {
            return localVarFp.updateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut(unitAdvertisementUuid, listAttachmentSequenceSerializerDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPICreateUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPostRequest
 */
export interface AdvertisementsAPICreateUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPICreateUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost
     */
    readonly unitAdvertisementUuid: string

    /**
     * 
     * @type {CreateUnitAdvertisementAttachmentSerializerDTO}
     * @memberof AdvertisementsAPICreateUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost
     */
    readonly createUnitAdvertisementAttachmentSerializerDTO: CreateUnitAdvertisementAttachmentSerializerDTO

    /**
     * 
     * @type {number}
     * @memberof AdvertisementsAPICreateUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdvertisementsAPICreateUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPICreateUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost
     */
    readonly order?: string
}

/**
 * Request parameters for createUnitAdvertisementPromotionsPost operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPICreateUnitAdvertisementPromotionsPostRequest
 */
export interface AdvertisementsAPICreateUnitAdvertisementPromotionsPostRequest {
    /**
     * 
     * @type {CreateUnitAdvertisementSerializerDTO}
     * @memberof AdvertisementsAPICreateUnitAdvertisementPromotionsPost
     */
    readonly createUnitAdvertisementSerializerDTO: CreateUnitAdvertisementSerializerDTO
}

/**
 * Request parameters for deleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIDeleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDeleteRequest
 */
export interface AdvertisementsAPIDeleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIDeleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete
     */
    readonly unitAdvertisementUuid: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIDeleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for getSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGet operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIGetSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGetRequest
 */
export interface AdvertisementsAPIGetSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIGetSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGet
     */
    readonly unitAdvertisementUuid: string
}

/**
 * Request parameters for listIntegrationValidationRulesPromotionsIntegrationValidationRulesGet operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIListIntegrationValidationRulesPromotionsIntegrationValidationRulesGetRequest
 */
export interface AdvertisementsAPIListIntegrationValidationRulesPromotionsIntegrationValidationRulesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListIntegrationValidationRulesPromotionsIntegrationValidationRulesGet
     */
    readonly countryUuid: string
}

/**
 * Request parameters for listUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIListUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGetRequest
 */
export interface AdvertisementsAPIListUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet
     */
    readonly unitAdvertisementUuid: string

    /**
     * 
     * @type {number}
     * @memberof AdvertisementsAPIListUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdvertisementsAPIListUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet
     */
    readonly order?: string
}

/**
 * Request parameters for listUnitAdvertisementsPromotionsGet operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIListUnitAdvertisementsPromotionsGetRequest
 */
export interface AdvertisementsAPIListUnitAdvertisementsPromotionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly cityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly lettingAgentUuid?: string

    /**
     * 
     * @type {boolean}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly hasProspects?: boolean

    /**
     * 
     * @type {PublicationStatusEnumDTO}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly status?: PublicationStatusEnumDTO

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly availableDateFromLt?: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly availableDateFromGt?: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly publishedAtLt?: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIListUnitAdvertisementsPromotionsGet
     */
    readonly publishedAtGt?: string
}

/**
 * Request parameters for olxAuthorizePromotionsIntegrationsOlxAuthGet operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGetRequest
 */
export interface AdvertisementsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGet
     */
    readonly code: string

    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGet
     */
    readonly state: string
}

/**
 * Request parameters for olxWebhookPromotionsIntegrationsOlxWebhookPost operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIOlxWebhookPromotionsIntegrationsOlxWebhookPostRequest
 */
export interface AdvertisementsAPIOlxWebhookPromotionsIntegrationsOlxWebhookPostRequest {
    /**
     * 
     * @type {OlxWebhookSerializerDTO}
     * @memberof AdvertisementsAPIOlxWebhookPromotionsIntegrationsOlxWebhookPost
     */
    readonly olxWebhookSerializerDTO: OlxWebhookSerializerDTO
}

/**
 * Request parameters for patchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIPatchUnitAdvertisementPromotionsUnitAdvertisementUuidPatchRequest
 */
export interface AdvertisementsAPIPatchUnitAdvertisementPromotionsUnitAdvertisementUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIPatchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch
     */
    readonly unitAdvertisementUuid: string

    /**
     * 
     * @type {UpdateUnitAdvertisementSerializerDTO}
     * @memberof AdvertisementsAPIPatchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch
     */
    readonly updateUnitAdvertisementSerializerDTO: UpdateUnitAdvertisementSerializerDTO
}

/**
 * Request parameters for publishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIPublishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPostRequest
 */
export interface AdvertisementsAPIPublishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIPublishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost
     */
    readonly unitAdvertisementUuid: string

    /**
     * 
     * @type {object}
     * @memberof AdvertisementsAPIPublishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost
     */
    readonly body: object
}

/**
 * Request parameters for putUnitAdvertisementPromotionsUnitAdvertisementUuidPut operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIPutUnitAdvertisementPromotionsUnitAdvertisementUuidPutRequest
 */
export interface AdvertisementsAPIPutUnitAdvertisementPromotionsUnitAdvertisementUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIPutUnitAdvertisementPromotionsUnitAdvertisementUuidPut
     */
    readonly unitAdvertisementUuid: string

    /**
     * 
     * @type {UpdateUnitAdvertisementSerializerDTO}
     * @memberof AdvertisementsAPIPutUnitAdvertisementPromotionsUnitAdvertisementUuidPut
     */
    readonly updateUnitAdvertisementSerializerDTO: UpdateUnitAdvertisementSerializerDTO
}

/**
 * Request parameters for unpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIUnpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPostRequest
 */
export interface AdvertisementsAPIUnpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPostRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIUnpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost
     */
    readonly unitAdvertisementUuid: string

    /**
     * 
     * @type {UnpublishUnitAdvertisementSerializerDTO}
     * @memberof AdvertisementsAPIUnpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost
     */
    readonly unpublishUnitAdvertisementSerializerDTO: UnpublishUnitAdvertisementSerializerDTO
}

/**
 * Request parameters for updateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut operation in AdvertisementsAPI.
 * @export
 * @interface AdvertisementsAPIUpdateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPutRequest
 */
export interface AdvertisementsAPIUpdateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPutRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvertisementsAPIUpdateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut
     */
    readonly unitAdvertisementUuid: string

    /**
     * 
     * @type {ListAttachmentSequenceSerializerDTO}
     * @memberof AdvertisementsAPIUpdateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut
     */
    readonly listAttachmentSequenceSerializerDTO: ListAttachmentSequenceSerializerDTO
}

/**
 * AdvertisementsAPI - object-oriented interface
 * @export
 * @class AdvertisementsAPI
 * @extends {BaseAPI}
 */
export class AdvertisementsAPI extends BaseAPI {
    /**
     * 
     * @summary Create Unit Advertisement Attachments
     * @param {AdvertisementsAPICreateUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public createUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost(requestParameters: AdvertisementsAPICreateUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPostRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).createUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost(requestParameters.unitAdvertisementUuid, requestParameters.createUnitAdvertisementAttachmentSerializerDTO, requestParameters.page, requestParameters.pageSize, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Unit Advertisement
     * @param {AdvertisementsAPICreateUnitAdvertisementPromotionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public createUnitAdvertisementPromotionsPost(requestParameters: AdvertisementsAPICreateUnitAdvertisementPromotionsPostRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).createUnitAdvertisementPromotionsPost(requestParameters.createUnitAdvertisementSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Unit Advertisement Attachment
     * @param {AdvertisementsAPIDeleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public deleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete(requestParameters: AdvertisementsAPIDeleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDeleteRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).deleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete(requestParameters.unitAdvertisementUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Unit Advertisement
     * @param {AdvertisementsAPIGetSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public getSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGet(requestParameters: AdvertisementsAPIGetSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGetRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).getSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGet(requestParameters.unitAdvertisementUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Integration Validation Rules
     * @param {AdvertisementsAPIListIntegrationValidationRulesPromotionsIntegrationValidationRulesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public listIntegrationValidationRulesPromotionsIntegrationValidationRulesGet(requestParameters: AdvertisementsAPIListIntegrationValidationRulesPromotionsIntegrationValidationRulesGetRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).listIntegrationValidationRulesPromotionsIntegrationValidationRulesGet(requestParameters.countryUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Unit Advertisement Attachments
     * @param {AdvertisementsAPIListUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public listUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet(requestParameters: AdvertisementsAPIListUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGetRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).listUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet(requestParameters.unitAdvertisementUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Unit Advertisements
     * @param {AdvertisementsAPIListUnitAdvertisementsPromotionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public listUnitAdvertisementsPromotionsGet(requestParameters: AdvertisementsAPIListUnitAdvertisementsPromotionsGetRequest = {}, options?: any) {
        return AdvertisementsAPIFp(this.configuration).listUnitAdvertisementsPromotionsGet(requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.countryUuid, requestParameters.regionUuid, requestParameters.cityUuid, requestParameters.lettingAgentUuid, requestParameters.hasProspects, requestParameters.status, requestParameters.availableDateFromLt, requestParameters.availableDateFromGt, requestParameters.publishedAtLt, requestParameters.publishedAtGt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Olx Authorize
     * @param {AdvertisementsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public olxAuthorizePromotionsIntegrationsOlxAuthGet(requestParameters: AdvertisementsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGetRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).olxAuthorizePromotionsIntegrationsOlxAuthGet(requestParameters.code, requestParameters.state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Olx Trigger Authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options?: any) {
        return AdvertisementsAPIFp(this.configuration).olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Olx Webhook
     * @param {AdvertisementsAPIOlxWebhookPromotionsIntegrationsOlxWebhookPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public olxWebhookPromotionsIntegrationsOlxWebhookPost(requestParameters: AdvertisementsAPIOlxWebhookPromotionsIntegrationsOlxWebhookPostRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).olxWebhookPromotionsIntegrationsOlxWebhookPost(requestParameters.olxWebhookSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Unit Advertisement
     * @param {AdvertisementsAPIPatchUnitAdvertisementPromotionsUnitAdvertisementUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public patchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch(requestParameters: AdvertisementsAPIPatchUnitAdvertisementPromotionsUnitAdvertisementUuidPatchRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).patchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch(requestParameters.unitAdvertisementUuid, requestParameters.updateUnitAdvertisementSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish Unit Advertisement
     * @param {AdvertisementsAPIPublishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public publishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost(requestParameters: AdvertisementsAPIPublishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPostRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).publishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost(requestParameters.unitAdvertisementUuid, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Unit Advertisement
     * @param {AdvertisementsAPIPutUnitAdvertisementPromotionsUnitAdvertisementUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public putUnitAdvertisementPromotionsUnitAdvertisementUuidPut(requestParameters: AdvertisementsAPIPutUnitAdvertisementPromotionsUnitAdvertisementUuidPutRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).putUnitAdvertisementPromotionsUnitAdvertisementUuidPut(requestParameters.unitAdvertisementUuid, requestParameters.updateUnitAdvertisementSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unpublish Unit Advertisement
     * @param {AdvertisementsAPIUnpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public unpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost(requestParameters: AdvertisementsAPIUnpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPostRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).unpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost(requestParameters.unitAdvertisementUuid, requestParameters.unpublishUnitAdvertisementSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Unit Advertisement Attachments Sequence
     * @param {AdvertisementsAPIUpdateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvertisementsAPI
     */
    public updateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut(requestParameters: AdvertisementsAPIUpdateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPutRequest, options?: any) {
        return AdvertisementsAPIFp(this.configuration).updateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut(requestParameters.unitAdvertisementUuid, requestParameters.listAttachmentSequenceSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
