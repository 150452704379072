import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  footer: {
    mt: 2,
    textAlign: 'center',
  },
  notificationButton: {
    '&:disabled': {
      color: 'text.secondary',
    },
    opacity: '0.2',
  },
};
