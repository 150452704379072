import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../../theme';

export const sxProps: Record<string, SxProps<Theme>> = {
  clickableTableRow: {
    cursor: 'pointer',
  },
  highlighted: {
    backgroundColor: palette.seashellpeach,
  },
};
