import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { Dispatch, FC } from 'react';
import React, { useCallback, useRef } from 'react';

import { Scrollbar } from '../../Scrollbar/scrollbar.component';
import type { Image } from '../carousel.type';
import { sxProps } from './thumbnails.styles';

type Props = {
  activeIdx: number;
  images: Image[];
  setActiveIdx: Dispatch<number>;
};

export const Thumbnails: FC<Props> = (props) => {
  const { activeIdx, images, setActiveIdx } = props;
  const refs = useRef<HTMLDivElement[]>([]);
  const handleClick: BoxProps['onClick'] = useCallback(
    (event) => {
      const { idx } = event.currentTarget.dataset;

      if (!idx) return;

      event.stopPropagation();
      setActiveIdx(+idx);
      refs.current[idx].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    },
    [setActiveIdx],
  );

  return (
    <Box sx={sxProps.thumbnails}>
      <Scrollbar>
        <Grid container wrap="nowrap" spacing={3}>
          {images.map((image, idx) => {
            const { id, url } = image;
            const imageSxProps = {
              ...sxProps.image,
              ...(activeIdx === idx && sxProps.activeImage),
              backgroundImage: `url(${url})`,
            } as SxProps;

            return (
              <Grid item key={id}>
                <Box
                  data-idx={idx}
                  onClick={handleClick}
                  ref={(ref: HTMLDivElement) => {
                    refs.current[idx] = ref;
                  }}
                  sx={imageSxProps}
                />
              </Grid>
            );
          })}
        </Grid>
      </Scrollbar>
    </Box>
  );
};
