import type {
  ServiceRequestDetailSerializerDTO,
  ListAttachmentSerializerDTO,
} from '../../../../../../connectors/ticket';
import type { FieldConfig } from '../../../../../shared';
import type { RequestDetailsData, ServiceRequestValues } from '../../../types';
import { getFieldsetsConfig } from './details-step.model';

export * from './details-step.component';
export const getDetailsFieldsConfig = (
  isStepper: boolean,
  details?: RequestDetailsData<ServiceRequestDetailSerializerDTO>,
  attachments: ListAttachmentSerializerDTO['results'] = [],
): FieldConfig<ServiceRequestValues>[] =>
  getFieldsetsConfig(isStepper, details, attachments).reduce<
    FieldConfig<ServiceRequestValues>[]
  >((acc, config) => [...acc, ...config.fields], []);
