import { ReactComponent as BuildingModernIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/buildings/building-modern.svg';
import { ReactComponent as CarGarage1Icon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/garage-parking/car-garage.svg';
import { ReactComponent as RealEstateDimensionsBlockIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import Grid from '@mui/material/Grid';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { PropertySerializerDTO } from '../../../../../connectors/property';
import {
  CollectionViewMode,
  Feature,
  getDisplayValue,
  Scrollbar,
  useTranslation,
} from '../../../../shared';

type Props = {
  data: PropertySerializerDTO;
  viewMode?: CollectionViewMode;
};

const GRID_SPACING_DEFAULT = 3;
const GRID_SPACING_LIST = [2, 4, 4, 6];

export const FeaturesList: FC<Props> = (props) => {
  const { data, viewMode } = props;
  const { t } = useTranslation();
  const gridSpacing = useMemo(
    () =>
      viewMode === CollectionViewMode.LIST
        ? GRID_SPACING_LIST
        : GRID_SPACING_DEFAULT,
    [viewMode],
  );
  // TODO: add new features after: https://fredensborg.atlassian.net/browse/MB-193
  const features = useMemo(
    () => [
      {
        dataTestId: 'area-square-meter',
        icon: <RealEstateDimensionsBlockIcon />,
        id: 'area',
        titleKey: 'property.fields.area',
        value: t('metricUnit.squareMetre', {
          value: getDisplayValue(data.area).value,
        }),
      },
      {
        dataTestId: 'building-count',
        icon: <BuildingModernIcon />,
        id: 'buildingCount',
        titleKey: 'property.fields.buildingCount',
        value: getDisplayValue(data.buildingCount).value,
      },
      {
        dataTestId: 'ground-area-count',
        icon: <CarGarage1Icon />,
        id: 'groundAreaCount',
        titleKey: 'property.fields.groundAreaCount',
        value: getDisplayValue(data.groundAreaCount).value,
      },
    ],
    [data.area, data.buildingCount, data.groundAreaCount, t],
  );

  if (viewMode === CollectionViewMode.MAP) {
    return null;
  }

  return (
    <Scrollbar>
      <Grid container spacing={gridSpacing} wrap="nowrap">
        {features.map(({ id, ...feature }) => (
          <Grid key={`feature-${viewMode}-${id}`} item>
            <Feature
              {...feature}
              size={viewMode === CollectionViewMode.GRID ? 'small' : 'normal'}
            />
          </Grid>
        ))}
      </Grid>
    </Scrollbar>
  );
};
