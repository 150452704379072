import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  emptyContentContainer: {
    m: 'auto',
    p: '40px 18px 58px',
    width: '100%',
  },
};
