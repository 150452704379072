import Grid from '@mui/material/Grid';
import type { SxProps } from '@mui/system/styleFunctionSx';
import omit from 'lodash-es/omit';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { getStatusColorsHex } from '../../../../../+ticket/shared';
import { TicketStatusEnumDTO } from '../../../../../../connectors/ticket';
import { DoughnutChart } from '../../../charts';
import { Legend } from '../Legend/legend.component';
import { sxProps } from './chart.styles';

type Props = {
  counts: Record<TicketStatusEnumDTO | 'total', number>;
};

export const Chart: FC<Props> = (props) => {
  const { counts } = props;
  const countObject = useMemo(
    () => omit(counts, [TicketStatusEnumDTO.Done]),
    [counts],
  );

  return (
    <Grid item sx={sxProps.body} xs={12}>
      <Grid container spacing={1}>
        <Grid
          item
          lg={7}
          sx={{ ...sxProps.column, ...sxProps.chartColumn } as SxProps}
          xs={12}
        >
          <DoughnutChart
            countLabelKey="dashboard.widgets.ticketStatusChart.count"
            countObject={countObject}
            getStatusColorsHex={getStatusColorsHex}
            percentLabelKey="dashboard.widgets.ticketStatusChart.percent"
          />
        </Grid>
        <Grid item lg={5} sx={sxProps.column} xs={12}>
          <Legend countObject={countObject} />
        </Grid>
      </Grid>
    </Grid>
  );
};
