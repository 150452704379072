import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import React, { useCallback, useMemo } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';

import { useTranslation } from '../../../translations';
import type { ListState, Tab as ITab } from '../list.type';
import { sxProps } from './tabs.styles';

type Props<TFilterValues> = {
  enableDefaultTab: boolean;
  listState: ListState<TFilterValues>;
  setListState: Dispatch<SetStateAction<ListState<TFilterValues>>>;
  tabs: ITab[];
};

export const Tabs = <TFiltersValues,>(
  props: Props<TFiltersValues>,
): ReturnType<FC<Props<TFiltersValues>>> => {
  const { enableDefaultTab, listState, setListState, tabs } = props;
  const { t } = useTranslation();
  const currentTab = useMemo(
    () => listState.currentTab ?? '',
    [listState.currentTab],
  );
  const handleTabsChange = useCallback(
    (_, currentTab: ITab['value']): void => {
      setListState((prevState) => {
        return {
          ...prevState,
          currentTab: currentTab || undefined,
          paginationConfig: {
            ...prevState.paginationConfig,
            currentPage: 1,
          },
        };
      });
    },
    [setListState],
  );

  return (
    <>
      <MuiTabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {enableDefaultTab && (
          <Tab label={t('list.defaultTab')} sx={sxProps.tab} value="" />
        )}
        {tabs.map((tab) => (
          <Tab
            key={`tab-${tab.value}`}
            label={t(tab.labelKey)}
            sx={sxProps.tab}
            value={tab.value}
          />
        ))}
      </MuiTabs>
      <Divider />
    </>
  );
};
