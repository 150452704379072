import isEqual from 'lodash-es/isEqual';
import isFunction from 'lodash-es/isFunction';

import type { AdvancedRadioValue } from '../fields';
import {
  DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  DEFAULT_FIELD_VALUE,
} from '../form.const';
import { isFieldsetConfig } from '../guards';
import type {
  AdvancedRadioFieldConfig,
  FieldConfig,
  FormProps,
} from '../types';

const getChangedFieldNames = <TFormValues>(
  newValues: TFormValues,
  oldValues: TFormValues,
): (keyof TFormValues)[] =>
  Object.entries(newValues).reduce<(keyof TFormValues)[]>(
    (acc, [key, newValue]) => {
      const fieldName = key as keyof TFormValues;
      const oldValue = oldValues[fieldName];

      return !isEqual(newValue, oldValue) ? [...acc, fieldName] : acc;
    },
    [],
  );

const getFieldConfigFromAdvancedRadioField = <TFormValues>(
  fieldName: keyof TFormValues,
  config: AdvancedRadioFieldConfig<TFormValues>,
  values: TFormValues,
): FieldConfig<TFormValues> | undefined => {
  const { options: configOptions } = config.props;
  const options = isFunction(configOptions)
    ? configOptions(values)
    : configOptions;
  const option = options.find((option) =>
    option.nestedFieldsConfig.some(
      (fieldConfig) => fieldConfig.field.name === fieldName,
    ),
  );

  return option?.nestedFieldsConfig.find(
    (fieldConfig) => fieldConfig.field.name === fieldName,
  );
};

const getFieldConfig = <TFormValues>(
  fieldName: keyof TFormValues,
  fieldsConfig: FormProps<TFormValues>['fieldsConfig'],
  values: TFormValues,
  foundFieldConfig: FieldConfig<TFormValues> | undefined = undefined,
): FieldConfig<TFormValues> | undefined => {
  let result = foundFieldConfig;

  fieldsConfig.forEach((config) => {
    if (isFieldsetConfig(config)) {
      result = getFieldConfig(fieldName, config.fields, values, result);
    } else {
      if (!result && config.field.name === fieldName) {
        result = config;
      }

      if (
        !result &&
        config.componentType === 'async-autocomplete' &&
        config.props.nestedFieldsConfig
      ) {
        result = getFieldConfig(
          fieldName,
          config.props.nestedFieldsConfig.fields,
          values,
          result,
        );
      }

      if (!result && config.componentType === 'advanced-radio') {
        result = getFieldConfigFromAdvancedRadioField(
          fieldName,
          config,
          values,
        );
      }
    }
  });

  return result;
};

const getRelatedAndNestedFieldsConfig = <TFormValues>(
  parentFieldsConfig: FormProps<TFormValues>['fieldsConfig'],
  values: TFormValues,
  config?: FieldConfig<TFormValues>,
): FieldConfig<TFormValues>[] => {
  if (config?.componentType === 'advanced-radio') {
    const { options: configOptions } = config.props;
    const options = isFunction(configOptions)
      ? configOptions(values)
      : configOptions;
    const nestedFieldsConfig =
      options.find(
        (option) =>
          option.value ===
          (values[config.field.name] as unknown as AdvancedRadioValue),
      )?.nestedFieldsConfig || [];

    return nestedFieldsConfig.filter((fieldConfig) => {
      const isFieldExcluded =
        config.props.getExcludedFieldsFromResetting &&
        config.props
          .getExcludedFieldsFromResetting(values)
          .includes(fieldConfig.field.name);

      return !isFieldExcluded;
    });
  }
  const relatedFields = config?.props.relatedFields || [];

  return (isFunction(relatedFields) ? relatedFields(values) : relatedFields)
    .map((relatedFieldName) =>
      getFieldConfig(relatedFieldName, parentFieldsConfig, values),
    )
    .filter((config) => config !== undefined) as FieldConfig<TFormValues>[];
};

const getRelatedFieldsConfig = <TFormValues>(
  changedFieldNames: (keyof TFormValues)[],
  fieldsConfig: FormProps<TFormValues>['fieldsConfig'],
  values: TFormValues,
): FieldConfig<TFormValues>[] => {
  const relatedFieldsConfig = changedFieldNames.reduce<
    FieldConfig<TFormValues>[]
  >((acc, changedFieldName) => {
    const config = getFieldConfig(changedFieldName, fieldsConfig, values);
    const relatedFieldsConfig = getRelatedAndNestedFieldsConfig(
      fieldsConfig,
      values,
      config,
    );

    return relatedFieldsConfig ? [...acc, ...relatedFieldsConfig] : acc;
  }, []);

  return [...new Set(relatedFieldsConfig)];
};

export const getResetRelatedFieldsPatch = <TFormValues>(
  fieldsConfig: FormProps<TFormValues>['fieldsConfig'],
  newValues: TFormValues,
  oldValues: TFormValues,
): Partial<TFormValues> => {
  const changedFieldNames = getChangedFieldNames<TFormValues>(
    newValues,
    oldValues,
  );
  const relatedFieldsConfig = getRelatedFieldsConfig(
    changedFieldNames,
    fieldsConfig,
    oldValues,
  );

  return relatedFieldsConfig.reduce<Partial<TFormValues>>(
    (acc, fieldConfig) => {
      const factoryValue =
        fieldConfig?.componentType === 'async-autocomplete'
          ? DEFAULT_ASYNC_AUTOCOMPLETE_VALUE
          : DEFAULT_FIELD_VALUE;

      return {
        ...acc,
        [fieldConfig.field.name]: factoryValue,
      };
    },
    {},
  );
};
