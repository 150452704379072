import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<'button' | 'toolbar', SxProps<Theme>> = {
  button: {
    '& + &': {
      ml: 1,
    },
  },
  toolbar: {
    mb: 2.5,
  },
};
