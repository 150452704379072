/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum UnitCategoryEnumDTO {
    ResidentialHomes = 'residential_homes',
    ResidentialParking = 'residential_parking',
    Advertising = 'advertising',
    Antenna = 'antenna',
    Storage = 'storage',
    CommonArea = 'common_area',
    RetailAndServices = 'retail_and_services',
    HospitalityAndRecreation = 'hospitality_and_recreation',
    Office = 'office',
    PublicServices = 'public_services',
    Warehouse = 'warehouse',
    Other = 'other'
}



