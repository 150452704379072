import type { DragEvent, TouchEvent } from 'react';

const DYNAMIC_STYLE_PROPERTIES = [
  'left',
  'position',
  'top',
  'width',
  'z-index',
];

const SCROLL_THRESHOLD = 100;

export const isDragEvent = (
  event: DragEvent | TouchEvent,
): event is DragEvent =>
  [
    'drag',
    'dragend',
    'dragenter',
    'dragleave',
    'dragover',
    'dragstart',
    'drop',
  ].includes(event.type);

export const findDropColumnIndex = (
  columnElements: (HTMLDivElement | null)[],
  dragEvent: DragEvent,
): number =>
  columnElements.findIndex((column) => {
    if (!column) return false;

    const { pageX: x, pageY: y } = dragEvent;
    const { top, bottom, left, right } = column.getBoundingClientRect();

    return x >= left && x <= right && y >= top && y <= bottom;
  });

export const findTouchDropColumnIndex = (
  columnElements: (HTMLDivElement | null)[],
  touchDropPosition: DOMRect,
): number =>
  columnElements.findIndex((column) => {
    if (!column) return false;

    const { x, y } = touchDropPosition;
    const { top, bottom, left, right } = column.getBoundingClientRect();

    return x >= left && x <= right && y >= top && y <= bottom;
  });

export const handleHorizontalScroll = (
  containerElement: HTMLDivElement | null,
  event: DragEvent | TouchEvent,
  scrollbarElement: HTMLElement | null,
): void => {
  if (!containerElement || !scrollbarElement) return;

  const {
    left: containerLeft,
    right: containerRight,
    width: containerWidth,
  } = containerElement.getBoundingClientRect();
  const pageX = isDragEvent(event)
    ? event.pageX
    : event.changedTouches[0].pageX;
  const { scrollLeft, scrollWidth } = scrollbarElement;

  if (pageX - containerLeft < SCROLL_THRESHOLD && scrollLeft > 0) {
    const positionLeft = scrollLeft - SCROLL_THRESHOLD;

    scrollbarElement.scrollTo({
      behavior: 'smooth',
      left: positionLeft > 0 ? positionLeft : 0,
    });
  } else if (
    containerRight - pageX < SCROLL_THRESHOLD &&
    scrollWidth - scrollLeft > containerWidth
  ) {
    scrollbarElement.scrollTo({
      behavior: 'smooth',
      left: scrollLeft + SCROLL_THRESHOLD,
    });
  }
};

export const handleVerticalScroll = (
  containerElement: HTMLDivElement | null,
  event: DragEvent | TouchEvent,
  scrollbarElement: HTMLElement | null,
): void => {
  if (!containerElement || !scrollbarElement) return;

  const {
    top: containerTop,
    bottom: containerBottom,
    height: containerHeight,
  } = containerElement.getBoundingClientRect();
  const pageY = isDragEvent(event)
    ? event.pageY
    : event.changedTouches[0].pageY;
  const { scrollTop, scrollHeight } = scrollbarElement;

  if (pageY - containerTop < SCROLL_THRESHOLD && scrollTop > 0) {
    const positionTop = scrollTop - SCROLL_THRESHOLD;

    scrollbarElement.scrollTo({
      behavior: 'smooth',
      top: positionTop > 0 ? positionTop : 0,
    });
  } else if (
    containerBottom - pageY < SCROLL_THRESHOLD &&
    scrollHeight - scrollTop > containerHeight
  ) {
    scrollbarElement.scrollTo({
      behavior: 'smooth',
      top: scrollTop + SCROLL_THRESHOLD,
    });
  }
};

export const removeDragStyles = (element: HTMLElement): void =>
  DYNAMIC_STYLE_PROPERTIES.forEach((property) =>
    element.style.setProperty(property, null),
  );

export const setDragStyles = (
  cardPadding: string,
  columnElement: HTMLElement | null,
  element: HTMLElement,
  pageX: number,
  pageY: number,
): void => {
  if (pageX === 0 && pageY === 0) return;

  const columnWidth = columnElement?.getBoundingClientRect().width;

  element.style.setProperty('left', `${pageX}px`);
  element.style.setProperty('position', 'fixed');
  element.style.setProperty('top', `${pageY}px`);
  element.style.setProperty(
    'width',
    columnWidth ? `calc(${columnWidth}px - ${cardPadding})` : 'auto',
  );
  element.style.setProperty('z-index', '2');
};
