import { ReactComponent as BinIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/delete/bin.svg';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { FC, ReactNode } from 'react';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import { useTranslation } from '../../translations';
import { Dialog } from '../Dialog/dialog.component';

type Props = {
  callback$: () => Promise<void>;
  children?: (handleOpen: ButtonProps['onClick']) => ReactNode;
  confirmationTextKey?: GenericTypes.TranslationLabel;
  confirmButtonTitleKey?: GenericTypes.TranslationLabel;
  declineButtonTitleKey?: GenericTypes.TranslationLabel;
  Icon?: ReactNode;
  isIconButton?: boolean;
};

export const ConfirmationDelete: FC<Props> = (props) => {
  const {
    callback$,
    children,
    confirmationTextKey,
    confirmButtonTitleKey,
    declineButtonTitleKey,
    Icon,
    isIconButton = true,
  } = props;
  const { t } = useTranslation();
  const [isFetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen: ButtonProps['onClick'] = (event): void => {
    event.stopPropagation();
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };
  const handleConfirm = async (): Promise<void> => {
    try {
      setFetching(true);
      await callback$();
      toast.success(t('dialog.confirmation.success'));
    } catch (e) {
      toast.error(t('errors.general.message'));
    } finally {
      setFetching(false);
    }

    handleClose();
  };
  const buttonTemplate = useMemo(() => {
    if (children) return children(handleOpen);

    return isIconButton ? (
      <IconButton
        color="error"
        data-testid="confirmation-delete-button"
        onClick={handleOpen}
      >
        {Icon || <BinIcon height={16} width={16} />}
      </IconButton>
    ) : (
      <Button
        color="error"
        onClick={handleOpen}
        size="small"
        variant="outlined"
      >
        {t('dialog.confirmation.button')}
      </Button>
    );
  }, [Icon, children, isIconButton, t]);

  return (
    <>
      {buttonTemplate}
      <Dialog
        confirmButtonTitleKey={confirmButtonTitleKey}
        declineButtonTitleKey={declineButtonTitleKey}
        isFetching={isFetching}
        isOpen={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        titleKey="dialog.confirmation.title"
      >
        <DialogContentText>
          <Typography component="span">
            {t(confirmationTextKey || 'dialog.confirmation.message')}
          </Typography>
        </DialogContentText>
      </Dialog>
    </>
  );
};
