import { ReactComponent as SearchIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/search/search.svg';
import MuiChip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

import { useTranslation } from '../../../translations';
import type { ListStateCommonProps } from '../collection.type';
import { sxProps } from './search.styles';

type Props<TFilterValues> = ListStateCommonProps<TFilterValues>;

export const Search = <TFilterValues,>(
  props: Props<TFilterValues>,
): ReturnType<FC<Props<TFilterValues>>> => {
  const { listState, setListState } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const searchEntries = useMemo(
    () => listState.searchQuery?.split(',') || [],
    [listState.searchQuery],
  );
  const updateSearchQuery = useCallback(
    (searchQuery: ListStateCommonProps['listState']['searchQuery']) => {
      setListState((prevState) => ({
        ...prevState,
        paginationConfig: {
          ...prevState.paginationConfig,
          currentPage: 1,
        },
        searchQuery,
      }));
    },
    [setListState],
  );
  const addSearchEntry = useCallback(() => {
    if (!inputValue) return;

    const trimmedValue = inputValue.trim();
    const isEntryUnique = searchEntries.every(
      (entry) => entry !== trimmedValue,
    );

    if (!isEntryUnique) return;

    const searchQuery = [...searchEntries, trimmedValue].toString();

    setInputValue('');
    updateSearchQuery(searchQuery);
  }, [inputValue, searchEntries, updateSearchQuery]);
  const removeSearchEntry = useCallback(
    (searchEntry: string) => {
      const entries = searchEntries.filter((entry) => entry !== searchEntry);
      const searchQuery = entries.toString() || undefined;

      updateSearchQuery(searchQuery);
    },
    [searchEntries, updateSearchQuery],
  );
  const handleKeyDown: TextFieldProps['onKeyDown'] = useCallback(
    ({ key }) => {
      if (key === 'Enter') {
        addSearchEntry();
      }
    },
    [addSearchEntry],
  );
  const handleQueryChange: TextFieldProps['onChange'] = useCallback(
    (event): void => {
      const { value } = event.target;

      setInputValue(value);
    },
    [],
  );

  return (
    <Grid container>
      <Grid item sx={sxProps.textFieldRow} xs={12}>
        <TextField
          autoComplete="off"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={sxProps.inputAdornment}>
                <IconButton onClick={addSearchEntry} sx={sxProps.searchIcon}>
                  <SearchIcon height={16} width={16} />
                </IconButton>
              </InputAdornment>
            ),
            sx: sxProps.input,
          }}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
          placeholder={t('list.searchPlaceholder')}
          sx={sxProps.textField}
          value={inputValue}
          variant="outlined"
        />
      </Grid>
      {searchEntries.length > 0 && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item sx={sxProps.entriesRow} xs={12}>
            <Grid container spacing={1.5}>
              {searchEntries.map((searchEntry) => (
                <Grid key={`chip-${searchEntry}`} item>
                  <MuiChip
                    label={searchEntry}
                    onDelete={() => removeSearchEntry(searchEntry)}
                    variant="outlined"
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
