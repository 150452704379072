import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { getStatusColorsHex } from '../../../../+ticket/shared';
import { TicketStatusEnumDTO } from '../../../../../connectors/ticket';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    p: 2,
  },
  [TicketStatusEnumDTO.Open]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.Open),
  },
  [TicketStatusEnumDTO.InProgress]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.InProgress),
  },
  [TicketStatusEnumDTO.AwaitingTenant]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        TicketStatusEnumDTO.AwaitingTenant,
      ),
  },
  [TicketStatusEnumDTO.AwaitingExternalProvider]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        TicketStatusEnumDTO.AwaitingExternalProvider,
      ),
  },
  [TicketStatusEnumDTO.OnHold]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.OnHold),
  },
  [TicketStatusEnumDTO.Done]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.Done),
  },
};
