import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  avatar: {
    height: 24,
    mr: 1,
    width: 24,
  },
  filterItem: {
    fontSize: (theme) => theme.typography.pxToRem(13),
    fontWeight: (theme) => theme.typography.fontWeightBold,
    p: (theme) => `${theme.spacing(1)} !important`,
  },
};
