import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import { AttachmentCategoryEnumDTO } from '../../../../../../connectors/ticket';
import type { ListAttachmentSerializerDTO } from '../../../../../../connectors/ticket';
import type { FormProps } from '../../../../../shared';
import {
  Form,
  proceedAttachmentsUpdate$,
  useAsync,
} from '../../../../../shared';
import { ticketClient } from '../../../../ticket.client';
import { getAttachmentUpdateProcedureCallbacks } from '../../../helpers';
import { getFields } from './form-model.const';
import type { Values } from './form-model.const';

type Props = {
  setEditMode: Dispatch<SetStateAction<boolean>>;
};

export const ImagesUploader: FC<Props> = (props) => {
  const { setEditMode } = props;
  const { id } = useParams();
  const { response, setResponse } = useAsync<ListAttachmentSerializerDTO>();
  const existingAttachments = useMemo(
    () => response.results,
    [response.results],
  );
  const attachmentCallbacks = useMemo(
    () => getAttachmentUpdateProcedureCallbacks(id),
    [id],
  );
  const fieldsConfig = useMemo(
    () => getFields(existingAttachments),
    [existingAttachments],
  );
  const config = useMemo<FormProps<Values>['config']>(
    () => ({
      onSubmit: async (values) => {
        await proceedAttachmentsUpdate$(
          { allAttachments: values.images, existingAttachments },
          attachmentCallbacks,
        );

        const images = await ticketClient.getAttachments$(
          id,
          AttachmentCategoryEnumDTO.Image,
        );

        setResponse(images);
        setEditMode(false);
      },
    }),
    [attachmentCallbacks, existingAttachments, id, setEditMode, setResponse],
  );

  return (
    <Form<Values>
      config={config}
      fieldsConfig={fieldsConfig}
      handleSuccess={() => null}
      submitLabelKey="attachment.buttons.upload"
      {...(existingAttachments.length > 0 && {
        onCancel: () => setEditMode(false),
      })}
    />
  );
};
