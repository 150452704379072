import { userClient } from '../../../../+user';
import { PublicationStatusEnumDTO } from '../../../../../connectors/property';
import { UserRoleEnumDTO } from '../../../../../connectors/user';
import type { FieldConfig } from '../../../../shared';
import { Country } from '../../../../shared';
import {
  DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  DEFAULT_CHECKBOX_VALUE,
  DEFAULT_SELECT_OPTION,
} from '../../../../shared/form/form.const';
import type { FilterValues } from './filter-panel.type';

export const INITIAL_SORT_VALUE = '-prospect_count';

export const INITIAL_VALUES: FilterValues = {
  hasProspects: DEFAULT_CHECKBOX_VALUE,
  lettingAgent: DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  sort: INITIAL_SORT_VALUE,
  status: DEFAULT_SELECT_OPTION.value,
};

export const getFieldsConfig = (
  filters?: FilterValues,
): FieldConfig<FilterValues>[] => [
  {
    componentType: 'select',
    field: {
      initialValue: filters?.sort || INITIAL_VALUES.sort,
      name: 'sort',
    },
    props: {
      isFilter: true,
      labelKey: 'collection.sortByLabel',
      options: [
        {
          labelKey: 'advertisement.list.sort.prospect.desc',
          value: '-prospect_count',
        },
        {
          labelKey: 'advertisement.list.sort.prospect.asc',
          value: 'prospect_count',
        },
        {
          labelKey: 'collection.sortByCreatedDate.desc',
          value: '-created_at',
        },
        {
          labelKey: 'collection.sortByCreatedDate.asc',
          value: 'created_at',
        },
      ],
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.status || INITIAL_VALUES.status,
      name: 'status',
    },
    props: {
      isFilter: true,
      labelKey: 'advertisement.list.status',
      options: [
        DEFAULT_SELECT_OPTION,
        {
          labelKey: 'advertisement.status.online',
          value: PublicationStatusEnumDTO.Published,
        },
        {
          labelKey: 'advertisement.status.offline',
          value: PublicationStatusEnumDTO.Draft,
        },
      ],
    },
  },
  {
    componentType: 'async-autocomplete',
    field: {
      initialValue: filters?.lettingAgent || INITIAL_VALUES.lettingAgent,
      name: 'lettingAgent',
    },
    props: {
      callback$: (query) =>
        userClient.getEmployeeSuggestions$(query, Country.PL, undefined, [
          UserRoleEnumDTO.LettingAgent,
        ]),
      isFilter: true,
      labelKey: 'advertisement.list.lettingAgent',
    },
  },
  {
    componentType: 'checkbox',
    field: {
      initialValue: filters?.hasProspects || INITIAL_VALUES.hasProspects,
      name: 'hasProspects',
    },
    props: {
      isFilter: true,
      labelKey: '',
      options: [{ labelKey: 'advertisement.list.hasProspects', name: 'false' }],
    },
  },
];
