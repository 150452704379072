/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ComplaintDetailSerializerDTO } from '../dto';
// @ts-ignore
import { ComplaintSerializerDTO } from '../dto';
// @ts-ignore
import { CreateComplaintSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { PatchComplaintSerializerDTO } from '../dto';
/**
 * ComplaintsAPI - axios parameter creator
 * @export
 */
export const ComplaintsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Complaint
         * @param {CreateComplaintSerializerDTO} createComplaintSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComplaintComplaintsPost: async (createComplaintSerializerDTO: CreateComplaintSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createComplaintSerializerDTO' is not null or undefined
            assertParamExists('createComplaintComplaintsPost', 'createComplaintSerializerDTO', createComplaintSerializerDTO)
            const localVarPath = `/complaints/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createComplaintSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Complaint Details
         * @param {string} complaintUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplaintDetailsComplaintsComplaintUuidGet: async (complaintUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complaintUuid' is not null or undefined
            assertParamExists('getComplaintDetailsComplaintsComplaintUuidGet', 'complaintUuid', complaintUuid)
            const localVarPath = `/complaints/{complaint_uuid}`
                .replace(`{${"complaint_uuid"}}`, encodeURIComponent(String(complaintUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Complaint
         * @param {string} complaintUuid 
         * @param {PatchComplaintSerializerDTO} patchComplaintSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchComplaintComplaintsComplaintUuidPatch: async (complaintUuid: string, patchComplaintSerializerDTO: PatchComplaintSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complaintUuid' is not null or undefined
            assertParamExists('patchComplaintComplaintsComplaintUuidPatch', 'complaintUuid', complaintUuid)
            // verify required parameter 'patchComplaintSerializerDTO' is not null or undefined
            assertParamExists('patchComplaintComplaintsComplaintUuidPatch', 'patchComplaintSerializerDTO', patchComplaintSerializerDTO)
            const localVarPath = `/complaints/{complaint_uuid}`
                .replace(`{${"complaint_uuid"}}`, encodeURIComponent(String(complaintUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchComplaintSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Complaint
         * @param {string} complaintUuid 
         * @param {CreateComplaintSerializerDTO} createComplaintSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putComplaintComplaintsComplaintUuidPut: async (complaintUuid: string, createComplaintSerializerDTO: CreateComplaintSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complaintUuid' is not null or undefined
            assertParamExists('putComplaintComplaintsComplaintUuidPut', 'complaintUuid', complaintUuid)
            // verify required parameter 'createComplaintSerializerDTO' is not null or undefined
            assertParamExists('putComplaintComplaintsComplaintUuidPut', 'createComplaintSerializerDTO', createComplaintSerializerDTO)
            const localVarPath = `/complaints/{complaint_uuid}`
                .replace(`{${"complaint_uuid"}}`, encodeURIComponent(String(complaintUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createComplaintSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComplaintsAPI - functional programming interface
 * @export
 */
export const ComplaintsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComplaintsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Complaint
         * @param {CreateComplaintSerializerDTO} createComplaintSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComplaintComplaintsPost(createComplaintSerializerDTO: CreateComplaintSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplaintSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComplaintComplaintsPost(createComplaintSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Complaint Details
         * @param {string} complaintUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComplaintDetailsComplaintsComplaintUuidGet(complaintUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplaintDetailSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComplaintDetailsComplaintsComplaintUuidGet(complaintUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Complaint
         * @param {string} complaintUuid 
         * @param {PatchComplaintSerializerDTO} patchComplaintSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchComplaintComplaintsComplaintUuidPatch(complaintUuid: string, patchComplaintSerializerDTO: PatchComplaintSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplaintSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchComplaintComplaintsComplaintUuidPatch(complaintUuid, patchComplaintSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Complaint
         * @param {string} complaintUuid 
         * @param {CreateComplaintSerializerDTO} createComplaintSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putComplaintComplaintsComplaintUuidPut(complaintUuid: string, createComplaintSerializerDTO: CreateComplaintSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplaintSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putComplaintComplaintsComplaintUuidPut(complaintUuid, createComplaintSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComplaintsAPI - factory interface
 * @export
 */
export const ComplaintsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComplaintsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Create Complaint
         * @param {CreateComplaintSerializerDTO} createComplaintSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComplaintComplaintsPost(createComplaintSerializerDTO: CreateComplaintSerializerDTO, options?: any): AxiosPromise<ComplaintSerializerDTO> {
            return localVarFp.createComplaintComplaintsPost(createComplaintSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Complaint Details
         * @param {string} complaintUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplaintDetailsComplaintsComplaintUuidGet(complaintUuid: string, options?: any): AxiosPromise<ComplaintDetailSerializerDTO> {
            return localVarFp.getComplaintDetailsComplaintsComplaintUuidGet(complaintUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Complaint
         * @param {string} complaintUuid 
         * @param {PatchComplaintSerializerDTO} patchComplaintSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchComplaintComplaintsComplaintUuidPatch(complaintUuid: string, patchComplaintSerializerDTO: PatchComplaintSerializerDTO, options?: any): AxiosPromise<ComplaintSerializerDTO> {
            return localVarFp.patchComplaintComplaintsComplaintUuidPatch(complaintUuid, patchComplaintSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Complaint
         * @param {string} complaintUuid 
         * @param {CreateComplaintSerializerDTO} createComplaintSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putComplaintComplaintsComplaintUuidPut(complaintUuid: string, createComplaintSerializerDTO: CreateComplaintSerializerDTO, options?: any): AxiosPromise<ComplaintSerializerDTO> {
            return localVarFp.putComplaintComplaintsComplaintUuidPut(complaintUuid, createComplaintSerializerDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createComplaintComplaintsPost operation in ComplaintsAPI.
 * @export
 * @interface ComplaintsAPICreateComplaintComplaintsPostRequest
 */
export interface ComplaintsAPICreateComplaintComplaintsPostRequest {
    /**
     * 
     * @type {CreateComplaintSerializerDTO}
     * @memberof ComplaintsAPICreateComplaintComplaintsPost
     */
    readonly createComplaintSerializerDTO: CreateComplaintSerializerDTO
}

/**
 * Request parameters for getComplaintDetailsComplaintsComplaintUuidGet operation in ComplaintsAPI.
 * @export
 * @interface ComplaintsAPIGetComplaintDetailsComplaintsComplaintUuidGetRequest
 */
export interface ComplaintsAPIGetComplaintDetailsComplaintsComplaintUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ComplaintsAPIGetComplaintDetailsComplaintsComplaintUuidGet
     */
    readonly complaintUuid: string
}

/**
 * Request parameters for patchComplaintComplaintsComplaintUuidPatch operation in ComplaintsAPI.
 * @export
 * @interface ComplaintsAPIPatchComplaintComplaintsComplaintUuidPatchRequest
 */
export interface ComplaintsAPIPatchComplaintComplaintsComplaintUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ComplaintsAPIPatchComplaintComplaintsComplaintUuidPatch
     */
    readonly complaintUuid: string

    /**
     * 
     * @type {PatchComplaintSerializerDTO}
     * @memberof ComplaintsAPIPatchComplaintComplaintsComplaintUuidPatch
     */
    readonly patchComplaintSerializerDTO: PatchComplaintSerializerDTO
}

/**
 * Request parameters for putComplaintComplaintsComplaintUuidPut operation in ComplaintsAPI.
 * @export
 * @interface ComplaintsAPIPutComplaintComplaintsComplaintUuidPutRequest
 */
export interface ComplaintsAPIPutComplaintComplaintsComplaintUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ComplaintsAPIPutComplaintComplaintsComplaintUuidPut
     */
    readonly complaintUuid: string

    /**
     * 
     * @type {CreateComplaintSerializerDTO}
     * @memberof ComplaintsAPIPutComplaintComplaintsComplaintUuidPut
     */
    readonly createComplaintSerializerDTO: CreateComplaintSerializerDTO
}

/**
 * ComplaintsAPI - object-oriented interface
 * @export
 * @class ComplaintsAPI
 * @extends {BaseAPI}
 */
export class ComplaintsAPI extends BaseAPI {
    /**
     * 
     * @summary Create Complaint
     * @param {ComplaintsAPICreateComplaintComplaintsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplaintsAPI
     */
    public createComplaintComplaintsPost(requestParameters: ComplaintsAPICreateComplaintComplaintsPostRequest, options?: any) {
        return ComplaintsAPIFp(this.configuration).createComplaintComplaintsPost(requestParameters.createComplaintSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Complaint Details
     * @param {ComplaintsAPIGetComplaintDetailsComplaintsComplaintUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplaintsAPI
     */
    public getComplaintDetailsComplaintsComplaintUuidGet(requestParameters: ComplaintsAPIGetComplaintDetailsComplaintsComplaintUuidGetRequest, options?: any) {
        return ComplaintsAPIFp(this.configuration).getComplaintDetailsComplaintsComplaintUuidGet(requestParameters.complaintUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Complaint
     * @param {ComplaintsAPIPatchComplaintComplaintsComplaintUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplaintsAPI
     */
    public patchComplaintComplaintsComplaintUuidPatch(requestParameters: ComplaintsAPIPatchComplaintComplaintsComplaintUuidPatchRequest, options?: any) {
        return ComplaintsAPIFp(this.configuration).patchComplaintComplaintsComplaintUuidPatch(requestParameters.complaintUuid, requestParameters.patchComplaintSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Complaint
     * @param {ComplaintsAPIPutComplaintComplaintsComplaintUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplaintsAPI
     */
    public putComplaintComplaintsComplaintUuidPut(requestParameters: ComplaintsAPIPutComplaintComplaintsComplaintUuidPutRequest, options?: any) {
        return ComplaintsAPIFp(this.configuration).putComplaintComplaintsComplaintUuidPut(requestParameters.complaintUuid, requestParameters.createComplaintSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
