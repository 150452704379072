import type { AxiosError, AxiosResponse } from 'axios';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';

import { useAuth } from '../../+auth';
import { HttpStatus } from '../http';
import { useTranslation } from '../translations';

interface Response<T> {
  isFetching: boolean;
  refetch: () => Promise<void>;
  response: T;
  setResponse: Dispatch<SetStateAction<T>>;
  error?: AxiosError['response'];
}

const isAxiosResponse = <T>(
  response: AxiosResponse<T> | T,
): response is AxiosResponse<T> =>
  response && (response as AxiosResponse<T>).data !== undefined;

export const useApi = <T>(
  initialState: T,
  callback$: () => Promise<AxiosResponse<T>['data'] | T>,
): Response<T> => {
  const lastRequestTS = useRef(0);
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [isFetching, setFetching] = useState(true);
  const [state, setState] = useState<T>(initialState);
  const [error, setError] = useState<AxiosError['response'] | undefined>(
    undefined,
  );
  const fetch = useCallback(async () => {
    const currentTs = +new Date();

    setFetching(true);

    try {
      const response = await callback$();

      // Temporary solution to show the last result despite the earlier ones
      if (currentTs < lastRequestTS.current) return;

      lastRequestTS.current = currentTs;
      setState(isAxiosResponse(response) ? response.data : response);
    } catch (e) {
      setError(e);

      if (e?.status === HttpStatus.UNAUTHORIZED) {
        toast.error(t('errors.general.tokenExpired'));
        logout();
      }
    } finally {
      setFetching(false);
    }
    // // To avoid situation when the data is fetched once again when the language was changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback$, logout]);

  useEffect(() => {
    fetch();

    return () => {
      lastRequestTS.current = 0;
    };
  }, [fetch]);

  return {
    error,
    isFetching,
    refetch: fetch,
    response: state,
    setResponse: setState,
  };
};
