import * as Yup from 'yup';

import type { AdvertisementIntegrationListSerializerDTO } from '../../../../../../connectors/company';
import { AdvertisementPlatformEnumDTO } from '../../../../../../connectors/company';
import type { FieldsetConfig } from '../../../../../shared';
import { DEFAULT_CHECKBOX_VALUE } from '../../../../../shared';
import type { FormValues } from '../form.types';

export const getFieldsetConfig = (
  integrations: AdvertisementIntegrationListSerializerDTO['results'],
): FieldsetConfig<FormValues>[] => [
  {
    fields: [
      {
        componentType: 'checkbox',
        field: {
          initialValue: DEFAULT_CHECKBOX_VALUE,
          name: 'integrations',
          validationSchema: Yup.array().min(1, 'form.errors.required'),
        },
        props: {
          isPictorial: true,
          options: integrations.map((integration) => ({
            disabled:
              integration.advertisementPlatform ===
              AdvertisementPlatformEnumDTO.Heimstaden,
            label: integration.name,
            name: integration.uuid,
            pictureURL: integration.logoUrl,
            subLabel: integration.url,
          })),
          required: true,
        },
      },
    ],
    key: 'platform',
    titleKey: 'advertisement.fieldset.integrations.title',
  },
];
