import { ReactComponent as MessagesBubbleStarIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-star.svg';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import uniqBy from 'lodash-es/uniqBy';
import type { FC, Dispatch, SetStateAction, UIEventHandler } from 'react';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';

import type { LanguageDTO } from '../../../../../connectors/company';
import type {
  ListResponseTemplateSerializerDTO,
  ResponseTemplateSerializerDTO,
} from '../../../../../connectors/ticket';
import {
  INITIAL_LIST_STATE as GENERIC_INITIAL_LIST_STATE,
  useApi,
  useDebounce,
  useList,
} from '../../../hooks';
import { useTranslation } from '../../../translations';
import { Dialog } from '../../Dialog/dialog.component';
import type { ListQueryParams } from '../../List';
import { Search } from '../../List/Search/search.component';
import { Scrollbar } from '../../Scrollbar/scrollbar.component';
import { Spinner } from '../../Spinner/spinner.component';
import { Template } from '../Template/template.component';
import { sxProps } from './template-picker.styles';

type Props = {
  getResponseTemplates$: (
    queryParams: ListQueryParams,
    language?: LanguageDTO,
  ) => Promise<ListResponseTemplateSerializerDTO>;
  isOpen: boolean;
  pickTemplate: Dispatch<
    SetStateAction<ResponseTemplateSerializerDTO['content']>
  >;
  setOpen: Dispatch<SetStateAction<boolean>>;
  removeResponseTemplate$?: (
    id: ResponseTemplateSerializerDTO['uuid'],
  ) => Promise<void>;
};

const INFINITY_SCROLL_DELAY = 1000;
const INITIAL_LIST_STATE = {
  ...GENERIC_INITIAL_LIST_STATE,
  paginationConfig: {
    ...GENERIC_INITIAL_LIST_STATE.paginationConfig,
    pageSize: 25,
  },
};
const REACH_BOTTOM_THRESHOLD = 100;

export const TemplatePicker: FC<Props> = (props) => {
  const {
    getResponseTemplates$,
    isOpen,
    pickTemplate,
    removeResponseTemplate$,
    setOpen,
  } = props;
  const { langCode, t } = useTranslation();
  const scrollbar = useRef<HTMLElement | null>(null);
  const [templates, setTemplates] = useState<ResponseTemplateSerializerDTO[]>(
    [],
  );
  const [pick, setPick] = useState<ResponseTemplateSerializerDTO>();
  const {
    listState,
    queryParams: listQueryParams,
    setListState,
    setPaginationFromResponse,
  } = useList<ListResponseTemplateSerializerDTO>(false, INITIAL_LIST_STATE);
  const getData$ = useCallback(async () => {
    if (!isOpen) return [];

    const response = await getResponseTemplates$(listQueryParams, langCode);

    setPaginationFromResponse(response);
    setTemplates((prevState) =>
      uniqBy([...prevState, ...response.results], 'uuid'),
    );

    return response.results;
  }, [
    getResponseTemplates$,
    isOpen,
    langCode,
    listQueryParams,
    setPaginationFromResponse,
  ]);
  const { error, isFetching } = useApi<ResponseTemplateSerializerDTO[]>(
    [],
    getData$,
  );
  const setNextPage = useCallback(
    () =>
      setListState((prevState) =>
        prevState.paginationConfig.next
          ? {
              ...prevState,
              paginationConfig: {
                ...prevState.paginationConfig,
                currentPage: prevState.paginationConfig.next,
              },
            }
          : prevState,
      ),
    [setListState],
  );
  const handleScroll = useCallback(
    (scrollHeight: number, scrollTop: number) => {
      if (!scrollbar.current) return;

      const scrollbarHeight = scrollbar.current.getBoundingClientRect().height;

      if (
        scrollbarHeight + scrollTop + REACH_BOTTOM_THRESHOLD >=
        scrollHeight
      ) {
        setNextPage();
      }
    },
    [setNextPage],
  );
  const debouncedScroll = useDebounce(handleScroll, INFINITY_SCROLL_DELAY);
  const onScroll: UIEventHandler<HTMLElement> = useCallback(
    (event) => {
      const { scrollHeight, scrollTop } = event.currentTarget;

      debouncedScroll(scrollHeight, scrollTop);
    },
    [debouncedScroll],
  );
  const handleClose = useCallback(() => {
    setOpen(false);
    setListState(INITIAL_LIST_STATE);
    setPick(undefined);
    setTemplates([]);
  }, [setListState, setOpen]);
  const handleConfirm = useCallback(() => {
    pickTemplate(pick?.content || '');
    handleClose();
  }, [handleClose, pick?.content, pickTemplate]);
  const handleRemoveTemplate$ = useCallback(
    async (id: ResponseTemplateSerializerDTO['uuid']) => {
      if (!removeResponseTemplate$) return Promise.reject();

      // eslint-disable-next-line no-useless-catch
      try {
        const response = await removeResponseTemplate$(id);

        setPick(undefined);
        setTemplates((prevState) =>
          prevState.filter((template) => template.uuid !== id),
        );

        return response;
      } catch (e) {
        throw e;
      }
    },
    [removeResponseTemplate$],
  );
  const onTemplateRemove$ = useMemo(
    () => (removeResponseTemplate$ ? handleRemoveTemplate$ : undefined),
    [handleRemoveTemplate$, removeResponseTemplate$],
  );

  useEffect(() => {
    setTemplates([]);
    setPick(undefined);
  }, [listQueryParams.search]);

  useEffect(() => {
    if (error) {
      toast.error(t('errors.general.message'));
    }
  }, [error, t]);

  return (
    <Dialog
      confirmButtonTitleKey="comment.template.use"
      fullWidth
      isOpen={isOpen}
      maxWidth="md"
      onClose={handleClose}
      onConfirm={handleConfirm}
      titleComponent={
        <Grid alignItems="center" container spacing={1} wrap="nowrap">
          <Grid item>
            <Icon color="primary">
              <MessagesBubbleStarIcon height={24} width={24} />
            </Icon>
          </Grid>
          <Grid item>
            <Typography color="text.secondary" marginBottom={0} variant="h2">
              {t('comment.template.title')}
            </Typography>
          </Grid>
        </Grid>
      }
    >
      <Grid container rowSpacing={2} sx={sxProps.container}>
        <Grid item xs={12}>
          <Search listState={listState} setListState={setListState} />
        </Grid>
        <Grid item sx={sxProps.listWrapper} xs={12}>
          <Scrollbar
            onScroll={onScroll}
            ref={(element) => {
              scrollbar.current = element;
            }}
          >
            <Grid container rowSpacing={2}>
              {templates.length > 0 && (
                <Grid item xs={12}>
                  <List>
                    {templates.map((template, idx) => (
                      <Fragment key={`template-${template.uuid}`}>
                        <Template
                          pick={pick}
                          setPick={setPick}
                          removeTemplate$={onTemplateRemove$}
                          template={template}
                        />
                        {idx < templates.length - 1 && <Divider />}
                      </Fragment>
                    ))}
                  </List>
                </Grid>
              )}
              {isFetching && (
                <Grid item xs={12}>
                  <Spinner size={24} />
                </Grid>
              )}
              {templates.length === 0 && !isFetching && (
                <Grid item xs={12}>
                  <Typography>{t('comment.template.empty')}</Typography>
                </Grid>
              )}
            </Grid>
          </Scrollbar>
        </Grid>
      </Grid>
    </Dialog>
  );
};
