import { ReactComponent as AlarmBellIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alert/alarm-bell.svg';
import MUIBox from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon/Icon';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import { useAuth } from '../../../../+auth';
import { authClient } from '../../../../+auth/auth.client';
import { Box, SwitchFieldLabel, useTranslation } from '../../../../shared';
import { fieldSettings } from './notification-settings.const';
import { sxProps } from './notification-settings.style';

export const NotificationSettings: FC = () => {
  const { user, setNotificationSettings } = useAuth();
  const notificationConfig = useMemo(
    () =>
      user?.notificationConfig || {
        isEmailEnabled: false,
        isSmsEnabled: false,
      },
    [user],
  );
  const { t } = useTranslation();
  const [isFetching, setFetching] = useState(false);
  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (isFetching) return;

      setFetching(true);
      setNotificationSettings({
        ...notificationConfig,
        [event.target.name]: event.target.checked,
      });

      try {
        await authClient.updateNotificationSetting$({
          [event.target.name]: event.target.checked,
        });

        toast.success(t('settings.notifications.success'));
      } catch (e) {
        toast.error(t('settings.notifications.error'));
        setNotificationSettings(notificationConfig);
      } finally {
        setFetching(false);
      }
    },
    [isFetching, notificationConfig, setNotificationSettings, t],
  );

  return (
    <Box>
      <MUIBox sx={sxProps.header}>
        <Icon sx={sxProps.icon}>
          <AlarmBellIcon height={24} />
        </Icon>
        <Typography sx={sxProps.title}>{t('notification.label')}</Typography>
      </MUIBox>
      <MUIBox sx={sxProps.features}>
        {fieldSettings.map((fieldConfig) => {
          const { helperTextKey, key, labelKey, name } = fieldConfig;

          return (
            <MUIBox sx={sxProps.box} key={key}>
              <FormControlLabel
                control={
                  <Switch
                    checked={notificationConfig[name]}
                    disabled={isFetching}
                    onChange={handleChange}
                    name={name}
                    sx={sxProps.switch}
                  />
                }
                label={
                  <SwitchFieldLabel
                    helperTextKey={helperTextKey}
                    labelKey={labelKey}
                  />
                }
              />
            </MUIBox>
          );
        })}
      </MUIBox>
    </Box>
  );
};
