import Avatar from '@mui/material/Avatar';
import type { MenuProps } from '@mui/material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';

import type { EmployeeSerializerDTO } from '../../../../../connectors/user';
import { getUserName } from '../../../helpers';
import { sxProps } from './suggestion-menu.styles';

type Props = {
  anchorEl: MenuProps['anchorEl'];
  isOpen: MenuProps['open'];
  onClose: MenuProps['onClose'];
  onSelect: (suggestion: EmployeeSerializerDTO) => void;
  suggestions: EmployeeSerializerDTO[];
};

export const SuggestionMenu: FC<Props> = (props) => {
  const { anchorEl, isOpen, onClose, onSelect, suggestions } = props;

  return (
    <Menu
      anchorEl={anchorEl}
      disableAutoFocus
      disableAutoFocusItem
      onClose={onClose}
      open={isOpen}
    >
      {suggestions.map((suggestion) => (
        <MenuItem
          key={`suggestion-${suggestion.uuid}`}
          onClick={() => onSelect(suggestion)}
        >
          <Avatar src={suggestion.profilePicture} sx={sxProps.avatar} />
          <Typography>{getUserName(suggestion)}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};
