import MuiList from '@mui/material/List';
import React, { Fragment } from 'react';
import type { FC } from 'react';

import { ThumbnailListItem } from '../ThumbnailListItem/thumbnail-list-item.component';
import type { ModelCommonProps, ViewModeCommonProps } from '../collection.type';

type Props<T> = ModelCommonProps<T> &
  ViewModeCommonProps & {
    model: T[];
  };

export const List = <T,>(props: Props<T>): ReturnType<FC<Props<T>>> => {
  const {
    children,
    listComponent,
    listItemComponent,
    model,
    modelKey,
    ...restProps
  } = props;

  if (listComponent) return listComponent(model);

  return (
    <MuiList>
      {model.map((item) => (
        <Fragment key={`li-${item[modelKey]}`}>
          {listItemComponent ? (
            listItemComponent(item, modelKey)
          ) : (
            <ThumbnailListItem item={item} modelKey={modelKey} {...restProps}>
              {children}
            </ThumbnailListItem>
          )}
        </Fragment>
      ))}
    </MuiList>
  );
};
