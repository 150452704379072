import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../../theme';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    p: 0,
  },
  footer: {
    backgroundColor: (theme) =>
      theme.palette.mode === 'light'
        ? palette.catskillWhite
        : theme.palette.background.paper,
    borderRadius: (theme) => theme.spacing(0, 0, 2, 2),
    p: 2,
  },
  header: {
    p: 2,
  },
  list: {
    p: 0,
  },
  listItem: {
    py: 3,
  },
};
