import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  addUserIcon: {
    backgroundColor: (theme) => theme.palette.grey[200],
    borderRadius: '50%',
    color: (theme) => theme.palette.secondary.light,
    height: 40,
    mr: 1,
    p: 1,
    width: 40,
  },
  assigningBox: {
    '&:hover': {
      cursor: 'pointer',
    },
    alignItems: 'center',
    display: 'flex',
    py: 0.5,
  },
};
