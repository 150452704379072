import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';

import type { CountrySerializerDTO } from '../../../../../connectors/company';
import type { ContactSerializerDTO } from '../../../../../connectors/property';
import type { UserRoleEnumDTO } from '../../../../../connectors/user';
import type { FormProps } from '../../../../shared';
import { CustomErrorType, Form, useTranslation } from '../../../../shared';
import type { Values } from '../../consts';
import { formConfig, getFieldsConfig } from '../../consts';
import { isReassignConfig } from '../ConfirmationDialog/confirmation-dialog.const';
import type {
  ConfirmationConfig,
  PickedConfirmationConfig,
} from '../ConfirmationDialog/confirmation-dialog.type';
import { sxProps } from './reassign.styles';

type Props = {
  dialogConfig: ConfirmationConfig;
  isDisabled: boolean;
  setDialogConfig: (config: PickedConfirmationConfig) => void;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  country?: CountrySerializerDTO['uuid'];
  excludedRoles?: UserRoleEnumDTO[];
  onReassign$?: (
    newUserId: ContactSerializerDTO['uuid'],
    oldUserId: ContactSerializerDTO['uuid'],
  ) => Promise<void>;
  roles?: UserRoleEnumDTO[];
};

export const Reassign: FC<Props> = (props) => {
  const {
    country,
    dialogConfig,
    excludedRoles,
    isDisabled,
    onReassign$,
    roles,
    setDialogConfig,
    setDisabled,
    setEditMode,
  } = props;
  const { t } = useTranslation();
  const fieldsConfig = useMemo(
    () => getFieldsConfig(country, roles, excludedRoles, isDisabled),
    [country, excludedRoles, isDisabled, roles],
  );
  const handleClickAway = useCallback(() => {
    setDialogConfig(undefined);
    setEditMode(false);
  }, [setDialogConfig, setEditMode]);
  const handleReassign$: FormProps<Values>['onChange'] = useCallback(
    async (values) => {
      if (!dialogConfig || !isReassignConfig(dialogConfig)) return;

      if (!values.user || !dialogConfig.oldUserId) return;

      if (values.user.value === dialogConfig.oldUserId) {
        setDialogConfig(undefined);
        setEditMode(false);

        return;
      }

      if (onReassign$) {
        try {
          setDisabled(true);
          await onReassign$(values.user.value, dialogConfig.oldUserId);
        } catch (e) {
          if (e.message === CustomErrorType.PREVENT_TOAST_ERROR) return;

          toast.error(t('errors.general.message'));
        } finally {
          setDisabled(false);
        }

        return;
      }

      setDialogConfig({ isOpen: true, newUser: values.user });
    },
    // dialogConfig is missed by purpose to avoid the infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onReassign$, setDialogConfig, setEditMode, setDisabled],
  );

  return (
    <Box sx={sxProps.reassignFormContainer}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box>
          <Form
            config={formConfig}
            onChange={handleReassign$}
            fieldsConfig={fieldsConfig}
            showButtons={false}
          />
        </Box>
      </ClickAwayListener>
    </Box>
  );
};
