import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { componentLazyImport } from '../routing/routes.helper';

const List = componentLazyImport(
  () => import('./List/list.component'),
  'LIBRARY_LIST',
);
const NotFound = componentLazyImport(
  () => import('../shared/errors/NotFound/not-found.component'),
  'LIBRARY_NOT_FOUND',
);
const TemplateList = componentLazyImport(
  () => import('./TemplateList/template-list.component'),
  'TEMPLATE_LIST',
);

const routes = {
  children: [
    {
      children: [
        { element: <Navigate to="templates" />, path: '' },
        { element: <TemplateList />, path: 'templates' },
        { element: <NotFound />, path: '*' },
      ],
      element: <List />,
      path: '',
    },
  ],
  element: <Outlet />,
  path: 'library',
};

export default routes;
