import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    backgroundColor: (theme) =>
      theme.palette.mode === 'dark'
        ? theme.palette.background.paper
        : 'rgba(255, 255, 255, 0.9)',
    border: (theme) =>
      theme.palette.mode === 'dark'
        ? 'unset'
        : `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
    bottom: 0,
    p: 3,
    position: 'absolute',
    width: '100%',
  },
  container: {
    height: '100%',
    minHeight: 350,
    position: 'relative',
  },
  link: {
    bottom: 'unset',
    top: 0,
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
  redirectLink: {
    '&:hover': {
      color: 'primary.main',
    },
    color: 'text.secondary',
  },
};
