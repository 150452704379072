import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import { Link } from 'react-router-dom';

export const AvatarRoot = styled(Link)((props) => ({
  backgroundColor:
    props.theme.palette.mode === 'dark'
      ? props.theme.palette.background.default
      : props.theme.palette.grey[100],
  display: 'block',
  padding: props.theme.spacing(2),
}));

export const sxProps: Record<string, SxProps<Theme>> = {
  contentColumn: {
    maxWidth: (theme) => `calc(100% - ${theme.spacing(6)})`,
  },
};
