import type {
  AdvertisementIntegrationListSerializerDTO,
  AttachmentSequenceSerializerDTO,
  CountrySerializerDTO,
} from '../../connectors/company';
import { ConfigsAPI } from '../../connectors/company';
import type {
  AdvertisementIntegrationPlatformValidationRulesSerializerDTO,
  CreateUnitAdvertisementAttachmentSerializerDTO,
  CreateUnitAdvertisementSerializerDTO,
  ListAttachmentSerializerDTO,
  ListUnitAdvertisementsSerializerDTO,
  OlxAuthorizeResponseSerializerDTO,
  UnitAdvertisementIntegrationResponseSerializerDTO,
  UnitAdvertisementIntegrationSerializerDTO,
  UnitAdvertisementSerializerDTO,
  UpdateUnitAdvertisementSerializerDTO,
} from '../../connectors/property';
import {
  AdvertisementsAPI,
  IntegrationsAPI,
  PropertiesAPI,
  PropertyObjectsAPI,
  UnitsAPI,
} from '../../connectors/property';
import type { AttachmentSerializerDTO } from '../../connectors/ticket';
import { mieInstance } from '../mie.instance';
import type { FilterValuesWithLocalization, ListQueryParams } from '../shared';
import { getEnvValue } from '../shared/helpers/env.helper';
import type { FilterValues } from './shared';

class AdvertisementClient {
  private client;

  private configClient;

  private integrationClient;

  private propertyClient;

  private propertyObjectClient;

  private unitClient;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.client = new AdvertisementsAPI(undefined, baeBath, mieInstance);
    this.configClient = new ConfigsAPI(undefined, baeBath, mieInstance);
    this.integrationClient = new IntegrationsAPI(
      undefined,
      baeBath,
      mieInstance,
    );
    this.propertyClient = new PropertiesAPI(undefined, baeBath, mieInstance);
    this.propertyObjectClient = new PropertyObjectsAPI(
      undefined,
      baeBath,
      mieInstance,
    );
    this.unitClient = new UnitsAPI(undefined, baeBath, mieInstance);
  }

  public create$ = async (
    advertisement: CreateUnitAdvertisementSerializerDTO,
  ): Promise<UnitAdvertisementSerializerDTO> => {
    const response = await this.client.createUnitAdvertisementPromotionsPost({
      createUnitAdvertisementSerializerDTO: advertisement,
    });

    return response.data;
  };

  public update$ = async (
    id: UnitAdvertisementSerializerDTO['uuid'],
    advertisement: UpdateUnitAdvertisementSerializerDTO,
  ): Promise<UnitAdvertisementSerializerDTO> => {
    const response =
      await this.client.patchUnitAdvertisementPromotionsUnitAdvertisementUuidPatch(
        {
          unitAdvertisementUuid: id,
          updateUnitAdvertisementSerializerDTO: advertisement,
        },
      );

    return response.data;
  };

  public addAttachments = async (
    id: UnitAdvertisementSerializerDTO['uuid'],
    attachments: CreateUnitAdvertisementAttachmentSerializerDTO['attachments'],
  ): Promise<ListAttachmentSerializerDTO> => {
    const response =
      await this.client.createUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsPost(
        {
          createUnitAdvertisementAttachmentSerializerDTO: { attachments },
          unitAdvertisementUuid: id,
        },
      );

    return response.data;
  };

  public publish$ = async (
    id: UnitAdvertisementSerializerDTO['uuid'],
  ): Promise<UnitAdvertisementIntegrationResponseSerializerDTO> => {
    const response =
      await this.client.publishUnitAdvertisementPromotionsUnitAdvertisementUuidPublishPost(
        { body: {}, unitAdvertisementUuid: id },
      );

    return response.data;
  };

  public unpublish$ = async (
    id: UnitAdvertisementSerializerDTO['uuid'],
    integrations: UnitAdvertisementIntegrationSerializerDTO['uuid'][],
  ): Promise<void> => {
    const payload = {
      integrations: integrations.map((integration) => ({
        uuid: integration,
      })),
    };

    await this.client.unpublishUnitAdvertisementPromotionsUnitAdvertisementUuidUnpublishPost(
      {
        unitAdvertisementUuid: id,
        unpublishUnitAdvertisementSerializerDTO: payload,
      },
    );
  };

  public getIntegrationsConfig$ = async (
    countryUuid: CountrySerializerDTO['uuid'],
  ): Promise<AdvertisementIntegrationListSerializerDTO> => {
    const response =
      await this.configClient.getAdvertisementIntegrationsConfigsPromotionsIntegrationsGet(
        { countryUuid },
      );

    return response.data;
  };

  public getValidationRules$ = async (
    countryId: CountrySerializerDTO['uuid'],
  ): Promise<
    AdvertisementIntegrationPlatformValidationRulesSerializerDTO[]
  > => {
    const response =
      await this.client.listIntegrationValidationRulesPromotionsIntegrationValidationRulesGet(
        { countryUuid: countryId },
      );

    return response.data.results;
  };

  public getList$ = async (
    queryParams?: ListQueryParams<FilterValuesWithLocalization<FilterValues>>,
  ): Promise<ListUnitAdvertisementsSerializerDTO> => {
    const { filters, page, pageSize, search } = { ...queryParams };
    const { localization, fields } = { ...filters };
    const { city, country, region } = { ...localization };
    const { hasProspects, lettingAgent, sort, status } = { ...fields };
    const response = await this.client.listUnitAdvertisementsPromotionsGet({
      cityUuid: city || undefined,
      countryUuid: country || undefined,
      hasProspects: hasProspects?.[0],
      lettingAgentUuid: lettingAgent?.value || undefined,
      order: sort,
      page,
      pageSize,
      regionUuid: region || undefined,
      search,
      status: status || undefined,
    });

    return response.data;
  };

  public getUnitAdvertisement$ = async (
    id: UnitAdvertisementSerializerDTO['uuid'],
  ): Promise<UnitAdvertisementSerializerDTO> => {
    const response =
      await this.client.getSingleUnitAdvertisementPromotionsUnitAdvertisementUuidGet(
        { unitAdvertisementUuid: id },
      );

    return response.data;
  };

  public getAttachments$ = async (
    id: UnitAdvertisementSerializerDTO['uuid'],
  ): Promise<ListAttachmentSerializerDTO> => {
    const response =
      await this.client.listUnitAdvertisementAttachmentsPromotionsUnitAdvertisementUuidAttachmentsGet(
        {
          order: 'sequence_number',
          unitAdvertisementUuid: id,
        },
      );

    return response.data;
  };

  public updateAttachmentsSequence$ = async (
    uuid: UnitAdvertisementSerializerDTO['uuid'],
    sequence: AttachmentSequenceSerializerDTO[],
  ): Promise<AttachmentSequenceSerializerDTO[]> => {
    const response =
      await this.client.updateUnitAdvertisementAttachmentsSequencePromotionsUnitAdvertisementUuidAttachmentsSequenceNumbersPut(
        {
          listAttachmentSequenceSerializerDTO: {
            attachments: sequence,
          },
          unitAdvertisementUuid: uuid,
        },
      );

    return response.data.results;
  };

  public deleteAttachment$ = async (
    id: UnitAdvertisementSerializerDTO['uuid'],
    attachmentId: AttachmentSerializerDTO['uuid'],
  ): Promise<void> => {
    await this.client.deleteUnitAdvertisementAttachmentPromotionsUnitAdvertisementUuidAttachmentsAttachmentUuidDelete(
      {
        attachmentUuid: attachmentId,
        unitAdvertisementUuid: id,
      },
    );
  };

  public authorizeOlx$ =
    async (): Promise<OlxAuthorizeResponseSerializerDTO> => {
      const response =
        await this.integrationClient.olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet();

      return response.data;
    };
}

export const advertisementClient = new AdvertisementClient();
