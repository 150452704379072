import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import React, { useMemo } from 'react';
import type { FC } from 'react';

import type { ModelCommonProps, ViewModeCommonProps } from '../collection.type';
import { sxProps } from './grid-item.styles';

type Props<T> = ModelCommonProps<T> &
  ViewModeCommonProps & {
    item: T;
  };

export const GridItem = <T,>(props: Props<T>): ReturnType<FC<Props<T>>> => {
  const {
    children,
    descriptionContent,
    item,
    modelKey,
    onItemClick,
    thumbnailUrl,
    titleContent,
    viewMode,
  } = props;
  const backgroundImage = useMemo(
    () => (thumbnailUrl ? `url(${thumbnailUrl(item)})` : undefined),
    [item, thumbnailUrl],
  );

  return (
    <Card
      {...(onItemClick && { onClick: () => onItemClick(item) })}
      sx={
        {
          ...sxProps.card,
          ...(onItemClick && sxProps.cardItemPointerEvents),
        } as SxProps
      }
    >
      <CardMedia
        component="div"
        sx={
          {
            ...sxProps.thumbnail,
            backgroundImage,
          } as SxProps
        }
      />
      <CardContent sx={sxProps.cardContent}>
        <Typography component="div" gutterBottom variant="h3">
          {titleContent(item)}
        </Typography>
        {descriptionContent && (
          <Typography color="text.secondary" variant="body2">
            {descriptionContent(item)}
          </Typography>
        )}
      </CardContent>
      {children && (
        <>
          <Divider />
          <CardActions sx={sxProps.cardActions}>
            {children(item, modelKey, viewMode)}
          </CardActions>
        </>
      )}
    </Card>
  );
};
