/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttachmentCategoryEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentTypeEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentVisibilityEnumDTO } from '../dto';
// @ts-ignore
import { CreateLeaseTemplateSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { LanguageDTO } from '../dto';
// @ts-ignore
import { LeaseTemplateLanguageTypeEnumDTO } from '../dto';
// @ts-ignore
import { LeaseTemplateSerializerDTO } from '../dto';
// @ts-ignore
import { ListAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ListLeaseTemplatesSerializerDTO } from '../dto';
// @ts-ignore
import { PatchLeaseTemplateSerializerDTO } from '../dto';
// @ts-ignore
import { PutLeaseTemplateSerializerDTO } from '../dto';
// @ts-ignore
import { UploadAttachmentResponseSerializerDTO } from '../dto';
/**
 * LeaseTemplatesAPI - axios parameter creator
 * @export
 */
export const LeaseTemplatesAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Lease Template
         * @param {CreateLeaseTemplateSerializerDTO} createLeaseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLeaseTemplateLeaseTemplatesPost: async (createLeaseTemplateSerializerDTO: CreateLeaseTemplateSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLeaseTemplateSerializerDTO' is not null or undefined
            assertParamExists('createLeaseTemplateLeaseTemplatesPost', 'createLeaseTemplateSerializerDTO', createLeaseTemplateSerializerDTO)
            const localVarPath = `/lease-templates/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLeaseTemplateSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Lease Template Attachment
         * @param {string} leaseTemplateLanguageUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete: async (leaseTemplateLanguageUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseTemplateLanguageUuid' is not null or undefined
            assertParamExists('deleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete', 'leaseTemplateLanguageUuid', leaseTemplateLanguageUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('deleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/lease-templates/{lease_template_language_uuid}/attachments/{attachment_uuid}`
                .replace(`{${"lease_template_language_uuid"}}`, encodeURIComponent(String(leaseTemplateLanguageUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDelete: async (leaseTemplateLanguageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseTemplateLanguageUuid' is not null or undefined
            assertParamExists('deleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDelete', 'leaseTemplateLanguageUuid', leaseTemplateLanguageUuid)
            const localVarPath = `/lease-templates/{lease_template_language_uuid}`
                .replace(`{${"lease_template_language_uuid"}}`, encodeURIComponent(String(leaseTemplateLanguageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Lease Template Attachment
         * @param {string} leaseTemplateLanguageUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet: async (leaseTemplateLanguageUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseTemplateLanguageUuid' is not null or undefined
            assertParamExists('downloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet', 'leaseTemplateLanguageUuid', leaseTemplateLanguageUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('downloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/lease-templates/{lease_template_language_uuid}/attachments/{attachment_uuid}/download`
                .replace(`{${"lease_template_language_uuid"}}`, encodeURIComponent(String(leaseTemplateLanguageUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Lease Template Attachments
         * @param {string} leaseTemplateLanguageUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet: async (leaseTemplateLanguageUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseTemplateLanguageUuid' is not null or undefined
            assertParamExists('getLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet', 'leaseTemplateLanguageUuid', leaseTemplateLanguageUuid)
            const localVarPath = `/lease-templates/{lease_template_language_uuid}/attachments`
                .replace(`{${"lease_template_language_uuid"}}`, encodeURIComponent(String(leaseTemplateLanguageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Lease Templates
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [title] 
         * @param {LeaseTemplateLanguageTypeEnumDTO} [templateType] 
         * @param {string} [countryUuid] 
         * @param {LanguageDTO} [language] 
         * @param {string} [uploadDateGte] 
         * @param {string} [uploadDateLte] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeaseTemplatesLeaseTemplatesGet: async (page?: number, pageSize?: number, order?: string, search?: string, title?: string, templateType?: LeaseTemplateLanguageTypeEnumDTO, countryUuid?: string, language?: LanguageDTO, uploadDateGte?: string, uploadDateLte?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/lease-templates/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (templateType !== undefined) {
                localVarQueryParameter['template_type'] = templateType;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (uploadDateGte !== undefined) {
                localVarQueryParameter['upload_date_gte'] = (uploadDateGte as any instanceof Date) ?
                    (uploadDateGte as any).toISOString().substr(0,10) :
                    uploadDateGte;
            }

            if (uploadDateLte !== undefined) {
                localVarQueryParameter['upload_date_lte'] = (uploadDateLte as any instanceof Date) ?
                    (uploadDateLte as any).toISOString().substr(0,10) :
                    uploadDateLte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGet: async (leaseTemplateLanguageUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseTemplateLanguageUuid' is not null or undefined
            assertParamExists('getSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGet', 'leaseTemplateLanguageUuid', leaseTemplateLanguageUuid)
            const localVarPath = `/lease-templates/{lease_template_language_uuid}`
                .replace(`{${"lease_template_language_uuid"}}`, encodeURIComponent(String(leaseTemplateLanguageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {PatchLeaseTemplateSerializerDTO} patchLeaseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch: async (leaseTemplateLanguageUuid: string, patchLeaseTemplateSerializerDTO: PatchLeaseTemplateSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseTemplateLanguageUuid' is not null or undefined
            assertParamExists('patchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch', 'leaseTemplateLanguageUuid', leaseTemplateLanguageUuid)
            // verify required parameter 'patchLeaseTemplateSerializerDTO' is not null or undefined
            assertParamExists('patchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch', 'patchLeaseTemplateSerializerDTO', patchLeaseTemplateSerializerDTO)
            const localVarPath = `/lease-templates/{lease_template_language_uuid}`
                .replace(`{${"lease_template_language_uuid"}}`, encodeURIComponent(String(leaseTemplateLanguageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchLeaseTemplateSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {PutLeaseTemplateSerializerDTO} putLeaseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut: async (leaseTemplateLanguageUuid: string, putLeaseTemplateSerializerDTO: PutLeaseTemplateSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseTemplateLanguageUuid' is not null or undefined
            assertParamExists('putLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut', 'leaseTemplateLanguageUuid', leaseTemplateLanguageUuid)
            // verify required parameter 'putLeaseTemplateSerializerDTO' is not null or undefined
            assertParamExists('putLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut', 'putLeaseTemplateSerializerDTO', putLeaseTemplateSerializerDTO)
            const localVarPath = `/lease-templates/{lease_template_language_uuid}`
                .replace(`{${"lease_template_language_uuid"}}`, encodeURIComponent(String(leaseTemplateLanguageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putLeaseTemplateSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Lease Template Attachment
         * @param {string} leaseTemplateLanguageUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {any} file 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost: async (leaseTemplateLanguageUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, file: any, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseTemplateLanguageUuid' is not null or undefined
            assertParamExists('uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost', 'leaseTemplateLanguageUuid', leaseTemplateLanguageUuid)
            // verify required parameter 'attachmentCategory' is not null or undefined
            assertParamExists('uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost', 'attachmentCategory', attachmentCategory)
            // verify required parameter 'attachmentType' is not null or undefined
            assertParamExists('uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost', 'attachmentType', attachmentType)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost', 'file', file)
            const localVarPath = `/lease-templates/{lease_template_language_uuid}/attachments`
                .replace(`{${"lease_template_language_uuid"}}`, encodeURIComponent(String(leaseTemplateLanguageUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


            if (attachmentCategory !== undefined) { 
                localVarFormParams.append('attachment_category', new Blob([JSON.stringify(attachmentCategory)], { type: "application/json", }));
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', new Blob([JSON.stringify(attachmentType)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (visibility !== undefined) { 
                localVarFormParams.append('visibility', new Blob([JSON.stringify(visibility)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeaseTemplatesAPI - functional programming interface
 * @export
 */
export const LeaseTemplatesAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeaseTemplatesAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Lease Template
         * @param {CreateLeaseTemplateSerializerDTO} createLeaseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLeaseTemplateLeaseTemplatesPost(createLeaseTemplateSerializerDTO: CreateLeaseTemplateSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeaseTemplateSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLeaseTemplateLeaseTemplatesPost(createLeaseTemplateSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Lease Template Attachment
         * @param {string} leaseTemplateLanguageUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete(leaseTemplateLanguageUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete(leaseTemplateLanguageUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDelete(leaseTemplateLanguageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDelete(leaseTemplateLanguageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Lease Template Attachment
         * @param {string} leaseTemplateLanguageUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet(leaseTemplateLanguageUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet(leaseTemplateLanguageUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Lease Template Attachments
         * @param {string} leaseTemplateLanguageUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet(leaseTemplateLanguageUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet(leaseTemplateLanguageUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Lease Templates
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [title] 
         * @param {LeaseTemplateLanguageTypeEnumDTO} [templateType] 
         * @param {string} [countryUuid] 
         * @param {LanguageDTO} [language] 
         * @param {string} [uploadDateGte] 
         * @param {string} [uploadDateLte] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeaseTemplatesLeaseTemplatesGet(page?: number, pageSize?: number, order?: string, search?: string, title?: string, templateType?: LeaseTemplateLanguageTypeEnumDTO, countryUuid?: string, language?: LanguageDTO, uploadDateGte?: string, uploadDateLte?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLeaseTemplatesSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeaseTemplatesLeaseTemplatesGet(page, pageSize, order, search, title, templateType, countryUuid, language, uploadDateGte, uploadDateLte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGet(leaseTemplateLanguageUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeaseTemplateSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGet(leaseTemplateLanguageUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {PatchLeaseTemplateSerializerDTO} patchLeaseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch(leaseTemplateLanguageUuid: string, patchLeaseTemplateSerializerDTO: PatchLeaseTemplateSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeaseTemplateSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch(leaseTemplateLanguageUuid, patchLeaseTemplateSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {PutLeaseTemplateSerializerDTO} putLeaseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut(leaseTemplateLanguageUuid: string, putLeaseTemplateSerializerDTO: PutLeaseTemplateSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeaseTemplateSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut(leaseTemplateLanguageUuid, putLeaseTemplateSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Lease Template Attachment
         * @param {string} leaseTemplateLanguageUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {any} file 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost(leaseTemplateLanguageUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, file: any, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadAttachmentResponseSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost(leaseTemplateLanguageUuid, attachmentCategory, attachmentType, file, title, description, visibility, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeaseTemplatesAPI - factory interface
 * @export
 */
export const LeaseTemplatesAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeaseTemplatesAPIFp(configuration)
    return {
        /**
         * 
         * @summary Create Lease Template
         * @param {CreateLeaseTemplateSerializerDTO} createLeaseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLeaseTemplateLeaseTemplatesPost(createLeaseTemplateSerializerDTO: CreateLeaseTemplateSerializerDTO, options?: any): AxiosPromise<LeaseTemplateSerializerDTO> {
            return localVarFp.createLeaseTemplateLeaseTemplatesPost(createLeaseTemplateSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Lease Template Attachment
         * @param {string} leaseTemplateLanguageUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete(leaseTemplateLanguageUuid: string, attachmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete(leaseTemplateLanguageUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDelete(leaseTemplateLanguageUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDelete(leaseTemplateLanguageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Lease Template Attachment
         * @param {string} leaseTemplateLanguageUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet(leaseTemplateLanguageUuid: string, attachmentUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet(leaseTemplateLanguageUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Lease Template Attachments
         * @param {string} leaseTemplateLanguageUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet(leaseTemplateLanguageUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): AxiosPromise<ListAttachmentSerializerDTO> {
            return localVarFp.getLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet(leaseTemplateLanguageUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Lease Templates
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [title] 
         * @param {LeaseTemplateLanguageTypeEnumDTO} [templateType] 
         * @param {string} [countryUuid] 
         * @param {LanguageDTO} [language] 
         * @param {string} [uploadDateGte] 
         * @param {string} [uploadDateLte] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeaseTemplatesLeaseTemplatesGet(page?: number, pageSize?: number, order?: string, search?: string, title?: string, templateType?: LeaseTemplateLanguageTypeEnumDTO, countryUuid?: string, language?: LanguageDTO, uploadDateGte?: string, uploadDateLte?: string, options?: any): AxiosPromise<ListLeaseTemplatesSerializerDTO> {
            return localVarFp.getLeaseTemplatesLeaseTemplatesGet(page, pageSize, order, search, title, templateType, countryUuid, language, uploadDateGte, uploadDateLte, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGet(leaseTemplateLanguageUuid: string, options?: any): AxiosPromise<LeaseTemplateSerializerDTO> {
            return localVarFp.getSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGet(leaseTemplateLanguageUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {PatchLeaseTemplateSerializerDTO} patchLeaseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch(leaseTemplateLanguageUuid: string, patchLeaseTemplateSerializerDTO: PatchLeaseTemplateSerializerDTO, options?: any): AxiosPromise<LeaseTemplateSerializerDTO> {
            return localVarFp.patchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch(leaseTemplateLanguageUuid, patchLeaseTemplateSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Lease Template
         * @param {string} leaseTemplateLanguageUuid 
         * @param {PutLeaseTemplateSerializerDTO} putLeaseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut(leaseTemplateLanguageUuid: string, putLeaseTemplateSerializerDTO: PutLeaseTemplateSerializerDTO, options?: any): AxiosPromise<LeaseTemplateSerializerDTO> {
            return localVarFp.putLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut(leaseTemplateLanguageUuid, putLeaseTemplateSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Lease Template Attachment
         * @param {string} leaseTemplateLanguageUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {any} file 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost(leaseTemplateLanguageUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, file: any, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): AxiosPromise<UploadAttachmentResponseSerializerDTO> {
            return localVarFp.uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost(leaseTemplateLanguageUuid, attachmentCategory, attachmentType, file, title, description, visibility, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createLeaseTemplateLeaseTemplatesPost operation in LeaseTemplatesAPI.
 * @export
 * @interface LeaseTemplatesAPICreateLeaseTemplateLeaseTemplatesPostRequest
 */
export interface LeaseTemplatesAPICreateLeaseTemplateLeaseTemplatesPostRequest {
    /**
     * 
     * @type {CreateLeaseTemplateSerializerDTO}
     * @memberof LeaseTemplatesAPICreateLeaseTemplateLeaseTemplatesPost
     */
    readonly createLeaseTemplateSerializerDTO: CreateLeaseTemplateSerializerDTO
}

/**
 * Request parameters for deleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete operation in LeaseTemplatesAPI.
 * @export
 * @interface LeaseTemplatesAPIDeleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDeleteRequest
 */
export interface LeaseTemplatesAPIDeleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIDeleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete
     */
    readonly leaseTemplateLanguageUuid: string

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIDeleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for deleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDelete operation in LeaseTemplatesAPI.
 * @export
 * @interface LeaseTemplatesAPIDeleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDeleteRequest
 */
export interface LeaseTemplatesAPIDeleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIDeleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDelete
     */
    readonly leaseTemplateLanguageUuid: string
}

/**
 * Request parameters for downloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet operation in LeaseTemplatesAPI.
 * @export
 * @interface LeaseTemplatesAPIDownloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGetRequest
 */
export interface LeaseTemplatesAPIDownloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGetRequest {
    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIDownloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly leaseTemplateLanguageUuid: string

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIDownloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for getLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet operation in LeaseTemplatesAPI.
 * @export
 * @interface LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGetRequest
 */
export interface LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet
     */
    readonly leaseTemplateLanguageUuid: string

    /**
     * 
     * @type {number}
     * @memberof LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet
     */
    readonly type?: string
}

/**
 * Request parameters for getLeaseTemplatesLeaseTemplatesGet operation in LeaseTemplatesAPI.
 * @export
 * @interface LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGetRequest
 */
export interface LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGet
     */
    readonly title?: string

    /**
     * 
     * @type {LeaseTemplateLanguageTypeEnumDTO}
     * @memberof LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGet
     */
    readonly templateType?: LeaseTemplateLanguageTypeEnumDTO

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {LanguageDTO}
     * @memberof LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGet
     */
    readonly language?: LanguageDTO

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGet
     */
    readonly uploadDateGte?: string

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGet
     */
    readonly uploadDateLte?: string
}

/**
 * Request parameters for getSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGet operation in LeaseTemplatesAPI.
 * @export
 * @interface LeaseTemplatesAPIGetSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGetRequest
 */
export interface LeaseTemplatesAPIGetSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIGetSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGet
     */
    readonly leaseTemplateLanguageUuid: string
}

/**
 * Request parameters for patchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch operation in LeaseTemplatesAPI.
 * @export
 * @interface LeaseTemplatesAPIPatchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatchRequest
 */
export interface LeaseTemplatesAPIPatchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIPatchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch
     */
    readonly leaseTemplateLanguageUuid: string

    /**
     * 
     * @type {PatchLeaseTemplateSerializerDTO}
     * @memberof LeaseTemplatesAPIPatchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch
     */
    readonly patchLeaseTemplateSerializerDTO: PatchLeaseTemplateSerializerDTO
}

/**
 * Request parameters for putLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut operation in LeaseTemplatesAPI.
 * @export
 * @interface LeaseTemplatesAPIPutLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPutRequest
 */
export interface LeaseTemplatesAPIPutLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIPutLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut
     */
    readonly leaseTemplateLanguageUuid: string

    /**
     * 
     * @type {PutLeaseTemplateSerializerDTO}
     * @memberof LeaseTemplatesAPIPutLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut
     */
    readonly putLeaseTemplateSerializerDTO: PutLeaseTemplateSerializerDTO
}

/**
 * Request parameters for uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost operation in LeaseTemplatesAPI.
 * @export
 * @interface LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPostRequest
 */
export interface LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost
     */
    readonly leaseTemplateLanguageUuid: string

    /**
     * 
     * @type {AttachmentCategoryEnumDTO}
     * @memberof LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost
     */
    readonly attachmentCategory: AttachmentCategoryEnumDTO

    /**
     * 
     * @type {AttachmentTypeEnumDTO}
     * @memberof LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost
     */
    readonly attachmentType: AttachmentTypeEnumDTO

    /**
     * 
     * @type {any}
     * @memberof LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost
     */
    readonly file: any

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost
     */
    readonly title?: string

    /**
     * 
     * @type {string}
     * @memberof LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost
     */
    readonly description?: string

    /**
     * 
     * @type {AttachmentVisibilityEnumDTO}
     * @memberof LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost
     */
    readonly visibility?: AttachmentVisibilityEnumDTO
}

/**
 * LeaseTemplatesAPI - object-oriented interface
 * @export
 * @class LeaseTemplatesAPI
 * @extends {BaseAPI}
 */
export class LeaseTemplatesAPI extends BaseAPI {
    /**
     * 
     * @summary Create Lease Template
     * @param {LeaseTemplatesAPICreateLeaseTemplateLeaseTemplatesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaseTemplatesAPI
     */
    public createLeaseTemplateLeaseTemplatesPost(requestParameters: LeaseTemplatesAPICreateLeaseTemplateLeaseTemplatesPostRequest, options?: any) {
        return LeaseTemplatesAPIFp(this.configuration).createLeaseTemplateLeaseTemplatesPost(requestParameters.createLeaseTemplateSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Lease Template Attachment
     * @param {LeaseTemplatesAPIDeleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaseTemplatesAPI
     */
    public deleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete(requestParameters: LeaseTemplatesAPIDeleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDeleteRequest, options?: any) {
        return LeaseTemplatesAPIFp(this.configuration).deleteLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDelete(requestParameters.leaseTemplateLanguageUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Lease Template
     * @param {LeaseTemplatesAPIDeleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaseTemplatesAPI
     */
    public deleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDelete(requestParameters: LeaseTemplatesAPIDeleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDeleteRequest, options?: any) {
        return LeaseTemplatesAPIFp(this.configuration).deleteLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidDelete(requestParameters.leaseTemplateLanguageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Lease Template Attachment
     * @param {LeaseTemplatesAPIDownloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaseTemplatesAPI
     */
    public downloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet(requestParameters: LeaseTemplatesAPIDownloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGetRequest, options?: any) {
        return LeaseTemplatesAPIFp(this.configuration).downloadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsAttachmentUuidDownloadGet(requestParameters.leaseTemplateLanguageUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Lease Template Attachments
     * @param {LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaseTemplatesAPI
     */
    public getLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet(requestParameters: LeaseTemplatesAPIGetLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGetRequest, options?: any) {
        return LeaseTemplatesAPIFp(this.configuration).getLeaseTemplateAttachmentsLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsGet(requestParameters.leaseTemplateLanguageUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.category, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Lease Templates
     * @param {LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaseTemplatesAPI
     */
    public getLeaseTemplatesLeaseTemplatesGet(requestParameters: LeaseTemplatesAPIGetLeaseTemplatesLeaseTemplatesGetRequest = {}, options?: any) {
        return LeaseTemplatesAPIFp(this.configuration).getLeaseTemplatesLeaseTemplatesGet(requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.search, requestParameters.title, requestParameters.templateType, requestParameters.countryUuid, requestParameters.language, requestParameters.uploadDateGte, requestParameters.uploadDateLte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Lease Template
     * @param {LeaseTemplatesAPIGetSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaseTemplatesAPI
     */
    public getSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGet(requestParameters: LeaseTemplatesAPIGetSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGetRequest, options?: any) {
        return LeaseTemplatesAPIFp(this.configuration).getSingleLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidGet(requestParameters.leaseTemplateLanguageUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Lease Template
     * @param {LeaseTemplatesAPIPatchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaseTemplatesAPI
     */
    public patchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch(requestParameters: LeaseTemplatesAPIPatchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatchRequest, options?: any) {
        return LeaseTemplatesAPIFp(this.configuration).patchLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPatch(requestParameters.leaseTemplateLanguageUuid, requestParameters.patchLeaseTemplateSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Lease Template
     * @param {LeaseTemplatesAPIPutLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaseTemplatesAPI
     */
    public putLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut(requestParameters: LeaseTemplatesAPIPutLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPutRequest, options?: any) {
        return LeaseTemplatesAPIFp(this.configuration).putLeaseTemplateLeaseTemplatesLeaseTemplateLanguageUuidPut(requestParameters.leaseTemplateLanguageUuid, requestParameters.putLeaseTemplateSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Lease Template Attachment
     * @param {LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaseTemplatesAPI
     */
    public uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost(requestParameters: LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPostRequest, options?: any) {
        return LeaseTemplatesAPIFp(this.configuration).uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost(requestParameters.leaseTemplateLanguageUuid, requestParameters.attachmentCategory, requestParameters.attachmentType, requestParameters.file, requestParameters.title, requestParameters.description, requestParameters.visibility, options).then((request) => request(this.axios, this.basePath));
    }
}
