import React, { useEffect, useMemo } from 'react';
import type { FC, ReactNode } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { NOT_FOUND_PATH } from '../../../routing';
import { Spinner } from '../../components';
import { useApi } from '../../hooks/api.hook';
import { HttpStatus } from '../../http';
import { useTranslation } from '../../translations';
import type { ContextValue } from './context';
import { Context } from './context';

type Props<T> = {
  children: (item: T) => ReactNode;
  getData$: () => Promise<T>;
  shouldRedirect?: boolean;
};

export const Provider = <T,>(props: Props<T>): ReturnType<FC<Props<T>>> => {
  const { children, getData$, shouldRedirect = false } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { error, isFetching, response, setResponse } = useApi<T | undefined>(
    undefined,
    getData$,
  );
  const value = useMemo<ContextValue<T | undefined>>(
    () => ({ response, setResponse }),
    [response, setResponse],
  );

  useEffect(() => {
    if (!shouldRedirect && error) {
      toast.error(t('errors.general.message'));
    }
  }, [error, shouldRedirect, t]);

  if (isFetching) {
    return <Spinner />;
  }

  if (shouldRedirect && error?.status === HttpStatus.NOT_FOUND) {
    navigate(NOT_FOUND_PATH);

    return null;
  }

  if (error) return null;

  return (
    <Context.Provider value={value}>{children(response as T)}</Context.Provider>
  );
};
