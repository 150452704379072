import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

const ILLUSTRATION_PATH = '/assets/thumbnail_prospect_user.svg';

export const sxProps: Record<'illustration' | 'linkIcon', SxProps<Theme>> = {
  illustration: {
    backgroundImage: `url(${ILLUSTRATION_PATH})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 200,
    width: 220,
  },
  linkIcon: {
    height: 24,
    mr: 1,
    verticalAlign: 'top',
    width: 24,
  },
};
