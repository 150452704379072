import { ReactComponent as LayersIcon } from '@heimstaden/icons-library/img/streamline-regular/design/layers/layers.svg';
import { ReactComponent as SofaDoubleIcon } from '@heimstaden/icons-library/img/streamline-regular/furnitures-decoration/sofas-benches/sofa-double.svg';
import { ReactComponent as HotelBedroom1Icon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/hotel-bedrooms/hotel-bedroom-1.svg';
import { ReactComponent as RealEstateDimensionsBlockIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/maps-dimensions/real-estate-dimensions-block.svg';
import Grid from '@mui/material/Grid';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { UnitSerializerDTO } from '../../../../../connectors/property';
import {
  CollectionViewMode,
  Feature,
  getDisplayValue,
  Scrollbar,
  useTranslation,
} from '../../../../shared';

type Props = {
  data: UnitSerializerDTO;
  viewMode?: CollectionViewMode;
};

const GRID_SPACING_DEFAULT = 3;
const GRID_SPACING_LIST = [2, 4, 4, 6];

export const FeaturesList: FC<Props> = (props) => {
  const { data, viewMode } = props;
  const { t } = useTranslation();
  const gridSpacing = useMemo(
    () =>
      viewMode === CollectionViewMode.LIST
        ? GRID_SPACING_LIST
        : GRID_SPACING_DEFAULT,
    [viewMode],
  );
  const features = useMemo(
    () => [
      {
        dataTestId: 'net-area',
        icon: <RealEstateDimensionsBlockIcon />,
        id: 'netArea',
        titleKey: 'unit.fields.netArea',
        value: t('metricUnit.squareMetre', {
          value: getDisplayValue(data.netArea).value,
        }),
      },
      {
        dataTestId: 'bedroom-count',
        icon: <HotelBedroom1Icon />,
        id: 'bedrooms',
        titleKey: 'unit.fields.bedrooms',
        value: getDisplayValue(data.bedrooms).value,
      },
      {
        dataTestId: 'room-count',
        icon: <SofaDoubleIcon />,
        id: 'rooms',
        titleKey: 'unit.fields.rooms',
        value: getDisplayValue(data.rooms).value,
      },
      {
        dataTestId: 'floor-count',
        icon: <LayersIcon />,
        id: 'floor',
        titleKey: 'unit.fields.floor',
        value: getDisplayValue(data.floor).value,
      },
    ],
    [data.bedrooms, data.floor, data.netArea, data.rooms, t],
  );

  if (viewMode === CollectionViewMode.MAP) {
    return null;
  }

  return (
    <Scrollbar>
      <Grid container spacing={gridSpacing} wrap="nowrap">
        {features.map(({ id, ...feature }) => (
          <Grid key={`feature-${viewMode}-${id}`} item>
            <Feature
              {...feature}
              size={viewMode === CollectionViewMode.GRID ? 'small' : 'normal'}
            />
          </Grid>
        ))}
      </Grid>
    </Scrollbar>
  );
};
