import type {
  ConfirmationConfig,
  PickedConfirmationConfig,
  ReassignConfig,
  RemoveConfig,
} from './confirmation-dialog.type';

export const isRemoveConfig = (
  config:
    | NonNullable<ConfirmationConfig>
    | NonNullable<PickedConfirmationConfig>,
): config is RemoveConfig => (config as RemoveConfig).id !== undefined;

export const isReassignConfig = (
  config:
    | NonNullable<ConfirmationConfig>
    | NonNullable<PickedConfirmationConfig>,
): config is ReassignConfig => 'oldUserId' in config || 'newUser' in config;
