import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';
import type { FC, ReactNode } from 'react';

import { sxProps } from './page-container.styles';

type Props = {
  children: NonNullable<ReactNode>;
  dense?: boolean;
};

export const PageContainer: FC<Props> = (props) => {
  const { dense, children } = props;

  return dense ? (
    <Box sx={{ mt: 3 }}>{children}</Box>
  ) : (
    <Box sx={sxProps.container}>
      <Container disableGutters maxWidth={false}>
        {children}
      </Container>
    </Box>
  );
};
