const PATHS = {
  ACTIVITY_LOG_LIST: '/properties/:id/logs',
  ATTACHMENTS_UPLOAD: '/properties/:id/attachments/upload',
  BUILDING_LIST: '/properties/:id/buildings',
  COMPLAINT_LIST: '/properties/:id/complaints',
  CONTRACT_REQUEST_LIST: '/properties/:id/contract-requests',
  CREATE: '/properties/create',
  CREATE_COMPLAINT: '/properties/:id/complaint',
  CREATE_CONTRACT_REQUEST: '/properties/:id/contract-request',
  CREATE_GENERAL_REQUEST: '/properties/:id/general-request',
  CREATE_INVOICE_REQUEST: '/properties/:id/invoice-request',
  CREATE_SERVICE_REQUEST: '/properties/:id/service-request',
  DETAILS: '/properties/:id',
  DOCUMENT_LIST: '/properties/:id/documents',
  GENERAL_REQUEST_LIST: '/properties/:id/general-requests',
  GROUND_AREA_LIST: '/properties/:id/ground-areas',
  INVOICE_REQUEST_LIST: '/properties/:id/invoice-requests',
  LIST: '/properties',
  PROPERTY_OBJECT_CREATE: '/properties/:id/objects/create',
  SERVICE_REQUEST_LIST: '/properties/:id/service-requests',
  UPDATE: '/properties/:id/update',
};

export default PATHS;
