import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    alignItems: 'center',
    borderRadius: 32,
    display: 'flex',
    justifyContent: 'space-between',
    p: (theme) => theme.spacing(0.5, 1.25, 0.5, 0.5),
    width: '100%',
  },
  item: {
    display: 'flex',
    flexWrap: 'nowrap',
    m: (theme) => theme.spacing(-0.5, -1.25, -0.5, -0.5),
  },
};
