import React from 'react';
import { Outlet } from 'react-router-dom';

import { ACTIVITY_LOG_VIEW_PERMISSIONS } from '../+activity-log';
import { PermissionGuard } from '../+auth/shared/guards/permission.guard';
import { VIEW_PERMISSIONS as PROPERTY_OBJECT_VIEW_PERMISSIONS } from '../+property-object/shared/consts/permission.const';
import { VIEW_PERMISSIONS as PROPERTY_VIEW_PERMISSIONS } from '../+property/shared/consts/permission.const';
import { APP_MAIN_PATH } from '../routing';
import { componentLazyImport } from '../routing/routes.helper';
import { VIEW_PERMISSIONS as ATTACHMENTS_VIEW_PERMISSIONS } from '../shared/attachment/shared/consts/permission.const';
import { DefaultRouteTabContent } from '../shared/components/DefaultRouteTabContent/default-route-tab-view.component';
import PATHS from './paths.const';
import { CREATE_PERMISSIONS, VIEW_PERMISSIONS } from './shared';

const DocumentList = componentLazyImport(
  () => import('./DocumentList/document-list.component'),
  'COMPANY_DOCUMENT_LIST',
);
const ActivityLogList = componentLazyImport(
  () => import('./ActivityLogList/activity-log-list.component'),
  'COMPANY_ACTIVITY_LOG',
);
const Create = componentLazyImport(
  () => import('./Create/create.component'),
  'COMPANY_CREATE',
);
const Details = componentLazyImport(
  () => import('./Details/details.component'),
  'COMPANY_DETAILS',
);
const List = componentLazyImport(
  () => import('./List/list.component'),
  'COMPANY_LIST',
);
const PropertyList = componentLazyImport(
  () => import('./PropertyList/property-list.component'),
  'COMPANY_PROPERTY_LIST',
);
const PropertyObjectList = componentLazyImport(
  () => import('./PropertyObjectList/property-object-list.component'),
  'COMPANY_PROPERTY_OBJECT_LIST',
);
const Update = componentLazyImport(
  () => import('./Update/update.component'),
  'COMPANY_UPDATE',
);
const NotFound = componentLazyImport(
  () => import('../shared/errors/NotFound/not-found.component'),
  'COMPANY_NOT_FOUND',
);

const defaultTabs = [
  {
    component: <PropertyList />,
    expectedPermissions: PROPERTY_VIEW_PERMISSIONS,
  },
  {
    component: <PropertyObjectList />,
    expectedPermissions: PROPERTY_OBJECT_VIEW_PERMISSIONS,
  },
  {
    component: <DocumentList />,
    expectedPermissions: ATTACHMENTS_VIEW_PERMISSIONS,
  },
  {
    component: <ActivityLogList />,
    expectedPermissions: ACTIVITY_LOG_VIEW_PERMISSIONS,
  },
];

const routes = {
  children: [
    { element: <List />, path: '' },
    {
      element: (
        <PermissionGuard
          fallbackRoute={PATHS.LIST}
          permissions={CREATE_PERMISSIONS}
        >
          <Create />
        </PermissionGuard>
      ),
      path: 'create',
    },
    { element: <Update />, path: ':id/update' },
    {
      children: [
        { element: <DefaultRouteTabContent tabs={defaultTabs} />, path: '' },
        { element: <ActivityLogList />, path: 'logs' },
        { element: <DocumentList />, path: 'documents' },
        { element: <NotFound />, path: '*' },
        { element: <PropertyList />, path: 'properties' },
        { element: <PropertyObjectList />, path: 'property-objects' },
      ],
      element: <Details />,
      path: ':id',
    },
  ],
  element: (
    <PermissionGuard
      fallbackRoute={APP_MAIN_PATH}
      permissions={VIEW_PERMISSIONS}
    >
      <Outlet />
    </PermissionGuard>
  ),
  path: 'companies',
};

export default routes;
