import type { UniversalTicketSerializerDTO } from '../../../../connectors/ticket';
import type {
  ProceedAttachmentsUpdateCallbacks,
  ProceedAttachmentsUploadCallbacks,
} from '../../../shared';
import { ticketClient } from '../../ticket.client';

export const getAttachmentUpdateProcedureCallbacks = (
  id: UniversalTicketSerializerDTO['uuid'],
): ProceedAttachmentsUpdateCallbacks => ({
  handleRemove$: (attachmentId) =>
    ticketClient.deleteAttachment$(id, attachmentId),
  handleSequence$: ticketClient.updateAttachmentsSequence$,
  handleUpdate$: (attachment) => ticketClient.updateAttachment$(attachment, id),
  handleUpload$: (category, type, file, visibility) =>
    ticketClient.uploadAttachment$(id, category, type, file, visibility),
});

export const getAttachmentUploadProcedureCallbacks = (
  id: UniversalTicketSerializerDTO['uuid'],
): ProceedAttachmentsUploadCallbacks => ({
  handleUpload$: (category, type, file, visibility) =>
    ticketClient.uploadAttachment$(id, category, type, file, visibility),
});
