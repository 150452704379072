import React, { useEffect, useMemo } from 'react';
import type { FC, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { REPORT_PATHS } from '../../../+report';
import { storageService } from '../../../shared';
import PATHS from '../../paths.const';
import { useAuth } from '../state';

interface Props {
  children: ReactElement;
}

export const AuthGuard: FC<Props> = (props) => {
  const { children } = props;
  const { isAuthenticated, isLoggedOut } = useAuth();
  const location = useLocation();
  const requestedLocation = storageService.getRequestedLocation();
  const isLocationToRedirect = useMemo(
    () => Boolean(requestedLocation && location.pathname !== requestedLocation),
    [location.pathname, requestedLocation],
  );
  const isNewLocationToRedirect = useMemo(
    () =>
      !isLoggedOut &&
      location.pathname !== '/' &&
      location.pathname !== REPORT_PATHS.DASHBOARD &&
      location.pathname !== requestedLocation,
    [isLoggedOut, location.pathname, requestedLocation],
  );

  useEffect(() => {
    if (isAuthenticated && isLocationToRedirect) {
      storageService.removeRequestedLocation();
    }
  }, [isAuthenticated, isLocationToRedirect]);

  if (!isAuthenticated) {
    if (isNewLocationToRedirect) {
      storageService.setRequestedLocation(location.pathname + location.search);
    }

    return <Navigate to={PATHS.LOGIN} />;
  }

  // This is done so that in case the route changes by any chance through
  // other means between the moment of request and the render we navigate to
  // the initially requested route.
  if (isLocationToRedirect) {
    return <Navigate to={requestedLocation} />;
  }

  return children;
};
