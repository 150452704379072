import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';

import { useTranslation } from '../../../../../shared';
import { sxProps } from './widget-final-status.styles';

export const WidgetFinalStatus: FC = () => {
  const { t } = useTranslation();

  return (
    <Typography component="span" sx={sxProps.text} variant="body2">
      {t('prospect.verification.status.widget')}
    </Typography>
  );
};
