import * as Yup from 'yup';
import 'yup-phone-lite';

import type {
  CreateProspectSerializerDTO,
  UnitAdvertisementSerializerDTO,
} from '../../../../../connectors/property';
import type { FieldConfig } from '../../../../shared';
import { DEFAULT_FIELD_VALUE } from '../../../../shared/form/form.const';

export const getFieldsConfig = (
  advertisement: UnitAdvertisementSerializerDTO,
): FieldConfig<CreateProspectSerializerDTO>[] => [
  {
    componentType: 'select',
    field: {
      // TODO: use proper model after: https://fredensborg.atlassian.net/browse/MB-696
      initialValue: advertisement.integrations[0]?.uuid,
      name: 'unitAdvertisementIntegration',
    },
    props: {
      labelKey: 'prospect.fields.integration',
      options: advertisement.integrations.map((advertisementIntegration) => ({
        label: advertisementIntegration.integration.name,
        value: advertisementIntegration.uuid,
      })),
      required: true,
    },
  },
  {
    componentType: 'input',
    field: {
      initialValue: DEFAULT_FIELD_VALUE,
      name: 'firstName',
    },
    props: {
      labelKey: 'user.fields.firstName',
      required: true,
      type: 'text',
    },
  },
  {
    componentType: 'input',
    field: {
      initialValue: DEFAULT_FIELD_VALUE,
      name: 'lastName',
    },
    props: {
      labelKey: 'user.fields.lastName',
      required: true,
      type: 'text',
    },
  },
  {
    componentType: 'input',
    field: {
      initialValue: DEFAULT_FIELD_VALUE,
      name: 'email',
      validationSchema: Yup.string().email('form.errors.email'),
    },
    props: {
      labelKey: 'user.fields.email',
      required: true,
      type: 'text',
    },
  },
  {
    componentType: 'input',
    field: {
      initialValue: DEFAULT_FIELD_VALUE,
      name: 'phoneNumber',
      validationSchema: Yup.string().phone('IN', 'form.errors.phone'),
    },
    props: {
      helperTextKey: 'user.fields.helperText.phoneNumber',
      labelKey: 'user.fields.phoneNumber',
      required: true,
      type: 'text',
    },
  },
];
