import FormControl from '@mui/material/FormControl';
import type { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import InputAdornment from '@mui/material/InputAdornment';
import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import type { FieldProps as FormikFieldProps } from 'formik';
import isFunction from 'lodash-es/isFunction';
import isNumber from 'lodash-es/isNumber';
import type { ElementType, FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { useTranslation } from '../../translations';
import { DEFAULT_FIELD_VALUE } from '../form.const';
import { getFirstErrorKey } from '../helpers';
import type { InputProps, InputValue } from './types';

type Props<TFormValues> = FormikFieldProps<InputValue, TFormValues> & {
  props: InputProps<TFormValues>;
};

export const InputField = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const { meta, field, form, props: fieldProps } = props;
  const { t } = useTranslation();
  const { error, value, touched } = meta;
  const { name, onChange, onBlur } = field;
  const { values } = form;
  const {
    disabled,
    disableWhen,
    endAdornment,
    fieldSx,
    formControlSx,
    helperTextKey,
    label,
    labelKey,
    placeholderKey,
    readonly,
    required,
    requiredWhen,
    startAdornmentIcon,
    type,
  } = fieldProps;
  const finalLabelKey = useMemo(() => {
    if (label) return label;

    return isFunction(labelKey) ? labelKey(values) : labelKey;
  }, [label, labelKey, values]);
  const errorMessageKey = useMemo(
    () => touched && getFirstErrorKey(error),
    [error, touched],
  );
  const isError = useMemo(() => Boolean(errorMessageKey), [errorMessageKey]);
  const isDisabled = useMemo(
    () => Boolean(disabled || readonly || (disableWhen && disableWhen(values))),
    [disableWhen, disabled, readonly, values],
  );
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );
  const handleChange: StandardInputProps['onChange'] = (event) => {
    onChange(event);
  };
  const handleKeyDown: TextFieldProps['onKeyDown'] = useCallback(
    (event) => {
      if (event.key !== 'Enter' || type === 'textarea') return;

      event.preventDefault();
    },
    [type],
  );
  const StartAdornmentIcon = useMemo(
    () =>
      (isFunction(startAdornmentIcon)
        ? startAdornmentIcon(values)
        : startAdornmentIcon) as ElementType,
    [startAdornmentIcon, values],
  );
  const helperText = useMemo(() => {
    if (isError && errorMessageKey) return t(errorMessageKey);
    if (helperTextKey) return t(helperTextKey);

    return undefined;
  }, [errorMessageKey, helperTextKey, isError, t]);

  return (
    <FormControl
      component="fieldset"
      disabled={isDisabled}
      error={isError}
      fullWidth
      required={isRequired && !readonly}
      sx={formControlSx}
    >
      <TextField
        disabled={isDisabled}
        error={isError}
        helperText={helperText}
        id={name}
        inputProps={{ 'data-testid': name }}
        onBlur={onBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...(placeholderKey && { placeholder: t(placeholderKey) })}
        sx={fieldSx}
        {...(type === 'number' && isNumber(value)
          ? { value }
          : { value: value || DEFAULT_FIELD_VALUE })}
        variant="outlined"
        {...(type !== 'textarea'
          ? { type }
          : {
              multiline: true,
              rows: 4,
            })}
        {...((endAdornment || startAdornmentIcon) && {
          InputProps: {
            ...(startAdornmentIcon && {
              startAdornment: (
                <InputAdornment position="start">
                  <StartAdornmentIcon height={24} width={24} />
                </InputAdornment>
              ),
            }),
            ...(endAdornment && {
              endAdornment: (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
            }),
          },
        })}
        {...(finalLabelKey && {
          InputLabelProps: { required: isRequired, shrink: true },
          label: t(finalLabelKey),
        })}
      />
    </FormControl>
  );
};
