import React from 'react';
import type { RouteObject } from 'react-router';
import { generatePath, Navigate } from 'react-router';
import { Outlet } from 'react-router-dom';

import { ACTIVITY_LOG_VIEW_PERMISSIONS } from '../+activity-log';
import { PermissionGuard } from '../+auth/shared/guards/permission.guard';
import { APP_MAIN_PATH } from '../routing';
import { componentLazyImport } from '../routing/routes.helper';
import { VIEW_PERMISSIONS as ATTACHMENTS_VIEW_PERMISSIONS } from '../shared/attachment/shared/consts/permission.const';
import { DefaultRouteTabContent } from '../shared/components/DefaultRouteTabContent/default-route-tab-view.component';
import PATHS, { TicketTypePath } from './paths.const';
import { READ_PERMISSIONS, TypeGuard } from './shared';

const NotFound = componentLazyImport(
  () => import('../shared/errors/NotFound/not-found.component'),
  'TICKET_NOT_FOUND',
);
const ActivityLogList = componentLazyImport(
  () => import('./ActivityLogList/activity-log-list.component'),
  'TICKET_ACTIVITY_LOG_LIST',
);
const DocumentList = componentLazyImport(
  () => import('./DocumentList/document-list.component'),
  'TICKET_DOCUMENT_LIST',
);
const Create = componentLazyImport(
  () => import('./Create/create.component'),
  'TICKET_CREATE',
);
const Details = componentLazyImport(
  () => import('./Details/details.component'),
  'TICKET_DETAILS',
);
const List = componentLazyImport(
  () => import('./List/list.component'),
  'TICKET_LIST',
);
const Update = componentLazyImport(
  () => import('./Update/update.component'),
  'TICKET_UPDATE',
);
const defaultTabs = [
  {
    component: <DocumentList />,
    expectedPermissions: ATTACHMENTS_VIEW_PERMISSIONS,
  },
  {
    component: <ActivityLogList />,
    expectedPermissions: ACTIVITY_LOG_VIEW_PERMISSIONS,
  },
];

const routes: RouteObject = {
  children: [
    {
      element: (
        <Navigate
          to={generatePath(PATHS.LIST, {
            type: TicketTypePath.SERVICE_REQUESTS,
          })}
        />
      ),
      path: '',
    },
    {
      children: [
        {
          element: (
            <PermissionGuard
              fallbackRoute={APP_MAIN_PATH}
              permissions={READ_PERMISSIONS}
            >
              <List />
            </PermissionGuard>
          ),
          path: '',
        },
        {
          element: <Create />,
          path: 'create',
        },
        { element: <Update />, path: ':id/update' },
        {
          children: [
            {
              element: <DefaultRouteTabContent tabs={defaultTabs} />,
              path: '',
            },
            { element: <DocumentList />, path: 'documents' },
            { element: <ActivityLogList />, path: 'logs' },
          ],
          element: <Details />,
          path: ':id',
        },
        { element: <NotFound />, path: '*' },
      ],
      element: (
        <TypeGuard>
          <Outlet />
        </TypeGuard>
      ),
      path: ':type',
    },
    { element: <NotFound />, path: '*' },
  ],
  element: <Outlet />,
  path: 'tickets',
};

export default routes;
