import * as Yup from 'yup';

import { DEFAULT_FIELD_VALUE } from '../../../../../shared';
import type { FieldsetConfig } from '../../../../../shared';
import type { DynamicValidationRules, FormValues } from '../form.types';

export const getFieldsetConfig = (
  dynamicValidationRules?: DynamicValidationRules,
): FieldsetConfig<FormValues>[] => [
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: DEFAULT_FIELD_VALUE,
          name: 'summary',
          validationSchema: Yup.string()
            .min(
              dynamicValidationRules?.title?.minLength ?? 1,
              'form.errors.number.min',
            )
            .max(
              dynamicValidationRules?.title?.maxLength ?? 1000,
              'form.errors.number.max',
            ),
        },
        props: {
          labelKey: 'advertisement.fields.summary',
          required: true,
          type: 'text',
        },
      },
    ],
    key: 'advertisement-title',
    titleKey: 'advertisement.fieldset.description.summary',
  },
  {
    fields: [
      {
        componentType: 'editor',
        field: {
          initialValue: DEFAULT_FIELD_VALUE,
          name: 'description',
          validationSchema: Yup.string().test('range', (value, context) => {
            const { createError, path } = context;
            const maxLength =
              dynamicValidationRules?.description?.maxLength ?? 65535;
            const minLength =
              dynamicValidationRules?.description?.minLength ?? 1;

            if (!value) {
              return createError({ message: 'form.errors.required', path });
            }

            const rawValue = value.replace(/<[^>]+>/g, '');

            // check value length without html
            if (rawValue.length < minLength) {
              return createError({ message: 'form.errors.number.min', path });
            }

            // check value length with html
            if (value.length > maxLength) {
              return createError({ message: 'form.errors.number.max', path });
            }

            return true;
          }),
        },
        props: {
          labelKey: 'advertisement.fields.description',
          required: true,
        },
      },
    ],
    key: 'advertisement-description',
    titleKey: 'advertisement.fieldset.description.description',
  },
];
