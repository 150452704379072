import Grid from '@mui/material/Grid';
import { Form as FormikForm, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import type { FC } from 'react';

import {
  FormButtons,
  FormField,
  FormFieldset,
  FormikEffect,
  GlobalError,
} from './components';
import { isFieldsetConfig } from './guards';
import {
  handleSubmit,
  prepareInitialValues,
  prepareValidationSchema,
} from './helpers';
import type { FormProps } from './types';

export const Form = <TFormValues,>(
  props: FormProps<TFormValues>,
): ReturnType<FC<FormProps<TFormValues>>> => {
  const {
    cancelLabelKey,
    config,
    fieldsConfig,
    formContentSx,
    formEffect,
    handleSuccess,
    inline = false,
    onCancel,
    onChange,
    showButtons = true,
    spacing = 4,
    submitLabelKey,
    wideButtons,
    validationSchema,
  } = props;
  const [globalError, setGlobalError] = useState<string | undefined>(undefined);
  const isReadonlyForm = useMemo(
    () =>
      fieldsConfig.every((config) =>
        isFieldsetConfig(config)
          ? config.fields.every((config) => config.props.readonly)
          : config.props.readonly,
      ),
    [fieldsConfig],
  );
  const areButtonsVisible = useMemo(
    () => showButtons && !isReadonlyForm,
    [isReadonlyForm, showButtons],
  );
  const initialValues = useMemo(
    () => prepareInitialValues(fieldsConfig),
    [fieldsConfig],
  );
  const validationSchemaToUse = useMemo(
    () => validationSchema ?? prepareValidationSchema(fieldsConfig),
    [fieldsConfig, validationSchema],
  );

  return (
    <Formik
      {...config}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit<TFormValues>(
        setGlobalError,
        handleSuccess,
        config.onSubmit,
      )}
      validationSchema={validationSchemaToUse}
    >
      {(formProps) => {
        const { values } = formProps;

        return (
          // Autocomplete cannot be disabled - https://bugs.chromium.org/p/chromium/issues/detail?id=587466
          <FormikForm data-testid="generic-form" noValidate>
            <FormikEffect fieldsConfig={fieldsConfig} onChange={onChange} />
            {formEffect && formEffect}
            <GlobalError errorMessage={globalError} />
            <Grid container spacing={spacing} sx={formContentSx}>
              {fieldsConfig.map((config) => {
                const commonProps = { isInline: inline, values };

                return isFieldsetConfig(config) ? (
                  <FormFieldset
                    config={config}
                    key={`form-fieldset-${config.key}`}
                    {...commonProps}
                  />
                ) : (
                  <FormField
                    config={config}
                    key={`form-input-${config.field.name}`}
                    {...commonProps}
                  />
                );
              })}
            </Grid>
            {areButtonsVisible && (
              <FormButtons<TFormValues>
                formProps={formProps}
                submitLabelKey={submitLabelKey}
                cancelLabelKey={cancelLabelKey}
                onCancel={onCancel}
                fullWidthButton={wideButtons}
              />
            )}
          </FormikForm>
        );
      }}
    </Formik>
  );
};
