import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useState } from 'react';

import type { CountrySerializerDTO } from '../../../../../connectors/property';
import type { UserRoleEnumDTO } from '../../../../../connectors/user';
import type { AsyncAutocompleteOption } from '../../../../shared';
import { useTranslation } from '../../../../shared';
import type { ContactUserId, User } from '../../types';
import { Assign } from '../Assign/assign.component';
import { ConfirmationDialog } from '../ConfirmationDialog/confirmation-dialog.component';
import {
  isReassignConfig,
  isRemoveConfig,
} from '../ConfirmationDialog/confirmation-dialog.const';
import type {
  ConfirmationConfig,
  PickedConfirmationConfig,
} from '../ConfirmationDialog/confirmation-dialog.type';
import { UserItem } from '../UserItem/user-item.component';

type Props = {
  users: User[];
  titleKey?: GenericTypes.TranslationLabel;
  assigningLabelKey?: GenericTypes.TranslationLabel;
  country?: CountrySerializerDTO['uuid'];
  excludedRoles?: UserRoleEnumDTO[];
  onAssignee$?: (
    option: NonNullable<AsyncAutocompleteOption<User['id']>>,
    setEditMode: Dispatch<SetStateAction<boolean>>,
  ) => Promise<void>;
  onReassign$?: (newUserId: User['id'], oldUserId: User['id']) => Promise<void>;
  onRemove$?: (
    id: ContactUserId,
    setEditMode: Dispatch<SetStateAction<boolean>>,
  ) => Promise<void>;
  reassignConfirmationDescriptionKey?: GenericTypes.TranslationLabel;
  removeConfirmationDescriptionKey?: GenericTypes.TranslationLabel;
  suggestionRoles?: UserRoleEnumDTO[];
};

export const UserList: FC<Props> = (props) => {
  const {
    assigningLabelKey,
    country,
    excludedRoles,
    onAssignee$,
    onReassign$,
    onRemove$,
    reassignConfirmationDescriptionKey,
    removeConfirmationDescriptionKey,
    suggestionRoles,
    titleKey,
    users,
  } = props;
  const { t } = useTranslation();
  const [isEditMode, setEditMode] = useState(false);
  const [dialogConfig, setDialogConfig] =
    useState<ConfirmationConfig>(undefined);
  const closeDialog = useCallback(() => {
    setDialogConfig(undefined);
  }, []);
  const [isFetching, setFetching] = useState(false);
  const handleDialogConfig = useCallback(
    (config: PickedConfirmationConfig) => {
      setDialogConfig((prevState) =>
        config
          ? {
              ...prevState,
              ...config,
              descriptionKey: isRemoveConfig(config)
                ? removeConfirmationDescriptionKey || ''
                : reassignConfirmationDescriptionKey || '',
            }
          : undefined,
      );
    },
    [reassignConfirmationDescriptionKey, removeConfirmationDescriptionKey],
  );
  const handleConfirmation$ = useCallback(() => {
    if (!dialogConfig) return Promise.reject();

    if (onRemove$ && isRemoveConfig(dialogConfig) && dialogConfig.id)
      return onRemove$(dialogConfig.id, setEditMode);

    if (
      onReassign$ &&
      isReassignConfig(dialogConfig) &&
      dialogConfig.newUser &&
      dialogConfig.oldUserId
    )
      return onReassign$(dialogConfig.newUser.value, dialogConfig.oldUserId);

    return Promise.reject();
  }, [dialogConfig, onReassign$, onRemove$]);

  return (
    <>
      {titleKey && (
        <Typography
          color="text.primary"
          component="div"
          fontWeight="bold"
          marginBottom={2}
          variant="h5"
        >
          {t(titleKey)}
        </Typography>
      )}
      <Grid container direction="column" spacing={2}>
        {users.map((user) => (
          <UserItem
            country={country}
            data={user}
            dialogConfig={dialogConfig}
            excludedRoles={excludedRoles}
            isDisabled={isFetching}
            isReassign={Boolean(onReassign$)}
            isRemove={Boolean(onRemove$)}
            key={`${user.id}-user-item`}
            onReassign$={
              !reassignConfirmationDescriptionKey ? onReassign$ : undefined
            }
            onRemove$={
              !removeConfirmationDescriptionKey ? onRemove$ : undefined
            }
            setDialogConfig={handleDialogConfig}
            setDisabled={setFetching}
            setEditMode={setEditMode}
            suggestionRoles={suggestionRoles}
          />
        ))}
        {assigningLabelKey && onAssignee$ && (
          <Assign
            assigningLabelKey={assigningLabelKey}
            country={country}
            excludedRoles={excludedRoles}
            isDisabled={isFetching}
            isEditMode={isEditMode}
            onAssignee$={onAssignee$}
            setDisabled={setFetching}
            setEditMode={setEditMode}
            suggestionRoles={suggestionRoles}
          />
        )}
      </Grid>
      {dialogConfig !== undefined && (
        <ConfirmationDialog
          callback$={handleConfirmation$}
          closeDialog={closeDialog}
          config={dialogConfig}
        />
      )}
    </>
  );
};
