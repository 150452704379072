import type { SelectOption } from '../../../shared';
import { ActorRole } from '../enums';

const actorRoleToTranslationLabelKeyMap: Record<
  ActorRole,
  GenericTypes.TranslationLabel
> = {
  [ActorRole.ASSIGNEE]: 'ticket.fields.assignee',
  [ActorRole.REPORTER]: 'ticket.fields.reporter.label',
};

export const getRoleTranslationLabelKey = (
  actorRole: ActorRole,
): GenericTypes.TranslationLabel =>
  actorRoleToTranslationLabelKeyMap[actorRole] || actorRole;

export const actorRoleOptions: SelectOption[] = Object.values(ActorRole).map(
  (actorRole) => ({
    labelKey: getRoleTranslationLabelKey(actorRole),
    value: actorRole,
  }),
);
