import type {
  PatchComplaintSerializerDTO,
  PatchContractRequestSerializerDTO,
  PatchGeneralRequestSerializerDTO,
  PatchInvoiceRequestSerializerDTO,
  PatchServiceRequestSerializerDTO,
  UniversalTicketSerializerDTO,
} from '../../../../connectors/ticket';
import { TicketTypeEnumDTO } from '../../../../connectors/ticket';
import { TicketTypePath } from '../../paths.const';
import { ticketClient } from '../../ticket.client';
import type { PatchRequest, Requests } from '../types';

export const getUpdateRequest$ = (
  data: PatchRequest,
  id: UniversalTicketSerializerDTO['uuid'],
  ticketType: TicketTypePath | UniversalTicketSerializerDTO['ticketType'],
): Promise<Requests> => {
  switch (ticketType) {
    case TicketTypeEnumDTO.Complaint:
    case TicketTypePath.COMPLAINTS: {
      return ticketClient.updateComplaint$(
        id,
        data as PatchComplaintSerializerDTO,
      );
    }
    case TicketTypeEnumDTO.ContractRequest:
    case TicketTypePath.CONTRACT_REQUESTS: {
      return ticketClient.updateContractRequest$(
        id,
        data as PatchContractRequestSerializerDTO,
      );
    }
    case TicketTypeEnumDTO.GeneralRequest:
    case TicketTypePath.GENERAL_REQUESTS: {
      return ticketClient.updateGeneralRequest$(
        id,
        data as PatchGeneralRequestSerializerDTO,
      );
    }
    case TicketTypeEnumDTO.InvoiceRequest:
    case TicketTypePath.INVOICE_REQUESTS: {
      return ticketClient.updateInvoiceRequest$(
        id,
        data as PatchInvoiceRequestSerializerDTO,
      );
    }
    case TicketTypeEnumDTO.ServiceRequest:
    case TicketTypePath.SERVICE_REQUESTS: {
      return ticketClient.updateServiceRequest$(
        id,
        data as PatchServiceRequestSerializerDTO,
      );
    }
    default: {
      return Promise.reject();
    }
  }
};
