import Box from '@mui/material/Box';
import React from 'react';
import type { FC } from 'react';

import { Logo } from '../Logo/logo.component';
import { sxProps } from './splash-screen.styles';

export const SplashScreen: FC = () => (
  <Box sx={sxProps.wrapper}>
    <Logo />
  </Box>
);
