import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    '& > svg': {
      mr: 2,
    },
    alignItems: 'center',
    display: 'flex',
  },
};
