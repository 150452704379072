import { generatePath } from 'react-router';

import { PROSPECT_PATHS } from '../../../../+prospect';
import {
  getTypePathBasedOnTicketType,
  TICKET_PATHS,
} from '../../../../+ticket';
import type {
  CommentWithProspectDataSerializerDTO,
  ProspectSerializerDTO,
} from '../../../../../connectors/property';
import type {
  CommentWithTicketDataSerializerDTO,
  UniversalTicketSerializerDTO,
} from '../../../../../connectors/ticket';

const isProspectComment = (
  comment:
    | CommentWithProspectDataSerializerDTO
    | CommentWithTicketDataSerializerDTO,
): comment is CommentWithProspectDataSerializerDTO => 'prospect' in comment;

const isTicketComment = (
  comment:
    | CommentWithProspectDataSerializerDTO
    | CommentWithTicketDataSerializerDTO,
): comment is CommentWithTicketDataSerializerDTO => 'ticket' in comment;

export const getCommentLink = (
  comment:
    | CommentWithProspectDataSerializerDTO
    | CommentWithTicketDataSerializerDTO,
): GenericTypes.URL | null => {
  if (isTicketComment(comment) && comment.ticket) {
    const type = getTypePathBasedOnTicketType(comment.ticket.ticketType);

    return generatePath(TICKET_PATHS.DETAILS, {
      id: comment.ticket.uuid,
      type,
    });
  }
  if (isProspectComment(comment) && comment.prospect)
    return generatePath(PROSPECT_PATHS.DETAILS, { id: comment.prospect.uuid });

  return null;
};

export const getReferenceNumber = (
  comment:
    | CommentWithProspectDataSerializerDTO
    | CommentWithTicketDataSerializerDTO,
): UniversalTicketSerializerDTO['referenceNumber'] | null => {
  return isTicketComment(comment) && comment.ticket
    ? comment.ticket.referenceNumber
    : null;
};

export const getStatus = (
  comment:
    | CommentWithProspectDataSerializerDTO
    | CommentWithTicketDataSerializerDTO,
): ProspectSerializerDTO['status'] | null => {
  return isProspectComment(comment) && comment.prospect
    ? comment.prospect.status
    : null;
};
