export enum AvailabilityMode {
  IMMEDIATELY = 'immediately',
  CUSTOM_DATE = 'customDate',
}

const availabilityTranslationLabelKeyMap: Record<
  AvailabilityMode,
  GenericTypes.TranslationLabel
> = {
  [AvailabilityMode.CUSTOM_DATE]:
    'advertisement.fieldset.options.availabilityCustomDateOption',
  [AvailabilityMode.IMMEDIATELY]:
    'advertisement.fieldset.options.availabilityImmediatelyOption',
};

export const getAvailabilityModeRadioOptions = Object.values(
  AvailabilityMode,
).map((value) => {
  return {
    labelKey: availabilityTranslationLabelKeyMap[value],
    value,
  };
});

export enum IncomeVerificationMode {
  MINIMAL = 'minimalIncome',
}

const incomeVerificationModeTranslationLabelKeyMap: Record<
  IncomeVerificationMode,
  GenericTypes.TranslationLabel
> = {
  [IncomeVerificationMode.MINIMAL]:
    'advertisement.fieldset.options.minimalIncomeOption',
};

export const getIncomeVerificationModeCheckboxOptions = Object.values(
  IncomeVerificationMode,
).map((value) => {
  return {
    labelKey: incomeVerificationModeTranslationLabelKeyMap[value],
    name: value,
  };
});

export enum InsuranceMode {
  REQUIRED = 'required',
}

const insuranceModeTranslationLabelKeyMap: Record<
  InsuranceMode,
  GenericTypes.TranslationLabel
> = {
  [InsuranceMode.REQUIRED]:
    'advertisement.fieldset.options.insuranceRequiredOption',
};

export const getInsuranceModeCheckboxOptions = Object.values(InsuranceMode).map(
  (value) => {
    return {
      labelKey: insuranceModeTranslationLabelKeyMap[value],
      name: value,
    };
  },
);

export enum VerificationMode {
  IMMEDIATELY = 'immediately',
  TRIGGER_MANUALLY = 'triggerManually',
}

const verificationModeTranslationLabelKeyMap: Record<
  VerificationMode,
  GenericTypes.TranslationLabel
> = {
  [VerificationMode.IMMEDIATELY]:
    'advertisement.fieldset.options.verificationImmediatelyOption',
  [VerificationMode.TRIGGER_MANUALLY]:
    'advertisement.fieldset.options.verificationTriggerManuallyOption',
};

export const getVerificationModeRadioOptions = Object.values(
  VerificationMode,
).map((value) => {
  return {
    labelKey: verificationModeTranslationLabelKeyMap[value],
    value,
  };
});
