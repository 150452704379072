import { ReactComponent as HotelBedroomIcon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/hotel-bedrooms/hotel-bedroom.svg';
import { ReactComponent as PencilWriteIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/edit/pen-write.svg';
import { ReactComponent as RealEstateDimensionsPlan1Icon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/maps-dimensions/real-estate-dimensions-plan-1.svg';
import React from 'react';
import { generatePath } from 'react-router';

import { getTicketTypeIcon } from '../../+ticket';
import {
  UNIT_PATHS,
  UnitCategoryPath,
  getUnitStatusTranslationLabelKey,
} from '../../+unit';
import { statusOptions as unitStatusOptions } from '../../+unit/shared/helpers/status.helper';
import type {
  UnitSerializerDTO,
  UnitStatusEnumDTO,
} from '../../../connectors/property';
import { TicketTypeEnumDTO } from '../../../connectors/ticket';
import type { ColumnConfig, FieldConfig } from '../../shared';
import {
  FilledImagePhotoIcon,
  getDisplayValue,
  ListActions,
  TranslationCell,
} from '../../shared';
import { DEFAULT_SELECT_OPTION } from '../../shared/form/form.const';

export const columnConfigs: ColumnConfig<UnitSerializerDTO>[] = [
  { getContent: () => <FilledImagePhotoIcon />, id: 'thumbnail' },
  {
    id: 'name',
    isMain: true,
    labelKey: 'unit.fields.name',
    propertyKey: 'name',
    sortConfig: { asc: 'name', desc: '-name' },
  },
  {
    getContent: ({ status }) => {
      const props = {
        labelKey: getUnitStatusTranslationLabelKey(status),
      };

      return <TranslationCell fontWeight={700} {...props} />;
    },
    id: 'status',
    labelKey: 'unit.fields.status.label',
  },
  {
    getContent: ({ grossArea }) => (
      <TranslationCell
        labelKey="metricUnit.squareMetre"
        options={{
          value: getDisplayValue(grossArea).value,
        }}
      />
    ),
    id: 'grossArea',
    labelKey: 'unit.fields.grossArea',
    sortConfig: { asc: 'gross_area', desc: '-gross_area' },
  },
  {
    id: 'rooms',
    labelIcon: RealEstateDimensionsPlan1Icon,
    propertyKey: 'rooms',
  },
  {
    id: 'bedrooms',
    labelIcon: HotelBedroomIcon,
    propertyKey: 'bedrooms',
  },
  {
    align: 'right',
    getContent: ({ uuid }) => {
      const elements = [
        {
          icon: getTicketTypeIcon(TicketTypeEnumDTO.ServiceRequest),
          key: 'service-request',
          to: generatePath(UNIT_PATHS.SERVICE_REQUEST_LIST, {
            category: UnitCategoryPath.HOMES,
            id: uuid,
          }),
        },
        {
          icon: PencilWriteIcon,
          key: 'unit-update',
          to: generatePath(UNIT_PATHS.UPDATE, {
            category: UnitCategoryPath.HOMES,
            id: uuid,
          }),
        },
      ];

      return <ListActions elements={elements} />;
    },
    id: 'actions',
    labelKey: 'list.actionsLabel',
  },
];

export type FilterValues = {
  status: UnitStatusEnumDTO | typeof DEFAULT_SELECT_OPTION.value;
};

export const INITIAL_FILTER_VALUES: FilterValues = {
  status: DEFAULT_SELECT_OPTION.value,
};

export const INITIAL_SORT = '-gross_area';

export const filterFieldsConfig: FieldConfig<FilterValues>[] = [
  {
    componentType: 'select',
    field: {
      initialValue: INITIAL_FILTER_VALUES.status,
      name: 'status',
    },
    props: {
      isFilter: true,
      labelKey: 'unit.fields.status.label',
      options: [DEFAULT_SELECT_OPTION, ...unitStatusOptions],
    },
  },
];
