import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  iconButton: {
    '& svg': {
      stroke: 'currentColor',
    },
    color: 'secondary.light',
  },
  iconButtonActive: {
    color: 'primary.main',
  },
};
