import { ReactComponent as FolderIcon } from '@heimstaden/icons-library/img/streamline-regular/files-folders/folders/folder.svg';
import { ReactComponent as DirectionButtonArrowsIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/move/direction-button-arrows.svg';
import { ReactComponent as RemoveIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import MuiIcon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import type { FC } from 'react';
import React from 'react';

import { useTranslation } from '../../../translations';
import { sxProps } from './gallery-description.styles';

const HINTS = [
  { icon: DirectionButtonArrowsIcon, labelKey: 'form.gallery.hint.drag' },
  { icon: RemoveIcon, labelKey: 'form.gallery.hint.remove' },
  { icon: FolderIcon, labelKey: 'form.gallery.hint.browse' },
];

export const GalleryDescription: FC = () => {
  const { t } = useTranslation();

  return (
    <List>
      {HINTS.map(({ icon: Icon, labelKey }) => (
        <ListItem key={`hint-${labelKey}`} sx={sxProps.listItem}>
          <ListItemIcon>
            <MuiIcon fontSize="small">
              <Icon />
            </MuiIcon>
          </ListItemIcon>
          <ListItemText>{t(labelKey)}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
};
