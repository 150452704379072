import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { Form as GenericForm } from '../../../../form';
import { useTranslation } from '../../../../translations';
import { buildCustomFile } from '../../helpers';
import { useAttachmentUploader } from '../../providers';
import { FormEffect } from '../FormEffect/form-effect.component';
import type { Values } from './form.model';
import { formConfig, getFieldsConfig } from './form.model';

export const Form: FC = () => {
  const { attachments, setAttachments } = useAttachmentUploader();
  const { t } = useTranslation();
  const currentAttachment = useMemo(
    () => attachments.find((attachment) => attachment.isCurrent),
    [attachments],
  );
  const fieldsConfig = useMemo(
    () =>
      getFieldsConfig(
        currentAttachment?.file,
        currentAttachment?.type,
        currentAttachment?.visibility,
      ),
    [
      currentAttachment?.file,
      currentAttachment?.type,
      currentAttachment?.visibility,
    ],
  );
  const onChange = useCallback(
    (values: Values) =>
      setAttachments((prevState) =>
        prevState.map((attachment) => {
          const { description, title, visibility } = values;
          const file = attachment.isCurrent
            ? buildCustomFile(attachment.file, title, description, visibility)
            : attachment.file;

          return {
            ...attachment,
            file,
            type: attachment.isCurrent ? values.type : attachment.type,
            visibility: attachment.isCurrent
              ? values.visibility
              : attachment.visibility,
          };
        }),
      ),
    [setAttachments],
  );
  const getAttachmentFieldsConfig = useCallback(
    (attachment) =>
      getFieldsConfig(
        attachment?.file,
        attachment?.type,
        attachment?.visibility,
      ),
    [],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {t('attachment.fieldset.files.title')}
        </Typography>
        <Typography>{t('attachment.fieldset.files.helperText')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <GenericForm
          config={formConfig}
          fieldsConfig={fieldsConfig}
          formEffect={
            <FormEffect getFieldsConfig={getAttachmentFieldsConfig} />
          }
          onChange={onChange}
          showButtons={false}
        />
      </Grid>
    </Grid>
  );
};
