import { ReactComponent as BinIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/delete/bin.svg';
import type { FC } from 'react';
import React from 'react';

import { ConfirmationDelete } from '../../ConfirmationDelete/confirmation-delete.component';
import { Icon } from '../Icon/icon.component';

type Props = {
  dataTestId: string;
  callback$: () => Promise<void>;
};

export const Delete: FC<Props> = (props) => {
  const { callback$ } = props;

  return (
    <ConfirmationDelete callback$={callback$}>
      {(handleOpen) => (
        <Icon icon={BinIcon} {...props} handleOpen={handleOpen} />
      )}
    </ConfirmationDelete>
  );
};
