import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import {
  DEFAULT_LANGUAGE,
  NL_LANGUAGE,
  PL_LANGUAGE,
  SUPPORTED_LANGS,
  TRANSLATION_CONTEXT,
} from './consts';
import enTranslations from './resources/en.json';
import nlTranslations from './resources/nl.json';
import plTranslations from './resources/pl.json';

const resources = {
  [DEFAULT_LANGUAGE]: {
    [TRANSLATION_CONTEXT]: enTranslations,
  },
  [NL_LANGUAGE]: {
    [TRANSLATION_CONTEXT]: nlTranslations,
  },
  [PL_LANGUAGE]: {
    [TRANSLATION_CONTEXT]: plTranslations,
  },
};

export const initTranslations = async (): Promise<void> => {
  await i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      defaultNS: TRANSLATION_CONTEXT,
      detection: {
        order: ['localStorage', 'navigator', 'htmlTag'],
      },
      fallbackLng: DEFAULT_LANGUAGE,
      interpolation: { escapeValue: false },
      load: 'languageOnly',
      missingKeyHandler: (lngs, ns, key, fallbackValue) => {
        if (!key) return;

        const error = new Error(
          `Missing translation for ${key} key in "${lngs}" languages`,
        );

        Sentry.captureException(error, {
          extra: {
            context: ns,
            fallbackValue,
            key,
            lngs,
          },
        });
      },
      nonExplicitSupportedLngs: true,
      resources,
      saveMissing: true,
      supportedLngs: SUPPORTED_LANGS,
    });
};
