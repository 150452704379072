import { ReactComponent as AddCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add-circle.svg';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import type { UserTypeEnumDTO } from '../../../../../connectors/user';
import { useTranslation } from '../../../../shared';
import PATHS from '../../../paths.const';
import { isUserTypeEditable } from '../../helpers';
import { sxProps } from './list-buttons-panel.styles';

type Props = {
  userType: UserTypeEnumDTO;
};

export const ListButtonsPanel: FC<Props> = (props) => {
  const { userType } = props;
  const { t } = useTranslation();
  const showPanel = useMemo(() => isUserTypeEditable(userType), [userType]);

  if (!showPanel) return null;

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="flex-end">
        <Button
          color="secondary"
          component={Link}
          size="large"
          to={PATHS.CREATE}
          variant="contained"
        >
          <Icon sx={sxProps.icon}>
            <AddCircleIcon />
          </Icon>
          {t('user.actions.createCustomer')}
        </Button>
      </Grid>
    </Grid>
  );
};
