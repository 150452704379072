import debounce from 'lodash-es/debounce';
import type { DebouncedFunc, DebounceSettings } from 'lodash-es/debounce';
import { useCallback } from 'react';

export const useDebounce = <T extends (...args: never) => unknown>(
  func: T,
  wait: number,
  options?: DebounceSettings,
  useDeps = true,
): DebouncedFunc<T> => {
  // used callback for memorize debunce function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(func, wait, options), useDeps ? [func] : []);
};
