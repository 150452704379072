import { ReactComponent as LayersLockIcon } from '@heimstaden/icons-library/img/streamline-regular/design/layers/layers-lock.svg';
import Box from '@mui/material/Box';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

import { AttachmentVisibilityEnumDTO } from '../../../../../../connectors/company';
import type { ListAttachmentSerializerDTO } from '../../../../../../connectors/ticket';
import {
  GalleryPreview,
  getGalleryPreviewImages,
  useAsync,
} from '../../../../../shared';
import { sxProps } from './gallery.styles';

export const Gallery: FC = () => {
  const { response } = useAsync<ListAttachmentSerializerDTO>();
  const [activeImageIdx, setActiveImageIdx] = useState(0);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const handleClick = useCallback(async (idx: number) => {
    setActiveImageIdx(idx);
    setPreviewOpen(true);
  }, []);
  const galleryImages = useMemo(
    () => getGalleryPreviewImages(response.results),
    [response.results],
  );

  if (galleryImages.length === 0) return null;

  return (
    <>
      <Box sx={sxProps.container}>
        {galleryImages.map((image, idx) => (
          <Box
            key={image.id}
            onClick={() => handleClick(idx)}
            sx={{ ...sxProps.image, backgroundImage: `url(${image.url})` }}
          >
            {image.visibility === AttachmentVisibilityEnumDTO.Internal && (
              <Box sx={sxProps.internalOnlyBadge}>
                <LayersLockIcon width={15} height={15} />
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <GalleryPreview
        activeIdx={activeImageIdx}
        images={galleryImages}
        open={isPreviewOpen}
        setActiveIdx={setActiveImageIdx}
        setOpen={setPreviewOpen}
      />
    </>
  );
};
