import { ReactComponent as RealEstateDimensionsMapIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/maps-dimensions/real-estate-dimensions-map.svg';
import MUIButton from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system/styleFunctionSx';
import React, { useMemo } from 'react';
import type { FC } from 'react';

import { useTranslation } from '../../../../../translations';
import type { Location } from '../../../map.type';
import { sxProps } from './button.styles';

type Props = {
  location: Location;
  sx?: SxProps<Theme>;
};

const googleMapsUrl = 'https://maps.google.com';

export const Button: FC<Props> = (props) => {
  const { location, sx } = props;
  const { t } = useTranslation();
  const href = useMemo(() => {
    const { lat, lng } = location;

    return `${googleMapsUrl}?q=${lat},${lng}`;
  }, [location]);
  const linSxProps = useMemo(() => ({ ...sxProps.link, ...sx }), [sx]);

  return (
    <MUIButton
      component={Link}
      variant="text"
      href={href}
      sx={linSxProps}
      target="_blank"
    >
      <RealEstateDimensionsMapIcon height={16} width={16} />
      <Typography variant="button" color="inherit">
        <strong>{t('map.button.text')}</strong>
      </Typography>
    </MUIButton>
  );
};
