import type { DetailedUnitSerializerDTO } from '../../../../connectors/property';
import type { UniversalTicketSerializerDTO } from '../../../../connectors/ticket';
import {
  EntityEnumDTO,
  TicketTypeEnumDTO,
} from '../../../../connectors/ticket';
import type { DetailedUserSerializerDTO } from '../../../../connectors/user';
import { formatStandardDateWrite } from '../../../shared';
import { DAYS_NUMBER_TO_FILTER_DONE_TICKET } from '../consts';
import type { Entity } from '../types';

export const isProfileUpdateTicket = (
  entityType: EntityEnumDTO,
  ticketType: TicketTypeEnumDTO,
): boolean =>
  ticketType === TicketTypeEnumDTO.GeneralRequest &&
  entityType === EntityEnumDTO.User;

export const isUnitEntity = (
  entity: Entity,
): entity is DetailedUnitSerializerDTO & {
  type: UniversalTicketSerializerDTO['entityType'];
} => entity.type === EntityEnumDTO.Unit;

export const isUserEntity = (
  entity: Entity,
): entity is DetailedUserSerializerDTO & {
  type: UniversalTicketSerializerDTO['entityType'];
} => entity.type === EntityEnumDTO.User;

export const getDateToFilteredColumns = (): GenericTypes.Date => {
  const now = new Date();
  const weekBeforeFromToday = now.setDate(
    now.getDate() - DAYS_NUMBER_TO_FILTER_DONE_TICKET,
  );

  return formatStandardDateWrite(new Date(weekBeforeFromToday));
};
