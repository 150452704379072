import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { Dialog as GenericDialog, Spinner } from '../../../../components';
import { useTranslation } from '../../../../translations';
import { useAttachmentUploader } from '../../providers';
import { Form } from '../Form/form.component';
import { Preview } from '../Preview/preview.component';
import { Uploader } from '../Uploader/uploader.component';

export const Dialog: FC = () => {
  const {
    attachments,
    customForm,
    closeDialog,
    isDialogOpen,
    isLoading,
    upload$,
  } = useAttachmentUploader();
  const { t } = useTranslation();
  const isSubmitDisabled = useMemo(
    () => attachments.some((attachment) => !attachment.isValid),
    [attachments],
  );

  return (
    <GenericDialog
      data-testid="attachment-dialog"
      actionButtonsPosition="flex-end"
      confirmButtonTitleKey="attachment.buttons.attach"
      enableActions={!isLoading && attachments.length > 0}
      fullWidth
      isConfirmDisabled={isSubmitDisabled}
      isOpen={isDialogOpen}
      maxWidth="md"
      onClose={closeDialog}
      onConfirm={upload$}
    >
      <Grid container spacing={3}>
        {isLoading ? (
          <Grid item xs={12}>
            <Spinner />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={attachments.length > 0 ? 6 : 12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {t('attachment.fieldset.files.title')}
                  </Typography>
                  <Typography>
                    {t('attachment.fieldset.files.helperText')}
                  </Typography>
                </Grid>
                {attachments.length > 0 && (
                  <Grid item xs={12}>
                    <Preview />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Uploader />
                </Grid>
              </Grid>
            </Grid>
            {attachments.length > 0 && (
              <Grid item xs={12} md={6}>
                {customForm || <Form />}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </GenericDialog>
  );
};
