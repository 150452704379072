import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import type { FC } from 'react';

import { useTranslation } from '../../translations';

type Props = {
  disabled: boolean;
  isSubmitting: boolean;
  fullWidth?: boolean;
  labelKey?: GenericTypes.TranslationLabel;
  loadingLabelKey?: GenericTypes.TranslationLabel;
};

export const SubmitButton: FC<Props> = (props) => {
  const { disabled, fullWidth, isSubmitting, labelKey, loadingLabelKey } =
    props;
  const { t } = useTranslation();
  const label = labelKey ? t(labelKey) : t('form.buttons.submit');
  const loadingLabel = loadingLabelKey
    ? t(loadingLabelKey)
    : t('form.buttons.loading');

  return (
    <LoadingButton
      color="primary"
      data-testid="form-navigation-button-submit"
      disabled={disabled}
      fullWidth={fullWidth}
      loading={isSubmitting}
      type="submit"
      variant="contained"
    >
      {isSubmitting && loadingLabel}
      {!isSubmitting && label}
    </LoadingButton>
  );
};

SubmitButton.displayName = 'SubmitButton';
