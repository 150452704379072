import { ReactComponent as ImageFileSearchIcon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/image-files/image-file-search.svg';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import type { CommonProcessSerializersAttachmentAttachmentSerializerDTO as AttachmentSerializerDTO } from '../../../../connectors/property';
import type { ColumnConfig } from '../../components';
import { ConfirmationDelete, ListActions } from '../../components';
import type { FieldConfig } from '../../form';
import { DEFAULT_MULTIPLE_SELECT_VALUE } from '../../form';
import { getDisplayValue } from '../../helpers';
import { documentTypeSelectOptions, getIcon } from '../shared';
import { VisibilityCell } from '../shared/components/VisibilityCell/visibility-cell.component';
import type { FilterValues } from './list.type';

export const getColumnsConfig = (
  handleDelete$: (id: AttachmentSerializerDTO['uuid']) => Promise<void>,
): ColumnConfig<AttachmentSerializerDTO>[] => [
  {
    getContent: ({ contentType }) => {
      const Icon = getIcon(contentType);

      return <Icon height={16} width={16} />;
    },
    id: 'type',
    labelKey: 'attachment.fields.contentType',
    propertyKey: 'contentType',
  },
  {
    getContent: ({ visibility }) => <VisibilityCell visibility={visibility} />,
    id: 'visibility',
    labelKey: 'attachment.fields.visibility',
  },
  {
    getContent: ({ title, name }) => <Typography>{title || name}</Typography>,
    id: 'name',
    labelKey: 'attachment.fields.title',
  },
  {
    getContent: ({ description }) => (
      <Box width={300}>
        <Typography>{getDisplayValue(description).value}</Typography>
      </Box>
    ),
    id: 'description',
    labelKey: 'attachment.fields.description',
  },
  {
    align: 'right',
    getContent: ({ uuid, url }) => {
      const elements = [
        {
          icon: ImageFileSearchIcon,
          key: 'preview',
          to: url,
        },
        {
          content: <ConfirmationDelete callback$={() => handleDelete$(uuid)} />,
          key: 'remove',
        },
      ];

      return <ListActions elements={elements} />;
    },
    id: 'actions',
    labelKey: 'list.actionsLabel',
  },
];

export const INITIAL_SORT = '-sequence_number';

export const INITIAL_FILTER_VALUES: FilterValues = {
  type: DEFAULT_MULTIPLE_SELECT_VALUE,
};

export const filterFieldsConfig: FieldConfig<FilterValues>[] = [
  {
    componentType: 'multiple-select',
    field: {
      initialValue: INITIAL_FILTER_VALUES.type,
      name: 'type',
    },
    props: {
      isFilter: true,
      labelKey: 'attachment.fields.type',
      options: documentTypeSelectOptions,
    },
  },
];
