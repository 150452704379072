import { Permission } from '../../../+auth/shared/enums/permission.enum';

export const CREATE_PERMISSIONS = [
  Permission.GLOBAL_WRITE,
  Permission.WRITE_CONTRACT,
  Permission.CAN_CREATE_LEASE_TEMPLATE,
];
export const VIEW_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.READ_CONTRACT,
  Permission.CAN_VIEW_LEASE_TEMPLATE,
];
