import { createContext } from 'react';

import type { AttachmentSerializerDTO } from '../../../../../connectors/company';
import type {
  EntityEnumDTO,
  DetailedPropertyObjectSerializerDTO,
  DetailedPropertySerializerDTO,
  DetailedUnitSerializerDTO,
  PropertySerializerDTO,
  PropertyObjectSerializerDTO,
  UnitSerializerDTO,
} from '../../../../../connectors/property';
import type { EntityDetailsData } from './type';

export type ContextValue = {
  clearData: () => void;
  getData$: (
    entity: EntityEnumDTO,
    id:
      | PropertySerializerDTO['uuid']
      | PropertyObjectSerializerDTO['uuid']
      | UnitSerializerDTO['uuid'],
  ) => Promise<void>;
  images: AttachmentSerializerDTO[];
  isUnitFetching: boolean;
  property: EntityDetailsData<DetailedPropertySerializerDTO> | undefined;
  propertyObject:
    | EntityDetailsData<DetailedPropertyObjectSerializerDTO>
    | undefined;
  unit: EntityDetailsData<DetailedUnitSerializerDTO> | undefined;
};
export const Context = createContext<ContextValue>({
  clearData: () => null,
  getData$: () => Promise.resolve(),
  images: [],
  isUnitFetching: false,
  property: undefined,
  propertyObject: undefined,
  unit: undefined,
});
