import type { FieldConfig, FormProps, SelectProps } from '../../form';
import { DEFAULT_SELECT_OPTION } from '../../form/form.const';
import type { LocalizationConfig } from '../../providers';
import type { FilterValues } from './localization-filter-panel.type';

export const INITIAL_VALUES: FilterValues = {
  city: DEFAULT_SELECT_OPTION.value,
  country: DEFAULT_SELECT_OPTION.value,
  region: DEFAULT_SELECT_OPTION.value,
};

export const isInitValue = (
  key: keyof FilterValues,
  value: string | undefined,
): boolean => INITIAL_VALUES[key] === value;

export const getFieldsConfig = (
  countries: LocalizationConfig['countryOptions'],
  fetchCities$: SelectProps<FilterValues>['callback$'],
  fetchRegions$: SelectProps<FilterValues>['callback$'],
  filters?: FilterValues,
): FieldConfig<FilterValues>[] => [
  {
    componentType: 'select',
    field: {
      initialValue: filters?.country || INITIAL_VALUES.country,
      name: 'country',
    },
    props: {
      isFilter: true,
      labelKey: 'localization.filterBy.country',
      options: [DEFAULT_SELECT_OPTION, ...countries],
      relatedFields: ['city', 'region'],
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.region || INITIAL_VALUES.region,
      name: 'region',
    },
    props: {
      callback$: fetchRegions$,
      disableWhen: ({ country }) =>
        !country || country === INITIAL_VALUES.country,
      isFilter: true,
      labelKey: 'localization.filterBy.region',
      options: [DEFAULT_SELECT_OPTION],
      relatedFields: ['city'],
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.city || INITIAL_VALUES.city,
      name: 'city',
    },
    props: {
      callback$: fetchCities$,
      disableWhen: ({ country }) =>
        !country || country === INITIAL_VALUES.country,
      isFilter: true,
      labelKey: 'localization.filterBy.city',
      options: [DEFAULT_SELECT_OPTION],
    },
  },
];

export const formConfig: FormProps<FilterValues>['config'] = {
  onSubmit: () => {
    // do nothing
  },
};
