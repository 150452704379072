import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import React, { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { generatePath, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../../../connectors/ticket';
import { CollectionViewMode, useTranslation } from '../../../../shared';
import PATHS from '../../../paths.const';
import { THUMBNAIL_PLACEHOLDER_PATH } from '../../consts';
import { isProfileUpdateTicket } from '../../helpers';
import { FeatureList } from '../FeatureList/feature-list.component';
import { StatusSwitcher } from '../StatusSwitcher/status-switcher.component';
import { sxProps } from './thumbnail-list-item.styles';

type Props = {
  item: UniversalTicketsWithAdditionalFieldsSerializerDTO;
};

export const ThumbnailListItem: FC<Props> = (props) => {
  const { item } = props;
  const { type } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const backgroundImage = useMemo(
    () => `url(${item.thumbnail || THUMBNAIL_PLACEHOLDER_PATH})`,
    [item.thumbnail],
  );
  const detailsLink = useMemo(
    () => generatePath(PATHS.DETAILS, { id: item.uuid, type }),
    [item.uuid, type],
  );
  const handleClick = useCallback(() => {
    if (!detailsLink) return;

    navigate(detailsLink);
  }, [detailsLink, navigate]);

  return (
    <ListItem
      sx={
        {
          ...sxProps.listItem,
          ...(detailsLink && sxProps.listItemPointerEvents),
        } as SxProps
      }
      onClick={handleClick}
    >
      <Grid container direction="row" wrap="nowrap">
        <Grid item sx={sxProps.imageColumn}>
          <Paper sx={{ ...sxProps.thumbnail, backgroundImage }} />
        </Grid>
        <Grid item sx={sxProps.contentColumn}>
          <Grid
            container
            direction="column"
            sx={sxProps.container}
            wrap="nowrap"
          >
            <Grid item xs={12}>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item sx={sxProps.headerColumn}>
                  <Typography noWrap variant="h3">
                    {isProfileUpdateTicket(item.entityType, item.ticketType)
                      ? t('ticket.details.profileUpdateTicket.title')
                      : item.summary}
                  </Typography>
                  <Typography color="secondary.light" noWrap variant="body2">
                    <strong>{item.referenceNumber}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <StatusSwitcher
                    country={item.countryUuid}
                    id={item.uuid}
                    status={item.status}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={sxProps.divider} />
            </Grid>
            <Grid item xs={12} sx={sxProps.contentRow}>
              <Grid container wrap="nowrap">
                <Grid item xs={12}>
                  <FeatureList data={item} viewMode={CollectionViewMode.LIST} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};
