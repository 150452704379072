import React from 'react';

import { UserName } from '../../../../../+user/shared';
import type { ComplaintDetailSerializerDTO } from '../../../../../../connectors/ticket';
import type { ColumnConfig } from '../../../../../shared';
import {
  FormattedDate,
  PLACEHOLDER,
  TranslationCell,
} from '../../../../../shared';
import { ComplaintTypeCell } from '../../ComplaintTypeCell/complaint-type-cell.component';
import { RequestCell } from '../../RequestCell/request-cell.component';
import { StatusSwitcher } from '../../StatusSwitcher/status-switcher.component';

export const columnsConfig: ColumnConfig<ComplaintDetailSerializerDTO>[] = [
  {
    getContent: ({ name, summary, ticketType }) => (
      <RequestCell id={name} summary={summary} type={ticketType} />
    ),
    id: 'request',
    labelKey: 'ticket.fields.request',
  },
  {
    getContent: ({ complaintType }) => (
      <ComplaintTypeCell type={complaintType} />
    ),
    id: 'complaintType',
    labelKey: 'ticket.fields.complaintType',
  },
  {
    getContent: ({ assignee }) =>
      assignee ? (
        <UserName user={assignee} />
      ) : (
        <TranslationCell labelKey="ticket.fields.defaultAssignee" />
      ),
    id: 'assignee',
    labelKey: 'ticket.fields.assignee',
  },
  {
    getContent: ({ createdAt }) => <FormattedDate date={createdAt} />,
    id: 'createdAt',
    labelKey: 'ticket.fields.createdDate',
  },
  {
    getContent: ({ dueDate }) =>
      dueDate ? <FormattedDate date={dueDate} /> : PLACEHOLDER,
    id: 'dueDate',
    labelKey: 'ticket.fields.dueDate',
  },
  {
    getContent: ({ countryUuid, status, uuid }) => (
      <StatusSwitcher country={countryUuid} id={uuid} status={status} />
    ),
    id: 'status',
    labelKey: 'ticket.fields.status',
  },
];
