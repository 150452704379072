import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../../theme';

export const sxProps: Record<string, SxProps<Theme>> = {
  body: {
    p: 2,
  },
  box: {
    p: 0,
  },
  footer: {
    backgroundColor: (theme) =>
      theme.palette.mode === 'light'
        ? palette.catskillWhite
        : theme.palette.background.paper,
    borderRadius: (theme) => theme.spacing(0, 0, 2, 2),
    p: 2,
  },
  header: {
    pb: 0,
    pt: 2,
    px: 2,
  },
  linkIcon: {
    fontSize: (theme) => theme.spacing(2),
    ml: 1,
    verticalAlign: 'middle',
  },
};
