import { useFormikContext } from 'formik';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';

import { AdvertisementPlatformEnumDTO } from '../../../../../../connectors/company';
import { EntityEnumDTO } from '../../../../../../connectors/property';
import { DEFAULT_CHECKBOX_VALUE } from '../../../../../shared';
import {
  useAdvertisementConfig,
  useAdvertisementForm,
} from '../../../providers';
import { getDynamicValidationSchema } from '../form.helper';
import type { FormValues } from '../form.types';
import { getInitialDescriptionValue } from './data-effect.helper';

export const DataEffect: FC = () => {
  const {
    getIntegrationsConfig,
    fetchValidationRules$,
    setDynamicValidationRules,
    validationRules,
  } = useAdvertisementConfig();
  const { clearData, getData$, property, propertyObject, unit } =
    useAdvertisementForm();
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const integrations = useMemo(
    () => getIntegrationsConfig(values.country),
    [getIntegrationsConfig, values.country],
  );

  useEffect(() => {
    const heimstadenIntegration = integrations.find(
      (integration) =>
        integration.advertisementPlatform ===
        AdvertisementPlatformEnumDTO.Heimstaden,
    );

    setFieldValue(
      'integrations',
      heimstadenIntegration
        ? [heimstadenIntegration.uuid]
        : DEFAULT_CHECKBOX_VALUE,
    );
  }, [integrations, setFieldValue, values.country]);

  useEffect(() => {
    clearData();
  }, [clearData, values.country, values.city]);

  useEffect(() => {
    setFieldValue('attachments', []);
  }, [
    setFieldValue,
    values.property?.value,
    values.propertyObject?.value,
    values.unit?.value,
  ]);

  useEffect(() => {
    if (!property || !propertyObject || !unit) return;

    const value = getInitialDescriptionValue(property, propertyObject, unit);

    setFieldValue('description', value);
  }, [property, propertyObject, setFieldValue, unit]);

  useEffect(() => {
    if (!values.property?.value) return;

    getData$(EntityEnumDTO.Property, values.property.value);
  }, [getData$, values.property?.value]);

  useEffect(() => {
    if (!values.propertyObject?.value) return;

    getData$(EntityEnumDTO.PropertyObject, values.propertyObject.value);
  }, [getData$, values.propertyObject?.value]);

  useEffect(() => {
    if (!values.unit?.value) return;

    getData$(EntityEnumDTO.Unit, values.unit.value);
  }, [getData$, values.unit?.value]);

  useEffect(() => {
    if (!values.country) return;

    fetchValidationRules$(values.country);
  }, [fetchValidationRules$, values.country]);

  useEffect(() => {
    if (!integrations || values.integrations.length < 1) return;

    const dynamicSchema = getDynamicValidationSchema(
      integrations,
      values.integrations,
      validationRules,
    );

    setDynamicValidationRules(dynamicSchema);
  }, [
    integrations,
    setDynamicValidationRules,
    validationRules,
    values.integrations,
  ]);

  return null;
};
