import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import i18next from 'i18next';
import 'nprogress/nprogress.css';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './app/+auth';
import { App } from './app/app.component';
import {
  getEnvValue,
  SettingsProvider,
  TranslationsProvider,
} from './app/shared';
import './index.css';
import reportWebVitals from './reportWebVitals';

const appVersion = getEnvValue('APP_VERSION');
const environment = getEnvValue('ENV_NAME');
const sentryDsn = getEnvValue('SENTRY_DSN');

// eslint-disable-next-line no-console
console.info(`App version: ${appVersion}`);

if (environment && sentryDsn) {
  const appName = getEnvValue('APP_NAME');
  const tracesSampleRate = getEnvValue('SENTRY_TRACES_SAMPLE_RATE');

  Sentry.init({
    beforeSend: (event, hint) => {
      if (
        hint?.originalException instanceof Error &&
        hint.originalException.name === 'ChunkLoadError'
      )
        return null;

      return event;
    },
    dsn: sentryDsn,
    environment,
    integrations: [new Integrations.BrowserTracing()],
    release: `${appName}@${appVersion}`,
    tracesSampleRate: tracesSampleRate ? Number(tracesSampleRate) : undefined,
  });

  Sentry.setTag('project', appName);
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { cacheTime: 0 } },
});

ReactDOM.render(
  <StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <I18nextProvider i18n={i18next}>
        <TranslationsProvider>
          <SettingsProvider>
            <BrowserRouter>
              <AuthProvider>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </AuthProvider>
            </BrowserRouter>
          </SettingsProvider>
        </TranslationsProvider>
      </I18nextProvider>
    </LocalizationProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
