import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  helperText: {
    color: (theme) => theme.palette.secondary.light,
    fontSize: (theme) => theme.typography.pxToRem(12),
    m: 0,
  },
  label: {
    fontSize: (theme) => theme.typography.pxToRem(14),
    fontWeight: (theme) => theme.typography.fontWeightBold,
    ml: 0,
  },
};
