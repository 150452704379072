import { ReactComponent as ArchitectureDoorIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as RealEstateBuildingHouseIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/real-estate/real-estate-building-house.svg';

import { getUnitAddress } from '../+unit';
import type {
  AttachmentSequenceSerializerDTO,
  CitySerializerDTO,
  CountrySerializerDTO,
} from '../../connectors/company';
import { GeneralAPI } from '../../connectors/company';
import {
  EntityEnumDTO,
  PropertyObjectsAPI,
  UnitCategoryEnumDTO,
} from '../../connectors/property';
import type {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
  CommonProcessSerializersAttachmentAttachmentSerializerDTO as AttachmentSerializerDTO, // eslint-disable-line max-len
  ContactSerializerDTO,
  CoordinatesSerializerDTO,
  CreateContactSerializerDTO,
  CreatePropertyObjectSerializerDTO,
  DetailedPropertyObjectSerializerDTO,
  ListAttachmentSerializerDTO,
  ListContactSerializerDTO,
  ListLogSerializerDTO,
  ListPropertyObjectsSerializerDTO,
  ListUnitsSerializerDTO,
  PatchPropertyObjectSerializerDTO,
  PropertyObjectReassignSerializerDTO,
  PropertyObjectSerializerDTO,
  PropertySerializerDTO,
  UnitSerializerDTO,
} from '../../connectors/property';
import type { AttachmentVisibilityEnumDTO } from '../../connectors/ticket';
import { TicketsAPI } from '../../connectors/ticket';
import type { UserRoleEnumDTO } from '../../connectors/user';
import { mieInstance } from '../mie.instance';
import type {
  AsyncAutocompleteOption,
  AttachmentFilterValues,
  CustomFile,
  ListQueryParams,
  LocalizationFilterValues,
} from '../shared';
import { getEnvValue } from '../shared/helpers/env.helper';
import type { FilterValues as FacilityFilterValues } from './FacilityList/facility-list.model';
import type { FilterValues as HomeFilterValues } from './HomeList/home-list.model';
import { getAddress } from './shared';

class PropertyObjectClient {
  private client;

  private generalClient;

  private ticketClient;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.client = new PropertyObjectsAPI(undefined, baeBath, mieInstance);
    this.generalClient = new GeneralAPI(undefined, baeBath, mieInstance);
    this.ticketClient = new TicketsAPI(undefined, baeBath, mieInstance);
  }

  public getCoordinates$ = async (
    queryParams?: ListQueryParams<LocalizationFilterValues>,
  ): Promise<CoordinatesSerializerDTO[]> => {
    const { filters, search } = { ...queryParams };
    const { city, country, region } = { ...filters };
    const response =
      await this.client.getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(
        {
          cityUuid: city,
          countryUuid: country,
          regionUuid: region,
          search,
        },
      );

    return response.data;
  };

  public getList$ = async (
    queryParams?: ListQueryParams<LocalizationFilterValues>,
  ): Promise<ListPropertyObjectsSerializerDTO> => {
    const { filters, page, pageSize, search, tab } = { ...queryParams };
    const { country, city, region } = { ...filters };
    const response = await this.client.getPropertyObjectsPropertyObjectsGet({
      category: tab,
      cityUuid: city,
      countryUuid: country,
      page,
      pageSize,
      regionUuid: region,
      search,
    });

    return response.data;
  };

  public getFacilities$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    queryParams?: ListQueryParams<FacilityFilterValues>,
  ): Promise<ListUnitsSerializerDTO> => {
    const { filters, page, pageSize, search, sort } = { ...queryParams };
    const { category, status } = { ...filters };
    const response =
      await this.client.getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(
        {
          category: category || undefined,
          excludeCategory: UnitCategoryEnumDTO.ResidentialHomes,
          order: sort,
          page,
          pageSize,
          propertyObjectUuid: id,
          search,
          status: status || undefined,
        },
      );

    return response.data;
  };

  public getHomes$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    queryParams?: ListQueryParams<HomeFilterValues>,
  ): Promise<ListUnitsSerializerDTO> => {
    const { filters, page, pageSize, search, sort } = { ...queryParams };
    const { status } = { ...filters };
    const response =
      await this.client.getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(
        {
          category: UnitCategoryEnumDTO.ResidentialHomes,
          order: sort,
          page,
          pageSize,
          propertyObjectUuid: id,
          search,
          status: status || undefined,
        },
      );

    return response.data;
  };

  public getDetails$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
  ): Promise<DetailedPropertyObjectSerializerDTO> => {
    const response =
      await this.client.getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(
        { propertyObjectUuid: id },
      );

    return response.data;
  };

  public getLogs$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    queryParams?: ListQueryParams,
  ): Promise<ListLogSerializerDTO> => {
    const { page, pageSize } = { ...queryParams };
    const response =
      await this.client.getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(
        {
          page,
          pageSize,
          propertyObjectUuid: id,
        },
      );

    return response.data;
  };

  public update$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    values: PatchPropertyObjectSerializerDTO,
  ): Promise<PropertyObjectSerializerDTO> => {
    const response =
      await this.client.patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(
        {
          patchPropertyObjectSerializerDTO: values,
          propertyObjectUuid: id,
        },
      );

    return response.data;
  };

  public create$ = async (
    values: CreatePropertyObjectSerializerDTO,
  ): Promise<PropertyObjectSerializerDTO> => {
    const response = await this.client.createPropertyObjectPropertyObjectsPost({
      createPropertyObjectSerializerDTO: values,
    });

    return response.data;
  };

  public delete$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
  ): Promise<void> => {
    await this.client.deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(
      { propertyObjectUuid: id },
    );
  };

  public getSuggestions$ = async (
    query: string,
    city?: CitySerializerDTO['uuid'],
    country?: CountrySerializerDTO['uuid'],
    property?: PropertySerializerDTO['uuid'],
  ): Promise<
    AsyncAutocompleteOption<PropertyObjectSerializerDTO['uuid']>[]
  > => {
    const response = await this.client.getPropertyObjectsPropertyObjectsGet({
      cityUuid: city,
      countryUuid: country,
      propertyUuid: property,
      search: query,
    });

    return response.data.results.map((result) => {
      const address = getAddress(
        result.addressLine,
        result.city,
        result.streetNumber,
      );

      return {
        icon: RealEstateBuildingHouseIcon,
        label: `${result.name} (${address})`,
        value: result.uuid,
      };
    });
  };

  public getHomeSuggestions$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    query: string,
  ): Promise<AsyncAutocompleteOption<UnitSerializerDTO['uuid']>[]> => {
    const response =
      await this.client.getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(
        {
          category: UnitCategoryEnumDTO.ResidentialHomes,
          propertyObjectUuid: id,
          search: query,
        },
      );

    return response.data.results.map((result) => {
      const address = getUnitAddress(
        result.addressLine || '',
        result.city,
        result.streetNumber || '',
      );

      return {
        icon: ArchitectureDoorIcon,
        label: `${result.name} (${address})`,
        value: result.uuid,
      };
    });
  };

  public getAttachments$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    category?: AttachmentCategoryEnumDTO,
    queryParams?: ListQueryParams<AttachmentFilterValues>,
  ): Promise<ListAttachmentSerializerDTO> => {
    const {
      filters,
      page,
      pageSize = 100,
      search,
      sort = 'sequence_number',
    } = { ...queryParams };
    const { type } = { ...filters };
    const response =
      await this.client.getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(
        {
          category,
          order: sort,
          page,
          pageSize,
          propertyObjectUuid: id,
          search,
          type: type?.join(',') || undefined,
        },
      );

    return response.data;
  };

  public uploadAttachments$ = async (
    category: AttachmentCategoryEnumDTO,
    file: CustomFile,
    type: AttachmentTypeEnumDTO,
    id: PropertyObjectSerializerDTO['uuid'],
    visibility: AttachmentVisibilityEnumDTO,
  ): Promise<AttachmentSerializerDTO[]> => {
    const response =
      await this.client.uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(
        {
          attachmentCategory: category,
          attachmentType: type,
          description: file.description,
          files: [file],
          propertyObjectUuid: id,
          title: file.title,
          visibility,
        },
      );

    return response.data;
  };

  public updateAttachment$ = async (
    attachment: AttachmentSerializerDTO,
    id: PropertyObjectSerializerDTO['uuid'],
  ): Promise<AttachmentSerializerDTO> => {
    const response =
      await this.generalClient.patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch(
        {
          attachmentUuid: attachment.uuid,
          entityType: EntityEnumDTO.PropertyObject,
          entityUuid: id,
          patchAttachmentSerializerDTO: attachment,
        },
      );

    return response.data;
  };

  public updateAttachmentsSequence$ = async (
    sequence: AttachmentSequenceSerializerDTO[],
  ): Promise<AttachmentSequenceSerializerDTO[]> => {
    const response =
      await this.generalClient.updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut(
        { listAttachmentSequenceSerializerDTO: { attachments: sequence } },
      );

    return response.data.results;
  };

  public deleteAttachment$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    attachmentId: AttachmentSerializerDTO['uuid'],
  ): Promise<void> => {
    await this.client.deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(
      { attachmentUuid: attachmentId, propertyObjectUuid: id },
    );
  };

  public downloadAttachment$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    attachmentId: AttachmentSerializerDTO['uuid'],
  ): Promise<string> => {
    const response =
      await this.client.downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(
        { attachmentUuid: attachmentId, propertyObjectUuid: id },
        { responseType: 'blob' },
      );

    return response.data;
  };

  public addContact$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    userId: CreateContactSerializerDTO['userUuid'],
  ): Promise<ContactSerializerDTO> => {
    const response =
      await this.client.addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(
        {
          createContactSerializerDTO: { userUuid: userId },
          propertyObjectUuid: id,
        },
      );

    return response.data;
  };

  public getContacts$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    queryParams?: ListQueryParams<{
      excludedRole?: UserRoleEnumDTO[];
      role?: UserRoleEnumDTO[];
    }>,
  ): Promise<ListContactSerializerDTO> => {
    const { filters, search } = { ...queryParams };
    const { excludedRole, role } = { ...filters };
    const response =
      await this.client.getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(
        {
          excludeRole: excludedRole?.join(','),
          propertyObjectUuid: id,
          role: role?.join(','),
          search,
        },
      );

    return response.data;
  };

  public deleteContact$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    contactId: ContactSerializerDTO['uuid'],
  ): Promise<void> => {
    await this.client.deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(
      { contactUuid: contactId, propertyObjectUuid: id },
    );
  };

  public reassignContactForPropertyObject$ = async (
    id: PropertyObjectSerializerDTO['uuid'],
    newJanitorId: PropertyObjectReassignSerializerDTO['newJanitorUuid'],
    oldJanitorId: PropertyObjectReassignSerializerDTO['oldJanitorUuid'],
  ): Promise<void> => {
    await this.client.reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(
      {
        propertyObjectReassignSerializerDTO: {
          newJanitorUuid: newJanitorId,
          oldJanitorUuid: oldJanitorId,
        },
        propertyObjectUuid: id,
      },
    );
  };
}
export const propertyObjectClient = new PropertyObjectClient();
