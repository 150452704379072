import { ReactComponent as CalendarCheck1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-check-1.svg';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import type { FC, Key, ReactElement } from 'react';
import React, { useMemo } from 'react';

import { Feature, FormattedDate } from '../../../../../../shared';
import {
  getDepartmentTypeTranslationLabelKey,
  getPriorityTypeTranslationLabelKey,
} from '../../../../consts';
import type { ServiceRequestValues } from '../../../../types';
import { PriorityIcon } from '../../../PriorityIcon/priority-icon.component';
import { ServiceRequestDetailsFeatureList } from '../../../ServiceRequestDetailsFeatureList/service-request-details-feature-list.component';
import { LocationFeature } from '../LocationFeature/location-feature.component';

type Element = {
  component: ReactElement;
  key: Key;
};

const KEY_PREFIX = 'service-request-summary-step-';

export const Sidebar: FC = () => {
  const { values } = useFormikContext<ServiceRequestValues>();
  const elements = useMemo<Element[]>(
    () => [
      {
        component: <LocationFeature />,
        key: 'location',
      },
      { component: <Divider />, key: 'divider-1' },
      {
        component: (
          <ServiceRequestDetailsFeatureList
            damagedItem={values.damagedItem}
            issueLocation={values.issueLocation}
            issueSubtype={values.issueSubtype}
            issueType={values.issueType}
            roomWithIssue={values.roomWithIssue}
          />
        ),
        key: 'service-request-feature-list',
      },
      { component: <Divider />, key: 'divider-2' },
      ...(values.department
        ? [
            {
              component: (
                <Feature
                  dataTestId="department-category"
                  titleKey="ticket.fields.department"
                  valueKey={getDepartmentTypeTranslationLabelKey(
                    values.department,
                  )}
                />
              ),
              key: 'department',
            },
          ]
        : []),
      ...(values.dueDate
        ? [
            {
              component: (
                <Feature
                  dataTestId="due-date"
                  icon={<CalendarCheck1Icon />}
                  titleKey="ticket.fields.dueDate"
                  value={<FormattedDate date={values.dueDate} />}
                />
              ),
              key: 'due-date',
            },
          ]
        : []),
      {
        component: (
          <Feature
            dataTestId="priority-status"
            icon={<PriorityIcon priority={values.priority} />}
            titleKey="ticket.fields.priority"
            valueKey={getPriorityTypeTranslationLabelKey(values.priority)}
          />
        ),
        key: 'priority',
      },
    ],
    [
      values.damagedItem,
      values.department,
      values.dueDate,
      values.issueLocation,
      values.issueSubtype,
      values.issueType,
      values.priority,
      values.roomWithIssue,
    ],
  );

  return (
    <Grid container direction="row" spacing={2}>
      {elements.map((element) => (
        <Grid item key={`${KEY_PREFIX}-${element.key}`} xs={12}>
          {element.component}
        </Grid>
      ))}
    </Grid>
  );
};
