import { accordionClasses, badgeClasses } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../theme';

export const sxProps: Record<string, SxProps<Theme>> = {
  accordion: {
    [`&.${accordionClasses.expanded}`]: {
      my: 0,
    },
    '&::before': {
      content: 'none',
    },
    boxShadow: 'none',
  },
  accordionDetails: {
    backgroundColor: palette.catskillWhite,
    borderRight: '4px solid',
    borderRightColor: 'primary.main',
    pl: 8,
    py: 0,
  },
  accordionSummary: {
    '& svg, & svg path': {
      color: 'primary.main',
      stroke: palette.waikawaGray,
    },
    [`&.${accordionClasses.expanded}`]: {
      '& svg, & svg path': {
        color: 'primary.main',
        stroke: (theme) => theme.palette.primary.main,
      },
      color: (theme) => theme.palette.secondary.dark,
      minHeight: '56px',
    },
    '&:hover': {
      backgroundColor: palette.catskillWhite,
      color: (theme) => theme.palette.secondary.dark,
    },
    color: palette.waikawaGray,
    fontSize: (theme) => theme.typography.pxToRem(18),
    fontWeight: (theme) => theme.typography.fontWeightBold,
    lineHeight: (theme) => theme.typography.pxToRem(20),
    minHeight: '56px',
    my: 0,
    px: 4,
  },
  badge: {
    [`& .${badgeClasses.standard}`]: {
      borderRadius: '50%',
      bottom: (theme) => theme.spacing(1.5),
    },
  },
  icon: {
    height: 24,
    minWidth: 24,
    mr: 1,
    width: 24,
  },
};
