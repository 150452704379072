import { LanguageDTO } from '../../../connectors/company';

export const PLACEHOLDER = '-';
export const DEFAULT_LANGUAGE = LanguageDTO.En;
export const NL_LANGUAGE = LanguageDTO.Nl;
export const PL_LANGUAGE = LanguageDTO.Pl;
export const SUPPORTED_LANGS: LanguageDTO[] = [
  DEFAULT_LANGUAGE,
  NL_LANGUAGE,
  PL_LANGUAGE,
];
export const SUPPORTED_LANG_OPTIONS = [
  {
    code: DEFAULT_LANGUAGE,
    icon: '/assets/flags/en.svg',
    labelKey: 'languages.en',
  },
  {
    code: NL_LANGUAGE,
    icon: '/assets/flags/nl.svg',
    labelKey: 'languages.nl',
  },
  {
    code: PL_LANGUAGE,
    icon: '/assets/flags/pl.svg',
    labelKey: 'languages.pl',
  },
];
export const TRANSLATION_CONTEXT = 'translation';
