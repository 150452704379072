import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import type { FC } from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { useAuth } from '../../../../+auth';
import { getUserRoleTranslationLabelKey, USER_PATHS } from '../../../../+user';
import { useTranslation } from '../../../translations';
import { Box } from '../../Box/box.component';
import { sxProps } from './profile.styles';

export const Profile: FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const profileLink = useMemo(
    () =>
      user?.uuid ? generatePath(USER_PATHS.DETAILS, { id: user.uuid }) : '',
    [user?.uuid],
  );
  const roleKey = useMemo(
    () => getUserRoleTranslationLabelKey(user?.roles || []),
    [user?.roles],
  );

  if (!user) return null;

  return (
    <Box sx={sxProps.box}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Avatar src={user.profilePicture} sx={sxProps.avatar} />
              </Grid>
            </Grid>
            {profileLink && (
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Link
                    color="primary.contrastText"
                    component={RouterLink}
                    to={profileLink}
                    variant="subtitle2"
                  >
                    {t('user.details.link')}
                  </Link>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                align="center"
                color="primary.contrastText"
                variant="h5"
                noWrap
              >
                {user.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" color="primary.contrastText">
                {t('user.details.roleLabel')} <strong>{t(roleKey)}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
