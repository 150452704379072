import createSvgIcon from '@mui/material/utils/createSvgIcon';
import React from 'react';

export const FilledContentSendIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      d="M1.67508 17.5L19.1667 10L1.67508 2.5L1.66675 8.33333L14.1667
  10L1.66675 11.6667L1.67508 17.5Z"
      fill="currentColor"
    />
  </svg>,

  'FilledContentSend',
);
