import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    minHeight: '100%',
    px: [2, 2, 4, 6],
    py: 3,
  },
};
