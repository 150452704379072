import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  activeFormLabel: {
    borderColor: 'secondary.light',
  },
  content: {
    width: '100%',
  },
  formLabel: {
    '&:last-of-type': {
      mb: 0,
    },
    border: '2px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    mb: 2,
    px: [1, 1, 2],
    py: 2,
  },
  nestedFields: {
    mt: 1,
  },
  radio: {
    height: 24,
    mr: [1, 1, 2],
    width: 24,
  },
};
