/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum UserRoleEnumDTO {
    SuperUser = 'SuperUser',
    ExternalCatalog = 'ExternalCatalog',
    CountryManager = 'CountryManager',
    CustomerService = 'CustomerService',
    BackOffice = 'BackOffice',
    Janitor = 'Janitor',
    Contractor = 'Contractor',
    SystemAdministrator = 'SystemAdministrator',
    LettingAgent = 'LettingAgent',
    AssetManager = 'AssetManager',
    PropertyManager = 'PropertyManager',
    ReadOnlyCountry = 'ReadOnlyCountry',
    ReadOnlyAll = 'ReadOnlyAll',
    FinanceManager = 'FinanceManager',
    Tenant = 'Tenant',
    Prospect = 'Prospect'
}



