/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { OlxAuthorizeResponseSerializerDTO } from '../dto';
// @ts-ignore
import { OlxWebhookSerializerDTO } from '../dto';
/**
 * IntegrationsAPI - axios parameter creator
 * @export
 */
export const IntegrationsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Olx Authorize
         * @param {string} code 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxAuthorizePromotionsIntegrationsOlxAuthGet: async (code: string, state: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('olxAuthorizePromotionsIntegrationsOlxAuthGet', 'code', code)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('olxAuthorizePromotionsIntegrationsOlxAuthGet', 'state', state)
            const localVarPath = `/promotions/integrations/olx/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Olx Trigger Authorization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/integrations/olx/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Olx Webhook
         * @param {OlxWebhookSerializerDTO} olxWebhookSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxWebhookPromotionsIntegrationsOlxWebhookPost: async (olxWebhookSerializerDTO: OlxWebhookSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'olxWebhookSerializerDTO' is not null or undefined
            assertParamExists('olxWebhookPromotionsIntegrationsOlxWebhookPost', 'olxWebhookSerializerDTO', olxWebhookSerializerDTO)
            const localVarPath = `/promotions/integrations/olx/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(olxWebhookSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsAPI - functional programming interface
 * @export
 */
export const IntegrationsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Olx Authorize
         * @param {string} code 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async olxAuthorizePromotionsIntegrationsOlxAuthGet(code: string, state: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.olxAuthorizePromotionsIntegrationsOlxAuthGet(code, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Olx Trigger Authorization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OlxAuthorizeResponseSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Olx Webhook
         * @param {OlxWebhookSerializerDTO} olxWebhookSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async olxWebhookPromotionsIntegrationsOlxWebhookPost(olxWebhookSerializerDTO: OlxWebhookSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.olxWebhookPromotionsIntegrationsOlxWebhookPost(olxWebhookSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationsAPI - factory interface
 * @export
 */
export const IntegrationsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Olx Authorize
         * @param {string} code 
         * @param {string} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxAuthorizePromotionsIntegrationsOlxAuthGet(code: string, state: string, options?: any): AxiosPromise<any> {
            return localVarFp.olxAuthorizePromotionsIntegrationsOlxAuthGet(code, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Olx Trigger Authorization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options?: any): AxiosPromise<OlxAuthorizeResponseSerializerDTO> {
            return localVarFp.olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Olx Webhook
         * @param {OlxWebhookSerializerDTO} olxWebhookSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        olxWebhookPromotionsIntegrationsOlxWebhookPost(olxWebhookSerializerDTO: OlxWebhookSerializerDTO, options?: any): AxiosPromise<void> {
            return localVarFp.olxWebhookPromotionsIntegrationsOlxWebhookPost(olxWebhookSerializerDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for olxAuthorizePromotionsIntegrationsOlxAuthGet operation in IntegrationsAPI.
 * @export
 * @interface IntegrationsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGetRequest
 */
export interface IntegrationsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGetRequest {
    /**
     * 
     * @type {string}
     * @memberof IntegrationsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGet
     */
    readonly code: string

    /**
     * 
     * @type {string}
     * @memberof IntegrationsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGet
     */
    readonly state: string
}

/**
 * Request parameters for olxWebhookPromotionsIntegrationsOlxWebhookPost operation in IntegrationsAPI.
 * @export
 * @interface IntegrationsAPIOlxWebhookPromotionsIntegrationsOlxWebhookPostRequest
 */
export interface IntegrationsAPIOlxWebhookPromotionsIntegrationsOlxWebhookPostRequest {
    /**
     * 
     * @type {OlxWebhookSerializerDTO}
     * @memberof IntegrationsAPIOlxWebhookPromotionsIntegrationsOlxWebhookPost
     */
    readonly olxWebhookSerializerDTO: OlxWebhookSerializerDTO
}

/**
 * IntegrationsAPI - object-oriented interface
 * @export
 * @class IntegrationsAPI
 * @extends {BaseAPI}
 */
export class IntegrationsAPI extends BaseAPI {
    /**
     * 
     * @summary Olx Authorize
     * @param {IntegrationsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsAPI
     */
    public olxAuthorizePromotionsIntegrationsOlxAuthGet(requestParameters: IntegrationsAPIOlxAuthorizePromotionsIntegrationsOlxAuthGetRequest, options?: any) {
        return IntegrationsAPIFp(this.configuration).olxAuthorizePromotionsIntegrationsOlxAuthGet(requestParameters.code, requestParameters.state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Olx Trigger Authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsAPI
     */
    public olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options?: any) {
        return IntegrationsAPIFp(this.configuration).olxTriggerAuthorizationPromotionsIntegrationsOlxAuthorizeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Olx Webhook
     * @param {IntegrationsAPIOlxWebhookPromotionsIntegrationsOlxWebhookPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsAPI
     */
    public olxWebhookPromotionsIntegrationsOlxWebhookPost(requestParameters: IntegrationsAPIOlxWebhookPromotionsIntegrationsOlxWebhookPostRequest, options?: any) {
        return IntegrationsAPIFp(this.configuration).olxWebhookPromotionsIntegrationsOlxWebhookPost(requestParameters.olxWebhookSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
