import { ReactComponent as EmailActionUnreadIcon } from '@heimstaden/icons-library/img/streamline-regular/emails/email-actions/email-action-unread.svg';
import { ReactComponent as CakeBirthdayIcon } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/cakes-candies/cake-birthday.svg';
import { ReactComponent as PhoneLandlineIcon } from '@heimstaden/icons-library/img/streamline-regular/phones-mobile-devices/phone/phone-landline.svg';
import { ReactComponent as SingleNeutralProfilePictureIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-profile-picture.svg';
import type { FC, SVGProps } from 'react';

import type { UserSerializerDTO } from '../../../../connectors/user';

export const fieldNameIconKeyMap: Partial<
  Record<keyof UserSerializerDTO, FC<SVGProps<SVGSVGElement>>>
> = {
  birthDate: CakeBirthdayIcon,
  email: EmailActionUnreadIcon,
  firstName: SingleNeutralProfilePictureIcon,
  lastName: SingleNeutralProfilePictureIcon,
  phoneNumber: PhoneLandlineIcon,
};

export const getFieldNameIcon = (
  key: keyof UserSerializerDTO,
): FC<SVGProps<SVGSVGElement>> | undefined =>
  fieldNameIconKeyMap[key] || undefined;
