import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { TicketStatusEnumDTO } from '../../../../../connectors/ticket';
import { Box, useTranslation } from '../../../../shared';
import type { DetailsData } from '../../types';
import { CountdownTimer } from '../CountdownTimer/countdown-timer.component';
import { StatusSwitcher } from '../StatusSwitcher/status-switcher.component';
import { Timeline } from '../Timeline/timeline.component';
import { sxProps } from './header-box.styles';

type Props = {
  data: DetailsData;
};

export const HeaderBox: FC<Props> = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const timelineEvents = useMemo(
    () => [
      {
        date: data.createdAt,
        labelKey: 'ticket.details.labels.requestSubmission',
        position: 0,
      },
      ...(data.dueDate
        ? [
            {
              date: data.dueDate,
              labelKey: 'ticket.details.labels.requestUntil',
              position: 100,
            },
          ]
        : []),
    ],
    [data.createdAt, data.dueDate],
  );

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={3} xl={2}>
          <Typography component="div" variant="h2">
            {t('ticket.details.labels.requestStatus')}
          </Typography>
          <StatusSwitcher
            country={data.countryUuid}
            id={data.uuid}
            status={data.status}
          />
        </Grid>
        <Grid item xs={12} lg={1}>
          <Divider orientation={lgDown ? 'horizontal' : 'vertical'} />
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <Grid container spacing={2} sx={sxProps.timelineColumn}>
            <Grid item xs={6} md={8} lg={6} xl={8}>
              <Timeline events={timelineEvents} />
            </Grid>
            {data.dueDate && data.status !== TicketStatusEnumDTO.Done && (
              <Grid item xs={6} md={4} lg={6} xl={4} textAlign="right">
                <CountdownTimer date={data.dueDate} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
