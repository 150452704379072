import { CountriesAPI } from '../connectors/company';
import type {
  CountrySerializerDTO,
  EntityEnumDTO,
  PropConfigSerializerDTO,
} from '../connectors/company';
import type { RegionSerializerDTO } from '../connectors/property';
import { mieInstance } from './mie.instance';
import type { AsyncAutocompleteOption, SelectOption } from './shared';
import { getEnvValue } from './shared/helpers/env.helper';

class LocalizationClient {
  private client;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.client = new CountriesAPI(undefined, baeBath, mieInstance);
  }

  public getCountries$ = async (): Promise<
    NonNullable<SelectOption<CountrySerializerDTO['uuid']>>[]
  > => {
    const response = await this.client.getCountriesCountriesGet({
      order: 'name',
    });

    return response.data.results.map(({ name, uuid }) => ({
      label: name,
      value: uuid,
    }));
  };

  public getRegions$ = async (
    countryId: CountrySerializerDTO['uuid'],
    activesForEntityType?: EntityEnumDTO,
    query?: string,
  ): Promise<
    NonNullable<AsyncAutocompleteOption<CountrySerializerDTO['uuid']>>[]
  > => {
    const response = await this.client.getRegionsCountriesCountryUuidRegionsGet(
      {
        activesForEntityType,
        countryUuid: countryId,
        pageSize: 100,
        search: query,
      },
    );

    return response.data.results.map(({ name, uuid }) => ({
      label: name,
      value: uuid,
    }));
  };

  public getCities$ = async (
    countryId: CountrySerializerDTO['uuid'],
    activesForEntityType?: EntityEnumDTO,
    regionId?: RegionSerializerDTO['uuid'],
    query?: string,
  ): Promise<
    NonNullable<AsyncAutocompleteOption<CountrySerializerDTO['uuid']>>[]
  > => {
    if (!countryId) {
      throw new Error('errors.country.missing');
    }

    const response = await this.client.getCitiesCountriesCountryUuidCitiesGet({
      activesForEntityType,
      countryUuid: countryId,
      pageSize: 100,
      regionUuid: regionId,
      search: query,
    });

    return response.data.results.map(({ name, uuid }) => ({
      label: name,
      value: uuid,
    }));
  };

  public getPropConfigs$ = async (
    countryId: CountrySerializerDTO['uuid'],
    entity: EntityEnumDTO,
  ): Promise<PropConfigSerializerDTO[]> => {
    if (!countryId) {
      throw new Error('errors.country.missing');
    }

    const response = await this.client.getPropConfigsCountriesPropConfigsGet({
      countryUuid: countryId,
      entity,
    });

    return response.data;
  };
}
export const localizationClient = new LocalizationClient();
