import { Box } from '@mui/material';
import MUIBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';

import { sxProps } from './questionnaire-content.styles';
import { QuestionnaireDummyData } from './questionnaire-dummy-data.const';

export const QuestionnaireContent: FC = () => {
  return (
    <Box sx={sxProps.container}>
      {QuestionnaireDummyData.map((data) => (
        <MUIBox key={data.key} sx={sxProps.item}>
          <Typography sx={sxProps.question}>{data.question}</Typography>
          <Typography sx={sxProps.answer}>{data.answer}</Typography>
        </MUIBox>
      ))}
    </Box>
  );
};
