import type { SxProps } from '@mui/system/styleFunctionSx';
import type { CSSProperties } from 'react';

import {
  CUSTOM_INFO_WINDOW_SPACING,
  INFO_WINDOW_HEIGHT,
  INFO_WINDOW_QUERY_SELECTOR,
  MAP_QUERY_SELECTOR,
  MARKER_SIZE,
  NATIVE_INFO_WINDOW_SPACING,
} from '../consts';

/**
 * Calculates distance between top of the Map and potential InfoWindow top
 * @param infoWindowDomRect DOMRect of Google Maps native Info Window
 * @param mapsDomRect DOMRect of Google Maps Wrapper
 * @returns number of pixels
 */
const getDistanceFromTop = (
  infoWindowDomRect: DOMRect,
  mapsDomRect: DOMRect,
): number =>
  infoWindowDomRect.bottom -
  mapsDomRect.top +
  NATIVE_INFO_WINDOW_SPACING +
  MARKER_SIZE +
  CUSTOM_INFO_WINDOW_SPACING;

/**
 * Calculates distance between bottom of the Map and potential InfoWindow bottom
 * @param infoWindowDomRect DOMRect of Google Maps native Info Window
 * @param mapsDomRect DOMRect of Google Maps Wrapper
 * @returns number of pixels
 */
const getDistanceFromBottom = (
  infoWindowDomRect: DOMRect,
  mapsDomRect: DOMRect,
): number =>
  mapsDomRect.bottom -
  infoWindowDomRect.bottom -
  NATIVE_INFO_WINDOW_SPACING +
  CUSTOM_INFO_WINDOW_SPACING;

/**
 * Calculates distance between left side of the Map and middle of the Marker
 * @param infoWindowDomRect DOMRect of Google Maps native Info Window
 * @param mapsDomRect DOMRect of Google Maps Wrapper
 * @returns number of pixels
 */
const getDistanceFromLeft = (
  infoWindowDomRect: DOMRect,
  mapsDomRect: DOMRect,
): number =>
  infoWindowDomRect.left - mapsDomRect.left + infoWindowDomRect.width / 2;

/**
 * Converts distance from pixels to percent value
 * @param axis horizontal or vertical
 * @param mapsDomRect DOMRect of Google Maps Wrapper
 * @param value distance in pixels
 * @returns number of percents
 */
const getRelativeDistance = (
  axis: 'horizontal' | 'vertical',
  mapsDomRect: DOMRect,
  value: number,
): number =>
  (100 * value) / mapsDomRect[axis === 'horizontal' ? 'width' : 'height'];

/**
 * @param leftPercent
 * @returns left position of InfoWindow
 */
const getHorizontalPosition = (
  leftPercent: number,
): { left: CSSProperties['left'] } => ({ left: `${leftPercent}%` });

/**
 * @param bottomPercent
 * @param distanceFromTop
 * @param topPercent
 * @returns bottom and top positions of InfoWindow
 */
const getVerticalPosition = (
  bottomPercent: number,
  distanceFromTop: number,
  topPercent: number,
): { bottom: CSSProperties['bottom']; top: CSSProperties['top'] } =>
  distanceFromTop <
  MARKER_SIZE + 2 * CUSTOM_INFO_WINDOW_SPACING + INFO_WINDOW_HEIGHT
    ? { bottom: 'unset', top: `${topPercent}%` }
    : { bottom: `${bottomPercent}%`, top: 'unset' };

/**
 * @param leftPercent
 * @returns position transformation by X axis of InfoWindow
 */
const getTransformation = (
  leftPercent: number,
): { transform: CSSProperties['transform'] } => {
  if (leftPercent < 25)
    return { transform: `translateX(${-MARKER_SIZE / 2}px)` };
  if (leftPercent > 75)
    return { transform: `translateX(calc(-100% + ${MARKER_SIZE / 2}px))` };

  return { transform: 'translateX(-50%)' };
};

export const getInfoWindowPosition = (): SxProps | undefined => {
  const mapsRect = document
    .querySelector(MAP_QUERY_SELECTOR)
    ?.getBoundingClientRect();
  const infoWindowRect = document
    .querySelector(INFO_WINDOW_QUERY_SELECTOR)
    ?.getBoundingClientRect();

  if (!mapsRect || !infoWindowRect) return undefined;

  const distanceFromTop = getDistanceFromTop(infoWindowRect, mapsRect);
  const distanceFromBottom = getDistanceFromBottom(infoWindowRect, mapsRect);
  const distanceFromLeft = getDistanceFromLeft(infoWindowRect, mapsRect);
  const topPercent = getRelativeDistance('vertical', mapsRect, distanceFromTop);
  const bottomPercent = getRelativeDistance(
    'vertical',
    mapsRect,
    distanceFromBottom,
  );
  const leftPercent = getRelativeDistance(
    'horizontal',
    mapsRect,
    distanceFromLeft,
  );

  return {
    ...getHorizontalPosition(leftPercent),
    ...getTransformation(leftPercent),
    ...getVerticalPosition(bottomPercent, distanceFromTop, topPercent),
  };
};
