import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useFormControl } from '@mui/material/FormControl';
import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import type { FormikHandlers } from 'formik';
import type { FieldHelperProps } from 'formik/dist/types';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { useTranslation } from '../../../translations';
import type { EditorValue } from '../../fields';
import { ChangeEffect, InitialValueEffect, Textarea } from './components';
import { CONFIG } from './editor.const';
import { sxProps } from './editor.styles';
import { Toolbar } from './plugins';
import './theme.css';

type Props = {
  handleBlur: FormikHandlers['handleBlur'];
  name: NonNullable<TextFieldProps['name']>;
  setValue: FieldHelperProps<EditorValue>['setValue'];
  helperTextKey?: GenericTypes.TranslationLabel;
  initialValue?: EditorValue;
  labelKey?: GenericTypes.TranslationLabel;
  placeholderKey?: GenericTypes.TranslationLabel;
};

export const Editor: FC<Props> = (props) => {
  const {
    handleBlur,
    helperTextKey,
    initialValue,
    labelKey,
    name,
    placeholderKey,
    setValue,
  } = props;
  const { error, disabled, required } = useFormControl() || {};
  const { t } = useTranslation();
  const isDisabled = useMemo(() => Boolean(disabled), [disabled]);

  return (
    <LexicalComposer initialConfig={CONFIG}>
      <InitialValueEffect value={initialValue} />
      <ChangeEffect isDisabled={isDisabled} setValue={setValue} />
      <Toolbar isDisabled={isDisabled} />
      <TextField
        disabled={isDisabled}
        error={error}
        onBlur={handleBlur}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: Textarea,
          inputProps: {
            'data-testid': name,
            id: name,
            name,
            sx: sxProps.input,
          },
        }}
        required={required}
        {...(helperTextKey && { helperText: t(helperTextKey) })}
        {...(labelKey && { label: t(labelKey) })}
        {...(placeholderKey && { placeholder: t(placeholderKey) })}
      />
    </LexicalComposer>
  );
};
