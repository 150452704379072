import { OptionCategory } from './async-autocomplete-field.enum';

const optionCategoryTranslationKeyMap: Record<
  OptionCategory,
  GenericTypes.TranslationLabel
> = {
  [OptionCategory.CONTACT]: 'list.groupBy.contact',
  [OptionCategory.OTHER]: 'list.groupBy.other',
};

export const getOptionCategoryLabelKey = (
  category: OptionCategory,
): GenericTypes.TranslationLabel | OptionCategory =>
  optionCategoryTranslationKeyMap[category] || category;
