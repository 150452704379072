/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum EntityEnumDTO {
    Unit = 'unit',
    Company = 'company',
    Property = 'property',
    PropertyObject = 'property_object',
    Inventory = 'inventory',
    Device = 'device',
    Ticket = 'ticket',
    Lease = 'lease',
    LeaseTemplate = 'lease_template',
    City = 'city',
    Region = 'region',
    UnitContact = 'unit_contact',
    Comment = 'comment',
    User = 'user',
    Attachment = 'attachment',
    Entrance = 'entrance',
    UnitAdvertisementIntegration = 'unit_advertisement_integration',
    AdvertisementIntegration = 'advertisement_integration',
    UnitAdvertisement = 'unit_advertisement',
    Prospect = 'prospect',
    KycIntegration = 'kyc_integration'
}



