import type { FC, Key } from 'react';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import type { Permission } from '../../../+auth';
import { isPermitted, useAuth } from '../../../+auth';
import type { TabsRouterProps } from '../TabsRouter';
import { TabsRouter } from '../TabsRouter';

type Props = {
  tabs: {
    dataTestId: string;
    expectedPermissions: Permission[];
    key: Key;
    labelKey: GenericTypes.TranslationLabel;
    path: string;
  }[];
};
export const ListsOutlet: FC<Props> = (props) => {
  const { tabs } = props;
  const { permissions } = useAuth();
  const filteredTabs = useMemo<TabsRouterProps['tabs']>(
    () =>
      tabs
        .filter((tab) => isPermitted(tab.expectedPermissions, permissions))
        .map((tab) => ({
          dataTestId: tab.dataTestId,
          key: tab.key,
          labelKey: tab.labelKey,
          path: tab.path,
        })),
    [permissions, tabs],
  );

  if (filteredTabs.length === 0) return null;

  return (
    <>
      <TabsRouter tabs={filteredTabs} />
      <Outlet />
    </>
  );
};
