import { KYCPlatformEnumDTO } from '../../../../../connectors/company';
import { SimpleRentStepCheck } from './verification-stepper.enum';
import type { VerificationStatus } from './verification-stepper.type';

export const PLATFORM_STATUSES: Record<
  KYCPlatformEnumDTO,
  Record<VerificationStatus, string>
> = {
  [KYCPlatformEnumDTO.SimplRent]: {
    PENDING: 'PENDING',
    SUCCESS: 'PASSED',
  },
};

export const PLATFORM_CHECK_ORDER: Record<KYCPlatformEnumDTO, string[]> = {
  [KYCPlatformEnumDTO.SimplRent]: [
    SimpleRentStepCheck.IDENTITY,
    SimpleRentStepCheck.AFFORDABILITY,
    SimpleRentStepCheck.CREDIT,
    SimpleRentStepCheck.FINAL_STATUS,
  ],
};
