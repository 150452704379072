export const fieldSettings = [
  {
    helperTextKey: 'settings.notifications.helperText.sms',
    key: 'sms-notification-setting',
    labelKey: 'settings.notifications.fieldLabel.sms',
    name: 'isSmsEnabled' as const,
  },
  {
    helperTextKey: 'settings.notifications.helperText.email',
    key: 'email-notification-setting',
    labelKey: 'settings.notifications.fieldLabel.email',
    name: 'isEmailEnabled' as const,
  },
];
