import { ReactComponent as MessagingWeChatIcon } from '@heimstaden/icons-library/img/streamline-regular/logos/messaging-video-meetings/messaging-we-chat.svg';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { Fragment, useCallback } from 'react';
import type { QueryKey } from 'react-query';

import { prospectClient } from '../../../../+prospect';
import type { ListCommentWithProspectSerializerDTO } from '../../../../../connectors/property';
import { Country } from '../../../providers';
import { useTranslation } from '../../../translations';
import { Box } from '../../Box/box.component';
import type { QueryContentProps } from '../../QueryContent';
import { QueryContent } from '../../QueryContent';
import { InboxComment } from '../InboxComment/inbox-comment.component';
import { sxProps } from './prospect-inbox.styles';

type Props = {
  count: number;
  queryKey: QueryKey;
};

// eslint-disable-next-line max-len
const OPTIONS: QueryContentProps<ListCommentWithProspectSerializerDTO>['options'] =
  { hideRefetchSpinner: true, refetchInterval: 15000 };
const SORT = '-created_at';

export const ProspectInbox: FC<Props> = (props) => {
  const { count, queryKey } = props;
  const { t } = useTranslation();
  const getData$ = useCallback(
    () =>
      prospectClient.getCommentsByCountry$(Country.PL, {
        pageSize: count,
        sort: SORT,
      }),
    [count],
  );

  return (
    <Box sx={sxProps.box}>
      <QueryContent getData$={getData$} options={OPTIONS} queryKey={queryKey}>
        {({ results }) => (
          <Grid container>
            <Grid item sx={sxProps.header} xs={12}>
              <Grid
                alignItems="center"
                container
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Icon sx={sxProps.icon}>
                    <MessagingWeChatIcon />
                  </Icon>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {t('dashboard.widgets.prospectInbox.title')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <List sx={sxProps.list}>
                {results.length > 0 ? (
                  results
                    .filter((comment) => comment.prospect)
                    .map((comment, idx) => (
                      <Fragment key={`list-item-${comment.uuid}`}>
                        <ListItem sx={sxProps.listItem}>
                          <InboxComment comment={comment} />
                        </ListItem>
                        {idx < results.length - 1 && <Divider />}
                      </Fragment>
                    ))
                ) : (
                  <ListItem sx={sxProps.listItem}>
                    <Typography>
                      {t('dashboard.widgets.prospectInbox.empty')}
                    </Typography>
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        )}
      </QueryContent>
    </Box>
  );
};
