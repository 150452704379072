import { ReactComponent as NotesDisableIcon } from '@heimstaden/icons-library/img/streamline-regular/content/notes/notes-disable.svg';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon/Icon';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';
import { Trans } from 'react-i18next';

import { sxProps } from './questionnaire-box.styles';

export const QuestionnaireEmptyMessage: FC = () => {
  return (
    <>
      <Icon component={NotesDisableIcon} sx={sxProps.icon} />
      <Box alignSelf="center" sx={sxProps.message}>
        <Typography align="center">
          <Trans
            components={{ bold: <strong /> }}
            i18nKey="prospect.questionnaire.message"
          />
        </Typography>
      </Box>
    </>
  );
};
