import type { FC } from 'react';
import React, { useCallback } from 'react';

import type { ListAttachmentSerializerDTO } from '../../../../connectors/property';
import { AsyncContentProvider } from '../../providers';
import { Box } from '../Box/box.component';
import { Carousel } from '../Carousel/carousel.component';
import { sxProps } from './gallery-box.styles';

type Props = {
  getImages$: () => Promise<ListAttachmentSerializerDTO>;
  placeholderURL: string;
};

export const GalleryBox: FC<Props> = (props) => {
  const { getImages$, placeholderURL } = props;
  const getData$ = useCallback(async () => {
    const images = await getImages$();

    return images.results.map((image) => ({
      description: image.description,
      id: image.uuid,
      name: image.title || image.name,
      url: image.url,
    }));
  }, [getImages$]);

  return (
    <Box sx={sxProps.box}>
      <AsyncContentProvider getData$={getData$}>
        {(images) => (
          <Carousel images={images} placeholderUrl={placeholderURL} />
        )}
      </AsyncContentProvider>
    </Box>
  );
};
