import { Permission } from '../../../+auth/shared/enums/permission.enum';

export const WRITE_PERMISSIONS = [
  Permission.GLOBAL_WRITE,
  Permission.WRITE_TICKETING,
];
export const READ_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.READ_TICKETING,
];
export const DELETE_PERMISSIONS = [Permission.GLOBAL_WRITE];
