import type { FC, ReactElement } from 'react';
import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../../../+auth';
import type { CountrySerializerDTO } from '../../../../connectors/company';
import { APP_MAIN_PATH } from '../../../routing';
import { Spinner } from '../../components';
import type { Country } from '../../providers';
import { isFeatureAvailable } from './feature-available.helper';

type Props = {
  countries: (Country | CountrySerializerDTO['uuid'])[];
  children: NonNullable<ReactElement>;
  shouldRedirect?: boolean;
};

export const FeatureAvailableGuard: FC<Props> = (props) => {
  const { children, countries, shouldRedirect = true } = props;
  const { isUserFetched, user } = useAuth();
  const isAvailable = useMemo(
    () => isFeatureAvailable(countries as Country[], user?.countryUuids),
    [countries, user?.countryUuids],
  );

  if (!isUserFetched) {
    return <Spinner />;
  }

  if (!isAvailable) {
    return shouldRedirect ? <Navigate to={APP_MAIN_PATH} /> : null;
  }

  return children;
};
