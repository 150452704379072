import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

const textWithLimitedLinesNumber: SxProps<Theme> = {
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
};

export const sxProps: Record<
  | 'description'
  | 'headerWrapper'
  | 'imageList'
  | 'mainImage'
  | 'textWrapper'
  | 'title',
  SxProps<Theme>
> = {
  description: {
    ...textWithLimitedLinesNumber,
    lineClamp: '4',
    WebkitLineClamp: '4',
  },
  headerWrapper: {
    flexWrap: (theme) =>
      theme.breakpoints.down('lg') ? 'wrap-reverse' : 'wrap',
    fontSize: 0,
  },
  imageList: { height: 380, width: '100%' },
  mainImage: {
    height: '380px',
    objectFit: 'cover',
    width: '100%',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    px: [0, 0, 0, 6],
    py: 3,
  },
  title: {
    ...textWithLimitedLinesNumber,
    lineClamp: '3',
    WebkitLineClamp: '3',
  },
};
