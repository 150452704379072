import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import type { FC, Key } from 'react';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useTranslation } from '../../translations';
import { sxProps } from './tabs-router.styles';

interface ITab {
  dataTestId: string;
  key: Key;
  labelKey: GenericTypes.TranslationLabel;
  path: string;
}

export interface Props {
  tabs: ITab[];
}

export const TabsRouter: FC<Props> = (props) => {
  const { tabs } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const tabPaths = useMemo(() => tabs.map((tab) => tab.path), [tabs]);
  const value = useMemo(() => {
    const foundElement = tabPaths
      .filter((path) => path)
      .find((path) => pathname.includes(path));

    return foundElement ? tabPaths.indexOf(foundElement) : 0;
  }, [pathname, tabPaths]);

  return (
    <Box sx={sxProps.box}>
      <MuiTabs
        indicatorColor="primary"
        scrollButtons="auto"
        sx={sxProps.tabs}
        textColor="primary"
        value={value}
        variant="scrollable"
      >
        {tabs.map((tab, idx) => {
          const { dataTestId, key, labelKey, path } = tab;

          return (
            <Tab
              component={Link}
              data-testid={dataTestId}
              key={key}
              label={t(labelKey)}
              sx={sxProps.tab}
              to={path || ''}
              value={idx}
            />
          );
        })}
      </MuiTabs>
    </Box>
  );
};
