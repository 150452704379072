import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    background: (theme) => theme.palette.background.paper,
    borderRadius: (theme) => theme.spacing(2),
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    mb: (theme) => theme.spacing(4),
  },
  filtersRow: {
    p: (theme) => theme.spacing(2),
  },
};
