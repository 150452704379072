import { ReactComponent as ConversationSmileTypeIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/conversation/conversation-smile-type.svg';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import uniqBy from 'lodash-es/uniqBy';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import type { LanguageDTO } from '../../../../../connectors/company';
import type {
  AttachmentSerializerDTO,
  CommentSerializerDTO,
  CreateReponseTemplateSerializerDTO,
  ListCommentSerializerDTO,
  ResponseTemplateSerializerDTO,
} from '../../../../../connectors/ticket';
import {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
  CommentVisibilityEnumDTO,
} from '../../../../../connectors/ticket';
import type { ListQueryParams, QueryContentProps } from '../../../../shared';
import {
  Box,
  Comments,
  INITIAL_LIST_STATE,
  useApi,
  useList,
  useQuery,
  useTranslation,
} from '../../../../shared';
import { ticketClient } from '../../../ticket.client';
import { QueryKey } from '../../enums';
import type { CommentFilterValues, DetailsData } from '../../types';
import { MessagesFilter } from '../MessagesFilter/messages-filter.component';
import { sxProps } from './comments-box.styles';

type Props = {
  id: DetailsData['uuid'];
};

const PAGE_SIZE = 25;
const REFETCH_INTERVAL = 15000;
const SORT = '-created_at';
const QUERY_OPTIONS: QueryContentProps<CommentSerializerDTO[]>['options'] = {
  refetchInterval: REFETCH_INTERVAL,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  retry: 3,
};
const FILTER_OPTIONS = [
  { labelKey: 'comment.filter.default', value: undefined },
  {
    labelKey: 'comment.filter.public',
    value: CommentVisibilityEnumDTO.Public,
  },
  {
    labelKey: 'comment.filter.internal',
    value: CommentVisibilityEnumDTO.Internal,
  },
];

export const CommentsBox: FC<Props> = (props) => {
  const { id } = props;
  const { t } = useTranslation();
  const [comments, setComments] = useState<CommentSerializerDTO[]>([]);
  const [isFetchingDocuments, setFetchingDocuments] = useState(false);
  const [isFetchingImages, setFetchingImages] = useState(false);
  const [isQueryEnabled, setQueryEnabled] = useState(false);
  const { setListState, setPaginationFromResponse, queryParams } = useList<
    ListCommentSerializerDTO,
    CommentFilterValues
  >(false, {
    ...INITIAL_LIST_STATE,
    paginationConfig: {
      ...INITIAL_LIST_STATE.paginationConfig,
      pageSize: PAGE_SIZE,
    },
    sort: SORT,
  });
  const orderedComments = useMemo(() => [...comments].reverse(), [comments]);
  const lastCommentId = useMemo(
    () => orderedComments[orderedComments.length - 1]?.uuid,
    [orderedComments],
  );
  const getComments$ = useCallback(async () => {
    const comments = await ticketClient.getComments$(id, queryParams);

    setComments((prevState) =>
      uniqBy([...prevState, ...comments.results], 'uuid'),
    );
    setPaginationFromResponse(comments);

    return comments.results;
  }, [id, queryParams, setPaginationFromResponse]);
  const refreshComments$ = useCallback(async () => {
    const comments = await ticketClient.getComments$(id, { sort: SORT });

    setComments((prevState) => {
      const newState = uniqBy([...comments.results, ...prevState], 'uuid');

      return newState.length !== prevState.length ? newState : prevState;
    });

    return comments.results;
  }, [id]);
  const uploadCommentAttachments$ = useCallback(
    async (
      attachmentCategory: AttachmentCategoryEnumDTO,
      attachmentType: AttachmentTypeEnumDTO,
      files: FileList,
      commentResponse: CommentSerializerDTO,
      visibility?: CommentVisibilityEnumDTO,
    ) =>
      ticketClient.uploadCommentAttachment$(
        id,
        commentResponse.uuid,
        attachmentCategory,
        attachmentType,
        Array.from(files),
        undefined,
        undefined,
        visibility,
      ),
    [id],
  );
  const uploadImages = useCallback(
    (imageFiles, comment, visibility): Promise<AttachmentSerializerDTO[]> => {
      if (imageFiles?.length) {
        setFetchingImages(true);

        return uploadCommentAttachments$(
          AttachmentCategoryEnumDTO.Image,
          AttachmentTypeEnumDTO.Gallery,
          imageFiles,
          comment,
          visibility,
        );
      }

      return Promise.resolve([]);
    },
    [uploadCommentAttachments$],
  );
  const uploadDocuments = useCallback(
    (
      documentFiles,
      comment,
      visibility,
    ): Promise<AttachmentSerializerDTO[]> => {
      if (documentFiles?.length) {
        setFetchingDocuments(true);

        return uploadCommentAttachments$(
          AttachmentCategoryEnumDTO.Document,
          AttachmentTypeEnumDTO.Other,
          documentFiles,
          comment,
          visibility,
        );
      }

      return Promise.resolve([]);
    },
    [uploadCommentAttachments$],
  );
  const addComment$ = useCallback(
    async (
      content: string,
      visibility?: CommentVisibilityEnumDTO,
      imageFiles?: FileList,
      documentFiles?: FileList,
    ): Promise<void> => {
      const comment = await ticketClient.addComment$(id, content, visibility);

      if (imageFiles?.length || documentFiles?.length) {
        const imagePromise = uploadImages(imageFiles, comment, visibility);
        const documentPromise = uploadDocuments(
          documentFiles,
          comment,
          visibility,
        );

        Promise.all([imagePromise, documentPromise])
          .then((responses) => {
            const attachments = responses.flat();

            setComments((prevState) =>
              prevState.map((storedComment) => {
                if (storedComment.uuid === comment.uuid) {
                  return {
                    ...storedComment,
                    attachments,
                  };
                }

                return storedComment;
              }),
            );
          })
          .catch(() => {
            toast.error(t('errors.general.message'));
          })
          .finally(() => {
            setFetchingImages(false);
            setFetchingDocuments(false);
          });
      }
      setComments((prevState) => [comment, ...prevState]);
    },
    [id, t, uploadDocuments, uploadImages],
  );

  const addResponseTemplate$ = useCallback(
    (template: CreateReponseTemplateSerializerDTO) =>
      ticketClient.addResponseTemplate$(template),
    [],
  );
  const getResponseTemplates$ = useCallback(
    (queryParams: ListQueryParams, language?: LanguageDTO) =>
      ticketClient.getResponseTemplates$(queryParams, language),
    [],
  );
  const removeResponseTemplate$ = useCallback(
    (id: ResponseTemplateSerializerDTO['uuid']) =>
      ticketClient.removeResponseTemplate$(id),
    [],
  );
  const getMentions$ = useCallback(
    (query: string) => ticketClient.getMentions$(query),
    [],
  );
  const setNextPage = useCallback(
    () =>
      setListState((prevState) =>
        prevState.paginationConfig.next
          ? {
              ...prevState,
              paginationConfig: {
                ...prevState.paginationConfig,
                currentPage: prevState.paginationConfig.next,
              },
            }
          : prevState,
      ),
    [setListState],
  );
  const setVisibility = useCallback(
    (visibility?: CommentVisibilityEnumDTO) =>
      setListState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          visibility,
        },
        paginationConfig: {
          ...prevState.paginationConfig,
          currentPage: 1,
        },
      })),
    [setListState],
  );
  const { error, isFetching } = useApi([], getComments$);
  const { error: queryError } = useQuery(
    QueryKey.REFRESH_COMMENTS,
    refreshComments$,
    {
      ...QUERY_OPTIONS,
      enabled: isQueryEnabled,
    },
  );

  useEffect(() => {
    setComments([]);
  }, [queryParams.filters]);

  useEffect(() => {
    if (error || queryError) {
      toast.error(t('errors.general.message'));
    }
  }, [error, queryError, t]);

  useEffect(() => {
    // Workaround to useQuery after timeout
    const timeout = setTimeout(() => setQueryEnabled(true), REFETCH_INTERVAL);

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    <Box sx={sxProps.box}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        sx={sxProps.header}
      >
        <Grid item sx={sxProps.container}>
          <ConversationSmileTypeIcon height={24} width={24} />
          <Typography marginBottom={0} variant="h2">
            {t('comment.title')}
          </Typography>
        </Grid>
        <Grid item>
          <MessagesFilter
            options={FILTER_OPTIONS}
            value={queryParams.filters?.visibility}
            setValue={setVisibility}
          />
        </Grid>
      </Grid>
      <Comments
        addComment$={addComment$}
        addResponseTemplate$={addResponseTemplate$}
        comments={orderedComments}
        getMentions$={getMentions$}
        getResponseTemplates$={getResponseTemplates$}
        isFetching={isFetching}
        isFetchingImages={isFetchingImages}
        isFetchingDocuments={isFetchingDocuments}
        lastCommentId={lastCommentId}
        onNextPage={setNextPage}
        removeResponseTemplate$={removeResponseTemplate$}
        visibility={queryParams.filters?.visibility}
      />
    </Box>
  );
};
