import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';

import { useTranslation, ObjectRow } from '../../../../shared';
import PATHS from '../../../paths.const';
import { DEFAULT_AVATAR_URL } from '../../consts';

type Props = {
  id: string;
  title: string;
  avatarSrc?: GenericTypes.URL;
};

export const Management: FC<Props> = (props) => {
  const { avatarSrc, id, title } = props;
  const { t } = useTranslation();

  return (
    <>
      <Typography
        color="text.primary"
        component="div"
        fontWeight="bold"
        marginBottom={2}
        variant="h2"
      >
        {t('management.title')}
      </Typography>
      <ObjectRow
        avatarSrc={avatarSrc || DEFAULT_AVATAR_URL}
        id={id}
        path={PATHS.DETAILS}
        subtitleKey="management.owner"
        title={title}
      />
    </>
  );
};
