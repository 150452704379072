import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton/IconButton';
import type { Theme } from '@mui/material/styles/createTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { AccountAvatar } from '../../../+account';
import { MenuIcon } from '../../icons';
import { useSettings } from '../../settings';
import { Logo } from '../Logo/logo.component';
import { Nav } from '../Nav/nav.component';
import { Drawer, DrawerContainer, DrawerMenuContainer } from './sidebar.styles';

export const Sidebar: FC = () => {
  const { saveSettings, settings } = useSettings();
  const location = useLocation();
  const isOpen = useMemo(() => settings.sidebarState, [settings.sidebarState]);
  const toggleSidebarState = useCallback(() => {
    saveSettings({ sidebarState: !settings.sidebarState });
  }, [saveSettings, settings.sidebarState]);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isOpen && smDown) {
      toggleSidebarState();
    } else if (!smDown) {
      saveSettings({ sidebarState: true });
    }
    // Close the drawer only on the change a pathname or mobile breakpoint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, smDown]);

  return (
    <DrawerContainer>
      <Drawer
        anchor={smDown ? 'right' : 'left'}
        variant={smDown ? 'temporary' : 'permanent'}
        open={isOpen}
        onClose={toggleSidebarState}
      >
        <Logo isSmall={!isOpen} />
        <AccountAvatar isSidebarOpened={isOpen} />
        <Divider />

        <Nav />
      </Drawer>
      {smDown && (
        <DrawerMenuContainer>
          <IconButton color="inherit" onClick={toggleSidebarState}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </DrawerMenuContainer>
      )}
    </DrawerContainer>
  );
};
