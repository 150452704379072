import type { FC, Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';

import type { ListAttachmentSerializerDTO } from '../../../../../../connectors/ticket';
import { useAsync } from '../../../../../shared';

type Props = {
  setEditMode: Dispatch<SetStateAction<boolean>>;
};

export const Effect: FC<Props> = (props) => {
  const { setEditMode } = props;
  const { response } = useAsync<ListAttachmentSerializerDTO>();

  useEffect(() => {
    if (response.results.length === 0) return;

    setEditMode(false);
  }, [response.results.length, setEditMode]);

  return null;
};
