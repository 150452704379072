import { ReactComponent as CheckIcon } from '@heimstaden/icons-library/img/custom/check.svg';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { useTranslation } from '../../../../../shared';
import { sxProps } from './status-indicator.styles';

type Props = {
  status: 'inProgress' | 'isSent' | 'verified';
};

export const StatusIndicator: FC<Props> = (props) => {
  const { status } = props;
  const { t } = useTranslation();
  const labelKey = useMemo(() => {
    switch (status) {
      case 'isSent':
        return 'prospect.verification.status.sent';
      case 'inProgress':
        return 'prospect.verification.status.inProgress';
      case 'verified':
        return 'prospect.verification.status.verified';
      default:
        return '';
    }
  }, [status]);
  const isVerified = useMemo(() => status === 'verified', [status]);
  const boxSxProps = useMemo(
    () => ({
      ...sxProps.box,
      ...(status !== 'isSent' && sxProps.right),
      ...(status === 'verified' && sxProps.verified),
    }),
    [status],
  );
  const textSxProps = useMemo(
    () => ({ ...sxProps.text, ...(isVerified && sxProps.textIcon) }),
    [isVerified],
  );

  if (!labelKey) return null;

  return (
    <Box sx={boxSxProps}>
      <Typography sx={textSxProps}>{t(labelKey)}</Typography>
      {isVerified && <CheckIcon height={18} width={18} />}
    </Box>
  );
};
