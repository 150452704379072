import Box from '@mui/material/Box';
import MUIDrawer from '@mui/material/Drawer';
import type { CSSObject } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles/createTheme';

import { palette } from '../../theme';

export const DRAWER_WIDTH = 256;

const openedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: DRAWER_WIDTH,
});

const closedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: 0,

  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(11.25),
  },
});

export const DrawerContainer = styled('div')({
  position: 'relative',
});

export const Drawer = styled(MUIDrawer)((props) => ({
  borderRight: 'unset',
  boxSizing: 'border-box',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  width: DRAWER_WIDTH,
  ...(props.open && {
    ...openedMixin(props.theme),
    '& .MuiDrawer-paper': openedMixin(props.theme),
  }),
  ...(!props.open && {
    ...closedMixin(props.theme),
    '& .MuiDrawer-paper': closedMixin(props.theme),
  }),
}));

export const DrawerMenuContainer = styled(Box)((props) => ({
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.8,
  },
  backgroundColor: palette.white,
  boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
  padding: 2,
  position: 'fixed',
  right: 4,
  top: 4,
  zIndex: props.theme.zIndex.appBar,
}));
