import React, { useCallback } from 'react';
import type { FC } from 'react';

import type {
  CoordinatesSerializerDTO,
  PropertySerializerDTO,
} from '../../../../../connectors/property';
import { ThumbnailInfoWindow } from '../../../../shared';
import { propertyClient } from '../../../property.client';
import {
  FeaturesList,
  getAddress,
  THUMBNAIL_PLACEHOLDER_PATH,
} from '../../index';

type Props = {
  id: string;
  navigateToDetails: (id: CoordinatesSerializerDTO['uuid']) => void;
};

export const InfoWindow: FC<Props> = (props) => {
  const { id, navigateToDetails } = props;
  const getData$ = useCallback(() => propertyClient.getDetails$(id), [id]);

  return (
    <ThumbnailInfoWindow<PropertySerializerDTO>
      descriptionContent={({ city }) => getAddress(city)}
      getData$={getData$}
      onItemClick={({ uuid }) => navigateToDetails(uuid)}
      thumbnailUrl={({ thumbnail }) => thumbnail || THUMBNAIL_PLACEHOLDER_PATH}
      titleContent={({ name }) => name}
    >
      {(item) => <FeaturesList data={item} />}
    </ThumbnailInfoWindow>
  );
};
