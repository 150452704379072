import { ReactComponent as CheckSquareIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/check-square.svg';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { PropConfigDataTypeEnumDTO } from '../../../../connectors/company';
import { useTranslation } from '../../translations';
import { getAttributeLabel } from './attribute-list.helper';
import type { AdditionalProps } from './attribute-list.type';

type Props = {
  attributes: AdditionalProps[];
  attributeListTitleKey: GenericTypes.TranslationLabel;
};

export const AttributeList: FC<Props> = (props) => {
  const { attributes, attributeListTitleKey } = props;
  const { t } = useTranslation();
  const booleanAttributes = useMemo(
    () =>
      attributes.filter(
        (attribute) =>
          attribute.datatype === PropConfigDataTypeEnumDTO.Boolean &&
          attribute.value,
      ),
    [attributes],
  );
  const nonBooleanAttributes = useMemo(
    () =>
      attributes.filter(
        (attribute) =>
          attribute.datatype !== PropConfigDataTypeEnumDTO.Boolean &&
          attribute.value,
      ),
    [attributes],
  );

  return (
    <Box mt={3}>
      <Typography component="h3" variant="subtitle2" mb={2}>
        {t(attributeListTitleKey)}
      </Typography>
      <Grid container spacing="15">
        {booleanAttributes.map((attribute) => (
          <Grid container item key={`attribute-${attribute.key}`} xs={6}>
            <Grid item mr={1}>
              <CheckSquareIcon height={24} width={24} />
            </Grid>
            <Grid>
              <Typography variant="body1" color="secondary">
                {getAttributeLabel(attribute.key)}
              </Typography>
            </Grid>
          </Grid>
        ))}
        {nonBooleanAttributes.map((attribute) => (
          <Grid container item key={`attribute-${attribute.key}`}>
            <Grid item xs={12}>
              <Typography variant="overline">
                {getAttributeLabel(attribute.key)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="secondary">
                {attribute.value}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
