import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { AttachmentVisibilityEnumDTO } from '../../../../../connectors/contract';
import {
  attachmentBuildCustomFile,
  AttachmentFormEffect,
  Form as GenericForm,
  useAttachmentUploader,
  useLocalization,
  useTranslation,
} from '../../../../shared';
import type { Meta, Values } from './upload-form.model';
import { formConfig, getFieldsConfig } from './upload-form.model';

export const UploadForm: FC = () => {
  const { attachments, setAttachments } = useAttachmentUploader<Meta>();
  const { userCountries } = useLocalization();
  const { t } = useTranslation();
  const currentAttachment = useMemo(
    () => attachments.find((attachment) => attachment.isCurrent),
    [attachments],
  );
  const fieldsConfig = useMemo(
    () =>
      getFieldsConfig(
        userCountries,
        currentAttachment?.file,
        currentAttachment?.meta,
      ),
    [currentAttachment?.file, currentAttachment?.meta, userCountries],
  );
  const onChange = useCallback(
    (values: Values) =>
      setAttachments((prevState) =>
        prevState.map((attachment) => {
          const { description, title, ...meta } = values;
          const file = attachment.isCurrent
            ? attachmentBuildCustomFile(
                attachment.file,
                title,
                description,
                AttachmentVisibilityEnumDTO.Internal,
              )
            : attachment.file;

          return {
            ...attachment,
            file,
            meta: attachment.isCurrent ? meta : attachment.meta,
          };
        }),
      ),
    [setAttachments],
  );
  const getAttachmentFieldsConfig = useCallback(
    (attachment) =>
      getFieldsConfig(userCountries, attachment?.file, attachment?.meta),
    [userCountries],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {t('library.fieldset.templates.title')}
        </Typography>
        <Typography>{t('library.fieldset.templates.helperText')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <GenericForm
          config={formConfig}
          fieldsConfig={fieldsConfig}
          formEffect={
            <AttachmentFormEffect<Values, Meta>
              getFieldsConfig={getAttachmentFieldsConfig}
            />
          }
          onChange={onChange}
          showButtons={false}
        />
      </Grid>
    </Grid>
  );
};
