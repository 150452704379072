import type { ServiceRequestDetailSerializerDTO } from '../../../../../../connectors/ticket';
import { EntityEnumDTO } from '../../../../../../connectors/ticket';
import { localizationClient } from '../../../../../localization.client';
import type { FieldConfig, LocalizationConfig } from '../../../../../shared';
import { DEFAULT_ASYNC_AUTOCOMPLETE_VALUE } from '../../../../../shared';
import {
  entityTypeOptions,
  getEntityIcon,
  getEntitySuggestions$,
  getEntityTypeFieldTranslationLabelKey,
  isUserEntity,
} from '../../../helpers';
import type { RequestDetailsData, ServiceRequestValues } from '../../../types';

export const getFieldsConfig = (
  countries: LocalizationConfig['countryOptions'],
  details?: RequestDetailsData<ServiceRequestDetailSerializerDTO>,
  isPredefinedData?: boolean,
): FieldConfig<ServiceRequestValues>[] => {
  const cityInitialData =
    details?.entity && !isUserEntity(details.entity) && details.entity.city
      ? { label: details.entity.city.name, value: details.entity.city.uuid }
      : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE;

  return [
    {
      componentType: 'select',
      field: {
        initialValue: details?.countryUuid || countries[0]?.value,
        name: 'country',
      },
      props: {
        disabled:
          (isPredefinedData && Boolean(details?.countryUuid)) ||
          countries.length === 1,
        labelKey: 'ticket.fields.country.label',
        options: countries,
        placeholderKey: 'ticket.fields.country.placeholder',
        relatedFields: [
          'apartment',
          'assignee',
          'city',
          'damagedItem',
          'department',
          'dueDate',
          'entity',
          'issueLocation',
          'issueSubtype',
          'issueType',
          'roomWithIssue',
          'tenant',
        ],
        required: true,
        size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
      },
    },
    {
      componentType: 'async-autocomplete',
      field: {
        initialValue: cityInitialData,
        name: 'city',
      },
      props: {
        callback$: (query, { country }) =>
          localizationClient.getCities$(country, undefined, undefined, query),
        disabled: isPredefinedData && Boolean(cityInitialData),
        disableWhen: ({ country }) => !country,
        labelKey: 'ticket.fields.city',
        relatedFields: ['apartment', 'assignee', 'entity', 'tenant'],
        required: true,
        size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
      },
    },
    {
      componentType: 'select',
      field: {
        initialValue:
          details?.entity && !isUserEntity(details.entity)
            ? details?.entity.type
            : EntityEnumDTO.Unit,
        name: 'entityType',
      },
      props: {
        disabled: isPredefinedData && !details?.reporter,
        disableWhen: ({ city, country }) => !country || !city,
        labelKey: 'ticket.fields.entityType',
        options: entityTypeOptions,
        relatedFields: details
          ? ['entity']
          : [
              'damagedItem',
              'department',
              'dueDate',
              'entity',
              'issueLocation',
              'issueSubtype',
              'issueType',
              'roomWithIssue',
            ],
        required: true,
        startAdornmentIcon: ({ entityType }: ServiceRequestValues) =>
          getEntityIcon(entityType),
      },
    },
    {
      componentType: 'async-autocomplete',
      field: {
        initialValue:
          details?.entity && !isUserEntity(details.entity)
            ? {
                additionalValues: details.entity,
                label: details.entity.name,
                value: details.entity.uuid,
              }
            : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
        name: 'entity',
      },
      props: {
        callback$: (query, { city, country, entityType }) =>
          getEntitySuggestions$(entityType, query, city?.value, country),
        disabled:
          isPredefinedData && details?.entity && !isUserEntity(details.entity),
        disableWhen: ({ country, city, entityType }) =>
          !country || !city?.value || !entityType,
        labelKey: ({ entityType }) =>
          getEntityTypeFieldTranslationLabelKey(entityType),
        relatedFields: ['apartment', 'assignee', 'tenant'],
        required: true,
      },
    },
  ];
};
