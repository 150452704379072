import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  header: {
    pr: [4, 0],
  },
  headerContent: {
    mx: 3,
  },
};
