import MuiGrid from '@mui/material/Grid';
import React, { Fragment } from 'react';
import type { FC } from 'react';

import { GridItem } from '../GridItem/grid-item.component';
import type { ModelCommonProps, ViewModeCommonProps } from '../collection.type';

type Props<T> = ModelCommonProps<T> &
  ViewModeCommonProps & {
    model: T[];
  };

export const Grid = <T,>(props: Props<T>): ReturnType<FC<Props<T>>> => {
  const {
    children,
    gridComponent,
    gridItemComponent,
    model,
    modelKey,
    ...restProps
  } = props;

  if (gridComponent) return gridComponent(model);

  return (
    <MuiGrid container justifyContent="flex-start" spacing={1.5} wrap="wrap">
      {model.map((item) => (
        <Fragment key={`grid-item-${item[modelKey]}`}>
          {gridItemComponent ? (
            gridItemComponent(item, modelKey)
          ) : (
            <GridItem
              item={item}
              key={`grid-item-${item[modelKey]}`}
              modelKey={modelKey}
              {...restProps}
            >
              {children}
            </GridItem>
          )}
        </Fragment>
      ))}
    </MuiGrid>
  );
};
