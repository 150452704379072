import React from 'react';
import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router';

import { activityLogRoutes } from '../+activity-log';
import { advertisementRoutes } from '../+advertisement';
import { AUTH_PATHS, AuthGuard, AuthLayout, GuestGuard } from '../+auth';
import { AuthorizationGuard } from '../+auth/shared';
import { companyRoutes } from '../+company';
import { libraryRoutes } from '../+library';
import { propertyRoutes } from '../+property';
import { propertyObjectRoutes } from '../+property-object';
import { prospectRoutes } from '../+prospect';
import { REPORT_PATHS, reportRoutes } from '../+report';
import { terminationRoutes } from '../+termination';
import { ticketRoutes, TicketTransitionProvider } from '../+ticket';
import { Provider as TicketConfigProvider } from '../+ticket/shared/providers/TicketConfig/provider';
import { unitRoutes } from '../+unit';
import { userRoutes } from '../+user';
import { CountryProvider, Layout } from '../shared';
import { getEnvValue } from '../shared/helpers/env.helper';
import { componentLazyImport } from './routes.helper';

const environment = getEnvValue('ENV_NAME');

const Authorization = componentLazyImport(
  () => import('../+auth/Authorization/authorization.component'),
  'AUTH_AUTHORIZATION',
);
const Login = componentLazyImport(
  () => import('../+auth/Login/login.component'),
  'AUTH_LOGIN',
);
const NotFound = componentLazyImport(
  () => import('../shared/errors/NotFound/not-found.component'),
  'APP_NOT_FOUND',
);

export const getRoutes = (): RouteObject[] => [
  {
    children: [
      { element: <Navigate to={AUTH_PATHS.LOGIN} />, path: '' },
      { element: <Login />, path: 'login' },
      {
        element: (
          <AuthorizationGuard>
            <Authorization />
          </AuthorizationGuard>
        ),
        path: ':authType/authorize',
      },
      { element: <Navigate to={AUTH_PATHS.LOGIN} />, path: '*' },
    ],
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    path: 'auth',
  },
  {
    children: [
      activityLogRoutes,
      advertisementRoutes,
      companyRoutes,
      libraryRoutes,
      propertyObjectRoutes,
      propertyRoutes,
      prospectRoutes,
      reportRoutes,
      { ...(environment !== 'prod' && terminationRoutes) },
      ticketRoutes,
      unitRoutes,
      userRoutes,
    ],
    element: (
      <AuthGuard>
        <CountryProvider>
          <TicketConfigProvider>
            <TicketTransitionProvider>
              <Layout />
            </TicketTransitionProvider>
          </TicketConfigProvider>
        </CountryProvider>
      </AuthGuard>
    ),
    path: '/',
  },
  { element: <Navigate to={REPORT_PATHS.DASHBOARD} />, index: true, path: '/' },
  { element: <NotFound />, path: '*' },
];
