import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../../theme';

export const sxProps: Record<string, SxProps<Theme>> = {
  actionIconButton: {
    p: 0,
  },
  authorAvatar: {
    ml: 1,
    mr: 'unset',
  },
  authorComment: {
    flexDirection: 'row-reverse',
    ml: 'auto',
    mr: 'unset',
  },
  authorContent: {
    background: (theme) =>
      theme.palette.mode === 'dark'
        ? palette.fiord
        : theme.palette.background.default,
  },
  authorDate: {
    ml: 'unset',
    mr: 1.5,
    textAlign: 'right',
  },
  avatar: {
    mr: 1,
  },
  comment: {
    '&:first-of-type': {
      pt: 2,
    },
    '&:last-of-type': {
      pb: 2,
    },
    display: 'flex',
    flexDirection: 'row',
    mb: 1,
    mr: 'auto',
    width: ['100%', '70%'],
  },
  content: {
    background: (theme) =>
      theme.palette.mode === 'dark'
        ? palette.fuchsiaBlue
        : theme.palette.grey[50],
    borderRadius: 4,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(0, 0, 0, 0.05)',
    p: (theme) => theme.spacing(1, 2),
  },
  date: {
    display: 'block',
    ml: 1.5,
    mt: 1,
  },
  dialogContent: {
    mt: 3,
  },
  dialogContentRow: {
    px: 4,
    py: 2,
    width: '100%',
  },
  internalContent: {
    background: (theme) =>
      theme.palette.mode === 'dark'
        ? palette.indigo
        : `linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.9)
          ), ${palette.orangePeel}`,
  },
  templatePreview: {
    background: (theme) =>
      theme.palette.mode === 'dark'
        ? palette.indigo
        : `linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.9)
          ), ${palette.orangePeel}`,
    borderRadius: (theme) => theme.spacing(2),
  },
  text: {
    wordBreak: 'break-word',
  },
};
