import Grid from '@mui/material/Grid';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { Form } from '../../../../../shared';
import { useAdvertisementForm } from '../../../providers';
import { config, getFieldsetConfig } from './unit-details.model';

export const UnitDetails: FC = () => {
  const { unit } = useAdvertisementForm();
  const fieldsetConfig = useMemo(
    () => (unit ? getFieldsetConfig(unit) : []),
    [unit],
  );

  if (!unit) return null;

  return (
    <Grid item xs={12}>
      <Form config={config} fieldsConfig={fieldsetConfig} showButtons={false} />
    </Grid>
  );
};
