import type { AxiosError } from 'axios';
import type { FC, ReactNode } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import type { QueryKey, UseQueryOptions } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { NOT_FOUND_PATH } from '../../../routing';
import { useQuery } from '../../hooks';
import { HttpStatus } from '../../http';
import { useTranslation } from '../../translations';
import { Spinner } from '../Spinner/spinner.component';

export type Props<T> = {
  children: (item: T, isLoading: boolean) => ReactNode;
  getData$: () => Promise<T>;
  queryKey: QueryKey;
  options?: UseQueryOptions<T, AxiosError['response']> & {
    disableSpinner?: boolean;
    enableRefreshNotification?: boolean;
    hideRefetchSpinner?: boolean;
  };
  shouldRedirect?: boolean;
};

export const QueryContent = <T,>(props: Props<T>): ReturnType<FC<Props<T>>> => {
  const {
    children,
    getData$,
    options,
    shouldRedirect = false,
    queryKey,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [requestCount, setRequestCount] = useState(0);
  const { data, dataUpdatedAt, error, isFetched, isFetching, isRefetching } =
    useQuery<T>(queryKey, getData$, options);
  const isLoading = useMemo(() => {
    if (!isFetched) return true;

    return options?.hideRefetchSpinner && options?.refetchInterval
      ? isFetching && !isRefetching
      : isFetching;
  }, [
    isFetched,
    isFetching,
    isRefetching,
    options?.hideRefetchSpinner,
    options?.refetchInterval,
  ]);

  useEffect(() => {
    if (!shouldRedirect && error) {
      toast.error(t('errors.general.message'));
    }
  }, [error, shouldRedirect, t]);

  useEffect(() => {
    if (!isFetched) return;

    setRequestCount((prevState) => prevState + 1);
  }, [dataUpdatedAt, isFetched]);

  useEffect(() => {
    if (!options?.enableRefreshNotification || requestCount <= 1) return;

    toast.success(t('list.toast.refreshed'));
  }, [requestCount, t, options?.enableRefreshNotification]);

  useEffect(() => {
    setRequestCount(0);
  }, [queryKey]);

  if (!options?.disableSpinner && isLoading) {
    return <Spinner />;
  }

  if (shouldRedirect && error?.status === HttpStatus.NOT_FOUND) {
    navigate(NOT_FOUND_PATH);

    return null;
  }

  if (error) return null;

  return <>{children(data as T, isLoading)}</>;
};
