import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import type { FC } from 'react';

import { UNIT_THUMBNAIL_PLACEHOLDER_PATH } from '../../../../../+unit';
import { Spinner, useTranslation } from '../../../../../shared';
import { useAdvertisementForm } from '../../../providers';
import { getGalleryImages } from './unit-preview.helper';
import { sxProps } from './unit-preview.styles';

export const UnitPreview: FC = () => {
  const { t } = useTranslation();
  const { images, unit, isUnitFetching } = useAdvertisementForm();
  const mainImage = useMemo(() => {
    const mainImage = images[0];
    const mainImageUrl = mainImage?.url ?? UNIT_THUMBNAIL_PLACEHOLDER_PATH;

    return { alt: mainImage?.description ?? '', url: mainImageUrl };
  }, [images]);
  const galleryImages = useMemo(() => getGalleryImages(images), [images]);

  if (isUnitFetching) {
    return (
      <Grid item xs={12} my={3}>
        <Spinner />
      </Grid>
    );
  }

  if (!unit) return null;

  return (
    <Grid item xs={12} my={3}>
      <Typography variant="h2">
        {t('advertisement.fieldset.address.previewTitle')}
      </Typography>
      <Grid container spacing={0} sx={sxProps.headerWrapper}>
        <Grid item xs={12} lg={6}>
          <Box
            component="img"
            src={mainImage.url}
            alt={mainImage.alt}
            sx={sxProps.mainImage}
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={sxProps.textWrapper}>
          <Typography sx={sxProps.title} mb={0.5} variant="h1" component="h3">
            {unit?.name}
          </Typography>
          <Typography sx={sxProps.description} variant="body1">
            {unit?.description}
          </Typography>
        </Grid>
      </Grid>
      <ImageList
        cols={4}
        gap={0}
        rowHeight={190}
        sx={sxProps.imageList}
        variant="quilted"
      >
        {galleryImages.map((item) => (
          <ImageListItem key={item.id} cols={item.cols} rows={item.rows}>
            <img src={item.url} alt={item.description} />
          </ImageListItem>
        ))}
      </ImageList>
    </Grid>
  );
};
