import type { AttachmentSerializerDTO } from '../../../../../../connectors/company';
import { AttachmentTypeEnumDTO } from '../../../../../../connectors/property';
import type { Thumbnail } from './types';

const imagesGridDisplayStrategy = (
  imgCount: number,
): Record<'cols' | 'rows', number[]> => {
  switch (imgCount) {
    case 2:
      return {
        cols: [2, 2],
        rows: [2, 2],
      };
    case 3:
      return {
        cols: [2, 2, 2],
        rows: [1, 2, 1],
      };
    case 4:
      return {
        cols: [1, 1, 2, 2],
        rows: [1, 1, 2, 1],
      };
    default:
      return {
        cols: [1, 1, 2, 1, 1],
        rows: [1, 1, 2, 1, 1],
      };
  }
};

export const getGalleryImages = (
  images: AttachmentSerializerDTO[] | undefined,
): Thumbnail[] => {
  let galleryImages =
    images?.filter((image) => image.type === AttachmentTypeEnumDTO.Gallery) ??
    [];

  if (galleryImages.length > 5) {
    galleryImages = galleryImages.slice(0, 5);
  }

  return galleryImages.map((image, index) => ({
    cols: imagesGridDisplayStrategy(galleryImages.length)?.cols[index] || 1,
    description: image.description,
    id: image.uuid,
    name: image.title || image.name,
    rows: imagesGridDisplayStrategy(galleryImages.length)?.rows[index] || 1,
    url: image.url,
  }));
};
