import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import type { ListAttachmentSerializerDTO } from '../../../../../connectors/company';
import type {
  DetailedPropertyObjectSerializerDTO,
  DetailedPropertySerializerDTO,
  DetailedUnitSerializerDTO,
  UnitAdvertisementSerializerDTO,
} from '../../../../../connectors/property';
import {
  Form,
  prepareValidationSchema,
  useTranslation,
} from '../../../../shared';
import PATHS from '../../../paths.const';
import { useAdvertisementConfig } from '../../providers';
import { getDynamicValidationSchema } from '../Form';
import { getConfig, getFields } from './update-form.const';
import type { Values } from './update-form.const';
import { getImages } from './update-form.helper';

type Props = {
  attachments: ListAttachmentSerializerDTO;
  details: UnitAdvertisementSerializerDTO;
  unit: DetailedUnitSerializerDTO;
  propertyObject: DetailedPropertyObjectSerializerDTO;
  property: DetailedPropertySerializerDTO | null;
};

export const UpdateForm: FC<Props> = (props) => {
  const { attachments, details, unit, propertyObject, property } = props;
  const {
    dynamicValidationRules,
    fetchValidationRules$,
    getIntegrationsConfig,
    setDynamicValidationRules,
    validationRules,
  } = useAdvertisementConfig();
  const getImagesData$ = useCallback(
    () => {
      const images = getImages(attachments, unit, propertyObject, property);

      return Promise.resolve(images);
    },
    // prevent rerender on attachments change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [property?.attachments, propertyObject?.attachments, unit?.attachments],
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const priceFieldSuffix = useMemo(
    // TODO: Find better solution - get currency from backend
    () => `${t('currency.pl')} ${t('advertisement.fieldset.pricing.period')}`,
    [t],
  );
  const handleCancel = useCallback(
    () => navigate(generatePath(PATHS.DETAILS, { id: details.uuid })),
    [details.uuid, navigate],
  );
  const handleSuccess = useCallback(
    ({ uuid }: UnitAdvertisementSerializerDTO) =>
      navigate(generatePath(PATHS.DETAILS, { id: uuid })),
    [navigate],
  );
  const config = useMemo(
    () => getConfig(attachments?.results, details),
    [attachments?.results, details],
  );
  const dynamicFieldsConfig = useMemo(
    () =>
      getFields(
        details,
        getImagesData$,
        priceFieldSuffix,
        dynamicValidationRules,
      ),
    [details, getImagesData$, priceFieldSuffix, dynamicValidationRules],
  );
  const validationSchema = useMemo(
    () => prepareValidationSchema(dynamicFieldsConfig),
    [dynamicFieldsConfig],
  );
  const fieldsConfig = useMemo(
    () => getFields(details, getImagesData$, priceFieldSuffix),
    [details, getImagesData$, priceFieldSuffix],
  );
  const integrationsConfig = useMemo(
    () => getIntegrationsConfig(details.countryUuid),
    [details.countryUuid, getIntegrationsConfig],
  );
  const integrations = useMemo(
    () => details.integrations.map(({ integration }) => integration.uuid),
    [details.integrations],
  );

  useEffect(() => {
    fetchValidationRules$(details.countryUuid);
  }, [details.countryUuid, fetchValidationRules$]);

  useEffect(() => {
    const dynamicSchema = getDynamicValidationSchema(
      integrationsConfig,
      integrations,
      validationRules,
    );

    setDynamicValidationRules(dynamicSchema);
  }, [
    integrations,
    integrationsConfig,
    setDynamicValidationRules,
    validationRules,
  ]);

  return (
    <Form<Values>
      config={config}
      fieldsConfig={fieldsConfig}
      handleSuccess={handleSuccess}
      onCancel={handleCancel}
      validationSchema={validationSchema}
    />
  );
};
