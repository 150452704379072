import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<'avatar' | 'box' | 'disabled', SxProps<Theme>> = {
  avatar: {
    height: 24,
    width: 24,
  },
  box: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  disabled: {
    opacity: 0.3,
  },
};
