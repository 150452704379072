import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  actionBlock: {
    ml: 1.5,
  },
  box: {
    alignItems: 'center',
    display: 'flex',
  },
  checkbox: {
    p: (theme) => theme.spacing(0, 2.25, 0, 0.5),
  },
};
