import type { FormikErrors } from 'formik';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import mapValues from 'lodash-es/mapValues';
import merge from 'lodash-es/merge';
import mergeWith from 'lodash-es/mergeWith';
import pickBy from 'lodash-es/pickBy';

import type {
  AdvertisementIntegrationSerializerDTO,
  AttachmentSerializerDTO,
} from '../../../../../connectors/company';
import type {
  AdvertisementIntegrationPlatformValidationRulesSerializerDTO,
  AdvertisementPlatformEnumDTO,
  EntityEnumDTO,
} from '../../../../../connectors/property';
import type { GalleryItem } from '../../../../shared';
import { formValueToValidationSchemaMap, steps } from './form.const';
import type {
  DynamicValidationRule,
  DynamicValidationRules,
  FormValues,
} from './form.types';

export const isStepValid = (
  idx: number,
  errors: FormikErrors<FormValues>,
): boolean =>
  steps
    .filter((step, stepIdx) => stepIdx <= idx)
    .every(
      (step) =>
        step.validationFieldNames.length > 0 &&
        step.validationFieldNames.every((fieldName) =>
          isEmpty(errors[fieldName]),
        ),
    );

export const convertToGalleryItem = (
  attachment: AttachmentSerializerDTO,
  entity: EntityEnumDTO,
): GalleryItem<AttachmentSerializerDTO, EntityEnumDTO> => ({
  ...attachment,
  description: attachment.description,
  entity,
  id: attachment.uuid,
  isActive: false,
  isSelected: false,
  title: attachment.title || attachment.name,
  url: attachment.url,
});

export const getDynamicValidationSchema = (
  integrationsConfig: AdvertisementIntegrationSerializerDTO[],
  integrations: AdvertisementIntegrationSerializerDTO['uuid'][],
  schema: AdvertisementIntegrationPlatformValidationRulesSerializerDTO[],
): DynamicValidationRules => {
  const selectedPlatforms = integrations
    .map(
      (platformId) =>
        integrationsConfig.find((config) => config.uuid === platformId)
          ?.advertisementPlatform,
    )
    .filter((v) => v);

  return schema.reduce<DynamicValidationRules>((acc, value) => {
    if (
      !selectedPlatforms.includes(
        value.platform as AdvertisementPlatformEnumDTO,
      )
    ) {
      return acc;
    }
    const rules = pickBy(value, (v, k) => k !== 'platform' && v !== null);
    const applicableRules = mapValues(formValueToValidationSchemaMap, (rule) =>
      mapValues(rule, (r) => (r ? rules[r] : null)),
    );

    return mergeWith(acc, applicableRules, (value, srcValue, key) => {
      if (typeof value === 'number') {
        switch (key as keyof DynamicValidationRule) {
          case 'max':
          case 'maxLength': {
            return isNil(srcValue) || value < srcValue ? value : srcValue;
          }
          case 'min':
          case 'minLength': {
            return isNil(srcValue) || value > srcValue ? value : srcValue;
          }
          default: {
            return srcValue;
          }
        }
      }

      return merge(value, srcValue);
    });
  }, {});
};
