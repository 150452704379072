import type { DetailedPropertyObjectSerializerDTO } from '../../../../connectors/property';

export const getAddress = (
  addressLine: DetailedPropertyObjectSerializerDTO['addressLine'],
  city: DetailedPropertyObjectSerializerDTO['city'],
  streetNumber: DetailedPropertyObjectSerializerDTO['streetNumber'],
): string =>
  [`${addressLine} ${streetNumber}`, city?.name, city?.country.name]
    .filter((s) => s)
    .join(', ');
