import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC, ReactNode } from 'react';
import React from 'react';

import { AsyncContentProvider } from '../../../../../providers';
import { sxProps } from './thumbnail-info-window.styles';

type Props<T> = {
  getData$: () => Promise<T>;
  titleContent: (item: T) => ReactNode;
  children?: (item: T) => ReactNode;
  descriptionContent?: (item: T) => ReactNode;
  onItemClick?: (item: T) => void;
  thumbnailUrl?: (item: T) => GenericTypes.URL;
};

export const ThumbnailInfoWindow = <T,>(
  props: Props<T>,
): ReturnType<FC<Props<T>>> => {
  const {
    children,
    descriptionContent,
    getData$,
    onItemClick,
    thumbnailUrl,
    titleContent,
  } = props;

  return (
    <AsyncContentProvider getData$={getData$}>
      {(item) => (
        <Grid
          container
          direction="row"
          wrap="nowrap"
          {...(onItemClick && { onClick: () => onItemClick(item) })}
          sx={
            {
              ...sxProps.container,
              ...(onItemClick && sxProps.listItemPointerEvents),
            } as SxProps
          }
        >
          {thumbnailUrl && (
            <Grid item>
              <Paper
                sx={{
                  ...sxProps.thumbnail,
                  backgroundImage: thumbnailUrl
                    ? `url(${thumbnailUrl(item)})`
                    : undefined,
                }}
              />
            </Grid>
          )}
          <Grid item sx={sxProps.contentColumn}>
            <Grid
              container
              direction="column"
              sx={sxProps.contentWrapper}
              wrap="nowrap"
            >
              <Grid item xs={12}>
                <Typography noWrap variant="h3">
                  {titleContent(item)}
                </Typography>
                {descriptionContent && (
                  <Typography noWrap variant="body2">
                    {descriptionContent(item)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider sx={sxProps.divider} />
              </Grid>
              {children && (
                <Grid item xs={12} sx={sxProps.contentRow}>
                  <Grid container wrap="nowrap">
                    <Grid item xs={12}>
                      {children(item)}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </AsyncContentProvider>
  );
};
