import { ReactComponent as TaskChecklistWriteIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-edition/task-checklist-write.svg';
import { ReactComponent as RealLocationHousePinIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/location/real-estate-location-house-pin.svg';
import { ReactComponent as ToolsWenchScrewdriverIcon } from '@heimstaden/icons-library/img/streamline-regular/tools-constructions/tools/tools-wench-screwdriver.svg';
import { ReactComponent as SingleManIdCardIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-man/single-man-id-card.svg';

import type {
  ServiceRequestDetailSerializerDTO,
  ListAttachmentSerializerDTO,
} from '../../../../../connectors/ticket';
import type { FieldConfig, LocalizationConfig } from '../../../../shared';
import type { RequestDetailsData, ServiceRequestValues } from '../../types';
import { AddressStep, getAddressStepFieldsConfig } from './AddressStep';
import { CategoryStep, getCategoryStepFieldsConfig } from './CategoryStep';
import { DetailsStep, getDetailsFieldsConfig } from './DetailsStep';
import { SummaryStep } from './SummaryStep/summary-step.component';
import type { Step } from './service-request-form.type';

export const steps: Step[] = [
  {
    component: AddressStep,
    icon: RealLocationHousePinIcon,
    key: 'service-request-address',
    labelKey: 'ticket.create.steps.address',
    validationFieldNames: ['city', 'country', 'entity', 'entityType'],
  },
  {
    component: CategoryStep,
    icon: ToolsWenchScrewdriverIcon,
    key: 'service-request-category',
    labelKey: 'ticket.create.steps.category',
    validationFieldNames: [
      'damagedItem',
      'dueDate',
      'issueLocation',
      'issueSubtype',
      'issueType',
      'roomWithIssue',
    ],
  },
  {
    component: DetailsStep,
    icon: SingleManIdCardIcon,
    key: 'service-request-details',
    labelKey: 'ticket.create.steps.details',
    validationFieldNames: [
      'apartment',
      'summary',
      'description',
      'reporter',
      'tenant',
    ],
  },
  {
    component: SummaryStep,
    icon: TaskChecklistWriteIcon,
    key: 'service-request-summery',
    labelKey: 'ticket.create.steps.summary',
    validationFieldNames: [],
  },
];

export const getFieldsConfig = (
  countries: LocalizationConfig['countryOptions'],
  details?: RequestDetailsData<ServiceRequestDetailSerializerDTO>,
  attachments: ListAttachmentSerializerDTO['results'] = [],
): FieldConfig<ServiceRequestValues>[] => [
  ...getAddressStepFieldsConfig(countries, details),
  ...getCategoryStepFieldsConfig(
    {
      getDamagedItemOptions: () => [],
      getIssueSubtypes: () => [],
      getIssueTypeOptions: () => [],
      getRoomTypeOptions: () => [],
      locationTypeOptions: [],
    },
    details,
  ),
  ...getDetailsFieldsConfig(true, details, attachments),
];
