import type { FC, ReactNode } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import type {
  AttachmentTypeEnumDTO,
  CommonProcessSerializersAttachmentAttachmentSerializerDTO as AttachmentSerializerDTO, // eslint-disable-line max-len
} from '../../../../../../connectors/property';
import { AttachmentCategoryEnumDTO } from '../../../../../../connectors/property';
import type { AttachmentVisibilityEnumDTO } from '../../../../../../connectors/ticket';
import type { CustomFile } from '../../../../form';
import { useTranslation } from '../../../../translations';
import { AttachmentDialog } from '../../../index';
import type { ContextValue } from './context';
import { Context } from './context';
import type { Attachment } from './type';

type Props<Meta = unknown> = {
  children: ReactNode;
  customForm?: ReactNode;
  onUpload$?: (
    category: AttachmentCategoryEnumDTO,
    file: CustomFile,
    type: AttachmentTypeEnumDTO,
    visibility: AttachmentVisibilityEnumDTO,
    meta?: Meta,
  ) => Promise<AttachmentSerializerDTO[]>;
  onUploaded?: (attachments: AttachmentSerializerDTO[]) => void;
};

export const Provider = <Meta,>(
  props: Props<Meta>,
): ReturnType<FC<Props<Meta>>> => {
  const { children, customForm, onUpload$, onUploaded } = props;
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState<Attachment<Meta>[]>([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const closeDialog = useCallback(() => {
    setDialogOpen(false);
    setLoading(false);
    setAttachments([]);
  }, []);
  const openDialog = useCallback(() => setDialogOpen(true), []);
  const upload$ = useCallback(async () => {
    if (!onUpload$) return;

    setLoading(true);
    const requests$ = attachments.map((attachment) =>
      onUpload$(
        AttachmentCategoryEnumDTO.Document,
        attachment.file,
        attachment.type,
        attachment.visibility,
        attachment.meta,
      ),
    );

    try {
      const attachments = await Promise.all(requests$);

      onUploaded?.(attachments.flat());
      closeDialog();
    } catch (e) {
      toast.error(t('errors.general.message'));
    } finally {
      setLoading(false);
    }
  }, [attachments, closeDialog, onUpload$, onUploaded, t]);
  const value = useMemo(
    () => ({
      attachments,
      closeDialog,
      customForm,
      isDialogOpen,
      isLoading,
      openDialog,
      setAttachments,
      upload$,
    }),
    [
      attachments,
      closeDialog,
      customForm,
      isDialogOpen,
      isLoading,
      openDialog,
      upload$,
    ],
  );

  return (
    <Context.Provider value={value as ContextValue}>
      {children}
      <AttachmentDialog />
    </Context.Provider>
  );
};
