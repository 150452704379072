import React from 'react';
import { Outlet } from 'react-router-dom';

import { PermissionGuard } from '../+auth/shared/guards/permission.guard';
import { APP_MAIN_PATH } from '../routing';
import { componentLazyImport } from '../routing/routes.helper';
import { DefaultRouteTabContent } from './DefaultRouteTabContent/default-route-tab-content.component';
import PATHS from './paths.const';
import { VIEW_PERMISSIONS, WRITE_PERMISSIONS } from './shared';

const NotFound = componentLazyImport(
  () => import('../shared/errors/NotFound/not-found.component'),
  'USER_NOT_FOUND',
);
const ActivityLogList = componentLazyImport(
  () => import('./ActivityLogList/activity-log-list.component'),
  'USER_ACTIVITY_LOG_LIST',
);
const Create = componentLazyImport(
  () => import('./Create/create.component'),
  'USER_CREATE',
);
const Details = componentLazyImport(
  () => import('./Details/details.component'),
  'USER_DETAILS',
);
const HomeList = componentLazyImport(
  () => import('./HomeList/home-list.component'),
  'USER_HOME_LIST',
);
const LeaseList = componentLazyImport(
  () => import('./LeaseList/lease-list.component'),
  'USER_LEASE_LIST',
);
const List = componentLazyImport(
  () => import('./List/list.component'),
  'USER_LIST',
);
const PropertyObjectList = componentLazyImport(
  () => import('./PropertyObjectList/property-object-list.component'),
  'USER_PROPERTY_OBJECT_LIST',
);
const ComplaintList = componentLazyImport(
  () => import('./ComplaintList/complaint-list.component'),
  'USER_COMPLAINT_LIST',
);
const ContractRequestList = componentLazyImport(
  () => import('./ContractRequestList/contract-request-list.component'),
  'USER_CONTRACT_REQUEST_LIST',
);
const GeneralRequestList = componentLazyImport(
  () => import('./GeneralRequestList/general-request-list.component'),
  'USER_GENERAL_REQUEST_LIST',
);
const InvoiceRequestList = componentLazyImport(
  () => import('./InvoiceRequestList/invoice-request-list.component'),
  'USER_INVOICE_REQUEST_LIST',
);
const ServiceRequestList = componentLazyImport(
  () => import('./ServiceRequestList/service-request-list.component'),
  'USER_SERVICE_REQUEST_LIST',
);
const Update = componentLazyImport(
  () => import('./Update/update.component'),
  'USER_UPDATE',
);
const CreateComplaint = componentLazyImport(
  () => import('./CreateComplaint/create-complaint.component'),
  'USER_COMPLAINT_CREATE',
);
const CreateContractRequest = componentLazyImport(
  () => import('./CreateContractRequest/create-contract-request.component'),
  'USER_CONTRACT_REQUEST_CREATE',
);
const CreateGeneralRequest = componentLazyImport(
  () => import('./CreateGeneralRequest/create-general-request.component'),
  'USER_GENERAL_REQUEST_CREATE',
);
const CreateInvoiceRequest = componentLazyImport(
  () => import('./CreateInvoiceRequest/create-invoice-request.component'),
  'USER_INVOICE_REQUEST_CREATE',
);
const CreateServiceRequest = componentLazyImport(
  () => import('./CreateServiceRequest/create-service-request.component'),
  'USER_SERVICE_REQUEST_CREATE',
);
const Settings = componentLazyImport(
  () => import('./Settings/settings.component'),
  'USER_SETTINGS',
);

const routes = {
  children: [
    { element: <List />, path: '' },
    { element: <Settings />, path: 'settings' },
    { element: <CreateComplaint />, path: ':id/complaint' },
    { element: <CreateContractRequest />, path: ':id/contract-request' },
    { element: <CreateGeneralRequest />, path: ':id/general-request' },
    { element: <CreateInvoiceRequest />, path: ':id/invoice-request' },
    { element: <CreateServiceRequest />, path: ':id/service-request' },
    {
      children: [
        {
          element: (
            <PermissionGuard
              fallbackRoute={PATHS.LIST}
              permissions={WRITE_PERMISSIONS}
            >
              <Create />
            </PermissionGuard>
          ),
          path: '',
        },
        { element: <NotFound />, path: '*' },
      ],
      element: <Outlet />,
      path: 'create',
    },
    { element: <Update />, path: ':id/update' },
    {
      children: [
        { element: <DefaultRouteTabContent />, path: '' },
        { element: <ActivityLogList />, path: 'logs' },
        { element: <HomeList />, path: 'homes' },
        { element: <LeaseList />, path: 'leases' },
        { element: <PropertyObjectList />, path: 'property-objects' },
        { element: <ComplaintList />, path: 'complaints' },
        { element: <ContractRequestList />, path: 'contract-requests' },
        { element: <InvoiceRequestList />, path: 'invoice-requests' },
        { element: <GeneralRequestList />, path: 'general-requests' },
        { element: <ServiceRequestList />, path: 'service-requests' },
        { element: <NotFound />, path: '*' },
      ],
      element: <Details />,
      path: ':id',
    },
    { element: <NotFound />, path: '*' },
  ],
  element: (
    <PermissionGuard
      fallbackRoute={APP_MAIN_PATH}
      permissions={VIEW_PERMISSIONS}
    >
      <Outlet />
    </PermissionGuard>
  ),
  path: 'users',
};

export default routes;
