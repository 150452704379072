import createSvgIcon from '@mui/material/utils/createSvgIcon';
import React from 'react';

export const ChevronDownIcon = createSvgIcon(
  <svg
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.76375 6.5637C4.93253 6.39498 5.1614 6.30019 5.40005 6.30019C5.6387
      6.30019 5.86758 6.39498 6.03635 6.5637L9.00005 9.5274L11.9638
      6.5637C12.0468 6.47774 12.1461 6.40918 12.2559 6.36201C12.3657 6.31484
      12.4838 6.29001 12.6033 6.28897C12.7228 6.28794 12.8413 6.31071 12.9519
      6.35596C13.0625 6.40121 13.163 6.46804 13.2475 6.55254C13.332 6.63705
      13.3988 6.73753 13.4441 6.84814C13.4893 6.95875 13.5121 7.07726 13.5111
      7.19676C13.51 7.31626 13.4852 7.43436 13.438 7.54416C13.3909 7.65397
      13.3223 7.75328 13.2364 7.8363L9.63635 11.4363C9.46758 11.605 9.2387
      11.6998 9.00005 11.6998C8.7614 11.6998 8.53253 11.605 8.36375
      11.4363L4.76375 7.8363C4.59503 7.66752 4.50024 7.43865 4.50024 7.2C4.50024
       6.96135 4.59503 6.73247 4.76375 6.5637V6.5637Z"
    />
  </svg>,
  'ChevronDown',
);
