import { isBefore, isValid } from 'date-fns';

import type {
  AuthenticationTypeEnumDTO,
  LoginOutputSerializerDTO,
} from '../../../connectors/user';

type StorageKey =
  | 'ACCESS_TOKEN'
  | 'AUTH_TYPE'
  | 'ACCEPTED_COOKIE_DATE'
  | 'ID_TOKEN'
  | 'REFRESH_TOKEN'
  | 'REQUESTED_LOCATION';

type StorageType = 'LOCAL' | 'SESSION';

class StorageService {
  /**
   * @return {boolean}
   */
  public isCookieAccepted = (): boolean => {
    const cookieDate = this.getItem('ACCEPTED_COOKIE_DATE');
    const date = new Date(cookieDate);

    return isValid(date) && isBefore(date, new Date());
  };

  /**
   * @return {string}
   */
  public getToken = (): string => this.getItem('ACCESS_TOKEN');

  /**
   * @return {AuthenticationTypeEnumDTO}
   */
  public getAuthType = (): AuthenticationTypeEnumDTO =>
    this.getItem('AUTH_TYPE') as AuthenticationTypeEnumDTO;

  /**
   * @return {string}
   */
  public getRefreshToken = (): string => this.getItem('REFRESH_TOKEN');

  /**
   * @return {string}
   */
  public getIdToken = (): string => this.getItem('ID_TOKEN');

  /**
   * @return {string}
   */
  public getRequestedLocation = (): string =>
    this.getItem('REQUESTED_LOCATION', 'SESSION');

  public removeRequestedLocation = (): void => {
    this.removeItem('REQUESTED_LOCATION', 'SESSION');
  };

  public setCookie = (): void => {
    this.setItem('ACCEPTED_COOKIE_DATE', new Date().toISOString(), 'LOCAL');
  };

  /**
   * @param location {string}
   */
  public setRequestedLocation = (location: string): void => {
    this.setItem('REQUESTED_LOCATION', location, 'SESSION');
  };

  /**
   * @param tokens {LoginOutputSerializerDTO}
   */
  public setTokens = (tokens: LoginOutputSerializerDTO): void => {
    const { accessToken, idToken, refreshToken } = tokens;

    this.setItem('ACCESS_TOKEN', accessToken);
    this.setItem('REFRESH_TOKEN', refreshToken);
    this.setItem('ID_TOKEN', idToken);
  };

  /**
   * @param type {AuthenticationTypeEnumDTO}
   */
  public setAuthType = (type: AuthenticationTypeEnumDTO): void => {
    this.setItem('AUTH_TYPE', type);
  };

  public clear = (): void => {
    this.removeItem('ACCESS_TOKEN');
    this.removeItem('AUTH_TYPE');
    this.removeItem('REFRESH_TOKEN');
    this.removeItem('ID_TOKEN');
  };

  /**
   * @param type {StorageType}
   * @return {string}
   */
  private getStorage = (type: StorageType): Storage =>
    type === 'SESSION' ? sessionStorage : localStorage;

  /**
   * @param item {StorageKey}
   * @param storageType {StorageType}
   */
  private removeItem = (
    item: StorageKey,
    storageType: StorageType = 'LOCAL',
  ): void => {
    const storage = this.getStorage(storageType);

    storage.removeItem(item);
  };

  /**
   * @param key {StorageKey}
   * @param storageType {StorageType}
   *
   * @return {string}
   */
  private getItem = (
    key: StorageKey,
    storageType: StorageType = 'LOCAL',
  ): string => {
    const storage = this.getStorage(storageType);

    return storage.getItem(key) || '';
  };

  /**
   * @param {StorageKey} key
   * @param {string} value
   * @param storageType {StorageType}
   */
  private setItem = (
    key: StorageKey,
    value: string,
    storageType: StorageType = 'LOCAL',
  ): void => {
    const storage = this.getStorage(storageType);

    storage.setItem(key, value);
  };
}
export default new StorageService();
