// Colors named based on https://chir.ag/projects/name-that-color
export const palette = {
  alabaster: '#fafafa',
  alto: '#e0e0e0',
  amethyst: '#ab47bc',
  athensGray: '#f4f5f7',
  atomicTangerine: '#ff9c5e',
  black: '#000000',
  blueRibbon: '#0050ff',
  boulder: '#757575',
  burningOrange: '#ff7043',
  burntSienna: '#ef5350',
  catskillWhite: '#f4f7fa',
  celery: '#9ccc65',
  cement: '#8d6e63',
  dodgerBlue: '#29b6f6',
  doveGray: '#616161',
  ebonyClay: '#222b36',
  fern: '#66bb6a',
  fiord: '#42526e',
  fiord44: '#44546a',
  frenchRose: '#ec407a',
  fruitSalad: '#4caf50',
  fuchsiaBlue: '#7e57c2',
  gallery: '#ececec',
  gorse: '#ffee58',
  governorBay: '#2a3eb3',
  grenadier: '#d04100',
  heather: '#b5c1d2',
  indigo: '#5c6bc0',
  internationalOrange: '#ff5000',
  jungleGreen: '#26a69a',
  mineShaft: '#333132',
  mirage: '#171c24',
  orangePeel: '#ff9800',
  periwinkleGray: '#ccd7eb',
  pictonBlue: '#42a5f5',
  pomegranate: '#f44336',
  regentGray: '#78909c',
  regentGray91: '#919eab',
  scooter: '#26c6da',
  seashellpeach: '#fff2ec',
  selectiveYellow: '#ffb400',
  silver: '#bdbdbd',
  silverChalice: '#9e9e9e',
  staleGray: '#6b778c',
  sunglow: '#ffca28',
  sunshade: '#ffa726',
  surfCrest: '#d7e8d9',
  waikawaGray: '#5d728f',
  wattle: '#d4e157',
  white: '#ffffff',
  wildSand: '#f5f5f5',
};
