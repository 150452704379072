import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    height: '100%',
    width: '100%',
  },
  content: {
    height: '100%',
    overflow: 'auto',
    width: '100%',
  },
};
