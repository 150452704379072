import { ReactComponent as ArchitectureDoorIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';

import type { AttachmentSequenceSerializerDTO } from '../../connectors/company';
import { GeneralAPI } from '../../connectors/company';
import { EntityEnumDTO, UnitsAPI } from '../../connectors/property';
import type {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
  CitySerializerDTO,
  CommonProcessSerializersAttachmentAttachmentSerializerDTO as AttachmentSerializerDTO, // eslint-disable-line max-len
  ContactSerializerDTO,
  CoordinatesSerializerDTO,
  CountrySerializerDTO,
  CreateContactSerializerDTO,
  CreateUnitSerializerDTO,
  DetailedUnitSerializerDTO,
  ListAttachmentSerializerDTO,
  ListContactSerializerDTO,
  ListLogSerializerDTO,
  ListTenantSerializerDTO,
  ListUnitsSerializerDTO,
  PatchUnitSerializerDTO,
  PropertyObjectSerializerDTO,
  PropertySerializerDTO,
  TenantSerializerDTO,
  UnitSerializerDTO,
} from '../../connectors/property';
import type { AttachmentVisibilityEnumDTO } from '../../connectors/ticket';
import { TicketsAPI } from '../../connectors/ticket';
import type { UserRoleEnumDTO } from '../../connectors/user';
import { mieInstance } from '../mie.instance';
import { getUserName } from '../shared';
import type {
  AsyncAutocompleteOption,
  AttachmentFilterValues,
  CustomFile,
  ListQueryParams,
  LocalizationFilterValues,
} from '../shared';
import { getEnvValue } from '../shared/helpers/env.helper';
import type { UnitCategoryPath } from './paths.const';
import { getAddress, getCategoryQueryParams } from './shared';

class UnitClient {
  private client;

  private generalClient;

  private ticketClient;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.client = new UnitsAPI(undefined, baeBath, mieInstance);
    this.generalClient = new GeneralAPI(undefined, baeBath, mieInstance);
    this.ticketClient = new TicketsAPI(undefined, baeBath, mieInstance);
  }

  public getCoordinates$ = async (
    queryParams?: ListQueryParams<LocalizationFilterValues>,
  ): Promise<CoordinatesSerializerDTO[]> => {
    const { filters, search } = { ...queryParams };
    const { city, country, region } = { ...filters };
    const response =
      await this.client.getUnitsCoordinatesListUnitsCoordinatesGet({
        cityUuid: city,
        countryUuid: country,
        regionUuid: region,
        search,
      });

    return response.data;
  };

  public getList$ = async (
    categoryList: UnitCategoryPath,
    queryParams?: ListQueryParams<LocalizationFilterValues>,
  ): Promise<ListUnitsSerializerDTO> => {
    const { filters, page, pageSize, search } = { ...queryParams };
    const { city, country, region } = { ...filters };
    const { category, excludeCategory } = getCategoryQueryParams(categoryList);
    const response = await this.client.getUnitsUnitsGet({
      category,
      cityUuid: city,
      countryUuid: country,
      excludeCategory,
      page,
      pageSize,
      regionUuid: region,
      search,
    });

    return response.data;
  };

  public getDetails$ = async (
    id: UnitSerializerDTO['uuid'],
  ): Promise<DetailedUnitSerializerDTO> => {
    const response = await this.client.getSingleUnitUnitsUnitUuidGet({
      unitUuid: id,
    });

    return response.data;
  };

  public getLogs$ = async (
    id: UnitSerializerDTO['uuid'],
    queryParams?: ListQueryParams,
  ): Promise<ListLogSerializerDTO> => {
    const { page, pageSize } = { ...queryParams };
    const response =
      await this.client.getSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet({
        page,
        pageSize,
        unitUuid: id,
      });

    return response.data;
  };

  public update$ = async (
    id: UnitSerializerDTO['uuid'],
    values: PatchUnitSerializerDTO,
  ): Promise<UnitSerializerDTO> => {
    const response = await this.client.patchUnitUnitsUnitUuidPatch({
      patchUnitSerializerDTO: values,
      unitUuid: id,
    });

    return response.data;
  };

  public create$ = async (
    values: CreateUnitSerializerDTO,
  ): Promise<UnitSerializerDTO> => {
    const response = await this.client.createUnitUnitsPost({
      createUnitSerializerDTO: values,
    });

    return response.data;
  };

  public delete$ = async (id: UnitSerializerDTO['uuid']): Promise<void> => {
    await this.client.deleteUnitUnitsUnitUuidDelete({ unitUuid: id });
  };

  public getSuggestions$ = async (
    query: string,
    category?: UnitCategoryPath,
    city?: CitySerializerDTO['uuid'],
    country?: CountrySerializerDTO['uuid'],
    property?: PropertySerializerDTO['uuid'],
    propertyObject?: PropertyObjectSerializerDTO['uuid'],
  ): Promise<AsyncAutocompleteOption<UnitSerializerDTO['uuid']>[]> => {
    const { category: queryParamCategory, excludeCategory } = category
      ? getCategoryQueryParams(category)
      : { category: undefined, excludeCategory: undefined };
    const response = await this.client.getUnitsUnitsGet({
      category: queryParamCategory,
      cityUuid: city,
      countryUuid: country,
      excludeCategory,
      propertyObjectUuid: propertyObject,
      propertyUuid: property,
      search: query,
    });

    return response.data.results.map((result) => {
      const address = getAddress(
        result.addressLine,
        result.city,
        result.streetNumber,
      );

      return {
        additionalValues: result,
        icon: ArchitectureDoorIcon,
        label: `${result.name} (${address})`,
        value: result.uuid,
      };
    });
  };

  public getTenants$ = async (
    id: UnitSerializerDTO['uuid'],
    queryParams?: ListQueryParams,
  ): Promise<ListTenantSerializerDTO> => {
    const { page, pageSize, search } = { ...queryParams };
    const response = await this.client.getTenantsForUnitUnitsUnitUuidTenantsGet(
      {
        page,
        pageSize,
        search,
        unitUuid: id,
      },
    );

    return response.data;
  };

  public getTenantSuggestions$ = async (
    id: UnitSerializerDTO['uuid'],
    query: string,
  ): Promise<AsyncAutocompleteOption<TenantSerializerDTO['uuid']>[]> => {
    const response = await this.getTenants$(id, { search: query });

    return (response.results || []).map((user) => ({
      label: `${getUserName(user)} - ${user.email}`,
      value: user.uuid,
    }));
  };

  public getAttachments$ = async (
    id: UnitSerializerDTO['uuid'],
    category?: AttachmentCategoryEnumDTO,
    queryParams?: ListQueryParams<AttachmentFilterValues>,
  ): Promise<ListAttachmentSerializerDTO> => {
    const {
      filters,
      page,
      pageSize = 100,
      search,
      sort = 'sequence_number',
    } = { ...queryParams };
    const { type } = { ...filters };
    const response =
      await this.client.getUnitAttachmentUnitsUnitUuidAttachmentsGet({
        category,
        order: sort,
        page,
        pageSize,
        search,
        type: type?.join(',') || undefined,
        unitUuid: id,
      });

    return response.data;
  };

  public uploadAttachment$ = async (
    category: AttachmentCategoryEnumDTO,
    file: CustomFile,
    type: AttachmentTypeEnumDTO,
    id: UnitSerializerDTO['uuid'],
    visibility: AttachmentVisibilityEnumDTO,
  ): Promise<AttachmentSerializerDTO[]> => {
    const response =
      await this.client.uploadUnitAttachmentUnitsUnitUuidAttachmentsPost({
        attachmentCategory: category,
        attachmentType: type,
        description: file.description,
        files: [file],
        title: file.title,
        unitUuid: id,
        visibility,
      });

    return response.data;
  };

  public updateAttachment$ = async (
    attachment: AttachmentSerializerDTO,
    id: UnitSerializerDTO['uuid'],
  ): Promise<AttachmentSerializerDTO> => {
    const response =
      await this.generalClient.patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch(
        {
          attachmentUuid: attachment.uuid,
          entityType: EntityEnumDTO.Unit,
          entityUuid: id,
          patchAttachmentSerializerDTO: attachment,
        },
      );

    return response.data;
  };

  public updateAttachmentsSequence$ = async (
    sequence: AttachmentSequenceSerializerDTO[],
  ): Promise<AttachmentSequenceSerializerDTO[]> => {
    const response =
      await this.generalClient.updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut(
        { listAttachmentSequenceSerializerDTO: { attachments: sequence } },
      );

    return response.data.results;
  };

  public deleteAttachment$ = async (
    id: UnitSerializerDTO['uuid'],
    attachmentId: AttachmentSerializerDTO['uuid'],
  ): Promise<void> => {
    await this.client.deleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete(
      {
        attachmentUuid: attachmentId,
        unitUuid: id,
      },
    );
  };

  public downloadAttachment$ = async (
    id: UnitSerializerDTO['uuid'],
    attachmentId: AttachmentSerializerDTO['uuid'],
  ): Promise<string> => {
    const response =
      await this.client.downloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet(
        {
          attachmentUuid: attachmentId,
          unitUuid: id,
        },
        { responseType: 'blob' },
      );

    return response.data;
  };

  public addContact$ = async (
    id: UnitSerializerDTO['uuid'],
    userId: CreateContactSerializerDTO['userUuid'],
  ): Promise<ContactSerializerDTO> => {
    const response =
      await this.client.addContactForUnitUnitsUnitUuidContactsPost({
        createContactSerializerDTO: {
          userUuid: userId,
        },
        unitUuid: id,
      });

    return response.data;
  };

  public getContacts$ = async (
    id: UnitSerializerDTO['uuid'],
    queryParams?: ListQueryParams<{
      excludedRole?: UserRoleEnumDTO[];
      role?: UserRoleEnumDTO[];
    }>,
  ): Promise<ListContactSerializerDTO> => {
    const { filters, search } = { ...queryParams };
    const { excludedRole, role } = { ...filters };
    const response =
      await this.client.getContactsForUnitUnitsUnitUuidContactsGet({
        excludeRole: excludedRole?.join(','),
        role: role?.join(','),
        search,
        unitUuid: id,
      });

    return response.data;
  };

  public deleteContact$ = async (
    id: UnitSerializerDTO['uuid'],
    contactUuid: ContactSerializerDTO['uuid'],
  ): Promise<void> => {
    await this.client.deleteContactForUnitUnitsUnitUuidContactsContactUuidDelete(
      {
        contactUuid,
        unitUuid: id,
      },
    );
  };
}
export const unitClient = new UnitClient();
