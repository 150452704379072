import { autocompleteClasses, outlinedInputClasses } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  filterInput: {
    [`& .${outlinedInputClasses.root} .${autocompleteClasses.input}`]: {
      p: 0,
      pr: 1,
    },
    [`& .${outlinedInputClasses.root}`]: {
      fontSize: (theme) => theme.typography.pxToRem(13),
      fontWeight: (theme) => theme.typography.fontWeightBold,
      px: 1,
      py: 0.5,
      // eslint-disable-next-line sort-keys
      pr: (theme) => `${theme.spacing(1)} !important`, // TODO: I don't know how to do it in the better way
    },
    '& *': {
      border: 'none',
    },
    p: 0,
  },
  input: {
    pr: (theme) => `${theme.spacing(2)} !important`, // TODO: I don't know how to do it in the better way
  },
};
