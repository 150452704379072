const PATHS = {
  ACTIVITY_LOG_LIST: '/users/:id/logs',
  ATTACHMENT_LIST: '/users/:id/attachments',
  ATTACHMENTS_UPLOAD: '/users/:id/attachments/upload',
  COMPLAINT_LIST: '/users/:id/complaints',
  CONTRACT_REQUEST_LIST: '/users/:id/contract-requests',
  CREATE: '/users/create',
  CREATE_COMPLAINT: '/users/:id/complaint',
  CREATE_CONTRACT_REQUEST: '/users/:id/contract-request',
  CREATE_GENERAL_REQUEST: '/users/:id/general-request',
  CREATE_INVOICE_REQUEST: '/users/:id/invoice-request',
  CREATE_SERVICE_REQUEST: '/users/:id/service-request',
  DETAILS: '/users/:id',
  GENERAL_REQUEST_LIST: '/users/:id/general-requests',
  HOME_LIST: '/users/:id/homes',
  INVOICE_REQUEST_LIST: '/users/:id/invoice-requests',
  LEASE_LIST: '/users/:id/leases',
  LIST: '/users',
  PROPERTY_OBJECT_LIST: '/users/:id/property-objects',
  SERVICE_REQUEST_LIST: '/users/:id/service-requests',
  SETTINGS: '/users/settings',
  UPDATE: '/users/:id/update',
};

export default PATHS;
