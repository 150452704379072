import type { ChangeEvent } from 'react';
import { generatePath } from 'react-router';

import { USER_PATHS } from '../../+user';
import type {
  CommentSerializerDTO,
  LinkedUserSerializerDTO,
} from '../../../connectors/ticket';
import type { EmployeeSerializerDTO } from '../../../connectors/user';
import { getUserName } from './format.helper';

const MENTION_SIGN = '@';

export type MentionTransformation = { displayValue: string; rawValue: string };

export const getMentionConfig = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
): {
  isMentionTriggered: boolean;
  query: string;
  selectionStart: number | null;
  triggerSignIdx: number;
} => {
  const { selectionStart, value } = event.target;
  const textBeforeCaret = value.slice(0, selectionStart || undefined);
  const tokens: string[] = textBeforeCaret.split(/\s/);
  const lastToken = tokens[tokens.length - 1];
  const triggerSignIdx = textBeforeCaret.endsWith(lastToken)
    ? textBeforeCaret.length - lastToken.length
    : -1;
  const potentialTriggerSign = textBeforeCaret[triggerSignIdx];
  const isMentionTriggered = potentialTriggerSign === MENTION_SIGN;
  const query = textBeforeCaret.slice(triggerSignIdx + 1);

  return { isMentionTriggered, query, selectionStart, triggerSignIdx };
};

export const getValueWithMention = (
  suggestion: EmployeeSerializerDTO,
  selectionStart: number | null,
  triggerSignIdx: number,
  value: string,
): string => {
  const preMention = value.substring(0, triggerSignIdx);
  // eslint-disable-next-line max-len
  const mention = `${MENTION_SIGN}${getUserName(suggestion)}`;
  const postMention =
    selectionStart !== null ? value.substring(selectionStart) : '';

  return `${preMention}${mention}${postMention}`;
};

export const getMentionTransformation = (
  suggestion: EmployeeSerializerDTO,
): MentionTransformation => ({
  displayValue: `${MENTION_SIGN}${getUserName(suggestion)}`,
  rawValue: `${MENTION_SIGN}${suggestion.uuid}`,
});

export const getCommentDisplayConfig = (
  content: CommentSerializerDTO['content'],
  mentionedUsers: CommentSerializerDTO['taggedUsers'],
): {
  content: string;
  id: number;
  mention?: LinkedUserSerializerDTO;
  path?: GenericTypes.URL;
}[] => {
  if (!mentionedUsers || mentionedUsers.length === 0)
    return [{ content, id: 0 }];

  const regExp = new RegExp(
    mentionedUsers.map((user) => `(${MENTION_SIGN}${user.uuid})`).join('|'),
    'g',
  );
  const splittedContent = content.split(regExp).filter((s) => s);

  return splittedContent.map((phrase, idx) => {
    const mention = mentionedUsers.find(
      (user) => phrase === `${MENTION_SIGN}${user.uuid}`,
    );

    return {
      content: mention ? getUserName(mention) : phrase,
      id: idx,
      mention,
      path: mention
        ? generatePath(USER_PATHS.DETAILS, { id: mention.uuid })
        : undefined,
    };
  });
};

export const getCommentRawValue = (
  mentionTransformations: MentionTransformation[],
  value: string,
): string =>
  mentionTransformations.reduce(
    (acc, v) => acc.replace(new RegExp(v.displayValue, 'g'), v.rawValue),
    value,
  );

export const isMentionIncluded = (
  mentionTransformations: MentionTransformation[],
  value: string,
): boolean =>
  mentionTransformations.some((transformation) =>
    value.includes(transformation.displayValue),
  );
