import { ReactComponent as ArrowDown1Icon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-down-1.svg';
import { ReactComponent as ArrowUp1Icon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-up-1.svg';
import { ReactComponent as ShippingLogisticEstimateTimeArrival2Icon } from '@heimstaden/icons-library/img/streamline-regular/shipping-delivery/shipment/shipping-logistic-estimate-time-arrival-2.svg';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import capitalize from 'lodash-es/capitalize';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import type { LogSerializerDTO } from '../../../../../../connectors/user';
import { FormattedDate } from '../../../../components';
import { sanitizeService } from '../../../../services';
import { useTranslation } from '../../../../translations';
import { sxProps } from './row-content.styles';

type Props = LogSerializerDTO & { clickedId?: LogSerializerDTO['uuid'] };

export const RowContent: FC<Props> = (props) => {
  const { clickedId, createdAt, field, newValue, oldValue, uuid } = props;
  const { t } = useTranslation();
  const fieldName = useMemo(
    () => capitalize(field).replace(/_/g, ' '),
    [field],
  );
  const isOpen = useMemo(() => clickedId === uuid, [clickedId, uuid]);

  return (
    <TableCell data-testid="activity-log-row" sx={sxProps.cell}>
      <Box sx={sxProps.box}>
        <Box sx={sxProps.icon}>
          <ShippingLogisticEstimateTimeArrival2Icon height={24} width={24} />
        </Box>
        <Box sx={sxProps.content}>
          <Box>
            <Typography>
              <Trans
                components={{ bold: <strong /> }}
                i18nKey="activityLog.text"
                values={{ fieldName }}
              />
            </Typography>
            <Typography variant="body2">
              <FormattedDate
                date={createdAt}
                format="standardDateTimeWithDay"
              />
            </Typography>
          </Box>
          {isOpen ? (
            <ArrowUp1Icon height={16} width={16} />
          ) : (
            <ArrowDown1Icon
              data-testid="activity-log-row-arrow-down"
              height={16}
              width={16}
            />
          )}
        </Box>
      </Box>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Table size="small" sx={sxProps.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  {t('activityLog.fields.oldValue')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">
                  {t('activityLog.fields.newValue')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                dangerouslySetInnerHTML={{
                  __html: sanitizeService.cleanText(oldValue),
                }}
                sx={sxProps.cellInfo}
              />
              <TableCell
                dangerouslySetInnerHTML={{
                  __html: sanitizeService.cleanText(newValue),
                }}
                sx={sxProps.cellInfo}
              />
            </TableRow>
          </TableBody>
        </Table>
      </Collapse>
    </TableCell>
  );
};
