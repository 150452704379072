import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<'button', SxProps<Theme>> = {
  button: {
    '& path': {
      stroke: 'currentColor',
    },
  },
};
