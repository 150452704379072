import { ReactComponent as SearchIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/search/search.svg';
import Autocomplete from '@mui/material/Autocomplete';
import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import type { FC, Dispatch, SetStateAction } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDebounce } from '../../../hooks';
import { useTranslation } from '../../../translations';
import { DEFAULT_ASYNC_AUTOCOMPLETE_VALUE } from '../../form.const';
import { sxProps } from './location-search-box.styles';

type Props = {
  disabled: boolean;
  formInput: string;
  map: google.maps.Map;
  placeholderKey?: GenericTypes.TranslationLabel;
  prediction: google.maps.places.AutocompletePrediction | null;
  required: boolean;
  setData: Dispatch<
    SetStateAction<{ latLng: google.maps.LatLng; placeId?: string }>
  >;
  setPrediction: Dispatch<
    SetStateAction<google.maps.places.AutocompletePrediction | null>
  >;
  errorMessageKey?: GenericTypes.TranslationLabel;
};

export const LocationSearchBox: FC<Props> = (props) => {
  const {
    disabled,
    errorMessageKey,
    formInput,
    map,
    placeholderKey,
    prediction,
    required,
    setData,
    setPrediction,
  } = props;
  const { t } = useTranslation();
  const [isInitialized, setInitialized] = useState(false);
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [isLoading, setLoading] = useState(false);
  const autocompleteService = useMemo(
    () => new google.maps.places.AutocompleteService(),
    [],
  );
  const placesService = useMemo(
    () => new google.maps.places.PlacesService(map),
    [map],
  );
  const setPlacePosition = useCallback(
    (placeId?: google.maps.places.AutocompletePrediction['place_id']) => {
      if (!placeId) return;

      placesService.getDetails(
        { fields: ['geometry.location'], placeId },
        (place) => {
          if (!place || !place.geometry?.location) return;

          setData({ latLng: place.geometry.location, placeId });
        },
      );
    },
    [placesService, setData],
  );
  const handleAutocompleteChange = useCallback(
    (_, prediction: google.maps.places.AutocompletePrediction | null): void => {
      setPlacePosition(prediction?.place_id || '');
      setPrediction(prediction);
    },
    [setPlacePosition, setPrediction],
  );
  const getPlaces$ = useCallback(
    async (
      input: string,
    ): Promise<google.maps.places.AutocompletePrediction[]> => {
      setLoading(true);

      try {
        const { predictions } = await autocompleteService.getPlacePredictions({
          input,
        });

        setPredictions(predictions);

        return predictions;
      } catch (e) {
        setPredictions([]);

        return [];
      } finally {
        setLoading(false);
      }
    },
    [autocompleteService],
  );
  const debouncedUpdateSearchQuery = useDebounce(getPlaces$, 500);
  const handleAutocompleteFocus = useCallback(async () => {
    await getPlaces$(formInput);
  }, [formInput, getPlaces$]);
  const handleInputChange: TextFieldProps['onChange'] = useCallback(
    (event) => {
      const { value } = event.target;

      if (value) {
        const input = formInput ? `${formInput}, ${value}` : value;

        debouncedUpdateSearchQuery(input);
      }
    },
    [debouncedUpdateSearchQuery, formInput],
  );
  const handleReferenceFieldsChange = useCallback(
    async (input: string) => {
      setPrediction(DEFAULT_ASYNC_AUTOCOMPLETE_VALUE);
      const predictions = await getPlaces$(input);

      setPlacePosition(predictions[0]?.place_id);
    },
    [getPlaces$, setPlacePosition, setPrediction], // TODO: improve deps instead of workaround on debounce
  );
  const debouncedReferenceFieldsChange = useDebounce(
    handleReferenceFieldsChange,
    500,
    undefined,
    false,
  );

  useEffect(() => {
    if (!isInitialized) {
      setInitialized(true);

      return;
    }

    debouncedReferenceFieldsChange(formInput);
    // avoid setting a position during the first render based on the formInput
    // during the first render we need to set position based on the lat lng
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedReferenceFieldsChange, formInput]);

  if (!placeholderKey) {
    return null;
  }

  return (
    <Autocomplete<google.maps.places.AutocompletePrediction>
      disabled={disabled}
      getOptionLabel={(option) => option.description}
      isOptionEqualToValue={(option, selectedOption) =>
        selectedOption !== DEFAULT_ASYNC_AUTOCOMPLETE_VALUE &&
        option?.place_id === selectedOption.place_id
      }
      loading={isLoading}
      onFocus={handleAutocompleteFocus}
      onChange={handleAutocompleteChange}
      options={predictions}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: <SearchIcon height={16} width={16} />,
            sx: sxProps.input,
          }}
          disabled={disabled}
          required={required}
          error={Boolean(errorMessageKey)}
          helperText={errorMessageKey ? t(errorMessageKey) : undefined}
          onChange={handleInputChange}
          placeholder={t(placeholderKey)}
        />
      )}
      renderOption={(props, prediction) => (
        <li {...props} key={prediction.place_id}>
          {prediction.description}
        </li>
      )}
      sx={sxProps.field}
      value={prediction}
    />
  );
};
