import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  content: {
    mt: 2,
  },
  disabled: {
    color: (theme) => theme.palette.grey[600],
  },
  icon: {
    height: 32,
    width: 32,
  },
  step: {
    '&:first-child > *': {
      borderBottomLeftRadius: 16,
      borderTopLeftRadius: 16,
    },
    '&:last-child > *': {
      borderBottomRightRadius: 16,
      borderTopRightRadius: 16,
    },
  },
  stepContent: {
    backgroundColor: (theme) => theme.palette.background.paper,
    height: '100%',
    outline: (theme) => `1px solid ${theme.palette.grey[800]}`,
    p: 2.5,
  },
  stepContentDisabled: {
    backgroundColor: (theme) => theme.palette.grey[300],
  },
  stepper: {
    display: 'flex',
  },
};
