import type { FC } from 'react';
import React, { useCallback } from 'react';
import { generatePath, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import type { UniversalTicketSerializerDTO } from '../../../../../connectors/ticket';
import { DeleteButton } from '../../../../shared';
import { ticketClient } from '../../../index';
import PATHS from '../../../paths.const';

type Props = {
  id: UniversalTicketSerializerDTO['uuid'];
};

export const Delete: FC<Props> = (props) => {
  const { id } = props;
  const { type } = useParams();
  const navigate = useNavigate();
  const handleDelete$ = useCallback(
    async (id) => {
      // eslint-disable-next-line no-useless-catch
      try {
        await ticketClient.delete$(id);
        navigate(generatePath(PATHS.LIST, { type }));
      } catch (e) {
        throw e;
      }
    },
    [navigate, type],
  );

  return (
    <DeleteButton
      dataTestId="delete-ticket"
      callback$={() => handleDelete$(id)}
    />
  );
};
