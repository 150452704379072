import { uniq } from 'lodash-es';

import { Permission } from '../../../+auth/shared/enums/permission.enum';

const VIEW_COMMON_PERMISSIONS = [Permission.GLOBAL_READ, Permission.READ_USER];

export const WRITE_PERMISSIONS = [
  Permission.GLOBAL_WRITE,
  Permission.WRITE_USER,
];
export const VIEW_TENANTS_PERMISSIONS = [
  ...VIEW_COMMON_PERMISSIONS,
  Permission.VIEW_TENANTS,
];
export const VIEW_EMPLOYEE_PERMISSIONS = [
  ...VIEW_COMMON_PERMISSIONS,
  Permission.VIEW_EMPLOYEES,
];
export const VIEW_EXTERNAL_EMPLOYEES_PERMISSIONS = [
  ...VIEW_COMMON_PERMISSIONS,
  Permission.VIEW_EXTERNAL_EMPLOYEES,
];
export const VIEW_LEASE_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.CAN_VIEW_LEASE,
];
export const VIEW_PERMISSIONS = uniq([
  ...VIEW_TENANTS_PERMISSIONS,
  ...VIEW_EMPLOYEE_PERMISSIONS,
  ...VIEW_EXTERNAL_EMPLOYEES_PERMISSIONS,
]);
