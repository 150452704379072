import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC, ReactNode } from 'react';

import { THEMES } from '../theme';
import { initialSettings } from './const';
import { Context } from './context';
import type { Settings } from './type';

interface SettingsProviderProps {
  children?: ReactNode;
}

export const restoreSettings = (): Settings | null => {
  let settings: Settings | null = null;

  try {
    const storedData: string | null = window.localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        sidebarState: true,
        theme: THEMES.LIGHT,
        // TODO: make dark mode great again!
        // theme: window.matchMedia('(prefers-color-scheme: dark)').matches
        //   ? THEMES.DARK
        //   : THEMES.LIGHT,
      };
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  return settings;
};

export const storeSettings = (settings: Settings): void => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

export const Provider: FC<SettingsProviderProps> = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState<Settings>(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setSettings(restoredSettings);
    }
  }, []);

  const saveSettings = useCallback(
    (updatedSettings: Partial<Settings>): void => {
      const newSettings = { ...settings, ...updatedSettings };

      setSettings(newSettings);
      storeSettings(newSettings);
    },
    [settings],
  );
  const contextValue = useMemo(
    () => ({
      saveSettings,
      settings,
    }),
    [saveSettings, settings],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

Provider.displayName = 'Settings Provider';
