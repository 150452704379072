import Grid from '@mui/material/Grid';
import { Field } from 'formik';
import type { FieldProps } from 'formik';
import React from 'react';
import type { FC } from 'react';

import {
  AdvancedRadioField,
  AsyncAutocompleteField,
  CheckboxField,
  DateField,
  EditorField,
  FileField,
  GalleryField,
  InputField,
  LocationField,
  MultipleSelectField,
  RadioField,
  SelectField,
  SwitchField,
  TimeField,
} from '../fields';
import type { FieldConfig } from '../types';

type Props<TFormValues> = {
  config: FieldConfig<TFormValues>;
  values: TFormValues;
  isInline?: boolean;
};

export const FormField = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<FieldConfig<TFormValues> | null>> => {
  const { config, isInline, values } = props;

  if (config.props.isHidden?.(values)) return null;

  return (
    <Grid {...(!isInline && (config.props.size || { xs: 12 }))} item>
      <Field name={config.field.name}>
        {(fieldProps: FieldProps) => {
          // !!! 'switch' necessary to narrow down types
          switch (config.componentType) {
            case 'async-autocomplete': {
              const { props } = config;

              return (
                <AsyncAutocompleteField<TFormValues>
                  {...fieldProps}
                  props={props}
                />
              );
            }
            case 'advanced-radio': {
              const { props } = config;

              return (
                <AdvancedRadioField<TFormValues>
                  {...fieldProps}
                  props={props}
                />
              );
            }
            case 'checkbox': {
              const { props } = config;

              return (
                <CheckboxField<TFormValues> {...fieldProps} props={props} />
              );
            }
            case 'date': {
              const { props } = config;

              return <DateField<TFormValues> {...fieldProps} props={props} />;
            }
            case 'editor': {
              const {
                field: { name },
                props,
              } = config;

              return <EditorField configProps={props} name={name} />;
            }
            case 'file': {
              const { props } = config;

              return <FileField<TFormValues> {...fieldProps} props={props} />;
            }
            case 'gallery': {
              const { props } = config;

              return (
                <GalleryField<TFormValues> {...fieldProps} props={props} />
              );
            }
            case 'input': {
              const { props } = config;

              return <InputField<TFormValues> {...fieldProps} props={props} />;
            }
            case 'location': {
              const { props } = config;

              return (
                <LocationField<TFormValues> {...fieldProps} props={props} />
              );
            }
            case 'multiple-select': {
              const { props } = config;

              return (
                <MultipleSelectField<TFormValues>
                  {...fieldProps}
                  props={props}
                />
              );
            }
            case 'radio': {
              const { props } = config;

              return <RadioField<TFormValues> {...fieldProps} props={props} />;
            }
            case 'select': {
              const { props } = config;

              return <SelectField<TFormValues> {...fieldProps} props={props} />;
            }
            case 'switch': {
              const { props } = config;

              return <SwitchField<TFormValues> {...fieldProps} props={props} />;
            }
            case 'time': {
              const { props } = config;

              return <TimeField<TFormValues> {...fieldProps} props={props} />;
            }
            default:
              return null;
          }
        }}
      </Field>
    </Grid>
  );
};
