import { ReactComponent as RealEstateDealDocumentIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/deals/real-estate-deal-document.svg';
import { ReactComponent as RealEstateBuildingHouseIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/real-estate/real-estate-building-house.svg';
import { ReactComponent as TicketIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/tickets/ticket.svg';
import type { FC, SVGProps } from 'react';
import { generatePath } from 'react-router';

import PATHS from '../../../+prospect/paths.const';
import {
  getTypePathBasedOnTicketType,
  TICKET_PATHS,
  ticketClient,
} from '../../../+ticket';
import type { NotificationSerializerDTO } from '../../../../connectors/notification';
import { EntityEnumDTO } from '../../../../connectors/notification';

export const getIcon = (
  entityType: EntityEnumDTO | undefined,
): FC<SVGProps<SVGSVGElement>> => {
  switch (entityType) {
    case EntityEnumDTO.Ticket: {
      return TicketIcon;
    }
    case EntityEnumDTO.Prospect: {
      return RealEstateDealDocumentIcon;
    }
    default: {
      return RealEstateBuildingHouseIcon;
    }
  }
};

export const getLink$ = async (
  entityType: EntityEnumDTO | undefined,
  id: NotificationSerializerDTO['entityUuid'],
): Promise<GenericTypes.URL | null> => {
  if (!id) return null;

  switch (entityType) {
    case EntityEnumDTO.Ticket: {
      const ticketType = await ticketClient.getTicketTypeByUuid$(id);

      if (!ticketType) return null;

      const type = getTypePathBasedOnTicketType(ticketType);

      return generatePath(TICKET_PATHS.DETAILS, { id, type });
    }
    case EntityEnumDTO.Prospect: {
      return generatePath(PATHS.DETAILS, { id });
    }
    default:
      return null;
  }
};
