/* eslint-disable max-len */
import {
  ReasonAwaitingExternalProviderEnumDTO,
  ReasonAwaitingTenantEnumDTO,
  ReasonOnHoldEnumDTO,
  SolutionDoneEnumDTO,
  TicketStatusEnumDTO,
} from '../../../../../connectors/ticket';
import type { FieldConfig, FormProps } from '../../../../shared';
import { getSelectOptions, getSolutionLabelKey } from './helper';

export type Values = {
  comment: string;
  isInternalComment: boolean;
  solution:
    | ReasonAwaitingExternalProviderEnumDTO
    | ReasonAwaitingTenantEnumDTO
    | ReasonOnHoldEnumDTO
    | SolutionDoneEnumDTO;
  confirmed?: string;
  resumptionDate?: string;
};

export const transitionAwaitingTenantFieldsConfig: FieldConfig<Values>[] = [
  {
    componentType: 'select',
    field: {
      initialValue: ReasonAwaitingTenantEnumDTO.AwaitingTenantReply,
      name: 'solution',
    },
    props: {
      labelKey: getSolutionLabelKey(TicketStatusEnumDTO.AwaitingTenant),
      options: getSelectOptions(TicketStatusEnumDTO.AwaitingTenant),
    },
  },
  {
    componentType: 'date',
    field: {
      initialValue: new Date().toISOString(),
      name: 'resumptionDate',
    },
    props: {
      labelKey: 'ticket.fields.resumptionDate',
    },
  },
  {
    componentType: 'input',
    field: {
      initialValue: '',
      name: 'comment',
    },
    props: {
      labelKey: 'ticket.fields.comment',
      type: 'textarea',
    },
  },
  {
    componentType: 'switch',
    field: {
      initialValue: false,
      name: 'isInternalComment',
    },
    props: {
      fieldLabelKey: 'ticket.fields.commentVisibility.fieldLabel',
      helperTextKey: 'ticket.fields.commentVisibility.helperText',
      labelKey: 'ticket.fields.commentVisibility.label',
    },
  },
];

export const transitionAwaitingExternalProviderFieldsConfig: FieldConfig<Values>[] =
  [
    {
      componentType: 'select',
      field: {
        initialValue:
          ReasonAwaitingExternalProviderEnumDTO.LackOfInternalResource,
        name: 'solution',
      },
      props: {
        labelKey: getSolutionLabelKey(
          TicketStatusEnumDTO.AwaitingExternalProvider,
        ),
        options: getSelectOptions(TicketStatusEnumDTO.AwaitingExternalProvider),
      },
    },
    {
      componentType: 'date',
      field: {
        initialValue: new Date().toISOString(),
        name: 'resumptionDate',
      },
      props: {
        labelKey: 'ticket.fields.resumptionDate',
      },
    },
    {
      componentType: 'input',
      field: {
        initialValue: '',
        name: 'comment',
      },
      props: {
        labelKey: 'ticket.fields.comment',
        type: 'textarea',
      },
    },
    {
      componentType: 'switch',
      field: {
        initialValue: false,
        name: 'isInternalComment',
      },
      props: {
        fieldLabelKey: 'ticket.fields.commentVisibility.fieldLabel',
        helperTextKey: 'ticket.fields.commentVisibility.helperText',
        labelKey: 'ticket.fields.commentVisibility.label',
      },
    },
  ];

export const transitionDoneFieldsConfig: FieldConfig<Values>[] = [
  {
    componentType: 'select',
    field: {
      initialValue: SolutionDoneEnumDTO.DoneByHeimstaden,
      name: 'solution',
    },
    props: {
      labelKey: getSolutionLabelKey(TicketStatusEnumDTO.Done),
      options: getSelectOptions(TicketStatusEnumDTO.Done),
    },
  },
  {
    componentType: 'input',
    field: {
      initialValue: '',
      name: 'comment',
    },
    props: {
      labelKey: 'ticket.fields.comment',
      type: 'textarea',
    },
  },
  {
    componentType: 'switch',
    field: {
      initialValue: false,
      name: 'isInternalComment',
    },
    props: {
      fieldLabelKey: 'ticket.fields.commentVisibility.fieldLabel',
      helperTextKey: 'ticket.fields.commentVisibility.helperText',
      labelKey: 'ticket.fields.commentVisibility.label',
    },
  },
  {
    componentType: 'checkbox',
    field: {
      initialValue: 'yes',
      name: 'confirmed',
    },
    props: {
      options: [
        {
          labelKey: 'ticket.fields.statusTransitionDoneConfirmed',
          name: 'yes',
        },
      ],
    },
  },
];

export const transitionOnHoldFieldsConfig: FieldConfig<Values>[] = [
  {
    componentType: 'date',
    field: {
      initialValue: new Date().toISOString(),
      name: 'resumptionDate',
    },
    props: {
      labelKey: 'ticket.fields.resumptionDate',
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: ReasonOnHoldEnumDTO.AwaitingHelpFromInternalResource,
      name: 'solution',
    },
    props: {
      labelKey: getSolutionLabelKey(TicketStatusEnumDTO.OnHold),
      options: getSelectOptions(TicketStatusEnumDTO.OnHold),
    },
  },
  {
    componentType: 'input',
    field: {
      initialValue: '',
      name: 'comment',
    },
    props: {
      labelKey: 'ticket.fields.comment',
      type: 'textarea',
    },
  },
  {
    componentType: 'switch',
    field: {
      initialValue: false,
      name: 'isInternalComment',
    },
    props: {
      fieldLabelKey: 'ticket.fields.commentVisibility.fieldLabel',
      helperTextKey: 'ticket.fields.commentVisibility.helperText',
      labelKey: 'ticket.fields.commentVisibility.label',
    },
  },
  {
    componentType: 'checkbox',
    field: {
      initialValue: 'yes',
      name: 'confirmed',
    },
    props: {
      options: [
        {
          labelKey: 'ticket.fields.statusTransitionOnHoldConfirmed',
          name: 'yes',
        },
      ],
    },
  },
];

export const getFieldsConfig = (
  transition?: TicketStatusEnumDTO,
): FieldConfig<Values>[] | null => {
  switch (transition) {
    case TicketStatusEnumDTO.AwaitingTenant:
      return transitionAwaitingTenantFieldsConfig;
    case TicketStatusEnumDTO.AwaitingExternalProvider:
      return transitionAwaitingExternalProviderFieldsConfig;
    case TicketStatusEnumDTO.Done:
      return transitionDoneFieldsConfig;
    case TicketStatusEnumDTO.OnHold:
      return transitionOnHoldFieldsConfig;
    default:
      return null;
  }
};

export const formConfig: FormProps<Values>['config'] = {
  onSubmit: () => {
    // do nothing
  },
};
