import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import type { FC } from 'react';
import React, { Fragment, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { sxProps } from './actions.styles';
import type { Element } from './actions.type';

type Props = {
  elements: Element[];
};

export const Actions: FC<Props> = (props) => {
  const { elements } = props;
  const isExternalLink = useCallback(
    (element: Element) =>
      Boolean(!element.onClick && element.to?.startsWith('http')),
    [],
  );

  return (
    <Box>
      {elements.map((element) => {
        const {
          content,
          icon: Icon,
          isDisabled = false,
          key,
          onClick,
          to,
        } = element;

        if (content) return <Fragment key={key}>{content}</Fragment>;

        if (!Icon) return null;

        return isExternalLink(element) ? (
          <MuiLink
            color="secondary"
            component="a"
            href={to}
            key={`${key}-action`}
            target="_blank"
            variant="button"
          >
            <IconButton>
              <Icon height={16} width={16} />
            </IconButton>
          </MuiLink>
        ) : (
          <Button
            color="secondary"
            disabled={isDisabled}
            key={`${key}-action`}
            onClick={(event) => {
              event.stopPropagation();
              onClick?.();
            }}
            {...(!onClick && to && { component: Link, to })}
            sx={sxProps.button}
            variant="text"
          >
            <Icon height={16} width={16} />
          </Button>
        );
      })}
    </Box>
  );
};
