import { ReactComponent as InformationCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alerts/information-circle.svg';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';

import { useTranslation } from '../../../translations';
import { sxProps } from './location-tip-box.styles';

type Props = {
  textKey: GenericTypes.TranslationLabel;
};

export const LocationTipBox: FC<Props> = (props) => {
  const { textKey } = props;
  const { t } = useTranslation();

  return (
    <Box sx={sxProps.container}>
      <Box
        component={InformationCircleIcon}
        height={16}
        sx={sxProps.icon}
        width={16}
      />
      <Typography>{t(textKey)}</Typography>
    </Box>
  );
};
