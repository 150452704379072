import { styled } from '@mui/material/styles';

export const LayoutRoot = styled('div')((props) => ({
  ...(props.theme.palette.mode === 'dark'
    ? {
        backgroundColor: props.theme.palette.background.default,
      }
    : {
        backgroundAttachment: 'fixed',
        backgroundImage: 'url(/assets/page_bcg.png)',
        backgroundSize: 'cover',
      }),
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));
export const LayoutWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});
export const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});
export const LayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  maxWidth: 1920,
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});
