import { ReactComponent as RealEstateDealShakeIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/deals/real-estate-deal-shake.svg';
import MUIBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { UnitAdvertisementSerializerDTO } from '../../../../../connectors/property';
import { Box, Feature, getFormattedPrice, useAsync } from '../../../../shared';

export const RentPriceBox: FC = () => {
  const { response } = useAsync<[UnitAdvertisementSerializerDTO]>();
  const [details] = response;
  const price = useMemo(
    () => getFormattedPrice(details.rentPrice, details.rentPriceCurrency),
    [details.rentPrice, details.rentPriceCurrency],
  );

  return (
    <Box size="small">
      <MUIBox>
        <Feature
          dataTestId="rent-price"
          icon={<RealEstateDealShakeIcon />}
          size="large"
          titleKey="advertisement.feature.rentPrice"
          value={
            <Typography marginBottom={0} variant="h2">
              {price}
            </Typography>
          }
        />
      </MUIBox>
    </Box>
  );
};
