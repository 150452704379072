import {
  LeaseStatusEnumDTO,
  LeaseTypeEnumDTO,
} from '../../../../connectors/contract';
import type { SelectOption } from '../../../shared';

const statusToTranslationLabelKeyMap: Record<
  LeaseStatusEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [LeaseStatusEnumDTO.Active]: 'contract.leaseStatus.active',
  [LeaseStatusEnumDTO.Potential]: 'contract.leaseStatus.potential',
  [LeaseStatusEnumDTO.Resigned]: 'contract.leaseStatus.resigned',
  [LeaseStatusEnumDTO.Signed]: 'contract.leaseStatus.signed',
  [LeaseStatusEnumDTO.Terminated]: 'contract.leaseStatus.terminated',
};

const typeToTranslationLabelKeyMap: Record<
  LeaseTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [LeaseTypeEnumDTO.Regulated]: 'contract.leaseType.regulated',
  [LeaseTypeEnumDTO.Unregulated]: 'contract.leaseType.unregulated',
};

export const getStatusTranslationLabelKey = (
  status: LeaseStatusEnumDTO,
): GenericTypes.TranslationLabel =>
  statusToTranslationLabelKeyMap[status] || status;

export const statusOptions: SelectOption<LeaseStatusEnumDTO>[] = Object.values(
  LeaseStatusEnumDTO,
).map((status) => ({
  labelKey: getStatusTranslationLabelKey(status),
  value: status,
}));

export const getTypeTranslationLabelKey = (
  type: LeaseTypeEnumDTO,
): GenericTypes.TranslationLabel => typeToTranslationLabelKeyMap[type] || type;

export const typeOptions: SelectOption<LeaseTypeEnumDTO>[] = Object.values(
  LeaseTypeEnumDTO,
).map((type) => ({
  labelKey: getTypeTranslationLabelKey(type),
  value: type,
}));
