import { createContext } from 'react';

import type { CountrySerializerDTO } from '../../../../connectors/company';
import type { SelectOption } from '../../form';
import type { Country } from './enum';

export type ContextValue = {
  countryOptions: SelectOption<Country | CountrySerializerDTO['uuid']>[];
  isCurrentCountry: (uuid: Country) => boolean;
  userCountries: SelectOption<Country | CountrySerializerDTO['uuid']>[];
};

export const Context = createContext<ContextValue>({
  countryOptions: [],
  isCurrentCountry: () => false,
  userCountries: [],
});
