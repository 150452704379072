import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { TicketTypeEnumDTO } from '../../../../../connectors/ticket';
import { Box, CollapseText } from '../../../../shared';
import {
  isComplaint,
  isContractRequest,
  isInvoiceRequest,
  isServiceRequest,
} from '../../helpers';
import type { DetailsData } from '../../types';
import { ComplaintDetailsFeatureList } from '../ComplaintDetailsFeatureList/complaint-details-feature-list.component';
import { ContractRequestDetailsFeatureList } from '../ContractRequestDetailsFeatureList/contract-request-details-feature-list.component';
import { Images } from '../Images/images.component';
import { InvoiceRequestDetailsFeatureList } from '../InvoiceRequestDetailsFeatureList/invoice-request-details-feature-list.component';
import { ServiceRequestDetailsFeatureList } from '../ServiceRequestDetailsFeatureList/service-request-details-feature-list.component';
import { sxProps } from './info-box.styles';

type Props = {
  data: DetailsData;
};

export const InfoBox: FC<Props> = (props) => {
  const { data } = props;
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isNotGeneralRequest = useMemo(
    () => data.ticketType !== TicketTypeEnumDTO.GeneralRequest,
    [data.ticketType],
  );

  return (
    <Box>
      <Grid container spacing={3} sx={sxProps.container}>
        <Grid item xs={12} md={isNotGeneralRequest ? 7 : 12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CollapseText text={data.description} />
            </Grid>
            <Grid item xs={12}>
              <Images id={data.uuid} />
            </Grid>
          </Grid>
        </Grid>
        {isNotGeneralRequest && (
          <>
            <Grid item xs={12} md={1}>
              <Divider orientation={mdDown ? 'horizontal' : 'vertical'} />
            </Grid>
            <Grid item xs={12} md={4}>
              {isServiceRequest(data) && (
                <ServiceRequestDetailsFeatureList
                  damagedItem={data.damagedItem}
                  issueLocation={data.issueLocation}
                  issueSubtype={data.issueSubtype}
                  issueType={data.issueType}
                  roomWithIssue={data.roomWithIssue}
                  ticketType={data.ticketType}
                />
              )}
              {isComplaint(data) && (
                <ComplaintDetailsFeatureList
                  complaintType={data.complaintType}
                  ticketType={data.ticketType}
                />
              )}
              {isContractRequest(data) && (
                <ContractRequestDetailsFeatureList
                  ticketSubtype={data.ticketSubtype}
                  ticketType={data.ticketType}
                />
              )}
              {isInvoiceRequest(data) && (
                <InvoiceRequestDetailsFeatureList
                  ticketSubtype={data.ticketSubtype}
                  ticketType={data.ticketType}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
