import type { LeaseTemplatesAPICreateLeaseTemplateLeaseTemplatesPostRequest } from '../../../../../connectors/contract';
import {
  LanguageDTO,
  LeaseTemplateLanguageTypeEnumDTO,
} from '../../../../../connectors/contract';
import type {
  CustomFile,
  FieldConfig,
  FormProps,
  LocalizationConfig,
} from '../../../../shared';
import {
  DEFAULT_FIELD_VALUE,
  DEFAULT_SELECT_OPTION,
  languageOptions,
} from '../../../../shared';
import { templateTypeOptions } from '../../helpers';

export type Values =
  // eslint-disable-next-line max-len
  LeaseTemplatesAPICreateLeaseTemplateLeaseTemplatesPostRequest['createLeaseTemplateSerializerDTO'];

export type Meta =
  | Pick<Values, 'countryUuid' | 'language' | 'templateType'>
  | undefined;

export const getFieldsConfig = (
  countries: LocalizationConfig['countryOptions'],
  file?: CustomFile,
  meta?: Meta,
): FieldConfig<Values>[] => [
  {
    componentType: 'input',
    field: {
      initialValue: file?.title || file?.name || DEFAULT_FIELD_VALUE,
      name: 'title',
    },
    props: {
      labelKey: 'library.fields.title',
      required: true,
      type: 'text',
    },
  },
  {
    componentType: 'input',
    field: {
      initialValue: file?.description || DEFAULT_FIELD_VALUE,
      name: 'description',
    },
    props: {
      labelKey: 'library.fields.description',
      required: true,
      type: 'textarea',
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue:
        meta?.countryUuid || countries[0]?.value || DEFAULT_SELECT_OPTION.value,
      name: 'countryUuid',
    },
    props: {
      disabled: countries.length === 1,
      labelKey: 'library.fields.country',
      options: countries,
      required: true,
      size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: meta?.language || LanguageDTO.En,
      name: 'language',
    },
    props: {
      labelKey: 'library.fields.language',
      options: languageOptions,
      required: true,
      size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    },
  },
  {
    componentType: 'advanced-radio',
    field: {
      initialValue:
        meta?.templateType || LeaseTemplateLanguageTypeEnumDTO.Static,
      name: 'templateType',
    },
    props: {
      options: templateTypeOptions,
      required: true,
    },
  },
];

export const formConfig: FormProps<Values>['config'] = {
  onSubmit: () => {
    // do nothing
  },
};
