import { ReactComponent as RemoveIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { DialogProps } from '@mui/material/Dialog';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { CSSProperties, FC, MouseEvent, ReactNode } from 'react';
import React, { isValidElement, useCallback } from 'react';

import { useTranslation } from '../../translations';
import { sxProps } from './dialog.styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  actionButtonsPosition?: CSSProperties['justifyContent'];
  children?: ReactNode;
  confirmButtonTitleKey?: GenericTypes.TranslationLabel;
  declineButtonTitleKey?: GenericTypes.TranslationLabel;
  enableActions?: boolean;
  fullWidth?: DialogProps['fullWidth'];
  isConfirmDisabled?: boolean;
  isFetching?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  onConfirm?: () => void;
  titleComponent?: ReactNode;
  titleIcon?: ReactNode;
  titleKey?: GenericTypes.TranslationLabel;
};

export const Dialog: FC<Props> = (props) => {
  const {
    actionButtonsPosition = 'center',
    children,
    confirmButtonTitleKey = 'dialog.confirmation.buttons.agree',
    declineButtonTitleKey = 'dialog.confirmation.buttons.disagree',
    enableActions = true,
    fullWidth,
    isConfirmDisabled,
    isFetching,
    isOpen,
    maxWidth = 'lg',
    onClose,
    onConfirm,
    titleComponent,
    titleIcon,
    titleKey,
  } = props;
  const { t } = useTranslation();
  const handleClose = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onClose?.();
    },
    [onClose],
  );
  const handleConfirm = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onConfirm?.();
    },
    [onConfirm],
  );
  const dialogActionsSxProps = {
    ...sxProps.dialogActions,
    justifyContent: actionButtonsPosition,
  };

  return (
    <MuiDialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={!isFetching ? handleClose : undefined}
      open={isOpen}
    >
      <Box sx={sxProps.title}>
        {isValidElement(titleIcon) && titleIcon}
        {isValidElement(titleComponent) && titleComponent}
        {!isValidElement(titleComponent) && titleKey && (
          <Typography color="primary" variant="overline">
            {t(titleKey)}
          </Typography>
        )}
        <IconButton onClick={handleClose} sx={sxProps.closeIcon}>
          <RemoveIcon height={16} width={16} />
        </IconButton>
      </Box>
      {isValidElement(children) && (
        <DialogContent sx={sxProps.dialogContent}>{children}</DialogContent>
      )}
      {enableActions && (
        <DialogActions sx={dialogActionsSxProps}>
          <Button
            data-testid="dialog-cancel-button"
            disabled={isFetching}
            onClick={handleClose}
            size="large"
            variant="outlined"
          >
            {t(declineButtonTitleKey)}
          </Button>
          <LoadingButton
            data-testid="dialog-submit-button"
            disabled={isConfirmDisabled}
            loading={isFetching}
            onClick={handleConfirm}
            size="large"
            sx={sxProps.confirmButton}
            variant="contained"
          >
            {t(confirmButtonTitleKey)}
          </LoadingButton>
        </DialogActions>
      )}
    </MuiDialog>
  );
};
