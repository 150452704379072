import { LanguageDTO } from '../../../connectors/company';
import { Country } from '../providers';

const countryToTranslationLabelKey: Record<
  Country,
  GenericTypes.TranslationLabel
> = {
  [Country.IS]: 'country.is',
  [Country.NL]: 'country.nl',
  [Country.NO]: 'country.no',
  [Country.PL]: 'country.pl',
};

export const getCountryLabelKey = (
  country: Country,
): GenericTypes.TranslationLabel => countryToTranslationLabelKey[country];

export const languageOptions = Object.values(LanguageDTO).map((value) => ({
  label: value.toUpperCase(),
  value,
}));
