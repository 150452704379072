import type { ProspectDetailsReadOnlySerializerDTO } from '../../../../../connectors/property';
import type { DateValue, FieldConfig, TimeValue } from '../../../../shared';

export type Values = {
  dateForViewing: DateValue;
  timeForViewing: TimeValue;
};

export const getFieldsConfig = (
  prospect: ProspectDetailsReadOnlySerializerDTO,
): FieldConfig<Values>[] => [
  {
    componentType: 'date',
    field: {
      initialValue: prospect.agreedDatetimeForViewing
        ? new Date(prospect.agreedDatetimeForViewing)
        : new Date(),
      name: 'dateForViewing',
    },
    props: {
      labelKey: 'prospect.fields.viewingDate',
      minDate: new Date(),
    },
  },
  {
    componentType: 'time',
    field: {
      initialValue: prospect.agreedDatetimeForViewing
        ? new Date(prospect.agreedDatetimeForViewing)
        : new Date(),
      name: 'timeForViewing',
    },
    props: {
      labelKey: 'prospect.fields.viewingDate',
    },
  },
];
