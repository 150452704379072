import React, { useCallback, useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';

import type {
  ListLogSerializerDTO,
  LogSerializerDTO,
} from '../../../../connectors/user';
import { List as GenericList, useApi } from '../../index';
import type { ListState } from '../../index';
import { RowContent } from '../shared';

type Props = {
  getData$: () => Promise<ListLogSerializerDTO>;
  enableSearch?: boolean;
  listState?: ListState;
  setListState?: Dispatch<SetStateAction<ListState>>;
};

export const List: FC<Props> = (props) => {
  const { enableSearch = false, getData$, listState, setListState } = props;
  const { isFetching, response } = useApi<ListLogSerializerDTO>(
    { count: 0, results: [] },
    getData$,
  );
  const [clickedId, setClickedId] = useState<
    LogSerializerDTO['uuid'] | undefined
  >(undefined);
  const handleRowClick = useCallback((data: LogSerializerDTO) => {
    setClickedId((prevState) =>
      prevState === data.uuid ? undefined : data.uuid,
    );
  }, []);

  return (
    <GenericList<LogSerializerDTO>
      columnsConfig={[]}
      enableSearch={enableSearch}
      isFetching={isFetching}
      listState={listState}
      model={response.results}
      modelKey="uuid"
      onRowClick={handleRowClick}
      setListState={setListState}
    >
      {(log) => <RowContent {...log} clickedId={clickedId} />}
    </GenericList>
  );
};
