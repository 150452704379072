import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  body: {
    p: 2,
  },
  chartColumn: {
    position: 'relative',
  },
  column: {
    alignItems: 'center',
    display: 'flex',
  },
};
