import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  backgroundIcon: {
    color: (theme) => theme.palette.secondary.light,
  },
  box: {
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
    background: (theme) => theme.palette.background.default,
    border: (theme) => `2px dashed ${theme.palette.divider}`,
    cursor: 'pointer',
    height: '100%',
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  foregroundIcon: {
    '&:hover': {
      background: (theme) => theme.palette.background.paper,
    },
    background: (theme) => theme.palette.background.paper,
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: '50%',
    color: (theme) => theme.palette.secondary.light,
    left: '100%',
    position: 'absolute',
    top: 0,
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
  wrapper: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
};
