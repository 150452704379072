import React from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import {
  getLeaseStatusTranslationLabelKey,
  leaseStatusOptions,
} from '../../+contract';
import { UNIT_PATHS } from '../../+unit';
import { getCategoryPath } from '../../+unit/shared';
import type { LeaseSerializerDTO } from '../../../connectors/contract';
import { UnitCategoryEnumDTO } from '../../../connectors/property';
import type { ColumnConfig, FieldConfig } from '../../shared';
import { FormattedDate, getDisplayValue, TranslationCell } from '../../shared';
import { DEFAULT_SELECT_OPTION } from '../../shared/form/form.const';

export const columnConfigs: ColumnConfig<LeaseSerializerDTO>[] = [
  {
    getContent: ({ unit }) => (
      <Link
        to={generatePath(UNIT_PATHS.DETAILS, {
          category: getCategoryPath(UnitCategoryEnumDTO.ResidentialHomes),
          id: unit.uuid,
        })}
      >
        {unit.name}
      </Link>
    ),
    id: 'name',
    labelKey: 'contract.fields.unit',
  },
  {
    getContent: ({ status }) => {
      const props = {
        labelKey: getLeaseStatusTranslationLabelKey(status),
      };

      return <TranslationCell fontWeight={700} {...props} />;
    },
    id: 'status',
    labelKey: 'contract.fields.leaseStatus',
  },
  {
    getContent: ({ price }) => getDisplayValue(price),
    id: 'price',
    labelKey: 'contract.fields.price',
  },
  {
    getContent: ({ startDate }) => <FormattedDate date={startDate} />,
    id: 'startDate',
    labelKey: 'contract.fields.startDate',
    sortConfig: { asc: 'start_date', desc: '-start_date' },
  },
  {
    getContent: ({ endDate }) => endDate && <FormattedDate date={endDate} />,
    id: 'endDate',
    labelKey: 'contract.fields.endDate',
    sortConfig: { asc: 'end_date', desc: '-end_date' },
  },
  {
    id: 'referenceNumber',
    labelKey: 'contract.fields.referenceNumber',
    propertyKey: 'referenceNumber',
  },
];

export type FilterValues = {
  status: LeaseSerializerDTO['status'] | typeof DEFAULT_SELECT_OPTION.value;
};

export const INITIAL_FILTER_VALUES: FilterValues = {
  status: DEFAULT_SELECT_OPTION.value,
};

export const INITIAL_SORT = '-start_date';

export const filterFieldsConfig: FieldConfig<FilterValues>[] = [
  {
    componentType: 'select',
    field: {
      initialValue: INITIAL_FILTER_VALUES.status,
      name: 'status',
    },
    props: {
      isFilter: true,
      labelKey: 'contract.fields.leaseStatus',
      options: [DEFAULT_SELECT_OPTION, ...leaseStatusOptions],
    },
  },
];
