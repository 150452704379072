import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import type { FC } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from '../../translations';
import { sxProps } from './general.styles';

interface Props {
  messageKey: GenericTypes.TranslationLabel;
  backButtonLabelKey?: GenericTypes.TranslationLabel;
  to?: string;
}

export const General: FC<Props> = (props) => {
  const { backButtonLabelKey, messageKey, to } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={sxProps.wrapper}>
      <Typography mb={4} variant="h2">
        {t(messageKey)}
      </Typography>
      <Box
        alt=""
        component="img"
        src={`/assets/error404_${theme.palette.mode}.svg`}
        width={300}
      />
      {to && backButtonLabelKey && (
        <Button component={Link} variant="contained" color="primary" to={to}>
          {t(backButtonLabelKey)}
        </Button>
      )}
    </Box>
  );
};
