import { tableSortLabelClasses } from '@mui/material/TableSortLabel';
import { toggleButtonClasses } from '@mui/material/ToggleButton';
import type { Theme, ThemeOptions } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import createTheme from '@mui/material/styles/createTheme';
import merge from 'lodash-es/merge';

import { palette } from './palette';
import { lightShadows } from './shadows';
import type { ThemeConfig } from './types';

export const THEMES = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

const baseOptions: ThemeOptions = {
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          padding: 0,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          '& > svg': {
            marginRight: 8,
          },
          borderRadius: 64,
        },
        containedSizeLarge: {
          fontSize: '1rem',
          fontWeight: 700,
          lineHeight: '1.5rem',
        },
        containedSizeSmall: {
          fontSize: '0.8125rem',
          fontWeight: 700,
          lineHeight: '1.375rem',
        },
        outlined: {
          '& > svg': {
            marginRight: 8,
          },
          borderRadius: 64,
        },
        outlinedSizeLarge: {
          fontSize: '1rem',
          fontWeight: 700,
          lineHeight: '1.5rem',
        },
        outlinedSizeSmall: {
          fontSize: '0.8125rem',
          fontWeight: 700,
          lineHeight: '1.375rem',
        },
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
          minWidth: 300,
          padding: 24,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: '16px',
          minWidth: 'auto',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        adornedStart: {
          '& path': {
            stroke: 'currentColor',
          },
        },
        input: {
          '&::placeholder': {
            opacity: 0.38,
          },
        },
        root: {
          borderColor: palette.silver,
          borderRadius: 16,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          letterSpacing: '0.15px',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          // equivalent to &.Mui-active
          [`&.${tableSortLabelClasses.active}`]: {
            '& svg': {
              fill: palette.internationalOrange,
            },
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: palette.waikawaGray,
            color: palette.white,
          },
          [`&.${toggleButtonClasses.selected}`]: {
            backgroundColor: palette.waikawaGray,
            color: palette.white,
          },
          [`&.${toggleButtonClasses.selected}:hover`]: {
            backgroundColor: 'transparent',
            color: palette.black,
          },
          borderColor: alpha(palette.black, 0.38),
          color: palette.black,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          '&:before': {
            border: `1px solid ${palette.silverChalice}`,
          },
          color: palette.white,
        },
        tooltip: {
          backgroundColor: palette.white,
          border: `1px solid ${palette.silverChalice}`,
          color: palette.boulder,
          fontSize: '0.625rem',
          fontWeight: 'bold',
          lineHeight: '1.25rem',
          padding: '4px',
          textTransform: 'uppercase',
        },
      },
    },
  },
  typography: {
    body2: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.8125rem',
      fontWeight: 400,
      textTransform: 'initial',
    },
    fontFamily: 'Helvetica, Arial, sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      marginBottom: 16,
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    overline: {
      color: palette.silverChalice,
      fontSize: '0.625rem',
      fontWeight: 600,
      lineHeight: '1.25rem',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
  },
};

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.LIGHT]: {
    components: {
      MuiCardContent: {
        styleOverrides: {
          root: {
            background: palette.alabaster,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              color: palette.mineShaft,
              opacity: 1,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: palette.mineShaft,
          },
        },
      },
    },
    palette: {
      action: {
        active: palette.staleGray,
      },
      background: {
        default: palette.athensGray,
        paper: palette.white,
      },
      error: {
        contrastText: palette.white,
        main: palette.pomegranate,
      },
      grey: {
        50: palette.alabaster,
        300: palette.alto,
        500: palette.silverChalice,
        700: palette.doveGray,
        800: palette.boulder,
      },
      mode: 'light',
      primary: {
        contrastText: palette.white,
        dark: palette.grenadier,
        main: palette.internationalOrange,
      },
      secondary: {
        light: palette.waikawaGray,
        main: palette.fiord44,
      },
      success: {
        contrastText: palette.white,
        main: palette.fruitSalad,
      },
      text: {
        primary: palette.mineShaft,
        secondary: palette.fiord44,
      },
      warning: {
        contrastText: palette.white,
        main: palette.orangePeel,
      },
    },
    shadows: lightShadows,
    typography: {
      allVariants: {
        color: palette.mineShaft,
      },
    },
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: palette.mirage,
        paper: palette.ebonyClay,
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: palette.white,
        main: palette.pomegranate,
      },
      grey: {
        50: palette.alabaster,
        300: palette.alto,
        500: palette.silverChalice,
      },
      info: {
        contrastText: palette.white,
        main: palette.blueRibbon,
      },
      mode: 'dark',
      primary: {
        contrastText: palette.white,
        dark: palette.grenadier,
        main: palette.internationalOrange,
      },
      secondary: {
        main: palette.white,
      },
      success: {
        contrastText: palette.white,
        main: palette.fruitSalad,
      },
      text: {
        primary: palette.white,
        secondary: palette.regentGray91,
      },
      warning: {
        contrastText: palette.white,
        main: palette.orangePeel,
      },
    },
    typography: {
      allVariants: {
        color: palette.white,
      },
    },
  },
};

export const createCustomTheme = (config: ThemeConfig): Theme => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    // eslint-disable-next-line no-console
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  return createTheme(merge({}, baseOptions, themeOptions));
};
