import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { RangeSelection } from 'lexical';
import { $getRoot, $getSelection } from 'lexical';
import { useEffect } from 'react';
import type { FC } from 'react';

import type { EditorValue } from '../../../../fields';

type Props = {
  value?: EditorValue;
};

export const InitialValueEffect: FC<Props> = (props) => {
  const { value } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!value) return;

    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(value, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);

      $getRoot().select();

      const selection = $getSelection() as RangeSelection | null;

      selection?.insertNodes(nodes);
    });
    // has to be triggered only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return null;
};
