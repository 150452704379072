import * as Yup from 'yup';

import type { AttachmentSerializerDTO } from '../../../../../../connectors/company';
import { EntityEnumDTO } from '../../../../../../connectors/property';
import {
  DEFAULT_FIELD_VALUE,
  DEFAULT_GALLERY_VALUE,
} from '../../../../../shared';
import type { GalleryItem, FieldsetConfig } from '../../../../../shared';
import type { DynamicValidationRules, FormValues } from '../form.types';

export const DEFAULT_MAX_SELECTED_ITEMS = 100;

export const getFieldsetConfig = (
  getItems$?: () => Promise<
    GalleryItem<AttachmentSerializerDTO, EntityEnumDTO>[]
  >,
  dynamicValidationRules?: DynamicValidationRules,
): FieldsetConfig<FormValues>[] => [
  {
    fields: [
      {
        componentType: 'gallery',
        field: {
          initialValue: DEFAULT_GALLERY_VALUE,
          name: 'attachments',
          validationSchema: Yup.array().test('range', (value, context) => {
            const { createError, path } = context;
            const maxSelectedItems =
              dynamicValidationRules?.attachments?.max ??
              DEFAULT_MAX_SELECTED_ITEMS;
            const minSelectedItems =
              dynamicValidationRules?.attachments?.min ?? 0;
            const selectedItems =
              value?.filter((item) => item.isSelected) || [];

            if (selectedItems.length < minSelectedItems) {
              return createError({ message: 'form.errors.required', path });
            }

            if (selectedItems.length > maxSelectedItems) {
              return createError({ message: 'form.errors.gallery.max', path });
            }

            return true;
          }),
        },
        props: {
          callback$: getItems$,
          helperTextKey: 'advertisement.fieldset.gallery.helperText',
          labelKey: 'advertisement.fieldset.gallery.title',
          required: true,
          tabs: [
            {
              labelKey: 'advertisement.fieldset.gallery.browser.unit',
              value: EntityEnumDTO.Unit,
            },
            {
              labelKey: 'advertisement.fieldset.gallery.browser.propertyObject',
              value: EntityEnumDTO.PropertyObject,
            },
            {
              labelKey: 'advertisement.fieldset.gallery.browser.property',
              value: EntityEnumDTO.Property,
            },
          ],
        },
      },
    ],
    key: 'gallery',
    titleKey: '',
  },
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: DEFAULT_FIELD_VALUE,
          name: 'virtualTourUrl',
          validationSchema: Yup.string().url('form.errors.url'),
        },
        props: {
          labelKey: 'advertisement.fields.virtualTourUrl',
          type: 'text',
        },
      },
    ],
    helperTextKey: 'advertisement.fieldset.virtualTour.helperText',
    key: 'virtualTour',
    titleKey: 'advertisement.fieldset.virtualTour.title',
  },
];
