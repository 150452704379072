import Box from '@mui/material/Box';
import type { FC, SVGProps } from 'react';
import React from 'react';

import { sxProps } from './suggestion-icon.styles';

type Props = {
  icon: FC<SVGProps<SVGSVGElement>>;
  width?: number;
  height?: number;
};

export const SuggestionIcon: FC<Props> = (props) => {
  const { icon: Icon, width = 16, height = 26 } = props;

  return (
    <Box
      sx={{ minWidth: width, ...sxProps.container }}
      height={height}
      width={width}
    >
      <Icon />
    </Box>
  );
};
