import { formControlLabelClasses } from '@mui/material/FormControlLabel';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  checkboxes: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  formControl: {
    [`&.${formControlLabelClasses.disabled}`]: {
      opacity: 0.26,
    },
    mb: 2,
  },
};
