import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { generatePath, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import {
  getUserRoleTranslationLabelKey,
  USER_PATHS,
  UserList,
} from '../../../../+user';
import { TicketTypeEnumDTO } from '../../../../../connectors/ticket';
import type { UserSerializerDTO } from '../../../../../connectors/user';
import { UserRoleEnumDTO } from '../../../../../connectors/user';
import type { AsyncAutocompleteOption } from '../../../../shared';
import { getUserName } from '../../../../shared';
import PATHS from '../../../paths.const';
import type { DetailsData, PatchRequests } from '../../types';

type Props = {
  callback$: (data: PatchRequests) => Promise<void>;
  data: DetailsData;
  isReassignDialog: boolean;
};

export const AssigneeSwitcher: FC<Props> = (props) => {
  const { callback$, data, isReassignDialog } = props;
  const { type } = useParams();
  const navigate = useNavigate();
  const listPath = useMemo(() => generatePath(PATHS.LIST, { type }), [type]);
  const handleAssignee$ = useCallback(
    (option: NonNullable<AsyncAutocompleteOption<UserSerializerDTO['uuid']>>) =>
      callback$({ assigneeUuid: option.value }),
    [callback$],
  );
  const handleRemove$ = useCallback(async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      await callback$({
        // TODO: consult with backend if marking as optional string or null is possible on their side
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        assigneeUuid: null,
      });

      if (isReassignDialog) {
        navigate(listPath);
      }
    } catch (e) {
      throw e;
    }
  }, [callback$, isReassignDialog, listPath, navigate]);
  const handleReassign$ = useCallback(
    async (newUserId: UserSerializerDTO['uuid']) => {
      // eslint-disable-next-line no-useless-catch
      try {
        await callback$({ assigneeUuid: newUserId });

        if (isReassignDialog) {
          navigate(listPath);
        }
      } catch (e) {
        throw e;
      }
    },
    [callback$, isReassignDialog, listPath, navigate],
  );
  const shouldExcludeJanitor = useMemo(
    () =>
      [
        TicketTypeEnumDTO.ContractRequest,
        TicketTypeEnumDTO.InvoiceRequest,
      ].some((type) => data.ticketType === type),
    [data],
  );
  const users = useMemo(
    () =>
      data.assignee
        ? [
            {
              avatarSrc: data.assignee.profilePicture,
              id: data.assignee.uuid,
              name: getUserName(data.assignee),
              path: USER_PATHS.DETAILS,
              position: getUserRoleTranslationLabelKey(data.assignee.roles),
            },
          ]
        : [],
    [data.assignee],
  );

  return (
    <UserList
      country={data.countryUuid}
      users={users}
      {...(!data.assignee && {
        assigningLabelKey: 'user.search.user',
        onAssignee$: handleAssignee$,
      })}
      {...(data.assignee && {
        onReassign$: handleReassign$,
        onRemove$: handleRemove$,
      })}
      {...(shouldExcludeJanitor && {
        excludedRoles: [UserRoleEnumDTO.Janitor],
      })}
      {...(isReassignDialog && {
        reassignConfirmationDescriptionKey: 'ticket.reassignConfirmation',
        removeConfirmationDescriptionKey: 'ticket.removeAssigneeConfirmation',
      })}
    />
  );
};
