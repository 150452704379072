import { ReactComponent as CheckDouble1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/check-double-1.svg';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import type { Dispatch, SetStateAction } from 'react';
import React, { forwardRef, useCallback } from 'react';
import { toast } from 'react-hot-toast';

import type { NotificationSerializerDTO } from '../../../../connectors/notification';
import { NotificationStatusDTO } from '../../../../connectors/notification';
import { useTranslation } from '../../translations';
import { notificationClient } from '../NotificationBell/notification.client';
import { sxProps } from './notifications-header.styles';

type Props = {
  areNotificationsSeen: boolean;
  isDisabled: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setNotifications: Dispatch<SetStateAction<NotificationSerializerDTO[]>>;
};

export const NotificationsHeader = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { areNotificationsSeen, isDisabled, setDisabled, setNotifications } =
      props;
    const { t } = useTranslation();

    const handleClick = useCallback(async () => {
      try {
        setDisabled(true);
        const response = await notificationClient.markAllAsSeen$();

        if (response) {
          setNotifications((prevState) =>
            prevState.map((notification) => ({
              ...notification,
              status: NotificationStatusDTO.Seen,
            })),
          );
        }
      } catch (e) {
        toast.error(t('errors.general.message'));
      } finally {
        setDisabled(false);
      }
    }, [setDisabled, setNotifications, t]);

    return (
      <Box ref={ref} sx={sxProps.headerMenu}>
        <Typography mb={0} variant="h2">
          {t('notification.label')}
        </Typography>
        <Button
          {...(areNotificationsSeen && { sx: sxProps.notificationButton })}
          color="secondary"
          disabled={areNotificationsSeen && isDisabled}
          onClick={handleClick}
          variant="text"
        >
          <CheckDouble1Icon height={16} width={16} />
          <Typography component="span" ml={1}>
            {t('notification.markAsSeenButton')}
          </Typography>
        </Button>
      </Box>
    );
  },
);
