import type { Account } from '../../../../+account';
import { typeOptions } from '../../../../+contract/shared';
import { userClient } from '../../../../+user';
import type { CountrySerializerDTO } from '../../../../../connectors/company';
import { TicketTypeEnumDTO } from '../../../../../connectors/ticket';
import type { UserSerializerDTO } from '../../../../../connectors/user';
import { UserTypeEnumDTO } from '../../../../../connectors/user';
import type { FieldConfig } from '../../../../shared';
import {
  DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  DEFAULT_MULTIPLE_SELECT_VALUE,
  DEFAULT_SELECT_OPTION,
} from '../../../../shared/form/form.const';
import {
  getDefaultAssigneeOptions,
  INITIAL_REQUEST_SORT,
  REQUEST_SORT_OPTIONS,
} from '../../consts';
import { ActorRole } from '../../enums';
import {
  actorRoleOptions,
  departmentTypeOptions,
  priorityTypeOptions,
} from '../../helpers';
import type { TicketConfig } from '../../providers';
import {
  isIssueTypeFieldDisabled,
  isRoomWithIssueFieldDisabled,
} from '../../providers';
import type { FilterValues } from './service-request-filter-panel.type';

export const INITIAL_VALUES: FilterValues = {
  actorRole: ActorRole.REPORTER,
  assignee: DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  department: DEFAULT_SELECT_OPTION.value,
  issueLocation: DEFAULT_SELECT_OPTION.value,
  issueType: DEFAULT_SELECT_OPTION.value,
  priority: DEFAULT_SELECT_OPTION.value,
  roomWithIssue: DEFAULT_SELECT_OPTION.value,
  sort: INITIAL_REQUEST_SORT,
  status: DEFAULT_MULTIPLE_SELECT_VALUE,
  ticketType: TicketTypeEnumDTO.ServiceRequest,
};

export const getFields = (
  country: CountrySerializerDTO['uuid'],
  config: TicketConfig,
  user: Account | null,
  filters?: FilterValues,
  specifiedUser?: Pick<UserSerializerDTO, 'userType' | 'uuid'>,
): FieldConfig<FilterValues>[] => [
  {
    componentType: 'select',
    field: {
      initialValue: INITIAL_VALUES.actorRole,
      name: 'actorRole',
    },
    props: {
      isFilter: true,
      isHidden: () =>
        Boolean(
          !specifiedUser || specifiedUser.userType === UserTypeEnumDTO.Customer,
        ),
      labelKey: 'ticket.fields.role',
      options: actorRoleOptions,
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.sort || INITIAL_VALUES.sort,
      name: 'sort',
    },
    props: {
      isFilter: true,
      labelKey: 'collection.sortByLabel',
      options: REQUEST_SORT_OPTIONS,
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.issueLocation || INITIAL_VALUES.issueLocation,
      name: 'issueLocation',
    },
    props: {
      isFilter: true,
      labelKey: 'ticket.filters.issueLocation',
      options: [DEFAULT_SELECT_OPTION, ...config.locationTypeOptions],
      relatedFields: ['issueType'],
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.roomWithIssue || INITIAL_VALUES.roomWithIssue,
      name: 'roomWithIssue',
    },
    props: {
      disableWhen: ({ issueLocation }) =>
        isRoomWithIssueFieldDisabled(issueLocation),
      isFilter: true,
      labelKey: 'ticket.filters.roomWithIssue',
      options: ({ issueLocation }) => [
        DEFAULT_SELECT_OPTION,
        ...(issueLocation ? config.getRoomTypeOptions(issueLocation) : []),
      ],
      relatedFields: ['issueType'],
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.issueType || INITIAL_VALUES.issueType,
      name: 'issueType',
    },
    props: {
      disableWhen: ({ issueLocation, roomWithIssue }) =>
        isIssueTypeFieldDisabled(issueLocation, roomWithIssue),
      isFilter: true,
      labelKey: 'ticket.filters.issueType',
      options: ({ issueLocation, roomWithIssue }) => [
        DEFAULT_SELECT_OPTION,
        ...(issueLocation
          ? config.getIssueTypeOptions(issueLocation, roomWithIssue)
          : []),
      ],
    },
  },
  {
    componentType: 'multiple-select',
    field: {
      initialValue: filters?.status || INITIAL_VALUES.status,
      name: 'status',
    },
    props: {
      isFilter: true,
      labelKey: 'ticket.filters.status',
      options: config.statusOptions,
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.priority || INITIAL_VALUES.priority,
      name: 'priority',
    },
    props: {
      isFilter: true,
      labelKey: 'ticket.filters.priority',
      options: [DEFAULT_SELECT_OPTION, ...priorityTypeOptions],
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: filters?.department || INITIAL_VALUES.department,
      name: 'department',
    },
    props: {
      isFilter: true,
      labelKey: 'ticket.filters.department',
      options: [DEFAULT_SELECT_OPTION, ...departmentTypeOptions],
    },
  },
  {
    componentType: 'async-autocomplete',
    field: {
      initialValue: filters?.assignee || INITIAL_VALUES.assignee,
      name: 'assignee',
    },
    props: {
      callback$: (query) =>
        userClient.getEmployeeSuggestions$(
          query,
          country,
          undefined,
          undefined,
          undefined,
          false,
        ),
      isFilter: true,
      isHidden: () => Boolean(specifiedUser),
      labelKey: 'ticket.filters.assignee',
      options: getDefaultAssigneeOptions(user?.uuid),
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: TicketTypeEnumDTO.ServiceRequest,
      name: 'ticketType',
    },
    props: {
      isHidden: () => true,
      options: typeOptions,
    },
  },
];
