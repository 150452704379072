import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { UserSerializerDTO } from '../../../../../connectors/user';
import { useTranslation } from '../../../../shared';
import { getUserRoleTranslationLabelKey } from '../../helpers';

type Props = Pick<UserSerializerDTO, 'roles'>;

export const Role: FC<Props> = (props) => {
  const { roles = [] } = props;
  const { t } = useTranslation();
  const firstRole = useMemo(() => roles[0], [roles]);
  const restRoles = useMemo(
    () => roles.filter((role) => role !== firstRole),
    [firstRole, roles],
  );
  const tooltipText = useMemo(
    () =>
      restRoles
        .map((role) => t(getUserRoleTranslationLabelKey(role)))
        .join(', '),
    [restRoles, t],
  );
  const content = (
    <Typography variant="body1">
      {t(getUserRoleTranslationLabelKey(firstRole))}{' '}
      {restRoles.length > 0 && `(${restRoles.length}+)`}
    </Typography>
  );

  if (roles.length === 0) return null;

  return tooltipText ? (
    <Tooltip arrow enterTouchDelay={0} placement="top" title={tooltipText}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};
