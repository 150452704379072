import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { Dispatch, FC, MouseEvent, SetStateAction } from 'react';
import React, { useCallback, useMemo } from 'react';

import type { AdvertisementIntegrationSerializerDTO } from '../../../../../connectors/company';
import type { UnitAdvertisementSerializerDTO } from '../../../../../connectors/property';
import { AdvertisementIntegrationStatusEnumDTO } from '../../../../../connectors/property';
import { CheckboxFieldLabel, useTranslation } from '../../../../shared';
import type { AdvertisementConfig } from '../../providers';
import type { SelectedIntegrationsState } from '../Unpublish/unpublish.component';
import { sxProps } from './unpublish-specified.styles';

type Props = {
  advertisementIntegrations: UnitAdvertisementSerializerDTO['integrations'];
  availableIntegrations: AdvertisementConfig['integrations'];
  itemSx: SxProps<Theme>;
  selectedIntegrations: SelectedIntegrationsState;
  selectedItemSx: SxProps<Theme>;
  setSelectedIntegrations: Dispatch<SetStateAction<SelectedIntegrationsState>>;
};

export const UnpublishSpecified: FC<Props> = (props) => {
  const {
    advertisementIntegrations,
    availableIntegrations,
    itemSx,
    selectedIntegrations,
    selectedItemSx,
    setSelectedIntegrations,
  } = props;
  const { t } = useTranslation();
  const handleBoxClick = useCallback(() => {
    if (!selectedIntegrations || Array.isArray(selectedIntegrations)) return;

    setSelectedIntegrations(undefined);
  }, [selectedIntegrations, setSelectedIntegrations]);
  const isDisabled = useCallback(
    (integrationId: AdvertisementIntegrationSerializerDTO['uuid']) => {
      const integration = advertisementIntegrations.find(
        (integration) => integration.integration.uuid === integrationId,
      );

      return (
        !integration ||
        integration.status !== AdvertisementIntegrationStatusEnumDTO.Success
      );
    },
    [advertisementIntegrations],
  );
  const handleCheckboxClick = useCallback(
    (
      event: MouseEvent<HTMLButtonElement>,
      integrationId: AdvertisementIntegrationSerializerDTO['uuid'],
    ) => {
      if (isDisabled(integrationId)) return;

      event.stopPropagation();
      setSelectedIntegrations((integrations) => {
        if (!Array.isArray(integrations)) return [integrationId];
        const filteredIntegrations = integrations.filter(
          (id) => id !== integrationId,
        );

        return filteredIntegrations.length > 0
          ? [...filteredIntegrations, integrationId]
          : undefined;
      });
    },
    [isDisabled, setSelectedIntegrations],
  );
  const isIntegrationSelected = useMemo(
    () =>
      Array.isArray(selectedIntegrations) && selectedIntegrations.length > 0,
    [selectedIntegrations],
  );

  return (
    <Box
      onClick={handleBoxClick}
      sx={
        {
          ...itemSx,
          ...sxProps.checkboxes,
          ...(isIntegrationSelected && selectedItemSx),
        } as SxProps
      }
    >
      <Typography component="p" marginBottom={2} variant="h2">
        {t('advertisement.unpublish.specifiedText')}
      </Typography>
      {availableIntegrations.map((integration) => {
        const isChecked =
          Array.isArray(selectedIntegrations) &&
          selectedIntegrations.includes(integration.uuid);

        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onClick={(event) =>
                  handleCheckboxClick(event, integration.uuid)
                }
              />
            }
            disabled={isDisabled(integration.uuid)}
            key={integration.uuid}
            label={
              <CheckboxFieldLabel
                label={integration.name}
                pictureURL={integration.logoUrl}
                subLabel={integration.url}
              />
            }
            sx={sxProps.formControl}
          />
        );
      })}
    </Box>
  );
};
