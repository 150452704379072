import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import type { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import Radio from '@mui/material/Radio';
import type { RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import type { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { FieldProps as FormikFieldProps } from 'formik';
import isFunction from 'lodash-es/isFunction';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { useTranslation } from '../../../translations';
import { FormField } from '../../components';
import { getFirstErrorKey } from '../../helpers';
import type {
  AdvancedRadioProps,
  AdvancedRadioValue,
  HtmlDataAttribute,
} from '../types';
import { sxProps } from './advanced-radio-field.styles';

type Props<TFormValues> = FormikFieldProps<AdvancedRadioValue, TFormValues> & {
  props: AdvancedRadioProps<TFormValues>;
};

export const AdvancedRadioField = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const { meta, field, form, props: fieldProps } = props;
  const { t } = useTranslation();
  const { error, touched } = meta;
  const { name, onBlur, onChange, value } = field;
  const { values } = form;
  const {
    disabled,
    disableWhen,
    options: fieldOptions,
    readonly,
    spacing = 2,
  } = fieldProps;
  const options = useMemo(
    () => (isFunction(fieldOptions) ? fieldOptions(values) : fieldOptions),
    [fieldOptions, values],
  );
  const errorMessageKey = useMemo(
    () => touched && getFirstErrorKey(error),
    [error, touched],
  );
  const isError = useMemo(() => Boolean(errorMessageKey), [errorMessageKey]);
  const isDisabled = useMemo(
    () => Boolean(disabled || readonly || (disableWhen && disableWhen(values))),
    [disableWhen, disabled, readonly, values],
  );
  const handleChange: StandardInputProps['onChange'] = (event) => {
    onChange(event);
  };
  const handleKeyDown: TextFieldProps['onKeyDown'] = useCallback((event) => {
    if (event.key === 'Enter') event.preventDefault();
  }, []);

  return (
    <FormControl
      component="fieldset"
      disabled={isDisabled}
      error={isError}
      fullWidth
    >
      <RadioGroup
        name={name}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
      >
        {options.map((option) => {
          const {
            helperTextKey,
            labelKey,
            nestedFieldsConfig,
            value: optionValue,
          } = option;
          const isActive = value === optionValue;
          const formLabelSxProps = {
            ...sxProps.formLabel,
            ...(isActive && sxProps.activeFormLabel),
          };

          return (
            <FormLabel
              key={`radio-option-${optionValue}`}
              sx={formLabelSxProps}
            >
              <Radio
                color="secondary"
                inputProps={
                  { 'data-testid': name } as HtmlDataAttribute<
                    RadioProps['inputProps']
                  >
                }
                onBlur={onBlur}
                sx={sxProps.radio}
                value={optionValue}
              />
              <Box sx={sxProps.content}>
                <Typography variant="subtitle2">{t(labelKey)}</Typography>
                <Typography
                  color="text.secondary"
                  marginTop={0.5}
                  variant="body2"
                >
                  {t(helperTextKey)}
                </Typography>
                {isActive && (
                  <Grid container spacing={spacing} sx={sxProps.nestedFields}>
                    {nestedFieldsConfig.map((config) => (
                      <FormField
                        key={`radio-option-nested-field-${config.field.name}`}
                        config={config}
                        values={values}
                      />
                    ))}
                  </Grid>
                )}
              </Box>
            </FormLabel>
          );
        })}
        {isError && (
          <FormHelperText error>{t(errorMessageKey || '')}</FormHelperText>
        )}
      </RadioGroup>
    </FormControl>
  );
};
