import { ReactComponent as RealEstateBuildingHouseIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/real-estate/real-estate-building-house.svg';
import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { AsyncContentProvider, Feature } from '../../../../../../shared';
import {
  getEntityData$ as getEntityDataHelper$,
  getEntityIcon,
  getEntityTypeOptionTranslationLabelKey,
  isUnitEntity,
  isUserEntity,
} from '../../../../helpers';
import type { ServiceRequestValues } from '../../../../types';

export const LocationFeature: FC = () => {
  const { values } = useFormikContext<ServiceRequestValues>();
  const getEntityData$ = useCallback(
    () => getEntityDataHelper$(values.entityType, values.entity?.value || ''),
    [values.entity, values.entityType],
  );
  const Icon = useMemo(
    () => getEntityIcon(values.entityType),
    [values.entityType],
  );

  return (
    <AsyncContentProvider getData$={getEntityData$}>
      {(data) => (
        <Grid container direction="row" spacing={2}>
          {isUnitEntity(data) && (
            <Grid item xs={12}>
              <Feature
                dataTestId="entity-type"
                icon={<RealEstateBuildingHouseIcon />}
                titleKey="ticket.options.entity.propertyObject"
                textWrap
                value={data.propertyObject.name}
              />
            </Grid>
          )}
          {!isUserEntity(data) && (
            <Grid item xs={12}>
              <Feature
                dataTestId="entity-type"
                icon={<Icon />}
                titleKey={getEntityTypeOptionTranslationLabelKey(
                  values.entityType,
                )}
                textWrap
                value={data.name}
              />
            </Grid>
          )}
        </Grid>
      )}
    </AsyncContentProvider>
  );
};
