import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const styles: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
};
