import type { PaginationProps } from '@mui/material/Pagination';
import MuiPagination from '@mui/material/Pagination';
import React, { useCallback, useMemo } from 'react';
import type { FC } from 'react';

import type { ListStateCommonProps } from '../collection.type';
import { sxProps } from './paggination.styles';

type Props<TFilterValues> = ListStateCommonProps<TFilterValues>;

export const Pagination = <TFilterValues,>(
  props: Props<TFilterValues>,
): ReturnType<FC<Props<TFilterValues>>> => {
  const { listState, setListState } = props;
  const count = useMemo(
    () =>
      Math.ceil(
        listState.paginationConfig.count / listState.paginationConfig.pageSize,
      ),
    [listState.paginationConfig.count, listState.paginationConfig.pageSize],
  );
  const handlePageChange: PaginationProps['onChange'] = useCallback(
    (event, value) => {
      setListState((prevState) => {
        return {
          ...prevState,
          paginationConfig: {
            ...prevState.paginationConfig,
            currentPage: value,
          },
        };
      });
    },
    [setListState],
  );

  return count > 1 ? (
    <MuiPagination
      count={count}
      page={listState.paginationConfig.currentPage}
      size="large"
      sx={sxProps.pagination}
      onChange={handlePageChange}
    />
  ) : null;
};
