import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  attachment: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 4,
    height: 72,
    width: 72,
  },
  border: {
    borderBottom: (theme) => [
      `1px solid ${theme.palette.divider}`,
      `1px solid ${theme.palette.divider}`,
      `1px solid ${theme.palette.divider}`,
      'unset',
    ],
    borderLeft: (theme) => [
      'unset',
      'unset',
      'unset',
      `1px solid ${theme.palette.divider}`,
    ],
    pb: [3, 3, 3, 'unset'],
    pl: ['unset', 'unset', 'unset', 3],
  },
  divider: {
    my: 3,
  },
  name: {
    wordBreak: 'break-word',
  },
};
