import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const THUMBNAIL_OFFSET = 8;
export const THUMBNAIL_SIZE = 48;

export const sxProps: Record<
  'mainImage' | 'more' | 'moreText' | 'name' | 'thumbnail' | 'thumbnails',
  SxProps<Theme>
> = {
  mainImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 1,
    height: 160,
    mb: 1,
  },
  more: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.grey[100],
    border: (theme) => `1px solid ${theme.palette.secondary.light}`,
    display: 'flex',
    justifyContent: 'center',
    mr: 0,
    p: 1,
  },
  moreText: {
    color: (theme) => theme.palette.grey[800],
    fontSize: (theme) => theme.typography.pxToRem(11),
  },
  name: {
    backgroundColor: (theme) => theme.palette.background.paper,
    bottom: (theme) => theme.spacing(2.5),
    p: 1,
    position: 'absolute',
    width: '80%',
  },
  thumbnail: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 1,
    cursor: 'pointer',
    flex: `0 0 ${THUMBNAIL_SIZE}px`,
    height: 48,
    mr: `${THUMBNAIL_OFFSET}px`,
  },
  thumbnails: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
};
