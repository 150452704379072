import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import type { TabsProps } from '@mui/material/Tabs';
import MuiTabs from '@mui/material/Tabs';
import React, { useCallback, useMemo, useState } from 'react';
import type { FC, ReactNode } from 'react';

import { useTranslation } from '../../translations';
import { sxProps } from './tabs.styles';

export type Tab = {
  component: ReactNode;
  labelKey: GenericTypes.TranslationLabel;
  value: TabsProps['value'];
};

type Props = {
  tabs: Tab[];
};

export const Tabs: FC<Props> = (props) => {
  const { tabs } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState(tabs[0]?.value);
  const currentTab = useMemo(
    () => tabs.find((tab) => tab.value === value),
    [tabs, value],
  );
  const handleChange: TabsProps['onChange'] = useCallback(
    (_, newValue) => setValue(newValue),
    [],
  );

  return (
    <Box>
      <Box sx={sxProps.box}>
        <MuiTabs
          indicatorColor="primary"
          onChange={handleChange}
          scrollButtons="auto"
          sx={sxProps.tabs}
          textColor="primary"
          value={value}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab
              key={`tab-${tab.value}`}
              label={t(tab.labelKey)}
              sx={sxProps.tab}
              value={tab.value}
            />
          ))}
        </MuiTabs>
      </Box>
      <Box>{currentTab && currentTab.component}</Box>
    </Box>
  );
};
