/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ContractRequestTypeEnumDTO {
    ChangeOrUpdateCustomerInformation = 'change_or_update_customer_information',
    ChangeOrUpdateContract = 'change_or_update_contract',
    CopyOfContract = 'copy_of_contract',
    ConfirmationReferenceOfTenancy = 'confirmation_reference_of_tenancy',
    ContractTermination = 'contract_termination',
    ContractRenewals = 'contract_renewals',
    ChangeApartment = 'change_apartment',
    ParkingSpace = 'parking_space',
    Storage = 'storage',
    QuestionsAboutRentIncrease = 'questions_about_rent_increase',
    RequestForRentSpecification = 'request_for_rent_specification',
    ApplicationForLandlordStatement = 'application_for_landlord_statement',
    Other = 'other'
}



