import Box from '@mui/material/Box';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { KYCPlatformEnumDTO } from '../../../../../connectors/company';
import type {
  KYCStatusSerializerDTO,
  ProspectSerializerDTO,
  ProspectVerificationSerializerDTO,
} from '../../../../../connectors/property';
import { Country } from '../../../../shared';
import { useProspectConfig } from '../../providers';
import { Certificate } from './Certificate/certificate.component';
import { StatusIndicator } from './StatusIndicator/status-indicator.component';
import { StatusStep } from './StatusStep/status-step.component';
import { WidgetFinalStatus } from './WidgetFinalStatus/widget-final-status.component';
import {
  PLATFORM_CHECK_ORDER,
  PLATFORM_STATUSES,
} from './verification-stepper.const';
import { sxProps } from './verification-stepper.styles';

type Props = {
  prospectId: ProspectSerializerDTO['uuid'];
  statuses: KYCStatusSerializerDTO[];
  integrationId?: ProspectVerificationSerializerDTO['kycIntegrationUuid'];
  isWidget?: boolean;
  verificationId?: ProspectVerificationSerializerDTO['uuid'];
};

export const VerificationStepper: FC<Props> = (props) => {
  const {
    integrationId,
    isWidget = false,
    prospectId,
    statuses,
    verificationId,
  } = props;
  const { getIntegrationsConfig } = useProspectConfig();
  const platform = useMemo(() => {
    const integrations = getIntegrationsConfig(Country.PL); // Prospects are only for Poland so far
    const integration = integrations.find(
      (integration) => integration.uuid === integrationId,
    );

    return integration?.kycPlatform || KYCPlatformEnumDTO.SimplRent;
  }, [getIntegrationsConfig, integrationId]);
  const isVerified = useMemo(
    () =>
      statuses.length > 0 &&
      statuses.every(
        (status) => status.statusValue === PLATFORM_STATUSES[platform].SUCCESS,
      ),
    [platform, statuses],
  );
  const verificationBoxSxProps = useMemo(
    () => ({ ...sxProps.wrapper, ...(isWidget && sxProps.widgetBox) }),
    [isWidget],
  );
  const finalStatuses = useMemo(() => {
    const platformCheckOrder = PLATFORM_CHECK_ORDER[platform];

    return statuses.sort((currentStatus, nextStatus) => {
      const currentIndexStatus = platformCheckOrder.indexOf(currentStatus.name);
      const nextIndexStatus = platformCheckOrder.indexOf(nextStatus.name);

      return currentIndexStatus - nextIndexStatus;
    });
  }, [platform, statuses]);

  return (
    <Box sx={verificationBoxSxProps}>
      {isVerified && isWidget && <WidgetFinalStatus />}
      {isVerified && !isWidget && (
        <Certificate prospectId={prospectId} verificationId={verificationId} />
      )}
      {!isVerified && (
        <>
          {!isWidget && <StatusIndicator status="inProgress" />}
          {finalStatuses.map((step, index, statuses) => {
            const { name, statusValue } = step;
            const nextStep = index + 1;

            return (
              <Box key={name} sx={sxProps.wrapper}>
                <StatusStep
                  isSmall={isWidget}
                  platform={platform}
                  value={statusValue}
                />
                {statuses.length !== nextStep && <Box sx={sxProps.line} />}
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};
