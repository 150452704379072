import Divider from '@mui/material/Divider';
import isEqual from 'lodash-es/isEqual';
import type { FC, Dispatch, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';

import type { EntityEnumDTO } from '../../../../connectors/company';
import type { FieldConfig } from '../../form';
import { FilterPanel } from '../FilterPanel/filter-panel.component';
import type { ListState } from '../List';
import { LocalizationFilterPanel } from '../LocalizationFilterPanel';
import { sxProps } from './filter-panel-with-localization.styles';
import type { FilterValues } from './filter-panel-with-localization.type';

type Props<T> = {
  fieldsConfig: FieldConfig<FilterValues<T>['fields']>[];
  listState: ListState<FilterValues<T>>;
  setListState: Dispatch<SetStateAction<ListState<FilterValues<T>>>>;
  isLocalizationHidden?: boolean;
  localizationEntityType?: EntityEnumDTO;
};

export const FilterPanelWithLocalization = <T,>(
  props: Props<T>,
): ReturnType<FC<Props<T>>> => {
  const {
    fieldsConfig,
    isLocalizationHidden,
    listState,
    localizationEntityType,
    setListState,
  } = props;
  const [localizationListState, setLocalizationListState] = useState({
    ...listState,
    filters: listState.filters?.localization,
  });
  const [fieldsListState, setFieldsListState] = useState({
    ...listState,
    filters: listState.filters?.fields,
  });

  useEffect(() => {
    if (!fieldsListState.filters) return;

    const values: FilterValues<T> = {
      fields: fieldsListState.filters,
      localization: localizationListState.filters,
    };

    setListState((prevState) => ({
      ...prevState,
      filters: !isEqual(values, prevState.filters) ? values : prevState.filters,
      paginationConfig: !isEqual(values, prevState.filters)
        ? { ...prevState.paginationConfig, currentPage: 1 }
        : prevState.paginationConfig,
    }));
  }, [localizationListState.filters, fieldsListState.filters, setListState]);

  return (
    <>
      {!isLocalizationHidden && (
        <>
          <LocalizationFilterPanel
            entityType={localizationEntityType}
            listState={localizationListState}
            setListState={setLocalizationListState}
          />
          <Divider sx={sxProps.divider} />
        </>
      )}
      <FilterPanel
        fieldsConfig={fieldsConfig}
        listState={fieldsListState}
        setListState={setFieldsListState}
      />
    </>
  );
};
