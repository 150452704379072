import Box from '@mui/material/Box';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FormikProps } from 'formik';
import isEqual from 'lodash-es/isEqual';
import React, { useMemo } from 'react';
import type { FC, MouseEvent } from 'react';

import { CancelButton } from '../cancel-button.component';
import { SubmitButton } from '../submit-button.component';
import { sxProps } from './form-buttonts.style';

type Props<TFormValues> = {
  formProps: FormikProps<TFormValues>;
  cancelLabelKey?: GenericTypes.TranslationLabel;
  fullWidthButton?: boolean;
  onCancel?: () => void;
  submitLabelKey?: GenericTypes.TranslationLabel;
  sx?: SxProps<Theme>;
};

export const FormButtons = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const {
    cancelLabelKey,
    formProps,
    fullWidthButton,
    onCancel,
    submitLabelKey,
    sx,
  } = props;
  const { initialValues, isSubmitting, isValid, values } = formProps;
  const isDisabled = useMemo(
    () => !isValid || isSubmitting || isEqual(initialValues, values),
    [initialValues, isSubmitting, isValid, values],
  );
  const handleCancel = (event: MouseEvent<HTMLButtonElement>): void => {
    formProps.handleReset(event);

    if (onCancel) onCancel();
  };

  return (
    <Box sx={{ ...sxProps.row, ...sx } as SxProps}>
      {onCancel && (
        <CancelButton
          disabled={formProps.isSubmitting}
          fullWidth={fullWidthButton}
          labelKey={cancelLabelKey}
          onClick={handleCancel}
        />
      )}
      <SubmitButton
        disabled={isDisabled}
        fullWidth={fullWidthButton}
        isSubmitting={formProps.isSubmitting}
        labelKey={submitLabelKey}
      />
    </Box>
  );
};
FormButtons.defaultProps = {
  cancelLabelKey: undefined,
  fullWidthButton: undefined,
  onCancel: undefined,
  submitLabelKey: undefined,
};
