import isFunction from 'lodash-es/isFunction';

import { isFieldsetConfig } from '../guards';
import type {
  AdvancedRadioFieldConfig,
  AsyncAutocompleteFieldConfig,
  FieldConfig,
  FormProps,
} from '../types';

export const isAsyncAutoCompleteFieldConfig = <TFormValues>(
  config: FieldConfig<TFormValues>,
): config is AsyncAutocompleteFieldConfig<TFormValues> =>
  config.componentType === 'async-autocomplete';

export const isAdvancedRadioFieldConfig = <TFormValues>(
  config: FieldConfig<TFormValues>,
): config is AdvancedRadioFieldConfig<TFormValues> =>
  config.componentType === 'advanced-radio';

export const prepareInitialValues = <TFormValues>(
  fieldsConfig: FormProps<TFormValues>['fieldsConfig'],
  initialValues = {} as TFormValues,
): TFormValues => {
  const values = initialValues;

  fieldsConfig.forEach((config): void => {
    if (isFieldsetConfig(config)) {
      prepareInitialValues(config.fields, values);
    } else {
      const { field } = config;

      if (isAdvancedRadioFieldConfig(config)) {
        const { options: configOptions } = config.props;
        const options = isFunction(configOptions)
          ? configOptions(values)
          : configOptions;

        options.forEach((option) => {
          prepareInitialValues(option.nestedFieldsConfig, values);
        });
      }

      if (
        isAsyncAutoCompleteFieldConfig(config) &&
        config.props.nestedFieldsConfig
      ) {
        prepareInitialValues(config.props.nestedFieldsConfig.fields, values);
      }

      values[field.name as keyof TFormValues] = field.initialValue;
    }
  });

  return values;
};
