import { generatePath } from 'react-router';

import type { UniversalTicketSerializerDTO } from '../../../../connectors/ticket';
import { TicketTypeEnumDTO } from '../../../../connectors/ticket';
import type { UserSerializerDTO } from '../../../../connectors/user';
import PATHS from '../../paths.const';

export const getTicketListPath = (
  id: UserSerializerDTO['uuid'],
  ticketType: UniversalTicketSerializerDTO['ticketType'],
): string | undefined => {
  switch (ticketType) {
    case TicketTypeEnumDTO.Complaint: {
      return generatePath(PATHS.COMPLAINT_LIST, { id });
    }
    case TicketTypeEnumDTO.ContractRequest: {
      return generatePath(PATHS.CONTRACT_REQUEST_LIST, {
        id,
      });
    }
    case TicketTypeEnumDTO.GeneralRequest: {
      return generatePath(PATHS.GENERAL_REQUEST_LIST, {
        id,
      });
    }
    case TicketTypeEnumDTO.InvoiceRequest: {
      return generatePath(PATHS.INVOICE_REQUEST_LIST, {
        id,
      });
    }
    case TicketTypeEnumDTO.ServiceRequest: {
      return generatePath(PATHS.SERVICE_REQUEST_LIST, {
        id,
      });
    }
    default: {
      return undefined;
    }
  }
};
