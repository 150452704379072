import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<'avatar', SxProps<Theme>> = {
  avatar: {
    height: 24,
    mr: 1,
    width: 24,
  },
};
