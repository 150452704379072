import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../../../../shared/theme/palette';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    m: -1,
  },
  image: {
    '&:hover': {
      borderColor: (theme) => theme.palette.primary.main,
    },
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    border: '2px solid transparent',
    borderRadius: 4,
    cursor: 'pointer',
    height: 70,
    m: 0.5,
    position: 'relative',
    width: 70,
  },
  internalOnlyBadge: {
    alignItems: 'center',
    backgroundColor: palette.selectiveYellow,
    border: '2px solid #fff',
    borderRadius: '50%',
    display: 'flex',
    height: '28px',
    justifyContent: 'center',
    position: 'absolute',
    right: '-10px',
    top: '-10px',
    width: '28px',
  },
};
