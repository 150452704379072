import TableBody from '@mui/material/TableBody';
import type { Dispatch, FC, ReactElement, SetStateAction } from 'react';
import React, { useMemo } from 'react';

import { EmptyRow } from '../EmptyRow/empty-row.component';
import { FetchingRow } from '../FetchingRow/fetching-row.component';
import { SimpleRow } from '../SimpleRow/simple-row.component';
import type { ColumnConfig } from '../list.type';

type Props<T> = {
  columnsConfig: ColumnConfig<T>[];
  isFetching: boolean;
  isRowsDisabled: boolean;
  isSelectingRowsEnabled: boolean;
  model: T[];
  modelKey: keyof T;
  selectedRows: T[];
  setSelectedRows: Dispatch<SetStateAction<T[]>>;
  children?: (item: T) => ReactElement;
  isHighlighted?: (item: T) => boolean;
  onRowClick?: (item: T) => void;
};

export const Body = <T,>(props: Props<T>): ReturnType<FC<Props<T>>> => {
  const {
    children,
    columnsConfig,
    isHighlighted,
    isFetching,
    isRowsDisabled,
    isSelectingRowsEnabled,
    model,
    modelKey,
    onRowClick,
    selectedRows,
    setSelectedRows,
  } = props;
  const colSpan = useMemo(
    () =>
      isSelectingRowsEnabled ? columnsConfig.length + 1 : columnsConfig.length,
    [columnsConfig.length, isSelectingRowsEnabled],
  );

  return (
    <TableBody>
      <>
        {isFetching && <FetchingRow colSpan={colSpan} />}
        {!isFetching && model.length === 0 && <EmptyRow colSpan={colSpan} />}
        {!isFetching &&
          model.map((item) => {
            return (
              <SimpleRow<T>
                columnsConfig={columnsConfig}
                isHighlighted={isHighlighted}
                isRowsDisabled={isRowsDisabled}
                isSelectingRowsEnabled={isSelectingRowsEnabled}
                item={item}
                key={`tr-simple-${item[modelKey]}`}
                modelKey={modelKey}
                onRowClick={onRowClick}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              >
                {children}
              </SimpleRow>
            );
          })}
      </>
    </TableBody>
  );
};
