import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    position: 'relative',
    touchAction: 'none',
  },
  dragging: {
    boxShadow:
      '0px 5px 8px -2px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.31)',
  },
  dropzonePlaceholder: {
    background: (theme) => theme.palette.background.default,
    border: (theme) => `${theme.spacing(0.5)} dashed ${theme.palette.divider}`,
    bottom: 0,
    display: 'block',
    height: '100%',
    left: 0,
    opacity: 0.85,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  iconButton: {
    '&:hover': {
      background: (theme) => theme.palette.background.paper,
    },
    background: (theme) => theme.palette.background.paper,
    border: (theme) => `1px solid ${theme.palette.divider}`,
    left: (theme) => `calc(100% - ${theme.spacing(2)})`,
    position: 'absolute',
    top: (theme) => theme.spacing(-2),
    zIndex: 1,
  },
  image: {
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    userSelect: 'none',
    width: '100%',
  },
  imageActive: {
    border: (theme) => `2px solid ${theme.palette.primary.main}`,
  },
  imageClickable: {
    cursor: 'pointer',
  },
  imageDisabled: {
    opacity: 0.3,
  },
  imageDraggable: {
    cursor: 'grab',
  },
  // avoid re-rendering images
  thumbnail: {
    '& > div': {
      backgroundSize: 'contain',
    },
  },
};
