import type { FormikConfig } from 'formik';
import isFunction from 'lodash-es/isFunction';
import * as Yup from 'yup';

import type { AdvancedRadioProps } from '../fields';
import { isFieldsetConfig } from '../guards';
import type { FormProps } from '../types';

export const prepareValidationSchema = <TFormValues>(
  fieldsConfig: FormProps<TFormValues>['fieldsConfig'],
  initialShape = {},
): FormikConfig<TFormValues>['validationSchema'] => {
  const shape: { [key: string]: Yup.AnySchema } = initialShape;

  fieldsConfig.forEach((config) => {
    if (isFieldsetConfig(config)) {
      prepareValidationSchema(config.fields, shape);
    } else {
      const { componentType, field, props } = config;

      if (componentType === 'advanced-radio') {
        const { options: fieldOptions } =
          props as AdvancedRadioProps<TFormValues>;
        const options = isFunction(fieldOptions)
          ? fieldOptions()
          : fieldOptions;

        options.forEach((option) => {
          prepareValidationSchema(option.nestedFieldsConfig, shape);
        });
      }

      const { name, validationSchema } = field;
      const { required } = props;

      if (validationSchema) {
        shape[name] = required
          ? validationSchema.required('form.errors.required')
          : validationSchema;
      } else if (required) {
        shape[name] = Yup.mixed().required('form.errors.required');
      }
    }
  });

  return Yup.object().shape(shape);
};
