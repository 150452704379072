import {
  AttachmentCategoryEnumDTO,
  EntityEnumDTO,
} from '../../../../../connectors/company';
import type {
  AttachmentSerializerDTO,
  ListAttachmentSerializerDTO,
} from '../../../../../connectors/company';
import type {
  DetailedPropertyObjectSerializerDTO,
  DetailedPropertySerializerDTO,
  DetailedUnitSerializerDTO,
  UnitAdvertisementSerializerDTO,
} from '../../../../../connectors/property';
import type { GalleryItem } from '../../../../shared';
import { advertisementClient } from '../../../advertisement.client';
import { convertToGalleryItem } from '../Form/form.helper';

const removeAttachments = async (
  currentAttachmentUuids: AttachmentSerializerDTO['uuid'][],
  attachments: ListAttachmentSerializerDTO['results'],
  details: UnitAdvertisementSerializerDTO,
  imageValues: GalleryItem<AttachmentSerializerDTO, EntityEnumDTO>[],
): Promise<void> => {
  const attachmentsToRemove = imageValues.filter(
    (attachment) =>
      currentAttachmentUuids.includes(attachment.uuid) &&
      !attachment.isSelected,
  );

  attachmentsToRemove.forEach((attachment) => {
    advertisementClient.deleteAttachment$(details.uuid, attachment.uuid);
  });
};

const addAttachments = async (
  currentAttachmentUuids: AttachmentSerializerDTO['uuid'][],
  attachments: ListAttachmentSerializerDTO['results'],
  details: UnitAdvertisementSerializerDTO,
  imageValues: GalleryItem<AttachmentSerializerDTO, EntityEnumDTO>[],
): Promise<void> => {
  const attachmentsToAdd = imageValues.filter(
    (attachment) =>
      !currentAttachmentUuids.includes(attachment.uuid) &&
      attachment.isSelected,
  );

  attachmentsToAdd.forEach((attachment) => {
    advertisementClient.addAttachments(details.uuid, [
      {
        attachmentUuid: attachment.uuid,
        sequenceNumber: imageValues.findIndex(
          (image) => image.uuid === attachment.uuid,
        ),
      },
    ]);
  });
};

const updateSequenceOrder = async (
  attachments: ListAttachmentSerializerDTO['results'],
  details: UnitAdvertisementSerializerDTO,
  imageValues: GalleryItem<AttachmentSerializerDTO, EntityEnumDTO>[],
): Promise<void> => {
  const sequence = attachments
    .filter((attachment) => {
      const indexInValues = imageValues.findIndex(
        (imageValue) => imageValue.uuid === attachment.uuid,
      );

      return indexInValues >= 0 && attachment.sequenceNumber !== indexInValues;
    })
    .map((attachment) => ({
      sequenceNumber: imageValues.findIndex(
        (imageValue) => imageValue.uuid === attachment.uuid,
      ),
      uuid: attachment.uuid,
    }));

  if (sequence.length === 0) return;
  await advertisementClient.updateAttachmentsSequence$(details.uuid, sequence);
};

export const updateAttachments = async (
  attachments: ListAttachmentSerializerDTO['results'],
  details: UnitAdvertisementSerializerDTO,
  imageValues: GalleryItem<AttachmentSerializerDTO, EntityEnumDTO>[],
): Promise<void> => {
  const currentAttachmentUuids = attachments.map(
    (attachment) => attachment.uuid,
  );

  await updateSequenceOrder(attachments, details, imageValues);
  await removeAttachments(
    currentAttachmentUuids,
    attachments,
    details,
    imageValues,
  );
  await addAttachments(
    currentAttachmentUuids,
    attachments,
    details,
    imageValues,
  );
};

export const getImages = (
  attachments: ListAttachmentSerializerDTO,
  unit: DetailedUnitSerializerDTO,
  propertyObject: DetailedPropertyObjectSerializerDTO,
  property: DetailedPropertySerializerDTO | null,
): GalleryItem<AttachmentSerializerDTO, EntityEnumDTO>[] => {
  const selectedAttachmentUuids = attachments.results.map(
    (attachment) => attachment.uuid,
  );

  return [
    ...(unit?.attachments.results || [])
      .filter(
        (attachment) => attachment.category === AttachmentCategoryEnumDTO.Image,
      )
      .map((attachment) =>
        convertToGalleryItem(attachment, EntityEnumDTO.Unit),
      ),
    ...(propertyObject?.attachments.results || [])
      .filter(
        (attachment) => attachment.category === AttachmentCategoryEnumDTO.Image,
      )
      .map((attachment) =>
        convertToGalleryItem(attachment, EntityEnumDTO.PropertyObject),
      ),
    ...(property?.attachments?.results || [])
      .filter(
        (attachment) => attachment.category === AttachmentCategoryEnumDTO.Image,
      )
      .map((attachment) =>
        convertToGalleryItem(attachment, EntityEnumDTO.Property),
      ),
  ]
    .map((image) => ({
      ...image,
      isSelected: selectedAttachmentUuids.includes(image.uuid),
    }))
    .sort(
      (a, b) =>
        attachments.results.findIndex(
          (attachment) => attachment.uuid === a.uuid,
        ) -
        attachments.results.findIndex(
          (attachment) => attachment.uuid === b.uuid,
        ),
    );
};
