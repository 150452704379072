import { propertyClient } from '../../../../../+property';
import { propertyObjectClient } from '../../../../../+property-object';
import { unitClient } from '../../../../../+unit';
import { localizationClient } from '../../../../../localization.client';
import type { FieldsetConfig, LocalizationConfig } from '../../../../../shared';
import {
  DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  DEFAULT_SELECT_OPTION,
} from '../../../../../shared';
import type { FormValues } from '../form.types';

export const getFieldsetConfig = (
  countries: LocalizationConfig['countryOptions'],
): FieldsetConfig<FormValues>[] => [
  {
    fields: [
      {
        componentType: 'select',
        field: {
          initialValue: DEFAULT_SELECT_OPTION.value,
          name: 'country',
        },
        props: {
          disabled: countries.length === 1,
          labelKey: 'ticket.fields.country.label',
          options: countries,
          placeholderKey: 'ticket.fields.country.placeholder',
          relatedFields: ['city', 'property', 'propertyObject', 'unit'],
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue: DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
          name: 'city',
        },
        props: {
          callback$: (query, { country }) =>
            localizationClient.getCities$(country, undefined, undefined, query),
          disableWhen: ({ country }) => !country,
          labelKey: 'ticket.fields.city',
          relatedFields: ['property', 'propertyObject', 'unit'],
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue: DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
          name: 'property',
        },
        props: {
          callback$: (query, { city, country }) =>
            country && city?.value
              ? propertyClient.getSuggestions$(
                  query,
                  undefined,
                  city.value,
                  country,
                )
              : Promise.resolve([]),
          disableWhen: ({ city, country }) => !country || !city?.value,
          labelKey: 'ticket.options.entity.property',
          relatedFields: ['propertyObject', 'unit'],
          required: true,
        },
      },
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue: DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
          name: 'propertyObject',
        },
        props: {
          callback$: (query, { property, city, country }) =>
            country && city?.value && property?.value
              ? propertyObjectClient.getSuggestions$(
                  query,
                  city.value,
                  country,
                  property.value,
                )
              : Promise.resolve([]),
          disableWhen: ({ city, country, property }) =>
            !country || !city?.value || !property?.value,
          labelKey: 'ticket.options.entity.propertyObject',
          relatedFields: ['unit'],
          required: true,
        },
      },
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue: DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
          name: 'unit',
        },
        props: {
          callback$: (query, { city, country, property, propertyObject }) =>
            country && city?.value && property?.value && propertyObject?.value
              ? unitClient.getSuggestions$(
                  query,
                  undefined,
                  city.value,
                  country,
                  property.value,
                  propertyObject.value,
                )
              : Promise.resolve([]),
          disableWhen: ({ city, country, property, propertyObject }) =>
            !country ||
            !city?.value ||
            !property?.value ||
            !propertyObject?.value,
          labelKey: 'ticket.options.entity.unit',
          required: true,
        },
      },
    ],
    key: 'address',
    titleKey: 'advertisement.fieldset.address.title',
  },
];
