import Typography from '@mui/material/Typography';
import type { Duration } from 'date-fns';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from '../../../../shared';

type Props = {
  date: GenericTypes.DateISO;
};

const ONE_SECOND_MS = 1000;
const ONE_MINUTE_MS = ONE_SECOND_MS * 60;
const ONE_HOUR_MS = ONE_MINUTE_MS * 60;
const ONE_DAY_MS = ONE_HOUR_MS * 24;

export const CountdownTimer: FC<Props> = (props) => {
  const { date } = props;
  const { t } = useTranslation();
  const endDate = useMemo(() => new Date(date), [date]);
  const getDuration = useCallback((): Duration => {
    const now = new Date();
    const timeLeft = endDate.getTime() - now.getTime();

    const days = Math.floor(timeLeft / ONE_DAY_MS);
    const hours = Math.floor((timeLeft % ONE_DAY_MS) / ONE_HOUR_MS);
    const minutes = Math.floor((timeLeft % ONE_HOUR_MS) / ONE_MINUTE_MS);

    return { days, hours, minutes };
  }, [endDate]);
  const [duration, setDuration] = useState<Duration>(getDuration());
  const isVisible = useCallback(
    (value: number | undefined) => value && value > 0,
    [],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const duration = getDuration();

      setDuration(duration);
    }, ONE_MINUTE_MS);

    return (): void => {
      clearInterval(interval);
    };
  }, [getDuration]);

  return (
    <Typography color="primary" variant="h2">
      {isVisible(duration.days) ? (
        <span>
          {duration.days}
          {t('ticket.details.labels.countdown.days')}{' '}
        </span>
      ) : null}
      <span>
        {duration.hours}
        {t('ticket.details.labels.countdown.hours')}{' '}
      </span>
      <span>
        {duration.minutes}
        {t('ticket.details.labels.countdown.minutes')}{' '}
      </span>
    </Typography>
  );
};
