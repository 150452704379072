import { UnitStatusEnumDTO } from '../../../../connectors/property';
import type { SelectOption } from '../../../shared';

const statusToTranslationLabelKeyMap: Record<
  UnitStatusEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [UnitStatusEnumDTO.ContractVacancy]: 'unit.fields.status.contractVacancy',
  [UnitStatusEnumDTO.Leased]: 'unit.fields.status.leased',
  [UnitStatusEnumDTO.RealVacancy]: 'unit.fields.status.realVacancy',
  [UnitStatusEnumDTO.RenovationVacancy]: 'unit.fields.status.renovationVacancy',
  [UnitStatusEnumDTO.StrategicVacancy]: 'unit.fields.status.strategicVacancy',
};

export const getStatusTranslationLabelKey = (
  status: UnitStatusEnumDTO,
): GenericTypes.TranslationLabel =>
  statusToTranslationLabelKeyMap[status] || status;

export const statusOptions: SelectOption[] = Object.values(
  UnitStatusEnumDTO,
).map((status) => ({
  labelKey: getStatusTranslationLabelKey(status),
  value: status,
}));
