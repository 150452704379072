import { BackendErrorType } from './error.enum';

const errorTypeToTranslationLabelKeyMap: Record<
  BackendErrorType,
  GenericTypes.TranslationLabel
> = {
  [BackendErrorType.ASSIGNEE_ROLE_NOT_ALLOWED]: '',
  [BackendErrorType.BOOLEAN_REQUIRED]: '',
  [BackendErrorType.DECIMAL_REQUIRED]: '',
  [BackendErrorType.EMPTY_FIELD]: 'form.errors.empty',
  [BackendErrorType.ENTRANCE_PROPERTY_OBJECT_MISMATCH]: '',
  [BackendErrorType.ERR_UNEXPECTED]: 'form.errors.unexpected',
  [BackendErrorType.FORBIDDEN]: 'form.errors.forbidden',
  [BackendErrorType.INTEGER_REQUIRED]: 'form.errors.number.general',
  [BackendErrorType.INVALID_BODY_FORMAT]: '',
  [BackendErrorType.INVALID_CHOICE]: 'ticket.errors.invalidChoice',
  [BackendErrorType.INVALID_DATE_FORMAT]: 'form.errors.invalidDate',
  [BackendErrorType.INVALID_ENUM_TYPE]: '',
  [BackendErrorType.INVALID_FORMAT_NO_VARIABLES]: '',
  [BackendErrorType.INVALID_FORMAT_VARIABLES]: '',
  [BackendErrorType.INVALID_VALUE]: 'form.errors.invalidValue',
  [BackendErrorType.NOT_FOUND]: '',
  [BackendErrorType.NOT_UNIQUE_FIELD]: 'form.errors.unique',
  [BackendErrorType.OBJECT_DOES_NOT_EXIST]: '',
  [BackendErrorType.OPEN_TICKET_ALREADY_EXIST]:
    'ticket.errors.duplicatedRequest',
  [BackendErrorType.PHONE_NUMBER_MISSING]: '',
  [BackendErrorType.PROSPECT_ALREADY_EXIST]: 'prospect.errors.duplicated',
  [BackendErrorType.REQUIRED_FIELD]: 'form.errors.required',
  [BackendErrorType.UNIT_NUMBER_ALREADY_EXISTS]: '',
  [BackendErrorType.UPDATE_NOT_ALLOWED]: '',
  [BackendErrorType.USER_NOTIFICATION_CONFIG_ALREADY_EXIST]: '',
  [BackendErrorType.UUID_REQUIRED]: '',
};

export const getErrorTypeTranslationLabelKey = (
  errorType: BackendErrorType,
): GenericTypes.TranslationLabel =>
  errorTypeToTranslationLabelKeyMap[errorType] || errorType;
