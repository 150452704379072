import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  activeIcon: {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
  },
  completedContent: {
    cursor: 'pointer',
  },
  completedIcon: {
    backgroundColor: 'success.main',
    color: 'success.contrastText',
  },
  content: {
    alignItems: 'center',
    cursor: 'default',
    display: 'flex',
  },
  element: {
    '& + &': {
      '&:before': {
        backgroundColor: (theme) => theme.palette.divider,
        content: '""',
        display: 'inline-block',
        height: 30,
        ml: 2.375,
        my: 1,
        width: '1px',
      },
    },
  },
  icon: {
    background: `rgba(68, 84, 106, 0.08)`,
    borderRadius: '50%',
    color: 'secondary.light',
    height: 40,
    mr: 2,
    p: 1,
    width: 40,
  },
  label: {},
};
