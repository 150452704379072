import { TicketStatusEnumDTO } from '../../../../connectors/ticket';

export const orderedStatuses = [
  TicketStatusEnumDTO.Open,
  TicketStatusEnumDTO.InProgress,
  TicketStatusEnumDTO.AwaitingTenant,
  TicketStatusEnumDTO.AwaitingExternalProvider,
  TicketStatusEnumDTO.OnHold,
  TicketStatusEnumDTO.Done,
];
