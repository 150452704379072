import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { generatePath } from 'react-router-dom';

import { ACTIVITY_LOG_VIEW_PERMISSIONS } from '../../+activity-log';
import {
  ATTACHMENTS_VIEW_PERMISSIONS,
  ListsOutlet as GenericListOutlet,
} from '../../shared';
import PATHS from '../paths.const';

export const ListsOutlet: FC = () => {
  const { id, type } = useParams();
  const tabs = useMemo(
    () => [
      {
        dataTestId: 'document-section',
        expectedPermissions: ATTACHMENTS_VIEW_PERMISSIONS,
        key: 'documents',
        labelKey: 'document.section',
        path: generatePath(PATHS.DOCUMENT_LIST, { id, type }),
      },
      {
        dataTestId: 'activity-log-section',
        expectedPermissions: ACTIVITY_LOG_VIEW_PERMISSIONS,
        key: 'activity-logs',
        labelKey: 'activityLog.section',
        path: generatePath(PATHS.ACTIVITY_LOG_LIST, { id, type }),
      },
    ],
    [id, type],
  );

  return <GenericListOutlet tabs={tabs} />;
};
