import type { AttachmentFileType } from '../../../attachment';

export enum FileCategory {
  IMAGE = 'image',
  DOCUMENT = 'document',
}

export interface CommentFile {
  category: FileCategory;
  data: ArrayBuffer | string;
  file: File;
  id: string;
  name: string;
  type: AttachmentFileType;
}

export interface CommentFilePreview extends CommentFile {
  remove(idx: number): void;
}
