import * as Sentry from '@sentry/react';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';
import type { UseTranslationOptions } from 'react-i18next';
import { useTranslation as useTranslationI18n } from 'react-i18next';

import type { LanguageDTO } from '../../../../connectors/company';
import { Spinner } from '../../components';
import { DEFAULT_LANGUAGE } from '../consts';
import { initTranslations } from '../service';
import { Context } from './context';

export const Provider: FC = ({ children }) => {
  const options: UseTranslationOptions = { useSuspense: false };
  const {
    t,
    i18n,
    ready: translationReady,
  } = useTranslationI18n(undefined, options);
  const langCode = useMemo(() => {
    if (!translationReady) return DEFAULT_LANGUAGE;

    return i18n.languages[0] as LanguageDTO;
  }, [i18n.languages, translationReady]);

  useEffect(() => {
    initTranslations().catch(Sentry.captureException);
  }, []);

  const changeLanguage = useCallback(
    async (language: LanguageDTO) => {
      if (!translationReady) return;

      await i18n.changeLanguage(language);
    },
    [i18n, translationReady],
  );

  const contextValue = useMemo(
    () => ({ changeLanguage, langCode, t }),
    [changeLanguage, langCode, t],
  );

  return translationReady ? (
    <Context.Provider value={contextValue}>{children}</Context.Provider>
  ) : (
    <Spinner />
  );
};

Provider.displayName = 'TranslationsProvider';
