import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  wrapper: {
    alignItems: 'center',
    backgroundColor: 'background.paper',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    p: 3,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
};
