import { ReactComponent as DirectionButtonArrowsIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/move/direction-button-arrows.svg';
import { ReactComponent as RemoveIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import type { IconButtonProps } from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { Dispatch, SetStateAction } from 'react';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { removeDragStyles, setDragStyles } from '../../../helpers';
import type { GalleryItem } from '../../fields';
import { sxProps } from './gallery-image.styles';

type Props = {
  item: GalleryItem;
  currentDrag?: GalleryItem | null;
  dragImageElement?: HTMLImageElement | null;
  isDragAllowed?: boolean;
  isRemoveAllowed?: boolean;
  isSelectAllowed?: boolean;
  isThumbnail?: boolean;
  onTouchDrop?: (position: DOMRect) => void;
  removeItem?: (id: GalleryItem['id']) => void;
  selectItem?: (id: GalleryItem['id']) => void;
  setCurrentDrag?: Dispatch<SetStateAction<GalleryItem | null>>;
};

export const GalleryImage = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    currentDrag,
    dragImageElement,
    isDragAllowed,
    isRemoveAllowed,
    isSelectAllowed,
    isThumbnail,
    item,
    onTouchDrop,
    removeItem,
    selectItem,
    setCurrentDrag,
  } = props;
  const container = useRef<HTMLDivElement>(null);
  const element = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const backgroundImage = useMemo(() => `url(${item.url})`, [item.url]);
  const isDisabled = useMemo(
    () => Boolean(isSelectAllowed && item.isSelected),
    [item.isSelected, isSelectAllowed],
  );
  const isSelectable = useMemo(
    () => Boolean(isSelectAllowed && !item.isSelected),
    [item.isSelected, isSelectAllowed],
  );
  const isDragging = useMemo(
    () => isDragAllowed && currentDrag?.id === item.id,
    [item.id, currentDrag?.id, isDragAllowed],
  );
  const isDropzoneVisible = useMemo(
    () => Boolean(isDragAllowed && currentDrag && !isDragging),
    [currentDrag, isDragAllowed, isDragging],
  );
  const boxStyle = useMemo(() => {
    if (isSelectAllowed) {
      return {
        ...sxProps.imageClickable,
        ...(item.isActive && sxProps.imageActive),
        ...(isDisabled && sxProps.imageDisabled),
      };
    }
    if (isDragAllowed) {
      return {
        ...sxProps.imageDraggable,
        ...(isDragging && sxProps.dragging),
      };
    }

    return null;
  }, [isDisabled, isDragAllowed, isDragging, isSelectAllowed, item.isActive]);
  const handleRemove: IconButtonProps['onClick'] = useCallback(
    (event) => {
      event.stopPropagation();

      if (!isRemoveAllowed) return;

      removeItem?.(item.id);
    },
    [isRemoveAllowed, item.id, removeItem],
  );
  const handleActionButtonTouchEvent:
    | IconButtonProps['onTouchStart']
    | IconButtonProps['onTouchEnd'] = useCallback(
    (event) => event.stopPropagation(),
    [],
  );
  const handleDragStart: BoxProps['onDragStart'] = useCallback(
    (event) => {
      if (!isDragAllowed || !dragImageElement || !setCurrentDrag) return;

      /* eslint-disable no-param-reassign */
      // changing style of cursor during dragging
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      /* eslint-enable no-param-reassign */
      event.dataTransfer.setDragImage(dragImageElement, 0, 0);
      setCurrentDrag(item);
    },
    [dragImageElement, isDragAllowed, item, setCurrentDrag],
  );
  const handleDrag: BoxProps['onDrag'] = useCallback(
    (event) => {
      if (!isDragAllowed || !container?.current) return;

      const { currentTarget: el, pageX, pageY } = event;

      setDragStyles(theme.spacing(0), container.current, el, pageX, pageY);
    },
    [isDragAllowed, theme],
  );
  const handleDragEnd: BoxProps['onDragEnd'] = useCallback(
    (event) => {
      if (!isDragAllowed || !setCurrentDrag) return;

      const { currentTarget: el } = event;

      removeDragStyles(el);
      setCurrentDrag(null);
    },
    [isDragAllowed, setCurrentDrag],
  );
  const handleTouchStart: BoxProps['onTouchStart'] = useCallback(() => {
    if (!isDragAllowed || !setCurrentDrag) return;

    setCurrentDrag(item);
  }, [isDragAllowed, item, setCurrentDrag]);
  const handleTouchMove: BoxProps['onTouchMove'] = useCallback(
    (event) => {
      if (!isDragAllowed || !element?.current) return;

      const { current: el } = element;
      const { pageX, pageY } = event.changedTouches[0];

      setDragStyles(theme.spacing(0), container.current, el, pageX, pageY);
    },
    [isDragAllowed, theme],
  );
  const handleTouchEnd: BoxProps['onTouchEnd'] = useCallback(
    (event) => {
      if (!isDragAllowed || !onTouchDrop) return;

      const { currentTarget: el } = event;

      onTouchDrop(el.getBoundingClientRect());
      removeDragStyles(el);
    },
    [isDragAllowed, onTouchDrop],
  );

  useImperativeHandle(ref, () => container.current as HTMLDivElement);

  return (
    <Grid
      item
      lg={isThumbnail ? 12 : 3}
      md={isThumbnail ? 12 : 4}
      xl={isThumbnail ? 12 : 2}
      xs={isThumbnail ? 12 : 6}
      ref={container}
    >
      <Box
        draggable={isDragAllowed}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        sx={
          {
            ...sxProps.box,
            ...(isDragging && sxProps.dragging),
            ...(isDragAllowed && sxProps.imageDraggable),
          } as SxProps
        }
        ref={element}
      >
        {isDropzoneVisible && <Box sx={sxProps.dropzonePlaceholder} />}
        {(isDragging || isRemoveAllowed) && !isDropzoneVisible && (
          <IconButton
            onClick={handleRemove}
            onTouchEnd={handleActionButtonTouchEvent}
            onTouchStart={handleActionButtonTouchEvent}
            sx={sxProps.iconButton}
          >
            {isDragging ? (
              <DirectionButtonArrowsIcon height={16} width={16} />
            ) : (
              <RemoveIcon height={16} width={16} />
            )}
          </IconButton>
        )}
        <Box
          {...(isSelectable && {
            onClick: () => selectItem?.(item.id),
          })}
          sx={{ ...boxStyle, ...(isThumbnail && sxProps.thumbnail) } as SxProps}
        >
          <Box sx={{ backgroundImage, ...sxProps.image } as SxProps} />
        </Box>
      </Box>
    </Grid>
  );
});
