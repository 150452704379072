import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { ProspectStatusEnumDTO } from '../../../../../connectors/property';
import {
  getDoughnutChartLegendEntries,
  useTranslation,
} from '../../../../shared';
import {
  getStatusColorsHex,
  getStatusTranslationLabelKey,
} from '../../helpers';
import { sxProps } from './chart-widget-legend.styles';

type Props = {
  countObject: Record<ProspectStatusEnumDTO | 'total', number>;
};

export const ChartWidgetLegend: FC<Props> = (props) => {
  const { countObject } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const entries = useMemo(
    () =>
      getDoughnutChartLegendEntries(
        countObject,
        getStatusColorsHex,
        getStatusTranslationLabelKey,
        theme.palette.mode,
      ),
    [countObject, theme.palette.mode],
  );

  return (
    <Grid container marginTop={1} spacing={2}>
      {entries.map((entry) => (
        <Grid item xs={6} sm={4} key={entry.key}>
          <Box sx={sxProps.box}>
            <Box
              sx={
                {
                  ...sxProps.rectangle,
                  backgroundColor: entry.backgroundColor,
                } as SxProps
              }
            />
            <Typography
              sx={
                {
                  ...sxProps.label,
                  ...(entry.status === 'total' && sxProps.total),
                } as SxProps
              }
            >
              {t(entry.labelKey)}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
