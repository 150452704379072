import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  boldText: {
    fontWeight: 'bold',
  },
  box: {
    p: 2,
  },
  header: {
    alignItems: 'center',
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    color: 'text.primary',
    display: 'flex',
    p: 2,
  },
  icon: {
    alignSelf: 'center',
    color: (theme) => theme.palette.grey[400],
    height: 48,
    my: 2,
    overflow: 'visible',
    width: 48,
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    m: 0,
    pl: 1,
  },
};
