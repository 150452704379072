import camelCase from 'lodash-es/camelCase';

import type { PropConfigSerializerDTO } from '../../../../connectors/company';
import { getAttributeLabel } from './attribute-list.helper';
import type {
  AdditionalProps,
  SpecificAttributeList,
} from './attribute-list.type';

const getPropValueByKey = (
  props: SpecificAttributeList,
  key: PropConfigSerializerDTO['key'],
): AdditionalProps['value'] => (props ? props[key] : null);

export const getAttributeListModel = (
  props: SpecificAttributeList,
  propConfigs: PropConfigSerializerDTO[],
): AdditionalProps[] =>
  propConfigs.map((item) => {
    const itemKey = camelCase(item.key);

    return {
      ...item,
      key: itemKey,
      label: getAttributeLabel(item.key),
      value: getPropValueByKey(props, itemKey),
    };
  });
