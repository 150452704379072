import { ReactComponent as SendEmailIcon } from '@heimstaden/icons-library/img/streamline-regular/emails/send-email/send-email.svg';
import { ReactComponent as ImageFileAddIcon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/image-files/image-file-add.svg';
import { ReactComponent as AttachmentIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/link-unlink/attachment.svg';
import { ReactComponent as MessagesBubbleStarIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-star.svg';
import Avatar from '@mui/material/Avatar';
import type { BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import uniqBy from 'lodash-es/uniqBy';
import type { FC } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';

import { useAuth } from '../../../../+auth';
import type { LanguageDTO } from '../../../../../connectors/company';
import type {
  ListResponseTemplateSerializerDTO,
  ResponseTemplateSerializerDTO,
} from '../../../../../connectors/ticket';
import { CommentVisibilityEnumDTO } from '../../../../../connectors/ticket';
import type { EmployeeSerializerDTO } from '../../../../../connectors/user';
import {
  supportedDocumentTypes,
  supportedImageTypes,
} from '../../../attachment/shared';
import type { MentionTransformation } from '../../../helpers';
import {
  getCommentRawValue,
  getMentionConfig,
  getMentionTransformation,
  getValueWithMention,
  isMentionIncluded,
} from '../../../helpers';
import { useApi, useDebounce } from '../../../hooks';
import { useTranslation } from '../../../translations';
import type { ListQueryParams } from '../../List';
import { Spinner } from '../../Spinner/spinner.component';
import { AttachmentsPreview } from '../AttachmentsPreview/attachments-preview.component';
import { SuggestionMenu } from '../SuggestionMenu/suggestion-menu.component';
import { TemplatePicker } from '../TemplatePicker/template-picker.component';
import {
  addAttachmentsToPreview,
  attachmentAddHandler,
  attachmentRemoveHandler,
} from './typer.helper';
import { sxProps } from './typer.styles';
import type { CommentFile } from './typer.type';
import { FileCategory } from './typer.type';

type Props = {
  addComment$: (
    comment: string,
    visibility: CommentVisibilityEnumDTO,
    imageFiles?: FileList,
    documentFiles?: FileList,
  ) => Promise<void>;
  getMentions$?: (query: string) => Promise<EmployeeSerializerDTO[]>;
  getResponseTemplates$?: (
    queryParams: ListQueryParams,
    language?: LanguageDTO,
  ) => Promise<ListResponseTemplateSerializerDTO>;
  removeResponseTemplate$?: (
    id: ResponseTemplateSerializerDTO['uuid'],
  ) => Promise<void>;
  visibility?: CommentVisibilityEnumDTO;
};

const options = [
  { labelKey: 'comment.filter.public', value: CommentVisibilityEnumDTO.Public },
  {
    labelKey: 'comment.filter.internal',
    value: CommentVisibilityEnumDTO.Internal,
  },
];

export const Typer: FC<Props> = (props) => {
  const {
    addComment$,
    getMentions$,
    getResponseTemplates$,
    removeResponseTemplate$,
    visibility,
  } = props;
  const inputRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { user } = useAuth();
  const [value, setValue] = useState('');
  const [mentionQuery, setMentionQuery] = useState('');
  const [areSuggestionsOpen, setSuggestionsOpen] = useState(false);
  const [mentionTransformations, setMentionTransformations] = useState<
    MentionTransformation[]
  >([]);
  const [mentionTriggerSignIdx, setMentionTriggerSignIdx] = useState(-1);
  const [mentionSelectionStart, setMentionSelectionStart] = useState<
    number | null
  >(null);
  const [isOpen, setOpen] = useState(false);
  const [isTemplatePickerOpen, setTemplatePickerOpen] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const documentsInput = useRef<HTMLInputElement>(null);
  const imagesInput = useRef<HTMLInputElement>(null);
  const [imagesPreview, setImagesPreview] = useState<CommentFile[]>([]);
  const [documentsPreview, setDocumentsPreview] = useState<CommentFile[]>([]);
  const [isCommentLoading, setCommentLoading] = useState(false);
  const handleRemoveDocument = useCallback(
    (idx) => {
      const attachments = documentsInput?.current?.files;

      if (!attachments) return;
      const attachmentRemove = attachmentRemoveHandler(
        attachments,
        documentsPreview,
        idx,
      );

      setDocumentsPreview(attachmentRemove.newCommentsImagesArray);
      documentsInput.current.files = attachmentRemove.fileBuffer.files;
    },
    [documentsPreview],
  );
  const handleRemoveImage = useCallback(
    (idx) => {
      const attachments = imagesInput?.current?.files;

      if (!attachments) return;
      const attachmentRemove = attachmentRemoveHandler(
        attachments,
        imagesPreview,
        idx,
      );

      setImagesPreview(attachmentRemove.newCommentsImagesArray);
      imagesInput.current.files = attachmentRemove.fileBuffer.files;
    },
    [imagesPreview],
  );
  const attachments = useMemo(() => {
    return [...imagesPreview, ...documentsPreview].map((attachment) => ({
      ...attachment,
      remove:
        attachment.category === FileCategory.IMAGE
          ? handleRemoveImage
          : handleRemoveDocument,
    }));
  }, [
    handleRemoveDocument,
    handleRemoveImage,
    documentsPreview,
    imagesPreview,
  ]);
  const handleImagesInputChange = useCallback((e): void => {
    addAttachmentsToPreview(e, FileCategory.IMAGE, (image: CommentFile) => {
      setImagesPreview((prevState) => {
        const { attachmentFiles, attachmentPreviews } = attachmentAddHandler(
          prevState,
          image,
        );

        if (imagesInput.current && attachmentFiles) {
          imagesInput.current.files = attachmentFiles;
        }

        return attachmentPreviews;
      });
    });
  }, []);
  const handleDocumentsInputChange = useCallback((e): void => {
    addAttachmentsToPreview(e, FileCategory.DOCUMENT, (document: CommentFile) =>
      setDocumentsPreview((prevState) => {
        const { attachmentFiles, attachmentPreviews } = attachmentAddHandler(
          prevState,
          document,
        );

        if (documentsInput.current && attachmentFiles) {
          documentsInput.current.files = attachmentFiles;
        }

        return attachmentPreviews;
      }),
    );
  }, []);
  const clearAllFiles = (): void => {
    setImagesPreview([]);
    if (imagesInput?.current?.value) {
      imagesInput.current.value = '';
    }
    setDocumentsPreview([]);
    if (documentsInput?.current?.value) {
      documentsInput.current.value = '';
    }
  };
  const hasMention = useMemo(
    () => isMentionIncluded(mentionTransformations, value),
    [mentionTransformations, value],
  );
  const getMentionOptions$ = useCallback(() => {
    if (mentionQuery.length < 2 || !getMentions$) return Promise.resolve([]);

    return getMentions$(mentionQuery);
  }, [getMentions$, mentionQuery]);
  const { response: suggestions, setResponse: setSuggestions } = useApi(
    [],
    getMentionOptions$,
  );
  const handleMention: TextFieldProps['onChange'] = useCallback(
    (event) => {
      if (!getMentions$) return;

      const { isMentionTriggered, query, selectionStart, triggerSignIdx } =
        getMentionConfig(event);

      if (!isMentionTriggered) {
        setMentionQuery('');
        setSuggestionsOpen(false);
        setSuggestions([]);

        return;
      }

      setMentionQuery(query);
      setMentionTriggerSignIdx(triggerSignIdx);
      setMentionSelectionStart(selectionStart);
    },
    [getMentions$, setSuggestions],
  );
  const debouncedHandleMention = useDebounce(handleMention, 500);
  const handleChange: TextFieldProps['onChange'] = useCallback(
    (event) => {
      setValue(event.target.value);

      debouncedHandleMention(event);
    },
    [debouncedHandleMention],
  );
  const handleSelectSuggestion = useCallback(
    (suggestion: EmployeeSerializerDTO) => {
      const valueToSet = getValueWithMention(
        suggestion,
        mentionSelectionStart,
        mentionTriggerSignIdx,
        value,
      );
      const mentionTransformation = getMentionTransformation(suggestion);

      setValue(valueToSet);
      setMentionTransformations((prevState) =>
        uniqBy([...prevState, mentionTransformation], 'rawValue'),
      );
      setSuggestionsOpen(false);
      setSuggestions([]);
    },
    [mentionSelectionStart, mentionTriggerSignIdx, setSuggestions, value],
  );
  const handleSuggestionDismiss = useCallback(() => {
    setMentionTriggerSignIdx(-1);
    setSuggestionsOpen(false);
    setSuggestions([]);
  }, [setSuggestions]);
  const sendComment$ = useCallback(
    async (visibility: CommentVisibilityEnumDTO) => {
      if (!value) {
        setOpen(false);

        return;
      }
      if (visibility === CommentVisibilityEnumDTO.Public && hasMention) {
        setOpen(false);
        toast.error(t('comment.errors.mentionInPublicComment'));

        return;
      }

      setFetching(true);

      try {
        const rawValue = getCommentRawValue(mentionTransformations, value);

        setCommentLoading(true);
        await addComment$(
          rawValue,
          visibility,
          imagesInput?.current?.files ?? undefined,
          documentsInput?.current?.files ?? undefined,
        );
        setCommentLoading(false);
        setValue('');
        setMentionTransformations([]);
        clearAllFiles();
      } catch (e) {
        toast.error(t('errors.general.message'));
      } finally {
        setFetching(false);
        setOpen(false);
      }
    },
    [addComment$, hasMention, mentionTransformations, t, value],
  );
  const handleKeyDown: BoxProps['onKeyDown'] = useCallback(
    async (event) => {
      switch (event.key) {
        case 'Enter': {
          await (visibility ? sendComment$(visibility) : setOpen(true));
          break;
        }
        default:
      }
    },
    [sendComment$, visibility],
  );

  useEffect(() => {
    if (suggestions.length > 0) {
      setSuggestionsOpen(true);
    }
  }, [suggestions.length]);

  return (
    <Grid alignItems="flex-end" container sx={sxProps.container} wrap="nowrap">
      <Grid item>
        <Avatar src={user?.profilePicture} sx={sxProps.avatar} />
      </Grid>
      <Grid flexGrow={1} item>
        <TextField
          disabled={isFetching}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachmentsPreview attachments={attachments} />
              </InputAdornment>
            ),
            sx: sxProps.input,
          }}
          maxRows={3}
          minRows={1}
          multiline
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={t('comment.typer.placeholder')}
          ref={inputRef}
          value={value}
        />
        <SuggestionMenu
          anchorEl={inputRef.current}
          isOpen={areSuggestionsOpen}
          onClose={handleSuggestionDismiss}
          onSelect={handleSelectSuggestion}
          suggestions={suggestions}
        />
      </Grid>
      <Grid item>
        <IconButton
          ref={ref}
          color={value ? 'primary' : 'secondary'}
          disabled={!value || isFetching}
          onClick={() =>
            visibility ? sendComment$(visibility) : setOpen(true)
          }
        >
          {isCommentLoading ? (
            <Spinner size={24} />
          ) : (
            <SendEmailIcon height={24} width={24} />
          )}
        </IconButton>
      </Grid>

      {getResponseTemplates$ && (
        <>
          <Grid item sx={sxProps.actionButtons}>
            <IconButton onClick={() => setTemplatePickerOpen(true)}>
              <MessagesBubbleStarIcon height={24} width={24} />
            </IconButton>
          </Grid>
          <TemplatePicker
            getResponseTemplates$={getResponseTemplates$}
            isOpen={isTemplatePickerOpen}
            pickTemplate={setValue}
            removeResponseTemplate$={removeResponseTemplate$}
            setOpen={setTemplatePickerOpen}
          />
        </>
      )}
      <input
        ref={imagesInput}
        accept={supportedImageTypes.join(', ')}
        className="sr-only"
        id="images-input"
        multiple
        type="file"
        onChange={handleImagesInputChange}
      />
      <InputLabel htmlFor="images-input" sx={sxProps.attachmentButtonLabel}>
        <IconButton component="span">
          <ImageFileAddIcon height={24} width={24} />
        </IconButton>
      </InputLabel>
      <input
        ref={documentsInput}
        accept={supportedDocumentTypes.join(', ')}
        className="sr-only"
        id="documents-input"
        multiple
        type="file"
        onChange={handleDocumentsInputChange}
      />
      <InputLabel htmlFor="documents-input" sx={sxProps.attachmentButtonLabel}>
        <IconButton component="span">
          <AttachmentIcon height={24} width={24} />
        </IconButton>
      </InputLabel>
      {!visibility && (
        <Menu
          anchorEl={ref.current}
          open={isOpen}
          onClose={() => setOpen(false)}
        >
          {options.map((option) => {
            const { labelKey, value } = option;

            return (
              <MenuItem
                disabled={
                  value === CommentVisibilityEnumDTO.Public && hasMention
                }
                onClick={() => sendComment$(value)}
                key={value}
              >
                <Typography variant="button">{t(labelKey || '')}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Grid>
  );
};
