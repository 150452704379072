import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { useTranslation } from '../../../translations';
import { sxProps } from './checkbox-field-label.styles';

type Props = {
  label?: string;
  labelKey?: GenericTypes.TranslationLabel;
  pictureURL?: GenericTypes.URL;
  subLabel?: string;
  subLabelKey?: GenericTypes.TranslationLabel;
};

export const CheckboxFieldLabel: FC<Props> = (props) => {
  const { label, labelKey, pictureURL, subLabel, subLabelKey } = props;
  const { t } = useTranslation();
  const finalLabel = useMemo(
    () => (labelKey ? t(labelKey) : label),
    [label, labelKey, t],
  );
  const finalSubLabel = useMemo(
    () => (subLabelKey ? t(subLabelKey) : subLabel),
    [subLabel, subLabelKey, t],
  );

  if (!finalSubLabel && !pictureURL) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{finalLabel}</>;
  }

  return (
    <Grid alignItems="center" container spacing={2}>
      {pictureURL && (
        <Grid item>
          <Avatar src={pictureURL} sx={sxProps.avatar} />
        </Grid>
      )}
      <Grid item>
        <Typography component="span" variant="h2" mb={0}>
          {finalLabel}
        </Typography>
        {finalSubLabel && (
          <Typography
            color="secondary"
            component="span"
            display="block"
            variant="subtitle2"
          >
            {finalSubLabel}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
