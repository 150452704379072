import type { FC } from 'react';
import { Fragment } from 'react';

import type {
  ComplaintDetailSerializerDTO,
  ContractRequestDetailSerializerDTO,
  GeneralRequestDetailSerializerDTO,
  InvoiceRequestDetailSerializerDTO,
  ServiceRequestDetailSerializerDTO,
  UniversalTicketSerializerDTO,
} from '../../../../connectors/ticket';
import { TicketTypeEnumDTO } from '../../../../connectors/ticket';
import { TicketTypePath } from '../../paths.const';
import {
  ComplaintForm,
  ContractRequestForm,
  GeneralRequestForm,
  InvoiceRequestForm,
} from '../components';
import type { TicketFormProps, DetailsData } from '../types';

export const isComplaint = (
  data: DetailsData,
): data is DetailsData<ComplaintDetailSerializerDTO> =>
  data.ticketType === TicketTypeEnumDTO.Complaint;

export const isContractRequest = (
  data: DetailsData,
): data is DetailsData<ContractRequestDetailSerializerDTO> =>
  data.ticketType === TicketTypeEnumDTO.ContractRequest;

export const isGeneralRequest = (
  data: DetailsData,
): data is DetailsData<GeneralRequestDetailSerializerDTO> =>
  data.ticketType === TicketTypeEnumDTO.GeneralRequest;

export const isInvoiceRequest = (
  data: DetailsData,
): data is DetailsData<InvoiceRequestDetailSerializerDTO> =>
  data.ticketType === TicketTypeEnumDTO.InvoiceRequest;

export const isServiceRequest = (
  data: DetailsData,
): data is DetailsData<ServiceRequestDetailSerializerDTO> =>
  data.ticketType === TicketTypeEnumDTO.ServiceRequest;

export const getTypePathBasedOnTicketType = (
  ticketType: TicketTypeEnumDTO,
): TicketTypePath | undefined => {
  switch (ticketType) {
    case TicketTypeEnumDTO.Complaint:
      return TicketTypePath.COMPLAINTS;
    case TicketTypeEnumDTO.ContractRequest:
      return TicketTypePath.CONTRACT_REQUESTS;
    case TicketTypeEnumDTO.GeneralRequest:
      return TicketTypePath.GENERAL_REQUESTS;
    case TicketTypeEnumDTO.InvoiceRequest:
      return TicketTypePath.INVOICE_REQUESTS;
    case TicketTypeEnumDTO.ServiceRequest:
      return TicketTypePath.SERVICE_REQUESTS;
    default:
      return undefined;
  }
};

export const getForm = (
  type: TicketTypePath | UniversalTicketSerializerDTO['ticketType'],
): FC<TicketFormProps> => {
  switch (type) {
    case TicketTypeEnumDTO.Complaint:
    case TicketTypePath.COMPLAINTS: {
      return ComplaintForm;
    }
    case TicketTypeEnumDTO.ContractRequest:
    case TicketTypePath.CONTRACT_REQUESTS: {
      return ContractRequestForm;
    }
    case TicketTypeEnumDTO.GeneralRequest:
    case TicketTypePath.GENERAL_REQUESTS: {
      return GeneralRequestForm;
    }
    case TicketTypeEnumDTO.InvoiceRequest:
    case TicketTypePath.INVOICE_REQUESTS: {
      return InvoiceRequestForm;
    }
    default: {
      return Fragment;
    }
  }
};
