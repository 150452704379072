import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  buttons: {
    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
    mt: 0,
    pt: 2,
  },
};
