import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash-es/isEmpty';
import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';

import { GlobalError } from '../../../../../shared';
import { Navigation } from '../Navigation/navigation.component';
import { NavigationButton } from '../NavigationButton/navigation-button.component';
import { SubmitButton } from '../SubmitButton/submit-button.component';
import { steps } from '../form.const';
import type { FormValues } from '../form.types';
import { sxProps } from './stepper-content.styles';

type Props = {
  globalErrorKey?: GenericTypes.TranslationLabel;
};

export const StepperContent: FC<Props> = (props) => {
  const { globalErrorKey } = props;
  const { errors, isSubmitting } = useFormikContext<FormValues>();
  const [activeStepIdx, setActiveStepIdx] = useState(0);
  const activeStep = useMemo(() => steps[activeStepIdx], [activeStepIdx]);
  const isLastStep = useMemo(
    () => activeStepIdx === steps.length - 1,
    [activeStepIdx],
  );
  const Component = useMemo(
    () => steps[activeStepIdx].component,
    [activeStepIdx],
  );

  useEffect(() => {
    if (isSubmitting && !isEmpty(errors)) {
      const fieldNames = Object.keys(errors) as (keyof FormValues)[];

      steps.forEach((step, idx) => {
        const isField = fieldNames.some((fieldName) =>
          step.validationFieldNames.includes(fieldName),
        );

        if (isField) {
          setActiveStepIdx(idx);
        }
      });
    }
  }, [errors, isSubmitting]);

  return (
    <>
      <Grid item xs={12} md={4}>
        <Navigation
          activeStepIdx={activeStepIdx}
          setActiveStepIdx={setActiveStepIdx}
        />
      </Grid>
      <Grid item xs={12} md={8} sx={sxProps.formContainer}>
        <GlobalError errorMessage={globalErrorKey} />
        <Component isStepper />
        {isLastStep ? (
          <SubmitButton />
        ) : (
          <NavigationButton
            activeStep={activeStep}
            activeStepIdx={activeStepIdx}
            setActiveStepIdx={setActiveStepIdx}
          />
        )}
      </Grid>
    </>
  );
};
