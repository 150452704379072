import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  icon: {
    color: (theme) => theme.palette.grey[500],
    mb: 0.5,
    mr: 1,
  },
  link: {
    alignItems: 'center',
    display: 'flex',
    maxWidth: '100%',
    textDecoration: 'none',
  },
  text: {
    color: (theme) => theme.palette.secondary.light,
  },
};
