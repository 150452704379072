import Box from '@mui/material/Box';
import type { FC, UIEventHandler } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { LanguageDTO } from '../../../../connectors/company';
import type {
  CommentSerializerDTO,
  CommentVisibilityEnumDTO,
  CreateReponseTemplateSerializerDTO,
  ListResponseTemplateSerializerDTO,
  ResponseTemplateSerializerDTO,
} from '../../../../connectors/ticket';
import type { EmployeeSerializerDTO } from '../../../../connectors/user';
import { useDebounce } from '../../hooks';
import type { ListQueryParams } from '../List';
import { Scrollbar } from '../Scrollbar/scrollbar.component';
import { Spinner } from '../Spinner/spinner.component';
import { Comment } from './Comment/comment.component';
import { Typer } from './Typer/typer.component';
import { sxProps } from './comments.styles';

type Props = {
  addComment$: (content: string) => Promise<void>;
  comments: CommentSerializerDTO[];
  isFetching: boolean;
  isFetchingImages?: boolean;
  isFetchingDocuments?: boolean;
  onNextPage: () => void;
  addResponseTemplate$?: (
    template: CreateReponseTemplateSerializerDTO,
  ) => Promise<ResponseTemplateSerializerDTO>;
  getMentions$?: (query: string) => Promise<EmployeeSerializerDTO[]>;
  getResponseTemplates$?: (
    queryParams: ListQueryParams,
    language?: LanguageDTO,
  ) => Promise<ListResponseTemplateSerializerDTO>;
  lastCommentId?: CommentSerializerDTO['uuid'];
  removeResponseTemplate$?: (
    id: ResponseTemplateSerializerDTO['uuid'],
  ) => Promise<void>;
  visibility?: CommentVisibilityEnumDTO;
};

const INFINITY_SCROLL_DELAY = 1000;
const REACH_TOP_THRESHOLD = 100;

export const Comments: FC<Props> = (props) => {
  const {
    addComment$,
    addResponseTemplate$,
    comments,
    getMentions$,
    getResponseTemplates$,
    isFetching,
    isFetchingImages = false,
    isFetchingDocuments = false,
    lastCommentId,
    onNextPage,
    removeResponseTemplate$,
    visibility,
  } = props;
  const commentsEndRef = useRef<HTMLDivElement>(null);
  const scrollbar = useRef<HTMLElement | null>(null);
  const [, setScrollHeight] = useState(0);
  const fixScrollPosition = useCallback(
    (currentHeight: number, prevHeight: number) => {
      const top = currentHeight - prevHeight;

      scrollbar.current?.scrollTo({ top });
    },
    [],
  );
  const scrollToBottom = useCallback(() => {
    scrollbar.current?.scrollTo({
      top: commentsEndRef.current?.offsetTop,
    });
  }, []);
  const handleScroll = useCallback(
    (scrollTop: number) => {
      if (scrollTop > REACH_TOP_THRESHOLD) return;

      onNextPage();
    },
    [onNextPage],
  );
  const debouncedScroll = useDebounce(handleScroll, INFINITY_SCROLL_DELAY);
  const onScroll: UIEventHandler<HTMLElement> = useCallback(
    (event) => {
      const { scrollTop } = event.currentTarget;

      debouncedScroll(scrollTop);
    },
    [debouncedScroll],
  );

  useEffect(() => {
    setScrollHeight((prevState) => {
      if (!scrollbar.current) return prevState;

      const { scrollHeight } = scrollbar.current;

      fixScrollPosition(scrollHeight, prevState);

      return scrollHeight;
    });
  }, [comments, fixScrollPosition, lastCommentId]);

  useEffect(() => {
    scrollToBottom();
  }, [isFetchingImages, isFetchingDocuments, lastCommentId, scrollToBottom]);

  return (
    <>
      <Scrollbar
        onScroll={onScroll}
        ref={(element) => {
          scrollbar.current = element;
        }}
      >
        <Box sx={sxProps.messages}>
          {isFetching && (
            <Box sx={sxProps.spinnerWrapper}>
              <Spinner size={24} />
            </Box>
          )}
          {comments.map((comment) => (
            <Comment
              addResponseTemplate$={addResponseTemplate$}
              isFetchingImages={
                isFetchingImages && comment.uuid === lastCommentId
              }
              isFetchingDocuments={
                isFetchingDocuments && comment.uuid === lastCommentId
              }
              key={comment.uuid}
              {...comment}
            />
          ))}
          <Box ref={commentsEndRef} />
        </Box>
      </Scrollbar>
      <Typer
        addComment$={addComment$}
        getMentions$={getMentions$}
        getResponseTemplates$={getResponseTemplates$}
        removeResponseTemplate$={removeResponseTemplate$}
        visibility={visibility}
      />
    </>
  );
};
