import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import {
  getTicketStatusColorsHex,
  getTicketStatusTranslationLabelKey,
} from '../../../../../+ticket';
import { useTranslation } from '../../../../translations';
import { getDoughnutChartLegendEntries } from '../../../charts';
import { sxProps } from './legend.styles';

type Props = {
  countObject: Record<string | 'total', number>; // TODO: type could be improved after https://fredensborg.atlassian.net/browse/MB-325
};

export const Legend: FC<Props> = (props) => {
  const { countObject } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const entries = useMemo(
    () =>
      getDoughnutChartLegendEntries(
        countObject,
        getTicketStatusColorsHex,
        getTicketStatusTranslationLabelKey,
        theme.palette.mode,
      ),
    [countObject, theme.palette.mode],
  );

  return (
    <List>
      {entries.map((entry) => (
        <ListItem key={entry.key} sx={sxProps.listItem}>
          <Box
            sx={{ ...sxProps.box, backgroundColor: entry.backgroundColor }}
          />
          <Typography variant="subtitle2">{t(entry.labelKey)}</Typography>
        </ListItem>
      ))}
    </List>
  );
};
