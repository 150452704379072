import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import type { FC } from 'react';
import React, { Fragment, useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import {
  getTicketTypeIcon,
  TICKET_PATHS,
  ticketClient,
  TicketRequestCell,
  TicketTypePath,
} from '../../../../+ticket';
import type { ServiceRequestFilterValues } from '../../../../+ticket/shared';
import { SERVICE_REQUEST_INITIAL_FILTER_VALUES } from '../../../../+ticket/shared';
import { TicketTypeEnumDTO } from '../../../../../connectors/ticket';
import { AsyncContentProvider } from '../../../providers';
import { useTranslation } from '../../../translations';
import { Box } from '../../Box/box.component';
import { sxProps } from './latest-service-request.styles';

type Props = {
  count: number;
};

const FILTERS: ServiceRequestFilterValues = {
  ...SERVICE_REQUEST_INITIAL_FILTER_VALUES,
  sort: '-created_at',
};

export const LatestServiceRequest: FC<Props> = (props) => {
  const { count } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const Icon = useMemo(
    () => getTicketTypeIcon(TicketTypeEnumDTO.ServiceRequest),
    [],
  );
  const getData$ = useCallback(
    () =>
      ticketClient.getTickets$({
        filters: { fields: FILTERS },
        pageSize: count,
      }),
    [count],
  );
  const redirectPath = generatePath(TICKET_PATHS.LIST, {
    type: TicketTypePath.SERVICE_REQUESTS,
  });

  return (
    <Box sx={sxProps.box}>
      <AsyncContentProvider getData$={getData$}>
        {({ results }) => (
          <Grid container>
            <Grid item sx={sxProps.header} xs={12}>
              <Grid
                alignItems="center"
                container
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Icon
                    height={24}
                    stroke={theme.palette.secondary.main}
                    width={24}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {t('dashboard.widgets.latestServiceRequest.title')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <List sx={sxProps.list}>
                {results.map((ticket, idx) => {
                  const to = generatePath(TICKET_PATHS.DETAILS, {
                    id: ticket.uuid,
                    type: TicketTypePath.SERVICE_REQUESTS,
                  });

                  return (
                    <Fragment key={`list-item-${ticket.uuid}`}>
                      <ListItem sx={sxProps.listItem}>
                        <RouterLink to={to}>
                          <TicketRequestCell
                            id={ticket.referenceNumber}
                            summary={ticket.summary}
                            type={ticket.ticketType}
                          />
                        </RouterLink>
                      </ListItem>
                      {idx < results.length - 1 && <Divider />}
                    </Fragment>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item sx={sxProps.footer} xs={12}>
              <Grid container justifyContent="flex-end">
                <Link component={RouterLink} to={redirectPath} underline="none">
                  <Typography color="primary" variant="button">
                    <strong>
                      {t('dashboard.widgets.latestServiceRequest.link')}
                    </strong>
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        )}
      </AsyncContentProvider>
    </Box>
  );
};
