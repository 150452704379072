import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import mapValues from 'lodash-es/mapValues';
import omitBy from 'lodash-es/omitBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { ListHook, ListState, PaginationConfig } from '../components';
import {
  ViewMode as CollectionViewMode,
  isViewMode as isCollectionViewMode,
} from '../components/Collection/collection.enum';
import type {
  CurrentTab,
  InternalParams,
  QueryParams,
} from '../components/List/list.type';
import { decodeSearchParam, encodeSearchParam } from '../helpers';

export const INITIAL_LIST_STATE: ListState<never> = {
  currentTab: undefined,
  filters: undefined,
  paginationConfig: {
    count: -1,
    currentPage: 1,
    next: null,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
    previous: null,
  },
  searchQuery: undefined,
  sort: undefined,
  viewMode: CollectionViewMode.LIST,
};

export const INITIAL_LIST_STATE_COLLECTION_VIEW: ListState<never> = {
  ...INITIAL_LIST_STATE,
  paginationConfig: {
    ...INITIAL_LIST_STATE.paginationConfig,
    pageSize: 12,
  },
};

export const useList = <
  T extends {
    count: number;
    next?: number | null;
    previous?: number | null;
  },
  TFilterValues = undefined,
>(
  enableSearchParams = false,
  initialListState: ListState<TFilterValues> = INITIAL_LIST_STATE,
  resetOnTabSearchParamChange = false,
): ListHook<T, TFilterValues> => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    filters,
    page,
    pageSize,
    search,
    sort,
    tab,
    viewMode,
    ...existingSearchParams
  } = useMemo(
    () =>
      enableSearchParams ? Object.fromEntries(searchParams.entries()) : {},
    [enableSearchParams, searchParams],
  );
  const getInitialFilters = useCallback((): TFilterValues | undefined => {
    const parsedFilters = filters
      ? decodeSearchParam<TFilterValues>(filters)
      : undefined;

    return parsedFilters !== undefined && !isEmpty(parsedFilters)
      ? { ...initialListState.filters, ...parsedFilters }
      : initialListState.filters;
  }, [initialListState.filters, filters]);
  const getInitialPaginationConfig = useCallback((): PaginationConfig => {
    return {
      ...initialListState.paginationConfig,
      ...(page && +page && { currentPage: +page }),
      ...(pageSize && +pageSize && { pageSize: +pageSize }),
    };
  }, [initialListState.paginationConfig, page, pageSize]);
  const getInitialSearchQuery = useCallback(
    () => search ?? initialListState.searchQuery,
    [initialListState.searchQuery, search],
  );
  const getInitialSort = useCallback(
    () => sort ?? initialListState.sort,
    [initialListState.sort, sort],
  );
  const getInitialTab = useCallback(
    (): CurrentTab | undefined => tab ?? initialListState.currentTab,
    [initialListState.currentTab, tab],
  );
  const getInitialViewMode = useCallback(
    () =>
      isCollectionViewMode(viewMode) ? viewMode : initialListState.viewMode,
    [initialListState.viewMode, viewMode],
  );
  const initialState = useMemo<ListState<TFilterValues>>(() => {
    const currentTab = getInitialTab();
    const filters = getInitialFilters();
    const paginationConfig = getInitialPaginationConfig();
    const searchQuery = getInitialSearchQuery();
    const sort = getInitialSort();
    const viewMode = getInitialViewMode();

    return {
      ...initialListState,
      currentTab,
      filters,
      paginationConfig,
      searchQuery,
      sort,
      viewMode,
    };
  }, [
    getInitialFilters,
    getInitialPaginationConfig,
    getInitialSearchQuery,
    getInitialSort,
    getInitialTab,
    getInitialViewMode,
    initialListState,
  ]);
  const [listState, setListState] =
    useState<ListState<TFilterValues>>(initialState);
  const setPaginationFromResponse = useCallback(
    (response: T): void => {
      const { count, next, previous } = response;

      setListState((prevState) => {
        return {
          ...prevState,
          paginationConfig: {
            ...prevState.paginationConfig,
            count,
            next: next ?? null,
            previous: previous ?? null,
          },
        };
      });
    },
    [setListState],
  );
  const internalParams = useMemo<InternalParams>(
    () => ({
      ...(listState.viewMode !== initialListState.viewMode && {
        viewMode: listState.viewMode,
      }),
    }),
    [initialListState.viewMode, listState.viewMode],
  );
  const queryParams = useMemo<QueryParams<TFilterValues>>(
    () => ({
      ...(!isEmpty(listState.filters) && { filters: listState.filters }),
      ...(listState.paginationConfig.currentPage !==
        initialListState.paginationConfig.currentPage && {
        page: listState.paginationConfig.currentPage,
      }),
      pageSize: listState.paginationConfig.pageSize,
      ...(listState.searchQuery && { search: listState.searchQuery }),
      ...(listState.sort && { sort: listState.sort }),
      ...(listState.currentTab && { tab: listState.currentTab }),
    }),
    [
      initialListState.paginationConfig.currentPage,
      listState.currentTab,
      listState.filters,
      listState.paginationConfig.currentPage,
      listState.paginationConfig.pageSize,
      listState.searchQuery,
      listState.sort,
    ],
  );
  const setLocationSearchParams = useCallback(() => {
    if (!enableSearchParams) return;

    const internalSearchParams = mapValues(internalParams, (v) =>
      encodeSearchParam(v),
    );
    const querySearchParams = mapValues(queryParams, (v) =>
      encodeSearchParam(v),
    );
    const searchParams = omitBy(
      {
        ...existingSearchParams,
        ...internalSearchParams,
        ...querySearchParams,
      },
      (value) => isNil(value),
    );

    setSearchParams(searchParams);
    // setLocationSearchParams should be triggered only on internalParams or queryParams changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalParams, queryParams]);

  useEffect(() => {
    setLocationSearchParams();
  }, [setLocationSearchParams]);

  useEffect(() => {
    if (!resetOnTabSearchParamChange) return;

    setListState((prevState) =>
      prevState.currentTab !== tab
        ? {
            ...initialListState,
            currentTab: tab,
          }
        : prevState,
    );
  }, [initialListState, resetOnTabSearchParamChange, tab]);

  return { listState, queryParams, setListState, setPaginationFromResponse };
};
