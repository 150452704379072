import { isSomePermissionIncluded } from '../../../+account';
import { isPermitted } from '../../../+auth';
import type { Permission } from '../../../+auth';
import { UserTypeEnumDTO } from '../../../../connectors/user';
import type { ColorsHex, ListTab, SelectOption } from '../../../shared';
import { palette } from '../../../shared/theme/palette';
import { getPermissionsBaseOnUserType } from './permissions.helper';

const userTypeToColorsHexMap: Record<UserTypeEnumDTO, ColorsHex> = {
  [UserTypeEnumDTO.Customer]: {
    backgroundColor: palette.fern,
    color: palette.black,
  },
  [UserTypeEnumDTO.Employee]: {
    backgroundColor: palette.pictonBlue,
    color: palette.black,
  },
  [UserTypeEnumDTO.ExternalEmployee]: {
    backgroundColor: palette.amethyst,
    color: palette.white,
  },
  [UserTypeEnumDTO.System]: {
    backgroundColor: palette.amethyst,
    color: palette.white,
  },
};

export const getUserTypeColorsHex = (userType: UserTypeEnumDTO): ColorsHex =>
  userTypeToColorsHexMap[userType] || {
    backgroundColor: palette.regentGray,
    color: palette.black,
  };

const userTypeToTranslationLabelKeyMap: Record<
  UserTypeEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [UserTypeEnumDTO.Customer]: 'user.userType.customer',
  [UserTypeEnumDTO.Employee]: 'user.userType.employee',
  [UserTypeEnumDTO.ExternalEmployee]: 'user.userType.externalEmployee',
  [UserTypeEnumDTO.System]: 'user.userType.system',
};

export const getUserTypeTranslationLabelKey = (
  userType: UserTypeEnumDTO,
): GenericTypes.TranslationLabel =>
  userTypeToTranslationLabelKeyMap[userType] || userType;

export const isUserTypeEditable = (userType: UserTypeEnumDTO): boolean =>
  userType === UserTypeEnumDTO.Customer;

export const isUserTypeSupported = (userType: UserTypeEnumDTO): boolean =>
  Object.values(UserTypeEnumDTO)
    .filter((userType) => userType !== UserTypeEnumDTO.System)
    .includes(userType);

export const getUserTypeBasedOnPermissions = (
  userType: UserTypeEnumDTO,
  permissions: Permission[],
): boolean => {
  const userTypePermissions = getPermissionsBaseOnUserType(userType);

  return isSomePermissionIncluded(permissions, userTypePermissions);
};
export const isAccessible = (
  expectedPermissions: Permission[],
  userPermissions: Permission[],
  userType: UserTypeEnumDTO,
): boolean =>
  isPermitted(expectedPermissions, userPermissions) &&
  isUserTypeEditable(userType);
export const getUserTypeListTabs = (permissions: Permission[]): ListTab[] =>
  Object.values(UserTypeEnumDTO)
    .filter((userType) => userType !== UserTypeEnumDTO.System)
    .filter((userType) => getUserTypeBasedOnPermissions(userType, permissions))
    .map((userType) => ({
      labelKey: getUserTypeTranslationLabelKey(userType),
      value: userType,
    }));

export const getUserTypeOptions = (permissions: Permission[]): SelectOption[] =>
  Object.values(UserTypeEnumDTO)
    .filter((userType) => userType !== UserTypeEnumDTO.System)
    .filter((userType) => getUserTypeBasedOnPermissions(userType, permissions))
    .map((userType) => ({
      labelKey: getUserTypeTranslationLabelKey(userType),
      value: userType,
    }));

export const isEmployee = (userType: UserTypeEnumDTO): boolean =>
  userType === UserTypeEnumDTO.Employee ||
  userType === UserTypeEnumDTO.ExternalEmployee;
