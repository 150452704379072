import type { FC, ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../+auth';
import type {
  CountrySerializerDTO,
  KYCIntegrationSerializerDTO,
} from '../../../../../connectors/company';
import { NOT_FOUND_PATH } from '../../../../routing';
import type { Country } from '../../../../shared';
import { HttpStatus, transformKeys, useApi } from '../../../../shared';
import { prospectClient } from '../../../prospect.client';
import type { ContextValue } from './context';
import { Context, initialConfig, initialCountryConfig } from './context';

type Props = {
  children: ReactNode;
};

export const Provider: FC<Props> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const { user } = useAuth();
  const fetchConfig$ = useCallback(
    (): Promise<
      {
        countryId: CountrySerializerDTO['uuid'];
        integrations: KYCIntegrationSerializerDTO[];
      }[]
    > =>
      user?.countryUuids
        ? Promise.all(
            user.countryUuids.map(async (countryId) => {
              const kycIntegrations =
                await prospectClient.getIntegrationsConfig$(countryId);

              return {
                countryId,
                integrations: kycIntegrations.results,
              };
            }, Promise.resolve([])),
          )
        : Promise.resolve([]),
    [user?.countryUuids],
  );
  const { error, response } = useApi([], fetchConfig$);
  const config = useMemo<ContextValue['config']>(
    () =>
      response.reduce(
        (config, { countryId, integrations }) => ({
          ...config,
          [countryId]: {
            integrations: integrations.map((integration) => ({
              ...integration,
              kycPlatform: transformKeys(integration.kycPlatform, 'SNAKE_CASE'),
            })),
          },
        }),
        initialConfig,
      ),
    [response],
  );
  const isNotFoundError = useMemo(
    () => error?.status === HttpStatus.NOT_FOUND,
    [error],
  );
  const getCountryConfig = useCallback(
    (country?: Country | CountrySerializerDTO['uuid']) =>
      country ? config[country as Country] : initialCountryConfig,
    [config],
  );
  const getIntegrationsConfig = useCallback(
    (country?: Country | CountrySerializerDTO['uuid']) =>
      getCountryConfig(country)?.integrations || [],
    [getCountryConfig],
  );
  const value = useMemo<ContextValue>(
    () => ({ config, getIntegrationsConfig }),
    [config, getIntegrationsConfig],
  );

  if (isNotFoundError) {
    navigate(NOT_FOUND_PATH);

    return null;
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
