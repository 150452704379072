import { ReactComponent as PinLocation1Icon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/pins/pin-location-1.svg';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles/createTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { Map } from '../Map';
import type { MapLocation } from '../Map';
import { sxProps } from './map-box.styles';

type Props = {
  address: string;
  location?: MapLocation;
};

export const MapBox: FC<Props> = (props) => {
  const { address, location } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const iconSize = useMemo(() => (lgUp ? 48 : 32), [lgUp]);

  return (
    <Box sx={sxProps.map}>
      <Box sx={sxProps.header}>
        {address && (
          <>
            <PinLocation1Icon height={iconSize} width={iconSize} />
            <Typography sx={sxProps.text} variant="body1">
              {address}
            </Typography>
          </>
        )}
      </Box>
      <Map isButton locations={[...(location ? [location] : [])]} />
    </Box>
  );
};
