import Checkbox from '@mui/material/Checkbox';
import type { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import type { FieldProps as FormikFieldProps } from 'formik/dist/Field';
import isEqual from 'lodash-es/isEqual';
import isFunction from 'lodash-es/isFunction';
import type { ChangeEvent, FC, Key } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';

import { generateClassName } from '../../../helpers';
import { useTranslation } from '../../../translations';
import { CheckboxFieldLabel, FieldLabel } from '../../components';
import { getFirstErrorKey } from '../../helpers';
import type { CheckboxProps, HtmlDataAttribute } from '../types';
import {
  CheckboxClassName,
  FormControlLabelClassName,
  sxProps,
} from './checkbox-field.styles';

type Props<TFormValues, TCheckboxValue> = FormikFieldProps<
  TCheckboxValue[],
  TFormValues
> & {
  props: CheckboxProps<TFormValues, TCheckboxValue>;
};

export const CheckboxField = <TFormValues, TCheckboxValue = string>(
  props: Props<TFormValues, TCheckboxValue>,
): ReturnType<FC<Props<TFormValues, TCheckboxValue>>> => {
  const { meta, field, form, props: fieldProps } = props;
  const { error, initialValue, touched, value = [] } = meta;
  const { name } = field;
  const { setFieldTouched, setFieldValue, values } = form;
  const {
    disabled,
    disableWhen,
    formControlSx,
    getReferenceFieldsToValuesMap,
    isFilter,
    isPictorial,
    labelKey,
    options,
    optionSize,
    readonly,
    required,
    requiredWhen,
  } = fieldProps;
  const { t } = useTranslation();
  const staticOptions = useMemo(
    () => (isFunction(options) ? options(values) : options),
    [options, values],
  );
  const finalLabelKey = useMemo(
    () => (isFunction(labelKey) ? labelKey(values) : labelKey),
    [labelKey, values],
  );
  const errorMessageKey = useMemo(
    () => touched && getFirstErrorKey(error),
    [error, touched],
  );
  const isDisabled = useMemo(
    () => Boolean(disabled || readonly || (disableWhen && disableWhen(values))),
    [disableWhen, disabled, readonly, values],
  );
  const isError = useMemo(() => Boolean(errorMessageKey), [errorMessageKey]);
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );
  const handleBlur = useCallback((): void => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const valueIndex = value.indexOf(
      event.target.name as unknown as TCheckboxValue,
    );
    const newValue =
      valueIndex < 0
        ? [...value, event.target.name]
        : [...value.slice(0, valueIndex), ...value.slice(valueIndex + 1)];

    setFieldValue(name, newValue);
  };
  const checkboxClassName = useMemo(
    () =>
      generateClassName([
        isPictorial && CheckboxClassName.PICTORIAL_CONTROL,
        isFilter && CheckboxClassName.FILTERED_FIELD,
      ]),
    [isFilter, isPictorial],
  );

  useEffect(() => {
    if (!getReferenceFieldsToValuesMap) return;

    if (!touched && isEqual(initialValue, value)) {
      return;
    }

    const referenceFieldsToValuesMap = getReferenceFieldsToValuesMap(values);

    Object.entries(referenceFieldsToValuesMap).forEach(([fieldName, value]) => {
      setFieldValue(fieldName, value);
    });
    // has to be triggered only on the field value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getReferenceFieldsToValuesMap,
    initialValue,
    name,
    setFieldTouched,
    setFieldValue,
    touched,
    value,
  ]);

  return (
    <FormControl
      component="fieldset"
      disabled={isDisabled}
      error={isError}
      fullWidth
      required={isRequired && !readonly}
      sx={formControlSx}
    >
      <FieldLabel isShrink={false} labelKey={finalLabelKey} />
      <Grid
        container
        component={FormGroup}
        {...(isPictorial && { spacing: 3 })}
      >
        {staticOptions.map((option) => {
          const {
            disabled,
            label,
            labelKey,
            name,
            pictureURL,
            subLabel,
            subLabelKey,
          } = option;
          const isChecked = value.includes(name);
          const formControlLabelClassName = generateClassName([
            isPictorial && FormControlLabelClassName.PICTORIAL,
            isChecked &&
              isPictorial &&
              FormControlLabelClassName.PICTORIAL_CHECKED,
          ]);

          return (
            <Grid
              {...(optionSize || { xs: 12 })}
              item
              key={name as unknown as Key}
            >
              <FormControlLabel
                className={formControlLabelClassName}
                control={
                  <Checkbox
                    checked={isChecked}
                    className={checkboxClassName}
                    disabled={disabled}
                    inputProps={
                      {
                        'data-testid': name as unknown as string,
                      } as HtmlDataAttribute<MuiCheckboxProps['inputProps']>
                    }
                    name={name as unknown as string}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={sxProps.checkbox}
                    {...(isPictorial && { color: 'secondary', size: 'small' })}
                  />
                }
                label={
                  <CheckboxFieldLabel
                    label={label}
                    labelKey={labelKey}
                    pictureURL={pictureURL}
                    subLabel={subLabel}
                    subLabelKey={subLabelKey}
                  />
                }
                sx={sxProps.formControlLabel}
              />
            </Grid>
          );
        })}
      </Grid>
      {isError && (
        <FormHelperText error>{t(errorMessageKey || '')}</FormHelperText>
      )}
    </FormControl>
  );
};
