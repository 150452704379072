import { ReactComponent as ArrowRightIcon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-right.svg';
import { ReactComponent as Check1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/check-1.svg';
import { ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import { getUserFieldNameIcon, userClient } from '../../../../+user';
import type { GeneralRequestDetailSerializerDTO } from '../../../../../connectors/ticket';
import type { DetailedUserSerializerDTO } from '../../../../../connectors/user';
import { Feature, useAsync, useTranslation } from '../../../../shared';
import { ticketClient } from '../../../ticket.client';
import type { DetailsData } from '../../types';
import {
  getDataDifferences,
  isDataNotEqual as isDataNotEqualHelper,
} from './profile-update-ticket.helper';
import { sxProps } from './profile-update-ticket.styles';
import type { ProfileUpdateTicketData } from './profile-update-ticket.type';

export const ProfileUpdateTicket: FC = () => {
  const { t } = useTranslation();
  const { response, setResponse } =
    useAsync<DetailsData<GeneralRequestDetailSerializerDTO>>();
  const [isFetching, setFetching] = useState(false);
  const newData = useMemo<ProfileUpdateTicketData | undefined>(() => {
    if (!response.description) return undefined;

    try {
      const { phone, ...data } = JSON.parse(response.description);

      return { ...data, phoneNumber: phone };
    } catch {
      return undefined;
    }
  }, [response.description]);
  const currentData = useMemo<ProfileUpdateTicketData>(() => {
    const { email, firstName, lastName, phoneNumber } =
      response.entity as DetailedUserSerializerDTO;

    return { email, firstName, lastName, phoneNumber };
  }, [response.entity]);
  const isDataNotEqual = useMemo(
    () => isDataNotEqualHelper(currentData, newData),
    [currentData, newData],
  );
  const dataDifferences = useMemo(
    () => getDataDifferences(currentData, newData),
    [currentData, newData],
  );
  const handleChangeData = useCallback(async () => {
    if (!isDataNotEqual || !newData) return;

    setFetching(true);

    try {
      const comment = t('ticket.details.profileUpdateTicket.comment');
      const [data] = await Promise.all([
        userClient.update$(response.entity.uuid, newData),
        ticketClient.addComment$(response.uuid, comment),
      ]);

      if (response.entity.uuid === response.reporter.uuid) {
        setResponse((prevState) => ({
          ...prevState,
          entity: { ...prevState.entity, ...data },
          reporter: { ...prevState.reporter, ...data },
        }));
      }
    } catch (e) {
      toast.error(t('errors.general.message'));
    } finally {
      setFetching(false);
    }
  }, [
    isDataNotEqual,
    newData,
    response.entity.uuid,
    response.reporter.uuid,
    response.uuid,
    setResponse,
    t,
  ]);
  const buttonSxProps = useMemo(
    () => ({
      ...sxProps.button,
      ...(!isDataNotEqual && sxProps.buttonAccepted),
    }),
    [isDataNotEqual],
  );
  const renderFeatures = useCallback(
    (data: Partial<ProfileUpdateTicketData>, isNewValue = true) =>
      Object.entries(data).map(([k, value]) => {
        const key = k as keyof ProfileUpdateTicketData;
        const Icon = getUserFieldNameIcon(key);
        const uniqKey = `${isNewValue ? 'new' : 'old'}-value-${key}`;

        return (
          <Box sx={sxProps.feature} key={uniqKey}>
            <Feature
              dataTestId="ticket-update-profile"
              icon={Icon && <Icon height={24} width={24} />}
              titleKey={`user.fields.${key}`}
              value={isNewValue ? value : currentData[key]}
            />
          </Box>
        );
      }),
    [currentData],
  );

  return (
    <>
      <Box sx={sxProps.header}>
        <Typography mb={0} variant="h2">
          {t('ticket.details.labels.changes')}
        </Typography>
        <ButtonBase
          disabled={isFetching || !isDataNotEqual}
          onClick={handleChangeData}
          sx={buttonSxProps}
        >
          <Typography fontWeight="bold" sx={sxProps.text}>
            {t(
              isDataNotEqual
                ? 'ticket.details.profileUpdateTicket.accept'
                : 'ticket.details.profileUpdateTicket.accepted',
            )}
          </Typography>
          <Check1Icon height={16} width={16} />
        </ButtonBase>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {renderFeatures(
            isDataNotEqual ? dataDifferences : currentData,
            false,
          )}
        </Grid>
        {isDataNotEqual && (
          <Grid item xs={6}>
            <Box sx={sxProps.box}>
              <Box sx={sxProps.arrow}>
                <ArrowRightIcon height={16} width={16} />
              </Box>
              {renderFeatures(dataDifferences)}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
