import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import type { FC } from 'react';
import React, { useState } from 'react';

import { Spinner } from '../../Spinner/spinner.component';
import type { Image } from '../carousel.type';

type Props = Omit<Image, 'id'> & { sx: BoxProps['sx'] };

export const ImagePreview: FC<Props> = (props) => {
  const { name, url, sx } = props;
  const [isLoaded, setLoaded] = useState(false);

  return (
    <Box sx={sx}>
      <img alt={name} onLoad={() => setLoaded(true)} src={url} />
      {!isLoaded && <Spinner />}
    </Box>
  );
};
