import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { EntityEnumDTO } from '../../../../../../connectors/property';
import { FormFieldset } from '../../../../../shared/form/components';
import {
  useAdvertisementConfig,
  useAdvertisementForm,
} from '../../../providers';
import { convertToGalleryItem } from '../form.helper';
import type { FormValues } from '../form.types';
import {
  DEFAULT_MAX_SELECTED_ITEMS,
  getFieldsetConfig,
} from './gallery-step.model';

export const GalleryStep: FC = () => {
  const { dynamicValidationRules } = useAdvertisementConfig();
  const { property, propertyObject, unit } = useAdvertisementForm();
  const { values } = useFormikContext<FormValues>();
  const getData$ = useCallback(
    () => {
      if (values.attachments.length > 0)
        return Promise.resolve(values.attachments);
      const maxSelectedItems =
        dynamicValidationRules?.attachments?.max ?? DEFAULT_MAX_SELECTED_ITEMS;

      const images = [
        ...(unit?.attachments.results || []).map((attachment) =>
          convertToGalleryItem(attachment, EntityEnumDTO.Unit),
        ),
        ...(propertyObject?.attachments.results || []).map((attachment) =>
          convertToGalleryItem(attachment, EntityEnumDTO.PropertyObject),
        ),
        ...(property?.attachments.results || []).map((attachment) =>
          convertToGalleryItem(attachment, EntityEnumDTO.Property),
        ),
      ].map((image, idx) => ({
        ...image,
        isSelected: idx < maxSelectedItems,
      }));

      return Promise.resolve(images);
    },
    // prevent rerender on attachments change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [property?.attachments, propertyObject?.attachments, unit?.attachments],
  );
  const fieldsetConfig = useMemo(() => getFieldsetConfig(getData$), [getData$]);

  return (
    <Box>
      <Grid container spacing={3}>
        {fieldsetConfig.map((config) => (
          <FormFieldset<FormValues>
            config={config}
            key={`fieldset-${config.key}`}
            values={values}
          />
        ))}
      </Grid>
    </Box>
  );
};
