import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import Box from '@mui/material/Box';
import type { ToggleButtonProps } from '@mui/material/ToggleButton';
import ToggleButton from '@mui/material/ToggleButton';
import type { TextFormatType } from 'lexical';
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from 'lexical';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { FORMAT_BUTTONS } from '../../editor.const';
import type { BlockType, FormatType } from '../../editor.type';
import { sxProps } from './toolbar.styles';

const SELECTED_BUTTON_INITIAL_VALUE: Partial<Record<FormatType, boolean>> = {
  bold: false,
  bullet: false,
  italic: false,
  number: false,
  underline: false,
};

type Props = {
  isDisabled: boolean;
};

export const Toolbar: FC<Props> = (props) => {
  const { isDisabled } = props;
  const [editor] = useLexicalComposerContext();
  const [isSelectedButton, setSelectedButton] = useState(
    SELECTED_BUTTON_INITIAL_VALUE,
  );
  const [blockType, setBlockType] = useState<BlockType>('paragraph');
  const handleClick: ToggleButtonProps['onChange'] = useCallback(
    (event) => {
      const { value } = event.currentTarget;

      if (['bullet', 'number'].includes(value)) {
        if (value === 'bullet') {
          const command =
            blockType !== 'ul'
              ? INSERT_UNORDERED_LIST_COMMAND
              : REMOVE_LIST_COMMAND;

          editor.dispatchCommand(command, undefined);
        }

        if (value === 'number') {
          const command =
            blockType !== 'ol'
              ? INSERT_ORDERED_LIST_COMMAND
              : REMOVE_LIST_COMMAND;

          editor.dispatchCommand(command, undefined);
        }
      } else {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, value);
      }
      setSelectedButton((prevState) => ({
        ...prevState,
        [value]: !prevState[value as TextFormatType],
      }));
    },
    [blockType, editor],
  );
  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode,
          );
          const type = parentList ? parentList.getTag() : element.getTag();

          setBlockType(type);
          setSelectedButton((prevState) => ({
            ...prevState,
            bullet: type === 'ul',
            number: type === 'ol',
          }));
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();

          setBlockType(type);
        }

        setSelectedButton((prevState) => ({
          ...prevState,
          bold: selection.hasFormat('bold'),
          italic: selection.hasFormat('italic'),
          underline: selection.hasFormat('underline'),
        }));
      }
    }
  }, [editor]);

  useEffect(() => {
    if (isDisabled) return;

    mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
    );
  }, [editor, isDisabled, updateToolbar]);

  return (
    <Box sx={sxProps.toolbar}>
      {FORMAT_BUTTONS.map(({ icon: Icon, value }) => (
        <ToggleButton
          aria-label={value}
          disabled={isDisabled}
          key={value}
          onClick={handleClick}
          selected={isSelectedButton[value]}
          sx={sxProps.button}
          value={value}
        >
          <Icon height={16} width={16} />
        </ToggleButton>
      ))}
    </Box>
  );
};
