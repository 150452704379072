import Box from '@mui/material/Box';
import type { FC } from 'react';
import React, { useMemo, useState } from 'react';

import { useAuth } from '../../../+auth';
import { getSections } from '../../../routing/nav-sections.const';
import { Section } from '../NavSection/section.component';

export const Nav: FC = () => {
  const { isUserFetched, user, logout } = useAuth();
  const sections = useMemo(
    () => getSections(isUserFetched, user, logout),
    [isUserFetched, logout, user],
  );
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box p={0}>
      {sections.map((section) => (
        <Section
          dataTestId={section.dataTestId}
          expanded={expanded}
          setExpanded={setExpanded}
          handleChange={handleChange}
          key={section.titleKey}
          titleKey={section.titleKey}
          icon={section.icon}
          items={section.items}
        />
      ))}
    </Box>
  );
};
