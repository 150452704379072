import type { BoxProps } from '@mui/material/Box';
import MUIBox from '@mui/material/Box';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC, ReactNode } from 'react';
import React, { useMemo } from 'react';

import { sxProps } from './box.styles';

type Props = Pick<BoxProps, 'sx'> & {
  children: NonNullable<ReactNode>;
  size?: 'normal' | 'small';
};

export const Box: FC<Props> = (props) => {
  const { children, size = 'normal', sx } = props;
  const mergedSxProps = useMemo<SxProps>(
    () => ({ ...sxProps.container, ...sxProps[size], ...sx }),
    [size, sx],
  );

  return <MUIBox sx={mergedSxProps}>{children}</MUIBox>;
};
