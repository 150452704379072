/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttachmentCategoryEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentTypeEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentVisibilityEnumDTO } from '../dto';
// @ts-ignore
import { CommonProcessSerializersAttachmentAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ContactSerializerDTO } from '../dto';
// @ts-ignore
import { CoordinatesSerializerDTO } from '../dto';
// @ts-ignore
import { CreateContactSerializerDTO } from '../dto';
// @ts-ignore
import { CreateUnitSerializerDTO } from '../dto';
// @ts-ignore
import { DetailedUnitSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { ListAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ListContactSerializerDTO } from '../dto';
// @ts-ignore
import { ListLogSerializerDTO } from '../dto';
// @ts-ignore
import { ListTenantSerializerDTO } from '../dto';
// @ts-ignore
import { ListUnitsSerializerDTO } from '../dto';
// @ts-ignore
import { PatchUnitSerializerDTO } from '../dto';
// @ts-ignore
import { UnitSerializerDTO } from '../dto';
// @ts-ignore
import { UnitStatusEnumDTO } from '../dto';
/**
 * UnitsAPI - axios parameter creator
 * @export
 */
export const UnitsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Contact For Unit
         * @param {string} unitUuid 
         * @param {CreateContactSerializerDTO} createContactSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContactForUnitUnitsUnitUuidContactsPost: async (unitUuid: string, createContactSerializerDTO: CreateContactSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('addContactForUnitUnitsUnitUuidContactsPost', 'unitUuid', unitUuid)
            // verify required parameter 'createContactSerializerDTO' is not null or undefined
            assertParamExists('addContactForUnitUnitsUnitUuidContactsPost', 'createContactSerializerDTO', createContactSerializerDTO)
            const localVarPath = `/units/{unit_uuid}/contacts`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContactSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Unit
         * @param {CreateUnitSerializerDTO} createUnitSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnitUnitsPost: async (createUnitSerializerDTO: CreateUnitSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUnitSerializerDTO' is not null or undefined
            assertParamExists('createUnitUnitsPost', 'createUnitSerializerDTO', createUnitSerializerDTO)
            const localVarPath = `/units/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUnitSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Contact For Unit
         * @param {string} unitUuid 
         * @param {string} contactUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactForUnitUnitsUnitUuidContactsContactUuidDelete: async (unitUuid: string, contactUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('deleteContactForUnitUnitsUnitUuidContactsContactUuidDelete', 'unitUuid', unitUuid)
            // verify required parameter 'contactUuid' is not null or undefined
            assertParamExists('deleteContactForUnitUnitsUnitUuidContactsContactUuidDelete', 'contactUuid', contactUuid)
            const localVarPath = `/units/{unit_uuid}/contacts/{contact_uuid}`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)))
                .replace(`{${"contact_uuid"}}`, encodeURIComponent(String(contactUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Unit Attachment
         * @param {string} unitUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete: async (unitUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('deleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete', 'unitUuid', unitUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('deleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/units/{unit_uuid}/attachments/{attachment_uuid}`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Unit
         * @param {string} unitUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnitUnitsUnitUuidDelete: async (unitUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('deleteUnitUnitsUnitUuidDelete', 'unitUuid', unitUuid)
            const localVarPath = `/units/{unit_uuid}`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Unit Attachment
         * @param {string} unitUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet: async (unitUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('downloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet', 'unitUuid', unitUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('downloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/units/{unit_uuid}/attachments/{attachment_uuid}/download`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Contacts For Unit
         * @param {string} unitUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactsForUnitUnitsUnitUuidContactsGet: async (unitUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, role?: string, excludeRole?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('getContactsForUnitUnitsUnitUuidContactsGet', 'unitUuid', unitUuid)
            const localVarPath = `/units/{unit_uuid}/contacts`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (excludeRole !== undefined) {
                localVarQueryParameter['exclude_role'] = excludeRole;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Unit Auditlogs
         * @param {string} unitUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet: async (unitUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('getSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet', 'unitUuid', unitUuid)
            const localVarPath = `/units/{unit_uuid}/auditlogs`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (userUuid !== undefined) {
                localVarQueryParameter['user_uuid'] = userUuid;
            }

            if (excludeFields !== undefined) {
                localVarQueryParameter['exclude_fields'] = excludeFields;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Unit
         * @param {string} unitUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleUnitUnitsUnitUuidGet: async (unitUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('getSingleUnitUnitsUnitUuidGet', 'unitUuid', unitUuid)
            const localVarPath = `/units/{unit_uuid}`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tenants For Unit
         * @param {string} unitUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantsForUnitUnitsUnitUuidTenantsGet: async (unitUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, email?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('getTenantsForUnitUnitsUnitUuidTenantsGet', 'unitUuid', unitUuid)
            const localVarPath = `/units/{unit_uuid}/tenants`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['first_name'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['last_name'] = lastName;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Unit Attachment
         * @param {string} unitUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitAttachmentUnitsUnitUuidAttachmentsGet: async (unitUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('getUnitAttachmentUnitsUnitUuidAttachmentsGet', 'unitUuid', unitUuid)
            const localVarPath = `/units/{unit_uuid}/attachments`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Units Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitsCoordinatesListUnitsCoordinatesGet: async (search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/units/coordinates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Units
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyObjectUuid] 
         * @param {string} [propertyUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitsUnitsGet: async (page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyObjectUuid?: string, propertyUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/units/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (propertyObjectUuid !== undefined) {
                localVarQueryParameter['property_object_uuid'] = propertyObjectUuid;
            }

            if (propertyUuid !== undefined) {
                localVarQueryParameter['property_uuid'] = propertyUuid;
            }

            if (entranceUuid !== undefined) {
                localVarQueryParameter['entrance_uuid'] = entranceUuid;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postal_code'] = postalCode;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (excludeCategory !== undefined) {
                localVarQueryParameter['exclude_category'] = excludeCategory;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (contactUuid !== undefined) {
                localVarQueryParameter['contact_uuid'] = contactUuid;
            }

            if (companyUuid !== undefined) {
                localVarQueryParameter['company_uuid'] = companyUuid;
            }

            if (businessRegion !== undefined) {
                localVarQueryParameter['business_region'] = businessRegion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Unit
         * @param {string} unitUuid 
         * @param {PatchUnitSerializerDTO} patchUnitSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUnitUnitsUnitUuidPatch: async (unitUuid: string, patchUnitSerializerDTO: PatchUnitSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('patchUnitUnitsUnitUuidPatch', 'unitUuid', unitUuid)
            // verify required parameter 'patchUnitSerializerDTO' is not null or undefined
            assertParamExists('patchUnitUnitsUnitUuidPatch', 'patchUnitSerializerDTO', patchUnitSerializerDTO)
            const localVarPath = `/units/{unit_uuid}`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUnitSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Unit
         * @param {string} unitUuid 
         * @param {CreateUnitSerializerDTO} createUnitSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUnitUnitsUnitUuidPut: async (unitUuid: string, createUnitSerializerDTO: CreateUnitSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('putUnitUnitsUnitUuidPut', 'unitUuid', unitUuid)
            // verify required parameter 'createUnitSerializerDTO' is not null or undefined
            assertParamExists('putUnitUnitsUnitUuidPut', 'createUnitSerializerDTO', createUnitSerializerDTO)
            const localVarPath = `/units/{unit_uuid}`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUnitSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Unit Attachment
         * @param {string} unitUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUnitAttachmentUnitsUnitUuidAttachmentsPost: async (unitUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'unitUuid' is not null or undefined
            assertParamExists('uploadUnitAttachmentUnitsUnitUuidAttachmentsPost', 'unitUuid', unitUuid)
            // verify required parameter 'attachmentCategory' is not null or undefined
            assertParamExists('uploadUnitAttachmentUnitsUnitUuidAttachmentsPost', 'attachmentCategory', attachmentCategory)
            // verify required parameter 'attachmentType' is not null or undefined
            assertParamExists('uploadUnitAttachmentUnitsUnitUuidAttachmentsPost', 'attachmentType', attachmentType)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadUnitAttachmentUnitsUnitUuidAttachmentsPost', 'files', files)
            const localVarPath = `/units/{unit_uuid}/attachments`
                .replace(`{${"unit_uuid"}}`, encodeURIComponent(String(unitUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


            if (attachmentCategory !== undefined) { 
                localVarFormParams.append('attachment_category', new Blob([JSON.stringify(attachmentCategory)], { type: "application/json", }));
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', new Blob([JSON.stringify(attachmentType)], { type: "application/json", }));
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (visibility !== undefined) { 
                localVarFormParams.append('visibility', new Blob([JSON.stringify(visibility)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitsAPI - functional programming interface
 * @export
 */
export const UnitsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Contact For Unit
         * @param {string} unitUuid 
         * @param {CreateContactSerializerDTO} createContactSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContactForUnitUnitsUnitUuidContactsPost(unitUuid: string, createContactSerializerDTO: CreateContactSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addContactForUnitUnitsUnitUuidContactsPost(unitUuid, createContactSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Unit
         * @param {CreateUnitSerializerDTO} createUnitSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUnitUnitsPost(createUnitSerializerDTO: CreateUnitSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUnitUnitsPost(createUnitSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Contact For Unit
         * @param {string} unitUuid 
         * @param {string} contactUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContactForUnitUnitsUnitUuidContactsContactUuidDelete(unitUuid: string, contactUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContactForUnitUnitsUnitUuidContactsContactUuidDelete(unitUuid, contactUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Unit Attachment
         * @param {string} unitUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete(unitUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete(unitUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Unit
         * @param {string} unitUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUnitUnitsUnitUuidDelete(unitUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUnitUnitsUnitUuidDelete(unitUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Unit Attachment
         * @param {string} unitUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet(unitUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet(unitUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Contacts For Unit
         * @param {string} unitUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactsForUnitUnitsUnitUuidContactsGet(unitUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, role?: string, excludeRole?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListContactSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactsForUnitUnitsUnitUuidContactsGet(unitUuid, updatedAtLte, updatedAtGt, search, role, excludeRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Unit Auditlogs
         * @param {string} unitUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet(unitUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLogSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet(unitUuid, userUuid, excludeFields, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Unit
         * @param {string} unitUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleUnitUnitsUnitUuidGet(unitUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedUnitSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleUnitUnitsUnitUuidGet(unitUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Tenants For Unit
         * @param {string} unitUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantsForUnitUnitsUnitUuidTenantsGet(unitUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, email?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTenantSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantsForUnitUnitsUnitUuidTenantsGet(unitUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, firstName, lastName, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Unit Attachment
         * @param {string} unitUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnitAttachmentUnitsUnitUuidAttachmentsGet(unitUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnitAttachmentUnitsUnitUuidAttachmentsGet(unitUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Units Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnitsCoordinatesListUnitsCoordinatesGet(search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoordinatesSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnitsCoordinatesListUnitsCoordinatesGet(search, countryUuid, regionUuid, cityUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Units
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyObjectUuid] 
         * @param {string} [propertyUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnitsUnitsGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyObjectUuid?: string, propertyUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUnitsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnitsUnitsGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, propertyObjectUuid, propertyUuid, entranceUuid, countryUuid, regionUuid, cityUuid, postalCode, category, excludeCategory, status, contactUuid, companyUuid, businessRegion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Unit
         * @param {string} unitUuid 
         * @param {PatchUnitSerializerDTO} patchUnitSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUnitUnitsUnitUuidPatch(unitUuid: string, patchUnitSerializerDTO: PatchUnitSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUnitUnitsUnitUuidPatch(unitUuid, patchUnitSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Unit
         * @param {string} unitUuid 
         * @param {CreateUnitSerializerDTO} createUnitSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUnitUnitsUnitUuidPut(unitUuid: string, createUnitSerializerDTO: CreateUnitSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnitSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUnitUnitsUnitUuidPut(unitUuid, createUnitSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Unit Attachment
         * @param {string} unitUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadUnitAttachmentUnitsUnitUuidAttachmentsPost(unitUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommonProcessSerializersAttachmentAttachmentSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadUnitAttachmentUnitsUnitUuidAttachmentsPost(unitUuid, attachmentCategory, attachmentType, files, title, description, visibility, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitsAPI - factory interface
 * @export
 */
export const UnitsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Add Contact For Unit
         * @param {string} unitUuid 
         * @param {CreateContactSerializerDTO} createContactSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContactForUnitUnitsUnitUuidContactsPost(unitUuid: string, createContactSerializerDTO: CreateContactSerializerDTO, options?: any): AxiosPromise<ContactSerializerDTO> {
            return localVarFp.addContactForUnitUnitsUnitUuidContactsPost(unitUuid, createContactSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Unit
         * @param {CreateUnitSerializerDTO} createUnitSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUnitUnitsPost(createUnitSerializerDTO: CreateUnitSerializerDTO, options?: any): AxiosPromise<UnitSerializerDTO> {
            return localVarFp.createUnitUnitsPost(createUnitSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Contact For Unit
         * @param {string} unitUuid 
         * @param {string} contactUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactForUnitUnitsUnitUuidContactsContactUuidDelete(unitUuid: string, contactUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContactForUnitUnitsUnitUuidContactsContactUuidDelete(unitUuid, contactUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Unit Attachment
         * @param {string} unitUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete(unitUuid: string, attachmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete(unitUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Unit
         * @param {string} unitUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnitUnitsUnitUuidDelete(unitUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUnitUnitsUnitUuidDelete(unitUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Unit Attachment
         * @param {string} unitUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet(unitUuid: string, attachmentUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet(unitUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Contacts For Unit
         * @param {string} unitUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactsForUnitUnitsUnitUuidContactsGet(unitUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, role?: string, excludeRole?: string, options?: any): AxiosPromise<ListContactSerializerDTO> {
            return localVarFp.getContactsForUnitUnitsUnitUuidContactsGet(unitUuid, updatedAtLte, updatedAtGt, search, role, excludeRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Unit Auditlogs
         * @param {string} unitUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet(unitUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ListLogSerializerDTO> {
            return localVarFp.getSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet(unitUuid, userUuid, excludeFields, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Unit
         * @param {string} unitUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleUnitUnitsUnitUuidGet(unitUuid: string, options?: any): AxiosPromise<DetailedUnitSerializerDTO> {
            return localVarFp.getSingleUnitUnitsUnitUuidGet(unitUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tenants For Unit
         * @param {string} unitUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantsForUnitUnitsUnitUuidTenantsGet(unitUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, email?: string, options?: any): AxiosPromise<ListTenantSerializerDTO> {
            return localVarFp.getTenantsForUnitUnitsUnitUuidTenantsGet(unitUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, firstName, lastName, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Unit Attachment
         * @param {string} unitUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitAttachmentUnitsUnitUuidAttachmentsGet(unitUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): AxiosPromise<ListAttachmentSerializerDTO> {
            return localVarFp.getUnitAttachmentUnitsUnitUuidAttachmentsGet(unitUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Units Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitsCoordinatesListUnitsCoordinatesGet(search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options?: any): AxiosPromise<Array<CoordinatesSerializerDTO>> {
            return localVarFp.getUnitsCoordinatesListUnitsCoordinatesGet(search, countryUuid, regionUuid, cityUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Units
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyObjectUuid] 
         * @param {string} [propertyUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitsUnitsGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyObjectUuid?: string, propertyUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options?: any): AxiosPromise<ListUnitsSerializerDTO> {
            return localVarFp.getUnitsUnitsGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, propertyObjectUuid, propertyUuid, entranceUuid, countryUuid, regionUuid, cityUuid, postalCode, category, excludeCategory, status, contactUuid, companyUuid, businessRegion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Unit
         * @param {string} unitUuid 
         * @param {PatchUnitSerializerDTO} patchUnitSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUnitUnitsUnitUuidPatch(unitUuid: string, patchUnitSerializerDTO: PatchUnitSerializerDTO, options?: any): AxiosPromise<UnitSerializerDTO> {
            return localVarFp.patchUnitUnitsUnitUuidPatch(unitUuid, patchUnitSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Unit
         * @param {string} unitUuid 
         * @param {CreateUnitSerializerDTO} createUnitSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUnitUnitsUnitUuidPut(unitUuid: string, createUnitSerializerDTO: CreateUnitSerializerDTO, options?: any): AxiosPromise<UnitSerializerDTO> {
            return localVarFp.putUnitUnitsUnitUuidPut(unitUuid, createUnitSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Unit Attachment
         * @param {string} unitUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUnitAttachmentUnitsUnitUuidAttachmentsPost(unitUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): AxiosPromise<Array<CommonProcessSerializersAttachmentAttachmentSerializerDTO>> {
            return localVarFp.uploadUnitAttachmentUnitsUnitUuidAttachmentsPost(unitUuid, attachmentCategory, attachmentType, files, title, description, visibility, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addContactForUnitUnitsUnitUuidContactsPost operation in UnitsAPI.
 * @export
 * @interface UnitsAPIAddContactForUnitUnitsUnitUuidContactsPostRequest
 */
export interface UnitsAPIAddContactForUnitUnitsUnitUuidContactsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIAddContactForUnitUnitsUnitUuidContactsPost
     */
    readonly unitUuid: string

    /**
     * 
     * @type {CreateContactSerializerDTO}
     * @memberof UnitsAPIAddContactForUnitUnitsUnitUuidContactsPost
     */
    readonly createContactSerializerDTO: CreateContactSerializerDTO
}

/**
 * Request parameters for createUnitUnitsPost operation in UnitsAPI.
 * @export
 * @interface UnitsAPICreateUnitUnitsPostRequest
 */
export interface UnitsAPICreateUnitUnitsPostRequest {
    /**
     * 
     * @type {CreateUnitSerializerDTO}
     * @memberof UnitsAPICreateUnitUnitsPost
     */
    readonly createUnitSerializerDTO: CreateUnitSerializerDTO
}

/**
 * Request parameters for deleteContactForUnitUnitsUnitUuidContactsContactUuidDelete operation in UnitsAPI.
 * @export
 * @interface UnitsAPIDeleteContactForUnitUnitsUnitUuidContactsContactUuidDeleteRequest
 */
export interface UnitsAPIDeleteContactForUnitUnitsUnitUuidContactsContactUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIDeleteContactForUnitUnitsUnitUuidContactsContactUuidDelete
     */
    readonly unitUuid: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIDeleteContactForUnitUnitsUnitUuidContactsContactUuidDelete
     */
    readonly contactUuid: string
}

/**
 * Request parameters for deleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete operation in UnitsAPI.
 * @export
 * @interface UnitsAPIDeleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDeleteRequest
 */
export interface UnitsAPIDeleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIDeleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete
     */
    readonly unitUuid: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIDeleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for deleteUnitUnitsUnitUuidDelete operation in UnitsAPI.
 * @export
 * @interface UnitsAPIDeleteUnitUnitsUnitUuidDeleteRequest
 */
export interface UnitsAPIDeleteUnitUnitsUnitUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIDeleteUnitUnitsUnitUuidDelete
     */
    readonly unitUuid: string
}

/**
 * Request parameters for downloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet operation in UnitsAPI.
 * @export
 * @interface UnitsAPIDownloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGetRequest
 */
export interface UnitsAPIDownloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIDownloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly unitUuid: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIDownloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for getContactsForUnitUnitsUnitUuidContactsGet operation in UnitsAPI.
 * @export
 * @interface UnitsAPIGetContactsForUnitUnitsUnitUuidContactsGetRequest
 */
export interface UnitsAPIGetContactsForUnitUnitsUnitUuidContactsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetContactsForUnitUnitsUnitUuidContactsGet
     */
    readonly unitUuid: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetContactsForUnitUnitsUnitUuidContactsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetContactsForUnitUnitsUnitUuidContactsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetContactsForUnitUnitsUnitUuidContactsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetContactsForUnitUnitsUnitUuidContactsGet
     */
    readonly role?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetContactsForUnitUnitsUnitUuidContactsGet
     */
    readonly excludeRole?: string
}

/**
 * Request parameters for getSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet operation in UnitsAPI.
 * @export
 * @interface UnitsAPIGetSingleUnitAuditlogsUnitsUnitUuidAuditlogsGetRequest
 */
export interface UnitsAPIGetSingleUnitAuditlogsUnitsUnitUuidAuditlogsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet
     */
    readonly unitUuid: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet
     */
    readonly userUuid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet
     */
    readonly excludeFields?: string

    /**
     * 
     * @type {number}
     * @memberof UnitsAPIGetSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UnitsAPIGetSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getSingleUnitUnitsUnitUuidGet operation in UnitsAPI.
 * @export
 * @interface UnitsAPIGetSingleUnitUnitsUnitUuidGetRequest
 */
export interface UnitsAPIGetSingleUnitUnitsUnitUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetSingleUnitUnitsUnitUuidGet
     */
    readonly unitUuid: string
}

/**
 * Request parameters for getTenantsForUnitUnitsUnitUuidTenantsGet operation in UnitsAPI.
 * @export
 * @interface UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGetRequest
 */
export interface UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGet
     */
    readonly unitUuid: string

    /**
     * 
     * @type {number}
     * @memberof UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGet
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGet
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGet
     */
    readonly email?: string
}

/**
 * Request parameters for getUnitAttachmentUnitsUnitUuidAttachmentsGet operation in UnitsAPI.
 * @export
 * @interface UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGetRequest
 */
export interface UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGet
     */
    readonly unitUuid: string

    /**
     * 
     * @type {number}
     * @memberof UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGet
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGet
     */
    readonly type?: string
}

/**
 * Request parameters for getUnitsCoordinatesListUnitsCoordinatesGet operation in UnitsAPI.
 * @export
 * @interface UnitsAPIGetUnitsCoordinatesListUnitsCoordinatesGetRequest
 */
export interface UnitsAPIGetUnitsCoordinatesListUnitsCoordinatesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsCoordinatesListUnitsCoordinatesGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsCoordinatesListUnitsCoordinatesGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsCoordinatesListUnitsCoordinatesGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsCoordinatesListUnitsCoordinatesGet
     */
    readonly cityUuid?: string
}

/**
 * Request parameters for getUnitsUnitsGet operation in UnitsAPI.
 * @export
 * @interface UnitsAPIGetUnitsUnitsGetRequest
 */
export interface UnitsAPIGetUnitsUnitsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly propertyObjectUuid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly propertyUuid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly entranceUuid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly cityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly postalCode?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly excludeCategory?: string

    /**
     * 
     * @type {UnitStatusEnumDTO}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly status?: UnitStatusEnumDTO

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly contactUuid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly companyUuid?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIGetUnitsUnitsGet
     */
    readonly businessRegion?: string
}

/**
 * Request parameters for patchUnitUnitsUnitUuidPatch operation in UnitsAPI.
 * @export
 * @interface UnitsAPIPatchUnitUnitsUnitUuidPatchRequest
 */
export interface UnitsAPIPatchUnitUnitsUnitUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIPatchUnitUnitsUnitUuidPatch
     */
    readonly unitUuid: string

    /**
     * 
     * @type {PatchUnitSerializerDTO}
     * @memberof UnitsAPIPatchUnitUnitsUnitUuidPatch
     */
    readonly patchUnitSerializerDTO: PatchUnitSerializerDTO
}

/**
 * Request parameters for putUnitUnitsUnitUuidPut operation in UnitsAPI.
 * @export
 * @interface UnitsAPIPutUnitUnitsUnitUuidPutRequest
 */
export interface UnitsAPIPutUnitUnitsUnitUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIPutUnitUnitsUnitUuidPut
     */
    readonly unitUuid: string

    /**
     * 
     * @type {CreateUnitSerializerDTO}
     * @memberof UnitsAPIPutUnitUnitsUnitUuidPut
     */
    readonly createUnitSerializerDTO: CreateUnitSerializerDTO
}

/**
 * Request parameters for uploadUnitAttachmentUnitsUnitUuidAttachmentsPost operation in UnitsAPI.
 * @export
 * @interface UnitsAPIUploadUnitAttachmentUnitsUnitUuidAttachmentsPostRequest
 */
export interface UnitsAPIUploadUnitAttachmentUnitsUnitUuidAttachmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UnitsAPIUploadUnitAttachmentUnitsUnitUuidAttachmentsPost
     */
    readonly unitUuid: string

    /**
     * 
     * @type {AttachmentCategoryEnumDTO}
     * @memberof UnitsAPIUploadUnitAttachmentUnitsUnitUuidAttachmentsPost
     */
    readonly attachmentCategory: AttachmentCategoryEnumDTO

    /**
     * 
     * @type {AttachmentTypeEnumDTO}
     * @memberof UnitsAPIUploadUnitAttachmentUnitsUnitUuidAttachmentsPost
     */
    readonly attachmentType: AttachmentTypeEnumDTO

    /**
     * 
     * @type {Array<any>}
     * @memberof UnitsAPIUploadUnitAttachmentUnitsUnitUuidAttachmentsPost
     */
    readonly files: Array<any>

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIUploadUnitAttachmentUnitsUnitUuidAttachmentsPost
     */
    readonly title?: string

    /**
     * 
     * @type {string}
     * @memberof UnitsAPIUploadUnitAttachmentUnitsUnitUuidAttachmentsPost
     */
    readonly description?: string

    /**
     * 
     * @type {AttachmentVisibilityEnumDTO}
     * @memberof UnitsAPIUploadUnitAttachmentUnitsUnitUuidAttachmentsPost
     */
    readonly visibility?: AttachmentVisibilityEnumDTO
}

/**
 * UnitsAPI - object-oriented interface
 * @export
 * @class UnitsAPI
 * @extends {BaseAPI}
 */
export class UnitsAPI extends BaseAPI {
    /**
     * 
     * @summary Add Contact For Unit
     * @param {UnitsAPIAddContactForUnitUnitsUnitUuidContactsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public addContactForUnitUnitsUnitUuidContactsPost(requestParameters: UnitsAPIAddContactForUnitUnitsUnitUuidContactsPostRequest, options?: any) {
        return UnitsAPIFp(this.configuration).addContactForUnitUnitsUnitUuidContactsPost(requestParameters.unitUuid, requestParameters.createContactSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Unit
     * @param {UnitsAPICreateUnitUnitsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public createUnitUnitsPost(requestParameters: UnitsAPICreateUnitUnitsPostRequest, options?: any) {
        return UnitsAPIFp(this.configuration).createUnitUnitsPost(requestParameters.createUnitSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Contact For Unit
     * @param {UnitsAPIDeleteContactForUnitUnitsUnitUuidContactsContactUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public deleteContactForUnitUnitsUnitUuidContactsContactUuidDelete(requestParameters: UnitsAPIDeleteContactForUnitUnitsUnitUuidContactsContactUuidDeleteRequest, options?: any) {
        return UnitsAPIFp(this.configuration).deleteContactForUnitUnitsUnitUuidContactsContactUuidDelete(requestParameters.unitUuid, requestParameters.contactUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Unit Attachment
     * @param {UnitsAPIDeleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public deleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete(requestParameters: UnitsAPIDeleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDeleteRequest, options?: any) {
        return UnitsAPIFp(this.configuration).deleteUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDelete(requestParameters.unitUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Unit
     * @param {UnitsAPIDeleteUnitUnitsUnitUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public deleteUnitUnitsUnitUuidDelete(requestParameters: UnitsAPIDeleteUnitUnitsUnitUuidDeleteRequest, options?: any) {
        return UnitsAPIFp(this.configuration).deleteUnitUnitsUnitUuidDelete(requestParameters.unitUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Unit Attachment
     * @param {UnitsAPIDownloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public downloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet(requestParameters: UnitsAPIDownloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGetRequest, options?: any) {
        return UnitsAPIFp(this.configuration).downloadUnitAttachmentUnitsUnitUuidAttachmentsAttachmentUuidDownloadGet(requestParameters.unitUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Contacts For Unit
     * @param {UnitsAPIGetContactsForUnitUnitsUnitUuidContactsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public getContactsForUnitUnitsUnitUuidContactsGet(requestParameters: UnitsAPIGetContactsForUnitUnitsUnitUuidContactsGetRequest, options?: any) {
        return UnitsAPIFp(this.configuration).getContactsForUnitUnitsUnitUuidContactsGet(requestParameters.unitUuid, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.role, requestParameters.excludeRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Unit Auditlogs
     * @param {UnitsAPIGetSingleUnitAuditlogsUnitsUnitUuidAuditlogsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public getSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet(requestParameters: UnitsAPIGetSingleUnitAuditlogsUnitsUnitUuidAuditlogsGetRequest, options?: any) {
        return UnitsAPIFp(this.configuration).getSingleUnitAuditlogsUnitsUnitUuidAuditlogsGet(requestParameters.unitUuid, requestParameters.userUuid, requestParameters.excludeFields, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Unit
     * @param {UnitsAPIGetSingleUnitUnitsUnitUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public getSingleUnitUnitsUnitUuidGet(requestParameters: UnitsAPIGetSingleUnitUnitsUnitUuidGetRequest, options?: any) {
        return UnitsAPIFp(this.configuration).getSingleUnitUnitsUnitUuidGet(requestParameters.unitUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tenants For Unit
     * @param {UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public getTenantsForUnitUnitsUnitUuidTenantsGet(requestParameters: UnitsAPIGetTenantsForUnitUnitsUnitUuidTenantsGetRequest, options?: any) {
        return UnitsAPIFp(this.configuration).getTenantsForUnitUnitsUnitUuidTenantsGet(requestParameters.unitUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.firstName, requestParameters.lastName, requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Unit Attachment
     * @param {UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public getUnitAttachmentUnitsUnitUuidAttachmentsGet(requestParameters: UnitsAPIGetUnitAttachmentUnitsUnitUuidAttachmentsGetRequest, options?: any) {
        return UnitsAPIFp(this.configuration).getUnitAttachmentUnitsUnitUuidAttachmentsGet(requestParameters.unitUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.category, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Units Coordinates List
     * @param {UnitsAPIGetUnitsCoordinatesListUnitsCoordinatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public getUnitsCoordinatesListUnitsCoordinatesGet(requestParameters: UnitsAPIGetUnitsCoordinatesListUnitsCoordinatesGetRequest = {}, options?: any) {
        return UnitsAPIFp(this.configuration).getUnitsCoordinatesListUnitsCoordinatesGet(requestParameters.search, requestParameters.countryUuid, requestParameters.regionUuid, requestParameters.cityUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Units
     * @param {UnitsAPIGetUnitsUnitsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public getUnitsUnitsGet(requestParameters: UnitsAPIGetUnitsUnitsGetRequest = {}, options?: any) {
        return UnitsAPIFp(this.configuration).getUnitsUnitsGet(requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.propertyObjectUuid, requestParameters.propertyUuid, requestParameters.entranceUuid, requestParameters.countryUuid, requestParameters.regionUuid, requestParameters.cityUuid, requestParameters.postalCode, requestParameters.category, requestParameters.excludeCategory, requestParameters.status, requestParameters.contactUuid, requestParameters.companyUuid, requestParameters.businessRegion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Unit
     * @param {UnitsAPIPatchUnitUnitsUnitUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public patchUnitUnitsUnitUuidPatch(requestParameters: UnitsAPIPatchUnitUnitsUnitUuidPatchRequest, options?: any) {
        return UnitsAPIFp(this.configuration).patchUnitUnitsUnitUuidPatch(requestParameters.unitUuid, requestParameters.patchUnitSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Unit
     * @param {UnitsAPIPutUnitUnitsUnitUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public putUnitUnitsUnitUuidPut(requestParameters: UnitsAPIPutUnitUnitsUnitUuidPutRequest, options?: any) {
        return UnitsAPIFp(this.configuration).putUnitUnitsUnitUuidPut(requestParameters.unitUuid, requestParameters.createUnitSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Unit Attachment
     * @param {UnitsAPIUploadUnitAttachmentUnitsUnitUuidAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsAPI
     */
    public uploadUnitAttachmentUnitsUnitUuidAttachmentsPost(requestParameters: UnitsAPIUploadUnitAttachmentUnitsUnitUuidAttachmentsPostRequest, options?: any) {
        return UnitsAPIFp(this.configuration).uploadUnitAttachmentUnitsUnitUuidAttachmentsPost(requestParameters.unitUuid, requestParameters.attachmentCategory, requestParameters.attachmentType, requestParameters.files, requestParameters.title, requestParameters.description, requestParameters.visibility, options).then((request) => request(this.axios, this.basePath));
    }
}
