import type { FC } from 'react';
import React, { useCallback } from 'react';

import type { CommonProcessSerializersAttachmentAttachmentSerializerDTO as AttachmentSerializerDTO } from '../../../../../../connectors/property';
import { ConfirmationDelete } from '../../../../components';

type Props = {
  onDelete$: (id: AttachmentSerializerDTO[]) => Promise<void>;
  rows: AttachmentSerializerDTO[];
};

export const SelectedRowsAction: FC<Props> = (props) => {
  const { onDelete$, rows } = props;
  const handleDelete$ = useCallback(() => onDelete$(rows), [onDelete$, rows]);

  return (
    <ConfirmationDelete
      callback$={handleDelete$}
      data-testid="selected-rows-action"
    />
  );
};
