import { ReactComponent as ArchitectureDoorIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as RealEstateBuildingHouseIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/real-estate/real-estate-building-house.svg';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { getUnitCategoryPath } from '../../../../+unit';
import { EntityEnumDTO } from '../../../../../connectors/ticket';
import { Feature, Map, MapButton, useTranslation } from '../../../../shared';
import {
  getEntityDetailsLink,
  getEntityIcon,
  getEntityTypeOptionTranslationLabelKey,
  isUnitEntity,
} from '../../helpers';
import type { DetailsData } from '../../types';
import { getAddress } from './map-box.helper';
import { sxProps } from './map-box.styles';

type Props = {
  data: DetailsData;
};

export const MapBox: FC<Props> = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const locations = useMemo(
    () =>
      'latitude' in data.entity && 'longitude' in data.entity
        ? [
            {
              id: data.uuid,
              lat: data.entity.latitude,
              lng: data.entity.longitude,
            },
          ]
        : [],
    [data.entity, data.uuid],
  );
  const Icon = useMemo(
    () => getEntityIcon(data.entity.type),
    [data.entity.type],
  );

  return (
    <Box sx={sxProps.container}>
      <Map locations={locations} />
      <Box sx={sxProps.box}>
        <Grid container spacing={2} direction="column">
          {!isUnitEntity(data.entity) ? (
            <Grid item xs={12}>
              <Feature
                dataTestId="property-object-address-link"
                icon={<Icon />}
                textWrap
                titleKey={getEntityTypeOptionTranslationLabelKey(
                  data.entity.type,
                )}
                value={
                  <Link
                    component={RouterLink}
                    sx={sxProps.redirectLink}
                    to={getEntityDetailsLink(
                      data.entity.type,
                      data.entity.uuid,
                    )}
                    underline="none"
                  >
                    {getAddress(data.entity)}
                  </Link>
                }
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Feature
                  dataTestId="property-object-address-link"
                  icon={<RealEstateBuildingHouseIcon />}
                  textWrap
                  titleKey="ticket.options.entity.propertyObject"
                  value={
                    <Link
                      component={RouterLink}
                      sx={sxProps.redirectLink}
                      to={getEntityDetailsLink(
                        EntityEnumDTO.PropertyObject,
                        data.entity.propertyObject.uuid,
                      )}
                      underline="none"
                    >
                      {getAddress(data.entity)}
                    </Link>
                  }
                />
              </Grid>
              {data.apartmentNumber && (
                <Grid item xs={12}>
                  <Feature
                    dataTestId="unit-address-link"
                    icon={<ArchitectureDoorIcon />}
                    textWrap
                    titleKey="ticket.options.entity.unit"
                    value={
                      <Link
                        component={RouterLink}
                        sx={sxProps.redirectLink}
                        to={getEntityDetailsLink(
                          EntityEnumDTO.Unit,
                          data.entity.uuid,
                          getUnitCategoryPath(data.entity.category),
                        )}
                        underline="none"
                      >
                        {t('ticket.details.labels.unit', {
                          number: data.apartmentNumber,
                        })}
                      </Link>
                    }
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
        <MapButton location={locations[0]} sx={sxProps.link} />
      </Box>
    </Box>
  );
};
