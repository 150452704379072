import type { UnitFeatureEnum } from '../../../../../+unit';
import {
  getUnitFeaturesCheckboxValue,
  unitCategoryOptions,
  unitFeatureOptions,
} from '../../../../../+unit';
import type { DetailedUnitSerializerDTO } from '../../../../../../connectors/property';
import type { FieldsetConfig, FormProps } from '../../../../../shared';

export type Values = {
  bedrooms: DetailedUnitSerializerDTO['bedrooms'];
  category: DetailedUnitSerializerDTO['category'];
  features: UnitFeatureEnum[];
  grossArea: DetailedUnitSerializerDTO['grossArea'];
  lastRenovated: DetailedUnitSerializerDTO['lastRenovated'];
  netArea: DetailedUnitSerializerDTO['netArea'];
  rooms: DetailedUnitSerializerDTO['rooms'];
};

export const getFieldsetConfig = (
  data: DetailedUnitSerializerDTO,
): FieldsetConfig<Values>[] => [
  {
    fields: [
      {
        componentType: 'select',
        field: {
          initialValue: data.category,
          name: 'category',
        },
        props: {
          labelKey: 'unit.fields.category.label',
          options: unitCategoryOptions,
          readonly: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: data.grossArea,
          name: 'grossArea',
        },
        props: {
          labelKey: 'unit.fields.grossArea',
          readonly: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: data.netArea,
          name: 'netArea',
        },
        props: {
          labelKey: 'unit.fields.netArea',
          readonly: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: data.rooms,
          name: 'rooms',
        },
        props: {
          labelKey: 'unit.fields.rooms',
          readonly: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: data.bedrooms,
          name: 'bedrooms',
        },
        props: {
          labelKey: 'unit.fields.bedrooms',
          readonly: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      ...(data.lastRenovated
        ? [
            {
              componentType: 'date',
              field: {
                initialValue: data.lastRenovated,
                name: 'lastRenovated',
              },
              props: {
                labelKey: 'unit.fields.lastRenovated',
                readonly: true,
                size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
              },
            } as const,
          ]
        : []),
    ],
    key: 'unit-details',
    titleKey: 'advertisement.fieldset.description.unitDetails',
  },
  {
    fields: [
      {
        componentType: 'checkbox',
        field: {
          initialValue: getUnitFeaturesCheckboxValue(data.features),
          name: 'features',
        },
        props: {
          options: unitFeatureOptions,
          optionSize: { lg: 6, md: 6, sm: 6, xl: 6, xs: 12 },
          readonly: true,
        },
      },
    ],
    key: 'unit-features',
    titleKey: 'advertisement.fieldset.description.unitFeatures',
  },
];

export const config: FormProps<Values>['config'] = {
  onSubmit: () => {
    // do nothing
  },
};
