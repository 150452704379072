import type { FC, Dispatch, SetStateAction } from 'react';
import React, { useMemo } from 'react';

import type { EntityEnumDTO } from '../../../../../connectors/property';
import type {
  FilterValuesWithLocalization,
  ListState,
} from '../../../../shared';
import { FilterPanelWithLocalization } from '../../../../shared';
import { getFieldsConfig } from './filter-panel.model';
import type { FilterValues } from './filter-panel.type';

type Props = {
  listState: ListState<FilterValuesWithLocalization<FilterValues>>;
  localizationEntityType: EntityEnumDTO;
  setListState: Dispatch<
    SetStateAction<ListState<FilterValuesWithLocalization<FilterValues>>>
  >;
};

export const FilterPanel: FC<Props> = (props) => {
  const { listState, localizationEntityType, setListState } = props;
  const fieldsConfig = useMemo(
    () => getFieldsConfig(listState.filters?.fields),
    // prevent rerender on filters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FilterPanelWithLocalization
      fieldsConfig={fieldsConfig}
      listState={listState}
      localizationEntityType={localizationEntityType}
      setListState={setListState}
    />
  );
};
