import type { PaletteMode } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';
import { ArcElement, Chart as ChartJS } from 'chart.js';
import type { CoreChartOptions } from 'chart.js';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import type { ChartProps } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';

import type { ColorsHex } from '../../../theme';
import { useTranslation } from '../../../translations';
import { TOTAL_BACKGROUND_COLOR } from './doughnut-chart.const';
import { sxProps } from './doughnut-chart.styles';

type Props<T> = {
  countLabelKey: GenericTypes.TranslationLabel;
  countObject: Record<string | 'total', number>;
  getStatusColorsHex: (paletteMode: PaletteMode, status: T) => ColorsHex;
  percentLabelKey: GenericTypes.TranslationLabel;
};

ChartJS.register(ArcElement);

export const DoughnutChart = <T,>(
  props: Props<T>,
): ReturnType<FC<Props<T>>> => {
  const { countLabelKey, countObject, getStatusColorsHex, percentLabelKey } =
    props;
  const theme = useTheme();
  const { t } = useTranslation();
  const entries = useMemo(
    () =>
      Object.entries(countObject)
        .filter(([key]) => key !== 'total')
        .map(([key, value]) => {
          const status = key as unknown as T;

          return {
            color: getStatusColorsHex(theme.palette.mode, status)
              .backgroundColor,
            value,
          };
        }),
    [countObject, getStatusColorsHex, theme.palette.mode],
  );
  const totalCount = useMemo(
    () =>
      countObject.total || entries.reduce((acc, entry) => acc + entry.value, 0),
    [countObject.total, entries],
  );
  const [count, setCount] = useState<number>(totalCount);
  const [percent, setPercent] = useState<number>(100);
  const dataset = useMemo<ChartProps<'doughnut'>['data']>(
    () => ({
      datasets: [
        {
          backgroundColor: entries.map((entry) => entry.color),
          borderColor: theme.palette.primary.contrastText,
          borderWidth: 4,
          data: entries.map((entry) => entry.value),
          hoverBorderColor: theme.palette.primary.contrastText,
          hoverBorderWidth: 2,
          weight: 2,
        },
        {
          backgroundColor: [TOTAL_BACKGROUND_COLOR],
          borderColor: theme.palette.primary.contrastText,
          borderWidth: 4,
          data: [totalCount],
          hoverBackgroundColor: [alpha(TOTAL_BACKGROUND_COLOR, 0.7)],
          hoverBorderColor: theme.palette.primary.contrastText,
          hoverBorderWidth: 4,
        },
      ],
    }),
    [entries, theme.palette.primary.contrastText, totalCount],
  );
  const handleInteraction: CoreChartOptions<'doughnut'>['onClick' | 'onHover'] =
    useCallback(
      (_, elements, chart) => {
        if (!elements[0] || elements[0].datasetIndex === 1) {
          setCount(totalCount);
          setPercent(100);

          return;
        }

        const entry = entries[elements[0].index];

        // eslint-disable-next-line no-param-reassign
        chart.canvas.style.cursor = entry ? 'pointer' : 'default';

        const percent = Math.round((100 * entry.value) / totalCount);

        setCount(entry.value);
        setPercent(percent);
      },
      [entries, totalCount],
    );
  const options = useMemo<ChartProps<'doughnut'>['options']>(
    () => ({
      cutout: '65%',
      onClick: handleInteraction,
      onHover: handleInteraction,
    }),
    [handleInteraction],
  );

  return (
    <Box sx={sxProps.container}>
      {count !== undefined && percent !== undefined && (
        <Box sx={sxProps.box}>
          <Typography align="center" variant="h4">
            {t(percentLabelKey, { percent })}
          </Typography>
          <Typography align="center" variant="body2">
            {t(countLabelKey, { count })}
          </Typography>
        </Box>
      )}
      <Doughnut data={dataset} options={options} />
    </Box>
  );
};
