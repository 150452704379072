import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import isNil from 'lodash-es/isNil';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router';

import type { AdvertisementIntegrationSerializerDTO } from '../../../../../connectors/company';
import type {
  CountrySerializerDTO,
  UnitAdvertisementSerializerDTO,
} from '../../../../../connectors/property';
import type { ListAttachmentSerializerDTO } from '../../../../../connectors/ticket';
import type { Country } from '../../../../shared';
import { Dialog, useAsync, useTranslation } from '../../../../shared';
import { advertisementClient } from '../../../advertisement.client';
import { useAdvertisementConfig } from '../../providers';
import { UnpublishAll } from '../UnpublishAll/unpublish-all.component';
import { UnpublishSpecified } from '../UnpublishSpecified/unpublish-specified.component';
import { sxProps } from './unpublish.styles';

type Props = {
  advertisementIntegrations: UnitAdvertisementSerializerDTO['integrations'];
  country: Country | CountrySerializerDTO['uuid'];
};
export type SelectedIntegrationsState =
  | 'ALL'
  | AdvertisementIntegrationSerializerDTO['uuid'][]
  | undefined;

export const Unpublish: FC<Props> = (props) => {
  const { advertisementIntegrations, country } = props;
  const { id } = useParams();
  const { setResponse } =
    useAsync<[UnitAdvertisementSerializerDTO, ListAttachmentSerializerDTO]>();
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const { getCountryConfig } = useAdvertisementConfig();
  const config = useMemo(
    () => getCountryConfig(country),
    [country, getCountryConfig],
  );
  const [selectedIntegrations, setSelectedIntegrations] =
    useState<SelectedIntegrationsState>();
  const handleClose = useCallback(() => {
    setSelectedIntegrations(undefined);
    setOpen(false);
  }, []);
  const handleConfirm = useCallback(async () => {
    if (!selectedIntegrations) return;

    try {
      const payload =
        selectedIntegrations === 'ALL'
          ? advertisementIntegrations.map((integration) => integration.uuid)
          : advertisementIntegrations
              .filter((integration) =>
                selectedIntegrations.includes(integration.integration.uuid),
              )
              .map((integration) => integration.uuid);

      await advertisementClient.unpublish$(id, payload);
      setResponse((prevState) => {
        const [details, attachments] = prevState;

        return [
          {
            ...details,
            integrations: details.integrations.filter(
              (integration) => !payload.includes(integration.uuid),
            ),
          },
          attachments,
        ];
      });
      toast.success(t('advertisement.unpublish.success'));
    } catch (e) {
      toast.error(t('errors.general.message'));
      handleClose();
    } finally {
      setOpen(false);
      setSelectedIntegrations(undefined);
    }
  }, [
    advertisementIntegrations,
    handleClose,
    id,
    selectedIntegrations,
    setResponse,
    t,
  ]);

  return (
    <>
      <Dialog
        confirmButtonTitleKey="advertisement.unpublish.button"
        isConfirmDisabled={isNil(selectedIntegrations)}
        onConfirm={handleConfirm}
        isOpen={isOpen}
        onClose={handleClose}
      >
        <Grid container marginTop={3} spacing={1}>
          <Grid item xs={12} sm={6}>
            <UnpublishAll
              advertisementIntegrations={advertisementIntegrations}
              availableIntegrations={config.integrations}
              selectedIntegrations={selectedIntegrations}
              itemSx={sxProps.item}
              selectedItemSx={sxProps.itemSelected}
              setSelectedIntegrations={setSelectedIntegrations}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UnpublishSpecified
              advertisementIntegrations={advertisementIntegrations}
              availableIntegrations={config.integrations}
              selectedIntegrations={selectedIntegrations}
              itemSx={sxProps.item}
              selectedItemSx={sxProps.itemSelected}
              setSelectedIntegrations={setSelectedIntegrations}
            />
          </Grid>
        </Grid>
      </Dialog>
      <ButtonBase onClick={() => setOpen(true)} sx={sxProps.button}>
        <Typography color="text.primary" variant="overline">
          {t('advertisement.unpublish.button')}
        </Typography>
      </ButtonBase>
    </>
  );
};
