import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';

import { TicketTypeEnumDTO } from '../../../../../connectors/ticket';
import type { FileValue } from '../../../../shared';
import {
  AsyncContentProvider,
  getUserName,
  Page,
  proceedAttachmentsUpload$,
  useTranslation,
} from '../../../../shared';
import { getTicketTypeTranslationLabelKey } from '../../consts';
import {
  getAttachmentUploadProcedureCallbacks,
  getCreateRequest$,
  getForm,
  isTicketDuplicated,
  isUserEntity,
} from '../../helpers';
import type { CreateRequest, PartialDetailsData } from '../../types';
import { ServiceRequestForm } from '../ServiceRequestForm';

type Props = {
  getDetails$: () => Promise<[PartialDetailsData]>;
  ticketType: TicketTypeEnumDTO;
};

export const EntityCreateRequest: FC<Props> = (props) => {
  const { getDetails$, ticketType } = props;
  const { t } = useTranslation();
  const handleSubmit$ = useCallback(
    async (data: CreateRequest, attachments: FileValue) => {
      try {
        const ticket = await getCreateRequest$(data, ticketType);
        const attachmentCallbacks = getAttachmentUploadProcedureCallbacks(
          ticket.uuid,
        );

        await proceedAttachmentsUpload$(attachments, attachmentCallbacks);

        return ticket;
      } catch (e) {
        if (isTicketDuplicated(e)) {
          toast.error(t('ticket.errors.duplicatedRequest'));
        } else {
          toast.error(t('errors.general.message'));
        }

        throw e;
      }
    },
    [t, ticketType],
  );
  const ticketTypeLabelKey = useMemo(
    () => getTicketTypeTranslationLabelKey(ticketType),
    [ticketType],
  );
  const Form = useMemo(() => getForm(ticketType), [ticketType]);

  return (
    <AsyncContentProvider getData$={getDetails$} shouldRedirect>
      {(details) => (
        <Page
          title={{
            key: 'ticket.entity.create.title',
            options: {
              name: isUserEntity(details[0].entity)
                ? getUserName(details[0].entity)
                : details[0].entity.name,
              ticketType: t(ticketTypeLabelKey),
            },
          }}
        >
          {ticketType === TicketTypeEnumDTO.ServiceRequest ? (
            <ServiceRequestForm
              details={details[0]}
              isPredefinedData
              onSubmit$={handleSubmit$}
            />
          ) : (
            <Form onSubmit$={handleSubmit$} isPredefinedData />
          )}
        </Page>
      )}
    </AsyncContentProvider>
  );
};
