import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { Image as TImage } from '../carousel.type';
import { sxProps } from './image.styles';

type Props = Pick<TImage, 'url'> & {
  isVisible: boolean;
  onClick?: BoxProps['onClick'];
};

export const Image: FC<Props> = (props) => {
  const { isVisible, onClick, url } = props;
  const imageSxProps = useMemo(
    () =>
      ({
        ...sxProps.image,
        ...(onClick && { cursor: 'pointer' }),
        backgroundImage: `url(${url})`,
        opacity: isVisible ? 1 : 0,
      } as SxProps),
    [isVisible, onClick, url],
  );

  return <Box sx={imageSxProps} onClick={onClick} />;
};
