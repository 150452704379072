import { createContext } from 'react';

import type { CountrySerializerDTO } from '../../../../../connectors/company';
import { Country } from '../../../../shared/providers/Country/enum';
import { getStatusesWorkflowOptions } from './helper';
import type { Config } from './type';

export type ContextValue = {
  config: Record<Country | CountrySerializerDTO['uuid'], Config>;
  getCountryConfig: (
    country?: Country | CountrySerializerDTO['uuid'],
  ) => Config;
};

export const initialCountryConfig: Config = {
  complaintTypes: [],
  contractRequestTypes: [],
  getDamagedItemOptions: () => [],
  getIssueSubtypes: () => [],
  getIssueTypeOptions: () => [],
  getRoomTypeOptions: () => [],
  invoiceRequestTypes: [],
  locationTypeOptions: [],
  statusesWorkflowOptions: getStatusesWorkflowOptions({}),
  statusOptions: [],
};

export const initialConfig: ContextValue['config'] = {
  [Country.IS]: initialCountryConfig,
  [Country.NL]: initialCountryConfig,
  [Country.NO]: initialCountryConfig,
  [Country.PL]: initialCountryConfig,
};

export const Context = createContext<ContextValue>({
  config: initialConfig,
  getCountryConfig: () => initialCountryConfig,
});
