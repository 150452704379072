import { ReactComponent as BinIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/delete/bin.svg';
import { ReactComponent as ViewSquareIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/view/view-square.svg';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import type { ListItemProps } from '@mui/material/ListItem';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { Dispatch, SetStateAction, TouchEvent } from 'react';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { getIcon, getImagePreviewPath } from '../../../attachment/shared';
import { removeDragStyles, setDragStyles } from '../../../helpers';
import type { CustomFile, FileValue } from '../../fields';
import { FileEdit } from '../FileEdit/file-edit.component';
import { sxProps } from './file-preview.styles';

type Props = {
  container: HTMLDivElement | null;
  currentDrag: CustomFile | null;
  dragImageElement: HTMLImageElement | null;
  file: CustomFile;
  files: FileValue;
  index: number;
  setCurrentDrag: Dispatch<SetStateAction<CustomFile | null>>;
  onUpdate: (files: FileValue) => void;
  onTouchDrop: (position: DOMRect) => void;
  onTouchMoveOver: (event: TouchEvent) => void;
  onRemove?: (index: number) => void;
};

// TODO: Create modal for changing name and description after: https://fredensborg.atlassian.net/browse/MB-421
export const FilePreview = forwardRef<HTMLLIElement, Props>((props, ref) => {
  const {
    container,
    currentDrag,
    dragImageElement,
    file,
    files,
    index,
    onRemove,
    onTouchDrop,
    onTouchMoveOver,
    onUpdate,
    setCurrentDrag,
  } = props;
  const element = useRef<HTMLLIElement>(null);
  const theme = useTheme();
  const Icon = useMemo(() => getIcon(file.type), [file.type]);
  const previewPath = useMemo(() => getImagePreviewPath(file), [file]);
  const isDragging = useMemo(
    () => currentDrag?.path === file.path,
    [currentDrag?.path, file.path],
  );
  const onDragStart: ListItemProps['onDragStart'] = useCallback(
    (event) => {
      if (!dragImageElement || !file.path) return;

      /* eslint-disable no-param-reassign */
      // changing style of cursor during dragging
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      /* eslint-enable no-param-reassign */
      event.dataTransfer.setDragImage(dragImageElement, 0, 0);
      setCurrentDrag(file);
    },
    [dragImageElement, file, setCurrentDrag],
  );
  const onDrag: ListItemProps['onDrag'] = useCallback(
    (event) => {
      const { currentTarget: el, pageX, pageY } = event;

      setDragStyles(theme.spacing(0), container, el, pageX, pageY);
    },
    [container, theme],
  );
  const onDragEnd: ListItemProps['onDragEnd'] = useCallback(
    (event) => {
      const { currentTarget: el } = event;

      removeDragStyles(el);
      setCurrentDrag(null);
    },
    [setCurrentDrag],
  );
  const onTouchStart: ListItemProps['onTouchStart'] = useCallback(() => {
    setCurrentDrag(file);
  }, [file, setCurrentDrag]);
  const onTouchMove: ListItemProps['onTouchMove'] = useCallback(
    (event) => {
      if (!element.current) return;

      const { current: el } = element;
      const { pageX, pageY } = event.changedTouches[0];

      setDragStyles(theme.spacing(0), container, el, pageX, pageY);
      onTouchMoveOver(event);
    },
    [container, onTouchMoveOver, theme],
  );
  const onTouchEnd: ListItemProps['onTouchEnd'] = useCallback(
    (event) => {
      const { currentTarget: el } = event;

      onTouchDrop(el.getBoundingClientRect());
      removeDragStyles(el);
    },
    [onTouchDrop],
  );

  useImperativeHandle(ref, () => element.current as HTMLLIElement);

  return (
    <ListItem
      draggable
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      sx={
        { ...sxProps.listItem, ...(isDragging && sxProps.dragging) } as SxProps
      }
      ref={element}
    >
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid item>
          <Grid alignItems="center" container spacing={2} wrap="nowrap">
            <Grid item>
              {previewPath ? (
                <Box
                  sx={{
                    ...sxProps.preview,
                    backgroundImage: `url(${previewPath})`,
                  }}
                />
              ) : (
                <Box sx={sxProps.icon}>
                  <Icon height={24} width={24} />
                </Box>
              )}
            </Grid>
            <Grid item>
              <Typography sx={sxProps.name} variant="h5">
                {file.title || file.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container wrap="nowrap">
            {previewPath && (
              <Grid item>
                <Tooltip
                  arrow
                  enterTouchDelay={0}
                  placement="top"
                  title={
                    <Box
                      sx={
                        {
                          ...sxProps.preview,
                          ...sxProps.tooltipPreview,
                          backgroundImage: `url(${previewPath})`,
                        } as SxProps
                      }
                    />
                  }
                >
                  <IconButton>
                    <ViewSquareIcon height={16} width={16} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <FileEdit file={file} files={files} onUpdate={onUpdate} />
            </Grid>
            {onRemove && (
              <Grid item>
                <IconButton onClick={() => onRemove(index)}>
                  <BinIcon height={16} width={16} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
});
