import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { FC } from 'react';
import React, { useCallback } from 'react';

export const DisabledContent: FC = () => {
  const [editor] = useLexicalComposerContext();
  const ref = useCallback(
    (rootElement: null | HTMLElement) => {
      editor.setRootElement(rootElement);
    },
    [editor],
  );

  return <div contentEditable={false} ref={ref} />;
};
