import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    display: 'inline-block',
    mr: 1,
  },
  skeleton: {
    width: '100px',
  },
};
