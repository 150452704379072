import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  card: {
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    m: (theme) => theme.spacing(1.5),
    width: (theme) => [
      '100%',
      `calc(50% - ${theme.spacing(3)})`,
      `calc(33.33% - ${theme.spacing(3)})`,
      `calc(25% - ${theme.spacing(3)})`,
      `calc(16.66% - ${theme.spacing(3)})`,
    ],
  },
  cardActions: {
    p: (theme) => theme.spacing(2),
  },
  cardContent: {
    '&:last-child': {
      pb: (theme) => theme.spacing(2),
    },
  },
  cardItemPointerEvents: {
    cursor: 'pointer',
  },
  thumbnail: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 0,
    boxShadow: 'none',
    height: 238,
    width: '100%',
  },
};
