import { Permission } from '../../../+auth/shared/enums/permission.enum';

export const CAN_VIEW_CONTACTS = [
  Permission.GLOBAL_READ,
  Permission.READ_PROPERTY,
  Permission.CAN_VIEW_UNIT_CONTACT,
];
export const CAN_CREATE_CONTACTS = [
  Permission.GLOBAL_WRITE,
  Permission.WRITE_PROPERTY,
  Permission.CAN_CREATE_UNIT_CONTACT,
];
export const CAN_DELETE_CONTACTS = [
  Permission.GLOBAL_WRITE,
  Permission.WRITE_PROPERTY,
  Permission.CAN_DELETE_UNIT_CONTACT,
];
export const CAN_UPDATE_CONTACTS = [
  Permission.GLOBAL_WRITE,
  Permission.WRITE_PROPERTY,
  Permission.CAN_UPDATE_UNIT_CONTACT,
];
export const CREATE_PERMISSIONS = [
  Permission.GLOBAL_WRITE,
  Permission.WRITE_PROPERTY,
  Permission.CAN_CREATE_UNITS,
];
export const UPDATE_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.WRITE_PROPERTY,
  Permission.CAN_UPDATE_UNITS,
];
export const VIEW_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.READ_PROPERTY,
  Permission.CAN_VIEW_UNITS,
];
