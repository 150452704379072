import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import type { FC, ReactElement } from 'react';
import React from 'react';

import { Search } from '../Search/search.component';
import type { ListStateCommonProps } from '../collection.type';
import { sxProps } from './header.styles';

type Props<TFilterValues> = ListStateCommonProps<TFilterValues> & {
  children?: ReactElement;
};

export const Header = <TFilterValues,>(
  props: Props<TFilterValues>,
): ReturnType<FC<Props<TFilterValues>>> => {
  const { children, ...restProps } = props;

  return (
    <Grid container sx={sxProps.container}>
      <Grid item xs={12}>
        <Search {...restProps} />
      </Grid>
      {children && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item sx={sxProps.filtersRow} xs={12}>
            {children}
          </Grid>
        </>
      )}
    </Grid>
  );
};
