import FormControl from '@mui/material/FormControl';
import { useField, useFormikContext } from 'formik';
import isFunction from 'lodash-es/isFunction';
import type { FC, FocusEvent } from 'react';
import React, { useCallback, useMemo } from 'react';

import { Editor } from '../../components';
import { getFirstErrorKey } from '../../helpers';
import type { EditorProps, EditorValue } from '../types';

type Props<TFormValues> = {
  configProps: EditorProps<TFormValues>;
  name: keyof TFormValues;
};

export const EditorField = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const { configProps, name } = props;
  const {
    disabled,
    disableWhen,
    helperTextKey,
    labelKey,
    placeholderKey,
    readonly,
    required,
    requiredWhen,
  } = configProps;
  const { values } = useFormikContext<TFormValues>();
  const [field, meta, helpers] = useField<EditorValue>(name as string);
  const { onBlur } = field;
  const { error, touched, value } = meta;
  const { setValue } = helpers;
  const finalLabelKey = useMemo(
    () => (isFunction(labelKey) ? labelKey(values) : labelKey),
    [labelKey, values],
  );
  const errorMessageKey = useMemo(
    () => touched && getFirstErrorKey(error),
    [error, touched],
  );
  const isError = useMemo(() => Boolean(errorMessageKey), [errorMessageKey]);
  const isDisabled = useMemo(
    () => Boolean(disabled || readonly || (disableWhen && disableWhen(values))),
    [disableWhen, disabled, readonly, values],
  );
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );
  const finalHelperTextKey = useMemo(() => {
    if (isError && errorMessageKey) return errorMessageKey;
    if (helperTextKey) return helperTextKey;

    return undefined;
  }, [errorMessageKey, helperTextKey, isError]);
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLDivElement>) => {
      onBlur({ ...event, target: { ...event.target, name } });
    },
    [name, onBlur],
  );

  return (
    <FormControl
      component="fieldset"
      disabled={isDisabled}
      error={isError}
      fullWidth
      required={isRequired && !readonly}
    >
      <Editor
        handleBlur={handleBlur}
        helperTextKey={finalHelperTextKey}
        initialValue={value}
        labelKey={finalLabelKey}
        name={name as string}
        placeholderKey={placeholderKey}
        setValue={setValue}
      />
    </FormControl>
  );
};
