import type {
  ContactSerializerDTO,
  SignerRoleEnumDTO,
  TenantSerializerDTO,
} from '../../../../connectors/property';
import type { UserRoleEnumDTO } from '../../../../connectors/user';
import { getUserName } from '../../../shared';
import PATHS from '../../paths.const';
import type { User } from '../types';
import { getUserRoleTranslationLabelKey } from './role.helper';
import {
  getSignerRoleTranslationLabelKey,
  isSigner,
} from './signer-role.helper';

const isContact = (
  user: ContactSerializerDTO | TenantSerializerDTO,
): user is ContactSerializerDTO => 'userUuid' in user;

export const prepareUserData = (
  user: ContactSerializerDTO | TenantSerializerDTO,
): User => ({
  avatarSrc: '/assets/__mocks/avatars/tenant.svg', // TODO: expose avatar after https://fredensborg.atlassian.net/browse/MB-644
  contactId: isContact(user) ? user.uuid : undefined,
  id: isContact(user) ? user.userUuid : user.uuid,
  name: getUserName(user),
  path: PATHS.DETAILS,
  position: isSigner(user.roles)
    ? getSignerRoleTranslationLabelKey(user.roles as SignerRoleEnumDTO[])
    : getUserRoleTranslationLabelKey(user.roles as UserRoleEnumDTO[]),
  roles: user.roles,
});
