import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import type { FC } from 'react';

import { useTranslation } from '../../translations';

type Props = {
  errorMessage?: string;
};

export const GlobalError: FC<Props> = ({ errorMessage }) => {
  const { t } = useTranslation();

  if (!errorMessage) {
    return null;
  }

  return (
    <FormHelperText error sx={{ mb: 2 }}>
      {t(errorMessage)}
    </FormHelperText>
  );
};
