import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  formControlLabel: {
    ml: 0,
  },
  radio: {
    marginLeft: -1.125, // Align to the left
  },
};
