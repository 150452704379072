import { badgeClasses } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../theme';

export const ACTIVE_CLASS_NAME = 'active';

export const sxProps: Record<string, SxProps<Theme>> = {
  badge: {
    [`&.${badgeClasses.root}`]: {
      display: 'block',
    },
    [`& .${badgeClasses.standard}`]: {
      borderRadius: '50%',
      bottom: (theme) => theme.spacing(2.75),
      left: (theme) => theme.spacing(-2),
    },
  },
  item: {
    '&.active': {
      fontWeight: (theme) => theme.typography.fontWeightBold,
    },
    '&.active, &:hover': {
      color: (theme) => theme.palette.secondary.dark,
      cursor: 'pointer',
    },
    borderRight: '4px solid transparent',
    color: palette.waikawaGray,
    fontSize: (theme) => theme.typography.pxToRem(16),
    lineHeight: (theme) => theme.typography.pxToRem(24),
    pb: 1.125,
  },
};
