import type { Theme } from '@mui/material/styles/createTheme';
import { alpha } from '@mui/system';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../theme';

export const sxProps: Record<string, SxProps<Theme>> = {
  iconBox: {
    border: `1px solid ${palette.heather}`,
    borderRadius: '50%',
    height: 56,
    mr: 1,
    p: 2,
    width: 56,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: palette.seashellpeach,
      borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
    },
    '&:last-child': {
      mb: 0,
    },
    alignItems: 'start',
    backgroundColor: palette.seashellpeach,
    border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    borderRadius: 2,
    display: 'flex',
    mb: 1,
    overflowX: 'hidden',
    p: 2,
    whiteSpace: 'initial',
  },
  menuItemSeen: {
    '&:hover': {
      backgroundColor: (theme) => theme.palette.background.paper,
      opacity: 1,
    },
    '&:last-child': {
      mb: 0,
    },
    backgroundColor: (theme) => theme.palette.background.paper,
    border: (theme) => `1px solid ${theme.palette.grey[300]}`,
    color: (theme) => theme.palette.secondary.light,
    opacity: 0.4,
  },
  menuList: {
    p: 0,
  },
};
