import { ReactComponent as EmailActionUnreadIcon } from '@heimstaden/icons-library/img/streamline-regular/emails/email-actions/email-action-unread.svg';
import { ReactComponent as PhoneLandlineIcon } from '@heimstaden/icons-library/img/streamline-regular/phones-mobile-devices/phone/phone-landline.svg';
import { ReactComponent as SingleNeutralActionsTextIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-actions-neutral/single-neutral-actions-text.svg';
import { Icon } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { getUserTicketListPath } from '../../../../+user';
import type {
  LinkedUserSerializerDTO,
  TicketTypeEnumDTO,
} from '../../../../../connectors/ticket';
import { getUserName, useTranslation } from '../../../../shared';
import { sxProps } from './reportedBy.styles';

type Props = {
  ticketType: TicketTypeEnumDTO;
  user: LinkedUserSerializerDTO;
};

export const ReportedBy: FC<Props> = (props) => {
  const { ticketType, user } = props;
  const { t } = useTranslation();
  const name = useMemo(() => getUserName(user), [user]);
  const path = useMemo(
    () => getUserTicketListPath(user.uuid, ticketType),
    [user, ticketType],
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography mb={2} variant="h5">
          {t('ticket.details.labels.reportedBy')}
        </Typography>
        <Typography variant="h2">{name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Link href={`mailto:${user.email}`} sx={sxProps.link}>
          <Icon sx={sxProps.icon}>
            <EmailActionUnreadIcon />
          </Icon>
          <Typography noWrap sx={sxProps.text} variant="subtitle2">
            {user.email}
          </Typography>
        </Link>
      </Grid>
      {user.phoneNumber && (
        <Grid item xs={12}>
          <Link href={`tel:${user.phoneNumber}`} sx={sxProps.link}>
            <Icon component={PhoneLandlineIcon} sx={sxProps.icon} />
            <Typography variant="subtitle2" noWrap sx={sxProps.text}>
              {user.phoneNumber}
            </Typography>
          </Link>
        </Grid>
      )}
      {path && (
        <Grid item xs={12}>
          <Link component={RouterLink} to={path} sx={sxProps.link}>
            <Icon sx={sxProps.icon}>
              <SingleNeutralActionsTextIcon />
            </Icon>
            <Typography sx={sxProps.text} variant="body2">
              <Trans
                components={{ bold: <strong /> }}
                i18nKey="ticket.actions.allTicketsReportedBy"
                values={{ name }}
              />
            </Typography>
          </Link>
        </Grid>
      )}
    </>
  );
};
