import * as Sentry from '@sentry/react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import type { FC, ReactNode } from 'react';

import type { UserNotificationConfigSerializerDTO } from '../../../../connectors/user';
import { storageService } from '../../../shared';
import { authClient } from '../../auth.client';
import type { Permission } from '../enums';
import { Context } from './context';
import type { ContextValue } from './context';
import { initialState, reducer } from './reducer';

interface Props {
  children: ReactNode;
}

export const Provider: FC<Props> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isUserFetching, setUserFetching] = useState(true);
  const logout = useCallback((preventRequest = false): void => {
    if (!preventRequest) authClient.logout();

    dispatch({ type: 'LOGOUT' });
  }, []);
  const getUserData$ = useCallback(async () => {
    const response = await authClient.me$();

    if (response) {
      const { email, firstName, lastName, uuid } = response;
      const name = `${firstName} ${lastName}`;

      dispatch({
        payload: {
          user: {
            ...response,
            countryUuid: response.countryUuids?.[0] || '', // TODO Handle more countries
            name,
            permissions: (response.permissions as Permission[]) || [],
          },
        },
        type: 'LOGIN',
      });

      Sentry.setTags({ email, name, uuid });
    } else {
      logout();
    }

    setUserFetching(false);
  }, [logout]);
  const authorize$: ContextValue['authorize$'] = useCallback(
    async (type, searchParams) => {
      try {
        await authClient.authorize$(type, searchParams);
        await getUserData$();
      } catch (e) {
        throw new Error('errors.general.message');
      }
    },
    [getUserData$],
  );
  const login$: ContextValue['login$'] = useCallback(
    async (email, password) => {
      try {
        await authClient.login$(email, password);
        await getUserData$();
      } catch (e) {
        throw new Error('errors.general.message');
      }
    },
    [getUserData$],
  );
  const initialize = useCallback(async (): Promise<void> => {
    const accessToken = storageService.getToken();

    if (accessToken) {
      dispatch({
        payload: { isAuthenticated: true },
        type: 'AUTH_INITIALIZE',
      });
      await getUserData$();
    } else {
      dispatch({
        payload: { isAuthenticated: false },
        type: 'AUTH_INITIALIZE',
      });
    }
  }, [getUserData$]);
  const userPermissions = useMemo(
    () => (state.user?.permissions || []) as Permission[],
    [state.user?.permissions],
  );
  const setNotificationSettings = useCallback(
    (payload: UserNotificationConfigSerializerDTO) => {
      dispatch({ payload, type: 'SET_NOTIFICATION_SETTINGS' });
    },
    [],
  );

  useEffect(() => {
    if (state.isAuthenticated) {
      return;
    }

    initialize();
  }, [initialize, state.isAuthenticated]);

  const contextValue = useMemo(
    () => ({
      ...state,
      authorize$,
      isUserFetched: !isUserFetching,
      login$,
      loginByProvider: authClient.loginByProvider,
      logout,
      permissions: userPermissions,
      setNotificationSettings,
    }),
    [
      authorize$,
      isUserFetching,
      login$,
      logout,
      setNotificationSettings,
      state,
      userPermissions,
    ],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

Provider.displayName = 'AuthProvider';
