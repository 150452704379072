import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

const TILE_BACKGROUND_PATH = '/assets/tile_bcg.png';

export const sxProps: Record<string, SxProps<Theme>> = {
  avatar: {
    border: (theme) =>
      `${theme.spacing(1)} solid ${theme.palette.primary.contrastText}`,
    height: (theme) => `calc(100px + ${theme.spacing(1)})`,
    width: (theme) => `calc(100px + ${theme.spacing(1)})`,
  },
  box: {
    backgroundImage: `url(${TILE_BACKGROUND_PATH})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
};
