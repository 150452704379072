import type { FC } from 'react';
import React, { useCallback } from 'react';
import { generatePath, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { PermissionGuard } from '../../+auth';
import {
  PROPERTY_OBJECT_PATHS,
  PROPERTY_OBJECT_VIEW_PERMISSIONS,
} from '../../+property-object';
import type { ListPropertyObjectsSerializerDTO } from '../../../connectors/property';
import { PropertyObjectCategoryEnumDTO } from '../../../connectors/property';
import {
  INITIAL_LIST_STATE,
  List as GenericList,
  useApi,
  useList,
} from '../../shared';
import PATHS from '../paths.const';
import { userClient } from '../user.client';
import type { FilterValues } from './property-object-list.model';
import {
  columnConfigs,
  filterFieldsConfig,
  INITIAL_FILTER_VALUES,
  INITIAL_SORT,
} from './property-object-list.model';

const PropertyObjectList: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    listState,
    queryParams: listQueryParams,
    setListState,
    setPaginationFromResponse,
  } = useList<ListPropertyObjectsSerializerDTO, FilterValues>(false, {
    ...INITIAL_LIST_STATE,
    currentTab: PropertyObjectCategoryEnumDTO.Building,
    filters: INITIAL_FILTER_VALUES,
    sort: INITIAL_SORT,
  });
  const getData$ = useCallback(async () => {
    const response = await userClient.getPropertyObjects$(id, listQueryParams);

    setPaginationFromResponse(response);

    return response;
  }, [id, listQueryParams, setPaginationFromResponse]);
  const { isFetching, response } = useApi<ListPropertyObjectsSerializerDTO>(
    { count: 0, results: [] },
    getData$,
  );

  return (
    <PermissionGuard
      fallbackRoute={generatePath(PATHS.DETAILS, { id })}
      permissions={PROPERTY_OBJECT_VIEW_PERMISSIONS}
    >
      <GenericList
        columnsConfig={columnConfigs}
        filterFieldsConfig={filterFieldsConfig}
        isFetching={isFetching}
        listState={listState}
        model={response.results}
        modelKey="uuid"
        onRowClick={({ uuid }) =>
          navigate(generatePath(PROPERTY_OBJECT_PATHS.DETAILS, { id: uuid }))
        }
        setListState={setListState}
      />
    </PermissionGuard>
  );
};

export default PropertyObjectList;
