import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import type { FC } from 'react';

import { getUserRoleTranslationLabelKey } from '../../../../+user';
import type { LinkedUserEssentialInfoSerializerDTO } from '../../../../../connectors/ticket';
import { getUserName, useTranslation } from '../../../../shared';

type Props = {
  user: LinkedUserEssentialInfoSerializerDTO;
};

export const CreatedBy: FC<Props> = (props) => {
  const { user } = props;
  const { t } = useTranslation();
  const name = useMemo(() => getUserName(user), [user]);
  const roleKey = useMemo(
    () => getUserRoleTranslationLabelKey(user.roles || []),
    [user.roles],
  );

  return (
    <Grid item xs={12}>
      <Typography mb={2} variant="h5">
        {t('ticket.details.labels.createdBy')}
      </Typography>
      <Grid alignItems="center" container spacing={1} wrap="nowrap">
        <Grid item>
          <Avatar src={user.profilePicture} />
        </Grid>
        <Grid item>
          <Typography color="primary" variant="subtitle2">
            {name}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {t(roleKey)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
