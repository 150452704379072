import Button from '@mui/material/Button';
import type { FC, MouseEventHandler, SVGProps } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from '../../../translations';
import { sxProps } from './icon.styles';

type Props = {
  dataTestId: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  handleOpen?: MouseEventHandler<HTMLButtonElement>;
  labelKey?: GenericTypes.TranslationLabel;
  to?: string;
};

export const Icon: FC<Props> = (props) => {
  const { dataTestId, icon: Icon, handleOpen, labelKey, to } = props;
  const { t } = useTranslation();

  return (
    <Button
      data-testid={dataTestId}
      {...(to ? { component: Link, to } : { onClick: handleOpen })}
      color="primary"
      sx={sxProps.button}
      variant="contained"
    >
      <Icon height={16} width={16} />
      {labelKey && t(labelKey)}
    </Button>
  );
};
