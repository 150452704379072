import createSvgIcon from '@mui/material/utils/createSvgIcon';
import React from 'react';

export const ChevronUpIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7074 12.7068C14.5199 12.8943 14.2656 12.9996 14.0004
      12.9996C13.7352 12.9996 13.4809 12.8943 13.2934 12.7068L10.0004
      9.41379L6.70741 12.7068C6.5188 12.8889 6.2662 12.9897 6.00401
      12.9875C5.74181 12.9852 5.491 12.88 5.30559 12.6946C5.12018 12.5092
      5.01501 12.2584 5.01273 11.9962C5.01045 11.734 5.11125 11.4814
      5.29341 11.2928L9.29341 7.29279C9.48094 7.10532 9.73524 7 10.0004
      7C10.2656 7 10.5199 7.10532 10.7074 7.29279L14.7074 11.2928C14.8949
      11.4803 15.0002 11.7346 15.0002 11.9998C15.0002 12.265 14.8949
      12.5193 14.7074 12.7068Z"
    />
  </svg>,
  'ChevronUp',
);
