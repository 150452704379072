export enum Permission {
  CAN_CHANGE_COMPANY = 'can_change_company',
  CAN_CREATE_ATTACHMENTS = 'can_create_attachments',
  CAN_CREATE_ADVERTISEMENT = 'can_create_advertisement_permission',
  CAN_CREATE_COMPANY = 'can_create_company',
  CAN_CREATE_LEASE_TEMPLATE = 'can_create_lease_template',
  CAN_CREATE_PROPERTY = 'create_property',
  CAN_CREATE_PROPERTY_OBJECT = 'create_property_object',
  CAN_CREATE_PROPERTY_OBJECT_CONTACT = 'create_property_object_contact',
  CAN_CREATE_PROSPECT = 'can_create_prospect_permission',
  CAN_CREATE_UNIT_CONTACT = 'create_unit_contact',
  CAN_CREATE_UNITS = 'create_units',
  CAN_DELETE_UNIT_CONTACT = 'delete_unit_contact',
  CAN_DELETE_PROSPECT = 'can_delete_prospect_permission',
  CAN_DELETE_PROPERTY_OBJECT_CONTACT = 'delete_property_object_contact',
  CAN_UPDATE_ADVERTISEMENT = 'can_update_advertisement_permission',
  CAN_UPDATE_PROPERTY = 'update_property',
  CAN_UPDATE_PROPERTY_OBJECT = 'update_property_object',
  CAN_UPDATE_PROPERTY_OBJECT_CONTACT = 'update_property_object_contact',
  CAN_UPDATE_UNIT_CONTACT = 'update_unit_contact',
  CAN_UPDATE_UNITS = 'update_units',
  CAN_VIEW_ADVERTISEMENT = 'can_view_advertisement_permission',
  CAN_VIEW_AUDIT_LOGS = 'view_auditlogs',
  CAN_VIEW_COMPANY = 'can_view_company',
  CAN_VIEW_LEASE = 'can_view_lease',
  CAN_VIEW_LEASE_TEMPLATE = 'can_view_lease_template',
  CAN_VIEW_PROPERTY = 'view_property',
  CAN_VIEW_PROPERTY_OBJECT = 'view_property_object',
  CAN_VIEW_PROPERTY_OBJECT_CONTACT = 'view_property_object_contact',
  CAN_VIEW_PROSPECT = 'can_view_prospect_permission',
  CAN_VIEW_UNIT_CONTACT = 'view_unit_contact',
  CAN_VIEW_UNITS = 'view_units',
  GLOBAL_READ = 'global_read',
  GLOBAL_WRITE = 'global_write',
  READ_COMPANY = 'read_company',
  READ_CONTRACT = 'read_contract',
  READ_PROPERTY = 'read_property',
  READ_TICKETING = 'read_ticketing',
  READ_USER = 'read_user',
  VIEW_ATTACHMENTS = 'view_attachments',
  VIEW_EMPLOYEES = 'view_employees',
  VIEW_EXTERNAL_EMPLOYEES = 'view_external_employees',
  VIEW_TENANTS = 'view_tenants',
  WRITE_COMPANY = 'write_company',
  WRITE_CONTRACT = 'write_contract',
  WRITE_PROPERTY = 'write_property',
  WRITE_TICKETING = 'write_ticketing',
  WRITE_USER = 'write_user',
}
