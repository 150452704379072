/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttachmentCategoryEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { AttachmentTypeEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentVisibilityEnumDTO } from '../dto';
// @ts-ignore
import { AwaitingExternalProviderSerializerDTO } from '../dto';
// @ts-ignore
import { AwaitingTenantSerializerDTO } from '../dto';
// @ts-ignore
import { CommentSerializerDTO } from '../dto';
// @ts-ignore
import { CommentVisibilityEnumDTO } from '../dto';
// @ts-ignore
import { ComplaintTypeEnumDTO } from '../dto';
// @ts-ignore
import { ContractRequestTypeEnumDTO } from '../dto';
// @ts-ignore
import { CreateCommentSerializerDTO } from '../dto';
// @ts-ignore
import { CreateReponseTemplateSerializerDTO } from '../dto';
// @ts-ignore
import { CreateTimeSpentOnTicketSerializerDTO } from '../dto';
// @ts-ignore
import { DepartmentTypeEnumDTO } from '../dto';
// @ts-ignore
import { DoneSerializerDTO } from '../dto';
// @ts-ignore
import { EntityEnumDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { InvoiceRequestTypeEnumDTO } from '../dto';
// @ts-ignore
import { IssueSubtypeEnumDTO } from '../dto';
// @ts-ignore
import { IssueTypeEnumDTO } from '../dto';
// @ts-ignore
import { LanguageDTO } from '../dto';
// @ts-ignore
import { ListAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ListCommentSerializerDTO } from '../dto';
// @ts-ignore
import { ListCommentWithTicketSerializerDTO } from '../dto';
// @ts-ignore
import { ListLogSerializerDTO } from '../dto';
// @ts-ignore
import { ListResponseTemplateSerializerDTO } from '../dto';
// @ts-ignore
import { ListTicketsWithAdditionalFieldsSerializerDTO } from '../dto';
// @ts-ignore
import { ListTimeSpentOnTicketSerializerDTO } from '../dto';
// @ts-ignore
import { LocationTypeEnumDTO } from '../dto';
// @ts-ignore
import { OnHoldSerializerDTO } from '../dto';
// @ts-ignore
import { PatchCommentSerializerDTO } from '../dto';
// @ts-ignore
import { PatchTimeSpentOnTicketSerializerDTO } from '../dto';
// @ts-ignore
import { PriorityTypeEnumDTO } from '../dto';
// @ts-ignore
import { ReassignTicketResponseSerializerDTO } from '../dto';
// @ts-ignore
import { ReassignTicketSerializerDTO } from '../dto';
// @ts-ignore
import { ResponseTemplateSerializerDTO } from '../dto';
// @ts-ignore
import { ResponseTemplateVisibilityEnumDTO } from '../dto';
// @ts-ignore
import { RoomTypeEnumDTO } from '../dto';
// @ts-ignore
import { SerializersTicketSerializerDTO } from '../dto';
// @ts-ignore
import { TicketMetadataSerializerDTO } from '../dto';
// @ts-ignore
import { TicketTimeSpentSumByUserSerializerDTO } from '../dto';
// @ts-ignore
import { TicketTypeEnumDTO } from '../dto';
// @ts-ignore
import { TicketsCountByStatusSerializerDTO } from '../dto';
// @ts-ignore
import { TimeSpentOnTicketSerializerDTO } from '../dto';
/**
 * TicketsAPI - axios parameter creator
 * @export
 */
export const TicketsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Ticket Comment
         * @param {string} ticketUuid 
         * @param {CreateCommentSerializerDTO} createCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTicketCommentTicketsTicketUuidCommentsPost: async (ticketUuid: string, createCommentSerializerDTO: CreateCommentSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('addTicketCommentTicketsTicketUuidCommentsPost', 'ticketUuid', ticketUuid)
            // verify required parameter 'createCommentSerializerDTO' is not null or undefined
            assertParamExists('addTicketCommentTicketsTicketUuidCommentsPost', 'createCommentSerializerDTO', createCommentSerializerDTO)
            const localVarPath = `/tickets/{ticket_uuid}/comments`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommentSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change Status To Awaiting External Provider
         * @param {string} ticketUuid 
         * @param {AwaitingExternalProviderSerializerDTO} awaitingExternalProviderSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost: async (ticketUuid: string, awaitingExternalProviderSerializerDTO: AwaitingExternalProviderSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('changeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost', 'ticketUuid', ticketUuid)
            // verify required parameter 'awaitingExternalProviderSerializerDTO' is not null or undefined
            assertParamExists('changeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost', 'awaitingExternalProviderSerializerDTO', awaitingExternalProviderSerializerDTO)
            const localVarPath = `/tickets/{ticket_uuid}/awaiting_external_provider`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(awaitingExternalProviderSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change Status To Awaiting Tenant
         * @param {string} ticketUuid 
         * @param {AwaitingTenantSerializerDTO} awaitingTenantSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost: async (ticketUuid: string, awaitingTenantSerializerDTO: AwaitingTenantSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('changeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost', 'ticketUuid', ticketUuid)
            // verify required parameter 'awaitingTenantSerializerDTO' is not null or undefined
            assertParamExists('changeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost', 'awaitingTenantSerializerDTO', awaitingTenantSerializerDTO)
            const localVarPath = `/tickets/{ticket_uuid}/awaiting_tenant`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(awaitingTenantSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change Status To Done
         * @param {string} ticketUuid 
         * @param {DoneSerializerDTO} doneSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusToDoneTicketsTicketUuidDonePost: async (ticketUuid: string, doneSerializerDTO: DoneSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('changeStatusToDoneTicketsTicketUuidDonePost', 'ticketUuid', ticketUuid)
            // verify required parameter 'doneSerializerDTO' is not null or undefined
            assertParamExists('changeStatusToDoneTicketsTicketUuidDonePost', 'doneSerializerDTO', doneSerializerDTO)
            const localVarPath = `/tickets/{ticket_uuid}/done`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doneSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change Status To In Progress
         * @param {string} ticketUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusToInProgressTicketsTicketUuidInProgressPost: async (ticketUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('changeStatusToInProgressTicketsTicketUuidInProgressPost', 'ticketUuid', ticketUuid)
            const localVarPath = `/tickets/{ticket_uuid}/in_progress`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change Status To On Hold
         * @param {string} ticketUuid 
         * @param {OnHoldSerializerDTO} onHoldSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusToOnHoldTicketsTicketUuidOnHoldPost: async (ticketUuid: string, onHoldSerializerDTO: OnHoldSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('changeStatusToOnHoldTicketsTicketUuidOnHoldPost', 'ticketUuid', ticketUuid)
            // verify required parameter 'onHoldSerializerDTO' is not null or undefined
            assertParamExists('changeStatusToOnHoldTicketsTicketUuidOnHoldPost', 'onHoldSerializerDTO', onHoldSerializerDTO)
            const localVarPath = `/tickets/{ticket_uuid}/on_hold`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(onHoldSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Response Template
         * @param {CreateReponseTemplateSerializerDTO} createReponseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResponseTemplateTicketsResponseTemplatesPost: async (createReponseTemplateSerializerDTO: CreateReponseTemplateSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReponseTemplateSerializerDTO' is not null or undefined
            assertParamExists('createResponseTemplateTicketsResponseTemplatesPost', 'createReponseTemplateSerializerDTO', createReponseTemplateSerializerDTO)
            const localVarPath = `/tickets/response_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReponseTemplateSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Time Spent On Ticket Info
         * @param {string} ticketUuid 
         * @param {CreateTimeSpentOnTicketSerializerDTO} createTimeSpentOnTicketSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost: async (ticketUuid: string, createTimeSpentOnTicketSerializerDTO: CreateTimeSpentOnTicketSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('createTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost', 'ticketUuid', ticketUuid)
            // verify required parameter 'createTimeSpentOnTicketSerializerDTO' is not null or undefined
            assertParamExists('createTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost', 'createTimeSpentOnTicketSerializerDTO', createTimeSpentOnTicketSerializerDTO)
            const localVarPath = `/tickets/{ticket_uuid}/time-spent`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTimeSpentOnTicketSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Comment Attachment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete: async (ticketUuid: string, commentUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('deleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete', 'ticketUuid', ticketUuid)
            // verify required parameter 'commentUuid' is not null or undefined
            assertParamExists('deleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete', 'commentUuid', commentUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('deleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/tickets/{ticket_uuid}/comments/{comment_uuid}/attachments/{attachment_uuid}`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)))
                .replace(`{${"comment_uuid"}}`, encodeURIComponent(String(commentUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Response Template
         * @param {string} responseTemplateUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete: async (responseTemplateUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseTemplateUuid' is not null or undefined
            assertParamExists('deleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete', 'responseTemplateUuid', responseTemplateUuid)
            const localVarPath = `/tickets/response_templates/{response_template_uuid}`
                .replace(`{${"response_template_uuid"}}`, encodeURIComponent(String(responseTemplateUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Ticket Attachment
         * @param {string} ticketUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete: async (ticketUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('deleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete', 'ticketUuid', ticketUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('deleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/tickets/{ticket_uuid}/attachments/{attachment_uuid}`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Ticket Comment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete: async (ticketUuid: string, commentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('deleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete', 'ticketUuid', ticketUuid)
            // verify required parameter 'commentUuid' is not null or undefined
            assertParamExists('deleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete', 'commentUuid', commentUuid)
            const localVarPath = `/tickets/{ticket_uuid}/comments/{comment_uuid}`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)))
                .replace(`{${"comment_uuid"}}`, encodeURIComponent(String(commentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Ticket
         * @param {string} ticketUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketTicketsTicketUuidDelete: async (ticketUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('deleteTicketTicketsTicketUuidDelete', 'ticketUuid', ticketUuid)
            const localVarPath = `/tickets/{ticket_uuid}`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Time Spent On Ticket Info
         * @param {string} timeSpentInfoUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDelete: async (timeSpentInfoUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeSpentInfoUuid' is not null or undefined
            assertParamExists('deleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDelete', 'timeSpentInfoUuid', timeSpentInfoUuid)
            const localVarPath = `/tickets/time-spent/{time_spent_info_uuid}`
                .replace(`{${"time_spent_info_uuid"}}`, encodeURIComponent(String(timeSpentInfoUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Ticket Attachment
         * @param {string} ticketUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet: async (ticketUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('downloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet', 'ticketUuid', ticketUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('downloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/tickets/{ticket_uuid}/attachments/{attachment_uuid}/download`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Time Spent On Ticket Info
         * @param {string} ticketUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [userUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet: async (ticketUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, countryUuid?: string, userUuid?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('getAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet', 'ticketUuid', ticketUuid)
            const localVarPath = `/tickets/{ticket_uuid}/time-spent`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (userUuid !== undefined) {
                localVarQueryParameter['user_uuid'] = userUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Comment Attachments
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet: async (ticketUuid: string, commentUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('getCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet', 'ticketUuid', ticketUuid)
            // verify required parameter 'commentUuid' is not null or undefined
            assertParamExists('getCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet', 'commentUuid', commentUuid)
            const localVarPath = `/tickets/{ticket_uuid}/comments/{comment_uuid}/attachments`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)))
                .replace(`{${"comment_uuid"}}`, encodeURIComponent(String(commentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Comments By Country With Additional Ticket Data
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [userRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet: async (countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, userRole?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryUuid' is not null or undefined
            assertParamExists('getCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet', 'countryUuid', countryUuid)
            const localVarPath = `/tickets/comments/{country_uuid}`
                .replace(`{${"country_uuid"}}`, encodeURIComponent(String(countryUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (userRole !== undefined) {
                localVarQueryParameter['user_role'] = userRole;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Particular Time Spent On Ticket Info
         * @param {string} timeSpentInfoUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGet: async (timeSpentInfoUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeSpentInfoUuid' is not null or undefined
            assertParamExists('getParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGet', 'timeSpentInfoUuid', timeSpentInfoUuid)
            const localVarPath = `/tickets/time-spent/{time_spent_info_uuid}`
                .replace(`{${"time_spent_info_uuid"}}`, encodeURIComponent(String(timeSpentInfoUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Response Templates
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [content] 
         * @param {string} [name] 
         * @param {string} [countryUuid] 
         * @param {string} [authorUuid] 
         * @param {ResponseTemplateVisibilityEnumDTO} [visibility] 
         * @param {LanguageDTO} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResponseTemplatesTicketsResponseTemplatesGet: async (page?: number, pageSize?: number, order?: string, search?: string, content?: string, name?: string, countryUuid?: string, authorUuid?: string, visibility?: ResponseTemplateVisibilityEnumDTO, language?: LanguageDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets/response_templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (authorUuid !== undefined) {
                localVarQueryParameter['author_uuid'] = authorUuid;
            }

            if (visibility !== undefined) {
                localVarQueryParameter['visibility'] = visibility;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Service Requests For Entity
         * @param {TicketTypeEnumDTO} [ticketType] 
         * @param {string} [assigneeUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceRequestsForEntityTicketsCountsGet: async (ticketType?: TicketTypeEnumDTO, assigneeUuid?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets/counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (ticketType !== undefined) {
                localVarQueryParameter['ticket_type'] = ticketType;
            }

            if (assigneeUuid !== undefined) {
                localVarQueryParameter['assignee_uuid'] = assigneeUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Response Template
         * @param {string} responseTemplateUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGet: async (responseTemplateUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseTemplateUuid' is not null or undefined
            assertParamExists('getSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGet', 'responseTemplateUuid', responseTemplateUuid)
            const localVarPath = `/tickets/response_templates/{response_template_uuid}`
                .replace(`{${"response_template_uuid"}}`, encodeURIComponent(String(responseTemplateUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ticket Attachments
         * @param {string} ticketUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAttachmentsTicketsTicketUuidAttachmentsGet: async (ticketUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('getTicketAttachmentsTicketsTicketUuidAttachmentsGet', 'ticketUuid', ticketUuid)
            const localVarPath = `/tickets/{ticket_uuid}/attachments`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ticket Auditlogs
         * @param {string} ticketUuid 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAuditlogsTicketsTicketUuidAuditlogsGet: async (ticketUuid: string, excludeFields?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('getTicketAuditlogsTicketsTicketUuidAuditlogsGet', 'ticketUuid', ticketUuid)
            const localVarPath = `/tickets/{ticket_uuid}/auditlogs`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (excludeFields !== undefined) {
                localVarQueryParameter['exclude_fields'] = excludeFields;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ticket Comments
         * @param {string} ticketUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {CommentVisibilityEnumDTO} [visibility] 
         * @param {string} [entityUuid] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketCommentsTicketsTicketUuidCommentsGet: async (ticketUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, visibility?: CommentVisibilityEnumDTO, entityUuid?: string, entityType?: EntityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('getTicketCommentsTicketsTicketUuidCommentsGet', 'ticketUuid', ticketUuid)
            const localVarPath = `/tickets/{ticket_uuid}/comments`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (visibility !== undefined) {
                localVarQueryParameter['visibility'] = visibility;
            }

            if (entityUuid !== undefined) {
                localVarQueryParameter['entity_uuid'] = entityUuid;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ticket Metadata For Country
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketMetadataForCountryTicketsCountryUuidMetadataGet: async (countryUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryUuid' is not null or undefined
            assertParamExists('getTicketMetadataForCountryTicketsCountryUuidMetadataGet', 'countryUuid', countryUuid)
            const localVarPath = `/tickets/{country_uuid}/metadata`
                .replace(`{${"country_uuid"}}`, encodeURIComponent(String(countryUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tickets
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {TicketTypeEnumDTO} [ticketType] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {string} [summary] 
         * @param {string} [reporterUuid] 
         * @param {string} [assigneeUuid] 
         * @param {boolean} [unassigned] 
         * @param {string} [entityUuid] 
         * @param {string} [propertyObjectUuid] 
         * @param {string} [propertyUuid] 
         * @param {InvoiceRequestTypeEnumDTO | ContractRequestTypeEnumDTO} [ticketSubtype] 
         * @param {IssueTypeEnumDTO} [issueType] 
         * @param {IssueSubtypeEnumDTO} [issueSubtype] 
         * @param {LocationTypeEnumDTO} [issueLocation] 
         * @param {RoomTypeEnumDTO} [roomWithIssue] 
         * @param {PriorityTypeEnumDTO} [priority] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {string} [status] 
         * @param {ComplaintTypeEnumDTO} [complaintType] 
         * @param {string} [referenceNumber] 
         * @param {string} [description] 
         * @param {string} [addressLine] 
         * @param {string} [dueDateLt] 
         * @param {string} [dueDateGt] 
         * @param {string} [cityUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {string} [doneAtGte] 
         * @param {string} [uuids] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketsTicketsGet: async (page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, ticketType?: TicketTypeEnumDTO, search?: string, name?: string, summary?: string, reporterUuid?: string, assigneeUuid?: string, unassigned?: boolean, entityUuid?: string, propertyObjectUuid?: string, propertyUuid?: string, ticketSubtype?: InvoiceRequestTypeEnumDTO | ContractRequestTypeEnumDTO, issueType?: IssueTypeEnumDTO, issueSubtype?: IssueSubtypeEnumDTO, issueLocation?: LocationTypeEnumDTO, roomWithIssue?: RoomTypeEnumDTO, priority?: PriorityTypeEnumDTO, entityType?: EntityEnumDTO, status?: string, complaintType?: ComplaintTypeEnumDTO, referenceNumber?: string, description?: string, addressLine?: string, dueDateLt?: string, dueDateGt?: string, cityUuid?: string, regionUuid?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, doneAtGte?: string, uuids?: string, businessRegion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (ticketType !== undefined) {
                localVarQueryParameter['ticket_type'] = ticketType;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (summary !== undefined) {
                localVarQueryParameter['summary'] = summary;
            }

            if (reporterUuid !== undefined) {
                localVarQueryParameter['reporter_uuid'] = reporterUuid;
            }

            if (assigneeUuid !== undefined) {
                localVarQueryParameter['assignee_uuid'] = assigneeUuid;
            }

            if (unassigned !== undefined) {
                localVarQueryParameter['unassigned'] = unassigned;
            }

            if (entityUuid !== undefined) {
                localVarQueryParameter['entity_uuid'] = entityUuid;
            }

            if (propertyObjectUuid !== undefined) {
                localVarQueryParameter['property_object_uuid'] = propertyObjectUuid;
            }

            if (propertyUuid !== undefined) {
                localVarQueryParameter['property_uuid'] = propertyUuid;
            }

            if (ticketSubtype !== undefined) {
                localVarQueryParameter['ticket_subtype'] = ticketSubtype;
            }

            if (issueType !== undefined) {
                localVarQueryParameter['issue_type'] = issueType;
            }

            if (issueSubtype !== undefined) {
                localVarQueryParameter['issue_subtype'] = issueSubtype;
            }

            if (issueLocation !== undefined) {
                localVarQueryParameter['issue_location'] = issueLocation;
            }

            if (roomWithIssue !== undefined) {
                localVarQueryParameter['room_with_issue'] = roomWithIssue;
            }

            if (priority !== undefined) {
                localVarQueryParameter['priority'] = priority;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (complaintType !== undefined) {
                localVarQueryParameter['complaint_type'] = complaintType;
            }

            if (referenceNumber !== undefined) {
                localVarQueryParameter['reference_number'] = referenceNumber;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (addressLine !== undefined) {
                localVarQueryParameter['address_line'] = addressLine;
            }

            if (dueDateLt !== undefined) {
                localVarQueryParameter['due_date_lt'] = (dueDateLt as any instanceof Date) ?
                    (dueDateLt as any).toISOString() :
                    dueDateLt;
            }

            if (dueDateGt !== undefined) {
                localVarQueryParameter['due_date_gt'] = (dueDateGt as any instanceof Date) ?
                    (dueDateGt as any).toISOString() :
                    dueDateGt;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (doneAtGte !== undefined) {
                localVarQueryParameter['done_at_gte'] = (doneAtGte as any instanceof Date) ?
                    (doneAtGte as any).toISOString().substr(0,10) :
                    doneAtGte;
            }

            if (uuids !== undefined) {
                localVarQueryParameter['uuids'] = uuids;
            }

            if (businessRegion !== undefined) {
                localVarQueryParameter['business_region'] = businessRegion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Time Spent On Ticket Sum
         * @param {string} ticketUuid 
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet: async (ticketUuid: string, userUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('getTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet', 'ticketUuid', ticketUuid)
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('getTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet', 'userUuid', userUuid)
            const localVarPath = `/tickets/{ticket_uuid}/user/{user_uuid}/time-spent-sum`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)))
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Ticket Comment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {PatchCommentSerializerDTO} patchCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch: async (ticketUuid: string, commentUuid: string, patchCommentSerializerDTO: PatchCommentSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('patchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch', 'ticketUuid', ticketUuid)
            // verify required parameter 'commentUuid' is not null or undefined
            assertParamExists('patchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch', 'commentUuid', commentUuid)
            // verify required parameter 'patchCommentSerializerDTO' is not null or undefined
            assertParamExists('patchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch', 'patchCommentSerializerDTO', patchCommentSerializerDTO)
            const localVarPath = `/tickets/{ticket_uuid}/comments/{comment_uuid}`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)))
                .replace(`{${"comment_uuid"}}`, encodeURIComponent(String(commentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCommentSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Time Spent On Ticket Info
         * @param {string} timeSpentInfoUuid 
         * @param {PatchTimeSpentOnTicketSerializerDTO} patchTimeSpentOnTicketSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch: async (timeSpentInfoUuid: string, patchTimeSpentOnTicketSerializerDTO: PatchTimeSpentOnTicketSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timeSpentInfoUuid' is not null or undefined
            assertParamExists('patchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch', 'timeSpentInfoUuid', timeSpentInfoUuid)
            // verify required parameter 'patchTimeSpentOnTicketSerializerDTO' is not null or undefined
            assertParamExists('patchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch', 'patchTimeSpentOnTicketSerializerDTO', patchTimeSpentOnTicketSerializerDTO)
            const localVarPath = `/tickets/time-spent/{time_spent_info_uuid}`
                .replace(`{${"time_spent_info_uuid"}}`, encodeURIComponent(String(timeSpentInfoUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTimeSpentOnTicketSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reassign
         * @param {ReassignTicketSerializerDTO} reassignTicketSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignTicketsReassignPost: async (reassignTicketSerializerDTO: ReassignTicketSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reassignTicketSerializerDTO' is not null or undefined
            assertParamExists('reassignTicketsReassignPost', 'reassignTicketSerializerDTO', reassignTicketSerializerDTO)
            const localVarPath = `/tickets/reassign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reassignTicketSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Response Template
         * @param {string} responseTemplateUuid 
         * @param {CreateReponseTemplateSerializerDTO} createReponseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut: async (responseTemplateUuid: string, createReponseTemplateSerializerDTO: CreateReponseTemplateSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseTemplateUuid' is not null or undefined
            assertParamExists('updateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut', 'responseTemplateUuid', responseTemplateUuid)
            // verify required parameter 'createReponseTemplateSerializerDTO' is not null or undefined
            assertParamExists('updateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut', 'createReponseTemplateSerializerDTO', createReponseTemplateSerializerDTO)
            const localVarPath = `/tickets/response_templates/{response_template_uuid}`
                .replace(`{${"response_template_uuid"}}`, encodeURIComponent(String(responseTemplateUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReponseTemplateSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Comment Attachment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost: async (ticketUuid: string, commentUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost', 'ticketUuid', ticketUuid)
            // verify required parameter 'commentUuid' is not null or undefined
            assertParamExists('uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost', 'commentUuid', commentUuid)
            // verify required parameter 'attachmentCategory' is not null or undefined
            assertParamExists('uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost', 'attachmentCategory', attachmentCategory)
            // verify required parameter 'attachmentType' is not null or undefined
            assertParamExists('uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost', 'attachmentType', attachmentType)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost', 'files', files)
            const localVarPath = `/tickets/{ticket_uuid}/comments/{comment_uuid}/attachments`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)))
                .replace(`{${"comment_uuid"}}`, encodeURIComponent(String(commentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


            if (attachmentCategory !== undefined) { 
                localVarFormParams.append('attachment_category', new Blob([JSON.stringify(attachmentCategory)], { type: "application/json", }));
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', new Blob([JSON.stringify(attachmentType)], { type: "application/json", }));
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (visibility !== undefined) { 
                localVarFormParams.append('visibility', new Blob([JSON.stringify(visibility)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Ticket Attachment
         * @param {string} ticketUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTicketAttachmentTicketsTicketUuidAttachmentsPost: async (ticketUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketUuid' is not null or undefined
            assertParamExists('uploadTicketAttachmentTicketsTicketUuidAttachmentsPost', 'ticketUuid', ticketUuid)
            // verify required parameter 'attachmentCategory' is not null or undefined
            assertParamExists('uploadTicketAttachmentTicketsTicketUuidAttachmentsPost', 'attachmentCategory', attachmentCategory)
            // verify required parameter 'attachmentType' is not null or undefined
            assertParamExists('uploadTicketAttachmentTicketsTicketUuidAttachmentsPost', 'attachmentType', attachmentType)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadTicketAttachmentTicketsTicketUuidAttachmentsPost', 'files', files)
            const localVarPath = `/tickets/{ticket_uuid}/attachments`
                .replace(`{${"ticket_uuid"}}`, encodeURIComponent(String(ticketUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


            if (attachmentCategory !== undefined) { 
                localVarFormParams.append('attachment_category', new Blob([JSON.stringify(attachmentCategory)], { type: "application/json", }));
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', new Blob([JSON.stringify(attachmentType)], { type: "application/json", }));
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (visibility !== undefined) { 
                localVarFormParams.append('visibility', new Blob([JSON.stringify(visibility)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateImportDataFormatTicketsDataImportPost: async (file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('validateImportDataFormatTicketsDataImportPost', 'file', file)
            const localVarPath = `/tickets/data_import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketsAPI - functional programming interface
 * @export
 */
export const TicketsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Ticket Comment
         * @param {string} ticketUuid 
         * @param {CreateCommentSerializerDTO} createCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTicketCommentTicketsTicketUuidCommentsPost(ticketUuid: string, createCommentSerializerDTO: CreateCommentSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTicketCommentTicketsTicketUuidCommentsPost(ticketUuid, createCommentSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change Status To Awaiting External Provider
         * @param {string} ticketUuid 
         * @param {AwaitingExternalProviderSerializerDTO} awaitingExternalProviderSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost(ticketUuid: string, awaitingExternalProviderSerializerDTO: AwaitingExternalProviderSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SerializersTicketSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost(ticketUuid, awaitingExternalProviderSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change Status To Awaiting Tenant
         * @param {string} ticketUuid 
         * @param {AwaitingTenantSerializerDTO} awaitingTenantSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost(ticketUuid: string, awaitingTenantSerializerDTO: AwaitingTenantSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SerializersTicketSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost(ticketUuid, awaitingTenantSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change Status To Done
         * @param {string} ticketUuid 
         * @param {DoneSerializerDTO} doneSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStatusToDoneTicketsTicketUuidDonePost(ticketUuid: string, doneSerializerDTO: DoneSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SerializersTicketSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStatusToDoneTicketsTicketUuidDonePost(ticketUuid, doneSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change Status To In Progress
         * @param {string} ticketUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStatusToInProgressTicketsTicketUuidInProgressPost(ticketUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SerializersTicketSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStatusToInProgressTicketsTicketUuidInProgressPost(ticketUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change Status To On Hold
         * @param {string} ticketUuid 
         * @param {OnHoldSerializerDTO} onHoldSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStatusToOnHoldTicketsTicketUuidOnHoldPost(ticketUuid: string, onHoldSerializerDTO: OnHoldSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SerializersTicketSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStatusToOnHoldTicketsTicketUuidOnHoldPost(ticketUuid, onHoldSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Response Template
         * @param {CreateReponseTemplateSerializerDTO} createReponseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResponseTemplateTicketsResponseTemplatesPost(createReponseTemplateSerializerDTO: CreateReponseTemplateSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseTemplateSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createResponseTemplateTicketsResponseTemplatesPost(createReponseTemplateSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Time Spent On Ticket Info
         * @param {string} ticketUuid 
         * @param {CreateTimeSpentOnTicketSerializerDTO} createTimeSpentOnTicketSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost(ticketUuid: string, createTimeSpentOnTicketSerializerDTO: CreateTimeSpentOnTicketSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSpentOnTicketSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost(ticketUuid, createTimeSpentOnTicketSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Comment Attachment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete(ticketUuid: string, commentUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete(ticketUuid, commentUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Response Template
         * @param {string} responseTemplateUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete(responseTemplateUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseTemplateSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete(responseTemplateUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Ticket Attachment
         * @param {string} ticketUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete(ticketUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete(ticketUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Ticket Comment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete(ticketUuid: string, commentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete(ticketUuid, commentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Ticket
         * @param {string} ticketUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicketTicketsTicketUuidDelete(ticketUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicketTicketsTicketUuidDelete(ticketUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Time Spent On Ticket Info
         * @param {string} timeSpentInfoUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDelete(timeSpentInfoUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDelete(timeSpentInfoUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Ticket Attachment
         * @param {string} ticketUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet(ticketUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet(ticketUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get All Time Spent On Ticket Info
         * @param {string} ticketUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [userUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet(ticketUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, countryUuid?: string, userUuid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTimeSpentOnTicketSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet(ticketUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, countryUuid, userUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Comment Attachments
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet(ticketUuid: string, commentUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet(ticketUuid, commentUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Comments By Country With Additional Ticket Data
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [userRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet(countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, userRole?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommentWithTicketSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet(countryUuid, page, pageSize, order, search, userRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Particular Time Spent On Ticket Info
         * @param {string} timeSpentInfoUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGet(timeSpentInfoUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSpentOnTicketSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGet(timeSpentInfoUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Response Templates
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [content] 
         * @param {string} [name] 
         * @param {string} [countryUuid] 
         * @param {string} [authorUuid] 
         * @param {ResponseTemplateVisibilityEnumDTO} [visibility] 
         * @param {LanguageDTO} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResponseTemplatesTicketsResponseTemplatesGet(page?: number, pageSize?: number, order?: string, search?: string, content?: string, name?: string, countryUuid?: string, authorUuid?: string, visibility?: ResponseTemplateVisibilityEnumDTO, language?: LanguageDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseTemplateSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResponseTemplatesTicketsResponseTemplatesGet(page, pageSize, order, search, content, name, countryUuid, authorUuid, visibility, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Service Requests For Entity
         * @param {TicketTypeEnumDTO} [ticketType] 
         * @param {string} [assigneeUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceRequestsForEntityTicketsCountsGet(ticketType?: TicketTypeEnumDTO, assigneeUuid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketsCountByStatusSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceRequestsForEntityTicketsCountsGet(ticketType, assigneeUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Response Template
         * @param {string} responseTemplateUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGet(responseTemplateUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseTemplateSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGet(responseTemplateUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Ticket Attachments
         * @param {string} ticketUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketAttachmentsTicketsTicketUuidAttachmentsGet(ticketUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketAttachmentsTicketsTicketUuidAttachmentsGet(ticketUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Ticket Auditlogs
         * @param {string} ticketUuid 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketAuditlogsTicketsTicketUuidAuditlogsGet(ticketUuid: string, excludeFields?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLogSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketAuditlogsTicketsTicketUuidAuditlogsGet(ticketUuid, excludeFields, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Ticket Comments
         * @param {string} ticketUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {CommentVisibilityEnumDTO} [visibility] 
         * @param {string} [entityUuid] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketCommentsTicketsTicketUuidCommentsGet(ticketUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, visibility?: CommentVisibilityEnumDTO, entityUuid?: string, entityType?: EntityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketCommentsTicketsTicketUuidCommentsGet(ticketUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, visibility, entityUuid, entityType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Ticket Metadata For Country
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketMetadataForCountryTicketsCountryUuidMetadataGet(countryUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketMetadataSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketMetadataForCountryTicketsCountryUuidMetadataGet(countryUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Tickets
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {TicketTypeEnumDTO} [ticketType] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {string} [summary] 
         * @param {string} [reporterUuid] 
         * @param {string} [assigneeUuid] 
         * @param {boolean} [unassigned] 
         * @param {string} [entityUuid] 
         * @param {string} [propertyObjectUuid] 
         * @param {string} [propertyUuid] 
         * @param {InvoiceRequestTypeEnumDTO | ContractRequestTypeEnumDTO} [ticketSubtype] 
         * @param {IssueTypeEnumDTO} [issueType] 
         * @param {IssueSubtypeEnumDTO} [issueSubtype] 
         * @param {LocationTypeEnumDTO} [issueLocation] 
         * @param {RoomTypeEnumDTO} [roomWithIssue] 
         * @param {PriorityTypeEnumDTO} [priority] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {string} [status] 
         * @param {ComplaintTypeEnumDTO} [complaintType] 
         * @param {string} [referenceNumber] 
         * @param {string} [description] 
         * @param {string} [addressLine] 
         * @param {string} [dueDateLt] 
         * @param {string} [dueDateGt] 
         * @param {string} [cityUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {string} [doneAtGte] 
         * @param {string} [uuids] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketsTicketsGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, ticketType?: TicketTypeEnumDTO, search?: string, name?: string, summary?: string, reporterUuid?: string, assigneeUuid?: string, unassigned?: boolean, entityUuid?: string, propertyObjectUuid?: string, propertyUuid?: string, ticketSubtype?: InvoiceRequestTypeEnumDTO | ContractRequestTypeEnumDTO, issueType?: IssueTypeEnumDTO, issueSubtype?: IssueSubtypeEnumDTO, issueLocation?: LocationTypeEnumDTO, roomWithIssue?: RoomTypeEnumDTO, priority?: PriorityTypeEnumDTO, entityType?: EntityEnumDTO, status?: string, complaintType?: ComplaintTypeEnumDTO, referenceNumber?: string, description?: string, addressLine?: string, dueDateLt?: string, dueDateGt?: string, cityUuid?: string, regionUuid?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, doneAtGte?: string, uuids?: string, businessRegion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTicketsWithAdditionalFieldsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketsTicketsGet(page, pageSize, order, updatedAtLte, updatedAtGt, ticketType, search, name, summary, reporterUuid, assigneeUuid, unassigned, entityUuid, propertyObjectUuid, propertyUuid, ticketSubtype, issueType, issueSubtype, issueLocation, roomWithIssue, priority, entityType, status, complaintType, referenceNumber, description, addressLine, dueDateLt, dueDateGt, cityUuid, regionUuid, countryUuid, department, doneAtGte, uuids, businessRegion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Time Spent On Ticket Sum
         * @param {string} ticketUuid 
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet(ticketUuid: string, userUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TicketTimeSpentSumByUserSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet(ticketUuid, userUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Ticket Comment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {PatchCommentSerializerDTO} patchCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch(ticketUuid: string, commentUuid: string, patchCommentSerializerDTO: PatchCommentSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch(ticketUuid, commentUuid, patchCommentSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Time Spent On Ticket Info
         * @param {string} timeSpentInfoUuid 
         * @param {PatchTimeSpentOnTicketSerializerDTO} patchTimeSpentOnTicketSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch(timeSpentInfoUuid: string, patchTimeSpentOnTicketSerializerDTO: PatchTimeSpentOnTicketSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSpentOnTicketSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch(timeSpentInfoUuid, patchTimeSpentOnTicketSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reassign
         * @param {ReassignTicketSerializerDTO} reassignTicketSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reassignTicketsReassignPost(reassignTicketSerializerDTO: ReassignTicketSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReassignTicketResponseSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reassignTicketsReassignPost(reassignTicketSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Response Template
         * @param {string} responseTemplateUuid 
         * @param {CreateReponseTemplateSerializerDTO} createReponseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut(responseTemplateUuid: string, createReponseTemplateSerializerDTO: CreateReponseTemplateSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseTemplateSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut(responseTemplateUuid, createReponseTemplateSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Comment Attachment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost(ticketUuid: string, commentUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttachmentSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost(ticketUuid, commentUuid, attachmentCategory, attachmentType, files, title, description, visibility, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Ticket Attachment
         * @param {string} ticketUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTicketAttachmentTicketsTicketUuidAttachmentsPost(ticketUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttachmentSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTicketAttachmentTicketsTicketUuidAttachmentsPost(ticketUuid, attachmentCategory, attachmentType, files, title, description, visibility, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateImportDataFormatTicketsDataImportPost(file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateImportDataFormatTicketsDataImportPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketsAPI - factory interface
 * @export
 */
export const TicketsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Add Ticket Comment
         * @param {string} ticketUuid 
         * @param {CreateCommentSerializerDTO} createCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTicketCommentTicketsTicketUuidCommentsPost(ticketUuid: string, createCommentSerializerDTO: CreateCommentSerializerDTO, options?: any): AxiosPromise<CommentSerializerDTO> {
            return localVarFp.addTicketCommentTicketsTicketUuidCommentsPost(ticketUuid, createCommentSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change Status To Awaiting External Provider
         * @param {string} ticketUuid 
         * @param {AwaitingExternalProviderSerializerDTO} awaitingExternalProviderSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost(ticketUuid: string, awaitingExternalProviderSerializerDTO: AwaitingExternalProviderSerializerDTO, options?: any): AxiosPromise<SerializersTicketSerializerDTO> {
            return localVarFp.changeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost(ticketUuid, awaitingExternalProviderSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change Status To Awaiting Tenant
         * @param {string} ticketUuid 
         * @param {AwaitingTenantSerializerDTO} awaitingTenantSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost(ticketUuid: string, awaitingTenantSerializerDTO: AwaitingTenantSerializerDTO, options?: any): AxiosPromise<SerializersTicketSerializerDTO> {
            return localVarFp.changeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost(ticketUuid, awaitingTenantSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change Status To Done
         * @param {string} ticketUuid 
         * @param {DoneSerializerDTO} doneSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusToDoneTicketsTicketUuidDonePost(ticketUuid: string, doneSerializerDTO: DoneSerializerDTO, options?: any): AxiosPromise<SerializersTicketSerializerDTO> {
            return localVarFp.changeStatusToDoneTicketsTicketUuidDonePost(ticketUuid, doneSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change Status To In Progress
         * @param {string} ticketUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusToInProgressTicketsTicketUuidInProgressPost(ticketUuid: string, options?: any): AxiosPromise<SerializersTicketSerializerDTO> {
            return localVarFp.changeStatusToInProgressTicketsTicketUuidInProgressPost(ticketUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change Status To On Hold
         * @param {string} ticketUuid 
         * @param {OnHoldSerializerDTO} onHoldSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusToOnHoldTicketsTicketUuidOnHoldPost(ticketUuid: string, onHoldSerializerDTO: OnHoldSerializerDTO, options?: any): AxiosPromise<SerializersTicketSerializerDTO> {
            return localVarFp.changeStatusToOnHoldTicketsTicketUuidOnHoldPost(ticketUuid, onHoldSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Response Template
         * @param {CreateReponseTemplateSerializerDTO} createReponseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResponseTemplateTicketsResponseTemplatesPost(createReponseTemplateSerializerDTO: CreateReponseTemplateSerializerDTO, options?: any): AxiosPromise<ResponseTemplateSerializerDTO> {
            return localVarFp.createResponseTemplateTicketsResponseTemplatesPost(createReponseTemplateSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Time Spent On Ticket Info
         * @param {string} ticketUuid 
         * @param {CreateTimeSpentOnTicketSerializerDTO} createTimeSpentOnTicketSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost(ticketUuid: string, createTimeSpentOnTicketSerializerDTO: CreateTimeSpentOnTicketSerializerDTO, options?: any): AxiosPromise<TimeSpentOnTicketSerializerDTO> {
            return localVarFp.createTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost(ticketUuid, createTimeSpentOnTicketSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Comment Attachment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete(ticketUuid: string, commentUuid: string, attachmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete(ticketUuid, commentUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Response Template
         * @param {string} responseTemplateUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete(responseTemplateUuid: string, options?: any): AxiosPromise<ResponseTemplateSerializerDTO> {
            return localVarFp.deleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete(responseTemplateUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Ticket Attachment
         * @param {string} ticketUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete(ticketUuid: string, attachmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete(ticketUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Ticket Comment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete(ticketUuid: string, commentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete(ticketUuid, commentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Ticket
         * @param {string} ticketUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketTicketsTicketUuidDelete(ticketUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTicketTicketsTicketUuidDelete(ticketUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Time Spent On Ticket Info
         * @param {string} timeSpentInfoUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDelete(timeSpentInfoUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDelete(timeSpentInfoUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Ticket Attachment
         * @param {string} ticketUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet(ticketUuid: string, attachmentUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet(ticketUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Time Spent On Ticket Info
         * @param {string} ticketUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [userUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet(ticketUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, countryUuid?: string, userUuid?: string, options?: any): AxiosPromise<ListTimeSpentOnTicketSerializerDTO> {
            return localVarFp.getAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet(ticketUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, countryUuid, userUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Comment Attachments
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet(ticketUuid: string, commentUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): AxiosPromise<ListAttachmentSerializerDTO> {
            return localVarFp.getCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet(ticketUuid, commentUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Comments By Country With Additional Ticket Data
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [userRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet(countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, userRole?: string, options?: any): AxiosPromise<ListCommentWithTicketSerializerDTO> {
            return localVarFp.getCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet(countryUuid, page, pageSize, order, search, userRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Particular Time Spent On Ticket Info
         * @param {string} timeSpentInfoUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGet(timeSpentInfoUuid: string, options?: any): AxiosPromise<TimeSpentOnTicketSerializerDTO> {
            return localVarFp.getParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGet(timeSpentInfoUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Response Templates
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [content] 
         * @param {string} [name] 
         * @param {string} [countryUuid] 
         * @param {string} [authorUuid] 
         * @param {ResponseTemplateVisibilityEnumDTO} [visibility] 
         * @param {LanguageDTO} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResponseTemplatesTicketsResponseTemplatesGet(page?: number, pageSize?: number, order?: string, search?: string, content?: string, name?: string, countryUuid?: string, authorUuid?: string, visibility?: ResponseTemplateVisibilityEnumDTO, language?: LanguageDTO, options?: any): AxiosPromise<ListResponseTemplateSerializerDTO> {
            return localVarFp.getResponseTemplatesTicketsResponseTemplatesGet(page, pageSize, order, search, content, name, countryUuid, authorUuid, visibility, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Service Requests For Entity
         * @param {TicketTypeEnumDTO} [ticketType] 
         * @param {string} [assigneeUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceRequestsForEntityTicketsCountsGet(ticketType?: TicketTypeEnumDTO, assigneeUuid?: string, options?: any): AxiosPromise<TicketsCountByStatusSerializerDTO> {
            return localVarFp.getServiceRequestsForEntityTicketsCountsGet(ticketType, assigneeUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Response Template
         * @param {string} responseTemplateUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGet(responseTemplateUuid: string, options?: any): AxiosPromise<ResponseTemplateSerializerDTO> {
            return localVarFp.getSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGet(responseTemplateUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ticket Attachments
         * @param {string} ticketUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAttachmentsTicketsTicketUuidAttachmentsGet(ticketUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): AxiosPromise<ListAttachmentSerializerDTO> {
            return localVarFp.getTicketAttachmentsTicketsTicketUuidAttachmentsGet(ticketUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ticket Auditlogs
         * @param {string} ticketUuid 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAuditlogsTicketsTicketUuidAuditlogsGet(ticketUuid: string, excludeFields?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ListLogSerializerDTO> {
            return localVarFp.getTicketAuditlogsTicketsTicketUuidAuditlogsGet(ticketUuid, excludeFields, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ticket Comments
         * @param {string} ticketUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {CommentVisibilityEnumDTO} [visibility] 
         * @param {string} [entityUuid] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketCommentsTicketsTicketUuidCommentsGet(ticketUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, visibility?: CommentVisibilityEnumDTO, entityUuid?: string, entityType?: EntityEnumDTO, options?: any): AxiosPromise<ListCommentSerializerDTO> {
            return localVarFp.getTicketCommentsTicketsTicketUuidCommentsGet(ticketUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, visibility, entityUuid, entityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ticket Metadata For Country
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketMetadataForCountryTicketsCountryUuidMetadataGet(countryUuid: string, options?: any): AxiosPromise<TicketMetadataSerializerDTO> {
            return localVarFp.getTicketMetadataForCountryTicketsCountryUuidMetadataGet(countryUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tickets
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {TicketTypeEnumDTO} [ticketType] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {string} [summary] 
         * @param {string} [reporterUuid] 
         * @param {string} [assigneeUuid] 
         * @param {boolean} [unassigned] 
         * @param {string} [entityUuid] 
         * @param {string} [propertyObjectUuid] 
         * @param {string} [propertyUuid] 
         * @param {InvoiceRequestTypeEnumDTO | ContractRequestTypeEnumDTO} [ticketSubtype] 
         * @param {IssueTypeEnumDTO} [issueType] 
         * @param {IssueSubtypeEnumDTO} [issueSubtype] 
         * @param {LocationTypeEnumDTO} [issueLocation] 
         * @param {RoomTypeEnumDTO} [roomWithIssue] 
         * @param {PriorityTypeEnumDTO} [priority] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {string} [status] 
         * @param {ComplaintTypeEnumDTO} [complaintType] 
         * @param {string} [referenceNumber] 
         * @param {string} [description] 
         * @param {string} [addressLine] 
         * @param {string} [dueDateLt] 
         * @param {string} [dueDateGt] 
         * @param {string} [cityUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {string} [doneAtGte] 
         * @param {string} [uuids] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketsTicketsGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, ticketType?: TicketTypeEnumDTO, search?: string, name?: string, summary?: string, reporterUuid?: string, assigneeUuid?: string, unassigned?: boolean, entityUuid?: string, propertyObjectUuid?: string, propertyUuid?: string, ticketSubtype?: InvoiceRequestTypeEnumDTO | ContractRequestTypeEnumDTO, issueType?: IssueTypeEnumDTO, issueSubtype?: IssueSubtypeEnumDTO, issueLocation?: LocationTypeEnumDTO, roomWithIssue?: RoomTypeEnumDTO, priority?: PriorityTypeEnumDTO, entityType?: EntityEnumDTO, status?: string, complaintType?: ComplaintTypeEnumDTO, referenceNumber?: string, description?: string, addressLine?: string, dueDateLt?: string, dueDateGt?: string, cityUuid?: string, regionUuid?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, doneAtGte?: string, uuids?: string, businessRegion?: string, options?: any): AxiosPromise<ListTicketsWithAdditionalFieldsSerializerDTO> {
            return localVarFp.getTicketsTicketsGet(page, pageSize, order, updatedAtLte, updatedAtGt, ticketType, search, name, summary, reporterUuid, assigneeUuid, unassigned, entityUuid, propertyObjectUuid, propertyUuid, ticketSubtype, issueType, issueSubtype, issueLocation, roomWithIssue, priority, entityType, status, complaintType, referenceNumber, description, addressLine, dueDateLt, dueDateGt, cityUuid, regionUuid, countryUuid, department, doneAtGte, uuids, businessRegion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Time Spent On Ticket Sum
         * @param {string} ticketUuid 
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet(ticketUuid: string, userUuid: string, options?: any): AxiosPromise<TicketTimeSpentSumByUserSerializerDTO> {
            return localVarFp.getTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet(ticketUuid, userUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Ticket Comment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {PatchCommentSerializerDTO} patchCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch(ticketUuid: string, commentUuid: string, patchCommentSerializerDTO: PatchCommentSerializerDTO, options?: any): AxiosPromise<CommentSerializerDTO> {
            return localVarFp.patchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch(ticketUuid, commentUuid, patchCommentSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Time Spent On Ticket Info
         * @param {string} timeSpentInfoUuid 
         * @param {PatchTimeSpentOnTicketSerializerDTO} patchTimeSpentOnTicketSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch(timeSpentInfoUuid: string, patchTimeSpentOnTicketSerializerDTO: PatchTimeSpentOnTicketSerializerDTO, options?: any): AxiosPromise<TimeSpentOnTicketSerializerDTO> {
            return localVarFp.patchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch(timeSpentInfoUuid, patchTimeSpentOnTicketSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reassign
         * @param {ReassignTicketSerializerDTO} reassignTicketSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignTicketsReassignPost(reassignTicketSerializerDTO: ReassignTicketSerializerDTO, options?: any): AxiosPromise<ReassignTicketResponseSerializerDTO> {
            return localVarFp.reassignTicketsReassignPost(reassignTicketSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Response Template
         * @param {string} responseTemplateUuid 
         * @param {CreateReponseTemplateSerializerDTO} createReponseTemplateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut(responseTemplateUuid: string, createReponseTemplateSerializerDTO: CreateReponseTemplateSerializerDTO, options?: any): AxiosPromise<ResponseTemplateSerializerDTO> {
            return localVarFp.updateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut(responseTemplateUuid, createReponseTemplateSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Comment Attachment
         * @param {string} ticketUuid 
         * @param {string} commentUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost(ticketUuid: string, commentUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): AxiosPromise<Array<AttachmentSerializerDTO>> {
            return localVarFp.uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost(ticketUuid, commentUuid, attachmentCategory, attachmentType, files, title, description, visibility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Ticket Attachment
         * @param {string} ticketUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTicketAttachmentTicketsTicketUuidAttachmentsPost(ticketUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): AxiosPromise<Array<AttachmentSerializerDTO>> {
            return localVarFp.uploadTicketAttachmentTicketsTicketUuidAttachmentsPost(ticketUuid, attachmentCategory, attachmentType, files, title, description, visibility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateImportDataFormatTicketsDataImportPost(file: any, options?: any): AxiosPromise<any> {
            return localVarFp.validateImportDataFormatTicketsDataImportPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addTicketCommentTicketsTicketUuidCommentsPost operation in TicketsAPI.
 * @export
 * @interface TicketsAPIAddTicketCommentTicketsTicketUuidCommentsPostRequest
 */
export interface TicketsAPIAddTicketCommentTicketsTicketUuidCommentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIAddTicketCommentTicketsTicketUuidCommentsPost
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {CreateCommentSerializerDTO}
     * @memberof TicketsAPIAddTicketCommentTicketsTicketUuidCommentsPost
     */
    readonly createCommentSerializerDTO: CreateCommentSerializerDTO
}

/**
 * Request parameters for changeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost operation in TicketsAPI.
 * @export
 * @interface TicketsAPIChangeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPostRequest
 */
export interface TicketsAPIChangeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIChangeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {AwaitingExternalProviderSerializerDTO}
     * @memberof TicketsAPIChangeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost
     */
    readonly awaitingExternalProviderSerializerDTO: AwaitingExternalProviderSerializerDTO
}

/**
 * Request parameters for changeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost operation in TicketsAPI.
 * @export
 * @interface TicketsAPIChangeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPostRequest
 */
export interface TicketsAPIChangeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIChangeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {AwaitingTenantSerializerDTO}
     * @memberof TicketsAPIChangeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost
     */
    readonly awaitingTenantSerializerDTO: AwaitingTenantSerializerDTO
}

/**
 * Request parameters for changeStatusToDoneTicketsTicketUuidDonePost operation in TicketsAPI.
 * @export
 * @interface TicketsAPIChangeStatusToDoneTicketsTicketUuidDonePostRequest
 */
export interface TicketsAPIChangeStatusToDoneTicketsTicketUuidDonePostRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIChangeStatusToDoneTicketsTicketUuidDonePost
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {DoneSerializerDTO}
     * @memberof TicketsAPIChangeStatusToDoneTicketsTicketUuidDonePost
     */
    readonly doneSerializerDTO: DoneSerializerDTO
}

/**
 * Request parameters for changeStatusToInProgressTicketsTicketUuidInProgressPost operation in TicketsAPI.
 * @export
 * @interface TicketsAPIChangeStatusToInProgressTicketsTicketUuidInProgressPostRequest
 */
export interface TicketsAPIChangeStatusToInProgressTicketsTicketUuidInProgressPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIChangeStatusToInProgressTicketsTicketUuidInProgressPost
     */
    readonly ticketUuid: string
}

/**
 * Request parameters for changeStatusToOnHoldTicketsTicketUuidOnHoldPost operation in TicketsAPI.
 * @export
 * @interface TicketsAPIChangeStatusToOnHoldTicketsTicketUuidOnHoldPostRequest
 */
export interface TicketsAPIChangeStatusToOnHoldTicketsTicketUuidOnHoldPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIChangeStatusToOnHoldTicketsTicketUuidOnHoldPost
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {OnHoldSerializerDTO}
     * @memberof TicketsAPIChangeStatusToOnHoldTicketsTicketUuidOnHoldPost
     */
    readonly onHoldSerializerDTO: OnHoldSerializerDTO
}

/**
 * Request parameters for createResponseTemplateTicketsResponseTemplatesPost operation in TicketsAPI.
 * @export
 * @interface TicketsAPICreateResponseTemplateTicketsResponseTemplatesPostRequest
 */
export interface TicketsAPICreateResponseTemplateTicketsResponseTemplatesPostRequest {
    /**
     * 
     * @type {CreateReponseTemplateSerializerDTO}
     * @memberof TicketsAPICreateResponseTemplateTicketsResponseTemplatesPost
     */
    readonly createReponseTemplateSerializerDTO: CreateReponseTemplateSerializerDTO
}

/**
 * Request parameters for createTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost operation in TicketsAPI.
 * @export
 * @interface TicketsAPICreateTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPostRequest
 */
export interface TicketsAPICreateTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPICreateTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {CreateTimeSpentOnTicketSerializerDTO}
     * @memberof TicketsAPICreateTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost
     */
    readonly createTimeSpentOnTicketSerializerDTO: CreateTimeSpentOnTicketSerializerDTO
}

/**
 * Request parameters for deleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete operation in TicketsAPI.
 * @export
 * @interface TicketsAPIDeleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDeleteRequest
 */
export interface TicketsAPIDeleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDeleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDeleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete
     */
    readonly commentUuid: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDeleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for deleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete operation in TicketsAPI.
 * @export
 * @interface TicketsAPIDeleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDeleteRequest
 */
export interface TicketsAPIDeleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDeleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete
     */
    readonly responseTemplateUuid: string
}

/**
 * Request parameters for deleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete operation in TicketsAPI.
 * @export
 * @interface TicketsAPIDeleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDeleteRequest
 */
export interface TicketsAPIDeleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDeleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDeleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for deleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete operation in TicketsAPI.
 * @export
 * @interface TicketsAPIDeleteTicketCommentTicketsTicketUuidCommentsCommentUuidDeleteRequest
 */
export interface TicketsAPIDeleteTicketCommentTicketsTicketUuidCommentsCommentUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDeleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDeleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete
     */
    readonly commentUuid: string
}

/**
 * Request parameters for deleteTicketTicketsTicketUuidDelete operation in TicketsAPI.
 * @export
 * @interface TicketsAPIDeleteTicketTicketsTicketUuidDeleteRequest
 */
export interface TicketsAPIDeleteTicketTicketsTicketUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDeleteTicketTicketsTicketUuidDelete
     */
    readonly ticketUuid: string
}

/**
 * Request parameters for deleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDelete operation in TicketsAPI.
 * @export
 * @interface TicketsAPIDeleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDeleteRequest
 */
export interface TicketsAPIDeleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDeleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDelete
     */
    readonly timeSpentInfoUuid: string
}

/**
 * Request parameters for downloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIDownloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGetRequest
 */
export interface TicketsAPIDownloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDownloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIDownloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for getAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGetRequest
 */
export interface TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet
     */
    readonly userUuid?: string
}

/**
 * Request parameters for getCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGetRequest
 */
export interface TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet
     */
    readonly commentUuid: string

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet
     */
    readonly type?: string
}

/**
 * Request parameters for getCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGetRequest
 */
export interface TicketsAPIGetCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet
     */
    readonly countryUuid: string

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet
     */
    readonly userRole?: string
}

/**
 * Request parameters for getParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGetRequest
 */
export interface TicketsAPIGetParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGet
     */
    readonly timeSpentInfoUuid: string
}

/**
 * Request parameters for getResponseTemplatesTicketsResponseTemplatesGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGetRequest
 */
export interface TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGet
     */
    readonly content?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGet
     */
    readonly authorUuid?: string

    /**
     * 
     * @type {ResponseTemplateVisibilityEnumDTO}
     * @memberof TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGet
     */
    readonly visibility?: ResponseTemplateVisibilityEnumDTO

    /**
     * 
     * @type {LanguageDTO}
     * @memberof TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGet
     */
    readonly language?: LanguageDTO
}

/**
 * Request parameters for getServiceRequestsForEntityTicketsCountsGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetServiceRequestsForEntityTicketsCountsGetRequest
 */
export interface TicketsAPIGetServiceRequestsForEntityTicketsCountsGetRequest {
    /**
     * 
     * @type {TicketTypeEnumDTO}
     * @memberof TicketsAPIGetServiceRequestsForEntityTicketsCountsGet
     */
    readonly ticketType?: TicketTypeEnumDTO

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetServiceRequestsForEntityTicketsCountsGet
     */
    readonly assigneeUuid?: string
}

/**
 * Request parameters for getSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGetRequest
 */
export interface TicketsAPIGetSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGet
     */
    readonly responseTemplateUuid: string
}

/**
 * Request parameters for getTicketAttachmentsTicketsTicketUuidAttachmentsGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGetRequest
 */
export interface TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGet
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGet
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGet
     */
    readonly type?: string
}

/**
 * Request parameters for getTicketAuditlogsTicketsTicketUuidAuditlogsGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetTicketAuditlogsTicketsTicketUuidAuditlogsGetRequest
 */
export interface TicketsAPIGetTicketAuditlogsTicketsTicketUuidAuditlogsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketAuditlogsTicketsTicketUuidAuditlogsGet
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketAuditlogsTicketsTicketUuidAuditlogsGet
     */
    readonly excludeFields?: string

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetTicketAuditlogsTicketsTicketUuidAuditlogsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetTicketAuditlogsTicketsTicketUuidAuditlogsGet
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getTicketCommentsTicketsTicketUuidCommentsGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGetRequest
 */
export interface TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGet
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {CommentVisibilityEnumDTO}
     * @memberof TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGet
     */
    readonly visibility?: CommentVisibilityEnumDTO

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGet
     */
    readonly entityUuid?: string

    /**
     * 
     * @type {EntityEnumDTO}
     * @memberof TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGet
     */
    readonly entityType?: EntityEnumDTO
}

/**
 * Request parameters for getTicketMetadataForCountryTicketsCountryUuidMetadataGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetTicketMetadataForCountryTicketsCountryUuidMetadataGetRequest
 */
export interface TicketsAPIGetTicketMetadataForCountryTicketsCountryUuidMetadataGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketMetadataForCountryTicketsCountryUuidMetadataGet
     */
    readonly countryUuid: string
}

/**
 * Request parameters for getTicketsTicketsGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetTicketsTicketsGetRequest
 */
export interface TicketsAPIGetTicketsTicketsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {TicketTypeEnumDTO}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly ticketType?: TicketTypeEnumDTO

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly summary?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly reporterUuid?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly assigneeUuid?: string

    /**
     * 
     * @type {boolean}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly unassigned?: boolean

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly entityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly propertyObjectUuid?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly propertyUuid?: string

    /**
     * 
     * @type {InvoiceRequestTypeEnumDTO | ContractRequestTypeEnumDTO}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly ticketSubtype?: InvoiceRequestTypeEnumDTO | ContractRequestTypeEnumDTO

    /**
     * 
     * @type {IssueTypeEnumDTO}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly issueType?: IssueTypeEnumDTO

    /**
     * 
     * @type {IssueSubtypeEnumDTO}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly issueSubtype?: IssueSubtypeEnumDTO

    /**
     * 
     * @type {LocationTypeEnumDTO}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly issueLocation?: LocationTypeEnumDTO

    /**
     * 
     * @type {RoomTypeEnumDTO}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly roomWithIssue?: RoomTypeEnumDTO

    /**
     * 
     * @type {PriorityTypeEnumDTO}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly priority?: PriorityTypeEnumDTO

    /**
     * 
     * @type {EntityEnumDTO}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly entityType?: EntityEnumDTO

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly status?: string

    /**
     * 
     * @type {ComplaintTypeEnumDTO}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly complaintType?: ComplaintTypeEnumDTO

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly referenceNumber?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly addressLine?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly dueDateLt?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly dueDateGt?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly cityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {DepartmentTypeEnumDTO}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly department?: DepartmentTypeEnumDTO

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly doneAtGte?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly uuids?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTicketsTicketsGet
     */
    readonly businessRegion?: string
}

/**
 * Request parameters for getTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet operation in TicketsAPI.
 * @export
 * @interface TicketsAPIGetTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGetRequest
 */
export interface TicketsAPIGetTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIGetTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet
     */
    readonly userUuid: string
}

/**
 * Request parameters for patchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch operation in TicketsAPI.
 * @export
 * @interface TicketsAPIPatchTicketCommentTicketsTicketUuidCommentsCommentUuidPatchRequest
 */
export interface TicketsAPIPatchTicketCommentTicketsTicketUuidCommentsCommentUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIPatchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIPatchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch
     */
    readonly commentUuid: string

    /**
     * 
     * @type {PatchCommentSerializerDTO}
     * @memberof TicketsAPIPatchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch
     */
    readonly patchCommentSerializerDTO: PatchCommentSerializerDTO
}

/**
 * Request parameters for patchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch operation in TicketsAPI.
 * @export
 * @interface TicketsAPIPatchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatchRequest
 */
export interface TicketsAPIPatchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIPatchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch
     */
    readonly timeSpentInfoUuid: string

    /**
     * 
     * @type {PatchTimeSpentOnTicketSerializerDTO}
     * @memberof TicketsAPIPatchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch
     */
    readonly patchTimeSpentOnTicketSerializerDTO: PatchTimeSpentOnTicketSerializerDTO
}

/**
 * Request parameters for reassignTicketsReassignPost operation in TicketsAPI.
 * @export
 * @interface TicketsAPIReassignTicketsReassignPostRequest
 */
export interface TicketsAPIReassignTicketsReassignPostRequest {
    /**
     * 
     * @type {ReassignTicketSerializerDTO}
     * @memberof TicketsAPIReassignTicketsReassignPost
     */
    readonly reassignTicketSerializerDTO: ReassignTicketSerializerDTO
}

/**
 * Request parameters for updateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut operation in TicketsAPI.
 * @export
 * @interface TicketsAPIUpdateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPutRequest
 */
export interface TicketsAPIUpdateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIUpdateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut
     */
    readonly responseTemplateUuid: string

    /**
     * 
     * @type {CreateReponseTemplateSerializerDTO}
     * @memberof TicketsAPIUpdateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut
     */
    readonly createReponseTemplateSerializerDTO: CreateReponseTemplateSerializerDTO
}

/**
 * Request parameters for uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost operation in TicketsAPI.
 * @export
 * @interface TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPostRequest
 */
export interface TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost
     */
    readonly commentUuid: string

    /**
     * 
     * @type {AttachmentCategoryEnumDTO}
     * @memberof TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost
     */
    readonly attachmentCategory: AttachmentCategoryEnumDTO

    /**
     * 
     * @type {AttachmentTypeEnumDTO}
     * @memberof TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost
     */
    readonly attachmentType: AttachmentTypeEnumDTO

    /**
     * 
     * @type {Array<any>}
     * @memberof TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost
     */
    readonly files: Array<any>

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost
     */
    readonly title?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost
     */
    readonly description?: string

    /**
     * 
     * @type {AttachmentVisibilityEnumDTO}
     * @memberof TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost
     */
    readonly visibility?: AttachmentVisibilityEnumDTO
}

/**
 * Request parameters for uploadTicketAttachmentTicketsTicketUuidAttachmentsPost operation in TicketsAPI.
 * @export
 * @interface TicketsAPIUploadTicketAttachmentTicketsTicketUuidAttachmentsPostRequest
 */
export interface TicketsAPIUploadTicketAttachmentTicketsTicketUuidAttachmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsAPIUploadTicketAttachmentTicketsTicketUuidAttachmentsPost
     */
    readonly ticketUuid: string

    /**
     * 
     * @type {AttachmentCategoryEnumDTO}
     * @memberof TicketsAPIUploadTicketAttachmentTicketsTicketUuidAttachmentsPost
     */
    readonly attachmentCategory: AttachmentCategoryEnumDTO

    /**
     * 
     * @type {AttachmentTypeEnumDTO}
     * @memberof TicketsAPIUploadTicketAttachmentTicketsTicketUuidAttachmentsPost
     */
    readonly attachmentType: AttachmentTypeEnumDTO

    /**
     * 
     * @type {Array<any>}
     * @memberof TicketsAPIUploadTicketAttachmentTicketsTicketUuidAttachmentsPost
     */
    readonly files: Array<any>

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIUploadTicketAttachmentTicketsTicketUuidAttachmentsPost
     */
    readonly title?: string

    /**
     * 
     * @type {string}
     * @memberof TicketsAPIUploadTicketAttachmentTicketsTicketUuidAttachmentsPost
     */
    readonly description?: string

    /**
     * 
     * @type {AttachmentVisibilityEnumDTO}
     * @memberof TicketsAPIUploadTicketAttachmentTicketsTicketUuidAttachmentsPost
     */
    readonly visibility?: AttachmentVisibilityEnumDTO
}

/**
 * Request parameters for validateImportDataFormatTicketsDataImportPost operation in TicketsAPI.
 * @export
 * @interface TicketsAPIValidateImportDataFormatTicketsDataImportPostRequest
 */
export interface TicketsAPIValidateImportDataFormatTicketsDataImportPostRequest {
    /**
     * 
     * @type {any}
     * @memberof TicketsAPIValidateImportDataFormatTicketsDataImportPost
     */
    readonly file: any
}

/**
 * TicketsAPI - object-oriented interface
 * @export
 * @class TicketsAPI
 * @extends {BaseAPI}
 */
export class TicketsAPI extends BaseAPI {
    /**
     * 
     * @summary Add Ticket Comment
     * @param {TicketsAPIAddTicketCommentTicketsTicketUuidCommentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public addTicketCommentTicketsTicketUuidCommentsPost(requestParameters: TicketsAPIAddTicketCommentTicketsTicketUuidCommentsPostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).addTicketCommentTicketsTicketUuidCommentsPost(requestParameters.ticketUuid, requestParameters.createCommentSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change Status To Awaiting External Provider
     * @param {TicketsAPIChangeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public changeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost(requestParameters: TicketsAPIChangeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).changeStatusToAwaitingExternalProviderTicketsTicketUuidAwaitingExternalProviderPost(requestParameters.ticketUuid, requestParameters.awaitingExternalProviderSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change Status To Awaiting Tenant
     * @param {TicketsAPIChangeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public changeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost(requestParameters: TicketsAPIChangeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).changeStatusToAwaitingTenantTicketsTicketUuidAwaitingTenantPost(requestParameters.ticketUuid, requestParameters.awaitingTenantSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change Status To Done
     * @param {TicketsAPIChangeStatusToDoneTicketsTicketUuidDonePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public changeStatusToDoneTicketsTicketUuidDonePost(requestParameters: TicketsAPIChangeStatusToDoneTicketsTicketUuidDonePostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).changeStatusToDoneTicketsTicketUuidDonePost(requestParameters.ticketUuid, requestParameters.doneSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change Status To In Progress
     * @param {TicketsAPIChangeStatusToInProgressTicketsTicketUuidInProgressPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public changeStatusToInProgressTicketsTicketUuidInProgressPost(requestParameters: TicketsAPIChangeStatusToInProgressTicketsTicketUuidInProgressPostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).changeStatusToInProgressTicketsTicketUuidInProgressPost(requestParameters.ticketUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change Status To On Hold
     * @param {TicketsAPIChangeStatusToOnHoldTicketsTicketUuidOnHoldPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public changeStatusToOnHoldTicketsTicketUuidOnHoldPost(requestParameters: TicketsAPIChangeStatusToOnHoldTicketsTicketUuidOnHoldPostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).changeStatusToOnHoldTicketsTicketUuidOnHoldPost(requestParameters.ticketUuid, requestParameters.onHoldSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Response Template
     * @param {TicketsAPICreateResponseTemplateTicketsResponseTemplatesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public createResponseTemplateTicketsResponseTemplatesPost(requestParameters: TicketsAPICreateResponseTemplateTicketsResponseTemplatesPostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).createResponseTemplateTicketsResponseTemplatesPost(requestParameters.createReponseTemplateSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Time Spent On Ticket Info
     * @param {TicketsAPICreateTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public createTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost(requestParameters: TicketsAPICreateTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).createTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentPost(requestParameters.ticketUuid, requestParameters.createTimeSpentOnTicketSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Comment Attachment
     * @param {TicketsAPIDeleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public deleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete(requestParameters: TicketsAPIDeleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDeleteRequest, options?: any) {
        return TicketsAPIFp(this.configuration).deleteCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsAttachmentUuidDelete(requestParameters.ticketUuid, requestParameters.commentUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Response Template
     * @param {TicketsAPIDeleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public deleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete(requestParameters: TicketsAPIDeleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDeleteRequest, options?: any) {
        return TicketsAPIFp(this.configuration).deleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete(requestParameters.responseTemplateUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Ticket Attachment
     * @param {TicketsAPIDeleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public deleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete(requestParameters: TicketsAPIDeleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDeleteRequest, options?: any) {
        return TicketsAPIFp(this.configuration).deleteTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDelete(requestParameters.ticketUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Ticket Comment
     * @param {TicketsAPIDeleteTicketCommentTicketsTicketUuidCommentsCommentUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public deleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete(requestParameters: TicketsAPIDeleteTicketCommentTicketsTicketUuidCommentsCommentUuidDeleteRequest, options?: any) {
        return TicketsAPIFp(this.configuration).deleteTicketCommentTicketsTicketUuidCommentsCommentUuidDelete(requestParameters.ticketUuid, requestParameters.commentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Ticket
     * @param {TicketsAPIDeleteTicketTicketsTicketUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public deleteTicketTicketsTicketUuidDelete(requestParameters: TicketsAPIDeleteTicketTicketsTicketUuidDeleteRequest, options?: any) {
        return TicketsAPIFp(this.configuration).deleteTicketTicketsTicketUuidDelete(requestParameters.ticketUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Time Spent On Ticket Info
     * @param {TicketsAPIDeleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public deleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDelete(requestParameters: TicketsAPIDeleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDeleteRequest, options?: any) {
        return TicketsAPIFp(this.configuration).deleteTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidDelete(requestParameters.timeSpentInfoUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Ticket Attachment
     * @param {TicketsAPIDownloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public downloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet(requestParameters: TicketsAPIDownloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGetRequest, options?: any) {
        return TicketsAPIFp(this.configuration).downloadTicketAttachmentTicketsTicketUuidAttachmentsAttachmentUuidDownloadGet(requestParameters.ticketUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Time Spent On Ticket Info
     * @param {TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet(requestParameters: TicketsAPIGetAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGetRequest, options?: any) {
        return TicketsAPIFp(this.configuration).getAllTimeSpentOnTicketInfoTicketsTicketUuidTimeSpentGet(requestParameters.ticketUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.countryUuid, requestParameters.userUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Comment Attachments
     * @param {TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet(requestParameters: TicketsAPIGetCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGetRequest, options?: any) {
        return TicketsAPIFp(this.configuration).getCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet(requestParameters.ticketUuid, requestParameters.commentUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.category, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Comments By Country With Additional Ticket Data
     * @param {TicketsAPIGetCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet(requestParameters: TicketsAPIGetCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGetRequest, options?: any) {
        return TicketsAPIFp(this.configuration).getCommentsByCountryWithAdditionalTicketDataTicketsCommentsCountryUuidGet(requestParameters.countryUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.search, requestParameters.userRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Particular Time Spent On Ticket Info
     * @param {TicketsAPIGetParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGet(requestParameters: TicketsAPIGetParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGetRequest, options?: any) {
        return TicketsAPIFp(this.configuration).getParticularTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidGet(requestParameters.timeSpentInfoUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Response Templates
     * @param {TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getResponseTemplatesTicketsResponseTemplatesGet(requestParameters: TicketsAPIGetResponseTemplatesTicketsResponseTemplatesGetRequest = {}, options?: any) {
        return TicketsAPIFp(this.configuration).getResponseTemplatesTicketsResponseTemplatesGet(requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.search, requestParameters.content, requestParameters.name, requestParameters.countryUuid, requestParameters.authorUuid, requestParameters.visibility, requestParameters.language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Service Requests For Entity
     * @param {TicketsAPIGetServiceRequestsForEntityTicketsCountsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getServiceRequestsForEntityTicketsCountsGet(requestParameters: TicketsAPIGetServiceRequestsForEntityTicketsCountsGetRequest = {}, options?: any) {
        return TicketsAPIFp(this.configuration).getServiceRequestsForEntityTicketsCountsGet(requestParameters.ticketType, requestParameters.assigneeUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Response Template
     * @param {TicketsAPIGetSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGet(requestParameters: TicketsAPIGetSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGetRequest, options?: any) {
        return TicketsAPIFp(this.configuration).getSingleResponseTemplateTicketsResponseTemplatesResponseTemplateUuidGet(requestParameters.responseTemplateUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ticket Attachments
     * @param {TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getTicketAttachmentsTicketsTicketUuidAttachmentsGet(requestParameters: TicketsAPIGetTicketAttachmentsTicketsTicketUuidAttachmentsGetRequest, options?: any) {
        return TicketsAPIFp(this.configuration).getTicketAttachmentsTicketsTicketUuidAttachmentsGet(requestParameters.ticketUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.category, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ticket Auditlogs
     * @param {TicketsAPIGetTicketAuditlogsTicketsTicketUuidAuditlogsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getTicketAuditlogsTicketsTicketUuidAuditlogsGet(requestParameters: TicketsAPIGetTicketAuditlogsTicketsTicketUuidAuditlogsGetRequest, options?: any) {
        return TicketsAPIFp(this.configuration).getTicketAuditlogsTicketsTicketUuidAuditlogsGet(requestParameters.ticketUuid, requestParameters.excludeFields, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ticket Comments
     * @param {TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getTicketCommentsTicketsTicketUuidCommentsGet(requestParameters: TicketsAPIGetTicketCommentsTicketsTicketUuidCommentsGetRequest, options?: any) {
        return TicketsAPIFp(this.configuration).getTicketCommentsTicketsTicketUuidCommentsGet(requestParameters.ticketUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.visibility, requestParameters.entityUuid, requestParameters.entityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ticket Metadata For Country
     * @param {TicketsAPIGetTicketMetadataForCountryTicketsCountryUuidMetadataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getTicketMetadataForCountryTicketsCountryUuidMetadataGet(requestParameters: TicketsAPIGetTicketMetadataForCountryTicketsCountryUuidMetadataGetRequest, options?: any) {
        return TicketsAPIFp(this.configuration).getTicketMetadataForCountryTicketsCountryUuidMetadataGet(requestParameters.countryUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tickets
     * @param {TicketsAPIGetTicketsTicketsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getTicketsTicketsGet(requestParameters: TicketsAPIGetTicketsTicketsGetRequest = {}, options?: any) {
        return TicketsAPIFp(this.configuration).getTicketsTicketsGet(requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.ticketType, requestParameters.search, requestParameters.name, requestParameters.summary, requestParameters.reporterUuid, requestParameters.assigneeUuid, requestParameters.unassigned, requestParameters.entityUuid, requestParameters.propertyObjectUuid, requestParameters.propertyUuid, requestParameters.ticketSubtype, requestParameters.issueType, requestParameters.issueSubtype, requestParameters.issueLocation, requestParameters.roomWithIssue, requestParameters.priority, requestParameters.entityType, requestParameters.status, requestParameters.complaintType, requestParameters.referenceNumber, requestParameters.description, requestParameters.addressLine, requestParameters.dueDateLt, requestParameters.dueDateGt, requestParameters.cityUuid, requestParameters.regionUuid, requestParameters.countryUuid, requestParameters.department, requestParameters.doneAtGte, requestParameters.uuids, requestParameters.businessRegion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Time Spent On Ticket Sum
     * @param {TicketsAPIGetTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public getTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet(requestParameters: TicketsAPIGetTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGetRequest, options?: any) {
        return TicketsAPIFp(this.configuration).getTimeSpentOnTicketSumTicketsTicketUuidUserUserUuidTimeSpentSumGet(requestParameters.ticketUuid, requestParameters.userUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Ticket Comment
     * @param {TicketsAPIPatchTicketCommentTicketsTicketUuidCommentsCommentUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public patchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch(requestParameters: TicketsAPIPatchTicketCommentTicketsTicketUuidCommentsCommentUuidPatchRequest, options?: any) {
        return TicketsAPIFp(this.configuration).patchTicketCommentTicketsTicketUuidCommentsCommentUuidPatch(requestParameters.ticketUuid, requestParameters.commentUuid, requestParameters.patchCommentSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Time Spent On Ticket Info
     * @param {TicketsAPIPatchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public patchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch(requestParameters: TicketsAPIPatchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatchRequest, options?: any) {
        return TicketsAPIFp(this.configuration).patchTimeSpentOnTicketInfoTicketsTimeSpentTimeSpentInfoUuidPatch(requestParameters.timeSpentInfoUuid, requestParameters.patchTimeSpentOnTicketSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reassign
     * @param {TicketsAPIReassignTicketsReassignPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public reassignTicketsReassignPost(requestParameters: TicketsAPIReassignTicketsReassignPostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).reassignTicketsReassignPost(requestParameters.reassignTicketSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Response Template
     * @param {TicketsAPIUpdateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public updateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut(requestParameters: TicketsAPIUpdateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPutRequest, options?: any) {
        return TicketsAPIFp(this.configuration).updateResponseTemplateTicketsResponseTemplatesResponseTemplateUuidPut(requestParameters.responseTemplateUuid, requestParameters.createReponseTemplateSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Comment Attachment
     * @param {TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost(requestParameters: TicketsAPIUploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost(requestParameters.ticketUuid, requestParameters.commentUuid, requestParameters.attachmentCategory, requestParameters.attachmentType, requestParameters.files, requestParameters.title, requestParameters.description, requestParameters.visibility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Ticket Attachment
     * @param {TicketsAPIUploadTicketAttachmentTicketsTicketUuidAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public uploadTicketAttachmentTicketsTicketUuidAttachmentsPost(requestParameters: TicketsAPIUploadTicketAttachmentTicketsTicketUuidAttachmentsPostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).uploadTicketAttachmentTicketsTicketUuidAttachmentsPost(requestParameters.ticketUuid, requestParameters.attachmentCategory, requestParameters.attachmentType, requestParameters.files, requestParameters.title, requestParameters.description, requestParameters.visibility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate Import Data Format
     * @param {TicketsAPIValidateImportDataFormatTicketsDataImportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsAPI
     */
    public validateImportDataFormatTicketsDataImportPost(requestParameters: TicketsAPIValidateImportDataFormatTicketsDataImportPostRequest, options?: any) {
        return TicketsAPIFp(this.configuration).validateImportDataFormatTicketsDataImportPost(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}
