import { ReactComponent as CheckIcon } from '@heimstaden/icons-library/img/custom/check.svg';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { KYCPlatformEnumDTO } from '../../../../../../connectors/company';
import type { KYCStatusSerializerDTO } from '../../../../../../connectors/property';
import { Spinner } from '../../../../../shared';
import { PLATFORM_STATUSES } from '../verification-stepper.const';
import { BoxClassName, IconClassName, sxProps } from './status-step.styles';

type Props = {
  isSmall: boolean;
  platform: KYCPlatformEnumDTO;
  value: KYCStatusSerializerDTO['statusValue'];
};

export const StatusStep: FC<Props> = (props) => {
  const { isSmall, platform, value } = props;
  const isSucceeded = useMemo(
    () => value === PLATFORM_STATUSES[platform].SUCCESS,
    [platform, value],
  );
  const isPending = useMemo(
    () => value === PLATFORM_STATUSES[platform].PENDING,
    [platform, value],
  );
  const conditionalClassName = useMemo(
    () =>
      [
        !isPending && BoxClassName.CONSTANT,
        !isPending && isSmall && BoxClassName.CONSTANT_SMALL,
        isSucceeded && BoxClassName.SUCCEEDED,
        isSmall && BoxClassName.SMALL,
      ]
        .filter((className) => className)
        .join(' '),
    [isPending, isSmall, isSucceeded],
  );
  const conditionalIconClassName = useMemo(
    () =>
      [isSmall && IconClassName.SMALL]
        .filter((className) => className)
        .join(' '),
    [isSmall],
  );

  if (isSucceeded) {
    return (
      <Box className={conditionalClassName} sx={sxProps.box}>
        <Icon
          className={conditionalIconClassName}
          color="success"
          component={CheckIcon}
          sx={sxProps.icon}
        />
      </Box>
    );
  }

  if (isPending) {
    return <Spinner color="secondary" size={isSmall ? 16 : 24} />;
  }

  return <Box className={conditionalClassName} sx={sxProps.box} />;
};
