/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum DepartmentTypeEnumDTO {
    BackOffice = 'back_office',
    CustomerService = 'customer_service',
    Operation = 'operation',
    MovingService = 'moving_service',
    TenantImprovement = 'tenant_improvement',
    RentalService = 'rental_service',
    CollectionsDepartment = 'collections_department',
    ContractAdministration = 'contract_administration',
    Technical = 'technical',
    Hoa = 'hoa'
}



