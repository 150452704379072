import * as Yup from 'yup';
import 'yup-phone-lite';

import type {
  BaseUserSerializerDTO,
  NationalitySerializerDTO,
  PatchUserSerializerDTO,
  UserSerializerDTO,
} from '../../../../../connectors/user';
import { UserTypeEnumDTO } from '../../../../../connectors/user';
import type {
  AsyncAutocompleteOption,
  FieldsetConfig,
  FormProps,
} from '../../../../shared';
import {
  DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  DEFAULT_FIELD_VALUE,
} from '../../../../shared';
import { userClient } from '../../../user.client';

export type Values = Omit<
  BaseUserSerializerDTO | PatchUserSerializerDTO,
  'nationality'
> & {
  nationality: AsyncAutocompleteOption<NationalitySerializerDTO['countryCode']>;
};
// TODO: add avatar field after https://fredensborg.atlassian.net/browse/MB-289
export const getFieldsConfig = (
  values?: UserSerializerDTO,
): FieldsetConfig<Values>[] => [
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: values?.firstName || DEFAULT_FIELD_VALUE,
          name: 'firstName',
        },
        props: {
          labelKey: 'user.fields.firstName',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.lastName || DEFAULT_FIELD_VALUE,
          name: 'lastName',
        },
        props: {
          labelKey: 'user.fields.lastName',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.email || DEFAULT_FIELD_VALUE,
          name: 'email',
          validationSchema: Yup.string().email('form.errors.email'),
        },
        props: {
          labelKey: 'user.fields.email',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.phoneNumber || DEFAULT_FIELD_VALUE,
          name: 'phoneNumber',
          validationSchema: Yup.string().phone('IN', 'form.errors.phone'),
        },
        props: {
          helperTextKey: 'user.fields.helperText.phoneNumber',
          labelKey: 'user.fields.phoneNumber',
          type: 'text',
        },
      },
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue:
            values?.nationalityName && values?.nationality
              ? { label: values.nationalityName, value: values.nationality }
              : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
          name: 'nationality',
        },
        props: {
          callback$: (query) => userClient.getNationalities$(query),
          labelKey: 'user.fields.nationality',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.virtualAccount || DEFAULT_FIELD_VALUE,
          name: 'virtualAccount',
        },
        props: {
          labelKey: 'user.fields.virtualAccount',
          type: 'text',
        },
      },
    ],
    helperTextKey: 'user.fieldset.details.helperText',
    key: 'user-details',
    size: { lg: 6, md: 12, sm: 12, xl: 6, xs: 12 },
    titleKey: 'user.fieldset.details.title',
  },
];

export const getConfig = (
  id?: UserSerializerDTO['uuid'],
): FormProps<Values>['config'] => ({
  onSubmit: (values) => {
    const user = {
      ...values,
      nationality: values.nationality?.value,
      userType: UserTypeEnumDTO.Customer,
    };

    return id
      ? userClient.update$(id, user)
      : userClient.create$(user as BaseUserSerializerDTO);
  },
});
