import { getPropertyAddress } from '../../../../+property';
import { getPropertyObjectAddress } from '../../../../+property-object';
import { getUnitAddress } from '../../../../+unit';
import type {
  DetailedPropertyObjectSerializerDTO,
  DetailedPropertySerializerDTO,
  DetailedUnitSerializerDTO,
} from '../../../../../connectors/property';
import { EntityEnumDTO } from '../../../../../connectors/ticket';
import type { Entity } from '../../types';

export const getAddress = (entity: Entity): string => {
  switch (entity.type) {
    case EntityEnumDTO.Company: {
      return '';
    }
    case EntityEnumDTO.Property: {
      return getPropertyAddress((entity as DetailedPropertySerializerDTO).city);
    }
    case EntityEnumDTO.PropertyObject: {
      const e: DetailedPropertyObjectSerializerDTO =
        entity as DetailedPropertyObjectSerializerDTO;

      return getPropertyObjectAddress(e.addressLine, e.city, e.streetNumber);
    }
    case EntityEnumDTO.Unit: {
      const e: DetailedUnitSerializerDTO = entity as DetailedUnitSerializerDTO;

      return getUnitAddress(e.addressLine, e.city, e.streetNumber);
    }
    default: {
      return '';
    }
  }
};
