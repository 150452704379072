import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import type { LanguageDTO } from '../../../../connectors/company';
import { useTranslation } from '../../translations';
import { SUPPORTED_LANG_OPTIONS } from '../../translations/consts';
import { sxProps } from './language-switcher.styles';

export const LanguageSwitcher: FC = () => {
  const { changeLanguage, langCode, t } = useTranslation();
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState(false);
  const handleSwitch$ = useCallback(
    async (lang: LanguageDTO) => {
      await changeLanguage(lang);
      setOpen(false);
    },
    [changeLanguage],
  );
  const currentLangIcon = useMemo(
    () =>
      SUPPORTED_LANG_OPTIONS.find((lang) => lang.code === langCode)?.icon || '',
    [langCode],
  );

  return (
    <>
      <IconButton
        data-testid="button-language-switcher"
        onClick={() => setOpen(true)}
        ref={ref}
        size="large"
        sx={
          {
            ...sxProps.icon,
            backgroundImage: `url('${currentLangIcon}')`,
          } as SxProps
        }
      />
      <Menu anchorEl={ref.current} open={isOpen} onClose={() => setOpen(false)}>
        {SUPPORTED_LANG_OPTIONS.map((lang) => (
          <MenuItem
            disabled={langCode === lang.code}
            selected={langCode === lang.code}
            onClick={() => handleSwitch$(lang.code)}
            key={`language-option-${lang.code}`}
          >
            <Grid alignItems="center" columnSpacing={1} container wrap="nowrap">
              <Grid item>
                <IconButton
                  size="large"
                  sx={
                    {
                      ...sxProps.icon,
                      backgroundImage: `url('${lang.icon}')`,
                    } as SxProps
                  }
                />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">{t(lang.labelKey)}</Typography>
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
