import type { AttachmentSerializerDTO } from '../../../../../../connectors/company';
import {
  AttachmentTypeEnumDTO,
  AttachmentVisibilityEnumDTO,
} from '../../../../../../connectors/company';
import type { CustomFile, FieldConfig, FormProps } from '../../../../form';
import { DEFAULT_FIELD_VALUE } from '../../../../form';
import {
  documentVisibilitySelectOptions,
  documentTypeSelectOptions,
} from '../../helpers';

export type Values = {
  description: AttachmentSerializerDTO['description'];
  title: AttachmentSerializerDTO['title'];
  type: AttachmentTypeEnumDTO;
  visibility: AttachmentVisibilityEnumDTO;
};

export const getFieldsConfig = (
  file?: CustomFile,
  type?: AttachmentTypeEnumDTO,
  visibility?: AttachmentVisibilityEnumDTO,
): FieldConfig<Values>[] => [
  {
    componentType: 'input',
    field: {
      initialValue: file?.title || file?.name || DEFAULT_FIELD_VALUE,
      name: 'title',
    },
    props: {
      labelKey: 'attachment.fields.title',
      type: 'text',
    },
  },
  {
    componentType: 'input',
    field: {
      initialValue: file?.description || DEFAULT_FIELD_VALUE,
      name: 'description',
    },
    props: {
      labelKey: 'attachment.fields.description',
      type: 'textarea',
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: type || AttachmentTypeEnumDTO.Other,
      name: 'type',
    },
    props: {
      labelKey: 'attachment.fields.type',
      options: documentTypeSelectOptions,
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: visibility || AttachmentVisibilityEnumDTO.Internal,
      name: 'visibility',
    },
    props: {
      labelKey: 'attachment.fields.visibilityLabel',
      options: documentVisibilitySelectOptions,
    },
  },
];

export const formConfig: FormProps<Values>['config'] = {
  onSubmit: () => {
    // do nothing
  },
};
