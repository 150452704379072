import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Badge from '@mui/material/Badge';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import type {
  Dispatch,
  FC,
  SetStateAction,
  SVGProps,
  SyntheticEvent,
} from 'react';
import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from '../../translations';
import type { NavItemProps } from '../NavItem';
import { NavItem } from '../NavItem';
import { sxProps } from './section.styles';

export type Props = {
  dataTestId: string;
  expanded: string | false;
  handleChange: (
    panel: string,
  ) =>
    | ((event: SyntheticEvent<Element, Event>, expanded: boolean) => void)
    | undefined;
  icon: FC<SVGProps<SVGSVGElement>>;
  items: NavItemProps[];
  setExpanded: Dispatch<SetStateAction<string | false>>;
  titleKey: GenericTypes.TranslationLabel;
};

export const Section: FC<Props> = (props) => {
  const {
    dataTestId,
    expanded,
    handleChange,
    items,
    icon,
    setExpanded,
    titleKey,
  } = props;
  const { t } = useTranslation();
  const [hasActiveChild, setActiveChild] = useState(false);
  const handleInitialActiveState = (isActive: boolean): void =>
    setActiveChild(isActive);

  useEffect(() => {
    if (hasActiveChild) {
      setExpanded(titleKey);
    }
  }, [hasActiveChild, setExpanded, titleKey]);
  const badgeCount = useMemo(
    () => items.reduce((acc, item) => (item.count ? acc + item.count : acc), 0),
    [items],
  );

  return (
    <Accordion
      data-testid={dataTestId}
      sx={sxProps.accordion}
      expanded={expanded === titleKey}
      onChange={handleChange(titleKey)}
      key={titleKey}
    >
      <AccordionSummary
        sx={sxProps.accordionSummary}
        aria-controls={`panel-${titleKey}bh-content`}
        id={`panel-${titleKey}bh-header`}
      >
        <Badge
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          badgeContent={badgeCount}
          color="primary"
          overlap="circular"
          sx={sxProps.badge}
        >
          <Icon component={icon} sx={sxProps.icon} />
        </Badge>
        {t(titleKey)}
      </AccordionSummary>
      <AccordionDetails sx={sxProps.accordionDetails}>
        <List>
          {items.map((item) => (
            <NavItem
              key={item.path || item.key}
              {...item}
              handleInitialActiveState={handleInitialActiveState}
            />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
