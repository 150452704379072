import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  dragging: {
    borderColor: (theme) => theme.palette.secondary.light,
    boxShadow:
      '0px 5px 8px -2px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.31)',
  },
  icon: {
    px: 1.5,
    py: 1.25,
  },
  listItem: {
    '&:last-of-type': {
      mb: 0,
    },
    backgroundColor: (theme) => theme.palette.background.paper,
    border: (theme) => `2px solid ${theme.palette.divider}`,
    borderRadius: (theme) => theme.spacing(2),
    cursor: 'grab',
    mb: 2,
    p: 1,
    touchAction: 'none',
  },
  name: {
    wordBreak: 'break-word',
  },
  preview: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: (theme) => theme.spacing(1),
    height: (theme) => theme.spacing(6),
    width: (theme) => theme.spacing(6),
  },
  tooltipPreview: {
    borderRadius: (theme) => theme.spacing(0.5),
    height: (theme) => theme.spacing(16),
    width: (theme) => theme.spacing(16),
  },
};
