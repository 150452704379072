import { Permission } from '../../../+auth/shared/enums/permission.enum';

export const CREATE_PERMISSIONS = [
  Permission.GLOBAL_WRITE,
  Permission.WRITE_PROPERTY,
  Permission.CAN_CREATE_PROPERTY,
];
export const UPDATE_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.WRITE_PROPERTY,
  Permission.CAN_UPDATE_PROPERTY,
];
export const VIEW_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.READ_PROPERTY,
  Permission.CAN_VIEW_PROPERTY,
];
