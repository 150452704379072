import { ReactComponent as SingleNeutralSearchIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-search.svg';
import LoadingButton from '@mui/lab/LoadingButton';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import { KYCPlatformEnumDTO } from '../../../../../../connectors/company';
import type { ProspectDetailsReadOnlySerializerDTO } from '../../../../../../connectors/property';
import { useAsync, useTranslation } from '../../../../../shared';
import { prospectClient } from '../../../../prospect.client';
import { useProspectConfig } from '../../../providers';

type Props = {
  // eslint-disable-next-line max-len
  country: ProspectDetailsReadOnlySerializerDTO['unit']['city']['country']['uuid'];
  id: ProspectDetailsReadOnlySerializerDTO['uuid'];
};

export const InvitationButton: FC<Props> = (props) => {
  const { country, id } = props;
  const { t } = useTranslation();
  const { setResponse } = useAsync<ProspectDetailsReadOnlySerializerDTO>();
  const { getIntegrationsConfig } = useProspectConfig();
  const [isFetching, setFetching] = useState(false);
  const integrations = useMemo(
    () => getIntegrationsConfig(country),
    [country, getIntegrationsConfig],
  );
  const simpleRentIntegration = useMemo(
    () =>
      integrations.find(
        (integration) =>
          integration.kycPlatform === KYCPlatformEnumDTO.SimplRent,
      ),
    [integrations],
  );
  const handleClick$ = useCallback(async () => {
    if (!simpleRentIntegration) return;

    setFetching(true);

    try {
      const response = await prospectClient.verificationInvite$(
        id,
        simpleRentIntegration.uuid,
      );

      setResponse((prevState) => ({
        ...prevState,
        prospectVerifications: [
          ...(prevState.prospectVerifications || []),
          response,
        ],
      }));
    } catch (e) {
      toast.error(t('errors.general.message'));
    } finally {
      setFetching(false);
    }
  }, [id, setResponse, simpleRentIntegration, t]);

  if (!simpleRentIntegration) return null;

  return (
    <LoadingButton
      color="primary"
      disabled={isFetching}
      loading={isFetching}
      loadingPosition="start"
      onClick={handleClick$}
      size="large"
      startIcon={<SingleNeutralSearchIcon height={24} width={24} />}
      variant="outlined"
    >
      {t('prospect.verification.status.inviteToVerify')}
    </LoadingButton>
  );
};
