import Typography from '@mui/material/Typography';
import type { TOptions } from 'i18next';
import type { CSSProperties, FC } from 'react';
import React from 'react';

import { useTranslation } from '../../translations';

type Props = {
  labelKey: GenericTypes.TranslationLabel;
  color?: string;
  fontWeight?: CSSProperties['fontWeight'];
  options?: TOptions;
};

export const TranslationCell: FC<Props> = (props) => {
  const { color, fontWeight, labelKey, options } = props;
  const { t } = useTranslation();

  return (
    <Typography sx={{ color, fontWeight }} variant="body2">
      {t(labelKey, options)}
    </Typography>
  );
};
