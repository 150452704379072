import { Formik } from 'formik';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { generatePath, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../+auth';
import type { AttachmentSerializerDTO } from '../../../../../connectors/company';
import type {
  ListAttachmentSerializerDTO,
  ServiceRequestDetailSerializerDTO,
} from '../../../../../connectors/ticket';
import {
  IssueSubtypeEnumDTO,
  RoomTypeEnumDTO,
  TicketVisibilityEnumDTO,
} from '../../../../../connectors/ticket';
import type { FileValue } from '../../../../shared';
import {
  handleSubmit,
  prepareInitialValues,
  prepareValidationSchema,
  useLocalization,
} from '../../../../shared';
import PATHS from '../../../paths.const';
import { Reporter } from '../../enums';
import { getTypePathBasedOnTicketType } from '../../helpers';
import type {
  Requests,
  RequestDetailsData,
  ServiceRequestValues,
} from '../../types';
import { Form } from './Form/form.component';
import { getFieldsConfig } from './service-request-form.const';

type Props = {
  onSubmit$: (
    payload: ServiceRequestDetailSerializerDTO,
    allAttachments: FileValue,
    existingAttachments?: AttachmentSerializerDTO[],
  ) => Promise<Requests>;
  attachments?: ListAttachmentSerializerDTO;
  details?: RequestDetailsData<ServiceRequestDetailSerializerDTO>;
  isPredefinedData?: boolean;
  isStepper?: boolean;
};

export const ServiceRequestForm: FC<Props> = (props) => {
  const {
    attachments,
    details,
    isPredefinedData,
    isStepper = true,
    onSubmit$,
  } = props;
  const { id, type } = useParams();
  const { userCountries } = useLocalization();
  const [globalError, setGlobalError] = useState<string | undefined>(undefined);
  const { user } = useAuth();
  const navigate = useNavigate();
  const existingAttachments = useMemo(
    () => attachments?.results || [],
    [attachments?.results],
  );
  const fieldsConfig = useMemo(
    () => getFieldsConfig(userCountries, details, existingAttachments),
    [details, existingAttachments, userCountries],
  );
  const handleSuccess = useCallback(
    ({ ticketType, uuid }: ServiceRequestDetailSerializerDTO) => {
      const type = getTypePathBasedOnTicketType(ticketType);

      navigate(generatePath(PATHS.DETAILS, { id: uuid, type }));
    },
    [navigate],
  );
  const handleCancel = useCallback(() => {
    navigate(generatePath(PATHS.DETAILS, { id, type }));
  }, [id, navigate, type]);
  const initialValues = useMemo(
    () => prepareInitialValues(fieldsConfig),
    [fieldsConfig],
  );
  const validationSchema = useMemo(
    () => prepareValidationSchema(fieldsConfig),
    [fieldsConfig],
  );
  const onSubmit = useCallback(
    (values: ServiceRequestValues) => {
      const {
        assignee,
        attachments,
        damagedItem,
        dueDate,
        entity,
        isPrivate,
        issueSubtype,
        reporter,
        roomWithIssue,
        tenant,
        ...rest
      } = values;

      if (!entity) return Promise.reject();

      const existingReporter = details?.reporter?.uuid;
      const newReporter =
        reporter === Reporter.ME ? user?.uuid || '' : tenant?.value || '';
      const reporterUuid = existingReporter || newReporter;
      const payload: ServiceRequestDetailSerializerDTO = {
        ...rest,
        // TODO: consult with backend if marking as optional string or null is possible on their side
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        assigneeUuid: assignee?.value || null,
        damagedItem: damagedItem || undefined,
        dueDate: dueDate ? dueDate.toISOString() : undefined,
        entityUuid: entity.value,
        issueSubtype: issueSubtype || IssueSubtypeEnumDTO.None,
        reporterUuid,
        roomWithIssue: roomWithIssue || RoomTypeEnumDTO.None,
        visibility: isPrivate
          ? TicketVisibilityEnumDTO.Private
          : TicketVisibilityEnumDTO.Public,
      };

      return onSubmit$(payload, attachments, existingAttachments);
    },
    [details?.reporter?.uuid, existingAttachments, onSubmit$, user?.uuid],
  );

  return (
    <Formik<ServiceRequestValues>
      initialValues={initialValues}
      onSubmit={handleSubmit(setGlobalError, handleSuccess, onSubmit)}
      validateOnMount
      validationSchema={validationSchema}
    >
      {() => (
        <Form
          details={details}
          fieldsConfig={fieldsConfig}
          globalErrorKey={globalError}
          isPredefinedData={isPredefinedData}
          isStepper={isStepper}
          onCancel={attachments ? handleCancel : undefined}
        />
      )}
    </Formik>
  );
};
