/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateInvoiceRequestSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { InvoiceRequestDetailSerializerDTO } from '../dto';
// @ts-ignore
import { InvoiceRequestSerializerDTO } from '../dto';
// @ts-ignore
import { PatchInvoiceRequestSerializerDTO } from '../dto';
/**
 * InvoiceRequestsAPI - axios parameter creator
 * @export
 */
export const InvoiceRequestsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Invoice Request
         * @param {CreateInvoiceRequestSerializerDTO} createInvoiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceRequestInvoiceRequestsPost: async (createInvoiceRequestSerializerDTO: CreateInvoiceRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvoiceRequestSerializerDTO' is not null or undefined
            assertParamExists('createInvoiceRequestInvoiceRequestsPost', 'createInvoiceRequestSerializerDTO', createInvoiceRequestSerializerDTO)
            const localVarPath = `/invoice-requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoiceRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Invoice Request Details
         * @param {string} invoiceRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGet: async (invoiceRequestUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceRequestUuid' is not null or undefined
            assertParamExists('getInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGet', 'invoiceRequestUuid', invoiceRequestUuid)
            const localVarPath = `/invoice-requests/{invoice_request_uuid}`
                .replace(`{${"invoice_request_uuid"}}`, encodeURIComponent(String(invoiceRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Invoice Request
         * @param {string} invoiceRequestUuid 
         * @param {PatchInvoiceRequestSerializerDTO} patchInvoiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch: async (invoiceRequestUuid: string, patchInvoiceRequestSerializerDTO: PatchInvoiceRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceRequestUuid' is not null or undefined
            assertParamExists('patchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch', 'invoiceRequestUuid', invoiceRequestUuid)
            // verify required parameter 'patchInvoiceRequestSerializerDTO' is not null or undefined
            assertParamExists('patchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch', 'patchInvoiceRequestSerializerDTO', patchInvoiceRequestSerializerDTO)
            const localVarPath = `/invoice-requests/{invoice_request_uuid}`
                .replace(`{${"invoice_request_uuid"}}`, encodeURIComponent(String(invoiceRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchInvoiceRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Invoice Request
         * @param {string} invoiceRequestUuid 
         * @param {CreateInvoiceRequestSerializerDTO} createInvoiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut: async (invoiceRequestUuid: string, createInvoiceRequestSerializerDTO: CreateInvoiceRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceRequestUuid' is not null or undefined
            assertParamExists('putInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut', 'invoiceRequestUuid', invoiceRequestUuid)
            // verify required parameter 'createInvoiceRequestSerializerDTO' is not null or undefined
            assertParamExists('putInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut', 'createInvoiceRequestSerializerDTO', createInvoiceRequestSerializerDTO)
            const localVarPath = `/invoice-requests/{invoice_request_uuid}`
                .replace(`{${"invoice_request_uuid"}}`, encodeURIComponent(String(invoiceRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvoiceRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceRequestsAPI - functional programming interface
 * @export
 */
export const InvoiceRequestsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceRequestsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Invoice Request
         * @param {CreateInvoiceRequestSerializerDTO} createInvoiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvoiceRequestInvoiceRequestsPost(createInvoiceRequestSerializerDTO: CreateInvoiceRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInvoiceRequestInvoiceRequestsPost(createInvoiceRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Invoice Request Details
         * @param {string} invoiceRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGet(invoiceRequestUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceRequestDetailSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGet(invoiceRequestUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Invoice Request
         * @param {string} invoiceRequestUuid 
         * @param {PatchInvoiceRequestSerializerDTO} patchInvoiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch(invoiceRequestUuid: string, patchInvoiceRequestSerializerDTO: PatchInvoiceRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch(invoiceRequestUuid, patchInvoiceRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Invoice Request
         * @param {string} invoiceRequestUuid 
         * @param {CreateInvoiceRequestSerializerDTO} createInvoiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut(invoiceRequestUuid: string, createInvoiceRequestSerializerDTO: CreateInvoiceRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut(invoiceRequestUuid, createInvoiceRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceRequestsAPI - factory interface
 * @export
 */
export const InvoiceRequestsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceRequestsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Create Invoice Request
         * @param {CreateInvoiceRequestSerializerDTO} createInvoiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceRequestInvoiceRequestsPost(createInvoiceRequestSerializerDTO: CreateInvoiceRequestSerializerDTO, options?: any): AxiosPromise<InvoiceRequestSerializerDTO> {
            return localVarFp.createInvoiceRequestInvoiceRequestsPost(createInvoiceRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Invoice Request Details
         * @param {string} invoiceRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGet(invoiceRequestUuid: string, options?: any): AxiosPromise<InvoiceRequestDetailSerializerDTO> {
            return localVarFp.getInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGet(invoiceRequestUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Invoice Request
         * @param {string} invoiceRequestUuid 
         * @param {PatchInvoiceRequestSerializerDTO} patchInvoiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch(invoiceRequestUuid: string, patchInvoiceRequestSerializerDTO: PatchInvoiceRequestSerializerDTO, options?: any): AxiosPromise<InvoiceRequestSerializerDTO> {
            return localVarFp.patchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch(invoiceRequestUuid, patchInvoiceRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Invoice Request
         * @param {string} invoiceRequestUuid 
         * @param {CreateInvoiceRequestSerializerDTO} createInvoiceRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut(invoiceRequestUuid: string, createInvoiceRequestSerializerDTO: CreateInvoiceRequestSerializerDTO, options?: any): AxiosPromise<InvoiceRequestSerializerDTO> {
            return localVarFp.putInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut(invoiceRequestUuid, createInvoiceRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInvoiceRequestInvoiceRequestsPost operation in InvoiceRequestsAPI.
 * @export
 * @interface InvoiceRequestsAPICreateInvoiceRequestInvoiceRequestsPostRequest
 */
export interface InvoiceRequestsAPICreateInvoiceRequestInvoiceRequestsPostRequest {
    /**
     * 
     * @type {CreateInvoiceRequestSerializerDTO}
     * @memberof InvoiceRequestsAPICreateInvoiceRequestInvoiceRequestsPost
     */
    readonly createInvoiceRequestSerializerDTO: CreateInvoiceRequestSerializerDTO
}

/**
 * Request parameters for getInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGet operation in InvoiceRequestsAPI.
 * @export
 * @interface InvoiceRequestsAPIGetInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGetRequest
 */
export interface InvoiceRequestsAPIGetInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoiceRequestsAPIGetInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGet
     */
    readonly invoiceRequestUuid: string
}

/**
 * Request parameters for patchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch operation in InvoiceRequestsAPI.
 * @export
 * @interface InvoiceRequestsAPIPatchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatchRequest
 */
export interface InvoiceRequestsAPIPatchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoiceRequestsAPIPatchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch
     */
    readonly invoiceRequestUuid: string

    /**
     * 
     * @type {PatchInvoiceRequestSerializerDTO}
     * @memberof InvoiceRequestsAPIPatchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch
     */
    readonly patchInvoiceRequestSerializerDTO: PatchInvoiceRequestSerializerDTO
}

/**
 * Request parameters for putInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut operation in InvoiceRequestsAPI.
 * @export
 * @interface InvoiceRequestsAPIPutInvoiceRequestInvoiceRequestsInvoiceRequestUuidPutRequest
 */
export interface InvoiceRequestsAPIPutInvoiceRequestInvoiceRequestsInvoiceRequestUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoiceRequestsAPIPutInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut
     */
    readonly invoiceRequestUuid: string

    /**
     * 
     * @type {CreateInvoiceRequestSerializerDTO}
     * @memberof InvoiceRequestsAPIPutInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut
     */
    readonly createInvoiceRequestSerializerDTO: CreateInvoiceRequestSerializerDTO
}

/**
 * InvoiceRequestsAPI - object-oriented interface
 * @export
 * @class InvoiceRequestsAPI
 * @extends {BaseAPI}
 */
export class InvoiceRequestsAPI extends BaseAPI {
    /**
     * 
     * @summary Create Invoice Request
     * @param {InvoiceRequestsAPICreateInvoiceRequestInvoiceRequestsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceRequestsAPI
     */
    public createInvoiceRequestInvoiceRequestsPost(requestParameters: InvoiceRequestsAPICreateInvoiceRequestInvoiceRequestsPostRequest, options?: any) {
        return InvoiceRequestsAPIFp(this.configuration).createInvoiceRequestInvoiceRequestsPost(requestParameters.createInvoiceRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Invoice Request Details
     * @param {InvoiceRequestsAPIGetInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceRequestsAPI
     */
    public getInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGet(requestParameters: InvoiceRequestsAPIGetInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGetRequest, options?: any) {
        return InvoiceRequestsAPIFp(this.configuration).getInvoiceRequestDetailsInvoiceRequestsInvoiceRequestUuidGet(requestParameters.invoiceRequestUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Invoice Request
     * @param {InvoiceRequestsAPIPatchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceRequestsAPI
     */
    public patchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch(requestParameters: InvoiceRequestsAPIPatchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatchRequest, options?: any) {
        return InvoiceRequestsAPIFp(this.configuration).patchInvoiceRequestInvoiceRequestsInvoiceRequestUuidPatch(requestParameters.invoiceRequestUuid, requestParameters.patchInvoiceRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Invoice Request
     * @param {InvoiceRequestsAPIPutInvoiceRequestInvoiceRequestsInvoiceRequestUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceRequestsAPI
     */
    public putInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut(requestParameters: InvoiceRequestsAPIPutInvoiceRequestInvoiceRequestsInvoiceRequestUuidPutRequest, options?: any) {
        return InvoiceRequestsAPIFp(this.configuration).putInvoiceRequestInvoiceRequestsInvoiceRequestUuidPut(requestParameters.invoiceRequestUuid, requestParameters.createInvoiceRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
