import { ReactComponent as BinIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/delete/bin.svg';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC, MouseEvent } from 'react';
import React, { useCallback } from 'react';

import { getIcon } from '../../helpers';
import { useAttachmentUploader } from '../../providers';
import { sxProps } from './preview.styles';

export const Preview: FC = () => {
  const { attachments, setAttachments } = useAttachmentUploader();
  const onSelect = useCallback(
    (idx: number) =>
      setAttachments((prevState) =>
        prevState.map((attachment, index) => ({
          ...attachment,
          isCurrent: idx === index,
        })),
      ),
    [setAttachments],
  );
  const onRemove = useCallback(
    (event: MouseEvent<HTMLButtonElement>, idx: number) => {
      event.stopPropagation();
      setAttachments((prevState) =>
        [...prevState.slice(0, idx), ...prevState.slice(idx + 1)].map(
          (attachment, index) => ({
            ...attachment,
            isCurrent: index === 0,
          }),
        ),
      );
    },
    [setAttachments],
  );

  return (
    <List sx={sxProps.list}>
      {attachments.map((attachment, idx) => {
        const Icon = getIcon(attachment.file.type);
        // eslint-disable-next-line max-len
        const key = `attachment-${attachment.file.name}-${attachment.file.type}-${attachment.file.lastModified}`;

        return (
          <ListItem
            key={key}
            onClick={() => onSelect(idx)}
            sx={
              {
                ...sxProps.listItem,
                ...(attachment.isCurrent && sxProps.listItemSelected),
              } as SxProps
            }
          >
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item>
                <Grid alignItems="center" container spacing={1} wrap="nowrap">
                  <Grid item>
                    <Icon height={24} width={24} />
                  </Grid>
                  <Grid item>
                    <Typography sx={sxProps.name} variant="h5">
                      {attachment.file.title || attachment.file.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <IconButton
                  data-testid={`${attachment.file.name}-option-${idx}`}
                  onClick={(event) => onRemove(event, idx)}
                >
                  <BinIcon height={16} width={16} />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
};
