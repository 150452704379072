import MUIBox from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import type { FC, Key } from 'react';
import React, { useCallback, useMemo } from 'react';

import type { EntityEnumDTO } from '../../../../connectors/company';
import type {
  CountrySerializerDTO,
  DetailedPropertyObjectSerializerDTO,
  DetailedPropertySerializerDTO,
  DetailedUnitSerializerDTO,
} from '../../../../connectors/property';
import { localizationClient } from '../../../localization.client';
import { useApi } from '../../hooks';
import type { SpecificAttributeList } from '../AttributeList';
import { getAttributeListModel, AttributeList } from '../AttributeList';
import { Box } from '../Box/box.component';
import type { CollapseTextProps } from '../CollapseText';
import { CollapseText } from '../CollapseText';
import { Feature } from '../Feature';
import type { FeatureProps } from '../Feature';
import { sxProps } from './info-box.styles';

type Props = {
  attributeList?:
    | DetailedUnitSerializerDTO['props']
    | DetailedPropertySerializerDTO['props']
    | DetailedPropertyObjectSerializerDTO['props'];
  attributeListTitleKey?: GenericTypes.TranslationLabel;
  featureList: (FeatureProps & { key: Key })[];
  additionalCollapseContent?: CollapseTextProps['additionalContent'];
  description?: CollapseTextProps['text'];
  countryUuid?: CountrySerializerDTO['uuid'];
  entityType?: EntityEnumDTO;
};

export const InfoBox: FC<Props> = (props) => {
  const {
    additionalCollapseContent,
    attributeList,
    attributeListTitleKey,
    countryUuid,
    description,
    entityType,
    featureList,
  } = props;
  const getPropConfigs = useCallback(() => {
    if (!entityType) return Promise.reject();

    return localizationClient.getPropConfigs$(countryUuid ?? '', entityType);
  }, [countryUuid, entityType]);
  const propConfigs = useApi([], getPropConfigs);
  const attributesModel = useMemo(
    () =>
      getAttributeListModel(
        attributeList as SpecificAttributeList,
        propConfigs?.response,
      ),
    [propConfigs, attributeList],
  );
  const hasValidAttributes = useMemo(
    () => entityType && attributesModel.some((item) => !!item.value),
    [attributesModel, entityType],
  );

  return (
    <Box>
      <CollapseText
        additionalContent={additionalCollapseContent}
        text={description}
      />
      <MUIBox sx={sxProps.features}>
        <Grid container spacing={2}>
          {featureList.map((feature) => {
            const { key, ...rest } = feature;

            return (
              <Grid key={`feature-${key}`} sx={sxProps.grid} item>
                <MUIBox>
                  <Feature {...rest} />
                </MUIBox>
              </Grid>
            );
          })}
        </Grid>
        {hasValidAttributes && (
          <AttributeList
            attributes={attributesModel}
            attributeListTitleKey={attributeListTitleKey ?? ''}
          />
        )}
      </MUIBox>
    </Box>
  );
};
