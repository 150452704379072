import type { Account } from '../../../+account';
import type { AsyncAutocompleteOption } from '../../../shared';

export const DAYS_NUMBER_TO_FILTER_DONE_TICKET = 7;
export const INITIAL_REQUEST_SORT = '-_priority';
export const UNASSIGNED_VALUE = 'UNASSIGNED';
export const REQUEST_SORT_OPTIONS = [
  { labelKey: 'ticket.list.sort.priority.desc', value: '-_priority' },
  { labelKey: 'ticket.list.sort.priority.asc', value: '_priority' },
  { labelKey: 'collection.sortByCreatedDate.desc', value: '-created_at' },
  { labelKey: 'collection.sortByCreatedDate.asc', value: 'created_at' },
];
export const getDefaultAssigneeOptions = (
  uuid?: Account['uuid'],
): NonNullable<AsyncAutocompleteOption<Account['uuid']>>[] => [
  {
    label: '',
    labelKey: 'ticket.fields.defaultAssignee',
    value: UNASSIGNED_VALUE,
  },
  ...(uuid
    ? [
        {
          label: '',
          labelKey: 'ticket.fields.assignedMe',
          value: uuid,
        },
      ]
    : []),
];
