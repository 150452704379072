/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttachmentCategoryEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { AttachmentTypeEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentVisibilityEnumDTO } from '../dto';
// @ts-ignore
import { CreateLeaseSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { LeaseSerializerDTO } from '../dto';
// @ts-ignore
import { ListAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ListLeasesSerializerDTO } from '../dto';
// @ts-ignore
import { SignLeaseSerializerDTO } from '../dto';
/**
 * LeasesAPI - axios parameter creator
 * @export
 */
export const LeasesAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Lease
         * @param {CreateLeaseSerializerDTO} createLeaseSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLeaseLeasesPost: async (createLeaseSerializerDTO: CreateLeaseSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLeaseSerializerDTO' is not null or undefined
            assertParamExists('createLeaseLeasesPost', 'createLeaseSerializerDTO', createLeaseSerializerDTO)
            const localVarPath = `/leases/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLeaseSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Lease Attachment
         * @param {string} leaseUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete: async (leaseUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseUuid' is not null or undefined
            assertParamExists('deleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete', 'leaseUuid', leaseUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('deleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/leases/{lease_uuid}/attachments/{attachment_uuid}`
                .replace(`{${"lease_uuid"}}`, encodeURIComponent(String(leaseUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Lease Attachment
         * @param {string} leaseUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet: async (leaseUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseUuid' is not null or undefined
            assertParamExists('downloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet', 'leaseUuid', leaseUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('downloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/leases/{lease_uuid}/attachments/{attachment_uuid}/download`
                .replace(`{${"lease_uuid"}}`, encodeURIComponent(String(leaseUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Leases
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [signerUuid] 
         * @param {string} [status] 
         * @param {string} [startDateLt] 
         * @param {string} [startDateLte] 
         * @param {string} [startDateGt] 
         * @param {string} [startDateGte] 
         * @param {string} [endDateLt] 
         * @param {string} [endDateLte] 
         * @param {string} [endDateGt] 
         * @param {string} [endDateGte] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeasesLeasesGet: async (page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, signerUuid?: string, status?: string, startDateLt?: string, startDateLte?: string, startDateGt?: string, startDateGte?: string, endDateLt?: string, endDateLte?: string, endDateGt?: string, endDateGte?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/leases/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (signerUuid !== undefined) {
                localVarQueryParameter['signer_uuid'] = signerUuid;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (startDateLt !== undefined) {
                localVarQueryParameter['start_date_lt'] = (startDateLt as any instanceof Date) ?
                    (startDateLt as any).toISOString().substr(0,10) :
                    startDateLt;
            }

            if (startDateLte !== undefined) {
                localVarQueryParameter['start_date_lte'] = (startDateLte as any instanceof Date) ?
                    (startDateLte as any).toISOString().substr(0,10) :
                    startDateLte;
            }

            if (startDateGt !== undefined) {
                localVarQueryParameter['start_date_gt'] = (startDateGt as any instanceof Date) ?
                    (startDateGt as any).toISOString().substr(0,10) :
                    startDateGt;
            }

            if (startDateGte !== undefined) {
                localVarQueryParameter['start_date_gte'] = (startDateGte as any instanceof Date) ?
                    (startDateGte as any).toISOString().substr(0,10) :
                    startDateGte;
            }

            if (endDateLt !== undefined) {
                localVarQueryParameter['end_date_lt'] = (endDateLt as any instanceof Date) ?
                    (endDateLt as any).toISOString().substr(0,10) :
                    endDateLt;
            }

            if (endDateLte !== undefined) {
                localVarQueryParameter['end_date_lte'] = (endDateLte as any instanceof Date) ?
                    (endDateLte as any).toISOString().substr(0,10) :
                    endDateLte;
            }

            if (endDateGt !== undefined) {
                localVarQueryParameter['end_date_gt'] = (endDateGt as any instanceof Date) ?
                    (endDateGt as any).toISOString().substr(0,10) :
                    endDateGt;
            }

            if (endDateGte !== undefined) {
                localVarQueryParameter['end_date_gte'] = (endDateGte as any instanceof Date) ?
                    (endDateGte as any).toISOString().substr(0,10) :
                    endDateGte;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Lease
         * @param {string} leaseUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleLeaseLeasesLeaseUuidGet: async (leaseUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseUuid' is not null or undefined
            assertParamExists('getSingleLeaseLeasesLeaseUuidGet', 'leaseUuid', leaseUuid)
            const localVarPath = `/leases/{lease_uuid}`
                .replace(`{${"lease_uuid"}}`, encodeURIComponent(String(leaseUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Unit Attachment
         * @param {string} leaseUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitAttachmentLeasesLeaseUuidAttachmentsGet: async (leaseUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseUuid' is not null or undefined
            assertParamExists('getUnitAttachmentLeasesLeaseUuidAttachmentsGet', 'leaseUuid', leaseUuid)
            const localVarPath = `/leases/{lease_uuid}/attachments`
                .replace(`{${"lease_uuid"}}`, encodeURIComponent(String(leaseUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign Lease
         * @param {SignLeaseSerializerDTO} signLeaseSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signLeaseLeasesSignPost: async (signLeaseSerializerDTO: SignLeaseSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signLeaseSerializerDTO' is not null or undefined
            assertParamExists('signLeaseLeasesSignPost', 'signLeaseSerializerDTO', signLeaseSerializerDTO)
            const localVarPath = `/leases/sign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signLeaseSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Lease Attachment
         * @param {string} leaseUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost: async (leaseUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'leaseUuid' is not null or undefined
            assertParamExists('uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost', 'leaseUuid', leaseUuid)
            // verify required parameter 'attachmentCategory' is not null or undefined
            assertParamExists('uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost', 'attachmentCategory', attachmentCategory)
            // verify required parameter 'attachmentType' is not null or undefined
            assertParamExists('uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost', 'attachmentType', attachmentType)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost', 'files', files)
            const localVarPath = `/leases/{lease_uuid}/attachments`
                .replace(`{${"lease_uuid"}}`, encodeURIComponent(String(leaseUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


            if (attachmentCategory !== undefined) { 
                localVarFormParams.append('attachment_category', new Blob([JSON.stringify(attachmentCategory)], { type: "application/json", }));
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', new Blob([JSON.stringify(attachmentType)], { type: "application/json", }));
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (visibility !== undefined) { 
                localVarFormParams.append('visibility', new Blob([JSON.stringify(visibility)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateImportDataFormatLeasesDataImportPost: async (file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('validateImportDataFormatLeasesDataImportPost', 'file', file)
            const localVarPath = `/leases/data_import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeasesAPI - functional programming interface
 * @export
 */
export const LeasesAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeasesAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Lease
         * @param {CreateLeaseSerializerDTO} createLeaseSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLeaseLeasesPost(createLeaseSerializerDTO: CreateLeaseSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeaseSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLeaseLeasesPost(createLeaseSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Lease Attachment
         * @param {string} leaseUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete(leaseUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete(leaseUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Lease Attachment
         * @param {string} leaseUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet(leaseUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet(leaseUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Leases
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [signerUuid] 
         * @param {string} [status] 
         * @param {string} [startDateLt] 
         * @param {string} [startDateLte] 
         * @param {string} [startDateGt] 
         * @param {string} [startDateGte] 
         * @param {string} [endDateLt] 
         * @param {string} [endDateLte] 
         * @param {string} [endDateGt] 
         * @param {string} [endDateGte] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeasesLeasesGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, signerUuid?: string, status?: string, startDateLt?: string, startDateLte?: string, startDateGt?: string, startDateGte?: string, endDateLt?: string, endDateLte?: string, endDateGt?: string, endDateGte?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLeasesSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeasesLeasesGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, signerUuid, status, startDateLt, startDateLte, startDateGt, startDateGte, endDateLt, endDateLte, endDateGt, endDateGte, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Lease
         * @param {string} leaseUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleLeaseLeasesLeaseUuidGet(leaseUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeaseSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleLeaseLeasesLeaseUuidGet(leaseUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Unit Attachment
         * @param {string} leaseUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnitAttachmentLeasesLeaseUuidAttachmentsGet(leaseUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnitAttachmentLeasesLeaseUuidAttachmentsGet(leaseUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign Lease
         * @param {SignLeaseSerializerDTO} signLeaseSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signLeaseLeasesSignPost(signLeaseSerializerDTO: SignLeaseSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeaseSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signLeaseLeasesSignPost(signLeaseSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Lease Attachment
         * @param {string} leaseUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost(leaseUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttachmentSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost(leaseUuid, attachmentCategory, attachmentType, files, title, description, visibility, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateImportDataFormatLeasesDataImportPost(file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateImportDataFormatLeasesDataImportPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeasesAPI - factory interface
 * @export
 */
export const LeasesAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeasesAPIFp(configuration)
    return {
        /**
         * 
         * @summary Create Lease
         * @param {CreateLeaseSerializerDTO} createLeaseSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLeaseLeasesPost(createLeaseSerializerDTO: CreateLeaseSerializerDTO, options?: any): AxiosPromise<LeaseSerializerDTO> {
            return localVarFp.createLeaseLeasesPost(createLeaseSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Lease Attachment
         * @param {string} leaseUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete(leaseUuid: string, attachmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete(leaseUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Lease Attachment
         * @param {string} leaseUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet(leaseUuid: string, attachmentUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet(leaseUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Leases
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [signerUuid] 
         * @param {string} [status] 
         * @param {string} [startDateLt] 
         * @param {string} [startDateLte] 
         * @param {string} [startDateGt] 
         * @param {string} [startDateGte] 
         * @param {string} [endDateLt] 
         * @param {string} [endDateLte] 
         * @param {string} [endDateGt] 
         * @param {string} [endDateGte] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeasesLeasesGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, signerUuid?: string, status?: string, startDateLt?: string, startDateLte?: string, startDateGt?: string, startDateGte?: string, endDateLt?: string, endDateLte?: string, endDateGt?: string, endDateGte?: string, options?: any): AxiosPromise<ListLeasesSerializerDTO> {
            return localVarFp.getLeasesLeasesGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, signerUuid, status, startDateLt, startDateLte, startDateGt, startDateGte, endDateLt, endDateLte, endDateGt, endDateGte, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Lease
         * @param {string} leaseUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleLeaseLeasesLeaseUuidGet(leaseUuid: string, options?: any): AxiosPromise<LeaseSerializerDTO> {
            return localVarFp.getSingleLeaseLeasesLeaseUuidGet(leaseUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Unit Attachment
         * @param {string} leaseUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitAttachmentLeasesLeaseUuidAttachmentsGet(leaseUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): AxiosPromise<ListAttachmentSerializerDTO> {
            return localVarFp.getUnitAttachmentLeasesLeaseUuidAttachmentsGet(leaseUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign Lease
         * @param {SignLeaseSerializerDTO} signLeaseSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signLeaseLeasesSignPost(signLeaseSerializerDTO: SignLeaseSerializerDTO, options?: any): AxiosPromise<LeaseSerializerDTO> {
            return localVarFp.signLeaseLeasesSignPost(signLeaseSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Lease Attachment
         * @param {string} leaseUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost(leaseUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): AxiosPromise<Array<AttachmentSerializerDTO>> {
            return localVarFp.uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost(leaseUuid, attachmentCategory, attachmentType, files, title, description, visibility, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateImportDataFormatLeasesDataImportPost(file: any, options?: any): AxiosPromise<any> {
            return localVarFp.validateImportDataFormatLeasesDataImportPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createLeaseLeasesPost operation in LeasesAPI.
 * @export
 * @interface LeasesAPICreateLeaseLeasesPostRequest
 */
export interface LeasesAPICreateLeaseLeasesPostRequest {
    /**
     * 
     * @type {CreateLeaseSerializerDTO}
     * @memberof LeasesAPICreateLeaseLeasesPost
     */
    readonly createLeaseSerializerDTO: CreateLeaseSerializerDTO
}

/**
 * Request parameters for deleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete operation in LeasesAPI.
 * @export
 * @interface LeasesAPIDeleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDeleteRequest
 */
export interface LeasesAPIDeleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof LeasesAPIDeleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete
     */
    readonly leaseUuid: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIDeleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for downloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet operation in LeasesAPI.
 * @export
 * @interface LeasesAPIDownloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGetRequest
 */
export interface LeasesAPIDownloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGetRequest {
    /**
     * 
     * @type {string}
     * @memberof LeasesAPIDownloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly leaseUuid: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIDownloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for getLeasesLeasesGet operation in LeasesAPI.
 * @export
 * @interface LeasesAPIGetLeasesLeasesGetRequest
 */
export interface LeasesAPIGetLeasesLeasesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly signerUuid?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly status?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly startDateLt?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly startDateLte?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly startDateGt?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly startDateGte?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly endDateLt?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly endDateLte?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly endDateGt?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetLeasesLeasesGet
     */
    readonly endDateGte?: string
}

/**
 * Request parameters for getSingleLeaseLeasesLeaseUuidGet operation in LeasesAPI.
 * @export
 * @interface LeasesAPIGetSingleLeaseLeasesLeaseUuidGetRequest
 */
export interface LeasesAPIGetSingleLeaseLeasesLeaseUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetSingleLeaseLeasesLeaseUuidGet
     */
    readonly leaseUuid: string
}

/**
 * Request parameters for getUnitAttachmentLeasesLeaseUuidAttachmentsGet operation in LeasesAPI.
 * @export
 * @interface LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGetRequest
 */
export interface LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGet
     */
    readonly leaseUuid: string

    /**
     * 
     * @type {number}
     * @memberof LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGet
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGet
     */
    readonly type?: string
}

/**
 * Request parameters for signLeaseLeasesSignPost operation in LeasesAPI.
 * @export
 * @interface LeasesAPISignLeaseLeasesSignPostRequest
 */
export interface LeasesAPISignLeaseLeasesSignPostRequest {
    /**
     * 
     * @type {SignLeaseSerializerDTO}
     * @memberof LeasesAPISignLeaseLeasesSignPost
     */
    readonly signLeaseSerializerDTO: SignLeaseSerializerDTO
}

/**
 * Request parameters for uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost operation in LeasesAPI.
 * @export
 * @interface LeasesAPIUploadLeaseAttachmentLeasesLeaseUuidAttachmentsPostRequest
 */
export interface LeasesAPIUploadLeaseAttachmentLeasesLeaseUuidAttachmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof LeasesAPIUploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost
     */
    readonly leaseUuid: string

    /**
     * 
     * @type {AttachmentCategoryEnumDTO}
     * @memberof LeasesAPIUploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost
     */
    readonly attachmentCategory: AttachmentCategoryEnumDTO

    /**
     * 
     * @type {AttachmentTypeEnumDTO}
     * @memberof LeasesAPIUploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost
     */
    readonly attachmentType: AttachmentTypeEnumDTO

    /**
     * 
     * @type {Array<any>}
     * @memberof LeasesAPIUploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost
     */
    readonly files: Array<any>

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIUploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost
     */
    readonly title?: string

    /**
     * 
     * @type {string}
     * @memberof LeasesAPIUploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost
     */
    readonly description?: string

    /**
     * 
     * @type {AttachmentVisibilityEnumDTO}
     * @memberof LeasesAPIUploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost
     */
    readonly visibility?: AttachmentVisibilityEnumDTO
}

/**
 * Request parameters for validateImportDataFormatLeasesDataImportPost operation in LeasesAPI.
 * @export
 * @interface LeasesAPIValidateImportDataFormatLeasesDataImportPostRequest
 */
export interface LeasesAPIValidateImportDataFormatLeasesDataImportPostRequest {
    /**
     * 
     * @type {any}
     * @memberof LeasesAPIValidateImportDataFormatLeasesDataImportPost
     */
    readonly file: any
}

/**
 * LeasesAPI - object-oriented interface
 * @export
 * @class LeasesAPI
 * @extends {BaseAPI}
 */
export class LeasesAPI extends BaseAPI {
    /**
     * 
     * @summary Create Lease
     * @param {LeasesAPICreateLeaseLeasesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeasesAPI
     */
    public createLeaseLeasesPost(requestParameters: LeasesAPICreateLeaseLeasesPostRequest, options?: any) {
        return LeasesAPIFp(this.configuration).createLeaseLeasesPost(requestParameters.createLeaseSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Lease Attachment
     * @param {LeasesAPIDeleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeasesAPI
     */
    public deleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete(requestParameters: LeasesAPIDeleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDeleteRequest, options?: any) {
        return LeasesAPIFp(this.configuration).deleteLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDelete(requestParameters.leaseUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Lease Attachment
     * @param {LeasesAPIDownloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeasesAPI
     */
    public downloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet(requestParameters: LeasesAPIDownloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGetRequest, options?: any) {
        return LeasesAPIFp(this.configuration).downloadLeaseAttachmentLeasesLeaseUuidAttachmentsAttachmentUuidDownloadGet(requestParameters.leaseUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Leases
     * @param {LeasesAPIGetLeasesLeasesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeasesAPI
     */
    public getLeasesLeasesGet(requestParameters: LeasesAPIGetLeasesLeasesGetRequest = {}, options?: any) {
        return LeasesAPIFp(this.configuration).getLeasesLeasesGet(requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.signerUuid, requestParameters.status, requestParameters.startDateLt, requestParameters.startDateLte, requestParameters.startDateGt, requestParameters.startDateGte, requestParameters.endDateLt, requestParameters.endDateLte, requestParameters.endDateGt, requestParameters.endDateGte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Lease
     * @param {LeasesAPIGetSingleLeaseLeasesLeaseUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeasesAPI
     */
    public getSingleLeaseLeasesLeaseUuidGet(requestParameters: LeasesAPIGetSingleLeaseLeasesLeaseUuidGetRequest, options?: any) {
        return LeasesAPIFp(this.configuration).getSingleLeaseLeasesLeaseUuidGet(requestParameters.leaseUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Unit Attachment
     * @param {LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeasesAPI
     */
    public getUnitAttachmentLeasesLeaseUuidAttachmentsGet(requestParameters: LeasesAPIGetUnitAttachmentLeasesLeaseUuidAttachmentsGetRequest, options?: any) {
        return LeasesAPIFp(this.configuration).getUnitAttachmentLeasesLeaseUuidAttachmentsGet(requestParameters.leaseUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.category, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign Lease
     * @param {LeasesAPISignLeaseLeasesSignPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeasesAPI
     */
    public signLeaseLeasesSignPost(requestParameters: LeasesAPISignLeaseLeasesSignPostRequest, options?: any) {
        return LeasesAPIFp(this.configuration).signLeaseLeasesSignPost(requestParameters.signLeaseSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Lease Attachment
     * @param {LeasesAPIUploadLeaseAttachmentLeasesLeaseUuidAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeasesAPI
     */
    public uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost(requestParameters: LeasesAPIUploadLeaseAttachmentLeasesLeaseUuidAttachmentsPostRequest, options?: any) {
        return LeasesAPIFp(this.configuration).uploadLeaseAttachmentLeasesLeaseUuidAttachmentsPost(requestParameters.leaseUuid, requestParameters.attachmentCategory, requestParameters.attachmentType, requestParameters.files, requestParameters.title, requestParameters.description, requestParameters.visibility, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate Import Data Format
     * @param {LeasesAPIValidateImportDataFormatLeasesDataImportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeasesAPI
     */
    public validateImportDataFormatLeasesDataImportPost(requestParameters: LeasesAPIValidateImportDataFormatLeasesDataImportPostRequest, options?: any) {
        return LeasesAPIFp(this.configuration).validateImportDataFormatLeasesDataImportPost(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}
