import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import type { FC, Dispatch, SetStateAction } from 'react';
import React, { useMemo } from 'react';

import type { CountrySerializerDTO } from '../../../../../connectors/company';
import type { ContactSerializerDTO } from '../../../../../connectors/property';
import type { UserRoleEnumDTO } from '../../../../../connectors/user';
import { ObjectRow } from '../../../../shared';
import type { ContactUserId, User } from '../../types';
import { isReassignConfig } from '../ConfirmationDialog/confirmation-dialog.const';
import type {
  ConfirmationConfig,
  PickedConfirmationConfig,
} from '../ConfirmationDialog/confirmation-dialog.type';
import { ItemActions } from '../ItemActions/item-actions.component';
import { Reassign } from '../Reassign/reassign.component';
import { sxProps } from './user-item.styles';

type Props = {
  data: User;
  dialogConfig: ConfirmationConfig;
  isDisabled: boolean;
  isReassign: boolean;
  isRemove: boolean;
  setDialogConfig: (config: PickedConfirmationConfig) => void;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  country?: CountrySerializerDTO['uuid'];
  excludedRoles?: UserRoleEnumDTO[];
  onReassign$?: (
    newUserId: ContactSerializerDTO['uuid'],
    oldUserId: ContactSerializerDTO['uuid'],
  ) => Promise<void>;
  onRemove$?: (
    id: ContactUserId,
    setEditMode: Dispatch<SetStateAction<boolean>>,
  ) => Promise<void>;
  suggestionRoles?: UserRoleEnumDTO[];
};

export const UserItem: FC<Props> = (props) => {
  const {
    country,
    data,
    excludedRoles,
    isDisabled,
    isReassign,
    isRemove,
    onReassign$,
    onRemove$,
    setDialogConfig,
    setDisabled,
    setEditMode,
    suggestionRoles,
    dialogConfig,
  } = props;
  const { avatarSrc, contactId, isFixed, path, position, id, name } = data;
  const isReassignForm = useMemo(
    () =>
      dialogConfig &&
      isReassignConfig(dialogConfig) &&
      dialogConfig.oldUserId === id,
    [dialogConfig, id],
  );

  return (
    <Grid item sx={sxProps.item}>
      {isReassignForm ? (
        <Reassign
          country={country}
          dialogConfig={dialogConfig}
          excludedRoles={excludedRoles}
          isDisabled={isDisabled}
          onReassign$={onReassign$}
          roles={suggestionRoles}
          setDialogConfig={setDialogConfig}
          setDisabled={setDisabled}
          setEditMode={setEditMode}
        />
      ) : (
        <Box sx={sxProps.container}>
          <ObjectRow
            avatarSrc={avatarSrc}
            id={id}
            path={isDisabled ? undefined : path}
            subtitleKey={position}
            title={name}
          />
          <ItemActions
            isDisabled={isDisabled}
            isFixed={isFixed}
            isReassign={isReassign}
            isRemove={isRemove}
            onRemove$={onRemove$}
            removeId={contactId || id}
            setDialogConfig={setDialogConfig}
            setDisabled={setDisabled}
            setEditMode={setEditMode}
            userId={id}
          />
        </Box>
      )}
    </Grid>
  );
};
