import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { useAuth } from '../../../../../../+auth';
import { getUserRoleTranslationLabelKey } from '../../../../../../+user';
import {
  getUserName,
  ObjectRow,
  useAsync,
  useTranslation,
} from '../../../../../../shared';
import type { UserData } from '../summary-step.type';

export const Reporter: FC = () => {
  const { response } = useAsync<UserData>();
  const { t } = useTranslation();
  const { user } = useAuth();
  const reporter = useMemo(() => {
    const role = response.reporter?.roles
      ? response.reporter.roles
      : user?.roles || [];

    return {
      avatarSrc: response.reporter
        ? response.reporter.profilePicture
        : user?.profilePicture,
      id: response.reporter ? response.reporter.uuid : user?.uuid || '',
      subtitleKey: getUserRoleTranslationLabelKey(role),
      title: response.reporter
        ? getUserName(response.reporter)
        : user?.name || '',
    };
  }, [
    response.reporter,
    user?.profilePicture,
    user?.name,
    user?.roles,
    user?.uuid,
  ]);

  return (
    <>
      <Typography
        component="div"
        color="text.primary"
        variant="overline"
        fontWeight="bold"
        marginBottom={2}
      >
        {t('ticket.fieldset.summary.reporter')}
      </Typography>
      <ObjectRow {...reporter} />
    </>
  );
};
