import { ConfigsAPI } from '../../connectors/company';
import type {
  CountrySerializerDTO,
  KYCIntegrationListSerializerDTO,
  KYCIntegrationSerializerDTO,
} from '../../connectors/company';
import type {
  CommentSerializerDTO,
  CreateProspectSerializerDTO,
  ListCommentSerializerDTO,
  ListCommentWithProspectSerializerDTO,
  ListProspectSerializerDTO,
  PatchProspectSerializerDTO,
  ProspectDetailsReadOnlySerializerDTO,
  ProspectDetailsSerializerDTO,
  ProspectSerializerDTO,
  ProspectStatusEnumDTO,
  ProspectVerificationSerializerDTO,
  UnitAdvertisementSerializerDTO,
} from '../../connectors/property';
import { AdvertisementsAPI, ProspectsAPI } from '../../connectors/property';
import { mieInstance } from '../mie.instance';
import { transformKeys } from '../shared';
import type { ListQueryParams } from '../shared';
import { getEnvValue } from '../shared/helpers/env.helper';

class ProspectClient {
  private advertisementClient;

  private configClient;

  private prospectClient;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.advertisementClient = new AdvertisementsAPI(
      undefined,
      baeBath,
      mieInstance,
    );
    this.configClient = new ConfigsAPI(undefined, baeBath, mieInstance);
    this.prospectClient = new ProspectsAPI(undefined, baeBath, mieInstance);
  }

  public create$ = async (
    payload: CreateProspectSerializerDTO,
  ): Promise<ProspectDetailsSerializerDTO> => {
    const result = await this.prospectClient.createProspectProspectsPost({
      createProspectSerializerDTO: payload,
    });

    return result.data;
  };

  public update$ = async (
    id: ProspectDetailsSerializerDTO['uuid'],
    payload: PatchProspectSerializerDTO,
  ): Promise<ProspectDetailsSerializerDTO> => {
    const result =
      await this.prospectClient.patchProspectProspectsProspectUuidPatch({
        patchProspectSerializerDTO: payload,
        prospectUuid: id,
      });

    return result.data;
  };

  public getDetails$ = async (
    id: ProspectDetailsSerializerDTO['uuid'],
  ): Promise<ProspectDetailsReadOnlySerializerDTO> => {
    const response =
      await this.prospectClient.retrieveProspectProspectsProspectUuidGet({
        prospectUuid: id,
      });

    return response.data;
  };

  public getList$ = async (
    advertisementId: UnitAdvertisementSerializerDTO['uuid'],
    queryParams?: ListQueryParams,
  ): Promise<ListProspectSerializerDTO> => {
    const { page, pageSize, search } = { ...queryParams };
    const response = await this.prospectClient.listProspectsProspectsGet({
      page,
      pageSize,
      search,
      unitPromotionUuid: advertisementId,
    });

    return response.data;
  };

  public addComment$ = async (
    id: ProspectDetailsSerializerDTO['uuid'],
    content: string,
  ): Promise<CommentSerializerDTO> => {
    const response =
      await this.prospectClient.addProspectCommentProspectsProspectUuidCommentsPost(
        {
          createProspectCommentSerializerDTO: { content },
          prospectUuid: id,
        },
      );

    return response.data;
  };

  public getComments$ = async (
    id: ProspectDetailsSerializerDTO['uuid'],
    queryParams?: ListQueryParams,
  ): Promise<ListCommentSerializerDTO> => {
    const { page, pageSize, search, sort } = { ...queryParams };
    const response =
      await this.prospectClient.getProspectCommentsProspectsProspectUuidCommentsGet(
        {
          order: sort,
          page,
          pageSize,
          prospectUuid: id,
          search,
        },
      );

    return response.data;
  };

  public getCommentsByCountry$ = async (
    id: CountrySerializerDTO['uuid'],
    queryParams?: ListQueryParams,
  ): Promise<ListCommentWithProspectSerializerDTO> => {
    const { page, pageSize, sort } = { ...queryParams };
    const response =
      await this.prospectClient.getCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet(
        {
          countryUuid: id,
          order: sort,
          page,
          pageSize,
        },
      );

    return response.data;
  };

  public getCounts$ = async (
    advertisementId: UnitAdvertisementSerializerDTO['uuid'],
  ): Promise<Record<ProspectStatusEnumDTO | 'total', number>> => {
    const response =
      await this.prospectClient.getProspectsCountProspectsCountsGet({
        unitPromotionUuid: advertisementId,
      });

    return transformKeys(response.data.prospectsCount, 'SNAKE_CASE');
  };

  public viewingCancel$ = async (
    id: ProspectSerializerDTO['uuid'],
  ): Promise<ProspectDetailsSerializerDTO> => {
    const response =
      await this.prospectClient.cancelViewingProspectsProspectUuidViewingCancelPost(
        {
          prospectUuid: id,
        },
      );

    return response.data;
  };

  public cancel$ = async (
    id: ProspectSerializerDTO['uuid'],
  ): Promise<ProspectDetailsSerializerDTO> => {
    const response =
      await this.prospectClient.cancelProspectProspectsProspectUuidCancelPost({
        prospectUuid: id,
      });

    return response.data;
  };

  public getIntegrationsConfig$ = async (
    countryUuid: CountrySerializerDTO['uuid'],
  ): Promise<KYCIntegrationListSerializerDTO> => {
    const response =
      await this.configClient.getKycIntegrationsConfigsKycIntegrationsGet({
        countryUuid,
      });

    return response.data;
  };

  public verificationInvite$ = async (
    id: ProspectSerializerDTO['uuid'],
    integrationId: KYCIntegrationSerializerDTO['uuid'],
  ): Promise<ProspectVerificationSerializerDTO> => {
    const response =
      await this.prospectClient.createKycProspectVerificationProspectsProspectUuidKycPost(
        {
          kYCProspectVerificationCreateSerializerDTO: {
            kycIntegrationUuid: integrationId,
          },
          prospectUuid: id,
        },
      );

    return response.data;
  };

  public downloadCertificate$ = async (
    id: ProspectSerializerDTO['uuid'],
    integrationId: KYCIntegrationSerializerDTO['uuid'],
  ): Promise<string> => {
    const response =
      await this.prospectClient.downloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet(
        { kycProspectVerificationUuid: integrationId, prospectUuid: id },
        { responseType: 'blob' },
      );

    return response.data;
  };
}
export const prospectClient = new ProspectClient();
