import { createContext } from 'react';

import type {
  AuthenticationTypeEnumDTO,
  UserNotificationConfigSerializerDTO,
} from '../../../../connectors/user';
import type { Permission } from '../enums';
import { initialState } from './reducer';
import type { State } from './type';

export interface ContextValue extends State {
  authorize$: (
    type: AuthenticationTypeEnumDTO,
    searchParams: URLSearchParams,
  ) => Promise<void>;
  isUserFetched: boolean;
  login$: (email: string, password: string) => Promise<void>;
  loginByProvider: (type: AuthenticationTypeEnumDTO) => void;
  logout: () => void;
  permissions: Permission[];
  setNotificationSettings: (
    notificationConfig: UserNotificationConfigSerializerDTO,
  ) => void;
}

export const Context = createContext<ContextValue>({
  ...initialState,
  authorize$: () => Promise.resolve(),
  isUserFetched: false,
  login$: () => Promise.resolve(),
  loginByProvider: () => null,
  logout: () => null,
  permissions: [],
  setNotificationSettings: () => null,
});
