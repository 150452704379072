import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type {
  Dispatch,
  FC,
  MouseEvent,
  ReactElement,
  SetStateAction,
} from 'react';
import React, { isValidElement, useCallback, useMemo } from 'react';

import { Cell } from '../Cell/cell.component';
import type { ColumnConfig } from '../list.type';
import { sxProps } from './simple-row.styles';

type Props<T> = {
  columnsConfig: ColumnConfig<T>[];
  isRowsDisabled: boolean;
  isSelectingRowsEnabled: boolean;
  item: T;
  modelKey: keyof T;
  selectedRows: T[];
  setSelectedRows: Dispatch<SetStateAction<T[]>>;
  children?: (item: T) => ReactElement;
  isHighlighted?: (item: T) => boolean;
  onRowClick?: (item: T) => void;
};

export const SimpleRow = <T,>(props: Props<T>): ReturnType<FC<Props<T>>> => {
  const {
    children,
    columnsConfig,
    isHighlighted,
    isRowsDisabled,
    isSelectingRowsEnabled,
    item,
    modelKey,
    onRowClick,
    selectedRows,
    setSelectedRows,
  } = props;
  const isRowSelected = useMemo(
    () => selectedRows.indexOf(item) !== -1,
    [item, selectedRows],
  );
  const handleCheckboxClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (isRowsDisabled) return;

      const selectedIndex = selectedRows.indexOf(item);
      let newSelected: T[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, item);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1),
        );
      }

      setSelectedRows(newSelected);
    },
    [isRowsDisabled, item, selectedRows, setSelectedRows],
  );
  const isRowHighlighted = useMemo(
    () => isHighlighted?.(item),
    [isHighlighted, item],
  );

  return (
    <TableRow
      {...(onRowClick &&
        !isRowsDisabled && {
          hover: true,
          onClick: () => onRowClick(item),
          sx: sxProps.clickableTableRow,
        })}
      {...(isSelectingRowsEnabled && {
        selected: isRowSelected,
      })}
      sx={(isRowHighlighted ? sxProps.highlighted : undefined) as SxProps}
    >
      {isSelectingRowsEnabled && (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isRowSelected}
            color="primary"
            data-testid="single-row-checkbox"
            disabled={isRowsDisabled}
            onClick={handleCheckboxClick}
          />
        </TableCell>
      )}
      {children && isValidElement(children(item))
        ? children(item)
        : columnsConfig.map((columnConfig, idx) => {
            const { id: columnId } = columnConfig;

            return (
              <Cell<T>
                columnConfig={columnConfig}
                idx={idx}
                item={item}
                key={`tc-${item[modelKey]}-${columnId}`}
              />
            );
          })}
    </TableRow>
  );
};
