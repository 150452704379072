import MUIBox from '@mui/material/Box';
import type { FC } from 'react';
import React from 'react';

import type { UnitAdvertisementSerializerDTO } from '../../../../../connectors/property';
import { Box, useAsync } from '../../../../shared';
import { IntegrationsFeature } from '../IntegrationsFeature/integrations-feature.component';
import { StatusFeature } from '../StatusFeature/status-feature.component';

export const StatusIntegrationsBox: FC = () => {
  const { response } = useAsync<[UnitAdvertisementSerializerDTO]>();
  const [details] = response;

  return (
    <Box size="small">
      <MUIBox>
        <StatusFeature size="large" status={details.status} textWrap />
        <IntegrationsFeature
          country={details.countryUuid}
          integrations={details.integrations}
          isUnpublishButton
        />
      </MUIBox>
    </Box>
  );
};
