import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import React, { useMemo } from 'react';
import type { FC } from 'react';

import { useTranslation } from '../../translations';

type Props = {
  isShrink?: boolean;
  labelKey?: GenericTypes.TranslationLabel;
  labelSx?: SxProps<Theme>;
};

export const FieldLabel: FC<Props> = (props) => {
  const { isShrink = true, labelKey, labelSx } = props;
  const { t } = useTranslation();
  const label = useMemo(() => labelKey && t(labelKey), [labelKey, t]);

  if (!label) return null;

  return isShrink ? (
    <InputLabel shrink>{label}</InputLabel>
  ) : (
    <FormLabel component="legend" sx={labelSx}>
      {label}
    </FormLabel>
  );
};
