import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import React, { useMemo } from 'react';
import type { FC } from 'react';

import type { ModelCommonProps, ViewModeCommonProps } from '../collection.type';
import { sxProps } from './thumbnail-list-item.styles';

type Props<T> = ModelCommonProps<T> &
  ViewModeCommonProps & {
    item: T;
  };

export const ThumbnailListItem = <T,>(
  props: Props<T>,
): ReturnType<FC<Props<T>>> => {
  const {
    children,
    descriptionContent,
    item,
    modelKey,
    onItemClick,
    thumbnailUrl,
    titleContent,
    viewMode,
  } = props;
  const backgroundImage = useMemo(
    () => (thumbnailUrl ? `url(${thumbnailUrl(item)})` : undefined),
    [item, thumbnailUrl],
  );

  return (
    <ListItem
      {...(onItemClick && { onClick: () => onItemClick(item) })}
      sx={
        {
          ...sxProps.listItem,
          ...(onItemClick && sxProps.listItemPointerEvents),
        } as SxProps
      }
    >
      <Grid container direction="row" wrap="nowrap">
        {thumbnailUrl && (
          <Grid item>
            <Paper
              sx={{
                ...sxProps.thumbnail,
                backgroundImage,
              }}
            />
          </Grid>
        )}
        <Grid item sx={sxProps.contentColumn}>
          <Grid
            container
            direction="column"
            sx={sxProps.container}
            wrap="nowrap"
          >
            <Grid item xs={12}>
              <Typography noWrap variant="h3">
                {titleContent(item)}
              </Typography>
              {descriptionContent && (
                <Typography noWrap variant="body2">
                  {descriptionContent(item)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider sx={sxProps.divider} />
            </Grid>
            {children && (
              <Grid item xs={12} sx={sxProps.contentRow}>
                <Grid container wrap="nowrap">
                  <Grid item xs={12}>
                    {children(item, modelKey, viewMode)}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};
