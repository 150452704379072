import Box from '@mui/material/Box';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { Dispatch, FC } from 'react';
import React, { useMemo } from 'react';

import type { Image } from '../carousel.type';
import { sxProps } from './navigation.styles';

type Props = {
  activeIdx: number;
  images: Image[];
  setActiveIdx: Dispatch<number>;
};

export const Navigation: FC<Props> = (props) => {
  const { activeIdx, images, setActiveIdx } = props;
  const isNavigationNeeded = useMemo(() => images.length > 1, [images.length]);

  if (!isNavigationNeeded) return null;

  return (
    <Box component="aside" sx={sxProps.container}>
      {images.map((image, idx) => (
        <Box
          key={image.id}
          onClick={() => setActiveIdx(idx)}
          sx={
            {
              ...sxProps.item,
              ...(activeIdx === idx && sxProps.activeItem),
            } as SxProps
          }
        />
      ))}
    </Box>
  );
};
