import type { FC, ReactNode } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import { propertyClient } from '../../../../+property';
import { propertyObjectClient } from '../../../../+property-object';
import { unitClient } from '../../../../+unit';
import {
  AttachmentCategoryEnumDTO,
  EntityEnumDTO,
} from '../../../../../connectors/property';
import { useTranslation } from '../../../../shared';
import type { ContextValue } from './context';
import { Context } from './context';
import type { Data } from './type';

type Props = {
  children: ReactNode;
};

const INITIAL_DATA: Data = {
  property: undefined,
  propertyObject: undefined,
  unit: undefined,
};

export const Provider: FC<Props> = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const [data, setData] = useState<Data>(INITIAL_DATA);
  const [isUnitFetching, setUnitFetching] = useState(false);
  const clearData = useCallback(() => {
    setData(INITIAL_DATA);
  }, []);
  const getData$: ContextValue['getData$'] = useCallback(
    async (entity, id) => {
      try {
        switch (entity) {
          case EntityEnumDTO.Property: {
            setData(INITIAL_DATA);
            const [property, attachments] = await Promise.all([
              propertyClient.getDetails$(id),
              propertyClient.getAttachments$(
                id,
                AttachmentCategoryEnumDTO.Image,
              ),
            ]);

            setData({ property: { ...property, attachments } });
            break;
          }
          case EntityEnumDTO.PropertyObject: {
            setData((prevState) => ({
              ...prevState,
              propertyObject: undefined,
              unit: undefined,
            }));
            const [propertyObject, attachments] = await Promise.all([
              propertyObjectClient.getDetails$(id),
              propertyObjectClient.getAttachments$(
                id,
                AttachmentCategoryEnumDTO.Image,
              ),
            ]);

            setData((prevState) => ({
              ...prevState,
              propertyObject: { ...propertyObject, attachments },
            }));
            break;
          }
          case EntityEnumDTO.Unit: {
            setUnitFetching(true);
            setData((prevState) => ({ ...prevState, unit: undefined }));
            const [unit, attachments] = await Promise.all([
              unitClient.getDetails$(id),
              unitClient.getAttachments$(id, AttachmentCategoryEnumDTO.Image),
            ]);

            setData((prevState) => ({
              ...prevState,
              unit: { ...unit, attachments },
            }));
            break;
          }
          default: {
            break;
          }
        }
      } catch (e) {
        toast.error(t('errors.general.message'));
      } finally {
        setUnitFetching(false);
      }
    },
    [t],
  );
  const images = useMemo(
    () => [
      ...(data.unit?.attachments.results || []),
      ...(data.propertyObject?.attachments.results || []),
      ...(data.property?.attachments.results || []),
    ],
    [
      data.property?.attachments.results,
      data.propertyObject?.attachments.results,
      data.unit?.attachments.results,
    ],
  );
  const value = useMemo<ContextValue>(
    () => ({
      clearData,
      getData$,
      images,
      isUnitFetching,
      property: data.property,
      propertyObject: data.propertyObject,
      unit: data.unit,
    }),
    [
      clearData,
      data.property,
      data.propertyObject,
      data.unit,
      getData$,
      images,
      isUnitFetching,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
