/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum InvoiceDistributionEnumDTO {
    InvoiceMail = 'invoice_mail',
    EInvoice = 'e_invoice',
    ElectronicInvoiceFormat = 'electronic_invoice_format',
    DirectDebit = 'direct_debit',
    CreditCardCharge = 'credit_card_charge'
}



