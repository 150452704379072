import { ReactComponent as AnalyticsBarsIcon } from '@heimstaden/icons-library/img/streamline-regular/business-products/analytics/analytics-bars.svg';
import Icon from '@mui/material/Icon';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { PriorityTypeEnumDTO } from '../../../../../connectors/ticket';
import { sxProps } from './priority-icon.style';

type Props = {
  priority?: PriorityTypeEnumDTO;
};

export const PriorityIcon: FC<Props> = (props) => {
  const { priority } = props;
  const iconSxProps = useMemo(
    () => (priority ? sxProps[priority] : undefined),
    [priority],
  );

  return (
    <Icon sx={iconSxProps}>
      <AnalyticsBarsIcon />
    </Icon>
  );
};
