import { ReactComponent as SingleNeutralIdCard3Icon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-id-card-3.svg';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import type { FC, Dispatch, SetStateAction } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import { useAuth } from '../../../../+auth';
import type {
  ListTicketsSerializerDTO,
  TicketTypeEnumDTO,
} from '../../../../../connectors/ticket';
import type { UserSerializerDTO } from '../../../../../connectors/user';
import type { AsyncAutocompleteOption } from '../../../../shared';
import { Dialog, Form, useTranslation } from '../../../../shared';
import { DEFAULT_ASYNC_AUTOCOMPLETE_VALUE } from '../../../../shared/form/form.const';
import { UNASSIGNED_VALUE } from '../../consts';
import { getUpdateRequest$ } from '../../helpers';
import type { DetailRequests } from '../../types';
import type { Values } from './reassign-request-action.model';
import { getFieldsConfig, formConfig } from './reassign-request-action.model';
import { sxProps } from './reassing-request-action.styles';

type Props = {
  rows: DetailRequests[];
  setResponse: Dispatch<SetStateAction<ListTicketsSerializerDTO>>;
  ticketType: TicketTypeEnumDTO;
};

export const ReassignRequestAction: FC<Props> = (props) => {
  const { rows, setResponse, ticketType } = props;
  const { user } = useAuth();
  const { t } = useTranslation();
  const [isFetching, setFetching] = useState(false);
  const [assignee, setAssignee] = useState<
    AsyncAutocompleteOption<UserSerializerDTO['uuid']>
  >(DEFAULT_ASYNC_AUTOCOMPLETE_VALUE);
  const [isReassignFormOpen, setReassignFormOpen] = useState(false);
  const fieldsConfig = useMemo(
    () => (user ? getFieldsConfig(user, ticketType) : []),
    [ticketType, user],
  );
  const handleReassign = useCallback(async () => {
    if (!assignee) return;

    setFetching(true);

    const ids = rows
      .filter((row) =>
        assignee.value === UNASSIGNED_VALUE
          ? Boolean(row.assignee)
          : row.assignee?.uuid !== assignee.value,
      )
      .map((row) => row.uuid);

    const newAssignee =
      assignee.value === UNASSIGNED_VALUE ? null : assignee.value;

    try {
      const request$ = Promise.all(
        ids.map((id) =>
          // TODO: consult with backend if marking as optional string or null is possible on their side
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getUpdateRequest$({ assigneeUuid: newAssignee }, id, ticketType),
        ),
      );

      const updatedTickets = await request$;

      setResponse((prevState) => ({
        ...prevState,
        results: prevState.results.map((ticket) =>
          ids.includes(ticket.uuid)
            ? {
                ...ticket,
                assignee: updatedTickets.find(
                  (updatedTicket) => updatedTicket.uuid === ticket.uuid,
                )?.assignee,
              }
            : ticket,
        ),
      }));
    } catch {
      toast.error(t('errors.general.message'));
    } finally {
      setReassignFormOpen(false);
    }
  }, [assignee, rows, setResponse, t, ticketType]);
  const handleChange = useCallback((values: Values) => {
    setAssignee(values.assignee);
  }, []);

  return (
    <>
      <Button
        color="secondary"
        onClick={() => setReassignFormOpen(true)}
        variant="text"
      >
        <Typography
          color="secondary.light"
          sx={sxProps.text}
          variant="subtitle2"
        >
          {t('ticket.actions.reassign.button')}
          <Icon component={SingleNeutralIdCard3Icon} sx={sxProps.icon} />
        </Typography>
      </Button>
      <Dialog
        isConfirmDisabled={!assignee || isFetching}
        isOpen={isReassignFormOpen}
        onClose={() => setReassignFormOpen(false)}
        onConfirm={handleReassign}
        titleKey="ticket.actions.reassign.title"
      >
        <>
          <Typography mb={2}>{t('ticket.actions.reassign.text')}</Typography>
          <Form
            config={formConfig}
            fieldsConfig={fieldsConfig}
            showButtons={false}
            onChange={handleChange}
            spacing={2}
          />
        </>
      </Dialog>
    </>
  );
};
