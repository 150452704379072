import type { FC } from 'react';
import React from 'react';
import { generatePath } from 'react-router';

import { PermissionGuard } from '../../+auth';
import {
  TICKET_READ_PERMISSIONS,
  TicketEntityRequestList,
} from '../../+ticket';
import { EntityEnumDTO } from '../../../connectors/company';
import { TicketTypeEnumDTO } from '../../../connectors/ticket';
import type { UserSerializerDTO } from '../../../connectors/user';
import { useAsync } from '../../shared';
import PATHS from '../paths.const';

const ComplaintList: FC = () => {
  const { response: user } = useAsync<UserSerializerDTO>();

  return (
    <PermissionGuard
      fallbackRoute={generatePath(PATHS.DETAILS, { id: user.uuid })}
      permissions={TICKET_READ_PERMISSIONS}
    >
      <TicketEntityRequestList
        createPath={PATHS.CREATE_COMPLAINT}
        entity={{ ...user, type: EntityEnumDTO.User }}
        ticketType={TicketTypeEnumDTO.Complaint}
      />
    </PermissionGuard>
  );
};

export default ComplaintList;
