import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    backgroundColor: (theme) => theme.palette.background.paper,
    border: (theme) => `2px dotted ${theme.palette.divider}`,
    borderRadius: (theme) => theme.spacing(2),
    cursor: 'pointer',
    px: 2,
    py: 6,
    width: '100%',
  },
  reuploader: {
    backgroundColor: (theme) => theme.palette.background.default,
    border: 'none',
    py: 2,
  },
};
