/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum AttachmentTypeEnumDTO {
    Gallery = 'gallery',
    Hero = 'hero',
    Thumbnail = 'thumbnail',
    ProfilePicture = 'profile_picture',
    HouseRules = 'house_rules',
    Manuals = 'manuals',
    FloorPlan = 'floor_plan',
    Pdf = 'pdf',
    ContractPdf = 'contract_pdf',
    Other = 'other'
}



