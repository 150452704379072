import { ReactComponent as SearchIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/search/search.svg';
import type { AutocompleteRenderInputParams } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import type { SxProps } from '@mui/system/styleFunctionSx';
import isFunction from 'lodash-es/isFunction';
import type {
  ChangeEvent,
  ElementType,
  KeyboardEventHandler,
  ReactElement,
} from 'react';
import React, { useMemo } from 'react';

import { Spinner } from '../../../components';
import { useTranslation } from '../../../translations';
import type { AsyncAutocompleteProps } from '../../fields';
import { sxProps } from './async-autocomplete-text-field.styles';

type Props<TFormValues> = {
  errorMessageKey: GenericTypes.TranslationLabel;
  fieldProps: AsyncAutocompleteProps<TFormValues>;
  handleKeyDown: KeyboardEventHandler<HTMLDivElement>;
  handleInputChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  isDisabled: boolean;
  isError: boolean;
  isLoading: boolean;
  name: string;
  params: AutocompleteRenderInputParams;
  values: TFormValues;
};

export const AsyncAutocompleteTextField = <TFormValues,>(
  props: Props<TFormValues>,
): ReactElement => {
  const {
    errorMessageKey,
    fieldProps,
    handleKeyDown,
    handleInputChange,
    isError,
    isLoading,
    name,
    params,
    values,
  } = props;
  const {
    fieldSx,
    inputAdornmentSx,
    isFilter,
    labelKey,
    placeholderKey,
    required,
    requiredWhen,
    startAdornmentIcon,
  } = fieldProps;

  const { t } = useTranslation();
  const StartAdornmentIcon = useMemo(
    () =>
      (isFunction(startAdornmentIcon)
        ? startAdornmentIcon(values)
        : startAdornmentIcon) as ElementType,
    [startAdornmentIcon, values],
  );
  const finalLabelKey = useMemo(
    () => (isFunction(labelKey) ? labelKey(values) : labelKey),
    [labelKey, values],
  );
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );

  return (
    <TextField
      {...params}
      error={isError}
      helperText={isError && t(errorMessageKey || '')}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={t(placeholderKey || 'fields.autocomplete.placeholder')}
      sx={
        {
          ...(isFilter && sxProps.filterInput),
          ...fieldSx,
        } as SxProps
      }
      InputProps={{
        ...params.InputProps,
        ...(startAdornmentIcon && {
          startAdornment: (
            <InputAdornment position="start" sx={inputAdornmentSx}>
              <StartAdornmentIcon height={24} width={24} />
            </InputAdornment>
          ),
        }),
        endAdornment: (
          <>
            {isLoading ? (
              <Spinner color="inherit" size={isFilter ? 16 : 24} />
            ) : null}
            {!isLoading ? (
              <SearchIcon
                height={isFilter ? 16 : 24}
                width={isFilter ? 16 : 24}
              />
            ) : null}
          </>
        ),
        inputProps: { ...params.inputProps, 'data-testid': name },
        sx: sxProps.input,
      }}
      {...(finalLabelKey &&
        !isFilter && {
          InputLabelProps: { required: isRequired, shrink: true },
          label: t(finalLabelKey),
        })}
    />
  );
};
