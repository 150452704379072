import React, { useMemo } from 'react';
import type { FC, ReactElement } from 'react';
import { useParams } from 'react-router';
import { Navigate, useSearchParams } from 'react-router-dom';

import { AuthenticationTypeEnumDTO } from '../../../../connectors/user';
import PATHS from '../../paths.const';

interface Props {
  children: ReactElement;
}

export const AuthorizationGuard: FC<Props> = (props) => {
  const { children } = props;
  const { authType } = useParams();
  const [searchParams] = useSearchParams();
  const {
    code,
    session_state: sessionState,
    state,
  } = useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams]);
  const isValidType = useMemo(
    () =>
      Object.values(AuthenticationTypeEnumDTO).includes(
        authType as AuthenticationTypeEnumDTO,
      ),
    [authType],
  );

  if (!code || !sessionState || !state || !isValidType) {
    return <Navigate to={PATHS.LOGIN} />;
  }

  return children;
};
