import { ReactComponent as RealEstateDealKeyIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/deals/real-estate-deal-key.svg';
import MUIBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React from 'react';

import type { UnitAdvertisementSerializerDTO } from '../../../../../connectors/property';
import { Box, Feature, FormattedDate, useAsync } from '../../../../shared';

export const AvailableFromFeatureBox: FC = () => {
  const { response } = useAsync<[UnitAdvertisementSerializerDTO]>();
  const [details] = response;

  if (!details.availableDateFrom) return null;

  return (
    <Box size="small">
      <MUIBox>
        <Feature
          dataTestId="availability-time"
          icon={<RealEstateDealKeyIcon />}
          size="large"
          textWrap
          titleKey="advertisement.feature.availableFrom"
          value={
            <Typography component="span" variant="h5">
              <FormattedDate date={details.availableDateFrom} />
            </Typography>
          }
        />
      </MUIBox>
    </Box>
  );
};
