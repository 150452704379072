import * as Yup from 'yup';

import type {
  CheckboxValue,
  FieldsetConfig,
  RadioValue,
} from '../../../../../shared';
import {
  DEFAULT_CHECKBOX_VALUE,
  DEFAULT_FIELD_VALUE,
} from '../../../../../shared';
import {
  AvailabilityMode,
  getAvailabilityModeRadioOptions,
  getIncomeVerificationModeCheckboxOptions,
  getInsuranceModeCheckboxOptions,
  getVerificationModeRadioOptions,
  IncomeVerificationMode,
  VerificationMode,
} from '../../../consts';
import type { FormValues } from '../form.types';

export const getFieldsetConfig = (
  fieldSuffix: string,
): FieldsetConfig<FormValues>[] => [
  {
    fields: [
      {
        componentType: 'radio',
        field: {
          initialValue: VerificationMode.IMMEDIATELY,
          name: 'verification',
        },
        props: {
          options: getVerificationModeRadioOptions,
          readonly: true,
          required: true,
        },
      },
    ],
    key: 'verification',
    titleKey: 'advertisement.fieldset.options.verification',
  },
  {
    fields: [
      {
        componentType: 'checkbox',
        field: {
          initialValue: DEFAULT_CHECKBOX_VALUE,
          name: 'incomeVerification',
        },
        props: {
          options: getIncomeVerificationModeCheckboxOptions,
          readonly: true,
          required: true,
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: DEFAULT_FIELD_VALUE,
          name: 'minimalIncome',
          validationSchema: Yup.mixed().when(['incomeVerification'], {
            is: (incomeVerification: CheckboxValue<IncomeVerificationMode>) =>
              incomeVerification.includes(IncomeVerificationMode.MINIMAL),
            otherwise: Yup.mixed().nullable(),
            then: Yup.number()
              .required('form.errors.required')
              .typeError('form.errors.number.general')
              .positive('form.errors.number.positive'),
          }),
        },
        props: {
          endAdornment: fieldSuffix,
          isHidden: ({ incomeVerification }) =>
            !incomeVerification.includes(IncomeVerificationMode.MINIMAL),
          labelKey: 'advertisement.fieldset.options.minimalIncome',
          requiredWhen: ({ incomeVerification }) =>
            incomeVerification.includes(IncomeVerificationMode.MINIMAL),
          type: 'number',
        },
      },
    ],
    key: 'income-verification',
    titleKey: 'advertisement.fieldset.options.income',
  },
  {
    fields: [
      {
        componentType: 'checkbox',
        field: {
          initialValue: DEFAULT_CHECKBOX_VALUE,
          name: 'insurance',
        },
        props: {
          options: getInsuranceModeCheckboxOptions,
          readonly: true,
          required: true,
        },
      },
    ],
    key: 'insurance',
    titleKey: 'advertisement.fieldset.options.insurance',
  },
  {
    fields: [
      {
        componentType: 'radio',
        field: {
          initialValue: AvailabilityMode.IMMEDIATELY,
          name: 'availability',
        },
        props: {
          options: getAvailabilityModeRadioOptions,
          required: true,
        },
      },
      {
        componentType: 'date',
        field: {
          initialValue: new Date(),
          name: 'availableDateFrom',
          validationSchema: Yup.mixed().when(['availability'], {
            is: (availability: RadioValue<AvailabilityMode>) =>
              availability === AvailabilityMode.CUSTOM_DATE,
            otherwise: Yup.mixed().nullable(),
            then: Yup.mixed().required('form.errors.required'),
          }),
        },
        props: {
          isHidden: ({ availability }) =>
            availability !== AvailabilityMode.CUSTOM_DATE,
          labelKey:
            'advertisement.fieldset.options.availabilityCustomDateOption',
          minDate: new Date(),
          requiredWhen: ({ availability }) =>
            availability === AvailabilityMode.CUSTOM_DATE,
        },
      },
    ],
    key: 'availability',
    titleKey: 'advertisement.fieldset.options.availability',
  },
];
