import { ReactComponent as FolderIcon } from '@heimstaden/icons-library/img/streamline-regular/files-folders/folders/folder.svg';
import { ReactComponent as PictureLandscapeIcon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/pictures/picture-landscape.svg';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

import type { TabType } from '../../../components';
import { Dialog, Tabs } from '../../../components';
import { useTranslation } from '../../../translations';
import type { GalleryItem } from '../../fields';
import { GalleryPicker } from '../GalleryPicker/gallery-picker.component';
import { sxProps } from './gallery-browser.styles';

type Props = {
  deactivateItems: () => void;
  items: GalleryItem[];
  selectActiveItems: () => void;
  selectItem: (id: GalleryItem['id']) => void;
  tabs: Omit<TabType, 'component'>[];
};

export const GalleryBrowser: FC<Props> = (props) => {
  const { deactivateItems, items, selectActiveItems, selectItem, tabs } = props;
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const activeItem = useMemo(
    () => items.find((item) => item.isActive),
    [items],
  );
  const finalTabs = useMemo(
    () =>
      tabs.map((tab) => ({
        ...tab,
        component: (
          <GalleryPicker
            entity={tab.value}
            items={items}
            selectItem={selectItem}
          />
        ),
      })),
    [items, selectItem, tabs],
  );
  const handleClose = useCallback(() => {
    deactivateItems();
    setDialogOpen(false);
  }, [deactivateItems]);
  const handleSelect = useCallback(() => {
    selectActiveItems();
    setDialogOpen(false);
  }, [selectActiveItems]);

  return (
    <>
      <Grid item lg={3} md={4} xl={2} xs={6}>
        <Box sx={sxProps.box} onClick={() => setDialogOpen(true)}>
          <Box sx={sxProps.wrapper}>
            <Icon fontSize="large" sx={sxProps.backgroundIcon}>
              <PictureLandscapeIcon />
            </Icon>
            <IconButton sx={sxProps.foregroundIcon}>
              <Icon fontSize="small">
                <FolderIcon />
              </Icon>
            </IconButton>
          </Box>
        </Box>
      </Grid>
      <Dialog
        enableActions={false}
        fullWidth
        isOpen={isDialogOpen}
        maxWidth="lg"
        onClose={handleClose}
        titleComponent={
          <Typography color="primary" variant="h2">
            {t('form.gallery.browser.title')}
          </Typography>
        }
      >
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Tabs tabs={finalTabs} />
          </Grid>
          {activeItem && (
            <Grid item md={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <img alt={activeItem.title} src={activeItem.url} />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2">{activeItem.title}</Typography>
                  {activeItem.description && (
                    <Typography>{activeItem.description}</Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      size="large"
                      onClick={handleSelect}
                      variant="contained"
                    >
                      {t('form.gallery.browser.insert')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Dialog>
    </>
  );
};
