import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { palette } from '../../../theme';

export const sxProps: Record<string, SxProps<Theme>> = {
  activeItem: {
    backgroundColor: 'primary.main',
  },
  container: {
    bottom: (theme) => theme.spacing(7),
    left: 0,
    position: 'absolute',
    right: 0,
    textAlign: 'center',
  },
  item: {
    '&:hover': {
      backgroundColor: 'primary.main',
    },
    backgroundColor: palette.silver,
    border: (theme) => `2px solid ${theme.palette.background.default}`,
    borderRadius: '50%',
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
    cursor: 'pointer',
    display: 'inline-block',
    height: (theme) => theme.spacing(2),
    margin: (theme) => theme.spacing(0, 1),
    width: (theme) => theme.spacing(2),
  },
};
