import React from 'react';

import { componentLazyImport } from '../routing/routes.helper';

const Dashboard = componentLazyImport(
  () => import('./Dashboard/dashboard.component'),
  'REPORT_DASHBOARD',
);

const routes = {
  element: <Dashboard />,
  path: 'dashboard',
};

export default routes;
