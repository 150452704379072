import { ReactComponent as ImageFileEdit } from '@heimstaden/icons-library/img/streamline-regular/images-photography/image-files/image-file-edit.svg';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

import { getImagePreviewPath } from '../../../attachment';
import { buildCustomFile, getIcon } from '../../../attachment/shared';
import { Dialog } from '../../../components';
import { useTranslation } from '../../../translations';
import type { CustomFile, FileValue } from '../../fields';
import { Form } from '../../form.component';
import type { FormProps } from '../../types';
import type { Values } from './file-edit.model';
import { getFieldsConfig } from './file-edit.model';
import { sxProps } from './file-edit.styles';

type Props = {
  file: CustomFile;
  files: FileValue;
  onUpdate: (files: FileValue) => void;
};

export const FileEdit: FC<Props> = (props) => {
  const { file, files, onUpdate } = props;
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const Icon = useMemo(() => getIcon(file.type), [file.type]);
  const previewPath = useMemo(() => getImagePreviewPath(file), [file]);
  const formConfig = useMemo<FormProps<Values>['config']>(
    () => ({
      onSubmit: (values) => {
        const { description, title, visibility } = values;
        const newFiles = files.map((f) =>
          f.path === file.path
            ? buildCustomFile(file, title, description, visibility)
            : f,
        );

        onUpdate(newFiles);
      },
    }),
    [file, files, onUpdate],
  );
  const fieldsConfig = useMemo(
    () =>
      getFieldsConfig(file.name, file.description, file.title, file.visibility),
    [file.description, file.name, file.title, file.visibility],
  );
  const openDialog = useCallback(() => setDialogOpen(true), []);
  const closeDialog = useCallback(() => setDialogOpen(false), []);

  return (
    <>
      <Grid item>
        <IconButton onClick={openDialog}>
          <ImageFileEdit height={16} width={16} />
        </IconButton>
      </Grid>
      <Dialog
        enableActions={false}
        fullWidth
        isOpen={isDialogOpen}
        maxWidth="md"
        onClose={closeDialog}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} sm={6}>
            {previewPath ? (
              <Box
                sx={{
                  ...sxProps.preview,
                  backgroundImage: `url(${previewPath})`,
                }}
              />
            ) : (
              <Box sx={sxProps.icon}>
                <Icon height={24} width={24} />
              </Box>
            )}
          </Grid>
          <Grid item xs={8} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t('form.labels.fileEditTitle')}
                </Typography>
                <Typography>{t('form.labels.fileEditDescription')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Form<Values>
                  config={formConfig}
                  fieldsConfig={fieldsConfig}
                  handleSuccess={closeDialog}
                  onCancel={closeDialog}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
