import Grid from '@mui/material/Grid';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router';

import {
  COMPANY_DEFAULT_AVATAR_URL,
  COMPANY_PATHS,
} from '../../../../+company';
import { propertyObjectClient } from '../../../../+property-object';
import type { CompanySerializerDTO } from '../../../../../connectors/company';
import type {
  ContactSerializerDTO,
  CountrySerializerDTO,
  PropertyObjectReassignSerializerDTO,
  PropertyObjectSerializerDTO,
} from '../../../../../connectors/property';
import type { UserRoleEnumDTO } from '../../../../../connectors/user';
import type { AsyncAutocompleteOption } from '../../../../shared';
import { CustomErrorType, useAsync, useTranslation } from '../../../../shared';
import { isFieldNotUnique } from '../../../../shared/errors/error.helper';
import { prepareUserData, USER_PATHS, UserList } from '../../../index';
import {
  CONTACT_ROLES,
  CUSTOMER_SERVICE_ROLES,
  JANITOR_ROLES,
  MANAGERS_ROLES,
} from '../../consts';
import type { User } from '../../types';

type Data = {
  contactPoints: User[];
  customerServices: User[];
  janitors: User[];
  managers: User[];
  tenants?: User[];
};

type Props = {
  company?: CompanySerializerDTO;
  country?: CountrySerializerDTO['uuid'];
  onAssigneeCallback$?: (
    id: string,
    value: NonNullable<AsyncAutocompleteOption<User['id']>>['value'],
  ) => Promise<ContactSerializerDTO>;
  onRemoveCallback$?: (id: string, customerId: User['id']) => Promise<void>;
  onReassignCallback$?: (
    id: PropertyObjectSerializerDTO['uuid'],
    newUserId: PropertyObjectReassignSerializerDTO['newJanitorUuid'],
    oldUserId: PropertyObjectReassignSerializerDTO['oldJanitorUuid'],
  ) => Promise<void>;
  reassignConfirmationDescriptionKey?: GenericTypes.TranslationLabel;
};

// TODO: Make each section more atomic -https://fredensborg.atlassian.net/browse/MYW-650
export const Sections: FC<Props> = (props) => {
  const {
    company,
    country,
    onAssigneeCallback$,
    onReassignCallback$,
    onRemoveCallback$,
    reassignConfirmationDescriptionKey,
  } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const { response, setResponse } = useAsync<Data>();
  const onAssignee$ = useCallback(
    async (
      option: NonNullable<AsyncAutocompleteOption<User['id']>>,
      setEditMode: Dispatch<SetStateAction<boolean>>,
      propertyKey: keyof Omit<Data, 'tenants'>,
    ) => {
      if (!onAssigneeCallback$) return;

      try {
        const contact = await onAssigneeCallback$(id, option.value);

        setEditMode(false);
        setResponse((response) => ({
          ...response,
          [propertyKey]: [...response[propertyKey], prepareUserData(contact)],
        }));
      } catch (e) {
        if (isFieldNotUnique(e)) {
          toast.error(t('user.errors.notUniqueContact'));

          return;
        }

        throw e;
      }
    },
    [id, onAssigneeCallback$, setResponse, t],
  );
  const assignCallback$ = useCallback(
    (propertyKey: keyof Omit<Data, 'tenants'>) =>
      onAssigneeCallback$
        ? (
            option: NonNullable<AsyncAutocompleteOption<User['id']>>,
            setEditMode: Dispatch<SetStateAction<boolean>>,
          ) => onAssignee$(option, setEditMode, propertyKey)
        : undefined,
    [onAssignee$, onAssigneeCallback$],
  );
  const onRemove$ = useCallback(
    async (
      contactId: ContactSerializerDTO['uuid'],
      setEditMode: Dispatch<SetStateAction<boolean>>,
      propertyKey: keyof Omit<Data, 'tenants'>,
    ) => {
      if (!onRemoveCallback$) return;

      // eslint-disable-next-line no-useless-catch
      try {
        await onRemoveCallback$(id, contactId);
        setEditMode(false);
        setResponse((response) => ({
          ...response,
          [propertyKey]: response[propertyKey].filter(
            (contact) => contact.contactId !== contactId,
          ),
        }));
      } catch (e) {
        throw e;
      }
    },
    [id, onRemoveCallback$, setResponse],
  );
  const removeCallback$ = useCallback(
    (propertyKey: keyof Omit<Data, 'tenants'>) =>
      onRemoveCallback$
        ? (
            id: ContactSerializerDTO['uuid'],
            setEditMode: Dispatch<SetStateAction<boolean>>,
          ) => onRemove$(id, setEditMode, propertyKey)
        : undefined,
    [onRemove$, onRemoveCallback$],
  );
  const onReassignAssignee$ = useCallback(
    async (
      newUserId: ContactSerializerDTO['uuid'],
      oldUserId: ContactSerializerDTO['uuid'],
      propertyKey: keyof Omit<Data, 'tenants'>,
      role: UserRoleEnumDTO[],
    ) => {
      try {
        if (!onReassignCallback$) return;

        await onReassignCallback$(id, newUserId, oldUserId);
        const contacts = await propertyObjectClient.getContacts$(id, {
          filters: { role },
        });

        setResponse((response) => ({
          ...response,
          [propertyKey]: contacts.results?.map((contact) =>
            prepareUserData(contact),
          ),
        }));
      } catch (e) {
        if (isFieldNotUnique(e)) {
          toast.error(t('user.errors.notUniqueContact'));

          throw new Error(CustomErrorType.PREVENT_TOAST_ERROR);
        }

        throw e;
      }
    },
    [id, onReassignCallback$, setResponse, t],
  );
  const managers = useMemo(
    () =>
      company
        ? [
            {
              avatarSrc: company.avatar?.url || COMPANY_DEFAULT_AVATAR_URL,
              id: company.uuid,
              isFixed: true,
              name: company.name,
              path: COMPANY_PATHS.DETAILS,
              position: 'management.owner',
            },
            ...response.managers,
          ]
        : [],
    [company, response.managers],
  );
  const tenants = useMemo(
    () =>
      (response.tenants || []).map((tenant) => ({
        ...tenant,
        path: USER_PATHS.DETAILS,
      })),
    [response.tenants],
  );
  const isVisible = useCallback(
    (propertyKey: keyof Omit<Data, 'tenants'>) =>
      response[propertyKey]?.length !== 0 || onAssigneeCallback$,
    [onAssigneeCallback$, response],
  );

  return (
    <Grid container spacing={3}>
      {company && (
        <Grid item xs={12}>
          <UserList
            assigningLabelKey="user.search.manager"
            country={country}
            onAssignee$={assignCallback$('managers')}
            onRemove$={removeCallback$('managers')}
            suggestionRoles={MANAGERS_ROLES}
            titleKey="management.title"
            users={managers}
          />
        </Grid>
      )}
      {isVisible('customerServices') && (
        <Grid item xs={12}>
          <UserList
            assigningLabelKey="user.search.customerService"
            country={country}
            onAssignee$={assignCallback$('customerServices')}
            onRemove$={removeCallback$('customerServices')}
            suggestionRoles={CUSTOMER_SERVICE_ROLES}
            titleKey="customerService.title"
            users={response.customerServices}
          />
        </Grid>
      )}
      {isVisible('contactPoints') && (
        <Grid item xs={12}>
          <UserList
            assigningLabelKey="user.search.contactPoint"
            country={country}
            onAssignee$={assignCallback$('contactPoints')}
            onRemove$={removeCallback$('contactPoints')}
            suggestionRoles={CONTACT_ROLES}
            titleKey="contactPoint.title"
            users={response.contactPoints}
          />
        </Grid>
      )}
      {isVisible('janitors') && (
        <Grid item xs={12}>
          <UserList
            assigningLabelKey="user.search.janitor"
            country={country}
            onAssignee$={assignCallback$('janitors')}
            onRemove$={removeCallback$('janitors')}
            onReassign$={
              onReassignCallback$
                ? (newUserId, oldUserId) =>
                    onReassignAssignee$(
                      newUserId,
                      oldUserId,
                      'janitors',
                      JANITOR_ROLES,
                    )
                : undefined
            }
            reassignConfirmationDescriptionKey={
              onReassignCallback$
                ? reassignConfirmationDescriptionKey
                : undefined
            }
            suggestionRoles={JANITOR_ROLES}
            titleKey="user.title.janitor"
            users={response.janitors}
          />
        </Grid>
      )}
      {tenants.length > 0 && (
        <Grid item xs={12}>
          <UserList titleKey="user.title.tenant" users={tenants} />
        </Grid>
      )}
    </Grid>
  );
};
