/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContractRequestDetailSerializerDTO } from '../dto';
// @ts-ignore
import { ContractRequestSerializerDTO } from '../dto';
// @ts-ignore
import { CreateContractRequestSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { PatchContractRequestSerializerDTO } from '../dto';
/**
 * ContractRequestsAPI - axios parameter creator
 * @export
 */
export const ContractRequestsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Contract Request
         * @param {CreateContractRequestSerializerDTO} createContractRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContractRequestContractRequestsPost: async (createContractRequestSerializerDTO: CreateContractRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createContractRequestSerializerDTO' is not null or undefined
            assertParamExists('createContractRequestContractRequestsPost', 'createContractRequestSerializerDTO', createContractRequestSerializerDTO)
            const localVarPath = `/contract-requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContractRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Contract Request Details
         * @param {string} contractRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractRequestDetailsContractRequestsContractRequestUuidGet: async (contractRequestUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractRequestUuid' is not null or undefined
            assertParamExists('getContractRequestDetailsContractRequestsContractRequestUuidGet', 'contractRequestUuid', contractRequestUuid)
            const localVarPath = `/contract-requests/{contract_request_uuid}`
                .replace(`{${"contract_request_uuid"}}`, encodeURIComponent(String(contractRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Contract Request
         * @param {string} contractRequestUuid 
         * @param {PatchContractRequestSerializerDTO} patchContractRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContractRequestContractRequestsContractRequestUuidPatch: async (contractRequestUuid: string, patchContractRequestSerializerDTO: PatchContractRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractRequestUuid' is not null or undefined
            assertParamExists('patchContractRequestContractRequestsContractRequestUuidPatch', 'contractRequestUuid', contractRequestUuid)
            // verify required parameter 'patchContractRequestSerializerDTO' is not null or undefined
            assertParamExists('patchContractRequestContractRequestsContractRequestUuidPatch', 'patchContractRequestSerializerDTO', patchContractRequestSerializerDTO)
            const localVarPath = `/contract-requests/{contract_request_uuid}`
                .replace(`{${"contract_request_uuid"}}`, encodeURIComponent(String(contractRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchContractRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Contract Request
         * @param {string} contractRequestUuid 
         * @param {CreateContractRequestSerializerDTO} createContractRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContractRequestContractRequestsContractRequestUuidPut: async (contractRequestUuid: string, createContractRequestSerializerDTO: CreateContractRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractRequestUuid' is not null or undefined
            assertParamExists('putContractRequestContractRequestsContractRequestUuidPut', 'contractRequestUuid', contractRequestUuid)
            // verify required parameter 'createContractRequestSerializerDTO' is not null or undefined
            assertParamExists('putContractRequestContractRequestsContractRequestUuidPut', 'createContractRequestSerializerDTO', createContractRequestSerializerDTO)
            const localVarPath = `/contract-requests/{contract_request_uuid}`
                .replace(`{${"contract_request_uuid"}}`, encodeURIComponent(String(contractRequestUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContractRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractRequestsAPI - functional programming interface
 * @export
 */
export const ContractRequestsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractRequestsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Contract Request
         * @param {CreateContractRequestSerializerDTO} createContractRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContractRequestContractRequestsPost(createContractRequestSerializerDTO: CreateContractRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContractRequestContractRequestsPost(createContractRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Contract Request Details
         * @param {string} contractRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractRequestDetailsContractRequestsContractRequestUuidGet(contractRequestUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractRequestDetailSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractRequestDetailsContractRequestsContractRequestUuidGet(contractRequestUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Contract Request
         * @param {string} contractRequestUuid 
         * @param {PatchContractRequestSerializerDTO} patchContractRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchContractRequestContractRequestsContractRequestUuidPatch(contractRequestUuid: string, patchContractRequestSerializerDTO: PatchContractRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchContractRequestContractRequestsContractRequestUuidPatch(contractRequestUuid, patchContractRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Contract Request
         * @param {string} contractRequestUuid 
         * @param {CreateContractRequestSerializerDTO} createContractRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContractRequestContractRequestsContractRequestUuidPut(contractRequestUuid: string, createContractRequestSerializerDTO: CreateContractRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractRequestSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContractRequestContractRequestsContractRequestUuidPut(contractRequestUuid, createContractRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractRequestsAPI - factory interface
 * @export
 */
export const ContractRequestsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractRequestsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Create Contract Request
         * @param {CreateContractRequestSerializerDTO} createContractRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContractRequestContractRequestsPost(createContractRequestSerializerDTO: CreateContractRequestSerializerDTO, options?: any): AxiosPromise<ContractRequestSerializerDTO> {
            return localVarFp.createContractRequestContractRequestsPost(createContractRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Contract Request Details
         * @param {string} contractRequestUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractRequestDetailsContractRequestsContractRequestUuidGet(contractRequestUuid: string, options?: any): AxiosPromise<ContractRequestDetailSerializerDTO> {
            return localVarFp.getContractRequestDetailsContractRequestsContractRequestUuidGet(contractRequestUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Contract Request
         * @param {string} contractRequestUuid 
         * @param {PatchContractRequestSerializerDTO} patchContractRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchContractRequestContractRequestsContractRequestUuidPatch(contractRequestUuid: string, patchContractRequestSerializerDTO: PatchContractRequestSerializerDTO, options?: any): AxiosPromise<ContractRequestSerializerDTO> {
            return localVarFp.patchContractRequestContractRequestsContractRequestUuidPatch(contractRequestUuid, patchContractRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Contract Request
         * @param {string} contractRequestUuid 
         * @param {CreateContractRequestSerializerDTO} createContractRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContractRequestContractRequestsContractRequestUuidPut(contractRequestUuid: string, createContractRequestSerializerDTO: CreateContractRequestSerializerDTO, options?: any): AxiosPromise<ContractRequestSerializerDTO> {
            return localVarFp.putContractRequestContractRequestsContractRequestUuidPut(contractRequestUuid, createContractRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createContractRequestContractRequestsPost operation in ContractRequestsAPI.
 * @export
 * @interface ContractRequestsAPICreateContractRequestContractRequestsPostRequest
 */
export interface ContractRequestsAPICreateContractRequestContractRequestsPostRequest {
    /**
     * 
     * @type {CreateContractRequestSerializerDTO}
     * @memberof ContractRequestsAPICreateContractRequestContractRequestsPost
     */
    readonly createContractRequestSerializerDTO: CreateContractRequestSerializerDTO
}

/**
 * Request parameters for getContractRequestDetailsContractRequestsContractRequestUuidGet operation in ContractRequestsAPI.
 * @export
 * @interface ContractRequestsAPIGetContractRequestDetailsContractRequestsContractRequestUuidGetRequest
 */
export interface ContractRequestsAPIGetContractRequestDetailsContractRequestsContractRequestUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractRequestsAPIGetContractRequestDetailsContractRequestsContractRequestUuidGet
     */
    readonly contractRequestUuid: string
}

/**
 * Request parameters for patchContractRequestContractRequestsContractRequestUuidPatch operation in ContractRequestsAPI.
 * @export
 * @interface ContractRequestsAPIPatchContractRequestContractRequestsContractRequestUuidPatchRequest
 */
export interface ContractRequestsAPIPatchContractRequestContractRequestsContractRequestUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractRequestsAPIPatchContractRequestContractRequestsContractRequestUuidPatch
     */
    readonly contractRequestUuid: string

    /**
     * 
     * @type {PatchContractRequestSerializerDTO}
     * @memberof ContractRequestsAPIPatchContractRequestContractRequestsContractRequestUuidPatch
     */
    readonly patchContractRequestSerializerDTO: PatchContractRequestSerializerDTO
}

/**
 * Request parameters for putContractRequestContractRequestsContractRequestUuidPut operation in ContractRequestsAPI.
 * @export
 * @interface ContractRequestsAPIPutContractRequestContractRequestsContractRequestUuidPutRequest
 */
export interface ContractRequestsAPIPutContractRequestContractRequestsContractRequestUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractRequestsAPIPutContractRequestContractRequestsContractRequestUuidPut
     */
    readonly contractRequestUuid: string

    /**
     * 
     * @type {CreateContractRequestSerializerDTO}
     * @memberof ContractRequestsAPIPutContractRequestContractRequestsContractRequestUuidPut
     */
    readonly createContractRequestSerializerDTO: CreateContractRequestSerializerDTO
}

/**
 * ContractRequestsAPI - object-oriented interface
 * @export
 * @class ContractRequestsAPI
 * @extends {BaseAPI}
 */
export class ContractRequestsAPI extends BaseAPI {
    /**
     * 
     * @summary Create Contract Request
     * @param {ContractRequestsAPICreateContractRequestContractRequestsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractRequestsAPI
     */
    public createContractRequestContractRequestsPost(requestParameters: ContractRequestsAPICreateContractRequestContractRequestsPostRequest, options?: any) {
        return ContractRequestsAPIFp(this.configuration).createContractRequestContractRequestsPost(requestParameters.createContractRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Contract Request Details
     * @param {ContractRequestsAPIGetContractRequestDetailsContractRequestsContractRequestUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractRequestsAPI
     */
    public getContractRequestDetailsContractRequestsContractRequestUuidGet(requestParameters: ContractRequestsAPIGetContractRequestDetailsContractRequestsContractRequestUuidGetRequest, options?: any) {
        return ContractRequestsAPIFp(this.configuration).getContractRequestDetailsContractRequestsContractRequestUuidGet(requestParameters.contractRequestUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Contract Request
     * @param {ContractRequestsAPIPatchContractRequestContractRequestsContractRequestUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractRequestsAPI
     */
    public patchContractRequestContractRequestsContractRequestUuidPatch(requestParameters: ContractRequestsAPIPatchContractRequestContractRequestsContractRequestUuidPatchRequest, options?: any) {
        return ContractRequestsAPIFp(this.configuration).patchContractRequestContractRequestsContractRequestUuidPatch(requestParameters.contractRequestUuid, requestParameters.patchContractRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Contract Request
     * @param {ContractRequestsAPIPutContractRequestContractRequestsContractRequestUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractRequestsAPI
     */
    public putContractRequestContractRequestsContractRequestUuidPut(requestParameters: ContractRequestsAPIPutContractRequestContractRequestsContractRequestUuidPutRequest, options?: any) {
        return ContractRequestsAPIFp(this.configuration).putContractRequestContractRequestsContractRequestUuidPut(requestParameters.contractRequestUuid, requestParameters.createContractRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
