import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  card: {
    '&:hover': {
      borderColor: (theme) => theme.palette.grey['500'],
    },
    background: (theme) => theme.palette.background.paper,
    border: '1px solid transparent',
    borderRadius: (theme) => theme.spacing(1),
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    cursor: 'grab',
    mb: 3,
    p: 3,
    touchAction: 'none',
  },
  cardDragging: {
    borderColor: (theme) => theme.palette.primary.main,
    boxShadow:
      '0px 20px 32px -8px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.31)',
  },
};
