import type { FormikErrors } from 'formik';
import isEmpty from 'lodash-es/isEmpty';

import type { ServiceRequestValues } from '../../types';
import { steps } from './service-request-form.const';

export const isStepValid = (
  idx: number,
  errors: FormikErrors<ServiceRequestValues>,
): boolean =>
  steps
    .filter((step, stepIdx) => stepIdx <= idx)
    .every(
      (step) =>
        step.validationFieldNames.length > 0 &&
        step.validationFieldNames.every((fieldName) =>
          isEmpty(errors[fieldName]),
        ),
    );
