import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  line: {
    backgroundColor: (theme) => theme.palette.divider,
    height: 2,
    mx: 1,
    width: 36,
  },
  text: {
    color: (theme) => theme.palette.secondary.light,
    display: 'flex',
    fontWeight: (theme) => theme.typography.fontWeightBold,
    justifyContent: 'start',
  },
  widgetBox: {
    justifyContent: 'center',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
  },
};
