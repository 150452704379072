import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { getRoundedCoordinate } from '../../../components';
import type { LocationInfoData } from '../../fields';
import { sxProps } from './location-info-box.styles';

type Props = {
  infoData: LocationInfoData;
  latLng: google.maps.LatLng;
};

export const LocationInfoBox: FC<Props> = (props) => {
  const { infoData, latLng } = props;
  const coordinates = useMemo(
    () => ({
      lat: getRoundedCoordinate(latLng.lat()),
      lng: getRoundedCoordinate(latLng.lng()),
    }),
    [latLng],
  );

  return (
    <Box sx={sxProps.container}>
      <Grid alignItems="center" container spacing={2}>
        <Grid item>
          <Box
            sx={{
              ...sxProps.thumbnail,
              backgroundImage: `url(${infoData.thumbnailUrl})`,
            }}
          />
        </Grid>
        <Grid item>
          <Typography>
            {infoData.name && <strong>{infoData.name}</strong>}
            {infoData.address && (
              <>
                <br />
                {infoData.address}
              </>
            )}
            <>
              <br />
              {coordinates.lat} {coordinates.lng}
            </>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
