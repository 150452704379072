import type { PaletteMode } from '@mui/material';

import { TicketStatusEnumDTO } from '../../../../connectors/ticket';
import type { ColorsHex } from '../../../shared';
import { palette } from '../../../shared/theme/palette';

const statusToColorsHexMap: Record<
  TicketStatusEnumDTO,
  Record<PaletteMode, ColorsHex>
> = {
  [TicketStatusEnumDTO.Open]: {
    dark: {
      backgroundColor: palette.silverChalice,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.silverChalice,
      color: palette.white,
    },
  },
  [TicketStatusEnumDTO.InProgress]: {
    dark: {
      backgroundColor: palette.pictonBlue,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.pictonBlue,
      color: palette.white,
    },
  },
  [TicketStatusEnumDTO.AwaitingTenant]: {
    dark: {
      backgroundColor: palette.atomicTangerine,
      color: palette.mineShaft,
    },
    light: {
      backgroundColor: palette.atomicTangerine,
      color: palette.mineShaft,
    },
  },
  [TicketStatusEnumDTO.AwaitingExternalProvider]: {
    dark: {
      backgroundColor: palette.amethyst,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.amethyst,
      color: palette.white,
    },
  },
  [TicketStatusEnumDTO.OnHold]: {
    dark: {
      backgroundColor: palette.pomegranate,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.pomegranate,
      color: palette.white,
    },
  },
  [TicketStatusEnumDTO.Done]: {
    dark: {
      backgroundColor: palette.fruitSalad,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.fruitSalad,
      color: palette.white,
    },
  },
};

export const getStatusColorsHex = (
  paletteMode: PaletteMode,
  status: TicketStatusEnumDTO,
): ColorsHex => statusToColorsHexMap[status][paletteMode] || palette.alto;
