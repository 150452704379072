import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { CommonProcessSerializersAttachmentAttachmentSerializerDTO as AttachmentSerializerDTO } from '../../../../../../connectors/property';
import { useTranslation } from '../../../../translations';
import { getVisibilityLabelKey } from '../../helpers';

type Props = {
  visibility: AttachmentSerializerDTO['visibility'];
};

export const VisibilityCell: FC<Props> = (props) => {
  const { visibility } = props;
  const { t } = useTranslation();
  const labelKey = useMemo(
    () => getVisibilityLabelKey(visibility),
    [visibility],
  );

  return <Typography>{t(labelKey)}</Typography>;
};
