import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import type { Dispatch, FC, ReactElement, SetStateAction } from 'react';
import React from 'react';

import { useTranslation } from '../../../translations';
import { sxProps } from './selected-rows-header.styles';

type Props<T> = {
  actionComponent: (
    rows: T[],
    setRowsDisabled: Dispatch<SetStateAction<boolean>>,
  ) => ReactElement;
  rows: T[];
  setRowsDisabled: Dispatch<SetStateAction<boolean>>;
};

export const SelectedRowsHeader = <T,>(
  props: Props<T>,
): ReturnType<FC<Props<T>>> => {
  const { actionComponent, rows, setRowsDisabled } = props;
  const { t } = useTranslation();

  return (
    <Box data-testid="selected-rows-header" sx={sxProps.box}>
      <Box sx={sxProps.checkbox}>
        <Checkbox color="primary" checked disabled />
      </Box>
      <Typography component="span" fontWeight="bold" variant="subtitle2">
        {t('list.selectedRowsText', { rowCount: rows.length })}:
      </Typography>
      {actionComponent && (
        <Box sx={sxProps.actionBlock}>
          {actionComponent(rows, setRowsDisabled)}
        </Box>
      )}
    </Box>
  );
};
