import type {
  ContactSerializerDTO,
  LettingAgentSerializerDTO,
  TenantSerializerDTO,
} from '../../../connectors/property';
import type { LinkedUserSerializerDTO } from '../../../connectors/ticket';
import type {
  EmployeeSerializerDTO,
  UserSerializerDTO,
} from '../../../connectors/user';
import { PLACEHOLDER } from '../consts';

export const getDisplayValue = (
  value: unknown,
): { value: string; labelKey?: GenericTypes.TranslationLabel } => {
  switch (typeof value) {
    case 'bigint':
    case 'number': {
      return {
        value: new Intl.NumberFormat('no-NO').format(value),
      };
    }
    case 'boolean': {
      return {
        labelKey: value ? 'list.values.trueValue' : 'list.values.falseValue',
        value: value.toString(),
      };
    }
    case 'string': {
      return {
        value: value.length ? value : PLACEHOLDER,
      };
    }
    case 'object': {
      return {
        value: PLACEHOLDER, // TODO: Find a way to display object
      };
    }
    default: {
      return {
        value: PLACEHOLDER,
      };
    }
  }
};

export const getFormattedPrice = (
  value: number | string,
  currency?: string,
): string => {
  const rawValue = typeof value === 'string' ? Number.parseFloat(value) : value;
  const formattedValue = !Number.isNaN(rawValue)
    ? getDisplayValue(rawValue).value
    : PLACEHOLDER;

  return [formattedValue, currency].filter((s) => s).join(' ');
};

export const getUserName = (
  user:
    | ContactSerializerDTO
    | EmployeeSerializerDTO
    | LettingAgentSerializerDTO
    | LinkedUserSerializerDTO
    | TenantSerializerDTO
    | UserSerializerDTO,
): string => `${user.firstName} ${user.lastName}`;

export const capitalizeFirstLetter = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);
