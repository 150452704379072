import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  actionButtons: {
    borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
  },
  attachmentButtonLabel: {
    minWidth: '40px',
  },
  avatar: {
    mr: 1,
  },
  container: {
    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
    mt: 'auto',
    pb: 1,
    pl: 2,
    pr: 1,
    pt: 1.5,
  },
  input: {
    '& .MuiInputAdornment-root': {
      height: 'auto',
      lineHeight: 0,
      m: 0,
      maxHeight: 'initial',
      width: '100%',
    },
    flexDirection: 'column',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    padding: (theme) => theme.spacing(1, 1.5),
  },
};
