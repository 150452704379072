import type { Cluster } from '@googlemaps/markerclusterer';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash-es/isEmpty';
import type { FC } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { renderToString } from 'react-dom/server';

import { View } from '../../../View/view.component';
import type { Props } from '../../../map.type';
import {
  MARKER_SIZE,
  MULTIPLE_MAP_CONFIG,
  READONLY_MAP_CONFIG,
} from '../../consts';
import { InfoWindow } from '../InfoWindow/info-window.component';

export const Content: FC<Props> = (props) => {
  const { children, locations, search } = props;
  const [infoWindowId, setInfoWindowId] = useState('');
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [isRefRendered, setRefRendered] = useState(false);
  const config = useMemo(
    () => (locations.length > 1 ? MULTIPLE_MAP_CONFIG : READONLY_MAP_CONFIG),
    [locations.length],
  );
  const map = useMemo(() => {
    if (!isRefRendered || !ref.current) return null;

    return new google.maps.Map(ref.current, config);
  }, [config, isRefRendered]);
  const infoWindow = useMemo(
    () =>
      new google.maps.InfoWindow({
        content: '',
        disableAutoPan: true,
      }),
    [],
  );
  const showInfoWindow = useCallback(
    (id: string, marker: google.maps.Marker) => {
      infoWindow.setContent(renderToString(<Box />));
      infoWindow.open(map, marker);

      const domReadyListener = infoWindow.addListener('domready', () => {
        setInfoWindowId(id);
        domReadyListener.remove();
      });
    },
    [infoWindow, map],
  );
  const hideInfoWindows = useCallback(() => {
    infoWindow.close();
    setInfoWindowId('');
  }, [infoWindow]);
  const markerStyle = useMemo<{
    icon: google.maps.Icon;
    label: Omit<google.maps.MarkerLabel, 'text'>;
  }>(
    () => ({
      icon: {
        scaledSize: new google.maps.Size(MARKER_SIZE, MARKER_SIZE),
        url: '/assets/pin.svg',
      },
      label: {
        color: theme.palette.background.paper,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 'bold',
        text: '',
      },
    }),
    [theme.palette.background.paper, theme.typography],
  );
  const markers = useMemo(
    () =>
      locations.reduce<google.maps.Marker[]>((markers, location) => {
        const { id, lat, lng } = location;
        const marker = new google.maps.Marker({
          icon: markerStyle.icon,
          position: new google.maps.LatLng(lat, lng),
        });

        if (children) {
          marker.addListener('click', () => {
            showInfoWindow(id, marker);
          });
        }

        return [...markers, marker];
      }, []),
    [children, locations, markerStyle.icon, showInfoWindow],
  );
  const bounds = useMemo(
    () =>
      locations.reduce<google.maps.LatLngBounds>((bounds, location) => {
        const { lat, lng } = location;
        const latLng = new google.maps.LatLng(lat, lng);

        bounds.extend(latLng);

        return bounds;
      }, new google.maps.LatLngBounds()),
    [locations],
  );
  const geocoder = useMemo(() => new google.maps.Geocoder(), []);
  const geocoderRequest = useMemo<google.maps.GeocoderRequest>(
    () => ({
      ...search,
      ...(search?.location && {
        location: new google.maps.LatLng(
          search.location.lat,
          search.location.lng,
        ),
      }),
    }),
    [search],
  );
  const centerMap$ = useCallback(
    async (map: google.maps.Map) => {
      const setCenter = (newBounds?: google.maps.LatLngBounds): void => {
        const finalBounds = newBounds || bounds;

        map.setCenter(finalBounds.getCenter());
        map.fitBounds(finalBounds);
      };

      if (isEmpty(geocoderRequest)) {
        setCenter();
      } else {
        await geocoder.geocode(geocoderRequest, (results) => {
          setCenter(results?.[0]?.geometry.viewport);
        });
      }
    },
    [bounds, geocoder, geocoderRequest],
  );
  const setMarkers = useCallback(
    (map: google.maps.Map) => {
      if (markers.length === 1) {
        const marker = markers[0];

        marker.setMap(map);

        setTimeout(() => {
          map.setZoom(READONLY_MAP_CONFIG.zoom);
        }, 100);
      } else {
        // eslint-disable-next-line no-new
        new MarkerClusterer({
          map,
          markers,
          renderer: {
            render(cluster: Cluster): google.maps.Marker {
              const { count, position } = cluster;
              const zIndex = +google.maps.Marker.MAX_ZINDEX + count;
              const markerOptions: google.maps.MarkerOptions = {
                icon: {
                  ...markerStyle.icon,
                  scaledSize: new google.maps.Size(54, 54),
                },
                label: { ...markerStyle.label, text: count.toString() },
                position,
                zIndex,
              };

              return new google.maps.Marker(markerOptions);
            },
          },
        });
      }
    },
    [markerStyle, markers],
  );

  useEffect(() => {
    setRefRendered(true);
  }, []);

  useEffect(() => {
    if (!map) return undefined;

    const listeners = ['click', 'drag', 'zoom_changed'].map((event) =>
      google.maps.event.addListener(map, event, hideInfoWindows),
    );

    window.addEventListener('resize', hideInfoWindows);

    setMarkers(map);
    centerMap$(map);

    return (): void => {
      listeners.forEach((listener) =>
        google.maps.event.removeListener(listener),
      );
      window.removeEventListener('resize', hideInfoWindows);
    };
  }, [centerMap$, hideInfoWindows, map, setMarkers]);

  return (
    <>
      <InfoWindow id={infoWindowId}>{children}</InfoWindow>
      <View ref={ref} />
    </>
  );
};
