import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { ProspectStatusEnumDTO } from '../../../../../connectors/property';
import { getStatusColorsHex } from '../../helpers';

export const sxProps: Record<string, SxProps<Theme>> = {
  [ProspectStatusEnumDTO.ProspectCancelled]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ProspectCancelled,
      ).backgroundColor,
    color: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ProspectCancelled,
      ).color,
  },
  [ProspectStatusEnumDTO.ProspectConfirmedByUser]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ProspectConfirmedByUser,
      ).backgroundColor,
    color: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ProspectConfirmedByUser,
      ).color,
  },
  [ProspectStatusEnumDTO.ProspectRegistered]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ProspectRegistered,
      ).backgroundColor,
    color: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ProspectRegistered,
      ).color,
  },
  [ProspectStatusEnumDTO.ProspectRejected]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ProspectRejected,
      ).backgroundColor,
    color: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ProspectRejected,
      ).color,
  },
  [ProspectStatusEnumDTO.Viewed]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, ProspectStatusEnumDTO.Viewed)
        .backgroundColor,
    color: (theme) =>
      getStatusColorsHex(theme.palette.mode, ProspectStatusEnumDTO.Viewed)
        .color,
  },
  [ProspectStatusEnumDTO.ViewingCanceled]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ViewingCanceled,
      ).backgroundColor,
    color: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ViewingCanceled,
      ).color,
  },
  [ProspectStatusEnumDTO.ViewingDateConfirmed]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ViewingDateConfirmed,
      ).backgroundColor,
    color: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ViewingDateConfirmed,
      ).color,
  },
  [ProspectStatusEnumDTO.ViewingFailed]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ViewingFailed,
      ).backgroundColor,
    color: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ViewingFailed,
      ).color,
  },
  [ProspectStatusEnumDTO.ViewingRequested]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ViewingRequested,
      ).backgroundColor,
    color: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        ProspectStatusEnumDTO.ViewingRequested,
      ).color,
  },
  box: {
    borderRadius: 2,
    display: 'block',
    fontSize: (theme) => theme.typography.pxToRem(12),
    fontWeight: (theme) => theme.typography.fontWeightBold,
    px: 1,
    py: 0.25,
    textAlign: 'center',
  },
};
