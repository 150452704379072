import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React from 'react';
import type { FC } from 'react';

import { useTranslation } from '../../../translations';
import { sxProps } from './empty-row.styles';

type Props = {
  colSpan: number;
};

export const EmptyRow: FC<Props> = (props) => {
  const { colSpan } = props;
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Box sx={sxProps.emptyContentContainer}>
          <Typography variant="body1" align="center">
            {t('list.emptyList')}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};
