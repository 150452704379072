import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { useTranslation } from '../../../../../shared';
import { FormFieldset } from '../../../../../shared/form/components';
import type { FormValues } from '../form.types';
import { getFieldsetConfig } from './pricing-step.model';

export const PricingStep: FC = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();
  const fieldSuffix = useMemo(
    // TODO: Find better solution - get currency from backend
    () => `${t('currency.pl')} ${t('advertisement.fieldset.pricing.period')}`,
    [t],
  );
  const fieldsetConfig = useMemo(
    () => getFieldsetConfig(fieldSuffix),
    [fieldSuffix],
  );

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item xs={12} sm={6} lg={5}>
        <Grid container spacing={3}>
          {fieldsetConfig.map((config) => (
            <Grid item key={`field-${config.key}`} xs={12}>
              <FormFieldset config={config} values={values} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
