/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum InvoiceRequestTypeEnumDTO {
    CopyOfInvoice = 'copy_of_invoice',
    GeneralInvoiceOrPaymentInquiries = 'general_invoice_or_payment_inquiries',
    EnforcementOfficerProcessInquiry = 'enforcement_officer_process_inquiry',
    RequestForArrangedPayment = 'request_for_arranged_payment',
    ChangingBankAccountNumber = 'changing_bank_account_number',
    RequestForAutomaticPayment = 'request_for_automatic_payment',
    OverviewOfOutstandingPayments = 'overview_of_outstanding_payments',
    Other = 'other'
}



