import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    backgroundColor: (theme) => theme.palette.background.paper,
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    borderRadius: 2,
    boxShadow:
      '0px 5px 8px -2px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.31)',
    display: 'flex',
    left: 0,
    ml: 2,
    mr: 2,
    p: 2,
    position: 'absolute',
    right: 0,
    top: (theme) => theme.spacing(2),
    zIndex: 1,
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.38)',
    mr: 1,
    mt: 0.5,
  },
};
