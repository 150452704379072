import Box from '@mui/material/Box';
import React, { forwardRef } from 'react';

import { MAP_STYLES, sxProps } from './view.styles';

export const View = forwardRef<HTMLDivElement>((props, ref) => (
  <Box sx={sxProps.content}>
    <Box ref={ref} style={MAP_STYLES} />
  </Box>
));
