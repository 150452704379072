import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  closeIcon: {
    p: 1,
    position: 'absolute',
    right: (theme) => theme.spacing(2),
    top: (theme) => theme.spacing(2),
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    pb: 0,
    pt: 2,
  },
  dialogContent: {
    p: 0,
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    mb: 1,
  },
};
