import type { CountrySerializerDTO } from '../../../../../../connectors/company';
import {
  EntityEnumDTO,
  LocationTypeEnumDTO,
} from '../../../../../../connectors/ticket';
import { Country } from '../../../../../shared';

export const getInitialIssueLocationValue = (
  country: CountrySerializerDTO['uuid'],
  entityType: EntityEnumDTO,
): LocationTypeEnumDTO | undefined => {
  switch (entityType) {
    case EntityEnumDTO.Property: {
      return country === Country.NO
        ? LocationTypeEnumDTO.OutdoorArea
        : LocationTypeEnumDTO.CommonOutdoorArea;
    }
    case EntityEnumDTO.PropertyObject: {
      return LocationTypeEnumDTO.CommonArea;
    }
    case EntityEnumDTO.Unit: {
      return LocationTypeEnumDTO.Apartment;
    }
    default: {
      return undefined;
    }
  }
};
