import Grid from '@mui/material/Grid';
import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import type { FC, Dispatch, SetStateAction } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { useDebounce } from '../../../hooks';
import { FilledActionSearchIcon } from '../../../icons';
import { useTranslation } from '../../../translations';
import type { ListState } from '../list.type';

type Props<TFilterValues> = {
  listState: ListState<TFilterValues>;
  setListState: Dispatch<SetStateAction<ListState<TFilterValues>>>;
};

export const Search = <TFilterValues,>(
  props: Props<TFilterValues>,
): ReturnType<FC<Props<TFilterValues>>> => {
  const { listState, setListState } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(listState.searchQuery || '');
  const updateSearchQuery = useCallback(
    (searchQuery: ListState['searchQuery']) => {
      setListState((prevState) => ({
        ...prevState,
        paginationConfig: {
          ...prevState.paginationConfig,
          currentPage: 1,
        },
        searchQuery,
      }));
    },
    [setListState],
  );
  const debouncedUpdateSearchQuery = useDebounce(updateSearchQuery, 500);
  const handleQueryChange: TextFieldProps['onChange'] = useCallback(
    (event): void => {
      const { value } = event.target;

      setInputValue(value);
      debouncedUpdateSearchQuery(value || undefined);
    },
    [debouncedUpdateSearchQuery],
  );

  useEffect(() => {
    setInputValue(listState.searchQuery || '');
  }, [listState.searchQuery]);

  return (
    <Grid item xs={12}>
      <TextField
        autoComplete="off"
        fullWidth
        InputProps={{
          startAdornment: <FilledActionSearchIcon height={24} width={24} />,
        }}
        onChange={handleQueryChange}
        placeholder={t('list.simpleSearchPlaceholder')}
        value={inputValue}
        variant="outlined"
      />
    </Grid>
  );
};
