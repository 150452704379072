import Box from '@mui/material/Box';
import type { CircularProgressProps } from '@mui/material/CircularProgress';
import CircularProgress from '@mui/material/CircularProgress';
import type { FC } from 'react';
import React from 'react';

import { styles } from './spinner.styles';

type Props = {
  color?: CircularProgressProps['color'];
  size?: CircularProgressProps['size'];
};

export const Spinner: FC<Props> = (props) => {
  const { color, size } = props;

  return (
    <Box sx={styles}>
      <CircularProgress color={color} size={size} />
    </Box>
  );
};
