import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';

import { AdvertisementPlatformEnumDTO } from '../../../../../connectors/company';
import { Country, useTranslation } from '../../../../shared';
import { advertisementClient } from '../../../advertisement.client';
import { useAdvertisementConfig } from '../../providers';
import { sxProps } from './olx-integration-button.styles';

export const OlxIntegrationButton: FC = () => {
  const { t } = useTranslation();
  const { getCountryConfig } = useAdvertisementConfig();
  const config = useMemo(
    () => getCountryConfig(Country.PL), // TODO: add multicountries support
    [getCountryConfig],
  );
  const integrationConfig = useMemo(
    () =>
      config.integrations.find(
        (integration) =>
          integration.advertisementPlatform ===
          AdvertisementPlatformEnumDTO.Olx,
      ),
    [config.integrations],
  );
  const handleClick = useCallback(async () => {
    try {
      const { url } = await advertisementClient.authorizeOlx$();

      window.location.href = url;
    } catch (e) {
      toast.error(t('errors.general.message'));
    }
  }, [t]);

  if (!integrationConfig) return null;

  return (
    <Button
      color="secondary"
      fullWidth
      onClick={handleClick}
      size="large"
      variant="contained"
    >
      {integrationConfig.logoUrl && (
        <Box
          sx={{
            ...sxProps.icon,
            backgroundImage: `url(${integrationConfig.logoUrl})`,
          }}
        />
      )}
      {t('advertisement.platform.auth')}
    </Button>
  );
};
