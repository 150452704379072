import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import type { FC } from 'react';

import { useTranslation } from '../../translations';
import { sxProps } from './notifications-empty.style';

export const NotificationsEmpty: FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={sxProps.emptyContainer}>
      <Box
        component="img"
        src="/assets/sunny.svg"
        height={214}
        mt={2}
        width={214}
      />
      <Typography variant="h2" mt={2}>
        {t('notification.emptyList.title')}
      </Typography>
      <Typography variant="body1">
        {t('notification.emptyList.text')}
      </Typography>
    </Box>
  );
};
