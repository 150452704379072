import { CompaniesAPI } from '../../connectors/company';
import type {
  AttachmentSerializerDTO,
  CompanyDetailSerializerDTO,
  CompanySerializerDTO,
  CreateCompanySerializerDTO,
  ListAttachmentSerializerDTO,
  ListCompaniesSerializerDTO,
  ListLogSerializerDTO,
} from '../../connectors/company';
import type {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
  ListPropertiesSerializerDTO,
  ListPropertyObjectsSerializerDTO,
} from '../../connectors/property';
import { PropertiesAPI, PropertyObjectsAPI } from '../../connectors/property';
import type { AttachmentVisibilityEnumDTO } from '../../connectors/ticket';
import { mieInstance } from '../mie.instance';
import type {
  AttachmentFilterValues,
  AsyncAutocompleteOption,
  CustomFile,
  ListQueryParams,
  LocalizationFilterValues,
} from '../shared';
import { getEnvValue } from '../shared/helpers/env.helper';
import type { FilterValues } from './PropertyObjectList/property-object-list.type';

class CompanyClient {
  private client;

  private propertyClient;

  private propertyObjectClient;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.client = new CompaniesAPI(undefined, baeBath, mieInstance);
    this.propertyClient = new PropertiesAPI(undefined, baeBath, mieInstance);
    this.propertyObjectClient = new PropertyObjectsAPI(
      undefined,
      baeBath,
      mieInstance,
    );
  }

  public getList$ = async (
    queryParams?: ListQueryParams<LocalizationFilterValues>,
  ): Promise<ListCompaniesSerializerDTO> => {
    const { page, pageSize, search, sort, filters } = { ...queryParams };
    const { city, country, region } = { ...filters };
    const response = await this.client.getCompaniesCompaniesGet({
      cityUuid: city || undefined,
      countryUuid: country || undefined,
      order: sort,
      page,
      pageSize,
      regionUuid: region || undefined,
      search,
    });

    return response.data;
  };

  public getProperties$ = async (
    id: CompanySerializerDTO['uuid'],
    queryParams?: ListQueryParams<LocalizationFilterValues>,
  ): Promise<ListPropertiesSerializerDTO> => {
    const { page, pageSize, search, sort, filters } = { ...queryParams };
    const { city, country, region } = { ...filters };
    const response = await this.propertyClient.getPropertiesPropertiesGet({
      cityUuid: city || undefined,
      companyUuid: id,
      countryUuid: country || undefined,
      order: sort,
      page,
      pageSize,
      regionUuid: region || undefined,
      search,
    });

    return response.data;
  };

  public getPropertyObjects$ = async (
    id: CompanySerializerDTO['uuid'],
    queryParams?: ListQueryParams<FilterValues>,
  ): Promise<ListPropertyObjectsSerializerDTO> => {
    const { page, pageSize, filters, search, sort } = { ...queryParams };
    const { category } = { ...filters };
    const response =
      await this.propertyObjectClient.getPropertyObjectsPropertyObjectsGet({
        category: category || undefined,
        companyUuid: id,
        order: sort,
        page,
        pageSize,
        search,
      });

    return response.data;
  };

  public getDetails$ = async (
    id: CompanyDetailSerializerDTO['uuid'],
  ): Promise<CompanyDetailSerializerDTO> => {
    const response = await this.client.getSingleCompanyCompaniesCompanyUuidGet({
      companyUuid: id,
    });

    return response.data;
  };

  public getLogs$ = async (
    id: CompanyDetailSerializerDTO['uuid'],
    queryParams?: ListQueryParams,
  ): Promise<ListLogSerializerDTO> => {
    const { page, pageSize } = { ...queryParams };
    const response =
      await this.client.getSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet(
        { companyUuid: id, page, pageSize },
      );

    return response.data;
  };

  public update$ = async (
    id: CompanyDetailSerializerDTO['uuid'],
    values: CreateCompanySerializerDTO,
  ): Promise<CompanySerializerDTO> => {
    const response = await this.client.patchCompanyCompaniesCompanyUuidPatch({
      companyUuid: id,
      patchCompanySerializerDTO: values,
    });

    return response.data;
  };

  public create$ = async (
    values: CreateCompanySerializerDTO,
  ): Promise<CompanySerializerDTO> => {
    const response = await this.client.createCompanyCompaniesPost({
      createCompanySerializerDTO: values,
    });

    return response.data;
  };

  public getSuggestions$ = async (
    query: string,
    country?: CompanySerializerDTO['uuid'],
  ): Promise<AsyncAutocompleteOption<CompanySerializerDTO['uuid']>[]> => {
    const response = await this.client.getCompaniesCompaniesGet({
      countryUuid: country,
      search: query,
    });

    return response.data.results.map((result) => ({
      label: result.name,
      value: result.uuid,
    }));
  };

  public getAttachments$ = async (
    id: CompanySerializerDTO['uuid'],
    queryParams?: ListQueryParams<AttachmentFilterValues>,
  ): Promise<ListAttachmentSerializerDTO> => {
    const {
      filters,
      page,
      pageSize = 100,
      search,
      sort = 'sequence_number',
    } = { ...queryParams };
    const { type } = { ...filters };
    const response =
      await this.client.getCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet(
        {
          companyUuid: id,
          order: sort,
          page,
          pageSize,
          search,
          type: type?.join(',') || undefined,
        },
      );

    return response.data;
  };

  public uploadAttachment$ = async (
    id: CompanySerializerDTO['uuid'],
    attachmentCategory: AttachmentCategoryEnumDTO,
    attachmentType: AttachmentTypeEnumDTO,
    file: CustomFile,
    visibility: AttachmentVisibilityEnumDTO,
  ): Promise<AttachmentSerializerDTO[]> => {
    const response =
      await this.client.uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost(
        {
          attachmentCategory,
          attachmentType,
          companyUuid: id,
          description: file.description,
          files: [file],
          title: file.title,
          visibility,
        },
      );

    return response.data;
  };

  public deleteAttachment$ = async (
    id: CompanySerializerDTO['uuid'],
    attachmentUuid: AttachmentSerializerDTO['uuid'],
  ): Promise<void> => {
    await this.client.deleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete(
      { attachmentUuid, companyUuid: id },
    );
  };
}
export const companyClient = new CompanyClient();
