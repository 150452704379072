import Box from '@mui/material/Box';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { isPermitted, useAuth } from '../../../../+auth';
import type {
  ListTicketsSerializerDTO,
  TicketTypeEnumDTO,
} from '../../../../../connectors/ticket';
import type { FilterValuesWithLocalization } from '../../../../shared';
import {
  getFilterPanelWithLocalizationInitialValues,
  INITIAL_LIST_STATE,
  List,
  useApi,
  useList,
} from '../../../../shared';
import {
  getTypePathBasedOnTicketType,
  TICKET_WRITE_PERMISSIONS,
  ticketClient,
} from '../../../index';
import PATHS from '../../../paths.const';
import {
  getFilterPanelInitialValues,
  getRequestFilterPanel,
  isUserEntity,
} from '../../helpers';
import type { DetailRequests, Entity, FilterValues } from '../../types';
import { CloseRequestAction } from '../CloseRequestAction/close-request-action.component';
import { ListButtons } from '../ListButtons/list-buttons.component';
import { ReassignRequestAction } from '../ReassignRequestAction/reassign-request-action.component';
import { getColumnsConfig } from './entity-request-list.helper';
import { sxProps } from './entity-request-list.styles';

type Props = {
  createPath: string;
  entity: Entity;
  ticketType: TicketTypeEnumDTO;
};

export const EntityRequestList: FC<Props> = (props) => {
  const { createPath, entity, ticketType } = props;
  const { permissions } = useAuth();
  const navigate = useNavigate();
  const columnsConfig = useMemo(
    () => getColumnsConfig(ticketType),
    [ticketType],
  );
  const FilterPanel = useMemo(
    () => getRequestFilterPanel(ticketType),
    [ticketType],
  );
  const filterPanelInitialValues = useMemo(() => {
    const initialValues = getFilterPanelInitialValues(ticketType);

    return getFilterPanelWithLocalizationInitialValues(initialValues);
  }, [ticketType]);
  const {
    listState,
    queryParams: listQueryParams,
    setListState,
    setPaginationFromResponse,
  } = useList<
    ListTicketsSerializerDTO,
    FilterValuesWithLocalization<FilterValues>
  >(false, { ...INITIAL_LIST_STATE, filters: filterPanelInitialValues });
  const getData$ = useCallback(async () => {
    const response = await ticketClient.getTickets$(listQueryParams, {
      id: entity.uuid,
      type: entity.type,
    });

    setPaginationFromResponse(response);

    return response;
  }, [entity, listQueryParams, setPaginationFromResponse]);
  const { isFetching, response, setResponse } =
    useApi<ListTicketsSerializerDTO>({ count: 0, results: [] }, getData$);
  const handleRowClick = useCallback(
    ({ uuid }) => {
      const type = getTypePathBasedOnTicketType(ticketType);

      navigate(generatePath(PATHS.DETAILS, { id: uuid, type }));
    },
    [navigate, ticketType],
  );
  const handleSelectedRowsActionComponent = useCallback(
    (rows, setRowsDisabled) => (
      <Box sx={sxProps.box}>
        <ReassignRequestAction
          rows={rows}
          setResponse={setResponse}
          ticketType={ticketType}
        />
        <CloseRequestAction
          rows={rows}
          setResponse={setResponse}
          setRowsDisabled={setRowsDisabled}
        />
      </Box>
    ),
    [setResponse, ticketType],
  );
  const canCreateTicket = useMemo(
    () => isPermitted(TICKET_WRITE_PERMISSIONS, permissions),
    [permissions],
  );

  return (
    <List
      buttonsPanelComponent={
        canCreateTicket ? (
          <ListButtons path={createPath} ticketType={ticketType} />
        ) : undefined
      }
      // TODO: Find solution to handle types
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columnsConfig={columnsConfig}
      filterPanelComponent={
        <FilterPanel
          isLocalizationHidden={!isUserEntity(entity) && Boolean(entity.city)}
          // TODO: Find solution to handle types
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          listState={listState}
          // TODO: Find solution to handle types
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setListState={setListState}
          {...(isUserEntity(entity) && { specifiedUser: entity })}
        />
      }
      isFetching={isFetching}
      listState={listState}
      model={response.results as DetailRequests[]}
      modelKey="uuid"
      onRowClick={handleRowClick}
      selectedRowsActionComponent={handleSelectedRowsActionComponent}
      setListState={setListState}
    />
  );
};
