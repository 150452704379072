import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  button: {
    '&:hover': {
      color: 'primary.main',
    },
    minWidth: 'auto',
    mx: 1,
    p: 0,
  },
};
