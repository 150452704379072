import { UserRoleEnumDTO } from '../../../../connectors/user';

export const CONTACT_ROLES: UserRoleEnumDTO[] = [
  UserRoleEnumDTO.BackOffice,
  UserRoleEnumDTO.Contractor,
  UserRoleEnumDTO.LettingAgent,
];

export const CUSTOMER_SERVICE_ROLES: UserRoleEnumDTO[] = [
  UserRoleEnumDTO.CustomerService,
];

export const JANITOR_ROLES: UserRoleEnumDTO[] = [UserRoleEnumDTO.Janitor];

export const MANAGERS_ROLES: UserRoleEnumDTO[] = [
  UserRoleEnumDTO.AssetManager,
  UserRoleEnumDTO.CountryManager,
  UserRoleEnumDTO.FinanceManager,
  UserRoleEnumDTO.PropertyManager,
];
