import { ReactComponent as ListBullets1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/lists/list-bullets-1.svg';
import { ReactComponent as ListNumbersIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/lists/list-numbers.svg';
import { ReactComponent as TextBoldIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/text-formating/text-bold.svg';
import { ReactComponent as TextItalicIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/text-formating/text-italic.svg';
import { ReactComponent as TextUnderlineIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/text-formating/text-underline.svg';
import { ListItemNode, ListNode } from '@lexical/list';
import * as Sentry from '@sentry/react';
import type { EditorThemeClasses } from 'lexical';

import type { FormatButton } from './editor.type';

const namespace = 'Editor';
const themePrefix = `${namespace}Theme__`;

const theme: EditorThemeClasses = {
  list: {
    listitem: `${themePrefix}listItem`,
    nested: {
      listitem: `${themePrefix}nestedListItem`,
    },
    ol: `${themePrefix}ol`,
    olDepth: [
      `${themePrefix}ol1`,
      `${themePrefix}ol2`,
      `${themePrefix}ol3`,
      `${themePrefix}ol4`,
      `${themePrefix}ol5`,
    ],
    ul: `${themePrefix}ul`,
  },
  ltr: `${themePrefix}ltr`,
  paragraph: `${themePrefix}paragraph`,
  rtl: `${themePrefix}rtl`,
  text: {
    bold: `${themePrefix}textBold`,
    italic: `${themePrefix}textItalic`,
    underline: `${themePrefix}textUnderline`,
  },
};

export const CONFIG = {
  namespace,
  nodes: [ListNode, ListItemNode],
  // Catch any errors that occur during Lexical updates and log them
  // or throw them as needed. If you don't throw them, Lexical will
  // try to recover gracefully without losing user data.
  onError: (error: Error) => {
    Sentry.captureException(error);
    throw error;
  },
  theme,
};

export const FORMAT_BUTTONS: FormatButton[] = [
  { icon: TextBoldIcon, value: 'bold' },
  { icon: TextItalicIcon, value: 'italic' },
  { icon: TextUnderlineIcon, value: 'underline' },
  { icon: ListBullets1Icon, value: 'bullet' },
  { icon: ListNumbersIcon, value: 'number' },
];
