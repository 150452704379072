import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  icon: {
    px: 1.5,
    py: 1.25,
  },
  preview: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: (theme) => theme.spacing(0.5),
    height: '100%',
    minHeight: 300,
    width: '100%',
  },
};
