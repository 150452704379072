import React, { useCallback, useMemo } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';

import type { AttachmentVisibilityEnumDTO } from '../../../../connectors/company';
import type {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
  CommonProcessSerializersAttachmentAttachmentSerializerDTO as AttachmentSerializerDTO, // eslint-disable-line max-len
  ListAttachmentSerializerDTO,
} from '../../../../connectors/property';
import type { ListState } from '../../components';
import { FilterPanel, List as GenericList } from '../../components';
import type { CustomFile } from '../../form';
import {
  shouldDecreasePaginationAfterMutation,
  shouldRevertListStateAfterMutation,
} from '../../helpers';
import { INITIAL_LIST_STATE, useApi } from '../../hooks';
import {
  AttachmentUploaderProvider,
  ListButtonsPanel,
  SelectedRowsAction,
} from '../shared';
import { filterFieldsConfig, getColumnsConfig } from './list.model';
import type { FilterValues } from './list.type';

type Props = {
  getData$: () => Promise<ListAttachmentSerializerDTO>;
  listState: ListState<FilterValues>;
  onDelete$: (id: AttachmentSerializerDTO['uuid']) => Promise<void>;
  setListState: Dispatch<SetStateAction<ListState<FilterValues>>>;
  onUpload$?: (
    category: AttachmentCategoryEnumDTO,
    file: CustomFile,
    type: AttachmentTypeEnumDTO,
    visibility: AttachmentVisibilityEnumDTO,
  ) => Promise<AttachmentSerializerDTO[]>;
};

export const List: FC<Props> = (props) => {
  const { getData$, listState, onDelete$, onUpload$, setListState } = props;
  const { isFetching, refetch, response } = useApi<ListAttachmentSerializerDTO>(
    { count: 0, results: [] },
    getData$,
  );
  const handleDelete$ = useCallback(
    async (
      payload: AttachmentSerializerDTO['uuid'] | AttachmentSerializerDTO[],
    ) => {
      const ids = Array.isArray(payload)
        ? payload.map((attachment) => attachment.uuid)
        : [payload];

      // eslint-disable-next-line no-useless-catch
      try {
        await Promise.all(ids.map((id) => onDelete$(id)));
        const shouldDecreasePagination = shouldDecreasePaginationAfterMutation(
          ids.length,
          response.results.length,
          listState.paginationConfig,
        );

        return shouldDecreasePagination
          ? setListState((prevState) => ({
              ...prevState,
              paginationConfig: {
                ...prevState.paginationConfig,
                currentPage: prevState.paginationConfig.currentPage - 1,
              },
            }))
          : refetch();
      } catch (e) {
        throw e;
      }
    },
    [
      listState.paginationConfig,
      onDelete$,
      refetch,
      response.results.length,
      setListState,
    ],
  );
  const columnsConfig = useMemo(
    () => getColumnsConfig(handleDelete$),
    [handleDelete$],
  );
  const onUploaded = useCallback(() => {
    const shouldRevertListState = shouldRevertListStateAfterMutation(
      listState.paginationConfig,
      listState.searchQuery,
    );

    return shouldRevertListState
      ? setListState((prevState) => ({
          ...prevState,
          paginationConfig: { ...prevState.paginationConfig, currentPage: 1 },
          searchQuery: INITIAL_LIST_STATE.searchQuery,
        }))
      : refetch();
  }, [
    listState.paginationConfig,
    listState.searchQuery,
    refetch,
    setListState,
  ]);
  const handleSelectedRowsActionComponent = useCallback(
    (rows: AttachmentSerializerDTO[]) => (
      <SelectedRowsAction onDelete$={handleDelete$} rows={rows} />
    ),
    [handleDelete$],
  );

  return (
    <AttachmentUploaderProvider onUpload$={onUpload$} onUploaded={onUploaded}>
      <GenericList
        buttonsPanelComponent={onUpload$ && <ListButtonsPanel />}
        columnsConfig={columnsConfig}
        filterPanelComponent={
          <FilterPanel
            fieldsConfig={filterFieldsConfig}
            listState={listState}
            setListState={setListState}
          />
        }
        isFetching={isFetching}
        listState={listState}
        model={response.results}
        modelKey="uuid"
        selectedRowsActionComponent={handleSelectedRowsActionComponent}
        setListState={setListState}
      />
    </AttachmentUploaderProvider>
  );
};
