import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    height: '100%',
    pb: [20, 20, 20, 0],
    width: '100%',
  },
  dot: {
    backgroundColor: (theme) => theme.palette.grey[700],
    borderRadius: '50%',
    height: 10,
    left: 0,
    position: 'absolute',
    top: -3,
    width: 10,
  },
  info: {
    ml: [-4, -4, -4, 0],
    mt: [10, 10, 10, 1],
    transform: ['rotate(45deg)', 'rotate(45deg)', 'rotate(45deg)', 'unset'],
  },
  point: {
    position: 'absolute',
    width: 200,
  },
  text: {
    '& > svg': {
      mr: 1,
    },
    display: 'flex',
  },
  timeline: {
    backgroundColor: (theme) => theme.palette.grey[700],
    height: 4,
    position: 'relative',
    width: '100%',
  },
};
