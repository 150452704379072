import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    mt: 1,
  },
  listWrapper: {
    maxHeight: '30vh',
  },
};
