import { ReactComponent as ArrowRightIcon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-right.svg';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiIcon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC, MouseEvent } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-hot-toast';

import type { CountrySerializerDTO } from '../../../../../connectors/company';
import type { UniversalTicketSerializerDTO } from '../../../../../connectors/ticket';
import { TicketStatusEnumDTO } from '../../../../../connectors/ticket';
import type { Country } from '../../../../shared';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CustomErrorType,
  useTranslation,
} from '../../../../shared';
import { getStatusTranslationLabelKey } from '../../consts';
import { useTicketConfig, useTransition } from '../../providers';
import { sxProps } from './status-switcher.styles';

type Props = {
  country: Country | CountrySerializerDTO['uuid'];
  id: UniversalTicketSerializerDTO['uuid'];
  status: TicketStatusEnumDTO | undefined;
};

export const StatusSwitcher: FC<Props> = (props) => {
  const { country, id, status } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { getCountryConfig } = useTicketConfig();
  const countryConfig = useMemo(
    () => getCountryConfig(country),
    [country, getCountryConfig],
  );
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState<TicketStatusEnumDTO>(
    status || TicketStatusEnumDTO.Open,
  );
  const [isDisabled, setDisabled] = useState(false);
  const { changeStatus$ } = useTransition();
  const Icon = useMemo(
    () => (isOpen ? ChevronUpIcon : ChevronDownIcon),
    [isOpen],
  );
  const labelKey = useMemo(() => getStatusTranslationLabelKey(value), [value]);
  const selectSxProps = useMemo(
    () => ({
      ...sxProps.select,
      ...sxProps[value],
      ...(isDisabled && sxProps.disabled),
    }),
    [isDisabled, value],
  );
  const options = useMemo(
    () => countryConfig.statusesWorkflowOptions[value] || [],
    [countryConfig.statusesWorkflowOptions, value],
  );
  const handleBoxClick: BoxProps['onClick'] = useCallback((event) => {
    event.stopPropagation();
    setOpen((prevState) => !prevState);
  }, []);
  const handleClose = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setOpen(false);
  }, []);
  const onItemClick = useCallback(
    async (
      event: MouseEvent<HTMLLIElement>,
      value: TicketStatusEnumDTO,
    ): Promise<void> => {
      event.stopPropagation();
      setDisabled(true);

      try {
        await changeStatus$([id], value);
        setValue(value);
      } catch (e) {
        if (e.message === CustomErrorType.ACTION_CANCELED) return;

        toast.error(t('errors.general.message'));
      } finally {
        setOpen(false);
        setDisabled(false);
      }
    },
    [changeStatus$, id, t],
  );

  useEffect(() => {
    if (!status) return;

    setValue(status);
  }, [status]);

  return (
    <Box key={id} sx={sxProps.container}>
      <Box
        data-testid="status-switcher"
        ref={ref}
        sx={selectSxProps}
        onClick={handleBoxClick}
      >
        <Typography color="primary.contrastText" sx={sxProps.text}>
          {t(labelKey)}
        </Typography>
        <Icon sx={sxProps.icon} />
      </Box>
      <Menu
        anchorEl={ref.current}
        MenuListProps={{
          sx: sxProps.menu,
        }}
        onClose={handleClose}
        open={isOpen}
        sx={sxProps.popover}
      >
        {options.map((status) => {
          const { labelKey, value } = status;

          return (
            <MenuItem
              disabled={isDisabled}
              key={value}
              onClick={(event) => onItemClick(event, value)}
              sx={sxProps.menuItem}
            >
              <Grid alignContent="center" container wrap="nowrap">
                <Grid item>
                  <MuiIcon sx={sxProps.transitionIcon}>
                    <ArrowRightIcon height={16} width={16} />
                  </MuiIcon>
                </Grid>
                <Grid
                  item
                  sx={{ ...sxProps.chip, ...sxProps[value] } as SxProps}
                >
                  <Typography sx={sxProps.menuItemText} variant="button">
                    {t(labelKey || '')}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
