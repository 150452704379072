import { isObject } from 'lodash-es';
import isEmpty from 'lodash-es/isEmpty';

import type { BackendErrorType } from '../../errors';
import { getErrorTypeTranslationLabelKey } from '../../errors';
import type { FormikErrorValues } from '../types';

export const getFirstErrorKey = (errors?: FormikErrorValues): string => {
  if (!errors) {
    return '';
  }

  return Array.isArray(errors)
    ? (errors[0] as GenericTypes.TranslationLabel)
    : (errors as GenericTypes.TranslationLabel);
};

export const transformFieldErrors = (
  errors?:
    | BackendErrorType[]
    | Record<string, BackendErrorType[] | Record<string, BackendErrorType[]>>,
  keyPrefix?: string,
): Record<string, GenericTypes.TranslationLabel[]> => {
  if (!errors || isEmpty(errors)) return {};

  if (Array.isArray(errors)) {
    return {
      nonFieldErrors: errors.map((error) =>
        getErrorTypeTranslationLabelKey(error),
      ),
    };
  }

  return Object.entries(errors).reduce((errors, [key, fieldErrors]) => {
    const fieldName = keyPrefix ? keyPrefix + key : key;
    const isArrayError = Array.isArray(fieldErrors);
    const isObjectError = !Array.isArray(fieldErrors) && isObject(fieldErrors);

    return {
      ...errors,
      ...(isArrayError && {
        [fieldName]: fieldErrors.map((error) =>
          getErrorTypeTranslationLabelKey(error),
        ),
      }),
      ...(isObjectError && transformFieldErrors(fieldErrors, key)),
      ...(!isArrayError &&
        !isObject(fieldErrors) && { [fieldName]: fieldErrors }),
    };
  }, {});
};

export const parseGlobalError = (
  error: BackendErrorType | BackendErrorType[],
): GenericTypes.TranslationLabel | undefined => {
  if (!error || isEmpty(error)) return undefined;

  return Array.isArray(error)
    ? getErrorTypeTranslationLabelKey(error[0])
    : getErrorTypeTranslationLabelKey(error);
};
