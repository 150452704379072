import { useFormikContext } from 'formik';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';

import type { UniversalTicketSerializerDTO } from '../../../../../connectors/ticket';
import { useTranslation } from '../../../../shared';
import type { PatchRequests } from '../../types';
import type { Values } from './department-switcher.model';

type Props = {
  callback$: (data: PatchRequests) => Promise<void>;
  department: UniversalTicketSerializerDTO['department'];
  setFetching: Dispatch<SetStateAction<boolean>>;
};

export const Effect: FC<Props> = (props) => {
  const { callback$, department, setFetching } = props;
  const { setFieldValue, values } = useFormikContext<Values>();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const { department: newDepartment } = values;

      if (!newDepartment || department === newDepartment) return;

      try {
        setFetching(true);
        // TODO: Remove assignee when the user and department will be linked - https://fredensborg.atlassian.net/browse/MB-486
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await callback$({ department: newDepartment });
      } catch (e) {
        setFieldValue('department', department);
        toast.error(t('errors.general.message'));
      } finally {
        setFetching(false);
      }
    })();
  }, [
    callback$,
    department,
    setFetching,
    setFieldValue,
    t,
    values,
    values.department,
  ]);

  return null;
};
