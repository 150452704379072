import type { ChangeEvent } from 'react';

import type { AttachmentFileType } from '../../../attachment';
import type { FileCategory, CommentFile } from './typer.type';

export const addAttachmentsToPreview = (
  e: ChangeEvent<HTMLInputElement>,
  category: FileCategory,
  handleImageAdd: (image: CommentFile) => void,
): void => {
  const { files } = e.currentTarget;

  if (!files) return;
  Array.from(files).forEach((file) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2 && reader.result) {
        handleImageAdd({
          category,
          data: reader.result,
          file,
          id: `${file.name}-${file.lastModified}`,
          name: file.name,
          type: file.type as AttachmentFileType,
        });
      }
    };
    reader.readAsDataURL(file);
  });
};

type AttachmentRemoveHandler = {
  fileBuffer: DataTransfer;
  newCommentsImagesArray: CommentFile[];
};

export const attachmentRemoveHandler = (
  attachments: FileList,
  attachmentsPreview: CommentFile[],
  idx: number,
): AttachmentRemoveHandler => {
  const fileBuffer = new DataTransfer();

  for (let i = 0; i < attachments.length; i += 1) {
    if (idx !== i) fileBuffer.items.add(attachments[i]);
  }
  const newCommentsImagesArray = [...attachmentsPreview];

  newCommentsImagesArray.splice(idx, 1);

  return { fileBuffer, newCommentsImagesArray };
};

type AttachmentAddHandler = {
  attachmentFiles: FileList | null;
  attachmentPreviews: CommentFile[];
};

export const attachmentAddHandler = (
  prevState: CommentFile[],
  attachment: CommentFile,
): AttachmentAddHandler => {
  let attachmentPreviews = [...prevState];
  let attachmentFiles = null;
  const isDuplicated = prevState.some((item) => item.id === attachment.id);

  if (!isDuplicated) {
    const dataTransfer = new DataTransfer();

    prevState.forEach((image) => {
      dataTransfer.items.add(image.file);
    });
    dataTransfer.items.add(attachment.file);
    attachmentFiles = dataTransfer.files;
    attachmentPreviews = [...attachmentPreviews, attachment];
  }

  return {
    attachmentFiles,
    attachmentPreviews,
  };
};
