import React, { useEffect, useMemo } from 'react';
import type { FC, ReactElement } from 'react';
import { toast } from 'react-hot-toast';
import { Navigate } from 'react-router-dom';

import { Spinner, useTranslation } from '../../../shared';
import type { Permission } from '../enums';
import { isPermitted as isPermittedHelper } from '../helpers';
import { useAuth } from '../state';

type Props = {
  children: ReactElement;
  fallbackRoute: string;
  permissions: Permission[];
};

export const PermissionGuard: FC<Props> = (props) => {
  const { children, fallbackRoute, permissions: expectedPermissions } = props;
  const { t } = useTranslation();
  const { isUserFetched, permissions } = useAuth();
  const isPermitted = useMemo(
    () => isPermittedHelper(expectedPermissions, permissions),
    [expectedPermissions, permissions],
  );

  useEffect(() => {
    if (!isUserFetched) return;

    if (!isPermitted) {
      toast.error(t('errors.general.notEnoughPermissions'));
    }
  }, [isPermitted, isUserFetched, t]);

  if (!isUserFetched) {
    return <Spinner />;
  }

  if (!isPermitted) {
    return <Navigate to={fallbackRoute} />;
  }

  return children;
};
