import { isTenant } from '../../../+user';
import {
  DepartmentTypeEnumDTO,
  EntityEnumDTO,
  PriorityTypeEnumDTO,
  TicketTypeEnumDTO,
} from '../../../../connectors/ticket';
import type { UserSerializerDTO } from '../../../../connectors/user';
import type { AsyncAutocompleteOption, SelectOption } from '../../../shared';
import { DEFAULT_ASYNC_AUTOCOMPLETE_VALUE, getUserName } from '../../../shared';
import {
  getDepartmentTypeTranslationLabelKey,
  getEntityTypeTranslationLabelKey,
  getPriorityTypeTranslationLabelKey,
  getTicketTypeTranslationLabelKey,
} from '../consts/translation-keys.const';
import { Reporter } from '../enums';
import type { RequestDetailsData } from '../types';

export const reporterTypeOptions: SelectOption<Reporter>[] = [
  { labelKey: 'ticket.fields.reporter.me', value: Reporter.ME },
  {
    labelKey: 'ticket.fields.reporter.tenant',
    value: Reporter.TENANT,
  },
];

export const departmentTypeOptions: SelectOption<DepartmentTypeEnumDTO>[] = [
  {
    labelKey: getDepartmentTypeTranslationLabelKey(
      DepartmentTypeEnumDTO.BackOffice,
    ),
    value: DepartmentTypeEnumDTO.BackOffice,
  },
  {
    labelKey: getDepartmentTypeTranslationLabelKey(
      DepartmentTypeEnumDTO.CollectionsDepartment,
    ),
    value: DepartmentTypeEnumDTO.CollectionsDepartment,
  },
  {
    labelKey: getDepartmentTypeTranslationLabelKey(
      DepartmentTypeEnumDTO.ContractAdministration,
    ),
    value: DepartmentTypeEnumDTO.ContractAdministration,
  },
  {
    labelKey: getDepartmentTypeTranslationLabelKey(
      DepartmentTypeEnumDTO.CustomerService,
    ),
    value: DepartmentTypeEnumDTO.CustomerService,
  },
  {
    labelKey: getDepartmentTypeTranslationLabelKey(DepartmentTypeEnumDTO.Hoa),
    value: DepartmentTypeEnumDTO.Hoa,
  },
  {
    labelKey: getDepartmentTypeTranslationLabelKey(
      DepartmentTypeEnumDTO.Operation,
    ),
    value: DepartmentTypeEnumDTO.Operation,
  },
  {
    labelKey: getDepartmentTypeTranslationLabelKey(
      DepartmentTypeEnumDTO.Technical,
    ),
    value: DepartmentTypeEnumDTO.Technical,
  },
];

export const entityTypeOptions: SelectOption[] = [
  EntityEnumDTO.Property,
  EntityEnumDTO.PropertyObject,
  EntityEnumDTO.Unit,
].map((type) => ({
  labelKey: getEntityTypeTranslationLabelKey(type),
  value: type,
}));

export const priorityTypeOptions: SelectOption<PriorityTypeEnumDTO>[] =
  Object.values(PriorityTypeEnumDTO).map((type) => ({
    labelKey: getPriorityTypeTranslationLabelKey(type),
    value: type,
  }));

export const ticketTypeOptions: SelectOption<TicketTypeEnumDTO>[] =
  Object.values(TicketTypeEnumDTO).map((type) => ({
    labelKey: getTicketTypeTranslationLabelKey(type),
    value: type,
  }));

export const getTenantFieldInitialValue = (
  data?: RequestDetailsData,
):
  | AsyncAutocompleteOption<UserSerializerDTO['uuid']>
  | typeof DEFAULT_ASYNC_AUTOCOMPLETE_VALUE =>
  data && data.reporter && isTenant(data.reporter.roles)
    ? {
        label: getUserName(data.reporter),
        value: data.reporter.uuid,
      }
    : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE;
