import type { UniversalTicketSerializerDTO } from '../../../../connectors/ticket';
import { TicketTypePath } from '../../paths.const';
import { ticketClient } from '../../ticket.client';
import type { DetailRequests } from '../types';

export const getDetailsRequest$ = (
  id: UniversalTicketSerializerDTO['uuid'],
  type: TicketTypePath,
): Promise<DetailRequests> => {
  switch (type) {
    case TicketTypePath.COMPLAINTS: {
      return ticketClient.getComplaintDetails$(id);
    }
    case TicketTypePath.CONTRACT_REQUESTS: {
      return ticketClient.getContractRequestDetails$(id);
    }
    case TicketTypePath.GENERAL_REQUESTS: {
      return ticketClient.getGeneralRequestDetails$(id);
    }
    case TicketTypePath.INVOICE_REQUESTS: {
      return ticketClient.getInvoiceRequestDetails$(id);
    }
    case TicketTypePath.SERVICE_REQUESTS: {
      return ticketClient.getServiceRequestDetails$(id);
    }
    default: {
      return Promise.reject();
    }
  }
};
