import { ReactComponent as AlarmBellIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alert/alarm-bell.svg';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import type { FC } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';

import type { NotificationSerializerDTO } from '../../../../connectors/notification';
import { NotificationStatusDTO } from '../../../../connectors/notification';
import { useRefHeight } from '../../hooks';
import { useTranslation } from '../../translations';
import { NotificationList } from '../NotificationList/notification-list.component';
import { NotificationsFooter } from '../NotificationsFooter/notifications-footer.component';
import { NotificationsHeader } from '../NotificationsHeader/notifications-header.component';
import type { QueryContentProps } from '../QueryContent';
import { QueryContent, QueryKey } from '../QueryContent';
import {
  NOTIFICATION_HEIGHT,
  NOTIFICATION_SPACES,
  sxProps,
} from './notification-bell.styles';
import { notificationClient } from './notification.client';

const REFETCH_INTERVAL = 30000;
const QUERY_OPTIONS: QueryContentProps<void>['options'] = {
  disableSpinner: true,
  refetchInterval: REFETCH_INTERVAL,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  retry: 3,
};

export const NotificationBell: FC = () => {
  const [isOpen, setOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [notifications, setNotifications] = useState<
    NotificationSerializerDTO[]
  >([]);
  const ref = useRef<HTMLButtonElement>(null);
  const [footerHeight, footerRef] = useRefHeight();
  const [headerHeight, headerRef] = useRefHeight();
  const { t } = useTranslation();
  const areNotificationsSeen = useMemo(
    () =>
      notifications.every(
        (notification) => notification.status === NotificationStatusDTO.Seen,
      ),
    [notifications],
  );
  const getData$ = useCallback(async () => {
    try {
      setDisabled(true);
      const response = await notificationClient.getList$();

      setNotifications(response.results);
    } catch (e) {
      toast.error(t('errors.general.message'));
    } finally {
      setDisabled(false);
    }
  }, [setDisabled, t]);

  const isEmpty = useMemo(() => notifications.length === 0, [notifications]);
  const listContainerHeight = useMemo(
    () =>
      NOTIFICATION_HEIGHT - headerHeight - footerHeight - NOTIFICATION_SPACES,
    [footerHeight, headerHeight],
  );

  return (
    <QueryContent
      getData$={getData$}
      options={QUERY_OPTIONS}
      queryKey={QueryKey.NOTIFICATIONS}
    >
      {() => (
        <>
          <Badge
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            color="primary"
            invisible={areNotificationsSeen}
            overlap="circular"
            sx={sxProps.badge}
            variant="dot"
          >
            <IconButton
              data-testid="button-notification"
              onClick={() => setOpen(true)}
              ref={ref}
              sx={sxProps.notificationBell}
            >
              <AlarmBellIcon height={24} />
            </IconButton>
          </Badge>
          <Popover
            anchorEl={ref.current}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            open={isOpen}
            onClose={() => setOpen(false)}
            PaperProps={{ sx: sxProps.paper }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <NotificationsHeader
              areNotificationsSeen={areNotificationsSeen}
              isDisabled={isDisabled}
              setDisabled={setDisabled}
              setNotifications={setNotifications}
              ref={headerRef}
            />
            <NotificationList
              containerHeight={listContainerHeight}
              notifications={notifications}
              setDisabled={setDisabled}
              setNotifications={setNotifications}
            />
            <NotificationsFooter
              isEmpty={isEmpty}
              isDisabled={isDisabled}
              setDisabled={setDisabled}
              setNotifications={setNotifications}
              ref={footerRef}
            />
          </Popover>
        </>
      )}
    </QueryContent>
  );
};
