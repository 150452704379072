import { gridClasses } from '@mui/material/Grid';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { TicketStatusEnumDTO } from '../../../../../connectors/ticket';
import { getStatusColorsHex } from '../../helpers';

export const sxProps: Record<string, SxProps<Theme>> = {
  column: {
    [`& > .${gridClasses.item}`]: {
      maxWidth: '100%',
    },
    minHeight: 500,
    pb: (theme) => theme.spacing(12),
  },
  columnNameBox: {
    borderRadius: (theme) => theme.spacing(1),
    color: (theme) => theme.palette.primary.contrastText,
    mb: 3,
    p: 0.5,
    width: '100%',
  },
  columnWrapper: {
    minWidth: 300,
  },
  container: {
    background: 'transparent',
  },
  disabledColumn: {
    opacity: 0.3,
  },
  [TicketStatusEnumDTO.Open]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.Open),
  },
  [TicketStatusEnumDTO.InProgress]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.InProgress),
  },
  [TicketStatusEnumDTO.AwaitingTenant]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        TicketStatusEnumDTO.AwaitingTenant,
      ),
  },
  [TicketStatusEnumDTO.AwaitingExternalProvider]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(
        theme.palette.mode,
        TicketStatusEnumDTO.AwaitingExternalProvider,
      ),
  },
  [TicketStatusEnumDTO.OnHold]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.OnHold),
  },
  [TicketStatusEnumDTO.Done]: {
    backgroundColor: (theme) =>
      getStatusColorsHex(theme.palette.mode, TicketStatusEnumDTO.Done),
  },
};
