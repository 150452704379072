import { ReactComponent as BusinessContractBreak } from '@heimstaden/icons-library/img/streamline-regular/business-products/business-deals/business-contract-break.svg';
import { ReactComponent as SettingsVerticalIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/controls/settings-vertical.svg';
import { ReactComponent as SmileyHappyIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-happy-1.svg';
import { ReactComponent as MonitorChartIcon } from '@heimstaden/icons-library/img/streamline-regular/programing-apps-websites/apps/monitor-chart.svg';
import { ReactComponent as RealEstateBuildingHousesIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/real-estate/real-estate-building-house.svg';
import { ReactComponent as RealEstatePersonSearchHouseIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/real-estate/real-estate-person-search-house.svg';
import { ReactComponent as PresentationStatisticsIcon } from '@heimstaden/icons-library/img/streamline-regular/work-office-companies/presentations/presentation-statistics.svg';
import { uniq } from 'lodash-es';

import type { Account } from '../+account';
import { ADVERTISEMENT_VIEW_PERMISSIONS } from '../+advertisement';
import { isPermitted } from '../+auth';
import { PROPERTY_VIEW_PERMISSIONS } from '../+property';
import { PROPERTY_OBJECT_VIEW_PERMISSIONS } from '../+property-object';
import { REPORT_PATHS } from '../+report';
import { TICKET_READ_PERMISSIONS } from '../+ticket';
import { UNIT_VIEW_PERMISSIONS } from '../+unit';
import type { NavSectionProps } from '../shared';
import { Country, isFeatureAvailable } from '../shared';
import { getEnvValue } from '../shared/helpers/env.helper';
import {
  getActivityLog,
  getAdvertisement,
  getCompany,
  getCustomerCare,
  getFacilities,
  getFilesLibrary,
  getMyAccount,
  getProperties,
  getPropertyObject,
  getSettings,
  getTermination,
  getUnits,
  getUserList,
} from './sections.const';

const environment = getEnvValue('ENV_NAME');

const VIEW_PORTFOLIO_PERMISSIONS = uniq([
  ...PROPERTY_VIEW_PERMISSIONS,
  ...PROPERTY_OBJECT_VIEW_PERMISSIONS,
  ...UNIT_VIEW_PERMISSIONS,
]);

export const getSections = (
  isUserFetched: boolean,
  user: Account | null,
  logout: () => void,
): NavSectionProps[] => [
  {
    dataTestId: 'report-section',
    icon: MonitorChartIcon,
    items: [
      {
        dataTestId: 'dashboard',
        path: REPORT_PATHS.DASHBOARD,
        titleKey: 'dashboard.section',
      },
    ],
    titleKey: 'sections.dashboard',
  },
  ...(isUserFetched
    ? [
        ...(isPermitted(VIEW_PORTFOLIO_PERMISSIONS, user?.permissions || [])
          ? [
              {
                dataTestId: 'portfolio-section',
                icon: RealEstateBuildingHousesIcon,
                items: [
                  ...getProperties(user),
                  ...getPropertyObject(user),
                  ...getUnits(user),
                  ...getFacilities(user),
                ],
                titleKey: 'sections.portfolio',
              },
            ]
          : []),
        ...(isFeatureAvailable([Country.PL], user?.countryUuids) &&
        isPermitted(ADVERTISEMENT_VIEW_PERMISSIONS, user?.permissions || [])
          ? [
              {
                dataTestId: 'letting',
                icon: RealEstatePersonSearchHouseIcon,
                items: [...getAdvertisement(user)],
                titleKey: 'sections.letting',
              },
            ]
          : []),
        ...(isPermitted(TICKET_READ_PERMISSIONS, user?.permissions || [])
          ? [
              {
                dataTestId: 'customer-care',
                icon: SmileyHappyIcon,
                items: [...getCustomerCare()],
                titleKey: 'sections.customerCare',
              },
            ]
          : []),
      ]
    : []),
  ...(environment === 'dev'
    ? [
        {
          dataTestId: 'termination-section',
          icon: BusinessContractBreak,
          items: [...getTermination()],
          titleKey: 'sections.termination',
        },
      ]
    : []),
  {
    dataTestId: 'organisation',
    icon: PresentationStatisticsIcon,
    items: [
      ...(isUserFetched
        ? [...getMyAccount(user), ...getUserList(user), ...getCompany(user)]
        : []),
    ],
    titleKey: 'sections.organisation',
  },
  {
    dataTestId: 'system',
    icon: SettingsVerticalIcon,
    items: [
      ...(isUserFetched
        ? [...getActivityLog(user), ...getFilesLibrary(user), ...getSettings()]
        : []),
      {
        dataTestId: 'button-logout',
        key: 'logoutButton',
        onClick: () => logout(),
        titleKey: 'logout.button',
      },
    ],
    titleKey: 'sections.system',
  },
];
