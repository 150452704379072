import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext } from 'react';

import type { Attachment } from './type';

export type ContextValue<Meta = unknown> = {
  attachments: Attachment<Meta>[];
  closeDialog: () => void;
  isDialogOpen: boolean;
  isLoading: boolean;
  openDialog: () => void;
  setAttachments: Dispatch<SetStateAction<Attachment<Meta>[]>>;
  upload$: () => Promise<void>;
  customForm?: ReactNode;
};

export const Context = createContext<ContextValue>({
  attachments: [],
  closeDialog: () => null,
  customForm: undefined,
  isDialogOpen: false,
  isLoading: false,
  openDialog: () => null,
  setAttachments: () => null,
  upload$: () => Promise.resolve(),
});
