import type { TFunction, TFunctionKeys } from 'i18next';
import { createContext } from 'react';

import type { LanguageDTO } from '../../../../connectors/company';
import { DEFAULT_LANGUAGE } from '../consts';

export type ContextValue = {
  changeLanguage: (language: LanguageDTO) => Promise<void>;
  langCode: LanguageDTO;
  t: TFunction;
};

export const Context = createContext<ContextValue>({
  changeLanguage: () => Promise.resolve(),
  langCode: DEFAULT_LANGUAGE,
  t: (key: TFunctionKeys) => key,
});
