import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  filterField: {
    '& *': {
      border: 'none',
    },
    '& div': {
      p: 0,
    },
    border: 'none',
    display: 'flex',
    flex: '1 1',
    fontWeight: 'bold',
    ml: 1,
    width: '100%',
  },
  filterFormControl: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
  },
};
