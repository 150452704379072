import { ReactComponent as NotesStarIcon } from '@heimstaden/icons-library/img/streamline-regular/content/notes/notes-star.svg';
import { ReactComponent as ResizeExpandSidesIcon } from '@heimstaden/icons-library/img/streamline-regular/design/resize/resize-expand-sides.svg';
import { ReactComponent as ImageFileAddIcon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/image-files/image-file-add.svg';
import { ReactComponent as MapsPin1Icon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin-1.svg';
import { ReactComponent as MapsPinIcon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin.svg';

import { leaseTypeOptions } from '../../../../+contract';
import { propertyClient } from '../../../../+property';
import { propertyObjectClient } from '../../../../+property-object';
import type {
  AttachmentVisibilityEnumDTO,
  PropConfigSerializerDTO,
} from '../../../../../connectors/company';
import type {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
  CommonProcessSerializersAttachmentAttachmentSerializerDTO as AttachmentSerializerDTO, // eslint-disable-line max-len
  CreateUnitSerializerDTO,
  DetailedUnitSerializerDTO,
  PatchUnitSerializerDTO,
  PropertyObjectSerializerDTO,
  UnitFeatureSerializerDTO,
  UnitSerializerDTO,
} from '../../../../../connectors/property';
import {
  ATTACHMENT_MAX_FILE_SIZE,
  attachmentConvertToFiles,
  attachmentSupportedImageTypeExtensions,
  DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
  DEFAULT_LOCATION_VALUE,
  formatStandardDateWrite,
  getAttributeFormFields,
  getAttributePayloadObject,
  getImagePreviewPath,
  proceedAttachmentsUpdate$,
  proceedAttachmentsUpload$,
} from '../../../../shared';
import type {
  AsyncAutocompleteOption,
  CheckboxValue,
  CustomFile,
  DEFAULT_FIELD_VALUE,
  FieldsetConfig,
  FileValue,
  FormProps,
  LocationValue,
  SpecificAttributeList,
} from '../../../../shared';
import { unitClient } from '../../../unit.client';
import {
  categoryOptions,
  featureOptions,
  getFeaturesCheckboxValue,
  UnitFeatureEnum,
} from '../../helpers';

export type Values = Omit<
  CreateUnitSerializerDTO | UnitSerializerDTO,
  'availableDateFrom' | 'features' | 'propertyObjectUuid'
> & {
  attachments: FileValue;
  availableDateFrom: typeof DEFAULT_FIELD_VALUE;
  features: CheckboxValue<UnitFeatureEnum>;
  location: LocationValue;
  propertyObject: AsyncAutocompleteOption<PropertyObjectSerializerDTO['uuid']>;
};

export const getFields = (
  values?: UnitSerializerDTO,
  attachments?: AttachmentSerializerDTO[],
  propConfigs?: PropConfigSerializerDTO[],
): FieldsetConfig<Values>[] => [
  {
    fields: [
      {
        componentType: 'input',
        field: {
          initialValue: values?.name,
          name: 'name',
        },
        props: {
          labelKey: 'unit.fields.name',
          required: true,
          type: 'text',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.description,
          name: 'description',
        },
        props: {
          labelKey: 'unit.fields.description',
          required: true,
          type: 'textarea',
        },
      },
      // TODO improve types - props have dynamic keys/names
      //  for now we don't have good solution how to type them stronger
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...getAttributeFormFields(
        values?.props as SpecificAttributeList,
        propConfigs,
      ),
    ],
    helperTextKey: 'unit.fieldset.basic.helperText',
    icon: MapsPinIcon,
    key: 'basic-information',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'unit.fieldset.basic.title',
  },
  {
    fields: [
      {
        componentType: 'file',
        field: {
          initialValue: attachments
            ? attachmentConvertToFiles(attachments)
            : [],
          name: 'attachments',
        },
        props: {
          accept: attachmentSupportedImageTypeExtensions,
          maxSize: ATTACHMENT_MAX_FILE_SIZE,
        },
      },
    ],
    helperTextKey: 'unit.fieldset.gallery.helperText',
    icon: ImageFileAddIcon,
    key: 'gallery',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'unit.fieldset.gallery.title',
  },
  {
    fields: [
      {
        componentType: 'async-autocomplete',
        field: {
          initialValue: values?.propertyObject
            ? {
                label: values.propertyObject.name,
                value: values.propertyObject.uuid,
              }
            : DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
          name: 'propertyObject',
        },
        props: {
          callback$: (query) => propertyObjectClient.getSuggestions$(query),
          labelKey: 'unit.fields.propertyObject',
          nestedFieldsConfig: {
            callback$: (value) => propertyObjectClient.getDetails$(value),
            fields: [
              {
                componentType: 'input',
                field: {
                  initialValue: values?.postalCode,
                  name: 'postalCode',
                },
                props: {
                  labelKey: 'unit.fields.postalCode',
                  required: true,
                  type: 'text',
                },
              },
              {
                componentType: 'input',
                field: {
                  initialValue: values?.addressLine,
                  name: 'addressLine',
                },
                props: {
                  labelKey: 'unit.fields.addressLine',
                  required: true,
                  size: { lg: 8, md: 8, sm: 6, xl: 8, xs: 12 },
                  type: 'text',
                },
              },
              {
                componentType: 'input',
                field: {
                  initialValue: values?.streetNumber,
                  name: 'streetNumber',
                },
                props: {
                  labelKey: 'unit.fields.streetNumber',
                  required: true,
                  size: { lg: 2, md: 2, sm: 3, xl: 2, xs: 12 },
                  type: 'text',
                },
              },
              {
                componentType: 'input',
                field: {
                  initialValue: values?.unitNumber,
                  name: 'unitNumber',
                },
                props: {
                  labelKey: 'unit.fields.unitNumber',
                  required: true,
                  size: { lg: 2, md: 2, sm: 3, xl: 2, xs: 12 },
                  type: 'text',
                },
              },
              {
                componentType: 'location',
                field: {
                  initialValue: values
                    ? { lat: values?.latitude, lng: values?.longitude }
                    : DEFAULT_LOCATION_VALUE,
                  name: 'location',
                },
                props: {
                  getInfoData: (formValues) => {
                    const {
                      addressLine,
                      attachments,
                      name,
                      postalCode,
                      streetNumber,
                      unitNumber,
                    } = formValues;
                    const address = [
                      `${addressLine ? `${addressLine} ` : ''}${
                        streetNumber ? `${streetNumber}` : ''
                      }${unitNumber ? `/${unitNumber}` : ''}`,
                      postalCode,
                    ]
                      .filter((a) => a)
                      .join(', ');
                    const thumbnailUrl = attachments[0]
                      ? getImagePreviewPath(attachments[0])
                      : '/assets/thumbnail_unit_placeholder.svg';

                    return {
                      address,
                      name,
                      thumbnailUrl,
                    };
                  },
                  pinUrl: '/assets/pin_unit.svg',
                  tipKey: 'unit.fields.position.tip',
                },
              },
            ],
            getFormFieldsToResponseMap: (
              response: PropertyObjectSerializerDTO,
            ) => ({
              addressLine: response.addressLine,
              location: { lat: response.latitude, lng: response.longitude },
              postalCode: response.postalCode,
              streetNumber: response.streetNumber,
            }),
            spacing: 3,
          },
          relatedFields: ['addressLine', 'postalCode', 'streetNumber'],
          required: true,
        },
      },
    ],
    helperTextKey: 'unit.fieldset.localisation.helperText',
    icon: MapsPin1Icon,
    key: 'localisation',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'unit.fieldset.localisation.title',
  },
  {
    fields: [
      {
        componentType: 'select',
        field: {
          initialValue: values?.category,
          name: 'category',
        },
        props: {
          labelKey: 'unit.fields.category.label',
          options: categoryOptions,
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.grossArea,
          name: 'grossArea',
        },
        props: {
          labelKey: 'unit.fields.grossArea',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.netArea,
          name: 'netArea',
        },
        props: {
          labelKey: 'unit.fields.netArea',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.rooms,
          name: 'rooms',
        },
        props: {
          labelKey: 'unit.fields.rooms',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.bedrooms,
          name: 'bedrooms',
        },
        props: {
          labelKey: 'unit.fields.bedrooms',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.floor,
          name: 'floor',
        },
        props: {
          labelKey: 'unit.fields.floor',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.rentalPrice,
          name: 'rentalPrice',
        },
        props: {
          labelKey: 'unit.fields.rentalPrice',
          required: true,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'date',
        field: {
          initialValue: values?.acquisitionDate,
          name: 'acquisitionDate',
        },
        props: {
          labelKey: 'unit.fields.acquisitionDate',
          maxDate: new Date(),
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
      {
        componentType: 'date',
        field: {
          initialValue: values?.lastRenovated,
          name: 'lastRenovated',
        },
        props: {
          labelKey: 'unit.fields.lastRenovated',
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
      {
        componentType: 'select',
        field: {
          initialValue: values?.rentPrincipal,
          name: 'rentPrincipal',
        },
        props: {
          labelKey: 'unit.fields.rentPrincipal',
          options: leaseTypeOptions,
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.extraPrice,
          name: 'extraPrice',
        },
        props: {
          labelKey: 'unit.fields.extraPrice',
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'number',
        },
      },
      {
        componentType: 'input',
        field: {
          initialValue: values?.extraPriceDescription,
          name: 'extraPriceDescription',
        },
        props: {
          labelKey: 'unit.fields.extraPriceDescription',
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
          type: 'textarea',
        },
      },
      {
        componentType: 'switch',
        field: {
          initialValue: values?.lettable,
          name: 'lettable',
        },
        props: {
          labelKey: 'unit.fields.lettable',
          size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
        },
      },
    ],
    helperTextKey: 'unit.fieldset.additional.helperText',
    icon: ResizeExpandSidesIcon,
    key: 'additional-information',
    size: { lg: 6, md: 12, sm: 12, xl: 6, xs: 12 },
    titleKey: 'unit.fieldset.additional.title',
  },
  {
    fields: [
      {
        componentType: 'checkbox',
        field: {
          initialValue: getFeaturesCheckboxValue(values?.features),
          name: 'features',
        },
        props: {
          labelKey: '',
          options: featureOptions,
          optionSize: { lg: 6, md: 6, sm: 6, xl: 6, xs: 12 },
        },
      },
    ],
    helperTextKey: 'unit.fieldset.features.helperText',
    icon: NotesStarIcon,
    key: 'features',
    size: { lg: 6, md: 6, sm: 12, xl: 6, xs: 12 },
    titleKey: 'unit.fieldset.features.title',
  },
];

export const getConfig = (
  data?: DetailedUnitSerializerDTO,
  existingAttachments: AttachmentSerializerDTO[] = [],
  propConfigs: PropConfigSerializerDTO[] = [],
): FormProps<Values>['config'] => ({
  onSubmit: async (values) => {
    const {
      acquisitionDate,
      attachments,
      features: formFeatures,
      lastRenovated,
      location,
      propertyObject,
      ...rest
    } = values;

    if (!location || !propertyObject) return Promise.reject();

    const uploadAttachments$ =
      (id: UnitSerializerDTO['uuid']) =>
      (
        category: AttachmentCategoryEnumDTO,
        type: AttachmentTypeEnumDTO,
        file: CustomFile,
        visibility: AttachmentVisibilityEnumDTO,
      ): Promise<AttachmentSerializerDTO[]> =>
        unitClient.uploadAttachment$(category, file, type, id, visibility);
    const updateAttachment$ =
      (id: UnitSerializerDTO['uuid']) =>
      (attachment: AttachmentSerializerDTO): Promise<AttachmentSerializerDTO> =>
        unitClient.updateAttachment$(attachment, id);
    const removeAttachment$ = (
      attachmentId: AttachmentSerializerDTO['uuid'],
    ): Promise<void> =>
      data
        ? unitClient.deleteAttachment$(data.uuid, attachmentId)
        : Promise.resolve();
    const payload: CreateUnitSerializerDTO | PatchUnitSerializerDTO = {
      ...rest,
      acquisitionDate: acquisitionDate
        ? formatStandardDateWrite(acquisitionDate)
        : undefined,
      features: Object.values(UnitFeatureEnum).reduce(
        (features, feature) => ({
          ...features,
          [feature]: formFeatures.includes(feature),
        }),
        {} as UnitFeatureSerializerDTO,
      ),
      lastRenovated: lastRenovated
        ? formatStandardDateWrite(lastRenovated)
        : undefined,
      latitude: location.lat,
      longitude: location.lng,
      propertyObjectUuid: propertyObject.value.toString(),
      props: getAttributePayloadObject(values, propConfigs),
    };

    if (data) {
      const [details] = await Promise.all([
        unitClient.update$(data.uuid, payload),
        proceedAttachmentsUpdate$(
          { allAttachments: attachments, existingAttachments },
          {
            handleRemove$: removeAttachment$,
            handleSequence$: propertyClient.updateAttachmentsSequence$,
            handleUpdate$: updateAttachment$(data.uuid),
            handleUpload$: uploadAttachments$(data.uuid),
          },
        ),
      ]);

      return details;
    }

    const details = await unitClient.create$(
      payload as CreateUnitSerializerDTO,
    );

    await proceedAttachmentsUpload$(attachments, {
      handleUpload$: uploadAttachments$(details.uuid),
    });

    return details;
  },
});
