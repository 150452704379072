import { createSvgIcon } from '@mui/material/utils';
import React from 'react';

export const ArrowRightIcon = createSvgIcon(
  <svg
    height="16"
    width="16"
    viewBox=" 0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 0.75L16.22 11.47C16.3607 11.6105 16.4398 11.8012 16.4398
      12C16.4398 12.1988 16.3607 12.3895 16.22 12.53L5.5 23.25"
      stroke="black"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'ArrowRight',
);
