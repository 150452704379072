import { createContext } from 'react';

import type {
  ComplaintSerializerDTO,
  ContractRequestSerializerDTO,
  GeneralRequestSerializerDTO,
  InvoiceRequestSerializerDTO,
  ServiceRequestSerializerDTO,
  TicketStatusEnumDTO,
  UniversalTicketSerializerDTO,
} from '../../../../../connectors/ticket';

export type ContextValue = {
  changeStatus$: (
    ids: UniversalTicketSerializerDTO['uuid'][],
    status: TicketStatusEnumDTO,
  ) => Promise<
    (
      | ComplaintSerializerDTO
      | ContractRequestSerializerDTO
      | GeneralRequestSerializerDTO
      | InvoiceRequestSerializerDTO
      | ServiceRequestSerializerDTO
    )[]
  >;
};

export const Context = createContext<ContextValue>({
  changeStatus$: () => Promise.reject(),
});
