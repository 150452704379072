const PATHS = {
  ACTIVITY_LOG_LIST: '/companies/:id/logs',
  CREATE: '/companies/create',
  DETAILS: '/companies/:id',
  DOCUMENT_LIST: '/companies/:id/documents',
  LIST: '/companies',
  PROPERTY_LIST: '/companies/:id/properties',
  PROPERTY_OBJECT_LIST: '/companies/:id/property-objects',
  UPDATE: '/companies/:id/update',
};

export default PATHS;
