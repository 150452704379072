import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<'box' | 'container', SxProps<Theme>> = {
  box: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  container: {
    maxWidth: '100%',
    position: 'relative',
  },
};
