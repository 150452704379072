import type { GridProps } from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FieldProps as FormikFieldProps } from 'formik/dist/Field';
import isFunction from 'lodash-es/isFunction';
import type { FC } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { InvisibleDragImage, Spinner } from '../../../components';
import {
  findDropColumnIndex,
  findTouchDropColumnIndex,
} from '../../../helpers';
import { useApi } from '../../../hooks';
import { useTranslation } from '../../../translations';
import {
  GalleryBrowser,
  GalleryDescription,
  GalleryImage,
} from '../../components';
import { getFirstErrorKey } from '../../helpers';
import type { GalleryItem, GalleryProps } from '../types';

type Props<TFormValues> = FormikFieldProps<GalleryItem[], TFormValues> & {
  props: GalleryProps<TFormValues>;
};

// TODO: introduce disabled and readonly state
export const GalleryField = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const { field, form, meta, props: fieldProps } = props;
  const { error, value } = meta;
  const { setFieldValue, values } = form;
  const { name } = field;
  const { callback$, helperTextKey, labelKey, required, tabs } = fieldProps;
  const { t } = useTranslation();
  const invisibleDragImage = useRef<HTMLImageElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [currentDrag, setCurrentDrag] = useState<GalleryItem | null>(null);
  const errorMessageKey = useMemo(() => getFirstErrorKey(error), [error]);
  const finalLabelKey = useMemo(
    () => (isFunction(labelKey) ? labelKey(values) : labelKey),
    [labelKey, values],
  );
  const fetchItems$ = useCallback(async () => {
    if (!callback$) return [];

    const response = await callback$();

    setFieldValue(name, response);

    return response;
  }, [callback$, name, setFieldValue]);
  const { isFetching } = useApi<GalleryItem[]>([], fetchItems$);
  const selectedItems = useMemo(
    () => value.filter((item) => item.isSelected),
    [value],
  );
  const unselectedItems = useMemo(
    () => value.filter((item) => !item.isSelected),
    [value],
  );
  const removeItem = useCallback(
    (id: GalleryItem['id']) => {
      const newValue = value.map((item) => ({
        ...item,
        isSelected: item.isSelected && item.id !== id,
      }));

      setFieldValue(name, newValue);
    },
    [name, setFieldValue, value],
  );
  const selectItem = useCallback(
    (id: GalleryItem['id']) => {
      const newValue = value.map((item) => ({
        ...item,
        isActive: item.id === id,
      }));

      setFieldValue(name, newValue);
    },
    [name, setFieldValue, value],
  );
  const deactivateItems = useCallback(
    () =>
      setFieldValue(
        name,
        value.map((item) => ({ ...item, isActive: false })),
      ),
    [name, setFieldValue, value],
  );
  const selectActiveItems = useCallback(
    () =>
      setFieldValue(
        name,
        value.map((item) => ({
          ...item,
          isActive: false,
          isSelected: item.isSelected || item.isActive,
        })),
      ),
    [name, setFieldValue, value],
  );
  const handleSwap = useCallback(
    (currentDrag: GalleryItem, positionIndex: number) => {
      if (positionIndex === -1) return;

      const itemsWithoutCurrentDrag = selectedItems.filter(
        (item) => item.id !== currentDrag.id,
      );
      const left = itemsWithoutCurrentDrag.slice(0, positionIndex);
      const right = itemsWithoutCurrentDrag.slice(positionIndex);

      setFieldValue(name, [...left, currentDrag, ...right, ...unselectedItems]);
    },
    [name, selectedItems, setFieldValue, unselectedItems],
  );
  const handleDragOver: GridProps['onDragOver'] = useCallback(
    (event) => event.preventDefault(),
    [],
  );
  const handleDrop: GridProps['onDrop'] = useCallback(
    (event) => {
      setCurrentDrag(null);

      if (!currentDrag || !itemRefs?.current) return;

      const positionIndex = findDropColumnIndex(itemRefs.current, event);

      handleSwap(currentDrag, positionIndex);
    },
    [currentDrag, handleSwap],
  );
  const handleTouchDrop = useCallback(
    (position: DOMRect) => {
      setCurrentDrag(null);

      if (!currentDrag || !itemRefs?.current) return;

      const positionIndex = findTouchDropColumnIndex(
        itemRefs.current,
        position,
      );

      handleSwap(currentDrag, positionIndex);
    },
    [currentDrag, handleSwap],
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {finalLabelKey && (
          <Typography variant="h2" marginBottom={1}>
            {t(finalLabelKey)}
          </Typography>
        )}
        {helperTextKey && <Typography>{t(helperTextKey)}</Typography>}
        <GalleryDescription />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          spacing={3}
        >
          <InvisibleDragImage ref={invisibleDragImage} />
          {isFetching ? (
            <Grid item xs={12}>
              <Spinner />
            </Grid>
          ) : (
            <>
              {selectedItems.map((item, idx) => (
                <GalleryImage
                  currentDrag={currentDrag}
                  dragImageElement={invisibleDragImage.current}
                  isDragAllowed
                  isRemoveAllowed={required ? idx > 0 : true}
                  isThumbnail={idx === 0}
                  item={item}
                  key={`gallery-image-${item.id}`}
                  ref={(el) => {
                    itemRefs.current[idx] = el;
                  }}
                  onTouchDrop={handleTouchDrop}
                  removeItem={removeItem}
                  selectItem={selectItem}
                  setCurrentDrag={setCurrentDrag}
                />
              ))}
              <GalleryBrowser
                deactivateItems={deactivateItems}
                items={value}
                selectActiveItems={selectActiveItems}
                selectItem={selectItem}
                tabs={tabs}
              />
            </>
          )}
          {errorMessageKey && (
            <Grid item xs={12}>
              <Typography color="error">{t(errorMessageKey)}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
