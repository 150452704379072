import { ReactComponent as MultipleActionsAddIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geometric-full-body-multiple-users-actions/multiple-actions-add.svg';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC, SetStateAction, Dispatch } from 'react';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';

import type { CountrySerializerDTO } from '../../../../../connectors/company';
import type { ContactSerializerDTO } from '../../../../../connectors/property';
import type { UserRoleEnumDTO } from '../../../../../connectors/user';
import type { AsyncAutocompleteOption, FormProps } from '../../../../shared';
import { CustomErrorType, Form, useTranslation } from '../../../../shared';
import type { Values } from '../../consts';
import { formConfig, getFieldsConfig } from '../../consts';
import { sxProps } from './assign.styles';

type Props = {
  assigningLabelKey: GenericTypes.TranslationLabel;
  isDisabled: boolean;
  isEditMode: boolean;
  onAssignee$: (
    option: NonNullable<AsyncAutocompleteOption<ContactSerializerDTO['uuid']>>,
    setEditMode: Dispatch<SetStateAction<boolean>>,
  ) => Promise<void>;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  country?: CountrySerializerDTO['uuid'];
  excludedRoles?: UserRoleEnumDTO[];
  suggestionRoles?: UserRoleEnumDTO[];
};

export const Assign: FC<Props> = (props) => {
  const {
    assigningLabelKey,
    country,
    excludedRoles,
    isDisabled,
    isEditMode,
    onAssignee$,
    setDisabled,
    setEditMode,
    suggestionRoles,
  } = props;
  const { t } = useTranslation();
  const fieldsConfig = useMemo(
    () => getFieldsConfig(country, suggestionRoles, excludedRoles, isDisabled),
    [country, excludedRoles, isDisabled, suggestionRoles],
  );
  const handleChange: FormProps<Values>['onChange'] = useCallback(
    async (values) => {
      if (!values.user) return;

      setDisabled(true);
      try {
        await onAssignee$(values.user, setEditMode);
      } catch (e) {
        if (e.message === CustomErrorType.PREVENT_TOAST_ERROR) return;

        toast.error(t('errors.general.message'));
      } finally {
        setDisabled(false);
      }
    },
    [onAssignee$, setDisabled, setEditMode, t],
  );

  return (
    <ClickAwayListener onClickAway={() => setEditMode(false)}>
      <Grid item>
        {isEditMode ? (
          <Form
            config={formConfig}
            onChange={handleChange}
            fieldsConfig={fieldsConfig}
            showButtons={false}
          />
        ) : (
          <Box onClick={() => setEditMode(true)} sx={sxProps.assigningBox}>
            <Box sx={sxProps.addUserIcon}>
              <MultipleActionsAddIcon height={24} width={24} />
            </Box>
            <Typography color="secondary.light" variant="subtitle2">
              {t(assigningLabelKey)}
            </Typography>
          </Box>
        )}
      </Grid>
    </ClickAwayListener>
  );
};
