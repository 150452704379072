import { ReactComponent as Check1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/check-1.svg';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { Spinner, useTranslation } from '../../../../../shared';
import type { StepProps } from '../status-stepper.type';
import { sxProps } from './default-step.styles';

export const DefaultStep: FC<StepProps> = (props) => {
  const {
    awaitingLabelKey,
    confirmedLabelKey,
    defaultLabelKey,
    index,
    isAwaiting,
    isConfirmed,
    status,
  } = props;
  const { t } = useTranslation();
  const isStepAwaiting = useMemo(
    () => isAwaiting.includes(status),
    [isAwaiting, status],
  );
  const isStepConfirmed = useMemo(
    () => isConfirmed.includes(status),
    [isConfirmed, status],
  );
  const isReadonly = useMemo(
    () => !isStepAwaiting && !isStepConfirmed,
    [isStepAwaiting, isStepConfirmed],
  );
  const testLabel = useMemo(
    () => (isStepConfirmed ? confirmedLabelKey : awaitingLabelKey),
    [awaitingLabelKey, confirmedLabelKey, isStepConfirmed],
  );
  const finalLabel = useMemo(
    () => (isReadonly ? defaultLabelKey : testLabel),
    [defaultLabelKey, isReadonly, testLabel],
  );
  const stepSxProps = {
    ...sxProps.step,
    ...(isStepConfirmed && sxProps.checkedStep),
  } as SxProps;

  return (
    <Box sx={sxProps.container}>
      {isStepAwaiting ? (
        <Box sx={sxProps.spinner}>
          <Spinner color="inherit" size={38} />
        </Box>
      ) : (
        <Box sx={stepSxProps}>
          {isStepConfirmed && (
            <Icon component={Check1Icon} sx={sxProps.checkedIcon} />
          )}
          {isReadonly && <Typography>{index + 1}</Typography>}
        </Box>
      )}
      <Typography fontWeight="bold">{t(finalLabel)}</Typography>
    </Box>
  );
};
