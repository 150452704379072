import { ReactComponent as SingleNeutralSearchIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-search.svg';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import type { CountrySerializerDTO } from '../../../../connectors/company';
import type {
  UserRoleEnumDTO,
  UserSerializerDTO,
} from '../../../../connectors/user';
import type {
  FieldConfig,
  AsyncAutocompleteOption,
  FormProps,
} from '../../../shared';
import { DEFAULT_ASYNC_AUTOCOMPLETE_VALUE } from '../../../shared/form/form.const';
import { userClient } from '../../user.client';

const sxProps: Record<string, SxProps<Theme>> = {
  adornmentIcon: {
    backgroundColor: (theme) => theme.palette.grey[200],
    borderRadius: '50%',
    height: 'unset',
    maxHeight: 'unset',
    p: 1,
    pr: 1,
  },
  field: {
    [`& .${outlinedInputClasses.root}`]: {
      borderRadius: 16,
      p: 0.5,
    },
  },
};

export type Values = {
  user:
    | AsyncAutocompleteOption<UserSerializerDTO['uuid']>
    | typeof DEFAULT_ASYNC_AUTOCOMPLETE_VALUE;
};

export const getFieldsConfig = (
  country?: CountrySerializerDTO['uuid'],
  roles?: UserRoleEnumDTO[],
  excludedRoles?: UserRoleEnumDTO[],
  isDisabled?: boolean,
): FieldConfig<Values>[] => [
  {
    componentType: 'async-autocomplete',
    field: {
      initialValue: DEFAULT_ASYNC_AUTOCOMPLETE_VALUE,
      name: 'user',
    },
    props: {
      callback$: (query) =>
        country
          ? userClient.getEmployeeSuggestions$(
              query,
              country,
              undefined,
              roles,
              excludedRoles,
            )
          : Promise.resolve([]),
      disabled: isDisabled,
      fieldSx: sxProps.field,
      inputAdornmentSx: sxProps.adornmentIcon,
      placeholderKey: 'user.search.placeholder',
      startAdornmentIcon: SingleNeutralSearchIcon,
    },
  },
];

export const formConfig: FormProps<Values>['config'] = {
  onSubmit: () => {
    // do nothing
  },
};
