import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  container: {
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: (theme) => theme.spacing(2),
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  normal: {
    p: (theme) => theme.spacing(4),
  },
  small: {
    p: 1.5,
  },
};
