import { ReactComponent as CheckCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/check-circle.svg';
import { ReactComponent as SingleManActionsRefreshIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-users-actions-man/single-man-actions-refresh.svg';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC, ReactNode } from 'react';
import React, { useCallback, useState } from 'react';

import { CustomErrorType, Dialog, useTranslation } from '../../../../shared';
import { sxProps } from './department-disclaimer.styles';

type Props = {
  children: (onOpen: () => Promise<void>) => ReactNode;
};

export const DepartmentDisclaimer: FC<Props> = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [confirm$, setConfirm$] = useState<{
    reject: (reason?: Error) => void;
    resolve: () => void;
  }>();
  const handleOpenDialog$ = useCallback(async () => {
    setDialogOpen(true);

    return new Promise<void>((resolve, reject) => {
      setConfirm$({ reject, resolve });
    });
  }, []);
  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
    confirm$?.reject(new Error(CustomErrorType.ACTION_CANCELED));
  }, [confirm$]);
  const handleConfirm = useCallback(() => {
    confirm$?.resolve();
    setDialogOpen(false);
  }, [confirm$]);

  return (
    <>
      {children(handleOpenDialog$)}
      <Dialog
        enableActions={false}
        isOpen={isDialogOpen}
        maxWidth="xs"
        onClose={handleCloseDialog}
      >
        <Grid alignItems="center" container direction="column" spacing={2}>
          <Grid item>
            <SingleManActionsRefreshIcon height={64} width={64} />
          </Grid>
          <Grid item>
            <Typography variant="h4">
              {t('ticket.disclaimer.department.title')}
            </Typography>
          </Grid>
          <Grid alignSelf="flex-start" item>
            <Typography>{t('ticket.disclaimer.department.text')}</Typography>
          </Grid>
          <Grid alignSelf="center" item>
            <Button
              color="primary"
              onClick={handleConfirm}
              size="large"
              sx={sxProps.button}
              variant="contained"
            >
              <CheckCircleIcon height={20} width={20} />
              {t('ticket.disclaimer.department.button')}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
