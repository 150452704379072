import { ReactComponent as EmailActionUnreadIcon } from '@heimstaden/icons-library/img/streamline-regular/emails/email-actions/email-action-unread.svg';
import { ReactComponent as PhoneLandlineIcon } from '@heimstaden/icons-library/img/streamline-regular/phones-mobile-devices/phone/phone-landline.svg';
import MuiBox from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { ProspectDetailsReadOnlySerializerDTO } from '../../../../../connectors/property';
import { Box, getUserName } from '../../../../shared';
import { Verification } from '../Verification/verification.component';
import { sxProps } from './user-box.styles';

type Props = {
  prospect: ProspectDetailsReadOnlySerializerDTO;
};

export const UserBox: FC<Props> = (props) => {
  const { prospect } = props;
  const email = useMemo(() => prospect.user.email, [prospect.user.email]);
  const name = useMemo(() => getUserName(prospect.user), [prospect.user]);
  const phoneNumber = useMemo(
    () => prospect.user.phoneNumber,
    [prospect.user.phoneNumber],
  );

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <MuiBox sx={sxProps.illustration} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" marginBottom={0} variant="h2">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Link
                  align="center"
                  color="secondary"
                  href={`mailto:${email}`}
                  noWrap
                  underline="none"
                  variant="h3"
                >
                  <Icon
                    component={EmailActionUnreadIcon}
                    sx={sxProps.linkIcon}
                  />
                  {email}
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Link
                  align="center"
                  color="secondary"
                  href={`tel:${phoneNumber}`}
                  noWrap
                  underline="none"
                  variant="h3"
                >
                  <Icon component={PhoneLandlineIcon} sx={sxProps.linkIcon} />
                  {phoneNumber}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid display="flex" justifyContent="center">
            <Verification prospect={prospect} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
