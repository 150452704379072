import { createSearchParams, matchPath } from 'react-router-dom';

export const getPathSearchIndex = (path?: string): number => {
  const index = path?.indexOf('?');

  return index && index > 0 ? index : 0;
};

export const isExactPath = (pathname: string, path?: string): boolean =>
  path ? Boolean(matchPath({ end: false, path }, pathname)) : false;

export const areExactSearchParams = (
  searchParams: URLSearchParams,
  path?: string,
): boolean => {
  const pathSearchIndex = getPathSearchIndex(path);
  const pathSearch = path?.substring(pathSearchIndex) || '';
  const pathSearchParams = createSearchParams(pathSearch);

  // eslint-disable-next-line no-restricted-syntax
  for (const params of pathSearchParams) {
    const [key, value] = params;
    const pathSearchValue = searchParams.get(key);

    if (pathSearchValue && pathSearchValue !== value) {
      return false;
    }
  }

  return true;
};
