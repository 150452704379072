import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import type { UniversalTicketSerializerDTO } from '../../../../../connectors/ticket';
import {
  Box,
  CustomErrorType,
  Spinner,
  useAsync,
  useTranslation,
} from '../../../../shared';
import { isTicketDuplicated, isUnitEntity } from '../../helpers';
import { useEntityContact } from '../../hooks';
import type { DetailsData, PatchRequests, Requests } from '../../types';
import { AssigneeSwitcher } from '../AssigneeSwitcher/assignee-switcher.component';
import { CreatedBy } from '../CreatedBy/created-by.component';
import { DepartmentDisclaimer } from '../DepartmentDisclaimer/department-disclaimer.component';
import { DepartmentSwitcher } from '../DepartmentSwitcher/department-switcher.component';
import { ReportedBy } from '../ReportedBy/reported-by.component';
import { sxProps } from './users-box.styles';

type Props = {
  callback$: (
    id: UniversalTicketSerializerDTO['uuid'],
    data: PatchRequests,
  ) => Promise<Requests>;
  data: DetailsData;
};

export const UsersBox: FC<Props> = (props) => {
  const { callback$, data } = props;
  const { t } = useTranslation();
  const { isEntityContact, isFetching } = useEntityContact({
    id: data.entity.uuid,
    parentId: isUnitEntity(data.entity)
      ? data.entity.propertyObject.uuid
      : undefined,
    type: data.entity.type,
  });
  const { setResponse } = useAsync<Requests>();
  const handleUpdate$ = useCallback(
    async (values: PatchRequests) => {
      try {
        const response = await callback$(data.uuid, values);

        setResponse((prevState) => ({ ...prevState, ...response }));
      } catch (e) {
        if (isTicketDuplicated(e)) {
          toast.error(t('ticket.errors.duplicatedRequest'));

          throw new Error(CustomErrorType.PREVENT_TOAST_ERROR);
        }

        throw e;
      }
    },
    [callback$, data.uuid, setResponse, t],
  );

  return (
    <Box>
      <Grid container direction="row" spacing={1}>
        <ReportedBy ticketType={data.ticketType} user={data.reporter} />
        <Grid item xs={12}>
          <Divider sx={sxProps.divider} />
        </Grid>
        {data.createdBy && <CreatedBy user={data.createdBy} />}
        <Grid item xs={12}>
          <Divider sx={sxProps.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography
            color="text.primary"
            component="div"
            fontWeight="bold"
            marginBottom={3}
            variant="h5"
          >
            {t('ticket.fields.assignee')}
          </Typography>
          {isFetching ? (
            <Spinner />
          ) : (
            <>
              <DepartmentDisclaimer>
                {(onOpen) => (
                  <DepartmentSwitcher
                    callback$={handleUpdate$}
                    data={data}
                    onDisclaimerDialogOpen={
                      isEntityContact ? undefined : onOpen
                    }
                  />
                )}
              </DepartmentDisclaimer>
              <AssigneeSwitcher
                callback$={handleUpdate$}
                isReassignDialog={!isEntityContact}
                data={data}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
