import { ReactComponent as ArchitectureDoorIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as Camera1Icon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/cameras/camera-1.svg';
import { ReactComponent as SettingsToggleHorizontalIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/controls/settings-toggle-horizontal.svg';
import { ReactComponent as PaperWriteIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-edition/paper-write.svg';
import { ReactComponent as AccountingCoinsBillIcon } from '@heimstaden/icons-library/img/streamline-regular/money-payments-finance/money/accounting-coins-bill.svg';
import { ReactComponent as AdvertisingMegaphoneIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/advertising/advertising-megaphone.svg';

import type { FieldConfig, LocalizationConfig } from '../../../../shared';
import { AddressStep, getAddressStepFieldsetConfig } from './AddressStep';
import {
  DescriptionStep,
  getDescriptionStepFieldsetConfig,
} from './DescriptionStep';
import { GalleryStep, getGalleryStepFieldsetConfig } from './GalleryStep';
import {
  getIntegrationsStepFieldsetConfig,
  IntegrationsStep,
} from './IntegrationsStep';
import { getOptionsStepFieldsetConfig, OptionsStep } from './OptionsStep';
import { getPricingStepFieldsetConfig, PricingStep } from './PricingStep';
import type {
  DynamicValidationRules,
  FormValues,
  FormValueToValidationSchemaKeyMap,
  Step,
} from './form.types';

export const steps: Step[] = [
  {
    component: AddressStep,
    icon: ArchitectureDoorIcon,
    key: 'advertisement-address',
    labelKey: 'advertisement.create.steps.address',
    validationFieldNames: [
      'city',
      'country',
      'property',
      'propertyObject',
      'unit',
    ],
  },
  {
    component: IntegrationsStep,
    icon: AdvertisingMegaphoneIcon,
    key: 'advertisement-integrations',
    labelKey: 'advertisement.create.steps.integrations',
    validationFieldNames: ['integrations'],
  },
  {
    component: DescriptionStep,
    icon: PaperWriteIcon,
    key: 'advertisement-description',
    labelKey: 'advertisement.create.steps.description',
    validationFieldNames: ['description', 'summary'],
  },
  {
    component: GalleryStep,
    icon: Camera1Icon,
    key: 'advertisement-gallery',
    labelKey: 'advertisement.create.steps.gallery',
    validationFieldNames: ['attachments', 'virtualTourUrl'],
  },
  {
    component: PricingStep,
    icon: AccountingCoinsBillIcon,
    key: 'advertisement-pricing',
    labelKey: 'advertisement.create.steps.pricing',
    validationFieldNames: ['depositPrice', 'feesPrice', 'rentPrice'],
  },
  {
    component: OptionsStep,
    icon: SettingsToggleHorizontalIcon,
    key: 'advertisement-options',
    labelKey: 'advertisement.create.steps.options',
    validationFieldNames: [
      'availability',
      'availableDateFrom',
      'incomeVerification',
      'insurance',
      'minimalIncome',
      'verification',
    ],
  },
];

export const getFieldsConfig = (
  countries: LocalizationConfig['userCountries'],
  dynamicValidationRules?: DynamicValidationRules,
): FieldConfig<FormValues>[] =>
  [
    ...getAddressStepFieldsetConfig(countries),
    ...getIntegrationsStepFieldsetConfig([]),
    ...getDescriptionStepFieldsetConfig(dynamicValidationRules),
    ...getGalleryStepFieldsetConfig(undefined, dynamicValidationRules),
    ...getPricingStepFieldsetConfig(''),
    ...getOptionsStepFieldsetConfig(''),
  ].reduce<FieldConfig<FormValues>[]>(
    (acc, config) => [...acc, ...config.fields],
    [],
  );

export const formValueToValidationSchemaMap: FormValueToValidationSchemaKeyMap =
  {
    attachments: { max: 'maxImagesNumber', min: 'minImagesNumber' },
    description: {
      maxLength: 'maxDescriptionLength',
      minLength: 'minDescriptionLength',
    },
    title: { maxLength: 'maxTitleLength', minLength: 'minTitleLength' },
  };
