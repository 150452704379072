import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { IssueTypeEnumDTO } from '../../../../../connectors/ticket';
import { palette, useTranslation } from '../../../../shared';
import {
  getIssueTypeIcon,
  getIssueTypeTranslationLabelKey,
} from '../../consts';
import { sxProps } from './issue-type-cell.styles';

type Props = {
  type: IssueTypeEnumDTO;
};

export const IssueTypeCell: FC<Props> = (props) => {
  const { type } = props;
  const { t } = useTranslation();
  const Icon = useMemo(() => getIssueTypeIcon(type), [type]);
  const translationKey = useMemo(
    () => getIssueTypeTranslationLabelKey(type),
    [type],
  );

  return (
    <Box sx={sxProps.box}>
      {Icon && <Icon height={24} stroke={palette.silverChalice} width={24} />}
      <Typography variant="subtitle2">{t(translationKey)}</Typography>
    </Box>
  );
};
