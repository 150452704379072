import { Permission } from '../../../+auth/shared/enums/permission.enum';

export const CREATE_PERMISSIONS = [
  Permission.GLOBAL_WRITE,
  Permission.CAN_CREATE_ADVERTISEMENT,
];
export const UPDATE_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.CAN_UPDATE_ADVERTISEMENT,
];
export const VIEW_PERMISSIONS = [
  Permission.GLOBAL_READ,
  Permission.CAN_VIEW_ADVERTISEMENT,
];
