import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { UNIT_THUMBNAIL_PLACEHOLDER_PATH } from '../../../../+unit';
import type {
  ListAttachmentSerializerDTO,
  UnitAdvertisementSerializerDTO,
} from '../../../../../connectors/property';
import {
  GalleryPreview,
  getGalleryPreviewImages,
  useAsync,
  useTranslation,
} from '../../../../shared';
import { sxProps, THUMBNAIL_OFFSET, THUMBNAIL_SIZE } from './gallery.styles';

export const Gallery: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [thumbnailsCount, setThumbnailsCount] = useState(0);
  const [activeImageIdx, setActiveImageIdx] = useState(0);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const handleClick = useCallback(async (idx: number) => {
    setActiveImageIdx(idx);
    setPreviewOpen(true);
  }, []);
  const { response } =
    useAsync<[UnitAdvertisementSerializerDTO, ListAttachmentSerializerDTO]>();
  const [details, attachments] = response;
  const handleThumbnailsCount = useCallback(() => {
    if (!ref.current) return;

    const count = Math.floor(ref.current.offsetWidth / THUMBNAIL_SIZE);
    const offsetWidth = ref.current.offsetWidth - count * THUMBNAIL_OFFSET;

    setThumbnailsCount(Math.floor(offsetWidth / THUMBNAIL_SIZE));
  }, []);
  const mainImage = useMemo(() => {
    const thumbnail = attachments.results[0];

    return thumbnail?.url || UNIT_THUMBNAIL_PLACEHOLDER_PATH;
  }, [attachments.results]);
  const galleryImages = useMemo(
    () => getGalleryPreviewImages(attachments.results),
    [attachments.results],
  );
  const thumbnails = useMemo(() => {
    const images = [...attachments.results];

    images.shift();

    return images;
  }, [attachments.results]);
  const displayedThumbnails = useMemo(
    () =>
      thumbnails.length > thumbnailsCount
        ? [...thumbnails].splice(0, thumbnailsCount - 1)
        : thumbnails,
    [thumbnails, thumbnailsCount],
  );
  const restImagesCount = useMemo(
    () => thumbnails.length - displayedThumbnails.length,
    [displayedThumbnails.length, thumbnails.length],
  );

  useEffect(() => {
    if (attachments.count === 0) return undefined;

    handleThumbnailsCount();
    window.addEventListener('resize', handleThumbnailsCount);

    return (): void => {
      window.removeEventListener('resize', handleThumbnailsCount);
    };
  }, [handleThumbnailsCount, attachments.count]);

  return (
    <Box>
      <Box position="relative">
        <Typography fontWeight="bold" sx={sxProps.name}>
          {details.name}
        </Typography>
        <Box
          onClick={() => handleClick(0)}
          sx={{
            ...sxProps.mainImage,
            backgroundImage: `url(${mainImage})`,
          }}
        />
      </Box>
      {thumbnails.length > 0 && (
        <>
          <Box ref={ref} sx={sxProps.thumbnails}>
            {displayedThumbnails.map((thumbnail, idx) => (
              <Box
                key={thumbnail.uuid}
                onClick={() => handleClick(idx)}
                sx={{
                  ...sxProps.thumbnail,
                  backgroundImage: `url(${thumbnail.url})`,
                }}
              />
            ))}
            {restImagesCount > 0 && (
              <Box
                onClick={() => handleClick(displayedThumbnails.length)}
                sx={{ ...sxProps.thumbnail, ...sxProps.more } as SxProps}
              >
                <Typography fontWeight="bold" sx={sxProps.moreText}>
                  {t('advertisement.details.gallery.textMore', {
                    count: restImagesCount,
                  })}
                </Typography>
              </Box>
            )}
          </Box>
          <GalleryPreview
            activeIdx={activeImageIdx}
            images={galleryImages}
            open={isPreviewOpen}
            setActiveIdx={setActiveImageIdx}
            setOpen={setPreviewOpen}
          />
        </>
      )}
    </Box>
  );
};
