import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { CompanySerializerDTO } from '../../../../../connectors/company';
import { DEFAULT_AVATAR_URL } from '../../consts';

type Props = {
  name: CompanySerializerDTO['name'];
  avatarUrl?: GenericTypes.URL;
};

export const NameCell: FC<Props> = (props) => {
  const { avatarUrl, name } = props;
  const src = useMemo(() => avatarUrl || DEFAULT_AVATAR_URL, [avatarUrl]);

  return (
    <Grid alignItems="center" container spacing={1}>
      <Grid item>
        <Avatar src={src} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" fontWeight="bold">
          {name}
        </Typography>
      </Grid>
    </Grid>
  );
};
