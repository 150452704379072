import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';

import { RoomTypeEnumDTO } from '../../../../../../connectors/ticket';
import { FormField, useTranslation } from '../../../../../shared';
import { useTicketConfig } from '../../../providers';
import type { ServiceRequestValues } from '../../../types';
import type { StepComponentProps } from '../service-request-form.type';
import { getInitialIssueLocationValue } from './category-step.helper';
import { getFieldsConfig } from './category-step.model';

export const CategoryStep: FC<StepComponentProps> = (props) => {
  const { details } = props;
  const { config } = useTicketConfig();
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<ServiceRequestValues>();
  const countryTicketConfig = useMemo(
    () => config[values.country],
    [config, values.country],
  );
  const fieldsConfig = useMemo(
    () => getFieldsConfig(countryTicketConfig, details),
    [countryTicketConfig, details],
  );
  const initialIssueLocationValue = useMemo(
    () => getInitialIssueLocationValue(values.country, values.entityType),
    [values.country, values.entityType],
  );

  useEffect(() => {
    const options = countryTicketConfig.getRoomTypeOptions(
      values.issueLocation,
    );

    if (options[0]?.value === RoomTypeEnumDTO.None) {
      setFieldValue('roomWithIssue', RoomTypeEnumDTO.None);
    }
  }, [countryTicketConfig, setFieldValue, values.issueLocation]);

  useEffect(() => {
    if (details?.issueLocation || !initialIssueLocationValue) return;

    setFieldValue('issueLocation', initialIssueLocationValue);
  }, [details?.issueLocation, initialIssueLocationValue, setFieldValue]);

  return (
    <>
      <Typography variant="h5" marginBottom={0.5}>
        {t('ticket.fieldset.category.title')}
      </Typography>
      <Typography color="text.secondary" marginBottom={2}>
        {t('ticket.fieldset.category.text')}
      </Typography>
      <Grid container spacing={2}>
        {fieldsConfig.map((config) => (
          <FormField<ServiceRequestValues>
            config={config}
            key={`fieldset-field-${config.field.name}`}
            values={values}
          />
        ))}
      </Grid>
    </>
  );
};
