import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { FormFieldset } from '../../../../../shared/form/components';
import { useAdvertisementConfig } from '../../../providers';
import type { FormValues } from '../form.types';
import { getFieldsetConfig } from './integrations-step.model';

export const IntegrationsStep: FC = () => {
  const { values } = useFormikContext<FormValues>();
  const { getCountryConfig } = useAdvertisementConfig();
  const config = useMemo(
    () => getCountryConfig(values.country),
    [getCountryConfig, values.country],
  );
  const fieldsetConfig = useMemo(
    () => getFieldsetConfig(config.integrations),
    [config.integrations],
  );

  return (
    <Grid container spacing={3}>
      {fieldsetConfig.map((config) => (
        <FormFieldset<FormValues>
          config={config}
          key={`fieldset-${config.key}`}
          values={values}
        />
      ))}
    </Grid>
  );
};
