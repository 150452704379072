import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';

import type { Image } from '../../Carousel/carousel.type';
import { GalleryPreview } from '../../GalleryPreview';
import { Thumbnail } from '../../Thumbnail/thumbnail.component';
import { sxProps } from './comment-gallery.styles';

type Props = {
  images: Image[];
  isFetchingImages?: boolean;
};

export const CommentGallery: FC<Props> = (props) => {
  const { images, isFetchingImages } = props;
  const [activeImageIdx, setActiveImageIdx] = useState(0);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const handleClick = useCallback(async (idx: number) => {
    setActiveImageIdx(idx);
    setPreviewOpen(true);
  }, []);

  return (
    <>
      <Box sx={sxProps.container}>
        {isFetchingImages ? (
          <Box sx={sxProps.image}>
            <Skeleton variant="rectangular" width={100} height={100} />
          </Box>
        ) : (
          images?.map((image, idx) => (
            <Box
              key={image.id}
              onClick={() => handleClick(idx)}
              sx={sxProps.image}
            >
              <Thumbnail key={image.id} url={image.url} />
            </Box>
          ))
        )}
      </Box>
      {images.length > 0 && (
        <GalleryPreview
          activeIdx={activeImageIdx}
          images={images}
          open={isPreviewOpen}
          setActiveIdx={setActiveImageIdx}
          setOpen={setPreviewOpen}
        />
      )}
    </>
  );
};
