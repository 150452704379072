import ThemeProvider from '@mui/system/ThemeProvider';
import React from 'react';
import type { FC } from 'react';
import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';

import { useAuth } from './+auth';
import { getRoutes } from './routing';
import {
  CookieDisclaimer,
  createCustomTheme,
  getEnvValue,
  SplashScreen,
  useSettings,
} from './shared';

const environment = getEnvValue('ENV_NAME');

export const App: FC = () => {
  const { isInitialized } = useAuth();
  const routes = getRoutes();
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const theme = createCustomTheme({
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      {/* <SettingsDrawer /> // TODO: make dark mode great again! */}
      <Toaster position="top-center" />
      {isInitialized ? content : <SplashScreen />}
      {environment !== 'prod' ? <CookieDisclaimer /> : null}
    </ThemeProvider>
  );
};
