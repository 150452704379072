import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import type { TableSortLabelProps } from '@mui/material/TableSortLabel';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import type { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useMemo } from 'react';

import { useTranslation } from '../../../translations';
import type { ColumnConfig, ListState, SortConfig } from '../list.type';

type Props<T, TFilterValues> = {
  columnsConfig: ColumnConfig<T>[];
  isRowsDisabled: boolean;
  isSelectingRowsEnabled: boolean;
  model: T[];
  selectedRows: T[];
  setSelectedRows: Dispatch<SetStateAction<T[]>>;
  listState?: ListState<TFilterValues>;
  setListState?: Dispatch<SetStateAction<ListState<TFilterValues>>>;
};

export const Header = <T, TFilterValues>(
  props: Props<T, TFilterValues>,
): ReturnType<FC<Props<T, TFilterValues>>> => {
  const {
    columnsConfig,
    isRowsDisabled,
    isSelectingRowsEnabled,
    listState,
    model,
    selectedRows,
    setListState,
    setSelectedRows,
  } = props;
  const { t } = useTranslation();
  const sort = useMemo(() => listState?.sort, [listState?.sort]);
  const isActiveSortOption = useCallback(
    (sortConfig: SortConfig) =>
      Boolean(sort && (sortConfig.asc === sort || sortConfig.desc === sort)),
    [sort],
  );
  const getSortOptionDirection = useCallback(
    (sortConfig: SortConfig): TableSortLabelProps['direction'] => {
      if (!isActiveSortOption(sortConfig)) return undefined;

      return sortConfig.asc === sort ? 'asc' : 'desc';
    },
    [isActiveSortOption, sort],
  );
  const handleSortOptionClick = useCallback(
    (sortConfig?: SortConfig) => {
      if (!setListState || !sortConfig) return;

      const direction = getSortOptionDirection(sortConfig);
      const sort = direction === 'asc' ? sortConfig.desc : sortConfig.asc;

      setListState((prevState) => ({
        ...prevState,
        paginationConfig: {
          ...prevState.paginationConfig,
          currentPage: 1,
        },
        sort,
      }));
    },
    [getSortOptionDirection, setListState],
  );
  const getContent = useCallback(
    (columnConfig: ColumnConfig<T>) => {
      const { labelIcon: Icon, labelKey } = columnConfig;

      if (Icon) return <Icon height={24} width={24} />;

      return labelKey ? (
        <Typography variant="subtitle2">{t(labelKey)}</Typography>
      ) : null;
    },
    [t],
  );
  const isCheckboxSelected = useMemo(
    () => model.length > 0 && selectedRows.length === model.length,
    [model.length, selectedRows.length],
  );
  const isCheckboxIndeterminate = useMemo(
    () => selectedRows.length > 0 && selectedRows.length < model.length,
    [model.length, selectedRows.length],
  );
  const handleSelectAllRowsClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isRowsDisabled) return;

      setSelectedRows(event.target.checked ? model : []);
    },
    [isRowsDisabled, model, setSelectedRows],
  );

  return (
    <TableHead>
      <TableRow>
        {isSelectingRowsEnabled && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isCheckboxSelected}
              color="primary"
              disabled={isRowsDisabled}
              indeterminate={isCheckboxIndeterminate}
              onChange={handleSelectAllRowsClick}
            />
          </TableCell>
        )}
        {columnsConfig.map((columnConfig: ColumnConfig<T>) => {
          const content = getContent(columnConfig);

          return (
            <TableCell align={columnConfig.align} key={`th-${columnConfig.id}`}>
              {content && columnConfig.sortConfig ? (
                <TableSortLabel
                  active={isActiveSortOption(columnConfig.sortConfig)}
                  direction={getSortOptionDirection(columnConfig.sortConfig)}
                  onClick={() => handleSortOptionClick(columnConfig.sortConfig)}
                >
                  {content}
                </TableSortLabel>
              ) : (
                content
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
