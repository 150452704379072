import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';

import storageService from '../../services/storage.service';
import { useTranslation } from '../../translations';
import { sxProps } from './cookie-disclaimer.styles';

export const CookieDisclaimer: FC = () => {
  const [isHidden, setHidden] = useState(storageService.isCookieAccepted());
  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    storageService.setCookie();
    setHidden(true);
  }, []);

  if (isHidden) return null;

  return (
    <Box sx={sxProps.disclaimerBox}>
      <Typography color="secondary.contrastText" mb={3} variant="body2">
        {t('cookie.text')}
      </Typography>
      <Box sx={sxProps.actionsBox}>
        <Box
          alt={t('cookie.text')}
          component="img"
          src="/assets/undraw_join_re_w1lh 1.svg"
          sx={sxProps.image}
        />
        <Button
          color="primary"
          onClick={handleClick}
          sx={sxProps.button}
          size="large"
          variant="contained"
        >
          {t('cookie.button')}
        </Button>
      </Box>
    </Box>
  );
};
