import { useFormikContext } from 'formik';
import type { FC } from 'react';
import { useEffect } from 'react';

import { EntityEnumDTO } from '../../../../../connectors/ticket';
import type { AsyncAutocompleteOption } from '../../../../shared';
import { Reporter } from '../../enums';
import type { Entity } from '../../types';

type EffectValues = {
  entity: AsyncAutocompleteOption<Entity['uuid']>;
  entityType: EntityEnumDTO;
  reporter: Reporter;
};

export const RequestFormEffect: FC = () => {
  const { setFieldValue, values } = useFormikContext<EffectValues>();

  useEffect(() => {
    if (
      values.entityType === EntityEnumDTO.Unit &&
      values.entity?.value &&
      values.reporter === Reporter.TENANT
    ) {
      setFieldValue('apartment', values.entity);
    }
  }, [setFieldValue, values.entity, values.entityType, values.reporter]);

  return null;
};
