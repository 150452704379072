export const DEFAULT_ASYNC_AUTOCOMPLETE_VALUE = null;
export const DEFAULT_CHECKBOX_VALUE = [];
export const DEFAULT_DATE_FIELD_VALUE = null;
export const DEFAULT_FIELD_VALUE = '';
export const DEFAULT_GALLERY_VALUE = [];
export const DEFAULT_LOCATION_VALUE = null;
export const DEFAULT_MULTIPLE_SELECT_VALUE = [];
export const DEFAULT_SELECT_OPTION = {
  labelKey: 'form.labels.defaultSelectOptionLabel',
  value: DEFAULT_FIELD_VALUE,
} as const;
