/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { EntityEnumDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { ListAttachmentSequenceSerializerDTO } from '../dto';
// @ts-ignore
import { PatchAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ResponseAttachmentSequenceSerializerDTO } from '../dto';
/**
 * GeneralAPI - axios parameter creator
 * @export
 */
export const GeneralAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Patch Attachment
         * @param {EntityEnumDTO} entityType 
         * @param {string} entityUuid 
         * @param {string} attachmentUuid 
         * @param {PatchAttachmentSerializerDTO} patchAttachmentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch: async (entityType: EntityEnumDTO, entityUuid: string, attachmentUuid: string, patchAttachmentSerializerDTO: PatchAttachmentSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch', 'entityType', entityType)
            // verify required parameter 'entityUuid' is not null or undefined
            assertParamExists('patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch', 'entityUuid', entityUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch', 'attachmentUuid', attachmentUuid)
            // verify required parameter 'patchAttachmentSerializerDTO' is not null or undefined
            assertParamExists('patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch', 'patchAttachmentSerializerDTO', patchAttachmentSerializerDTO)
            const localVarPath = `/general/{entity_type}/{entity_uuid}/attachments/{attachment_uuid}`
                .replace(`{${"entity_type"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entity_uuid"}}`, encodeURIComponent(String(entityUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchAttachmentSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Attachment Sequence
         * @param {ListAttachmentSequenceSerializerDTO} listAttachmentSequenceSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut: async (listAttachmentSequenceSerializerDTO: ListAttachmentSequenceSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'listAttachmentSequenceSerializerDTO' is not null or undefined
            assertParamExists('updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut', 'listAttachmentSequenceSerializerDTO', listAttachmentSequenceSerializerDTO)
            const localVarPath = `/general/attachments/sequence_numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listAttachmentSequenceSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralAPI - functional programming interface
 * @export
 */
export const GeneralAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Patch Attachment
         * @param {EntityEnumDTO} entityType 
         * @param {string} entityUuid 
         * @param {string} attachmentUuid 
         * @param {PatchAttachmentSerializerDTO} patchAttachmentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch(entityType: EntityEnumDTO, entityUuid: string, attachmentUuid: string, patchAttachmentSerializerDTO: PatchAttachmentSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch(entityType, entityUuid, attachmentUuid, patchAttachmentSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Attachment Sequence
         * @param {ListAttachmentSequenceSerializerDTO} listAttachmentSequenceSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut(listAttachmentSequenceSerializerDTO: ListAttachmentSequenceSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAttachmentSequenceSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut(listAttachmentSequenceSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralAPI - factory interface
 * @export
 */
export const GeneralAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralAPIFp(configuration)
    return {
        /**
         * 
         * @summary Patch Attachment
         * @param {EntityEnumDTO} entityType 
         * @param {string} entityUuid 
         * @param {string} attachmentUuid 
         * @param {PatchAttachmentSerializerDTO} patchAttachmentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch(entityType: EntityEnumDTO, entityUuid: string, attachmentUuid: string, patchAttachmentSerializerDTO: PatchAttachmentSerializerDTO, options?: any): AxiosPromise<AttachmentSerializerDTO> {
            return localVarFp.patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch(entityType, entityUuid, attachmentUuid, patchAttachmentSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Attachment Sequence
         * @param {ListAttachmentSequenceSerializerDTO} listAttachmentSequenceSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut(listAttachmentSequenceSerializerDTO: ListAttachmentSequenceSerializerDTO, options?: any): AxiosPromise<ResponseAttachmentSequenceSerializerDTO> {
            return localVarFp.updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut(listAttachmentSequenceSerializerDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch operation in GeneralAPI.
 * @export
 * @interface GeneralAPIPatchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatchRequest
 */
export interface GeneralAPIPatchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatchRequest {
    /**
     * 
     * @type {EntityEnumDTO}
     * @memberof GeneralAPIPatchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch
     */
    readonly entityType: EntityEnumDTO

    /**
     * 
     * @type {string}
     * @memberof GeneralAPIPatchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch
     */
    readonly entityUuid: string

    /**
     * 
     * @type {string}
     * @memberof GeneralAPIPatchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch
     */
    readonly attachmentUuid: string

    /**
     * 
     * @type {PatchAttachmentSerializerDTO}
     * @memberof GeneralAPIPatchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch
     */
    readonly patchAttachmentSerializerDTO: PatchAttachmentSerializerDTO
}

/**
 * Request parameters for updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut operation in GeneralAPI.
 * @export
 * @interface GeneralAPIUpdateAttachmentSequenceGeneralAttachmentsSequenceNumbersPutRequest
 */
export interface GeneralAPIUpdateAttachmentSequenceGeneralAttachmentsSequenceNumbersPutRequest {
    /**
     * 
     * @type {ListAttachmentSequenceSerializerDTO}
     * @memberof GeneralAPIUpdateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut
     */
    readonly listAttachmentSequenceSerializerDTO: ListAttachmentSequenceSerializerDTO
}

/**
 * GeneralAPI - object-oriented interface
 * @export
 * @class GeneralAPI
 * @extends {BaseAPI}
 */
export class GeneralAPI extends BaseAPI {
    /**
     * 
     * @summary Patch Attachment
     * @param {GeneralAPIPatchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralAPI
     */
    public patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch(requestParameters: GeneralAPIPatchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatchRequest, options?: any) {
        return GeneralAPIFp(this.configuration).patchAttachmentGeneralEntityTypeEntityUuidAttachmentsAttachmentUuidPatch(requestParameters.entityType, requestParameters.entityUuid, requestParameters.attachmentUuid, requestParameters.patchAttachmentSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Attachment Sequence
     * @param {GeneralAPIUpdateAttachmentSequenceGeneralAttachmentsSequenceNumbersPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralAPI
     */
    public updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut(requestParameters: GeneralAPIUpdateAttachmentSequenceGeneralAttachmentsSequenceNumbersPutRequest, options?: any) {
        return GeneralAPIFp(this.configuration).updateAttachmentSequenceGeneralAttachmentsSequenceNumbersPut(requestParameters.listAttachmentSequenceSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
