import { ReactComponent as ElectronicsRockerSwitchIcon } from '@heimstaden/icons-library/img/streamline-regular/computers-devices-electronics/electronics/electronics-rocker-switch.svg';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';

import type { UnitAdvertisementSerializerDTO } from '../../../../../connectors/property';
import { AdvertisementIntegrationStatusEnumDTO } from '../../../../../connectors/property';
import type { AdvertisementConfig } from '../../providers';
import type { SelectedIntegrationsState } from '../Unpublish/unpublish.component';
import { sxProps } from './unpublish-all.styles';

type Props = {
  advertisementIntegrations: UnitAdvertisementSerializerDTO['integrations'];
  availableIntegrations: AdvertisementConfig['integrations'];
  itemSx: SxProps<Theme>;
  selectedIntegrations: SelectedIntegrationsState;
  selectedItemSx: SxProps<Theme>;
  setSelectedIntegrations: Dispatch<SetStateAction<SelectedIntegrationsState>>;
};

export const UnpublishAll: FC<Props> = (props) => {
  const {
    advertisementIntegrations,
    availableIntegrations,
    itemSx,
    selectedIntegrations,
    selectedItemSx,
    setSelectedIntegrations,
  } = props;
  const isDisabled = useMemo(
    () =>
      advertisementIntegrations.some(
        (integration) =>
          integration.status !== AdvertisementIntegrationStatusEnumDTO.Success,
      ),
    [advertisementIntegrations],
  );
  const handleClick = useCallback(() => {
    if (isDisabled) return;

    setSelectedIntegrations('ALL');
  }, [isDisabled, setSelectedIntegrations]);
  const isSelected = useMemo(
    () => selectedIntegrations === 'ALL',
    [selectedIntegrations],
  );

  return (
    <ButtonBase
      component="div"
      disabled={isDisabled}
      onClick={handleClick}
      sx={{ ...itemSx, ...(isSelected && selectedItemSx) } as SxProps}
    >
      <Box {...(isDisabled && { sx: sxProps.disabled })}>
        <Icon component={ElectronicsRockerSwitchIcon} sx={sxProps.icon} />
        <Typography
          component="p"
          fontWeight="normal"
          marginTop={2}
          marginBottom={2}
          variant="h2"
        >
          <Trans
            components={{ bold: <strong /> }}
            i18nKey="advertisement.unpublish.allText"
          />
        </Typography>
        <Grid container justifyContent="center" spacing={2}>
          {availableIntegrations.map((integration) => (
            <Grid item key={integration.uuid}>
              <Avatar src={integration.logoUrl} sx={sxProps.avatar} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ButtonBase>
  );
};
