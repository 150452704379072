import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React from 'react';

import { useTranslation } from '../../../../../shared';
import type { FormValues } from '../form.types';
import { sxProps } from './submit-button.styles';

export const SubmitButton: FC = () => {
  const { isSubmitting, isValid, submitForm } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  return (
    <Box sx={sxProps.container}>
      <LoadingButton
        color="primary"
        data-testid="form-navigation-button-create-advertisement"
        disabled={!isValid}
        loading={isSubmitting}
        onClick={submitForm}
        type="submit"
        variant="contained"
      >
        {t('advertisement.create.button.submit')}
      </LoadingButton>
    </Box>
  );
};
