import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  entriesRow: {
    p: (theme) => theme.spacing(2),
  },
  input: {
    p: 0,
  },
  inputAdornment: {
    mr: (theme) => theme.spacing(2),
  },
  searchIcon: {
    color: (theme) => theme.palette.text.primary,
  },
  textField: {
    '& fieldset': {
      border: 'none',
    },
  },
  textFieldRow: {
    p: (theme) => theme.spacing(0, 2, 0, 1),
  },
};
