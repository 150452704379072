import type { FC, ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { isPermitted, useAuth } from '../../../../+auth';
import type { CountrySerializerDTO } from '../../../../../connectors/company';
import type { TicketMetadataSerializerDTO } from '../../../../../connectors/ticket';
import { NOT_FOUND_PATH } from '../../../../routing';
import type { Country } from '../../../../shared';
import { HttpStatus, useApi } from '../../../../shared';
import { ticketClient } from '../../../ticket.client';
import { READ_PERMISSIONS, WRITE_PERMISSIONS } from '../../consts';
import type { ContextValue } from './context';
import { Context, initialConfig, initialCountryConfig } from './context';
import { prepareConfig } from './helper';

type Props = {
  children: ReactNode;
};

export const Provider: FC<Props> = (props) => {
  const { children } = props;
  const { user, permissions } = useAuth();
  const navigate = useNavigate();
  const canFetchConfig = useMemo(
    () => isPermitted([...READ_PERMISSIONS, ...WRITE_PERMISSIONS], permissions),
    [permissions],
  );
  const fetchMetadata$ = useCallback(
    (): Promise<
      (TicketMetadataSerializerDTO & {
        countryId: CountrySerializerDTO['uuid'];
      })[]
    > =>
      canFetchConfig && user?.countryUuids
        ? Promise.all(
            user.countryUuids.map(async (countryId) => {
              const countryConfig = await ticketClient.getMetadata$(countryId);

              return { countryId, ...countryConfig };
            }, Promise.resolve([])),
          )
        : Promise.resolve([]),
    [canFetchConfig, user?.countryUuids],
  );
  const { error, response } = useApi([], fetchMetadata$);
  const config = useMemo(
    () =>
      response.reduce(
        (config, { countryId, ...countryConfig }) => ({
          ...config,
          [countryId]: prepareConfig(countryConfig),
        }),
        initialConfig,
      ),
    [response],
  );
  const isNotFoundError = useMemo(
    () => error?.status === HttpStatus.NOT_FOUND,
    [error],
  );
  const getCountryConfig = useCallback(
    (country?: Country | CountrySerializerDTO['uuid']) => {
      const countryConfig = country && config[country as Country];

      return countryConfig || initialCountryConfig;
    },
    [config],
  );
  const value = useMemo<ContextValue>(
    () => ({ config, getCountryConfig }),
    [config, getCountryConfig],
  );

  if (isNotFoundError) {
    navigate(NOT_FOUND_PATH);

    return null;
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
