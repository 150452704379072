import type { FieldConfig, FileValue } from '../../../form';
import type { Accept } from '../enums';

export type Values = {
  attachments: FileValue;
};

export const getFields = (): FieldConfig<Values>[] => [
  {
    componentType: 'file',
    field: {
      initialValue: [],
      name: 'attachments',
    },
    props: {
      labelKey: 'attachment.fields.attachments',
    },
  },
];

// https://github.com/HeimstadenGroup/hst-mie/blob/50a92dafdea4ea4ec5771e7971e89cc49b77e8b4/services/common/process/validators/attachment.py#L5
export const MAX_FILE_SIZE = 1024 * 1024 * 50; // 50MB;

// https://github.com/HeimstadenGroup/hst-mie/blob/50a92dafdea4ea4ec5771e7971e89cc49b77e8b4/services/common/process/validators/attachment.py#L10
export const supportedFileTypes = [
  'application/msword',
  'application/pdf',
  'application/rtf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'model/3mf',
  'model/vrml',
  'text/csv',
];

// https://github.com/HeimstadenGroup/hst-mie/blob/50a92dafdea4ea4ec5771e7971e89cc49b77e8b4/services/common/process/validators/attachment.py#L16
export const supportedDocumentTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'application/pdf',
  'text/csv',
];

// https://github.com/HeimstadenGroup/hst-mie/blob/50a92dafdea4ea4ec5771e7971e89cc49b77e8b4/services/common/process/validators/attachment.py#L11
export const supportedImageTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];

export const supportedDocumentTypeExtensions: Accept = {
  'application/msword': ['.doc'],
  'application/pdf': ['.pdf'],
  'application/rtf': ['.rtf'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'text/csv': ['.csv'],
};

export const supportedImageTypeExtensions: Accept = {
  'image/*': ['.jpeg', '.jpg', '.png', '.svg'],
};

export const supportedFileTypeExtensions: Accept = {
  ...supportedDocumentTypeExtensions,
  ...supportedImageTypeExtensions,
};
