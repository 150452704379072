/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * - Tenant (API user, actual tenant with a contractual relationship with Heimstaden) - Co-Tenant (API user, actual tenant with a cosignature to a contractual relationship with Heimstaden) - Resident (API user, person living with a tenant in the unit,             but with no legal obligations towards the contract) - Depositor (API user, the person that paid the deposit, can be the same as the tenant)
 * @export
 * @enum {string}
 */
export enum SignerRoleEnumDTO {
    Tenant = 'Tenant',
    CoTenant = 'Co-Tenant',
    Resident = 'Resident',
    Depositor = 'Depositor'
}



