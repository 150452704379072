import type { FormikErrors } from 'formik';
import type { Dispatch, SetStateAction } from 'react';

import type { FormProps } from '../types';
import { parseGlobalError, transformFieldErrors } from './error.helper';

type OnSubmit<TFormValues> = FormProps<TFormValues>['config']['onSubmit'];

export const handleSubmit = <TFormValues>(
  setGlobalError: Dispatch<SetStateAction<string | undefined>>,
  handleSuccess: FormProps<TFormValues>['handleSuccess'],
  onSubmit: OnSubmit<TFormValues>,
): OnSubmit<TFormValues> => {
  return async (values, helpers): Promise<void> => {
    const { setErrors, setSubmitting, setStatus } = helpers;

    try {
      const response = await onSubmit(values, helpers);

      if (handleSuccess) {
        handleSuccess(response as never);
      }

      setStatus({ success: true });
    } catch (e) {
      if (e.data?.message || e.message || e.data?.detail?.nonFieldErrors) {
        const globalError = parseGlobalError(
          e.data?.message || e.message || e.data?.detail?.nonFieldErrors,
        );

        setGlobalError(globalError);
      }

      if (e.data?.detail) {
        const errors = transformFieldErrors(e.data.detail);

        setErrors(errors as FormikErrors<TFormValues>);
      }

      setStatus({ success: false });
    } finally {
      setSubmitting(false);
    }
  };
};
