import type { FC } from 'react';
import React, { useMemo } from 'react';

import type {
  CreateProspectSerializerDTO,
  UnitAdvertisementSerializerDTO,
} from '../../../../../connectors/property';
import type { FormProps } from '../../../../shared';
import { Form as GenericForm } from '../../../../shared';
import { prospectClient } from '../../../prospect.client';
import { getFieldsConfig } from './form.const';

type Props = {
  advertisement: UnitAdvertisementSerializerDTO;
  handleSuccess: FormProps<CreateProspectSerializerDTO>['handleSuccess'];
};

export const Form: FC<Props> = (props) => {
  const { advertisement, handleSuccess } = props;
  const config = useMemo(
    () => ({
      onSubmit: (values: CreateProspectSerializerDTO) =>
        prospectClient.create$(values),
    }),
    [],
  );
  const fieldsConfig = useMemo(
    () => getFieldsConfig(advertisement),
    [advertisement],
  );

  return (
    <GenericForm<CreateProspectSerializerDTO>
      config={config}
      fieldsConfig={fieldsConfig}
      handleSuccess={handleSuccess}
      showButtons
      submitLabelKey="prospect.buttons.create"
    />
  );
};
