import type { PaletteMode } from '@mui/material';

import { ProspectStatusEnumDTO } from '../../../../connectors/property';
import type { ColorsHex } from '../../../shared';
import { palette } from '../../../shared/theme/palette';

const statusToTranslationLabelKeyMap: Record<
  ProspectStatusEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [ProspectStatusEnumDTO.ContractSent]: 'prospect.status.contractSent',
  [ProspectStatusEnumDTO.ContractSigned]: 'prospect.status.contractSigned',
  [ProspectStatusEnumDTO.ProspectCancelled]: 'prospect.status.cancelled',
  [ProspectStatusEnumDTO.ProspectConfirmedByUser]:
    'prospect.status.confirmedByUser',
  [ProspectStatusEnumDTO.ProspectRegistered]: 'prospect.status.registered',
  [ProspectStatusEnumDTO.ProspectRejected]: 'prospect.status.rejected',
  [ProspectStatusEnumDTO.Viewed]: 'prospect.status.viewed',
  [ProspectStatusEnumDTO.ViewingCanceled]: 'prospect.status.viewingCancelled',
  [ProspectStatusEnumDTO.ViewingDateConfirmed]:
    'prospect.status.viewingDateConfirmed',
  [ProspectStatusEnumDTO.ViewingFailed]: 'prospect.status.viewingFailed',
  [ProspectStatusEnumDTO.ViewingRequested]: 'prospect.status.viewingRequested',
};

export const getStatusTranslationLabelKey = (
  status: ProspectStatusEnumDTO,
): GenericTypes.TranslationLabel =>
  statusToTranslationLabelKeyMap[status] || status;

const statusToColorsHexMap: Record<
  ProspectStatusEnumDTO,
  Record<PaletteMode, ColorsHex>
> = {
  [ProspectStatusEnumDTO.ContractSent]: {
    dark: {
      backgroundColor: palette.dodgerBlue,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.dodgerBlue,
      color: palette.white,
    },
  },
  [ProspectStatusEnumDTO.ContractSigned]: {
    dark: {
      backgroundColor: palette.fruitSalad,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.fruitSalad,
      color: palette.white,
    },
  },
  [ProspectStatusEnumDTO.ProspectCancelled]: {
    dark: {
      backgroundColor: palette.frenchRose,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.frenchRose,
      color: palette.white,
    },
  },
  [ProspectStatusEnumDTO.ProspectConfirmedByUser]: {
    dark: {
      backgroundColor: palette.cement,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.cement,
      color: palette.white,
    },
  },
  [ProspectStatusEnumDTO.ProspectRegistered]: {
    dark: {
      backgroundColor: palette.orangePeel,
      color: palette.mineShaft,
    },
    light: {
      backgroundColor: palette.orangePeel,
      color: palette.mineShaft,
    },
  },
  [ProspectStatusEnumDTO.ProspectRejected]: {
    dark: {
      backgroundColor: palette.burntSienna,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.burntSienna,
      color: palette.white,
    },
  },
  [ProspectStatusEnumDTO.Viewed]: {
    dark: {
      backgroundColor: palette.fern,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.fern,
      color: palette.white,
    },
  },
  [ProspectStatusEnumDTO.ViewingCanceled]: {
    dark: {
      backgroundColor: palette.burningOrange,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.burningOrange,
      color: palette.white,
    },
  },
  [ProspectStatusEnumDTO.ViewingDateConfirmed]: {
    dark: {
      backgroundColor: palette.celery,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.celery,
      color: palette.white,
    },
  },
  [ProspectStatusEnumDTO.ViewingFailed]: {
    dark: {
      backgroundColor: palette.grenadier,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.grenadier,
      color: palette.white,
    },
  },
  [ProspectStatusEnumDTO.ViewingRequested]: {
    dark: {
      backgroundColor: palette.blueRibbon,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.blueRibbon,
      color: palette.white,
    },
  },
  [ProspectStatusEnumDTO.ViewingFailed]: {
    dark: {
      backgroundColor: palette.pomegranate,
      color: palette.white,
    },
    light: {
      backgroundColor: palette.pomegranate,
      color: palette.white,
    },
  },
};

export const getStatusColorsHex = (
  paletteMode: PaletteMode,
  status: ProspectStatusEnumDTO,
): ColorsHex => statusToColorsHexMap[status][paletteMode] || palette.alto;
