import MUIBox from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import React, { useEffect, useMemo, useState } from 'react';
import type { Dispatch, FC, ReactElement, SetStateAction } from 'react';

import type { FieldConfig } from '../../form';
import { Box } from '../Box/box.component';
import { Scrollbar } from '../Scrollbar/scrollbar.component';
import { Body } from './Body/body.component';
import { FilterPanel } from './FilterPanel/filter-panel.component';
import { Header } from './Header/header.component';
import { Pagination } from './Pagination/pagination.component';
import { Search } from './Search/search.component';
import { SelectedRowsHeader } from './SelectedRowsHeader/selected-rows-header.component';
import { Tabs } from './Tabs/tabs.component';
import { sxProps } from './list.styles';
import type { ColumnConfig, ListState, Tab } from './list.type';

export type Props<T, TFiltersValues> = {
  columnsConfig: ColumnConfig<T>[];
  isFetching: boolean;
  model: T[];
  modelKey: keyof T;
  buttonsPanelComponent?: ReactElement;
  children?: (item: T) => ReactElement;
  enableDefaultTab?: boolean;
  enableHeader?: boolean;
  enablePagination?: boolean;
  enableSearch?: boolean;
  filterFieldsConfig?: FieldConfig<TFiltersValues>[];
  filterPanelComponent?: ReactElement;
  isHighlighted?: (item: T) => boolean;
  listState?: ListState<TFiltersValues>;
  onRowClick?: (item: T) => void;
  selectedRowsActionComponent?: (
    rows: T[],
    setRowsDisabled: Dispatch<SetStateAction<boolean>>,
  ) => ReactElement;
  setListState?: Dispatch<SetStateAction<ListState<TFiltersValues>>>;
  tabs?: Tab[];
};

export const List = <T, TFiltersValues = undefined>(
  props: Props<T, TFiltersValues>,
): ReturnType<FC<Props<T, TFiltersValues>>> => {
  const {
    buttonsPanelComponent,
    children,
    columnsConfig,
    enableDefaultTab = true,
    enableHeader = true,
    enablePagination = true,
    enableSearch = true,
    filterFieldsConfig,
    filterPanelComponent,
    isFetching,
    isHighlighted,
    listState,
    model,
    modelKey,
    onRowClick,
    selectedRowsActionComponent,
    setListState,
    tabs,
  } = props;
  const [isRowsDisabled, setRowsDisabled] = useState(false);
  const isSelectingRowsEnabled = useMemo(
    () => Boolean(selectedRowsActionComponent),
    [selectedRowsActionComponent],
  );
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  useEffect(() => {
    setSelectedRows([]);
  }, [model]);

  return (
    <Grid container data-testid="list" spacing={3}>
      {listState && setListState && tabs && tabs.length > 0 && (
        <Grid item xs={12}>
          <Tabs<TFiltersValues>
            enableDefaultTab={enableDefaultTab}
            listState={listState}
            setListState={setListState}
            tabs={tabs}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box sx={sxProps.box}>
          <Grid sx={sxProps.headerBox} container spacing={2}>
            {buttonsPanelComponent && buttonsPanelComponent}
            {enableSearch && listState && setListState && (
              <Search<TFiltersValues>
                listState={listState}
                setListState={setListState}
              />
            )}
            {(filterFieldsConfig || filterPanelComponent) && setListState && (
              <FilterPanel<TFiltersValues>
                fieldsConfig={filterFieldsConfig}
                filterPanelComponent={filterPanelComponent}
                isFetching={isFetching}
                setListState={setListState}
              />
            )}
          </Grid>
          <Scrollbar>
            <MUIBox>
              {selectedRowsActionComponent && selectedRows.length > 0 && (
                <SelectedRowsHeader
                  actionComponent={selectedRowsActionComponent}
                  rows={selectedRows}
                  setRowsDisabled={setRowsDisabled}
                />
              )}
              <Table>
                {enableHeader && (
                  <Header<T, TFiltersValues>
                    columnsConfig={columnsConfig}
                    isRowsDisabled={isRowsDisabled}
                    isSelectingRowsEnabled={isSelectingRowsEnabled}
                    listState={listState}
                    model={model}
                    selectedRows={selectedRows}
                    setListState={setListState}
                    setSelectedRows={setSelectedRows}
                  />
                )}
                <Body<T>
                  columnsConfig={columnsConfig}
                  isHighlighted={isHighlighted}
                  isFetching={isFetching}
                  isRowsDisabled={isRowsDisabled}
                  isSelectingRowsEnabled={isSelectingRowsEnabled}
                  model={model}
                  modelKey={modelKey}
                  onRowClick={onRowClick}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                >
                  {children}
                </Body>
              </Table>
            </MUIBox>
          </Scrollbar>
          {enablePagination &&
            listState &&
            setListState &&
            model.length > 0 && (
              <Pagination<TFiltersValues>
                listState={listState}
                setListState={setListState}
              />
            )}
        </Box>
      </Grid>
    </Grid>
  );
};
