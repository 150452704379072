import { $generateHtmlFromNodes } from '@lexical/html';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import type { FieldHelperProps } from 'formik/dist/types';
import type { FC } from 'react';
import React, { useCallback } from 'react';

import { useDebounce } from '../../../../../hooks';
import type { EditorValue } from '../../../../fields';

type Props = {
  isDisabled: boolean;
  setValue: FieldHelperProps<EditorValue>['setValue'];
};

export const ChangeEffect: FC<Props> = (props) => {
  const { isDisabled, setValue } = props;
  const handleChange = useCallback(
    (editorState, editor) => {
      editor.update(() => {
        const value = $generateHtmlFromNodes(editor, null);

        setValue(value);
      });
    },
    [setValue],
  );
  const debouncedHandleChange = useDebounce(handleChange, 200);

  if (isDisabled) return null;

  return <OnChangePlugin onChange={debouncedHandleChange} />;
};
