import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import type { SelectProps } from '@mui/material/Select';
import Select from '@mui/material/Select';
import type { SelectInputProps } from '@mui/material/Select/SelectInput';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FieldProps as FormikFieldProps } from 'formik';
import isFunction from 'lodash-es/isFunction';
import React, { useCallback, useMemo } from 'react';
import type { ElementType, FC } from 'react';

import { useApi } from '../../../hooks';
import { useTranslation } from '../../../translations';
import { FieldLabel } from '../../components';
import { getFirstErrorKey } from '../../helpers';
import { DEFAULT_SELECT_OPTION } from '../../index';
import type { MultipleSelectProps, SelectOption } from '../types';
import { sxProps } from './multiple-select-field.styles';

type Props<TFormValues, TSelectValue> = FormikFieldProps<
  TSelectValue,
  TFormValues
> & {
  props: MultipleSelectProps<TFormValues, TSelectValue>;
};

export const MultipleSelectField = <
  TFormValues,
  TSelectValue extends (string | number)[] = (string | number)[],
>(
  props: Props<TFormValues, TSelectValue>,
): ReturnType<FC<Props<TFormValues, TSelectValue>>> => {
  const { field, form, meta, props: fieldProps } = props;
  const { t } = useTranslation();
  const { error, touched, value } = meta;
  const { setFieldValue, values } = form;
  const { name, onBlur } = field;
  const {
    callback$,
    disabled,
    disableWhen,
    fieldSx,
    formControlSx,
    isFilter,
    labelKey,
    options,
    placeholderKey,
    readonly,
    required,
    requiredWhen,
    startAdornmentIcon,
  } = fieldProps;
  const labelId = useMemo(() => `${name}-label`, [name]);
  const finalLabelKey = useMemo(
    () => (isFunction(labelKey) ? labelKey(values) : labelKey),
    [labelKey, values],
  );
  const handleChange: SelectInputProps<TSelectValue>['onChange'] = useCallback(
    (event): void => {
      const { value } = event.target;

      setFieldValue(name, typeof value === 'string' ? value.split(',') : value);
    },
    [name, setFieldValue],
  );
  const errorMessageKey = useMemo(
    () => touched && getFirstErrorKey(error),
    [error, touched],
  );
  const isError = useMemo(() => Boolean(errorMessageKey), [errorMessageKey]);
  const isPermanentlyDisabled = useMemo(
    () => Boolean(disabled || readonly || (disableWhen && disableWhen(values))),
    [disabled, disableWhen, readonly, values],
  );
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );
  const staticOptions = useMemo(
    () => (isFunction(options) ? options(values) : options),
    [options, values],
  );
  const fetchOptions$ = useCallback(() => {
    if (!callback$ || isPermanentlyDisabled) return Promise.resolve([]);

    return callback$();
  }, [callback$, isPermanentlyDisabled]);
  const { isFetching, response: fetchedOptions } = useApi<
    SelectOption<TSelectValue>[]
  >([], fetchOptions$);
  const selectOptions = useMemo(
    () => [...staticOptions, ...fetchedOptions],
    [fetchedOptions, staticOptions],
  );
  const finalOptions = useMemo(
    () =>
      selectOptions.map(({ label, labelKey, value: optionValue }, idx) => (
        <MenuItem
          data-testid={`${name}-option-${idx}`}
          key={`select-option-${optionValue}`}
          sx={sxProps.menuItem}
          value={optionValue as never}
        >
          <Checkbox
            checked={
              Array.isArray(value) && value.some((v) => v === optionValue)
            }
            color="secondary"
            size="small"
          />
          {labelKey ? t(labelKey) : label}
        </MenuItem>
      )),
    [name, selectOptions, t, value],
  );
  const isValueAvailable = useMemo(
    () =>
      Array.isArray(value) &&
      (value as TSelectValue).every((selected) =>
        selectOptions.some((option) => option.value === selected),
      ),
    [selectOptions, value],
  );
  const isTemporarilyDisabled = useMemo(
    () => Boolean(isFetching || (callback$ && !isValueAvailable)),
    [callback$, isFetching, isValueAvailable],
  );
  const isDisabled = useMemo(
    () => isPermanentlyDisabled || isTemporarilyDisabled,
    [isPermanentlyDisabled, isTemporarilyDisabled],
  );
  const finalValue = useMemo(
    () => (isValueAvailable ? value : ([] as unknown as TSelectValue)),
    [isValueAvailable, value],
  );
  const renderStandardValue: SelectProps<TSelectValue>['renderValue'] =
    useCallback(
      (selectedOptions: TSelectValue) =>
        selectedOptions
          .map((value) => {
            const selectedOption = selectOptions.find(
              (option) => option.value === value,
            );

            return selectedOption?.labelKey
              ? t(selectedOption.labelKey)
              : selectedOption?.label || '';
          })
          .filter((v) => v)
          .join(', '),
      [selectOptions, t],
    );
  const renderFilterValue: SelectProps<TSelectValue>['renderValue'] =
    useCallback(
      (selectedOptions: TSelectValue) =>
        selectedOptions.length === selectOptions.length ||
        selectedOptions.length === 0
          ? t('form.labels.defaultSelectOptionLabel')
          : t('form.labels.multiselectLabel', {
              count: selectedOptions.length,
            }),
      [selectOptions.length, t],
    );
  const renderValue: SelectProps<TSelectValue>['renderValue'] = useCallback(
    (selectedOptions: TSelectValue) =>
      isFilter && selectedOptions.length !== 1
        ? renderFilterValue(selectedOptions)
        : renderStandardValue(selectedOptions),
    [isFilter, renderFilterValue, renderStandardValue],
  );
  const StartAdornmentIcon = useMemo(
    () =>
      (isFunction(startAdornmentIcon)
        ? startAdornmentIcon(values)
        : startAdornmentIcon) as ElementType,
    [startAdornmentIcon, values],
  );

  return (
    <FormControl
      component="fieldset"
      disabled={isDisabled}
      error={isError}
      fullWidth
      required={isRequired && !readonly}
      sx={
        {
          ...(isFilter && sxProps.filterFormControl),
          ...formControlSx,
        } as SxProps
      }
      variant="outlined"
    >
      <Box>
        <FieldLabel isShrink={!isFilter} labelKey={finalLabelKey} />
      </Box>
      <>
        <Select<TSelectValue>
          displayEmpty
          error={isError}
          input={
            <OutlinedInput
              {...(!isFilter &&
                finalLabelKey && { label: t(finalLabelKey), notched: true })}
              id={name}
              inputProps={{ 'data-testid': name }}
              placeholder={placeholderKey ? t(placeholderKey) : undefined}
              startAdornment={
                startAdornmentIcon && (
                  <InputAdornment position="start">
                    <StartAdornmentIcon height={24} width={24} />
                  </InputAdornment>
                )
              }
            />
          }
          labelId={labelId}
          multiple
          onBlur={onBlur}
          onChange={handleChange}
          renderValue={renderValue}
          sx={{ ...(isFilter && sxProps.filterField), ...fieldSx } as SxProps}
          value={finalValue}
        >
          {placeholderKey && (
            <MenuItem value={DEFAULT_SELECT_OPTION.value} disabled hidden>
              {t(placeholderKey)}
            </MenuItem>
          )}
          {finalOptions}
        </Select>
        {isError && (
          <FormHelperText error>{t(errorMessageKey || '')}</FormHelperText>
        )}
      </>
    </FormControl>
  );
};
