import type { FormikTouched } from 'formik';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';

import type { Values } from '../../../../../+library/shared/components/UploadForm/upload-form.model';
import type { FieldConfig } from '../../../../form';
import { useDebounce } from '../../../../hooks';
import { useAttachmentUploader } from '../../providers';
import type { Attachment } from '../../providers/AttachmentUploader/type';

type Props<T, M> = {
  getFieldsConfig: (attachment?: Attachment<M>) => FieldConfig<T>[];
};

export const FormEffect = <T, M = unknown>(
  props: Props<T, M>,
): ReturnType<FC<Props<T, M>>> => {
  const { getFieldsConfig } = props;
  const { setTouched, validateForm, values } = useFormikContext<T>();
  const { attachments, setAttachments } = useAttachmentUploader<M>();
  const handleValidateForm = useCallback(
    async (values) => {
      const errors = await validateForm(values);
      const isValid = Object.values(errors).length === 0;

      setAttachments((prevState) =>
        prevState.map((attachment) =>
          attachment.isCurrent ? { ...attachment, isValid } : attachment,
        ),
      );
    },
    [setAttachments, validateForm],
  );
  const debouncedHandleValidateForm = useDebounce(handleValidateForm, 200);

  useEffect(() => {
    debouncedHandleValidateForm(values);
  }, [debouncedHandleValidateForm, values]);

  useEffect(() => {
    (async () => {
      const isAllCheck = attachments.every(
        (attachment) => attachment.isValid !== undefined,
      );

      if (isAllCheck) return;

      const newAttachments = await Promise.all(
        attachments.map(async (attachment) => {
          if (attachment.isValid !== undefined) return attachment;

          const fieldsConfig = getFieldsConfig(attachment);
          const values = fieldsConfig.reduce(
            (values, config) => ({
              ...values,
              [config.field.name]: config.field.initialValue,
            }),
            {} as Values,
          );
          const errors = await validateForm(values);
          const isValid = Object.values(errors).length === 0;

          return { ...attachment, isValid };
        }),
      );

      const currentAttachment = newAttachments.find(
        (attachment) => attachment.isCurrent,
      );
      const fieldsConfig = getFieldsConfig(currentAttachment);
      const touched = fieldsConfig.reduce(
        (values, config) => ({ ...values, [config.field.name]: false }),
        {} as FormikTouched<T>,
      );

      // When some of the attachments are not valid we are displaying the error on the current attachment form because we have one instance of the form.
      setTouched(touched);
      setAttachments(newAttachments);
    })();
  }, [attachments, getFieldsConfig, setAttachments, setTouched, validateForm]);

  return null;
};
