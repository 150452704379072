import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  arrow: {
    backgroundColor: (theme) => theme.palette.background.paper,
    color: (theme) => theme.palette.grey[500],
    height: 32,
    left: -16,
    mt: -2,
    p: 1,
    position: 'absolute',
    top: '50%',
    width: 32,
  },
  box: {
    borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
    pl: 4,
    position: 'relative',
  },
  button: {
    '&:hover': {
      opacity: 0.8,
    },
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.grey[500],
    borderRadius: 2,
    color: (theme) => theme.palette.primary.contrastText,
    display: 'inline-flex',
    ml: 2,
    padding: (theme) => theme.spacing(0.5, 2),
  },
  buttonAccepted: {
    backgroundColor: (theme) => theme.palette.success.main,
  },
  feature: {
    pb: 2,
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    mb: 2,
  },
  text: {
    color: 'inherit',
    fontSize: (theme) => theme.typography.pxToRem(13),
    mr: 1,
  },
};
