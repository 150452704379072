import { ReactComponent as ArchitectureDoorIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as CalendarAdd1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-add-1.svg';
import { ReactComponent as RealEstateBuildingHousesIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/real-estate/real-estate-building-house.svg';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../../../connectors/ticket';
import {
  CollectionViewMode,
  Feature,
  FormattedDate,
  getUserName,
  Scrollbar,
  useTranslation,
} from '../../../../shared';
import { getPriorityTypeTranslationLabelKey } from '../../consts';
import { PriorityIcon } from '../PriorityIcon/priority-icon.component';
import { sxProps } from './feature-list.styles';

type Props = {
  data: UniversalTicketsWithAdditionalFieldsSerializerDTO;
  viewMode?: CollectionViewMode;
};

const GRID_SPACING_DEFAULT = 3;
const GRID_SPACING_LIST = [2, 4, 4, 6];

export const FeatureList: FC<Props> = (props) => {
  const { data, viewMode } = props;
  const { t } = useTranslation();
  const gridSpacing = useMemo(
    () =>
      viewMode === CollectionViewMode.LIST
        ? GRID_SPACING_LIST
        : GRID_SPACING_DEFAULT,
    [viewMode],
  );
  const gridWrap = useMemo(
    () => (viewMode === CollectionViewMode.GRID ? 'wrap' : 'nowrap'),
    [viewMode],
  );
  const features = useMemo(
    () => [
      {
        dataTestId: 'created-date',
        icon: <CalendarAdd1Icon />,
        id: 'createdDate',
        titleKey: 'ticket.fields.createdDate',
        value: <FormattedDate date={data.createdAt} />,
      },
      {
        dataTestId: 'priority-highest',
        icon: <PriorityIcon priority={data.priority} />,
        id: 'priority',
        titleKey: 'ticket.fields.priority',
        value: t(getPriorityTypeTranslationLabelKey(data.priority)),
      },
      {
        dataTestId: 'assignee',
        icon: (
          <Avatar src={data.assignee?.profilePicture} sx={sxProps.avatar} />
        ),
        id: 'assignee',
        titleKey: 'ticket.fields.assignee',
        ...(data.assignee
          ? { value: getUserName(data.assignee) }
          : { valueKey: 'ticket.fields.defaultAssignee' }),
      },
      ...(data.addressLine
        ? [
            {
              dataTestId: 'address',
              icon: <RealEstateBuildingHousesIcon />,
              id: 'addressLine',
              titleKey: 'ticket.fields.addressLine',
              value: `${data.addressLine} ${data.streetNumber || ''}`.trim(),
            },
          ]
        : []),
      ...(data.entityType === 'unit'
        ? [
            {
              dataTestId: 'apartment-number',
              icon: <ArchitectureDoorIcon />,
              id: 'apartmentNumber',
              titleKey: 'ticket.fields.apartmentNumber',
              value: data.apartmentNumber,
            },
          ]
        : []),
    ],
    [
      data.addressLine,
      data.apartmentNumber,
      data.assignee,
      data.createdAt,
      data.entityType,
      data.priority,
      data.streetNumber,
      t,
    ],
  );

  if (viewMode === CollectionViewMode.MAP) {
    return null;
  }

  return (
    <Scrollbar>
      <Grid
        columnSpacing={gridSpacing}
        container
        rowSpacing={1}
        wrap={gridWrap}
      >
        {features.map(({ id, ...feature }) => (
          <Grid key={`feature-${viewMode}-${id}`} item>
            <Feature
              {...feature}
              size={viewMode === CollectionViewMode.GRID ? 'small' : 'normal'}
            />
          </Grid>
        ))}
      </Grid>
    </Scrollbar>
  );
};
