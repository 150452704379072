import React from 'react';
import type { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { APP_MAIN_PATH } from '../../../routing';
import { useAuth } from '../state';

interface Props {
  children: ReactElement;
}

export const GuestGuard: FC<Props> = (props) => {
  const { children } = props;
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={APP_MAIN_PATH} />;
  }

  return children;
};
