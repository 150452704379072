import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { getUserRoleTranslationLabelKey } from '../../../../../../+user';
import {
  getUserName,
  ObjectRow,
  useAsync,
  useTranslation,
} from '../../../../../../shared';
import type { UserData } from '../summary-step.type';

export const Assignee: FC = () => {
  const { t } = useTranslation();
  const { response } = useAsync<UserData>();
  const assignee = useMemo(
    () => ({
      avatarSrc: response.assignee?.profilePicture,
      id: response.assignee ? response.assignee.uuid : 'default-assign-id',
      subtitleKey: response.assignee?.roles
        ? getUserRoleTranslationLabelKey(response.assignee.roles)
        : '',
      title: response.assignee
        ? getUserName(response.assignee)
        : t('ticket.fields.defaultAssignee'),
    }),
    [response.assignee, t],
  );

  return (
    <>
      <Typography
        component="div"
        color="text.primary"
        variant="overline"
        fontWeight="bold"
        marginBottom={2}
      >
        {t('ticket.fieldset.summary.assignee')}
      </Typography>
      <ObjectRow {...assignee} />
    </>
  );
};
