import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    backgroundColor: (theme) => theme.palette.background.default,
    borderRadius: (theme) => theme.spacing(2),
    p: 2,
  },
};
