import { ReactComponent as ArchitectureDoorIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as MapsPinIcon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin.svg';
import { ReactComponent as RealEstateBuildingHousesIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/real-estate/real-estate-building-house.svg';
import uniqBy from 'lodash-es/uniqBy';
import type { FC, SVGProps } from 'react';
import { Fragment } from 'react';
import { generatePath } from 'react-router';

import { companyClient } from '../../../+company';
import { PROPERTY_PATHS, propertyClient } from '../../../+property';
import {
  PROPERTY_OBJECT_PATHS,
  propertyObjectClient,
} from '../../../+property-object';
import { UNIT_PATHS, unitClient } from '../../../+unit';
import type { UnitCategoryPath } from '../../../+unit/paths.const';
import { userClient } from '../../../+user';
import type {
  CitySerializerDTO,
  CompanyDetailSerializerDTO,
  CountrySerializerDTO,
} from '../../../../connectors/company';
import type {
  DetailedPropertyObjectSerializerDTO,
  DetailedPropertySerializerDTO,
  DetailedUnitSerializerDTO,
  ListContactSerializerDTO,
  PropertyObjectSerializerDTO,
  UnitSerializerDTO,
} from '../../../../connectors/property';
import type { UniversalTicketSerializerDTO } from '../../../../connectors/ticket';
import { EntityEnumDTO } from '../../../../connectors/ticket';
import type {
  DetailedUserSerializerDTO,
  UserRoleEnumDTO,
  UserSerializerDTO,
} from '../../../../connectors/user';
import type { AsyncAutocompleteOption } from '../../../shared';
import { AsyncAutocompleteOptionCategory, getUserName } from '../../../shared';
import type { Entity } from '../types';

export const getEntityData$ = async (
  type: UniversalTicketSerializerDTO['entityType'],
  uuid: UniversalTicketSerializerDTO['uuid'],
): Promise<Entity> => {
  let data:
    | CompanyDetailSerializerDTO
    | DetailedPropertyObjectSerializerDTO
    | DetailedPropertySerializerDTO
    | DetailedUnitSerializerDTO
    | DetailedUserSerializerDTO
    | undefined;

  switch (type) {
    case EntityEnumDTO.Company: {
      data = await companyClient.getDetails$(uuid);
      break;
    }
    case EntityEnumDTO.Property: {
      data = await propertyClient.getDetails$(uuid);
      break;
    }
    case EntityEnumDTO.PropertyObject: {
      data = await propertyObjectClient.getDetails$(uuid);
      break;
    }
    case EntityEnumDTO.Unit: {
      data = await unitClient.getDetails$(uuid);
      break;
    }
    case EntityEnumDTO.User: {
      data = await userClient.getDetails$(uuid);
      break;
    }
    default: {
      break;
    }
  }

  return { ...data, type } as Entity;
};

export const getEntitySuggestions$ = (
  entityType: EntityEnumDTO,
  query: string,
  city?: CitySerializerDTO['uuid'],
  country?: CountrySerializerDTO['uuid'],
): Promise<AsyncAutocompleteOption<Entity['uuid']>[]> => {
  switch (entityType) {
    case EntityEnumDTO.Company:
      return companyClient.getSuggestions$(query, country);
    case EntityEnumDTO.Property:
      return propertyClient.getSuggestions$(query, undefined, city, country);
    case EntityEnumDTO.PropertyObject:
      return propertyObjectClient.getSuggestions$(query, city, country);
    case EntityEnumDTO.Unit:
      return unitClient.getSuggestions$(query, undefined, city, country);
    default:
      return Promise.resolve([]);
  }
};

export const getEntityContactsSuggestions$ = (
  entityType?: EntityEnumDTO,
  entityUuid?: PropertyObjectSerializerDTO['uuid'] | UnitSerializerDTO['uuid'],
  parentEntityUuid?: PropertyObjectSerializerDTO['uuid'],
  query?: string,
  role?: UserRoleEnumDTO[],
  excludedRole?: UserRoleEnumDTO[],
): Promise<
  NonNullable<AsyncAutocompleteOption<UserSerializerDTO['uuid']>>[]
> => {
  if (!entityType || !entityUuid) return Promise.resolve([]);

  const prepareData = (
    response: ListContactSerializerDTO,
  ): NonNullable<AsyncAutocompleteOption<UserSerializerDTO['uuid']>>[] =>
    (response.results || []).map((contact) => ({
      category: AsyncAutocompleteOptionCategory.CONTACT,
      label: `${getUserName(contact)} - ${contact.email}`,
      value: contact.userUuid,
    }));
  const queryParams = { filters: { excludedRole, role }, search: query };

  switch (entityType) {
    case EntityEnumDTO.Unit: {
      const unitContacts$ = unitClient
        .getContacts$(entityUuid, queryParams)
        .then(prepareData);
      const propertyObjectContacts$ = parentEntityUuid
        ? propertyObjectClient
            .getContacts$(parentEntityUuid, queryParams)
            .then(prepareData)
        : Promise.resolve({ count: 0, results: [] }).then(prepareData);

      return Promise.all([unitContacts$, propertyObjectContacts$]).then(
        ([unitContacts, propertyObjectContacts]) =>
          uniqBy(
            [...propertyObjectContacts, ...unitContacts],
            (suggestion) => suggestion.value,
          ),
      );
    }
    case EntityEnumDTO.PropertyObject:
      return propertyObjectClient
        .getContacts$(entityUuid, queryParams)
        .then(prepareData);
    default:
      return Promise.resolve([]);
  }
};

const entityTypeFieldTranslationLabelKeyMap: Record<
  EntityEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [EntityEnumDTO.AdvertisementIntegration]: '',
  [EntityEnumDTO.Attachment]: '',
  [EntityEnumDTO.City]: '',
  [EntityEnumDTO.Comment]: '',
  [EntityEnumDTO.Company]: '',
  [EntityEnumDTO.Device]: '',
  [EntityEnumDTO.Entrance]: '',
  [EntityEnumDTO.Inventory]: '',
  [EntityEnumDTO.KycIntegration]: '',
  [EntityEnumDTO.Lease]: '',
  [EntityEnumDTO.LeaseTemplate]: '',
  [EntityEnumDTO.Property]: 'ticket.fields.entity.property',
  [EntityEnumDTO.PropertyObject]: 'ticket.fields.entity.propertyObject',
  [EntityEnumDTO.Prospect]: '',
  [EntityEnumDTO.Region]: '',
  [EntityEnumDTO.Ticket]: '',
  [EntityEnumDTO.Unit]: 'ticket.fields.entity.unit',
  [EntityEnumDTO.UnitAdvertisement]: '',
  [EntityEnumDTO.UnitAdvertisementIntegration]: '',
  [EntityEnumDTO.UnitContact]: '',
  [EntityEnumDTO.User]: '',
};

const entityTypeOptionTranslationLabelKeyMap: Record<
  EntityEnumDTO,
  GenericTypes.TranslationLabel
> = {
  [EntityEnumDTO.AdvertisementIntegration]:
    'ticket.options.entity.advertisementIntegration',
  [EntityEnumDTO.Attachment]: 'ticket.options.entity.attachment',
  [EntityEnumDTO.City]: 'ticket.options.entity.city',
  [EntityEnumDTO.Comment]: 'ticket.options.entity.comment',
  [EntityEnumDTO.Company]: 'ticket.options.entity.company',
  [EntityEnumDTO.Device]: 'ticket.options.entity.device',
  [EntityEnumDTO.Entrance]: 'ticket.options.entity.entrance',
  [EntityEnumDTO.Inventory]: 'ticket.options.entity.inventory',
  [EntityEnumDTO.Lease]: 'ticket.options.entity.lease',
  [EntityEnumDTO.LeaseTemplate]: '',
  [EntityEnumDTO.Property]: 'ticket.options.entity.property',
  [EntityEnumDTO.PropertyObject]: 'ticket.options.entity.propertyObject',
  [EntityEnumDTO.Prospect]: 'ticket.options.entity.prospect',
  [EntityEnumDTO.Region]: 'ticket.options.entity.region',
  [EntityEnumDTO.Ticket]: 'ticket.options.entity.ticket',
  [EntityEnumDTO.Unit]: 'ticket.options.entity.unit',
  [EntityEnumDTO.UnitAdvertisement]: 'ticket.options.entity.unitAdvertisement',
  [EntityEnumDTO.UnitAdvertisementIntegration]:
    'ticket.options.entity.unitAdvertisementIntegration',
  [EntityEnumDTO.UnitContact]: 'ticket.options.entity.unitContact',
  [EntityEnumDTO.User]: 'ticket.options.entity.user',
  [EntityEnumDTO.KycIntegration]: '',
};

export const getEntityTypeFieldTranslationLabelKey = (
  entityType: EntityEnumDTO,
): GenericTypes.TranslationLabel =>
  entityTypeFieldTranslationLabelKeyMap[entityType] || entityType;

export const getEntityTypeOptionTranslationLabelKey = (
  entityType: EntityEnumDTO,
): GenericTypes.TranslationLabel =>
  entityTypeOptionTranslationLabelKeyMap[entityType] || entityType;

export const getEntityIcon = (
  entityType: EntityEnumDTO,
): FC<SVGProps<SVGSVGElement>> | FC => {
  switch (entityType) {
    case EntityEnumDTO.Property:
      return MapsPinIcon;
    case EntityEnumDTO.PropertyObject:
      return RealEstateBuildingHousesIcon;
    case EntityEnumDTO.Unit:
      return ArchitectureDoorIcon;
    default:
      return Fragment;
  }
};

export const getEntityUnitSuggestions$ = (
  query: string,
  entityType: EntityEnumDTO,
  city?: CitySerializerDTO['uuid'],
  country?: CountrySerializerDTO['uuid'],
  entity?: Entity['uuid'],
): Promise<AsyncAutocompleteOption<UnitSerializerDTO['uuid']>[]> => {
  if (!entity)
    return unitClient.getSuggestions$(query, undefined, country, city);

  switch (entityType) {
    case EntityEnumDTO.Property:
      return propertyClient.getHomeSuggestions$(entity, query);
    case EntityEnumDTO.PropertyObject:
      return propertyObjectClient.getHomeSuggestions$(entity, query);
    case EntityEnumDTO.Unit:
      return unitClient.getSuggestions$(query, undefined, country, city);
    default:
      return Promise.resolve([]);
  }
};
export const getEntityDetailsLink = (
  type: EntityEnumDTO,
  id: Entity['uuid'],
  category?: UnitCategoryPath,
): GenericTypes.URL => {
  switch (type) {
    case EntityEnumDTO.Unit: {
      return generatePath(UNIT_PATHS.DETAILS, {
        category,
        id,
      });
    }
    case EntityEnumDTO.Property: {
      return generatePath(PROPERTY_PATHS.DETAILS, { id });
    }
    case EntityEnumDTO.PropertyObject: {
      return generatePath(PROPERTY_OBJECT_PATHS.DETAILS, { id });
    }
    default: {
      return '';
    }
  }
};
