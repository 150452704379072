import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React from 'react';

import type { ServiceRequestDetailSerializerDTO } from '../../../../../../connectors/ticket';
import { FormButtons, GlobalError } from '../../../../../shared';
import type { RequestDetailsData, ServiceRequestValues } from '../../../types';
import { steps } from '../service-request-form.const';
import { sxProps } from './one-page-content.style';

type Props = {
  details?: RequestDetailsData<ServiceRequestDetailSerializerDTO>;
  globalErrorKey?: GenericTypes.TranslationLabel;
  onCancel?: () => void;
};

export const OnePageContent: FC<Props> = (props) => {
  const { details, globalErrorKey, onCancel } = props;
  const formProps = useFormikContext<ServiceRequestValues>();

  return (
    <>
      <GlobalError errorMessage={globalErrorKey} />
      {steps
        .filter((step, idx) => idx !== steps.length - 1)
        .map((step) => {
          const { component: Component, key } = step;

          return (
            <Grid item key={key} xs={12}>
              <Component details={details} isStepper={false} />
            </Grid>
          );
        })}
      <Grid item xs={12}>
        <FormButtons<ServiceRequestValues>
          formProps={formProps}
          onCancel={onCancel}
          submitLabelKey="ticket.details.buttons.update"
          sx={sxProps.buttons}
        />
      </Grid>
    </>
  );
};
