import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { ComplaintTypeEnumDTO } from '../../../../../connectors/ticket';
import { useTranslation } from '../../../../shared';
import { getComplaintTypeTranslationLabelKey } from '../../consts';

type Props = {
  type: ComplaintTypeEnumDTO[];
};

export const ComplaintTypeCell: FC<Props> = (props) => {
  const { type } = props;
  const { t } = useTranslation();
  const value = useMemo(
    () =>
      type
        .map((type) => t(getComplaintTypeTranslationLabelKey(type)))
        .join(', '),
    [t, type],
  );

  return <Typography variant="body2">{value}</Typography>;
};
