import type {
  ListNotificationSerializerDTO,
  NotificationSerializerDTO,
} from '../../../../connectors/notification';
import { NotificationsAPI } from '../../../../connectors/notification';
import { mieInstance } from '../../../mie.instance';
import { getEnvValue } from '../../helpers';

const PAGE_SIZE = 100;

class NotificationClient {
  private client;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.client = new NotificationsAPI(undefined, baeBath, mieInstance);
  }

  public clearAll$ = async (): Promise<void> => {
    await this.client.archiveNotificationsNotificationsArchivePost();
  };

  public getList$ = async (): Promise<ListNotificationSerializerDTO> => {
    const response = await this.client.getNotificationsNotificationsGet({
      order: '-created_at',
      pageSize: PAGE_SIZE,
    });

    return response.data;
  };

  public markAsSeen$ = async (
    id: NotificationSerializerDTO['uuid'],
  ): Promise<NotificationSerializerDTO> => {
    const response =
      await this.client.markNotificationAsSeenNotificationsNotificationUuidSeenPost(
        {
          notificationUuid: id,
        },
      );

    return response.data;
  };

  public markAllAsSeen$ = async (): Promise<ListNotificationSerializerDTO> => {
    const response =
      await this.client.markNotificationsAsSeenNotificationsSeenPost({
        pageSize: PAGE_SIZE,
      });

    return response.data;
  };
}

export const notificationClient = new NotificationClient();
