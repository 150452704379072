import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  field: {
    left: 0,
    ml: 2,
    mr: 2,
    position: 'absolute',
    right: 0,
    top: (theme) => theme.spacing(2),
    zIndex: 1,
  },
  input: {
    backgroundColor: (theme) => theme.palette.background.paper,
    border: 'unset',
    boxShadow:
      '0px 5px 8px -2px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.31)',
  },
};
