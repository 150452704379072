import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { FormField, useTranslation } from '../../../../../shared';
import type { ServiceRequestValues } from '../../../types';
import { RequestFormEffect } from '../../RequestFormEffect/request-form-effect.component';
import type { StepComponentProps } from '../service-request-form.type';
import { getFieldsetsConfig } from './details-step.model';

export const DetailsStep: FC<StepComponentProps> = (props) => {
  const { details, isStepper } = props;
  const { values } = useFormikContext<ServiceRequestValues>();
  const { t } = useTranslation();
  const fieldsetConfig = useMemo(
    () => getFieldsetsConfig(isStepper, details),
    [isStepper, details],
  );

  return (
    <Grid container spacing={3}>
      <RequestFormEffect />
      {fieldsetConfig.map((fieldset) => (
        <Grid
          item
          key={fieldset.key}
          {...(fieldset.size
            ? fieldset.size
            : { sm: isStepper ? 12 : 6, xs: 12 })}
        >
          <Typography variant="h5" marginBottom={0.5}>
            {t(fieldset.titleKey)}
          </Typography>
          {fieldset.textKey && (
            <Typography color="text.secondary" marginBottom={4} variant="body1">
              {t(fieldset.textKey)}
            </Typography>
          )}
          <Grid container marginBottom={2} spacing={3}>
            {fieldset.fields.map((config) => (
              <FormField<ServiceRequestValues>
                config={config}
                key={`fieldset-field-${config.field.name}`}
                values={values}
              />
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
