import { AttachmentVisibilityEnumDTO } from '../../../../../connectors/company';
import { documentVisibilitySelectOptions } from '../../../attachment/shared';
import type { CustomFile } from '../../fields';
import { DEFAULT_FIELD_VALUE } from '../../form.const';
import type { FieldConfig } from '../../types';

export type Values = {
  description: CustomFile['description'];
  title: CustomFile['title'];
  visibility: AttachmentVisibilityEnumDTO;
};

export const getFieldsConfig = (
  name: CustomFile['name'],
  description: CustomFile['description'],
  title: CustomFile['title'],
  // eslint-disable-next-line max-len
  visibility: AttachmentVisibilityEnumDTO = AttachmentVisibilityEnumDTO.Internal,
): FieldConfig<Values>[] => [
  {
    componentType: 'input',
    field: {
      initialValue: title || name || DEFAULT_FIELD_VALUE,
      name: 'title',
    },
    props: {
      labelKey: 'attachment.fields.title',
      type: 'text',
    },
  },
  {
    componentType: 'input',
    field: {
      initialValue: description || DEFAULT_FIELD_VALUE,
      name: 'description',
    },
    props: {
      labelKey: 'attachment.fields.description',
      type: 'textarea',
    },
  },
  {
    componentType: 'select',
    field: {
      initialValue: visibility || AttachmentVisibilityEnumDTO.Internal,
      name: 'visibility',
    },
    props: {
      labelKey: 'attachment.fields.visibilityLabel',
      options: documentVisibilitySelectOptions,
    },
  },
];
