import * as Sentry from '@sentry/react';
import type { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import type { QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery as useReactQuery } from 'react-query';

import { useAuth } from '../../+auth';
import { HttpStatus } from '../http';
import { useTranslation } from '../translations';

type Error = AxiosError['response'];

export const useQuery = <T>(
  key: QueryKey,
  callback$: () => Promise<AxiosResponse<T>['data'] | T>,
  options?: UseQueryOptions<T, Error>,
): UseQueryResult<T, Error> => {
  const query = useReactQuery<T, Error>(key, callback$, options);
  const { t } = useTranslation();
  const { logout } = useAuth();

  useEffect(() => {
    if (query.error?.status === HttpStatus.UNAUTHORIZED) {
      toast.error(t('errors.general.tokenExpired'));
      logout();
    }

    if (query.error?.data) {
      Sentry.captureException(query.error.data);
    }
  }, [query.error, logout, t]);

  return query;
};
