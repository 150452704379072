import Grid from '@mui/material/Grid';
import type { FC } from 'react';
import React from 'react';

import type { UnitAdvertisementSerializerDTO } from '../../../../../connectors/property';
import { Box, CollapseText, useAsync } from '../../../../shared';
import { Gallery } from '../Gallery/gallery.component';

export const OverviewBox: FC = () => {
  const { response } = useAsync<[UnitAdvertisementSerializerDTO]>();
  const [details] = response;

  return (
    <Box size="small">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Gallery />
        </Grid>
        <Grid item xs={12}>
          <CollapseText
            isTitle={false}
            text={details.advertisementDescription}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
