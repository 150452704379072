/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CommentSerializerDTO } from '../dto';
// @ts-ignore
import { CommentVisibilityEnumDTO } from '../dto';
// @ts-ignore
import { CreateProspectCommentSerializerDTO } from '../dto';
// @ts-ignore
import { CreateProspectSerializerDTO } from '../dto';
// @ts-ignore
import { EntityEnumDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { KYCProcessInitializationParamsSerializerDTO } from '../dto';
// @ts-ignore
import { KYCProcessInitializationResponseSerializerDTO } from '../dto';
// @ts-ignore
import { KYCProspectVerificationCreateSerializerDTO } from '../dto';
// @ts-ignore
import { KYCSimpleRentWebhookSerializerDTO } from '../dto';
// @ts-ignore
import { LeaseBaseParamsSerializerDTO } from '../dto';
// @ts-ignore
import { ListCommentSerializerDTO } from '../dto';
// @ts-ignore
import { ListCommentWithProspectSerializerDTO } from '../dto';
// @ts-ignore
import { ListKYCProspectVerificationSerializerDTO } from '../dto';
// @ts-ignore
import { ListProspectSerializerDTO } from '../dto';
// @ts-ignore
import { PatchProspectCommentSerializerDTO } from '../dto';
// @ts-ignore
import { PatchProspectSerializerDTO } from '../dto';
// @ts-ignore
import { ProspectConvertedIntoTenantResponseSerializerDTO } from '../dto';
// @ts-ignore
import { ProspectDetailsReadOnlySerializerDTO } from '../dto';
// @ts-ignore
import { ProspectDetailsSerializerDTO } from '../dto';
// @ts-ignore
import { ProspectVerificationSerializerDTO } from '../dto';
// @ts-ignore
import { ProspectsCountByStatusSerializerDTO } from '../dto';
// @ts-ignore
import { ViewingRequestSerializerDTO } from '../dto';
/**
 * ProspectsAPI - axios parameter creator
 * @export
 */
export const ProspectsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Prospect Comment
         * @param {string} prospectUuid 
         * @param {CreateProspectCommentSerializerDTO} createProspectCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProspectCommentProspectsProspectUuidCommentsPost: async (prospectUuid: string, createProspectCommentSerializerDTO: CreateProspectCommentSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('addProspectCommentProspectsProspectUuidCommentsPost', 'prospectUuid', prospectUuid)
            // verify required parameter 'createProspectCommentSerializerDTO' is not null or undefined
            assertParamExists('addProspectCommentProspectsProspectUuidCommentsPost', 'createProspectCommentSerializerDTO', createProspectCommentSerializerDTO)
            const localVarPath = `/prospects/{prospect_uuid}/comments`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProspectCommentSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Kyc Verification Process
         * @param {string} prospectUuid 
         * @param {string} kycProspectVerificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete: async (prospectUuid: string, kycProspectVerificationUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('cancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete', 'prospectUuid', prospectUuid)
            // verify required parameter 'kycProspectVerificationUuid' is not null or undefined
            assertParamExists('cancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete', 'kycProspectVerificationUuid', kycProspectVerificationUuid)
            const localVarPath = `/prospects/{prospect_uuid}/kyc/{kyc_prospect_verification_uuid}`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)))
                .replace(`{${"kyc_prospect_verification_uuid"}}`, encodeURIComponent(String(kycProspectVerificationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Prospect
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelProspectProspectsProspectUuidCancelPost: async (prospectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('cancelProspectProspectsProspectUuidCancelPost', 'prospectUuid', prospectUuid)
            const localVarPath = `/prospects/{prospect_uuid}/cancel`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Viewing
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelViewingProspectsProspectUuidViewingCancelPost: async (prospectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('cancelViewingProspectsProspectUuidViewingCancelPost', 'prospectUuid', prospectUuid)
            const localVarPath = `/prospects/{prospect_uuid}/viewing/cancel`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Kyc Prospect Verification
         * @param {string} prospectUuid 
         * @param {KYCProspectVerificationCreateSerializerDTO} kYCProspectVerificationCreateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKycProspectVerificationProspectsProspectUuidKycPost: async (prospectUuid: string, kYCProspectVerificationCreateSerializerDTO: KYCProspectVerificationCreateSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('createKycProspectVerificationProspectsProspectUuidKycPost', 'prospectUuid', prospectUuid)
            // verify required parameter 'kYCProspectVerificationCreateSerializerDTO' is not null or undefined
            assertParamExists('createKycProspectVerificationProspectsProspectUuidKycPost', 'kYCProspectVerificationCreateSerializerDTO', kYCProspectVerificationCreateSerializerDTO)
            const localVarPath = `/prospects/{prospect_uuid}/kyc`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kYCProspectVerificationCreateSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Prospect
         * @param {CreateProspectSerializerDTO} createProspectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProspectProspectsPost: async (createProspectSerializerDTO: CreateProspectSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProspectSerializerDTO' is not null or undefined
            assertParamExists('createProspectProspectsPost', 'createProspectSerializerDTO', createProspectSerializerDTO)
            const localVarPath = `/prospects/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProspectSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Prospect Comment
         * @param {string} prospectUuid 
         * @param {string} commentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete: async (prospectUuid: string, commentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('deleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete', 'prospectUuid', prospectUuid)
            // verify required parameter 'commentUuid' is not null or undefined
            assertParamExists('deleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete', 'commentUuid', commentUuid)
            const localVarPath = `/prospects/{prospect_uuid}/comments/{comment_uuid}`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)))
                .replace(`{${"comment_uuid"}}`, encodeURIComponent(String(commentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Kyc Certificate
         * @param {string} prospectUuid 
         * @param {string} kycProspectVerificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet: async (prospectUuid: string, kycProspectVerificationUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('downloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet', 'prospectUuid', prospectUuid)
            // verify required parameter 'kycProspectVerificationUuid' is not null or undefined
            assertParamExists('downloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet', 'kycProspectVerificationUuid', kycProspectVerificationUuid)
            const localVarPath = `/prospects/{prospect_uuid}/kyc/{kyc_prospect_verification_uuid}/download_certificate`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)))
                .replace(`{${"kyc_prospect_verification_uuid"}}`, encodeURIComponent(String(kycProspectVerificationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fail Viewing
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failViewingProspectsProspectUuidViewingFailPost: async (prospectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('failViewingProspectsProspectUuidViewingFailPost', 'prospectUuid', prospectUuid)
            const localVarPath = `/prospects/{prospect_uuid}/viewing/fail`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Comments By Country With Additional Prospect Data
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [userRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet: async (countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, userRole?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryUuid' is not null or undefined
            assertParamExists('getCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet', 'countryUuid', countryUuid)
            const localVarPath = `/prospects/comments/{country_uuid}`
                .replace(`{${"country_uuid"}}`, encodeURIComponent(String(countryUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (userRole !== undefined) {
                localVarQueryParameter['user_role'] = userRole;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Kyc Prospect Verification
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycProspectVerificationProspectsProspectUuidKycGet: async (prospectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('getKycProspectVerificationProspectsProspectUuidKycGet', 'prospectUuid', prospectUuid)
            const localVarPath = `/prospects/{prospect_uuid}/kyc`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Prospect Comments
         * @param {string} prospectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {CommentVisibilityEnumDTO} [visibility] 
         * @param {string} [entityUuid] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProspectCommentsProspectsProspectUuidCommentsGet: async (prospectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, visibility?: CommentVisibilityEnumDTO, entityUuid?: string, entityType?: EntityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('getProspectCommentsProspectsProspectUuidCommentsGet', 'prospectUuid', prospectUuid)
            const localVarPath = `/prospects/{prospect_uuid}/comments`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (visibility !== undefined) {
                localVarQueryParameter['visibility'] = visibility;
            }

            if (entityUuid !== undefined) {
                localVarQueryParameter['entity_uuid'] = entityUuid;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Prospects Count
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [uuid] 
         * @param {string} [userUuid] 
         * @param {string} [unitPromotionIntegrationUuid] 
         * @param {string} [unitPromotionUuid] 
         * @param {string} [status] 
         * @param {string} [agreedDatetimeForViewingLt] 
         * @param {string} [agreedDatetimeForViewingGt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProspectsCountProspectsCountsGet: async (updatedAtLte?: string, updatedAtGt?: string, search?: string, uuid?: string, userUuid?: string, unitPromotionIntegrationUuid?: string, unitPromotionUuid?: string, status?: string, agreedDatetimeForViewingLt?: string, agreedDatetimeForViewingGt?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/prospects/counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (userUuid !== undefined) {
                localVarQueryParameter['user_uuid'] = userUuid;
            }

            if (unitPromotionIntegrationUuid !== undefined) {
                localVarQueryParameter['unit_promotion_integration_uuid'] = unitPromotionIntegrationUuid;
            }

            if (unitPromotionUuid !== undefined) {
                localVarQueryParameter['unit_promotion_uuid'] = unitPromotionUuid;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (agreedDatetimeForViewingLt !== undefined) {
                localVarQueryParameter['agreed_datetime_for_viewing_lt'] = (agreedDatetimeForViewingLt as any instanceof Date) ?
                    (agreedDatetimeForViewingLt as any).toISOString() :
                    agreedDatetimeForViewingLt;
            }

            if (agreedDatetimeForViewingGt !== undefined) {
                localVarQueryParameter['agreed_datetime_for_viewing_gt'] = (agreedDatetimeForViewingGt as any instanceof Date) ?
                    (agreedDatetimeForViewingGt as any).toISOString() :
                    agreedDatetimeForViewingGt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Public Prospect Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProspectInfoProspectsPublicGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/prospects/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ProspectAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "ProspectAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Kyc Prospect Verification
         * @param {string} prospectUuid 
         * @param {string} kycProspectVerificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet: async (prospectUuid: string, kycProspectVerificationUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('getSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet', 'prospectUuid', prospectUuid)
            // verify required parameter 'kycProspectVerificationUuid' is not null or undefined
            assertParamExists('getSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet', 'kycProspectVerificationUuid', kycProspectVerificationUuid)
            const localVarPath = `/prospects/{prospect_uuid}/kyc/{kyc_prospect_verification_uuid}`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)))
                .replace(`{${"kyc_prospect_verification_uuid"}}`, encodeURIComponent(String(kycProspectVerificationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiate Kyc Verification Process
         * @param {string} prospectUuid 
         * @param {KYCProcessInitializationParamsSerializerDTO} kYCProcessInitializationParamsSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateKycVerificationProcessProspectsProspectUuidKycInitiatePost: async (prospectUuid: string, kYCProcessInitializationParamsSerializerDTO: KYCProcessInitializationParamsSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('initiateKycVerificationProcessProspectsProspectUuidKycInitiatePost', 'prospectUuid', prospectUuid)
            // verify required parameter 'kYCProcessInitializationParamsSerializerDTO' is not null or undefined
            assertParamExists('initiateKycVerificationProcessProspectsProspectUuidKycInitiatePost', 'kYCProcessInitializationParamsSerializerDTO', kYCProcessInitializationParamsSerializerDTO)
            const localVarPath = `/prospects/{prospect_uuid}/kyc/initiate`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kYCProcessInitializationParamsSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Kyc Webhook Simpl Rent
         * @param {string} kycProspectVerificationUuid 
         * @param {KYCSimpleRentWebhookSerializerDTO} kYCSimpleRentWebhookSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost: async (kycProspectVerificationUuid: string, kYCSimpleRentWebhookSerializerDTO: KYCSimpleRentWebhookSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kycProspectVerificationUuid' is not null or undefined
            assertParamExists('kycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost', 'kycProspectVerificationUuid', kycProspectVerificationUuid)
            // verify required parameter 'kYCSimpleRentWebhookSerializerDTO' is not null or undefined
            assertParamExists('kycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost', 'kYCSimpleRentWebhookSerializerDTO', kYCSimpleRentWebhookSerializerDTO)
            const localVarPath = `/prospects/webhook/simplrent/{kyc_prospect_verification_uuid}`
                .replace(`{${"kyc_prospect_verification_uuid"}}`, encodeURIComponent(String(kycProspectVerificationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kYCSimpleRentWebhookSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Prospects
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [uuid] 
         * @param {string} [userUuid] 
         * @param {string} [unitPromotionIntegrationUuid] 
         * @param {string} [unitPromotionUuid] 
         * @param {string} [status] 
         * @param {string} [agreedDatetimeForViewingLt] 
         * @param {string} [agreedDatetimeForViewingGt] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProspectsProspectsGet: async (updatedAtLte?: string, updatedAtGt?: string, search?: string, uuid?: string, userUuid?: string, unitPromotionIntegrationUuid?: string, unitPromotionUuid?: string, status?: string, agreedDatetimeForViewingLt?: string, agreedDatetimeForViewingGt?: string, page?: number, pageSize?: number, order?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/prospects/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (userUuid !== undefined) {
                localVarQueryParameter['user_uuid'] = userUuid;
            }

            if (unitPromotionIntegrationUuid !== undefined) {
                localVarQueryParameter['unit_promotion_integration_uuid'] = unitPromotionIntegrationUuid;
            }

            if (unitPromotionUuid !== undefined) {
                localVarQueryParameter['unit_promotion_uuid'] = unitPromotionUuid;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (agreedDatetimeForViewingLt !== undefined) {
                localVarQueryParameter['agreed_datetime_for_viewing_lt'] = (agreedDatetimeForViewingLt as any instanceof Date) ?
                    (agreedDatetimeForViewingLt as any).toISOString() :
                    agreedDatetimeForViewingLt;
            }

            if (agreedDatetimeForViewingGt !== undefined) {
                localVarQueryParameter['agreed_datetime_for_viewing_gt'] = (agreedDatetimeForViewingGt as any instanceof Date) ?
                    (agreedDatetimeForViewingGt as any).toISOString() :
                    agreedDatetimeForViewingGt;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Prospect Comment
         * @param {string} prospectUuid 
         * @param {string} commentUuid 
         * @param {PatchProspectCommentSerializerDTO} patchProspectCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch: async (prospectUuid: string, commentUuid: string, patchProspectCommentSerializerDTO: PatchProspectCommentSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('patchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch', 'prospectUuid', prospectUuid)
            // verify required parameter 'commentUuid' is not null or undefined
            assertParamExists('patchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch', 'commentUuid', commentUuid)
            // verify required parameter 'patchProspectCommentSerializerDTO' is not null or undefined
            assertParamExists('patchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch', 'patchProspectCommentSerializerDTO', patchProspectCommentSerializerDTO)
            const localVarPath = `/prospects/{prospect_uuid}/comments/{comment_uuid}`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)))
                .replace(`{${"comment_uuid"}}`, encodeURIComponent(String(commentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchProspectCommentSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Prospect
         * @param {string} prospectUuid 
         * @param {PatchProspectSerializerDTO} patchProspectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProspectProspectsProspectUuidPatch: async (prospectUuid: string, patchProspectSerializerDTO: PatchProspectSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('patchProspectProspectsProspectUuidPatch', 'prospectUuid', prospectUuid)
            // verify required parameter 'patchProspectSerializerDTO' is not null or undefined
            assertParamExists('patchProspectProspectsProspectUuidPatch', 'patchProspectSerializerDTO', patchProspectSerializerDTO)
            const localVarPath = `/prospects/{prospect_uuid}`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchProspectSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Prospect Convert Into Tenant
         * @param {string} prospectUuid 
         * @param {LeaseBaseParamsSerializerDTO} leaseBaseParamsSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prospectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost: async (prospectUuid: string, leaseBaseParamsSerializerDTO: LeaseBaseParamsSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('prospectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost', 'prospectUuid', prospectUuid)
            // verify required parameter 'leaseBaseParamsSerializerDTO' is not null or undefined
            assertParamExists('prospectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost', 'leaseBaseParamsSerializerDTO', leaseBaseParamsSerializerDTO)
            const localVarPath = `/prospects/{prospect_uuid}/convert_into_tenant`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leaseBaseParamsSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject Prospect
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectProspectProspectsProspectUuidRejectPost: async (prospectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('rejectProspectProspectsProspectUuidRejectPost', 'prospectUuid', prospectUuid)
            const localVarPath = `/prospects/{prospect_uuid}/reject`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request Viewing
         * @param {string} prospectUuid 
         * @param {ViewingRequestSerializerDTO} viewingRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestViewingProspectsProspectUuidViewingRequestPut: async (prospectUuid: string, viewingRequestSerializerDTO: ViewingRequestSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('requestViewingProspectsProspectUuidViewingRequestPut', 'prospectUuid', prospectUuid)
            // verify required parameter 'viewingRequestSerializerDTO' is not null or undefined
            assertParamExists('requestViewingProspectsProspectUuidViewingRequestPut', 'viewingRequestSerializerDTO', viewingRequestSerializerDTO)
            const localVarPath = `/prospects/{prospect_uuid}/viewing/request`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(viewingRequestSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve Prospect
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveProspectProspectsProspectUuidGet: async (prospectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('retrieveProspectProspectsProspectUuidGet', 'prospectUuid', prospectUuid)
            const localVarPath = `/prospects/{prospect_uuid}`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Prospect Status To Contract Sent
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProspectStatusToContractSentProspectsProspectUuidContractSentPost: async (prospectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('setProspectStatusToContractSentProspectsProspectUuidContractSentPost', 'prospectUuid', prospectUuid)
            const localVarPath = `/prospects/{prospect_uuid}/contract_sent`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Viewing Viewed
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewingViewedProspectsProspectUuidViewingViewedPost: async (prospectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectUuid' is not null or undefined
            assertParamExists('viewingViewedProspectsProspectUuidViewingViewedPost', 'prospectUuid', prospectUuid)
            const localVarPath = `/prospects/{prospect_uuid}/viewing/viewed`
                .replace(`{${"prospect_uuid"}}`, encodeURIComponent(String(prospectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProspectsAPI - functional programming interface
 * @export
 */
export const ProspectsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProspectsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Prospect Comment
         * @param {string} prospectUuid 
         * @param {CreateProspectCommentSerializerDTO} createProspectCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProspectCommentProspectsProspectUuidCommentsPost(prospectUuid: string, createProspectCommentSerializerDTO: CreateProspectCommentSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProspectCommentProspectsProspectUuidCommentsPost(prospectUuid, createProspectCommentSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel Kyc Verification Process
         * @param {string} prospectUuid 
         * @param {string} kycProspectVerificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete(prospectUuid: string, kycProspectVerificationUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete(prospectUuid, kycProspectVerificationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel Prospect
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelProspectProspectsProspectUuidCancelPost(prospectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectDetailsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelProspectProspectsProspectUuidCancelPost(prospectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel Viewing
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelViewingProspectsProspectUuidViewingCancelPost(prospectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectDetailsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelViewingProspectsProspectUuidViewingCancelPost(prospectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Kyc Prospect Verification
         * @param {string} prospectUuid 
         * @param {KYCProspectVerificationCreateSerializerDTO} kYCProspectVerificationCreateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createKycProspectVerificationProspectsProspectUuidKycPost(prospectUuid: string, kYCProspectVerificationCreateSerializerDTO: KYCProspectVerificationCreateSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectVerificationSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createKycProspectVerificationProspectsProspectUuidKycPost(prospectUuid, kYCProspectVerificationCreateSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Prospect
         * @param {CreateProspectSerializerDTO} createProspectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProspectProspectsPost(createProspectSerializerDTO: CreateProspectSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectDetailsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProspectProspectsPost(createProspectSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Prospect Comment
         * @param {string} prospectUuid 
         * @param {string} commentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete(prospectUuid: string, commentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete(prospectUuid, commentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Kyc Certificate
         * @param {string} prospectUuid 
         * @param {string} kycProspectVerificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet(prospectUuid: string, kycProspectVerificationUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet(prospectUuid, kycProspectVerificationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fail Viewing
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failViewingProspectsProspectUuidViewingFailPost(prospectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectDetailsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failViewingProspectsProspectUuidViewingFailPost(prospectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Comments By Country With Additional Prospect Data
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [userRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet(countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, userRole?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommentWithProspectSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet(countryUuid, page, pageSize, order, search, userRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Kyc Prospect Verification
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKycProspectVerificationProspectsProspectUuidKycGet(prospectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListKYCProspectVerificationSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKycProspectVerificationProspectsProspectUuidKycGet(prospectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Prospect Comments
         * @param {string} prospectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {CommentVisibilityEnumDTO} [visibility] 
         * @param {string} [entityUuid] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProspectCommentsProspectsProspectUuidCommentsGet(prospectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, visibility?: CommentVisibilityEnumDTO, entityUuid?: string, entityType?: EntityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProspectCommentsProspectsProspectUuidCommentsGet(prospectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, visibility, entityUuid, entityType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Prospects Count
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [uuid] 
         * @param {string} [userUuid] 
         * @param {string} [unitPromotionIntegrationUuid] 
         * @param {string} [unitPromotionUuid] 
         * @param {string} [status] 
         * @param {string} [agreedDatetimeForViewingLt] 
         * @param {string} [agreedDatetimeForViewingGt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProspectsCountProspectsCountsGet(updatedAtLte?: string, updatedAtGt?: string, search?: string, uuid?: string, userUuid?: string, unitPromotionIntegrationUuid?: string, unitPromotionUuid?: string, status?: string, agreedDatetimeForViewingLt?: string, agreedDatetimeForViewingGt?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectsCountByStatusSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProspectsCountProspectsCountsGet(updatedAtLte, updatedAtGt, search, uuid, userUuid, unitPromotionIntegrationUuid, unitPromotionUuid, status, agreedDatetimeForViewingLt, agreedDatetimeForViewingGt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Public Prospect Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicProspectInfoProspectsPublicGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectDetailsReadOnlySerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicProspectInfoProspectsPublicGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Kyc Prospect Verification
         * @param {string} prospectUuid 
         * @param {string} kycProspectVerificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet(prospectUuid: string, kycProspectVerificationUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectVerificationSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet(prospectUuid, kycProspectVerificationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initiate Kyc Verification Process
         * @param {string} prospectUuid 
         * @param {KYCProcessInitializationParamsSerializerDTO} kYCProcessInitializationParamsSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateKycVerificationProcessProspectsProspectUuidKycInitiatePost(prospectUuid: string, kYCProcessInitializationParamsSerializerDTO: KYCProcessInitializationParamsSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KYCProcessInitializationResponseSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateKycVerificationProcessProspectsProspectUuidKycInitiatePost(prospectUuid, kYCProcessInitializationParamsSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Kyc Webhook Simpl Rent
         * @param {string} kycProspectVerificationUuid 
         * @param {KYCSimpleRentWebhookSerializerDTO} kYCSimpleRentWebhookSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost(kycProspectVerificationUuid: string, kYCSimpleRentWebhookSerializerDTO: KYCSimpleRentWebhookSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost(kycProspectVerificationUuid, kYCSimpleRentWebhookSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List Prospects
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [uuid] 
         * @param {string} [userUuid] 
         * @param {string} [unitPromotionIntegrationUuid] 
         * @param {string} [unitPromotionUuid] 
         * @param {string} [status] 
         * @param {string} [agreedDatetimeForViewingLt] 
         * @param {string} [agreedDatetimeForViewingGt] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProspectsProspectsGet(updatedAtLte?: string, updatedAtGt?: string, search?: string, uuid?: string, userUuid?: string, unitPromotionIntegrationUuid?: string, unitPromotionUuid?: string, status?: string, agreedDatetimeForViewingLt?: string, agreedDatetimeForViewingGt?: string, page?: number, pageSize?: number, order?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListProspectSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProspectsProspectsGet(updatedAtLte, updatedAtGt, search, uuid, userUuid, unitPromotionIntegrationUuid, unitPromotionUuid, status, agreedDatetimeForViewingLt, agreedDatetimeForViewingGt, page, pageSize, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Prospect Comment
         * @param {string} prospectUuid 
         * @param {string} commentUuid 
         * @param {PatchProspectCommentSerializerDTO} patchProspectCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch(prospectUuid: string, commentUuid: string, patchProspectCommentSerializerDTO: PatchProspectCommentSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch(prospectUuid, commentUuid, patchProspectCommentSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Prospect
         * @param {string} prospectUuid 
         * @param {PatchProspectSerializerDTO} patchProspectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProspectProspectsProspectUuidPatch(prospectUuid: string, patchProspectSerializerDTO: PatchProspectSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectDetailsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProspectProspectsProspectUuidPatch(prospectUuid, patchProspectSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Prospect Convert Into Tenant
         * @param {string} prospectUuid 
         * @param {LeaseBaseParamsSerializerDTO} leaseBaseParamsSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prospectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost(prospectUuid: string, leaseBaseParamsSerializerDTO: LeaseBaseParamsSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectConvertedIntoTenantResponseSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prospectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost(prospectUuid, leaseBaseParamsSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reject Prospect
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectProspectProspectsProspectUuidRejectPost(prospectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectDetailsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectProspectProspectsProspectUuidRejectPost(prospectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request Viewing
         * @param {string} prospectUuid 
         * @param {ViewingRequestSerializerDTO} viewingRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestViewingProspectsProspectUuidViewingRequestPut(prospectUuid: string, viewingRequestSerializerDTO: ViewingRequestSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectDetailsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestViewingProspectsProspectUuidViewingRequestPut(prospectUuid, viewingRequestSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve Prospect
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveProspectProspectsProspectUuidGet(prospectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectDetailsReadOnlySerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveProspectProspectsProspectUuidGet(prospectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Prospect Status To Contract Sent
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProspectStatusToContractSentProspectsProspectUuidContractSentPost(prospectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectDetailsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProspectStatusToContractSentProspectsProspectUuidContractSentPost(prospectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Viewing Viewed
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewingViewedProspectsProspectUuidViewingViewedPost(prospectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectDetailsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewingViewedProspectsProspectUuidViewingViewedPost(prospectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProspectsAPI - factory interface
 * @export
 */
export const ProspectsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProspectsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Add Prospect Comment
         * @param {string} prospectUuid 
         * @param {CreateProspectCommentSerializerDTO} createProspectCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProspectCommentProspectsProspectUuidCommentsPost(prospectUuid: string, createProspectCommentSerializerDTO: CreateProspectCommentSerializerDTO, options?: any): AxiosPromise<CommentSerializerDTO> {
            return localVarFp.addProspectCommentProspectsProspectUuidCommentsPost(prospectUuid, createProspectCommentSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Kyc Verification Process
         * @param {string} prospectUuid 
         * @param {string} kycProspectVerificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete(prospectUuid: string, kycProspectVerificationUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete(prospectUuid, kycProspectVerificationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Prospect
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelProspectProspectsProspectUuidCancelPost(prospectUuid: string, options?: any): AxiosPromise<ProspectDetailsSerializerDTO> {
            return localVarFp.cancelProspectProspectsProspectUuidCancelPost(prospectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Viewing
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelViewingProspectsProspectUuidViewingCancelPost(prospectUuid: string, options?: any): AxiosPromise<ProspectDetailsSerializerDTO> {
            return localVarFp.cancelViewingProspectsProspectUuidViewingCancelPost(prospectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Kyc Prospect Verification
         * @param {string} prospectUuid 
         * @param {KYCProspectVerificationCreateSerializerDTO} kYCProspectVerificationCreateSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKycProspectVerificationProspectsProspectUuidKycPost(prospectUuid: string, kYCProspectVerificationCreateSerializerDTO: KYCProspectVerificationCreateSerializerDTO, options?: any): AxiosPromise<ProspectVerificationSerializerDTO> {
            return localVarFp.createKycProspectVerificationProspectsProspectUuidKycPost(prospectUuid, kYCProspectVerificationCreateSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Prospect
         * @param {CreateProspectSerializerDTO} createProspectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProspectProspectsPost(createProspectSerializerDTO: CreateProspectSerializerDTO, options?: any): AxiosPromise<ProspectDetailsSerializerDTO> {
            return localVarFp.createProspectProspectsPost(createProspectSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Prospect Comment
         * @param {string} prospectUuid 
         * @param {string} commentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete(prospectUuid: string, commentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete(prospectUuid, commentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Kyc Certificate
         * @param {string} prospectUuid 
         * @param {string} kycProspectVerificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet(prospectUuid: string, kycProspectVerificationUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet(prospectUuid, kycProspectVerificationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fail Viewing
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failViewingProspectsProspectUuidViewingFailPost(prospectUuid: string, options?: any): AxiosPromise<ProspectDetailsSerializerDTO> {
            return localVarFp.failViewingProspectsProspectUuidViewingFailPost(prospectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Comments By Country With Additional Prospect Data
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [userRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet(countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, userRole?: string, options?: any): AxiosPromise<ListCommentWithProspectSerializerDTO> {
            return localVarFp.getCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet(countryUuid, page, pageSize, order, search, userRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Kyc Prospect Verification
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycProspectVerificationProspectsProspectUuidKycGet(prospectUuid: string, options?: any): AxiosPromise<ListKYCProspectVerificationSerializerDTO> {
            return localVarFp.getKycProspectVerificationProspectsProspectUuidKycGet(prospectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Prospect Comments
         * @param {string} prospectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {CommentVisibilityEnumDTO} [visibility] 
         * @param {string} [entityUuid] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProspectCommentsProspectsProspectUuidCommentsGet(prospectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, visibility?: CommentVisibilityEnumDTO, entityUuid?: string, entityType?: EntityEnumDTO, options?: any): AxiosPromise<ListCommentSerializerDTO> {
            return localVarFp.getProspectCommentsProspectsProspectUuidCommentsGet(prospectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, visibility, entityUuid, entityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Prospects Count
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [uuid] 
         * @param {string} [userUuid] 
         * @param {string} [unitPromotionIntegrationUuid] 
         * @param {string} [unitPromotionUuid] 
         * @param {string} [status] 
         * @param {string} [agreedDatetimeForViewingLt] 
         * @param {string} [agreedDatetimeForViewingGt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProspectsCountProspectsCountsGet(updatedAtLte?: string, updatedAtGt?: string, search?: string, uuid?: string, userUuid?: string, unitPromotionIntegrationUuid?: string, unitPromotionUuid?: string, status?: string, agreedDatetimeForViewingLt?: string, agreedDatetimeForViewingGt?: string, options?: any): AxiosPromise<ProspectsCountByStatusSerializerDTO> {
            return localVarFp.getProspectsCountProspectsCountsGet(updatedAtLte, updatedAtGt, search, uuid, userUuid, unitPromotionIntegrationUuid, unitPromotionUuid, status, agreedDatetimeForViewingLt, agreedDatetimeForViewingGt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Public Prospect Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicProspectInfoProspectsPublicGet(options?: any): AxiosPromise<ProspectDetailsReadOnlySerializerDTO> {
            return localVarFp.getPublicProspectInfoProspectsPublicGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Kyc Prospect Verification
         * @param {string} prospectUuid 
         * @param {string} kycProspectVerificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet(prospectUuid: string, kycProspectVerificationUuid: string, options?: any): AxiosPromise<ProspectVerificationSerializerDTO> {
            return localVarFp.getSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet(prospectUuid, kycProspectVerificationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiate Kyc Verification Process
         * @param {string} prospectUuid 
         * @param {KYCProcessInitializationParamsSerializerDTO} kYCProcessInitializationParamsSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateKycVerificationProcessProspectsProspectUuidKycInitiatePost(prospectUuid: string, kYCProcessInitializationParamsSerializerDTO: KYCProcessInitializationParamsSerializerDTO, options?: any): AxiosPromise<KYCProcessInitializationResponseSerializerDTO> {
            return localVarFp.initiateKycVerificationProcessProspectsProspectUuidKycInitiatePost(prospectUuid, kYCProcessInitializationParamsSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Kyc Webhook Simpl Rent
         * @param {string} kycProspectVerificationUuid 
         * @param {KYCSimpleRentWebhookSerializerDTO} kYCSimpleRentWebhookSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost(kycProspectVerificationUuid: string, kYCSimpleRentWebhookSerializerDTO: KYCSimpleRentWebhookSerializerDTO, options?: any): AxiosPromise<void> {
            return localVarFp.kycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost(kycProspectVerificationUuid, kYCSimpleRentWebhookSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Prospects
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [uuid] 
         * @param {string} [userUuid] 
         * @param {string} [unitPromotionIntegrationUuid] 
         * @param {string} [unitPromotionUuid] 
         * @param {string} [status] 
         * @param {string} [agreedDatetimeForViewingLt] 
         * @param {string} [agreedDatetimeForViewingGt] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProspectsProspectsGet(updatedAtLte?: string, updatedAtGt?: string, search?: string, uuid?: string, userUuid?: string, unitPromotionIntegrationUuid?: string, unitPromotionUuid?: string, status?: string, agreedDatetimeForViewingLt?: string, agreedDatetimeForViewingGt?: string, page?: number, pageSize?: number, order?: string, options?: any): AxiosPromise<ListProspectSerializerDTO> {
            return localVarFp.listProspectsProspectsGet(updatedAtLte, updatedAtGt, search, uuid, userUuid, unitPromotionIntegrationUuid, unitPromotionUuid, status, agreedDatetimeForViewingLt, agreedDatetimeForViewingGt, page, pageSize, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Prospect Comment
         * @param {string} prospectUuid 
         * @param {string} commentUuid 
         * @param {PatchProspectCommentSerializerDTO} patchProspectCommentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch(prospectUuid: string, commentUuid: string, patchProspectCommentSerializerDTO: PatchProspectCommentSerializerDTO, options?: any): AxiosPromise<CommentSerializerDTO> {
            return localVarFp.patchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch(prospectUuid, commentUuid, patchProspectCommentSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Prospect
         * @param {string} prospectUuid 
         * @param {PatchProspectSerializerDTO} patchProspectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProspectProspectsProspectUuidPatch(prospectUuid: string, patchProspectSerializerDTO: PatchProspectSerializerDTO, options?: any): AxiosPromise<ProspectDetailsSerializerDTO> {
            return localVarFp.patchProspectProspectsProspectUuidPatch(prospectUuid, patchProspectSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Prospect Convert Into Tenant
         * @param {string} prospectUuid 
         * @param {LeaseBaseParamsSerializerDTO} leaseBaseParamsSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prospectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost(prospectUuid: string, leaseBaseParamsSerializerDTO: LeaseBaseParamsSerializerDTO, options?: any): AxiosPromise<ProspectConvertedIntoTenantResponseSerializerDTO> {
            return localVarFp.prospectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost(prospectUuid, leaseBaseParamsSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reject Prospect
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectProspectProspectsProspectUuidRejectPost(prospectUuid: string, options?: any): AxiosPromise<ProspectDetailsSerializerDTO> {
            return localVarFp.rejectProspectProspectsProspectUuidRejectPost(prospectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request Viewing
         * @param {string} prospectUuid 
         * @param {ViewingRequestSerializerDTO} viewingRequestSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestViewingProspectsProspectUuidViewingRequestPut(prospectUuid: string, viewingRequestSerializerDTO: ViewingRequestSerializerDTO, options?: any): AxiosPromise<ProspectDetailsSerializerDTO> {
            return localVarFp.requestViewingProspectsProspectUuidViewingRequestPut(prospectUuid, viewingRequestSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve Prospect
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveProspectProspectsProspectUuidGet(prospectUuid: string, options?: any): AxiosPromise<ProspectDetailsReadOnlySerializerDTO> {
            return localVarFp.retrieveProspectProspectsProspectUuidGet(prospectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Prospect Status To Contract Sent
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProspectStatusToContractSentProspectsProspectUuidContractSentPost(prospectUuid: string, options?: any): AxiosPromise<ProspectDetailsSerializerDTO> {
            return localVarFp.setProspectStatusToContractSentProspectsProspectUuidContractSentPost(prospectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Viewing Viewed
         * @param {string} prospectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewingViewedProspectsProspectUuidViewingViewedPost(prospectUuid: string, options?: any): AxiosPromise<ProspectDetailsSerializerDTO> {
            return localVarFp.viewingViewedProspectsProspectUuidViewingViewedPost(prospectUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addProspectCommentProspectsProspectUuidCommentsPost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIAddProspectCommentProspectsProspectUuidCommentsPostRequest
 */
export interface ProspectsAPIAddProspectCommentProspectsProspectUuidCommentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIAddProspectCommentProspectsProspectUuidCommentsPost
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {CreateProspectCommentSerializerDTO}
     * @memberof ProspectsAPIAddProspectCommentProspectsProspectUuidCommentsPost
     */
    readonly createProspectCommentSerializerDTO: CreateProspectCommentSerializerDTO
}

/**
 * Request parameters for cancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPICancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDeleteRequest
 */
export interface ProspectsAPICancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPICancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPICancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete
     */
    readonly kycProspectVerificationUuid: string
}

/**
 * Request parameters for cancelProspectProspectsProspectUuidCancelPost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPICancelProspectProspectsProspectUuidCancelPostRequest
 */
export interface ProspectsAPICancelProspectProspectsProspectUuidCancelPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPICancelProspectProspectsProspectUuidCancelPost
     */
    readonly prospectUuid: string
}

/**
 * Request parameters for cancelViewingProspectsProspectUuidViewingCancelPost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPICancelViewingProspectsProspectUuidViewingCancelPostRequest
 */
export interface ProspectsAPICancelViewingProspectsProspectUuidViewingCancelPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPICancelViewingProspectsProspectUuidViewingCancelPost
     */
    readonly prospectUuid: string
}

/**
 * Request parameters for createKycProspectVerificationProspectsProspectUuidKycPost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPICreateKycProspectVerificationProspectsProspectUuidKycPostRequest
 */
export interface ProspectsAPICreateKycProspectVerificationProspectsProspectUuidKycPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPICreateKycProspectVerificationProspectsProspectUuidKycPost
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {KYCProspectVerificationCreateSerializerDTO}
     * @memberof ProspectsAPICreateKycProspectVerificationProspectsProspectUuidKycPost
     */
    readonly kYCProspectVerificationCreateSerializerDTO: KYCProspectVerificationCreateSerializerDTO
}

/**
 * Request parameters for createProspectProspectsPost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPICreateProspectProspectsPostRequest
 */
export interface ProspectsAPICreateProspectProspectsPostRequest {
    /**
     * 
     * @type {CreateProspectSerializerDTO}
     * @memberof ProspectsAPICreateProspectProspectsPost
     */
    readonly createProspectSerializerDTO: CreateProspectSerializerDTO
}

/**
 * Request parameters for deleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIDeleteProspectCommentProspectsProspectUuidCommentsCommentUuidDeleteRequest
 */
export interface ProspectsAPIDeleteProspectCommentProspectsProspectUuidCommentsCommentUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIDeleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIDeleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete
     */
    readonly commentUuid: string
}

/**
 * Request parameters for downloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIDownloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGetRequest
 */
export interface ProspectsAPIDownloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIDownloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIDownloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet
     */
    readonly kycProspectVerificationUuid: string
}

/**
 * Request parameters for failViewingProspectsProspectUuidViewingFailPost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIFailViewingProspectsProspectUuidViewingFailPostRequest
 */
export interface ProspectsAPIFailViewingProspectsProspectUuidViewingFailPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIFailViewingProspectsProspectUuidViewingFailPost
     */
    readonly prospectUuid: string
}

/**
 * Request parameters for getCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIGetCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGetRequest
 */
export interface ProspectsAPIGetCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet
     */
    readonly countryUuid: string

    /**
     * 
     * @type {number}
     * @memberof ProspectsAPIGetCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ProspectsAPIGetCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet
     */
    readonly userRole?: string
}

/**
 * Request parameters for getKycProspectVerificationProspectsProspectUuidKycGet operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIGetKycProspectVerificationProspectsProspectUuidKycGetRequest
 */
export interface ProspectsAPIGetKycProspectVerificationProspectsProspectUuidKycGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetKycProspectVerificationProspectsProspectUuidKycGet
     */
    readonly prospectUuid: string
}

/**
 * Request parameters for getProspectCommentsProspectsProspectUuidCommentsGet operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGetRequest
 */
export interface ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGet
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {number}
     * @memberof ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {CommentVisibilityEnumDTO}
     * @memberof ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGet
     */
    readonly visibility?: CommentVisibilityEnumDTO

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGet
     */
    readonly entityUuid?: string

    /**
     * 
     * @type {EntityEnumDTO}
     * @memberof ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGet
     */
    readonly entityType?: EntityEnumDTO
}

/**
 * Request parameters for getProspectsCountProspectsCountsGet operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIGetProspectsCountProspectsCountsGetRequest
 */
export interface ProspectsAPIGetProspectsCountProspectsCountsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectsCountProspectsCountsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectsCountProspectsCountsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectsCountProspectsCountsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectsCountProspectsCountsGet
     */
    readonly uuid?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectsCountProspectsCountsGet
     */
    readonly userUuid?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectsCountProspectsCountsGet
     */
    readonly unitPromotionIntegrationUuid?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectsCountProspectsCountsGet
     */
    readonly unitPromotionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectsCountProspectsCountsGet
     */
    readonly status?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectsCountProspectsCountsGet
     */
    readonly agreedDatetimeForViewingLt?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetProspectsCountProspectsCountsGet
     */
    readonly agreedDatetimeForViewingGt?: string
}

/**
 * Request parameters for getSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIGetSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGetRequest
 */
export interface ProspectsAPIGetSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIGetSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet
     */
    readonly kycProspectVerificationUuid: string
}

/**
 * Request parameters for initiateKycVerificationProcessProspectsProspectUuidKycInitiatePost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIInitiateKycVerificationProcessProspectsProspectUuidKycInitiatePostRequest
 */
export interface ProspectsAPIInitiateKycVerificationProcessProspectsProspectUuidKycInitiatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIInitiateKycVerificationProcessProspectsProspectUuidKycInitiatePost
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {KYCProcessInitializationParamsSerializerDTO}
     * @memberof ProspectsAPIInitiateKycVerificationProcessProspectsProspectUuidKycInitiatePost
     */
    readonly kYCProcessInitializationParamsSerializerDTO: KYCProcessInitializationParamsSerializerDTO
}

/**
 * Request parameters for kycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIKycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPostRequest
 */
export interface ProspectsAPIKycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIKycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost
     */
    readonly kycProspectVerificationUuid: string

    /**
     * 
     * @type {KYCSimpleRentWebhookSerializerDTO}
     * @memberof ProspectsAPIKycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost
     */
    readonly kYCSimpleRentWebhookSerializerDTO: KYCSimpleRentWebhookSerializerDTO
}

/**
 * Request parameters for listProspectsProspectsGet operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIListProspectsProspectsGetRequest
 */
export interface ProspectsAPIListProspectsProspectsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly uuid?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly userUuid?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly unitPromotionIntegrationUuid?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly unitPromotionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly status?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly agreedDatetimeForViewingLt?: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly agreedDatetimeForViewingGt?: string

    /**
     * 
     * @type {number}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIListProspectsProspectsGet
     */
    readonly order?: string
}

/**
 * Request parameters for patchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIPatchProspectCommentProspectsProspectUuidCommentsCommentUuidPatchRequest
 */
export interface ProspectsAPIPatchProspectCommentProspectsProspectUuidCommentsCommentUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIPatchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIPatchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch
     */
    readonly commentUuid: string

    /**
     * 
     * @type {PatchProspectCommentSerializerDTO}
     * @memberof ProspectsAPIPatchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch
     */
    readonly patchProspectCommentSerializerDTO: PatchProspectCommentSerializerDTO
}

/**
 * Request parameters for patchProspectProspectsProspectUuidPatch operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIPatchProspectProspectsProspectUuidPatchRequest
 */
export interface ProspectsAPIPatchProspectProspectsProspectUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIPatchProspectProspectsProspectUuidPatch
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {PatchProspectSerializerDTO}
     * @memberof ProspectsAPIPatchProspectProspectsProspectUuidPatch
     */
    readonly patchProspectSerializerDTO: PatchProspectSerializerDTO
}

/**
 * Request parameters for prospectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIProspectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPostRequest
 */
export interface ProspectsAPIProspectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIProspectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {LeaseBaseParamsSerializerDTO}
     * @memberof ProspectsAPIProspectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost
     */
    readonly leaseBaseParamsSerializerDTO: LeaseBaseParamsSerializerDTO
}

/**
 * Request parameters for rejectProspectProspectsProspectUuidRejectPost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIRejectProspectProspectsProspectUuidRejectPostRequest
 */
export interface ProspectsAPIRejectProspectProspectsProspectUuidRejectPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIRejectProspectProspectsProspectUuidRejectPost
     */
    readonly prospectUuid: string
}

/**
 * Request parameters for requestViewingProspectsProspectUuidViewingRequestPut operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIRequestViewingProspectsProspectUuidViewingRequestPutRequest
 */
export interface ProspectsAPIRequestViewingProspectsProspectUuidViewingRequestPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIRequestViewingProspectsProspectUuidViewingRequestPut
     */
    readonly prospectUuid: string

    /**
     * 
     * @type {ViewingRequestSerializerDTO}
     * @memberof ProspectsAPIRequestViewingProspectsProspectUuidViewingRequestPut
     */
    readonly viewingRequestSerializerDTO: ViewingRequestSerializerDTO
}

/**
 * Request parameters for retrieveProspectProspectsProspectUuidGet operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIRetrieveProspectProspectsProspectUuidGetRequest
 */
export interface ProspectsAPIRetrieveProspectProspectsProspectUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIRetrieveProspectProspectsProspectUuidGet
     */
    readonly prospectUuid: string
}

/**
 * Request parameters for setProspectStatusToContractSentProspectsProspectUuidContractSentPost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPISetProspectStatusToContractSentProspectsProspectUuidContractSentPostRequest
 */
export interface ProspectsAPISetProspectStatusToContractSentProspectsProspectUuidContractSentPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPISetProspectStatusToContractSentProspectsProspectUuidContractSentPost
     */
    readonly prospectUuid: string
}

/**
 * Request parameters for viewingViewedProspectsProspectUuidViewingViewedPost operation in ProspectsAPI.
 * @export
 * @interface ProspectsAPIViewingViewedProspectsProspectUuidViewingViewedPostRequest
 */
export interface ProspectsAPIViewingViewedProspectsProspectUuidViewingViewedPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProspectsAPIViewingViewedProspectsProspectUuidViewingViewedPost
     */
    readonly prospectUuid: string
}

/**
 * ProspectsAPI - object-oriented interface
 * @export
 * @class ProspectsAPI
 * @extends {BaseAPI}
 */
export class ProspectsAPI extends BaseAPI {
    /**
     * 
     * @summary Add Prospect Comment
     * @param {ProspectsAPIAddProspectCommentProspectsProspectUuidCommentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public addProspectCommentProspectsProspectUuidCommentsPost(requestParameters: ProspectsAPIAddProspectCommentProspectsProspectUuidCommentsPostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).addProspectCommentProspectsProspectUuidCommentsPost(requestParameters.prospectUuid, requestParameters.createProspectCommentSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Kyc Verification Process
     * @param {ProspectsAPICancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public cancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete(requestParameters: ProspectsAPICancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDeleteRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).cancelKycVerificationProcessProspectsProspectUuidKycKycProspectVerificationUuidDelete(requestParameters.prospectUuid, requestParameters.kycProspectVerificationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Prospect
     * @param {ProspectsAPICancelProspectProspectsProspectUuidCancelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public cancelProspectProspectsProspectUuidCancelPost(requestParameters: ProspectsAPICancelProspectProspectsProspectUuidCancelPostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).cancelProspectProspectsProspectUuidCancelPost(requestParameters.prospectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Viewing
     * @param {ProspectsAPICancelViewingProspectsProspectUuidViewingCancelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public cancelViewingProspectsProspectUuidViewingCancelPost(requestParameters: ProspectsAPICancelViewingProspectsProspectUuidViewingCancelPostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).cancelViewingProspectsProspectUuidViewingCancelPost(requestParameters.prospectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Kyc Prospect Verification
     * @param {ProspectsAPICreateKycProspectVerificationProspectsProspectUuidKycPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public createKycProspectVerificationProspectsProspectUuidKycPost(requestParameters: ProspectsAPICreateKycProspectVerificationProspectsProspectUuidKycPostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).createKycProspectVerificationProspectsProspectUuidKycPost(requestParameters.prospectUuid, requestParameters.kYCProspectVerificationCreateSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Prospect
     * @param {ProspectsAPICreateProspectProspectsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public createProspectProspectsPost(requestParameters: ProspectsAPICreateProspectProspectsPostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).createProspectProspectsPost(requestParameters.createProspectSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Prospect Comment
     * @param {ProspectsAPIDeleteProspectCommentProspectsProspectUuidCommentsCommentUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public deleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete(requestParameters: ProspectsAPIDeleteProspectCommentProspectsProspectUuidCommentsCommentUuidDeleteRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).deleteProspectCommentProspectsProspectUuidCommentsCommentUuidDelete(requestParameters.prospectUuid, requestParameters.commentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Kyc Certificate
     * @param {ProspectsAPIDownloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public downloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet(requestParameters: ProspectsAPIDownloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGetRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).downloadKycCertificateProspectsProspectUuidKycKycProspectVerificationUuidDownloadCertificateGet(requestParameters.prospectUuid, requestParameters.kycProspectVerificationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fail Viewing
     * @param {ProspectsAPIFailViewingProspectsProspectUuidViewingFailPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public failViewingProspectsProspectUuidViewingFailPost(requestParameters: ProspectsAPIFailViewingProspectsProspectUuidViewingFailPostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).failViewingProspectsProspectUuidViewingFailPost(requestParameters.prospectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Comments By Country With Additional Prospect Data
     * @param {ProspectsAPIGetCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public getCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet(requestParameters: ProspectsAPIGetCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGetRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).getCommentsByCountryWithAdditionalProspectDataProspectsCommentsCountryUuidGet(requestParameters.countryUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.search, requestParameters.userRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Kyc Prospect Verification
     * @param {ProspectsAPIGetKycProspectVerificationProspectsProspectUuidKycGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public getKycProspectVerificationProspectsProspectUuidKycGet(requestParameters: ProspectsAPIGetKycProspectVerificationProspectsProspectUuidKycGetRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).getKycProspectVerificationProspectsProspectUuidKycGet(requestParameters.prospectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Prospect Comments
     * @param {ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public getProspectCommentsProspectsProspectUuidCommentsGet(requestParameters: ProspectsAPIGetProspectCommentsProspectsProspectUuidCommentsGetRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).getProspectCommentsProspectsProspectUuidCommentsGet(requestParameters.prospectUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.visibility, requestParameters.entityUuid, requestParameters.entityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Prospects Count
     * @param {ProspectsAPIGetProspectsCountProspectsCountsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public getProspectsCountProspectsCountsGet(requestParameters: ProspectsAPIGetProspectsCountProspectsCountsGetRequest = {}, options?: any) {
        return ProspectsAPIFp(this.configuration).getProspectsCountProspectsCountsGet(requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.uuid, requestParameters.userUuid, requestParameters.unitPromotionIntegrationUuid, requestParameters.unitPromotionUuid, requestParameters.status, requestParameters.agreedDatetimeForViewingLt, requestParameters.agreedDatetimeForViewingGt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Public Prospect Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public getPublicProspectInfoProspectsPublicGet(options?: any) {
        return ProspectsAPIFp(this.configuration).getPublicProspectInfoProspectsPublicGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Kyc Prospect Verification
     * @param {ProspectsAPIGetSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public getSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet(requestParameters: ProspectsAPIGetSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGetRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).getSingleKycProspectVerificationProspectsProspectUuidKycKycProspectVerificationUuidGet(requestParameters.prospectUuid, requestParameters.kycProspectVerificationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initiate Kyc Verification Process
     * @param {ProspectsAPIInitiateKycVerificationProcessProspectsProspectUuidKycInitiatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public initiateKycVerificationProcessProspectsProspectUuidKycInitiatePost(requestParameters: ProspectsAPIInitiateKycVerificationProcessProspectsProspectUuidKycInitiatePostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).initiateKycVerificationProcessProspectsProspectUuidKycInitiatePost(requestParameters.prospectUuid, requestParameters.kYCProcessInitializationParamsSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Kyc Webhook Simpl Rent
     * @param {ProspectsAPIKycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public kycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost(requestParameters: ProspectsAPIKycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).kycWebhookSimplRentProspectsWebhookSimplrentKycProspectVerificationUuidPost(requestParameters.kycProspectVerificationUuid, requestParameters.kYCSimpleRentWebhookSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Prospects
     * @param {ProspectsAPIListProspectsProspectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public listProspectsProspectsGet(requestParameters: ProspectsAPIListProspectsProspectsGetRequest = {}, options?: any) {
        return ProspectsAPIFp(this.configuration).listProspectsProspectsGet(requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.uuid, requestParameters.userUuid, requestParameters.unitPromotionIntegrationUuid, requestParameters.unitPromotionUuid, requestParameters.status, requestParameters.agreedDatetimeForViewingLt, requestParameters.agreedDatetimeForViewingGt, requestParameters.page, requestParameters.pageSize, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Prospect Comment
     * @param {ProspectsAPIPatchProspectCommentProspectsProspectUuidCommentsCommentUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public patchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch(requestParameters: ProspectsAPIPatchProspectCommentProspectsProspectUuidCommentsCommentUuidPatchRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).patchProspectCommentProspectsProspectUuidCommentsCommentUuidPatch(requestParameters.prospectUuid, requestParameters.commentUuid, requestParameters.patchProspectCommentSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Prospect
     * @param {ProspectsAPIPatchProspectProspectsProspectUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public patchProspectProspectsProspectUuidPatch(requestParameters: ProspectsAPIPatchProspectProspectsProspectUuidPatchRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).patchProspectProspectsProspectUuidPatch(requestParameters.prospectUuid, requestParameters.patchProspectSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Prospect Convert Into Tenant
     * @param {ProspectsAPIProspectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public prospectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost(requestParameters: ProspectsAPIProspectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).prospectConvertIntoTenantProspectsProspectUuidConvertIntoTenantPost(requestParameters.prospectUuid, requestParameters.leaseBaseParamsSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reject Prospect
     * @param {ProspectsAPIRejectProspectProspectsProspectUuidRejectPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public rejectProspectProspectsProspectUuidRejectPost(requestParameters: ProspectsAPIRejectProspectProspectsProspectUuidRejectPostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).rejectProspectProspectsProspectUuidRejectPost(requestParameters.prospectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request Viewing
     * @param {ProspectsAPIRequestViewingProspectsProspectUuidViewingRequestPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public requestViewingProspectsProspectUuidViewingRequestPut(requestParameters: ProspectsAPIRequestViewingProspectsProspectUuidViewingRequestPutRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).requestViewingProspectsProspectUuidViewingRequestPut(requestParameters.prospectUuid, requestParameters.viewingRequestSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve Prospect
     * @param {ProspectsAPIRetrieveProspectProspectsProspectUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public retrieveProspectProspectsProspectUuidGet(requestParameters: ProspectsAPIRetrieveProspectProspectsProspectUuidGetRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).retrieveProspectProspectsProspectUuidGet(requestParameters.prospectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Prospect Status To Contract Sent
     * @param {ProspectsAPISetProspectStatusToContractSentProspectsProspectUuidContractSentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public setProspectStatusToContractSentProspectsProspectUuidContractSentPost(requestParameters: ProspectsAPISetProspectStatusToContractSentProspectsProspectUuidContractSentPostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).setProspectStatusToContractSentProspectsProspectUuidContractSentPost(requestParameters.prospectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Viewing Viewed
     * @param {ProspectsAPIViewingViewedProspectsProspectUuidViewingViewedPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsAPI
     */
    public viewingViewedProspectsProspectUuidViewingViewedPost(requestParameters: ProspectsAPIViewingViewedProspectsProspectUuidViewingViewedPostRequest, options?: any) {
        return ProspectsAPIFp(this.configuration).viewingViewedProspectsProspectUuidViewingViewedPost(requestParameters.prospectUuid, options).then((request) => request(this.axios, this.basePath));
    }
}
