/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttachmentCategoryEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { AttachmentTypeEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentVisibilityEnumDTO } from '../dto';
// @ts-ignore
import { CompanyDetailSerializerDTO } from '../dto';
// @ts-ignore
import { CompanySerializerDTO } from '../dto';
// @ts-ignore
import { CreateCompanySerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { ListAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ListCompaniesSerializerDTO } from '../dto';
// @ts-ignore
import { ListLogSerializerDTO } from '../dto';
// @ts-ignore
import { PatchCompanySerializerDTO } from '../dto';
/**
 * CompaniesAPI - axios parameter creator
 * @export
 */
export const CompaniesAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Company
         * @param {CreateCompanySerializerDTO} createCompanySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyCompaniesPost: async (createCompanySerializerDTO: CreateCompanySerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanySerializerDTO' is not null or undefined
            assertParamExists('createCompanyCompaniesPost', 'createCompanySerializerDTO', createCompanySerializerDTO)
            const localVarPath = `/companies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanySerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Company Attachment
         * @param {string} companyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete: async (companyUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyUuid' is not null or undefined
            assertParamExists('deleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete', 'companyUuid', companyUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('deleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/companies/{company_uuid}/attachments/{attachment_uuid}`
                .replace(`{${"company_uuid"}}`, encodeURIComponent(String(companyUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Company
         * @param {string} companyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyCompaniesCompanyUuidDelete: async (companyUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyUuid' is not null or undefined
            assertParamExists('deleteCompanyCompaniesCompanyUuidDelete', 'companyUuid', companyUuid)
            const localVarPath = `/companies/{company_uuid}`
                .replace(`{${"company_uuid"}}`, encodeURIComponent(String(companyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Company Attachment
         * @param {string} companyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet: async (companyUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyUuid' is not null or undefined
            assertParamExists('downloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet', 'companyUuid', companyUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('downloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/companies/{company_uuid}/attachments/{attachment_uuid}/download`
                .replace(`{${"company_uuid"}}`, encodeURIComponent(String(companyUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Companies
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {string} [organizationNumber] 
         * @param {string} [addressLine1] 
         * @param {string} [addressLine2] 
         * @param {string} [postalCode] 
         * @param {string} [countryUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompaniesGet: async (page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, name?: string, organizationNumber?: string, addressLine1?: string, addressLine2?: string, postalCode?: string, countryUuid?: string, cityUuid?: string, regionUuid?: string, municipalityUuid?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (organizationNumber !== undefined) {
                localVarQueryParameter['organization_number'] = organizationNumber;
            }

            if (addressLine1 !== undefined) {
                localVarQueryParameter['address_line_1'] = addressLine1;
            }

            if (addressLine2 !== undefined) {
                localVarQueryParameter['address_line_2'] = addressLine2;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postal_code'] = postalCode;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (municipalityUuid !== undefined) {
                localVarQueryParameter['municipality_uuid'] = municipalityUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Company Attachments
         * @param {string} companyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet: async (companyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyUuid' is not null or undefined
            assertParamExists('getCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet', 'companyUuid', companyUuid)
            const localVarPath = `/companies/{company_uuid}/attachments`
                .replace(`{${"company_uuid"}}`, encodeURIComponent(String(companyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Company Auditlog
         * @param {string} companyUuid 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet: async (companyUuid: string, excludeFields?: string, page?: number, pageSize?: number, order?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyUuid' is not null or undefined
            assertParamExists('getSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet', 'companyUuid', companyUuid)
            const localVarPath = `/companies/{company_uuid}/auditlogs`
                .replace(`{${"company_uuid"}}`, encodeURIComponent(String(companyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (excludeFields !== undefined) {
                localVarQueryParameter['exclude_fields'] = excludeFields;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Company
         * @param {string} companyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleCompanyCompaniesCompanyUuidGet: async (companyUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyUuid' is not null or undefined
            assertParamExists('getSingleCompanyCompaniesCompanyUuidGet', 'companyUuid', companyUuid)
            const localVarPath = `/companies/{company_uuid}`
                .replace(`{${"company_uuid"}}`, encodeURIComponent(String(companyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Company
         * @param {string} companyUuid 
         * @param {PatchCompanySerializerDTO} patchCompanySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompanyCompaniesCompanyUuidPatch: async (companyUuid: string, patchCompanySerializerDTO: PatchCompanySerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyUuid' is not null or undefined
            assertParamExists('patchCompanyCompaniesCompanyUuidPatch', 'companyUuid', companyUuid)
            // verify required parameter 'patchCompanySerializerDTO' is not null or undefined
            assertParamExists('patchCompanyCompaniesCompanyUuidPatch', 'patchCompanySerializerDTO', patchCompanySerializerDTO)
            const localVarPath = `/companies/{company_uuid}`
                .replace(`{${"company_uuid"}}`, encodeURIComponent(String(companyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCompanySerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Company
         * @param {string} companyUuid 
         * @param {CreateCompanySerializerDTO} createCompanySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyCompaniesCompanyUuidPut: async (companyUuid: string, createCompanySerializerDTO: CreateCompanySerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyUuid' is not null or undefined
            assertParamExists('putCompanyCompaniesCompanyUuidPut', 'companyUuid', companyUuid)
            // verify required parameter 'createCompanySerializerDTO' is not null or undefined
            assertParamExists('putCompanyCompaniesCompanyUuidPut', 'createCompanySerializerDTO', createCompanySerializerDTO)
            const localVarPath = `/companies/{company_uuid}`
                .replace(`{${"company_uuid"}}`, encodeURIComponent(String(companyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanySerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Company Attachment
         * @param {string} companyUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost: async (companyUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyUuid' is not null or undefined
            assertParamExists('uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost', 'companyUuid', companyUuid)
            // verify required parameter 'attachmentCategory' is not null or undefined
            assertParamExists('uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost', 'attachmentCategory', attachmentCategory)
            // verify required parameter 'attachmentType' is not null or undefined
            assertParamExists('uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost', 'attachmentType', attachmentType)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost', 'files', files)
            const localVarPath = `/companies/{company_uuid}/attachments`
                .replace(`{${"company_uuid"}}`, encodeURIComponent(String(companyUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


            if (attachmentCategory !== undefined) { 
                localVarFormParams.append('attachment_category', new Blob([JSON.stringify(attachmentCategory)], { type: "application/json", }));
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', new Blob([JSON.stringify(attachmentType)], { type: "application/json", }));
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (visibility !== undefined) { 
                localVarFormParams.append('visibility', new Blob([JSON.stringify(visibility)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesAPI - functional programming interface
 * @export
 */
export const CompaniesAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Company
         * @param {CreateCompanySerializerDTO} createCompanySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyCompaniesPost(createCompanySerializerDTO: CreateCompanySerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyCompaniesPost(createCompanySerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Company Attachment
         * @param {string} companyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete(companyUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete(companyUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Company
         * @param {string} companyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyCompaniesCompanyUuidDelete(companyUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyCompaniesCompanyUuidDelete(companyUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Company Attachment
         * @param {string} companyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet(companyUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet(companyUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Companies
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {string} [organizationNumber] 
         * @param {string} [addressLine1] 
         * @param {string} [addressLine2] 
         * @param {string} [postalCode] 
         * @param {string} [countryUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompaniesGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, name?: string, organizationNumber?: string, addressLine1?: string, addressLine2?: string, postalCode?: string, countryUuid?: string, cityUuid?: string, regionUuid?: string, municipalityUuid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCompaniesSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompaniesGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, name, organizationNumber, addressLine1, addressLine2, postalCode, countryUuid, cityUuid, regionUuid, municipalityUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Company Attachments
         * @param {string} companyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet(companyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet(companyUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Company Auditlog
         * @param {string} companyUuid 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet(companyUuid: string, excludeFields?: string, page?: number, pageSize?: number, order?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLogSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet(companyUuid, excludeFields, page, pageSize, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Company
         * @param {string} companyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleCompanyCompaniesCompanyUuidGet(companyUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDetailSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleCompanyCompaniesCompanyUuidGet(companyUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Company
         * @param {string} companyUuid 
         * @param {PatchCompanySerializerDTO} patchCompanySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCompanyCompaniesCompanyUuidPatch(companyUuid: string, patchCompanySerializerDTO: PatchCompanySerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCompanyCompaniesCompanyUuidPatch(companyUuid, patchCompanySerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Company
         * @param {string} companyUuid 
         * @param {CreateCompanySerializerDTO} createCompanySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompanyCompaniesCompanyUuidPut(companyUuid: string, createCompanySerializerDTO: CreateCompanySerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompanyCompaniesCompanyUuidPut(companyUuid, createCompanySerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Company Attachment
         * @param {string} companyUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost(companyUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttachmentSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost(companyUuid, attachmentCategory, attachmentType, files, title, description, visibility, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesAPI - factory interface
 * @export
 */
export const CompaniesAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesAPIFp(configuration)
    return {
        /**
         * 
         * @summary Create Company
         * @param {CreateCompanySerializerDTO} createCompanySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyCompaniesPost(createCompanySerializerDTO: CreateCompanySerializerDTO, options?: any): AxiosPromise<CompanySerializerDTO> {
            return localVarFp.createCompanyCompaniesPost(createCompanySerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Company Attachment
         * @param {string} companyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete(companyUuid: string, attachmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete(companyUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Company
         * @param {string} companyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyCompaniesCompanyUuidDelete(companyUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompanyCompaniesCompanyUuidDelete(companyUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Company Attachment
         * @param {string} companyUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet(companyUuid: string, attachmentUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet(companyUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Companies
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {string} [organizationNumber] 
         * @param {string} [addressLine1] 
         * @param {string} [addressLine2] 
         * @param {string} [postalCode] 
         * @param {string} [countryUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompaniesGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, name?: string, organizationNumber?: string, addressLine1?: string, addressLine2?: string, postalCode?: string, countryUuid?: string, cityUuid?: string, regionUuid?: string, municipalityUuid?: string, options?: any): AxiosPromise<ListCompaniesSerializerDTO> {
            return localVarFp.getCompaniesCompaniesGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, name, organizationNumber, addressLine1, addressLine2, postalCode, countryUuid, cityUuid, regionUuid, municipalityUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Company Attachments
         * @param {string} companyUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet(companyUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): AxiosPromise<ListAttachmentSerializerDTO> {
            return localVarFp.getCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet(companyUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Company Auditlog
         * @param {string} companyUuid 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet(companyUuid: string, excludeFields?: string, page?: number, pageSize?: number, order?: string, options?: any): AxiosPromise<ListLogSerializerDTO> {
            return localVarFp.getSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet(companyUuid, excludeFields, page, pageSize, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Company
         * @param {string} companyUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleCompanyCompaniesCompanyUuidGet(companyUuid: string, options?: any): AxiosPromise<CompanyDetailSerializerDTO> {
            return localVarFp.getSingleCompanyCompaniesCompanyUuidGet(companyUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Company
         * @param {string} companyUuid 
         * @param {PatchCompanySerializerDTO} patchCompanySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompanyCompaniesCompanyUuidPatch(companyUuid: string, patchCompanySerializerDTO: PatchCompanySerializerDTO, options?: any): AxiosPromise<CompanySerializerDTO> {
            return localVarFp.patchCompanyCompaniesCompanyUuidPatch(companyUuid, patchCompanySerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Company
         * @param {string} companyUuid 
         * @param {CreateCompanySerializerDTO} createCompanySerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompanyCompaniesCompanyUuidPut(companyUuid: string, createCompanySerializerDTO: CreateCompanySerializerDTO, options?: any): AxiosPromise<CompanySerializerDTO> {
            return localVarFp.putCompanyCompaniesCompanyUuidPut(companyUuid, createCompanySerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Company Attachment
         * @param {string} companyUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost(companyUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): AxiosPromise<Array<AttachmentSerializerDTO>> {
            return localVarFp.uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost(companyUuid, attachmentCategory, attachmentType, files, title, description, visibility, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCompanyCompaniesPost operation in CompaniesAPI.
 * @export
 * @interface CompaniesAPICreateCompanyCompaniesPostRequest
 */
export interface CompaniesAPICreateCompanyCompaniesPostRequest {
    /**
     * 
     * @type {CreateCompanySerializerDTO}
     * @memberof CompaniesAPICreateCompanyCompaniesPost
     */
    readonly createCompanySerializerDTO: CreateCompanySerializerDTO
}

/**
 * Request parameters for deleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete operation in CompaniesAPI.
 * @export
 * @interface CompaniesAPIDeleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDeleteRequest
 */
export interface CompaniesAPIDeleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIDeleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete
     */
    readonly companyUuid: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIDeleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for deleteCompanyCompaniesCompanyUuidDelete operation in CompaniesAPI.
 * @export
 * @interface CompaniesAPIDeleteCompanyCompaniesCompanyUuidDeleteRequest
 */
export interface CompaniesAPIDeleteCompanyCompaniesCompanyUuidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIDeleteCompanyCompaniesCompanyUuidDelete
     */
    readonly companyUuid: string
}

/**
 * Request parameters for downloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet operation in CompaniesAPI.
 * @export
 * @interface CompaniesAPIDownloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGetRequest
 */
export interface CompaniesAPIDownloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIDownloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly companyUuid: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIDownloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet
     */
    readonly attachmentUuid: string
}

/**
 * Request parameters for getCompaniesCompaniesGet operation in CompaniesAPI.
 * @export
 * @interface CompaniesAPIGetCompaniesCompaniesGetRequest
 */
export interface CompaniesAPIGetCompaniesCompaniesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly organizationNumber?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly addressLine1?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly addressLine2?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly postalCode?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly countryUuid?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly cityUuid?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly regionUuid?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompaniesCompaniesGet
     */
    readonly municipalityUuid?: string
}

/**
 * Request parameters for getCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet operation in CompaniesAPI.
 * @export
 * @interface CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGetRequest
 */
export interface CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet
     */
    readonly companyUuid: string

    /**
     * 
     * @type {number}
     * @memberof CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet
     */
    readonly order?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet
     */
    readonly updatedAtLte?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet
     */
    readonly updatedAtGt?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet
     */
    readonly type?: string
}

/**
 * Request parameters for getSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet operation in CompaniesAPI.
 * @export
 * @interface CompaniesAPIGetSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGetRequest
 */
export interface CompaniesAPIGetSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet
     */
    readonly companyUuid: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet
     */
    readonly excludeFields?: string

    /**
     * 
     * @type {number}
     * @memberof CompaniesAPIGetSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof CompaniesAPIGetSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet
     */
    readonly order?: string
}

/**
 * Request parameters for getSingleCompanyCompaniesCompanyUuidGet operation in CompaniesAPI.
 * @export
 * @interface CompaniesAPIGetSingleCompanyCompaniesCompanyUuidGetRequest
 */
export interface CompaniesAPIGetSingleCompanyCompaniesCompanyUuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIGetSingleCompanyCompaniesCompanyUuidGet
     */
    readonly companyUuid: string
}

/**
 * Request parameters for patchCompanyCompaniesCompanyUuidPatch operation in CompaniesAPI.
 * @export
 * @interface CompaniesAPIPatchCompanyCompaniesCompanyUuidPatchRequest
 */
export interface CompaniesAPIPatchCompanyCompaniesCompanyUuidPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIPatchCompanyCompaniesCompanyUuidPatch
     */
    readonly companyUuid: string

    /**
     * 
     * @type {PatchCompanySerializerDTO}
     * @memberof CompaniesAPIPatchCompanyCompaniesCompanyUuidPatch
     */
    readonly patchCompanySerializerDTO: PatchCompanySerializerDTO
}

/**
 * Request parameters for putCompanyCompaniesCompanyUuidPut operation in CompaniesAPI.
 * @export
 * @interface CompaniesAPIPutCompanyCompaniesCompanyUuidPutRequest
 */
export interface CompaniesAPIPutCompanyCompaniesCompanyUuidPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIPutCompanyCompaniesCompanyUuidPut
     */
    readonly companyUuid: string

    /**
     * 
     * @type {CreateCompanySerializerDTO}
     * @memberof CompaniesAPIPutCompanyCompaniesCompanyUuidPut
     */
    readonly createCompanySerializerDTO: CreateCompanySerializerDTO
}

/**
 * Request parameters for uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost operation in CompaniesAPI.
 * @export
 * @interface CompaniesAPIUploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPostRequest
 */
export interface CompaniesAPIUploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIUploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost
     */
    readonly companyUuid: string

    /**
     * 
     * @type {AttachmentCategoryEnumDTO}
     * @memberof CompaniesAPIUploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost
     */
    readonly attachmentCategory: AttachmentCategoryEnumDTO

    /**
     * 
     * @type {AttachmentTypeEnumDTO}
     * @memberof CompaniesAPIUploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost
     */
    readonly attachmentType: AttachmentTypeEnumDTO

    /**
     * 
     * @type {Array<any>}
     * @memberof CompaniesAPIUploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost
     */
    readonly files: Array<any>

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIUploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost
     */
    readonly title?: string

    /**
     * 
     * @type {string}
     * @memberof CompaniesAPIUploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost
     */
    readonly description?: string

    /**
     * 
     * @type {AttachmentVisibilityEnumDTO}
     * @memberof CompaniesAPIUploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost
     */
    readonly visibility?: AttachmentVisibilityEnumDTO
}

/**
 * CompaniesAPI - object-oriented interface
 * @export
 * @class CompaniesAPI
 * @extends {BaseAPI}
 */
export class CompaniesAPI extends BaseAPI {
    /**
     * 
     * @summary Create Company
     * @param {CompaniesAPICreateCompanyCompaniesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesAPI
     */
    public createCompanyCompaniesPost(requestParameters: CompaniesAPICreateCompanyCompaniesPostRequest, options?: any) {
        return CompaniesAPIFp(this.configuration).createCompanyCompaniesPost(requestParameters.createCompanySerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Company Attachment
     * @param {CompaniesAPIDeleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesAPI
     */
    public deleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete(requestParameters: CompaniesAPIDeleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDeleteRequest, options?: any) {
        return CompaniesAPIFp(this.configuration).deleteCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDelete(requestParameters.companyUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Company
     * @param {CompaniesAPIDeleteCompanyCompaniesCompanyUuidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesAPI
     */
    public deleteCompanyCompaniesCompanyUuidDelete(requestParameters: CompaniesAPIDeleteCompanyCompaniesCompanyUuidDeleteRequest, options?: any) {
        return CompaniesAPIFp(this.configuration).deleteCompanyCompaniesCompanyUuidDelete(requestParameters.companyUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Company Attachment
     * @param {CompaniesAPIDownloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesAPI
     */
    public downloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet(requestParameters: CompaniesAPIDownloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGetRequest, options?: any) {
        return CompaniesAPIFp(this.configuration).downloadCompanyAttachmentCompaniesCompanyUuidAttachmentsAttachmentUuidDownloadGet(requestParameters.companyUuid, requestParameters.attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Companies
     * @param {CompaniesAPIGetCompaniesCompaniesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesAPI
     */
    public getCompaniesCompaniesGet(requestParameters: CompaniesAPIGetCompaniesCompaniesGetRequest = {}, options?: any) {
        return CompaniesAPIFp(this.configuration).getCompaniesCompaniesGet(requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.name, requestParameters.organizationNumber, requestParameters.addressLine1, requestParameters.addressLine2, requestParameters.postalCode, requestParameters.countryUuid, requestParameters.cityUuid, requestParameters.regionUuid, requestParameters.municipalityUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Company Attachments
     * @param {CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesAPI
     */
    public getCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet(requestParameters: CompaniesAPIGetCompanyAttachmentsCompaniesCompanyUuidAttachmentsGetRequest, options?: any) {
        return CompaniesAPIFp(this.configuration).getCompanyAttachmentsCompaniesCompanyUuidAttachmentsGet(requestParameters.companyUuid, requestParameters.page, requestParameters.pageSize, requestParameters.order, requestParameters.updatedAtLte, requestParameters.updatedAtGt, requestParameters.search, requestParameters.category, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Company Auditlog
     * @param {CompaniesAPIGetSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesAPI
     */
    public getSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet(requestParameters: CompaniesAPIGetSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGetRequest, options?: any) {
        return CompaniesAPIFp(this.configuration).getSingleCompanyAuditlogCompaniesCompanyUuidAuditlogsGet(requestParameters.companyUuid, requestParameters.excludeFields, requestParameters.page, requestParameters.pageSize, requestParameters.order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Company
     * @param {CompaniesAPIGetSingleCompanyCompaniesCompanyUuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesAPI
     */
    public getSingleCompanyCompaniesCompanyUuidGet(requestParameters: CompaniesAPIGetSingleCompanyCompaniesCompanyUuidGetRequest, options?: any) {
        return CompaniesAPIFp(this.configuration).getSingleCompanyCompaniesCompanyUuidGet(requestParameters.companyUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Company
     * @param {CompaniesAPIPatchCompanyCompaniesCompanyUuidPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesAPI
     */
    public patchCompanyCompaniesCompanyUuidPatch(requestParameters: CompaniesAPIPatchCompanyCompaniesCompanyUuidPatchRequest, options?: any) {
        return CompaniesAPIFp(this.configuration).patchCompanyCompaniesCompanyUuidPatch(requestParameters.companyUuid, requestParameters.patchCompanySerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Company
     * @param {CompaniesAPIPutCompanyCompaniesCompanyUuidPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesAPI
     */
    public putCompanyCompaniesCompanyUuidPut(requestParameters: CompaniesAPIPutCompanyCompaniesCompanyUuidPutRequest, options?: any) {
        return CompaniesAPIFp(this.configuration).putCompanyCompaniesCompanyUuidPut(requestParameters.companyUuid, requestParameters.createCompanySerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Company Attachment
     * @param {CompaniesAPIUploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesAPI
     */
    public uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost(requestParameters: CompaniesAPIUploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPostRequest, options?: any) {
        return CompaniesAPIFp(this.configuration).uploadCompanyAttachmentCompaniesCompanyUuidAttachmentsPost(requestParameters.companyUuid, requestParameters.attachmentCategory, requestParameters.attachmentType, requestParameters.files, requestParameters.title, requestParameters.description, requestParameters.visibility, options).then((request) => request(this.axios, this.basePath));
    }
}
