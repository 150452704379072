import type { AutocompleteRenderGroupParams } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import ListSubheader from '@mui/material/ListSubheader';
import type { TextFieldProps } from '@mui/material/TextField';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FieldProps as FormikFieldProps } from 'formik';
import isFunction from 'lodash-es/isFunction';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { FC, FocusEvent, SyntheticEvent } from 'react';

import { useDebounce } from '../../../hooks';
import { AsyncContentProvider } from '../../../providers';
import { useTranslation } from '../../../translations';
import {
  AsyncAutocompleteNestedFields,
  FieldLabel,
  AsyncAutocompleteMenuItem,
  AsyncAutocompleteTextField,
} from '../../components';
import { DEFAULT_ASYNC_AUTOCOMPLETE_VALUE } from '../../form.const';
import { getFirstErrorKey } from '../../helpers';
import type { AsyncAutocompleteOption, AsyncAutocompleteProps } from '../types';
import { getOptionCategoryLabelKey } from './async-autocomplete-field.const';
import type { OptionCategory } from './async-autocomplete-field.enum';
import { isOptionEqualToValue } from './async-autocomplete-field.helper';
import { sxProps } from './async-autocomplete-field.styles';

type Props<TFormValues> = FormikFieldProps<
  AsyncAutocompleteOption,
  TFormValues
> & {
  props: AsyncAutocompleteProps<TFormValues>;
};

export const AsyncAutocompleteField = <TFormValues,>(
  props: Props<TFormValues>,
): ReturnType<FC<Props<TFormValues>>> => {
  const lastRequestTS = useRef(0);
  const { field, form, meta, props: fieldProps } = props;
  const { t } = useTranslation();
  const { error, initialValue, touched, value } = meta;
  const { setFieldValue, setFieldError, setFieldTouched, values } = form;
  const { name, onBlur } = field;
  const {
    callback$,
    disabled,
    disableWhen,
    formControlSx,
    isFilter,
    labelKey,
    nestedFieldsConfig,
    options: specifiedOptions = [],
    readonly,
    required,
    requiredWhen,
  } = fieldProps;
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] =
    useState<AsyncAutocompleteOption[]>(specifiedOptions);
  const finalLabelKey = useMemo(
    () => (isFunction(labelKey) ? labelKey(values) : labelKey),
    [labelKey, values],
  );
  const isDisabled = useMemo(
    () => Boolean(disabled || readonly || (disableWhen && disableWhen(values))),
    [disabled, disableWhen, readonly, values],
  );
  const isRequired = useMemo(
    () => Boolean(required || (requiredWhen && requiredWhen(values))),
    [required, requiredWhen, values],
  );
  const getOptionDetails$ = useCallback(() => {
    if (
      !nestedFieldsConfig?.callback$ ||
      !value ||
      !touched ||
      value?.value === initialValue?.value
    )
      return Promise.resolve(undefined);

    return nestedFieldsConfig.callback$(value.value);
  }, [initialValue?.value, nestedFieldsConfig, touched, value]);
  const getSuggestions$ = useCallback(
    async (query: string, values: TFormValues, isDisabled = false) => {
      if (isDisabled) return;

      const currentTs = +new Date();

      try {
        setLoading(true);
        const options = await callback$(query, values);

        // Temporary solution to show the last result despite the earlier ones
        if (currentTs < lastRequestTS.current) return;

        lastRequestTS.current = currentTs;
        setOptions([...specifiedOptions, ...options]);
      } catch (e) {
        setFieldError(name, e.message);
        setOptions(specifiedOptions);
      } finally {
        setLoading(false);
      }
    },
    [callback$, name, setFieldError, specifiedOptions],
  );
  const debouncedUpdateSearchQuery = useDebounce(getSuggestions$, 500);
  const handleInputChange: TextFieldProps['onChange'] = (event) => {
    debouncedUpdateSearchQuery(event.target.value, values, isDisabled);
  };
  const handleAutocompleteChange = useCallback(
    (event: SyntheticEvent, option: AsyncAutocompleteOption) => {
      setFieldValue(name, option || DEFAULT_ASYNC_AUTOCOMPLETE_VALUE);
      setOptions(specifiedOptions);

      if (!touched) {
        setFieldTouched(name, true, false);
      }
    },
    [name, setFieldTouched, setFieldValue, specifiedOptions, touched],
  );
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLDivElement>): void => {
      setOptions(specifiedOptions);
      onBlur(event);
    },
    [onBlur, specifiedOptions],
  );
  const handleClick = useCallback(async () => {
    if (value) return;

    await getSuggestions$('', values, isDisabled);
  }, [getSuggestions$, isDisabled, value, values]);
  const handleKeyDown: TextFieldProps['onKeyDown'] = useCallback((event) => {
    if (event.key === 'Enter') event.preventDefault();
  }, []);
  const errorMessageKey = useMemo(
    () => (touched ? getFirstErrorKey(error) : ''),
    [error, touched],
  );
  const isError = useMemo(() => Boolean(errorMessageKey), [errorMessageKey]);
  const getOptionLabel = useCallback(
    (option: AsyncAutocompleteOption) => {
      if (option?.labelKey) return t(option.labelKey);
      if (option?.label) return option.label;

      return '';
    },
    [t],
  );
  const shouldGroup = useMemo(
    () => options.some((option) => option && option.category),
    [options],
  );
  const groupProps = {
    groupBy: (option: AsyncAutocompleteOption) => option?.category || 'other',
    renderGroup: (params: AutocompleteRenderGroupParams) => {
      const { children, group } = params;
      const labelKey = getOptionCategoryLabelKey(group as OptionCategory);
      const key = `${labelKey}-subheader`;
      const label = t(labelKey);

      return (
        <Fragment key={key}>
          <ListSubheader>{label}</ListSubheader>
          {children}
        </Fragment>
      );
    },
  };

  useEffect(() => {
    return () => {
      lastRequestTS.current = 0;
    };
  }, []);

  return (
    <>
      <FormControl
        component="fieldset"
        disabled={isDisabled}
        error={isError}
        fullWidth
        required={isRequired && !readonly}
        sx={
          {
            ...(isFilter && sxProps.filterFormControl),
            ...formControlSx,
          } as SxProps
        }
      >
        {isFilter && (
          <Box>
            <FieldLabel isShrink={false} labelKey={finalLabelKey} />
          </Box>
        )}
        <Autocomplete<AsyncAutocompleteOption>
          disabled={isDisabled}
          filterOptions={(options) => options}
          getOptionLabel={getOptionLabel}
          id={name}
          isOptionEqualToValue={isOptionEqualToValue}
          {...(shouldGroup && groupProps)}
          loading={isLoading}
          onBlur={handleBlur}
          onChange={handleAutocompleteChange}
          onFocus={handleClick}
          onKeyDown={handleKeyDown}
          options={options}
          popupIcon={false}
          renderOption={(props, option) => {
            const { id } = props;

            return (
              <AsyncAutocompleteMenuItem
                htmlProps={props}
                isFilter={isFilter}
                key={id}
                option={option}
              />
            );
          }}
          renderInput={(params) => (
            <AsyncAutocompleteTextField
              errorMessageKey={errorMessageKey}
              fieldProps={fieldProps}
              handleKeyDown={handleKeyDown}
              handleInputChange={handleInputChange}
              isDisabled={isDisabled}
              isError={isError}
              isLoading={isLoading}
              name={name}
              params={params}
              values={values}
            />
          )}
          sx={isFilter ? sxProps.filterField : null}
          value={value}
        />
      </FormControl>
      {nestedFieldsConfig && (
        <AsyncContentProvider getData$={getOptionDetails$}>
          {() => (
            <AsyncAutocompleteNestedFields fieldsConfig={nestedFieldsConfig} />
          )}
        </AsyncContentProvider>
      )}
    </>
  );
};
