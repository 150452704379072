import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type {
  BaseContactSerializerDTO,
  LinkedUserSerializerDTO,
} from '../../../../../connectors/property';
import type { UserSerializerDTO } from '../../../../../connectors/user';
import { getUserName, useTranslation } from '../../../../shared';
import { getUserRoleTranslationLabelKey } from '../../helpers';
import { sxProps } from './user-name.styles';

type Props = {
  user: UserSerializerDTO | BaseContactSerializerDTO | LinkedUserSerializerDTO;
  displayRole?: boolean;
  noAvatar?: boolean;
};

export const UserName: FC<Props> = (props) => {
  const { displayRole = false, noAvatar = false, user } = props;
  const { t } = useTranslation();
  const name = useMemo(() => getUserName(user), [user]);
  const roleLabelKey = useMemo(
    () =>
      'roles' in user && user.roles !== undefined
        ? getUserRoleTranslationLabelKey(user.roles)
        : undefined,
    [user],
  );
  const avatarSrc = useMemo(
    () => ('profilePicture' in user ? user.profilePicture : ''),
    [user],
  );

  return (
    <Grid alignItems="center" container spacing={1} wrap="nowrap">
      {!noAvatar && (
        <Grid item>
          <Avatar src={avatarSrc} sx={sxProps.avatar} />
        </Grid>
      )}
      <Grid item>
        <Typography variant="subtitle2">{name}</Typography>
        <Typography variant="body2">
          {displayRole && roleLabelKey ? t(roleLabelKey) : user.email}
        </Typography>
      </Grid>
    </Grid>
  );
};
