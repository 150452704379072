import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  checkedIcon: {
    height: 16,
    width: 16,
  },
  checkedStep: {
    backgroundColor: 'secondary.light',
    border: 'unset',
    color: (theme) => theme.palette.primary.contrastText,
  },
  container: {
    maxWidth: 135,
    textAlign: 'center',
    width: '100%',
  },
  spinner: {
    color: 'secondary.light',
    mb: 2,
  },
  step: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.background.paper,
    border: (theme) => `2px solid ${theme.palette.grey[300]}`,
    borderRadius: '50%',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    mb: 2,
    mx: 'auto',
    width: 40,
  },
};
