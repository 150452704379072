import { ReactComponent as AddIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add.svg';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import type { TicketTypeEnumDTO } from '../../../../../connectors/ticket';
import { useTranslation } from '../../../../shared';
import { getTicketTypeTranslationLabelKey } from '../../consts';
import { sxProps } from './list-buttons.styles';

type Props = {
  path: string;
  ticketType: TicketTypeEnumDTO;
};

export const ListButtons: FC<Props> = (props) => {
  const { path, ticketType } = props;
  const { category, id } = useParams();
  const { t } = useTranslation();
  const ticketTypeLabelKey = useMemo(
    () => getTicketTypeTranslationLabelKey(ticketType),
    [ticketType],
  );
  const dataTestId = useMemo(
    () => `button-create-${ticketType}`.replace('_', '-'),
    [ticketType],
  );

  return (
    <Grid container item justifyContent="flex-end" xs={12}>
      <Button
        data-testid={dataTestId}
        color="secondary"
        component={Link}
        size="large"
        to={generatePath(path, { category, id })}
        variant="contained"
      >
        <Icon sx={sxProps.icon}>
          <AddIcon />
        </Icon>
        {t('ticket.entity.create.buttonLabel', {
          ticketType: t(ticketTypeLabelKey),
        })}
      </Button>
    </Grid>
  );
};
