import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  box: {
    mb: 1,
    mt: 3,
  },
  features: {
    borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
    mt: 2,
  },
  header: {
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    color: (theme) => theme.palette.secondary.light,
  },
  switch: {
    ml: 1.5,
    mr: 1,
  },

  title: {
    fontSize: (theme) => theme.typography.pxToRem(26),
    fontWeight: (theme) => theme.typography.fontWeightBold,
    ml: 1,
  },
};
