import { ReactComponent as RemoveIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import Backdrop from '@mui/material/Backdrop';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useMemo } from 'react';

import { ImagePreview } from '../Carousel/ImagePreview/image-preview.component';
import { Thumbnails } from '../Carousel/Thumbnails/thumbnails.component';
import type { Direction, Image } from '../Carousel/carousel.type';
import { sxProps } from './gallery-preview.styles';

type Props = {
  activeIdx: number;
  images: Image[];
  open: boolean;
  setActiveIdx: Dispatch<SetStateAction<number>>;
  setOpen: Dispatch<boolean>;
};

const DEFAULT_ITEM_IDX = 0;

export const GalleryPreview: FC<Props> = (props) => {
  const { activeIdx, images, open, setActiveIdx, setOpen } = props;
  const currentImage = useMemo(() => images[activeIdx], [activeIdx, images]);
  const changeVisibleImage = useCallback(
    (direction: Direction): void => {
      setActiveIdx((prevState: number) => {
        if (direction === 'left' && prevState === DEFAULT_ITEM_IDX)
          return images.length - 1;
        if (direction === 'right' && prevState === images.length - 1) return 0;

        return direction === 'left' ? prevState - 1 : prevState + 1;
      });
    },
    [images.length, setActiveIdx],
  );
  const handleKeyDown = useCallback<NonNullable<BoxProps['onKeyDown']>>(
    (ev): void => {
      switch (ev.key) {
        case 'ArrowLeft': {
          changeVisibleImage('left');
          break;
        }
        case 'ArrowRight': {
          changeVisibleImage('right');
          break;
        }
        case 'Escape': {
          setOpen(false);
          break;
        }
        default:
      }
    },
    [changeVisibleImage, setOpen],
  );

  return (
    <Backdrop sx={sxProps.backdrop} open={open} onClick={() => setOpen(false)}>
      <Box aria-hidden="true" onKeyDown={handleKeyDown} tabIndex={-1}>
        <Grid
          container
          flexDirection="column"
          spacing={2}
          sx={sxProps.content}
          wrap="nowrap"
        >
          <IconButton onClick={() => setOpen(false)} sx={sxProps.closeIcon}>
            <RemoveIcon height={24} width={24} />
          </IconButton>
          <Grid item>
            <Grid alignItems="flex-start" container spacing={[2, 2, 2, 4]}>
              <Grid item lg={8} sx={sxProps.contentColumn} xs={12}>
                <ImagePreview
                  name={currentImage.name}
                  url={currentImage.url}
                  sx={sxProps.imageWrapper}
                />
              </Grid>
              <Grid
                item
                lg={4}
                sx={
                  {
                    ...sxProps.contentColumn,
                    ...sxProps.scrollableColumn,
                  } as SxProps
                }
                xs={12}
              >
                <Box pb={2} pt={[2, 2, 2, 6]}>
                  <Typography color="primary.contrastText" mb={2} variant="h1">
                    {currentImage.name}
                  </Typography>
                  {currentImage.description && (
                    <Typography color="primary.contrastText">
                      {currentImage.description}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Thumbnails
              activeIdx={activeIdx}
              images={images}
              setActiveIdx={setActiveIdx}
            />
          </Grid>
        </Grid>
      </Box>
    </Backdrop>
  );
};
