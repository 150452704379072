import type {
  CreateLeaseTemplateSerializerDTO,
  LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPostRequest, // eslint-disable-line max-len
  LeaseTemplateSerializerDTO,
  ListLeaseTemplatesSerializerDTO,
  UploadAttachmentResponseSerializerDTO,
} from '../../connectors/contract';
import { LeaseTemplatesAPI } from '../../connectors/contract';
import { mieInstance } from '../mie.instance';
import type { ListQueryParams } from '../shared';
import { getEnvValue } from '../shared/helpers/env.helper';
import type { FilterValues } from './TemplateList/template-list.model';

class LibraryClient {
  private leasesTemplateClient;

  constructor() {
    const baeBath = getEnvValue('API_PATH');

    this.leasesTemplateClient = new LeaseTemplatesAPI(
      undefined,
      baeBath,
      mieInstance,
    );
  }

  public getTemplateList$ = async (
    queryParams?: ListQueryParams<FilterValues>,
  ): Promise<ListLeaseTemplatesSerializerDTO> => {
    const { filters, page, pageSize, search, sort } = { ...queryParams };
    const { country, language, type } = { ...filters };
    const response =
      await this.leasesTemplateClient.getLeaseTemplatesLeaseTemplatesGet({
        countryUuid: country || undefined,
        language: language || undefined,
        order: sort,
        page,
        pageSize,
        search,
        templateType: type || undefined,
      });

    return response.data;
  };

  public createTemplate$ = async (
    template: CreateLeaseTemplateSerializerDTO,
  ): Promise<LeaseTemplateSerializerDTO> => {
    const response =
      await this.leasesTemplateClient.createLeaseTemplateLeaseTemplatesPost({
        createLeaseTemplateSerializerDTO: template,
      });

    return response.data;
  };

  public uploadAttachment$ = async (
    // eslint-disable-next-line max-len
    payload: LeaseTemplatesAPIUploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPostRequest,
  ): Promise<UploadAttachmentResponseSerializerDTO> => {
    const response =
      await this.leasesTemplateClient.uploadLeaseTemplateAttachmentLeaseTemplatesLeaseTemplateLanguageUuidAttachmentsPost(
        payload,
      );

    return response.data;
  };
}

export const libraryClient = new LibraryClient();
