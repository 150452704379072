import React, { useMemo } from 'react';
import type { FC, ReactElement } from 'react';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';

import { NOT_FOUND_PATH } from '../../../../routing';
import { UnitCategoryPath } from '../../../paths.const';

interface Props {
  children: ReactElement;
}

export const CategoryGuard: FC<Props> = (props) => {
  const { children } = props;
  const { category } = useParams();
  const isValid = useMemo(
    () =>
      Object.values(UnitCategoryPath).includes(category as UnitCategoryPath),
    [category],
  );

  return isValid ? children : <Navigate to={NOT_FOUND_PATH} />;
};
