import {
  Status,
  Wrapper as GoogleMapsWrapper,
} from '@googlemaps/react-wrapper';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC, ReactElement, ReactNode } from 'react';
import React, { useCallback } from 'react';

import { getEnvValue } from '../../../helpers';
import { useTranslation } from '../../../translations';
import { Spinner } from '../../Spinner/spinner.component';
import { sxProps } from './wrapper.styles';

const API_KEY = getEnvValue('GOOGLE_API_KEY');

type Props = {
  children: NonNullable<ReactNode>;
  sx?: BoxProps['sx'];
};

export const Wrapper: FC<Props> = (props) => {
  const { children, sx } = props;
  const { langCode, t } = useTranslation();
  const render = useCallback<(status: Status) => ReactElement>(
    (status) => {
      if (status === Status.FAILURE)
        return <Typography>{t('errors.general.message')}</Typography>;

      return <Spinner />;
    },
    [t],
  );
  const boxSxProps = useCallback(() => ({ ...sxProps.wrapper, ...sx }), [sx]);

  return (
    <Box sx={boxSxProps}>
      <GoogleMapsWrapper
        apiKey={API_KEY}
        language={langCode}
        libraries={['places']}
        render={render}
      >
        {children}
      </GoogleMapsWrapper>
    </Box>
  );
};
