import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';

export const decodeSearchParam = <T = unknown>(
  param: string,
): T | undefined => {
  try {
    return JSON.parse(decodeURIComponent(param));
  } catch {
    return undefined;
  }
};

const encodeObject = (value: object): string | undefined => {
  const filteredObject = omitBy(value, (v) => v === '' || isNil(v));

  return !isEmpty(filteredObject)
    ? encodeURIComponent(JSON.stringify(filteredObject))
    : undefined;
};

export const encodeSearchParam = (
  value: boolean | number | object | string | undefined,
): string | undefined => {
  if (value === '' || isNil(value)) return undefined;

  return typeof value === 'object' && !Array.isArray(value)
    ? encodeObject(value)
    : value.toString();
};
