import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  actionsBox: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  button: { mb: 'auto', ml: 'auto' },
  disclaimerBox: {
    backgroundColor: (theme) => theme.palette.secondary.dark,
    borderRadius: 4,
    bottom: [12, 24],
    left: [12, 'unset'],
    maxWidth: ['100%', 320],
    position: 'absolute',
    pt: 6,
    px: 3,
    right: [12, 24],
    zIndex: (theme) => theme.zIndex.modal + 1,
  },
  image: {
    height: 135,
    width: 130,
  },
};
