import { ReactComponent as ViewOffIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/view/view-off.svg';
import { ReactComponent as WifiSignal1Icon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/wireless/wifi-signal-1.svg';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { UnitAdvertisementSerializerDTO } from '../../../../../connectors/property';
import { PublicationStatusEnumDTO } from '../../../../../connectors/property';
import type { FeatureProps } from '../../../../shared';
import { Feature, useTranslation } from '../../../../shared';
import { sxProps } from './status-feature.styles';

type Props = {
  status: UnitAdvertisementSerializerDTO['status'];
  size?: FeatureProps['size'];
  textWrap?: FeatureProps['textWrap'];
};

export const StatusFeature: FC<Props> = (props) => {
  const { size, status, textWrap } = props;
  const { t } = useTranslation();
  const isOnline = useMemo(
    () => status === PublicationStatusEnumDTO.Published,
    [status],
  );

  return (
    <Box>
      <Feature
        dataTestId="status"
        icon={isOnline ? <WifiSignal1Icon /> : <ViewOffIcon />}
        size={size}
        textWrap={textWrap}
        titleKey="advertisement.feature.status"
        value={
          <Typography
            component="span"
            fontWeight="bold"
            variant="h5"
            {...(isOnline && { sx: sxProps.online })}
          >
            {t(
              isOnline
                ? 'advertisement.status.online'
                : 'advertisement.status.offline',
            )}
          </Typography>
        }
      />
    </Box>
  );
};
