import { ReactComponent as RemoveCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/remove-circle.svg';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { getIcon } from '../../../../attachment/shared';
import type { CommentFilePreview } from '../../Typer/typer.type';
import { FileCategory } from '../../Typer/typer.type';
import { sxProps } from './attachment-preview.styles';

type Props = {
  attachment: CommentFilePreview;
  idx: number;
};

export const AttachmentPreview: FC<Props> = (props) => {
  const { attachment, idx } = props;
  const Icon = useMemo(() => getIcon(attachment.type), [attachment.type]);
  const isImage = useMemo(
    () => attachment.category === FileCategory.IMAGE,
    [attachment],
  );

  return (
    <Box
      key={attachment.id}
      sx={{
        ...(isImage ? sxProps.imageContainer : sxProps.documentContainer),
        backgroundImage: isImage ? `url(${attachment.data})` : null,
      }}
    >
      {!isImage && (
        <>
          <Box sx={sxProps.icon}>
            <Icon height={24} width={24} />
          </Box>
          <Typography sx={sxProps.name} variant="subtitle2">
            {attachment.name}
          </Typography>
        </>
      )}
      <IconButton
        sx={sxProps.removeButton}
        onClick={() => attachment.remove(idx)}
      >
        <RemoveCircleIcon height={16} width={16} />
      </IconButton>
    </Box>
  );
};
