/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum RoomTypeEnumDTO {
    Attic = 'attic',
    BalconyOrRoofTerrace = 'balcony_or_roof_terrace',
    Basement = 'basement',
    Bathroom = 'bathroom',
    Bedroom = 'bedroom',
    BoilerRoom = 'boiler_room',
    Elevator = 'elevator',
    Facade = 'facade',
    Firebreak = 'firebreak',
    OutdoorAreaOrGarden = 'outdoor_area_or_garden',
    Hall = 'hall',
    Hallway = 'hallway',
    Kitchen = 'kitchen',
    LivingRoom = 'living_room',
    OnTheRoof = 'on_the_roof',
    ParkingSpace = 'parking_space',
    RecreationRoom = 'recreation_room',
    ToiletRoom = 'toilet_room',
    Stairwell = 'stairwell',
    Storage = 'storage',
    StorageHallway = 'storage_hallway',
    BicycleParking = 'bicycle_parking',
    GarageGate = 'garage_gate',
    ParkingSpacePlatforms = 'parking_space_platforms',
    PlaygroundEquipment = 'playground_equipment',
    StreetWorkoutZoneEquipment = 'street_workout_zone_equipment',
    None = 'none'
}



