import { ReactComponent as CheckCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/check-circle-1.svg';
import { ReactComponent as SingleManActionsRefreshIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-users-actions-man/single-man-actions-refresh.svg';
import { ReactComponent as SingleManActionsRemoveIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-users-actions-man/single-man-actions-remove.svg';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo, useState } from 'react';
import type { FC, MouseEvent } from 'react';
import { toast } from 'react-hot-toast';
import { Trans } from 'react-i18next';

import { CustomErrorType, Dialog, useTranslation } from '../../../../shared';
import { isReassignConfig } from './confirmation-dialog.const';
import { sxProps } from './confirmation-dialog.styles';
import type { ConfirmationConfig } from './confirmation-dialog.type';

type Props = {
  callback$: () => Promise<void>;
  closeDialog: () => void;
  config: NonNullable<ConfirmationConfig>;
};

export const ConfirmationDialog: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { callback$, config, closeDialog } = props;
  const [isFetching, setFetching] = useState(false);
  const userName = useMemo(() => {
    return isReassignConfig(config) ? config.newUser?.label ?? '' : undefined;
  }, [config]);
  const handleClick$ = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      try {
        setFetching(true);
        await callback$();
        toast.success(t('dialog.confirmation.success'));
      } catch (e) {
        if (e.message === CustomErrorType.PREVENT_TOAST_ERROR) return;

        toast.error(t('errors.general.message'));
      } finally {
        closeDialog();
        setFetching(false);
      }
    },
    [callback$, closeDialog, t],
  );
  const confirmLabelKey = useMemo(() => {
    if (isReassignConfig(config)) return 'user.reassign.reassignButtonLabel';

    return 'dialog.confirmation.buttons.agree';
  }, [config]);
  const Icon = useMemo(
    () =>
      isReassignConfig(config)
        ? SingleManActionsRefreshIcon
        : SingleManActionsRemoveIcon,
    [config],
  );

  return (
    <Dialog
      enableActions={false}
      fullWidth
      isOpen={config.isOpen || false}
      maxWidth="xs"
      onClose={closeDialog}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sx={sxProps.gridItem}>
          <Icon height={64} width={64} />
        </Grid>
        <Grid item xs={12} sx={sxProps.gridItem}>
          <Typography variant="h4">{t('user.reassign.title')}</Typography>
        </Grid>
        <Grid item xs={12} sx={sxProps.gridItem}>
          <Typography>
            <Trans
              components={{ bold: <strong /> }}
              i18nKey={config.descriptionKey}
              values={{ userName }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sx={sxProps.buttonsGridItem}>
          <Button
            color="primary"
            disabled={isFetching}
            onClick={closeDialog}
            size="large"
            sx={sxProps.button}
            variant="outlined"
          >
            {t('user.reassign.cancelButtonLabel')}
          </Button>
          <LoadingButton
            color="primary"
            loading={isFetching}
            onClick={handleClick$}
            size="large"
            sx={sxProps.button}
            variant="contained"
          >
            <CheckCircleIcon height={24} width={24} />
            {t(confirmLabelKey)}
          </LoadingButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};
