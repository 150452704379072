import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { PriorityTypeEnumDTO } from '../../../../../connectors/ticket';

export const sxProps: Record<string, SxProps<Theme>> = {
  [PriorityTypeEnumDTO.Highest]: {
    color: (theme) => theme.palette.error.main,
  },
  [PriorityTypeEnumDTO.Lowest]: {
    color: (theme) => theme.palette.info.main,
  },
};
