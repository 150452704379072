/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum DamagedItemEnumDTO {
    Floor = 'floor',
    Drain = 'drain',
    Scrubber = 'scrubber',
    Bathtub = 'bathtub',
    ShowerDoor = 'shower_door',
    ShowerRail = 'shower_rail',
    ShowerHead = 'shower_head',
    ShowerDrain = 'shower_drain',
    ShowerHose = 'shower_hose',
    Mirror = 'mirror',
    Sink = 'sink',
    Luminaire = 'luminaire',
    Lamp = 'lamp',
    Ceiling = 'ceiling',
    PowerFailure = 'power_failure',
    SewerPipe = 'sewer_pipe',
    WindowInside = 'window_inside',
    WindowOutside = 'window_outside',
    FloorHeating = 'floor_heating',
    DoorInside = 'door_inside',
    Switch = 'switch',
    Vermin = 'vermin',
    Wall = 'wall',
    Socket = 'socket',
    SocketWithSwitch = 'socket_with_switch',
    CentralHeatingPiping = 'central_heating_piping',
    MechanicalVentilation = 'mechanical_ventilation',
    VentilationGrid = 'ventilation_grid',
    PullSwitch = 'pull_switch',
    Radiator = 'radiator',
    MainValve = 'main_valve',
    WashingMachineFaucet = 'washing_machine_faucet',
    WaterSystem = 'water_system',
    Boiler = 'boiler',
    Tap = 'tap',
    Toilet = 'toilet',
    PartitionWall = 'partition_wall',
    DoorOutside = 'door_outside',
    FacadeCladding = 'facade_cladding',
    WaspNest = 'wasp_nest',
    Roof = 'roof',
    Door = 'door',
    ElectricDoor = 'electric_door',
    HeatingSystem = 'heating_system',
    Graffiti = 'graffiti',
    SprinklerSystem = 'sprinkler_system',
    LightingInFirebreak = 'lighting_in_firebreak',
    Planting = 'planting',
    Paving = 'paving',
    Fence = 'fence',
    Intercom = 'intercom',
    Nameplate = 'nameplate',
    Numberplate = 'numberplate',
    Mailbox = 'mailbox',
    VentilationBalance = 'ventilation_balance',
    StairGate = 'stair_gate',
    Banister = 'banister',
    EmergencyLighting = 'emergency_lighting',
    FireExtinguisher = 'fire_extinguisher',
    FireAlarm = 'fire_alarm',
    FireHoseOrFireReel = 'fire_hose_or_fire_reel',
    SmokeDetector = 'smoke_detector',
    FrontDoorbell = 'front_doorbell',
    Step = 'step',
    Drainpipe = 'drainpipe',
    ShadingOrSunshades = 'shading_or_sunshades',
    OutdoorTap = 'outdoor_tap',
    GasTap = 'gas_tap',
    GasPipeline = 'gas_pipeline',
    Countertop = 'countertop',
    BuiltInEquipment = 'built_in_equipment',
    KitchenCabinet = 'kitchen_cabinet',
    KitchenDrawer = 'kitchen_drawer',
    Baseboard = 'baseboard',
    ExtractorHood = 'extractor_hood',
    GeyserTap = 'geyser_tap',
    DishwasherTap = 'dishwasher_tap',
    ButtonByTheElevatorDoor = 'button_by_the_elevator_door',
    ButtonsInTheElevatorItself = 'buttons_in_the_elevator_itself',
    HandrailInElevator = 'handrail_in_elevator',
    ElevatorMalfunctionsOrIsStandingStill = 'elevator_malfunctions_or_is_standing_still',
    ElevatorDoors = 'elevator_doors',
    ElevatorMirror = 'elevator_mirror',
    ElevatorChair = 'elevator_chair',
    EmergencyTelephoneInTheElevator = 'emergency_telephone_in_the_elevator',
    LightingInTheElevator = 'lighting_in_the_elevator',
    RoofCovering = 'roof_covering',
    Gutter = 'gutter',
    RoofTile = 'roof_tile',
    Chimney = 'chimney',
    GarageDoor = 'garage_door',
    ParkingBarrier = 'parking_barrier',
    ParkingPass = 'parking_pass',
    SinkOrFountain = 'sink_or_fountain',
    TelevisionOrTelephoneConnection = 'television_or_telephone_connection',
    Thermostat = 'thermostat',
    LoftLadder = 'loft_ladder',
    Pests = 'pests',
    SolarPanels = 'solar_panels',
    BicycleParking = 'bicycle_parking',
    BikeRack = 'bike_rack',
    CookerHood = 'cooker_hood',
    ElectricityIssue = 'electricity_issue',
    FurnitureDamage = 'furniture_damage',
    GarageGate = 'garage_gate',
    InductionHob = 'induction_hob',
    InternetAccess = 'internet_access',
    LanSocket = 'lan_socket',
    MovableBarrier = 'movable_barrier',
    Platform = 'platform',
    PlaygroundEquipment = 'playground_equipment',
    Refrigerator = 'refrigerator',
    Router = 'router',
    Sand = 'sand',
    Oven = 'oven',
    StreetWorkoutZoneEquipment = 'street_workout_zone_equipment',
    WashingMachine = 'washing_machine',
    Other = 'other'
}



