import { ReactComponent as UploadSquare3Icon } from '@heimstaden/icons-library/img/streamline-regular/internet-networks-servers/upload-download/upload-square-3.svg';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import type { FC } from 'react';
import React from 'react';

import { useTranslation } from '../../../../translations';
import { useAttachmentUploader } from '../../providers';
import { sxProps } from './list-buttons-panel.styles';

export const ListButtonsPanel: FC = () => {
  const { t } = useTranslation();
  const { openDialog } = useAttachmentUploader();

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="flex-end">
        <Button
          color="secondary"
          data-testid="button-upload-documents"
          onClick={openDialog}
          size="large"
          variant="contained"
        >
          <Icon sx={sxProps.icon}>
            <UploadSquare3Icon />
          </Icon>
          {t('attachment.buttons.upload')}
        </Button>
      </Grid>
    </Grid>
  );
};
